/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 *
 * ฤดูหนาว 20 ตค. - 20 กพ.

ฤดูหนาว
เริ่มประมาณกลางเดือนตุลาคมถึงประมาณกลางเดือนกุมภาพันธ์
ฤดูร้อน
เริ่มประมาณกลางเดือนกุมภาพันธ์ถึงประมาณกลางเดือนพฤษภาคม
https://www.tmd.go.th/info/info.php?FileID=23

comment จากผู้ใช้
- ช่วงแสดงอุณหภูมิต่ำสุด แสดงช่วงระหว่าง 1 พฤศจิกายน -สิ้นเดือนกุมพาพันธ์
-ช่วงแสดงอุณหภูมิสุงสุด แสดงช่วงระหว่าง 1 มีนาคม -สิ้นเดือนตุลาคม

 *
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { take, orderBy } from 'lodash';
import moment from 'moment';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';

import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Slider from 'react-slick';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  FaSun, FaChartLine, FaAngleRight, FaAngleLeft, FaRegCircle,
} from '../../utils/Fontawesome';

import DashboardContext from '../../contexts/dashboardContext';
import WeatherChart from './WeatherChart';
import TemperatureChart from './TemperatureChart';

// translate
import tJson from '../../utils/TJson';

// icon
import {
  HiiTemperIcon,
  HiiMoistureIcon,
  HiiPressureIcon,
  HiiSunshineIcon,
} from '../../utils/Icon';

import '../../../../public/css/slick.css';

import {
  styles,
} from '../../styles/Style';

const primary = blue[800];
const lineBox = grey[300];
const currentMonth = moment().format('M');
function NextArrow(props) {
  const { onClick } = props; // className
  return (
    <FaAngleRight
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#555',
        bottom: -30,
        right: 0,
        fontSize: '1.5em',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}
function PrevArrow(props) {
  const { onClick } = props; // className,
  return (
    <FaAngleLeft
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#555',
        bottom: -30,
        right: 26,
        fontSize: '1.5em',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

const statusOrange = orange[600];

class Temperature extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      dataSummary: [],
      datas: [],
      latestDate: new Date(),

    };
  }

  componentDidMount() {
    this._mounted = true;

    // get data from ws
    this.setData();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataTemperature !== this.props.dataTemperature) {
      this.setData();
    }
  }

  setData() {
    const { dataTemperature } = this.props;
    try {
      if (dataTemperature.data !== undefined) {
        const sort = (currentMonth >= 11 || currentMonth <= 2) ? 'asc' : 'desc';
        const datas = take(dataTemperature.data.data.data, 5);
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 1);
        const dataSummary = dataTemperature.summary.data.filter(d => {
          const time = new Date(d.temperature_datetime.replace(/-/g, '/')).getTime();
          return last24hrs < time;
        });
        this.setState({
          dataSummary,
          datas: orderBy(datas, ['temperature'], [sort]),
          latestDate: this.getMaxDate(datas),
        });
      }
      return '';
    } catch (error) {
      return '';
    }
  }

  handleChart = (handler, data) => e => {
    const { t } = this.props;
    e.preventDefault();

    const params = {
      header: t('กราฟสภาพอากาศ'),
      content: <WeatherChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };

    handler(params);
  }

  handleRainfallTempChart = (handler, data) => e => {
    const { t } = this.props;
    e.preventDefault();

    const params = {
      header: t('กราฟอุณหภูมิ'),
      content: <TemperatureChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };

    handler(params);
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.temperature_datetime.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format('YYYY-MM-DD')} เวลา : ${moment(maxdate).format('HH:mm')}`;
    }
    return moment().format('YYYY-MM-DD HH:mm');
  };

  render() {
    const { classes, t } = this.props;

    const {
      dataSummary, datas, latestDate,
    } = this.state;

    const statusColor = (currentMonth >= 11 || currentMonth <= 2) ? primary : statusOrange;
    let seasonMaxMin = t('อุณหภูมิสูงสุด');
    if (currentMonth >= 11 || currentMonth <= 2) {
      seasonMaxMin = t('อุณหภูมิต่ำสุด');
    }

    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      speed: 7000,
      autoplaySpeed: 7000,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 786,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 4,
          },
        },
        {
          breakpoint: 414,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    return (
      <Fragment key="key">
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box
                mt={{
                  xs: 0, sm: 0, md: 3, lg: 3,
                }}
                mb={0}
                pb={0}
                style={{ width: '100%' }}
              >
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiSunshineIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4">
                      {t('สภาพอากาศ')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Slider
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...settings}
              >
                {dataSummary.map(row => (
                  <Grid item xs key={row.geocode.area_name.th}>
                    <Box mt={3} ml={2} pt={2} border={1} borderBottom={3} borderColor={lineBox} className={classes.paperTempSlide} display="flex" align="center" justifyContent="center">
                      <Box>
                        <Box display="flex" alignItems="center" justifyContent="center" height={60}>
                          <Box display="block" p={1} fontSize={16} fontWeight="fontWeightBold" fontFamily="Kanit" justifyContent="center">
                            {`${tJson(row.geocode.area_name, 'ภาค')}`}
                          </Box>
                        </Box>
                        <Box display="flex" pb={-1} mb={-1} justifyContent="center">
                          <Box display="inline" pb={0} mb={0} fontSize={45} fontWeight="fontWeightBold" color={statusColor}>{row.temperature.toFixed(0)}</Box>
                          <Box display="inline" pt={1} color={statusColor}><FaRegCircle size={10} /></Box>
                        </Box>
                        <Box display="flex" justifyContent="center">
                          <Box align="center" width="100%">
                            <HiiTemperIcon />
                            {' '}
                            <Typography variant="body2" display="inline">{t('อุณหภูมิ')}</Typography>
                          </Box>
                        </Box>
                        <Box display="flex" pb={0} mb={0} mt={2} justifyContent="center">
                          <Box display="inline" pb={0} mb={0} fontSize={24} fontWeight="fontWeightBold" color={statusColor}>
                            {typeof row.humid !== 'undefined' && (
                              `${row.humid.toFixed(0)}`
                            )}
                            {typeof row.humid === 'undefined' && (
                              '-'
                            )}
                            <span style={{ fontSize: '1rem' }}>%</span>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="center">
                          <Box display="inline" align="center" width="100%">
                            <HiiMoistureIcon />
                            {' '}
                            {t('ความชื้นสัมพัทธ์')}
                          </Box>
                        </Box>
                        <Box display="flex" pb={0} mb={0} mt={2} justifyContent="center">
                          <Box display="inline" pb={0} mb={0} fontSize={21} fontWeight="fontWeightBold" color={statusColor}>
                            {typeof row.pressure !== 'undefined' && (
                              `${row.pressure.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                            )}
                            {typeof row.pressure === 'undefined' && (
                              '- '
                            )}
                            <span style={{ fontSize: '1rem' }}>hPa</span>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="center">
                          <Box display="inline" align="center" width="100%">
                            <HiiPressureIcon style={{ paddingTop: 5 }} />
                            {' '}
                            {t('ความกดอากาศ')}
                          </Box>
                        </Box>
                        <Box display="block" mt={2} p={1} alignItems="center" fontSize={12} fontFamily="sans-serif">
                          {`${tJson(row.geocode.tumbon_name, 'ต.')}`}
                          {' '}
                          {`${tJson(row.geocode.amphoe_name, 'อ.')}`}
                          {' '}
                          {`${tJson(row.geocode.province_name, 'จ.')}`}
                        </Box>
                        <Box display="block" color={grey[500]} p={1} alignItems="center" fontSize={10} fontFamily="sans-serif">
                          {t('เวลา')}
                          {' '}
                          {` ${moment(row.temperature_datetime).format('HH:mm')} `}
                          {' '}
                          {t('น.')}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Slider>
            </Grid>
            {/*  temperature table */}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box style={{ overflow: 'auto' }}>
                <Box display="flex" py={1} borderBottom={1} borderColor={lineBox} fontFamily="Kanit">
                  <Box p={2}><FaSun size={18} color={grey[500]} /></Box>
                  <Box p={1}>
                    <Box display="block" fontSize={18} fontWeight="fontWeightBold">
                      {`${seasonMaxMin} `}
                      {' ('}
                      {t('องศาเซลเซียส')}
                      {')'}
                    </Box>
                    <Box display="block" fontSize={12}>
                      {/* {`ล่าสุด ${moment(latestDate).locale('th').format('D MMM YYYY HH:mm')}`} */}
                      {/* {`ล่าสุด : ${moment(latestDate).format('YYYY-MM-DD')} เวลา : ${moment(latestDate).format('HH:mm')} น.`} */}
                      {t('ล่าสุด')}
                      {' '}
                      {` : ${latestDate} `}
                      {' '}
                      {t('น.')}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t('ที่ตั้ง')}
                        </TableCell>
                        <TableCell align="center">
                          {t('เวลา')}
                        </TableCell>
                        <TableCell align="center">
                          &#8451;
                        </TableCell>
                        <TableCell align="center">
                          {t('กราฟ')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {datas.map(row => (
                        <TableRow key={row.station.id}>
                          <TableCell component="th" scope="row" style={{ whiteSpace: 'normal', paddingLeft: 0 }}>
                            <DashboardContext.Consumer>
                              {({ handleModalOpen }) => (
                                <Fragment key="key">
                                  <Tooltip title={`${tJson(row.agency.agency_name, 'หน่วยงาน')}`}>
                                    <Button
                                      aria-label="Chart"
                                      onClick={this.handleChart(handleModalOpen, row)}
                                      style={{ textAlign: 'left', paddingLeft: 10 }}
                                    >
                                      {`${tJson(row.station.tele_station_name, 'สถานี')}`}
                                      {`${tJson(row.geocode.tumbon_name, 'ต.')}`}
                                      {`${tJson(row.geocode.amphoe_name, 'อ.')}`}
                                      {`${tJson(row.geocode.province_name, 'จ.')}`}
                                    </Button>
                                  </Tooltip>
                                </Fragment>
                              )}
                            </DashboardContext.Consumer>
                          </TableCell>
                          <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                            {moment(row.temperature_datetime).locale('th').format('HH:00')}
                            {' '}
                            {t('น.')}
                          </TableCell>
                          <TableCell align="right">
                            {row.temperature > 0 ? (
                              <div>
                                {row.temperature.toFixed(1)}
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DashboardContext.Consumer>
                              {({ handleModalOpen }) => (
                                <Fragment key="key">
                                  <Tooltip title={t('กราฟอุณหภูมิ')}>
                                    <IconButton className={classes.button} style={{ textAlign: 'center' }} aria-label="Chart" onClick={this.handleRainfallTempChart(handleModalOpen, row)}>
                                      <FaChartLine fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Fragment>
                              )}
                            </DashboardContext.Consumer>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

NextArrow.defaultProps = {
  // className: '',
  onClick: () => { },
};

PrevArrow.defaultProps = {
  // className: '',
  onClick: () => { },
};

NextArrow.propTypes = {
  // className: PropTypes.string,
  onClick: PropTypes.func,
};

PrevArrow.propTypes = {
  // className: PropTypes.string,
  onClick: PropTypes.func,
};

Temperature.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTemperature: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(Temperature);
