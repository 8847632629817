/* eslint-disable import/no-useless-path-segments */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AboutInfo from './AboutInfo';
import AboutDetail from './AboutDetail';
import AboutSlide from './AboutSlide';
import AboutMeaning from './AboutMeaning';
import AllLegend from '../legend/AllLegend';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    color: 'white',
    // backgroundColor: theme.palette.background.paper,
    "& .MuiPaper-root": {
      backgroundColor: 'rgb(6, 30, 44)',
      color: '#fff',
    },
  },
  divider: {
    borderTop: 'none',
    borderBottom: '1px solid #0f3045',
    marginTop: 0,
    marginBottom: 0,
  },
  body: {
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    color: 'white',
    margin: 0,
  },
  img: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  icon: {
    color: '#fff',
  },
}));

// img
const initPath = process.env.MIX_APP_PATH;

export default function NestedList() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <AboutDetail />
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>คู่มือการใช้งาน</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AboutSlide />
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.divider} />
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>ความหมายเกณฑ์สี</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AllLegend />
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.divider} />
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel3bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>ความหมายสัญลักษณ์</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AboutInfo />
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.divider} />
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel4bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>นิยามศัพท์</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AboutMeaning />
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.divider} />
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel5bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>ดาวน์โหลดคู่มือ</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box px={6}><a href={`${initPath}images/bma/BMA-Manual.pdf`} target="_blank"><img src={`${initPath}images/theme/bma/banner/about/manual.png`} alt="BMA" className={classes.img} /></a></Box>
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.divider} />
    </div>
  );
}
