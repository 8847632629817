import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { HiiTakeMaskIcon, HiiUnMaskIcon } from '../../../utils/Icon';
// import iconTemperature from '../../widgets/map/iconCircle';
// const classes = useStyles();

const Pm25Color = [
  {
    label: 'ดีมาก',
    rangeTitle: '0-15.0',
    statusColor: '#69C8FA',
    textColor: '#69C8FA',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#69C8FA',
    constrastColor: '#000',
    fontSize: '12px',
    index: 0,
  },
  {
    label: 'ดี',
    rangeTitle: '15.1-25.0',
    statusColor: '#92d050',
    textColor: '#92d010',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#9ECC63',
    constrastColor: '#FFF',
    fontSize: '12px',
    index: 1,
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '25.1-37.5',
    statusColor: '#FFFD54',
    textColor: '#E2E05B',
    textColorTb: '#000',
    textColorAvgTb: '#000',
    bgColorSitComp: '#FFFD54',
    constrastColor: '#000',
    fontSize: '12px',
    index: 2,
  },
  {
    label: 'เริ่มมีผลต่อสุขภาพ',
    rangeTitle: '37.6-75.0',
    statusColor: '#F2A43C',
    textColor: '#F2A43C',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#FE8A04',
    constrastColor: '#000',
    fontSize: '12px',
    index: 3,
  },
  {
    label: 'มีผลกระทบต่อสุขภาพ',
    rangeTitle: '>75.1',
    statusColor: '#DD554D',
    textColor: '#DD554D',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#EE141F',
    constrastColor: '#000',
    fontSize: '12px',
    index: 4,
  },
];

const Pm10Color = [
  {
    label: 'ดีมาก',
    rangeTitle: '0-50',
    statusColor: '#69C8FA',
    textColor: '#69C8FA',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#69C8FA',
    constrastColor: '#000',
    fontSize: '12px',
    index: 0,
  },
  {
    label: 'ดี',
    rangeTitle: '51-80',
    statusColor: '#92d050',
    textColor: '#92d010',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#9ECC63',
    constrastColor: '#FFF',
    fontSize: '12px',
    index: 1,
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '81-120',
    statusColor: '#FFFD54',
    textColor: '#E2E05B',
    textColorTb: '#000',
    textColorAvgTb: '#000',
    bgColorSitComp: '#FFFD54',
    constrastColor: '#000',
    fontSize: '12px',
    index: 2,
  },
  {
    label: 'เริ่มมีผลต่อสุขภาพ',
    rangeTitle: '121-180',
    statusColor: '#F2A43C',
    textColor: '#F2A43C',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#FE8A04',
    constrastColor: '#000',
    fontSize: '12px',
    index: 3,
  },
  {
    label: 'มีผลกระทบต่อสุขภาพ',
    rangeTitle: '>181',
    statusColor: '#DD554D',
    textColor: '#DD554D',
    textColorTb: '#FFF',
    textColorAvgTb: '#000',
    bgColorSitComp: '#EE141F',
    constrastColor: '#000',
    fontSize: '12px',
    index: 4,
  },
];

const useStyles = makeStyles(theme => ({
  maskIcon: {
    fontSize: '4em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '3em',
    },
  },
}));

const PmStatusColor = (value, type) => {
  let color;
  if (type === 'layerPm10') {
    if (value > 181) {
      color = Pm10Color['4'];
    } else if (value > 120) {
      color = Pm10Color['3'];
    } else if (value > 80) {
      color = Pm10Color['2'];
    } else if (value > 50) {
      color = Pm10Color['1'];
    } else {
      color = Pm10Color['0'];
    }
  } else if (type === 'layerPm25') {
    if (value > 75) {
      color = Pm25Color['4'];
    } else if (value > 37.5) {
      color = Pm25Color['3'];
    } else if (value > 25) {
      color = Pm25Color['2'];
    } else if (value > 15) {
      color = Pm25Color['1'];
    }else if (value > 0) {
      color = Pm25Color['0']; 
    } else {
      color = Pm25Color['0'];
    }
  }
  return { color };
};

const PmIcon = ({ val, type }) => {
  const classes = useStyles();

  let compIcon;
  let par;
  if (type === 'pm25') {
    par = 37;
  } else {
    par = 80;
  }
  if (val > par) {
    compIcon = (<HiiTakeMaskIcon className={classes.maskIcon} />);
  } else {
    compIcon = (<HiiUnMaskIcon className={classes.maskIcon} />);
  }
  return (
    <>
      {compIcon}
    </>
  );
};
export {
  PmStatusColor,
  PmIcon,
  Pm25Color,
  Pm10Color,
};
PmIcon.propTypes = {
  val: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
