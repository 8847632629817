import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy, uniqueId, isEmpty } from 'lodash';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Hidden } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

// map
import L from 'leaflet';
import {
  Map, Marker, Popup, TileLayer, Tooltip, GeoJSON, LayerGroup, LayersControl, WMSTileLayer,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
import { GestureHandling } from 'leaflet-gesture-handling';
import FullscreenControl from 'react-leaflet-fullscreen';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { withTranslation } from 'react-i18next';
import tJson from '../../utils/TJson';
// color
import { FaChartLine, FaCircle, FaSquare } from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

// styles
import { styles } from '../../styles/Style';

// dam
import DamStatusColor from './DamStatusColor';
import DamDailyChart from './DamDailyChart';
import DamMediumChart from './DamMediumChart';
import DamContext from '../../contexts/damContext';

// css
// import '../../../../node_modules/leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import CurrentLocation from '../currentlocation/CurrentLocation';

// icons
import {
  HiiDamL0Icon,
  HiiDamL1Icon,
  HiiDamL2Icon,
  HiiDamL3Icon,
  HiiDamL4Icon,
  HiiDamL5Icon,
} from '../../utils/Icon';

const { BaseLayer, Overlay } = LayersControl;
const initPathWMS = process.env.MIX_APP_WMS;

/**
 * Dam Map
 */
class DamMap extends Component {
  // static whyDidYouRender = true

  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.damLargeLayer = createRef();
    this.damMediumLayer = createRef();

    this.state = {
      // currentHover: 'ชี้เส้นลำน้ำเพื่อแสดงชื่อ',
      height: this.setHeightMap(),
      geojsonProvince: [],
      geojsonBasin: [],
    };

    this.eachLargeDamData = this.eachLargeDamData.bind(this);
    this.eachMediumDamData = this.eachMediumDamData.bind(this);
    // this.onEachRiverFeature = this.onEachRiverFeature.bind(this);
    // this.onEachProvinceFeature = this.onEachProvinceFeature.bind(this);
    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.getGeoJson = this.getGeoJson.bind(this);
    this.handleZoom = this.handleZoom.bind(this);

    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;

    this._mounted = true;

    this.getGeoJson();
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  shouldComponentUpdate(nextProps) {
    const {
      datasLargeDam, datasMediumDam, areaType, areaId, language,
    } = this.props;
    if ((datasLargeDam === nextProps.datasLargeDam)
      && (datasMediumDam === nextProps.datasMediumDam)
      && (areaType === nextProps.areaType)
      && (language === nextProps.i18n.language)
      && (areaId === nextProps.areaId)) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const { areaType, areaId } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    let obj = this.refBoundaryProvince.current.leafletElement._layers;
    // thailand
    if (areaId === '') {
      const { location, zoom } = this.props;

      // set default map view
      // this.map.options.zoomSnap = 0.5;
      this.map.setView(location, zoom);

      // set default data

      return;
    }

    if (areaType !== prevProps.areaType || areaId !== prevProps.areaId) {
      if (areaType === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }

      // get feature
      // eslint-disable-next-line no-underscore-dangle

      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          // change zoom snap to make fitBounds work
          // this.map.options.zoomSnap = 0.8;

          // province
          if (areaType === '0') {
            // get province code
            const provCode = obj[key].feature.properties.prov_code;

            if (provCode.toString() === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          } else { // basin
            const basinCode = obj[key].feature.properties.BASIN_CODE;
            if (basinCode === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  // onEachRiverFeature(feature, layer) {
  //   layer.on({
  //     mouseover: e => {
  //       const myLayer = e.target;

  //       if (!L.Browser.ie && !L.Browser.opera) {
  //         myLayer.bringToFront();
  //       }
  //     },
  //   });
  // }

  // onEachProvinceFeature = (feature, layer) => {
  //   const popupContent = feature.properties.title;
  //   // layer.bindPopup(popupContent);
  //   layer.bindTooltip(popupContent);
  // }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 850;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 665;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 665;
    } else if (isWidthDown('md', width)) {
      heightMap = 600;
    }
    return heightMap;
  }

  getGeoJson() {
    Promise.all([
      fetch('json/boundary/thailand.json'),
      fetch('json/boundary/basin.json'),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([province, basin]) => {
        this.setState({ geojsonProvince: province, geojsonBasin: basin });
      })
      .catch(error => this.setState({ error }));
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle(feature) {
    const { areaType, areaId } = this.props;

    if (areaType === '1' && areaId) {
      if (feature.properties.BASIN_CODE === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getProvinceStyle(feature) {
    const { areaType, areaId } = this.props;

    if (areaType === '0' && areaId) {
      if (feature.properties.prov_code === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: `${t('กราฟอ่างเก็บน้ำรายวัน กรมชลประทาน')}`,
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamDailyChart id={data.dam.id} agencyId={12} />,
    };

    handler(params);
  }

  handleChartMedium = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const header = `${t('กราฟอ่างเก็บน้ำขนาดกลางรายปี กรมชลประทาน')}`;
    const { damsMediumRidList } = this.props;

    const params = {
      header,
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamMediumChart id={data.dam.id} damsMediumRid={damsMediumRidList} />,
    };

    handler(params);
  }

  // get dam status
  damStatus = (data, percent) => {
    const { t } = this.props;
    const status = DamStatusColor(parseFloat(percent).toFixed(0));
    const statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (<span style='color:${status.color.statusColor}'>${parseFloat(percent).toFixed(0)}% ${t('รนก.')}</span>)`;

    return statusText;
  }

  // set marker icon based on criteria
  setIcon = (val, type) => {
    const status = DamStatusColor(val, type);

    return status.icon;
  }

  setHighlightIcon = val => {
    const status = DamStatusColor(val);
    let icon = '';

    // setup icon
    if (val > 80) {
      icon = <HiiDamL5Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 60) {
      icon = <HiiDamL4Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 40) {
      icon = <HiiDamL3Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 20) {
      icon = <HiiDamL2Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 0) {
      icon = <HiiDamL1Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else {
      icon = <HiiDamL0Icon style={{ fontSize: '60px' }} />;
    }

    return icon;
  }

  handleZoom = () => {
    const currentZoom = this.map.getZoom();

    if (currentZoom <= 4) {
      // hide layers
      // console.log(this.map);
      this.map.removeLayer(this.damLargeLayer.current);
    } else {
      // show layers
    }
  }

  eachLargeDamData(data, i) {
    const { classes, t } = this.props;

    if (data.dam.dam_lat && data.dam.dam_long) {
      // marker position
      const position = [parseFloat(data.dam.dam_lat), parseFloat(data.dam.dam_long)];

      // marker icons
      const icon = this.setIcon(parseFloat(data.dam_storage_percent).toFixed(0), 'large');

      // highlight icon
      const highlightIcon = this.setHighlightIcon(parseFloat(data.dam_storage_percent).toFixed(0));
      const color = DamStatusColor(parseFloat(data.dam_storage_percent).toFixed(0));
      const damStorageStatus = this.damStatus(
        data.dam_storage,
        parseFloat(data.dam_storage_percent)
          .toFixed(0),
      );

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <div>
              <Box display="flex" justifyContent="center">
                <Box>
                  <Typography variant="subtitle2">
                    {`${tJson(data.dam.dam_name, ' ')} `}
                    {' '}
                    {`${tJson(data.geocode.province_name, 'จ.')}`}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box>
                  {highlightIcon}
                </Box>
                <Box mx={1}>
                  <Typography variant="h3" style={{ marginTop: '10px' }}>
                    <span style={{ color: color.color.statusColor }}>
                      <strong>
                        {`${parseFloat(data.dam_storage_percent).toFixed(0)}%`}
                      </strong>
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" mt={1}>
                <Box>
                  <strong>
                    {t('ปริมาณน้ำกักเก็บ')}
                    {`(% ${t('รนก.')})`}
                  </strong>
                </Box>
              </Box>
              <Box display="flex" mt={2}>
                <Box flexGrow={1}>
                  {t('น้ำไหลลง / ระบาย')}
                </Box>
                <Box>
                  <strong>{parseFloat(data.dam_inflow).toFixed(2)}</strong>
                  {' '}
                  /
                  {' '}
                  <strong>{data.dam_released}</strong>
                </Box>
              </Box>
              <Box display="flex" mt={1}>
                <Box flexGrow={1}>
                  {t('ปริมาณน้ำกักเก็บ')}
                </Box>
                <Box>
                  <strong>
                    <div
                      className="text-center"
                      style={{ whiteSpace: 'nowrap' }}
                      dangerouslySetInnerHTML={{ __html: damStorageStatus }}
                    />
                  </strong>
                </Box>
              </Box>
              <Box display="flex" mt={1}>
                <Box flexGrow={1}>
                  {t('ปริมาณน้ำใช้การได้จริง')}
                </Box>
                <Box>
                  {isEmpty(data.dam_uses_water_percent) === true && (
                    <strong>{`${data.dam_uses_water.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${parseFloat(data.dam_uses_water_percent).toFixed(0)}% ${t('รนก.')})`}</strong>
                  )}
                  {isEmpty(data.dam_uses_water_percent) === false && (
                    <strong>-</strong>
                  )}
                </Box>
              </Box>
              <Box display="flex" mt={1}>
                <Box flexGrow={1}>
                  {`${t('หน่วย')} ${t('(ล้าน ลบ.ม.)')}`}
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-end" mt={2}>
                <Box flexGrow={1} display="block">
                  <Box>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name, ' ')}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="secondary">
                      {`${t('วันที่')} ${data.dam_date}`}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <DamContext.Consumer>
                    {({ handleModalOpen }) => (
                      <Button color="secondary" onClick={this.handleChart(handleModalOpen, data)} title={t('แสดง')}>
                        <FaChartLine />
                      </Button>
                    )}
                  </DamContext.Consumer>
                </Box>
              </Box>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.dam.dam_name, ' ')}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  eachMediumDamData(data, i) {
    const { classes, t } = this.props;

    if (data.dam.dam_lat && data.dam.dam_long) {
      // marker position
      const position = [parseFloat(data.dam.dam_lat), parseFloat(data.dam.dam_long)];

      // marker icons
      const icon = this.setIcon(parseFloat(data.dam_storage_percent).toFixed(0), 'medium');

      // highlight icon
      const highlightIcon = this.setHighlightIcon(parseFloat(data.dam_storage_percent).toFixed(0));
      const color = DamStatusColor(parseFloat(data.dam_storage_percent).toFixed(0));

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {`${tJson(data.dam.dam_name, ' ')} `}
                        {' '}
                        {`${tJson(data.geocode.province_name, 'จ.')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                      <Box display="flex" justifyContent="center">
                        <Box>{highlightIcon}</Box>
                        <Box>
                          <Typography variant="h3" style={{ marginTop: '10px' }}>
                            <span style={{ color: color.color.statusColor }}>
                              <strong>
                                {`${parseFloat(data.dam_storage_percent).toFixed(0)}%`}
                              </strong>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <strong>
                        {t('ปริมาณน้ำกักเก็บ')}
                        {`(% ${t('รนก.')})`}
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ปริมาณน้ำกักเก็บ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>
                        {data.dam_storage}
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('น้ำไหลลง / ระบาย')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{parseFloat(data.dam_inflow).toFixed(2)}</strong>
                      {' '}
                      /
                      {' '}
                      <strong>{parseFloat(data.dam_released).toFixed(2)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      {`${t('หน่วย')} ${t('(ล้าน ลบ.ม.)')}`}
                    </TableCell>
                    <TableCell className={classes.hiddenLine} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`${t('วันที่')} ${data.dam_date}`}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <DamContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button color="secondary" onClick={this.handleChartMedium(handleModalOpen, data)} title={t('แสดง')}>
                            <FaChartLine />
                          </Button>
                        )}
                      </DamContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.dam.dam_name, ' ')}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')}`}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  mapLayerControl = () => {
    const { datasLargeDam, datasMediumDam, t } = this.props;
    const datasLargeDamSort = orderBy(datasLargeDam, 'dam_storage_percent', 'asc');
    const datasMediumDamSort = orderBy(datasMediumDam, 'dam_storage_percent', 'asc');
    return (
      <LayersControl position="topright">
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Esri_WorldStreetMap">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <Overlay name={t('อ่างขนาดใหญ่')} checked="true">
          <LayerGroup ref={this.damLargeLayer}>
            {datasLargeDamSort
              .map(this.eachLargeDamData)}
          </LayerGroup>
        </Overlay>
        <Overlay name={t('อ่างขนาดกลาง')} checked="true">
          <LayerGroup ref={this.damMediumLayer}>
            {datasMediumDamSort
              .map(this.eachMediumDamData)}
          </LayerGroup>
        </Overlay>
        <Overlay name={t('เส้นแม่น้ำ')} checked>
          <WMSTileLayer
            url={`${initPathWMS}wms`}
            version="1.1.0"
            opacity={1}
            transparent
            layers="layers=thaiwater30:river_thailand"
            srs="EPSG:4326"
            format="image/png"
            tiled
          />
        </Overlay>
      </LayersControl>
    );
  }

  render() {
    const {
      error, height, geojsonProvince, geojsonBasin, // currentHover,
    } = this.state;

    const {
      location, zoom, classes, t, i18n,
    } = this.props;

    // sort marker position z-index
    // const datasLargeDamSort = orderBy(datasLargeDam, 'dam_storage_percent', 'asc');
    // const datasMediumDamSort = orderBy(datasMediumDam, 'dam_storage_percent', 'asc');

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
            {error}
          </Typography>
        </Paper>
      );
    }

    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          onZoomEnd={this.handleZoom}
          gestureHandling
        >
          <Control position="bottomleft">
            <div className="info">
              <Typography variant="body1">
                <FaSquare />
                {' '}
                {t('อ่างขนาดใหญ่')}
                {' '}
                <FaCircle />
                {' '}
                {t('อ่างขนาดกลาง')}
              </Typography>
            </div>
          </Control>

          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonBasin}
            style={this.getBasinStyle}
            ref={this.refBoundaryBasin}
            // onEachFeature={this.onEachBasinFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            // onEachFeature={this.onEachProvinceFeature}
          />
          {/* <GeoJSON
            key={uniqueId()}
            data={geojsonRiver}
            style={this.getRiverStyle()}
            // onEachFeature={this.onEachRiverFeature}
          /> */}
          {(i18n.language === 'th') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'en') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'jp') && (
            this.mapLayerControl()
          )}
          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />
        </Map>
      </>
    );
  }
}

DamMap.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  datasLargeDam: PropTypes.array.isRequired,
  datasMediumDam: PropTypes.array.isRequired,
  damsMediumRidList: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  location: PropTypes.array.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  areaType: PropTypes.string.isRequired,
  areaId: PropTypes.string.isRequired,
  i18n: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(DamMap);
