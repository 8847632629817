import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// icon
import { FaCloud } from '../../utils/Fontawesome';
import { HiiForecastIcon } from '../../utils/Icon';

import Modal from '../modal/Modal';
import RainfallMap from './RainfallMap';
import RainfallContext from '../../contexts/rainfallContext';
import RainfallStatusColor from '../rainfall/RainfallStatusColor';
import RainfallChart from '../rainfall/RainfallChart';

// translate
import tJson from '../../utils/TJson';

import {
  styles, lineBox,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const style = {
  container: {
    maxHeight: 490,
    borderBottom: '1px solid #ccc',
  },
};

class Rainfall extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false,
      error: null,
      latest: moment().format('YYYY-MM-DD'),
      dataRainfall: [],
      type: 'dashBoard',

      // map
      map: null,
      zoom: 5.65,
      location: [12.9, 101.6],
      markers: [],

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      df: [],
    };

    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleSetMap = this.handleSetMap.bind(this);
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
  }

  getData() {
    // get data from service rain 24hr
    fetch(`${process.env.MIX_API_URL}public/rain_24h`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        // sorting and filter, rainfall24
        const rainfall24Sort = orderBy(result.data, ['rain_24h'], ['desc']);
        // https://devsupport.hii.or.th/?support_page=open_ticket&ticket_id=523&auth_code=hA0apG5G1o
        const filterDatas = rainfall24Sort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return ((last24hrs < time) && d.rain_24h >= 0.1);
        });
        this.setState({
          dataRainfall: filterDatas,
          isLoading: false,
          latest: this.getMaxDate(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.rainfall_datetime.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format('YYYY-MM-DD')} `;
    }
    return new Date();
  }

  // get rainfall status
  rainfallStatus = data => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(1), 'rainfall24h');
    return status;
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleChart = (handler, data, type) => e => {
    const { t } = this.props;
    e.preventDefault();
    const params = {
      header: t(data.station.tele_station_name, 'สถานี'),
      content: <RainfallChart
        id={data.station.id}
        provinceId={data.geocode.province_code}
        selectedType={type}
      />,
    };
    handler(params);
  }

  handleData = () => {
    this.setState({
      // filterDatas: data,
      type: 'rainfall24h',
    });
  };

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  render() {
    const { classes, t, i18n } = this.props;

    // get required data from state
    const {
      isLoading,
      error,
      open,
      dataRainfall,
      latest,
      type,
      map,
      markers,
      zoom,
      location,
      df,
      modalData,
    } = this.state;

    return (
      <div>
        {error && <div className="text-center">{error.message}</div>}
        <div className={classNames(classes.layout, classes.paperGrid)}>
          <RainfallContext.Provider
            value={{
              datas: dataRainfall,
              handleModalOpen: this.handleModalOpen,
              type,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs>
                <Box mt={3} mb={0} pb={0} style={{ width: '100%' }}>
                  <Box display="flex" width="100%">
                    <Box pt={1}>
                      <HiiForecastIcon fontSize={24} />
                    </Box>
                    <Box pt={1} pl={1}>
                      <Typography variant="h4" display="inline">
                        {t('แผนที่ฝน 24 ชม.')}
                        {' '}
                      </Typography>
                      <Typography variant="h6" display="inline">
                        {t('ย้อนหลัง')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box display="block" mt={2} flexDirection="row-reverse">
                  {/* <Box borderBottom="2" border="1" display="flex" justifyContent="flex-end">
                    <Button
                      aria-owns={anchorEl ? 'simple-menu' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                      variant="outlined"
                      size="small"
                      color="default"
                      align="right"
                      className={classes.button}
                      style={{ fontFamily: 'Kanit', fontSize: 14, marginRight: 5 }}
                    >
                      ภาพรวมประเทศไทย
                      <i className="material-icons">arrow_drop_down</i>
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                      style={{ fontFamily: 'Kanit', fontSize: 14 }}
                    >
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคเหนือ</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคตะวันออกเฉียงเหนือ</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคกลาง</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคใต้</MenuItem>
                    </Menu>
                    <Button
                      variant="outlined"
                      size="small"
                      color="default"
                      align="right"
                      className={classes.button}
                      style={{ fontFamily: 'Kanit', fontSize: 14 }}
                    >
                      ขยายแผนที่
                      <FaTimes style={{ marginLeft: 5 }}/>
                    </Button>
                  </Box> */}
                  <Box width="100%">
                    <RainfallMap
                      value=""
                      areaName=""
                      language={i18n.language}
                      datasRainToday={df}
                      datasRainYesterday={df}
                      datasRainMonth={df}
                      datasRainYear={df}
                      searchRainfall24={dataRainfall}
                      searchRainToday={df}
                      searchRainYesterday={df}
                      searchRainMonth={df}
                      searchRainYear={df}
                      dataRainfall={dataRainfall}
                      error={error}
                      isLoading={isLoading}
                      latest={latest}
                      map={map}
                      markers={markers}
                      modalHeader={modalData.header}
                      modalSubHeader={modalData.subheader}
                      modalContent={modalData.content}
                      open={open}
                      type={type}
                      // value={value}
                      zoom={zoom}
                      location={location}
                      handleSetMap={this.handleSetMap}
                    />
                  </Box>
                </Box>
                <Box display="flex" width="100%" justifyContent="center" alignContent="center">
                  <Box border={1} borderRight={0} borderColor={lineBox} align="center" display="flex" justifyContent="center" alignItems="center" width="20%">
                    <Box fontWeight="fontWeightMedium">
                      {t('เกณฑ์ (มม.)')}
                    </Box>
                  </Box>
                  <Box m={0} pb={0} borderTop={0} border={1} borderColor={lineBox} display="inline" width="15%">
                    <Box display="flex" borderTop={1} borderColor={lineBox}>
                      <Box display="inline" style={{ backgroundColor: '#AED3FF' }} width="100%" py={0}>
                        <div className={classes.regend}>
                          {'> 0-10 '}
                        </div>
                      </Box>
                    </Box>
                    <Box display="block" pb={-3} borderTop={1} borderColor={lineBox} aling="center">
                      <div className={classes.regend}>
                        {t('ฝนเล็กน้อย')}
                      </div>
                    </Box>
                  </Box>
                  <Box m={0} pb={0} borderTop={0} borderLeft={0} border={1} borderColor={lineBox} display="inline" width="20%">
                    <Box display="flex" borderTop={1} borderColor={lineBox} aling="center" width="100%">
                      <Box display="inline" style={{ backgroundColor: '#aeecb7' }} width="50%" py={0}>
                        <div className={classes.regend}>
                          {'> 10-20 '}
                        </div>
                      </Box>
                      <Box display="inline" style={{ backgroundColor: '#7fc63c' }} width="50%" py={0}>
                        <div className={classes.regend}>
                          {'> 20-35 '}
                        </div>
                      </Box>
                    </Box>
                    <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
                      <div className={classes.regend}>
                        {t('ฝนปานกลาง')}
                      </div>
                    </Box>
                  </Box>
                  <Box m={0} pb={0} borderTop={0} borderLeft={0} border={1} borderColor={lineBox} display="inline" aling="center" width="30%">
                    <Box display="flex" borderTop={1} borderColor={lineBox} aling="center" width="100%">
                      <Box display="inline" style={{ backgroundColor: '#f0d447' }} width="50%" py={0}>
                        <div className={classes.regend}>
                          {'> 35-50 '}
                        </div>
                      </Box>
                      <Box display="inline" style={{ backgroundColor: '#ef9036' }} width="50%" py={0}>
                        <div className={classes.regend}>
                          {'> 50-70 '}
                        </div>
                      </Box>
                      <Box display="inline" style={{ backgroundColor: '#bd6a27' }} width="50%" py={0}>
                        <div className={classes.regend} style={{ color: 'white' }}>
                          {'> 70-90 '}
                        </div>
                      </Box>
                    </Box>
                    <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
                      <div className={classes.regend}>
                        {t('ฝนหนัก')}
                      </div>
                    </Box>
                  </Box>
                  <Box m={0} pb={0} border={1} borderLeft={0} borderColor={lineBox} display="inline" aling="center" width="15%">
                    <Box display="block" style={{ backgroundColor: '#da352e' }} py={0} width="100%">
                      <div className={classes.regend} style={{ color: 'white' }}>
                        {'> 90 '}
                      </div>
                    </Box>
                    <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
                      <div className={classes.regend}>
                        {t('ฝนหนักมาก')}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                {/* <Box mt={2}>
                  <Link href={`${initPath}report-salinity`} title="ระบบติดตามสถานการณ์ ค่าความเค็ม คลองประเวศบุรีรมย์" target="_blank">
                    <img src={`${initPath}images/report-salinity/Banner.png`} alt="ระบบติดตามสถานการณ์ ค่าความเค็ม คลองประเวศบุรีรมย์" className={classes.responsive} />
                  </Link>
                </Box> */}
                <Box display="flex" pt={1} borderBottom={1}>
                  <Box p={2}><FaCloud size={18} color={grey[500]} /></Box>
                  <Box p={1}>
                    <Box display="block" fontSize={16} fontWeight="fontWeightBold">
                      {t('ปริมาณฝน 24 ชม. ย้อนหลัง')}
                      {' '}
                      {t('(มม.)')}
                    </Box>
                    <Box display="block" fontSize={12}>
                      {t('ล่าสุด')}
                      {' '}
                      {`: ${latest} `}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <TableContainer style={style.container}>
                    <Table stickyHeader className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" style={{ backgroundColor: '#e0e0e0' }}>{t('ที่ตั้ง')}</TableCell>
                          <TableCell align="center" style={{ backgroundColor: '#e0e0e0' }}>{t('เวลา')}</TableCell>
                          <TableCell align="center" style={{ backgroundColor: '#e0e0e0' }}>{t('มม.')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataRainfall
                          .slice(0, 15).map(data => {
                            let mapLink = data.station.tele_station_name.th;
                            if (data.station.tele_station_lat
                              && data.station.tele_station_long
                            ) {
                              mapLink = (
                                <Button
                                  onClick={this.handleSearchMarker(
                                    data.station.id,
                                    data.station.tele_station_lat,
                                    data.station.tele_station_long,
                                  )}
                                  title={`${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                                  style={{ textAlign: 'left', paddingLeft: 5 }}
                                >
                                  {`${tJson(data.station.tele_station_name, 'สถานี')} ${tJson(data.geocode.amphoe_name, 'อ.')} ${tJson(data.geocode.province_name, 'จ.')}`}
                                </Button>
                              );
                            }
                            const rain24h = parseFloat(data.rain_24h).toFixed(1);
                            const rainfallStatusC = this.rainfallStatus(rain24h, 'rainfall24h').color.statusColor;
                            const rainfallStatusFontColor = this.rainfallStatus(rain24h, 'rainfall24h').color.constrastColor;

                            return (
                              <TableRow key={data.id}>
                                <TableCell component="th" scope="row" style={{ whiteSpace: 'normal', paddingLeft: 6 }}>
                                  {mapLink}
                                </TableCell>
                                <TableCell align="center">
                                  {data.rainfall_datetime
                                    .substring(data.rainfall_datetime.length - 5)}
                                  {' '}
                                  {t('น.')}
                                </TableCell>
                                <TableCell align="center">
                                  <Box display="flex" alignItems="center" justifyContent="center">
                                    <Box
                                      borderRadius={3}
                                      style={{
                                        backgroundColor: rainfallStatusC,
                                        color: rainfallStatusFontColor,
                                        width: '45px',
                                      }}
                                    >
                                      {parseFloat(data.rain_24h).toFixed(1)}
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Link href="https://forms.gle/EpveRopomxPsFtXR9" title="แบบสำรวจความพึงพอใจ Thaiwater.net" target="_blank">
                      <img src={`${initPath}images/theme/banner/poll-banner.png`} alt="แบบสำรวจความพึงพอใจ Thaiwater.net" className={classes.responsive} />
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Modal
                open={modalData.open}
                modalHeader={modalData.header}
                modalContent={modalData.content}
                resetModal={this.resetModal}
              />
            </Grid>
          </RainfallContext.Provider>
        </div>
      </div>
    );
  }
}

Rainfall.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(Rainfall);
