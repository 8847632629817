import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  uniqueId,
} from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Map, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import { Hidden } from '@material-ui/core';

import {
  styles,
} from '../../styles/Style';

// icon
import { HiiFrameIcon, HiiMonitorIcon } from '../../utils/Icon';

const initPath = process.env.MIX_APP_URL;

class StatisticsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      geojsonProvince: null,
      datas: [],
      isType: 'nhc_statistic',
    };
  }

  componentDidMount() {
    this.getGeoJson();

    this.getData();
  }

  getGeoJson = () => {
    fetch(`${initPath}json/boundary/thailand.json`)
      .then(res => res.json())
      .then(data => {
        this.setState({
          geojsonProvince: data,
        });
        // console.log(data);
      })
      .catch(error => error);
  };

  getData() {
    fetch(`${initPath}json/contents/report_statistics.json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          datas: result,
        });
      })
      .catch(error => error);
  }

      handleChange = (event, value) => {
        this.setState({
          value,
        });
      };

      highlightFeature = e => {
        const layer = e.target;
        layer.setStyle({
          weight: 1,
          opacity: 1,
          fillColor: '#1976D2',
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
      }

        resetHighlight = e => {
          const layer = e.target;
          layer.setStyle({
            weight: 2,
            opacity: 1,
            color: '#FFFFFF',
            fillColor: '#9E9E9E',
          });
        }

      onEachFeatureProvince = (feature, layer) => {
        layer.on({
          mouseover: this.highlightFeature,
          mouseout: this.resetHighlight,
        });

        const popupContent = `
        <div style="text-align: center; font-family: Kanit; font-weight: 300">
          <div style="font-size: 13px; "> ศูนย์ข้อมูลน้ำระดับจังหวัด </div>
          <div style="font-size: 18px;">จ. ${layer.feature.properties.title}</div>
          <button 
          style="margin-top: 10px; width: 140px; height: 50px; color: #000; font-size: 13px;cursor: pointer;
          background-color: #E6F0F7; border:0; border-radius: 6px; font-family: Kanit;" 
          onclick="window.open('https://${layer.feature.properties.title_eng}.thaiwater.net/', '_blank')" title="เยี่ยมชมเว็บไซต์"> 
          <img src="${initPath}images/theme/icon/netglo.png" alt="เยี่ยมชมเว็บไซต์" style="vertical-align: middle;" />   เยี่ยมชมเว็บไซต์ </button>
        </div>
      `;

        layer.bindPopup(popupContent);

        layer.bindTooltip(layer.feature.properties.title, {
          permanent: true, direction: 'center',
        });
      }

      render() {
        const { classes, t } = this.props;
        const {
          value, geojsonProvince, isType, datas,
        } = this.state;

        // รายงานสถิติ การใช้งานเว็บไซต์ ThaiWater.net
        const getIframeStatWebHeight = () => {
          const { width } = this.props; // Extract width from props
          if (width === 'xs') {
            return '600px'; // Height for widths less than or equal to 480
          } if (width === 'sm' || width === 'md') {
            return '1300px'; // Height for widths less than or equal to 980
          }
          return '2200px'; // Height for widths greater than 980
        };

        // สถิติรวมรายปี
        const getIframeStatYearHeight = () => {
          const { width } = this.props;
          if (width === 'xs') {
            return '300px'; // Height for widths less than or equal to 480
          } if (width === 'sm' || width === 'md') {
            return '400px'; // Height for widths less than or equal to 980
          }
          return '500px'; // Height for widths greater than 980
        };

        // รายงานรวมรายปี
        const getIframeYearHeight = () => {
          const { width } = this.props;
          if (width === 'xs') {
            return '500px'; // Height for widths less than or equal to 480
          } if (width === 'sm' || width === 'md') {
            return '1100px'; // Height for widths less than or equal to 980
          }
          return '1900px'; // Height for widths greater than 980
        };

        return (
          <>
            <div className={classes.layout}>
              <Grid container alignItems="center" spacing={4} style={{ marginTop: 10 }}>
                <Grid item>
                  <Box display="flex" mb={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Box display="flex" alignItems="center">
                        <HiiMonitorIcon style={{ fontSize: '1em', marginRight: 5 }} />
                        <Link to={`${initPath}statistics-report`}>
                          <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                            {' '}
                            {t('สถิติข้อมูล')}
                          </Typography>
                        </Link>
                      </Box>
                    </Breadcrumbs>
                  </Box>
                </Grid>
              </Grid>
              <Divider />

              <Grid container spacing={4}>
                <Grid item xs>
                  <Box display="flex" mt={4} style={{ width: '100%' }}>
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                    >
                      <Tab style={{ marginRight: '5px', borderBottom: 0 }} label={t('รายงานการให้บริการข้อมูล')} className={`${value === 0 ? `${classes.boxTopRadiusPrimary} ${classes.active_tab}` : classes.boxTopRadiusOutline}`} />
                      <Tab style={{ marginRight: '5px', borderBottom: 0 }} label={t('สถิติการใช้เว็บไซต์ ThaiWater.net')} className={`${value === 1 ? `${classes.boxTopRadiusPrimary} ${classes.active_tab}` : classes.boxTopRadiusOutline}`} />
                      <Tab style={{ marginRight: '5px', borderBottom: 0 }} label={t('สถิติการใช้งานศูนย์ข้อมูลน้ำระดับจังหวัด')} className={`${value === 2 ? `${classes.boxTopRadiusPrimary} ${classes.active_tab}` : classes.boxTopRadiusOutline}`} />
                    </Tabs>
                  </Box>
                  <Divider />
                </Grid>
              </Grid>

              {value === 0 && (
              <Grid container spacing={4}>
                <Grid item xs>
                  <Box
                    display="flex"
                    p={2}
                    mb={0}
                    style={{
                      width: '100%',
                      backgroundColor: '#E1E1E2',
                      height: '80px',
                      justifyContent: { xs: 'center', md: 'flex-center' },
                      alignItems: 'center',
                    }}
                  >

                    <Fragment key={uniqueId()}>

                      <Button
                        variant="outlined"
                        onClick={() => { this.setState({ isType: 'nhc_statistic' }); }}
                        className={isType === 'nhc_statistic' ? classes.activeButton : ''}
                        style={{
                          backgroundColor: isType !== 'nhc_statistic' ? 'white' : '',
                        }}
                      >
                        <Typography style={{ fontSize: '14px' }}>{t('รายงานคลังข้อมูลน้ำแห่งชาติ')}</Typography>
                      </Button>

                      <Button
                        variant="outlined"
                        onClick={() => { this.setState({ isType: 'province_statistics' }); }}
                        className={isType === 'province_statistics' ? classes.activeButton : ''}
                        style={{
                          marginLeft: 10,
                          backgroundColor: isType !== 'province_statistics' ? 'white' : '',
                        }}
                      >
                        <Typography style={{ fontSize: '14px' }}>{t('รายงานระดับจังหวัด')}</Typography>
                      </Button>

                      <Divider />
                    </Fragment>

                  </Box>
                </Grid>

                <Grid container>
                  <Grid item xs>
                    <Box width="100%" p={1}>
                      {datas
                        .filter(row => row.type === isType && row.link !== '#')
                        .map(row => (
                          <Fragment key={uniqueId()}>
                            <Button href={`${initPath}${row.link}`}>
                              <HiiFrameIcon style={{ fontSize: '3em', marginRight: 5 }} />
                              <Typography variant="h6">{t(row.title.trim())}</Typography>
                            </Button>
                            <Box p={1}>
                              <Divider />
                            </Box>
                          </Fragment>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              )}

              {value === 1 && (
              <Grid container spacing={4}>
                <Grid item xs>
                  <Box style={{ width: '100%' }}>
                    <Box display="block" fontWeight={600} fontSize={26}>
                      {t('รายงานสถิติ')}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box display="block" mr={3} width="auto">
                        <Typography style={{ fontSize: 18 }} noWrap>
                          {t('การใช้งานเว็บไซต์ ThaiWater.net')}
                        </Typography>
                      </Box>
                      <Hidden xsDown><Box style={{ borderBottom: '5px solid #9E9E9E', width: '100%' }} /></Hidden>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs>
                  <Box display="flex">
                    <iframe
                      title="รายงานสถิติเว็บไซต์ ThaiWater.net"
                      width="1200px"
                      height={getIframeStatWebHeight()}
                      src="https://lookerstudio.google.com/embed/reporting/ad493041-f4b2-4a4e-b113-d6a21ec25ef1/page/EiSAC"
                      style={{ border: 0 }}
                    // allowfullscreen
                    />
                  </Box>
                </Grid>
              </Grid>
              )}

              {value === 2 && (
              <Grid container spacing={4}>

                <Grid item xs>
                  <Box display="flex" width="100%">
                    <Map
                      center={[15, 101.5]}
                      zoom={7}
                      style={{ width: '1392px', height: '696px' }}
                      scrollWheelZoom={false}
                      fullscreenControl
                    >
                      {geojsonProvince && (
                      <GeoJSON
                        data={geojsonProvince}
                        style={{
                          stroke: true,
                          color: '#F5F5F5',
                          weight: 2,
                          opacity: 0.8,
                          fillOpacity: 1,
                          fillColor: '#9E9E9E',
                        }}
                        onEachFeature={this.onEachFeatureProvince}
                      />
                      )}
                    </Map>
                  </Box>
                </Grid>

                <Grid item xs>
                  <Box style={{ width: '100%' }}>
                    <Box display="block" fontWeight="fontWeightBold">
                      <Box display="block" fontWeight={600} fontSize={26}>
                        {t('สถิติรวมรายปี')}
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box display="block" mr={3} width="auto">
                        <Typography style={{ fontSize: 18 }} noWrap>
                          {t('การใช้งานเว็บไซต์ศูนย์น้ำระดับจังหวัด')}
                        </Typography>
                      </Box>
                      <Hidden xsDown><Box style={{ borderBottom: '5px solid #9E9E9E', width: '100%' }} /></Hidden>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs>
                  <Box display="flex">
                    <iframe
                      title="สถิติรวมรายปี การใช้งานเว็บไซต์ศูนย์น้ำระดับจังหวัด"
                      width="1200px"
                      height={getIframeStatYearHeight()}
                      src="https://lookerstudio.google.com/embed/reporting/07042d14-317c-40bd-b7a0-5d1575ae9edb/page/RU4sD"
                      style={{ border: 0 }}
                    />
                  </Box>
                </Grid>

                <Grid item xs>
                  <Box style={{ width: '100%' }}>
                    <Box display="block" fontWeight="fontWeightBold">
                      <Box display="block" fontWeight={600} fontSize={26}>
                        {t('รายงานรวมรายปี')}
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box display="block" mr={3} width="auto">
                        <Typography style={{ fontSize: 18 }} noWrap>
                          {t('การใช้งานเว็บไซต์ศูนย์น้ำระดับจังหวัด')}
                        </Typography>
                      </Box>
                      <Hidden xsDown><Box style={{ borderBottom: '5px solid #9E9E9E', width: '100%' }} /></Hidden>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box display="flex">
                    <iframe
                      title="การใช้งานเว็บไซต์จังหวัด"
                      width="1200px"
                      height={getIframeYearHeight()}
                      src="https://lookerstudio.google.com/embed/reporting/c69ea634-45d2-48bd-8f73-5b73616be94c/page/J4YI"
                      style={{ border: 0 }}
                    />
                  </Box>
                </Grid>

              </Grid>
              )}
            </div>
          </>
        );
      }
}

StatisticsReport.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(StatisticsReport);
