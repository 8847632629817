import React from 'react';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';

import DamStatusColor from './DamStatusColor';

/**
 * DamLegend
 */
function DamLegend(props) {
  // media queries
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  let size = 5;
  // 2020-06-11 ตัด n/a ออกเนื่องจากสเกลรูปแบบที่ตี๋ให้แก้ไขเหมือนข้อมูลฝน ไม่มี n/a
  // const range = [101, 81, 51, 31, 10, 'n/a'];
  const range = [101, 81, 51, 31, 10];
  const arrays = [];

  const { t } = props;

  // show legend 3 columns for small devices
  if (!matches) {
    size = 3;
  }

  while (range.length > 0) { arrays.push(range.splice(0, size)); }
  const divStyle = {
    borderLeft: '1px solid #ccc',
  };
  const divStyleNon = {
    fontSize: 11,
  };

  return (
    <>
      <Grid container spacing={0} style={{ border: '1px solid #ccc', fontSize: 11 }}>
        {arrays.reverse().map(r => (
          <Grid container direction="row" spacing={0} key={Math.floor(Math.random() * 1000000)}>
            {r.reverse().map((d, i) => {
              const status = DamStatusColor(d);
              const styleConditon = (i > 0) ? divStyle : divStyleNon;

              return (
                <Grid item xs key={d} style={styleConditon}>
                  <Box display="flex">
                    <Box
                      flexGrow={1}
                      display="block"
                      align="center"
                      style={{
                        background: status.color.statusColor,
                        color: status.color.constrastColor,
                        textAlign: 'center',
                      }}
                    >
                      {status.color.rangeTitle}
                    </Box>
                  </Box>
                  <Box flexGrow={1} display="block" align="center">
                    {t(status.color.label)}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography variant="caption" gutterBottom>
            {`% ${t('ปริมาณน้ำเก็บกัก')}`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

DamLegend.propTypes = {
  t: PropTypes.any.isRequired,
};
export default withTranslation()(DamLegend);
