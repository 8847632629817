/**
 * *
 * @version 1.0.0
 * @author [phithakkhet@hii.or.th]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
    marginBottom: 4,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  legendRain: {
    height: '20px',
    width: '40px',
    marginRight: 10,
    marginBottom: 4,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  legendRadar: {
    width: 50,
    height: '20px',
    marginBottom: 4,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  label: {
    color: '#eee',
    fontSize: '0.9em',
    fontWeight: 400,
    marginRight: 30,
    fontFamily: 'sans-serif',
    whiteSpace: 'no-wrap',
    [theme.breakpoints.down('xs')]: {
      marginRight: 23,
    },
  },
  labelRadar: {
    color: '#eee',
    fontSize: '0.9em',
    fontWeight: 400,
    textAlign: 'center',
    fontFamily: 'sans-serif',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.55em',
      padding: '0px 2px',
    },
  },
  title: {
    paddingTop: 5,
    color: 'white',
    fontSize: '1em',
    fontWeight: 400,
    fontFamily: 'sans-serif',
    marginBottom: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    borderBottom: '1px solid #0f3045',
  },
}));

const AllLegend = () => {
  const classes = useStyles();

  return (
    <Box display="block" px={{ xs: 0, sm: 3 }}>
      <Box>
        <Box className={classes.title}>เกณฑ์ระดับน้ำ</Box>
        <Box display="flex" flexWrap="wrap">
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#69b9a0',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>ปกติ</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#ffc107',
              }}
              className={classes.legend}
            />
            <Box Box className={classes.label}>เตือนภัย</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#e91e63',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>วิกฤต</Box>
          </Box>
        </Box>
      </Box>
      <Divider className={classes.row} />
      <Box>
        <Box className={classes.title}>เกณฑ์น้ำท่วม</Box>
        <Box display="flex" flexWrap="wrap">
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#69b9a0',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>ปกติ</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#ffc107',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>น้ำท่วมขังเล็กน้อย</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#e91e63',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>น้ำท่วม</Box>
          </Box>
        </Box>
      </Box>
      <Divider className={classes.row} />
      <Box className={classes.title}>เกณฑ์ปริมาณน้ำฝน</Box>
      <Box display={{ xs: 'flex', sm: 'flex' }} flexWrap="wrap">
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#69b9a0',
            }}
            className={classes.legendRain}
          />
          <Box className={classes.label}>ฝนละออง</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#ffee58',
            }}
            className={classes.legendRain}
          />
          <Box className={classes.label}>ฝนเล็กน้อย</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#ffc107',
            }}
            className={classes.legendRain}
          />
          <Box className={classes.label}>ฝนปานกลาง</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#e91e63',
            }}
            className={classes.legendRain}
          />
          <Box className={classes.label}>ฝนหนัก</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#d32f2f',
            }}
            className={classes.legendRain}
          />
          <Box className={classes.label}>ฝนหนักมาก</Box>
        </Box>
      </Box>
      <Divider className={classes.row} />
      <Box className={classes.title}>เรดาร์ (mm/hr)</Box>
      <Box>
        <Grid container direction="row" justifyContent="flex-start" spacing={0} wrap="wrap">
          <Grid item key="key">
            <Box className={classes.labelRadar}> >120</Box>
            <Box
              style={{
                backgroundColor: 'rgb(156, 50, 212)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}> 100-120</Box>
            <Box
              style={{
                backgroundColor: 'rgb(133, 27, 27)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}> 85-100</Box>
            <Box
              style={{
                backgroundColor: 'rgb(205, 37, 34)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}> 70-85</Box>
            <Box
              style={{
                backgroundColor: 'rgb(255, 46, 7)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}> 55-70</Box>
            <Box
              style={{
                backgroundColor: 'rgb(202, 102, 4)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}> 40-55</Box>
            <Box
              style={{
                backgroundColor: 'rgb(253, 140, 2)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}>30-40</Box>
            <Box
              style={{
                backgroundColor: 'rgb(250, 213, 0)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}> 20-30</Box>
            <Box
              style={{
                backgroundColor: 'rgb(104, 202, 1)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}> 10-20</Box>
            <Box
              style={{
                backgroundColor: 'rgb(151, 229, 169)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}>5-10</Box>
            <Box
              style={{
                backgroundColor: 'rgb(173, 211, 255)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
          <Grid item key="key">
            <Box className={classes.labelRadar}>1-5</Box>
            <Box
              style={{
                backgroundColor: 'rgb(203, 227, 255)',
              }}
              className={classes.legendRadar}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AllLegend;
