import React, { Component } from 'react';
import 'leaflet/dist/leaflet.css';
import Control from 'react-leaflet-control';
import { Button, Tooltip } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { Marker, Tooltip as Tooltipmarker } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const initPath = process.env.MIX_APP_PATH;

class CurrentLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      position: null,
      error: null,
      showLocation: false,
      watchId: null,
    };
  }

  componentDidMount() {
    const errorHandler = error => {
      this.setState({ error: error.message });
    };

    const watchId = navigator.geolocation.watchPosition(this.successHandler, errorHandler);
    this.setState({ watchId });
  }

  componentWillUnmount() {
    const { watchId } = this.state;
    if (watchId) {
      navigator.geolocation.clearWatch(watchId);
    }
  }

  clickToPan = () => {
    const { mapRef, showOnly } = this.props;
    const { showLocation, position, watchId } = this.state;
    if (position) {
      if (!showLocation) {
        this.setState({ showLocation: true });
        if (mapRef.current && position) {
          if (!showOnly) {
            mapRef.current.leafletElement.setView(position, 13);
          }
        }
      } else {
        this.setState({ showLocation: false });
        navigator.geolocation.clearWatch(watchId);
      }
    } else {
      navigator.geolocation.watchPosition(this.successHandler, this.showError);
    }
  };

  successHandler = position => {
    const { latitude, longitude } = position.coords;
    this.setState({ position: [latitude, longitude] });
  };

  showError =error => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('permission denied : สิทธิ์การเข้าถึงตำแหน่งของบราวเซอร์ไม่ถูกเปิดใช้งาน');
        break;
      case error.POSITION_UNAVAILABLE:
        console.error('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        console.error('The request to get user location timed out.');
        break;
      default:
        console.error('An unknown error occurred.');
        break;
    }
  };

  render() {
    const { t, themeDL } = this.props;
    const { position, error, showLocation } = this.state;

    const customIcon = new L.Icon({
      iconUrl: `${initPath}images/pin-red.gif`,
      iconSize: [60, 60],
      iconAnchor: [30, 58],
      className: 'marker-icon',
    });

    return (
      <div>
        <Control position={themeDL ? 'topright' : 'topleft'}>
          <Tooltip title={t('ตำแหน่งปัจจุบัน')}>
            <Button
              onClick={this.clickToPan}
              style={themeDL ? {
                top: '-5px', height: '40px', width: '40px', backgroundColor: 'rgba(6, 30, 44, 0.7)', borderRadius: '50%',
              }
                : {
                  top: '5px', bottom: '5px', left: '2px', height: '30px', width: '30px', backgroundColor: 'white',
                }}
            >
              {showLocation
                ? <GpsFixedIcon style={themeDL ? { color: '#79b5f1' } : { color: '#1976d2' }} />
                : <LocationSearchingIcon style={themeDL ? { color: 'white' } : { color: '#151a1c' }} />}
            </Button>
          </Tooltip>
        </Control>
        {showLocation && position && (
        <Marker position={position} icon={customIcon}>
          <Tooltipmarker direction="top" permanent offset={[0, -35]}>
            {t('ตำแหน่งปัจจุบัน')}
          </Tooltipmarker>
        </Marker>
        )}
        {error && (
          <p>
            Error:
            {error}
          </p>
        )}
      </div>
    );
  }
}

CurrentLocation.propTypes = {
  t: PropTypes.func.isRequired,
  themeDL: PropTypes.bool.isRequired,
  mapRef: PropTypes.object.isRequired,
  showOnly: PropTypes.bool.isRequired,
};

export default (withTranslation())(CurrentLocation);
