import React, {
  useState, useEffect,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import moment from 'moment';
import PropTypes from 'prop-types';

// material ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import TraffyIcon from './icon/traffy';

const useStyles = makeStyles(theme => ({
  root: {
    width: '65%',
    paddingLeft: '20px',
    paddingRight: '20px',
    zIndex: 99999,
    backgroundColor: 'white',
    borderRadius: '5px',
    position: 'fixed',
    left: '20%',
    bottom: '1%',
  },
  hiddenLine: {
    borderBottom: 0,
    fontSize: 'small',
    whiteSpace: 'normal',
  },
  content: {
    fontFamily: 'Kanit',
    [theme.breakpoints.down('xs')]: {
      width: 240,
    },
  },
  address: {
    fontSize: '0.8em',
    [theme.breakpoints.down('xs')]: {
      width: 240,
    },
  },
  imgBmaContent: {
    height: '200px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: 217,
      height: 160,
    },
  },
  logo: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const initPath = process.env.MIX_APP_PATH;

export default function CompTraffyFlood(props) {
  // https://publicapi.traffy.in.th/share/teamchadchart/search?text=%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%A0%E0%B8%97:%E0%B8%99%E0%B9%89%E0%B8%B3%E0%B8%97%E0%B9%88%E0%B8%A7%E0%B8%A1&start=2022-06-27&end=2022-06-27
  const classes = useStyles();
  const CurrentDate = moment().format('Y-M-DD');

  // const api = `https://publicapi.traffy.in.th/share/teamchadchart/search?text=ประเภท:น้ำท่วม&start=${CurrentDate}&end=${CurrentDate}`;

  // 2022-07-21 ปรับการแสดงผลให้ includes comment คำว่า น้ำท่วม และปรับช่วยเวลาแสดง 3 ชั่วโมงล่าสุด
  const api = `https://publicapi.traffy.in.th/share/teamchadchart/search?start=${CurrentDate}&end=${CurrentDate}&problem_type_abdul=น้ำท่วม`;
  const [dataTraffyFlood, setDataTraffyFlood] = useState([]);
  const [error, setError] = useState(null);
  const { bindMarker } = props;

  function fetchData() {
    fetch(api)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // const CurrentDateTime = moment().format('Y-M-d HH');
        // const datas = result.results.filter(d => (moment(d.timestamp).format('Y-m-d HH') === CurrentDateTime));

        // 2022-07-21 ปรับการแสดงผลให้ includes comment คำว่า น้ำท่วม และปรับช่วยเวลาแสดง 3 ชั่วโมงล่าสุด
        const datas = result.results.filter(d => d.comment.includes('น้ำท่วม') && (moment(d.timestamp).format('YYYY-MM-DD HH') >= moment().subtract(3, 'hours').format('YYYY-MM-DD HH')));

        const dataflood = result;
        dataflood.data = datas;
        setDataTraffyFlood(datas);
      })
      .catch(e => { setError(e); });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function eachDataFloodMarker(value, index) {
    const position = [value.coords[1], value.coords[0]];

    if (position) {
      return (
        <Marker
          id={value.id}
          key={index}
          position={position}
          icon={TraffyIcon}
          ref={bindMarker(value.id)}
        >
          <Popup minWidth={280} maxWidth={600} minHeight={150} maxHeight={600} autoPan={false}>

            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLine}
                      padding="none"
                      align="left"
                    >
                      <Box p={1} className={classes.content}>
                        <div>
                          {value.comment}
                        </div>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      {(value.photo !== null)
                        ? (
                          <Link href={value.photo} target="_blank">
                            <img src={value.photo} alt={value.id} className={classes.imgBmaContent} />
                          </Link>
                        )
                        : (
                          null
                        )}

                      <Box className={classes.address}>
                        <div>
                          {
                            (value.province === 'กรุงเทพมหานคร')
                              ? `แขวง${value.subdistrict} เขต${value.district} จังหวัด${value.province}`
                              : `ตำบล${value.subdistrict} อำเภอ${value.district} จังหวัด${value.province}`
                          }
                        </div>
                        <div>
                          {`ล่าสุด : ${moment(value.timestamp).format('YYYY-MM-DD HH:mm')}`}
                        </div>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Box display="flex" justifyItems="center">
                        <img src={`${initPath}images/marker/bma/Traffy/fondue.png`} className={classes.logo} />
                        <div>
                          {'ที่มา : '}
                          <a href="https://share.traffy.in.th/teamchadchart?fbclid=IwAR1m9Qy8R-vHi1MDjBEsuTaP6_-pkTlq7yRwp8ybFckifTd80_NgTjDft1Q" alt="ที่มา" target="_blank" rel="noreferrer">traffy fondue</a>
                        </div>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>

          <Hidden smDown>
            <Tooltip title={`ที่ : ${value.address}`}>
              <span>
                <div>
                  {' '}
                  <strong>ที่ : </strong>
                  {` ${value.address}`}
                </div>
                <div>
                  <strong>เวลา : </strong>
                  {`${moment(value.timestamp).format('YYYY-MM-DD HH:mm')}`}
                </div>
                <div>
                  <strong>สถานะ : </strong>
                  {' '}
                  {`${value.state}`}
                </div>
                <div>
                  ที่มา : traffy fondue
                </div>
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  return (

    <div>
      {error && <Grid key="error-text" className="text-center">{error.message}</Grid>}
      {(dataTraffyFlood) ? dataTraffyFlood.map(eachDataFloodMarker) : null}

    </div>
  );
}

CompTraffyFlood.propTypes = {
  bindMarker: PropTypes.func.isRequired,
};
