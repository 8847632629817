import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

import { withTranslation } from 'react-i18next';

// lodash
import {
  flow, uniqueId, groupBy, orderBy,
} from 'lodash/fp';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

// material-ui
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import grey from '@material-ui/core/colors/grey';

// icon
import {
  FaHome, FaSpinner,
} from '../../../utils/Fontawesome';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
const sstTitle = 'แผนภาพอุณหภูมิผิวน้ำทะเล';
const waveTitle = 'แผนภาพความสูงและทิศทางของคลื่นทะเล';
const sstConditon = [
  { key: 143, value: 'ประเทศไทย' },
  { key: 144, value: 'มหาสมุทรแปซิฟิคเหนือ' },
  { key: 146, value: 'มหาสมุทรอินเดีย' },
  { key: 145, value: 'ทั่วโลก' },
];
const waveCondition = [
  { key: 24, value: 'ประเทศไทย' },
  { key: 156, value: 'มหาสมุทรแปซิฟิคเหนือ' },
  { key: 23, value: 'มหาสมุทรอินเดีย' },
  { key: 155, value: 'ทั่วโลก' },
];

const sstLatest = `${process.env.MIX_API_URL}public/weather_img/sst_ocean_weather`;
const waveLatest = `${process.env.MIX_API_URL}public/weather_img/wave_height_ocean_weather`;

const mediaType = [];
mediaType[143] = {
  index: 0, title: sstTitle, thumbSize: { w: 232, h: 172 }, option: sstConditon, url: sstLatest,
};
mediaType[144] = {
  index: 1, title: sstTitle, thumbSize: { w: 232, h: 172 }, option: sstConditon, url: sstLatest,
};
mediaType[145] = {
  index: 3, title: sstTitle, thumbSize: { w: 232, h: 172 }, option: sstConditon, url: sstLatest,
};
mediaType[146] = {
  index: 2, title: sstTitle, thumbSize: { w: 232, h: 172 }, option: sstConditon, url: sstLatest,
};

mediaType[24] = {
  index: 0, title: waveTitle, thumbSize: { w: 232, h: 171 }, option: waveCondition, url: waveLatest,
};
mediaType[156] = {
  index: 1, title: waveTitle, thumbSize: { w: 232, h: 171 }, option: waveCondition, url: waveLatest,
};
mediaType[23] = {
  index: 2, title: waveTitle, thumbSize: { w: 232, h: 171 }, option: waveCondition, url: waveLatest,
};
mediaType[155] = {
  index: 3, title: waveTitle, thumbSize: { w: 232, h: 171 }, option: waveCondition, url: waveLatest,
};

const hourList = [
  { time: '06:00' },
  // { time: '18:00' },
];

const currentDate = new Date(moment().subtract(1, 'day'));
class SstHistory extends Component {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      dataList: [],
      groupDatas: [],
      latestImage: [],
      displayState: 'history',

      photoIndex: 0,
      isOpen: false,
      isOpenLatestImage: false,

      mediaTypeId: 143,
    };
  }

  componentDidMount() {
    const { match: { params: { mediaTypeId } } } = this.props;
    const startDate = new Date(moment().subtract(1, 'day'));

    this.setState({
      isLoading: true,
      mediaTypeId,
      startDate,
      valueDropDown: mediaTypeId,
    });
    this.fetchData(mediaTypeId, startDate);
  }

  fetchData = (mediaTypeId, date) => {
    Promise.all([
      fetch(`${process.env.MIX_API_URL}public/weather_history_img/month?agency_id=56&media_type_id=${mediaTypeId}&year=${moment(date).format('YYYY')}&month=${moment(date).format('MM')}`),
      fetch(mediaType[mediaTypeId].url),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([res1, res2]) => {
        // group data by date
        const groupData = flow(
          orderBy(['media_datetime'], ['desc']),
          groupBy(d => moment(d.media_datetime).format('YYYY-MM-DD')),
        )(res1.data);
        const dataArray = Object.entries(groupData)
          .map(([key, val]) => ({ date: key, datas: val }));

        this.setState({
          isLoading: false,
          dataList: res1.data,
          groupDatas: dataArray,
          latestImage: res2.data,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = date => {
    this.setState({
      isLoading: true,
      startDate: date,
    });

    const { mediaTypeId } = this.state;
    this.fetchData(mediaTypeId, date);
  };

  handleDateChangeButton = change => {
    const { startDate } = this.state;
    let selectedDate = startDate;

    if (change === '1') {
      selectedDate = moment(startDate).add(1, 'months');
    } else if (change === '-1') {
      selectedDate = moment(startDate).add(-1, 'months');
    }
    this.setState({
      isLoading: true,
      startDate: selectedDate,
    });
    const { mediaTypeId } = this.state;
    this.fetchData(mediaTypeId, selectedDate);
  }

  handleDropDownChange = event => {
    const { history } = this.props;
    const { value } = event.target;
    const { startDate } = this.state;
    this.setState({
      isLoading: true,
      valueDropDown: value,
      mediaTypeId: value,

    });
    this.fetchData(value, startDate);

    const url = `${process.env.MIX_APP_PATH}weather/sea/sst/history/${value}`;
    history.push(url);
  }

  // render วันละ 1 ภาพ
  renderHistoryWave(startDate, classes, datas, images, width, height, thumbWidth, thumbHeight) {
    const { t } = this.props;
    let currentIndex = 0;
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
          <Grid item><Button onClick={() => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={4} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  openTo="month"
                  views={['year', 'month']}
                  value={startDate}
                  onChange={this.handleChange}
                  style={{ fontSize: 14 }}
                  maxDate={currentDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item><Button onClick={() => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>
        {datas.length <= 0
          && (
            <Grid container spacing={4} justify="center">
              <Grid item xs>
                <Typography variant="h6">
                  {t('ไม่พบข้อมูล')}
                </Typography>
              </Grid>
            </Grid>
          )}
        <Grid container spacing={4}>
          {datas.map(row => {
            let realImage = '';
            const currentImgIndex = currentIndex;

            const showImg = (row.media_path_thumb !== null)
              ? row.thumbnail_media_path : row.media_path;

            const timeShow = moment(row.media_datetime).format('LL');
            if (row.media_path !== '') {
              images.push({
                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${row.media_path}`,
                title: timeShow,
              });

              realImage = (
                <Button
                  key={uniqueId()}
                  onClick={() => this.setState({
                    isOpen: true,
                    photoIndex: currentImgIndex,
                  })}
                >
                  <Img
                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`}
                    width={width}
                    height={height}
                    alt={timeShow}
                    className={classes.responsive}
                    key={uniqueId()}
                    unloader={(
                      <Img
                        src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                        alt="No Image"
                        className={classes.responsive}
                      />
                    )}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                  alt={timeShow}
                  className={classes.responsive}
                />
              );
            }
            currentIndex += 1;

            return (
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.boxWeatherSstHistory}
                >
                  <Box display="block" align="center">
                    {realImage}
                  </Box>
                  <Box p={1} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      {timeShow}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }

  // render ภาพรายวัน เรียงตามเวลา
  renderHistory(startDate, classes, dataList, groupDatas, images) {
    const { t } = this.props;
    let currentIndex = 0;
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
          <Grid item><Button onClick={() => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={3} md={3} lg={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                openTo="month"
                views={['year', 'month']}
                value={startDate}
                onChange={this.handleChange}
                style={{ fontSize: 14 }}
                maxDate={currentDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item><Button onClick={() => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>

        {dataList.length <= 0
            && (
              <Grid container spacing={4}>
                <Grid item xs>
                  <Typography variant="h6">
                    {t('ไม่พบข้อมูล')}
                  </Typography>
                </Grid>
              </Grid>
            )}

        {groupDatas.map(item => (
          <Grid container spacing={4} key={uniqueId()}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box p={1} mt={2} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                {moment(item.date).format('LL')}
              </Box>
            </Grid>
            {hourList.map(meta => {
              const total = item
                .datas
                // .filter(row => moment(row.media_datetime).format('HH:mm') === meta.time)
                .length;
              if (total === 0) {
                return (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                    <Box display="flex" alignItems="center" justifyContent="center" className={classes.boxWeatherHistory}>
                      <Box display="block">
                        <Box display="block" align="center">
                          <Img
                            src="https://via.placeholder.com/232x171.png?text=No+Image"
                            width="232"
                            height="171"
                            className={classes.responsive}
                            alt="No Image"
                          />
                        </Box>
                        <Box p={1} display="flex" alignItems="center" justifyContent="center">
                          <Typography className={classes.dateTime}>
                            {t('เวลา')}
                            {'  : '}
                            {' '}
                            {meta.time}
                            {' '}
                            {t('น.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              }

              return (
                <Fragment key={uniqueId()}>
                  {item
                    .datas
                    // .filter(row => moment(row.media_datetime).format('HH:mm') === meta.time)
                    .map(row => {
                      let realImage = '';
                      const currentImgIndex = currentIndex;
                      if (row.media_path_thumb !== null) {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                          title: moment(row.media_datetime).format('LLL'),
                        });
                        realImage = (
                          <Button
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                              width="301"
                              height="250"
                              className={classes.responsive}
                              alt={moment(row.media_datetime).format('LLL')}
                            />
                          </Button>
                        );
                        currentIndex += 1;
                      } else {
                        realImage = (
                          <Img
                            src="https://via.placeholder.com/300x250.png?text=No+Image"
                            width="302"
                            height="250"
                            className={classes.responsive}
                            alt="No Image"
                          />
                        );
                      }

                      return (
                        <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                          <Box display="flex" alignItems="center" justifyContent="center" className={classes.boxWeatherHistory}>
                            <Box display="block">
                              <Box display="block" align="center">
                                {realImage}
                              </Box>
                              <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                <Typography className={classes.dateTime}>
                                  {t('เวลา')}
                                  {'  : '}
                                  {' '}
                                  {/* {meta.time} */}
                                  {moment(row.media_datetime).format('HH:mm')}
                                  {' '}
                                  {t('น.')}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Fragment>
              );
            })}
          </Grid>
        ))}
      </>
    );
  }

  render() {
    const { classes, t } = this.props;
    let latestImg = {};
    const {
      isLoading,
      error,
      photoIndex,
      isOpen,
      startDate,
      displayState,
      mediaTypeId,
      valueDropDown,
      isOpenLatestImage,
      latestImage,
      dataList,
      groupDatas,
    } = this.state;

    // lightbox
    const images = [];
    const { w: thumbWidth, h: thumbHeight } = mediaType[mediaTypeId].thumbSize;
    const width = '100%';
    const height = '100%';

    if (latestImage.length > 0) {
      latestImg = {
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${latestImage[mediaType[mediaTypeId].index].cover_image.media_path}`,
        datetime: moment(latestImage[mediaType[mediaTypeId].index].cover_image.media_datetime).format('LL'),
      };
    }

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {isLoading === true && (
                <FaSpinner size={25} />
              )}

              {error && (
                <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/sea`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('ทะเล')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t(mediaType[mediaTypeId].title)}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 40 }}>
            <Grid item xs={12} md>
              <Box m={1} className={classes.titleName}>
                {t(mediaType[mediaTypeId].title)}
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <NativeSelect
                value={valueDropDown}
                onChange={this.handleDropDownChange}
                // input={<BootstrapInput name="region" id="regend-customized-native-simple" />}
                className={classes.outlinedLeftPrimary}
              >
                { mediaType[mediaTypeId].option.map(row => (
                  <option fontFamily="Kanit" value={row.key} style={{ fontFamily: 'Kanit' }} key={uniqueId()}>{t(row.value)}</option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid item xs={3} md={2}>
              <Tooltip title={t('ดูภาพล่าสุด')}>
                <Button
                  className={classes.outlinedPrimary}
                  color={displayState === 'latest' ? 'primary' : 'default'}
                  variant={displayState === 'latest' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'latest' })}
                >
                  <ImageOutlinedIcon fontSize="small" />
                  <Hidden xsDown>
                    <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }} noWrap>
                      {t('ดูภาพล่าสุด')}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={3} md={2}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <Button
                  className={classes.outlinedRightPrimary}
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'history' })}
                >
                  <PhotoLibraryOutlinedIcon fontSize="small" />
                  <Hidden xsDown>
                    <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }} noWrap>
                      {t('ดูภาพย้อนหลัง')}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          {
            displayState === 'history' && this.renderHistory(startDate, classes, dataList, groupDatas, images, currentDate)
          }

          {displayState === 'latest' && (
          <Grid container direction="row" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
            <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
              <Box display="flex" alignItems="center" justifyContent="center" className={classes.boxWeatherSstHistory}>
                <Box display="block">
                  <Button onClick={() => this.setState({ isOpenLatestImage: true })}>
                    <Box display="block" align="center">
                      <Img
                        src={latestImg.src}
                        className={classes.responsive}
                        alt="image-last-rain"
                        key={uniqueId()}
                        width={width}
                        height={height}
                        unloader={(
                          <Img
                            src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt="No Image"
                            className={classes.responsive}
                          />
                          )}
                      />
                    </Box>
                  </Button>
                  <Box p={1} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      {latestImg.datetime}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          )}

        </div>

        {isOpenLatestImage && (
          <Lightbox
            mainSrc={latestImg.src}
            imageTitle={latestImg.datetime}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpenLatestImage: false })}
          />
        )}

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

SstHistory.defaultProps = {
  match: {
    params: {
      mediaTypeId: 143,
    },
  },
};

SstHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      mediaTypeId: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(SstHistory));
