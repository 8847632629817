import React, {
  useState, useRef,
} from 'react';
import moment from 'moment';
// import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';

// ui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import ListSubheader from '@material-ui/core/ListSubheader';

// components
import DamMainDailyChartHC from './DamMainDailyChartHC';

import { styles } from '../../styles/Style';

const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());

const useStyles = makeStyles(styles);

export default function DamMainDailyChart() {
  const { t } = useTranslation();
  const classes = useStyles();
  const childRef = useRef();
  const years = yearsRange(currentYear, 1993);
  const [selectedType, setSelecetedType] = useState('total_dam_storage');
  const [selectedYear, setSelectedYear] = useState([
    `${currentYear}`, `${currentYear - 1}`, `${currentYear - 2}`,
  ]);

  const handleChange = event => {
    if (event.target.name === 'selectedYear') {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      // if (value.length <= 15) {
      setSelectedYear(value);
      // } else {
      //   alert(t('เลือกปีได้ สูงสุด 15 ปีย้อนหลัง'));
      // }
    } else {
      setSelecetedType(event.target.value);
    }
  };

  const handleChart = () => {
    /*
    *   CALL A METHOD FROM THE CHILD
    */
    childRef.current.resetChart();
    childRef.current.renderChart();
  };

  return (
    <>
      <form autoComplete="off">
        <Grid container spacing={2}>
          {/* แสดงกราฟ */}
          <Grid item xs={12} sm={9} md={10} lg={10} xl={10}>
            <Box py={{ xs: 0, sm: 4 }} className={classes.boxGraphDam}>
              <Box className={classes.graphDam}>
                <DamMainDailyChartHC
                  ref={childRef}
                  type={selectedType}
                  year={selectedYear}
                />
              </Box>
            </Box>
          </Grid>
          {/* Menu control */}
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <InputLabel htmlFor="type">{t('ประเภทข้อมูล')}</InputLabel>
                  <Select
                    value={selectedType}
                    onChange={handleChange}
                    inputProps={{
                      name: 'selectedType',
                      id: 'type',
                    }}
                  >
                    <MenuItem value="total_dam_storage">{t('ปริมาณน้ำเก็บกัก')}</MenuItem>
                    <MenuItem value="total_dam_uses_water">{t('ปริมาณน้ำใช้การ')}</MenuItem>
                    <MenuItem value="total_dam_inflow">{t('ปริมาณน้ำไหลลง')}</MenuItem>
                    <MenuItem value="total_dam_released">{t('ปริมาณน้ำระบาย')}</MenuItem>
                    <MenuItem value="total_dam_inflow_acc">{t('ปริมาณน้ำไหลลงสะสม')}</MenuItem>
                    <MenuItem value="total_dam_inflow_acc">{t('ปริมาณน้ำไหลลงสะสม')}</MenuItem>
                    <MenuItem value="total_dam_released_acc">{t('ปริมาณน้ำระบายสะสม')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <InputLabel htmlFor="year">{t('ปี')}</InputLabel>
                  <Select
                    multiple
                    native
                    value={selectedYear}
                    onChange={handleChange}
                    inputProps={{
                      name: 'selectedYear',
                      id: 'year',
                    }}
                  >
                    {years.map(year => {
                      // <ListSubheader>2003-2008 (ผลรวม 3 อ่างหลัก)</ListSubheader>
                      if (year === '2008') {
                        return (
                          <option key={year} value={year} selected={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                            {year}
                          </option>
                        );
                      }
                      // <ListSubheader>1954-2002 (ผลรวม 2 อ่างหลัก)</ListSubheader>
                      if (year === '2002') {
                        return (
                          <option key={year} value={year} selected={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                            {year}
                          </option>
                        );
                      }
                      // <ListSubheader>2009-ปัจจุบัน (ผลรวม 4 อ่างหลัก)</ListSubheader>
                      if (year === String(currentYear)) {
                        return (
                          <option key={year} value={year} selected={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                            {year}
                          </option>
                        );
                      }
                      return (
                        <option key={year} value={year} selected={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                          {year}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <Button variant="outlined" color="primary" size="small" onClick={handleChart}>
                    {t('แสดง')}
                  </Button>
                </FormControl>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className={classes.info}>
          *
          {' '}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </Box>
        <Box className={classes.info}>
          **
          {' '}
          {t('เนื่องจากทั้ง 4 เขื่อนมีข้อมูลสมบูรณ์ในช่วงเวลาที่แตกต่างกัน สามารถอ่านรายละเอียดเงื่อนไขการแสดงผลเพิ่มเติมได้ที่แถบ “คำอธิบาย”')}
        </Box>
      </Grid>
    </>
  );
}
