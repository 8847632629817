import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  orderBy, uniqBy, filter,
} from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// icon .
import { FaCalendarAlt, FaSpinner } from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class ReportYearly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      yearlyList: [],
      yearSelected: moment().year() + 543,
      yearCurrentLink: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._mounted = true;

    this.getData();
  }

  getData() {
    Promise.all([
      fetch(`${initPath}json/contents/report_yearly.json`),
    ])
      .then(([yearly]) => Promise.all([yearly.json()]))
      .then(([yearlyLists]) => {
        const yearlyList = uniqBy(orderBy(yearlyLists, ['year'], ['desc']), 'year');
        const yearSelected = yearlyList.slice(0, 1).map(data => data.year);
        const yearCurrentLink = yearlyList.slice(0, 1).map(data => data.link);
        // set state
        this.setState({
          isLoading: false,
          yearlyList,
          yearSelected,
          yearCurrentLink,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = event => {
    const { yearlyList } = this.state;
    const yearlyListFilter = filter(yearlyList,
      val => val.year === parseInt(event.target.value, 0));
    const yearCurrentLink = yearlyListFilter.slice(0, 1).map(data => data.link);

    this.setState({ yearSelected: event.target.value, yearCurrentLink });
  };

  urlEncode = url => Buffer.from(url).toString('base64');

  render() {
    const { classes, t } = this.props;
    const {
      yearSelected, yearCurrentLink, isLoading, error,
    } = this.state;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={6} md={5} lg={5}>
            <Link component="a" href={`${initPath}${yearCurrentLink}`} title={`${t('รายงานสถานการณ์น้ำ')} ${t('ปี')} ${yearSelected}`} target="_blank">
              <img src={`${initPath}images/theme/banner/report/yearly/${yearSelected}.png`} width="100%" className={classes.outLined} alt={`${t('รายงานสถานการณ์น้ำ')} ${t('ปี')} ${yearSelected}`} />
              <img src={`${initPath}images/theme/banner/report/book-shadow.png`} width="100%" style={{ marginTop: -6 }} alt="" />
            </Link>
          </Grid>
          <Grid item xs={6} md={7} lg={7}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" style={{ height: '100%' }}>
              <Box display="block">
                <Box>
                  <Typography variant="h5">
                    {t('รายงานสถานการณ์น้ำ')}
                    <br />
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <br />
                    {yearSelected}
                  </Typography>
                  <hr />
                </Box>
                <Box mt={1}>
                  <Button
                    href={`${initPath}report/yearly/history`}
                    className={classes.buttonOutlined}
                    variant="outlined"
                  >
                    <FaCalendarAlt />
                    <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูย้อนหลัง')}</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

ReportYearly.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(ReportYearly));
