import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  uniqBy, orderBy,
} from 'lodash';

import moment from 'moment';
// import {
//   Link,
// } from 'react-router-dom';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

// lightbox

import 'react-image-lightbox/style.css';

// width
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';

// icon .
import {
  FaSpinner, FaCaretUp, FaCaretDown, FaEquals,
} from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

import WlStatusColor from '../wl/WlStatusColor';

import BmaMap from './FwhBmaMap';
import BmaContext from '../../contexts/BmaContext';
import Modal from '../modal/Modal';

// sidebar
// import SidebarWater from '../navigation/SidebarWater';

const initPath = process.env.MIX_APP_PATH;

class FwhBmaWater extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error

      datas: [],
      photoIndex: 0,
      isOpen: false,
      dataCondition: [],
      dataProvince: [],
      dataBasin: [],
      geojsonProvinceBkk: [],
      dataBkkWl: [],
      dataBkkRain24h: [],
      dataBkkCanal: [],
      dataBkkFlow: [],
      dataBkkFloodRoad: [],
      provinceBkkCode: '10',
      areaText: 'ทั่วประเทศ',
      geojsonPond: [],

      // for search
      dataInit: [],
      dataInitLastest: [],
      searchData: [],
      searchTerm: '',

      // map
      map: null,
      zoom: 10.5,
      location: [13.743251, 100.6],
      markers: [],

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      // radio group
      areaType: '0',
      areaId: '',
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
    this.getGeoJson();
    this.getGeoJsonPond();
  }

   getData = () => {
     const { provinceBkkCode } = this.state;
     Promise.all([
       fetch(`${process.env.MIX_API_URL}public/waterlevel_load`),
       fetch(`${process.env.MIX_API_URL}public/rain_24h`),
       fetch(`${process.env.MIX_API_URL}public/canal_waterlevel`),
       fetch(`${process.env.MIX_API_URL}public/flow`),
       fetch(`${process.env.MIX_API_URL}public/flood_road`),
     ])
       .then(([res1, res2, res3, res4, res5]) => Promise.all([res1.json(),
         res2.json(), res3.json(), res4.json(), res5.json()]))
       .then(([WaterleveLoad, Rain24h, WaterleveCanal, WarterFlow, FloodRoad]) => {
         const currentDateTime = new Date();
         const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
         const filterDataWaterLevel = orderBy(WaterleveLoad.waterlevel_data.data, {
           storage_percent: Number,
         })
           .filter(d => {
             const time = new Date(d.waterlevel_datetime.replace(/-/g, '/')).getTime();
             return ((last24hrs < time) && (d.waterlevel_msl != null));
           })
           .filter(d => d.storage_percent != null);

         const BkkWl = filterDataWaterLevel.filter(d => (
           d.geocode.province_code === provinceBkkCode
         ));

         let datasProvince = WaterleveLoad.waterlevel_data.data.map(d => ({
           id: d.geocode.province_code,
           name: d.geocode.province_name.th,
         }));

         datasProvince = uniqBy(datasProvince.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

         const rainfall24Sort = orderBy(Rain24h.data, ['rain_24h'], ['desc']);

         const dataRainfall24h = rainfall24Sort.filter(d => {
           const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
           return (last24hrs < time && d.rain_24h > 0);
         });

         const BkkRain24h = dataRainfall24h.filter(d => (
           d.geocode.province_code === provinceBkkCode
         ));
         const BkkCanal = WaterleveCanal.data;
         const BkkFlow = WarterFlow.data;
         const BkkFloodRoad = FloodRoad.data;

         // set state
         this.setState({
           isLoading: false,
           dataInit: filterDataWaterLevel,
           dataInitLastest: filterDataWaterLevel,
           datas: filterDataWaterLevel,
           dataProvince: datasProvince,
           dataBkkWl: BkkWl,
           dataBkkRain24h: BkkRain24h,
           dataBkkCanal: BkkCanal,
           dataBkkFlow: BkkFlow,
           dataBkkFloodRoad: BkkFloodRoad,
           dataCondition: datasProvince,
           latestDate: this.getMaxDate(filterDataWaterLevel),
           searchData: BkkWl,
         });
       })
       .catch(error => this.setState({ error, isLoading: false }));
   };

    getGeoJson = () => {
      const { provinceBkkCode } = this.state;
      Promise.all([
        fetch(`${initPath}json/boundary/thailand.json`),
      ])
        .then(([res2]) => Promise.all([res2.json()]))
        .then(([province]) => {
          const bkk = province.features.filter(p => p.properties.prov_code === provinceBkkCode);
          this.setState({
            geojsonProvinceBkk: bkk,
          });
        })
        .catch(error => this.setState({ error, isLoading: false }));
    };

    getGeoJsonPond() {
      fetch(`${initPath}json/fwh_bma/Pond.json`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('loading Poud Something went wrong ...');
        })
        .then(result => {
          this.setState({
            geojsonPond: result,
          });
        })
        .catch(error => this.setState({ error }));
    }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    const { modalData } = this.state;
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleSearchMarker = (id, latitude, longitude) => e => {
    const { map, markers } = this.state;
    e.preventDefault();
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
      const LatLng = { lat: latitude, lng: longitude };
      const px = map.project(LatLng);
      px.y -= 320 / 2;
      map.panTo(map.unproject(px), { animate: true });
    }
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.waterlevel_datetime.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format('YYYY-MM-DD')} เวลา : ${moment(maxdate).format('HH:mm')}`;
    }
    return moment().format('YYYY-MM-DD');
  }

  // compare current water level with previous data
  compareWl = (currentWl, previousWl, storagePercent) => {
    let text = '';
    let component = '';
    const status = WlStatusColor(storagePercent);

    if (currentWl === previousWl) {
      text = 'ทรงตัว';
      component = (
        <Tooltip title={`${text}ที่ระดับ ${currentWl} ม.รทก.`}>
          <IconButton>
            <FaEquals size={15} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    } else if (currentWl > previousWl) {
      text = 'เพิ่มขึ้น';
      component = (
        <Tooltip title={`${text} ${parseFloat(currentWl - previousWl).toFixed(2)} ม.รทก.`}>
          <IconButton>
            <FaCaretUp size={20} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    } else if (currentWl < previousWl) {
      text = 'ลดลง';
      component = (
        <Tooltip title={`${text} ${parseFloat(previousWl - currentWl).toFixed(2)} ม.รทก.`}>
          <IconButton>
            <FaCaretDown size={20} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    }
    return [{ text, component }];
  }

  // get water level status
  wlStatus = storagePercent => {
    const status = WlStatusColor(storagePercent);
    return (
      <span
        style={{
          color: status.color.statusColor,
        }}
      >
        {status.color.label}
      </span>
    );
  }

  resetModal = () => {
    const { modalDataReset, modalData } = this.state;
    this.setState({ modalData: modalDataReset });
    modalData.open = false;
    this.setState({ modalData });
  }

  filterDatas() {
    const {
      searchTerm, areaId, dataInit, rowsPerPage, page, areaType,
    } = this.state;
    const searchData = dataInit
      .filter(d => {
        // search by keyword
        if (searchTerm !== '') {
          if (areaId !== '') {
            return (d.station.tele_station_name.th.indexOf(searchTerm) > -1
              || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
              || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
              || d.geocode.province_name.th.indexOf(searchTerm) > -1)
              && (d.basin.basin_code.toString() === areaId || d.geocode.province_code === areaId);
          }
          return d.station.tele_station_name.th.indexOf(searchTerm) > -1;
        }

        // // search by area type
        if (areaId !== '') {
          return (areaType === '1' ? d.basin.basin_code.toString() === areaId : d.geocode.province_code === areaId);
        }
        return d;
      });
    const pageCurrent = (searchData.length < rowsPerPage) ? 0 : page;

    this.setState({
      searchData,
      page: pageCurrent,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      modalData,
    } = this.state;

    const {
      isLoading, error, searchData,

    } = this.state;

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div>
        <BmaContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >

          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs>
                <Box mt={3} display="flex">
                  <Typography variant="h6" gutterBottom>
                    สถานการณ์น้ำใน กทม
                  </Typography>
                </Box>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs>
                <BmaMap
                  {...this.state}
                  datas={searchData}
                  handleSetMap={this.handleSetMap}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Modal
                open={modalData.open}
                modalHeader={modalData.header}
                modalContent={modalData.content}
                resetModal={this.resetModal}
              />
            </Grid>
          </div>
        </BmaContext.Provider>
      </div>
    );
  }
}

FwhBmaWater.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }))(FwhBmaWater);
