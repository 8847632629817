import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import styles from './Style';

export default function RainIsohyet(props) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [monthObj, setMonthObj] = useState({});
  const { dataIsohyet, isohyetGeoTiff } = props;
  const [value, setValue] = useState(isohyetGeoTiff);

  function handleChange(event) {
    setValue(event.target.value);
    props.handler(event.target.value, monthObj[event.target.value]);
  }

  function renderIsohyet(varDataIsohyet) {
    const currentDate = moment();
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year() + 543;
    const thaiYearShort = currentYear.toString().slice(-2);

    return varDataIsohyet.map((rows, i) => {
      if (i < currentMonth) {
        return (
          <FormControlLabel
            key={rows.geotiff}
            value={rows.geotiff}
            control={<Radio />}
            label={`${rows.month} ${thaiYearShort}`}
          />
        );
      }
      return null;
    });
  }

  return (
    <>
      {(isLoading === true && !dataIsohyet) && (
        <div className="text-center">
          Loading...
        </div>
      )}
      <Paper style={styles.menuControlStyle}>
        <FormControl component="fieldset">
          <Box component="span" m={1} style={styles.mainmenu}>
            {t('ฝน NHC')}
          </Box>
          <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
            <RadioGroup
              aria-label="rain_isohyet"
              name="rain_isohyet"
              value={value}
              onChange={handleChange}
            >
              {dataIsohyet && renderIsohyet(dataIsohyet)}
            </RadioGroup>

          </div>
        </FormControl>
      </Paper>
    </>
  );
}

RainIsohyet.propTypes = {
  dataIsohyet: PropTypes.array.isRequired,
  isohyetGeoTiff: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  handlerInitMonth: PropTypes.func.isRequired,
};
