import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { orderBy as orderByLodash } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

// ui
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/moment';
import grey from '@material-ui/core/colors/grey';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  FaChartLine, FaSpinner,
  FaAngleLeft, FaAngleRight,
} from '../../utils/Fontawesome';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';

import {
  styles, SmallDamTableCell, StyledTableRow,
} from '../../styles/Style';

// dam
import DamStatusColor from './DamStatusColor';
import DamContext from '../../contexts/damContext';
import DamMediumChart from './DamMediumChart';
import tJson from '../../utils/TJson';

const tableGroupColor = '#006fb6';
const tableSummaryColor = '#eee';
const tableGrandSummaryColor = grey[300]; // #F44336
const CustomButton = withStyles(() => ({
  root: {
    '&:hover': {
      border: '1px solid #000',
      backgroundColor: '#fff',
      color: '#000',
      outlined: {
        border: '1px solid #000',
      },
    },
  },
}))(Button);

// table Header
const columnData = [
  {
    id: 'dam.dam_name.th', align: 'left', numeric: false, disablePadding: true, label: 'อ่างเก็บน้ำ', sortable: true,
  },
  {
    id: 'geocode.province_name.th', align: 'left', numeric: false, disablePadding: true, label: 'จังหวัด', sortable: true,
  },
  {
    id: 'dam.normal_storage', align: 'right', numeric: true, disablePadding: false, label: 'กักเก็บปกติ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam.min_storage', align: 'right', numeric: true, disablePadding: false, label: 'กักเก็บต่ำสุด', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_storage', align: 'right', numeric: true, disablePadding: false, label: 'กักเก็บ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_uses_water', align: 'right', numeric: true, disablePadding: false, label: 'ใช้การได้จริง', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_inflow', align: 'right', numeric: true, disablePadding: false, label: 'น้ำไหลลง', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_released', align: 'right', numeric: true, disablePadding: false, label: 'น้ำระบาย', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  // {
  //   id: 'chart', numeric: false, disablePadding: false, label: '',
  // },
];

const areas = [
  { id: 1, name: 'ภาคเหนือ' },
  { id: 2, name: 'ภาคตะวันออกเฉียงเหนือ' },
  { id: 3, name: 'ภาคกลาง' },
  { id: 4, name: 'ภาคตะวันตก' },
  { id: 5, name: 'ภาคตะวันออก' },
  { id: 6, name: 'ภาคใต้' },
  { id: 9, name: 'อื่นๆ' },
];

class DamMediumFullList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      mediumDamRid: [],
      selectedDateMediumRid: '',

      // table sorting
      order: 'asc',
      orderBy: 'dam_name',
      selectedDamRidOffice: 'all',
      dataRidType: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, name: `สำนักชลประทานที่ ${i + 1}` })),
      filterDatasMedium: props.filterDatasDamMedium,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.selectedDateMediumRid === '') {
      return {
        selectedDateMediumRid: props.latestDateMediumRid,
        mediumDamRid: props.filterDatasDamMedium,
      };
    }

    if (state.filterDatasMedium.length === 0) {
      return {
        filterDatasMedium: props.filterDatasDamMedium,
      };
    }

    return null;
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { filterDatasDamMedium } = this.props;
    this.setState({ [name]: value });
    const data = filterDatasDamMedium.filter(item => item.dam.rid_office === value);
    this.setState({ mediumDamRid:data})
  }

  handleDateChange = date => {
    // console.log(moment(date).locale('th').format('YYYY-MM-DD'));
    this.setState({
      isLoading: true,
      selectedDateMediumRid: date,
    });

    this.fetchData(moment(date).format('YYYY-MM-DD'), 2);
  };

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const header = t('กราฟอ่างเก็บน้ำขนาดกลางรายปี กรมชลประทาน');
    const { damsMediumRidList } = this.props;
    const params = {
      header,
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamMediumChart id={data.dam.id} damsMediumRid={damsMediumRidList} rid_office={data.dam.rid_office} />,
    };

    handler(params);
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderBy === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderBy: orderByProperty });
  };

  fetchData = (damDate, damSize) => {
    fetch(`${process.env.MIX_API_URL}analyst/dam?dam_date=${damDate}&dam_size=${damSize}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // sorting and filter, large dam
        let datasDamDailyRid = [];
        datasDamDailyRid= orderByLodash(result.data.dam_medium, ['dam_storage'], ['desc']);
        const { selectedDamRidOffice } = this.state;
        if(selectedDamRidOffice !== 'all'){
          datasDamDailyRid = datasDamDailyRid.filter(item => parseInt(item.dam.rid_office, 10) === parseInt(selectedDamRidOffice, 10)); 
        }
        // set state
        this.setState({
          isLoading: false,
          mediumDamRid: datasDamDailyRid,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  setDate = step => {
    let newDate = '';

    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    const { latestDateMediumRid } = this.props;
    const { selectedDateMediumRid } = this.state;

    // set new date base on agency and step (previous date or next date)
    if (step === 'prev') {
      newDate = moment(selectedDateMediumRid).subtract(1, 'days').format('YYYY-MM-DD');
    } else if (step === 'next') {
      newDate = moment(selectedDateMediumRid).add(1, 'days').format('YYYY-MM-DD');

      // check max date
      if (newDate > latestDateMediumRid) {
        alert(t('ไม่สามารถเปลี่ยนวันได้ เนื่องจากข้อมูลยังไม่มีถึงวันที่เลือก'));
        return;
      }
    }
    // console.log(newDate);

    // if date is valid change date state
    if (newDate.match(regEx) != null) {
      this.setState({
        selectedDateMediumRid: newDate,
      });
      this.handleDateChange(newDate);
    }
  }

  render() {
    const {
      classes, latestDateMediumRid, theme, t,
    } = this.props;

    const {
      isLoading,
      error,
      selectedDateMediumRid,
      selectedDamRidOffice,
      order,
      orderBy,
      filterDatasMedium,
    } = this.state;

    let {
      mediumDamRid,
    } = this.state;
    // selectedDamRidOffice = 11 ไม่มีข้อมูล
    if (mediumDamRid.length === 0 && selectedDamRidOffice !== 11) {
      mediumDamRid = filterDatasMedium;
    }
    // const groupMediumDam = _.groupBy(mediumDamRid, 'geocode.area_code');

    let grandTotalNormalStorage = 0;
    let grandTotalMinStorage = 0;
    let grandTotalInflow = 0;
    let grandTotalReleased = 0;
    let grandTotalDamStorage = 0;
    let grandTotalDamUseWater = 0;
    const totalMediumdam = mediumDamRid.length;
    return (
      <>
        {isLoading === true && (
          <FaSpinner
            size={75}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              margin: '-50px 0px 0px -50px',
            }}
          />
        )}
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Box display="flex" justifyContent="center" my={2}>
              <IconButton
                className={classes.button}
                aria-label={t('วันก่อนหน้า')}
                onClick={() => {
                  this.setDate('prev');
                }}
              >
                <FaAngleLeft fontSize="small" />
              </IconButton>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label={t('ข้อมูลวันที่')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={selectedDateMediumRid}
                  maxDate={latestDateMediumRid}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                className={classes.button}
                aria-label={t('วันถัดไป')}
                onClick={() => {
                  this.setDate('next');
                }}
              >
                <FaAngleRight fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Box display="flex" justifyContent="center" my={2}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="year">{t('สปช.')}</InputLabel>
                <Select
                  value={selectedDamRidOffice}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'selectedDamRidOffice',
                    id: 'id',
                  }}
                >
                  <MenuItem key={0} value={'all'}>
                    {"ทั้งหมด"} {/* "ทั้งหมด" means "All" */}
                  </MenuItem>
                  {this.state.dataRidType.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
       
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {error && (
              <div className="text-center">{error.message}</div>
            )}

            <TableContainer>
              <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                <TableHeaderEnhance
                  columnData={columnData}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  firstColumnSticky
                />
                <TableBody>
                  {areas
                    .map(area => {
                      const totalDamInArea = mediumDamRid
                        .filter(row => row.geocode.area_code === area.id.toString())
                        .length;

                      if (totalDamInArea === 0) return null;

                      let totalNormalStorage = 0;
                      let totalMinStorage = 0;
                      let totalInflow = 0;
                      let totalReleased = 0;
                      let totalDamStorage = 0;
                      let totalDamUseWater = 0;

                      return (
                        <Fragment key={area.id}>
                          <StyledTableRow style={{ backgroundColor: tableGroupColor }}>
                            <SmallDamTableCell className="first-column-sticky" style={{ fontSize: '1em', color: '#FFF', backgroundColor: tableGroupColor }}>
                              <strong>
                                {t(area.name)}
                                {` (${totalDamInArea})`}
                              </strong>
                            </SmallDamTableCell>
                            <SmallDamTableCell colSpan={8} style={{ backgroundColor: tableGroupColor }} />
                          </StyledTableRow>
                          {orderByLodash(mediumDamRid, orderBy, order)
                            .filter(row => row.geocode.area_code === area.id.toString())
                            .map(row => {
                              // color status
                              const status = DamStatusColor(
                                parseFloat(row.dam_storage_percent).toFixed(0),
                              );
                              // total by area
                              totalNormalStorage += row.dam.normal_storage;
                              if (row.dam.min_storage) {
                                totalMinStorage += row.dam.min_storage;
                              }
                              totalInflow += row.dam_inflow;
                              totalReleased += row.dam_released;
                              totalDamStorage += row.dam_storage;
                              totalDamUseWater += row.dam_uses_water;

                              // grand total
                              grandTotalNormalStorage += row.dam.normal_storage;
                              if (row.dam.min_storage) {
                                grandTotalMinStorage += row.dam.min_storage;
                              }
                              grandTotalInflow += row.dam_inflow;
                              grandTotalReleased += row.dam_released;
                              grandTotalDamStorage += row.dam_storage;
                              grandTotalDamUseWater += row.dam_uses_water;

                              return (
                                <StyledTableRow key={row.id}>
                                  <SmallDamTableCell component="th" scope="row" align="left" className="first-column-sticky">
                                    <DamContext.Consumer>
                                      {({ handleModalOpen }) => (
                                        <>
                                          <Tooltip title={t('กราฟรายปี')}>
                                            <CustomButton
                                              onClick={this.handleChart(handleModalOpen, row, 12)}
                                            >
                                              {/* {row.dam.dam_name.th.replace('อ่างเก็บน้ำ', '')} */}
                                              {tJson(row.dam.dam_name, ' ')}
                                              {/* {tJson(row.dam.dam_name, 'อ่างเก็บน้ำ')} */}
                                            </CustomButton>
                                          </Tooltip>
                                          <Tooltip title={t('แสดงกราฟรายปี')}>
                                            <IconButton className={classes.button} aria-label="Chart" onClick={this.handleChart(handleModalOpen, row)}>
                                              <FaChartLine fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </DamContext.Consumer>
                                  </SmallDamTableCell>
                                  <SmallDamTableCell>
                                    {tJson(row.geocode.province_name, 'จ.')}
                                  </SmallDamTableCell>
                                  <SmallDamTableCell align="right">
                                    {row.dam.normal_storage}
                                  </SmallDamTableCell>
                                  <SmallDamTableCell align="right">
                                    {row.dam.min_storage}
                                  </SmallDamTableCell>
                                  <SmallDamTableCell align="right">
                                    {row.dam_storage}
                                    <span style={{ color: status.color.statusColor }}>
                                      {/* {` (${row.dam_storage_percent.toFixed(0)}%)`} */}
                                      {(row.dam_storage_percent) ? `(${row.dam_storage_percent.toFixed(0)})%` : row.dam_storage_percent}
                                    </span>
                                  </SmallDamTableCell>
                                  <SmallDamTableCell align="right">
                                    {row.dam_uses_water}
                                  </SmallDamTableCell>
                                  <SmallDamTableCell align="right">{row.dam_inflow}</SmallDamTableCell>
                                  <SmallDamTableCell align="right">{row.dam_released}</SmallDamTableCell>
                                </StyledTableRow>
                              );
                            })}
                          <StyledTableRow style={{ backgroundColor: tableSummaryColor }}>
                            <SmallDamTableCell>
                              <strong>
                                {t('รวม')}
                                {t(area.name)}
                              </strong>
                            </SmallDamTableCell>
                            <SmallDamTableCell />
                            <SmallDamTableCell align="right">
                              <strong>
                                {totalNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </strong>
                            </SmallDamTableCell>
                            <SmallDamTableCell align="right">
                              <strong>
                                {totalMinStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </strong>
                            </SmallDamTableCell>
                            <SmallDamTableCell align="right">
                              <strong>
                                {totalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </strong>
                            </SmallDamTableCell>
                            <SmallDamTableCell align="right">
                              <strong>
                                {totalDamUseWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </strong>
                            </SmallDamTableCell>
                            <SmallDamTableCell align="right">
                              <strong>
                                {totalInflow.toFixed(2)}
                              </strong>
                            </SmallDamTableCell>
                            <SmallDamTableCell align="right">
                              <strong>
                                {totalReleased.toFixed(2)}
                              </strong>
                            </SmallDamTableCell>
                          </StyledTableRow>
                        </Fragment>
                      );
                    })}
                  <StyledTableRow style={{ backgroundColor: tableGrandSummaryColor }}>
                    <SmallDamTableCell>
                      <strong>
                        {t('รวมทั้งประเทศ')}
                        {' '}
                        {`(${totalMediumdam})`}
                      </strong>
                    </SmallDamTableCell>
                    <SmallDamTableCell />
                    <SmallDamTableCell align="right">
                      <strong>
                        {grandTotalNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </strong>
                    </SmallDamTableCell>
                    <SmallDamTableCell align="right">
                      <strong>
                        {grandTotalMinStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </strong>
                    </SmallDamTableCell>
                    <SmallDamTableCell align="right">
                      <strong>
                        {grandTotalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </strong>
                    </SmallDamTableCell>
                    <SmallDamTableCell align="right">
                      <strong>
                        {grandTotalDamUseWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </strong>
                    </SmallDamTableCell>
                    <SmallDamTableCell align="right">
                      <strong>
                        {grandTotalInflow.toFixed(2)}
                      </strong>
                    </SmallDamTableCell>
                    <SmallDamTableCell align="right">
                      <strong>
                        {grandTotalReleased.toFixed(2)}
                      </strong>
                    </SmallDamTableCell>
                  </StyledTableRow>
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <SmallDamTableCell colSpan={12} align="right">
                      <Link color="secondary" href="http://water.rid.go.th" target="_blank" rel="noopener noreferrer">
                        <Typography variant="caption">
                          {t('ข้อมูลจาก กรมชลประทาน')}
                        </Typography>
                      </Link>
                    </SmallDamTableCell>
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="flex-end">
              <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                {`${t('ข้อมูลล่าสุดวันที่')} ${moment(selectedDateMediumRid).locale('th').format('DD MMM YYYY')} ${t('(หน่วย ล้าน ลบ.ม.)')}`}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

DamMediumFullList.defaultProps = {
  latestDateMediumRid: moment().subtract(1, 'days').format('YYYY-MM-DD'),
};

DamMediumFullList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  filterDatasDamMedium: PropTypes.array.isRequired,
  latestDateMediumRid: PropTypes.string,
  damsMediumRidList: PropTypes.array.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(DamMediumFullList);
