import React from 'react';
import { useParams } from 'react-router-dom';
import WaterGateChart from '../../water_gate/WaterGateChart';
import DischargeChart from '../../wl_rid/DischargeChart';

const wgChart = () => {
  const { stationId, provinceId, startDate } = useParams();
  return (
    <>
      <WaterGateChart id={parseInt(stationId, 10)} provinceId={provinceId} startDate={startDate} />
    </>
  );
};

export default wgChart;
