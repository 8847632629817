import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
// style, icons
import { withStyles } from '@material-ui/core/styles';

import { withTranslation, Trans } from 'react-i18next';

// ui
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { FaChartLine } from '../../utils/Fontawesome';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';

import Typography from '@material-ui/core/Typography';

// table .
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// ui
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';

import {
  styles, StyledTableRow,
} from '../../styles/Style';

class SealevelForcastList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      page: 0,
      rowsPerPage: 10,
      order: 'asc',
      orderByPro: 'tide_station_th',
    };
    this.handleChart = this.handleChart.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isLoading: false }), 500);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChart = data => e => {
    const { handleModalForcastOpen } = this.props;
    e.preventDefault();

    const newData = {
      id: data.id,
      code: data.tide_code,
    };

    handleModalForcastOpen(newData);
  };

  handleRequestSort = (event, property) => {
    const { order, orderByPro } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByPro === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByPro: orderByProperty });
  };

  render() {
    const { classes, theme, datas, t, i18n } = this.props;
    const {
      rowsPerPage, page, isLoading, order, orderByPro,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, datas.length - page * rowsPerPage);

    // table Header
    const columnData = [
      {
        id: 'tide_station_th', disablePadding: false, label: 'ชื่อสถานี', sortable: true, align: 'left',
      },
      {
        id: 'min_value', disablePadding: false, label: 'ต่ำสุด', unit: '(เวลา)', sortable: true, align: 'center',
      },
      {
        id: 'max_value', disablePadding: false, label: 'สูงสุด', unit: '(เวลา)', sortable: true, align: 'center',
      },
      {
        id: 'time_0000', disablePadding: false, label: '00.00', sortable: false, align: 'center',
      },
      {
        id: 'time_0400', disablePadding: false, label: '04.00', sortable: false, align: 'center',
      },
      {
        id: 'time_0800', disablePadding: false, label: '08.00', sortable: false, align: 'center',
      },
      {
        id: 'time_1200', disablePadding: false, label: '12.00', sortable: false, align: 'center',
      },
      {
        id: 'time_1600', disablePadding: false, label: '16.00', sortable: false, align: 'center',
      },
      {
        id: 'time_2000', disablePadding: false, label: '20.00', sortable: false, align: 'center',
      },
      {
        id: 'chart', disablePadding: false, label: 'กราฟ', align: 'center',
      },
    ];

    return (
      <>
        <TableContainer component={Paper}>
          <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
            <TableHead>
              <StyledTableRow key={1}>
                <TableCell />
                <TableCell align="center" />
                <TableCell align="center" />
                <TableCell align="center" colSpan={6}>{t('ทำนายล่วงหน้าทุก 4 ชั่วโมง')}</TableCell>
                <TableCell />
              </StyledTableRow>
            </TableHead>
            <TableHeaderEnhance
              columnData={columnData}
              order={order}
              orderBy={orderByPro}
              onRequestSort={this.handleRequestSort}
              firstColumnSticky
            />
            <TableBody>
              {orderBy(datas, orderByPro, order)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => {
                  const { handleSearchMarkerForcast } = this.props;

                  let mapLink = null;
                  if (
                    handleSearchMarkerForcast
                    && row.tide_station_lat
                    && row.tide_station_long
                  ) {
                    mapLink = (
                      <Button
                        onClick={handleSearchMarkerForcast(
                          id,
                          row.tide_station_lat,
                          row.tide_station_long,
                        )}
                        title={t('สถานีทำนาย โดย สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)')}
                        style={{ textAlign: 'left', paddingLeft: 5 }}
                      >
                        {i18n.language === 'th' && (row.tide_station_th)}
                        {i18n.language === 'en' && (row.tide_station_en)}
                        {i18n.language === 'jp' && (row.tide_station_th)}
                      </Button>
                    );
                  } else {
                    mapLink = row.tide_station_th;

                    if (i18n.language === 'en') {
                      mapLink = row.tide_station_en;
                    } else if (i18n.language === 'jp') {
                      mapLink = row.tide_station_th;
                    }
                  }

                  return (
                    <StyledTableRow key={row.id}>
                      <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                        {mapLink}
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          {`${row.min_value}`}
                        </Typography>
                        <Typography variant="caption" noWrap>
                          ({`${row.min_time}`})
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          {`${row.max_value}`}
                        </Typography>
                        <Typography variant="caption" noWrap>
                          ({`${row.max_time}`})
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {`${row.time_0000}`}
                      </TableCell>
                      <TableCell align="center">
                        {`${row.time_0400}`}
                      </TableCell>
                      <TableCell align="center">
                        {`${row.time_0800}`}
                      </TableCell>
                      <TableCell align="center">
                        {`${row.time_1200}`}
                      </TableCell>
                      <TableCell align="center">
                        {`${row.time_1600}`}
                      </TableCell>
                      <TableCell align="center">
                        {`${row.time_2000}`}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={t('แสดงกราฟ')}>
                          <IconButton
                            onClick={this.handleChart(row)}
                            className={classes.button}
                            aria-label="Chart"
                          >
                            <FaChartLine fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}

              {/* {emptyRows <= 0 && !isLoading && (
                <TableCell component="th" scope="row" colSpan={10} align="center">
                  ไม่มีข้อมูล
                </TableCell>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          className={classes.hiddenLine}
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActionsWrapped}
          labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
          labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to}  ${count} ${t('รายการ')}`}
        />
      </>
    );
  }
}

SealevelForcastList.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  handleModalForcastOpen: PropTypes.func.isRequired,
  handleSearchMarkerForcast: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(SealevelForcastList));
