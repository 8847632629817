/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Img from "react-image";
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
// ui
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
// icon
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import WarningIcon from '@material-ui/icons/Warning';

import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import DashboardContext from '../../contexts/dashboardContext';
import Modal from '../modal/Modal';

import Dam from './Dam';
import Storm from './Storm';
import Temperature from './Temperature';
import DamUseWater from './DamUseWater';
import Situation from './Situation';
import Rainfall from './Rainfall';
import WaterlevelTab from './WaterlevelTab';
import ImageSituation from './situations/alert/ImageSituation';
import { processDataAndFilterLatest, fetchOneFile } from '../report/drive/utils/Utils';
import { hiiReportSituation } from '../report/drive/path/drivepath';//โฟล์เดอร์ report
// style
import '../../../../public/css/app.css';
import { styles } from '../../styles/Style';

const StyledSnackbar = withStyles({
  root: {
    color: 'white',
    padding: '0',
    background: '#fff',
    border: '3px solid #78B641',
    zIndex: 9999,
    borderRadius: 15,
    fontFamily: 'Kanit',
    '&: .MuiSnackbarContent-root': {
      width: '100%',
      padding: '0px 16px !important',
    },
    '&: .MuiSnackbarContent-message': {
      padding: '0px',
      width: '100%',
    },
    '&: .MuiAlert-message': {
      width: '100%',
    },
  },
})(Snackbar);

const StyledSnackbarMhesi = withStyles({
  root: {
    color: 'white',
    padding: '0px',
    zIndex: 9999,
    fontFamily: 'Kanit',
    '&: .MuiSnackbarContent-root': {
      width: '100%',
      padding: '0px 16px !important',
    },
    '&: .MuiSnackbarContent-message': {
      padding: '0px',
      width: '100%',
    },
    '&: .MuiAlert-message': {
      width: '100%',
    },
  },
})(Snackbar);

const StyledAlert = withStyles(theme => ({
  root: {
    padding: '0',
    background: '#fff',
    [theme.breakpoints.up('sm')]: {
      width: 220,
    },
  },
}))(Alert);

const initPath = process.env.MIX_APP_PATH;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status /
      error: null, // error /
      dataDam: {}, // เขื่อน /
      dataStorm: {}, // ภาพพายุ /
      dataTemperature: {}, // อุณหภูมิ /
      dataPopupReport: {}, // popup แจ้งเตือนสถานการณ์ /
      isShowWaterlevel: true,

      setOpen: false,
      PollId: 'Poll02112022',

      setOpenMhesi: true,

      setOpenReport: true,
      // modal //
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
    };
  }

  // eslint-disable-next-line camelcase
  componentDidMount() {
    // set loading status /
    this.setState({ isLoading: true });

    this.getDataPopupReport();
    // get data from ws
    this.getData();
  }

  // async getData() {
  //   // get data from service /
  //   fetch(`${process.env.MIX_API_URL}public/thaiwater_main`)
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error('Something went wrong ...');
  //     })
  //     .then(result => {
  //       const dataDam = result.dam;
  //       const dataStorm = result.storm;
  //       const dataTemperature = result.temperature;
  //       this.setState({
  //         dataDam,
  //         dataStorm,
  //         dataTemperature,
  //         isLoading: false,
  //       });
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  async getData() {
    try {
      const response = await fetch(`${process.env.MIX_API_URL}public/thaiwater_main`);
      if (!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const result = await response.json();
      const dataDam = result.dam;
      const dataStorm = result.storm;
      const dataTemperature = result.temperature;

      this.setState({
        dataDam,
        dataStorm,
        dataTemperature,
        isLoading: false,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ isLoading: false });
    }
  }

  async getDataPopupReport() {
    const datapopup = await fetchOneFile(1, hiiReportSituation, 1, moment().format("YYYY"));

    if (datapopup && datapopup.files) {
      const sortedimage = await processDataAndFilterLatest(datapopup);
      const filterimage = await sortedimage.filter((file) => {
        if (file.dateStart && file.dateEnd) {
          const now = moment();
          const startDate = moment(file.dateStart, "YYYYMMDD", true);
          const endDate = moment(file.dateEnd, "YYYYMMDD", true);

          if (!startDate.isValid() || !endDate.isValid()) {
            console.log('Invalid date format');
            return false;
          }

          return now.isBetween(startDate, endDate, 'day', '[]');
        }
      }).sort((a, b) => {
        const aDate = new Date(currentYear, parseInt(a.date.substring(0, 2), 10) - 1, parseInt(a.date.substring(2, 4), 10));
        const bDate = new Date(currentYear, parseInt(b.date.substring(0, 2), 10) - 1, parseInt(b.date.substring(2, 4), 10));
        return bDate - aDate;
      });

      if (filterimage[0]) {
        this.setState({
          dataPopupReport: filterimage[0],
          isLoading: false,
        });
      }
    }
  }

  handleModalOpen = params => {
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleFeedbackClose = () => {
    this.setState({ setOpen: false });
  };

  handleMhesiClose = () => {
    this.setState({ setOpenMhesi: false });
  };

  handleReportClose = () => {
    this.setState({ setOpenReport: false });
  };

  handleFormClose = () => {
    const { PollId } = this.state;
    // ในกรณีมีการ edit หรือต้องการให้โชว์ popup ใหม่ให้ใช้  format Poll, Date, Month, Year eg. Poll 02 11 2022 -> Poll02112022
    sessionStorage.setItem(PollId, false);
    // ในอนาคตปรับเป็น cookie ก็ได้
    // this.setState({ setOpenFormModal: false });

    // popup feedback
    this.setState({ setOpen: false });

    // popup Mhesi
    // this.setState({ setOpenMhesi: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleFeedback = () => {
    this.setState({ setOpen: true });
  };
  handleMhesi = () => {
    this.setState({ setOpenMhesi: true });
  };

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  render() {
    const { classes, i18n } = this.props;
    const {
      isLoading,
      error,
      dataDam,
      dataStorm,
      dataTemperature,
      setOpen,
      modalData,
      isShowWaterlevel,
      setOpenMhesi,
      setOpenReport,
      dataPopupReport,
      // setOpenPoll,
      // PollId,
    } = this.state;


    const image = `${initPath}images/theme/banner/feedback/hii-active.png`;
    const imageSelected = `${initPath}images/theme/banner/feedback/hii-hover.png`;

    const HoverImage = props => {
      function over(e) {
        e.currentTarget.src = props.hoverImage;
      }
      function out(e) {
        e.currentTarget.src = props.normalImage;
      }
      return <img src={props.normalImage} onMouseOver={over} onMouseOut={out} className={classes.feedback} onClick={this.handleFeedback} alt="feedback" />;
    };

    return (
      <div>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        <DashboardContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <Fragment key="key">
            {/* banner แบบสอบถาม และแจ้งปัญหา */}
            <Box style={{
              position: 'fixed', bottom: 0, right: 0, zIndex: 999,
            }}
            >
              <HoverImage normalImage={image} hoverImage={imageSelected} />
            </Box>

            <StyledSnackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={setOpen}
              style={{ marginBottom: 70, padding: 0 }}
              message={
                (
                  <Box>
                    {/* แบบสำรวจความพึงพอใจ */}
                    <StyledAlert icon={<CheckBoxIcon fontSize="inherit" style={{ color: '#78B641' }} />}>
                      <Link href="https://forms.gle/EpveRopomxPsFtXR9" target="_blank" title="แบบสำรวจความพึงพอใจ Thaiwater.net">
                        <Box display="flex" alignItems="center">
                          <Box
                            className={classes.buttonPopup}
                            display="block"
                            alignItems="center"
                          >
                            แบบประเมินความพึงพอใจ
                          </Box>
                        </Box>
                      </Link>
                    </StyledAlert>
                    <Divider />
                    {/* แบบสำรวจแจ้งปัญหาการใช้งาน */}
                    <StyledAlert icon={<InfoIcon fontSize="inherit" style={{ color: '#1976d2', marginTop: 10 }} />}>
                      <Link href="https://devsupport.hii.or.th/" target="_blank" title="แบบสำรวจแจ้งปัญหาการใช้งาน Waterinfo เวอร์ชั่นใหม่">
                        <Box display="flex">
                          <Box display="block" flexDirection="column">
                            <Box className={classes.buttonPopup}>
                              หากพบปัญหาการใช้งานเว็บไซต์
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              style={{
                                color: '#000', fontSize: '0.8em', fontFamily: 'Kanit',
                              }}
                            >
                              สามารถแจ้งได้ที่นี่
                            </Box>
                          </Box>
                        </Box>
                      </Link>
                    </StyledAlert>
                  </Box>
                )
              }
              action={
                (
                  <IconButton
                    size="small"
                    aria-label="close"
                    onClick={this.handleFormClose}
                    title="close"
                    style={{ marginTop: -85 }}
                  >
                    <CloseIcon fontSize="small" style={{ color: '#000' }} />
                  </IconButton>
                )
              }
            />

            {/* popup Mhesi Warroom ศูนย์ปฏิบัติการสถานการณ์น้ำท่วม อว.เพื่อประชาชน */}
            <StyledSnackbarMhesi
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={setOpenMhesi}
              style={{ bottom: setOpenReport ? 100 : 0 }}
              message={
                (
                  <Box ml={-2}>
                    <IconButton
                      size="small"
                      aria-label="close"
                      onClick={this.handleMhesiClose}
                      title="ปิด"
                      style={{ display: 'flex', position: 'absolute', right: 15, zIndex: 9 }}
                    >
                      <CloseIcon fontSize="small" style={{ color: '#fff', background: '#5b3f91' }} />
                    </IconButton>
                    <Link href="https://www.thaiwater.net/mhesi-warroom" target="_blank" title="ศูนย์ปฏิบัติการสถานการณ์น้ำท่วม อว.เพื่อประชาชน">
                      <Img
                        src={`${initPath}images/mhesi_warroom/banner_mhesi.png`}
                        width="100%"
                        height="100%"
                        style={{ position: 'relative', cursor: 'pointer' }}
                        alt="ศูนย์ปฏิบัติการสถานการณ์น้ำท่วม อว.เพื่อประชาชน"
                      />
                    </Link>
                  </Box>
                )
              }

            />
            {(dataPopupReport && Object.keys(dataPopupReport).length > 0) &&
              (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  open={setOpenReport}
                  message={
                    (

                      <Box display="flex" style={{ textDecorationLine: 'underline' }}>
                        <Alert
                          icon={<WarningIcon fontSize="inherit" />}
                          onClose={this.handleReportClose}
                          severity="warning"
                          style={{
                            backgroundColor: '#C1413A',
                            marginBottom: 0,
                            fontSize: '1.2em',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            fontFamily: 'Kanit',
                          }}
                        >
                          <RouterLink to={`${initPath}report/situation`} title="ประกาศ! แจ้งเตือนสถานการณ์ Thaiwater.net">
                            <Box display="flex"
                              alignItems="center"
                              minWidth={{ xs: 200, sm: 255, md: 261 }}
                              className={classes.btn}
                              style={{
                                color: '#FFFFFF',
                              }}
                            >
                              ประกาศ! แจ้งเตือนสถานการณ์
                            </Box>
                          </RouterLink>
                        </Alert>
                      </Box>
                    )
                  }
                  action={
                    (
                      <IconButton size="small" aria-label="close" onClick={this.handleReportClose} style={{ backgroundColor: '#C1413A', marginLeft: -50 }} title="close">
                        <CloseIcon fontSize="small" style={{ color: 'white' }} />
                      </IconButton>
                    )
                  }

                />
              )
            }


            <Box mt={2} />
            {isLoading === false && (
              <Situation />
            )}

            {/* 2022-01-10 ปิดการใช้งาน tab สถานการณ์ระดับน้ำภาคใต้เนื่องจากหมดเหตุการณ์แล้ว  */}
            {/* 2023-09-27 รับสั่งจาก วรรธนศักดิ์ สุปะกิ่ง ให้เปิดการใช้งาน ติดตามสถานการณ์น้ำท่วม 66 - 67 เฉพาะ tab ชี-มูล */}
            {/* 2023-09-28 รับสั่งจาก วรรธนศักดิ์ สุปะกิ่ง ให้เพิ่มการใช้งานสถานีฝน 24 ชม. */}
            {/* 2024-02-12 ปิดการใช้งาน tab สถานการณ์ระดับน้ำภาคใต้เนื่องจากหมดเหตุการณ์แล้ว  */}
            {/* 2024-09-16 รับสั่งจาก วาสุกรี แซ่เตีย ในกลุ่มตรวจสอบ ให้เพิ่ม tab สถานการณ์น้ำ ภาคตะวันออกเฉียงเหนือ */}
            {isShowWaterlevel && <WaterlevelTab />}
            <Rainfall />

            {isLoading === false && (
              <Temperature
                dataTemperature={dataTemperature}
              />
            )}
            {/* end Temperature */}

            <div className={classes.layout}>
              {isLoading === false && (
                <Storm
                  dataStorm={dataStorm}
                />
              )}
            </div>

            {/* ---------------- dam */}
            {isLoading === false && (
              <Dam
                dataDam={dataDam}
              />
            )}
            {/* ---------------- end dam */}
            {isLoading === false && (
              <DamUseWater
                dataDam={dataDam}
                language={i18n.language}
              />
            )}
          </Fragment>
          <div className={classes.layout}>
            <Modal
              open={modalData.open}
              modalHeader={modalData.header}
              modalContent={modalData.content}
              resetModal={this.resetModal}
            />
          </div>
          <ImageSituation dataPopupReport={dataPopupReport} />
        </DashboardContext.Provider>
      </div >
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }), withTranslation())(Dashboard);
