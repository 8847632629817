/**
 * *
 * @version 1.0.2
 * @author [phithakkhet@hii.or.th]
 */

import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

// table .
import InputBase from '@material-ui/core/InputBase';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tab from '@material-ui/core/Tab';
import { Divider } from 'material-ui';

export const initPath = process.env.MIX_APP_PATH;

export const lineBox = grey[300];

export const BootstrapInput = withStyles(theme => ({
  root: {
    height: 41,
    'label + &': {
      marginTop: theme.spacing(3),
    },

  },
  input: { 
    position: 'relative',
    // backgroundColor: theme.palette.background.paper,
    width: 'auto',
    // padding: '4px 0 5px',
    fontFamily: 'Kanit',
    fontSize: 14,
    margin: 'auto',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: grey[100],
    },
  },
}))(TableRow);

export const StyledTableCell = withStyles(theme => ({
  root: {
    color: grey[900],
  },
  head: {
    backgroundColor: grey[300],
    // color: theme.palette.common.white,
    // fontWeight: 800,
    fontSize: 14,
    height: theme.spacing(2),
  },
  body: {
    fontSize: 12,
    color: grey[600],
    height: theme.spacing(8),
  },
  footer: {
    color: grey[900],
    fontWeight: 500,
    fontSize: 14,
    height: theme.spacing(2),
    borderBottom: '2px solid #999',
  },

}))(TableCell);

export const StyledFootTableCell = withStyles(theme => ({
  footer: {
    color: grey[900],
    fontWeight: 500,
    fontSize: 14,
    height: theme.spacing(2),
    borderBottom: '2px solid #999',
    backgroundColor: grey[400],
  },

}))(TableCell);

export const TabStyle = withStyles(theme => ({
  // comment for warning  the key `overrides` provided to the classes prop
  // is not implemented in ForwardRef(Tab).
  overrides: {
    MuiExpansionPanelSummary: {
      expended: {
        minHeight: 48,
      },
    },
  },
}))(Tab);



export const styles = theme => ({
  // overrides: {
  //   MuiSvgIcon: {
  //     root: {
  //       '& .MuiSvgIcon-root-76': {
  //         fontSize: 53,
  //       },
  //     }
  //   }
  // }
  // overrides: {
  //   MuiExpansionPanelSummary: {
  //     expended: {
  //       minHeight: 48,
  //     },
  //   },
  // MuiTypography: {
  //   h6: {
  //     fontSize: '2rem',
  //     fontFamily: 'Kanit',
  //     // [theme.breakpoints.down('sm')]: {
  //     //   fontSize: '1rem',
  //     // },
  //   },
  // },
  // },
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  content: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  layout: {
    justifyContent: 'space-around',
    width: 'auto',
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      width: 1160,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  layoutSearch: {
    justifyContent: 'space-around',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
  },
  layoutWater: {
    width: '100%',
    left: 0,
    right: 0,
    zIndex: -1,
    backgroundColor: grey[100],
  },
  layoutGrey: {
    backgroundColor: grey[100],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  layoutColor: {
    backgroundColor: grey[200],
    // padding: theme.spacing.unit * 2,
    backgroundSize: 'cover',
    width: '100%',
    // height: '400px',
    align: 'center',
  },
  listItemText: {
    fontSize: '1em',
  },
  circularProgress: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  bgSearch: {
    background: `url( ${initPath}images/theme/banner/bg-search.jpg) center center`, // ok short
    // padding: theme.spacing.unit * 2,
    backgroundSize: 'cover',
    width: '100%',
    align: 'center',

    
    marginTop: '73px',
    marginLeft: 0,
    marginRight: 0,
    zIndex: 1200,
  },
  rootSearchHeader: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 48,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    fontFamily: 'Kanit',
    height: 73,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  toolbar: {
    display: 'flex',
    align: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    // ...theme.mixins.toolbar,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  MenuItem: {
    fontFamily: 'Kanit',
    fontSize: 16,
    color: grey[900],
  },
  MuiTab: {
    fontFamily: 'Kanit',
    height: 80,
    fontSize: 16,
    borderLeft: '1px solid #ccc',
    paddingTop: 0,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  nameSiteTH: {
    color: theme.palette.primary.main,
  },
  nameSiteEN: {
    color: theme.palette.secondary.main,
  },
  HiiThaiwaterIcon: {
    fontSize: 150,
  },
  chart: {
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
    },

  },
  // typography: { useNextVariants: true },
  rootSearch: {
    border: '1px solid #ced4da',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // height: 48,
    height: 41,
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    color: 'white',
    fontFamily: 'Kanit',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    marginLeft: '-4px',
    height: '48px',
  },
  // button: {
  //   margin: theme.spacing.unit,
  // },
  buttonDownload: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    color: grey[900],
    border: '1px solid #ced4da',
    backgroundColor: 'white',
    align: 'center',
    alignItems: 'center',
    marginBottom: -22,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 500,
    fontFamily: 'Kanit',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      textDecoration: 'none',
    },
  },
  responsive: {
    width: '100%',
  },
  responsiveAbout: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  paperWeather: {
    padding: theme.spacing(1),
    background: theme.palette.background.blue,
    borderBottom: '5px',
    height: 400,
  },
  paperGrid: {
    padding: `${theme.spacing(2)}px 0`,
  },
  paperModal: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  inline: {
    display: 'inline',
  },
  margin: {
    margin: 0,
  },
  // fontBaseWeight: {
  //   fontSize: 12,
  //   color: grey[900],
  //   fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  // },
  iconButton: {
    marginRight: 8,
  },
  input: {
    margin: theme.spacing(1),
    fontFamily: 'Kanit',
  },
  inputSearch: {
    marginLeft: 8,
    flex: 1,
    fontFamily: 'Kanit',
  },
  title: {
    fontFamily: 'Kanit',
    fontWeight: 500,
  },
  iconRight: {
    paddingTop: 0,
    right: 0,
    position: 'absolute',
  },
  fontTitle: {
    padding: theme.spacing(1),
    fontFamily: 'Kanit',
  },
  boxBgGrey: {
    padding: '3px 7px',
    backgroundColor: grey[300],
    borderRadius: '5px',
  },
  boxBgPrimary: {
    padding: '3px 7px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    color: 'white',
  },
  link: {
    margin: theme.spacing(1),
  },
  lableColor: {
    fontSize: 12,
    color: 'white',
    height: 30,
    align: 'center',
    padding: '0 40px',
  },
  outLinedRadius: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontSize: 16,
    padding: 0,
  },
  outLinedRadiusForm: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontSize: 16,
    padding: 0,
    height: 41,
  },
  outLinedSelect: {
    fontSize: 16,
    padding: '0px 9px',
  },
  linkInline: {
    marginLeft: 5,
    fontSize: 11,
    padding: 0,
    color: grey[900],
    fontWeight: 700,
  },
  fontBase: {
    fontSize: 14,
    color: grey[600],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  },
  fontBaseWeight: {
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
    color: grey[900],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  },
  fontBaseWeightAbout: {
    fontWeight: 500,
    fontSize: 14,
    color: grey[900],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  },
  fontWeight: {
    fontWeight: 500,
  },
  outLined: {
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '0px',
  },
  outLinedAbout: {
    border: '1px solid #ced4da',
    padding: theme.spacing(2),
  },
  outLinedReport: {
    border: '1px solid #ced4da',
    fontSize: 16,
  },
  customBoxImage: {
    zIndex: 999,
    backgroundColor: 'white',
  },
  customBoxContent: {
    border: '1px solid #ccc',
    zIndex: 100,
  },
  boxTitle: {
    fontSize: '16px',
    color: grey[900],
  },
  boxImages: {
    marginLeft: -10,
    marginTop: -47,
    zIndex: 999,
    backgroundColor: 'white',
  },
  boxDownload: {
    borderLeft: '1px solid #ddd',
    paddingTop: 20,
  },
  boxContent: {
    fontSize: '14px',
    color: grey[600],
  },
  tabsIndicator: {
    backgroundColor: 'primary',
  },
  table: {
    // minWidth: 650,
    width: '100%',
  },
  tableMore: {
    backgroundColor: '#ddd',
    fontFamily: 'Kanit',
    fontWeight: 500,
    color: '#555',
    height: 20,
  },
  formControl: {
    root: {
      width: '100%',
    },
    width: '100%',
  },
  regend: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    // color: 'white',
    // marginRight: 10,
    fullWidth: '100%',
    // width: '100%',
    // justifyContent: 'center',
  },
  regendDam: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    // marginRight: 10,
    fullWidth: '100%',
    // width: '100%',
    // justifyContent: 'center',
  },
  regendLevel: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    // marginRight: 10,
    fullWidth: '100%',
    // width: '100%',
    // justifyContent: 'center',
  },
  regendOcean: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    // marginRight: 10,
    fullWidth: '100%',
    // width: '100%',
    // justifyContent: 'center',
  },
  regendQuality: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    // marginRight: 10,
    fullWidth: '100%',
    // width: '100%',
    // justifyContent: 'center',
  },
  heading: {
    backgroundColor: '#428FCA',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  widget: {
    position: 'fixed',
    border: '1px solid #ced4da',
    borderRadius: 8,
    margin: theme.spacing(3),
    align: 'center',
    backgroundColor: 'white',
    zIndex: 1201,
  },
  inactive: {
    backgroundColor: 'white!important',
    textDecoration: 'none',
    fontSize: 12,
    color: '#000',
    width: '100%',
  },
  active: {
    width: '100%',
    color: 'white',
    backgroundColor: '#ff0000!important',
    textDecoration: 'none',
    fontSize: 12,
  },

  box: {
    border: '1px solid #ccc',
    borderRadius: 6,
  },
  boxSqua: {
    border: '1px solid #ccc',
  },
  boxTopRadius: {
    border: '1px solid #ccc',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: 'white',
  },
  boxBottomRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
  },
  boxBottomRadiusNoPadding: {
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
  },

  button: {
    // margin: theme.spacing.unit * 1,
    color: grey[900],
    // margin: theme.spacing.unit,
    fontFamily: 'Kanit',
    fontWeight: 400,
  },
  // button: {
  //   margin: theme.spacing.unit,
  // },
  buttonIcon: {
    margin: theme.spacing(1),
    fontFamily: 'Kanit',
    fontWeight: 400,
  },
  buttonStorm: {
    fontSize: 10,
    margin: 2,
    padding: '0px 5px',
  },
  buttonWidget: {
    padding: 0,
    fontSize: 10,
    marginLeft: 4,
    width: 83,
    fontFamily: 'Kanit',
  },
  borderSituation: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontFamily: 'Kanit',
  },
  borderLeft: {
    borderLeft: '1px solid #ced4da',
    [theme.breakpoints.down('sm')]: {
      borderLeft: '0px solid #ced4da',
    },
  },
  boxLink: {
    width: '100%',
    height: 120,
    // padding: theme.spacing.unit * 2,
    border: '1px solid #ccc',
  },
  iconButtonText: {
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
  },
  iconButtonTextSearch: {
    width: '100%',
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: grey[300],
  },
  titleBar: {
    color: 'white',
    fontFamily: 'Kanit',
    fontSize: 15,
    fontWeight: 500,
    padding: theme.spacing(1),
  },
  titleBarList: {
    borderTopLeftRadius: 6,
    border: '1px solid #ccc',
  },
  titleName: {
    fontFamily: 'Kanit',
    fontSize: 15,
    fontWeight: 500,
    padding: theme.spacing(1),
  },
  titleSub: {
    color: grey[600],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
  },
  titleSubList: {
    color: grey[600],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
    align: 'center',
    margin: theme.spacing(2),
  },
  titleSubSea: {
    color: grey[600],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
    align: 'center',
    margin: theme.spacing(2),
  },
  bgPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  boxTop: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
  },
  boxSquaHead: {
    backgroundColor: grey[300],
    fontFamily: 'Kanit',
    fontSize: 15,
    fontWeight: 500,
  },
  boxRadius: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: grey[300],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 16,
    padding: theme.spacing(1),
  },
  boxSearchLayer: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    fontFamily: 'Kanit',
    fontWeight: 500,
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    fontSize: 12,
  },
  boxRadiusForecast: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: grey[300],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 16,
  },
  titleBarSelect: {
    fontFamily: 'Kanit',
    fontSize: 14,
    fontWeight: 500,
    height: 47,
    border: '1px solid #ccc',
  },
  titleBarSelectClimate: {
    backgroundColor: 'white',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  titleBarSelectClimateBg: {
    // backgroundColor: grey[300],
  },
  titleBarSelectFix: {
    fontFamily: 'Kanit',
    fontSize: 14,
    fontWeight: 500,
    height: 50,
    border: '1px solid #ccc',
  },
  statusNull: {
    backgroundColor: '#aaa',
  },
  statuslight: {
    backgroundColor: '#AED4FF',
  },
  statusMedium: {
    backgroundColor: '#67CA00',
  },
  statusHeavy: {
    backgroundColor: '#EC2F04',
  },
  status: {
    padding: '0px 10px',
    borderRadius: 4,
  },
  normal: {
    backgroundColor: '#931910',
    color: 'white',
  },
  stay: {
    backgroundColor: '#EF8000',
    color: 'white',
  },
  critical: {
    backgroundColor: '#EC2F00',
    color: 'white',
  },
  severe: {
    backgroundColor: '#931910',
    color: 'white',
  },
  boxLinkSea: {
    width: '100%',
    height: 120,
    padding: theme.spacing(2),
    border: '1px solid #ccc',
  },
  titleBarSelectSea: {
    fontFamily: 'Kanit',
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: grey[300],
    height: 53,
  },
  boxImg: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
  },
  btnSubmit: {
    width: '100%',
    backgroundColor: grey[300],
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontWeight: 500,
    align: 'center',
    fontFamily: 'Kanit',
    fontSize: 16,
    height: 41,
  },
  formControlSearch: {
    width: '100%',
    border: '1px solid #ced4da',
    borderRadius: 5,
    padding: '3px 14px',
    align: 'center',
    margin: 'auto',
  },
  // rootSearch: {
  //   border: '1px solid #ced4da',
  //   padding: '2px 12px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   height: 39,
  //   fontFamily: 'Kanit',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     width: '100%',
  //   },
  //   [theme.breakpoints.up('lg')]: {
  //     width: '100%',
  //   },
  // },
  rootSearchDam: {
    border: '1px solid #ced4da',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 32,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  // wrapper: {
  //   width: '100%',
  //   display: 'inline-flex',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  // },
});

export const getModalStyle = () => {
  const top = 20;
  const left = 25;

  return {
    top: `${top}%`,
    left: `${left}%`,
    margin: 'auto',
    width: '50%',
  };
};
