import React from 'react';
import WaterlevelTab from '../WaterlevelTab';

const WaterlevelTabSit = () => (
  <div>
    <WaterlevelTab />
  </div>
);

export default WaterlevelTabSit;
