import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './Style';

export default function RainForecastMonthNavigation(props) {
  const { t } = useTranslation();
  const { handlerLayer } = props;
  const [layerRainfall, setLayerRainfall] = useState(true);
  const [layerDam, setLayerDam] = useState(true);

  const handleChangeBox = event => {
    handlerLayer(event.target.name, event.target.checked);

    if (event.target.name === 'layerDam') {
      setLayerDam(event.target.checked);
    } else if (event.target.name === 'layerRainfall') {
      setLayerRainfall(event.target.checked);
    }
  };

  return (
    <>
      <Paper style={styles.menuControlStyle}>
        <FormControl component="fieldset">
          <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={layerRainfall} onChange={handleChangeBox} name="layerRainfall" />}
                  label={t('สถานีฝน')}
                />
                <FormControlLabel
                  control={<Checkbox checked={layerDam} onChange={handleChangeBox} name="layerDam" />}
                  label={t('สถานีเขื่อน')}
                />
              </FormGroup>
            </FormControl>
          </div>
        </FormControl>
      </Paper>
    </>
  );
}

RainForecastMonthNavigation.propTypes = {
  handlerLayer: PropTypes.func.isRequired,
};
