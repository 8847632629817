import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId, orderBy } from 'lodash';
// import * as ReactDOMServer from 'react-dom/server';
import { withTranslation } from 'react-i18next';

// material ui
import { withStyles } from '@material-ui/core/styles';
import { Tooltip as TooltipMui } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Popover from '@material-ui/core/Popover';
import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';

// map
import L from 'leaflet';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  GeoJSON,
  LayerGroup,
  LayersControl,
  Tooltip,
  WMSTileLayer,
} from 'react-leaflet';
import Hidden from '@material-ui/core/Hidden';
import Control from 'react-leaflet-control';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { CircularProgressbar } from 'react-circular-progressbar';
import moment from 'moment';

// icon
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import LayersIcon from '@material-ui/icons/Layers';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import { PlottyGeotiffLayer } from './GeotiffLayer';

// legend
import CanalLegend from './legend/CanalLegend';
import WaterLevelLegend from './legend/WaterLevelLegend';
import FloodLegend from './legend/FloodLegend';
import LegendSymbol from './LegendSymbol';

import { styles } from '../../../styles/StyleFloodplain';
import { FaChartLine } from '../../../utils/Fontawesome';

import {
  HiiEyeOutlineIcon, HiiWaterLevel2xIcon, HiiForecastWaterIcon,
} from '../../../utils/Icon';

// water level
import WlStatusColor from '../../wl/WlStatusColor';
import WlContext from '../../../contexts/wlContext';
import WlChart from '../../wl/WlChart';

import iconImage from '../../widgets/map/iconImage';
import FloodPlainForecastChart from './FloodPlainForecastChart';
import iconCircle from '../../widgets/map/iconCircle';
import tJson from '../../../utils/TJson';
import WaterGateChart from '../../water_gate/WaterGateChart';

import AboutContent from './about/AboutContent';

// component
import CompBoundarySelect from './compSelect/CompBoundarySelect';
import CompWaterSelect from './compSelect/CompWaterSelect';
import CompForecastSelect from './compSelect/CompForecastSelect';
import CompLayer from './CompLayer';

// css
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;
const { BaseLayer, Overlay } = LayersControl;

const banner = `${initPath}images/models/banner_floodplan_forecasting.png`;

const styleFloodForecas = {
  popUp: {
    textAlign: 'center',
    position: 'fixed',
    width: '10px',
  },
};

const cpyFloodPlainOptions = {
  name: 'CPY',
  band: 0,
  opacity: 0.5, // not work
  displayMin: 0,
  displayMax: 20,
  colorScale: 'cpyFloodPlainScale',
  clampLow: false,
  clampHigh: true,
};

const StyleSwitch = withStyles({
  switchBase: {
    color: '#ccc',
    '&$checked': {
      color: '#009688',
    },
    '&$checked + $track': {
      backgroundColor: '#009688',
    },
  },
  checked: {},
  track: {},
})(Switch);

class FloodPlainForecastMap extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.refBoundaryFloodplain = createRef();
    this.layer = createRef();
    this.layer1 = createRef();
    this.waterGateLayer = createRef();
    this.waterGateLayerBma = createRef();

    this.state = {
      error: '',
      geojsonBasin: [],
      geojsonProvince: [],
      geojsonFloodPlain: [],
      hover: false,
      openLegendCPY: false,
      openLegendSymbol: false,
      textCPY: '',
      textESAN: '',
      anchorEl: null,
      // open: false,
      openInfo: false,
      openLayer: false,
      openBoundaryComp: false,
      openWaterComp: false,
      openForecastComp: false,
      checked: true, // legend -show, -hidden

      waterLevelMapChecked: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleMapChecked = this.handleMapChecked.bind(this);

    this.handleLayerClick = this.handleLayerClick.bind(this);
    this.handleBoundaryClick = this.handleBoundaryClick.bind(this);
    this.handleWaterClick = this.handleWaterClick.bind(this);
    this.handleForecastClick = this.handleForecastClick.bind(this);

    this.getGeoJson = this.getGeoJson.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.highlightFeature = this.highlightFeature.bind(this);
    this.resetHighlight = this.resetHighlight.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleBoxToggle = this.handleBoxToggle.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getFloodplainStyle = this.getFloodplainStyle.bind(this);
    this.getInitDateData = this.getInitDateData.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;
    this.getGeoJson();
    this.getInitDateData();
    this._mounted = true;
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '15%';
    this.map = this.refMap.current.leafletElement;
    handleSetMap(this.map);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleModalClick(event) {
    this.setState({
      openInfo: Boolean(event.currentTarget),
    });
  }

  handleLayerClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openLayer: Boolean(event.currentTarget),
    });
  }

  handleBoundaryClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openBoundaryComp: Boolean(event.currentTarget),
    });
  }

  handleWaterClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openWaterComp: Boolean(event.currentTarget),
    });
  }

  handleForecastClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openForecastComp: Boolean(event.currentTarget),
    });
  }

  handleClose(event) {
    this.setState({
      anchorEl: event.currentTarget,
      // open: false,
      openLayer: false,
      openInfo: false,
      openBoundaryComp: false,
      openWaterComp: false,
      openForecastComp: false,
    });
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      // open: Boolean(event.currentTarget),
    });
  }

  handleChange = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleMapChecked(name, value) {
    this.setState({ [name]: value });
    this.handleMenuPopupClose();
  }

  handleMouseOut() {
    this.setState({ hover: false });
  }

  handleMenuPopupClose() {
    this.setState({
      openInfo: false,
      openWaterComp: false,
      openForecastComp: false,
      openBoundaryComp: false,
      openLayer: false,
    });
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = '80%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '800px';
      heightChart = '65%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#061e2c',
      padding: '20px',
      position: 'absolute',
      zIndex: 999999,
    };
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  getGeoJson() {
    Promise.all([
      fetch('json/boundary/basin.json'),
      fetch('json/boundary/thailand.json'),
      fetch('json/boundary/floodplain.json'),
    ])
      .then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
      .then(([basin, thailand, floodplain]) => {
        this.setState({
          geojsonBasin: basin,
          geojsonProvince: thailand,
          geojsonFloodPlain: floodplain,
        });
      })
      .catch(error => this.setState({ error }));
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle = () => ({
    stroke: true,
    color: 'black',
    weight: 1,
    fillColor: 'rgba(124,240,10,0.5)',
    opacity: 0.5,
    fillOpacity: 0.1,
  })

  getInitDateData() {
    Promise.all([
      fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/floodmap/CPY_MAP_latest.txt`),
      fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/floodmap/ESAN_MAP_latest.txt`),
    ])
      .then(([res1, res2]) => Promise.all([res1.text(), res2.text()]))
      .then(([textCPY, textESAN]) => {
        this.setState({ textCPY, textESAN });
      })
      .catch(error => this.setState({ error }));
  }

  getProvinceStyle = () => ({
    stroke: true,
    color: 'black',
    weight: 1,
    fillColor: 'rgba(124,240,10,0.5)',
    opacity: 0.5,
    fillOpacity: 0.1,
  });

  getFloodplainStyle = () => ({
    stroke: true,
    color: '#b71c1c',
    weight: 2,
    fillColor: '#ffcdd2',
    opacity: 0.9,
    fillOpacity: 0.4,
  });

  handleRainfallChart = (id, type, teleStationData, teleStationThis) => () => {
    const {
      handleModalOpen, t,
    } = this.props;
    const params = {
      header: t('กราฟคาดการณ์น้ำท่า-ระดับน้ำในแม่น้ำสายหลัก'),
      content: <FloodPlainForecastChart
        name={id}
        type={type}
        teleStation={teleStationData}
        teleStationThis={teleStationThis}
      />,
    };
    handleModalOpen(params);
  }

  eachData = (data, i) => {
    const {
      zoom, datas, classes, t,
    } = this.props;

    if (data[3] && data[4] != null) {
      // marker position

      const position = [parseFloat(data[3]), parseFloat(data[4])];

      // marker icons
      const icon = iconImage.waterLevel;

      const location = '';

      return (
        <Marker
          id={data.code}
          key={i}
          position={position}
          icon={icon}
          onClick={this.handleRainfallChart(data[0], 'wl', datas, data)}
          ref={this.bindMarker(data[0])}
          location={location}
          zoom={zoom}
          classes={classes}
        >
          <Hidden smDown>
            <Tooltip title={`${data[0]} ${t('สถานี')} ${data[2]}`}>
              <span>
                <strong>
                  {`${data[0]} ${t('สถานี')} ${data[2]}`}
                </strong>
                <br />
                {`${data[11]} ${data[12]} ${data[13]}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  eachDataDischarge = (data, i) => {
    const { zoom, classes, datasDischarge } = this.props;

    if (data[3] && data[4] != null) {
      // marker position
      const position = [parseFloat(data[3]), parseFloat(data[4])];

      // marker icons
      const icon = iconImage.WlDischarge;

      const location = '';

      return (
        <Marker
          id={data.code}
          key={i}
          position={position}
          icon={icon}
          onClick={this.handleRainfallChart(data[0], 'discharge', datasDischarge, data)}
          ref={this.bindMarker(data[0])}
          location={location}
          zoom={zoom}
          classes={classes}
        >
          <Hidden smDown>
            <Tooltip title={`${data[0]} สถานี ${data[2]}`}>
              <span>
                <strong>
                  {`${data[0]} สถานี ${data[2]}`}
                </strong>
                <br />
                {`${data[11]} ${data[12]} ${data[13]}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  handleBoxToggle = () => this.setState({ hover: true });

  handleClickLegendCPY = () => {
    const { openLegendCPY } = this.state;
    if (openLegendCPY === false) {
      this.setState({ openLegendCPY: true });
    } else {
      this.setState({ openLegendCPY: false });
    }
  };

  handleClickLegendSymbol = () => {
    const { openLegendSymbol } = this.state;
    if (openLegendSymbol === false) {
      this.setState({ openLegendSymbol: true });
    } else {
      this.setState({ openLegendSymbol: false });
    }
  };

  /*
   * layer interaction, mouseover, mouseout, show tooltip, change color etc
   */
  highlightFeature = e => {
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 0,
      stroke: true,
      weight: 2,
      color: deepOrange[700],
    });

    // layer.bringToFront();
  }

  resetHighlight = e => {
    this.refBoundaryBasin.current.leafletElement.resetStyle(e.target);
  }

  resetHighlightFloodplain = e => {
    this.refBoundaryFloodplain.current.leafletElement.resetStyle(e.target);
  }

  onEachBasinFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    layer.bindTooltip(`<h4>ลุ่มน้ำ${layer.feature.properties.BASIN_T}</h4>`).openTooltip();
  }

  onEachProvinceFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });
    const popupContent = `<h4>จังหวัด${layer.feature.properties.title}</h4>`;
    layer.bindTooltip(popupContent);
  };

  // Popup = feature => (
  //   <div>
  //     <strong>{feature.properties.title}</strong>
  //     <br />
  //     <strong>พื้นที่</strong> ${feature.properties.AREA_CAPA}
  //     <br />
  //     <strong>ความจุ</strong> ${feature.properties.CAPA}
  //   </div>
  // );

  onEachFloodPlainFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlightFloodplain,
      // click: this.map.openPopup(),
    });

    // const popupContent = ReactDOMServer.renderToString(
    //   <Popup feature={feature} />,
    // );

    const popupContent = `
      <strong>${layer.feature.properties.title}</strong>
      <strong>พื้นที่</strong> ${layer.feature.properties.AREA_CAPA}<br>
      <strong>ความจุ</strong> ${layer.feature.properties.CAPA}
    `;

    const tooltipContent = `<strong>${layer.feature.properties.title}</strong>
      <br />
      พื้นที่ ${layer.feature.properties.AREA_CAPA}
      <br />
      ความจุ ${layer.feature.properties.CAPA}`;

    layer.bindPopup(popupContent);
    layer.bindTooltip(tooltipContent);

    // layer.addEventListener('click', () => {
    //   console.log('show popup');
    //   layer.bindPopup(popupContent).openPopup();
    // });
  };
  /*
   * END layer interaction, mouseover, mouseout, show tooltip, change color etc
   */

  /*
   * Start Water Level
   */
  // format Wl number
  formatWl = value => (typeof value === 'undefined' || value == null ? '-' : parseFloat(value).toFixed(2))

  // set marker icon for watelevel
  setWlIcon = val => {
    const status = WlStatusColor(val);

    return status.icon;
  }

  handleWaterLevelChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: `${t('กราฟระดับน้ำ')}`,
      content: <WlChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  eachWlData = (data, i) => {
    const {
      location, zoom, datas, classes, t,
    } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.waterlevel_msl != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long)];

      // marker icons
      const icon = this.setWlIcon(parseFloat(data.storage_percent));

      // status colors
      const statusColor = WlStatusColor(data.storage_percent);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `${tJson(data.geocode.province_name, 'จ.')}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
          zIndexOffset={i}
        >
          {/* ระดับน้ำ (ม.รทก.) */}
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={3} align="center">
                      <Typography variant="subtitle2" className={classes.popupTitle}>
                        {tJson(data.station.tele_station_name, 'สถานี')}
                      </Typography>
                      <Typography variant="caption" className={classes.popupSubtitle}>
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <div style={{ width: '100px', position: 'relative', margin: '0 auto' }}>
                        <CircularProgressbar
                          value={parseInt(data.storage_percent, 10)}
                          text={`${Number.isNaN(parseInt(data.storage_percent, 10))
                            ? 'n/a'
                            : parseInt(data.storage_percent, 10)}%`}
                          styles={{
                            text: {
                              fill: '#000000',
                              fontSize: '2em',
                            },
                            path: {
                              stroke: statusColor.color.statusColor,
                            },
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList]}><strong>{t('ระดับน้ำ')}</strong></TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValue]} align="right">
                      {this.formatWl(data.waterlevel_msl)}
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupUnit]} align="right">
                      ม.รทก.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList]}><strong>{t('ระดับตลิ่ง')}</strong></TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValue]} align="right">
                      {this.formatWl(data.station.min_bank)}
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupUnit]} align="right">
                      ม.รทก.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList]}><strong>{t('ระดับท้องน้ำ')}</strong></TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValue]} align="right">
                      {this.formatWl(data.station.ground_level)}
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupUnit]} align="right">
                      ม.รทก.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={2}>
                      <Typography variant="caption" color="secondary" className={classes.popupRef}>
                        {`ข้อมูล: ${t(data.agency.agency_name.th)}`}
                      </Typography>
                      <Box>
                        <Typography variant="caption" color="secondary" className={classes.popupDate}>
                        {'ล่าสุด: '}{data.waterlevel_datetime}
                          {' '}
                          {'น.          '}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <WlContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleWaterLevelChart(handleModalOpen, data)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </WlContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={tJson(data.station.tele_station_name, 'สถานี')}>
              <span>
                <strong>
                  {tJson(data.station.tele_station_name, 'สถานี')}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }
  /*
   * END Water Level
   */

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: t('กราฟระดับน้ำที่ปตร./ฝาย'),
      content: <WaterGateChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }

  eachDataWaterGate = (data, i) => {
    const {
      zoom,
      datas,
      classes,
      t,
    } = this.props;
    if (data.station.tele_station_lat && data.station.tele_station_long != null) {
      // marker position
      const position = [
        parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long),
      ];
      // marker icons
      let icon = iconCircle.gold;
      if (data.watergate_datetime_in < moment().format('YYYY-MM-DD')) {
        icon = iconCircle.grey;
      }
      let location = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        location = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        location += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        location += `${tJson(data.geocode.province_name, 'จ.')}`;
      }
      const station = (tJson(data.station.tele_station_name, 'สถานี')).split(' ');
      let stationList = tJson(data.station.tele_station_name, 'สถานี');
      if (station.length > 1 && station[0].search('สถานีโทรมาตร') > -1) {
        stationList = station[1];
      }
      if (stationList === '') {
        stationList = tJson(data.station.tele_station_name, 'สถานี');
      }
      return (
        <Marker
          id={data.station.id}
          key={i}
          position={position}
          icon={icon}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
        >
          <Popup autoPan={false}>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} className={classes.hiddenLine} padding="none" align="center">
                      <Typography variant="subtitle2" className={classes.popupTitle}>
                        {`${stationList}`}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.popupSubtitle}>
                        {location}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList]}>{t('ระดับน้ำเหนือ')}</TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValueBold]} align="right">
                      {this.formatWl(data.watergate_in)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList]}>{t('ระดับน้ำท้าย')}</TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValueBold]} align="right">
                      {this.formatWl(data.watergate_out)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary" className={classes.popupUnitGrey}>
                        {`${t('หน่วย')} : ${t('(ม.รทก.)')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary" className={classes.popupRef}>
                        {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name)}`}
                      </Typography>
                      <Box>
                        <Typography variant="caption" color="secondary" className={classes.popupDate}>
                          {'ล่าสุด: '}{data.watergate_datetime_in}
                          {' '}
                          {'น.        '}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <WlContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleChart(handleModalOpen, data)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </WlContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${stationList}`}>
              <span>
                <strong>
                  {`${stationList}`}
                </strong>
                <br />
                {location}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataWaterGateCanal = (data, i) => {
    const {
      location,
      zoom,
      dataWaterCanal,
      classes,
    } = this.props;

    if (data.station.canal_lat && data.station.canal_long) {
      // marker position
      const position = [
        parseFloat(data.station.canal_lat),
        parseFloat(data.station.canal_long),
      ];
      const iconGreenUp = new L.Icon({
        iconUrl: `${initPath}images/marker/triangle_new/green-up.png`,
        iconSize: [10, 10],
      });
      const icon = iconGreenUp;

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          location={location}
          zoom={zoom}
          datas={dataWaterCanal}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false} className={classes.bmaPopup}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLinePopup}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Box className={classes.popupTitle}>
                        {'สถานี '}
                        {data.station.canal_name.th}
                      </Box>
                      <Box className={classes.popupSubtitle}>
                        {locationText}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList]}>
                      ระดับน้ำ
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValueBold]} align="right">
                      {this.formatWl(data.canal_out)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="caption" color="secondary" className={classes.popupUnitGrey}>
                        หน่วย : (ม.รทก.)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLinePopup}>
                      <Box className={classes.popupRef}>
                        {`ข้อมูล : ${data.agency.agency_name.th}`}
                      </Box>
                      <Box className={classes.popupDate}>
                        {'ล่าสุด : '}
                        {data.canal_datetime}
                        {' '}
                        {'น.          '}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>

          {/* Template popup ขอบเขตน้ำทุ่ง */}
          {/* <Popup autoPan={false} className={classes.bmaPopup}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLinePopup}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Box mb={2} className={classes.popupTitle}>
                        {'ทุ่งโพธิ์พระยา'}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupListBody]}>
                      พื้นที่
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValueBold]} align="right">
                      {this.formatWl(data.canal_out)}
                      {' ไร่'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupListBody]}>
                      ความจุ
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValueBold]} align="right">
                      {this.formatWl(data.canal_out)}
                      {' ล้าน ลบ.ม.'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} align="right" colSpan={2}>
                      <WlContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleChart(handleModalOpen, data)}
                            title={'แสดงกราฟ'}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </WlContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup> */}

          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.canal_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.canal_name.th}`}</strong>
                <br />
                {`${data.geocode.tumbon_name.th} ${data.geocode.amphoe_name.th} `}
                <br />
                {`${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>

      );
    }
    return false;
  }

  isFullscreen = () => {
    const elem = document.documentElement;
    if (window.navigator.userAgent.includes('Chrome')) {
      if (!document.fullscreenElement) {
        elem.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else if (window.navigator.userAgent.includes('WebKit')) {
      if (!document.webkitFullscreenElement) {
        elem.webkitRequestFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } else if (window.navigator.userAgent.includes('Firefox')) {
      if (!document.mozFullScreenElement) {
        elem.mozRequestFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
    }
  }

  zoomIn() {
    this.map.zoomIn();
  }

  zoomOut() {
    this.map.zoomOut();
  }

  render() {
    const {
      error,
      hover,
      geojsonBasin,
      openLegendCPY,
      textCPY,
      textESAN,
      geojsonProvince,
      geojsonFloodPlain,
      openLegendSymbol,
      waterLevelMapChecked,
      anchorEl,
      openInfo,
      openLayer,
      openBoundaryComp,
      openWaterComp,
      openForecastComp,
      checked,
    } = this.state;
    const {
      location,
      zoom,
      datas,
      datasDischarge,
      t,
      datasWaterGate,
      dataWl,
      dataWaterCanal,
      classes,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <div className="text-center">{error.message}</div>
      );
    }

    const tooltipStyle = {
      display: hover ? 'block' : 'none',
      maxWidth: '337px',
      padding: '10px',
      wordBreak: 'break-all',
    };

    const cpyMapFloodPlain = `${process.env.MIX_FEWS_URL}/model-output/data_portal/floodmap/CPY_MAP_MAX.tif`;
    const esanMapFloodPlain = `${process.env.MIX_FEWS_URL}/model-output/data_portal/floodmap/ESAN_MAP_MAX.tif`;

    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          ref={this.refMap}
          style={{
            height: window.height,
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
          }}
        >
          {/* <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          /> */}
          <LayersControl position="topleft">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Esri_WorldStreetMap">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <BaseLayer name="Google Street">
              <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              />
            </BaseLayer>
            <BaseLayer name="Google Hybrid">
              <TileLayer
                url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              />
            </BaseLayer>
            <Overlay key="waterlevel" name="ระดับน้ำ" checked="true">
              <LayerGroup>
                {orderBy(dataWl.filter(d => d.storage_percent != null), ['storage_percent'], ['desc']).map(this.eachWlData)}
              </LayerGroup>
            </Overlay>
            <Overlay key="floodForecast1" name={t('ระดับน้ำ (ม.รทก.)')}>
              <LayerGroup ref={this.layer}>
                {datas.map(this.eachData)}
              </LayerGroup>
            </Overlay>
            <Overlay key="floodForecast2" name={t('น้ำท่า (ลบ.ม./ว)')}>
              <LayerGroup ref={this.layer1}>
                {datasDischarge.map(this.eachDataDischarge)}
              </LayerGroup>
            </Overlay>
            <Overlay key="watergate" name={t('ระดับน้ำปตร')} checked>
              <LayerGroup ref={this.waterGateLayer}>
                {datasWaterGate.map(this.eachDataWaterGate)}
              </LayerGroup>
            </Overlay>
            <Overlay key="watergateCanal" name={t('ระดับน้ำปตร กทม.')} checked>
              <LayerGroup ref={this.waterGateLayerBma}>
                {dataWaterCanal.map(this.eachDataWaterGateCanal)}
              </LayerGroup>
            </Overlay>
            <Overlay name={t('เส้นแม่น้ำ')}>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
            <Overlay key="boundary_basin" name={t('ขอบเขตลุ่มน้ำ')} checked>
              <LayerGroup>
                <GeoJSON
                  key={uniqueId()}
                  data={geojsonBasin}
                  style={this.getBasinStyle()}
                  ref={this.refBoundaryBasin}
                  onEachFeature={(feature, layer) => this.onEachBasinFeature(feature, layer)}
                />
              </LayerGroup>
            </Overlay>
            <Overlay key="boundary_province" name={t('ขอบเขตจังหวัด')}>
              <LayerGroup>
                <GeoJSON
                  key={uniqueId()}
                  data={geojsonProvince}
                  style={this.getProvinceStyle}
                  ref={this.refBoundaryProvince}
                  onEachFeature={this.onEachProvinceFeature}
                />
              </LayerGroup>
            </Overlay>
            <Overlay key="boundary_floodplain" name={t('ขอบเขตทุ่ง')}>
              <LayerGroup>
                <GeoJSON
                  key={uniqueId()}
                  data={geojsonFloodPlain}
                  style={this.getFloodplainStyle}
                  ref={this.refBoundaryFloodplain}
                  onEachFeature={this.onEachFloodPlainFeature}
                />
              </LayerGroup>
            </Overlay>
          </LayersControl>
          <PlottyGeotiffLayer
            layerRef={this.refGeotiff1}
            layerMap={this.refMap}
            url={cpyMapFloodPlain}
            options={cpyFloodPlainOptions}
            name="คาดการณ์น้ำท่วมสูงสุด ลุ่มเจ้าพระยา"
          />
          <PlottyGeotiffLayer
            layerRef={this.refGeotiff1}
            layerMap={this.refMap}
            url={esanMapFloodPlain}
            options={cpyFloodPlainOptions}
            name="คาดการณ์น้ำท่วมสูงสุด ภาคอีสาน"
          />
          <img src={banner} alt="banner" className={classes.banner} />
          {/* <Control position="topright">
            <div
              style={{ backgroundColor: 'white', padding: '8px', borderRadius: '9px' }}
            >
              <strong>Flood Plain Potential Index</strong>
              <br />
              {`CPY Lastest: ${textCPY}`}
              <br />
              {`ESAN Lastest: ${textESAN}`}
            </div>
          </Control> */}

          <Control position="topright">
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <Box>
                <TooltipMui title="แผนที่ฐาน" placement="left-start">
                  <Button
                    className={classes.iconBma}
                    id="btn"
                    onClick={this.handleLayerClick}
                  >
                    <LayersIcon size="small" />
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openLayer}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className={classes.bmaPophover}
                  style={{ zIndex: 999999 }}
                >
                  <CompLayer
                    handlBaseMapChecked={this.handlBaseMapChecked}
                    // basemapChecked={basemapChecked}
                  />
                </Popover>
              </Box>
            </Box>
            <Hidden smDown>
              <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
                <Box>
                  <TooltipMui title="เต็มหน้าจอ" placement="left-start">
                    <Button
                      className={classes.iconBma}
                      onClick={() => this.isFullscreen()}
                    >
                      <FullscreenIcon size="small" />
                    </Button>
                  </TooltipMui>
                </Box>
              </Box>
            </Hidden>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <Box>
                <TooltipMui title="แนะนำการใช้งาน" placement="left-start" style={{ zIndex: 0 }}>
                  <Button
                    className={classes.iconBma}
                    aria-describedby={this.id}
                    // onClick={this.handleClick}
                    onClick={this.handleModalClick}
                  >
                    <InfoIcon size="small" />
                  </Button>
                </TooltipMui>
                <Modal
                  id="modal-chart"
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={openInfo}
                  onClose={this.handleClose}
                  style={{ align: 'center', justifyContent: 'center', zIndex: 999999 }}
                >
                  <div style={this.getModalStyle()}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={0}
                    >
                      <Grid item>
                        <IconButton onClick={this.handleClose} style={{ padding: 0 }}>
                          <CloseIcon fontSize="small" style={{ color: 'white' }} />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Typography variant="h5" id="modal-title">
                      {/* {modalHeader} */}
                    </Typography>

                    <Typography variant="subtitle1" id="simple-modal-description">
                      {/* {modalSubHeader} */}
                    </Typography>
                    <Box mt={{ xs: 0, sm: 3, zIndex: 99999 }}>
                      <AboutContent />
                    </Box>
                  </div>
                </Modal>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <TooltipMui title="ขยาย" placement="left-start">
                <Box>
                  <Button
                    className={classes.iconBma}
                    onClick={() => this.zoomIn()}
                    aria-label="zoom in"
                  >
                    <ZoomInIcon size="small" />
                  </Button>
                </Box>
              </TooltipMui>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <TooltipMui title="ย่อ" placement="left-start">
                <Box>
                  <Button
                    className={classes.iconBma}
                    onClick={() => this.zoomOut()}
                    aria-label="zoom out"
                  >
                    <ZoomOutIcon size="small" />
                  </Button>
                </Box>
              </TooltipMui>
            </Box>
          </Control>
          {/* Layer Data */}
          <Control position="topright" style={{ zIndex: '99999 !important' }}>
            <Box display="block" justifyContent="center" alignItems="center" className={classes.sidebarBmaGroup}>
              <Box>
                <TooltipMui title="ข้อมูลทางกายภาพ" placement="left-start" style={{ zIndex: 1 }}>
                  <Box>
                    <Button
                      className={classes.iconBma}
                      onClick={this.handleBoundaryClick}
                      aria-label="other data"
                    // onMouseOver={this.handleRiverClick}
                    // onClick={this.handleRadarBkk}
                    >
                      <HiiEyeOutlineIcon size="small" className={classes.sidebarIcon} />
                    </Button>
                  </Box>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openBoundaryComp}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  style={{ zIndex: 99999 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CompBoundarySelect
                    handleMapChecked={this.handleMapChecked}
                    waterLevelMapChecked={waterLevelMapChecked}
                  />
                </Popover>
              </Box>
              <Box>
                <TooltipMui title="ข้อมูลตรวจวัด" placement="left-start" style={{ zIndex: 1 }}>
                  <Button
                    className={classes.iconBma}
                    onClick={this.handleWaterClick}
                  >
                    <HiiWaterLevel2xIcon size="small" className={classes.sidebarIcon} />
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openWaterComp}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  style={{ zIndex: 99999 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CompWaterSelect
                    handletimeLineStatus={this.handletimeLineStatus}
                    handleMapChecked={this.handleMapChecked}
                    waterLevelMapChecked={waterLevelMapChecked}
                    // timelineStatus={timelineStatus}
                    handleMenuPopupClose={this.handleMenuPopupClose}
                  />
                </Popover>
              </Box>

              {/* ข้อมูลคาดการณ์ */}
              {/* <Box>
                <TooltipMui title="ข้อมูลคาดการณ์" placement="left-start" style={{ zIndex: 1 }}>
                  <Button
                    className={classes.iconBma}
                    onClick={this.handleForecastClick}
                  >
                    <HiiForecastWaterIcon size="small" className={classes.sidebarIcon} />
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openForecastComp}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  style={{ zIndex: 99999 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CompForecastSelect
                    handleMapChecked={this.handleMapChecked}
                    waterLevelMapChecked={waterLevelMapChecked}
                  />
                </Popover>
              </Box> */}
            </Box>
          </Control>

          <Control position="topleft">
            <div
              style={{
                backgroundColor: 'white', padding: '8px', borderRadius: '9px', width: '3em',
              }}
              onBlur={() => this.setState({ hover: true })}
              onFocus={() => this.setState({ hover: false })}
              onMouseEnter={this.handleBoxToggle}
              onMouseOut={this.handleMouseOut}
            >
              Info
            </div>
            <Paper>
              <div style={tooltipStyle}>
                {t('ระบบคาดการณ์น้ำท่วม พัฒนาด้วยแบบจำลองอุทกพลศาสตร์แบบ 1 มิติ แสดงผลคาดการณ์น้ำท่าและระดับน้ำณ สถานีตรวจวัดหลัก โดยคัดเลือกจากสถานีวัดน้ำท่าของกรมชลประทานและ สถานีวัดระดับน้ำของสถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน) ที่มีการรายงานข้อมูลปริมาณน้ำท่าหรือระดับน้ำแบบ real time ทั้งรายชั่วโมงหรือรายวัน ระบบทำการประมวลผลวันละ 1 ครั้ง โดยคาดการณ์น้ำท่าและระดับน้ำ 7 วันล่วงหน้า เพื่อนำไปใช้ในการสนับสนุนการตัดสินใจเพื่อบริหาร จัดการอุทกภัยได้ทันต่อเหตุการณ์และมีประสิทธิภาพ')}
              </div>
            </Paper>
          </Control>
          <Control position="bottomright">
            <Box>
              <Hidden smDown>
                <Fade in={checked}>
                  <Box className={classes.bmalagend}>
                    <Hidden xsDown>
                      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={0}>
                        <Grid item xs>
                          <CanalLegend />
                        </Grid>
                        <Grid item xs>
                          <WaterLevelLegend />
                        </Grid>
                        <Grid item xs>
                          <FloodLegend />
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Box>
                </Fade>
                <Box display="flex" className={classes.lebleLegend}>
                  <TooltipMui title="เกณฑ์" placement="left-start">
                    <StyleSwitch checked={checked} onChange={this.handleChange} inputProps={{ 'aria-label': 'Collapse' }} color="primary" />
                  </TooltipMui>
                  <Box pt={1} display="flex" justifyItems="center" onClick={this.handleChange} className={classes.bmaLegandLable}>เปิด/ปิด เกณฑ์สี</Box>
                </Box>
              </Hidden>
            </Box>

          </Control>
        </Map>
      </>
    );
  }
}

FloodPlainForecastMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  datasDischarge: PropTypes.array.isRequired,
  dataWl: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  dataWaterCanal: PropTypes.array.isRequired,
  datasWaterGate: PropTypes.array.isRequired,
};

export default compose(
  withWidth(), withStyles(styles), withTranslation(),
)(FloodPlainForecastMap);
