import React, { Component } from 'react';
import PropTypes from 'prop-types';

// style, icons
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import DamLargeList from './DamLargeList';
import DamMediumList from './DamMediumList';

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: '#006fb6',
  },
  active_tab: {
    color: 'white',
    backgroundColor: '#006fb6',
  },
});

class DamList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes, datasLargeDam, datasMediumDam, handleSearchMarker, damsMediumRidList, t,
    } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Paper square width="100%">
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.indicator }}
            variant="fullWidth"
          >
            <Tab
              label={t('อ่างขนาดใหญ่')}
              className={value === 0 ? classes.active_tab : ''}
            />
            <Tab
              label={t('อ่างขนาดกลาง')}
              className={value === 1 ? classes.active_tab : ''}
            />
          </Tabs>
        </Paper>
        {value === 0 && (
          <TabContainer>
            <DamLargeList
              datas={datasLargeDam}
              handleSearchMarker={handleSearchMarker}
            />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <DamMediumList
              datas={datasMediumDam}
              handleSearchMarker={handleSearchMarker}
              damsMediumRidList={damsMediumRidList}
            />
          </TabContainer>
        )}
      </div>
    );
  }
}

DamList.propTypes = {
  classes: PropTypes.object.isRequired,
  datasLargeDam: PropTypes.array.isRequired,
  datasMediumDam: PropTypes.array.isRequired,
  handleSearchMarker: PropTypes.func.isRequired,
  damsMediumRidList: PropTypes.array.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DamList);
