/**
 * *
 * @version 1.0.0
 * @author [Phithakkhet@hii.or.th]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

// icon
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import {
  BmaLogo,
} from '../../../utils/IconBma';

// img
const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    width: '100%',
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
    },
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    fontStyle: 'italic',
    alignItems: 'center',
  },
  subTitle: {
    fontFamily: 'sans-serif',
    fontWeight: 600,
    fontSize: '0.9em',
    margin: 0,
    alignItems: 'center',
  },
  subTitle2: {
    fontFamily: 'sans-serif',
    fontWeight: 600,
    fontSize: '1em',
    margin: 0,
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
  },
  body: {
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    color: 'white',
    margin: 0,
  },
  img: {
    width: '90%',
  },
  bmaLogo: {
    color: '#fff',
    width: 100,
    height: 70,
    marginTop: -30,
    [theme.breakpoints.only('sm')]: {
      marginTop: 0,
    },
  },
  li: {
    color: '#fff',
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    listStylePosition: 'outside',
    listStyle: 'disc',
    margin: 5,
    marginLeft: -23,
  },
}));

const CompInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box px={1}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="block">
              <Box><BmaLogo className={classes.bmaLogo} /></Box>
              <Box><img src={`${initPath}images/theme/bma/banner/about/bma-site.png`} className={classes.img} alt="BMA" /></Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" className={classes.title}>ระบบติดตามสถานการณ์น้ำกรุงเทพ</Typography>
            <p className={classes.subTitle2}>ระบบใช้ทำอะไร?</p>
            <p className={classes.body}>เป็นระบบที่ใช้ติดตามสถานการณ์ฝนตกหนักและบริเวณที่เกิดน้ำท่วมในพื้นที่กรุงเทพมหานคร แบบทันทีทันใด (Real time) โดยระบบสามารถแจ้งเตือนถนนที่คาดว่าจะเกิดน้ำท่วมซึ่งจะส่งผลต่อสภาพการจราจร รวมถึงสามารถติดตามแจ้งเตือนระดับน้ำในคลองที่คาดว่าน้ำจะล้นตลิ่ง ซึ่งจะช่วยให้ประชาชนสามารถติดตามเตรียมพร้อมรับมือกับสถานการณ์ได้ล่วงหน้า อีกทั้งช่วยให้เจ้าหน้าที่ใช้ประกอบการตัดสินใจในการเข้าแก้ไขปัญหาได้อย่างทันท่วงทีและตรงประเด็น เนื่องจากระบบสามารถแสดงรายละเอียดของปัญหา เช่น การแจ้งพิกัดตำแหน่งที่เกิดปัญหา ภาพแสดงปัญหาที่เกิดขึ้นจริง ณ ตอนนนั้น เป็นต้น</p>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" spacing={0}>
          <Grid item xs>
            <Box mt={2}><p className={classes.subTitle2}>ระบบประกอบด้วยอะไรบ้าง?</p></Box>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={6}>
            <List style={{ padding: 0 }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar style={{ minWidth: 35 }}>
                  <NotificationsActiveIcon size="small" style={{ color: '#E39D00' }} />
                </ListItemAvatar>
                <ListItemText
                  primary={(<p className={classes.subTitle}>ข้อมูลปริมาณน้ำฝนตรวจวัด</p>)}
                  secondary={(
                    // eslint-disable-next-line react/jsx-fragments
                    <React.Fragment>
                      <ul className={classes.ul}>
                        <li className={classes.li}>
                          ฝนตรวจวัดรายชั่วโมง
                        </li>
                        <li className={classes.li}>
                          ฝนตรวจวัดในอดีต สะสมราย 1 วัน 3 วัน 7 วัน 15 วัน และ 30 วัน
                        </li>
                        <li className={classes.li}>
                          ฝนตรวจวัดจากเรดาร์ตรวจอากาศแสดงความเข้ม ฝนราย 5 นาที
                        </li>
                      </ul>
                    </React.Fragment>
                  )}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List style={{ padding: 0 }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar style={{ minWidth: 35 }}>
                  <NotificationsActiveIcon size="small" style={{ color: '#E39D00' }} />
                </ListItemAvatar>
                <ListItemText
                  primary={(<p className={classes.subTitle}>ข้อมูลปริมาณน้ำฝนคาดการณ์ล่วงหน้า</p>)}
                  secondary={(
                    // eslint-disable-next-line react/jsx-fragments
                    <React.Fragment>
                      <ul className={classes.ul}>
                        <li className={classes.li}>
                          คาดการณ์ปริมาณน้ำฝนรายวัน ล่วงหน้า 3 วัน จากแบบลำลองสภาพอากาศ WRF-ROMS
                        </li>
                        <li className={classes.li}>
                          คาดการณ์ปริมาณน้ำฝนล่วงหน้า 1 ชั่วโมง จากระบบเรดาร์ตรวจอากาศ
                        </li>
                      </ul>
                    </React.Fragment>
                  )}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List style={{ padding: 0 }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar style={{ minWidth: 35 }}>
                  <NotificationsActiveIcon size="small" style={{ color: '#E39D00' }} />
                </ListItemAvatar>
                <ListItemText
                  primary={(<p className={classes.subTitle}>ข้อมูลสถานีตรวจวัดน้ำท่วมบนถนน</p>)}
                  secondary={(
                    // eslint-disable-next-line react/jsx-fragments
                    <React.Fragment>
                      <ul className={classes.ul}>
                        <li className={classes.li}>
                          ระดับน้ำท่วมถนน ตรวจวัดราย 5 นาที
                        </li>
                      </ul>
                    </React.Fragment>
                  )}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List style={{ padding: 0 }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar style={{ minWidth: 35 }}>
                  <NotificationsActiveIcon size="small" style={{ color: '#E39D00' }} />
                </ListItemAvatar>
                <ListItemText
                  primary={(<p className={classes.subTitle}>ข้อมูลสถานีตรวจวัดระดับน้ำในคลอง</p>)}
                  secondary={(
                    // eslint-disable-next-line react/jsx-fragments
                    <React.Fragment>
                      <ul className={classes.ul}>
                        <li className={classes.li}>
                          ระดับน้ำ ตรวจวัดทุก 5 นาที และ 10 นาที
                        </li>
                        <li className={classes.li}>
                          อัตราการไหล ตรวจวัดทุก 5 นาที
                        </li>
                      </ul>

                    </React.Fragment>
                  )}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs>
            <List style={{ padding: 0 }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar style={{ minWidth: 35 }}>
                  <NotificationsActiveIcon size="small" style={{ color: '#E39D00' }} />
                </ListItemAvatar>
                <ListItemText
                  primary={(<p className={classes.subTitle}>ข้อมูลการแจ้งเตือนเหตุการณ์</p>)}
                  secondary={(
                    // eslint-disable-next-line react/jsx-fragments
                    <React.Fragment>
                      <ul className={classes.ul}>
                        <li className={classes.li}>
                          การแจ้งเตือนเหตุการณ์จากทวิตเตอร์ ของหน่วยงาน สวพ. จส.100 สวพ ทางหลวง และสสน.
                        </li>
                        <li className={classes.li}>
                          การแจ้งเหตุการณ์จาก Taffy Fondue
                        </li>
                        <li className={classes.li}>
                          การแจ้งเตือนปริมาณฝนจากเรดาร์ของสำนักการระบายน้ำกรุงเทพมหานคร
                        </li>
                      </ul>
                    </React.Fragment>
                  )}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CompInfo;
