import React from 'react';

import {
  BrowserRouter as Router,
} from 'react-router-dom';

import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';

// icon
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

// style
import { styles } from './styleStormSurgeMap';
// import './style.scss';
// import './styles.css';


import MenuStormSidebar from './MenuStormSurgeSidebar';


const initPath = process.env.MIX_APP_PATH;
const useStyles = makeStyles(styles);

const StyleIconButton = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))((props) => <IconButton disableRipple {...props} />);

export default function Header() {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);
  const [open] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
    province: '10',
  });


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  return (
    <Router>
      <AppBar
        color="inherit"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ zIndex: 9999998 }}
      >
        <div className={classes.layout}>
          <Toolbar disableGutters>
            <Box style={{ width: '100%' }}>
              <Grid container direction="row" alignItems="center" style={{ width: '100%', marginTop: 3 }}>
                <Grid item xs={11} sm md="auto">
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Link href={`${initPath}storm_surge`}>
                        <img src={`${initPath}images/theme/storm_surge/hii-60.png`} alt="SeaLevel" className={classes.imgLogo} />
                      </Link>
                    </Box>
                    <Box>
                      <Link href={`${initPath}storm_surge`}>
                        <Typography noWrap className={classes.titleAppTH}>
                          ระบบคาดการณ์และเตือนภัยล่วงหน้าบริเวณอ่าวไทย
                        </Typography>
                        <Typography noWrap className={classes.titleAppEN}>
                          FORECASTING AND EARLY WARNING SYSTEM FOR THE GULF OF THAILAND
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1} sm={1} md>
                  <Box display="flex" justifyContent="flex-end">
                    {['right'].map((anchor) => (
                      <StyleIconButton
                        key="key"
                        color="inherit"
                        aria-label="open drawer"
                        // edge="end"
                        onClick={toggleDrawer(anchor, true)}
                        className={`${clsx(open && classes.hide)} ${classes.buttonMenu}`}
                      >
                        <MenuIcon id="navbar-drawer-button" fontSize="large" className={classes.expandIcon} />
                      </StyleIconButton>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </div>
      </AppBar>
      <main>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <div>
          {/* <Switch>
            {Routes().map((route) => ((route.private === true && route.private !== undefined) ? (
              <PrivateRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            )))}
            {GA.init('waterinfo') && <GA.RouteTracker />}
          </Switch> */}
          {/* <Switch>
            {Routes().map(route => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </Switch> */}
        </div>
      </main>

      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            style={{ zIndex: 9999999 }}
          >
            <div className={classes.drawerHeader}>
              <IconButton id="navbar-drawer-close-button" onClick={toggleDrawer(anchor, false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <MenuStormSidebar />
          </Drawer>
        </React.Fragment>
      ))}
    </Router>
  );
}
