import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;
const sizeIcon = 12;
const FrGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Floodroad/icon_floodroad32px-Green.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const FrYellow = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Floodroad/icon_floodroad32px-Yellow.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const FrPink = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Floodroad/icon_floodroad32px-pink.png`,
  iconSize: [sizeIcon, sizeIcon],
});


const frColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปกติ',
    rangeTitle: '>0-10',
    statusColor: '#00b050',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้ำท่วมขังเล็กน้อย',
    rangeTitle: '>10-20',
    statusColor: '#003cfa',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'น้ำท่วม',
    rangeTitle: '>20',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const FloodRoadStatusColor = fr => {
  let color;
  let icon;
  const [zero, one, two, three] = frColor;

  if (fr === null) {
    color = zero;
    icon = FrGreen;
  } else if (fr > 20) {
    color = three;
    icon = FrPink;
  } else if (fr > 10) {
    color = two;
    icon = FrYellow;
  } else {
    color = one;
    icon = FrGreen;
  }

  return { color, icon };
};

export default FloodRoadStatusColor;
