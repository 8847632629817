/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: 800,
    paddingLeft: '20px',
    paddingRight: '20px',
    zIndex: 99999,
    // backgroundColor: 'white',
    borderRadius: '5px',
    position: 'fixed',
    // left: '15%',
    color: 'white',
    bottom: '1%',

    [theme.breakpoints.down('xs')]: {
      width: '80%',
      bottom: '60px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
  },
  title: {
    color: 'white',
    fontFamily: 'Kanit',
    fontSize: '1.1em',
  },
  rail: {
    backgroundColor: 'white',
  },
  mark: {
    background: 'white',
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginLeft: '-6px',
    marginTop: '-5px',
  },
  markActive: {
    background: '#1976d2',
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginLeft: '-6px',
    marginTop: '-5px',
  },
  marked: {
    // paddingTop: 'none',
  },
  track: {
    background: '#1976d2',
  },
  markLabel: {
    transform: 'translateX(-100%)',
    '&[data-index="0"]': {
      transform: 'none',
    },
  },
}));

export default function CompRainFallGeoTiff(props) {
  const classes = useStyles();
  const [current, setCurrent] = useState(-1);
  const [labelGeotiff, setLabelGeotiff] = useState([]);
  const {
    dataRainGeotiff, refCompRain15Day30Day, refCompRain1Day, refCompRain3Day7Day, handleCompRainColorScaleChange,
  } = props;

  function valueLabelFormat(value) {
    if (!isEmpty(labelGeotiff)) {
      return labelGeotiff[value].label;
    }
    return '';
  }
  function valuetext(value) {
    return `${value}`;
  }
  const changeValue = (event, newValue) => {
    setCurrent(newValue);
    handleCompRainColorScaleChange(newValue);
  };

  const handleDateRainChange = (url, index) => {
    const geotiffUrl = `${process.env.MIX_FEWS_URL}/model-output/data_portal/radar/default.tif`;

    if (index >= 3) {
      refCompRain15Day30Day.setURL(url);
      refCompRain1Day.setURL(geotiffUrl);
      refCompRain3Day7Day.setURL(geotiffUrl);
    } else if (index >= 1) {
      refCompRain3Day7Day.setURL(url);
      refCompRain1Day.setURL(geotiffUrl);
      refCompRain15Day30Day.setURL(geotiffUrl);
    } else {
      refCompRain1Day.setURL(url);
      refCompRain3Day7Day.setURL(geotiffUrl);
      refCompRain15Day30Day.setURL(geotiffUrl);
    }
  };

  useEffect(() => {
    const arr = [];
    dataRainGeotiff.map((ele, i) => arr.push({
      value: i, label: ele.rain_day, url: ele.pic_geotiff,
    }));
    setLabelGeotiff(arr);
    setCurrent(0);
  }, [dataRainGeotiff]);

  useEffect(() => {
    if (isEmpty(labelGeotiff) === false) {
      handleDateRainChange(labelGeotiff[current].url, current);
    }
  }, [current]);

  return (
    <Box ml={1} className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          {/* ภาพเส้นชั้นน้ำฝน (Isohyet) */}
          <Box className={classes.title}>ฝนตรวจวัดในอดีต</Box>
          <Slider
            classes={{
              thumb: classes.thumb,
              rail: classes.rail,
              track: classes.track,
              valueLabel: classes.valueLabel,
              mark: classes.mark,
              marked: classes.marked,
              markActive: classes.markActive,
            }}
            value={current}
            onChange={changeValue}
            defaultValue={0}
            valueLabelFormat={valueLabelFormat}
            getAriaValueText={valuetext}
            step={null}
            valueLabelDisplay="auto"
            min={-1}
            max={5}
            marks={labelGeotiff}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

CompRainFallGeoTiff.propTypes = {
  dataRainGeotiff: PropTypes.array.isRequired,
  refCompRain1Day: PropTypes.any.isRequired,
  refCompRain3Day7Day: PropTypes.any.isRequired,
  refCompRain15Day30Day: PropTypes.any.isRequired,
  handleCompRainColorScaleChange: PropTypes.func.isRequired,
};
