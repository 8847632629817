import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { uniqueId } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

// import FaSpinner from 'react-icons/lib/fa/spinner';
import tJson from '../../utils/TJson';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const graphType = [
  { id: 'station', name: 'เปรียบเทียบสถานีคุณภาพน้ำ' },
  { id: 'param', name: 'เปรียบเทียบพารามิเตอร์' },
  { id: 'waterlevel', name: 'เปรียบเทียบสถานีวัดระดับน้ำ' },
  { id: 'datetime', name: 'เปรียบเทียบวันที่เวลาและสถานี' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * ComponentName
 */
class WaterQualityDateTimeChart extends Component {
  constructor(props) {
    super(props);
    const today = moment(new Date()).add(-3, 'day')
      .locale('th')
      .format('YYYY-MM-DD');
    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      dataParam: [],
      dataStation: [],
      waterQualityLoad: [],
      selectedDate: new Date(),
      provinceId: props.provinceId,
      graphTypeSelect: 'datetime',
      graphTypeSelectName: graphType[3].name,
      paramSelect: 'salinity',
      stationSelect: [props.id],
      dateTimeItems: [today],
      startDate: [today],
      endDate: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      today: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      startTime: moment()
        .subtract(3, 'days')
        .locale('th')
        .format('HH:mm'),
      endTime: moment(new Date())
        .locale('th')
        .format('HH:mm'),
    };

    this.renderChart = this.renderChart.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getWaterQualityLoad();
    this.renderChart();
  }

  static getDerivedStateFromProps(props, state) {
    let shouldRerenderChart = false;
    const { id } = state;
    shouldRerenderChart = shouldRerenderChart || id !== props.id;
    if (shouldRerenderChart) {
      this.setState({ id: props.id });
    }
    return null;
  }

  // componentWillReceiveProps(nextProps) {
  //   this.renderChart();
  //   let shouldRerenderChart = false;

  //   const { id } = this.state;
  //   shouldRerenderChart = shouldRerenderChart || id !== nextProps.id;

  //   if (shouldRerenderChart) {
  //     this.setState({ id: nextProps.id });
  //     this.renderChart();
  //   }
  // }

  getChartOptions() {
    const { t } = this.props;
    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            fontSize: '13px',
          },
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        tickInterval: 0.2,
        title: {
          text: t('ความเค็ม (g/L)'),
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'rgba(255, 139, 4, 1)',
            dashStyle: 'shortdash',
            width: 1,
            label: {
              text: null,
              align: 'left',
            },
          },
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 1,
            label: {
              text: null,
              align: 'left',
            },
          },
        ],
        plotBands: [],
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: t('ความเค็ม'),
          data: [],
          tooltip: {
            valueSuffix: ' (g/L)',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getWaterQualityLoad() {
    fetch(`${process.env.MIX_API_URL}analyst/waterquality_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataParam = result.waterquality_param.data;
        const dataStation = result.waterquality_station.data;

        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(
          currentDateTime.getDate() - 2,
        );

        const waterQualityLoad = result.data.data.filter(d => {
          const time = new Date(d.waterquality_datetime.replace(/-/g, '/')).getTime();
          return last24hrs < time;
        });

        this.setState({
          dataParam,
          dataStation,
          waterQualityLoad,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleStartDateChange = (date, index) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    const newLocal = this.state;
    const dateTimeItems = newLocal.dateTimeItems.slice();
    dateTimeItems[index] = newDate;
    this.setState({ dateTimeItems });
  };

  handleParamChange = event => {
    this.setState({ paramSelect: event.target.value });
  };

  handleStationChange = event => {
    this.setState({ stationSelect: event.target.value });
  };

  handleChange = event => {
    if (event.target.name === 'stationSelect') {
      if (event.target.value.length <= 15) {
        this.setState({ [event.target.name]: event.target.value });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChart = () => {
    this.renderChart();
  };

  handleGraphTypeChange = event => {
    graphType.map(type => {
      if (type.id === event.target.value) {
        this.setState(previousState => ({
          ...previousState,
          graphTypeSelectName: type.name,
        }), () => this.showState());
        // this.setState({ ...this.state, graphTypeSelectName: type.name }, () => this.showState());
      }
      return true;
    });

    this.setState({ isLoading: false });
    this.setState({ graphTypeSelect: event.target.value });
  };

  showState = () => {
    const { graphTypeSelect } = this.state;
    const {
      handleParameterModalOpen,
      handleDateTimeModalOpen,
      handleWaterlevelModalOpen,
      handleStationModalOpen,
    } = this.props;

    if (graphTypeSelect === 'param') {
      handleParameterModalOpen(this.state);
    } else if (graphTypeSelect === 'datetime') {
      handleDateTimeModalOpen(this.state);
    } else if (graphTypeSelect === 'waterlevel') {
      handleWaterlevelModalOpen(this.state);
    } else if (graphTypeSelect === 'station') {
      handleStationModalOpen(this.state);
    }
  };

  makeMultiDateList = () => {
    const { dateTimeItems } = this.state;
    const { t } = this.props;
    const elements = dateTimeItems.map((date, index) => (
      <Grid item xs={5} sm={4} md={4} lg={4}>
        <FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              key={uniqueId()}
              label={t('วันที่')}
              format="YYYY-MM-DD"
              views={['year', 'month', 'date']}
              value={date}
              onChange={e => this.handleStartDateChange(e, index)}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>
    ));
    return elements;
  };

  makeAddMultiDateList = () => {
    const { dateTimeItems } = this.state;
    const moments = dateTimeItems.map(d => moment(d));
    const newDate = moment
      .max(moments)
      .add(1, 'd')
      .locale('th')
      .format('YYYY-MM-DD');
    dateTimeItems.push(newDate);

    this.setState({ dateTimeItems }, () => {
      this.makeMultiDateList();
    });
  };

  makeRemoveMultiDateList = () => {
    const { dateTimeItems } = this.state;
    const newDate = dateTimeItems.length > 1 ? dateTimeItems.slice(0, -1) : dateTimeItems;

    this.setState({ dateTimeItems: newDate }, () => {
      this.makeMultiDateList();
    });
  };

  multiSelectStationRenderer = selected => {
    const { dataStation } = this.state;
    const stationName = [];
    selected.map(n => {
      const dd = dataStation.find(st => st.id === n);
      if (typeof dd !== 'undefined') {
        stationName.push(dd.waterquality_station_name.th);
      }
      return stationName;
    });

    return <span>{stationName.join(', ')}</span>;
  };

  renderChart() {
    const {
      stationSelect, paramSelect, dataParam, dateTimeItems,
    } = this.state;

    fetch(
      `${
        process.env.MIX_API_URL
      }analyst/waterquality_compare_datetime_graph?waterquality_station_id=${stationSelect}&param=${paramSelect}&date=${dateTimeItems}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const dataSeries = [];

        let ts = null;
        let val = null;

        // find max, min
        //  const max = _.maxBy(result.data[0].data, "value").value;
        //  const min = _.minBy(result.data[0].data, "value").value;

        result.data.map(data => {
          const series = [];
          data.station.map(n => {
            val = parseFloat(n.value);
            ts = tJson(n.station_name, 'สถานี');

            if (!Number.isNaN(val)) {
              series.push([ts, val]);
            }
            return series;
          });

          dataSeries.push({
            name: `${data.datetime}`,
            data: series,
            tooltip: {
              valueSuffix: ' ',
              xDateFormat: '%d %B %Y %H:%M',
            },
          });

          return dataSeries;
        });

        // set chart options
        const chartOptions = this.getChartOptions();

        // chartOptions.yAxis.max = 0.7;
        chartOptions.yAxis.min = 0;
        chartOptions.series = dataSeries;

        dataParam.map(row => {
          if (row.value === paramSelect) {
            chartOptions.yAxis.title.text = tJson(row.name, 'พารามิเตอร์');
          }
          return chartOptions;
        });

        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      graphTypeSelect,
      paramSelect,
      stationSelect,
      dataParam,
      dataStation,
    } = this.state;

    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return <div className="text-center">{/* <FaSpinner size={70} /> */}</div>;
    }

    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs={10} sm={3} md={3} lg={3}>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('ชนิดกราฟ')}
              </InputLabel>
              <Select
                value={graphTypeSelect}
                onChange={this.handleGraphTypeChange}
              >
                {graphType.map(row => (
                  <MenuItem key={uniqueId()} value={row.id}>
                    {t(row.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={4} md={4} lg={4}>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('พารามิเตอร์')}
              </InputLabel>
              <Select value={paramSelect} onChange={this.handleParamChange}>
                {dataParam.map(row => (
                  <MenuItem key={uniqueId()} value={row.value}>
                    {tJson(row.name, 'พารามิเตอร์')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={4} md={4} lg={4}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station">
                {t('สถานีคุณภาพน้ำ')}
              </InputLabel>
              <Select
                multiple
                value={stationSelect}
                onChange={this.handleChange}
                input={<Input id="station" />}
                renderValue={this.multiSelectStationRenderer}
                MenuProps={MenuProps}
                inputProps={{
                  name: 'stationSelect',
                  id: 'station',
                }}
              >
                {dataStation.map(row => (
                  <MenuItem key={uniqueId()} value={row.id}>
                    <Checkbox checked={stationSelect.indexOf(row.id) > -1} />
                    <ListItemText primary={tJson(row.waterquality_station_name, 'สถานี')} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {this.makeMultiDateList()}
          <Grid item xs={10}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.makeAddMultiDateList}
              title={t('เพิ่มวันที่')}
            >
              {t('เพิ่มวันที่')}
            </Button>
            &nbsp;
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.makeRemoveMultiDateList}
              title={t('ลบวันที่')}
            >
              {t('ลบวันที่')}
            </Button>
            &nbsp;
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.handleChart}
              title="แสดงกราฟ"
            >
              แสดง
            </Button>
          </Grid>
          <Grid item xs={12}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Grid>
          <Grid item xs={12}>
            <div style={{ color: '#A2A2A2' }}>
              {`*${t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}`}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

WaterQualityDateTimeChart.propTypes = {
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  handleStationModalOpen: PropTypes.func.isRequired,
  handleParameterModalOpen: PropTypes.func.isRequired,
  handleDateTimeModalOpen: PropTypes.func.isRequired,
  handleWaterlevelModalOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(WaterQualityDateTimeChart);
