import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;

const iconRectRed = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/red.png`,
  iconSize: [8, 8],
});

const iconRectCrimson = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/crimson.png`,
  iconSize: [8, 8],
});

const iconRectBlue = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/blue.png`,
  iconSize: [8, 8],
});

const iconRectGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/green.png`,
  iconSize: [8, 8],
});

const iconRectYellow = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/yellow.png`,
  iconSize: [8, 8],
});

const iconRectBrown = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/orange.png`,
  iconSize: [8, 8],
});

const iconRectGrey = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/grey.png`,
  iconSize: [8, 8],
});

const iconRectWhite = new L.Icon({
  iconUrl: `${initPath}images/marker/rectangle/white.png`,
  iconSize: [8, 8],
});

const iconBlueUp = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/blue-up.png`,
  iconSize: [12, 12],
});

const iconBrowUp = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/brow-up.png`,
  iconSize: [12, 12],
});

const iconGreenUp = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/green-up.png`,
  iconSize: [12, 12],
});

const iconRedUp = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/red-up.png`,
  iconSize: [12, 12],
});

const iconYellowUp = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/yellow-up.png`,
  iconSize: [12, 12],
});

const iconBlueDown = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/blue-down.png`,
  iconSize: [12, 12],
});

const iconBrowDown = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/brow-down.png`,
  iconSize: [12, 12],
});

const iconGreenDown = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/green-down.png`,
  iconSize: [12, 12],
});

const iconRedDown = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/red-down.png`,
  iconSize: [12, 12],
});

const iconYellowDown = new L.Icon({
  iconUrl: `${initPath}images/marker/triangle/yellow-down.png`,
  iconSize: [12, 12],
});

const iconRect = {
  red: iconRectRed,
  crimson: iconRectCrimson,
  blue: iconRectBlue,
  green: iconRectGreen,
  yellow: iconRectYellow,
  brown: iconRectBrown,
  grey: iconRectGrey,
  white: iconRectWhite,

  blueup: iconBlueUp,
  brownup: iconBrowUp,
  greenup: iconGreenUp,
  redup: iconRedUp,
  yellowup: iconYellowUp,

  bluedown: iconBlueDown,
  browndown: iconBrowDown,
  greendown: iconGreenDown,
  reddown: iconRedDown,
  yellowdown: iconYellowDown,
};

export default iconRect;
