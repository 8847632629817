import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;

const iconRed = new L.Icon({
  iconUrl: `${initPath}images/marker/temperature/red.png`,
  iconSize: [12, 18],
});

const iconBlue = new L.Icon({
  iconUrl: `${initPath}images/marker/temperature/blue.png`,
  iconSize: [12, 18],
});

const iconYellow = new L.Icon({
  iconUrl: `${initPath}images/marker/temperature/yellow.png`,
  iconSize: [12, 18],
});

const iconGreenLight = new L.Icon({
  iconUrl: `${initPath}images/marker/temperature/green-light.png`,
  iconSize: [12, 18],
});

const iconGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/temperature/green.png`,
  iconSize: [12, 18],
});

const iconGrays = new L.Icon({
  iconUrl: `${initPath}images/marker/temperature/gray.png`,
  iconSize: [12, 18],
});

const iconSky = new L.Icon({
  iconUrl: `${initPath}images/marker/temperature/sky.png`,
  iconSize: [12, 18],
});

const icon = {
  red: iconRed,
  blue: iconBlue,
  yellow: iconYellow,
  greenLight: iconGreenLight,
  green: iconGreen,
  grays: iconGrays,
  sky: iconSky,
};

export default icon;
