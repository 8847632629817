import { makeStyles } from '@material-ui/core/styles';

const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles((theme) => ({
    layout:{ 
        width: 1280,
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down('lg')]: {
            
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    navbar: {
        background: `url(${initPath}images/mhesi_warroom/bg-header.jpg)`,
        position: 'absolute',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        height: 1500,
        width: '100%',
        left: 0,
    },
    navbarMobile: {
        background: `url(${initPath}images/mhesi_warroom/bg-header.jpg)`,
        position: 'absolute',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        height: 800,
        width: '100%',
        left: 0,
    },
    spacingHeader: {
        paddingTop: 120,
        marginBottom: -60,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 250,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 400,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 500,
        },
    },
    dateTime: {
        position: 'absolute',
        paddingTop: '15%',
        color: '#000',  
        fontSize: '2em',
        fontWeight: 300,
        textAlign: 'right',
        left: '55%',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            left: '15%',
            fontSize: '1em',
            paddingLeft: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            left: '10%',
            fontSize: '1em',
            paddingLeft: '25%',
        },
    },
    dateBox: {
        background: '#ffffffe0',
        padding: '5px 10px',
        borderRadius: 50,
        [theme.breakpoints.down('sm')]: {
            padding: '3px 5px',
        },
    },
    dateTimeMobile: {
        position: 'absolute',
        paddingTop: 370,
        paddingLeft: '25%',
        color: '#000',  
        fontSize: '1.5em',
        fontWeight: 400,
        textAlign: 'right',
    },
    logo: {
        width: 80,
        marginRight: 8,
    },
    logoPartner: {
        width: 80,
        marginRight: 8,
        '&:hover': {
            transform: 'scale(1.05)',
            cursor: 'pointer',
        },
    },
    logoFooter: {
        width: 100,
    },
    tabTitle: {
        position: 'absolute',
        left: 0,
        top: 6,
        display: 'flex',
        color: '#fff',
        fontWeight: 400,
        fontSize: '1.5em',
        fontStyle: 'italic',
        paddingLeft: 45,

        justifyContent: 'space-between',
        width: '100%',
        marginRight: 30,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1em',
            marginTop: 5,
        },
    },
    title: {
        color: '#000',
        fontWeight: 600,
        padding: '5px',
        fontSize: '1.5em',
        textAlign: 'center',
        borderRadius: 6,
    },
    subTitle: {
        fontSize: '1.3em',
        textAlign: 'center',
    },
    subTitleRes: {
        fontSize: '1.3em',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9em',
            padding: '7px',
        },
    },
    link: {
        color: '#000',
        cursor: 'pointer !important',
        '&:link': {
            opacity: 0.8,
        },
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer',
        },
    },
    bannerLink: {
        color: '#000',
        padding: 50,
        backgroundColor: '#E1E1E2',
        textAlign: 'center',
        '&:hover $img': {
            opacity: 0.8,
            transform: 'scale(1.05)',
        },
    },
    img: {
        width: '100%',
    },

    imgLink: {
        width: '100%',
        boxShadow: '3px 2px 3px rgb(181 177 177 / 78%)',
    },
    
    imgLinkGis: {
        width: '100%',
        boxShadow: '3px 2px 3px rgb(181 177 177 / 78%)',
    },
    imgGis: {
        borderRadius: 9,
        width: '100%',
        height: 'auto',
        [theme.breakpoints.only('sm')]: {
            height: 250,
        },
        [theme.breakpoints.up('md')]: {
            height: 500,
        },
    },

    // สถานการณ์น้ำประจำวัน

    imgLinkDaily: {
        width: '100%',
        boxShadow: '3px 2px 3px rgb(181 177 177 / 78%)',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: 180,
        },
        [theme.breakpoints.up('md')]: {
            height: 300,
        },
    },
    imgLinkDailyReport: {
        width: '100%',
        boxShadow: '3px 2px 3px rgb(181 177 177 / 78%)',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: 180,
        },
        [theme.breakpoints.up('md')]: {
            height: 300,
        },
    },


    // ภารกิจศูนย์ปฏิบัติการฯ
    imgLink: {
        width: '100%',
        boxShadow: '3px 2px 3px rgb(181 177 177 / 78%)',
        // [theme.breakpoints.down('sm')]: {
        //     height: 210,
        // },
        [theme.breakpoints.up('md')]: {
            height: 300,
        },
    },

    imgWrapper: {
        display: 'block',
        cursor: 'pointer',
        '&:hover $imgLink': {
            opacity: 0.8,
            transform: 'scale(1.02)',
            
        },
    },
    btn: {
        color: '#000',
        fontSize: '0.8em',
        marginTop: 10,
        marginBottom: 10,
        padding: '3px 15px',
        textAlign: 'right',
        borderRadius: 6,
    },
    date: {
        fontSize: '0.8em',
        color: '#8A8A8A',
    },
    footer: {
        position:'absolute',
        left:0,
        width:'100%',
        display: 'block',
        justifyContent: 'center',
    },
    bgMobileApp: {
        background: `url(${initPath}images/mhesi_warroom/bg-app.webp)`,
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        height: 420,
        [theme.breakpoints.down('md')]: {
            height: '100%',
            backgroundPosition: 'bottom center',
        },
        width: '100%',
        padding: 40,
        display: 'flex',
        justifyContent: 'center',
    },
    address: {
        backgroundColor: '#6C3175',
        padding: 40,
        color: '#fff',
        width:'100%',
        display: 'flex',
        justifyContent: 'center',
    },
    copyright: {
        backgroundColor: '#E9E7EA',
        padding: 20,
        width:'100%',
        color: '#000',
        fontSize: '0.9em',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    hotline: {
        position: 'fixed',
        bottom: 10,
        right: 10,
        width: 150,
        height: 150,
        zIndex: 1000,
    },


}));

export default useStyles;