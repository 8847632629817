import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CropSquareIcon from '@material-ui/icons/CropSquare';

export default function LegendMarker() {
  const { t } = useTranslation();
  return (
    <Paper style={{
      opacity: '0.8', backgroundColor: '#EAEAEA', marginTop: 8, marginBottom: 8,
    }}
    >
      <Box p={1}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <CropSquareIcon />
          <span style={{ marginLeft: 10 }}>
            <strong>{t('อ่างเก็บน้ำ')}</strong>
          </span>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <RadioButtonUncheckedIcon />
          <span style={{ marginLeft: 10 }}>
            <strong>{t('สถานีฝน')}</strong>
          </span>
        </div>
      </Box>
    </Paper>
  );
}
