import React, { useState, useEffect } from 'react';
import { isEmpty, uniqueId } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Box, Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip as TooltipMui,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ExtremeIndexDetail from './ExtremeIndexDetail';

import {
  FaSpinner,
} from '../../utils/Fontawesome';

const useStyles = makeStyles({
  boxList: {
    border: '1px solid #ccc',
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#0064AF',
    color: 'white',
  },
  buttonDownload: {
    borderRadius: 20,
    padding: '2px 10px',
    fontSize: '0.8em',
    '&:hover': {
      backgroundColor: '#0064AF',
      color: 'white',
    },
  },
  wordWrap: {
    wordWrap: 'break-word',
  },
});

export default function CmFile() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    time: false,
    index: false,
  });
  const [datas, setDatas] = useState([]);
  const [temporal, setTemporal] = useState('Statistics');
  const [index, setIndex] = useState('');
  const [indexList, setIndexList] = useState([]);
  // const [fileCount, setFileCount] = useState(0);
  const [scenario, setScenario] = useState('historical');
  const statisticListAll = ['Mean', 'DiffMean'];
  const [statisticList, setStatisticList] = useState(statisticListAll);
  const [statistic, setStatistic] = useState('Mean');
  const [parameter, setParameter] = useState('Rainfall');
  const [model, setModel] = useState('02BCC-CSM2-MR');
  const [dc, setDc] = useState('Statistics');
  const [dcList, setDcList] = useState(['Extreme', 'Statistics']);
  const [time, setTime] = useState('20150101-20441231');

  const [temporalList, setTemporalList] = useState(['Annual', 'Daily', 'Monthly', 'Statistics']);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [open, setOpen] = useState(false);

  const scenarioList = ['historical', 'ssp126', 'ssp245', 'ssp370', 'ssp585'];
  const modelList = ['01ACCESS-ESM1-5', '02BCC-CSM2-MR', '03CanESM5', '04CMCC-ESM2', '05FGOALS-g3_MIP', '06IPSL-CM6A-LR', '07MIROC6', '08MPI-ESM1-2-LR'];
  const timeList = ['20150101-20441231', '20450101-20721231', '20730101-21001231'];

  useEffect(() => {
    if (dc === 'Statistics') {
      setIndex([]);
    }
  }, [dc]);

  useEffect(() => {
    const list = (parameter === 'Temperature') ? ['Statistics'] : ['Extreme', 'Statistics'];
    setDcList(list);
    if (parameter === 'Temperature') {
      setDc('Statistics');
    }
  }, [parameter]);

  useEffect(() => {
    if (temporal !== 'Statistics') {
      setTime('');
      setStatistic('');
    } else {
      setScenario('');
    }
  }, [temporal]);

  useEffect(() => {
    const iList = (dc === 'Extreme') ? ['CDD', 'CWD', 'PRCPTOT', 'R10mm', 'R20mm', 'R30mm', 'R35mm', 'R40mm', 'R90mm', 'R95PT', 'Rx1D', 'Rx2D', 'Rx3D', 'Rx4D', 'Rx5D', 'SDII'] : [];
    setIndexList(iList);

    const tList = (dc === 'Extreme') ? ['Annual', 'Statistics'] : ['Annual', 'Daily', 'Monthly', 'Statistics'];
    setTemporalList(tList);
  }, [dc]);

  useEffect(() => {
    const list = (parameter === 'Temperature') ? ['Statistics'] : ['Extreme', 'Statistics'];
    setDcList(list);
    if (parameter === 'Temperature') {
      setDc('Statistics');
    }
  }, [parameter]);

  useEffect(() => {
    const list = (scenario === 'Present') ? ['Mean'] : ['Mean', 'DiffMean'];
    setStatisticList(list);
  }, [scenario]);

  function getFiles() {
    let formErrors = {};

    if (parameter.trim() === '') {
      formErrors = { ...formErrors, parameter: true };
    } else {
      setErrors({ parameter: false });
    }
    if (model.trim() === '') {
      formErrors = { ...formErrors, model: true };
    } else {
      setErrors({ model: false });
    }
    if (dc.trim() === '') {
      formErrors = { ...formErrors, dc: true };
    } else if (dc.trim() === 'Extreme') {
      if (isEmpty(index)) {
        formErrors = { ...formErrors, index: true };
      } else {
        setErrors({ index: false });
      }
    }
    if (temporal.trim() === '') {
      formErrors = { ...formErrors, dc: true };
    } else if (temporal.trim() === 'Statistics') {
      if (isEmpty(time)) {
        formErrors = { ...formErrors, time: true };
      } else {
        setErrors({ time: false });
      }
      if (isEmpty(statistic)) {
        formErrors = { ...formErrors, statistic: true };
      } else {
        setErrors({ statistic: false });
      }
    } else if (isEmpty(scenario)) {
      formErrors = { ...formErrors, scenario: true };
    } else {
      setErrors({ scenario: false });
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setDatas([]);
    } else {
      const url = `${process.env.MIX_APP_URL}proxy/json.php?file=${process.env.MIX_API_HII_URL}/v2/${process.env.MIX_API_HII_TOKEN}/climate_change_file/param=model=${model}%26dc=${dc}%26index=${index}%26parameter=${parameter}%26temporal=${temporal}%26scenario=${scenario}%26time=${time}%26statistic=${statistic}`;
      fetch(url)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          if (result.status === 'ok') {
            setDatas(result.data);
            // setFileCount(result.file_count);
          } else {
            setDatas([]);
          }
          setLoading(false);
        })
        .catch(error => error);
    }
  }

  useEffect(() => {
    getFiles();
  }, []);

  const handleToggle = fileId => {
    const currentIndex = selectedFiles.indexOf(fileId);
    const newSelected = [...selectedFiles];

    if (currentIndex === -1) {
      newSelected.push(fileId);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedFiles(newSelected);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedFiles([]);
    } else {
      const allFileIds = datas.map(file => file.name);
      setSelectedFiles(allFileIds);
    }
    setSelectAll(!selectAll);
  };

  const downloadSelectedFiles = async () => {
    const postData = [];
    const selectedFilesData = datas.filter(file => selectedFiles.includes(file.name));

    selectedFilesData.forEach(file => {
      fetch(`${process.env.MIX_APP_URL}proxy/zip.php?file=${file.url}`)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(error => console.error('Error downloading file:', error));
    });

    selectedFilesData.map(item => {
      postData.push({ name: item.name });
    });
    try {
      const url = `${process.env.MIX_APP_URL}proxy/api-aseanwater.php?file=${process.env.MIX_API_HII_URL}/v2/${process.env.MIX_API_HII_TOKEN}/climate_change_download&body=${JSON.stringify({ postData })}`;
      const response = await fetch(url);
      if (response.ok) {
        console.log('update download count succeeded');
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  const handleInputChange = (event, setter) => {
    setter(event.target.value);
  };

  const handleIndexChange = event => {
    const {
      target: { value },
    } = event;
    setIndex(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const renderFiles = fileList => {
    const rowsPerColumn = 10;
    // โพปรับเป็นแสดงผล column เดียว ไม่ต้องคำนวน row ต่อ แถว
    // const NumOfColumn = (fileCount > 10) ? 3 : 12;
    const columns = [];
    for (let i = 0; i < fileList.length; i += rowsPerColumn) {
      columns.push(fileList.slice(i, i + rowsPerColumn));
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'between' }} width="100%">
        <Box p={3} className={classes.boxList} width="100%">
          <ListItem button onClick={handleSelectAll}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selectAll}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </ListItem>
          <Grid container spacing={2}>
            {columns.map(column => (
              <Grid item xs={12} sm={6} md={12} key={column[0].name}>
                {column.map(file => (
                  <ListItem key={file.name} button onClick={() => handleToggle(file.name)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedFiles.indexOf(file.name) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      secondary={`Downloads: ${file.download_count}`}
                      classes={{ primary: classes.wordWrap }}
                    />
                  </ListItem>
                ))}
              </Grid>
            ))}
          </Grid>
          <Button variant="contained" color="primary" onClick={downloadSelectedFiles}>
            Download Selected Files
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box my={3} sx={{ width: { xs: '100%', md: '70%' } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl className={classes.formControl} error={errors.parameter}>
              <InputLabel htmlFor="parameter">Parameter *</InputLabel>
              <Select
                aria-label="parameter"
                name="parameter"
                value={parameter}
                onChange={e => handleInputChange(e, setParameter)}
                inputProps={{
                  name: 'parameter',
                  id: 'parameter',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="Rainfall">Rainfall</MenuItem>
                <MenuItem value="Temperature">Temperature</MenuItem>
              </Select>
              {errors.parameter && <FormHelperText> Please select Parameter</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl className={classes.formControl} error={errors.dc}>
              <InputLabel htmlFor="dc">Dataset Category *</InputLabel>
              <Select
                aria-label="dc"
                name="dc"
                value={dc}
                onChange={e => handleInputChange(e, setDc)}
                inputProps={{
                  name: 'dc',
                  id: 'dc',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="">Select</MenuItem>
                {dcList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.dc && <FormHelperText> Please select Dataset Category</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Box display="flex">
              <FormControl className={classes.formControl} error={errors.index}>
                <InputLabel htmlFor="index">Extreme Index</InputLabel>
                <Select
                  aria-label="index"
                  name="index"
                  multiple
                  value={index}
                  onChange={handleIndexChange}
                  renderValue={selected => selected.join(', ')}
                  inputProps={{
                    name: 'index',
                    id: 'index',
                  }}
                  style={{ minWidth: 140 }}
                >
                  {indexList.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={index.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TooltipMui title="Definition of Extreme Rainfall Indices" placement="left-start" style={{ zIndex: 1 }}>
                <Button onClick={handleOpenDialog}>
                  <InfoIcon size="small" />
                </Button>
              </TooltipMui>
            </Box>
            <Modal
              id="modal"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ align: 'center', justifyContent: 'center' }}
              open={open}
              onClose={handleClose}
            >
              <div className={classes.paper}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={0}
                >
                  <Grid item>
                    <IconButton style={{ padding: 0 }}>
                      <CloseIcon fontSize="small" style={{ color: 'white' }} onClick={handleClose} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography variant="h5" id="modal-title">
                  Definition of Extreme Rainfall Indices
                </Typography>
                <Box mt={{ xs: 0, sm: 3 }}>
                  <ExtremeIndexDetail />
                </Box>
              </div>
            </Modal>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl className={classes.formControl} error={errors.temporal}>
              <InputLabel htmlFor="temporal">Temporal *</InputLabel>
              <Select
                aria-label="temporal"
                name="temporal"
                value={temporal}
                onChange={e => handleInputChange(e, setTemporal)}
                inputProps={{
                  name: 'temporal',
                  id: 'temporal',
                }}
                style={{ minWidth: 140 }}
              >
                {temporalList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.temporal && <FormHelperText> Please select Temporal</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lx={3}>
            <FormControl className={classes.formControl} error={errors.model}>
              <InputLabel htmlFor="Model">Model *</InputLabel>
              <Select
                aria-label="model"
                name="model"
                value={model}
                onChange={e => handleInputChange(e, setModel)}
                inputProps={{
                  name: 'model',
                  id: 'model',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="">Select</MenuItem>
                {modelList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.model && <FormHelperText> Please select Model</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl className={classes.formControl} error={errors.scenario}>
              <InputLabel htmlFor="scenario">scenario</InputLabel>
              <Select
                aria-label="scenario"
                name="scenario"
                value={scenario}
                onChange={e => handleInputChange(e, setScenario)}
                inputProps={{
                  name: 'scenario',
                  id: 'scenario',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="">Select</MenuItem>
                {scenarioList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.scenario && <FormHelperText> Please select Scenario</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl className={classes.formControl} error={errors.time}>
              <InputLabel htmlFor="time">Time Period</InputLabel>
              <Select
                aria-label="time"
                name="time"
                value={time}
                onChange={e => handleInputChange(e, setTime)}
                inputProps={{
                  name: 'time',
                  id: 'time',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="">Select</MenuItem>
                {timeList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.time && <FormHelperText> Please select Time Period</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl className={classes.formControl} error={errors.statistic}>
              <InputLabel htmlFor="statistic">Statistic Type</InputLabel>
              <Select
                aria-label="statistic"
                name="statistic"
                value={statistic}
                onChange={e => handleInputChange(e, setStatistic)}
                inputProps={{
                  name: 'statistic',
                  id: 'statistic',
                }}
                style={{ minWidth: 140 }}
              >
                {statisticList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.statistic && <FormHelperText> Please select statistic</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl className={classes.formControl}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                style={{ fontFamily: 'Kanit !important' }}
                title="แสดงผล"
                onClick={() => getFiles()}
              >
                Submit
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {isLoading === true && (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      )}
      <Grid container justifyContent="flex-start" spacing={3} key={uniqueId()}>
        {(isEmpty(datas))
          ? (<div className="text-center">NO DATA according to the conditions you selected.</div>)
          : renderFiles(datas)}
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        maxWidth="md"
      >
        <DialogTitle id="modal-title">Definition of Extreme Rainfall Indices</DialogTitle>
        <DialogContent>
          <ExtremeIndexDetail />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
