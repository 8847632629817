/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import _ from 'lodash';
import {
  uniqueId, orderBy, uniqBy,
} from 'lodash';
import moment from 'moment';
import Img from 'react-image';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

// form
import SearchIcon from '@material-ui/icons/Search';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// icon .
import IconButton from '@material-ui/core/IconButton';
import { FaHome } from '../../utils/Fontawesome';
import { HiiWaterQualityIcon } from '../../utils/Icon';

import {
  styles, BootstrapInput,
} from '../../styles/Style';

import Modal from '../modal/Modal';
import WaterQualityMap from './WaterQualityMap';
import WaterQualityLists from './WaterQualityLists';
import WaterQualityStationChart from './WaterQualityStationChart';
import WaterQualityParameterChart from './WaterQualityParameterChart';
import WaterQualityDateTimeChart from './WaterQualityDateTimeChart';
import WaterQualityWaterLevelChart from './WaterQualityWaterLevelChart';
import WaterQualityLegend from './WaterQualityLegend';

// sidebar
import SidebarWater from '../navigation/SidebarWater';

// translate
import tJson from '../../utils/TJson';

// translate
// import tJson from '../../utils/TJson';

const initPath = process.env.MIX_APP_PATH;
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const graphType = [
  { id: 'station', name: 'เปรียบเทียบสถานีคุณภาพน้ำ' },
  { id: 'param', name: 'เปรียบเทียบพารามิเตอร์' },
  { id: 'waterlevel', name: 'เปรียบเทียบสถานีวัดระดับน้ำ' },
  { id: 'datetime', name: 'เปรียบเทียบวันที่เวลาและสถานี' },
];

class WaterQuality extends Component {
  constructor(props) {
    super(props);

    this.buonds = [];
    this.state = {
      search: '',
      searchWaterquality: [],
      lastSearchWaterquality: [],

      // map
      map: null,
      zoom: 5,
      location: [12.9, 101.6],
      markers: [],
      // currentBounds: null,

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      value: '0',
      checked: false,
      isLoading: false,
      error: null, // error
      filterDatasWaterqaulity: [],
      latestDate: '',
      waterqualityLoad: [],
      dataFilter: [],
      dataProvince: [],
      dataBasin: [],
      dataPosition: [],
      areaName: '',
      isOpenCollapse: false,

      // radio group
      areaType: '0',
      areaId: '',

      dataCondition: [],
      areaText: 'ทั่วประเทศ',
      agencyName: 'ทุกหน่วยงาน',
      dataAgency: [],

      dataInitLastest: [],
      searchData: [],
      searchTerm: '',

      //agency select
      agencyId: '',
    };

    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleSetMap = this.handleSetMap.bind(this);
  }

  componentDidMount() {
    // this.scrollToBottom();
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.filterDatasWaterqaulity !== state.datas) {
      return {
        datas: state.filterDatasWaterqaulity,
        searchWaterquality: state.filterDatasWaterqaulity,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  getData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/waterquality_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        // const currentDateTime = new Date();
        const last24hrs = (new Date().getTime() / 1000) - (24 * 3600);
        // console.log(last24hrs)

        // sorting and filter
        const waterqualitySort = orderBy(
          result.data.data,
          ['waterquality_datetime'],
          ['desc'],
        );
        const datasWaterqualityAll = waterqualitySort.filter(d => {
          const time = new Date(d.waterquality_datetime.replace(/-/g, '/')).getTime();
          return last24hrs < time;
        });

        // replace value -9999 to null
        const datasWaterquality = datasWaterqualityAll.map(d => ({
          id: d.id,
          waterquality_id: d.waterquality_id,
          waterquality_datetime: d.waterquality_datetime,
          waterquality_do: d.waterquality_do !== -9999 ? d.waterquality_do : null,
          waterquality_ph: d.waterquality_ph !== -9999 ? d.waterquality_ph : null,
          waterquality_temp: d.waterquality_temp !== -9999 ? d.waterquality_temp : null,
          waterquality_turbid: d.waterquality_turbid !== -9999 ? d.waterquality_turbid : null,
          waterquality_bod: d.waterquality_bod !== -9999 ? d.waterquality_bod : null,
          waterquality_tcb: d.waterquality_tcb !== -9999 ? d.waterquality_tcb : null,
          waterquality_fcb: d.waterquality_fcb !== -9999 ? d.waterquality_fcb : null,
          waterquality_nh3n: d.waterquality_nh3n !== -9999 ? d.waterquality_nh3n : null,
          waterquality_wqi: d.waterquality_wqi !== -9999 ? d.waterquality_wqi : null,
          waterquality_ammonium: d.waterquality_ammonium !== -9999 ? d.waterquality_ammonium : null,
          waterquality_nitrate: d.waterquality_nitrate !== -9999 ? d.waterquality_nitrate : null,
          waterquality_colorstatus: d.waterquality_colorstatus > 0
            ? d.waterquality_colorstatus : null,
          waterquality_status: d.waterquality_status,
          waterquality_salinity: d.waterquality_salinity !== -9999 ? d.waterquality_salinity : null,
          waterquality_conductivity: d.waterquality_conductivity > 0
            ? d.waterquality_conductivity : null,
          waterquality_tds: d.waterquality_tds !== -9999 ? d.waterquality_tds : null,
          waterquality_chlorophyll: d.waterquality_chlorophyll !== -9999
            ? d.waterquality_chlorophyll : null,
          waterquality_station: d.waterquality_station,
          station_type: d.station_type,
          basin: d.basin,
        }));

        let dataProvince = result.data.data.map(d => ({
          id: d.waterquality_station.province_code,
          name: d.waterquality_station.province_name.th,
        }));
        dataProvince = uniqBy(dataProvince.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataBasin = result.data.data.map(d => ({
          id: d.basin.basin_code,
          name: d.basin.basin_name.th,
        }));
        dataBasin = uniqBy(dataBasin.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataAgency = result.data.data.map(d => ({
          id: d.waterquality_station.agency_id,
          name: d.waterquality_station.agency_name,
        }));
        dataAgency = uniqBy(dataAgency.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th')), 'id');

        this.setState({
          dataProvince,
          dataBasin,
          dataAgency,
          dataPosition: dataProvince,
          filterDatasWaterqaulity: datasWaterquality,
          isLoading: false,
          dataCondition: dataProvince,
          // latestDate: this.getMaxDate(result.data.data),
          latestDate: this.getMaxDate(result.data.data),
          waterqualityLoad: result.waterquality_param.data,
          lastSearchWaterquality: datasWaterquality,
        });
      })
      .catch(error => this.setState({ error, isLoading: true }));
    return true;
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.waterquality_datetime.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));

      return `${moment(maxdate).format('YYYY-MM-DD')} เวลา : ${moment(maxdate).format('HH:mm')} น.`;
    }
    return 'ไม่มีข้อมูล';
  };

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleSearchMarker = (id, latitude, longitude) => e => {
    const { map, markers } = this.state;
    e.preventDefault();
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
      const LatLng = {
        lat: latitude,
        lng: longitude,
      };
      const px = map.project(LatLng);
      px.y -= 212 / 2;
      map.panTo(map.unproject(px), { animate: true });
    }
  };

  handleParameterModalOpen = data => {
    const { classes, t } = this.props;
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = `${t(data.graphTypeSelectName)}`;
    modalData.subheader = null;
    modalData.content = (
      <WaterQualityParameterChart
        id={data.id}
        provinceId={data.provinceId}
        classes={classes}
        handleStationModalOpen={this.handleStationModalOpen}
        handleParameterModalOpen={this.handleParameterModalOpen}
        handleDateTimeModalOpen={this.handleDateTimeModalOpen}
        handleWaterlevelModalOpen={this.handleWaterlevelModalOpen}
      />
    );
    this.setState({ modalData });
  };

  handleWaterlevelModalOpen = data => {
    const { classes, t } = this.props;
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = `${t(data.graphTypeSelectName)}`;
    modalData.subheader = null;
    modalData.content = (
      <WaterQualityWaterLevelChart
        id={data.id}
        provinceId={data.provinceId}
        classes={classes}
        handleStationModalOpen={this.handleStationModalOpen}
        handleParameterModalOpen={this.handleParameterModalOpen}
        handleDateTimeModalOpen={this.handleDateTimeModalOpen}
        handleWaterlevelModalOpen={this.handleWaterlevelModalOpen}
      />
    );
    this.setState({ modalData });
  };

  handleDateTimeModalOpen = data => {
    const { classes, t } = this.props;
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = `${t(data.graphTypeSelectName)}`;
    modalData.subheader = null;
    modalData.content = (
      <WaterQualityDateTimeChart
        id={data.id}
        provinceId={data.provinceId}
        classes={classes}
        handleStationModalOpen={this.handleStationModalOpen}
        handleParameterModalOpen={this.handleParameterModalOpen}
        handleDateTimeModalOpen={this.handleDateTimeModalOpen}
        handleWaterlevelModalOpen={this.handleWaterlevelModalOpen}
      />
    );
    this.setState({ modalData });
  };

  handleStationModalOpen = data => {
    const { classes, t } = this.props;
    // this.setState({
    //   open: true,
    //   modalHeader: graphType[0].name,
    //   modalContent: (
    //     <WaterQualityStationChart
    //       id={data.id}
    //       provinceId={data.provinceId}
    //       classes={classes}
    //       handleStationModalOpen={this.handleStationModalOpen}
    //       handleParameterModalOpen={this.handleParameterModalOpen}
    //       handleDateTimeModalOpen={this.handleDateTimeModalOpen}
    //       handleWaterlevelModalOpen={this.handleWaterlevelModalOpen}
    //     />
    //   ),
    // });
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = t(graphType[0].name);
    modalData.subheader = null;
    modalData.content = (
      <WaterQualityStationChart
        id={data.id}
        provinceId={data.provinceId}
        classes={classes}
        handleStationModalOpen={this.handleStationModalOpen}
        handleParameterModalOpen={this.handleParameterModalOpen}
        handleDateTimeModalOpen={this.handleDateTimeModalOpen}
        handleWaterlevelModalOpen={this.handleWaterlevelModalOpen}
      />
    );
    this.setState({ modalData });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      this.filterDatas();
    })
  };

  handleSearchClick = () => {
    this.filterDatas();
  };

  handlePositionChange = (event, value) => {
    const { dataProvince, dataBasin, filterDatasWaterqaulity } = this.state;
    const { t } = this.props;
    let dataCondition = dataProvince;
    let areaText = t('ทั่วประเทศ');
    if (value === '1') {
      dataCondition = dataBasin;
      areaText = t('ทุกลุ่มน้ำ');
    }

    this.setState({
      areaType: value,
      dataCondition,
      areaId: '',
      areaText,
      dataInitLastest: filterDatasWaterqaulity,
      // searchWaterquality: filterDatasWaterqaulity,
      searchData: filterDatasWaterqaulity,
    }, () => {
      this.filterDatas();
    });
    // const dataPosition = (value === '1') ? dataBasin : dataProvince;
    // this.setState({
    //   value,
    //   dataPosition,
    //   searchWaterquality: filterDatasWaterqaulity,
    //   lastSearchWaterquality: filterDatasWaterqaulity,
    // });
  };

  handlePositionFilterChange = event => {
    const column = event.target.value;
    const { filterDatasWaterqaulity } = this.state;

    let searchWaterquality = [];
    if (column.length === 0) {
      searchWaterquality = filterDatasWaterqaulity;
    } else {
      searchWaterquality = filterDatasWaterqaulity.filter(
        value => column.indexOf(value.waterquality_station.province_name.th) !== -1
          || column.indexOf(value.basin.basin_name.th) !== -1,
      );
    }

    this.setState({
      lastSearchWaterquality: searchWaterquality,
      searchWaterquality,
      areaName: column,
    });
  }

  handleFilterAreaChange = areaId => event => {
    this.setState({ [areaId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  handleFilterAgencyChange = agencyId => event => {
    this.setState({ [agencyId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  filterDatas = () => {
    const { search, areaId, areaType, lastSearchWaterquality, agencyId  } = this.state;
    // console.log(search, ' | ', areaId, ' | ', lastSearchWaterquality);
    let searchWaterqualityFilter = [];
    searchWaterqualityFilter = lastSearchWaterquality
      .filter(d => {
        if (search !== '') {
          if(d.waterquality_station.river_name){
            if(!(tJson(d.waterquality_station.waterquality_station_name, 'สถานี').indexOf(search) > -1 
              || d.waterquality_station.amphoe_name.th.indexOf(search) > -1
              || d.waterquality_station.tumbon_name.th.indexOf(search) > -1
              || d.waterquality_station.province_name.th.indexOf(search) > -1
              || d.waterquality_station.river_name.indexOf(search) > -1
              || d.waterquality_station.waterquality_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
            )){
              return false
            }
          }else{
            return false
          }
        }
        if (areaId !== '') {
          if(areaType === '1' ? !(d.basin.basin_code.toString() === areaId) : !(d.waterquality_station.province_code === areaId)){
            return false
          }
        }
        if (agencyId !== '') {
          if(!(d.waterquality_station.agency_id.toString() === agencyId)){
            return false
          }
        }
        return d;
      });

    this.setState({
      searchWaterquality: searchWaterqualityFilter,
    });
    // let searchWaterqualityFilter = [];
    // if (search.length === 0) {
    //   searchWaterqualityFilter = lastSearchWaterquality;
    // } else {
    //   searchWaterqualityFilter = lastSearchWaterquality.filter(
    //     d => d.waterquality_station.waterquality_station_name.th.indexOf(search) !== -1,
    //   );
    // }

    // this.setState({
    //   searchWaterquality: searchWaterqualityFilter,
    // });
  };

  handleClick = () => {
    const { isOpenCollapse } = this.state; // classes
    if (isOpenCollapse === false) {
      this.setState({ isOpenCollapse: true });
    } else {
      this.setState({ isOpenCollapse: false });
    }
  }

  // scrollToBottom() {
  //   this.el.scrollIntoView({ behavior: 'smooth' });
  // }

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  render() {
    const {
      search,
      searchWaterquality,
      error,
      latestDate,
      areaType,
      areaText,
      areaId,
      dataCondition,
      modalData,
      dataAgency,
      agencyId, 
      agencyName,
    } = this.state;
    const {
      classes,
      theme,
      t,
      i18n,
    } = this.props;
    return (
      <>
        {error && <Grid className="text-center">{error.message}</Grid>}
        {/* Sidebar  */}
        <SidebarWater />
        <div className={classes.layout}>
          {/* ====== แถบ path หน้าด้านบน ====== */}
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}water`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสถานการณ์น้ำ')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('คุณภาพน้ำ')}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {/* ====== End แถบ path หน้าด้านบน ====== */}
          {/* ==== ชุดเมนูด้านบนตาราง/ตารางข้อมูล/แผนที่ ==== */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={5} lg={4}>
              {/* ==== Title ==== */}
              <Box display="flex" py={1} width="100%">
                <Box display="inline" p={1} alignItems="center">
                  <HiiWaterQualityIcon fontSize={24} />
                </Box>
                <Box display="inline" pt={2} alignItems="center">
                  <Typography variant="h4">
                    {t('คุณภาพน้ำ')}
                  </Typography>
                </Box>
              </Box>
              {/* ==== End Title ==== */}
              {/* ==== Map ==== */}
              <Box mt={2} width="100%">
                <WaterQualityMap
                  {...this.state}
                  datas={searchWaterquality}
                  handleStationModalOpen={this.handleStationModalOpen}
                  handleParameterModalOpen={this.handleParameterModalOpen}
                  handleDateTimeModalOpen={this.handleDateTimeModalOpen}
                  handleWaterlevelModalOpen={this.handleWaterlevelModalOpen}
                  handleSetMap={this.handleSetMap}
                  language={i18n.language}
                />
              </Box>
              {/* ==== End Map ==== */}
              {/* ==== Legend ==== */}
              <Box mt={2}>
                <WaterQualityLegend t={t} />
              </Box>
              {/* ==== End Legend ==== */}
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
              <Box mt={3} />
              <Grid container spacing={2}>
                {/* ==== Radio Button ข้อมูลจังหวัด or ข้อมูลลุ่มน้ำ ==== */}
                <Grid item xs={12} sm={6} md={5}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41 }}>
                    <Box>
                      <FormControl variant="outlined" component="fieldset">
                        <RadioGroup aria-label="position" name="position" value={areaType} onChange={this.handlePositionChange} row>
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลจังหวัด')}</Typography>}
                            labelPlacement="end"
                            fontFamily="Kanit"
                            style={{ height: 31, fontFamily: 'Kanit' }}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลลุ่มน้ำ')}</Typography>}
                            labelPlacement="end"
                            style={{ height: 31, fontFamily: 'Kanit' }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                {/* ==== End Radio Button ข้อมูลจังหวัด or ข้อมูลลุ่มน้ำ ==== */}
                {/* ==== DropDown ข้อมูลจังหวัด or ข้อมูลลุ่มน้ำ ==== */}
                <Grid item xs={12} sm={6} md={7}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                    <FormControl variant="outlined" className={classes.formControl} width="100%">
                      <NativeSelect
                        id="select"
                        value={areaId}
                        onChange={this.handleFilterAreaChange('areaId')}
                        input={(
                          <BootstrapInput
                            className={classes.outLinedRadius}
                            style={{ paddingLeft: 10 }}
                          />
                        )}
                      >
                        <option value="" style={{ fontFamily: 'Kanit' }}>{t(areaText)}</option>
                        {dataCondition.map(val => (
                          <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Grid>
                {/* ==== End DropDown ข้อมูลจังหวัด or ข้อมูลลุ่มน้ำ ==== */}
                {/* ==== DropDown หน่วยงาน ==== */}
                <Grid item xs={12} sm={7}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined">
                        <NativeSelect
                          id="agencySelect"
                          value={agencyId.toString()}
                          onChange={this.handleFilterAgencyChange('agencyId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                            )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit' }}>{t(agencyName)}</option>
                          {dataAgency.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{tJson(val.name, 'หน่วยงาน')}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                {/* ==== End DropDown หน่วยงาน ==== */}
                {/* ==== ช่องค้นหาข้อมูล ==== */}
                <Grid item xs={12} sm={5}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                    <Paper className={classes.rootSearch} elevation={0}>
                      <InputBase
                        display="flex"
                        className={classes.inputSearch}
                        id="search"
                        label={t('ค้นหาคุณภาพน้ำ')}
                        type="search"
                        margin="none"
                        value={search}
                        onChange={this.handleChange('search')}
                        placeholder={t('ค้นหา')}
                      />
                      <IconButton className={classes.iconButton} aria-label="Search">
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Box>
                </Grid>
                {/* ==== End ช่องค้นหาข้อมูล ==== */}
              </Grid>
              {/* ===== ตารางข้อมูล ===== */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box mt={2} />
                  <WaterQualityLists
                    datas={searchWaterquality}
                    handleSearchMarker={this.handleSearchMarker}
                    handleStationModalOpen={this.handleStationModalOpen}
                  />
                  <Box width="100%" p={1} align="right" fontSize={12} fontFamily="Kanit" color={theme.palette.secondary.main}>
                    {`${t('ล่าสุด')} ${latestDate}`}
                  </Box>
                </Grid>
              </Grid>
              {/* ===== End ตารางข้อมูล ===== */}
            </Grid>
          </Grid>
          {/* ==== End ชุดเมนูด้านบนตาราง/ตารางข้อมูล/แผนที่ ==== */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Tooltip title={t('ระบบคาดการณ์ความเค็มในแม่น้ำเจ้าพระยา')}>
                <Button
                  style={{ width: '100%', height: 'auto', padding: 0 }}
                  onClick={() => window.open(`${process.env.MIX_APP_URL}salinity`, '_blank')}
                >
                  <Img
                    src={`${initPath}images/modal/banner/Banner_basin_salinity.png`}
                    style={{ width: '100%', height: 'auto' }}
                    alt={t('ระบบคาดการณ์ความเค็มในแม่น้ำเจ้าพระยา')}
                    className={classes.responsive}
                    unloader={(
                      <Img
                        src="https://via.placeholder.com/204x375?text=No%20Image"
                        alt="No Data"
                        className={classes.responsive}
                      />
                    )}
                  />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
        {/* ==== Modal แสดงผลกราฟ ==== */}
        <Modal
          open={modalData.open}
          modalHeader={modalData.header}
          modalContent={modalData.content}
          resetModal={this.resetModal}
        />
        {/* ==== End Modal แสดงผลกราฟ ==== */}
      </>
    );
  }
}

WaterQuality.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(WaterQuality);
