import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  result,
  uniqueId,
} from 'lodash';
import Img from 'react-image';
import { withRouter } from 'react-router';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
import { Typography, Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// icon
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import BurstModeOutlinedIcon from '@material-ui/icons/BurstModeOutlined';
import WavesOutlinedIcon from '@material-ui/icons/WavesOutlined';

import {
  Link,
} from 'react-router-dom';
import {
  FaHome,
} from '../../../utils/Fontawesome';

import { styles } from '../../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
const monthText = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
const width = '90%';
// lightbox
let images = [];

class RainDistribution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayState: 'month',
      selectedYear: 1952,
      datas1: [],
      datas2: [],
      selectedStartYear: 1952,
      selectedEndYear: 1997,
      selectedMonth: '01',
      filterMode: 'year',
      // lightbox
      photoIndex: 0,
      isOpen: false,
      year: this.props.match.params.year,
      valueDropDown: this.props.match.params.year,
    };
  }

  componentDidMount() {
    this.fetchData( );
  }

  handleYearChange = (newDate, field) => {
    if (newDate && newDate.year) {
      const selectedYear = newDate.year();
      const selectedMonth = newDate.month() + 1; // Months are 0-based, so add 1
  
      // Check which field is being updated
      if (field === 'startYear') {
        this.setState({ selectedStartYear: selectedYear, isLoading: true }, () => {
          this.fetchData();
        });
      } else if (field === 'endYear') {
        this.setState({ selectedEndYear: selectedYear, isLoading: true }, () => {
          this.fetchData();
        });
      } else if (field === 'month') {
        this.setState({ selectedMonth, isLoading: true }, () => {
          this.fetchData();
        });
      } else if (field === 'year') {
        this.setState({ selectedYear, isLoading: true }, () => {
          this.fetchData();
        });
      }
    } else {
      console.error('Invalid date selected');
    }
  };

  fetchData = async () => {
    const { selectedYear, selectedStartYear, selectedEndYear, selectedMonth, filterMode } = this.state;
  
    if (!selectedYear) return;
  
    const baseUrl = `${process.env.MIX_API_URL}analyst/rain_chaopraya48_history_img`;
    const yearParam = `year=${selectedYear}`;
    const rangeParam = `year=${selectedStartYear}to${selectedEndYear}&month=${selectedMonth}`;
  
    const url1 = `${baseUrl}?${yearParam}`;
    const url2 = `${baseUrl}?${rangeParam}`;
  
    try {
      const [response1, response2] = await Promise.all([fetch(url1), fetch(url2)]);
  
      if (!response1.ok || !response2.ok) {
        throw new Error('Something went wrong while fetching data...');
      }
  
      const [result1, result2] = await Promise.all([response1.json(), response2.json()]);
  
      this.setState({
        isLoading: false,
        datas1: result1?.data || [],
        datas2: result2?.data || [],
      });
    } catch (error) {
      this.setState({ error, isLoading: false });
      console.error('Fetch error:', error);
    }
  };
  
  handleDateChangeButton = (direction) => {
    this.setState((prevState) => {
      const year = direction === '-1' ? prevState.selectedYear - 1 : prevState.selectedYear + 1;
      
      return { selectedYear: year };
    }, () => {
      this.fetchData(this.state.selectedYear); 
    });
  };  

  renderChaopraya(classes, year) {
    const { t, i18n } = this.props;
    const { datas1, datas2, selectedYear, selectedMonth,selectedStartYear,selectedEndYear, filterMode } = this.state;
    const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;
    let realImage = '';
    images = [];

    let dataToDisplay = [];
    if (filterMode === 'year') {
      dataToDisplay = datas1;
    } else if (filterMode === 'month') {
      dataToDisplay = datas2;
    }
  
    return (
      <Grid container justify="center" spacing={4}>
        {filterMode === 'year' && (

          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <Select
                value={filterMode}
                onChange={(e) => this.setState({ filterMode: e.target.value })}
                displayEmpty
              >
                <MenuItem value="year">{t('รายปี')}</MenuItem>
                <MenuItem value="month">{t('รายเดือน')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

            <Grid item>
              <Button
                onClick={() => this.handleDateChangeButton('-1')}
                style={{ visibility: selectedYear > 1952 ? 'visible' : 'hidden' }}
              >
                <KeyboardArrowLeftIcon />
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    openTo="year"
                    views={['year']}
                    value={new Date(selectedYear, 0, 1)}
                    onChange={(date) => this.handleYearChange(date, 'year')}
                    style={{ fontSize: 14 }}
                    minDate={new Date(1952, 0, 1)}
                    maxDate={new Date(1997, 0, 1)}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                onClick={() => this.handleDateChangeButton('1')}
                style={{ visibility: selectedYear < 1997 ? 'visible' : 'hidden' }}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </Grid>
          </Grid>
        )}
          {filterMode === 'month' && (
            <Grid container alignItems="center" justify="center" spacing={2}>

          <Grid item xs={12} sm={4} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <Select
                value={filterMode}
                onChange={(e) => this.setState({ filterMode: e.target.value })}
                displayEmpty
              >
                <MenuItem value="year">{t('รายปี')}</MenuItem>
                <MenuItem value="month">{t('รายเดือน')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    openTo="month"
                    views={['month']}
                    format="MMMM"
                    value={selectedMonth ? new Date(0, selectedMonth - 1, 1) : null}
                    onChange={(date) => this.handleYearChange(date, 'month')}
                    style={{ fontSize: 14 }}
                    d
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item >
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    openTo="year"
                    views={['year']}
                    value={new Date(selectedStartYear, 0, 1)}
                    onChange={(date) => this.handleYearChange(date, 'startYear')}
                    style={{ fontSize: 14 }}
                    minDate={new Date(1952, 0, 1)}
                    maxDate={new Date(1997, 0, 1)}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            {"-"}
            <Grid item >
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    openTo="year"
                    views={['year']}
                    value={new Date(selectedEndYear, 0, 1)}
                    onChange={(date) => this.handleYearChange(date, 'endYear')}
                    style={{ fontSize: 14 }}
                    minDate={new Date(selectedStartYear, 0, 1)}
                    maxDate={new Date(1997, 0, 1)}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          
          </Grid>
          )}

          {dataToDisplay.map((item, index) => {
            const months = new Date(item.datetime).toLocaleString('th-TH', { month: 'long' });
            const month = t(months);
            
            const imageUrl = `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${item.media_path}`;
            const year = new Date(item.datetime).getFullYear(); // Get the year from the datetime
    
            if (item.media_path) {
              images.push({
                src: imageUrl,
                title: filterMode === 'month' ? year : month 
              });

              realImage = (
                <Button
                  onClick={() =>
                    this.setState({
                      isOpen: true,
                      photoIndex: index,
                    })
                  }
                >
                  <Img
                    src={imageUrl}
                    width="300"
                    height="250"
                    className={classes.responsive}
                    alt={item.datetime}
                  />
                </Button>
              );
            } 
    
            return (
              <Grid item xs={6} sm={4} md={3} lg={3} key={item.id || uniqueId()}>
                <Box p={0} display="block">
                  <Box
                    display="block"
                    className={classNames(classes.boxRadiusForecast, classes.statusNull)}
                    align="center"
                  >
                    <Box p={1} style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                      <Typography variant="h6">
                      {filterMode === 'month' ? year : month}
                        </Typography>
                    </Box>
                  </Box>
                  <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                    {realImage}
                  </Box>
                </Box>
              </Grid>
            );
          })}
          
      </Grid>
    );
  }
  
  handleDropDownChange = event => {
    const { history } = this.props;
    const { value } = event.target;
    this.setState({
      valueDropDown: value,
      year: value,
    });

    const url = `${process.env.MIX_APP_PATH}weather/rain_dist/${value}`;
    history.push(url);
  }

  renderMonth(classes, year) {
    const { t } = this.props;
    const imageList = [];
    imageList['30'] = ['avg30y_th01__13.57.png', 'avg30y_th02__18.18.png', 'avg30y_th03__44.46.png', 'avg30y_th04__84.52.png', 'avg30y_th05__186.32.png', 'avg30y_th06__177.01.png', 'avg30y_th07__189.40.png', 'avg30y_th08__230.69.png', 'avg30y_th09__241.89.png', 'avg30y_th10__162.86.png', 'avg30y_th11__78.92.png', 'avg30y_th12__39.12.png'];
    // imageList['30_1_old'] = ['avg30y_202001_22.27.png', 'avg30y_202002_17.77.png', 'avg30y_202003_48.51.png', 'avg30y_202004_82.87.png', 'avg30y_202005_180.74.png', 'avg30y_202006_173.91.png', 'avg30y_202007_201.89.png', 'avg30y_202008_242.97.png', 'avg30y_202009_245.05.png', 'avg30y_202010_153.64.png', 'avg30y_202011_68.94.png', 'avg30y_202012_42.42.png'];
    imageList['30_1'] = ['avg30y_01_update202111_23.87.png', 'avg30y_02_update202111_18.18.png', 'avg30y_03_update202111_49.51.png', 'avg30y_04_update202111_84.2.png', 'avg30y_05_update202111_183.05.png', 'avg30y_06_update202111_176.31.png', 'avg30y_07_update202111_203.57.png', 'avg30y_08_update202111_238.06.png', 'avg30y_09_update202111_248.42.png', 'avg30y_10_update202111_156.33.png', 'avg30y_11_update202111_72.81.png', 'avg30y_12_update202111_45.29.png'];
    const imageShow = imageList[year];

    images = [];

    return (
      <>
        <Grid container justify="center" spacing={4}>
          { monthText.map((row, i) => {
            let realImage = '';
            const num = i + 1;
            const img = (num < 10) ? `0${num}.gif` : `${num}.gif`;
            const imgSrc = (year === '48') ? img : imageShow[i];

            images.push({
              src: `${initPath}uploads/rain_distribution/${year}year/${imgSrc}`,
              title: `${row}`,
            });
            realImage = (
              <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                <Img
                  src={`${initPath}uploads/rain_distribution/${year}year/${imgSrc}`}
                  width={width}
                  alt={row}
                  // className={classes.responsive}
                />
              </Button>
            );
            return (
              <Grid item xs={6} sm={4} md={3} lg={3} key={uniqueId()}>
                <Box p={0} display="block">
                  <Box display="block" className={classNames(classes.boxRadiusForecast, classes.statusNull)} align="center">
                    <Box p={1} style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                      <Typography variant="h6">{t(row)}</Typography>
                    </Box>
                  </Box>
                  <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                    {realImage}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }

  renderYear(classes, year) {
    const { t } = this.props;
    images = [];
    const img = (year === '48') ? 'iso-rainall.gif' : 'avg30y_th__1467.00.png';
    const imgSrc = `${initPath}uploads/rain_distribution/${year}year/${img}`;
    images.push({
      src: imgSrc,
      title: t('ภาพรวม'),
    });

    return (
      <>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={6} sm={4} md={3} lg={3} key={uniqueId()}>
            <Box p={0} display="block">
              <Box display="block" className={classNames(classes.boxRadiusForecast, classes.statusNull)} align="center">
                <Box p={1} style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                  <Typography variant="h6">{t('ภาพรวม')}</Typography>
                </Box>
              </Box>
              <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                <Button onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}>
                  <Img
                    src={imgSrc}
                    width={width}
                    alt={t('ภาพรวม')}
                    className={classes.responsive}
                  />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    const { classes, t } = this.props;
    const {
      displayState,
      photoIndex,
      isOpen,
      valueDropDown,
      year,
    } = this.state;

    const imageTitlesList = [];
    imageTitlesList['30'] = ['แผนภาพแสดงการกระจายตัวของปริมาณฝนเฉลี่ย 30 ปีย้อนหลัง', 'ตั้งแต่ พ.ศ. 2524-2553', 'ดูภาพเฉลี่ยรายเดือน', 'ดูภาพเฉลี่ย 30 ปี', ''];
    imageTitlesList['30_1'] = ['แผนภาพแสดงการกระจายตัวของปริมาณฝนเฉลี่ย 30 ปีย้อนหลัง', 'ตั้งแต่ พ.ศ. 2534-2563', 'ดูภาพเฉลี่ยรายเดือน', 'ดูภาพเฉลี่ย 30 ปี', ''];
    imageTitlesList['48'] = ['แผนภาพแสดงการกระจายตัวของปริมาณฝนเฉลี่ย 48 ปีย้อนหลัง', 'ตั้งแต่ พ.ศ. 2493-2540', 'ดูเฉลี่ยรายเดือน', 'ดูเฉลี่ยทั้งปี', 'ดูลุ่มเจ้าพระยา'];
    const imageTitles = imageTitlesList[valueDropDown];
    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/rainfall`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('ฝน')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t(imageTitles[0])}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 24 }}>
            <Grid item xs={12} md={5}>
              <Box m={1} className={classes.titleName}>
                {t(imageTitles[1])}
              </Box>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('เลือกปริมาณฝน ย้อนหลัง')}>
                <Select
                  value={valueDropDown}
                  onChange={this.handleDropDownChange}
                  className={classes.outlinedLeftPrimary}
                >
                  <MenuItem value="30">
                    {t('30')}
                    {t('ปี')}
                    {t(' (2524-2553)')}
                  </MenuItem>
                  <MenuItem value="30_1">
                    {t('30')}
                    {t('ปี')}
                    {t(' (2534-2563)')}
                  </MenuItem>
                  <MenuItem value="48">
                    {t('48')}
                    {t('ปี')}
                    {t(' (2493-2540)')}
                  </MenuItem>
                </Select>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูเฉลี่ยรายเดือน')}>
                <Button
                  onClick={() => this.setState({ displayState: 'month' })}
                  className={classes.outlinedPrimary}
                  color={displayState === 'month' ? 'primary' : 'default'}
                  variant={displayState === 'month' ? 'contained' : ''}
                >
                  <PhotoLibraryOutlinedIcon />
                  <Hidden smDown>
                    <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>
                      {t(imageTitles[2])}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูเฉลี่ยทั้งปี')}>
                <Button
                  onClick={() => this.setState({ displayState: 'year' })}
                  className={classes.outlinedPrimary}
                  color={displayState === 'year' ? 'primary' : 'default'}
                  variant={displayState === 'year' ? 'contained' : ''}
                >
                  <BurstModeOutlinedIcon />
                  <Hidden smDown>
                    <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>
                      {t(imageTitles[3])}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
            </Grid>

            { year === '48' && (
              <Grid item xs>
                <Tooltip title={t('ดูลุ่มเจ้าพระยา')}>
                  <Button
                    onClick={() => this.setState({ displayState: 'chaopraya' })}
                    className={classes.outlinedPrimary}
                    color={displayState === 'chaopraya' ? 'primary' : 'default'}
                    variant={displayState === 'chaopraya' ? 'contained' : ''}
                  >
                    <WavesOutlinedIcon />
                    <Hidden smDown>
                      <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>
                        {t(imageTitles[4])}
                      </Typography>
                    </Hidden>
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={4} justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
            <Grid item xs={12} md={12} lg={12}>
              { displayState === 'month' && (
                this.renderMonth(classes, year)
              )}
              { displayState === 'year' && (
                this.renderYear(classes, year)
              )}
              { displayState === 'chaopraya' && (
                this.renderChaopraya(classes, year)
              )}
            </Grid>
          </Grid>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

RainDistribution.defaultProps = {
  match: {
    params: {
      year: '48',
    },
  },
};

RainDistribution.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      year: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
};

export default withRouter(
  withTranslation()(withStyles(styles, { withTheme: true })(RainDistribution)),
);
