import iconRain from '../widgets/map/iconRain';

const rainfallColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#bdbdbd',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เล็กน้อย',
    rangeTitle: '> 0-10',
    statusColor: '#a9d1fc',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เล็กน้อย',
    rangeTitle: '> 10-20',
    statusColor: '#9ceeb2',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>20-35',
    statusColor: '#66c803',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>35-50',
    statusColor: '#f6d300',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'หนัก',
    rangeTitle: '>50-70',
    statusColor: '#fe8a04',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'หนัก',
    rangeTitle: '>70-90',
    statusColor: '#ca6504',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'หนักมาก',
    rangeTitle: '>90',
    statusColor: '#ee141f',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const rainfallColorYear = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#bdbdbd',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>0-200',
    statusColor: '#fffff5',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>200-400',
    statusColor: '#ffffcc',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>400-600',
    statusColor: '#ffff99',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>600-800',
    statusColor: '#ffff66',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>800-1000',
    statusColor: '#ffff33',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1000-1040',
    statusColor: '#ffff0d',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1040-1080',
    statusColor: '#ffff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1080-1120',
    statusColor: '#ccff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1120-1160',
    statusColor: '#99ff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1160-1200',
    statusColor: '#66ff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1200-1260',
    statusColor: '#33ff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1260-1320',
    statusColor: '#00ff33',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1320-1380',
    statusColor: '#00ff66',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1380-1440',
    statusColor: '#00ff99',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1440-1500',
    statusColor: '#00ffcc',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1500-1600',
    statusColor: '#00ffff',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1600-1700',
    statusColor: '#00ccff',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1700-1800',
    statusColor: '#0099ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1800-1900',
    statusColor: '#0066ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1900-2000',
    statusColor: '#0033ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>2000-2100',
    statusColor: '#0000ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>2100-2200',
    statusColor: '#4300f8',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>2200-2300',
    statusColor: '#6600ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>2300-2400',
    statusColor: '#9900ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>2400-2500',
    statusColor: '#cc00ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>2500-3000',
    statusColor: '#ff00ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>3000-3500',
    statusColor: '#ff00cc',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>3500-4000',
    statusColor: '#ff0099',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>4000-5000',
    statusColor: '#ff0066',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>5000-8000',
    statusColor: '#ec0420',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const rainfallColorMonth = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#bdbdbd',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>0-20',
    statusColor: '#fffff5',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>20-40',
    statusColor: '#ffffcc',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>40-60',
    statusColor: '#ffff99',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>60-80',
    statusColor: '#ffff66',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>80-100',
    statusColor: '#ffff33',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>100-110',
    statusColor: '#ffff0d',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>110-120',
    statusColor: '#ffff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>120-130',
    statusColor: '#ccff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>130-140',
    statusColor: '#99ff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>140-150',
    statusColor: '#66ff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>150-160',
    statusColor: '#33ff00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>160-170',
    statusColor: '#00ff33',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>170-180',
    statusColor: '#00ff66',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>180-190',
    statusColor: '#00ff99',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>190-200',
    statusColor: '#00ffcc',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>200-210',
    statusColor: '#00ffff',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>210-220',
    statusColor: '#00ccff',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>220-230',
    statusColor: '#0099ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>230-240',
    statusColor: '#0066ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>240-250',
    statusColor: '#0033ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>250-260',
    statusColor: '#0000ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>260-270',
    statusColor: '#4300f8',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>270-280',
    statusColor: '#6600ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>280-290',
    statusColor: '#9900ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>290-300',
    statusColor: '#cc00ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>300-400',
    statusColor: '#ff00ff',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>400-500',
    statusColor: '#ff00cc',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>500-700',
    statusColor: '#ff0099',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>700-1000',
    statusColor: '#ff0066',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: '',
    rangeTitle: '>1000-3000',
    statusColor: '#ec0420',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const rainfallColor3Day = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#bdbdbd',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เล็กน้อย',
    rangeTitle: '> 0-1',
    statusColor: '#ccc',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เล็กน้อย',
    rangeTitle: '> 1-5',
    statusColor: '#cce3ff',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เล็กน้อย',
    rangeTitle: '>5-10',
    statusColor: '#AED3FF',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>10-20',
    statusColor: '#aeecb7',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>20-35',
    statusColor: '#7fc63c',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'หนัก',
    rangeTitle: '>35-50',
    statusColor: '#f6d300',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'หนัก',
    rangeTitle: '>50-70',
    statusColor: '#ef9036',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'หนัก',
    rangeTitle: '>70-90',
    statusColor: '#bd6a27',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'หนักมาก',
    rangeTitle: '>90-150',
    statusColor: '#EE141F',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'หนักมาก',
    rangeTitle: '>150-200',
    statusColor: '#CC2723',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'หนักมาก',
    rangeTitle: '>200-300',
    statusColor: '#891B1D',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'หนักมาก',
    rangeTitle: '>300',
    statusColor: '#9931CF',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const RainfallStatusColor = (rainfall, type) => {
  let color = [];
  let icon;
  const rainfallIcon = iconRain;
  // console.log(rainfall)
  // console.log(type)

  if (type === 'rainfallYear') {
    if (rainfall > 0 && rainfall < 200) {
      color = rainfallColorYear['1'];
      icon = rainfallIcon.ifffff5;
    } else if (rainfall >= 200 && rainfall < 400) {
      color = rainfallColorYear['2'];
      icon = rainfallIcon.iffffcc;
    } else if (rainfall >= 400 && rainfall < 600) {
      color = rainfallColorYear['3'];
      icon = rainfallIcon.iffff99;
    } else if (rainfall >= 600 && rainfall < 800) {
      color = rainfallColorYear['4'];
      icon = rainfallIcon.iffff66;
    } else if (rainfall >= 800 && rainfall < 1000) {
      color = rainfallColorYear['5'];
      icon = rainfallIcon.iffff33;
    } else if (rainfall >= 1000 && rainfall < 1040) {
      color = rainfallColorYear['6'];
      icon = rainfallIcon.iffff0d;
    } else if (rainfall >= 1040 && rainfall < 1080) {
      color = rainfallColorYear['7'];
      icon = rainfallIcon.iffff00;
    } else if (rainfall >= 1080 && rainfall < 1120) {
      color = rainfallColorYear['8'];
      icon = rainfallIcon.iccff00;
    } else if (rainfall >= 1120 && rainfall < 1160) {
      color = rainfallColorYear['9'];
      icon = rainfallIcon.i99ff00;
    } else if (rainfall >= 1160 && rainfall < 1200) {
      color = rainfallColorYear['10'];
      icon = rainfallIcon.i66ff00;
    } else if (rainfall >= 1200 && rainfall < 1260) {
      color = rainfallColorYear['11'];
      icon = rainfallIcon.i33ff00;
    } else if (rainfall >= 1260 && rainfall < 1320) {
      color = rainfallColorYear['12'];
      icon = rainfallIcon.i00ff33;
    } else if (rainfall >= 1320 && rainfall < 1380) {
      color = rainfallColorYear['13'];
      icon = rainfallIcon.i00ff66;
    } else if (rainfall >= 1380 && rainfall < 1440) {
      color = rainfallColorYear['14'];
      icon = rainfallIcon.i00ff99;
    } else if (rainfall >= 1440 && rainfall < 1500) {
      color = rainfallColorYear['15'];
      icon = rainfallIcon.i00ffcc;
    } else if (rainfall >= 1500 && rainfall < 1600) {
      color = rainfallColorYear['16'];
      icon = rainfallIcon.i00ffff;
    } else if (rainfall >= 1600 && rainfall < 1700) {
      color = rainfallColorYear['17'];
      icon = rainfallIcon.i00ccff;
    } else if (rainfall >= 1700 && rainfall < 1800) {
      color = rainfallColorYear['18'];
      icon = rainfallIcon.i0099ff;
    } else if (rainfall >= 1800 && rainfall < 1900) {
      color = rainfallColorYear['19'];
      icon = rainfallIcon.i0066ff;
    } else if (rainfall >= 1900 && rainfall < 2000) {
      color = rainfallColorYear['20'];
      icon = rainfallIcon.i0033ff;
    } else if (rainfall >= 2000 && rainfall < 2100) {
      color = rainfallColorYear['21'];
      icon = rainfallIcon.i0000ff;
    } else if (rainfall >= 2100 && rainfall < 2200) {
      color = rainfallColorYear['22'];
      icon = rainfallIcon.i4300f8;
    } else if (rainfall >= 2200 && rainfall < 2300) {
      color = rainfallColorYear['23'];
      icon = rainfallIcon.i6600ff;
    } else if (rainfall >= 2300 && rainfall < 2400) {
      color = rainfallColorYear['24'];
      icon = rainfallIcon.i9900ff;
    } else if (rainfall >= 2400 && rainfall < 2500) {
      color = rainfallColorYear['25'];
      icon = rainfallIcon.icc00ff;
    } else if (rainfall >= 2500 && rainfall < 3000) {
      color = rainfallColorYear['26'];
      icon = rainfallIcon.iff00ff;
    } else if (rainfall >= 3000 && rainfall < 3500) {
      color = rainfallColorYear['27'];
      icon = rainfallIcon.iff00cc;
    } else if (rainfall >= 3500 && rainfall < 4000) {
      color = rainfallColorYear['28'];
      icon = rainfallIcon.iff0099;
    } else if (rainfall >= 4000 && rainfall < 5000) {
      color = rainfallColorYear['29'];
      icon = rainfallIcon.iff0066;
    } else if (rainfall >= 5000 && rainfall < 8000) {
      color = rainfallColorYear['30'];
      icon = rainfallIcon.iec0420;
    } else if (rainfall >= 8000) {
      color = rainfallColorYear['30'];
      icon = rainfallIcon.iec0420;
    } else {
      color = rainfallColor['0'];
      icon = rainfallIcon.grey;
    }
  } else if (type === 'rainfallMonth') {
    if (rainfall > 0 && rainfall < 20) {
      color = rainfallColorMonth['1'];
      icon = rainfallIcon.ifffff5;
    } else if (rainfall >= 20 && rainfall < 40) {
      color = rainfallColorMonth['2'];
      icon = rainfallIcon.iffffcc;
    } else if (rainfall >= 40 && rainfall < 60) {
      color = rainfallColorMonth['3'];
      icon = rainfallIcon.iffff99;
    } else if (rainfall >= 60 && rainfall < 80) {
      color = rainfallColorMonth['4'];
      icon = rainfallIcon.iffff66;
    } else if (rainfall >= 80 && rainfall < 100) {
      color = rainfallColorMonth['5'];
      icon = rainfallIcon.iffff33;
    } else if (rainfall >= 100 && rainfall < 110) {
      color = rainfallColorMonth['6'];
      icon = rainfallIcon.iffff0d;
    } else if (rainfall >= 110 && rainfall < 120) {
      color = rainfallColorMonth['7'];
      icon = rainfallIcon.iffff00;
    } else if (rainfall >= 120 && rainfall < 130) {
      color = rainfallColorMonth['8'];
      icon = rainfallIcon.iccff00;
    } else if (rainfall >= 130 && rainfall < 140) {
      color = rainfallColorMonth['9'];
      icon = rainfallIcon.i99ff00;
    } else if (rainfall >= 140 && rainfall < 150) {
      color = rainfallColorMonth['10'];
      icon = rainfallIcon.i66ff00;
    } else if (rainfall >= 150 && rainfall < 160) {
      color = rainfallColorMonth['11'];
      icon = rainfallIcon.i33ff00;
    } else if (rainfall >= 160 && rainfall < 170) {
      color = rainfallColorMonth['12'];
      icon = rainfallIcon.i00ff33;
    } else if (rainfall >= 170 && rainfall < 180) {
      color = rainfallColorMonth['13'];
      icon = rainfallIcon.i00ff66;
    } else if (rainfall >= 180 && rainfall < 190) {
      color = rainfallColorMonth['14'];
      icon = rainfallIcon.i00ff99;
    } else if (rainfall >= 190 && rainfall < 200) {
      color = rainfallColorMonth['15'];
      icon = rainfallIcon.i00ffcc;
    } else if (rainfall >= 200 && rainfall < 210) {
      color = rainfallColorMonth['16'];
      icon = rainfallIcon.i00ffff;
    } else if (rainfall >= 210 && rainfall < 220) {
      color = rainfallColorMonth['17'];
      icon = rainfallIcon.i00ccff;
    } else if (rainfall >= 220 && rainfall < 230) {
      color = rainfallColorMonth['18'];
      icon = rainfallIcon.i0099ff;
    } else if (rainfall >= 230 && rainfall < 240) {
      color = rainfallColorMonth['19'];
      icon = rainfallIcon.i0066ff;
    } else if (rainfall >= 240 && rainfall < 250) {
      color = rainfallColorMonth['20'];
      icon = rainfallIcon.i0033ff;
    } else if (rainfall >= 250 && rainfall < 260) {
      color = rainfallColorMonth['21'];
      icon = rainfallIcon.i0000ff;
    } else if (rainfall >= 260 && rainfall < 270) {
      color = rainfallColorMonth['22'];
      icon = rainfallIcon.i4300f8;
    } else if (rainfall >= 270 && rainfall < 280) {
      color = rainfallColorMonth['23'];
      icon = rainfallIcon.i6600ff;
    } else if (rainfall >= 280 && rainfall < 290) {
      color = rainfallColorMonth['24'];
      icon = rainfallIcon.i9900ff;
    } else if (rainfall >= 290 && rainfall < 300) {
      color = rainfallColorMonth['25'];
      icon = rainfallIcon.icc00ff;
    } else if (rainfall >= 300 && rainfall < 400) {
      color = rainfallColorMonth['26'];
      icon = rainfallIcon.iff00ff;
    } else if (rainfall >= 400 && rainfall < 500) {
      color = rainfallColorMonth['27'];
      icon = rainfallIcon.iff00cc;
    } else if (rainfall >= 500 && rainfall < 700) {
      color = rainfallColorMonth['28'];
      icon = rainfallIcon.iff0099;
    } else if (rainfall >= 700 && rainfall < 1000) {
      color = rainfallColorMonth['29'];
      icon = rainfallIcon.iff0066;
    } else if (rainfall >= 1000 && rainfall < 3000) {
      color = rainfallColorMonth['30'];
      icon = rainfallIcon.iec0420;
    } else if (rainfall >= 3000) {
      color = rainfallColorMonth['30'];
      icon = rainfallIcon.iec0420;
    } else {
      color = rainfallColor['0'];
      icon = rainfallIcon.grey;
    }
  } else if (type === 'rainfall24h' || type === 'rainfallToday' || type === 'rainfallYesterday') {
    if (rainfall > 90) {
      color = rainfallColor['7'];
      icon = rainfallIcon.red;
    } else if (rainfall > 70 && rainfall <= 90) {
      color = rainfallColor['6'];
      icon = rainfallIcon.tawny;
    } else if (rainfall > 50 && rainfall <= 70) {
      color = rainfallColor['5'];
      icon = rainfallIcon.yellow;
    } else if (rainfall > 35 && rainfall <= 50) {
      color = rainfallColor['4'];
      icon = rainfallIcon.gold;
    } else if (rainfall > 20 && rainfall <= 35) {
      color = rainfallColor['3'];
      icon = rainfallIcon.green;
    } else if (rainfall > 10 && rainfall <= 20) {
      color = rainfallColor['2'];
      icon = rainfallIcon.celadon;
    } else if (rainfall > 0 && rainfall <= 10) {
      color = rainfallColor['1'];
      icon = rainfallIcon.blue;
    } else {
      color = rainfallColor['0'];
      icon = rainfallIcon.ifffff5;
    }
  } else if (type === 'rainfall3Day' || type === 'rainfall7Day' || type === 'rainfall5Day' || type === 'rainfall15Day') {
    if (rainfall > 300) {
      color = rainfallColor3Day['12'];
      icon = rainfallIcon.i9900ff;
    } else if (rainfall > 200 && rainfall <= 300) {
      color = rainfallColor3Day['11'];
      icon = rainfallIcon.iec0420;
    } else if (rainfall > 150 && rainfall <= 200) {
      color = rainfallColor3Day['10'];
      icon = rainfallIcon.red;
    } else if (rainfall > 90 && rainfall <= 150) {
      color = rainfallColor3Day['9'];
      icon = rainfallIcon.red;
    } else if (rainfall > 70 && rainfall <= 90) {
      color = rainfallColor3Day['8'];
      icon = rainfallIcon.tawny;
    } else if (rainfall > 50 && rainfall <= 70) {
      color = rainfallColor3Day['7'];
      icon = rainfallIcon.yellow;
    } else if (rainfall > 35 && rainfall <= 50) {
      color = rainfallColor3Day['6'];
      icon = rainfallIcon.gold;
    } else if (rainfall > 20 && rainfall <= 35) {
      color = rainfallColor3Day['5'];
      icon = rainfallIcon.green;
    } else if (rainfall > 10 && rainfall <= 20) {
      color = rainfallColor3Day['4'];
      icon = rainfallIcon.celadon;
    } else if (rainfall > 5 && rainfall <= 10) {
      color = rainfallColor3Day['3'];
      icon = rainfallIcon.blue;
    } else if (rainfall > 1 && rainfall <= 5) {
      color = rainfallColor3Day['2'];
      icon = rainfallIcon.blue;
    } else if (rainfall > 0 && rainfall <= 1) {
      color = rainfallColor3Day['1'];
      icon = rainfallIcon.grey;
    } else {
      color = rainfallColor3Day['0'];
      icon = rainfallIcon.ifffff5;
    }
  }
  return { color, icon };
};

export default RainfallStatusColor;
