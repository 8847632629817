// React
import React, { useEffect, useState } from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Img from 'react-image';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
// style
import { styles } from '../../styles/Style';

import { uniqueId } from 'lodash';
// const useStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  ...styles,
  downloadButton: {
    margin: theme.spacing(1),
    backgroundColor: 'blue',
    color: 'white',
    padding: theme.spacing(1),
  },
}));
const initPath = process.env.MIX_APP_PATH;

export default function ReportVorticities() {
  const classes = useStyles();

  const downloadImage = (url) => {
    const link = document.createElement('a');
    const formattedDate = moment().format('YYYYMMDD');
    link.href = url;
    link.download = `wrf_vortex_hii_${formattedDate}.gif`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className={classes.layout}>
      <Box>
        <Box p={1} pt={0} mb={3}>
          <Img
            src={`${initPath}images/report_vorticities/topbar.png`}
            width={'100%'}
            alt="No Image"
          />
        </Box>
        <Box>
          <Grid container spacing={2} justifyContent="flex-end">
            <Button
              className={classes.downloadButton}
              variant="contained"
              color="primary"
              onClick={() => downloadImage(`./proxy/wrf-vortex.php?file=d02_verticity.gif`)}
              startIcon={<GetAppIcon />}
            >
              Download
            </Button>
          </Grid>
        </Box>
        <Box m={3}>
          <Grid container spacing={2} justifyContent="space-around">
            <Grid item xs={12} lg={10}>
              <div style={{ textAlign: 'center' }}>
                <img src={`./proxy/wrf-vortex.php?file=d02_verticity.gif`} key={uniqueId()} width="80%" alt="WRF-Vortex" />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box my={4} display="flex" justifyContent="center" color={'#888'} width={'100%'}>Disclaimer : ข้อมูลนี้เป็นผลจากแบบจำลอง WRF-ROMS เพื่อประกอบการตัดสินใจร่วมกับข้อมูลตรวจวัดอื่นๆ</Box>
        <Box my={3} display="flex">
          <Box><strong>คำอธิบายข้อมูล</strong> อัตราการหมุนของการเคลื่อนที่ขององค์ประกอบอากาศในบรรยากาศ โดยเฉพาะในชั้นบรรยากาศที่อยู่ใกล้พื้นผิวโลก (ระดับต่ำ) พิจารณาที่ความสูงประมาณ 850-900 hPa หรือต่ำกว่า แสดงถึงแนวโน้มของอากาศที่จะหมุนวนในทิศทางตามเข็มนาฬิกาหรือทวนเข็มนาฬิกา การหมุนหมุนวนที่มีค่าสูง (ค่าเป็นบวก) มักเกี่ยวข้องกับพาุยหรือ ระบบอากาศที่มีความแปรปรวนสูง เช่น พายุดีเปรสชัน หรือพายุฝน เป็นต้น</Box>
        </Box>
      </Box>
    </div>
  );
}