import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;
const sizeIcon = 20;

const TraffyIcon = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Traffy/fondue.png`,
  iconSize: [sizeIcon, sizeIcon],
});

export default TraffyIcon;
