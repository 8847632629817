import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import { Hidden } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';

// icon .
import {
  FaPlay,
  FaCalendarAlt,
  FaImages,
  FaHome,
} from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class WeatherRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };

  render() {
    const { classes, theme, t } = this.props;
    const { value } = this.state;

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={10}>
            <Grid item xs>
              <Box mt={5} mb={3} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Box display="flex" width="100%">
                    <Box display="inline" p={1}>
                      <FaHome style={theme.iconSub} />
                      <Typography className={classes.titleSubList} inline="true">
                        {t('ติดตามสภาพอากาศ')}
                      </Typography>
                      <Typography inline="true">
                        /
                      </Typography>
                      <Typography className={classes.titleSubList} inline="true">
                        {t('ฝน')}
                      </Typography>
                      <Typography inline="true">
                        /
                      </Typography>
                      <Typography className={classes.titleSubList} inline="true">
                        {t('แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12} md={12} lg={12}>
              <div style={{ width: '100%' }}>
                <Box display="flex">
                  <Box flexGrow={1} p={0} display="flex" className={classes.titleBarList} alignItems="center">
                    <Box p={1} className={classes.titleName}>
                      {t('แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน')}
                    </Box>
                  </Box>
                  <Box border={1} style={{ border: '1px solid #ccc' }} display="flex" alignItems="center">
                    <Button href={`${initPath}/weather`} size="small" variant="contained" color="primary" className={classes.iconButtonText} style={{ borderRadius: 0 }}>
                      <Box p={1}>
                        <FaImages className={classes.iconButton} />
                        <Hidden smDown>
                          {t('ดูภาพล่าสุด')}
                        </Hidden>
                      </Box>
                    </Button>
                  </Box>
                  <Box p={1} border={1} style={{ border: '1px solid #ccc', borderTopRightRadius: 6 }} display="flex" alignItems="center">
                    <Button href={`${initPath}/weather`} size="small" className={classes.iconButtonText}>
                      <FaPlay className={classes.iconButton} />
                      <Hidden smDown>
                        {t('ดูภาพเคลื่อนไหว')}
                      </Hidden>
                    </Button>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Box display="flex" py={3} width="100%" alignItems="center" justifyContent="center" bgcolor="background.paper">
                <Box display="inline" fontFamily="Kanit">
                  <Input
                    defaultValue="กุมภาพันธ์ 2562"
                    className={classes.input}
                    justifyContent="center"
                    inputProps={{
                      'aria-label': 'Description',
                    }}
                  />
                </Box>
                <Box display="inline" fontFamily="Kanit">
                  <FaCalendarAlt className={classes.iconButton} />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12} md={12} lg={12}>
              <Box display="inline">
                <Box display="flex" width="100%">
                  <Tabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="secondary"
                    variant="fullWidth"
                  >
                    <Tab label={<Box width="100%" fontFamily="Kanit" fontWeight="fontWeightMedium" fontSize={14}>Thailand (3km x 3km)</Box>} />
                    <Tab label={<Box width="100%" fontFamily="Kanit" fontWeight="fontWeightMedium" fontSize={14}>Southeast Asia (9km x 9km)</Box>} />
                    <Tab label={<Box width="100%" fontFamily="Kanit" fontWeight="fontWeightMedium" fontSize={14}>Thailand (3km x 3km)</Box>} />
                    <Tab label={<Box width="100%" fontFamily="Kanit" fontWeight="fontWeightMedium" fontSize={14}>Thailand (3km x 3km)</Box>} />
                  </Tabs>
                </Box>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={this.handleChangeIndex}
                  style={{ padding: '0px!important' }}
                >
                  <TabContainer>
                    <Grid container spacing={0}>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusHeavy} align="center" fontFamily="Kanit">ฝนหนักมาก</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusMedium} align="center" fontFamily="Kanit">ฝนปานกลาง</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusHeavy} align="center" fontFamily="Kanit">ฝนหนักมาก</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusMedium} align="center" fontFamily="Kanit">ฝนปานกลาง</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusHeavy} align="center" fontFamily="Kanit">ฝนหนักมาก</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusMedium} align="center" fontFamily="Kanit">ฝนปานกลาง</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusHeavy} align="center" fontFamily="Kanit">ฝนหนักมาก</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statusMedium} align="center" fontFamily="Kanit">ฝนปานกลาง</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                        <Box m={2}>
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">8 เม.ย. 2562</Box>
                          <Box p={1} className={classes.statuslight} align="center" fontFamily="Kanit">ฝนเล็กน้อย</Box>
                          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                            <img src={`${initPath}images/theme/banner/weather/Screen Shot 2562-06-09 at 10.09.48.png`} className={classes.responsive} alt="thaiwater" />
                          </Box>
                        </Box>
                      </Grid>

                    </Grid>
                  </TabContainer>
                  <TabContainer dir={theme.direction}>2</TabContainer>
                  <TabContainer dir={theme.direction}>3</TabContainer>
                  <TabContainer dir={theme.direction}>4</TabContainer>
                </SwipeableViews>
              </Box>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

WeatherRadar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(WeatherRadar));
