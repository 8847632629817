import React, { Component } from 'react';

// ui
import Grid from '@material-ui/core/Grid';
import { FaSpinner } from '../../../utils/Fontawesome';

class WindAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      animation: '',

    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.fetchData();
  }

  fetchData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/wind10m_forecast_animation_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          animation: result.data[0].media_path,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      isLoading,
      error,
      animation,
    } = this.state;

    return (
      <div>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={25} />
          </div>
        )}

        { animation && (
        <Grid container spacing={4} style={{ marginTop: 18, marginBottom: 24 }}>
          <Grid item xs>
            <video width="100%" controls autoPlay>
              <source src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${animation}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
        )}
      </div>
    );
  }
}

export default WindAnimation;
