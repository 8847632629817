import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import { orderBy, uniqBy } from 'lodash';
import moment from 'moment';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

// width
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { FaSpinner, FaCalendarAlt } from '../../utils/Fontawesome';

// translate
// import tJson from '../../utils/TJson';

import {
  styles,
} from '../../styles/Style';

// icons
import { HiiDamIcon } from '../../utils/Icon';

// dam
import DamLatest from './DamLatest';
import DamLargeFullList from './DamLargeFullList';
import DamMediumFullList from './DamMediumFullList';
import Modal from '../modal/Modal';

import DamContext from '../../contexts/damContext';

import SidebarWater from '../navigation/SidebarWater';

const initPath = process.env.MIX_APP_PATH;

class Dam extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      errorInfo: null,
      filterDatasDamDailyRid: [],
      filterDatasDamDailyEgat: [],
      filterDatasDamMedium: [],
      datasEgatHourly: [],
      latestDateRid: '',
      latestDateEgat: '',
      dataBasin: [],
      dataProvince: [],
      damsMediumRidList: [],
      lastUrl: '',
      // modal
      // open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      language: props.i18n.language,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { location } = this.props;
    // set loading status
    if (this._isMounted) {
      this.setState({ isLoading: true });

      // get data from ws
      this.getData();
      const lastLocationName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      this.setState({ lastUrl: lastLocationName });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.i18n.language !== prevState.language) {
      this.setStateLanguage(prevProps.i18n.language);
    }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setStateLanguage(value) {
    this.setState({ language: value });
  }

  getData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/dam`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // sorting and filter, large dam
        let damDailySort = orderBy(result.data.dam_daily, ['dam_storage_percent'], ['desc']);
        const datasDamDailyRid = damDailySort.filter(d => {
          const row = d;
          const time = new Date(d.dam_date).getTime();
          row.dam.dam_name.th = d.dam.dam_name.th;
          return ((last24hrs < time) && (d.agency.id === 12));
        });
        damDailySort = orderBy(result.data.dam_daily, ['dam_storage'], ['desc']);
        const datasDamDailyEgat = damDailySort.filter(d => {
          const row = d;
          row.agency.agency_name.th = d.agency.agency_name.th;
          row.geocode.province_name.th = d.geocode.province_name.th;
          row.geocode.amphoe_name.th = d.geocode.amphoe_name.th;
          row.geocode.tumbon_name.th = d.geocode.tumbon_name.th;
          return (d.agency.id === 8);
        });

        // sorting and filter, medium dam
        const damMediumSort = orderBy(result.data.dam_medium, ['dam_storage_percent'], ['desc']);
        const datasDamMedium = damMediumSort.filter(d => {
          const time = new Date(d.dam_date).getTime();
          const row = d;
          // let damName = '';
          // if (d.dam.dam_name.th.includes('อ่างเก็บน้ำ')) {
          //   damName = d.dam.dam_name.th.replace('อ่างเก็บน้ำ', '');
          // }
          // else {
          //   damName = d.dam.dam_name.th;
          // }
          row.dam.dam_name.th = d.dam.dam_name.th;
          row.geocode.province_name.th = d.geocode.province_name.th;
          row.geocode.amphoe_name.th = d.geocode.amphoe_name.th;
          row.geocode.tumbon_name.th = d.geocode.tumbon_name.th;
          return (last24hrs < time);
        });
        // dam medium dropdown
        const damsMediumRidList = orderBy(datasDamMedium, ['dam.dam_name.th'], ['asc']).map(d => ({
          id: d.dam.id,
          name: d.dam.dam_name.th,
          rid_office: d.dam.rid_office,
        }));

        // basin and province dropdown
        const dataListProvinceLarge = result.data.dam_daily.map(d => ({
          id: d.geocode.province_code,
          name: d.geocode.province_name.th,
        }));

        const dataListProvinceMedium = result.data.dam_medium.map(d => ({
          id: d.geocode.province_code,
          name: d.geocode.province_name.th,
        }));
        let dataListProvince = dataListProvinceLarge.concat(dataListProvinceMedium);

        dataListProvince = uniqBy(dataListProvince.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataListBasin = result.data.dam_medium.map(d => ({
          id: d.basin.basin_code,
          name: d.basin.basin_name.th,
        }));
        dataListBasin = uniqBy(dataListBasin.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        // console.log('datasDamMedium  : ', datasDamMedium);
        this.setState({
          filterDatasDamDailyRid: datasDamDailyRid,
          filterDatasDamDailyEgat: datasDamDailyEgat,
          filterDatasDamMedium: datasDamMedium,
          // datasEgatHourly: tempDatasEgatHourly,
          datasEgatHourly: result.data.dam_hourly.filter(d => (d.agency.id === 8)),
          isLoading: false,
          latestDateRid: this.getMaxDate(datasDamDailyRid),
          latestDateEgat: this.getMaxDate(datasDamDailyEgat),
          latestDateMediumRid: this.getMaxDate(datasDamMedium),
          dataProvince: dataListProvince,
          dataBasin: dataListBasin,
          damsMediumRidList,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    this._isMounted = false;
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.dam_date));
      const maxdate = new Date(Math.max.apply(null, dates));

      return moment(maxdate).format('YYYY-MM-DD');
      // return maxdate.toLocaleDateString('th-TH', {
      //   year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
      // });
    }

    return new Date();
  }

  handleModalOpen = params => {
    // this.setState({
    //   open: true,
    //   modalHeader: params.header,
    //   modalSubHeader: params.subheader,
    //   modalContent: params.content,
    // });
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  setUrl = url => e => {
    this.setState({ lastUrl: url });
  }

  render() {
    const {
      classes,
      theme,
      t,
    } = this.props;
    // const currentLocation = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const {
      isLoading,
      error,
      errorInfo,
      filterDatasDamDailyRid,
      filterDatasDamDailyEgat,
      filterDatasDamMedium,
      datasEgatHourly,
      latestDateRid,
      latestDateEgat,
      latestDateMediumRid,
      dataProvince,
      dataBasin,
      damsMediumRidList,
      // open,
      modalData,
      lastUrl,
    } = this.state;
    const currentLocation = lastUrl;
    if (errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }

    // console.log(currentLocation);
    return (
      <>

        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {/* Sidebar  */}
        <SidebarWater />
        <Router>
          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs>
                {isLoading === true && (
                  <div className="text-center">
                    <FaSpinner size={70} />
                  </div>
                )}
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    {t('ติดตามสถานการณ์น้ำ')}
                  </Typography>
                </Box>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs>
                <Box display="flex" mt={3} p={0} className={classes.boxTopRadius} alignItems="center" height={53}>
                  <Box flexGrow={1} p={1} display="flex" alignItems="center" style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}>
                    <Box pl={2} className={classes.titleBar}>{t('อ่างเก็บน้ำ')}</Box>
                  </Box>
                  <Box p={1} style={{ borderLeft: '1px solid #ccc', borderTopRightRadius: 6 }} display="flex" alignItems="center" justifyContent="center">
                    {/* <Link to={`${process.env.MIX_APP_PATH}weather/history/month/64/189`}> */}
                    <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                      <Button className={classes.outlinedOldRightPrimary} href={`${initPath}weather/history/month/64/189`}>
                        <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                        <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูข้อมูลย้อนหลัง')}</Typography></Hidden>
                      </Button>
                    </Tooltip>
                    {/* </Link> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <Box display="flex" pt={1} width="100%">
                  <Box display="inline" p={1} alignItems="center">
                    <HiiDamIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('อ่างเก็บน้ำ')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                  <Grid item xs>
                    <Box pt={1} mt={{ xs: 0, md: 1 }} display="flex" justifyContent="center" alignItems="center">
                      <Button
                        href={`${initPath}water`}
                        variant={currentLocation === 'water' ? 'contained' : 'outlined'}
                        className={currentLocation === 'water' ? classes.activeButton : classes.button}
                        fullWidth
                      >
                        <Typography variant="h6"><Hidden smDown>{t('ภาพรวมล่าสุด')}</Hidden></Typography>
                        <Typography variant="h6"><Hidden mdUp>{t('ล่าสุด')}</Hidden></Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box pt={1} mt={{ xs: 0, md: 1 }} display="flex" justifyContent="center" alignItems="center">
                      <Button
                        to={`${initPath}water/dam/large`}
                        component={Link}
                        onClick={this.setUrl('large')}
                        variant={currentLocation === 'large' ? 'contained' : 'outlined'}
                        className={currentLocation === 'large' ? classes.activeButton : classes.button}
                        fullWidth
                      >
                        <Typography variant="h6"><Hidden smDown>{t('อ่างขนาดใหญ่')}</Hidden></Typography>
                        <Typography variant="h6"><Hidden mdUp>{t('ใหญ่')}</Hidden></Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box pt={1} mt={{ xs: 0, md: 1 }} display="flex" justifyContent="center" alignItems="center">
                      <Button
                        to={`${initPath}water/dam/medium`}
                        component={Link}
                        onClick={this.setUrl('medium')}
                        variant={currentLocation === 'medium' ? 'contained' : 'outlined'}
                        className={currentLocation === 'medium' ? classes.activeButton : classes.button}
                        fullWidth
                      >
                        <Typography variant="h6"><Hidden smDown>{t('อ่างขนาดกลาง')}</Hidden></Typography>
                        <Typography variant="h6"><Hidden mdUp>{t('กลาง')}</Hidden></Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs>
                <DamContext.Provider
                  value={{
                    handleModalOpen: this.handleModalOpen,
                  }}
                >
                  <Switch>
                    <Route
                      key="latest"
                      exact
                      path={`${initPath}water`}
                      render={() => (
                        <DamLatest
                          filterDatasDamDaily={filterDatasDamDailyRid}
                          filterDatasDamMedium={filterDatasDamMedium}
                          latestDateRid={latestDateRid}
                          latestDateEgat={latestDateEgat}
                          dataProvince={dataProvince}
                          dataBasin={dataBasin}
                          damsMediumRidList={damsMediumRidList}
                        />
                      )}
                    />
                    <Route
                      key="large"
                      exact
                      path={`${initPath}water/dam/large`}
                      render={() => (
                        <DamLargeFullList
                          datasRid={filterDatasDamDailyRid}
                          datasEgat={filterDatasDamDailyEgat}
                          latestDateRid={latestDateRid}
                          latestDateEgat={latestDateEgat}
                          datasEgatHourly={datasEgatHourly}
                        />
                      )}
                    />
                    <Route
                      key="medium"
                      exact
                      path={`${initPath}water/dam/medium`}
                      render={() => (
                        <DamMediumFullList
                          filterDatasDamMedium={filterDatasDamMedium}
                          latestDateMediumRid={latestDateMediumRid}
                          damsMediumRidList={damsMediumRidList}
                        />
                      )}
                    />
                  </Switch>
                </DamContext.Provider>
              </Grid>
            </Grid>
          </div>
        </Router>
        <Modal
          open={modalData.open}
          modalHeader={modalData.header}
          modalContent={modalData.content}
          resetModal={this.resetModal}
        />
      </>
    );
  }
}

Dam.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  // width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  i18n: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(Dam);
