import React, { Component, Fragment } from 'react';
import PropTypes, { symbol } from 'prop-types';
import moment from 'moment';
import { uniqueId, maxBy, fill } from 'lodash';

// HighCharts
import Highcharts, { color } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { FaSpinner } from '../../../utils/Fontawesome';

// translate
import tJson from '../../../utils/TJson';
import { marker } from 'leaflet';
import { type } from 'jquery';
import { PmStatusColor } from './PmStatusColor';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});
const zonePm25 = [15, 25, 37.5, 75, 1000];
const zonePm10 = [50, 80, 120, 180, 1000];

class PmChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      max: 0,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      provinceId: props.provinceId,
      teleStation: [],
      airQuality: props.type,
      startDate: moment().subtract(3, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    const { type } = this.props;
    this.setState({ isLoading: true, airQuality: type });
    this.getProvince();
    this.getStation();
    this.renderChart();
  }

  getChartOptions() {
    const { t } = this.props;
    this.options = {
      chart: {
        type: 'column',
        zoomType: 'x',
        renderTo: 'graph',
        plotBackgroundColor: {
          linearGradient: {
            x1: 0, y1: 0, x2: 0, y2: 1,
          },
          stops: [
            [0, '#2d6ba1'], // top color
            [1, '#184d77'], // bottom color
          ],
        },
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: false,
      },
      xAxis: {
      },
      plotOptions: {
        column: {
          borderWidth: 0.2,
          borderColor: '#000000',
        },
      },
      yAxis: {
        title: {
          text: `${t('PM2.5')}`,
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          format: '{value:.2f}',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      legend: {
        enabled: true,
        symbolRadius: 0,
        symbolHeight: 15,
        symbolWidth: 7,
        squareSymbol: false,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ` ${t('µg/m³')}`,
      },
      series: [
        {
          name: t('PM 2.5'),
          type: 'spline',
          fillOpacity: 0.3,
          yAxis: 0,
          data: [],
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getProvince() {
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataProvince = result.province.data.sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));
        this.setState({
          dataProvince,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getStation() {
    const { provinceId, id } = this.state;
    const url = `${process.env.MIX_API_URL}frontend/shared/pm25_station?province_code=${provinceId}`;
    fetch(`${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const teleStation = result.data;
        if (teleStation.length > 0) {
          const stationByClick = teleStation.filter(
            d => parseInt(d.station_id, 10) === parseInt(id, 10),
          )[0];
          if (stationByClick) {
            this.setState({ id: stationByClick.station_id });
          } else {
            this.setState({ id: teleStation[0].station_id });
          }
        } else {
          this.setState({ id: '' });
          this.renderChart();
        }
        this.setState({
          teleStation,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value }, () => {
      this.getStation();
    });
  };

  handleAirQualityChange = event => {
    this.setState({ airQuality: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    const { today } = this.state;

    if (moment(date).format('YYYY-MM-DD') === today) {
      this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => {
        this.handleChart();
      });
    } else {
      const dt = `${moment(date).format('YYYY-MM-DD')}`;
      this.setState({ endDate: moment(dt).format('YYYY-MM-DD') }, () => {
        this.handleChart();
      });
    }
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  renderChart = () => {
    const {
      id, startDate, endDate, airQuality,
    } = this.state;
    const { t } = this.props;
    let segment;
    let namePm;
    let lType;
    let text;
    let zoneColor = [];
    if ([airQuality].includes('pm25')) {
      segment = 'pm25_graph';
      namePm = 'PM 2.5';
      lType = 'layerPm25';
      zoneColor = zonePm25;
    } else {
      segment = 'pm10_graph';
      namePm = 'PM 10';
      lType = 'layerPm10';
      zoneColor = zonePm10;
    }

    const isWithin31Days = moment(endDate).diff(startDate, 'days') < 31;
    if (isWithin31Days) {
      text = 'ไม่เกิน 31 วัน';
    } else {
      text = 'วันที่เริ่มต้น และ วันที่สิ้นสุด เกิน 31 วัน กรุณาค้นหาใหม่';
      alert(text);
      return null;
    }
    // https://api-v3.thaiwater.net/api/v1/thaiwater30/public/pm25_graph?station_id=685656&start_date=2023-02-21&end_date=2023-02-22
    if (id) {
      fetch(`${process.env.MIX_API_URL}public/${segment}?station_id=${id}&start_date=${startDate}&end_date=${endDate}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          const dataSeries = [];
          const avgSeries = [];
          if (result.result === 'OK' && result.data.length > 0) {
            const dataPm = result.data;
            const avgPm = result.data;
            // // find max, min
            const max = maxBy(dataPm, 'value') === undefined ? 0 : maxBy(dataPm, 'value').value;

            this.setState({
              max,
            });
            let ts = null;
            let val = null;
            const dateTime = [];
            const dataVal = [];
            const avgVal = [];
            let countArr = [0, 0, 0, 0, 0];
            let tempColor;
            let countSum = 0;
            moment.locale('en-EN');
            dataPm.map(n => {
              tempColor = PmStatusColor(n.value,lType)
              val = parseFloat(n.value);
              ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
              dataSeries.push([ts, val]);
              dateTime.push(moment(n.datetime, 'YYYY-MM-DD HH:mm').format('DD MMM, h:mm A'));
              dataVal.push(n.value);
              if(n.value !== null){
                countArr[tempColor.color.index] = countArr[tempColor.color.index] + 1
              }
              return dataSeries;
            });
            countSum = countArr.reduce((a, b) => a + b, 0)
            
            avgPm.map(n => {
              val = parseFloat(n.avg_24h);
              ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
              avgSeries.push([ts, val]);
              dateTime.push(moment(n.datetime, 'YYYY-MM-DD HH:mm').format('DD MMM, h:mm A'));
              avgVal.push(n.avg_24h);
              return avgSeries;
            });

            const dataPM = {
              zoneAxis: "y",
              zones: [
                {
                  value: zoneColor[0],
                  color: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1,
                    },
                    stops: [
                      [0, "#69C8FA"],
                      [1, "#98d5f5"],
                    ],
                  },
                },
                {
                  value: zoneColor[1],
                  color: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1,
                    },
                    stops: [
                      [0, "#9ECC63"],
                      [1, "#cbf595"],
                    ],
                  },
                },
                {
                  value: zoneColor[2],
                  color: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1,
                    },
                    stops: [
                      [0, "#FFFD54"],
                      [1, "#f7f67c"],
                    ],
                  },
                },
                {
                  value: zoneColor[3],
                  color: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1,
                    },
                    stops: [
                      [0, "#F2A43C"],
                      [1, "#fad5a5"],
                    ],
                  },
                },
                {
                  value: zoneColor[4],
                  color: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1,
                    },
                    stops: [
                      [0, "#DD554D"],
                      [1, "#fa938c"],
                    ],
                  },
                },
              ],
              name: `${namePm}`,
              data: dataVal,
              // showInLegend: false,
              // legendSymbol: "rectangle",
              color: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 1,
                    y2: 0
                },
                stops: [
                  [countArr[0]/countSum, '#69C8FA'],
                  [(countArr[0]+countArr[1])/countSum, '#92d050'],
                  [(countArr[0]+countArr[1]+countArr[2])/countSum, '#FFFD54'],
                  [(countArr[0]+countArr[1]+countArr[2]+countArr[3])/countSum, '#F2A43C'],
                  [(countArr[0]+countArr[1]+countArr[2]+countArr[3]+countArr[4])/countSum, '#ff0000'],
                ],
            },
              // legendSymbolColor: '#880088'
            };

            const dataAvgPM = {
              name: `${t("ค่าเฉลี่ย 24 ชั่วโมงย้อนหลัง")}`,
              type: 'spline',
              data: avgVal,
              connectNulls : true,
              color: '#00b7ff',
              marker: {
                enabled: false,
                radius: 3,
              }
            };
            // // set chart options
            const chartOptions = this.getChartOptions();
            chartOptions.xAxis.categories = dateTime;
            chartOptions.yAxis.title.text = `${namePm} (µg/m³)`;
            chartOptions.series = [dataPM, dataAvgPM];
            this.setState({ chartOptions, isLoading: false });
          } else if (result.data.RespMessage === 'limit date range') {
            alert('วันที่เริ่มต้น และ วันที่สิ้นสุด เกิน 31 วัน กรุณาค้นหาใหม่');
          }
        })
        .catch(error => this.setState({ error, isLoading: false }));
    } else {
      this.setState({ max: 0, isLoading: false });
    }
  }

  render() {
    const {
      dataProvince, id, max, provinceId, teleStation, startDate, endDate, today, airQuality,
    } = this.state;
    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
            {error}
          </Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    let chart = '';
    let info = '';
    if (max === 0) {
      chart = <Paper className={classes.paper}>{t('ไม่มีข้อมูลในช่วงเวลาที่ท่านเลือก')}</Paper>;
      info = '';
    } else {
      chart = <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {'*'}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    }

    return (
      <Fragment key="key">
        {errorMsg}
        <Grid container spacing={1}>
          <Grid item xs sm={5} md>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('คุณภาพอากาศ')}
              </InputLabel>
              <Select value={airQuality} onChange={this.handleAirQualityChange}>
                <MenuItem value="pm25">
                  PM 2.5
                </MenuItem>
                <MenuItem value="pm10">
                  PM 10
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs sm={5} md>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select value={provinceId} onChange={this.handleProvinceChange}>
                {dataProvince.map(row => (
                  <MenuItem key={uniqueId()} value={row.province_code}>
                    {`${tJson(row.province_name, 'จ.')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs sm={7} md>
            <FormControl>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <Select value={id} onChange={this.handleStationChange}>
                {teleStation.filter(d => d.station_name.th !== '').map(row => (
                  <MenuItem key={row.station_id} value={row.station_id}>
                    {`${tJson(row.station_name, 'สถานี')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่เริ่มต้น')}
                  views={['year', 'month', 'date']}
                  format="YYYY-MM-DD"
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {chart}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

PmChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(PmChart);
