/* eslint-disable no-unused-vars */
import React, { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId,
  orderBy,
} from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Hidden } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Label } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import CircularProgressbar from 'react-circular-progressbar';
import Box from '@material-ui/core/Box';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// map
import L from 'leaflet';
import {
  Map, Marker, Popup, TileLayer, Tooltip, GeoJSON, LayerGroup, LayersControl, WMSTileLayer, CircleMarker, Pane,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Control from 'react-leaflet-control';
import 'react-leaflet-fullscreen/dist/styles.css';
import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { FaChartLine, FaSpinner } from '../../../utils/Fontawesome';
import LeafletFullScreenFix from '../../../utils/LeafletFullScreenFix';

// translate
import tJson from '../../../utils/TJson';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import { styles } from '../../../styles/Style';
import { PmStatusColor, PmIcon } from './PmStatusColor';
import PmChart from './PmChart';
import WeatherContext from '../../../contexts/weatherContext';
import './stylespm.scss';
import CurrentLocation from '../../currentlocation/CurrentLocation';

const { BaseLayer, Overlay } = LayersControl; // BaseLayer
const initPathWMS = process.env.MIX_APP_WMS;
const initPath = process.env.MIX_APP_PATH;

const options = [
  { value: 'group1', label: 'Group 1' },
  { value: 'group2', label: 'Group 2' },
];

const createClusterCustomIcon = cluster => {
  const markers = cluster.getAllChildMarkers();
  let mean = 0;
  let sum = 0;
  const dataArray = [];
  markers.map(marker => {
    sum += marker.options.value;
    dataArray.push(marker.options.value);
    return sum;
  });
  mean = sum / markers.length;

  // const value = mean;
  const value = Math.max(...dataArray);

  let colorClass = 'marker-cluster-sky';
  if (value > 75) {
    colorClass = 'marker-cluster-redpink';
  } else if (value > 37.5) {
    colorClass = 'marker-cluster-orange';
  } else if (value > 25) {
    colorClass = 'marker-cluster-yellow';
  } else if (value > 15) {
    colorClass = 'marker-cluster-oldgreen';
  } else if (value > 0) {
    colorClass = 'marker-cluster-sky';
  } else {
    colorClass = 'marker-cluster-sky';
  }

  let x = 45;
  let y = 45;
  if (cluster.getChildCount() < 10) {
    x = 50;
    y = 50;
  } else if (cluster.getChildCount() > 100) {
    x = 50;
    y = 50;
  }

  return L.divIcon({
    html: `<div><span>${value}µg/m³</span></div>`,
    className: `leaflet-marker-icon marker-cluster ${colorClass} leaflet-zoom-animated leaflet-interactive`,
    iconSize: L.point(x, y, true),
  });
};
const createClusterCustomIcon10 = cluster => {
  const markers = cluster.getAllChildMarkers();
  let mean = 0;
  let sum = 0;
  const dataArray = [];
  markers.map(marker => {
    sum += marker.options.value;
    dataArray.push(marker.options.value);
    return sum;
  });
  mean = sum / markers.length;

  // const value = mean;
  const value = Math.max(...dataArray);

  let colorClass = 'marker-cluster-sky';
  if (value > 181) {
    colorClass = 'marker-cluster-redpink';
  } else if (value > 120) {
    colorClass = 'marker-cluster-orange';
  } else if (value > 80) {
    colorClass = 'marker-cluster-yellow';
  } else if (value > 50) {
    colorClass = 'marker-cluster-oldgreen';
  } else {
    colorClass = 'marker-cluster-sky';
  }

  let x = 45;
  let y = 45;
  if (cluster.getChildCount() < 10) {
    x = 50;
    y = 50;
  } else if (cluster.getChildCount() > 100) {
    x = 50;
    y = 50;
  }

  return L.divIcon({
    html: `<div><span>${value}µg/m³</span></div>`,
    className: `leaflet-marker-icon marker-cluster ${colorClass} leaflet-zoom-animated leaflet-interactive`,
    iconSize: L.point(x, y, true),
  });
};

class PmMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.pm25Layer = createRef();
    this.pm10Layer = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryRegion = createRef();

    this.state = {
      error: null,
      height: this.setHeightMap(),
      zoom: this.setZoomMap(),
      geojsonRegion: [],
      geojsonProvince: [],
      geojsonBasin: [],
      loading: false,
      activePopup: null,
      datasPm25: [],
    };
    this.getRegionStyle = this.getRegionStyle.bind(this);
    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getGeoJson = this.getGeoJson.bind(this);
    this.getProvinceGeoJson = this.getProvinceGeoJson.bind(this);
    this.renderCanvas = L.canvas({ padding: 0.1 });
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;
    this.mapLayerControl();
    this.getGeoJson();
    this.getProvinceGeoJson();
    this.getDataPm();
    this._mounted = true;
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  componentDidUpdate(prevProps) {
    const { regionId } = this.props;
    const { datasPm25 } = this.state;
    // thailand
    if (regionId === "" && prevProps.regionId !== "") {
      const { location, zoom } = this.props;
      this.map.setView(location, zoom);
    }

    let mainObj = this.refBoundaryRegion.current;
    if (regionId.length > 1) {
      mainObj = this.refBoundaryProvince.current;
    }

    if (mainObj !== null) {
      // eslint-disable-next-line no-underscore-dangle
      const obj = mainObj.leafletElement._layers;

      if (regionId !== prevProps.regionId) {
        // get feature
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          if ({}.hasOwnProperty.call(obj, key)) {
            if (regionId.length > 1 && regionId.length < 3) {
              const provCode = obj[key].feature.properties.prov_code;
              if (provCode.toString() === regionId) {
                this.map.fitBounds(obj[key].getBounds());
              }
            } else if (regionId.length > 3 && datasPm25.length > 0) {
              const currentOuterRegion = datasPm25.filter(
                (data) => data.geocode.province_code === regionId
              );
              let sumOfLat = 0;
              let sumOfLong = 0;
              let totalStation = 0;

              currentOuterRegion.forEach((data) => {
                sumOfLat += data.station.tele_station_lat;
                sumOfLong += data.station.tele_station_long;
                totalStation += 1;
              });
              if (this.map) {
                this.map.setView(
                  [sumOfLat / totalStation, sumOfLong / totalStation],
                  10
                );
              }
            } else {
              const regionCode = obj[key].feature.properties.code;
              if (regionCode.toString() === regionId) {
                this.map.fitBounds(obj[key].getBounds());
              }
            }
          }
        }
      }
    }
  }

  getDataPm() {
    fetch(`${process.env.MIX_API_URL}public/thaiwater/weather`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong ...");
      })
      .then(async (result) => {
        if (
          result.pm25.data !== null &&
          result.pm25.data.length > 0 &&
          result.pm25?.data
        ) {
          const datasPm = result.pm25?.data;
          this.setState({
            datasPm25: datasPm,
          });

          if (result.pm10?.data) {
            this.setState({});
          }
        }
      })
      .catch((error) => this.setState({ error }));
    return true;
  }

  getGeoJson() {
    fetch(`${initPath}json/boundary/region_tmd.json`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("loading region Something went wrong ...");
      })
      .then((result) => {
        this.setState({
          geojsonRegion: result,
        });
      })
      .catch((error) => this.setState({ error }));
  }

  getProvinceGeoJson() {
    fetch(`${initPath}json/boundary/thailand.json`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("loading region Something went wrong ...");
      })
      .then((result) => {
        this.setState({
          geojsonProvince: result,
        });
      })
      .catch((error) => this.setState({ error }));
  }

  getRegionStyle(feature) {
    const { regionId } = this.props;
    if (regionId !== "0") {
      if (feature.properties.code === regionId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: "#FFF",
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: "#FFF",
      fillOpacity: 0,
    };
  }

  getProvinceStyle(feature) {
    const { regionId } = this.props;
    if (regionId !== "0") {
      if (feature.properties.prov_code === regionId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: "#FFF",
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: "#FFF",
      fillOpacity: 0,
    };
  }

  setIcon = (val) => {
    const status = PmStatusColor(val);

    return status.icon;
  };

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 724;
    // let heightMap = 641;
    if (isWidthDown("xl", width) && isWidthUp("lg", width)) {
      heightMap = 724;
    } else if (isWidthDown("lg", width) && isWidthUp("md", width)) {
      heightMap = 641;
    } else if (isWidthDown("md", width) && isWidthUp("sm", width)) {
      heightMap = 700;
    } else if (isWidthDown("sm", width) && isWidthUp("xs", width)) {
      heightMap = 550;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown("sm", width) && isWidthUp("xs", width)) {
      zoom = 5.5;
    }
    return zoom;
  }

  // add marker reference
  bindMarker = (id, index) => (ref1) => {
    const { markersPm25 } = this.props;
    if (ref1) {
      markersPm25[id] = ref1.leafletElement;
      markersPm25[id].addEventListener("click", (e) => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  };

  bindMarker2 = (id) => (ref2) => {
    const { markersPm10 } = this.props;
    if (ref2) {
      markersPm10[id] = ref2.leafletElement;
      markersPm10[id].addEventListener("click", (e) => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  };

  handleChart = (handler, data, type) => (e) => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: t("กราฟแสดงคุณภาพอากาศ"),
      content: (
        <PmChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
          type={type}
        />
      ),
    };
    handler(params);
  };

  CircleColor = (index) => {
    const { classes } = this.props;
    const markerTooltipColor = [
      classes.markerTooltipPm25,
      classes.markerStatusPm50,
      classes.markerStatusPm100,
      classes.markerStatusPm200,
      classes.markerStatusPmOver200,
    ];
    return markerTooltipColor[index];
  };

  handleClosePopup = () => {
    console.log("close");
    this.setState({ activePopup: null });
  };

  handlePopupOpen = (popupId) => {
    console.log("activePopup");
    this.setState({ activePopup: popupId });
  };

  eachDataPm25 = (data, i) => {
    const { datas } = this.state;
    const { location, zoom, classes, t, selectedLayer, dataPm25 } = this.props;

    if (
      data.station.tele_station_lat &&
      data.station.tele_station_long &&
      data.pm25_value != null
    ) {
      const statusColor = PmStatusColor(data.pm25_value, selectedLayer);

      let locationText = "";
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, "ต.")} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, "อ.")} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `${tJson(data.geocode.province_name, "จ.")}`;
      }

      // marker position
      const position = [
        parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long),
      ];

      // marker icons
      const value = data.pm25_value;
      let colorClass = "marker-cluster-sky";
      if (value > 75) {
        colorClass = "marker-cluster-redpink";
      } else if (value > 37.5) {
        colorClass = "marker-cluster-orange";
      } else if (value > 25) {
        colorClass = "marker-cluster-yellow";
      } else if (value > 15) {
        colorClass = "marker-cluster-oldgreen";
      } else if (value > 0) {
        colorClass = "marker-cluster-sky";
      } else {
        colorClass = "marker-cluster-sky";
      }
      let space = "";
      if (value < 10) {
        space = "&nbsp&nbsp";
      } else {
        space = "&nbsp";
      }
      const icon = L.divIcon({
        html: `${space}${value} µg/m³`,
        className: `leaflet-marker-icon marker-cluster ${colorClass}`,
        iconSize: L.point(60, 20, true),
      });

      return (
        <div key={i}>
          <Marker
            id={data.station.id}
            key={i}
            center={[
              Number(data.station.tele_station_lat),
              Number(data.station.tele_station_long),
            ]}
            position={position}
            icon={icon}
            ref={this.bindMarker(data.station.id)}
            location={location}
            zoom={zoom}
            datas={dataPm25}
            value={data.pm25_value}
            classes={classes}
            zIndexOffset={i}
          >
            <Popup autoPan={false} autoClose closeOnClick closeOnEscapeKey>
              <Box py={1} pb={0}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={classes.hiddenLine}
                        padding="none"
                        align="center"
                        colSpan={2}
                        style={{
                          border: "1px solid #eee",
                          borderBottom: "none",
                        }}
                      >
                        <Box
                          display="flex"
                          p={1}
                          width="100%"
                          justifyContent="center"
                          style={{
                            backgroundColor: statusColor.color.statusColor,
                            color: statusColor.color.constrastColor,
                            fontSize: 16,
                          }}
                        >
                          <Box
                            display="flex"
                            alignContent="center"
                            alignItems="center"
                          >
                            <PmIcon val={data.pm25_value} type="pm25" />
                            <Typography
                              variant="h5"
                              style={{ fontWeight: 500 }}
                            >
                              {statusColor.color.label}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.hiddenLine}
                        align="center"
                        colSpan={2}
                        style={{ border: "1px solid #eee", borderTop: "none" }}
                      >
                        <Box
                          pt={1}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignContent="center"
                            alignItems="center"
                          >
                            <Box sx={{ py: 0, px: 1 }}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: "1.2rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {t("PM 2.5")}
                              </Typography>
                            </Box>
                            <Box sx={{ py: 0, px: 1 }} mb={1}>
                              <Typography
                                variant="h6"
                                style={{
                                  color: statusColor.color.textColor, // สีเหลือง กับ สีเขียว จะไม่ใช่สีเดียวกับสถานะ
                                  fontSize: "2.5em",
                                  alignContent: "flex-start",
                                }}
                              >
                                {`${data.pm25_value}`}
                              </Typography>
                            </Box>
                            <Box sx={{ py: 0, px: 1 }}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: "0.8em",
                                  justifyContent: "flex-start",
                                  display: "flex",
                                }}
                              >
                                {t("µg/m")}
                                <sup>3</sup>
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: "1em",
                              fontWeight: 400,
                              justifyContent: "flex-start",
                              display: "flex",
                            }}
                          >
                            {(data.pm25_avg_24h !== null &&`${t("เฉลี่ย 24 ชม.")} ${data.pm25_avg_24h} ${t("µg/m")}`)}{(data.pm25_avg_24h !== null && <sup>3</sup>)}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding="none" align="center" colSpan={2}>
                        <Box style={{ whiteSpace: "pre-wrap" }}>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 500 }}
                          >
                            {`${tJson(
                              data.station.tele_station_name,
                              "สถานี"
                            )}`}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 500 }}
                            display="block"
                          >
                            {`${tJson(data.geocode.amphoe_name, "อ.")}`}{" "}
                            {`${tJson(data.geocode.province_name, "จ.")}`}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.hiddenLine}
                        style={{ paddingLeft: "0px" }}
                      >
                        <Typography
                          variant="caption"
                          color="secondary"
                          style={{ fontSize: "0.7em" }}
                        >
                          {t("ข้อมูล")}{" "}
                          {`: ${tJson(data.agency.agency_name, "หน่วยงาน")}`}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="secondary"
                          display="block"
                          style={{ fontSize: "0.7em" }}
                        >
                          {`ล่าสุด : ${data.pm25_datetime} ${t("น")}.`}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          width="100%"
                          style={{ minHeight: 34 }}
                        >
                          <WeatherContext.Consumer>
                            {({ handleModalOpen }) => (
                              <Button
                                color="secondary"
                                onClick={this.handleChart(
                                  handleModalOpen,
                                  data,
                                  "pm25"
                                )}
                                title={t("แสดงกราฟ")}
                                style={{ padding: 0 }}
                              >
                                <FaChartLine className={classes.rightIcon} />
                              </Button>
                            )}
                          </WeatherContext.Consumer>
                          <Box />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Popup>
            <Hidden smDown>
              <Tooltip
                title={`${tJson(data.station.tele_station_name, "สถานี")}`}
              >
                <span>
                  <strong>
                    {`${tJson(data.station.tele_station_name, "สถานี")}`}
                  </strong>
                  <br />
                  {`${tJson(data.geocode.tumbon_name, "ต.")} ${tJson(
                    data.geocode.amphoe_name,
                    "อ."
                  )} `}
                  {`${tJson(data.geocode.province_name, "จ.")}`}
                  <br />
                  {`${t("PM 2.5")}: ${data.pm25_value} µg/m`}
                  <sup>3</sup>
                  <br />
                  {`${tJson(data.agency.agency_name, "หน่วยงาน ")}`}
                </span>
              </Tooltip>
            </Hidden>
          </Marker>
        </div>
      );
    }
    return false;
  };

  eachDataPm10 = (data, i) => {
    const { datas } = this.state;
    const { location, zoom, classes, t, selectedLayer, dataPm10 } = this.props;

    if (
      data.station.tele_station_lat &&
      data.station.tele_station_long &&
      data.pm10_value != null
    ) {
      const statusColor = PmStatusColor(data.pm10_value, selectedLayer);

      let locationText = "";
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, "ต.")} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, "อ.")} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `${tJson(data.geocode.province_name, "จ.")}`;
      }

      // marker position
      const position = [
        parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long),
      ];

      // marker icons
      const value = data.pm10_value;
      let colorClass = "marker-cluster-sky";
      if (value > 181) {
        colorClass = "marker-cluster-redpink";
      } else if (value > 120) {
        colorClass = "marker-cluster-orange";
      } else if (value > 80) {
        colorClass = "marker-cluster-yellow";
      } else if (value > 50) {
        colorClass = "marker-cluster-oldgreen";
      } else {
        colorClass = "marker-cluster-sky";
      }
      let space = "";
      if (value < 10) {
        space = "&nbsp&nbsp";
      } else {
        space = "&nbsp";
      }
      const icon = L.divIcon({
        html: `${space}${value} µg/m³`,
        className: `leaflet-marker-icon marker-cluster ${colorClass}`,
        iconSize: L.point(60, 20, true),
      });

      return (
        <div key={i}>
          <Marker
            id={data.station.id}
            center={[
              Number(data.station.tele_station_lat),
              Number(data.station.tele_station_long),
            ]}
            key={i}
            position={position}
            icon={icon}
            ref={this.bindMarker2(data.station.id)}
            location={location}
            zoom={zoom}
            datas={dataPm10}
            value={data.pm10_value}
            classes={classes}
            zIndexOffset={i}
          >
            <Popup autoPan={false}>
              <Box p={1} pb={0}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={classes.hiddenLine}
                        padding="none"
                        align="center"
                        colSpan={2}
                        style={{
                          border: "1px solid #eee",
                          borderBottom: "none",
                        }}
                      >
                        <Box
                          display="flex"
                          p={1}
                          width="100%"
                          justifyContent="center"
                          style={{
                            backgroundColor: statusColor.color.statusColor,
                            color: statusColor.color.constrastColor,
                            fontSize: 16,
                          }}
                        >
                          <Box
                            display="flex"
                            alignContent="center"
                            alignItems="center"
                          >
                            <PmIcon val={data.pm10_value} type="pm10" />
                            <Typography
                              variant="h5"
                              style={{ fontWeight: 500 }}
                            >
                              {statusColor.color.label}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.hiddenLine}
                        align="center"
                        colSpan={2}
                        style={{ border: "1px solid #eee", borderTop: "none" }}
                      >
                        <Box
                          pt={1}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignContent="center"
                            alignItems="center"
                          >
                            <Box sx={{ py: 0, px: 1 }}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: "1.2rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {t("PM 10")}
                              </Typography>
                            </Box>
                            <Box sx={{ py: 0, px: 1 }} mb={1}>
                              <Typography
                                variant="h6"
                                style={{
                                  color: statusColor.color.textColor, // สีเหลือง กับ สีเขียว จะไม่ใช่สีเดียวกับสถานะ
                                  fontSize: "3em",
                                  alignContent: "flex-start",
                                }}
                              >
                                {`${data.pm10_value}`}
                              </Typography>
                            </Box>
                            <Box sx={{ py: 0, px: 1 }}>
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: "0.8em",
                                  justifyContent: "flex-start",
                                  display: "flex",
                                }}
                              >
                                {t("µg/m")}
                                <sup>3</sup>
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: "1em",
                              fontWeight: 400,
                              justifyContent: "flex-start",
                              display: "flex",
                            }}
                          >
                            {(data.pm10_avg_24h !== null &&`${t("เฉลี่ย 24 ชม.")} ${data.pm10_avg_24h} ${t("µg/m")}`)}{(data.pm10_avg_24h !== null && <sup>3</sup>)}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding="none" align="center" colSpan={2}>
                        <Box p={1}>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 500, whiteSpace: "wrap" }}
                          >
                            {`${tJson(
                              data.station.tele_station_name,
                              "สถานี"
                            )}`}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 500, whiteSpace: "normal" }}
                            display="block"
                          >
                            {`${tJson(data.geocode.amphoe_name, "อ.")}`}{" "}
                            {`${tJson(data.geocode.province_name, "จ.")}`}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.hiddenLine}
                        style={{ paddingLeft: "0px" }}
                      >
                        <Typography
                          variant="caption"
                          color="secondary"
                          style={{ fontSize: "0.7em" }}
                        >
                          {t("ข้อมูล")}{" "}
                          {`: ${tJson(data.agency.agency_name, "หน่วยงาน")}`}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="secondary"
                          display="block"
                          style={{ fontSize: "0.7em" }}
                        >
                          {`ล่าสุด : ${data.pm10_datetime} ${t("น")}.`}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          width="100%"
                          style={{ minHeight: 34 }}
                        >
                          <WeatherContext.Consumer>
                            {({ handleModalOpen }) => (
                              <Button
                                color="secondary"
                                onClick={this.handleChart(
                                  handleModalOpen,
                                  data,
                                  "pm10"
                                )}
                                title={t("แสดงกราฟ")}
                                style={{ padding: 0 }}
                              >
                                <FaChartLine className={classes.rightIcon} />
                              </Button>
                            )}
                          </WeatherContext.Consumer>
                          <Box />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Popup>
            <Hidden smDown>
              <Tooltip
                title={`${tJson(data.station.tele_station_name, "สถานี")}`}
              >
                <span>
                  <strong>
                    {`${tJson(data.station.tele_station_name, "สถานี")}`}
                  </strong>
                  <br />
                  {`${tJson(data.geocode.tumbon_name, "ต.")} ${tJson(
                    data.geocode.amphoe_name,
                    "อ."
                  )} `}
                  {`${tJson(data.geocode.province_name, "จ.")}`}
                  <br />
                  {`${t("PM 10")}: ${data.pm10_value} µg/m`}
                  <sup>3</sup>
                  <br />
                  {`${tJson(data.agency.agency_name, "หน่วยงาน ")}`}
                </span>
              </Tooltip>
            </Hidden>
          </Marker>
        </div>
      );
    }
    return false;
  };

  renderMarker = () => {
    const { dataPm25, dataPm10, selectedLayer } = this.props;
    let dataPm;
    if (selectedLayer === "layerPm25") {
      dataPm = "";
    } else if (selectedLayer === "layerPm10") {
      dataPm = "";
    }
    return dataPm;
  };

  mapLayerControl = () => {
    const { dataPm25, dataPm10, t, handleBaseLayerChange, selectedLayer } =
      this.props;
    const { renderPm10 } = this.setState;
    return (
      <>
        <LayerGroup ref={this.pm25Layer}>{this.renderMarker()}</LayerGroup>
        <LayerGroup ref={this.pm10Layer}>{this.renderMarker()}</LayerGroup>
      </>
    );
  };

  render() {
    const {
      error,
      height,
      zoom,
      geojsonProvince,
      geojsonRegion,
      geojsonBasin,
      loading,
    } = this.state;
    const {
      location,
      classes,
      i18n,
      selectedLayer,
      handleBaseLayerChange,
      dataPm25,
      dataPm10,
    } = this.props;

    let errorMsg = "";
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }
    let loadingMsg = "";
    if (loading === true) {
      loadingMsg = <FaSpinner size={70} />;
    }

    return (
      <>
        {errorMsg}
        {loadingMsg}
        <Map
          id="map"
          tap
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          gestureHandling
        >
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </BaseLayer>
            <BaseLayer name="Esri_WorldStreetMap">
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />
            </BaseLayer>
          </LayersControl>

          <Control position="topright">
            <select onChange={handleBaseLayerChange}>
              <option value="layerPm25">ฝุ่น PM 2.5</option>
              <option value="layerPm10">ฝุ่น PM 10</option>
            </select>
          </Control>

          {selectedLayer === "layerPm25" && (
            <LayerGroup>
              <MarkerClusterGroup
                chunkedLoading
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={10}
                showCoverageOnHover
                spiderfyDistanceMultiplier={1}
                removeOutsideVisibleBounds
                iconCreateFunction={createClusterCustomIcon}
              >
                {dataPm25.slice(0, 100).map(this.eachDataPm25)}
                {orderBy(dataPm25, ["pm25_value"], ["asc"]).map(
                  this.eachDataPm25
                )}
              </MarkerClusterGroup>
            </LayerGroup>
          )}

          {selectedLayer === "layerPm10" && (
            <LayerGroup>
              <MarkerClusterGroup
                chunkedLoading
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={10}
                showCoverageOnHover
                spiderfyDistanceMultiplier={1}
                removeOutsideVisibleBounds
                iconCreateFunction={createClusterCustomIcon10}
              >
                {dataPm10.slice(0, 100).map(this.eachDataPm10)}
                {orderBy(dataPm10, ["pm10_value"], ["asc"]).map(
                  this.eachDataPm10
                )}
              </MarkerClusterGroup>
            </LayerGroup>
          )}

          <GeoJSON
            key={uniqueId()}
            data={geojsonRegion}
            style={this.getRegionStyle}
            ref={this.refBoundaryRegion}
            onEachFeature={this.onEachRegionFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            onEachFeature={this.onEachProvinceFeature}
          />

          {i18n.language === "th" && this.mapLayerControl()}

          {i18n.language === "en" && this.mapLayerControl()}

          {i18n.language === "jp" && this.mapLayerControl()}

          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />
        </Map>
      </>
    );
  }
}

PmMap.propTypes = {
  classes: PropTypes.object.isRequired,
  dataPm25: PropTypes.array.isRequired,
  dataPm10: PropTypes.array.isRequired,
  regionId: PropTypes.string.isRequired,
  markersPm25: PropTypes.array.isRequired,
  markersPm10: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleBaseLayerChange: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
  selectedLayer: PropTypes.string.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(PmMap);
