import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId, isEmpty } from 'lodash';

import { withTranslation } from 'react-i18next';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// map
import L from 'leaflet';

import {
  Map, Marker, TileLayer, GeoJSON, LayerGroup, LayersControl, Tooltip, Popup, WMSTileLayer,
} from 'react-leaflet';
import Control from 'react-leaflet-control';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Hidden } from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InfoIcon from '@material-ui/icons/Info';

import { styles } from '../../styles/Style';
import { FaChartLine } from '../../utils/Fontawesome';

import iconImage from '../widgets/map/iconImage';

import { PlottyGeotiffLayer } from './GeotiffLayer';

import tJson from '../../utils/TJson';

// icon
import {
  HiiRainIcon,
  HiiDamL0Icon,
  HiiDamL1Icon,
  HiiDamL2Icon,
  HiiDamL3Icon,
  HiiDamL4Icon,
  HiiDamL5Icon,
} from '../../utils/Icon';

// css
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

// wl
import WlContext from '../../contexts/wlContext';
import WlStatusColor from '../wl/WlStatusColor';

// rainfall
import WlChart from '../wl/WlChart';
import RainfallStatusColor from '../rainfall/RainfallStatusColor';
import RainfallChart from '../rainfall/RainfallChart';

// dam
import DamContext from '../../contexts/damContext';
import DamStatusColor from '../dam/DamStatusColor';
import DamDailyChart from '../dam/DamDailyChart';

import DroughtList from './DroughtList';
import DroughtRainLegend from './DroughtRainLegend';
import DroughtLegend from './DroughtLegend';
import DroughtDamLegend from './DroughtDamLegend';
import CurrentLocation from '../currentlocation/CurrentLocation';

// geotiff
const geoTiffFiles = [
  { file: 'obs_7days.tif', name: 'แผนที่ฝนสะสมย้อนหลัง 7 วันจาก PERSIANN', active: true },
  { file: 'obs_10days.tif', name: 'แผนที่ฝนสะสมย้อนหลัง 10 วันจาก PERSIANN', active: false },
  { file: 'obs_15days.tif', name: 'แผนที่ฝนสะสมย้อนหลัง 15 วันจาก PERSIANN', active: false },
  { file: 'obs_30days.tif', name: 'แผนที่ฝนสะสมย้อนหลัง 30 วันจาก PERSIANN', active: false },
  { file: 'obs_60days.tif', name: 'แผนที่ฝนสะสมย้อนหลัง 60 วันจาก PERSIANN', active: false },
  { file: 'fore_5days.tif', name: 'แผนที่ฝนสะสมคาดการณ์ล่วงหน้า 5 วันจาก WRF-ROMS', active: false },
  { file: 'fore_7days.tif', name: 'แผนที่ฝนสะสมคาดการณ์ล่วงหน้า 7 วันจาก WRF-ROMS', active: false },
];

const geoTiffdrought = [
  { file: 'DRI_latest.tif', name: 'ระดับความเสี่ยงภัยแล้ง', active: false },
];
const geoTiffUrl = 'https://fews2.hii.or.th/model-output/data_portal/drought/';

const geoTiffOptions = {
  band: 0,
  displayMin: 0,
  displayMax: 1300,
  name: 'Rain Forecast Month Volume',
  colorScale: 'droughtFore_7days',
  clampLow: false,
  clampHigh: true,
};
const geoTiffOptionsdrought = {
  band: 0,
  displayMin: 0.01,
  displayMax: 100,
  name: 'Drought Assessment Index',
  colorScale: 'drougth_dvi',
  clampLow: false,
  clampHigh: true,
};

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;

const { Overlay } = LayersControl;
const banner = `${initPath}images/models/banner-drought-monitoring.png`;

class DroughtMonitoringMap extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.refBoundaryThailand = createRef();
    this.layerRainfallYesterda = createRef();
    this.layerDataWaterlevel = createRef();
    this.layerDataDam = createRef();
    this.layerBasin = createRef();
    this.refGeoTiff = createRef();

    this.state = {
      error: '',
      geojsonRiver: [],
      geojsonBasin: [],
      geojsonThailand: [],
      hover: false,
      openDroughtRainLegend: true,
      openDroughtLegend: false,
      openDroughtDamLegend: false,
      droughtDateLatest: '',
      isBoxVisible: true,
      initActive: false,
    };

    this.getGeoJson = this.getGeoJson.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.getDroughtStyle = this.getDroughtStyle.bind(this);
    this.highlightFeature = this.highlightFeature.bind(this);
    this.resetHighlight = this.resetHighlight.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.bindMarker = this.bindMarker.bind(this);
    this.handleBoxToggle = this.handleBoxToggle.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  componentDidMount() {
    const { handleSetMap, width } = this.props;
    this.getGeoJson();
    this._mounted = true;
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '15%';
    document.getElementsByClassName('leaflet-top leaflet-right')[0].style.zIndex = '999999';
    this.map = this.refMap.current.leafletElement;
    handleSetMap(this.map);
    this.getDroughtDateLatest();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  getGeoJson() {
    Promise.all([
      fetch(`${initPath}json/river/river_main.json`),
      fetch(`${initPath}json/boundary/basin.json`),
      fetch(`${initPath}json/boundary/tambon.json`),
    ])
      .then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
      .then(([river, basin, tambon]) => {
        this.setState({ geojsonRiver: river, geojsonBasin: basin, geojsonThailand: tambon });
      })
      .catch(error => this.setState({ error }));
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle = () => ({
    stroke: true,
    color: '#434348',
    weight: 1,
    fillColor: '#b1b1f7',
    opacity: 1,
    fillOpacity: 0.3,
  })

  getDroughtStyle(feature) {
    const { datasDrought } = this.props;
    let droughtStyle = {
      stroke: false,
      color: '#e0801f',
      weight: 1,
      fillColor: '',
      opacity: 1,
      fillOpacity: 0,
    };

    datasDrought.map(dd => {
      if (feature.properties.TAMBON_IDN === dd[0]) {
        droughtStyle = {
          stroke: true,
          color: '#e0801f',
          weight: 1,
          fillColor: '#ee7b4a',
          opacity: 1,
          fillOpacity: 0.6,
        };
      }
    });
    return droughtStyle;
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  // set marker icon based on criteria
  setIconRainfallYesterday = (val, type) => {
    const status = RainfallStatusColor(val, type);

    return status.icon;
  }

  setIconWaterlevel = val => {
    const status = WlStatusColor(val);

    return status.icon;
  }

  formatWl = value => (typeof value === 'undefined' || value == null ? '-' : parseFloat(value).toFixed(2))

  setRainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(2), type);
    return status;
  }

  eachDataRainfallYesterday = (data, i) => {
    const { f, datasRainfallYesterday, t, classes } = this.props;

    if (data.tele_station_lat && data.tele_station_long
      && data.rainfall_value != null) {
      // marker position
      const position = [parseFloat(data.tele_station_lat),
        parseFloat(data.tele_station_long)];
      // marker icons
      const icon = this.setIconRainfallYesterday(parseFloat(data.rainfall_value), 'rainfallYesterday');
      // status colors
      const rainfall = parseFloat(data.rainfall_value).toFixed(0);
      const textRainfallStatus = this.setRainfallStatus(rainfall, 'rainfallYesterday').color.statusColor;
      const rainfallStatusFontColor = this.setRainfallStatus(rainfall, 'rainfallYesterday').color.constrastColor;

      return (
        <Marker
          id={data.tele_station_id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.tele_station_id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {` ${tJson(data.amphoe_name, 'อ.')}`}
                      {` ${tJson(data.province_name, 'จ.')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${data.rainfall_value}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล')}: ${tJson(data.agency_name, ' ')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} น.`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <WlContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallYesterDayChart(handleModalOpen, data, 'rainfallYesterday', datasRainfallYesterday)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </WlContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${tJson(data.agency_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.agency_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.province_name, 'จ.')}`}
                <br />
                {`${tJson(data.amphoe_name, 'อ.')} ${tJson(data.tumbon_name, 'ต.')}`}
                <br />
                {`${t('ข้อมูล')}:${tJson(data.agency_name, ' ')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  handleRainfallYesterDayChart = (handler, data, type, filterData) => e => {
    const { t } = this.props;
    e.preventDefault();

    const params = {
      header: `${t('กราฟฝน')} ${data.tele_station_name.th}`,
      content: <RainfallChart
        id={data.tele_station_id}
        provinceId={data.province_code}
        selectedType={type}
        filterData={filterData}
      />,
    };
    handler(params);
  }

  eachWaterlevelData = (data, i) => {
    const {
      location, zoom, datas, classes, t,
    } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.waterlevel_msl != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long)];

      // marker icons // this.setIconWaterlevel(parseFloat(data.storage_percent));
      const icon = iconImage.waterLevel;

      // status colors
      const statusColor = WlStatusColor(data.storage_percent);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `${tJson(data.geocode.province_name, 'จ.')}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {data.station.tele_station_name.th}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <div style={{ width: '50px', position: 'relative', margin: '0 auto' }}>
                        <CircularProgressbar
                          value={parseInt(data.storage_percent, 10)}
                          text={`${Number.isNaN(parseInt(data.storage_percent, 10))
                            ? 'n/a'
                            : parseInt(data.storage_percent, 10)}%`}
                          styles={{
                            text: {
                              fill: '#000000',
                            },
                            path: {
                              stroke: statusColor.color.statusColor,
                            },
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับน้ำ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.waterlevel_msl)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับตลิ่ง')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.station.min_bank)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับท้องน้ำ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.station.ground_level)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {t('หน่วย')}
                        {' : '}
                        {'('}
                        {t('ม.รทก.')}
                        {')'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.waterlevel_datetime}
                        {' '}
                        {t('น.')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <WlContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleWaterLevelChart(handleModalOpen, data)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </WlContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  handleWaterLevelChart = (handler, data) => e => {
    const { t } = this.props;
    e.preventDefault();

    const params = {
      header: `${t('กราฟระดับน้ำ')} ${tJson(data.station.tele_station_name, ' ')} ${tJson(data.geocode.province_name, 'จ.')}`,
      content: <WlChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }

  highlightFeature = e => {
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 0.6,
      stroke: true,
      color: '#434348',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    layer.bindTooltip(`<h4>${layer.feature.properties.BASIN_T}</h4>`).openTooltip();
  }

  resetHighlight = e => {
    this.refBoundaryBasin.current.leafletElement.resetStyle(e.target);
  }

  highlightThailandFeature = (e, data) => {
    const { t } = this.props;
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 0.6,
      stroke: true,
      color: '#e0801f',
      fillColor: '#ee7b4a',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    layer.bindTooltip(`<h4>${layer.feature.properties.TAM_NAM_T}, ${t('คะแนนความเปราะบางภัยแล้ง')} : ${data[4]}, ${t('ระดับความเสี่ยงภัยแล้ง')} : ${data[5]}</h4>`).openTooltip();
  }

  resetThailandHighlight = e => {
    this.refBoundaryBasin.current.leafletElement.resetStyle(e.target);
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 0.6,
      stroke: true,
      color: '#e0801f',
      fillColor: '#ee7b4a',
      weight: 1,
    });
  }

  onEachBasinFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });
  }

  onEachDroughtFeature = (feature, layer) => {
    const { datasDrought } = this.props;
    datasDrought.map(i => {
      if (i[0] === feature.properties.TAMBON_IDN) {
        layer.on({
          mouseover: e => this.highlightThailandFeature(e, i),
          mouseout: this.resetThailandHighlight,
        });
      }
    });
  }

  getDroughtDateLatest = () => {
    const { datasDrought } = this.props;
    const droughtDateLatest = datasDrought.slice(0, 1).map(i => i[6])[0];
    this.setState({ droughtDateLatest });
  }

  handleBoxToggle = () => this.setState({ hover: true });

  // get dam status
  damStatus = (data, percent) => {
    const status = DamStatusColor(parseFloat(percent).toFixed(0));
    const statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (<span style='color:${status.color.statusColor}'>${parseFloat(percent).toFixed(0)}% รนก.</span>)`;

    return statusText;
  }

  // set marker icon based on criteria
  setIconDam = (val, type) => {
    const status = DamStatusColor(val, type);

    return status.icon;
  }

  setHighlightIconDam = val => {
    const status = DamStatusColor(val);
    let icon = '';

    // setup icon
    if (val > 80) {
      icon = <HiiDamL5Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 60) {
      icon = <HiiDamL4Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 40) {
      icon = <HiiDamL3Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 20) {
      icon = <HiiDamL2Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 0) {
      icon = <HiiDamL1Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else {
      icon = <HiiDamL0Icon style={{ fontSize: '60px' }} />;
    }

    return icon;
  }

  handleDamChart = (handler, data) => e => {
    const { t } = this.props;
    e.preventDefault();

    const params = {
      header: t('กราฟอ่างเก็บน้ำรายวัน กรมชลประทาน'),
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamDailyChart id={data.dam.id} agencyId={12} />,
    };

    handler(params);
  }

  eachDamData = (data, i) => {
    const { classes, t } = this.props;

    if (data.dam.dam_lat && data.dam.dam_long) {
      // marker position
      const position = [parseFloat(data.dam.dam_lat), parseFloat(data.dam.dam_long)];

      // marker icons
      let icon = ''; // this.setIconDam(parseFloat(data.dam_storage_percent).toFixed(0), 'large');
      const damPercent = parseFloat(data.dam_storage_percent).toFixed(0);
      if (damPercent > 100) {
        icon = iconImage.iconDroughtDam30;
      } else if (damPercent >= 80) {
        icon = iconImage.iconDroughtDam80100;
      } else if (damPercent >= 50) {
        icon = iconImage.iconDroughtDam5080;
      } else if (damPercent >= 30) {
        icon = iconImage.iconDroughtDam3050;
      } else {
        icon = iconImage.iconDroughtDam30;
      }

      // highlight icon
      const highlightIcon = this.setHighlightIconDam(parseFloat(data.dam_storage_percent).toFixed(0));
      const color = DamStatusColor(parseFloat(data.dam_storage_percent).toFixed(0));
      const damStorageStatus = this.damStatus(
        data.dam_storage,
        parseFloat(data.dam_storage_percent)
          .toFixed(0),
      );

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {`${tJson(data.dam.dam_name, 'อ่างเก็บน้ำ')} `}
                        {' '}
                        {`${tJson(data.geocode.province_name, 'จ.')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={2} align="center">
                      <Box display="flex" justifyContent="center">
                        <Box>{highlightIcon}</Box>
                        <Box>
                          <Typography variant="h3" style={{ marginTop: '10px' }}>
                            <span style={{ color: color.color.statusColor }}>
                              <strong>
                                {`${parseFloat(data.dam_storage_percent).toFixed(0)}%`}
                              </strong>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <strong>{t('ปริมาณน้ำกักเก็บ (% รนก.)')}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      {t('น้ำไหลลง')}
                      {' / '}
                      {t('ระบาย')}
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{parseFloat(data.dam_inflow).toFixed(2)}</strong>
                      {' '}
                      /
                      {' '}
                      <strong>{data.dam_released}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ปริมาณน้ำกักเก็บ')}</TableCell>
                    <TableCell className={classes.hiddenLine} colSpan={2} align="right">
                      <strong>
                        <div
                          className="text-center"
                          style={{ whiteSpace: 'nowrap' }}
                          dangerouslySetInnerHTML={{ __html: damStorageStatus }}
                        />
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ปริมาณน้ำใช้การได้จริง')}</TableCell>
                    {isEmpty(data.dam_uses_water_percent) === true && (
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>{`${data.dam_uses_water.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${parseFloat(data.dam_uses_water_percent).toFixed(0)}% รนก.)`}</strong>
                      </TableCell>
                    )}
                    {isEmpty(data.dam_uses_water_percent) === false && (
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>-</strong>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      {t('หน่วย (ล้าน ลบ.ม.)')}
                    </TableCell>
                    <TableCell className={classes.hiddenLine} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`${t('วันที่')} ${data.dam_date}`}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <DamContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button color="secondary" onClick={this.handleDamChart(handleModalOpen, data)} title="แสดง">
                            <FaChartLine />
                          </Button>
                        )}
                      </DamContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.dam.dam_name, 'อ่างเก็บน้ำ')}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  handleDatasDroughtList = (datas, header, geojsonThailand) => {
    const {
      handleModalOpen,
    } = this.props;
    const params = {
      header: <p align="center">{`${header}`}</p>,
      content: <DroughtList
        datas={datas}
        header={header}
        geojsonThailand={geojsonThailand}
      />,
    };
    handleModalOpen(params);
  }

  handleClickDroughtRainLegend = () => {
    const { openDroughtRainLegend } = this.state;
    this.setState({ openDroughtRainLegend: openDroughtRainLegend === false });
  };

  handleClickDroughtLegend = () => {
    const { openDroughtLegend } = this.state;
    this.setState({ openDroughtLegend: openDroughtLegend === false });
  };

  handleClickDroughtDamLegend = () => {
    const { openDroughtDamLegend } = this.state;
    this.setState({ openDroughtDamLegend: openDroughtDamLegend === false });
  };

  toggleBox = () => {
    const { isBoxVisible } = this.state;
    this.setState({ isBoxVisible: isBoxVisible === false });

    const layers = document.getElementsByClassName('leaflet-control-layers-expanded');
    if (isBoxVisible === true) {
      for (let index = 0; index < layers.length; index++) {
        document.getElementsByClassName('leaflet-control-layers-expanded')[index].style.display = 'none';
      }
    } else {
      for (let index = 0; index < layers.length; index++) {
        document.getElementsByClassName('leaflet-control-layers-expanded')[index].style.display = 'block';
      }
    }
  };

  handleMouseOut() {
    this.setState({ hover: false });
  }

  render() {
    const {
      error, hover, geojsonRiver, geojsonBasin, geojsonThailand, openDroughtRainLegend, openDroughtLegend,
      droughtDateLatest, openDroughtDamLegend, isBoxVisible, initActive,
    } = this.state;

    const {
      location, zoom, datasRainfallYesterday, dataWaterlevel,
      datasDam, datasDrought, t, width,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <div className="text-center">{error.message}</div>
      );
    }
    const layers = document.getElementsByClassName('leaflet-control-layers-expanded');
    if (isWidthDown('sm', width) && initActive === false) {
      for (let index = 0; index < layers.length; index++) {
        document.getElementsByClassName('leaflet-control-layers-expanded')[index].style.display = 'none';
        if(index > 5 ){
        this.setState({ initActive: true, isBoxVisible: false });
        }
      }
    }

    // eslint-disable-next-line no-unused-vars
    const tooltipStyle = {
      display: hover ? 'block' : 'none',
      maxWidth: '337px',
      padding: '10px',
      wordBreak: 'break-all',
    };

    const { classes } = this.props;
    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          ref={this.refMap}
          style={{
            height: window.height,
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
          }}
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          />
          <LayersControl position="topright" collapsed>
            <Overlay key="rainfallYesterday" name={t('สถานีโทรมาตรตรวจวัดฝน')} checked>
              <LayerGroup ref={this.layerRainfallYesterday}>
                {datasRainfallYesterday.map(this.eachDataRainfallYesterday)}
              </LayerGroup>
            </Overlay>
            <Overlay key="waterlevel" name={t('สถานีโทรมาตรตรวจวัดระดับน้ำ')}>
              <LayerGroup ref={this.layerDataWaterlevel}>
                {dataWaterlevel.map(this.eachWaterlevelData)}
              </LayerGroup>
            </Overlay>
            <Overlay key="dam" name={t('ปริมาณน้ำในเขื่อน')}>
              <LayerGroup ref={this.layerDataDam}>
                {datasDam.map(this.eachDamData)}
              </LayerGroup>
            </Overlay>
            <Overlay key="thailand" name={t('พื้นที่เสี่ยงภัยแล้ง')} checked>
              <LayerGroup ref={this.layerBasin}>
                <GeoJSON
                  key={uniqueId()}
                  data={geojsonThailand}
                  style={this.getDroughtStyle}
                  ref={this.refBoundaryThailand}
                  onEachFeature={(feature, layer) => this.onEachDroughtFeature(feature, layer)}
                />
              </LayerGroup>
            </Overlay>
            <Overlay key="basin" name={t('ลุ่มน้ำ')}>
              <LayerGroup ref={this.layerBasin}>
                <GeoJSON
                  key={uniqueId()}
                  data={geojsonBasin}
                  style={this.getBasinStyle()}
                  ref={this.refBoundaryBasin}
                  onEachFeature={(feature, layer) => this.onEachBasinFeature(feature, layer)}
                />
              </LayerGroup>
            </Overlay>
            <Overlay name={t('เส้นแม่น้ำ')} checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
          </LayersControl>

          <CurrentLocation mapRef={this.refMap} showOnly={false} />

          <Control position="topleft">
            <Hidden mdUp>
              <Button
                onClick={this.toggleBox}
                style={{
                  backgroundColor: 'white',
                  zIndex: 99999,
                }}
              >
                {t('ชั้นข้อมูล')}
                {isBoxVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            </Hidden>
            <Box style={{ background: '#ff0000' }}>
              {geoTiffFiles.map((gtf, key) => (
                <PlottyGeotiffLayer
                  layerRef={this.refGeoTiff}
                  url={geoTiffUrl + gtf.file}
                  options={geoTiffOptions}
                  name={t(gtf.name)}
                  active={gtf.active}
                  key={key}
                />
              ))}
              {geoTiffdrought.map((gtf, key) => (
                <PlottyGeotiffLayer
                  layerRef={this.refGeoTiff}
                  url={geoTiffUrl + gtf.file}
                  options={geoTiffOptionsdrought}
                  name={t(gtf.name)}
                  active={gtf.active}
                  key={key}
                />
              ))}
            </Box>
            <div
              onBlur={() => this.setState({ hover: true })}
              onFocus={() => this.setState({ hover: false })}
              onMouseEnter={this.handleBoxToggle}
              onMouseOut={this.handleMouseOut}
            >
              <InfoIcon />
            </div>
            <Paper>
              <div style={tooltipStyle}>
                {t('ระบบติดตามภัยแล้ง วิเคราะห์พื้นที่สี่ยงภัยแล้ง โดยใช้ดัชนีความเสี่ยงจากภัยแล้ง DRI (Drought Risk Index)  แสดงความน่าจะเป็นของพื้นที่เสี่ยงต่อการเกิดภัยแล้ง โดยวิเคราะห์จากการประเมินความเสี่ยง 2 ลักษณะ คือ ภัยพิบัติ (Hazard)  และความล่อแหลมที่ส่งผลกระทบต่อคน ทรัพย์สิน การดำรงชีวิต และสิ่งแวดล้อม (Vulnerability) ในแต่ละพื้นที่')}
              </div>
            </Paper>
          </Control>

          <GeoJSON
            key={uniqueId()}
            data={geojsonRiver}
            style={this.getRiverStyle()}
          />
          <img src={banner} alt={t('ระบบติดตามภัยแล้ง')} className={classes.styleBanner} />
          <Control position="topright">
            <div
              style={{
                backgroundColor: 'white', padding: '8px', borderRadius: '9px', marginTop: 20,
              }}
              onBlur={() => this.setState({ hover: true })}
              onFocus={() => this.setState({ hover: false })}
              onMouseEnter={this.handleBoxToggle}
              onMouseOut={this.handleMouseOut}
            >
              {droughtDateLatest}
            </div>
          </Control>
          <Control position="topright">
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={() => this.handleDatasDroughtList(datasDrought, t('รายละเอียดพื้นที่เสี่ยงภัยแล้ง'), geojsonThailand)}
              startIcon={<FormatListBulletedIcon />}
            >
              {t('รายละเอียดพื้นที่เสี่ยงภัยแล้ง')}
            </Button>
          </Control>
          <Control position="bottomright">
            {openDroughtRainLegend ? <DroughtRainLegend /> : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
                marginBottom: 30,
              }}
              aria-haspopup="true"
              onClick={this.handleClickDroughtRainLegend}
              endIcon={<ArrowDropUpIcon />}
            >
              {t('ฝนสะสม (มม.)')}
            </Button>
          </Control>
          <Control position="bottomleft">
            {openDroughtDamLegend ? <DroughtDamLegend /> : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={this.handleClickDroughtDamLegend}
              endIcon={<ArrowDropUpIcon />}
            >
              {t('ปริมาณน้ำในเขื่อน')}
            </Button>
            {openDroughtLegend ? <DroughtLegend /> : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
                marginLeft: 10,
              }}
              aria-haspopup="true"
              onClick={this.handleClickDroughtLegend}
              endIcon={<ArrowDropUpIcon />}
            >
              {t('ระดับความเสี่ยงภัยแล้ง')}
            </Button>
          </Control>
        </Map>
      </>
    );
  }
}

DroughtMonitoringMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  datasRainfallYesterday: PropTypes.array.isRequired,
  datasDam: PropTypes.array.isRequired,
  datasDrought: PropTypes.array.isRequired,
  dataWaterlevel: PropTypes.array.isRequired,
  datasDischarge: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  // geojsonRiver: PropTypes.object.isRequired,
  // geojsonBasin: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(DroughtMonitoringMap);
