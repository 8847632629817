import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import {
  styles,
} from '../../styles/Style';

import WindowScreen from '../../utils/WindowsFullScreen';

const initPath = process.env.MIX_APP_PATH;

// icon
const IconZoomOut = `${initPath}images/theme/icon/zoom-out.png`;
const IconZoomIn = `${initPath}images/theme/icon/zoom-in.png`;

class SidebarExpand extends Component {
  constructor(props) {
    super(props);
    this.state = {

      fullScreenStatus: this.handleGetFullscreen(),
    };

    this.handleSetFullscreen = this.handleSetFullscreen.bind(this);
    this.handleGetFullscreen = this.handleGetFullscreen.bind(this);
  }

  handleSetFullscreen = () => {
    let fullScreen = { status: 'enable', maxWidth: 'xl' };

    if (localStorage.fullScreen && localStorage.fullScreen !== '') {
      const getfullScreen = JSON.parse(localStorage.fullScreen);
      fullScreen = {
        status: (getfullScreen.status === 'disable') ? 'enable' : 'disable',
        maxWidth: (getfullScreen.status === 'disable') ? 'xl' : 'lg',
      };
    }

    localStorage.setItem('fullScreen', JSON.stringify(fullScreen));
    const fullScreenStatus = JSON.parse(localStorage.fullScreen);
    this.setState({ fullScreenStatus });

    window.location.reload();
  };

  handleGetFullscreen = () => {
    let fullScreen = { status: 'disable', maxWidth: 'lg' };

    if (localStorage.fullScreen && localStorage.fullScreen !== '') {
      const getfullScreen = JSON.parse(localStorage.fullScreen);
      fullScreen = {
        status: (getfullScreen.status === '') ? 'disable' : getfullScreen.status,
        maxWidth: (getfullScreen.maxWidth === '') ? 'lg' : getfullScreen.maxWidth,
      };
    }
    return fullScreen;
  };

  render() {
    const { classes } = this.props;
    const { fullScreenStatus } = this.state;

    return WindowScreen() !== 'xs' && (
      <>
        <Hidden mdDown>
          <Box className={classes.sidebarExpand}>
            {fullScreenStatus.status === 'enable' ? (
              <Tooltip title="ย่อ" placement="left-start" arrow>
                <Link href="#" onClick={this.handleSetFullscreen} style={{ color: '#ff0000' }}>
                  <img src={IconZoomOut} width="16" height="14" alt="zoom out" />
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title="ขยาย" placement="left-start" arrow>
                <Link href="#" onClick={this.handleSetFullscreen} style={{ color: '#ff0000' }}>
                  <img src={IconZoomIn} width="16" height="14" alt="zoom in" />
                </Link>
              </Tooltip>
            )}
          </Box>
        </Hidden>
      </>
    );
  }
}

SidebarExpand.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SidebarExpand);
