import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;

const iconImageDam = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/icon-dam.png`,
  iconSize: [20, 20],
});

const iconImg = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/icon-img.png`,
  iconSize: [20, 20],
});

const iconImageVideo = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/icon-vdo.png`,
  iconSize: [20, 20],
});

const iconRaindroupUp = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/icon-raindrop_up.png`,
  iconSize: [20, 20],
});

const iconWaterLevel = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/icon_waterlevel.png`,
  iconSize: [20, 20],
});

const iconWlDischarge = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/icon_wl_discharge.png`,
  iconSize: [20, 20],
});

const iconDroughtDam30 = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/model/drought_monitor/dam-30.png`,
  iconSize: [20, 20],
});

const iconDroughtDam3050 = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/model/drought_monitor/dam-30-50.png`,
  iconSize: [20, 20],
});

const iconDroughtDam5080 = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/model/drought_monitor/dam-50-80.png`,
  iconSize: [20, 20],
});

const iconDroughtDam80100 = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/model/drought_monitor/dam-80-100.png`,
  iconSize: [20, 20],
});

const iconDroughtDam100 = new L.Icon({
  iconUrl: `${initPath}images/marker/icon/model/drought_monitor/dam-100.png`,
  iconSize: [20, 20],
});

const iconImage = {
  dam: iconImageDam,
  image: iconImg,
  video: iconImageVideo,
  raindroupUp: iconRaindroupUp,
  waterLevel: iconWaterLevel,
  WlDischarge: iconWlDischarge,
  iconDroughtDam30,
  iconDroughtDam3050,
  iconDroughtDam5080,
  iconDroughtDam80100,
  iconDroughtDam100,
};

export default iconImage;
