import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  orderBy, uniqueId, uniqBy,
} from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';

// icon
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';

// table .
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import tJson from '../../utils/TJson';

import {
  FaImage, FaVideo, FaSpinner,
} from '../../utils/Fontawesome';

import {
  StyledTableRow, styles, BootstrapInput,
} from '../../styles/Style';

import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import CctvMap from './CctvMap';
import CctvMedia from './CctvMedia';

// sidebar
import SidebarWater from '../navigation/SidebarWater';

import WlContext from '../../contexts/wlContext';

// icon .
import { HiiCctvIcon } from '../../utils/Icon';

// table Header
const columnData = [
  {
    id: 'title', numeric: false, disablePadding: true, label: 'สถานี', sortable: true,
  },
  {
    id: 'geocode.tumbon_name.th', numeric: false, disablePadding: false, label: 'ตำบล', sortable: true,
  },
  {
    id: 'geocode.amphoe_name.th', numeric: false, disablePadding: false, label: 'อำเภอ', sortable: true,
  },
  {
    id: 'geocode.province_name.th', numeric: false, disablePadding: false, label: 'จังหวัด', sortable: true,
  },
  {
    id: 'chart', numeric: false, disablePadding: false, label: '',
  },
];

class Cctv extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      errorInfo: null, // error

      page: 0,
      rowsPerPage: 10,
      dataCondition: [],
      dataProvince: [],
      dataBasin: [],
      areaText: 'ทั่วประเทศ',
      agencyName: 'ทุกหน่วยงาน',
      dataAgency: [],

      // for search
      dataInit: [],
      dataInitLastest: [],
      searchData: [],
      searchTerm: '',

      // map
      map: null,
      zoom: 5,
      location: [12.9, 101.6],
      markers: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,

      // radio group
      areaType: '0',
      areaId: '',

      //agency select
      agencyId: '',

      order: 'asc',
      orderByState: 'geocode.province_name.th',

    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // log error messages to an error reporting service here
  }

  getData = () => {
    fetch(`${process.env.MIX_API_URL}analyst/cctv`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const filterDatas = result.data.filter(cctv => (cctv.media_type === 'img' || cctv.media_type === 'url') && cctv.is_active === true);

        // basin and province dropdown
        const dataProvince = uniqBy(result.data.map(d => ({
          id: d.geocode.province_code,
          name: d.geocode.province_name,
        })), 'id');
        dataProvince.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th'));

        const dataBasin = uniqBy(result.data.map(d => {
          if (d.basin.basin_code !== undefined) {
            return ({
              id: d.basin.basin_code,
              name: d.basin.basin_name,
            });
          }
          return null;
        }), 'id');
        dataBasin.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th'));

        const dataAgency = uniqBy(
          result.data
            .filter(cctv => (cctv.media_type === 'img' || cctv.media_type === 'url') && cctv.is_active === true)
            .map(d => {
              if (d.basin.basin_code !== undefined) {
                return ({
                  id: d.agency.id,
                  name: d.agency.agency_name,
                });
              }
              return null;
            })
          , 'id'
        );
        dataAgency.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th'));

        // set state
        this.setState({
          isLoading: false,
          dataInit: filterDatas,
          dataInitLastest: filterDatas,
          dataProvince,
          dataBasin,
          dataAgency,
          dataCondition: dataProvince,
          searchData: filterDatas,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleModal = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: `CCTV ${data.description}`,
      content: <CctvMedia data={data} />,
    };
    handler(params);
  }

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByState === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  handleAreaTypeChange = (event, value) => {
    const { t } = this.props;
    const { dataProvince, dataBasin, dataInit } = this.state;
    let dataCondition = dataProvince;
    let areaText = t('ทั่วประเทศ');
    if (value === '1') {
      dataCondition = dataBasin;
      areaText = t('ทุกลุ่มน้ำ');
    }

    this.setState({
      areaType: value,
      dataCondition,
      areaId: '',
      areaText,
      dataInitLastest: dataInit,
      searchData: dataInit,
    }, () => {
      this.filterDatas();
    });
  };

  handleSearchChange = event => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm }, () => {
      this.filterDatas();
    })
  };

  handleFilterAreaChange = areaId => event => {
    this.setState({ [areaId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  handleFilterAgencyChange = agencyId => event => {
    this.setState({ [agencyId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = 'max-content';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = 'max-content';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = 'max-content';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  filterDatas() {
    const {
      searchTerm, areaId, dataInit, rowsPerPage, page, areaType, agencyId
    } = this.state;
    const searchData = dataInit
      .filter(d => {
        if (searchTerm !== '') {
          if(!(d.title.indexOf(searchTerm) > -1 
              || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
              || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
              || d.geocode.province_name.th.indexOf(searchTerm) > -1
              || d.sub_basin_id.toString().toUpperCase().indexOf(searchTerm) > -1
            )){
            return false
          }
        }
        if (areaId !== '') {
          if(areaType === '1' ? !(d.basin.basin_code.toString() === areaId) : !(d.geocode.province_code === areaId)){
            return false
          }
        }
        if (agencyId !== '') {
          if(!(d.agency.id.toString() === agencyId)){
            return false
          }
        }
        return d;
      });
    const pageCurrent = (searchData.length < rowsPerPage) ? 0 : page;

    this.setState({
      searchData,
      page: pageCurrent,
    });
  }

  render() {
    const { classes, t } = this.props;
    const { areaType, areaId, areaText } = this.state;
    const { agencyId, agencyName, dataAgency } = this.state;

    const {
      isLoading, error, errorInfo, searchData, dataCondition, modalHeader, modalSubHeader,
      modalContent, open,
    } = this.state;
    const {
      rowsPerPage, page, order, orderByState,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, searchData.length - page * rowsPerPage);

    if (errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <WlContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          {/* Sidebar  */}
          <SidebarWater />

          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs>
                <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
                  <Box display="flex" pb={0} mb={0}>
                    <Typography variant="h6" gutterBottom>
                      {t('ติดตามสถานการณ์น้ำ')}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={5} lg={5}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" pt={2} alignItems="center">
                    <HiiCctvIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('กล้อง CCTV')}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} width="100%">
                  <CctvMap
                    {...this.state}
                    datas={searchData}
                    handleSetMap={this.handleSetMap}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                <Box mt={3} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41 }}>
                      <Box>
                        <FormControl variant="outlined" component="fieldset">
                          <RadioGroup aria-label="position" id="position" name="position" value={areaType} onChange={this.handleAreaTypeChange} row>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลจังหวัด')}</Typography>}
                              labelPlacement="end"
                              fontFamily="Kanit"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลลุ่มน้ำ')}</Typography>}
                              labelPlacement="end"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined" className={classes.formControl} width="100%">
                        <NativeSelect
                          id="select"
                          value={areaId}
                          onChange={this.handleFilterAreaChange('areaId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                            )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit' }}>{areaText}</option>
                          {dataCondition.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{tJson(val.name, ' ')}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined">
                        <NativeSelect
                          id="agencySelect"
                          value={agencyId.toString()}
                          onChange={this.handleFilterAgencyChange('agencyId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                            )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit' }}>{t(agencyName)}</option>
                          {dataAgency.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{tJson(val.name, 'หน่วยงาน')}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <Paper className={classes.rootSearch} elevation={0}>
                        <InputBase
                          display="flex"
                          className={classes.inputSearch}
                          placeholder={t('ค้นหา')}
                          id="search"
                          onChange={this.handleSearchChange}
                        />
                        <IconButton className={classes.iconButton} aria-label="Search">
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box mt={2} />
                    <TableContainer>
                      <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                        <TableHeaderEnhance
                          columnData={columnData}
                          order={order}
                          orderBy={orderByState}
                          onRequestSort={this.handleRequestSort}
                          firstColumnSticky
                        />
                        <TableBody>
                          {orderBy(searchData, orderByState, order)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(row => {
                              let mapLink = row.title;
                              const title = (tJson(row.agency.agency_name, ' ') === '') ? row.description : tJson(row.agency.agency_name, ' ');
                              if (
                                this.handleSearchMarker
                                && row.lat
                                && row.long
                              ) {
                                mapLink = (
                                  <Button
                                    className={classes.buttonStyle}
                                    style={{ textAlign: 'left' }}
                                    onClick={this.handleSearchMarker(
                                      row.id,
                                      row.lat,
                                      row.long,
                                    )}
                                    title={title}
                                  >
                                    {row.title}
                                  </Button>
                                );
                              }

                              let iconStyle = (<FaImage fontSize="small" />);
                              if (row.media_type === 'vdo') {
                                iconStyle = (<FaVideo fontSize="small" />);
                              }

                              return (
                                <StyledTableRow key={row.id}>
                                  <TableCell component="th" scope="row" style={{ paddingLeft: 16, textAlign: 'left' }} align="left" className="first-column-sticky">
                                    {mapLink}
                                  </TableCell>
                                  <TableCell align="left">{tJson(row.geocode.tumbon_name, ' ')}</TableCell>
                                  <TableCell align="left">{tJson(row.geocode.amphoe_name, ' ')}</TableCell>
                                  <TableCell align="left">{tJson(row.geocode.province_name, ' ')}</TableCell>
                                  <TableCell>
                                    <WlContext.Consumer>
                                      {({ handleModalOpen }) => (
                                        <>
                                          <Tooltip title={t('แสดงข้อมูล CCTV')}>
                                            <IconButton
                                              className={classes.button}
                                              aria-label={row.id}
                                              onClick={this.handleModal(handleModalOpen, row)}
                                            >
                                              {iconStyle}
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </WlContext.Consumer>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                          {emptyRows === rowsPerPage && (
                          <StyledTableRow style={{ height: 180, backgroundColor: grey[300] }}>
                            <TableCell colSpan={5} style={{ height: 120, textAlign: 'center' }}>
                              <Typography variant="subtitle2" gutterBottom>
                                {t('ไม่มีข้อมูลตามเงื่อนไขที่เลือก')}
                              </Typography>
                            </TableCell>
                          </StyledTableRow>
                          )}
                        </TableBody>
                        <TableFooter />
                      </Table>
                    </TableContainer>
                    {searchData.length > rowsPerPage && (
                      <TablePagination
                        component="div"
                        className={classes.hiddenLine}
                        count={searchData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActionsWrapped}
                        labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
                        labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to}  ${count} ${t('รายการ')}`}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Modal
                id="modal-chart"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={this.handleModalClose}
                style={{ align: 'center', justifyContent: 'center' }}
              >
                <div style={this.getModalStyle()} className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Typography variant="h5" id="modal-title">
                    {modalHeader}
                  </Typography>
                  <Typography variant="subtitle1" id="simple-modal-description">
                    {modalSubHeader}
                  </Typography>
                  <Box mt={3}>
                    <div className={classes.paper} style={{ padding: '0px !important' }}>
                      {modalContent}
                    </div>
                  </Box>
                </div>
              </Modal>
            </Grid>
          </div>
        </WlContext.Provider>
      </div>
    );
  }
}

Cctv.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles, { withTheme: true }), withTranslation(),
)(Cctv);
