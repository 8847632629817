import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SimpleSnackbar(props) {
  const classes = useStyles();
  const { stateSnackBar } = props;
  const { open } = stateSnackBar;
  const [openSb, setOpenSb] = React.useState(open);

  React.useEffect(() => {
    setOpenSb(open);
  }, [stateSnackBar]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSb(false);
  };

  return (
    <div className={classes.root}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar open={openSb} autoHideDuration={50000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          &emsp;&emsp;ไม่มีข้อมูล!&emsp;&emsp;
        </Alert>
      </Snackbar>
    </div>
  );
}

SimpleSnackbar.propTypes = {
  stateSnackBar: PropTypes.any.isRequired,
};
