import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import moment from "moment";
import heightMeasurementJSONPathum from "./json/heightMeasurementPathum.json";
import { isNull } from "lodash";

import useStyles from "./Style";

export default function DemoSiteTable({
  currentData,
  setCurrentPicNameFunc,
  setLatestDataPointFunc,
}) {
  const [processData, setProcessData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();

  useEffect(() => {
    if (currentData) {
      if (currentData.hii) {
        const processHii = currentData.hii.map((set) => {
          return { datetime: set[0], hiiValue: set[1] };
        });
        const processPathum = currentData.pathum.map((set) => {
          return { datetime: set[0], pathumValue: set[1] };
        });
        let mergedArray = [];
        for(let i=0; i<processPathum.length; i++) {
          mergedArray.push({
           ...processPathum[i], 
           ...(processPathum.find((itmInner) => itmInner.datetime === processPathum[i].datetime))}
          );
        }
        const filteredArray = mergedArray
          .filter((point) => {
            if (isNull(point.hiiValue) && !point.pathumValue) {
              return false;
            }
            if (isNull(point.pathumValue) && !point.hiiValue) {
              return false;
            }
            return true;
          })
          .sort((a, b) => b.datetime - a.datetime);
        setProcessData(filteredArray);
      }
    }
  }, [currentData]);

  useEffect(() => {
    if (processData.length > 0) {
      let hiiLastDataPoint = 0;
      let hiiLastDataPointTime = 0;
      let pathumLastDataPoint = 0;
      let pathumLastDataPointTime = 0;

      for (let index = 0; index < processData.length; index++) {
        const element = processData[index];
        if (
          element.hiiValue &&
          !Number.isNaN(element.hiiValue) &&
          hiiLastDataPoint === 0
        ) {
          hiiLastDataPoint = element.hiiValue;
          hiiLastDataPointTime = element.datetime;
        }
        if (
          element.pathumValue &&
          !Number.isNaN(element.pathumValue) &&
          pathumLastDataPoint === 0
        ) {
          pathumLastDataPoint = element.pathumValue;
          pathumLastDataPointTime = element.datetime;
        }
      }
      let returnNameObj = { hii: "", pathum: "" };
      if (hiiLastDataPoint > 247.4) {
        returnNameObj.hii = "247_400";
      } else if (hiiLastDataPoint < 239.0) {
        returnNameObj.hii = "239_000";
      } else {
        const splitNameString = (Math.round(hiiLastDataPoint / 0.2) * 0.2)
          .toFixed(1)
          .toString()
          .split(".");
        returnNameObj.hii = `${splitNameString[0]}_${splitNameString[1]}00`;
      }
      if (pathumLastDataPoint > 247.4) {
        returnNameObj.pathum = "247_400";
      } else if (pathumLastDataPoint < 239.0) {
        returnNameObj.pathum = "239_000";
      } else {
        const splitNameString = (Math.round(pathumLastDataPoint / 0.2) * 0.2)
          .toFixed(1)
          .toString()
          .split(".");
        returnNameObj.pathum = `${splitNameString[0]}_${splitNameString[1]}00`;
      }
      setCurrentPicNameFunc(returnNameObj);
      setLatestDataPointFunc({
        hii: {
          datetime: hiiLastDataPointTime,
          height: hiiLastDataPoint,
          volume: calculateWithHeight(hiiLastDataPoint),
        },
        pathum: {
          datetime: pathumLastDataPointTime,
          height: pathumLastDataPoint,
          volume: calculateWithHeight(pathumLastDataPoint),
        },
      });
    }
  }, [processData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateWithHeight = (height) => {
    if (isNull(height)) {
      return "";
    }
    var rounded = Math.round(height * 100) / 100;
    let closestVolume = null;
    let closestMargin = 100;
    heightMeasurementJSONPathum.forEach((row) => {
      let currentMargin = Math.abs(rounded - row.MSL_Height);
      if (currentMargin < closestMargin) {
        closestMargin = currentMargin;
        closestVolume = row.volume;
      }
    });
    return closestVolume;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className="table-custom-sticky">
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={1} rowSpan={2} className={classes.tableHeadCell}>
                  วันที่/เวลา
                </TableCell>
                <TableCell align="center" colSpan={2} className={classes.tableHeadCell}>
                  สถานีปทุมมาตร
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" colSpan={1} className={classes.tableHeadCell}>
                  ระดับน้ำ (ม.รทก)
                </TableCell>
                <TableCell align="right" colSpan={1} className={classes.tableHeadCell}>
                  ปริมาณน้ำ (ลบ.ม.)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processData &&
                processData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow>
                        <TableCell className={classes.tableHeadCell}>
                          {moment(row.datetime)
                            .locale("th")
                            .format("DD/MM/YYYY HH:mm น.")}
                        </TableCell>
                        <TableCell align="right" className={classes.tableHeadCell}>
                          {row.pathumValue ? (row.pathumValue).toFixed(2) : null}
                        </TableCell>
                        <TableCell align="right" className={classes.tableHeadCell}>
                          {calculateWithHeight(row.pathumValue).toLocaleString(undefined, {maximumFractionDigits: 0})}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
            <TableFooter>
              <TableRow>
                {processData && (
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={7}
                    count={processData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
