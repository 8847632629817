import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    position: 'fixed',
    left: '1%',
    bottom: '6%',
  },
  legend: {
    height: '20px',
    width: '40px',
    marginRight: 10,
    opacity: 0.8,
  },
});

export default function Legend() {
  const classes = useStyles();

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.9',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FFF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> &lt; 1 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#CCE3FF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 1 - 5 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#ADD4FF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 5 - 10 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#96E6AB',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 10 - 20 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#69C902',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 20 - 35 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FAD600',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 35 - 50 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FF8A04',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 50 - 70 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#CC6602',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 70 - 90 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FF2E04',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 90 - 150 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#CC2624',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 150 - 200 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#871A1A',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 200 - 300 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#9930CF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> &gt; 300 </Typography></Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
