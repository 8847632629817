/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId, orderBy } from 'lodash';
// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgressbar } from 'react-circular-progressbar';
import Box from '@material-ui/core/Box';

// map
import L from 'leaflet';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  WMSTileLayer,
  Tooltip,
  GeoJSON,
  LayerGroup,
  LayersControl,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

// color
import deepOrange from '@material-ui/core/colors/deepOrange';
import { WiRaindrop } from 'react-icons/wi';
import { FaCircle, FaSquare } from 'react-icons/fa';
import { BsFillTriangleFill, BsPentagonFill } from 'react-icons/bs';

import {
  FaChartLine,
  // ,FaCircle, FaSquare,
} from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

import { styles } from '../../styles/Style';

// components
import CanalChart from './CanalChart';
import FlowChart from './FlowChart';
import RainfallChart from './RainfallChart';
import WlChart from './WlChart';
import FloodRoadChart from './FloodRoadChart';
import WlStatusColor from '../wl/WlStatusColor';
import RainfallStatusColor from '../rainfall/RainfallStatusColor';
import RainLegend from '../dashboard/legend/RainLegend';
import WlLegend from '../dashboard/legend/WlLegend';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

// icon
import { HiiRainIcon } from '../../utils/Icon';
import iconCircle from '../widgets/map/iconCircle';

import BmaContext from '../../contexts/BmaContext';
// const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;
const { BaseLayer, Overlay } = LayersControl;
const initPath = process.env.MIX_APP_PATH;

class BmaWaterMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.waterLevelLayer = createRef();
    this.rainfall24hLayer = createRef();
    this.canalLayer = createRef();
    this.flowLayer = createRef();
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    this.state = {
      error: '',
      height: this.setHeightMap(),
      openLegend1: false,
      openLegend2: false,
    };

    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.handleClickLegend1 = this.handleClickLegend1.bind(this);
    this.handleClickLegend2 = this.handleClickLegend2.bind(this);
    // this.getGeoJson = this.getGeoJson.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;

    // this._mounted = true;

    // this.getGeoJson();

    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
    // this.getProvinceStyle();
  }

  shouldComponentUpdate(nextProps) {
    const {
      datas,
      areaType,
      areaId,
      dataBkkRain24h,
      dataBkkCanal,
      dataBkkFlow,
      dataBkkFloodRoad,
    } = this.props;
    if (
      datas === nextProps.datas
      && areaType === nextProps.areaType
      && dataBkkRain24h === nextProps.dataBkkRain24h
      && dataBkkCanal === nextProps.dataBkkCanal
      && dataBkkFlow === nextProps.dataBkkFlow
      && dataBkkFloodRoad === nextProps.dataBkkFloodRoad
      && areaId === nextProps.areaId
    ) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const { areaType, areaId } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    let obj = this.refBoundaryProvince.current.leafletElement._layers;

    // thailand
    if (areaId === '') {
      const { location, zoom } = this.props;
      this.map.setView(location, zoom);

      return;
    }
    if (areaType !== prevProps.areaType || areaId !== prevProps.areaId) {
      if (areaType === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }

      // get feature
      // eslint-disable-next-line no-underscore-dangle

      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          // change zoom snap to make fitBounds work

          // province
          if (areaType === '0') {
            const provCode = obj[key].feature.properties.prov_code;
            if (provCode.toString() === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          } else {
            // basin
            const basinCode = obj[key].feature.properties.BASIN_CODE;
            if (basinCode === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 600;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 600;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 600;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 450;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  // set marker icon based on criteria
  setIcon = val => {
    const status = WlStatusColor(val);

    return status.icon;
  };

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  });

  // get dam status
  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(2), type);
    return status;
  };

  setIconRain24h = (val, type) => {
    const status = RainfallStatusColor(val, type);
    return status.icon;
  };

  getProvinceStyle = () => ({
    stroke: true,
    color: deepOrange[700],
    weight: 2,
    fillColor: '#FFF',
    fillOpacity: 0,
  });

  handleWaterLevelChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟระดับน้ำ',
      content: (
        <WlChart id={data.station.id} provinceId={data.geocode.province_code} />
      ),
    };

    handler(params);
  };

  handleWaterLevelCanalChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟคลอง',
      content: (
        <CanalChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
        />
      ),
    };
    handler(params);
  };

  handleWaterLevelFlowChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟวัดอัตราการไหล',
      content: (
        <FlowChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
        />
      ),
    };
    handler(params);
  };

  handleRainfallChart = (handler, data, type, filterData) => e => {
    e.preventDefault();
    let params;
    if (type === 'rainfallYesterday') {
      params = {
        header: `กราฟฝน ${data.tele_station_name.th}`,
        content: (
          <RainfallChart
            id={data.tele_station_id}
            provinceId={data.province_code}
            selectedType={type}
            filterData={filterData}
          />
        ),
      };
    } else {
      params = {
        header: `กราฟฝน ${data.station.tele_station_name.th}`,
        content: (
          <RainfallChart
            id={data.station.id}
            provinceId={data.geocode.province_code}
            selectedType={type}
            filterData={filterData}
            year={data.rainfall_datetime.substring(0, 4)}
            month={data.rainfall_datetime.substring(5, 7)}
          />
        ),
      };
    }
    handler(params);
  };

  handleFloodRoadChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟวัดระดับน้ำท่วมถนน',
      content: (
        <FloodRoadChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
        />
      ),
    };
    handler(params);
  };

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      if (this.refMap.current.leafletElement) {
        const map = this.refMap.current.leafletElement;
        map.on('popupopen', e => {
          // eslint-disable-next-line no-underscore-dangle
          const px = map.project(e.target._popup._latlng);
          // eslint-disable-next-line no-underscore-dangle
          px.y -= e.target._popup._container.clientHeight / 2;
          map.panTo(map.unproject(px), { animate: true });
        });
      }
    }
  };

  formatWl = value => (typeof value === 'undefined' || value == null
    ? '-'
    : parseFloat(value).toFixed(2));

  eachDataWL = (data, i) => {
    const {
      location, zoom, datas, classes,
    } = this.props;

    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
      && data.waterlevel_msl != null
    ) {
      // marker position
      const position = [
        parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long),
      ];

      // marker icons
      const icon = this.setIcon(parseFloat(data.storage_percent));
      // const icon = iconRect.blue;
      // status colors
      const statusColor = WlStatusColor(data.storage_percent);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLine}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Typography variant="subtitle2">
                        {data.station.tele_station_name.th}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <div
                        style={{
                          width: '100px',
                          position: 'relative',
                          margin: '0 auto',
                        }}
                      >
                        <CircularProgressbar
                          value={parseInt(data.storage_percent, 10)}
                          text={`${Number.isNaN(parseInt(data.storage_percent, 10))
                            ? 'n/a'
                            : parseInt(data.storage_percent, 10)
                            }%`}
                          styles={{
                            text: {
                              fill: '#000000',
                              fontSize: '2em',
                            },
                            path: {
                              stroke: statusColor.color.statusColor,
                            },
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      ระดับน้ำ
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.waterlevel_msl)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      ระดับตลิ่ง
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.station.min_bank)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      ระดับท้องน้ำ
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>
                        {this.formatWl(data.station.ground_level)}
                      </strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ม.รทก.)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`ข้อมูล: ${data.agency.agency_name.th}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.waterlevel_datetime}
                        {' '}
                        {'น.          '}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleWaterLevelChart(
                              handleModalOpen,
                              data,
                            )}
                            title="แสดงกราฟ test"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ fontSize: '2em' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.tele_station_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.tele_station_name.th}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} `}
                <br />
                {`จ.${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  eachDataRain24h = (data, i) => {
    const { classes, dataBkkRain24h } = this.props;
    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
      && data.rain_24h >= 0
      && data.station.id > 0
    ) {
      // && data.rain_24h != null
      // marker position
      const position = [
        parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long),
      ];
      // marker icons
      const icon = this.setIconRain24h(
        parseFloat(data.rain_24h),
        'rainfall24h',
      );
      // status colors
      // const statusColor = RainfallStatusColor(data.rain_24h);
      const rainfall24h = parseFloat(data.rain_24h).toFixed(2);
      const textRainfallStatus = this.rainfallStatus(rainfall24h, 'rainfall24h')
        .color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(
        rainfall24h,
        'rainfall24h',
      ).color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.hiddenLine}
                    padding="none"
                    align="center"
                    colSpan={2}
                  >
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${data.station.tele_station_name.th}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {` อ.${data.geocode.amphoe_name.th}`}
                      {` จ.${data.geocode.province_name.th}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.hiddenLine}
                    align="center"
                    colSpan={2}
                  >
                    <Box
                      pt={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                    >
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${data.rain_24h}`}
                          </Typography>
                          <Typography variant="h6">มม.</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.hiddenLine}
                    padding="none"
                    align="center"
                    colSpan={2}
                  >
                    <Typography variant="h6">
                      ปริมาณฝนสะสม 24 ย้อนหลัง
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.hiddenLine}
                    padding="none"
                    align="center"
                    colSpan={2}
                  >
                    <Typography variant="caption" color="secondary">
                      {`ข้อมูล: ${data.agency.agency_name.th}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}>
                    <Typography
                      variant="caption"
                      color="secondary"
                    >
                      {`${data.rainfall_datetime} น.`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    {data.agency.agency_shortname.en !== 'DWR' ? (
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleRainfallChart(
                              handleModalOpen,
                              data,
                              'rainfall24h',
                              dataBkkRain24h,
                            )}
                            title="แสดงกราฟ"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    ) : (
                      <BmaContext.Consumer>
                        {() => (
                          <Button
                            color="secondary"
                            title="ไม่มีกราฟ"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ color: '#9e9e9e' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.tele_station_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.tele_station_name.th}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} จ.${data.geocode.province_name.th}`}
                <br />
                {`ข้อมูล:${data.agency.agency_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  eachDataCanal = (data, i) => {
    const {
      location, zoom, datas, classes,
    } = this.props;
    if (data.station.canal_lat && data.station.canal_long) {
      // marker position
      const position = [
        parseFloat(data.station.canal_lat),
        parseFloat(data.station.canal_long),
      ];

      // marker icons
      // const icon = this.setIcon(parseFloat(data.storage_percent));
      // const icon = iconCircle.yellow;
      // const iconRectWhite = new L.Icon({
      //   iconUrl: 'https://icons.iconarchive.com/icons/custom-icon-design/flatastic-6/512/Triangle-icon.png',
      //   iconSize: [10, 10],
      // });
      // console.log('data', data);
      // const icon = iconRectWhite;
      // const statusColor = WlStatusColor(data.storage_percent);
      const iconGreenUp = new L.Icon({
        iconUrl: `${initPath}images/marker/triangle_new/green-up.png`,
        iconSize: [10, 10],
      });
      const icon = iconGreenUp;

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLine}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Typography variant="subtitle2">
                        {data.station.canal_name.th}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      ระดับน้ำ
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.canal_value)}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ม.รทก.)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`ข้อมูล: ${data.agency.agency_name.th}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.canal_datetime}
                        {' '}
                        {'น.          '}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleWaterLevelCanalChart(
                              handleModalOpen,
                              data,
                            )}
                            title="แสดงกราฟ test"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ fontSize: '2em' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.canal_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.canal_name.th}`}</strong>
                <br />
                {`${data.geocode.tumbon_name.th} ${data.geocode.amphoe_name.th} `}
                <br />
                {`${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  eachDataFlow = (data, i) => {
    const {
      location, zoom, datas, classes,
    } = this.props;
    if (data.station.flow_lat && data.station.flow_long) {
      // marker position
      const position = [
        parseFloat(data.station.flow_lat),
        parseFloat(data.station.flow_long),
      ];

      // marker icons
      // const icon = this.setIcon(parseFloat(data.storage_percent));
      const icon = iconCircle.yellow;
      // const statusColor = WlStatusColor(data.storage_percent);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLine}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Typography variant="subtitle2">
                        {data.station.flow_name.th}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      อัตราการไหล
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.flow_value)}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ลบ.ม/วินาที)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`ข้อมูล: ${data.agency.agency_name.th}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.flow_datetime}
                        {' '}
                        {'น.          '}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleWaterLevelFlowChart(
                              handleModalOpen,
                              data,
                            )}
                            title="แสดงกราฟ test"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ fontSize: '2em' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>

          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.flow_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.flow_name.th}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} `}
                <br />
                {`จ.${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  eachDataFloodRoad = (data, i) => {
    const {
      location, zoom, datas, classes,
    } = this.props;
    if (data.station.floodroad_lat && data.station.floodroad_long) {
      // marker position
      const position = [
        parseFloat(data.station.floodroad_lat),
        parseFloat(data.station.floodroad_long),
      ];

      // marker icons
      // const icon = this.setIcon(parseFloat(data.storage_percent));
      const iconpantagonRed = new L.Icon({
        iconUrl: `${initPath}images/marker/triangle_new/pentagon-red.png`,
        iconSize: [9, 9],
      });
      const iconpantagonGray = new L.Icon({
        iconUrl: `${initPath}images/marker/triangle_new/pentagon-gray-pre.png`,
        iconSize: [9, 9],
      });
      // const icon = iconCircle.red;
      let icon = iconpantagonGray;
      if (data.floodroad_value > 0) {
        icon = iconpantagonRed;
      } else {
        icon = iconpantagonGray;
      }

      // const statusColor = WlStatusColor(data.storage_percent);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLine}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Typography variant="subtitle2">
                        {data.station.floodroad_name.th}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      ระดับน้ำท่วมถนน
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.floodroad_value)}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ลบ.ม)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`ข้อมูล: ${data.agency.agency_name.th}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.floodroad_datetime}
                        {' '}
                        {'น.          '}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleFloodRoadChart(
                              handleModalOpen,
                              data,
                            )}
                            title="แสดงกราฟ"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ fontSize: '2em' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.floodroad_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.floodroad_name.th}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} `}
                <br />
                {`จ.${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  handleClickLegend1() {
    const { openLegend1 } = this.state;
    if (openLegend1 === false) {
      this.setState({ openLegend1: true });
    } else {
      this.setState({ openLegend1: false });
    }
    this.forceUpdate();
  }

  handleClickLegend2() {
    const { openLegend2 } = this.state;
    if (openLegend2 === false) {
      this.setState({ openLegend2: true });
    } else {
      this.setState({ openLegend2: false });
    }
    this.forceUpdate();
  }


  render() {
    const {
      error, height, openLegend1, // openLegend2,
    } = this.state;
    const {
      location,
      datas,
      dataBkkRain24h,
      dataBkkCanal,
      dataBkkFlow,
      dataBkkFloodRoad,
      zoom,
      geojsonProvinceBkk,
    } = this.props;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    return (
      <>
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          gestureHandling
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution='&copy; <a href="http://osm.org/copyright">ESRI World Street Map</a> contributors'
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvinceBkk}
            style={this.getProvinceStyle}
          // ref={this.refBoundaryProvince}
          // onEachFeature={this.onEachProvinceFeature}
          />
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </BaseLayer>
            <BaseLayer name="Esri_WorldStreetMap">
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />
            </BaseLayer>
            <Overlay
              key="floodroadLayer"
              name="สถานีวัดน้ำท่วมถนน"
              checked="true"
            >
              <LayerGroup ref={this.canalLayer}>
                {dataBkkFloodRoad
                  .sort((a, b) => a.floodroad_datetime - b.floodroad_datetime)
                  .map(this.eachDataFloodRoad)}
              </LayerGroup>
            </Overlay>
            <Overlay key="flowLayer" name="สถานีวัดอัตราการไหล" checked="true">
              <LayerGroup ref={this.canalLayer}>
                {dataBkkFlow
                  .sort((a, b) => a.flow_datetime - b.flow_datetime)
                  .map(this.eachDataFlow)}
              </LayerGroup>
            </Overlay>
            <Overlay key="canalLayer" name="สถานีคลอง" checked="true">
              <LayerGroup ref={this.canalLayer}>
                {/* คลองกับระดับน้ำยุบรวมกัน */}
                {dataBkkCanal.map(this.eachDataCanal)}
              </LayerGroup>
            </Overlay>
            <Overlay key="waterlevel" name="สถานีระดับน้ำ" checked="true">
              <LayerGroup ref={this.waterLevelLayer}>
                {orderBy(
                  datas.filter(d => d.storage_percent != null),
                  ['storage_percent'],
                  ['desc'],
                ).map(this.eachDataWL)}
              </LayerGroup>
            </Overlay>
            <Overlay key="rainfall24h" name="สถานีฝน" checked="true">
              <LayerGroup ref={this.rainfall24hLayer}>
                {dataBkkRain24h
                  .sort((a, b) => a.rain_24h - b.rain_24h)
                  .map(this.eachDataRain24h)}
              </LayerGroup>
            </Overlay>

            <Overlay name="เส้นคลอง" checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:spatial_canal_bma"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
            <Overlay name="เส้นแม่น้ำ" checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
          </LayersControl>
          <Control position="bottomleft">
            <div className="info">
              <Typography variant="body1">
                <FaCircle />
                &nbsp; สถานีวัดอัตราการไหล
              </Typography>
              <Typography variant="body1">
                <BsFillTriangleFill />
                &nbsp; สถานีคลอง
              </Typography>
              <Typography variant="body1">
                <FaSquare />
                &nbsp; สถานีระดับน้ำ
              </Typography>
              <Typography variant="body1">
                <BsPentagonFill />
                &nbsp; สถานีระดับน้ำท่วมถนน
              </Typography>
              <Typography variant="body1">
                <WiRaindrop
                  size="30"
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '-7px',
                    marginTop: '-2px',
                  }}
                />
                สถานีฝน
              </Typography>
            </div>
          </Control>
          {/* <Control position="bottomright">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                width: '105%',
              }}
            >
              <div style={{ bottom: 0 }}>
                {openLegend1 ? (
                  <div>
                    <RainLegend />
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'white',
                    zIndex: 99999,
                    bottom: '1%',
                    left: '1%',
                    fontSize: '12px',
                    marginBottom: '-5%',
                  }}
                  aria-haspopup="true"
                  onClick={this.handleClickLegend1}
                >
                  เกณฑ์ฝนสะสม (มม.)
                </Button>
              </div>
              <div style={{ bottom: 0, paddingLeft: '2%', width: 'auto' }}>
                {openLegend2 ? (
                  <div>
                    <WlLegend />
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'white',
                    zIndex: 99999,
                    bottom: '1%',
                    left: '1%',
                    fontSize: '12px',
                  }}
                  aria-haspopup="true"
                  onClick={this.handleClickLegend2}
                >
                  เกณฑ์ระดับน้ำ (มม.)
                </Button>
              </div>
            </div>
          </Control> */}
          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
        </Map>
      </>
    );
  }
}

BmaWaterMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  dataBkkRain24h: PropTypes.array.isRequired,
  dataBkkCanal: PropTypes.array.isRequired,
  dataBkkFlow: PropTypes.array.isRequired,
  dataBkkFloodRoad: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  areaType: PropTypes.string.isRequired,
  areaId: PropTypes.string.isRequired,
  geojsonProvinceBkk: PropTypes.array.isRequired,
};

export default compose(withWidth(), withStyles(styles))(BmaWaterMap);
