import React, {
  createRef, Component,
} from 'react';
import PropTypes from 'prop-types';
import { uniqueId, orderBy, isEmpty } from 'lodash';
// map
import L from 'leaflet';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  WMSTileLayer,
  Tooltip,
  GeoJSON,
  LayerGroup,
  LayersControl,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
import { withStyles } from '@material-ui/core/styles';
// import Img from 'react-image';

// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// material-ui
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Tooltip as TooltipMui } from '@material-ui/core';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

// lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// icon
// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import deepOrange from '@material-ui/core/colors/deepOrange';
import LayersIcon from '@material-ui/icons/Layers';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ExploreIcon from '@material-ui/icons/Explore';
import Help from '@material-ui/icons/Help';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import InfoIcon from '@material-ui/icons/Info';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Locate from 'leaflet.locatecontrol';
import iconCircle from '../widgets/map/iconCircle';
import {
  FaChartLine,
  // ,FaCircle, FaSquare,
} from '../../utils/Fontawesome';
import BmaContext from '../../contexts/BmaContext';
import {
  HiiRainIcon, HiiRadarIcon, BmaLogo,
  HIIForecastRaindaylyIcon, HiiRadarOutlineIcon, HiiEyeOutlineIcon,
} from '../../utils/IconBma';
import { styles } from '../../styles/StyleBma';
import 'leaflet/dist/leaflet.css';

// component
import RainLegend from './legend/RainLegend';
import WlLegend from './legend/WlLegend';
import RadarBkkLegend from './legend/RadarBkkLegend';
import FloodRoadLegend from './legend/FloodRoadLegend';
import RainfallLegend from './legend/RainfallLegend';
import Rainfall30DayLegend from './legend/Rainfall30DayLegend';
import RainfallStatusColor from './icon/rainfall';
import WlStatusColor from './icon/wl';
import CanalStatusColor from './icon/canal';
import FloodRoadStatusColor from './icon/floodroad';
import RainfallChart from './chart/RainfallChart';
import FloodRoadChart from './chart/FloodRoadChart';
import CanalChart from './chart/CanalChart';
import WlChart from './chart/WlChart';
import FlowChart from './chart/FlowChart';
import CompRainFallGeoTiff from './CompRainFallGeoTiff';
// import LocateControl from './LocateControl';
import CompRadarBkk from './CompRadarBkk';
import CompSlideRainDaily from './CompSlideRainDaily';
import BmaWarning from './bma_warning/BmaWarning';
import CompTraffyFlood from './CompTraffyFlood';
import CompNoti from './CompNoti';
import CompLayer from './CompLayer';
import CompTwitter from './CompTwitter';
import CompRainSelect from './compSelect/CompRainSelect';
import CompRadarSelect from './compSelect/CompRadarSelect';
import CompWaterSelect from './compSelect/CompWaterSelect';
import CompExtraSelect from './compSelect/CompExtraSelect';
import AboutContent from './about/AboutContent';

// style
import './bmaStyle.scss';
import 'react-leaflet-fullscreen/dist/styles.css';

// const currentToDay = new Date().toLocaleDateString('th-TH', {
//   year: 'numeric',
//   month: 'short',
//   day: 'numeric',
//   hour: 'numeric',
//   minute: 'numeric',
// }).slice(0, 13);

const initPathWMS = process.env.MIX_APP_WMS;
const { BaseLayer, Overlay } = LayersControl;
const initPath = process.env.MIX_APP_PATH;

// ภาพแนะนำการใช้งานเบื้องต้น
const instruction = [
  `${initPath}images/bma/instruction/instruction_01.jpg`,
  `${initPath}images/bma/instruction/instruction_02.jpg`,
  `${initPath}images/bma/instruction/instruction_03.jpg`,
];
class BmaWaterMap extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.map = null;
    this.layerDataRainfall = createRef();
    this.layerDataTwitter = createRef();
    this.layerDataFloodroad = createRef();
    this.layerDataBmaWarning = createRef();
    this.layerDataTraffyFlood = createRef();
    this.refMap = createRef();
    this.layerRainfall24 = createRef();

    this.rainAmphoeRef = createRef();
    this.riverRef = createRef();
    this.provinceRef = createRef();

    this.state = {
      error: '',
      setOpen: true, // dev-support
      openRainLegend: true,
      openWlLegend: false,
      openFloodRoadLegend: false,
      // checkedRadar: false,
      dataSlideRaindaily: [],
      // getDateRadar: 'ตรวจวัด',
      // urlRadar: '',
      currentIndexRainDaily: 0,
      valueRainDaily: 16,
      imageRadarBkk: null,
      anchorEl: null,
      open: false,
      openInfo: false,
      openNoti: false,
      openLayer: false,
      openRiverComp: false,
      openRainComp: false,
      openRadarComp: false,
      openWaterComp: false,

      riverMapChecked: false,
      radarMapChecked: true,
      traffyMapChecked: true,
      twitterMapChecked: true,
      waterLevelMapChecked: false,
      floodStationMapChecked: true,
      waterFlowMapChecked: false,
      rainStationMapChecked: true,
      timelineStatus: '3',
      basemapChecked: '3',
      checked: false, // legend -show, -hidden

      dataUrbanWarning: [],
      compRainColorScale: 0,

      // lightbox
      photoIndex: 0,
      isOpen: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleModalClick = this.handleModalClick.bind(this);

    this.handleNotiClick = this.handleNotiClick.bind(this);
    this.handleLayerClick = this.handleLayerClick.bind(this);
    this.handleRiverClick = this.handleRiverClick.bind(this);
    this.handleRainClick = this.handleRainClick.bind(this);
    this.handleRadarClick = this.handleRadarClick.bind(this);
    this.handleWaterClick = this.handleWaterClick.bind(this);

    this.handleMapChecked = this.handleMapChecked.bind(this);
    this.handletimeLineStatus = this.handletimeLineStatus.bind(this);
    this.handleMenuPopupClose = this.handleMenuPopupClose.bind(this);
    this.handleDataUrbanWarning = this.handleDataUrbanWarning.bind(this);
    this.handleCompRainColorScaleChange = this.handleCompRainColorScaleChange.bind(this);

    this.refCompRadar = createRef();
    this.refCompRain = createRef();
    this.refCompRain1Day = createRef();
    this.refCompRain3Day7Day = createRef();
    this.refCompRain15Day30Day = createRef();
    this.refCompRadarBkk = createRef();
    this.myStateRadarRef = createRef(this.getDateRadar);

    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.bindMarker = this.bindMarker.bind(this);
    this.countNumberWarning = this.countNumberWarning.bind(this); // กระดิ่งแจ้งเตือน
    this.handlBaseMapChecked = this.handlBaseMapChecked.bind(this);

    // this.getGeoJsonAmphoe = this.getGeoJsonAmphoe.bind(this);
    // this.getGeoJsonRainAmphoe = this.getGeoJsonRainAmphoe.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    const { handleSetMap } = this.props;

    // document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '15%';
    this.map = this.refMap.current.leafletElement;
    handleSetMap(this.map);
    const geotiffUrl = `${process.env.MIX_FEWS_URL}/model-output/data_portal/radar/default.tif`;
    const geotiffOptions = {
      band: 0,
      // opacity: 0.5, // not work
      name: 'Composite Radar',
      renderer: new L.LeafletGeotiff.Plotty({
        displayMin: 0,
        displayMax: 200,
        colorScale: 'compRadarScale',
        clampLow: false,
        clampHigh: false,
      }),
    };

    this.refCompRadar.current = L.leafletGeotiff(geotiffUrl, geotiffOptions).addTo(this.map);

    this.handleCompRainFallGeoTiffColorScale(this.map);

    this.setStyleLayerControl();
    this.handleResetMap();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      open, openInfo, openNoti, openLayer, openRiverComp, checked,
      openRainComp, openRadarComp, openWaterComp, setOpen, anchorEl,
      isOpen,
    } = this.state;

    // fix rerender map and mobile not rerender
    if (nextProps !== this.props
      || nextState.openInfo !== openInfo
      || nextState.openNoti !== openNoti
      || nextState.openLayer !== openLayer
      || nextState.openRiverComp !== openRiverComp
      || nextState.openRainComp !== openRainComp
      || nextState.openRadarComp !== openRadarComp
      || nextState.openWaterComp !== openWaterComp
      || nextState.open !== open
      || nextState.setOpen !== setOpen
      || nextState.anchorEl !== anchorEl
      || nextState.checked !== checked
      || nextState.isOpen !== isOpen
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    // custom style fullmap
    if (prevProps.configurationDrawerOpen) {
      document.querySelector('body').style.overflowY = 'hidden';
    } else {
      document.querySelector('body').style.overflowY = 'hidden';
    }

    // แก้ไขเวลากดเมนู จะแสดง popup จะมีสกอบาร์ติดมา
    document.querySelector('body').style.overflow = 'hidden';

    const { getGeoJsonRainAmphoe } = this.props;

    if (prevProps.getGeoJsonRainAmphoe !== getGeoJsonRainAmphoe) {
      this.sortData();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleCompRainFallGeoTiffColorScale(map) {
    const geotiffUrl = `${process.env.MIX_FEWS_URL}/model-output/data_portal/radar/default.tif`;

    // สีภาพเส้นชั้นน้ำฝน (isohyet) สะสม 1 วัน
    const option1Day = {
      band: 0,
      name: 'Isohyet1Day',
      renderer: new L.LeafletGeotiff.Plotty({
        displayMin: 0,
        displayMax: 300,
        colorScale: 'bmaIsohyetScale_1Day',
        clampLow: false,
        clampHigh: false,
      }),
    };
    this.refCompRain1Day.current = L.leafletGeotiff(geotiffUrl, option1Day).addTo(map);

    // สีภาพเส้นชั้นน้ำฝน (isohyet) สะสม 15,30 วัน
    const option15Day30Day = {
      band: 0,
      name: 'Isohyet15Day30Day',
      renderer: new L.LeafletGeotiff.Plotty({
        displayMin: 0,
        displayMax: 700,
        colorScale: 'bmaIsohyetScale_15Day_30Day',
        clampLow: false,
        clampHigh: false,
      }),
    };
    this.refCompRain15Day30Day.current = L.leafletGeotiff(geotiffUrl, option15Day30Day).addTo(map);

    // สีภาพเส้นชั้นน้ำฝน (isohyet) สะสม 3,5,7 วัน
    const option3Day7Day = {
      band: 0,
      name: 'Isohyet3Day7Day',
      renderer: new L.LeafletGeotiff.Plotty({
        displayMin: 0,
        displayMax: 300,
        colorScale: 'bmaIsohyetScale_3Day_7Day',
        clampLow: false,
        clampHigh: false,
      }),
    };
    this.refCompRain3Day7Day.current = L.leafletGeotiff(geotiffUrl, option3Day7Day).addTo(map);
  }

  handleLayerClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openLayer: Boolean(event.currentTarget),
    });
  }

  handleModalClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openInfo: Boolean(event.currentTarget),
    });
  }

  handleNotiClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openNoti: Boolean(event.currentTarget),
    });
  }

  handleRiverClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openRiverComp: Boolean(event.currentTarget),
    });
  }

  handleRainClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openRainComp: Boolean(event.currentTarget),
    });
  }

  handleRadarClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openRadarComp: Boolean(event.currentTarget),
    });
  }

  handleWaterClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      openWaterComp: Boolean(event.currentTarget),
    });
  }

  handleClose(event) {
    this.setState({
      anchorEl: event.currentTarget,
      open: false,
      openInfo: false,
      openNoti: false,
      openRiverComp: false,
      openRainComp: false,
      openRadarComp: false,
      openWaterComp: false,
      openLayer: false,
    });
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
    });
  }

  handleOverlayChange(e) {
    if (e.name === 'เส้นคลอง') {
      this.setState({ riverMapChecked: e.type === 'overlayadd' });
    }
    if (e.name === 'แจ้งเตือนจากเรดาร์กทม.') {
      this.setState({ radarMapChecked: e.type === 'overlayadd' });
    }
    if (e.name === 'แจ้งเตือนจากTraffy') {
      this.setState({ traffyMapChecked: e.type === 'overlayadd' });
    }
    if (e.name === 'ทวิตเตอร์') {
      this.setState({ twitterMapChecked: e.type === 'overlayadd' });
    }
    if (e.name === 'สถานีระดับน้ำ') {
      this.setState({ waterLevelMapChecked: e.type === 'overlayadd' });
    }
    if (e.name === 'สถานีน้ำท่วมถนน') {
      this.setState({ floodStationMapChecked: e.type === 'overlayadd' });
    }
    if (e.name === 'สถานีวัดอัตราการไหล') {
      this.setState({ waterFlowMapChecked: e.type === 'overlayadd' });
    }
    if (e.name === 'สถานีโทรมาตรตรวจวัดฝน') {
      this.setState({ rainStationMapChecked: e.type === 'overlayadd' });
    }
  }

  handleMapChecked(name, value) {
    this.setState({ [name]: value });
    this.handleMenuPopupClose();
  }

  handletimeLineStatus(value) {
    const { timelineStatus } = this.state;
    const geotiffUrl = `${process.env.MIX_FEWS_URL}/model-output/data_portal/radar/default.tif`;

    this.refCompRain1Day.current.setURL(geotiffUrl);
    this.refCompRain3Day7Day.current.setURL(geotiffUrl);
    this.refCompRain15Day30Day.current.setURL(geotiffUrl);

    this.refCompRadar.current.setURL(geotiffUrl);

    if (timelineStatus === value) {
      this.setState({ timelineStatus: '0' });
      this.handleRadarBkk('0');
    } else {
      this.setState({ timelineStatus: value });
      this.handleRadarBkk(value);
    }
  }

  handleMenuPopupClose() {
    this.setState({
      openInfo: false,
      openNoti: false,
      openLayer: false,
      openRiverComp: false,
      openRainComp: false,
      openRadarComp: false,
      openWaterComp: false,
    });
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = '80%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '800px';
      heightChart = '65%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#061e2c',
      padding: '20px',
      position: 'absolute',
    };
  }

  handleDataUrbanWarning = value => {
    this.setState({ dataUrbanWarning: value });
  }

  handleCompRainColorScaleChange = value => {
    this.setState({ compRainColorScale: value });
  }

  setStyleLayerControl() {
    if (this.map) {
      // add opacity
      document.getElementsByClassName('leaflet-overlay-pane')[0].style.opacity = 0.5;
      // hide leaflet control
      document.getElementsByClassName('leaflet-bar leaflet-control')[0].style.display = 'none';
      setTimeout(() => document.getElementsByClassName('leaflet-control-layers leaflet-control')[0].classList.add('leaflet-control-A'));
    }
  }

  sortData = () => {
    const { getGeoJsonRainAmphoe, width } = this.props;
    const array = [];

    getGeoJsonRainAmphoe.forEach(element => {
      if (element.date !== undefined) {
        const dates = new Date(element.date.replace(/-/g, '/'));
        const datesThai = dates.toLocaleDateString('th-TH', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
        });
        if (isWidthDown('xs', width)) {
          array.push(datesThai.slice(0, 8));
        } else {
          array.push(datesThai.slice(0, 13));
        }
      } else {
        switch (element.status) {
          case 'sum_obs':
            array.push('ฝนตรวจวัด 3 วัน');
            break;
          case 'sum_fcst':
            array.push('ฝนพยากรณ์ 3 วัน');
            break;
          case 'sum_6day':
            array.push('ฝนสะสม 6 วัน');
            break;
          default: break;
        }
      }
    });

    const arrayyear = array.filter((a, b) => array.indexOf(a) === b);
    // dataSlideRaindaily

    const arrDataDaily = arrayyear.slice(0, 6);

    const step = Math.floor(100 / arrDataDaily.length);
    const NewarrDataDaily = arrDataDaily.map((ele, i) => {
      // New properties to be added
      const newPropsObj = {
        value: step * (i + 1),
        label: ele,
      };
      // Assign new properties and return
      return Object.assign(newPropsObj);
    });
    // filter current date for set default value feature slide rainforest (CompSlideRainDaily.js)
    // const currentDateData = isWidthDown('xs', width) ? NewarrDataDaily.filter(value => value.label.trim() === moment().format('DD MMM')) : NewarrDataDaily.filter(value => value.label.trim() === moment().add(+543, 'year').format('ll'));
    function toThaiDateString(date) {
      const monthNames = [
        'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.',
        'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.',
        'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.',
      ];
      const year = date.getFullYear() + 543;
      const month = monthNames[date.getMonth()];
      const numOfDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${numOfDay} ${month} ${year}`;
    }
    const currentDateData = NewarrDataDaily.filter(value => {
      if (isWidthDown('xs', width)) {
        return value.label.trim() === moment().format('DD MMM');
      }
      return value.label.trim() === toThaiDateString(new Date());
    });
    this.setState({
      dataSlideRaindaily: NewarrDataDaily,
      valueRainDaily: currentDateData[0].value,
    });
    this.setCurrentDate(currentDateData[0].label);
  }

  handleFeedbackClose = () => {
    this.setState({ setOpen: false });
  };

  handleClickRainLegend = () => {
    const { openRainLegend } = this.state;
    this.setState({ openRainLegend: openRainLegend === false });
  };

  handleClickWlLegend = () => {
    const { openWlLegend } = this.state;
    this.setState({ openWlLegend: openWlLegend === false });
  };

  handleClickFloodRoadLegend = () => {
    const { openFloodRoadLegend } = this.state;
    this.setState({ openFloodRoadLegend: openFloodRoadLegend === false });
  };

  handleInfo = (handler, data) => e => {
    e.preventDefault();
    const params = {
      header: ' ',
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <CompInfo
        id={''}
        provinceId={''}
      />,
    };

    handler(params);
  }

  handleRainfallChart = (handler, data, type, filterData) => e => {
    e.preventDefault();
    let params;
    if (type === 'rainfallYesterday') {
      params = {
        header: `กราฟฝน ${data.tele_station_name.th}`,
        content: (
          <RainfallChart
            id={data.tele_station_id}
            // provinceId={data.province_code}
            provinceName={data.province_name.th}
            selectedType={type}
            filterData={filterData}
          />
        ),
      };
    } else {
      params = {
        header: `กราฟฝน ${data.station.tele_station_name.th}`,
        content: (
          <RainfallChart
            id={data.station.id}
            provinceId={data.geocode.province_code}
            provinceName={data.geocode.province_name.th}
            amphoeName={data.geocode.amphoe_name.th}
            selectedType={type}
            filterData={filterData}
            year={data.rainfall_datetime.substring(0, 4)}
            month={data.rainfall_datetime.substring(5, 7)}
          />
        ),
      };
    }
    handler(params);
  };

  handleFloodRoadChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟวัดระดับน้ำท่วมถนน',
      content: (
        <FloodRoadChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
        />
      ),
    };
    handler(params);
  };

  handleWaterLevelCanalChart = (handler, data) => e => {
    e.preventDefault();
    const params = {
      header: 'กราฟคลอง',
      content: (
        <CanalChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
        />
      ),
    };
    handler(params);
  };

  handleWaterLevelChart = (handler, data) => e => {
    e.preventDefault();
    const params = {
      header: 'กราฟระดับน้ำ',
      content: (
        <WlChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
        />
      ),
    };
    handler(params);
  };

  handleWaterLevelFlowChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟวัดอัตราการไหล',
      content: (
        <FlowChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
        />
      ),
    };
    handler(params);
  };

  getProvinceStyle = () => ({
    stroke: true,
    color: 'black',
    weight: 1,
    fillColor: 'transparent',
    opacity: 1,
    fillOpacity: 0,
  });

  getProvinceBkkStyle = () => ({
    stroke: true,
    color: deepOrange[700],
    weight: 1,
    fillColor: '#FFF',
    fillOpacity: 0.25,
  });

  getRainAmphoeStyle = feature => {
    const { timelineStatus } = this.state;// สถานะเปิดปิด dialog ดูสถานการณ์ฝนรายวัน
    const maxrain = Number(feature.properties.rain);
    if (timelineStatus === '2') {
      if (maxrain > 0 && maxrain <= 1) {
        return {
          fillColor: '#FFFFFF',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 1 && maxrain <= 5) {
        return {
          fillColor: '#cbe2fc',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 5 && maxrain <= 10) {
        return {
          fillColor: '#add4fb',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 10 && maxrain <= 20) {
        return {
          fillColor: '#95e6af',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 20 && maxrain <= 35) {
        return {
          fillColor: '#72bd45',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 35 && maxrain <= 50) {
        return {
          fillColor: '#f8d649',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 50 && maxrain <= 70) {
        return {
          fillColor: '#ff8933',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 70 && maxrain <= 90) {
        return {
          fillColor: '#cc6524',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 90 && maxrain <= 150) {
        return {
          fillColor: '#fe2b1f',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 150 && maxrain <= 200) {
        return {
          fillColor: '#ca242b',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 200 && maxrain <= 300) {
        return {
          fillColor: '#851a1e',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain > 300) {
        return {
          fillColor: '#9c34c7',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      } if (maxrain === 0) {
        return {
          // fillColor: 'transparent',
          fillColor: '#FFFFFF',
          weight: 1.5,
          opacity: 1,
          color: '#FFFFFF',
          dashArray: '2',
          fillOpacity: 0.5,
        };
      }
      return {
        fillColor: '',
        weight: 1.5,
        opacity: 2,
        color: '#FFFFFF',
        dashArray: '3',
        fillOpacity: 0.5,
      };
    }
    return {
      // fillColor: '',
      fillColor: 'transparent',
      weight: 1.5,
      opacity: 1,
      color: '#FFFFFF',
      dashArray: 4,
      fillOpacity: 0.5,
      zIndexOffset: 3,
    };
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  // set marker icon based on criteria
  setIcon = val => {
    const status = WlStatusColor(val);
    return status.icon;
  };

  setIconCanal = (val, warningLevel, criticalLevel) => {
    const status = CanalStatusColor(val, warningLevel, criticalLevel);
    return status.icon;
  };

  // set marker icon based on criteria
  setIconFloodRoad = val => {
    const status = FloodRoadStatusColor(val);
    return status.icon;
  };

  // get dam status
  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(2), type);
    return status;
  };

  setIconRain24h = (val, type) => {
    const status = RainfallStatusColor(val, type);
    return status.icon;
  };

  formatWl = value => (typeof value === 'undefined' || value == null
    ? '-'
    : parseFloat(value).toFixed(2));

  eachDataWaterLevel = (data, i) => {
    const {
      location, zoom, dataWaterLevel, classes,
    } = this.props;
    if (
      data.lat
      && data.long
      && data.waterlevel_msl != null
    ) {
      // marker position
      const position = [
        parseFloat(data.lat),
        parseFloat(data.long),
      ];

      // marker icons
      let icon;
      if (data.station_type === 'canal') {
        icon = this.setIconCanal(
          parseFloat(data.waterlevel_msl), data.warning_level, data.critical_level,
        );
      } else {
        icon = this.setIcon(parseFloat(data.waterlevel_msl));
      }
      // const icon = iconRect.blue;
      // status colors
      // const statusColor = WlStatusColor(data.waterlevel_msl);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={dataWaterLevel}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false} className={classes.bmaPopup}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLinePopup}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Box className={classes.titlePopup}>
                        {'สถานี '}
                        {data.station_name}
                      </Box>
                      <Box className={classes.subTitlePopup}>
                        {locationText}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  {
                    (data.station_type === 'canal' && (data.wl_out > 0 || data.wl_out < 0))
                      ? (
                        <>
                          <TableRow>
                            <TableCell className={classes.hiddenLinePopup}>
                              ระดับน้ำด้านนอก
                            </TableCell>
                            <TableCell className={classes.hiddenLinePopup} align="right">
                              <strong>{this.formatWl(data.wl_out)}</strong>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className={classes.hiddenLinePopup}>
                              ระดับน้ำด้านใน
                            </TableCell>
                            <TableCell className={classes.hiddenLinePopup} align="right">
                              <strong>{this.formatWl(data.waterlevel_msl)}</strong>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                      : (
                        <TableRow>
                          <TableCell className={classes.hiddenLinePopup}>
                            ระดับน้ำ
                          </TableCell>
                          <TableCell className={classes.hiddenLinePopup} align="right">
                            <strong>{this.formatWl(data.waterlevel_msl)}</strong>
                          </TableCell>
                        </TableRow>
                      )
                  }

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ม.รทก.)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLinePopup}>
                      <Box className={classes.dataRef}>
                        {`ข้อมูล : ${data.agency.agency_name.th}`}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.datePopup}>
                      {'ล่าสุด : '}
                      {data.waterlevel_datetime}
                      {' '}
                      {'น.          '}
                    </TableCell>
                    <TableCell className={classes.hiddenLinePopup} align="right">
                      {
                        (data.station_type === 'canal')
                          ? (
                            <BmaContext.Consumer>
                              {({ handleModalOpen }) => (
                                <Button
                                  color="secondary"
                                  onClick={this.handleWaterLevelCanalChart(
                                    handleModalOpen,
                                    data,
                                  )}
                                  title="แสดงกราฟ ระดับน้ำ"
                                  style={{ padding: 0 }}
                                >
                                  <FaChartLine
                                    className={classes.rightIcon}
                                    style={{ fontSize: '1em' }}
                                  />
                                </Button>
                              )}
                            </BmaContext.Consumer>
                          )
                          : (
                            <BmaContext.Consumer>
                              {({ handleModalOpen }) => (
                                <Button
                                  color="secondary"
                                  onClick={this.handleWaterLevelChart(
                                    handleModalOpen,
                                    data,
                                  )}
                                  title="แสดงกราฟ ระดับน้ำ"
                                  style={{ padding: 0 }}
                                >
                                  <FaChartLine
                                    className={classes.rightIcon}
                                    style={{ fontSize: '1em' }}
                                  />
                                </Button>
                              )}
                            </BmaContext.Consumer>
                          )

                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`สถานี${data.station_name}`}>
              <span>
                <strong>{`สถานี${data.station_name}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} `}
                <br />
                {`จ.${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataWaterCanal = (data, i) => {
    const {
      location, zoom, dataWaterCanal, classes,
    } = this.props;

    if (data.station.canal_lat && data.station.canal_long) {
      // marker position
      const position = [
        parseFloat(data.station.canal_lat),
        parseFloat(data.station.canal_long),
      ];
      const iconGreenUp = new L.Icon({
        iconUrl: `${initPath}images/marker/triangle_new/green-up.png`,
        iconSize: [10, 10],
      });
      const icon = iconGreenUp;

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={dataWaterCanal}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false} className={classes.bmaPopup}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLinePopup}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Box className={classes.titlePopup}>
                        {'สถานี '}
                        {data.station.canal_name.th}
                      </Box>
                      <Box className={classes.subTitlePopup}>
                        {locationText}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLinePopup}>
                      ระดับน้ำ
                    </TableCell>
                    <TableCell className={classes.hiddenLinePopup} align="right">
                      <strong>{this.formatWl(data.canal_value)}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ม.รทก.)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLinePopup}>
                      <Box className={classes.dataRef}>
                        {`ข้อมูล : ${data.agency.agency_name.th}`}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.datePopup}>
                      {'ล่าสุด : '}
                      {data.canal_datetime}
                      {' '}
                      {'น.          '}
                    </TableCell>
                    <TableCell className={classes.hiddenLinePopup} align="right">
                      {/* <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleWaterLevelCanalChart(
                              handleModalOpen,
                              data,
                            )}
                            title="แสดงกราฟ test"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ fontSize: '2em' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer> */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>

          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.canal_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.canal_name.th}`}</strong>
                <br />
                {`${data.geocode.tumbon_name.th} ${data.geocode.amphoe_name.th} `}
                <br />
                {`${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>

      );
    }
    return false;
  }

  eachDataRainfall24h = (data, i) => {
    const { classes, dataRainfall24h } = this.props;
    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
      && data.rain_24h >= 0
      && data.station.id > 0
    ) {
      // && data.rain_24h != null
      // marker position
      const position = [
        parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long),
      ];
      // marker icons
      const icon = this.setIconRain24h(
        parseFloat(data.rain_24h),
        'rainfall24h',
      );
      // status colors
      // const statusColor = RainfallStatusColor(data.rain_24h);
      const rainfall24h = parseFloat(data.rain_24h).toFixed(2);
      const textRainfallStatus = this.rainfallStatus(rainfall24h, 'rainfall24h')
        .color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(
        rainfall24h,
        'rainfall24h',
      ).color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}

        >
          <Popup className={classes.bmaPopup}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.hiddenLinePopup}
                    padding="none"
                    align="center"
                    colSpan={2}
                  >
                    <Box className={classes.titlePopup}>
                      {`สถานี${data.station.tele_station_name.th}`}
                    </Box>
                    <Box className={classes.subTitlePopup}>
                      {` อ.${data.geocode.amphoe_name.th}`}
                      {` จ.${data.geocode.province_name.th}`}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.hiddenLinePopup}
                    align="center"
                    colSpan={2}
                  >
                    <Box
                      pt={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                    >
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${data.rain_24h}`}
                          </Typography>
                          <Typography variant="h6">มม.</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    padding="none"
                    align="center"
                    colSpan={2}
                    style={{ paddingBottom: 10 }}
                  >
                    <Typography variant="h6">
                      ปริมาณฝนสะสม 24 ย้อนหลัง
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className={classes.hiddenLinePopup}>
                    <Box className={classes.dataRef}>
                      {`ข้อมูล : ${data.agency.agency_name.th}`}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.datePopup}>
                    {'ล่าสุด : '}
                    {`${data.rainfall_datetime} น.`}
                  </TableCell>
                  <TableCell className={classes.hiddenLinePopup} align="right">
                    {data.agency.agency_shortname.en !== 'DWR' ? (
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleRainfallChart(
                              handleModalOpen,
                              data,
                              'rainfall24h',
                              dataRainfall24h,
                            )}
                            title="แสดงกราฟ"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    ) : (
                      <BmaContext.Consumer>
                        {() => (
                          <Button
                            color="secondary"
                            title="ไม่มีกราฟ"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ color: '#9e9e9e' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.tele_station_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.tele_station_name.th}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} จ.${data.geocode.province_name.th}`}
                <br />
                {`ข้อมูล : ${data.agency.agency_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataFloodRoad = (data, i) => {
    const {
      location, zoom, dataWaterFloodRoad, classes,
    } = this.props;
    if (data.station.floodroad_lat && data.station.floodroad_long) {
      // marker position
      const position = [
        parseFloat(data.station.floodroad_lat),
        parseFloat(data.station.floodroad_long),
      ];

      // marker icons
      const icon = this.setIconFloodRoad(parseFloat(data.floodroad_value));

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={dataWaterFloodRoad}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false} className={classes.bmaPopup}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLinePopup}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Box className={classes.titlePopup}>
                        {'สถานี '}
                        {data.station.floodroad_name.th}
                      </Box>
                      <Box className={classes.subTitlePopup}>
                        {locationText}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLinePopup}>
                      ระดับน้ำท่วมถนน
                    </TableCell>
                    <TableCell className={classes.hiddenLinePopup} align="right">
                      <strong>{this.formatWl(data.floodroad_value)}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ซม.)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLinePopup}>
                      <Box className={classes.dataRef}>
                        {`ข้อมูล: ${data.agency.agency_name.th}`}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.datePopup}>
                      {'ล่าสุด :'}
                      {data.floodroad_datetime}
                      {' '}
                      {'น.          '}
                    </TableCell>
                    <TableCell className={classes.hiddenLinePopup} align="right">
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleFloodRoadChart(
                              handleModalOpen,
                              data,
                            )}
                            title="แสดงกราฟ"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ fontSize: '1em' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.floodroad_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.floodroad_name.th}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} `}
                <br />
                {`จ.${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  eachDataFlow = (data, i) => {
    const {
      location, zoom, dataWaterFlow, classes,
    } = this.props;
    if (data.station.flow_lat && data.station.flow_long) {
      // marker position
      const position = [
        parseFloat(data.station.flow_lat),
        parseFloat(data.station.flow_long),
      ];

      const icon = iconCircle.green;

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `ต.${data.geocode.tumbon_name.th} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `อ.${data.geocode.amphoe_name.th} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `จ.${data.geocode.province_name.th}`;
      }

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={dataWaterFlow}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false} className={classes.bmaPopup}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLinePopup}
                      padding="none"
                      colSpan={2}
                      align="center"
                    >
                      <Box className={classes.titlePopup}>
                        {'สถานี '}
                        {data.station.flow_name.th}
                      </Box>
                      <Box className={classes.subTitlePopup}>
                        {locationText}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLinePopup}>
                      อัตราการไหล
                    </TableCell>
                    <TableCell className={classes.hiddenLinePopup} align="right">
                      <strong>{this.formatWl(data.flow_value)}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="caption" color="secondary">
                        หน่วย : (ลบ.ม/วินาที)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLinePopup}>
                      <Box className={classes.dataRef}>
                        {`ข้อมูล : ${data.agency.agency_name.th}`}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.datePopup}>
                      {'ล่าสุด : '}
                      {data.flow_datetime}
                      {' '}
                      {'น.          '}
                    </TableCell>
                    <TableCell className={classes.hiddenLinePopup} align="right">
                      <BmaContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleWaterLevelFlowChart(
                              handleModalOpen,
                              data,
                            )}
                            title="แสดงกราฟ อัตราการไหล"
                            style={{ padding: 0 }}
                          >
                            <FaChartLine
                              className={classes.rightIcon}
                              style={{ fontSize: '1em' }}
                            />
                          </Button>
                        )}
                      </BmaContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>

          <Hidden smDown>
            <Tooltip title={`สถานี${data.station.flow_name.th}`}>
              <span>
                <strong>{`สถานี${data.station.flow_name.th}`}</strong>
                <br />
                {`ต.${data.geocode.tumbon_name.th} อ.${data.geocode.amphoe_name.th} `}
                <br />
                {`จ.${data.geocode.province_name.th}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  changeFormatDate = date => {
    const dates = new Date(date.replace(/-/g, '/'));
    const datesThai = dates.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    }).slice(0, 13);
    return datesThai;
  }

  matchesData = element => {
    const {
      getGeoJsonAmphoe,
    } = this.props;
    const geoamphoe = getGeoJsonAmphoe;

    const matches = [];
    for (let i = 0; i < geoamphoe.length; i += 1) {
      for (let j = 0; j < element.data.length; j += 1) {
        if (geoamphoe[i].properties.AMPHOE_IDN === String(element.data[j].ID)) {
          geoamphoe[i].properties.rain = element.data[j].Rain;
          matches.push(geoamphoe[i]);
        }
      }
    }
    return matches;
  }

  setCurrentDate = value => {
    const {
      getGeoJsonRainAmphoe,
    } = this.props;

    // this.setState({ getCurrent: value });
    let matchData = null;
    let NewDate = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const element of getGeoJsonRainAmphoe) {
      if (element.date !== undefined) {
        NewDate = this.changeFormatDate(element.date);
        if (NewDate === value) {
          matchData = this.matchesData(element);
        }
      } else if ((value === 'ฝนตรวจวัด 3 วัน') && (element.status === 'sum_obs')) {
        matchData = this.matchesData(element);
      } else if ((value === 'ฝนพยากรณ์ 3 วัน') && (element.status === 'sum_fcst')) {
        matchData = this.matchesData(element);
      } else if ((value === 'ฝนสะสม 6 วัน') && (element.status === 'sum_6day')) {
        matchData = this.matchesData(element);
      }
    }
  }

  handleChangeRaindaily = event => {
    // this.setState({ getCurrent: event.target.value });
    this.setCurrentDate(event.target.value);
  }

  handleChangeSlideRaindaily = async (event, newValue) => {
    const {
      dataSlideRaindaily,
    } = this.state;

    const index = dataSlideRaindaily.findIndex(ele => ele.value === newValue);

    if (index) {
      this.setState({ currentIndexRainDaily: index + 1 });
    }

    const find = dataSlideRaindaily.find(mark => mark.value === newValue);
    if (find) {
      // this.setState({ getCurrent: find.label });
      this.setCurrentDate(find.label);
    }
    this.setState({ valueRainDaily: newValue });
  }

  setCurrentIndexRainDaily = value => {
    this.setState({ currentIndexRainDaily: value });
  }

  FormControlLabelAndRadio = dateTime => {
    const {
      classes,
    } = this.props;
    return (
      <FormControlLabel
        key={dateTime}
        value={dateTime}
        control={(
          <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}

          />
        )}
        label={dateTime}
      />
    );
  }

  highlightFeature = e => {
    const layer = e.target;
    layer.setStyle({
      weight: 1,
      opacity: 1,
      color: 'red',
      dashArray: '0',
      fillOpacity: 0.5,
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight = e => {
    const layer = e.target;
    layer.setStyle({
      weight: 2,
      opacity: 1,
      color: '#FFFFFF',
      dashArray: '3',
      fillOpacity: 0.5,
    });
  }

  onEachFeatureRain = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });

    const rainQuantity = (layer.feature.properties.rain) ? `${layer.feature.properties.rain}มม.` : ' - ';
    const popupContent = `
      <b>จังหวัด : </b>${layer.feature.properties.PROV_NAM_T}<br>
      <b>อำเภอ : </b>${layer.feature.properties.AMPHOE_T}<br>
      <b>ปริมาณฝนสูงสุด : </b>${rainQuantity}<br/>
    `;

    const TooltipContent = `
      <b>จังหวัด : </b>${layer.feature.properties.PROV_NAM_T}<br>
      <b>อำเภอ : </b>${layer.feature.properties.AMPHOE_T}<br>
    `;

    layer.bindPopup(popupContent);
    layer.bindTooltip(TooltipContent);
  }

  // radar

  setDateRadar = data => {
    this.myStateRadarRef.current = data;
    // this.setState({ getDateRadar: data });
  };

  handleDateChange = (val, txt, checkOpen) => {
    this.setDateRadar(txt);
    if (checkOpen === true) {
      this.refCompRadar.current.setURL(val);
    }
    // this.setState({ urlRadar: val });
    // refCompRadar.current.setURL(`https://live1.hii.or.th/product/latest/comp_radar/${val}`);
  }

  handleInitDate = val => {
    this.setDateRadar(val);
  }

  // handleChangeSlideRadar = () => {
  //   const {
  //     checkedRadar, getDateRadar, urlRadar,
  //   } = this.state;

  //   this.setState({ checkedRadar: !checkedRadar });

  //   const geotiffUrl = `${process.env.MIX_FEWS_URL}/model-output/data_portal/radar/default.tif`;

  //   if (!checkedRadar === false) {
  //     this.refCompRain1Day.current.setURL(geotiffUrl);
  //     this.refCompRain3Day7Day.current.setURL(geotiffUrl);
  //     this.refCompRain15Day30Day.current.setURL(geotiffUrl);
  //   } else {
  //     this.handleDateChange(urlRadar, getDateRadar, !checkedRadar);
  //   }
  // };

  // radar bkk
  handleRadarBkk = value => {
    const { imageRadarBkk } = this.state;
    if (value !== '3') {
      this.map.removeLayer(imageRadarBkk);
    }
  };

  handleSetImageRadarBkk = image => {
    this.setState({ imageRadarBkk: image });
  }

  handleResetMap = () => {
    const { location, zoom } = this.props;
    this.map.setView([13.72, 100.6], zoom);
    this.map.setView(location, zoom);
    const btnLocate = document.getElementsByClassName('leaflet-bar-part leaflet-bar-part-single')[0];
    const LocationMarker = L.Marker.extend({
      initialize(latlng, options) {
        L.Util.setOptions(this, options);
        this._latlng = latlng;
        this.createIcon();
      },
      createIcon() {
        const opt = this.options;
        let style = '';
        if (opt.color !== undefined) {
          style += `stroke:${opt.color};`;
        }
        if (opt.weight !== undefined) {
          style += `stroke-width:${opt.weight};`;
        }
        if (opt.fillColor !== undefined) {
          style += `fill:${opt.fillColor};`;
        }
        if (opt.fillOpacity !== undefined) {
          style += `fill-opacity:${opt.fillOpacity};`;
        }
        if (opt.opacity !== undefined) {
          style += `opacity:${opt.opacity};`;
        }
        const icon = this._getIconSVG(opt, style);
        this._locationIcon = L.divIcon({
          className: icon.className,
          html: icon.svg,
          iconSize: [icon.w, icon.h],
        });
        this.setIcon(this._locationIcon);
      },
      _getIconSVG(options, style) {
        const r = options.radius;
        const w = options.weight;
        const s = r + w;
        const s2 = s * 2;
        const svg = `${`<svg xmlns="http://www.w3.org/2000/svg" width="${s2}" height="${s2}" version="1.1" viewBox="-${s} -${s} ${s2} ${s2}">`
          + '<circle r="'}${r}" style="${style}" />`
          + '</svg>';
        return {
          className: 'leaflet-control-locate-location',
          svg,
          w: s2,
          h: s2,
        };
      },
      setStyle(style) {
        L.Util.setOptions(this, style);
        this.createIcon();
      },
    });
    const locateOptions = {
      position: 'topright',
      initialZoomLevel: 13,
      icon: 'fa fa-location-arrow',
      circlePadding: [0, 0],
      strings: {
        title: 'แสดงตำแหน่งปัจจุบัน.',
        popup: 'คุณอยู่ที่นี่',
      },
      markerStyle: {
        className: 'leaflet-control-locate-location-arrow',
        color: deepOrange[500],
        fillColor: '#fc8428',
        fillOpacity: 1,
        weight: 3,
        opacity: 1,
        radius: 9,
      },
      markerClass: LocationMarker,
      onActivate: () => { },
    };
    const lc = new Locate(locateOptions);
    if (btnLocate) {
      btnLocate.click();
    } else {
      lc.addTo(this.map);
      lc.start();
    }
  }

  countNumberWarning = () => {
    const { dataRainfall24h, dataWaterFloodRoad } = this.props;
    const { dataUrbanWarning } = this.state;
    const resultRainfall24h = [];
    const resultWaterFloodRoad = [];
    // สถานีฝนที่มีฝนตก >= 60 mm
    dataRainfall24h.map(data => data.rain_24h >= 60 && resultRainfall24h.push(data));
    // สถานีน้ำท่วมถนนที่มีระดับน้ำ >=20 cm.
    dataWaterFloodRoad.map(data => data.floodroad_value >= 20 && resultWaterFloodRoad.push(data));
    const countNum = resultWaterFloodRoad.length + resultRainfall24h.length
      + dataUrbanWarning.length;
    return countNum; // จำนวนตัวเลขสถานการณ์ที่เข้าเงื่อนไขแจ้งเตือน
  }

  handleChange = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  isFullscreen = () => {
    const elem = document.documentElement;
    if (window.navigator.userAgent.includes('Chrome')) {
      if (!document.fullscreenElement) {
        elem.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else if (window.navigator.userAgent.includes('WebKit')) {
      if (!document.webkitFullscreenElement) {
        elem.webkitRequestFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } else if (window.navigator.userAgent.includes('Firefox')) {
      if (!document.mozFullScreenElement) {
        elem.mozRequestFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
    }
  }

  zoomIn() {
    this.map.zoomIn();
  }

  zoomOut() {
    this.map.zoomOut();
  }

  handlBaseMapChecked(value) {
    this.setState({ basemapChecked: value });
    if (this.map) {
      const layerControlElement = document.getElementsByClassName('leaflet-control-layers')[0];
      layerControlElement.getElementsByTagName('input')[Number(value)].click();
    }
    this.handleMenuPopupClose();
  }

  render() {
    const {
      error, setOpen, dataSlideRaindaily, currentIndexRainDaily,
      valueRainDaily, riverMapChecked,
      radarMapChecked, traffyMapChecked, twitterMapChecked,
      waterLevelMapChecked, floodStationMapChecked, waterFlowMapChecked,
      rainStationMapChecked, timelineStatus, basemapChecked, openLayer, checked,
      openWaterComp, anchorEl, openInfo, openNoti, openRiverComp, openRadarComp, openRainComp, open,
      dataUrbanWarning, compRainColorScale, photoIndex, isOpen,
    } = this.state;

    const {
      classes, location, zoom, dataRainfall24h, dataWaterLevel,
      dataWaterFloodRoad, dataWaterFlow, dataTwitterNews, getGeoJsonAmphoe,
      dataRadarBkk, metaDataRadarBkk, dataRainGeotiff,
    } = this.props;
    console.log('getGeoJsonAmphoe', getGeoJsonAmphoe);
    let errorMsg = '';
    if (error) {
      errorMsg = <div className="text-center">{error.message}</div>;
    }

    return (
      <>
        {errorMsg}
        {isOpen && (
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            mainSrc={instruction[photoIndex]}
            nextSrc={instruction[(photoIndex + 1) % instruction.length]}
            prevSrc={instruction[(photoIndex + instruction.length - 1) % instruction.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => this.setState({
              photoIndex: (photoIndex + instruction.length - 1) % instruction.length,
            })}
            onMoveNextRequest={() => this.setState({
              photoIndex: (photoIndex + 1) % instruction.length,
            })}
          />
        )}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={setOpen}
          autoHideDuration={6000}
          style={{ zIndex: 999999, bottom: 100 }}
          message={(
            <>
              <Link href="https://devsupport.hii.or.th/" target="_blank" title="แบบสำรวจแจ้งปัญหาการใช้งาน BMA">
                <img src={`${initPath}images/theme/bma/banner/bma-feedback.png`} width="350" height="95" alt="แบบสำรวจแจ้งปัญหาการใช้งาน BMA" className={classes.bmaImgFeedback} />
              </Link>
            </>
          )}
          action={(
            <>
              <IconButton size="small" aria-label="close" onClick={this.handleFeedbackClose} style={{ marginRight: '-3px', backgroundColor: '#2630A8' }}>
                <CloseIcon fontSize="small" style={{ color: 'white' }} />
              </IconButton>
            </>
          )}
        />
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomControl={false}
          maxZoom={16}
          minZoom={7}
          fullscreenControl
          maxBounds={[
            [12, 99], // south west
            [15, 102], // north east
          ]}
          ref={this.refMap}
          style={{
            height: window.height,
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
          }}
          onoverlayadd={e => this.handleOverlayChange(e)}
          onoverlayremove={e => this.handleOverlayChange(e)}
          whenReady={map => {
            map.target.locate();
            map.target.on('locationfound', () => {});
          }}
        >

          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution='&copy; <a href="http://osm.org/copyright">ESRI World Street Map</a> contributors'
          />

          <GeoJSON
            key={uniqueId()}
            data={getGeoJsonAmphoe}
            style={this.getRainAmphoeStyle}
            onEachFeature={this.onEachFeatureRain}
            ref={this.rainAmphoeRef}
          />
          {timelineStatus === '1' ? (
            <CompRainFallGeoTiff
              dataRainGeotiff={dataRainGeotiff}
              refCompRain1Day={this.refCompRain1Day.current}
              refCompRain3Day7Day={this.refCompRain3Day7Day.current}
              refCompRain15Day30Day={this.refCompRain15Day30Day.current}
              handleCompRainColorScaleChange={this.handleCompRainColorScaleChange}
            />
          ) : null}

          {timelineStatus === '2' ? (
            <CompSlideRainDaily
              dataSlideRaindaily={dataSlideRaindaily}
              currentIndexRainDaily={currentIndexRainDaily}
              valueRainDaily={valueRainDaily}
              handleChangeSlideRaindaily={this.handleChangeSlideRaindaily}
              setCurrentIndexRainDaily={this.setCurrentIndexRainDaily}
            />
          ) : null}

          {timelineStatus === '3' && !isEmpty(metaDataRadarBkk)
            ? (
              <CompRadarBkk
                dataRadarBkk={dataRadarBkk}
                currentMapRef={this.refMap.current.leafletElement}
                metaDataRadarBkk={metaDataRadarBkk}
                handleSetImageRadarBkk={this.handleSetImageRadarBkk}
              />
            ) : null}

          {/* Layer */}
          <LayersControl collapsed className={classes.bmaLayer}>
            <BaseLayer name="OpenStreetMap">
              <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Carto">
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              />
            </BaseLayer>
            <BaseLayer name="Stadia.AlidadeSmoothDark">
              <TileLayer
                url="https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=m1wmkesPbQtvW852fhyMWn6XHj4XEy7ACy4DnKqXVNDgwGFzzvepTUwemCLloMxG"
                attribution='<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </BaseLayer>
            <BaseLayer name="Darkall" checked>
              <TileLayer
                url=" https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
              />
            </BaseLayer>

            <BaseLayer name="Google Satellite">
              <TileLayer
                url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                attribution="Google Satellite"
              />
            </BaseLayer>

            <BaseLayer name="Google Traffic">
              <TileLayer
                url="https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}"
                maxZoom={20}
                minZoom={2}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                attribution="Google Traffic"
              />
            </BaseLayer>

            <Overlay key="waterlevel" name="สถานีระดับน้ำ" checked={waterLevelMapChecked}>
              <LayerGroup
                ref={this.waterLevelLayer}
                onadd={() => { this.setState({ openWlLegend: true }); }}
                onremove={() => { this.setState({ openWlLegend: false }); }}
              >
                {orderBy(
                  dataWaterLevel.filter(d => d.waterlevel_msl !== null),
                  ['storage_percent'],
                  ['desc'],
                ).map(this.eachDataWaterLevel)}
              </LayerGroup>
            </Overlay>

            <Overlay
              key="layerRainfall24"
              name="สถานีโทรมาตรตรวจวัดฝน"
              checked={rainStationMapChecked}
            >
              <LayerGroup
                ref={this.layerRainfall24}
                onadd={() => { this.setState({ openRainLegend: true }); }}
                onremove={() => { this.setState({ openRainLegend: false }); }}
              >
                {dataRainfall24h.map(this.eachDataRainfall24h)}
              </LayerGroup>
            </Overlay>

            <Overlay key="canal" name="สถานีน้ำท่วมถนน" checked={floodStationMapChecked}>
              <LayerGroup
                ref={this.layerDataFloodroad}
                onadd={() => { this.setState({ openFloodRoadLegend: true }); }}
                onremove={() => { this.setState({ openFloodRoadLegend: false }); }}
              >
                {dataWaterFloodRoad
                  .filter(d => d.floodroad_value > 20)
                  .map(this.eachDataFloodRoad)}
              </LayerGroup>
            </Overlay>

            <Overlay key="flowLayer" name="สถานีวัดอัตราการไหล" checked={waterFlowMapChecked}>
              <LayerGroup ref={this.layerDataRainfall}>
                {orderBy(
                  dataWaterFlow.filter(d => d.flow_datetime !== null),
                  ['desc'],
                ).map(this.eachDataFlow)}
              </LayerGroup>
            </Overlay>

            {/* BmaWarning */}
            <Overlay key="BmaWarning" name="แจ้งเตือนจากเรดาร์กทม." checked={radarMapChecked}>
              <LayerGroup ref={this.layerDataBmaWarning} key="BmaWarning">
                <BmaWarning handleDataUrbanWarning={this.handleDataUrbanWarning} />
              </LayerGroup>
            </Overlay>

            <Overlay key="traffyFlood" name="แจ้งเตือนจากTraffy" checked={traffyMapChecked}>
              <LayerGroup ref={this.layerDataTraffyFlood}>
                <CompTraffyFlood bindMarker={this.bindMarker} />
              </LayerGroup>
            </Overlay>

            <Overlay key="twitterLayer" name="ทวิตเตอร์" checked={twitterMapChecked}>
              <LayerGroup ref={this.layerDataTwitter}>
                <CompTwitter
                  dataTwitter={dataTwitterNews}
                  classes={classes}
                  bindMarker={this.bindMarker}
                />
              </LayerGroup>
            </Overlay>

            <Overlay name="เส้นคลอง" checked={riverMapChecked}>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:spatial_canal_bma"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
          </LayersControl>

          {/* banner logo */}
          <Control position="topleft">
            <Button
              color="secondary"
              onClick={this.handleResetMap}
              title="HOME"
              style={{ padding: 0 }}
            >
              <BmaLogo className={classes.bmaLogo} style={{ color: '#fff' }} />
            </Button>
          </Control>
          <Control position="topright">
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBmaOutline}>
              <Box m={1}>
                <TooltipMui title="แจ้งเตือน" placement="left-start" style={{ zIndex: 1 }}>
                  <Button
                    className={classes.iconBma}
                    aria-describedby={this.id}
                    onClick={this.handleNotiClick}
                  >
                    {/* ตัวเลขแจ้งเตือนสถานการ์ถ้ามีสถานการ์มากกว่า 1 icon จะแสดงสีส้ม
                    แต่หากเหตุการณ์ปกติจะแสดงสีขาว */}
                    <Badge
                      badgeContent={this.countNumberWarning()}
                      overlap="rectangular"
                      color="error"
                    >
                      <NotificationsActiveIcon size="small" style={{ color: this.countNumberWarning() !== 0 ? '#E39D00' : '#FFFFFF' }} />
                    </Badge>
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openNoti}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className={classes.bmaPophover}
                  style={{ zIndex: 999999 }}
                >
                  <CompNoti
                    rainfall={dataRainfall24h}
                    floodroad={dataWaterFloodRoad}
                    dataUrbanWarning={dataUrbanWarning}
                  />
                </Popover>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <Box>
                <TooltipMui title="คู่มือการใช้งาน" placement="left-start">
                  <Button
                    className={classes.iconBma}
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    <Help size="small" />
                  </Button>
                </TooltipMui>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <Box>
                <TooltipMui title="แผนที่ฐาน" placement="left-start">
                  <Button
                    className={classes.iconBma}
                    id="btn"
                    onClick={this.handleLayerClick}
                  >
                    <LayersIcon size="small" />
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openLayer}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className={classes.bmaPophover}
                  style={{ zIndex: 999999 }}
                >
                  <CompLayer
                    handlBaseMapChecked={this.handlBaseMapChecked}
                    basemapChecked={basemapChecked}
                  />
                </Popover>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <Box>
                <TooltipMui title="ตำแหน่งปัจจุบัน" placement="left-start">
                  <Button
                    className={classes.iconBma}
                    onClick={this.handleResetMap}
                  >
                    <ExploreIcon size="small" />
                  </Button>
                </TooltipMui>
              </Box>
            </Box>
            <Hidden smDown>
              <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
                <Box>
                  <TooltipMui title="เต็มหน้าจอ" placement="left-start">
                    <Button
                      className={classes.iconBma}
                      onClick={() => this.isFullscreen()}
                    >
                      <FullscreenIcon size="small" />
                    </Button>
                  </TooltipMui>
                </Box>
              </Box>
            </Hidden>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <Box>
                <TooltipMui title="แนะนำการใช้งาน" placement="left-start" style={{ zIndex: 1 }}>
                  <Button
                    className={classes.iconBma}
                    aria-describedby={this.id}
                    // onClick={this.handleClick}
                    onClick={this.handleModalClick}
                  >
                    <InfoIcon size="small" />
                  </Button>
                </TooltipMui>
                <Modal
                  id="modal-chart"
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={openInfo}
                  onClose={this.handleClose}
                  style={{ align: 'center', justifyContent: 'center' }}
                >
                  <div style={this.getModalStyle()} className={classes.paper}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={0}
                    >
                      <Grid item>
                        <IconButton onClick={this.handleClose} style={{ padding: 0 }}>
                          <CloseIcon fontSize="small" style={{ color: 'white' }} />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Typography variant="h5" id="modal-title">
                      {/* {modalHeader} */}
                    </Typography>

                    <Typography variant="subtitle1" id="simple-modal-description">
                      {/* {modalSubHeader} */}
                    </Typography>
                    <Box mt={{ xs: 0, sm: 3 }}>
                      <AboutContent />
                    </Box>
                  </div>
                </Modal>
                {/* <Popover
                  id={this.id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className={classes.bmaPophover}
                  style={{ zIndex: 999999 }}
                >
                  <CompInfo />
                </Popover> */}
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <TooltipMui title="ขยาย" placement="left-start">
                <Box>
                  <Button
                    className={classes.iconBma}
                    onClick={() => this.zoomIn()}
                    aria-label="zoom in"
                  >
                    <ZoomInIcon size="small" />
                  </Button>
                </Box>
              </TooltipMui>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.sidebarBma}>
              <TooltipMui title="ย่อ" placement="left-start">
                <Box>
                  <Button
                    className={classes.iconBma}
                    onClick={() => this.zoomOut()}
                    aria-label="zoom out"
                  >
                    <ZoomOutIcon size="small" />
                  </Button>
                </Box>
              </TooltipMui>
            </Box>
          </Control>

          {/* Layer Data */}
          <Control position="topright" style={{ zIndex: '99999 !important' }}>
            <Box display="block" justifyContent="center" alignItems="center" className={classes.sidebarBmaGroup}>
              <Box>
                <TooltipMui title="ฝน" placement="left-start">
                  <Button
                    className={classes.iconBma}
                    onClick={this.handleRainClick}
                  >
                    <HIIForecastRaindaylyIcon size="small" className={classes.sidebarIcon} />
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openRainComp}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CompRainSelect
                    handletimeLineStatus={this.handletimeLineStatus}
                    timelineStatus={timelineStatus}
                    handleMenuPopupClose={this.handleMenuPopupClose}
                  />
                </Popover>
              </Box>

              <Box>
                <TooltipMui title="เรดาร์" placement="left-start">
                  <Button
                    className={classes.iconBma}
                    onClick={this.handleRadarClick}
                  >
                    <HiiRadarIcon size="small" className={classes.sidebarIcon} />
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openRadarComp}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CompRadarSelect
                    handletimeLineStatus={this.handletimeLineStatus}
                    timelineStatus={timelineStatus}
                    handleMenuPopupClose={this.handleMenuPopupClose}
                  />
                </Popover>
              </Box>

              <Box>
                <TooltipMui title="ดูข้อมูลอื่น" placement="left-start">
                  <Box>
                    <Button
                      className={classes.iconBma}
                      onClick={this.handleRiverClick}
                      aria-label="other data"
                    // onMouseOver={this.handleRiverClick}
                    // onClick={this.handleRadarBkk}
                    >
                      <HiiEyeOutlineIcon size="small" className={classes.sidebarIcon} />
                    </Button>
                  </Box>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openRiverComp}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CompExtraSelect
                    handleMapChecked={this.handleMapChecked}
                    riverMapChecked={riverMapChecked}
                    radarMapChecked={radarMapChecked}
                    traffyMapChecked={traffyMapChecked}
                    twitterMapChecked={twitterMapChecked}
                  />
                </Popover>
              </Box>

              <Box>
                <TooltipMui title="ข้อมูลสถานี" placement="left-start">
                  <Button
                    className={classes.iconBma}
                    onClick={this.handleWaterClick}
                  >
                    <HiiRadarOutlineIcon size="small" className={classes.sidebarIcon} />
                  </Button>
                </TooltipMui>
                <Popover
                  id={this.id}
                  open={openWaterComp}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CompWaterSelect
                    handleMapChecked={this.handleMapChecked}
                    waterLevelMapChecked={waterLevelMapChecked}
                    floodStationMapChecked={floodStationMapChecked}
                    waterFlowMapChecked={waterFlowMapChecked}
                    rainStationMapChecked={rainStationMapChecked}
                  />
                </Popover>
              </Box>
            </Box>
          </Control>

          <Box className={classes.bmalagend}>
            <Fade in={checked}>
              <Box>
                <Hidden xsDown>
                  <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={0}>
                    {waterLevelMapChecked
                      ? (
                        <Grid item xs>
                          <WlLegend />
                        </Grid>
                      )
                      : null}

                    {floodStationMapChecked
                      ? (
                        <Grid item xs>
                          <FloodRoadLegend />
                        </Grid>
                      )
                      : null}

                    {timelineStatus === '1' || rainStationMapChecked
                      ? (
                        <Grid item xs>
                          <RainLegend />
                        </Grid>
                      )
                      : null}
                  </Grid>

                </Hidden>

                {timelineStatus === '3' && !isEmpty(metaDataRadarBkk)
                  ? (
                    <RadarBkkLegend metaDataRadarBkk={metaDataRadarBkk} />
                  ) : null}
                {timelineStatus === '2' && !isEmpty(metaDataRadarBkk)
                  ? (
                    <RainfallLegend />
                  ) : null}
                {timelineStatus === '1' && compRainColorScale >= 3 && !isEmpty(metaDataRadarBkk)
                  ? (
                    <Rainfall30DayLegend />
                  ) : null}
                {timelineStatus === '1' && compRainColorScale < 3 && !isEmpty(metaDataRadarBkk)
                  ? (
                    <RainfallLegend />
                  ) : null}
              </Box>
            </Fade>
            <Hidden xsDown>
              <Box display="flex" justifyContent="flex-end" justifyItems="center">
                <TooltipMui title="เกณฑ์" placement="left-start">
                  <Switch onChange={this.handleChange} inputProps={{ 'aria-label': 'Collapse' }} />
                </TooltipMui>
                <Box pt={1} display="flex" justifyItems="center" onClick={this.handleChange} className={classes.bmaLegandLable}>เปิด/ปิด เกณฑ์สี</Box>
              </Box>
            </Hidden>
          </Box>
        </Map>
      </>
    );
  }
}

BmaWaterMap.propTypes = {
  classes: PropTypes.object.isRequired,
  dataRainfall24h: PropTypes.array.isRequired,
  dataWaterLevel: PropTypes.array.isRequired,
  dataWaterCanal: PropTypes.array.isRequired,
  dataWaterFloodRoad: PropTypes.array.isRequired,
  dataWaterFlow: PropTypes.array.isRequired,
  dataTwitterNews: PropTypes.array.isRequired,
  dataRadarBkk: PropTypes.array.isRequired,
  dataRainGeotiff: PropTypes.array.isRequired,
  metaDataRadarBkk: PropTypes.object.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  geojsonProvince: PropTypes.array.isRequired,
  geojsonProvinceBkk: PropTypes.array.isRequired,
  getGeoJsonAmphoe: PropTypes.array.isRequired,
  getGeoJsonRainAmphoe: PropTypes.array.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default compose(withWidth(), withStyles(styles))(BmaWaterMap);
