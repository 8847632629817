import React, { Component } from 'react';

// ui
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { FaSpinner } from '../../../utils/Fontawesome';

class TroughAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      animation: 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_fcst_00utc.mp4',

    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.fetchData();
  }

  fetchData() {
    // get data from service
    const timeNow = moment().hours();
    // 00utc เสร็จ 15.00
    // 12utc เสร็จ 03.00

    const [morning, evening] = [15, 3];

    const [vdoM, vdoE] = ['https://live1.hii.or.th/product/latest/wrfroms/trough/trough_fcst_00utc.mp4', 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_fcst_12utc.mp4'];

    let srcVdoTrough = vdoM;
    if (timeNow >= morning && evening < timeNow) {
      srcVdoTrough = vdoM;
    } else {
      srcVdoTrough = vdoE;
    }
    this.setState({
      animation: srcVdoTrough,
      isLoading: false,
    });
  }

  render() {
    const {
      isLoading,
      error,
      animation,
    } = this.state;

    return (
      <div>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={25} />
          </div>
        )}

        { animation && (
        <Grid container spacing={4} style={{ marginTop: 18, marginBottom: 24 }}>
          <Grid item xs>
            <video width="100%" controls autoPlay>
              <source src={`${animation}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
        )}
      </div>
    );
  }
}

export default TroughAnimation;
