/* eslint-disable no-irregular-whitespace */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';
import moment from 'moment';
import * as Parser from 'rss-parser';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Button } from '@material-ui/core';

// icon .
import { FaHome, FaSpinner } from '../../utils/Fontawesome';
import { HiiPresentIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const parser = new Parser();

const initPath = process.env.MIX_APP_PATH;
const currentDate = new Date(moment());

class ReportWeekly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      datas: [],
      dataFilter: [],
    };
  }

  async componentDidMount() {
    const datas = await parser.parseURL(`./proxy/feed.php?file=${process.env.MIX_TIWRM_URL}/web/index.php/archive-report.feed?type=rss`);
    const maxDate = this.getMaxDate(datas.items);
    this.setState({
      datas: datas.items,
      dataFilter: datas.items.filter(d => moment(d.isoDate).format('YYYY-MM') === moment(maxDate).format('YYYY-MM')),
      startDate: maxDate,
    });
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.isoDate));
      const maxdate = new Date(Math.max.apply(null, dates));
      return moment(maxdate).format('YYYY-MM-DD');
    }

    return new Date();
  }

  handleChange = date => {
    const { datas } = this.state;

    this.setState({
      startDate: date,
      dataFilter: datas.filter(d => moment(d.isoDate).format('YYYY-MM') === moment(date).format('YYYY-MM')),
    });
  };

  render() {
    const { classes, t, i18n } = this.props;
    const {
      isLoading, error, dataFilter, startDate,
    } = this.state;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}report`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('รายงานสถานการณ์')}
                </Typography>
              </Button>
              <Button href={`${initPath}report_weekly`}>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('รายสัปดาห์')}
                </Typography>
              </Button>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiPresentIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4" gutterBottom>
                      {t('รายสัปดาห์')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box display="flex" py={2} width="100%" alignItems="center" justifyContent="center">
              <Box display="inline" fontFamily="Kanit">
                <Typography variant="h6" style={{ marginRight: 10 }}>{t('รายสัปดาห์')}</Typography>
              </Box>
              <Box display="inline">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                  <KeyboardDatePicker
                    openTo="month"
                    views={['year', 'month']}
                    value={startDate}
                    onChange={this.handleChange}
                    style={{ fontSize: 14 }}
                    maxDate={currentDate}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          { dataFilter.map(row => (
            <Fragment key={uniqueId()}>
              <Grid item xs={6} sm={4} md={2} lg={2} align="center">
                <Box p={2} fontSize={{ xs: 11, sm: 14, md: 15 }} fontFamily="Kanit">
                  {t(row.title.replace('รายงานข้อมูลน้ำรายสัปดาห์', ''))}
                </Box>
                <Box>
                  <Link to={`${initPath}report/report/view?url=${row.link}`} color="inherit" underline="none">
                    <img src={`${initPath}images/theme/banner/report/report-weekly-history.png`} style={{ width: '100%' }} className={classes.outLined} alt="Hii" />
                    <img src={`${initPath}images/theme/banner/report/book-shadow.png`} style={{ width: '100%', marginTop: -6 }} alt="Hii" />
                  </Link>
                </Box>

                {/* <Typography variant="body2" gutterBottom>
                  {row.contentSnippet}
                </Typography> */}

                {/* 2020-05-20 เนื่องจากยังไม่มีระบบ backoffice สนับสนุน ทำให้ไฟล์ download
                ไม่มีตัวไฟล์อยู่ในโพสของระบบเก่า จึงได้ปิดไว้ก่อน เมื่อมีระบบพร้อมแล้ว
                จึงจะเปิดมาแสดงผลตาม design */}
                {/* <Grid item xs={12} md={1} lg={1} className={classes.boxDownload}>
                  <Box display="flex" p={0} alignItems="center" justifyContent="center">
                    <Box p={0}>
                      <IconButton className={classes.button} aria-label="Delete">
                        <FaFileDownload />
                      </IconButton>
                      <Typography gutterBottom style={{ fontSize: 12, color: grey[700] }}>
                        ดาวน์โหลด
                      </Typography>
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </div>
    );
  }
}

ReportWeekly.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(ReportWeekly));
