
import React, { Component } from 'react';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import {
  styles,
} from '../../../styles/Style';

const thaiwaterPath = process.env.MIX_THAIWATER_URL;
class ChartChaopraya extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedIndex: 0,
    };
  }

  render() {
    return (
      <>
        {/* <Box className={classes.layout}> */}
        <Box>
          <iframe
            src={`${thaiwaterPath}/DATA/REPORT/php/chart/chaopraya/2013/chaopraya.php`}
            title="ติดตามสถานการณ์น้ำในลุ่มน้ำเจ้าพระยา"
            allowFullScreen
            width="100%"
            height="3900"
            style={{ border: 'none' }}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(ChartChaopraya);
