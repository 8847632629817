import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Pm25Color, Pm10Color } from './PmStatusColor';
import {
  styles, lineBox,
} from '../../../styles/Style';

function PmLegend(props) {
  const { classes, t, selectedLayer } = props;
  let pmArray = Pm25Color;
  if (selectedLayer === 'layerPm10') {
    pmArray = Pm10Color;
  }
  return (
    <>
      <Box display="flex" width="100%" justifyContent="center" alignContent="center">
        {
          pmArray.map((data, i) => {
            const key = i;
            return (
              <Box key={key} m={0} pb={0} borderTop={0} border={1} borderColor={lineBox} display="inline" width="20%">
                <Box display="flex" borderTop={1} borderColor={lineBox}>
                  <Box display="inline" style={{ backgroundColor: data.statusColor }} width="100%" py={0}>
                    <div className={classes.regend}>
                      {data.rangeTitle}
                    </div>
                  </Box>
                </Box>
                <Box display="block" pb={-3} borderTop={1} borderColor={lineBox} aling="center">
                  <div className={classes.regend}>
                    {t(data.label)}
                  </div>
                </Box>
              </Box>
            );
          })
        }
      </Box>
    </>
  );
}

PmLegend.propTypes = {
  selectedLayer: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(PmLegend);
