import iconRect from '../widgets/map/iconRect';

const [S0, S1, S2, S3, S4, S5] = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อยวิกฤต',
    rangeTitle: '<=10',
    statusColor: '#db802b',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อย',
    rangeTitle: '>10-30',
    statusColor: '#ffc000',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปกติ',
    rangeTitle: '>30-70',
    statusColor: '#00b050',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'มาก',
    rangeTitle: '>70-100',
    statusColor: '#003cfa',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'ล้นตลิ่ง',
    rangeTitle: '>100',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const SealevelStatusColor = sl => {
  let color;
  let icon;

  if (sl === null) {
    color = S0;
    icon = iconRect.grey;
  } else if (sl > 100) {
    color = S5;
    icon = iconRect.red;
  } else if (sl > 70) {
    color = S4;
    icon = iconRect.blue;
  } else if (sl > 30) {
    color = S3;
    icon = iconRect.green;
  } else if (sl > 10) {
    color = S2;
    icon = iconRect.yellow;
  } else if (sl <= 10) {
    color = S1;
    icon = iconRect.brown;
  } else {
    color = S0;
    icon = iconRect.grey;
  }

  return { color, icon };
};

export default SealevelStatusColor;

// import iconRect from '../widgets/map/iconRect';

// const slColor = [
//   {
//     label: 'ไม่มีข้อมูล',
//     rangeTitle: 'ข้อมูลไม่เพียงพอ',
//     statusColor: '#BDBDBD',
//     constrastColor: '#000',
//     fontSize: '12px',
//   },
//   {
//     label: 'น้อยวิกฤต',
//     rangeTitle: '<=10',
//     statusColor: '#db802b',
//     constrastColor: '#000',
//     fontSize: '12px',
//   },
//   {
//     label: 'น้อย',
//     rangeTitle: '>10-30',
//     statusColor: '#ffc000',
//     constrastColor: '#000',
//     fontSize: '12px',
//   },
//   {
//     label: 'ปกติ',
//     rangeTitle: '>30-70',
//     statusColor: '#00b050',
//     constrastColor: '#fff',
//     fontSize: '12px',
//   },
//   {
//     label: 'มาก',
//     rangeTitle: '>70-100',
//     statusColor: '#003cfa',
//     constrastColor: '#fff',
//     fontSize: '12px',
//   },
//   {
//     label: 'ล้นตลิ่ง',
//     rangeTitle: '>100',
//     statusColor: '#ff0000',
//     constrastColor: '#fff',
//     fontSize: '12px',
//   },
// ];

// const SealevelStatusColor = sl => {
//   let color;
//   let icon;

//   if (sl === null) {
//     color = slColor[0];
//     icon = iconRect.grey;
//   } else if (sl > 100) {
//     color = slColor[5];
//     icon = iconRect.red;
//   } else if (sl > 70) {
//     color = slColor[4];
//     icon = iconRect.blue;
//   } else if (sl > 30) {
//     color = slColor[3];
//     icon = iconRect.green;
//   } else if (sl > 10) {
//     color = slColor[2];
//     icon = iconRect.yellow;
//   } else if (sl <= 10) {
//     color = slColor[1];
//     icon = iconRect.brown;
//   } else {
//     color = slColor[0];
//     icon = iconRect.grey;
//   }

//   icon = iconRect.grey;

//   return { color, icon };
// };

// export default SealevelStatusColor;
