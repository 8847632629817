import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    position: 'fixed',
    left: '1%',
    bottom: '6%',
  },
  legend: {
    height: '20px',
    width: '40px',
    marginRight: 10,
    opacity: 0.5,
  },
});

export default function Legend() {
  const classes = useStyles();

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.9',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">&lt; 1 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#CBE3FF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 1 - 5 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#ADD3FF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 5 - 10 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#97E5A9',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 10 - 20 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#68CA01',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 20 - 30 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FAD500',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 30 - 40 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FD8C02',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 40 - 55 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#CA6604',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 55 - 70 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FF2E07',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 70 - 85 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#CD2522',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 85 - 100 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#851B1B',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 100 - 120 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#9C32D4',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">&gt; 120</Typography></Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
