import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';
// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { FaSpinner } from '../../../../utils/Fontawesome';
// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  newLine: {
    display: 'block',
  },
});
/**
    * ComponentName
    */
class FloodPlainChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      chartOptions: this.getChartOptions(),
      areaNo: props.area,
      dataFloodPlain: props.dataFloodPlain,
      dataChart: undefined,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.renderChart();
  }

  getChartOptions() {
    this.options = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: 'ปริมาณความจุทุ่ง (ล้าน ลบ.ม)',
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        // gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'center',
            },
          },
          {
            value: null,
            color: 'black',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: '',
          data: [],
          tooltip: {
            valueSuffix: ' ล้าน ลบ.ม',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
          },
        },
      },
    };

    return this.options;
  }

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const { areaNo, dataFloodPlain } = this.state;
    fetch(`https://partners.thaiwater.net:3002/webApi/getTCPFloodDataByArea?area=${areaNo}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        let ts = null;
        let val = null;
        const dataSeries = [];
        const floodDataByArea = result.data.filter(d => {
          if (d.type === 'V') {
            const date = new Date(d.measure_datetime);
            date.setHours(date.getHours() + 7);
            ts = date.valueOf();
            // เวลาที่เพิ่มเข้ามา ไม่สามารถใช้งานได้
            // ts = moment(d.measure_datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
            val = parseFloat(d.value);
            dataSeries.push([ts, val]);
            return d;
          }
        });
        const max = maxBy(floodDataByArea, 'value');
        const min = minBy(floodDataByArea, 'value');

        const max2 = (dataFloodPlain.CAPA_S > max.value) ? dataFloodPlain.CAPA_S : max.value;
        const min2 = (dataFloodPlain.CAPA_S < min.value) ? dataFloodPlain.CAPA_S : min.value;
        const chartOptions = this.getChartOptions();
        chartOptions.series[0].data = dataSeries;
        chartOptions.series[0].name = 'ปริมาณความจุทุ่ง';
        chartOptions.yAxis.max = max2;
        chartOptions.yAxis.min = min2;
        chartOptions.yAxis.plotLines[0].value = dataFloodPlain.CAPA_S;
        chartOptions.yAxis.plotLines[0].label.text = `${'ปริมาณความจุทุ่งสูงสุด'} <strong>${dataFloodPlain.CAPA}</strong>`;

        this.setState({
          dataChart: floodDataByArea,
          chartOptions,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      dataChart,
      dataFloodPlain,
      isLoading,
      error,
      chartOptions,
    } = this.state;
    const { classes } = this.props;

    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }
    let info = '';
    if (dataChart) {
      info = (
        <div style={{ color: '#A2A2A2' }}>
          *สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
        </div>
      );
    } else {
      info = '';
    }

    return (
      <>
        <Grid container spacing={1} style={{ marginTop: '-20px' }}>
          <Grid item xs>
            <Typography variant="caption" className={classes.newLine}>
              ชื่อทุ่ง : &nbsp;
              {dataFloodPlain.name}
            </Typography>
            <Typography variant="caption" className={classes.newLine}>
              ขนาดพื้นที่ : &nbsp;
              {dataFloodPlain.AREA_CAPA}
            </Typography>
            <Typography variant="caption" className={classes.newLine}>
              ขนาดความจุ : &nbsp;
              {dataFloodPlain.CAPA}
            </Typography>
            <Typography variant="caption" className={classes.newLine}>
              วันที่ล่าสุด : &nbsp;
              {(dataFloodPlain.measure_datetime) ? moment(dataFloodPlain.measure_datetime).format('YYYY-MM-DD') : '-'}
            </Typography>
            {/* <Typography variant="caption" className={classes.newLine}>
              ร้อยละความจุ : &nbsp;
              <Box component="span" sx={{ color: 'red' }}>
                {`${dataFloodPlain.capacity_max}%`}
              </Box>
            </Typography> */}
          </Grid>
          <Grid item xs={12}>
            {dataChart ? (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            ) : (
              <Grid item xs={12} align="center">ไม่มีข้อมูลในช่วงเวลาที่เลือก</Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

FloodPlainChart.propTypes = {
  classes: PropTypes.object.isRequired,
  area: PropTypes.string.isRequired,
  dataFloodPlain: PropTypes.object.isRequired,
};

export default withStyles(styles)(FloodPlainChart);
