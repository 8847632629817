import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {
  flow, groupBy, map,
} from 'lodash/fp';
import {
  uniqueId,
} from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import { Button } from '@material-ui/core';

// icon .
import grey from '@material-ui/core/colors/grey';
import { FaSpinner, FaPaperPlane } from '../../utils/Fontawesome';
import { HiiResearchIcon } from '../../utils/Icon';

// color .

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
class Research extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      datas: [],
      dataFilter: [],
      typeList: [],
      selected: 0,
    };
  }

  componentDidMount() {
    this._mounted = true;

    this.getData();
  }

  getData() {
    fetch(`${initPath}json/contents/research.json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const valueList = result[0].type;
        const typeList = flow(
          groupBy('type'),
          map(
            group => ({ ...group[0], qty: group.length }),
          ),
        )(result);

        // set state
        this.setState({
          isLoading: false,
          datas: result,
          dataFilter: result.filter(d => d.type === valueList),
          typeList,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = (type, i) => {
    const { datas } = this.state;

    this.setState({
      dataFilter: datas.filter(d => d.type === type),
      selected: i,
    });
  };

  render() {
    const { classes, t } = this.props;
    const {
      isLoading, error, dataFilter, typeList, selected,
    } = this.state;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <Fragment key="key">
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box mt={3} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Box display="flex" width="100%">
                    <Box display="inline" pt={1}>
                      <HiiResearchIcon fontSize={24} />
                    </Box>
                    <Box display="inline" pt={1} pl={1}>
                      <Typography variant="h4" gutterBottom>
                        {t('งานวิจัย-ความรู้')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={3} />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <List component="nav">
                <Divider />
                { typeList.map((row, i) => {
                  const color = (i === selected) ? 'primary' : 'default';
                  return (
                    <Fragment key={uniqueId()}>
                      <ListItem
                        button
                        selected={row === i}
                        onClick={() => { this.handleChange(row.type, i); }}
                      >
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <FaPaperPlane color={grey[900]} />
                        </ListItemIcon>
                        <ListItemText><Typography variant="h6">{t(row.type_text)}</Typography></ListItemText>
                        <ListItemSecondaryAction>
                          <Fab
                            color={color}
                            onClick={() => { this.handleChange(row.type, i); }}
                            style={{ width: 35, height: 35 }}
                          >
                            {row.qty}
                          </Fab>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </Fragment>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              { dataFilter.map(row => {
                const link = (row.link.match('contents')) ? `${process.env.MIX_APP_URL}${row.link}` : `${process.env.MIX_THAIWATER_URL}${row.link}`;
                return (
                  <Fragment key={uniqueId()}>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item xs>
                        <Button href={`${initPath}report/research/view?url=${link}`}>
                          <Typography variant="h6">{t(row.title.trim())}</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    {/*
                    2020-05-19 เนื่องจากการกรอกข้อมูลวิจัย ยังไม่มีระบบ backoffice สนับสนุน
                    ทำให้รายละเอียด และ keyword
                    มีไม่ครบทุกหัวข้อ จึงได้ปิดไว้ก่อน
                    เมื่อมีระบบพร้อมแล้วจึงจะเปิดมาแสดงผลตาม design
                    */}
                    {/* <ListItem alignItems="flex-start">
                    <Box p={1}><FaUsers fontSize={50} color={grey[500]} /></Box>
                    <ListItemText
                      primary={(
                        <Typography className={classes.fontBase}>
                          {row.short_description}
                        </Typography>
                    )}
                      secondary={(
                        <Fragment key="key">
                          <Typography
                            component="span"
                            className={classNames(classes.inline, classes.fontBaseWeight)}
                          >
                            Keywords
                          </Typography>
                          <Typography
                            component="span"
                            className={classNames(classes.inline, classes.fontBase)}
                          >
                            –
                            {' '}
                            {row.keyword}
                          </Typography>
                        </Fragment>
                    )}
                    />
                  </ListItem> */}
                    <Box py={1}>
                      <Divider />
                    </Box>
                  </Fragment>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

Research.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(Research));
