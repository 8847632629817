/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import tJson from '../../utils/TJson';
import { FaSpinner } from '../../utils/Fontawesome';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

const initPath = process.env.MIX_APP_PATH;

/**
 * ComponentName
 */
class WlChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      provinceId: props.provinceId,
      startDateParam: props.startDate,
      teleStation: [],
      canalStation: [],
      startDate: moment().subtract(3, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD HH:mm'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      dataChart: undefined,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getProvince();
    this.getStation();
    this.renderChart();
  }

  // annotations: [
  //   {
  //     labels: [
  //       {
  //         point: { x: 5, y: 5 },
  //         useHTML: true,
  //         text: '&#x25B2; จุดที่มีระดับน้ำสูงสุด/ต่ำสุด',
  //       },
  //     ],
  //   },
  // ],

  getChartOptions() {
    const { t } = this.props;
    this.options = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: `${t('ระดับน้ำ (ม.รทก)')}`,
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: 'black',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
        plotBands: [
          {
            // > 100
            from: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำล้นตลิ่ง')} ( > 100% )</strong>`,
              style: {
                color: '#ff0000',
              },
            },
          },
          {
            // > 70-100
            from: null,
            to: null,
            color: 'rgba(0, 0, 0, 0)',
            label: {
              text: `<strong>${t('น้ำมาก')}  ( > 70-100% )</strong>`,
              style: {
                color: '#003cfa',
              },
            },
          },
          {
            // > 30-70
            from: null,
            to: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำปานกลาง')} ( > 30-70% )</strong>`,
              style: {
                color: '#00b050',
              },
            },
          },
          {
            // > 10-30
            from: null,
            to: null,
            color: 'rgba(0, 0, 0, 0)',
            label: {
              text: `<strong>${t('น้ำน้อย')} ( > 10-30% )</strong>`,
              style: {
                color: '#ffc000',
              },
            },
          },
          {
            // <= 10
            from: null,
            to: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำน้อยวิกฤต')} ( < 10% )</strong>`,
              style: {
                color: '#db802b',
              },
            },
          },
        ],
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: t('ระดับน้ำ'),
          data: [],
          tooltip: {
            valueSuffix: t('ม.รทก.'),
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getProvince() {
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataProvince = result.province.data.sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));

        this.setState({
          dataProvince,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getStation() {
    const { provinceId } = this.state;
    fetch(`${process.env.MIX_API_URL}frontend/shared/tele_canal_station?province_code=${provinceId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const teleStation = result.data.tele_waterlevel;
        const canalStation = result.data.canal_waterlevel;

        this.setState({
          teleStation,
          canalStation,
        });

        this.handleChart();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value }, () => {
      this.getStation();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    const { today } = this.state;

    if (moment(date).format('YYYY-MM-DD') === today) {
      this.setState({ endDate: moment(date).format('YYYY-MM-DD HH:mm') }, () => {
        this.handleChart();
      });
    } else {
      const dt = `${moment(date).format('YYYY-MM-DD')} 23:59`;
      this.setState({ endDate: moment(dt).format('YYYY-MM-DD HH:mm') }, () => {
        this.handleChart();
      });
    }
  };

  handleChart = () => {
    const { id, teleStation } = this.state;
    const stationByClick = teleStation.filter(
      d => parseInt(d.station_id, 10) === parseInt(id, 10),
    )[0];
    const stationId = stationByClick === undefined
      ? teleStation[0].station_id : stationByClick.station_id;
    if (stationId !== undefined) {
      this.setState({ id: stationId });
    }

    this.resetChart();
    this.renderChart();
  };


  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const {
      id,
      startDate,
      endDate,
      startDateParam,
    } = this.state;
    const { t } = this.props;

    let startDateValue = startDate;
    let endDateValue = endDate;
    if (startDateParam) {
      const startDateFormat = moment(startDateParam).subtract(3, 'days').locale('th').format('YYYY-MM-DD');
      const endDateFormat = moment(startDateParam).locale('th').format('YYYY-MM-DD 23:59');

      startDateValue = startDateFormat;
      endDateValue = endDateFormat;

      this.setState({ startDate: startDateFormat, endDate: endDateFormat });
    }
    fetch(
      `${process.env
        .MIX_API_URL}public/waterlevel_graph?station_type=tele_waterlevel&station_id=${id}&start_date=${startDateValue}&end_date=${endDateValue}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // fix case all value is null
        const dataClean = result.data.graph_data.filter(x => x.value !== null);

        if (dataClean.length > 0) {
          // transform datas
          const dataSeries = [];
          let ts = null;
          let val = null;

          dataClean.map(n => {
            val = parseFloat(n.value);
            ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
            dataSeries.push([ts, val]);
            return dataSeries;
          });

          const max = maxBy(dataClean, 'value');
          const min = minBy(dataClean, 'value');

          // set chart options
          const chartOptions = this.getChartOptions();
          const depth = parseFloat(result.data.min_bank - result.data.ground_level);

          chartOptions.yAxis.max = maxBy([result.data.min_bank, max.value + 0.5]);
          chartOptions.yAxis.min = minBy([result.data.ground_level, min.value]);
          chartOptions.series[0].data = dataSeries;

          // plotLines
          if (result.data.min_bank) {
            chartOptions.yAxis.plotLines[0].value = result.data.min_bank;
            chartOptions.yAxis.plotLines[0].label.text = `${t('ระดับตลิ่ง')} <strong>${result.data
              .min_bank}</strong> ${t('ม.รทก.')}`;
          }

          if (result.data.ground_level) {
            chartOptions.yAxis.plotLines[1].value = result.data.ground_level;
            chartOptions.yAxis.plotLines[1].label.text = `${t('ระดับท้องน้ำ')} <strong>${parseFloat(
              Math.round(result.data.ground_level * 100) / 100,
            ).toFixed(2)}</strong> ${t('ม.รทก.')}`;
          }

          // plotBands
          if (result.data.min_bank && result.data.ground_level) {
            chartOptions.yAxis.plotBands[0].from = result.data.min_bank;
            chartOptions.yAxis.plotBands[1].from = result.data.ground_level + depth * 0.7;
            chartOptions.yAxis.plotBands[1].to = result.data.min_bank;
            chartOptions.yAxis.plotBands[2].from = result.data.ground_level + depth * 0.3;
            chartOptions.yAxis.plotBands[2].to = result.data.ground_level + depth * 0.7;
            chartOptions.yAxis.plotBands[3].from = result.data.ground_level + depth * 0.1;
            chartOptions.yAxis.plotBands[3].to = result.data.ground_level + depth * 0.3;
            chartOptions.yAxis.plotBands[4].from = result.data.ground_level;
            chartOptions.yAxis.plotBands[4].to = result.data.ground_level + depth * 0.1;
          }
          this.setState({
            dataChart: dataClean,
            chartOptions,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false, dataChart: undefined });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderSponsor(id) {
    const { teleStation } = this.state;
    let sponsorMode = false;
    let sponsorText = '';
    teleStation.forEach(element => {
      if (element.id === id && element.sponsor_by) {
        sponsorMode = true;
        sponsorText = element.sponsor_by;
      }
    });
    return sponsorMode ? `สนับสนุนโดย ${sponsorText}` : null;
  }

  render() {
    const {
      dataProvince, id, provinceId, teleStation, canalStation, startDate, endDate, today, dataChart, sponsored_by, sponsoredMode,
    } = this.state;
    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    let name = '';
    if (teleStation.length > 0) {
      let provinceName = '';
      let stationName = '';
      const stationByClick = teleStation.filter(
        d => parseInt(d.station_id, 10) === parseInt(id, 10),
      )[0];
      const station = stationByClick === undefined ? teleStation[0] : stationByClick;

      if (station) {
        provinceName = station.province_name;
        stationName = (station.station_name.th)
          ? tJson(station.station_name, 'สถานี') : station.station_old_code;
      } else {
        const canal = canalStation.filter(d => parseInt(d.id, 10) === parseInt(id, 10))[0];

        provinceName = canal.province_name;
        stationName = tJson(canal.station_name, 'สถานี');
      }
      // check if station contain province name, omit show duplicate province name
      name = `${stationName} ${tJson(provinceName, 'จ.')}`;
    }

    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }
    let info = '';
    if (dataChart) {
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {this.renderSponsor(id)}
          <br />
          {`*${t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}`}
        </div>
      );
    } else {
      info = '';
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select value={provinceId} onChange={this.handleProvinceChange}>
                {dataProvince.map(row => (
                  <MenuItem key={row.id} value={row.province_code}>
                    {t(row.province_name.th)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <NativeSelect value={id} onChange={this.handleStationChange}>
                <optgroup label={t('โทรมาตร')}>
                  {teleStation.map(row => (
                    <option key={row.id} value={row.station_id}>
                      {row.station_old_code}
                      {' '}
                      {row.station_name.th}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={t('คลอง')}>
                  {canalStation.map(row => (
                    <option key={row.id} value={row.id}>
                      {row.station_name.th}
                    </option>
                  ))}
                </optgroup>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12} align="center" style={{ fontFamily: 'Lucida Grande,Lucida Sans Unicode,Arial,Helvetica, sans-serif', fontSize: '12px' }}>
            {name}
          </Grid>
          <Grid item xs={12}>
            {dataChart ? (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            ) : (
              <Grid item xs={12} align="center">{t('ไม่มีข้อมูลในช่วงเวลาที่เลือก')}</Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

WlChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
  startDate: PropTypes.string,
  sponsored_by: PropTypes.any
};

WlChart.defaultProps = {
  startDate: null,
};

export default compose(withStyles(styles), withTranslation('translations', { withRef: true }))(WlChart);
