import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { HiiiSeaIcon } from '../../utils/Icon';

import SidebarWeather from '../navigation/SidebarWeather';
import Swan from '../forecast/swan/Swan';
import Sst from './sst/Sst';
import SstHii from './sst/SstHii';
import Wave from './wave/Wave';
import SshHii from './ssh/SshHii';

import {
  styles,
} from '../../styles/Style';

class WeatherSea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      sea: 0,
    };
  }

  handleChange = (event, value) => {
    this.state({
      event,
      value,
    });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <>

        {/* Sidebar */}
        <SidebarWeather />

        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Typography variant="h6" gutterBottom>
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Box display="flex" py={1} width="100%">
                <Box display="inline" p={1} alignItems="center">
                  <HiiiSeaIcon fontSize={24} />
                </Box>
                <Box display="inline" pt={1} alignItems="center">
                  <Typography variant="h4">
                    {t('ทะเล')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Wave />
          <Swan />
          <Sst />
          <Grid container spacing={4}>
            <SstHii />
            <SshHii />
          </Grid>
        </div>
      </>
    );
  }
}

WeatherSea.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withTranslation(), withStyles(styles, { withTheme: true }))(WeatherSea);
