import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;

const iconCircleRed = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/red.png`,
  iconSize: [8, 8],
});

const iconCircleBlue = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/0033ff.png`,
  iconSize: [10, 10],
});

const iconCircleCeladon = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/celadon.png`,
  iconSize: [8, 8],
});

const iconCircleGold = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/gold.png`,
  iconSize: [8, 8],
});

const iconCircleGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/green.png`,
  iconSize: [8, 8],
});

const iconCircleOrange = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/orange.png`,
  iconSize: [8, 8],
});

const iconCircleYellow = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/yellow.png`,
  iconSize: [8, 8],
});

const iconCircleTawny = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/tawny.png`,
  iconSize: [8, 8],
});

const iconCircleGrey = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/gray.png`,
  iconSize: [8, 8],
});

const iconfffff5 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/fffff5.png`,
  iconSize: [10, 10],
});

const iconffffcc = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ffffcc.png`,
  iconSize: [10, 10],
});

const iconffff99 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ffff99.png`,
  iconSize: [10, 10],
});

const iconffff66 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ffff66.png`,
  iconSize: [10, 10],
});

const iconffff33 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ffff33.png`,
  iconSize: [10, 10],
});

const iconffff0d = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ffff0d.png`,
  iconSize: [10, 10],
});

const iconffff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ffff00.png`,
  iconSize: [10, 10],
});

const iconccff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ccff00.png`,
  iconSize: [10, 10],
});

const icon99ff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/99ff00.png`,
  iconSize: [10, 10],
});

const icon66ff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/66ff00.png`,
  iconSize: [10, 10],
});

const icon33ff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/33ff00.png`,
  iconSize: [10, 10],
});

const icon00ff33 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/00ff33.png`,
  iconSize: [10, 10],
});

const icon00ff66 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/00ff66.png`,
  iconSize: [10, 10],
});

const icon00ff99 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/00ff99.png`,
  iconSize: [10, 10],
});

const icon00ffcc = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/00ffcc.png`,
  iconSize: [10, 10],
});

const icon00ffff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/00ffff.png`,
  iconSize: [10, 10],
});

const icon00ccff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/00ccff.png`,
  iconSize: [10, 10],
});

const icon0099ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/0099ff.png`,
  iconSize: [10, 10],
});

const icon0066ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/0066ff.png`,
  iconSize: [10, 10],
});

const icon0033ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/0033ff.png`,
  iconSize: [10, 10],
});

const icon0000ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/0000ff.png`,
  iconSize: [10, 10],
});

const icon4300f8 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/4300f8.png`,
  iconSize: [10, 10],
});

const icon6600ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/6600ff.png`,
  iconSize: [10, 10],
});

const icon9900ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/9900ff.png`,
  iconSize: [10, 10],
});

const iconcc00ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/cc00ff.png`,
  iconSize: [10, 10],
});

const iconff00ff = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ff00ff.png`,
  iconSize: [10, 10],
});

const iconff00cc = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ff00cc.png`,
  iconSize: [10, 10],
});

const iconff0099 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ff0099.png`,
  iconSize: [10, 10],
});

const iconff0066 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ff0066.png`,
  iconSize: [10, 10],
});

const iconec0420 = new L.Icon({
  iconUrl: `${initPath}images/marker/circle/ec0420.png`,
  iconSize: [10, 10],
});

const iconCircle = {
  red: iconCircleRed,
  blue: iconCircleBlue,
  celadon: iconCircleCeladon,
  gold: iconCircleGold,
  green: iconCircleGreen,
  yellow: iconCircleYellow,
  orange: iconCircleOrange,
  tawny: iconCircleTawny,
  grey: iconCircleGrey,
  ifffff5: iconfffff5,
  iffffcc: iconffffcc,
  iffff99: iconffff99,
  iffff66: iconffff66,
  iffff33: iconffff33,
  iffff0d: iconffff0d,
  iffff00: iconffff00,
  iccff00: iconccff00,
  i99ff00: icon99ff00,
  i66ff00: icon66ff00,
  i33ff00: icon33ff00,
  i00ff33: icon00ff33,
  i00ff66: icon00ff66,
  i00ff99: icon00ff99,
  i00ffcc: icon00ffcc,
  i00ffff: icon00ffff,
  i00ccff: icon00ccff,
  i0099ff: icon0099ff,
  i0066ff: icon0066ff,
  i0033ff: icon0033ff,
  i0000ff: icon0000ff,
  i4300f8: icon4300f8,
  i6600ff: icon6600ff,
  i9900ff: icon9900ff,
  icc00ff: iconcc00ff,
  iff00ff: iconff00ff,
  iff00cc: iconff00cc,
  iff0099: iconff0099,
  iff0066: iconff0066,
  iec0420: iconec0420,
};

export default iconCircle;
