import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AnimationFromUrl extends Component {
  render() {
    const { media } = this.props;

    return (
      <video controls autoPlay style={{ height: 'fit-content', width: '100%' }}>
        <source src={`${media}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
}

Animation.propTypes = {
  media: PropTypes.string.isRequired,
};

export default AnimationFromUrl;
