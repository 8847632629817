import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';

// icon
import { FaHome } from '../../../utils/Fontawesome';

// style, css
import { styles } from '../../../styles/Style';

// componens
import VaporHistoryList from './VaporHistoryList';

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 8 }}>
    {children}
  </Typography>
);

const currentDate = new Date(moment());

const initPath = process.env.MIX_APP_PATH;

class VaporHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(moment()),
      // for get data condition
      mvalue: { year: moment().format('YYYY'), month: moment().format('M'), day: moment().format('DD') },
      // tab
      value: 0,
      displayState: 'history',
      // historyStatButton: 'primary',
      // lastestStatButton: '',
    };
  }

  componentDidMount() {
    // get data from ws
    this.fetchData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDateChange = date => {
    this.setState({
      startDate: moment(date).format('YYYY-MM-DD'),
      mvalue: { year: moment(date).format('YYYY'), month: moment(date).format('M'), day: moment(date).format('DD') },
    });
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate;
    if (change === '1') {
      selectedDate = moment(this.state.startDate).add(1, 'days').format('YYYY-MM-DD');
    } else if (change === '-1') {
      selectedDate = moment(this.state.startDate).add(-1, 'days').format('YYYY-MM-DD');
    }
    this.setState({
      startDate: selectedDate,
      mvalue: { year: moment(selectedDate).format('YYYY'), month: moment(selectedDate).format('M'), day: moment(selectedDate).format('DD') },
    });
  }

  handleLastest = () => {
    const {
      value,
    } = this.state;
    // let startDate;
    const mvalue = { year: moment().format('YYYY'), month: moment().format('M'), day: moment().format('DD') };
    this.setState({
      displayState: 'latest',
      // startDate,
      mvalue,
      value,
    });
  }

  handleHistory = () => {
    const {
      value,
      startDate,
    } = this.state;

    const mvalue = { year: moment(startDate).format('YYYY'), month: moment(startDate).format('M'), day: moment(startDate).format('DD') };

    this.setState({
      displayState: 'history',
      mvalue,
      value,
    });
  }

  fetchData() {
    // get data from service
    fetch(`https://api.hii.or.th/tiservice/v1/ws/MBtOTp6IUXbjaCxhQoFQNrFgZUCzNgbo/pwv/history/${moment().format('YYYY-MM-DD')}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          startDate: moment(),
          mvalue: { year: moment().format('YYYY'), month: moment().format('M'), day: moment().format('DD') },
        });
      });
  }

  renderHistory(classes) {
    const {
      startDate, mvalue, value, displayState,
    } = this.state;
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 30, marginBottom: 16 }}>
          <Grid item><Button onClick={() => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={3} md={3} lg={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                openTo="month"
                views={['year', 'month', 'date']}
                value={startDate}
                format={moment(startDate).format('DD MMMM yyyy')}
                onChange={this.handleDateChange}
                style={{ fontSize: 14 }}
                maxDate={currentDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item><Button onClick={() => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <VaporHistoryList period={mvalue} agencyId="55" mediaTypeId="149" displayState={displayState} />
          </Grid>
        </Grid>
      </>
    );
  }

  renderLastest() {
    const {
      mvalue,
      value,
      displayState,
    } = this.state;
    const { classes } = this.props;

    return (
      <>

        <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <VaporHistoryList period={mvalue} agencyId="55" mediaTypeId="149" displayState={displayState} />
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    const { classes, t } = this.props;
    const {
      startDate,
      displayState,
      // historyStatButton,
      // lastestStatButton,
    } = this.state;

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/rainfall`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('ฝน')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('แผนที่ค่าปริมาณไอน้ำในชั้นบรรยากาศ')}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 24 }}>
            <Grid item xs={12} sm={6} md={8}>
              <Box m={1} className={classes.titleName}>
                {t('แผนที่ค่าปริมาณไอน้ำในชั้นบรรยากาศจากข้อมูลสัญญาณดาวเทียม (GNSS-Precipitable Water Vapor)')}
              </Box>
            </Grid>
            <Grid item xs>
              <Button
                size="small"
                color={displayState === 'latest' ? 'primary' : 'default'}
                variant={displayState === 'latest' ? 'contained' : 'text'}
                className={classes.outlinedLeftXsPrimary}
                onClick={this.handleLastest}
              >
                <ImageOutlinedIcon fontSize="small" />
                <Typography variant="h6" style={{ marginLeft: 8 }}>
                  {t('ดูภาพล่าสุด')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <Button
                  size="small"
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                  className={classes.outlinedRightXsPrimary}
                  onClick={this.handleHistory}
                >
                  <PhotoLibraryOutlinedIcon fontSize="small" />
                  <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูภาพย้อนหลัง')}</Typography>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          {startDate && (
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                {(displayState === 'history') && (
                  this.renderHistory(classes)
                )}
                {(displayState === 'latest') && (
                  this.renderLastest()
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </>
    );
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

VaporHistory.defaultProps = {
  match: {
    params: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  },
};

VaporHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      month: PropTypes.string,
      year: PropTypes.string,
    }),
  }),
  // theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(VaporHistory));
