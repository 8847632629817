import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import L from 'leaflet';
import {
  Marker, Popup, Tooltip, LayerGroup,
} from 'react-leaflet';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';

import { useTranslation } from 'react-i18next';

// material ui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { Hidden } from '@material-ui/core';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

// icons and style
import {
  styles,
} from '../../../styles/Style';

const stationIcon = L.icon({
  iconUrl: '../images/marker/circle/whiteMarker.png',

  iconSize: [8, 8],
});

const useStyles = makeStyles(styles);

export default function RainfallMarker() {
  const { t } = useTranslation();
  const refRainfallStation = useRef(null);
  const [datas, setData] = useState([]);
  const classes = useStyles();

  // function transformData(d) {
  //   const stationArray = [];
  //   const objStation = {};

  //   // find unique date
  //   const dataDate = [];
  //   d.forEach(element => {
  //     dataDate.push(element.date);
  //   });
  //   dataDate.sort();

  //   // find unique station id
  //   const stationId = [];
  //   d[0].value.forEach(element => {
  //     stationId.push(element.stn_id);
  //   });
  //   const uniqueStationId = [...new Set(stationId)];
  //   // console.log(uniqueStationId);

  //   uniqueStationId.forEach(element => {
  //     objStation.stn_id = element;
  //     objStation.lat = 13;
  //     objStation.lng = 100;
  //     objStation.name = 'ชื่อสถานี';
  //     objStation.province_name = 'จังหวัด';
  //     objStation.amphoe_name = 'อำเภอ';
  //     objStation.tambon_name = 'ตำบล';

  //     // create data array for each station and push to data object (date, max, min, mean)
  //     const stationDataArray = [];

  //     dataDate.forEach(dt => {
  //       const objData = {};
  //       const filterData = d.find(e => e.date === dt);
  //       // console.log(filterData.value)
  //       const maxVal = filterData.value.filter(
  //         obj => obj.stn_id === parseInt(element, 10) && obj.type === 'up'
  //       );
  //       const minVal = filterData.value.filter(
  //         obj => obj.stn_id === parseInt(element, 10) && obj.type === 'low'
  //       );
  //       const meanVal = filterData.value.filter(
  //         obj => obj.stn_id === parseInt(element, 10) && obj.type === 'mean'
  //       );

  //       objData.date = dt;
  //       objData.max = maxVal[0].rain; // date -> value -> stn_id, type -> rain
  //       objData.min = minVal[0].rain;
  //       objData.mean = meanVal[0].rain;

  //       stationDataArray.push(objData);
  //     });

  //     // push station data to station
  //     objStation.data = stationDataArray;

  //     // push array to object
  //     stationArray.push(objStation);
  //   });

  //   // console.log(stationArray);
  //   return stationArray;
  // }

  function fetchData() {
    // popup data
    const url = 'https://live1.hii.or.th/product/latest/rain/one_map/data/rain_fsct6m.json';
    fetch(url).then(response => response.json())
      .then(result => {
        // transform data
        // code here
        // const d = transformData(result);
        // console.log(d)
        // const d = [
        //   {
        //     stn_id: 552301, // ok
        //     lat: 13,
        //     lng: 100,
        //     name: 'ชื่อสถานี',
        //     province_name: 'จังหวัด',
        //     amphoe_name: 'อำเภอ',
        //     tambon_name: 'ตำบล',
        //     data: [
        //       {
        //         date: '202008',
        //         max: 108,
        //         min: 8,
        //         mean: 58,
        //       },
        //       {
        //         date: '202009',
        //         max: 109,
        //         min: 19,
        //         mean: 59,
        //       },
        //       {
        //         date: '202010',
        //         max: 110,
        //         min: 10,
        //         mean: 50,
        //       },
        //     ],
        //   },
        // ];

        setData(result);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function eachRainfallStation(data, i) {
    if (data.latitude && data.longitude) {
      // marker position
      const position = [parseFloat(data.latitude), parseFloat(data.longitude)];

      return (
        <Marker
          id={data.station_id}
          key={i}
          position={position}
          icon={stationIcon}
        >
          <Popup autoPan>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={4} align="center">
                      <Typography variant="subtitle2">
                        {`${data.station_name} `}
                        <br />
                        {`${data.tambon} `}
                        {`${data.amphoe} `}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.hiddenLine}>
                              {t('เดือน')}
                            </TableCell>
                            <TableCell className={classes.hiddenLine} align="right">
                              {t('ค่าเฉลี่ย')}
                            </TableCell>
                            <TableCell className={classes.hiddenLine} align="right">
                              {t('ต่ำสุด')}
                            </TableCell>
                            <TableCell className={classes.hiddenLine} align="right">
                              {t('สูงสุด')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.value[0].map(val => (
                            <TableRow key={uniqueId()}>
                              <TableCell className={classes.hiddenLine}>
                                {moment(val.date).format('MMMM')}
                              </TableCell>
                              <TableCell className={classes.hiddenLine} align="right">
                                {val['rainfall.mean']}
                              </TableCell>
                              <TableCell className={classes.hiddenLine} align="right">
                                {val['rainfall.min']}
                              </TableCell>
                              <TableCell className={classes.hiddenLine} align="right">
                                {val['rainfall.max']}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={4}>
                      {t('หน่วย')}
                      {' ('}
                      {t('มม.')}
                      {')'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {t('สถานีฝน')}
                  <hr />
                  {`${data.station_name} `}
                  <br />
                  {`${data.tambon} `}
                  {`${data.amphoe} `}
                </strong>
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  return (
    <LayerGroup ref={refRainfallStation}>
      {datas
        .map(eachRainfallStation)}
    </LayerGroup>
  );
}
