
import iconRect from '../widgets/map/iconRect';

const wlColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อยวิกฤต',
    rangeTitle: '<=10',
    statusColor: '#db802b',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อย',
    rangeTitle: '>10-30',
    statusColor: '#ffc000',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปกติ',
    rangeTitle: '>30-70',
    statusColor: '#00b050',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'มาก',
    rangeTitle: '>70-100',
    statusColor: '#003cfa',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'ล้นตลิ่ง',
    rangeTitle: '>100',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const WlStatusColorDB = (currentWl, previousWl, storagePercent) => {
  let color;
  let icon;
  const [zero, one, two, three, four, five] = wlColor;
  // console.log(currentWl, previousWl, storagePercent);

  if ((storagePercent === null) || (Number.isNaN(previousWl))) {
    color = zero;
    icon = iconRect.grey;
  } else if (storagePercent > 100) {
    if (currentWl === previousWl) {
      color = five;
      icon = iconRect.red;
    } else if (currentWl > previousWl) {
      color = five;
      icon = iconRect.redup;
    } else if (currentWl < previousWl) {
      color = five;
      icon = iconRect.reddown;
    }
  } else if (storagePercent > 70) {
    if (currentWl === previousWl) {
      color = four;
      icon = iconRect.blue;
    } else if (currentWl > previousWl) {
      color = four;
      icon = iconRect.blueup;
    } else if (currentWl < previousWl) {
      color = four;
      icon = iconRect.bluedown;
    }
  } else if (storagePercent > 30) {
    if (currentWl === previousWl) {
      color = three;
      icon = iconRect.green;
    } else if (currentWl > previousWl) {
      color = three;
      icon = iconRect.greenup;
    } else if (currentWl < previousWl) {
      color = three;
      icon = iconRect.greendown;
    }
  } else if (storagePercent > 10) {
    if (currentWl === previousWl) {
      color = two;
      icon = iconRect.yellow;
    } else if (currentWl > previousWl) {
      color = two;
      icon = iconRect.yellowup;
    } else if (currentWl < previousWl) {
      color = two;
      icon = iconRect.yellowdown;
    }
  } else if (storagePercent <= 10) {
    if (currentWl === previousWl) {
      color = one;
      icon = iconRect.brown;
    } else if (currentWl > previousWl) {
      color = one;
      icon = iconRect.brownup;
    } else if (currentWl < previousWl) {
      color = one;
      icon = iconRect.browndown;
    }
  } else {
    color = zero;
    icon = iconRect.grey;
  }

  return { color, icon };
};

export default WlStatusColorDB;
