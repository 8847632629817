// https://react.i18next.com/
// https://react.i18next.com/latest/migrating-v9-to-v10
// https://github.com/i18next/i18next-browser-languageDetector
// https://react.i18next.com/latest/i18next-instance

import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

class LangDemo extends Component {
  render() {
    const { t, i18n } = this.props;

    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };

    // const currentTH = i18n.language === 'th' ? '50px' : '30px';
    // const currentEN = i18n.language === 'en' ? '50px' : '30px';

    return (
      <>
        <Box
          width="100%"
          align="center"
          pt={3}
          pb={3}
        >
          {'กดเปลี่ยนภาษา แล้ว refresh หน้าจอ 1 ครั้ง'}
          {' '}
          {' ภาษาที่เลือกจะยังเปลี่ยนภาษาที่ได้เลือกไว้ก่อนหน้า'}
        </Box>
        <Box
          width="100%"
          align="center"
        >
          <Button onClick={() => changeLanguage('th')}>
            TH
          </Button>
          <Button onClick={() => changeLanguage('en')}>
            EN
          </Button>
          <Button onClick={() => changeLanguage('jp')}>
            JP
          </Button>
        </Box>
        <Box
          width="100%"
          align="center"
        >
          {' '}
          <Trans>คลังข้อมูลน้ำแห่งชาติ</Trans>
          {' / '}
          {t('คลังข้อมูลน้ำแห่งชาติ')}
        </Box>
      </>
    );
  }
}

export default withTranslation()(LangDemo);


/** react hook

import { useTranslation } from "react-i18next";

const App = () => {
  const { t } = useTranslation();

  return <>{t("ทดสอบ")}</>;
};

** */
