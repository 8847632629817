/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@material-ui/core';

import {
  HiiFloodIcon, HiiFlowIcon, HiiWaterLevelV2Icon, HiiRainIcon,
} from '../../../utils/IconBma';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: '100%',
    padding: 10,
  },
  label: {
    fontSize: '1em',
    fontWeight: 300,
  },
  icon: {
    fontSize: 25,
    marginRight: 5,
    padding: 2,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
});

const CompWaterSelect = props => {
  const classes = useStyles();

  const {
    handleMapChecked,
    waterLevelMapChecked,
    floodStationMapChecked,
    waterFlowMapChecked,
    rainStationMapChecked,
  } = props;

  const handleChange = event => {
    handleMapChecked(event.target.name, event.target.checked);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormGroup aria-label="water" name="water">
          <FormControlLabel value="1" name="waterLevelMapChecked" control={<Checkbox checked={waterLevelMapChecked} style={{ color: waterLevelMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box fontWeight={300}><HiiWaterLevelV2Icon className={classes.icon} />  สถานีวัดระดับน้ำ</Box>} className={classes.label} />
          <FormControlLabel value="2" name="floodStationMapChecked" control={<Checkbox checked={floodStationMapChecked} style={{ color: floodStationMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box fontWeight={300}><HiiFloodIcon className={classes.icon} />  สถานีน้ำท่วมถนน</Box>} className={classes.label} />
          <FormControlLabel value="3" name="waterFlowMapChecked" control={<Checkbox checked={waterFlowMapChecked} style={{ color: waterFlowMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box fontWeight={300}><HiiFlowIcon className={classes.icon} />  สถานีวัดอัตราการไหล</Box>} className={classes.label} />
          <FormControlLabel value="4" name="rainStationMapChecked" control={<Checkbox checked={rainStationMapChecked} style={{ color: rainStationMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box fontWeight={300}><HiiRainIcon className={classes.icon} />สถานีวัดปริมาณน้ำฝน</Box>} className={classes.label} />
        </FormGroup>
      </FormControl>
    </div>
  );
};

CompWaterSelect.propTypes = {
  handleMapChecked: PropTypes.func.isRequired,
  waterLevelMapChecked: PropTypes.bool.isRequired,
  floodStationMapChecked: PropTypes.bool.isRequired,
  waterFlowMapChecked: PropTypes.bool.isRequired,
  rainStationMapChecked: PropTypes.bool.isRequired,
};

export default CompWaterSelect;
