/* eslint-disable max-len */
// react components
import Dashboard from '../components/dashboard/Dashboard';
import AboutUs from '../components/about/AboutUs';
import Research from '../components/research/Research';
import Media from '../components/media/Media';
import MediaBookView from '../components/media/BookView';
import MediaBookAll from '../components/media/BookAll';
import MediaVideoView from '../components/media/VideoView';
import MediaVideoAll from '../components/media/VideoAll';
import Report from '../components/report/Report';
import ReportDaily from '../components/report/ReportDaily';
import ReportDailySummary from '../components/report/ReportDailySummary';
import ReportWeekly from '../components/report/ReportWeekly';
import ReportMonthly from '../components/report/ReportMonthly';
import ReportMonthlyDroughtHistory from '../components/report/ReportMonthlyDroughtHistory';
import ReportMonthlyDroughtView from '../components/report/ReportMonthlyDroughtView';
import ReportRainForecastSixMonthHistory from '../components/report/ReportRainForecastSixMonthHistory';
import ReportRainForecastSixMonthView from '../components/report/ReportRainForecastSixMonthView';
import ReportYearlyHistory from '../components/report/ReportYearlyHistory';
import ReportYearlyView from '../components/report/ReportYearlyView';
import ReportViewIframe from '../components/report/view/ViewIframe';
import ChartChaopraya from '../components/report/chart/ChartChaopraya';
import SituationDistrict from '../components/situation/district/Main';
import SituationCommunity from '../components/situation/community/Main';
import RainForecastMonth from '../components/forecast/rain_forecast_month/Main';
import ReportSalinity from '../components/report_salinity/ReportSalinity';
import ReportVorticities from '../components/report_vorticities/ReportVorticities';
import CPFOMain from '../components/cpfo/cpfoMain';

import FloodForecast from '../components/forecast/floodforecast/FloodForecast';
import FloodPlainForecast from '../components/forecast/floodplain_forcecast/FloodPlainForecast';
import FloodPlainForecastUi from '../components/forecast/floodplain_forcecast_ui/FloodPlainForecast';
import Salinity from '../components/forecast/Salinity/Salinity';
import FlashFlood from '../components/flashflood/FlashFlood';
import DroughtMonitoring from '../components/drought_monitoring/DroughtMonitoring';
import SwanHistory from '../components/forecast/swan/SwanHistory';

// weather
import Weather from '../components/weather/Weather';
import WeatherList from '../components/weather/weather/WeatherList';
import Storm from '../components/weather/WeatherStorm';
import StormHistoryJtwc from '../components/storm_history/StormHistoryJtwc';
import StormMeaning from '../components/storm_history/StormMeaning';
import StormSurge from '../components/storm_surge/map/StormSurgeForecast';
import StormSurgeForecast from '../components/storm_surge/forecast';
import Rainfall from '../components/weather/WeatherRain';
import Radar from '../components/weather/WeatherRadar';
import RadarHistory from '../components/radar/RadarHistory';
import Sea from '../components/weather/WeatherSea';
import SstHistory from '../components/weather/sst/SstHistory';
import SstHiiHistory from '../components/weather/sst/SstHiiHistory';
import RainAccumulationHistory from '../components/weather/rain_accumulation/RainAccumulationHistory';
import UpperWindHistory from '../components/weather/upperwind/UpperWindHistory';
import VerticalWindHistory from '../components/weather/upperwind/VerticalWindHistory';
import WeatherImageHistory from '../components/weather/weather-image/WeatherImageHistory';
import WeatherImageHistoryMonth from '../components/weather/weather-image/WeatherImageHistoryMonth';
import TroughHistory from '../components/weather/trough/TroughHistory';

import SearchResult from '../components/search/SearchResult';
import RainDistribution from '../components/weather/weather-image/RainDistribution';
import PrecipitationHistory from '../components/weather/precipitation/PrecipitationHistory';
import VaporHistory from '../components/weather/vapor/VaporHistory';
import WindHistory from '../components/weather/wind/WindHistory';
import WrfHistory from '../components/forecast/wrf/WrfHistory';
// import WrfAnimation from '../components/forecast/wrf/WrfAnimation';

// asd
import WaterSitDam from '../components/dam/Dam';
import Gate from '../components/watersit/WaterSitWaterGate';
import Wl from '../components/watersit/WaterSitWaterLevel';
import Cctv from '../components/cctv/Cctv';
import Ocean from '../components/sealevel/Sealevel';
import Quality from '../components/water_quality/WaterQuality';
import GsmapHistory from '../components/weather/gsmap/GsmapHistory';
import StormJtwc from '../components/storm_history/StormJtwc';
import CompRadar from '../components/forecast/comp_radar/CompRadar';
import WrfRoms from '../components/forecast/wrfroms_vector/WrfRoms';

import LandingPageV3 from '../components/landingpage/PageV3';
import Sitemap from '../components/sitemap/Sitemap';
import LangDemo from '../components/lang_demo/LangDemo';

//  bma version 0 ไม่ได้ใช้งาน
import bmawater from '../components/bmadrainage/BmaWater';
// bma version 1 ไม่ได้ใช้งาน
// import Bma from '../components/bma/BmaWater';

import fwhBmawater from '../components/fwh_bmadrainage/FwhBmaWater';

// new UI
import BmaUi from '../components/bma_ui/BmaWater';

import RidWateLevel from '../components/wl_rid/RidWaterLevel';

import WaterlevelTabSit from '../components/dashboard/situations/WaterlevelTabSit';
// error404
import Error404 from '../components/error404/Error404';

import { withSeo, seo } from '../utils/Seo';

// กราฟสำหรับเรียกจาก url ใช้ใน water chart
// ตย.การเรียกใช้งาน https://www.thaiwater.net/water/gate/chart/{รหัสสถานี}/{รหัสจังหวัด}
import damChart from '../components/water_chart/chart/damChart';
import wlChart from '../components/water_chart/chart/wlChart';
import wlChartDate from '../components/water_chart/chart/wlChartDate';
import wgChart from '../components/water_chart/chart/wgChart';
import wgChartDate from '../components/water_chart/chart/wgChartDate';

// กราฟสำหรับเรียกจาก url ใช้ใน hydrogence 901 land
import rfChart from '../components/water_chart/chart/rfChart';

// ข้อมูลภาพ ClimateChange สำหรับ aseanwater.net
import ClimateChange from '../components/climate_change/ClimateChange';

// ข้อมูลภาพ GsMap สำหรับ aseanwater.net
import GsMap from '../components/gsmap/GsMap';

// ข้อมูลภาพ MhesiWarroom สำหรับ ศูนย์ปฏิบัติการสถานการณ์น้ำท่วม อว. เพื่อประชาชน
import MhesiWarroom from '../components/mhesi_warroom/MhesiWarroom';

// สถิติข้อมูลเว็บไซต์
import StatisticsReport from '../components/nhc_statistics_report/StatisticsReport';

// รายงานแจ้งเตือนสถานการณ์
import BoxSituationHistory from '../components/report/drive/BoxSituationHistory';
// รายงาน Infographic สถานการณ์น้ำ
import BoxInfographicHistory from '../components/report/drive/BoxInfographicHistory';

// 3 เทคโนโลยีตรวจวัดระดับน้ำอัตโนมัติ
import DemoSite from '../components/demo_site/DemoSite';

// เทคโนโลยีตรวจวัดระดับน้ำอัตโนมัติ โทรมาตร
import DemoSiteTele from '../components/demo_site_tele/DemoSite';

// เทคโนโลยีตรวจวัดระดับน้ำอัตโนมัติ ปทุมาตร
import DemoSitePathummat from '../components/demo_site_pathummat/DemoSite';
import DemoSiteCombine from '../components/demo_site_combine/DemoSite';

// not used
// import Forecast from '../components/forecast/Forecast';
// import WaterSit from '../components/watersit/WaterSit';
// import WaterSitDam from './WaterSitDam';
// import WeatherStormSearch from '../components/weather/WeatherStormSearch';
// import WeatherRainList from '../components/weather/WeatherRainList';
// import WeatherClimate from '../components/weather/WeatherClimate';
// import StormHistory from '../components/storm/StormHistory';
// import StormDemo from '../components/storm/Storm';
// import Rainfall from '../components/rainfall/Rainfall';
// import Radar from '../components/radar/Radar';
// import CompRadar from '../components/forecast/compradar/CompRadar'

const initPath = process.env.MIX_APP_PATH;

// === seo config ===
// เพิ่ม comp แล้วเพิ่มคำอธิบาย keyword ใน ไฟล์ resources\js\store\SeoMeta.js
const DashboardComp = withSeo({ component: Dashboard, meta: seo.Dashboard.meta });
const AboutUsComp = withSeo({ component: AboutUs, meta: seo.AboutUs.meta });
const CctvComp = withSeo({ component: Cctv, meta: seo.Cctv.meta });
const WaterSitDamComp = withSeo({ component: WaterSitDam, meta: seo.WaterSitDam.meta });
const DroughtMonitoringComp = withSeo({ component: DroughtMonitoring, meta: seo.DroughtMonitoring.meta });
const FlashFloodComp = withSeo({ component: FlashFlood, meta: seo.FlashFlood.meta });
const FloodForecastComp = withSeo({ component: FloodForecast, meta: seo.FloodForecast.meta });
const FloodPlainForecastComp = withSeo({ component: FloodPlainForecast, meta: seo.FloodPlainForecast.meta });
const FloodPlainForecastUiComp = withSeo({ component: FloodPlainForecastUi, meta: seo.FloodPlainForecast.meta });
const MediaComp = withSeo({ component: Media, meta: seo.Media.meta });
const ReportComp = withSeo({ component: Report, meta: seo.Report.meta });
const ResearchComp = withSeo({ component: Research, meta: seo.Research.meta });
const OceanComp = withSeo({ component: Ocean, meta: seo.Ocean.meta });
const SearchResultComp = withSeo({ component: SearchResult, meta: seo.SearchResult.meta });
const SitemapComp = withSeo({ component: Sitemap, meta: seo.Sitemap.meta });
const QualityComp = withSeo({ component: Quality, meta: seo.Quality.meta });
const GateComp = withSeo({ component: Gate, meta: seo.Gate.meta });
const WlComp = withSeo({ component: Wl, meta: seo.Wl.meta });
const WeatherComp = withSeo({ component: Weather, meta: seo.Weather.meta });
const RadarComp = withSeo({ component: Radar, meta: seo.Radar.meta });
const RainfallComp = withSeo({ component: Rainfall, meta: seo.Rainfall.meta });
const SeaComp = withSeo({ component: Sea, meta: seo.Sea.meta });
const StormComp = withSeo({ component: Storm, meta: seo.Storm.meta });
const StormSurgeComp = withSeo({ component: StormSurge, meta: seo.StormSurge.meta });
const StormSurgeForecastComp = withSeo({ component: StormSurgeForecast, meta: seo.StormSurge.meta });
const SituationDistrictComp = withSeo({ component: SituationDistrict, meta: seo.SituationDistrict.meta });
const RainForecastMonthComp = withSeo({ component: RainForecastMonth, meta: seo.RainForecastMonth.meta });
const SituationCommunityComp = withSeo({ component: SituationCommunity, meta: seo.SituationCommunity.meta });
const ReportDailySummaryComp = withSeo({ component: ReportDailySummary, meta: seo.ReportDailySummary.meta });
const ReportDailyComp = withSeo({ component: ReportDaily, meta: seo.ReportDaily.meta });
const ReportWeeklyComp = withSeo({ component: ReportWeekly, meta: seo.ReportWeekly.meta });
const ReportMonthlyComp = withSeo({ component: ReportMonthly, meta: seo.ReportMonthly.meta });
const ReportYearlyHistoryComp = withSeo({ component: ReportYearlyHistory, meta: seo.ReportYearlyHistory.meta });
const WaterChartDamComp = withSeo({ component: damChart, meta: seo.WaterChartDamComp.meta });
const WaterChartWlComp = withSeo({ component: wlChart, meta: seo.WaterChartWlComp.meta });
const WaterChartWlDateComp = withSeo({ component: wlChartDate, meta: seo.WaterChartWlDateComp.meta });
const WaterChartWgComp = withSeo({ component: wgChart, meta: seo.WaterChartWgComp.meta });
const WaterChartWgDateComp = withSeo({ component: wgChartDate, meta: seo.WaterChartWgDateComp.meta });
const RfChartComp = withSeo({ component: rfChart, meta: seo.RfChartComp.meta });
const climateChangeComp = withSeo({ component: ClimateChange, meta: seo.ClimateChange.meta });
const GsMapComp = withSeo({ component: GsMap, meta: seo.GsMap.meta });
const StatisticsReportComp = withSeo({ component: StatisticsReport, meta: seo.StatisticsReport.meta });
const ReportSalinityComp = withSeo({ component: ReportSalinity, meta: seo.ReportSalinity.meta });
const ReportVorticitiesComp = withSeo({ component: ReportVorticities, meta: seo.ReportVorticities.meta });
const CPFOMainComp = withSeo({ component: CPFOMain, meta: seo.CPFOMain.meta });
const BmaUiComp = withSeo({ component: BmaUi, meta: seo.BmaUi.meta });
const WeatherListComp = withSeo({ component: WeatherList, meta: seo.WeatherList.meta });
const mhesiWarroomComp = withSeo({ component: MhesiWarroom, meta: seo.MhesiWarroom.meta });
const DemoSiteComp = withSeo({ component: DemoSite, meta: seo.DemoSite.meta });
const DemoSiteTeleComp = withSeo({ component: DemoSiteTele, meta: seo.DemoSiteTele.meta });
const DemoSitePathummatComp = withSeo({ component: DemoSitePathummat, meta: seo.DemoSitePathummat.meta });
const DemoSiteCombineComp = withSeo({ component: DemoSiteCombine, meta: seo.DemoSiteCombine.meta });

// === routes config ===
const Routes = () => [
  {
    path: initPath,
    exact: true,
    component: DashboardComp,
  },
  {
    path: `${initPath}about`,
    exact: true,
    component: AboutUsComp,
  },
  {
    path: `${initPath}research`,
    exact: true,
    component: ResearchComp,
  },
  {
    path: `${initPath}research/:reportType`,
    exact: true,
    component: ReportViewIframe,
  },
  {
    path: `${initPath}media`,
    exact: true,
    component: MediaComp,
  },
  {
    path: `${initPath}media/book/view/:bookUrl`,
    exact: true,
    component: MediaBookView,
  },
  {
    path: `${initPath}media/book/all`,
    exact: true,
    component: MediaBookAll,
  },
  {
    path: `${initPath}media/video/view/:videoUrl`,
    exact: true,
    component: MediaVideoView,
  },
  {
    path: `${initPath}media/video/all`,
    exact: true,
    component: MediaVideoAll,
  },
  {
    path: `${initPath}report`,
    exact: true,
    component: ReportComp,
  },
  {
    path: `${initPath}report_daily`,
    exact: true,
    component: ReportDailyComp,
  },
  {
    path: `${initPath}report_weekly`,
    exact: true,
    component: ReportWeeklyComp,
  },
  {
    path: `${initPath}report/daily/summary`,
    exact: true,
    component: ReportDailySummaryComp,
  },
  {
    path: `${initPath}report_monthly`,
    exact: true,
    component: ReportMonthlyComp,
  },
  {
    path: `${initPath}report/monthly/drought/history`,
    exact: true,
    component: ReportMonthlyDroughtHistory,
  },
  {
    path: `${initPath}report/monthly/drought/:month/:url`,
    exact: true,
    component: ReportMonthlyDroughtView,
  },
  {
    path: `${initPath}report/monthly/rain_forecast_6m/history`,
    exact: true,
    component: ReportRainForecastSixMonthHistory,
  },
  {
    path: `${initPath}report/monthly/rain_forecast_6m/:month/:url`,
    exact: true,
    component: ReportRainForecastSixMonthView,
  },
  {
    path: `${initPath}report/yearly/history`,
    exact: true,
    component: ReportYearlyHistoryComp,
  },
  {
    path: `${initPath}report/yearly/:year/:url`,
    exact: true,
    component: ReportYearlyView,
  },
  {
    path: `${initPath}report/:reportType/view`,
    exact: true,
    component: ReportViewIframe,
  },
  {
    path: `${initPath}report/chart/chaopraya`,
    exact: true,
    component: ChartChaopraya,
  },
  // {
  //   path: `${initPath}wl`,
  //   exact: false,
  //   component: Wl,
  // },
  {
    path: `${initPath}report-salinity`,
    exact: true,
    component: ReportSalinityComp,
  },
  {
    path: `${initPath}report-wrf-vortex`,
    exact: true,
    component: ReportVorticitiesComp,
  },
  {
    path: `${initPath}comparing-rain-predictions`,
    exact: true,
    component: CPFOMainComp,
  },
  {
    path: `${initPath}salinity/:showType?`,
    exact: true,
    component: Salinity,
  },
  // {
  //   path: `${initPath}storm/:date?/:agencyId/:mediaTypeId`,
  //   exact: true,
  //   component: StormHistory,
  // },
  {
    path: `${initPath}storm/history/jtwc`,
    exact: true,
    component: StormHistoryJtwc,
  },
  {
    path: `${initPath}storm/jtwc`,
    exact: true,
    component: StormJtwc,
  },
  {
    path: `${initPath}storm/meaning`,
    exact: true,
    component: StormMeaning,
  },
  {
    path: `${initPath}floodforecast/:showType?`,
    exact: true,
    component: FloodForecastComp,
  },
  {
    path: `${initPath}floodplain`,
    exact: true,
    component: FloodPlainForecastComp,
  },
  {
    path: `${initPath}floodplain_ui`,
    exact: true,
    component: FloodPlainForecastUiComp,
  },
  {
    path: `${initPath}flashflood`,
    exact: true,
    component: FlashFloodComp,
  },
  // {
  //   path: `${initPath}radar`,
  //   exact: false,
  //   component: Radar,
  // },
  {
    path: `${initPath}forecast/rainfall_month`,
    exact: true,
    component: RainForecastMonthComp,
  },
  {
    path: `${initPath}situation/community`,
    exact: true,
    component: SituationCommunityComp,
  },
  {
    path: `${initPath}forecast/compradar`,
    exact: true,
    component: CompRadar,
  },
  {
    path: `${initPath}forecast/wrfroms`,
    exact: true,
    component: WrfRoms,
  },
  {
    path: `${initPath}forecast/wrf/history/:status`,
    exact: true,
    component: WrfHistory,
  },
  // {
  // path: `${initPath}forecast/wrf/history/:status`,
  // exact: true,
  // component: WrfHistory,
  // component: WrfAnimation,
  // },
  // {
  //   path: `${initPath}forecast`,
  //   exact: false,
  //   component: Forecast,
  // },
  // {
  //   path: `${initPath}situation`,
  //   exact: true,
  //   component: Situation,
  // },
  // {
  //   path: `${initPath}rainfall`,
  //   exact: true,
  //   component: Rainfall,
  // },
  {
    path: `${initPath}weather`,
    exact: true,
    component: WeatherComp,
  },
  {
    path: `${initPath}weather/storm`,
    exact: true,
    component: StormComp,
  },
  {
    path: `${initPath}weather/rainfall`,
    exact: true,
    component: RainfallComp,
  },
  {
    path: `${initPath}weather/rainfall/chart/:rainfallType/:stationId/:provinceId`,
    exact: true,
    component: RfChartComp,
  },
  {
    path: `${initPath}weather/gsmaphistory`,
    exact: true,
    component: GsmapHistory,
  },
  {
    path: `${initPath}weather/radar`,
    exact: true,
    component: RadarComp,
  },
  {
    path: `${initPath}weather/radar/history/:radarType/:date?`,
    exact: true,
    component: RadarHistory,
  },
  {
    path: `${initPath}weather/sea`,
    exact: true,
    component: SeaComp,
  },
  {
    path: `${initPath}weather/sea/sst/history/:mediaTypeId?`,
    exact: true,
    component: SstHistory,
  },
  {
    path: `${initPath}weather/sea/sst/hii/history/`,
    exact: true,
    component: SstHiiHistory,
  },
  {
    path: `${initPath}forecast/swan/history`,
    exact: true,
    component: SwanHistory,
  },
  {
    path: `${initPath}forecast/swan/history/:status`,
    exact: true,
    component: SwanHistory,
  },
  // {
  //   path: `${initPath}weather/weatherstormsearch`,
  //   exact: true,
  //   component: WeatherStormSearch,
  // },
  {
    path: `${initPath}weather/upperwind/history/5km`,
    exact: true,
    component: VerticalWindHistory,
  },
  {
    path: `${initPath}weather/upperwind/history/:height`,
    exact: true,
    component: UpperWindHistory,
  },
  {
    path: `${initPath}weather/wind/history`,
    exact: true,
    component: WindHistory,
  },
  {
    path: `${initPath}weather/trough/history`,
    exact: true,
    component: TroughHistory,
  },
  {
    path: `${initPath}weather/rainAccumulation/history`,
    exact: true,
    component: RainAccumulationHistory,
  },
  {
    path: `${initPath}weather/history/month/:agencyId/:mediaTypeId`,
    exact: true,
    component: WeatherImageHistoryMonth,
  },
  {
    path: `${initPath}weather/:date/:agencyId/:mediaTypeId`,
    exact: true,
    component: WeatherImageHistory,
  },
  {
    path: `${initPath}weather/precipitation/histroy`,
    exact: true,
    component: PrecipitationHistory,
  },
  {
    path: `${initPath}weather/vapor/histroy`,
    exact: true,
    component: VaporHistory,
  },
  {
    path: `${initPath}weather/rain_dist/:year`,
    exact: true,
    component: RainDistribution,
  },
  {
    path: `${initPath}water`,
    exact: true,
    component: WaterSitDamComp,
  },
  {
    path: `${initPath}water/dam`,
    exact: true,
    component: WaterSitDamComp,
  },
  {
    path: `${initPath}water/dam/large`,
    exact: true,
    component: WaterSitDamComp,
  },
  {
    path: `${initPath}water/dam/medium`,
    exact: true,
    component: WaterSitDamComp,
  },
  {
    path: `${initPath}water/dam/chart/:damtype/:id`,
    exact: true,
    component: WaterChartDamComp,
  },
  {
    path: `${initPath}water/gate`,
    exact: true,
    component: GateComp,
  },
  {
    path: `${initPath}water/gate/chart/:stationId/:provinceId`,
    exact: true,
    component: WaterChartWgComp,
  },
  {
    path: `${initPath}water/gate/chart/:stationId/:provinceId/:startDate`,
    exact: true,
    component: WaterChartWgDateComp,
  },
  {
    path: `${initPath}water/wl`,
    exact: true,
    component: WlComp,
  },
  {
    path: `${initPath}water/wl/rid`,
    exact: true,
    component: RidWateLevel,
  },
  {
    path: `${initPath}water/wl/chart/:stationId/:provinceId`,
    exact: true,
    component: WaterChartWlComp,
  },
  {
    path: `${initPath}water/wl/chart/:stationId/:provinceId/:selectedType`,
    exact: true,
    component: WaterChartWlDateComp,
  },
  {
    path: `${initPath}water/cctv`,
    exact: true,
    component: CctvComp,
  },
  {
    path: `${initPath}water/ocean`,
    exact: true,
    component: OceanComp,
  },
  {
    path: `${initPath}water/quality`,
    exact: true,
    component: QualityComp,
  },
  {
    path: `${initPath}search/:searchTerm`,
    exact: true,
    component: SearchResultComp,
  },
  {
    path: `${initPath}situation/district`,
    exact: true,
    component: SituationDistrictComp,
  },
  {
    path: `${initPath}one_map/one_map_rain_forecast`,
    exact: true,
    component: RainForecastMonth,
  },
  {
    path: `${initPath}drought/monitoring`,
    exact: true,
    component: DroughtMonitoringComp,
  },
  {
    path: `${initPath}storm_surge`,
    exact: true,
    component: StormSurgeComp,
  },
  {
    path: `${initPath}storm_surge/forecast`,
    exact: true,
    component: StormSurgeForecastComp,
  },
  {
    path: `${initPath}v3`,
    exact: true,
    component: LandingPageV3,
  },
  {
    path: `${initPath}sitemap`,
    exact: true,
    component: SitemapComp,
  },
  {
    path: `${initPath}lang_demo`,
    exact: true,
    component: LangDemo,
  },
  {
    path: `${initPath}bmawater`,
    exact: true,
    component: bmawater,
  },
  {
    path: `${initPath}fwh_bmawater`,
    exact: true,
    component: fwhBmawater,
  },
  {
    path: `${initPath}bma`,
    exact: true,
    component: BmaUiComp,
  },
  {
    path: `${initPath}bma_ui`,
    exact: true,
    component: BmaUi,
  },
  {
    path: `${initPath}latest-situation`,
    exact: true,
    component: WaterlevelTabSit,
  },
  {
    path: `${initPath}climate-change`,
    exact: true,
    component: climateChangeComp,
  },
  {
    path: `${initPath}gsmap-asean`,
    exact: true,
    component: GsMapComp,
  },
  {
    path: `${initPath}latest-situation/:category`,
    exact: true,
    component: WaterlevelTabSit,
  },
  {
    path: `${initPath}statistics-report`,
    exact: true,
    component: StatisticsReportComp,
  },
  {
    path: `${initPath}weather/weather-station`,
    exact: true,
    component: WeatherListComp,
  },
  {
    path: `${initPath}mhesi-warroom`,
    exact: true,
    component: mhesiWarroomComp,
  },
  {
    path: `${initPath}report/situation`,
    exact: true,
    component: BoxSituationHistory,
  },
  {
    path: `${initPath}report/infographic`,
    exact: true,
    component: BoxInfographicHistory,
  },
  {
    path: `${initPath}demo-site`,
    exact: true,
    component: DemoSiteComp,
  },
  {
    path: `${initPath}demo-site-tele`,
    exact: true,
    component: DemoSiteTeleComp,
  },
  {
    path: `${initPath}demo-site-pathummat`,
    exact: true,
    component: DemoSitePathummatComp,
  },
  {
    path: `${initPath}demo-site-combine`,
    exact: true,
    component: DemoSiteCombineComp,
  },
  {
    path: '*',
    exact: true,
    component: Error404,
  },
];
export default Routes;
