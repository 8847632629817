import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Link,
} from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Lightbox from 'react-image-lightbox';

// form .
import IconButton from '@material-ui/core/IconButton';
import { Hidden } from '@material-ui/core';
import Img from 'react-image';

// icon .
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import {
  FaCalendarAlt, FaSpinner,
} from '../../utils/Fontawesome';
import { HiiRain24Icon } from '../../utils/Icon';

// conponents
import SidebarWeather from '../navigation/SidebarWeather';
import Rainfall from '../rainfall/Rainfall';
import Rainforecast from '../forecast/wrf/Wrf';
import Precipitation from './precipitation/Precipitation';
// import Vapor from './vapor/Vapor';

// style
import { styles } from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
const thaiwaterPath = process.env.MIX_THAIWATER_URL;
const livePath = process.env.MIX_LIVE_URL;
const images = [];
const imageRadar = [];

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class WeatherRain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      date: new Date(),
      isOpen: false,
      isOpenGsMap: false,
      isOpenRainAccumulation: false,
      isOpenRadar: false,
      isOpenRainImage: false,
      isOpenPWV: false,
      lastImageGsMAP: '',
      rainImage: '',
      rainAccumulat: [],
      latestRainAccmulat: '',
      sea: 'gsm_010',
      height: this.setHeightImg(),
      month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    };
  }

  componentDidMount() {
    this.getImageLastDate('gsm_010');
    this.getRainImage();
    this.getRainAccumulatImg();
  }

  setHeightImg() {
    const { width } = this.props;
    let heightImg = 0;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightImg = 480;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightImg = 601;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightImg = 'auto';
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightImg = 'auto';
    }
    return heightImg;
  }

  getRainAccumulatImg() {
    const startDate = new Date(moment());
    const selectedMonth = moment(startDate).format('MM');
    const selectedYear = moment(startDate).format('YYYY');

    fetch(`${process.env.MIX_API_URL}analyst/rainaccumulat_img?year=${selectedYear}&month=${selectedMonth}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          rainAccumulat: result.data,
          isLoading: false,
          latestRainAccmulat: this.getMaxDate(result.data),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.datetime));
      const maxDate = new Date(Math.max.apply(null, dates));
      return moment(maxDate).format('YYYY-MM-DD');
    }
    return new Date();
  }

  getRainImage() {
    fetch(`${process.env.MIX_API_URL}public/weather_img/image_generate`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const link = `${process.env.MIX_APP_PATH}weather/${moment(result.data[2][0].datetime).format('YYYY-MM-DD')}/64/180`;
        const rainImage = {
          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[2][0].media_path}`,
          link,
        };
        this.setState({
          rainImage,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getImageLastDate(type) {
    if (type === 'gsm_010') {
      this.setState({
        lastImageGsMAP: `${livePath}/product/latest/sat_rain/gsmap_010_bias/gsmap_010_bias.png`,
        sea: 'gsm_010',
      });
    } else if (type === 'gsm_025') {
      this.setState({
        lastImageGsMAP: `${livePath}/product/latest/sat_rain/gsmap_025_bias/gsmap_025_bias.png`,
        sea: 'gsm_025',
      });
    } else if (type === 'per_gccs') {
      this.setState({
        lastImageGsMAP: `${livePath}/product/latest/sat_rain/persiann_bias/persiann_bias.png`,
        sea: 'per_gccs',
      });
    }
  }

  handleChange = event => {
    this.getImageLastDate(event.target.value);
  };

  render() {
    const { classes, theme, t } = this.props;
    const {
      isLoading,
      error,
      isOpen,
      date,
      lastImageGsMAP,
      sea,
      isOpenGsMap,
      isOpenRadar,
      isOpenRainAccumulation,
      isOpenRainImage,
      isOpenPWV,
      rainImage,
      rainAccumulat,
      latestRainAccmulat,
      month,
      height,
    } = this.state;
    images.push({
      src: `${thaiwaterPath}/hydro_report/archive/thailand/rainfall_[thailand][1][st0][sub1]_both_${date.getFullYear() - 1}-01-01_${date.getFullYear() - 1}-12-31.png`,
    });
    imageRadar.push({
      src: `${process.env.MIX_LIVE_URL}/product/latest/radar/plot/composite_radar.png`,
      url: `${process.env.MIX_LIVE_URL}/product/latest/radar/daily_radar_th.html`,
    });
    // const imageThirty = ['avg30y_th01__13.57.png', 'avg30y_th02__18.18.png', 'avg30y_th03__44.46.png', 'avg30y_th04__84.52.png', 'avg30y_th05__186.32.png', 'avg30y_th06__177.01.png', 'avg30y_th07__189.40.png', 'avg30y_th08__230.69.png', 'avg30y_th09__241.89.png', 'avg30y_th10__162.86.png', 'avg30y_th11__78.92.png', 'avg30y_th12__39.12.png'];
    // const rainDist48 = `${initPath}uploads/rain_distribution/48year/${moment().format('MM')}.gif`;
    // const rainDist30 = `${initPath}uploads/rain_distribution/30year/${imageThirty[moment().format('M')]}`;
    const rainAvg30 = `${initPath}uploads/rain_distribution/30_1year/avg30y_update202111_1499.61.png`;

    // filter rain Accumulat
    let rainAccumulatFilter = [];
    rainAccumulatFilter = rainAccumulat.filter(d => moment(d.datetime).format('YYYY-MM-DD') === latestRainAccmulat && (d.datetime.indexOf('T00:00:00+07:00') > -1 || d.datetime.indexOf('T12:00:00+07:00') > -1));
    if (rainAccumulatFilter.length === 1) {
      rainAccumulatFilter.push('');
    }
    if (rainAccumulatFilter.length === 0) {
      rainAccumulatFilter.push('');
      rainAccumulatFilter.push('');
    }

    return (
      <>

        {/* Sidebar */}
        <SidebarWeather />

        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {isLoading === true && (
                <FaSpinner size={25} />
              )}

              {error && (
                <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs>
              <Box mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Typography variant="h6" gutterBottom>
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Box display="flex" py={1} width="100%">
                <Box display="inline" p={1} alignItems="center">
                  <HiiRain24Icon fontSize={24} />
                </Box>
                <Box display="inline" pt={1} alignItems="center">
                  <Typography variant="h4">
                    {t('ฝน')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.boxTopRadius}
            style={{ marginTop: 16, marginBottom: 20 }}
          >
            <Grid
              item
              xs={8}
              sm={6}
              md={8}
              lg={8}
              style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}
            >
              <Box m={1} className={classes.titleBar}>
                {t('แผนที่ปริมาณสะสม 24 ชม. ย้อนหลัง')}
              </Box>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพล่าสุด')}>
                <Button
                  className={classes.outlinedLeftDesktopPrimary}
                  onClick={() => this.setState({ isOpenRainImage: true })}
                >
                  <ImageOutlinedIcon fontSize="small" />
                  <Hidden xsDown>
                    <Typography variant="h6" style={{ marginLeft: 8 }}>
                      {t('ดูภาพล่าสุด')}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
              {isOpenRainImage && (
                <Lightbox
                  mainSrc={rainImage.src}
                  imagePadding={50}
                  reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                  onCloseRequest={() => this.setState({ isOpenRainImage: false })}
                />
              )}
            </Grid>
            <Grid item xs>
              <Link to={`${rainImage.link}`}>
                <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                  <Button className={classes.outlinedDesktopPrimary}>
                    <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                    <Hidden xsDown>
                      <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>
                        {t('ดูภาพย้อนหลัง')}
                      </Typography>
                    </Hidden>
                  </Button>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
          <Rainfall />

          {/* Weather Image */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={3} lg={3} className={classes.boxMarginTop}>
              <Box display="flex" p={0} className={classes.boxTopRadius} alignItems="center" height={53}>
                <Box flexGrow={1} p={1} display="flex" justifyContent="center" alignItems="center" className={classes.boxTitlePrimary}>
                  <Box p={1} className={classes.titleBar} fontSize={16}>{t('ภาพเรดาร์ COMPOSITE')}</Box>
                </Box>
                <Box p={1}>
                  <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                    <Button
                      // className={classes.outlinedOldPrimary}
                      href={imageRadar[0].url}
                    >
                      <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
              <Box p={2} display="flex" className={classes.boxBottomRadarRadius} height={height} align="center" alignItems="center" justifyContent="center">
                <Box style={{ display: 'flex', height: '100%' }}>
                  <Button onClick={() => this.setState({ isOpenRadar: true })}>
                    <Img
                      src={imageRadar[0].src}
                      style={{ width: '90%', height: 'auto' }}
                      alt="ภาพ composite-radar"
                      // className={classes.responsive}
                      unloader={(
                        <Img
                          src="https://via.placeholder.com/204x375?text=No%20Image"
                          alt="No Data"
                          className={classes.responsive}
                        />
                      )}
                    />
                  </Button>
                  {isOpenRadar && (
                    <Lightbox
                      mainSrc={imageRadar[0].src}
                      imagePadding={50}
                      reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                      onCloseRequest={() => this.setState({ isOpenRadar: false })}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} lg={3} className={classes.boxMarginTop}>
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.boxTopRadius} style={{ backgroundColor: theme.palette.primary.main }}>
                  <Box flexgrow={1} p={1} display="flex" justifyContent="center" alignItems="center">
                    <Box pl={2} className={classes.titleBar}>
                      {t('ฝนดาวเทียมที่ปรับแก้ความเอนเอียง')}
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box display="flex" className={classes.boxBottomGsRadius} height={height} align="center" justifyContent="center">
                <Box display="block" width="100%">
                  <Grid container spacing={0} width="100%">
                    <Grid item xs flexgrow={1}>
                      <Select
                        value={sea}
                        name="sea"
                        onChange={this.handleChange}
                        className={classes.outlinedLeftDesktopPrimary}
                      >
                        <MenuItem value="gsm_010">GSMaP (10km X 10km)</MenuItem>
                        <MenuItem value="gsm_025">GSMaP (25km x 25km)</MenuItem>
                        <MenuItem value="per_gccs">PERSIANN (4km x 4km)</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs>
                      <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                        <Button
                          href={`${initPath}weather/gsmaphistory`}
                          className={classes.outlinedDesktopPrimary}
                          style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}
                        >
                          <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Box display="flex" align="center" alignItems="center" justifyContent="center">
                    <Box style={{ display: 'inline-flex', height: '100%' }}>
                      <Button onClick={() => this.setState({ isOpenGsMap: true })} height="auto">
                        <Img
                          src={lastImageGsMAP}
                          style={{ width: '68%', height: 'auto' }}
                          alt="ภาพ GsMap"
                          // className={classes.responsive}
                          unloader={(
                            <img
                              src="https://via.placeholder.com/300x250?text=No%20Image"
                              width="67%"
                              height="auto"
                              alt="No Data"
                            />
                          )}
                        />
                      </Button>
                      {isOpenGsMap && (
                        <Lightbox
                          mainSrc={lastImageGsMAP}
                          imagePadding={50}
                          reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                          onCloseRequest={() => this.setState({ isOpenGsMap: false })}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* เปลี่ยนเป็น  tiwrmdev จาก devsupport id=1199 */}
            <Grid item xs={12} md={3} lg={3} className={classes.boxMarginTop}>
              <Box display="flex" p={0} className={classes.boxTopRadius} alignItems="center" height={53}>
                <Box flexGrow={1} p={1} display="flex" justifyContent="center" alignItems="center" className={classes.boxTitlePrimary}>
                  <Box p={1} className={classes.titleBar} fontSize={16}>{t('ฝนสะสมจากโทรมาตร')}</Box>
                </Box>
                <Box p={1}>
                  <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                    <Button
                      onClick={() => window.open(`https://tiwrmdev.hii.or.th/DATA/REPORT/php/radar/show_NHCRainContour.php?sdate=${moment(date).format('D')} ${month[(moment(date).format('M') * 1) - 1]} ${moment(date).format('YYYY')}&subm=1#`, '_blank')}
                    >
                      <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
              <Box p={2} display="flex" className={classes.boxBottomRadarRadius} height={height} align="center" alignItems="center" justifyContent="center">
                <Box style={{ display: 'inline-flex', height: '100%' }}>
                  <Button onClick={() => this.setState({ isOpenRainAccumulation: true })}>
                    <Img
                      src={`https://tiwrmdev.hii.or.th/ContourImg/${moment(date).format('YYYY')}/${moment(date).format('MM')}/${moment(date).format('DD')}/nhcRainfall_Daily_1d_${moment(date).format('YYYY-MM-DD')}_07.png`}
                      style={{ width: '85%', height: 'auto' }}
                      alt={t('ภาพฝนสะสมจากโทรมาตร')}
                      // className={classes.responsive}
                      unloader={(
                        <Img
                          // src={`https://tiwrm.hii.or.th/ContourImg/${moment(date).subtract(1, 'days').format('YYYY')}/${moment(date).subtract(1, 'days').format('MM')}/${moment(date).subtract(1, 'days').format('DD')}/nhcRainfall_Daily_1d_${moment(date).subtract(1, 'days').format('YYYY-MM-DD')}_07.png`}
                          src="https://via.placeholder.com/204x375?text=No%20Image"
                          alt="No Data"
                          // style={{ width: '80%', height: 'auto' }}
                          className={classes.responsive}
                        />
                      )}
                    />
                  </Button>
                  {isOpenRainAccumulation && (
                    <Lightbox
                      mainSrc={`https://tiwrmdev.hii.or.th/ContourImg/${moment(date).format('YYYY')}/${moment(date).format('MM')}/${moment(date).format('DD')}/nhcRainfall_Daily_1d_${moment(date).format('YYYY-MM-DD')}_07.png`}
                      imagePadding={50}
                      reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                      onCloseRequest={() => this.setState({ isOpenRainAccumulation: false })}
                    />
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={3} lg={3} className={classes.boxMarginTop}>
              <Box display="flex" p={0} className={classes.boxTopRadius} alignItems="center" height={53}>
                <Box flexGrow={1} p={1} display="flex" justifyContent="center" alignItems="center" className={classes.boxTitlePrimary}>
                  <Box p={1} className={classes.titleBar} fontSize={16}>
                    {t('แผนที่ค่าปริมาณไอน้ำ (GNSS-PWV)')}
                  </Box>
                </Box>
                <Box p={1}>
                  <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                    <Button
                      href={`${initPath}weather/vapor/histroy`}
                    >
                      <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                      {/* <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }} noWrap>ดูข้อมูลย้อนหลัง</Typography></Hidden> */}
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
              <Box p={2} display="flex" className={classes.boxBottomRadarRadius} height={height} align="center" alignItems="center" justifyContent="center">
                <Box style={{ display: 'inline-flex', height: '100%' }}>
                  <Button onClick={() => this.setState({ isOpenPWV: true })} height="auto">
                    <Img
                      src="https://pwv.hii.or.th/pwvmap/latest/tn_pwv_latest.png"
                      style={{ width: '100%', height: 'auto' }}
                      alt="ภาพ PWV"
                      // className={classes.responsive}
                      unloader={(
                        <img
                          src="https://via.placeholder.com/300x250?text=No%20Image"
                          width="67%"
                          height="auto"
                          alt="No Data"
                        />
                      )}
                    />
                  </Button>
                  {isOpenPWV && (
                    <Lightbox
                      mainSrc="https://pwv.hii.or.th/pwvmap/latest/pwv_latest.png"
                      imagePadding={50}
                      reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                      onCloseRequest={() => this.setState({ isOpenPWV: false })}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/*  ปิดการแสดงผลเนื่องจากไม่มีภาพจากต้นฉบับมาเป็นเวลานาน */}
          {/* <Grid container direction="row" spacing={4} className={classes.boxDailyMarginTop}>
            <Grid item xs={12} md={12} lg={12}>
              <Box display="flex" mt={3} p={0} className={classes.boxTopRadius} alignItems="center" height={53}>
                <Box flexGrow={1} p={1} display="flex" alignItems="center" style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}>
                  <Box pl={2} className={classes.titleBar}>ภาพฝนสะสมรายวัน</Box>
                </Box>
                <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                  <Tooltip title="ดูข้อมูลย้อนหลัง">
                    <Button
                      className={classes.outlinedOldPrimary}
                      href={`${initPath}weather/rainAccumulation/history`}
                    >
                      <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                      <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}> ดูข้อมูลย้อนหลัง</Typography></Hidden>
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
              <Box width="100%" display="block" className={classes.boxBottomFixibleRadius} align="center">
                <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={0}>
                  { rainAccumulatFilter.map((row, i) => {
                    let realImage = '';
                    let dateTime = '';
                    if (row.media_path !== undefined) {
                      RainAccImageLightbox.push({
                        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                        title: `วันที่ ${moment(row.datetime).format('YYYY-MM-DD')} เวลา : ${moment(row.datetime).format('HH:mm')}`,
                      });
                      realImage = (
                        <CreditTooltip
                          title={imageSource[2].rain_accumulation.title}
                          link={imageSource[2].rain_accumulation.url}
                        >
                          <Button
                            onClick={() => this.setState({ isOpenRainAccImage: true, photoIndex: i })}
                            style={{ padding: 0, paddingBottom: 3 }}
                          >
                            <Img
                              key={uniqueId()}
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                              width="100%"
                              height="100%"
                              alt={moment(row.media_datetime).format('ll')}
                              className={classes.responsive}
                            />
                          </Button>
                        </CreditTooltip>
                      );
                      dateTime = (
                        <Typography className={classes.dateTime} variant="caption">
                          {`วันที่ ${moment(row.datetime).format('YYYY-MM-DD')}`}
                          {' '}
                          {`เวลา : ${moment(row.datetime).format('HH:mm')} UTC`}
                        </Typography>
                      );
                    } else {
                      realImage = (
                        <CreditTooltip
                          title={imageSource[2].rain_accumulation.title}
                          link={imageSource[2].rain_accumulation.url}
                        >
                          <Img
                            key={uniqueId()}
                            src="https://via.placeholder.com/532x290?text=No%20Image"
                            // width="100%"
                            // height="100%"
                            alt="No Image"
                            className={classes.responsive}
                          />
                        </CreditTooltip>
                      );
                      dateTime = (
                        <Typography className={classes.dateTime} variant="caption">
                          {`วันที่ ${moment(row.datetime).format('YYYY-MM-DD')}`}
                          {' '}
                          {'เวลา : - UTC'}
                        </Typography>
                      );
                    }
                    return (
                      <Grid item xs={12} sm md lg key={uniqueId()}>
                        <Box
                          width="100%"
                          align="center"
                          p={{
                            sm: 1, md: 4, lg: 4,
                          }}
                        >
                          {realImage}
                          {dateTime}
                        </Box>
                      </Grid>
                    );
                  })}
                  {isOpenRainAccImage && (
                    <Lightbox
                      mainSrc={RainAccImageLightbox[photoIndex].src}
                      nextSrc={RainAccImageLightbox[(photoIndex + 1)
                      % RainAccImageLightbox.length].src}
                      prevSrc={RainAccImageLightbox[(photoIndex
                      + RainAccImageLightbox.length - 1) % RainAccImageLightbox.length].src}
                      imageTitle={RainAccImageLightbox[photoIndex].title}
                      reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                      onCloseRequest={() => this.setState({ isOpenRainAccImage: false })}
                      onMovePrevRequest={() => (
                        this.setState({
                          photoIndex: (photoIndex
                           + RainAccImageLightbox.length - 1) % RainAccImageLightbox.length,
                        }))}
                      onMoveNextRequest={() => (
                        this.setState({
                          photoIndex: (photoIndex + 1) % RainAccImageLightbox.length,
                        }))}
                    />
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid> */}

          {/* ปิดการใช้งานให้แสดงเฉพาะภาพล่าสุด PWV ตามด้านบน */}
          {/* <Grid container spacing={6}>
            <Grid item xs={12} md={12} lg={12}>
              <Vapor />
            </Grid>
          </Grid> */}

          {/*  แผนภาพฝนสะสมรายวัน */}

          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Precipitation />
            </Grid>
          </Grid>
          {/* Modal raining district */}
          <Grid id="district-anchor" container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Box mt={3}>
                <Tooltip title={t('ระบบติดตามสถานการณ์ฝนรายอำเภอ')}>
                  <Button
                    style={{ width: '100%', height: 'auto', padding: 0 }}
                    onClick={() => window.open(`${process.env.MIX_APP_URL}situation/district`, '_blank')}
                  >
                    <Img
                      src={`${initPath}images/modal/banner/Banner_raining_district.png`}
                      style={{ width: '100%', height: 'auto' }}
                      alt={t('ระบบติดตามสถานการณ์ฝนรายอำเภอ')}
                      className={classes.responsive}
                      unloader={(
                        <Img
                          src="https://via.placeholder.com/204x375?text=No%20Image"
                          alt="No Data"
                          className={classes.responsive}
                        />
                      )}
                    />
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          {/* คาดการณ์ฝนล่วงหน้า 7 วัน  */}
          <Rainforecast />

          {/* Modal rain forecast month */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Box pt={3}>
                <Tooltip title={t('ระบบแสดงแผนที่ฝนคาดการณ์รายเดือน')}>
                  <Button
                    style={{ width: '100%', height: 'auto', padding: 0 }}
                    // onClick={() => window.open('http://www.thaiwater.net/forecast/rainfall_month', '_blank')}
                    onClick={() => window.open(`${process.env.MIX_APP_URL}forecast/rainfall_month`, '_blank')}
                  >
                    <Img
                      src={`${initPath}images/modal/banner/Banner_rain_forecast_month.png`}
                      style={{ width: '100%', height: 'auto' }}
                      alt={t('ระบบแสดงแผนที่ฝนคาดการณ์รายเดือน')}
                      className={classes.responsive}
                      unloader={(
                        <Img
                          src="https://via.placeholder.com/204x375?text=No%20Image"
                          alt="No Data"
                          className={classes.responsive}
                        />
                      )}
                    />
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Box mt={3} mb={0} display="flex" className={classes.boxTopRadius} alignItems="center">
                <Box p={1} pl={4} pr={7} fontFamily="Kanit" fontSize={14} flexGrow={1} fontWeight="fontWeightMedium">
                  {t('ภาพฝนสะสมในช่วงเวลาที่ผ่านมา')}
                </Box>
                <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                  <a target="_blank" rel="noopener noreferrer" href={`${thaiwaterPath}/hydro_report/gallery/index.php`}>
                    <IconButton className={classes.button}>
                      <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                    </IconButton>
                  </a>
                </Box>
              </Box>
              {/* == ภาพฝนสะสมในช่วงเวลาที่ผ่านมา == */}
              <Box p={1} display="flex" className={classes.boxBottomWeatherRadius} align="center" alignItems="center" justifyContent="center">
                <Button onClick={() => this.setState({ isOpen: true })}>
                  <Img
                    src={`${thaiwaterPath}/hydro_report/archive/thailand/rainfall_[thailand][1][st0][sub1]_both_${date.getFullYear() - 1}-01-01_${date.getFullYear() - 1}-12-31.png`}
                    alt={t('ภาพฝนสะสมในช่วงเวลาที่ผ่านมา')}
                    width="70%"
                    className={classes.responsiveWeather}
                  />
                </Button>
                {isOpen && (
                  <Lightbox
                    mainSrc={images[0].src}
                    reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box mt={3} mb={0} display="flex" className={classes.boxTopRadius} alignItems="center">
                <Box p={1} pl={2} fontFamily="Kanit" fontSize={14} flexGrow={1} fontWeight="fontWeightMedium">
                  {t('ภาพกระจายตัวของปริมาณฝน 30 ปีย้อนหลัง')}
                </Box>
                <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                  <IconButton href={`${initPath}weather/rain_dist/30`} className={classes.button}>
                    <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                  </IconButton>
                </Box>
              </Box>
              <Box display="flex" pt={8} pb={4} className={classes.boxBottomWeatherRadius} alignItems="center" justifyContent="center">
                <img src={`${rainAvg30}`} width="50%" alt="thaiwater" className={classes.responsiveImg} />
                {/* <img src={`${rainDist30}`} width="60%" alt="thaiwater" className={classes.responsiveWeather} /> */}
                {/* <Typography className={classes.titleSub} style={{ paddingTop: 15 }}>
                  แผนภาพแสดงการกระจายตัวของ
                </Typography>
                <Typography className={classes.titleSub}>
                  ปริมาณฝน เฉลี่ย 30 ปีย้อนหลัง
                </Typography>
                <Typography className={classes.titleSub}>
                  (ตั้งแต่ พ.ศ. 2524-2553)
                </Typography> */}
              </Box>
            </Grid>
            {/* 2021-01-06 ดร.เจตส์ นน. แจ้งเอาแผนที่ฝน 48 ปี ออกจากระบบเพราะเป็นข้อมูลเก่ามากแล้วอาจทำให้สับสนต่อการใช้งาน */}
            {/* <Grid item xs={12} md={4} lg={4}>
              <Box mt={3} mb={0} display="flex" className={classes.boxTopRadius} alignItems="center" justifyContent="center">
                <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
                  ภาพกระจายตัวของปริมาณฝน 48 ปีย้อนหลัง
                </Box>
                <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                  <IconButton href={`${initPath}weather/rain_dist/48`} className={classes.button}>
                    <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                  </IconButton>
                </Box>
              </Box>
              <Box display="flex" pt={8} pb={4} className={classes.boxBottomWeatherRadius} align="center" alignItems="center" justifyContent="center">
                <img src={`${rainDist48}`} width="80%" alt="thaiwater" className={classes.responsiveWeather} />
                <Typography className={classes.titleSub} style={{ paddingTop: 15 }}>
                  แผนภาพแสดงการกระจายตัวของ
                </Typography>
                <Typography className={classes.titleSub}>
                  ปริมาณฝน เฉลี่ย 48 ปีย้อนหลัง
                </Typography>
                <Typography className={classes.titleSub}>
                  (ตั้งแต่ พ.ศ. 2493-2540)
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
        </div>
      </>
    );
  }
}

WeatherRain.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

// export default withStyles(styles, { withTheme: true })(WeatherRain);
export default compose(
  withWidth(), withStyles(styles, { withTheme: true }),
  withTranslation(),
)(WeatherRain);
