/* eslint-disable no-nested-ternary */
/**
 * *
 * @version 1.0.0
 * @author [Permporn]
 * Modify by Thitiporn
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  flow, uniqueId, groupBy, orderBy,
} from 'lodash/fp';

import { withStyles } from '@material-ui/core/styles';
import {
  Link,
} from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { SEO } from '../../../utils/Seo';

// ui
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Img from 'react-image';
import grey from '@material-ui/core/colors/grey';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Tooltip from '@material-ui/core/Tooltip';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { Hidden } from '@material-ui/core';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import { isEmpty } from 'lodash';
import {
  FaHome, FaSpinner,
} from '../../../utils/Fontawesome';

import Animation from '../../forecast/Animation';
// import 'moment/locale/ja';

import {
  styles, BootstrapInput,
} from '../../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
let showImg = '';

const weatherCondition = [
  { key: 2, value: 'อุณหภูมิ' },
  { key: 1, value: 'ความชื้น' },
  { key: 3, value: 'ความกดอากาศ' },
];
const mapWeaterCondition = [
  { key: 22, value: 'แผนที่อากาศ' },
  { key: 28, value: 'แผนที่ลมชั้นบนระดับ 850 hPa' },
  { key: 29, value: 'แผนที่ลมชั้นบนระดับ 925 hPa' },
];
const vegetationCondition = [
  { key: 37, value: 'TERRA' },
  { key: 36, value: 'AQUA' },
];
const soilMoistureCondition = [
  { key: 160, value: 'AFWA - Surface' },
  { key: 161, value: 'AFWA - Sub-Surface' },
  { key: 162, value: 'WMO - Surface' },
  { key: 163, value: 'WMO - Sub-Surface' },
];
const seaSurfaceTemperatureImageCondition = [
  { key: 143, value: 'ประเทศไทย' },
  { key: 144, value: 'มหาสมุทรแปซิฟิคเหนือ' },
  { key: 145, value: 'มหาสมุทรอินเดีย' },
  { key: 146, value: 'ทั่วโลก' },
];

const cloudCondition = [
  { key: 52, value: 'Digital Typhoon' },
  { key: 50, value: 'Kochi University' },
];

const stormPathCondition = [
  { key: 62, value: ['latest', 'history'] },
];
// cloud mtsat-2 kochi
// const cloudImageCondition = [
//   { key: 141, value: ['latest', 'history'] },
// ];
const rainRallCondition = [
  { key: 180, value: ['latest', 'history'] },
];
const waterLevelCondition = [
  { key: 175, value: ['latest', 'history'] },
];

// array by media type id
const mediaType = [];
mediaType[1] = { title: 'แผนที่แสดงการกระจายตัวความชื้น', thumbSize: { w: 232, h: 377 }, option: weatherCondition };
mediaType[2] = { title: 'แผนที่แสดงการกระจายตัวอุณหภูมิ', thumbSize: { w: 232, h: 377 }, option: weatherCondition };
mediaType[3] = { title: 'แผนที่แสดงการกระจายตัวความกดอากาศ', thumbSize: { w: 232, h: 377 }, option: weatherCondition };
mediaType[22] = { title: 'แผนที่อากาศ', thumbSize: { w: 232, h: 154 }, option: mapWeaterCondition }; // tmd weather map
mediaType[28] = { title: 'แผนที่ลมชั้นบนระดับ 850 hPa', thumbSize: { w: 232, h: 180 }, option: mapWeaterCondition }; // tmd
mediaType[29] = { title: 'แผนที่ลมชั้นบนระดับ 925 hPa', thumbSize: { w: 232, h: 180 }, option: mapWeaterCondition }; // tmd
mediaType[36] = { title: 'ค่าดัชนีพืชพรรณจากภาพถ่ายดาวเทียม AQUA', thumbSize: { w: 232, h: 180 }, option: vegetationCondition }; // AQUA
mediaType[37] = { title: 'ค่าดัชนีพืชพรรณจากภาพถ่ายดาวเทียม TERRA', thumbSize: { w: 232, h: 180 }, option: vegetationCondition }; // TERRA
mediaType[62] = { title: 'เส้นทางพายุ', thumbSize: { w: 232, h: 258 }, option: stormPathCondition };
mediaType[141] = { title: 'ภาพเมฆ', thumbSize: { w: 232, h: 170 }, option: cloudCondition }; // kochi
mediaType[160] = { title: 'ค่าความชื้นในดิน ของ AFWA - Surface', thumbSize: { w: 232, h: 397 }, option: soilMoistureCondition }; // AFWA - Surface
mediaType[161] = { title: 'ค่าความชื้นในดิน ของ AFWA - Sub-Surface', thumbSize: { w: 232, h: 397 }, option: soilMoistureCondition }; // AFWA - Sub-Surface
mediaType[162] = { title: 'ค่าความชื้นในดิน ของ WMO - Surface', thumbSize: { w: 232, h: 397 }, option: soilMoistureCondition }; // WMO  - Surface
mediaType[163] = { title: 'ค่าความชื้นในดิน ของ WMO - Sub-Surface', thumbSize: { w: 232, h: 397 }, option: soilMoistureCondition }; // WMO  - Sub-Surface
mediaType[175] = { title: 'ภาพระดับน้ำ', thumbSize: { w: 232, h: 390 }, option: waterLevelCondition }; // generate water level image
mediaType[180] = { title: 'ภาพปริมาณฝนสะสม 24 ชั่วโมง', thumbSize: { w: 232, h: 390 }, option: rainRallCondition }; // generate rainfall image

mediaType[143] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล ประเทศไทย', thumbSize: { w: 232, h: 172 }, option: seaSurfaceTemperatureImageCondition };
mediaType[144] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล มหาสมุทรแปซิฟิค', thumbSize: { w: 232, h: 172 }, option: seaSurfaceTemperatureImageCondition };
mediaType[145] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล ทั่วโลก', thumbSize: { w: 232, h: 172 }, option: seaSurfaceTemperatureImageCondition };
mediaType[146] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล มหาสมุทรอินเดีย', thumbSize: { w: 232, h: 172 }, option: seaSurfaceTemperatureImageCondition };

// lightbox
let images = [];

const currentDate = new Date(moment());

class WeatherImageHistory extends Component {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      datas: [],
      selectedDate: '',
      yearOfmonth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      datePickerType: 'date',
      title: '',
      yearTab: '',
      valueDropDown: '',
      cumulativeRainfallStatus: false,
      imageWaterLevelStatus: false,
      viewLatestImage: false,
      viewAnimationImage: false,
      rainImage: '',
      isOpenWlImage: false,
      wlImage: '',
      isOpenRainImage: false,
      displayState: 'history',
      stormAnimationCloud: null,
    };
    // this.handleChange = this.handleChange.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
  }

  componentDidMount() {
    const { match: { params: { date, agencyId, mediaTypeId } } } = this.props;
    let selecDate;
    if (date) {
      selecDate = date;
    } else {
      selecDate = moment().format('YYYY-MM-DD');
    }
    this.setState({
      isLoading: true,
      selectedDate: selecDate,
      valueDropDown: mediaTypeId, // สำหรับตั้งค่า value dropdown เมื่อกดเข้ามาใน history
    });
    this.fetchStormAnimation();
    this.fetchData(selecDate, agencyId, mediaTypeId);
  }

  handleDateChange = date => {
    const { match: { params: { agencyId, mediaTypeId } } } = this.props;
    let selectedDate = moment().format('YYYY-MM-DD');
    if (date) {
      selectedDate = moment(date).format('YYYY-MM-DD');
    }
    this.setState({
      isLoading: true,
      selectedDate,
    });
    this.fetchData(selectedDate, agencyId, mediaTypeId);
  };

  handleDateChangeButton = change => {
    const { match: { params: { agencyId, mediaTypeId } } } = this.props;
    let { selectedDate } = this.state;
    const { datePickerType } = this.state;
    let datetype = 'days';

    if (datePickerType !== 'date' && !isEmpty(datePickerType)) {
      datetype = datePickerType;
    }
    if (change === '1') {
      selectedDate = moment(selectedDate).add(1, datetype).format('YYYY-MM-DD');
    } else if (change === '-1') {
      selectedDate = moment(selectedDate).add(-1, datetype).format('YYYY-MM-DD');
    }
    this.setState({
      isLoading: true,
      selectedDate,
    });
    this.fetchData(selectedDate, agencyId, mediaTypeId);
  }

  // เมื่อเปลี่ยนค่า dropdown ให้ตั้งค่า value ที่ dropdown ใหม่ และ ferch data ใหม่
  // handleChange(event) {
  //   const { value } = event.target;
  //   const { match: { params: { agencyId } } } = this.props;
  //   const { selectedDate } = this.state;
  //   this.setState({ valueDropDown: value });
  //   this.fetchData(selectedDate, agencyId, value);
  // }

  handleDropDownChange(event) {
    const { history, match: { params: { agencyId, mediaTypeId } } } = this.props;
    const pathName = history.location.pathname.split('/');
    const { value } = event.target;
    const { selectedDate } = this.state;
    this.setState({ valueDropDown: value });
    let url = '';

    if (mediaTypeId === '141') {
      // cloud kochi, digital typhoon
      // http://localhost1/thaiwater_v4/weather/2022-04-05/50/141
      // http://localhost1/thaiwater_v4/weather/2022-04-05/52/141
      const varAgencyId = value;
      this.fetchData(selectedDate, varAgencyId, mediaTypeId);
      url = `${process.env.MIX_APP_PATH}weather/${selectedDate}/${varAgencyId}/${mediaTypeId}`;
    } else if (mediaTypeId === '160'
      || mediaTypeId === '161'
      || mediaTypeId === '162'
      || mediaTypeId === '163'
      || mediaTypeId === '1'
      || mediaTypeId === '2'
      || mediaTypeId === '3'
      || mediaTypeId === '28'
      || mediaTypeId === '29'
      || mediaTypeId === '143'
      || mediaTypeId === '144'
      || mediaTypeId === '145'
      || mediaTypeId === '146'
      || mediaTypeId === '22'
      || mediaTypeId === '37'
      || mediaTypeId === '36'
    ) {
      // example : http://wwww.thaiwater.net/weather/2022-03/55/162
      // http://wwww.thaiwater.net/weather/2022-03/55/161
      // value = media type id
      const varMediaTypeId = value;
      this.fetchData(selectedDate, agencyId, varMediaTypeId);
      url = `${process.env.MIX_APP_PATH}weather/${selectedDate}/${agencyId}/${varMediaTypeId}`;
    } else {
      this.fetchData(selectedDate, agencyId, value);
      url = `${process.env.MIX_APP_PATH}weather/${pathName[4]}/${pathName[5]}/${value}`;
    }
    history.push(url);
  }

  // get antimation image from service storm india
  fetchStormAnimation = () => {
    fetch(`${process.env.MIX_API_URL}public/weather_img/animation?media_type_id=141&agency_id=50`)
      .then(response => {
        if (response.ok) {
          this.setState({ isLoading: true });
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({ stormAnimationCloud: result.data.media_path });
      })
      .catch(error => this.setState({ error }));
  }

  fetchData = (selectedDate, agencyId, mediaTypeId) => {
    const { t } = this.props;
    const date1 = moment(selectedDate);
    const startDate = date1.add(-1, 'days');
    const date2 = moment(selectedDate);
    const endDate = date2.add(2, 'days');
    let mTypeId;
    // date
    if (mediaTypeId === '1' || mediaTypeId === '2' || mediaTypeId === '3' || mediaTypeId === '175' || mediaTypeId === '180' || (mediaTypeId === '141' && agencyId === '52') || (mediaTypeId === '141' && agencyId === '50')) {
      const url = `${process.env.MIX_API_URL}public/weather_history_img/date?agency_id=${agencyId}&media_type_id=${mediaTypeId}&date=${selectedDate}`;

      fetch(url)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          if (agencyId === '50' || agencyId === '52') {
            // agency 50 = kochi
            // พบปัญหาภาพแสดงไม่ครบ 24 ภาพใน 1 วัน
            // datas: result.data.filter(d
            //  => (moment(d.media_datetime).format('YYYY-MM-DD') === selectedDate)),
            this.setState({
              isLoading: false,
              datas: result.data,
              title: t(mediaType[mediaTypeId].title),
            });
          } else {
            this.setState({
              isLoading: false,
              datas: result.data,
              title: t(mediaType[mediaTypeId].title),
            });
            return null;
          }
          return null;
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    // date_range
    // tmd weather map
    if (mediaTypeId === '22' || mediaTypeId === '28' || mediaTypeId === '29') {
      fetch(`${process.env.MIX_API_URL}public/weather_history_img/date_range?agency_id=${agencyId}&media_type_id=${mediaTypeId}&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          this.setState({
            isLoading: false,
            datas: result.data.filter(d => (moment(d.media_datetime).format('YYYY-MM-DD') === selectedDate)),
            title: t(mediaType[mediaTypeId].title),
          });
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    // month
    if (mediaTypeId === '36' || mediaTypeId === '37' || mediaTypeId === '153' || mediaTypeId === '140' || mediaTypeId === '142' || mediaTypeId === '14' || mediaTypeId === '149' || mediaTypeId === '150' || mediaTypeId === '151' || mediaTypeId === '152' || mediaTypeId === '173' || mediaTypeId === '62' || mediaTypeId === '27' || (mediaTypeId === '141' && agencyId === '51') || mediaTypeId === '157' || mediaTypeId === '158' || mediaTypeId === '159' || mediaTypeId.split('_')[0] === '143' || mediaTypeId.split('_')[0] === '144' || mediaTypeId.split('_')[0] === '145' || mediaTypeId.split('_')[0] === '146' || mediaTypeId === '5' || mediaTypeId === '6' || mediaTypeId === '4' || mediaTypeId.split('_')[0] === '155' || mediaTypeId === '185' || mediaTypeId === '187' || mediaTypeId === '189') {
      let agent;
      if (mediaTypeId === '62') {
        // storm ucl
        agent = 41;
      } else {
        agent = agencyId;
      }
      if (mediaTypeId.split('_')[0] === '143' || mediaTypeId.split('_')[0] === '144' || mediaTypeId.split('_')[0] === '145' || mediaTypeId.split('_')[0] === '146' || mediaTypeId.split('_')[0] === '155') {
        // eslint-disable-next-line prefer-destructuring
        mTypeId = mediaTypeId.split('_')[0];
      } else {
        mTypeId = mediaTypeId;
      }
      if (mediaTypeId === '141' && agencyId === '50') {
        agent = '52';
      } else {
        agent = agencyId;
      }

      fetch(`${process.env.MIX_API_URL}public/weather_history_img/month?agency_id=${agent}&media_type_id=${mTypeId}&year=${selectedDate.split('-')[0]}&month=${selectedDate.split('-')[1]}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          const resultAll = [];
          if (mediaTypeId === '141' && agent === '51') {
            const map = new Map();
            result.data.map((item, i) => {
              if (!map.has(item.media_path)) {
                map.set(item.media_path, true);
                resultAll.push({
                  id: i,
                  filepath: item.filepath,
                  filename: item.filename,
                  media_datetime: item.media_datetime,
                  media_path: item.media_path,
                  thumbnail_filename: item.thumbnail_filename,
                  thumbnail_filepath: item.thumbnail_filepath,
                  thumbnail_media_path: item.thumbnail_media_path,
                });
              }
              return false;
            });
            this.setState({
              isLoading: false,
              datas: resultAll.filter(d => (moment(d.media_datetime).format('YYYY-MM-DD') === selectedDate)),
              datePickerType: 'date',
              title: result.data[0].description,
            });
          } else if (mediaTypeId === '62') {
            // ภาพเส้นทางพายุ ucl
            const groupDatas = flow(
              groupBy(d => moment(d.media_datetime).format('YYYY-MM-DD')),
            )(result.data);

            const groupData = Object.entries(groupDatas)
              .map(([key, val]) => ({ date: key, datas: val }));

            this.setState({
              isLoading: false,
              datas: groupData,
              datePickerType: 'month',
              title: t(mediaType[mediaTypeId].title),
            });
          } else if (mediaTypeId === '27') {
            result.data.map((item, i) => {
              const dateTime = `${((item.filename.split('_')[1]).split('.')[0]).substring(0, 4)}-${((item.filename.split('_')[1]).split('.')[0]).substring(4, 6)}-${((item.filename.split('_')[1]).split('.')[0]).substring(6, 8)} ${((item.filename.split('_')[1]).split('.')[0]).substring(8, 11)}:00`;
              resultAll.push({
                id: i,
                filepath: item.filepath,
                filename: item.filename,
                media_datetime: moment(dateTime, 'YYYY-MM-DD HH:mm').add(7, 'hour').format('YYYY-MM-DD HH:mm'),
                media_path: item.media_path,
                thumbnail_filename: item.thumbnail_filename,
                thumbnail_filepath: item.thumbnail_filepath,
                thumbnail_media_path: item.thumbnail_media_path,
              });
              return false;
            });
            this.setState({
              isLoading: false,
              datas: resultAll.filter(d => (moment(d.media_datetime).format('YYYY-MM-DD') === selectedDate)),
              datePickerType: 'date',
              title: result.data[0].description,
            });
          } else if (mTypeId === '143' || mediaTypeId.split('_')[0] === '144' || mediaTypeId.split('_')[0] === '145' || mediaTypeId.split('_')[0] === '146' || mTypeId === '155') {
            result.data.map((item, i) => {
              resultAll.push({
                id: i,
                filepath: item.filepath,
                filename: item.filename,
                media_datetime: item.media_datetime.split(' ')[0],
                media_path: item.media_path,
                thumbnail_filename: item.thumbnail_filename,
                thumbnail_filepath: item.thumbnail_filepath,
                thumbnail_media_path: item.thumbnail_media_path,
              });
              return false;
            });
            this.setState({
              isLoading: false,
              datas: resultAll,
              datePickerType: 'month',
              title: t(mediaType[mediaTypeId].title),
            });
          } else if (mediaTypeId === '5' || mediaTypeId === '6' || mediaTypeId === '4') {
            result.data.map((item, i) => {
              const dateTime = (item.filename.split('_')[5]).split('.')[0];
              const dateTimeT = `${dateTime.substring(0, 4)}-${dateTime.substring(4, 6)}-${dateTime.substring(6, 8)} ${dateTime.substring(8, 10)}:${dateTime.substring(10, 12)}`;
              let thumbnail = item.thumbnail_media_path;
              if (thumbnail === '') {
                thumbnail = item.media_path;
              }
              resultAll.push({
                id: i,
                filepath: item.filepath,
                filename: item.filename,
                media_datetime: moment(dateTimeT, 'YYYY-MM-DD HH:mm').add(7, 'hour').format('YYYY-MM-DD HH:mm'),
                media_path: item.media_path,
                thumbnail_filename: item.thumbnail_filename,
                thumbnail_filepath: item.thumbnail_filepath,
                thumbnail_media_path: thumbnail,
              });
              return false;
            });
            this.setState({
              isLoading: false,
              datas: resultAll.filter(d => (moment(d.media_datetime.split(' ')[0]).format('YYYY-MM-DD') === selectedDate)),
              datePickerType: 'date',
              title: result.data[0].description,
            });
          } else if (agent === '52') {
            const map = new Map();
            result.data.map((item, i) => {
              if (!map.has(item.media_path)) {
                map.set(item.media_path, true);
                resultAll.push({
                  id: i,
                  filepath: item.filepath,
                  filename: item.filename,
                  media_datetime: item.media_datetime.split(' ')[0],
                  media_path: item.media_path,
                  thumbnail_filename: item.thumbnail_filename,
                  thumbnail_filepath: item.thumbnail_filepath,
                  thumbnail_media_path: item.thumbnail_media_path,
                });
              }
              return false;
            });
            this.setState({
              isLoading: false,
              datas: resultAll.filter(d => (moment(d.media_datetime).format('YYYY-MM-DD') === selectedDate)),
              datePickerType: 'date',
              title: result.data[0].description,
            });
          } else {
            this.setState({
              isLoading: false,
              datas: result.data,
              datePickerType: 'month',
              title: t(mediaType[mediaTypeId].title),
            });
          }
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    // 12moth
    if (mediaTypeId === '160' || mediaTypeId === '161' || mediaTypeId === '162' || mediaTypeId === '163') {
      const agent = agencyId;
      mTypeId = mediaTypeId;
      const arrayMonth = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      const arrayData = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const element of arrayMonth) {
        fetch(`${process.env.MIX_API_URL}public/weather_history_img/month?agency_id=${agent}&media_type_id=${mTypeId}&year=${selectedDate.split('-')[0]}&month=${element}`)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Something went wrong ...');
          })
          .then(result => {
            if (result.data.length > 0) {
              result.data.forEach(elementData => {
                arrayData.push(elementData);
              });
            }
            this.setState({
              isLoading: false,
              datas: arrayData,
              datePickerType: 'year',
              title: t(mediaType[mediaTypeId].title),
              // eslint-disable-next-line react/no-unused-state
              yearTab: selectedDate.split('-')[0],
            });
          })
          .catch(error => this.setState({ error, isLoading: false }));
      }
    }

    if (mediaTypeId === '180' || mediaTypeId === '175' || mediaTypeId === '176' || mediaTypeId === '186' || mediaTypeId === '188') {
      fetch(`${process.env.MIX_API_URL}public/weather_img/image_generate`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          const link = `${process.env.MIX_APP_PATH}weather/${moment(result.data[2][0].datetime).format('YYYY-MM-DD')}/64/180`;
          const rainImage = {
            src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[2][0].media_path}`,
            dateTime: moment(result.data[2][0].datetime).format('LLL'),
            link,
          };
          const wlImage = {
            src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[1][0].media_path}`,
            dateTime: moment(result.data[1][0].datetime).format('LLL'),
            link,
          };
          this.setState({
            rainImage,
            wlImage,
            isLoading: false,
          });
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }
  }

  render() {
    images = [];
    const {
      match: { params: { mediaTypeId, agencyId } },
      classes,
      // theme,
      t,
      i18n,
    } = this.props;
    const {
      isLoading,
      error,
      datas,
      photoIndex,
      isOpen,
      selectedDate,
      datePickerType,
      title,
      valueDropDown,
      yearOfmonth,
      yearTab,
      cumulativeRainfallStatus,
      rainImage,
      isOpenRainImage,
      isOpenWlImage,
      displayState,
      imageWaterLevelStatus,
      wlImage,
      viewLatestImage,
      viewAnimationImage,
      stormAnimationCloud,
    } = this.state;
    let currentIndex = 0;
    const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;
    // moment.locale(languageI18n);

    const breadCrumbsPathLevel3 = () => {
      if (mediaTypeId === '62' || mediaTypeId === '141') {
        return (
          <Link to={`${initPath}weather/storm`}>
            <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
              {t('แผนที่วิเคราะห์เส้นทางและความแรงของพายุ')}
            </Typography>
          </Link>
        );
      }
      return null;
    };

    const breadCrumbsPathLevel2 = () => {
      if (mediaTypeId === '180') {
        return (
          <Link to={`${initPath}weather/rainfall`}>
            <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
              {t('ฝน')}
            </Typography>
          </Link>
        );
      } if (mediaTypeId === '175') {
        return (
          <Link to={`${initPath}water/wl`}>
            <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
              {t('ระดับน้ำ')}
            </Typography>
          </Link>
        );
      } if (mediaTypeId === '62' || mediaTypeId === '141') {
        return (
          <Link to={`${initPath}weather/storm`}>
            <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
              {t('พายุ')}
            </Typography>
          </Link>
        );
      }
      if (mediaTypeId === '185' || mediaTypeId === '187' || mediaTypeId === '189') {
        return (
          <Link to={`${initPath}weather/water`}>
            <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
              {t('อ่างเก็บน้ำ')}
            </Typography>
          </Link>
        );
      }
      if (mediaTypeId === '143' || mediaTypeId === '144' || mediaTypeId === '145' || mediaTypeId === '146') {
        return (
          <Link to={`${initPath}weather/sea`}>
            <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
              {t('ทะเล')}
            </Typography>
          </Link>
        );
      }
      return (
        <Link to={`${initPath}weather`}>
          <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
            {t('อากาศ')}
          </Typography>
        </Link>
      );
    };
    const breadCrumbsPathLevel1 = () => {
      if (mediaTypeId === '175') {
        return (
          <Link to={`${initPath}water/wl`}>
            <FaHome className={classes.iconSub} />
            <Typography
              variant="h6"
              color="textSecondary"
              display="inline"
              style={{ fontSize: 14 }}
            >
              {' '}
              {t('ติดตามสถานการณ์น้ำ')}
            </Typography>
          </Link>
        );
      }
      return (
        <Link to={`${initPath}weather`}>
          <FaHome className={classes.iconSub} />
          <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
            {' '}
            {t('ติดตามสภาพอากาศ')}
          </Typography>
        </Link>
      );
    };

    const dateTimeFormatLightbox = (value, id) => { 
      if (
        id === '3'
        || id === '2'
        || id === '1'
        || id === '28'
        || id === '29') {
        // ข้อมูลรายวัน
        // แสดง วันที่ เวลา
        if (['1', '2', '3'].includes(id)) {
          return `${moment.utc(value).locale(languageI18n).format('LLL')} ${t('น.')}`;
        }
        return `${moment(value).locale(languageI18n).format('LLL')} ${t('น.')}`;
        // return `${moment(value).utc().format('LLL')} น.`;
      }
      if (id === '160'
        || id === '161'
        || id === '162'
        || id === '163'
        || id === '37'
        || id === '36'
        || id === '62'
        || id === '141'
        || id === '143'
        || id === '144'
        || id === '145'
        || id === '146'
      ) {
        // ข้อมูลรายเดือน/รายปี
        // แสดงวันที่
        return `${moment(value).format('LL')}`;
      }
      // 2023-10-24 แก้ไขแสดงผลวันที่เป็นแบบ UTC ตามรูปภาพแผนที่อากาศ https://www.thaiwater.net/weather/2023-10-20/13/22
      if (id === '22') {
        return `${moment(value).utc().format('LL')}`;
      }
      return value;
    };

    const dropdownWindMap = () => mediaType[mediaTypeId].option.map((row, i) => {
      if (i !== 0) {
        return (
          <option
            key={uniqueId()}
            fontFamily="Kanit"
            value={row.key}
            style={{ fontFamily: 'Kanit' }}
          >
            {t(t(row.value))}
          </option>
        );
      }
      return null;
    });

    const dropdownWeatherMap = () => mediaType[mediaTypeId].option.map((row, i) => {
      if (i === 0) {
        return (
          <option
            key={uniqueId()}
            fontFamily="Kanit"
            value={row.key}
            style={{ fontFamily: 'Kanit' }}
          >
            {t(row.value)}
          </option>
        );
      }
      return null;
    });

    const dropdownCloud = () => mediaType[mediaTypeId].option.map(row => (
      <option
        key={uniqueId()}
        fontFamily="Kanit"
        value={row.key}
        style={{ fontFamily: 'Kanit' }}
      >
        {t(row.value)}
      </option>
    ));

    // 2023-11-07 แก้ไข format month ให้มี 2 digit เพื่อรองรับ browser safari บน ipad, iphone, macOS
    const convertFormatDate = (year, month) => {
      const paddedMonth = String(month).padStart(2, '0');
      return year ? moment(`${yearTab}-${paddedMonth}-01`).format('MMMM YYYY') : false;
    };

    return (
      <>
        {isLoading === true && (
          <FaSpinner size={25} />
        )}

        {error && (
          <div className="text-center">{error.message}</div>
        )}

        <SEO 
          title={title + ' ' + selectedDate}
          description={title + ' ' + selectedDate}
          keywords={title}
        />
        
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                {breadCrumbsPathLevel1()}
                {breadCrumbsPathLevel2()}
                {breadCrumbsPathLevel3()}
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t(title)}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 24 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" className={classes.boxTopRadius}>
                <Box flexGrow={1} p={1} display="flex">
                  <Box className={classes.titleName}>
                    {t(title)}
                  </Box>
                </Box>
                {/* select dropdown */}
                {
                  mediaTypeId === '1'
                    || mediaTypeId === '2'
                    || mediaTypeId === '3'
                    || mediaTypeId === '22'
                    || mediaTypeId === '28'
                    || mediaTypeId === '29'
                    || mediaTypeId === '37'
                    || mediaTypeId === '36'
                    || mediaTypeId === '160'
                    || mediaTypeId === '161'
                    || mediaTypeId === '162'
                    || mediaTypeId === '163'
                    || mediaTypeId === '141'
                    || mediaTypeId === '143'
                    || mediaTypeId === '144'
                    || mediaTypeId === '145'
                    || mediaTypeId === '146' ? (
                      <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <NativeSelect
                            value={valueDropDown}
                            onChange={this.handleDropDownChange}
                            input={<BootstrapInput name="region" id="regend-customized-native-simple" className={classes.boxBottom} />}
                          >
                            {/* dropdown หน้า history ของกลุ่มข้อมูลแผนที่ลม */}
                            {(mediaTypeId === '28' || mediaTypeId === '29') && (dropdownWindMap())}
                            {/* dropdown หน้า history ของกลุ่มข้อมูลแผนที่ความกดอากาศ */}
                            {(mediaTypeId === '22') && (dropdownWeatherMap())}
                            {/* dropdown หน้า history ของกลุ่มข้อมูลภาพเมฆ */}
                            {(mediaTypeId === '141'
                              || mediaTypeId === '160'
                              || mediaTypeId === '161'
                              || mediaTypeId === '162'
                              || mediaTypeId === '163'
                              || mediaTypeId === '143'
                              || mediaTypeId === '144'
                              || mediaTypeId === '145'
                              || mediaTypeId === '146'
                              || mediaTypeId === '1'
                              || mediaTypeId === '2'
                              || mediaTypeId === '3'
                              || mediaTypeId === '37'
                              || mediaTypeId === '36'
                            ) && (dropdownCloud())}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    ) : (
                      <>
                        {/* tab button menu lasted and history */}
                        <Box style={{ borderLeft: '1px solid #ccc' }}>
                          {mediaType[mediaTypeId].option.map(row => (
                            <Tooltip title={t('ดูภาพล่าสุด')}>
                              <Button
                                className={classes.outlinedOldPrimary}
                                color={displayState === row.value[0] ? 'primary' : 'default'}
                                variant={displayState === row.value[0] ? 'contained' : 'text'}
                                onClick={
                                  () => this.setState({
                                    viewLatestImage: true,
                                    displayState: row.value[0],
                                  })
                                }
                              >
                                <ImageOutlinedIcon fontSize="small" />
                                <Hidden xsDown>
                                  <Typography variant="h6" style={{ marginLeft: 8 }}>
                                    {t('ดูภาพล่าสุด')}
                                  </Typography>
                                </Hidden>
                              </Button>
                            </Tooltip>
                          ))}
                        </Box>
                        <Box style={{ borderLeft: '1px solid #ccc' }}>
                          {mediaType[mediaTypeId].option.map(row => (
                            <Tooltip title={t('ดูภาพย้อนหลัง')}>
                              <Button
                                className={classes.outlinedOldRightPrimary}
                                color={displayState === row.value[1] ? 'primary' : 'default'}
                                variant={displayState === row.value[1] ? 'contained' : 'text'}
                                onClick={
                                  () => this.setState({
                                    viewLatestImage: false,
                                    displayState: row.value[1],
                                  })
                                }
                              >
                                <PhotoLibraryOutlinedIcon fontSize="small" />
                                <Hidden xsDown>
                                  <Typography variant="h6" style={{ marginLeft: 8 }}>
                                    {t('ดูภาพย้อนหลัง')}
                                  </Typography>
                                </Hidden>
                              </Button>
                            </Tooltip>
                          ))}
                        </Box>
                      </>
                    )
                }
              </Box>
            </Grid>
          </Grid>

          {!viewLatestImage && !viewAnimationImage && (
            <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
              <Grid item><Button onClick={() => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
              <Grid item xs sm={4} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  {(datePickerType === 'date' && !cumulativeRainfallStatus && !imageWaterLevelStatus) && (
                    <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        format="LL"
                        value={selectedDate}
                        views={['year', 'month', 'date']}
                        maxDate={currentDate}
                        onChange={this.handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  {datePickerType === 'month' && (
                    <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        openTo="month"
                        views={['year', 'month']}
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        maxDate={currentDate}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  {datePickerType === 'year' && (
                    <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        openTo="year"
                        views={['year']}
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        maxDate={currentDate}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </FormControl>
              </Grid>
              <Grid item><Button onClick={() => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
            </Grid>
          )}

          {/* ไม่พบข้อมูลในช่วงเวลาที่เลือก */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              {datas.length <= 0
                && (
                  <Typography variant="h6">
                    {t('ไม่พบข้อมูลในช่วงเวลาที่เลือก')}
                  </Typography>
                )}
            </Grid>
          </Grid>

          {
            // เส้นทางพายุย้อนหลัง และดูภาพล่าสุด
            (mediaTypeId === '62') ? (
              <>
                {viewLatestImage ? (
                  <>
                    {datas.slice(0, 1).map(item => (
                      <Grid container spacing={4} direction="row" justifyContent="center" key={uniqueId()}>
                        {item.datas
                          .map((row, index) => {
                            if (index < 2) {
                              const currentImgIndex = currentIndex;
                              let realImage = '';
                              if (row.media_path !== null) {
                                images.push({ src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`, title: row.media_datetime });
                                realImage = (
                                  <Button
                                    key={uniqueId()}
                                    onClick={() => this.setState({
                                      isOpen: true,
                                      photoIndex: currentImgIndex,
                                    })}
                                  >
                                    <Img
                                      src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                                      className={classes.responsive}
                                      alt={row.datetime}
                                      key={uniqueId()}
                                    />
                                  </Button>
                                );
                                currentIndex += 1;
                              } else {
                                realImage = (
                                  <Img
                                    src="https://placehold.co/580x310.png?text=No+Data"
                                    alt="No Data"
                                    key={uniqueId()}
                                    className={classes.responsive}
                                  />
                                );
                              }
                              return (
                                <Grid item xs={12} sm={6} md={6} lg={6} key={uniqueId()}>
                                  <Box display="block" alignItems="center" justifyContent="center" className={classes.boxStormHistory}>
                                    <Box align="center" key={uniqueId()}>
                                      {realImage}
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            }
                            return null;
                          })}
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {datas.map(item => (
                      <Grid key={uniqueId()}>
                        <Grid container spacing={4} key={uniqueId()}>
                          <Grid item xs key={uniqueId()}>
                            <Box p={1} pb={1} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                              {moment(item.date).format('LL')}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4} direction="row" justify="center" key={uniqueId()}>
                          {item.datas
                            .map((row, index) => {
                              if (index < 2) {
                                const currentImgIndex = currentIndex;
                                let realImage = '';
                                if (row.media_path !== null) {
                                  images.push({ src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`, title: row.media_datetime });
                                  realImage = (
                                    <Button
                                      key={uniqueId()}
                                      onClick={() => this.setState({
                                        isOpen: true,
                                        photoIndex: currentImgIndex,
                                      })}
                                    >
                                      <Img
                                        src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                                        className={classes.responsive}
                                        alt={row.datetime}
                                        key={uniqueId()}
                                      />
                                    </Button>
                                  );
                                  currentIndex += 1;
                                } else {
                                  realImage = (
                                    <Img
                                      src="https://placehold.co/580x310.png?text=No+Data"
                                      alt="No Data"
                                      key={uniqueId()}
                                      className={classes.responsive}
                                    />
                                  );
                                }
                                return (
                                  <Grid item xs={12} sm={6} md={6} lg={6} key={uniqueId()}>
                                    <Box display="block" alignItems="center" justifyContent="center" className={classes.boxStormHistory}>
                                      <Box align="center" key={uniqueId()}>
                                        {realImage}
                                      </Box>
                                    </Box>
                                  </Grid>
                                );
                              }
                              return null;
                            })}
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </>
            ) : null
          }

          {
            // ดูภาพเมฆล่าสุด
            (mediaTypeId === '141' && (viewAnimationImage || viewLatestImage)) ? (
              <Grid container justifycen="center" spacing={4} style={{ marginTop: 20 }}>
                {viewAnimationImage ? (
                  <Grid item xs={12} sm={12} md={6} style={{ border: '1px solid #ccc' }}>
                    <Box p={1} align="center">
                      <Animation media={stormAnimationCloud} />
                      <Box pt={3}>
                        {orderBy(datas, ['media_datetime'], ['desc']).filter(row => row.media_path)
                          .slice(0, 1)
                          .map(row => {
                            if (row.media_datetime) {
                              return (
                                <Typography className={classes.dateTime}>
                                  {moment(row.media_datetime).format('LLL')}
                                  {' '}
                                  UTC
                                </Typography>
                              );
                            }
                            return null;
                          })}
                      </Box>
                    </Box>
                  </Grid>
                ) : viewLatestImage ? (
                  <Grid item xs={12} sm={12} md={6} align="center" style={{ border: '1px solid #ccc' }}>
                    {orderBy(datas, ['media_datetime'], ['desc']).filter(row => row.media_path)
                      .slice(0, 1)
                      .map(row => {
                        showImg = (row.media_path !== null) ? row.media_path : row.media_path_thumb;
                        if (showImg !== null) {
                          const currentImgIndex = currentIndex;
                          images.push({
                            src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`,
                            title: row.media_datetime,
                            // title: timeShow,
                          });
                          return (
                            <Box key={uniqueId()}>
                              <Button
                                onClick={() => this.setState({
                                  isOpen: true,
                                  photoIndex: currentImgIndex,
                                })}
                              >
                                <Img
                                  src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`}
                                  // width={640}
                                  // height={480}
                                  // alt={timeShow}
                                  className={classes.responsive}
                                  unloader={(
                                    <Img
                                      src="https://placehold.co/640x480?text=No+Image"
                                      alt="No Image"
                                      className={classes.responsive}
                                    />
                                  )}
                                />
                              </Button>
                              <Box p={1}>
                                <Typography className={classes.dateTime}>
                                  {moment(row.media_datetime).format('LLL')}
                                  {' '}
                                  UTC
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }
                        return (
                          <Img
                            src="https://placehold.co/640x480?text=No+Image"
                            // alt={timeShow}
                            className={classes.responsive}
                          />
                        );
                      })}
                  </Grid>
                ) : null}
              </Grid>
            ) : null
          }

          {
            // strom map
            // ความชื้น
            // else ใน condition นี้มี แสดงผล แผนที่อากาศ
            (mediaTypeId === '62') ? (
              <></>
            ) : (
              <>
                {mediaTypeId !== '180' && mediaTypeId !== '175' && !viewLatestImage && !viewAnimationImage && (
                  <Grid container spacing={4}>
                    {datas.map(row => {
                      let realImage = '';
                      let width;
                      let height = 188;
                      let boxTime = '';
                      let timeShow;
                      const currentImgIndex = currentIndex;
                      // 2023-05-31 แก้ไขแสดงผลเวลาจาก `${moment(row.media_datetime).utc().format('HH:mm')} ` เป็น `${moment(row.media_datetime).locale(languageI18n).format('HH:mm')}`
                      // 2023-10-24 แก้ไขแสดงผลเวลา mediaTypeId === '22' คือแผนที่อากาศ แสดงผลเวลาเป็น UTC ตามรูปภาพ
                      // 2023-01-08 agencyId === '52' แสดงผลเวลา LocalTime
                      if (mediaTypeId === '22' || agencyId === '52') {
                        timeShow = moment(row.media_datetime).utc().format('HH:mm');
                      } else {
                        timeShow = (datePickerType === 'date') ? `${moment(row.media_datetime).locale(languageI18n).format('HH:mm')} ` : `${moment(row.media_datetime).utc().format('LL')}`;
                      }
                      // [Ticket #480] ตรวจสอบภาพความชื้น
                      // https://devsupport.hii.or.th/?support_page=open_ticket&ticket_id=480&auth_code=pULQ2V6Fyz
                      if (['1', '2', '3'].includes(mediaTypeId)) {
                        timeShow = `${moment.utc(row.media_datetime).locale(languageI18n).format('HH:mm')}`;
                      }
                      if (row.filepath.split('/')[2] === 'satellite_rain' || row.filepath.split('/')[2] === 'countour_image') {
                        width = '';
                      }
                      if (mediaTypeId === '141') {
                        // MTSAT-2 ดูภาพย้อนหลังรายวัน
                        width = '100%';
                        height = '100%';
                        if (agencyId === '52') {
                          timeShow += ' Local Time';
                        } else {
                          timeShow += 'UTC';
                        }
                        boxTime = (
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                            {timeShow}
                          </Box>
                        );
                      } else {
                        width = '90%';
                        timeShow += t('น.');
                        boxTime = (
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                            {timeShow}
                          </Box>
                        );
                      }
                      const { w: thumbWidth, h: thumbHeight } = mediaType[mediaTypeId].thumbSize;
                      showImg = (row.media_path_thumb !== null)
                        ? row.thumbnail_media_path : row.media_path;
                      if (row.media_path !== '') {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${row.media_path}`,
                          title: row.media_datetime,
                          // title: timeShow,
                        });
                        realImage = (
                          <Button
                            key={uniqueId()}
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`}
                              width={width}
                              height={height}
                              alt={timeShow}
                              className={classes.responsive}
                              key={uniqueId()}
                              unloader={(
                                <Img
                                  src={`https://placehold.co/${thumbWidth}x${thumbHeight}?text=No+Image`}
                                  alt="No Image"
                                  className={classes.responsive}
                                />
                              )}
                            />
                          </Button>
                        );
                        currentIndex += 1;
                      } else {
                        realImage = (
                          <Img
                            src={`https://placehold.co/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt={timeShow}
                            className={classes.responsive}
                          />
                        );
                      }

                      if (datePickerType !== 'year') {
                        return (
                          <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                            <Box className={classes.boxWeatherHistory}>
                              <Box display="block" align="center">
                                {realImage}
                              </Box>
                              <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                <Typography className={classes.dateTime}>
                                  {boxTime}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  </Grid>
                )}
              </>
            )
          }
          {
            // การแสดงผล ค่าความชื้นในดิน (Soil Moisture)
            (mediaTypeId === '160' || mediaTypeId === '161' || mediaTypeId === '162' || mediaTypeId === '163') ? (
              <>
                {yearOfmonth.map(result => (
                  <>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={12} lg={12} key={uniqueId()}>
                        <Box p={1} mt={2} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                          {convertFormatDate(yearTab, result)}
                        </Box>
                      </Grid>
                      {datas
                        .filter(row => moment(row.media_datetime).format('MMMM') === moment(`2019-${String(result).padStart(2, '0')}-01`).format('MMMM'))
                        .map(row => {
                          let realImage = '';
                          let width;
                          let height = 188;
                          let boxTime = '';
                          const currentImgIndex = currentIndex;

                          if (row.filepath.split('/')[2] === 'satellite_rain' || row.filepath.split('/')[2] === 'countour_image') {
                            width = '';
                          } else if (mediaTypeId === '141') {
                            width = '100%';
                            height = '100%';
                            boxTime = (
                              <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                                {`${moment(row.media_datetime).format('HH:mm')} ${t('น.')}`}
                              </Box>
                            );
                          } else {
                            width = '90%';
                            boxTime = (
                              <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                                {(datePickerType === 'date') ? moment(row.media_datetime).format('HH:mm') : moment(row.media_datetime).format('LL')}
                              </Box>
                            );
                          }

                          if (row.media_path_thumb !== null) {
                            images.push({ src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${row.media_path}`, title: row.media_datetime });
                            realImage = (
                              <Button
                                key={uniqueId()}
                                onClick={() => this.setState({
                                  isOpen: true,
                                  photoIndex: currentImgIndex,
                                })}
                              >
                                <Img
                                  src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${row.thumbnail_media_path}`}
                                  width={width}
                                  height={height}
                                  alt={row.datetime}
                                  className={classes.responsive}
                                  key={uniqueId()}
                                  unloader={(
                                    <img
                                      src="https://placehold.co/232x268?text=No+Image"
                                      alt="No Data"
                                    />
                                  )}
                                />
                              </Button>
                            );
                            currentIndex += 1;
                          } else {
                            realImage = (
                              <Img
                                src="https://placehold.co/232x268?text=No+Image" // src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                                alt="No Data"
                                className={classes.responsive}
                                style={{ maxHeight: 274 }}
                                key={uniqueId()}
                              />
                            );
                          }

                          return (
                            <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                              <Box className={classes.boxSoilHistory}>
                                <Box display="block" align="center">
                                  {realImage}
                                </Box>
                                <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                  <Typography className={classes.dateTime}>
                                    {boxTime}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                ))}
              </>
            ) : null
          }
          {
            // การแสดงผล ภาพปริมาณฝนสะสม 24 ชั่วโมง
            (mediaTypeId === '180') ? (
              <>
                {!cumulativeRainfallStatus ? (
                  <Grid container spacing={4}>
                    {datas.map(row => {
                      let realImage = '';
                      let width;
                      let height = 188;
                      let boxTime = '';
                      const currentImgIndex = currentIndex;
                      let timeShow = (datePickerType === 'date') ? `${moment(row.media_datetime).utc().format('HH:mm')} ` : moment(row.media_datetime).utc().format('LL');

                      if (row.filepath.split('/')[2] === 'satellite_rain' || row.filepath.split('/')[2] === 'countour_image') {
                        width = '';
                      }

                      if (mediaTypeId === '141') {
                        // MTSAT-2
                        width = '100%';
                        height = '100%';
                        timeShow += 'UTC';
                        boxTime = (
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                            mtsat 2
                            {timeShow}
                          </Box>
                        );
                      } else {
                        width = '90%';
                        timeShow += t('น.');
                        boxTime = (
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                            {timeShow}
                          </Box>
                        );
                      }

                      const { w: thumbWidth, h: thumbHeight } = mediaType[mediaTypeId].thumbSize;

                      showImg = (row.media_path_thumb !== null)
                        ? row.thumbnail_media_path : row.media_path;

                      if (row.media_path !== '') {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`,
                          title: timeShow,
                        });

                        realImage = (
                          <Button
                            key={uniqueId()}
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`}
                              width={width}
                              height={height}
                              alt={timeShow}
                              className={classes.responsive}
                              key={uniqueId()}
                              unloader={(
                                <Img
                                  src={`https://placehold.co/${thumbWidth}x${thumbHeight}?text=No+Image`}
                                  alt="No Image"
                                  className={classes.responsive}
                                />
                              )}
                            />
                          </Button>
                        );
                      } else {
                        realImage = (
                          <Img
                            src={`https://placehold.co/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt={timeShow}
                            className={classes.responsive}
                          />
                        );
                      }
                      currentIndex += 1;

                      if (datePickerType !== 'year') {
                        return (
                          <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                            <Box className={classes.boxWeatherHistory}>
                              <Box display="block" align="center">
                                {realImage}
                              </Box>
                              <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                <Typography className={classes.dateTime}>
                                  {boxTime}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                      <Box className={classes.boxWeatherHistory}>
                        <Button onClick={() => this.setState({ isOpenRainImage: true })}>
                          <Box display="block" align="center">
                            <Img
                              src={rainImage.src}
                              className={classes.responsive}
                              alt="image-last-rain"
                              key={uniqueId()}
                              unloader={(
                                <img
                                  src="https://placehold.co/204x375?text=No+Image"
                                  alt="No Data"
                                />
                              )}
                            />
                          </Box>
                        </Button>
                        <Box p={1} display="flex" alignItems="center" justifyContent="center">
                          <Typography className={classes.dateTime}>
                            {rainImage.dateTime}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            ) : null
          }
          {
            // การแสดงผล ภาพภาพระดับน้ำ
            (mediaTypeId === '175') ? (
              <>
                {!imageWaterLevelStatus ? (
                  <Grid container spacing={4}>
                    {datas.map(row => {
                      let realImage = '';
                      let width;
                      let height = 188;
                      let boxTime = '';
                      const currentImgIndex = currentIndex;
                      const timeShow = (datePickerType === 'date') ? `${moment(row.media_datetime).utc().format('HH:mm')} น.` : moment(row.media_datetime).utc().format('LL');

                      if (row.filepath.split('/')[2] === 'satellite_rain' || row.filepath.split('/')[2] === 'countour_image') {
                        width = '';
                      }

                      if (mediaTypeId === '141') {
                        // MTSAT-2
                        width = '100%';
                        height = '100%';
                        boxTime = (
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                            mtsat 2
                            {timeShow}
                          </Box>
                        );
                      } else {
                        width = '90%';
                        boxTime = (
                          <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                            {timeShow}
                          </Box>
                        );
                      }

                      const { w: thumbWidth, h: thumbHeight } = mediaType[mediaTypeId].thumbSize;

                      showImg = (row.media_path_thumb !== null)
                        ? row.thumbnail_media_path : row.media_path;

                      if (row.media_path !== '') {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`,
                          title: timeShow,
                        });

                        realImage = (
                          <Button
                            key={uniqueId()}
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`}
                              width={width}
                              height={height}
                              alt={timeShow}
                              className={classes.responsive}
                              key={uniqueId()}
                              unloader={(
                                <Img
                                  src={`https://placehold.co/${thumbWidth}x${thumbHeight}?text=No+Image`}
                                  alt="No Image"
                                  className={classes.responsive}
                                />
                              )}
                            />
                          </Button>
                        );
                      } else {
                        realImage = (
                          <Img
                            src={`https://placehold.co/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt={timeShow}
                            className={classes.responsive}
                          />
                        );
                      }
                      currentIndex += 1;

                      if (datePickerType !== 'year') {
                        return (
                          <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                            <Box className={classes.boxWeatherHistory}>
                              <Box display="block" align="center">
                                {realImage}
                              </Box>
                              <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                <Typography className={classes.dateTime}>
                                  {boxTime}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  </Grid>
                ) : (
                  <Grid container spacing={4}>
                    <>
                      <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                        <Box className={classes.boxWeatherHistory}>
                          <Button onClick={() => this.setState({ isOpenWlImage: true })}>
                            <Box display="block" align="center">
                              <Img
                                src={wlImage.src}
                                className={classes.responsive}
                                alt="image-last-rain"
                                key={uniqueId()}
                                unloader={(
                                  <img
                                    src="https://placehold.co/204x375?text=No+Image"
                                    alt="No Data"
                                  />
                                )}
                              />
                            </Box>
                          </Button>
                          <Box p={1} display="flex" alignItems="center" justifyContent="center">
                            <Typography className={classes.dateTime}>
                              {wlImage.dateTime}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  </Grid>
                )}
              </>
            ) : null
          }
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={dateTimeFormatLightbox(images[photoIndex].title, mediaTypeId)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}

        {isOpenRainImage && (
          <Lightbox
            mainSrc={rainImage.src}
            imageTitle={rainImage.dateTime}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpenRainImage: false })}
          />
        )}

        {isOpenWlImage && (
          <Lightbox
            mainSrc={wlImage.src}
            imageTitle={wlImage.dateTime}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpenWlImage: false })}
          />
        )}
      </>
    );
  }
}

WeatherImageHistory.defaultProps = {
  match: {
    params: {
      mediaTypeId: 'phs240',
      date: '2019-01-01',
    },
  },
};

WeatherImageHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      mediaTypeId: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      agencyId: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(WeatherImageHistory);
