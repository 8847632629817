import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// images
import Lightbox from 'react-image-lightbox';
import Img from 'react-image';

// lodash
import {
  uniqueId,
} from 'lodash';

// style, css
import { styles } from '../../../styles/Style';

class Gsmap10kmListLast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
    };
    this.initialState = { ...this.state };
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  render() {
    const { classes, data } = this.props;
    const { isOpen, photoIndex } = this.state;
    return (
      <>
        <Grid container spacing={4} style={{ paddingTop: 20 }}>
          {data.map((result, i) => (
            <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
              <Box mt={1} className={classes.boxWeatherHistory}>
                <Box display="block" align="center">
                  <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                    <Img
                      src={result.url}
                      className={classes.responsive}
                      alt="imageGSMaP10KM"
                      unloader={(
                        <Img
                          src="https://via.placeholder.com/232x425?text=No%20Image"
                          alt="No Image"
                          className={classes.responsive}
                        />
                      )}
                    />
                  </Button>
                </Box>
                <Box p={2} display="flex" alignItems="center" justifyContent="center">
                  <Typography className={classes.dateTime}>
                    {result.date}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={data[photoIndex].url}
            nextSrc={data[(photoIndex + 1) % data.length].url}
            prevSrc={data[(photoIndex + data.length - 1) % data.length].url}
            imageTitle={data[photoIndex].date}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + data.length - 1) % data.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % data.length,
              }))}
          />
        )}
      </>
    );
  }
}

Gsmap10kmListLast.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
};

export default withStyles(styles, { withTheme: true })(Gsmap10kmListLast);
