import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// moment
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Hidden } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

// icon
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { FaHome, FaCalendarAlt } from '../../../utils/Fontawesome';

// style, css
import { styles } from '../../../styles/Style';

// components
import Gsmap10kmListLast from './Gsmap10kmListLast';
import Gsmap10kmListHistory from './Gsmap10kmListHistory';
import Gsmap25kmListLast from './Gsmap25kmListLast';
import Gsmap25kmListHistory from './Gsmap25kmListHistory';
import Gsmap4kmListLast from './Gsmap4kmListLast';
import Gsmap4kmListHistory from './Gsmap4kmListHistory';
import Gpm10kmListLast from './Gpm10kmListLast';
import Gpmap10kmListHistory from './Gpmap10kmListHistory';

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">{children}</Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const initPath = process.env.MIX_APP_PATH;
class GsmapHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      monthly: 0,
      GSMap10KM: [],
      GSMap10KMHistory: [],
      GSMap25KM: [],
      GSMap25KMHistory: [],
      GPMap10KM: [],
      GPMap10KMHistory: [],
      GSMap4KM: [],
      GSMap4KMHistory: [],
      datename: [],
      condition: 0,
      displayState: 'latest',
    };
    this.initialState = { ...this.state };
  }

  componentDidMount() {
    this.getGSMaP10KMLast('gsm_010');
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  getGSMaP10KMLast(type) {
    const toDay = moment().toDate();
    const localTime = (toDay.getTimezoneOffset() !== '-420') ? 7 : 0;
    // const limits = (
    //  moment().format('HH') + localTime >= 13 && moment().format('m') >= 15) ? 0 : 1;
    const limits = (Number(moment().format('H')) + localTime >= 19) ? 0 : 1;
    // console.log('limits : ', Number(moment().format('H')) + localTime );
    const domain = process.env.MIX_LIVE_URL;
    const URL1 = `${domain}/product/history/sat_rain/GSMaP_010_bias/`;
    const URL2 = `${domain}/product/history/sat_rain/GSMaP_025_bias/`;
    const URL3 = `${domain}/product/history/sat_rain/Persiann_bias/`;
    const URL4 = `${domain}/product/history/sat_rain/GPM_bias/`;
    const months = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค'];
    const arrayGSMaP10KM = [];
    const arrayGSMaP25KM = [];
    const arrayGSMaP4KM = [];
    const arrayGPMaP10KM = [];
    for (let i = 7; i > limits; i -= 1) {
      const d = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate() - i);
      const currDay = d.getDate();
      const currMonth = d.getMonth() + 1;
      const currYear = d.getFullYear();
      const currMonthFull = ((`${currMonth}`).length < 2 ? '0' : '') + currMonth;
      const currDayFull = ((`${currDay}`).length < 2 ? '0' : '') + currDay;
      const imageDate = `${currYear}${currMonthFull}${currDayFull}`;
      const imagePath = `${currYear}/${currMonthFull}`;
      const dateName = `${currDayFull} ${months[d.getMonth()]} ${moment().toDate().getFullYear() + 543}`;
      if (type === 'gsm_010') {
        // const urlImg = `${URL1 + imagePath}/${type}_${imageDate}_bias.png`;
        const urlImg = `${URL1 + imagePath}/gsm_nrt_bias_${imageDate}.png`;
        const obj10km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGSMaP10KM.push(obj10km);
      } else if (type === 'gsm_025') {
        const urlImg = `${URL2 + imagePath}/${type}_${imageDate}_bias.png`;
        const obj25km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGSMaP25KM.push(obj25km);
      } else if (type === 'per_gccs') {
        // const urlImg = `${URL3 + imagePath}/${type}_${imageDate}_bias.png`;
        const urlImg = `${URL3 + imagePath}/per_ccs_bias_${imageDate}.png`;
        const obj4km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGSMaP4KM.push(obj4km);
      } else if (type === 'gpm_010') {
        // const urlImg = `${URL3 + imagePath}/${type}_${imageDate}_bias.png`;
        const urlImg = `${URL4 + imagePath}/gpm_imerg_early_bias_${imageDate}.png`;
        const objgpm10km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGPMaP10KM.push(objgpm10km);
      }
    }
    this.setState({
      GSMap10KM: arrayGSMaP10KM,
      GSMap25KM: arrayGSMaP25KM,
      GSMap4KM: arrayGSMaP4KM,
      GPMap10KM: arrayGPMaP10KM,
    });
    this.forceUpdate();
  }

  getGSMaPArchive(dateCurrent, type) {
    function daysInMonth(Month, Year) {
      return new Date(Year, Month, 0).getDate();
    }
    const today = dateCurrent;
    const months = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค'];
    const month = today.getMonth();
    const year = today.getFullYear();
    const numDay = daysInMonth(month + 1, year);
    const maxDay = numDay;
    const domain = process.env.MIX_LIVE_URL;
    const URL1 = `${domain}/product/history/sat_rain/GSMaP_010_bias/`;
    const URL2 = `${domain}/product/history/sat_rain/GSMaP_025_bias/`;
    const URL3 = `${domain}/product/history/sat_rain/Persiann_bias/`;
    const URL4 = `${domain}/product/history/sat_rain/GPM_bias/`;
    const arrayGSMaP10KM = [];
    const arrayGSMaP25KM = [];
    const arrayGSMaP4KM = [];
    const arrayGPM10KM = [];

    for (let i = 1; i <= maxDay; i += 1) {
      const d = new Date(today.getFullYear(), month, i);
      const currDay = d.getDate();
      const currMonth = d.getMonth() + 1;
      const currYear = d.getFullYear();
      const currMonthFull = ((`${currMonth}`).length < 2 ? '0' : '') + currMonth;
      const currDayFull = ((`${currDay}`).length < 2 ? '0' : '') + currDay;
      const imageDate = `${currYear}${currMonthFull}${currDayFull}`;
      const imagePath = `${currYear}/${currMonthFull}`;
      const dateName = `${currDayFull} ${months[d.getMonth()]} ${currYear + 543}`;

      if (type === 'gsm_010') {
        // const urlImg = `${URL1 + imagePath}/${type}_${imageDate}_bias.png`;
        const urlImg = `${URL1 + imagePath}/gsm_nrt_bias_${imageDate}.png`;
        const obj10km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGSMaP10KM.push(obj10km);
      } else if (type === 'gsm_025') {
        const urlImg = `${URL2 + imagePath}/${type}_${imageDate}_bias.png`;
        const obj25km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGSMaP25KM.push(obj25km);
      } else if (type === 'per_gccs') {
        // const urlImg = `${URL3 + imagePath}/${type}_${imageDate}.png`;
        const urlImg = `${URL3 + imagePath}/per_ccs_bias_${imageDate}.png`;
        const obj4km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGSMaP4KM.push(obj4km);
      } else if (type === 'gpm_010') {
        const urlImg = `${URL4 + imagePath}/gpm_imerg_early_bias_${imageDate}.png`;
        const obj10gpmkm = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
        arrayGPM10KM.push(obj10gpmkm);

      }
    }
    this.setState({
      GSMap10KMHistory: arrayGSMaP10KM,
      GSMap25KMHistory: arrayGSMaP25KM,
      GSMap4KMHistory: arrayGSMaP4KM,
      GPMap10KMHistory: arrayGPM10KM,
    });
    this.forceUpdate();
  }

  handleClick = data => {
    const { value } = this.state;
    if (data === 0) {
      this.setState({ condition: 0, startDate: new Date() });
      if (value === 0) {
        this.getGSMaP10KMLast('gsm_010');
        this.forceUpdate();
      } else if (value === 1) {
        // this.getGSMaP10KMLast('gsm_025');
        this.getGSMaP10KMLast('gpm_010');
        this.forceUpdate();
      } else if (value === 2) {
        this.getGSMaP10KMLast('per_gccs');
        this.forceUpdate();
      }
    } else {
      this.setState({ condition: 1, startDate: new Date() });
      if (value === 0) {
        this.getGSMaPArchive(moment().toDate(), 'gsm_010');
        this.forceUpdate();
      } else if (value === 1) {
        // this.getGSMaPArchive(moment().toDate(), 'gsm_025');
        this.getGSMaPArchive(moment().toDate(), 'gpm_010');
        this.forceUpdate();
      } else if (value === 2) {
        this.getGSMaPArchive(moment().toDate(), 'per_gccs');
        this.forceUpdate();
      }
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
    if (value === 0) {
      this.getGSMaP10KMLast('gsm_010');
      this.getGSMaPArchive(new Date(), 'gsm_010');
      this.setState({ startDate: new Date(moment()) });
      this.forceUpdate();
      // } else if (value === 1) {
      //   this.getGSMaP10KMLast('gsm_025');
      //   this.getGSMaPArchive(new Date(), 'gsm_025');
      //   this.setState({ startDate: new Date(moment()) });
      //   this.forceUpdate();
      // } 
    } else if (value === 1) {
      this.getGSMaP10KMLast('gpm_010');
      this.getGSMaPArchive(new Date(), 'gpm_010');
      this.setState({ startDate: new Date(moment()) });
      this.forceUpdate();
    }
    else if (value === 2) {
      this.getGSMaP10KMLast('per_gccs');
      this.getGSMaPArchive(new Date(), 'per_gccs');
      this.setState({ startDate: new Date(moment()) });
      this.forceUpdate();
    }
  };

  render() {
    const { classes, t } = this.props;
    const {
      GSMap10KM,
      GSMap25KM,
      GSMap4KM,
      GPMap10KM,
      condition,
      GSMap10KMHistory,
      GSMap25KMHistory,
      GSMap4KMHistory,
      GPMap10KMHistory,
      value,
      displayState,
    } = this.state;
    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/rainfall`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('ฝน')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('ข้อมูลฝนดาวเทียมที่ผ่านการปรับแก้ความเอนเอียงแล้ว')}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
            <Grid item xs={12} sm={10} md={8}>
              <Box m={1} className={classes.titleName}>
                {t('ข้อมูลฝนดาวเทียมที่ผ่านการปรับแก้ความเอนเอียงแล้ว')}
              </Box>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพล่าสุด')}>
                <Button
                  className={classes.outlinedLeftPrimary}
                  onClick={() => this.handleClick(0, this.setState({ displayState: 'latest' }))}
                  color={displayState === 'latest' ? 'primary' : 'default'}
                  variant={displayState === 'latest' ? 'contained' : 'text'}
                >
                  <ImageOutlinedIcon fontSize="small" />
                  <Hidden smDown>
                    <Typography variant="h6" style={{ marginLeft: 8 }}>
                      {t('ดูภาพล่าสุด')}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                <Button
                  className={classes.outlinedRightPrimary}
                  onClick={() => this.handleClick(1, this.setState({ displayState: 'history' }))}
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                >
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                  <Hidden smDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูข้อมูลย้อนหลัง')}</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 20 }}>
            <Grid item xs>
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="secondary"
                variant="fullWidth"
                className={classes.boxTab}
              >
                <Tab
                  label={(
                    <>
                      GSMaP (10km X 10km)
                    </>
                  )}
                  className={value === 0 ? classes.active_tab : ''}
                />
                <Tab
                  label={(
                    <>
                      GPM (10km x 10km)
                    </>
                  )}
                  className={value === 1 ? classes.active_tab : ''}
                />
                <Tab
                  label={(
                    <>
                      PERSIANN (4km x 4km)
                    </>
                  )}
                  className={value === 2 ? classes.active_tab : ''}
                />
              </Tabs>
              {/* GSMaP (10km X 10km) */}
              {value === 0 && (
                <>
                  {condition === 0 ? (
                    <Gsmap10kmListLast data={GSMap10KM} />
                  ) : (
                    <Gsmap10kmListHistory data={GSMap10KMHistory} />
                  )}
                </>
              )}
              {/* GSMaP (25km x 25km) */}
              {/* {value === 1 && (
                <>
                  {condition === 0 ? (
                    <Gsmap25kmListLast data={GSMap25KM} />
                  ) : (
                    <>
                      {GSMap25KMHistory.length > 0 && (
                        <Gsmap25kmListHistory data={GSMap25KMHistory} />
                      )}
                    </>
                  )}
                </>
              )} */}
              {/*  GPM (10kmx10km) */}
              {value === 1 && (
                <>
                  {condition === 0 ? (
                    <Gpm10kmListLast data={GPMap10KM} />
                  ) : (
                    <>
                      {GPMap10KMHistory.length > 0 && (
                        <Gpmap10kmListHistory data={GPMap10KMHistory} />
                      )}
                    </>
                  )}
                </>
              )}
              {/* PERSIANN (4km x 4km) */}
              {value === 2 && (
                <>
                  {condition === 0 ? (
                    <Gsmap4kmListLast data={GSMap4KM} />
                  ) : (
                    <>
                      {GSMap4KMHistory.length > 0 && (
                        <Gsmap4kmListHistory data={GSMap4KMHistory} />
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

GsmapHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(GsmapHistory));
