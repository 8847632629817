import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId, uniqBy, orderBy,
} from 'lodash';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import './pagination.scss';

// form .
import FormControl from '@material-ui/core/FormControl';

// color
import grey from '@material-ui/core/colors/grey';
import Pagination from './Pagination';

// icon .
import { FaSpinner, FaCaretRight } from '../../utils/Fontawesome';
import { HiiBarrenIcon, HiiFloodHistoryIcon } from '../../utils/Icon';

import {
  styles, BootstrapInput,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;


class ReportSituation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      dataFlood: [],
      dataDrought: [],
      dataFloodFilter: [],
      dataDroughtFilter: [],
      floodYearList: [],
      droughtYearList: [],
      valueFloodDropDown: moment().year() + 543,
      valueDroughtDropDown: moment().year() + 543,
      currentPage: 1,
      PageSize: 10,
      searchTerm: '',
      dialogSearch: false,
    };
  }

  componentDidMount() {
    this._mounted = true;

    this.getData();
  }

  getData() {
    Promise.all([
      fetch(`${initPath}json/contents/report_flood.json`),
      fetch(`${initPath}json/contents/report_drought.json`),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([flood, drought]) => {
        const floodYearList = uniqBy(orderBy(flood, ['year'], ['desc']), 'year');
        const droughtYearList = uniqBy(orderBy(drought, ['year'], ['desc']), 'year');
        const valueFloodDropDown = floodYearList[0].year;
        const valueDroughtDropDown = droughtYearList[0].year;

        flood.map((row, i) => {
          const d = row;
          d.id = i;

          return null;
        });

        // set state
        this.setState({
          isLoading: false,
          dataFlood: flood,
          dataDrought: drought,
          dataFloodFilter: flood.filter(d => d.year === valueFloodDropDown),
          dataDroughtFilter: drought.filter(d => d.year === valueDroughtDropDown),
          floodYearList,
          droughtYearList,
          valueFloodDropDown,
          valueDroughtDropDown,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleDropDownChange = (name, dataName) => event => {
    const { dataFlood, dataDrought } = this.state;
    const selectedYear = event.target.value;
    let data = '';
    if (event.target.value === '') {
      data = (name === 'valueFloodDropDown')
        ? dataFlood
        : dataDrought;
      this.setState({ dialogSearch: true });
    } else {
      data = (name === 'valueFloodDropDown')
        ? dataFlood.filter(d => d.year.toString() === selectedYear)
        : dataDrought.filter(d => d.year.toString() === selectedYear);
      this.setState({ dialogSearch: false, searchData: undefined });
    }

    this.setState({
      [name]: selectedYear,
      [dataName]: data,
    });
  };

  handleSearchChange = event => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm }, () => {
      this.filterDatas();
    });
  };

  filterDatas() {
    const { searchTerm, dataFlood } = this.state;
    const searchData = dataFlood
      .filter(d => {
        if (searchTerm !== '') {
          return d.title.indexOf(searchTerm) > -1;
        }
        return d;
      });
    this.setState({ searchData });
  }

  render() {
    const { classes, t } = this.props;
    const {
      isLoading, error, dataFloodFilter, dataDroughtFilter, floodYearList,
      droughtYearList, valueFloodDropDown, valueDroughtDropDown, currentPage,
      PageSize, searchData, dialogSearch,
    } = this.state;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const result = searchData
      ? searchData.slice(firstPageIndex, lastPageIndex)
      : dataFloodFilter.slice(firstPageIndex, lastPageIndex);

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <div style={{ width: '100%' }}>
                <Box display="flex" mt={2}>
                  <Box width="100%">
                    <List component="nav">
                      <ListItem>
                        <ListItemIcon>
                          <HiiFloodHistoryIcon fontSize={18} style={{ color: grey[900] }} />
                        </ListItemIcon>
                        <Typography variant="h6" fontWeight="fontWeightMedium" fontFamily="Kanit">
                          {t('บันทึกเหตุการณ์น้ำท่วม')}
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Box p={1} flexShrink={0}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <NativeSelect
                        value={valueFloodDropDown}
                        onChange={this.handleDropDownChange('valueFloodDropDown', 'dataFloodFilter')}
                        input={<BootstrapInput name="region" id="regend-customized-native-simple" />}
                        className={classes.outLinedSelect}
                      >
                        <option fontFamily="Kanit" value="" style={{ fontFamily: 'Kanit' }} key={uniqueId()}>{t('แสดงทั้งหมด')}</option>
                        {floodYearList.map(row => (
                          <option fontFamily="Kanit" value={row.year} style={{ fontFamily: 'Kanit' }} key={uniqueId()}>{row.year}</option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Box>
              </div>
              {dialogSearch && (
                <Box display="flex" style={{ justifyContent: 'end' }}>
                  <Grid item xs={12} sm={3} md={6}>
                    <Box fontFamily="Kanit">
                      <Paper className={classes.rootSearch} elevation={0}>
                        <InputBase
                          display="flex"
                          className={classes.inputSearch}
                          width="120px"
                          placeholder={t('ค้นหา')}
                          id="search"
                          onChange={this.handleSearchChange}
                        />
                        <IconButton className={classes.iconButton} aria-label="Search">
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  </Grid>
                </Box>
              )}
              <List component="nav">
                {result.map(row => (
                  <Fragment key={uniqueId()}>
                    <ListItem
                      button
                      key={uniqueId()}
                      component="a"
                      href={row.link}
                      target="_blank"
                    >
                      <FaCaretRight />
                      <Typography variant="body2" style={{ marginLeft: 10 }}>
                        {row.title}
                      </Typography>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={searchData ? searchData.length : dataFloodFilter.length}
                pageSize={PageSize}
                onPageChange={page => this.setState({ currentPage: page })}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div style={{ width: '100%' }}>
                <Box display="flex" mt={2}>
                  <Box width="100%">
                    <List component="nav">
                      <ListItem>
                        <ListItemIcon>
                          <HiiBarrenIcon fontSize={18} />
                        </ListItemIcon>
                        <Typography variant="h6" fontWeight="fontWeightMedium" fontFamily="Kanit">
                          {t('บันทึกเหตุการณ์น้ำแล้ง')}
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Box p={1} flexShrink={0}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <NativeSelect
                        value={valueDroughtDropDown}
                        onChange={this.handleDropDownChange('valueDroughtDropDown', 'dataDroughtFilter')}
                        input={<BootstrapInput name="region" id="regend-customized-native-simple" />}
                        className={classes.outLinedSelect}
                      >
                        <option fontFamily="Kanit" value="" style={{ fontFamily: 'Kanit' }} key={uniqueId()}>{t('แสดงทั้งหมด')}</option>
                        {droughtYearList.map(row => (
                          <option fontFamily="Kanit" value={row.year} style={{ fontFamily: 'Kanit' }} key={uniqueId()}>{row.year}</option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Box>
              </div>
              <List component="nav">
                {dataDroughtFilter.map(row => (
                  <Fragment key={uniqueId()}>
                    <ListItem
                      button
                      key={uniqueId()}
                      component="a"
                      href={row.link}
                      target="_blank"
                    >
                      <FaCaretRight />
                      <Typography variant="body2" style={{ marginLeft: 10 }}>
                        {row.title}
                      </Typography>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

ReportSituation.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(ReportSituation));
