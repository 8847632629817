/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

import { wrap } from 'highcharts';

// img
const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: '100%',
    padding: 10,
  },
  label: {
    fontSize: '1em',
    fontWeight: 300,
  },

});

const CompExtraSelect = props => {
  const classes = useStyles();
  const { handleMapChecked, riverMapChecked, radarMapChecked, traffyMapChecked, twitterMapChecked } = props;

  const handleChange = event => {
    handleMapChecked(event.target.name, event.target.checked);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="river" name="river">
          <FormControlLabel value="1" name="riverMapChecked" control={<Checkbox checked={riverMapChecked} onChange={handleChange} style={{ color: riverMapChecked ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>แสดงเส้นทางน้ำ กทม.</Box>} className={classes.label} />
          <FormControlLabel value="2" name="radarMapChecked" control={<Checkbox checked={radarMapChecked} onChange={handleChange} style={{ color: radarMapChecked ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>แจ้งเตือนจาก เรดาร์กทม.</Box>} className={classes.label} />
          <FormControlLabel value="3" name="traffyMapChecked" control={<Checkbox checked={traffyMapChecked} onChange={handleChange} style={{ color: traffyMapChecked ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>แจ้งเตือนจาก Traffy fondue</Box>} className={classes.label} />
          <FormControlLabel value="4" name="twitterMapChecked" control={<Checkbox checked={twitterMapChecked} onChange={handleChange} style={{ color: twitterMapChecked ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>แจ้งเตือนจาก Twitter</Box>} className={classes.label} />
          {/* <FormControlLabel value="2" control={<Radio style={{ color: value === '2' ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>ปิด</Box>} className={classes.label} /> */}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

CompExtraSelect.propTypes = {
  handleMapChecked: PropTypes.func.isRequired,
  riverMapChecked: PropTypes.bool.isRequired,
  radarMapChecked: PropTypes.bool.isRequired,
  traffyMapChecked: PropTypes.bool.isRequired,
  twitterMapChecked: PropTypes.bool.isRequired,
};

export default CompExtraSelect;
