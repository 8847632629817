import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: 800,
    paddingLeft: '20px',
    paddingRight: '20px',
    zIndex: 99999,
    borderRadius: '5px',
    position: 'fixed',
    color: 'white',
    bottom: '1%',

    [theme.breakpoints.down('xs')]: {
      width: '80%',
      bottom: '60px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
  },
  title: {
    color: 'white',
    fontFamily: 'Kanit',
    fontSize: '1.1em',
  },
  rail: {
    backgroundColor: 'white',
  },
  markLabel: {
    marginTop: 'none !important',
  },
  mark: {
    background: 'white',
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginLeft: '-6px',
    marginTop: '-5px',
  },
  markActive: {
    background: '#1976d2',
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginLeft: '-6px',
    marginTop: '-5px',
  },
  marked: {
    paddingTop: 'none',
  },
}));

export default function CompSlideRainDaily(props) {
  const classes = useStyles();
  const {
    dataSlideRaindaily,
    handleChangeSlideRaindaily,
    valueRainDaily,
  } = props;

  return (
    <Box ml={1} className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Box className={classes.title}>ฝนพยากรณ์รายวัน</Box>
          <Slider
            classes={{
              thumb: classes.thumb,
              rail: classes.rail,
              track: classes.track,
              valueLabel: classes.valueLabel,
              mark: classes.mark,
              marked: classes.marked,
              markActive: classes.markActive,
            }}
            value={valueRainDaily}
            onChange={handleChangeSlideRaindaily}
            defaultValue={valueRainDaily}
            key={`slider-raindaily-${valueRainDaily}`} /* fixed issue */
            aria-label="Slider"
            step={null}
            marks={dataSlideRaindaily}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

CompSlideRainDaily.propTypes = {
  valueRainDaily: PropTypes.number.isRequired,
  dataSlideRaindaily: PropTypes.array.isRequired,
  handleChangeSlideRaindaily: PropTypes.func.isRequired,
};
