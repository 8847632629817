import React, { createContext, useState, useContext, useRef, useCallback } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalSize, setModalSize] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const chartRef = useRef(null);

  const handleClickSize = (newSize) => {
    setModalSize(newSize);
  };

  const handleClickFullScreen = (val) => {
    setIsFullScreen(val);
  };

  // Define updateChartSize function
  const updateChartSize = useCallback(() => {
    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      chart.reflow();
    }
  }, []);

  const contextValue = {
    modalSize,
    handleClickSize,
    isFullScreen,
    handleClickFullScreen,
    chartRef,
    updateChartSize
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export const withModal = (WrappedComponent) => {
  const WithModal = React.forwardRef((props, ref) => {
    return (
      <ModalContext.Consumer>
        {(context) => <WrappedComponent {...props} {...context} ref={ref} />}
      </ModalContext.Consumer>
    );
  });

  return WithModal;
};
export default withModal;