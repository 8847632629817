import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { HiiRefreshIcon } from '../../utils/IconBma';

class RefreshPage extends Component {
  constructor(props) {
    super(props);
    this.handleRefreshPage = this.handleRefreshPage.bind(this);
  }

  componentDidMount() {
    // console.log('Version 1');

    // this.interval = setInterval(() => {
    //   this.handleRefreshPage();
    // }, 360000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleRefreshPage = () => {
    if ('caches' in window) {
      window.caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
    setTimeout(window.location.reload(true));
  };

  render() {
    return (
      <React.Fragment key={uniqueId()}>
        <Button
          color="secondary"
          onClick={() => this.handleRefreshPage()}
          title="Update Data"
          style={{ padding: 8 }}
        >
          <HiiRefreshIcon style={{ fontSize: 27, marginTop: '8px' }} />
        </Button>
      </React.Fragment>
    );
  }
}

RefreshPage.RefreshPage = {
  classes: PropTypes.object.isRequired,
};

export default RefreshPage;
