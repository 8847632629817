import L from 'leaflet';
import iconRain from '../../widgets/map/iconRain';

const initPath = process.env.MIX_APP_PATH;
const sizeIcon = 18;
const RainFallGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Rainfall/icon_rainfall32px-3.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const RainFallYellow = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Rainfall/icon_rainfall32px-4.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const RainFallOrange = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Rainfall/icon_rainfall32px-5.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const RainFallRed = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Rainfall/icon_rainfall32px-7.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const rainfallColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#bdbdbd',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เล็กน้อย',
    rangeTitle: '> 0-0.5',
    statusColor: '#69b9a0',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เล็กน้อย',
    rangeTitle: '> 0.5-10',
    statusColor: '#ffee58',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>10-35',
    statusColor: '#ffc107',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>35-90',
    statusColor: '#e91e63',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'หนัก',
    rangeTitle: '>90',
    statusColor: '#b71c1c',
    constrastColor: '#000',
    fontSize: '12px',
  },
];

const RainfallStatusColor = (rainfall, type) => {
  let color = [];
  let icon;

  const rainfallIcon = iconRain;
  if (type === 'rainfall24h') {
    if (rainfall > 90) {
      color = rainfallColor['5'];
      icon = rainfallIcon.red;
    } else if (rainfall > 35 && rainfall <= 90) {
      color = rainfallColor['4'];
      icon = RainFallRed;
    } else if (rainfall > 10 && rainfall <= 35) {
      color = rainfallColor['3'];
      icon = RainFallOrange;
    } else if (rainfall > 0.5 && rainfall <= 10) {
      color = rainfallColor['2'];
      icon = RainFallYellow;
    } else if (rainfall > 0 && rainfall <= 0.5) {
      color = rainfallColor['1'];
      icon = RainFallGreen;
    } else {
      color = rainfallColor['0'];
      icon = RainFallGreen;
    }
  }

  return { color, icon };
};

export default RainfallStatusColor;
