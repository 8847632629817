// React
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { maxBy, debounce } from 'lodash';
// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { styles } from '../../styles/Style';
// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

export default function ReportSalinityChart(props) {
  const chartRef = useRef(null);
  const { stationId, stationType } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [getError, setError] = useState();
  const [settingChart, setSettingChart] = useState({
    title: {
      text: 'Loading ...',
    },
    plotOptions: {
      line: {
        turboThreshold: 0,
      },
      series: {
        showInNavigator: true,
        gapSize: 0,
      },
    },
    chart: {
      zoomType: 'x',
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    navigator: {
      enabled: true,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%d-%b}',
      },
    },
    yAxis: [
      {
        labels: {
          format: '{value:.2f}  ',
        },
        title: {
          text: ' ',
        },
        opposite: false,
      }, {
        gridLineWidth: 1,
        title: {
          text: ' ',
        },
        labels: {
          format: '{value:.2f} ',
        },
        opposite: true,
      },
    ],
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: ' กรัม / ลิตร',
    },
    colors: ['#11625C', '#DFAE05', '#B6008C', '#7D02CD'],
    legend: {
      enabled: true,
      verticalAlign: 'bottom',
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: [
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
          ],
        },
      },
    },
    series: [],
  });

  function findMaxValue(data, c) {
    const arrayMaxValue = [];
    const arrayMinValue = [];
    const dataSalinitySeries = []; // ระดับน้ำทะเลสุทธิ
    const dataDoSeries = [];
    const dataNh3Series = [];
    let valSalinity = null;
    let valDo = null;
    let valNh3 = null;
    data.forEach(element => {
      valSalinity = parseFloat(element.salt);
      valDo = parseFloat(element.do);
      valNh3 = parseFloat(element.nh3);
      dataSalinitySeries.push(valSalinity);
      dataDoSeries.push(valDo);
      dataNh3Series.push(valNh3);
    });
    const maxSalinity = maxBy(dataSalinitySeries);
    const minSalinity = 0;
    const arrayAllSeries = dataSalinitySeries.concat(dataDoSeries, dataNh3Series, c);
    const dataCleanValid = arrayAllSeries.filter(x => x !== null);
    const max = maxBy(dataCleanValid);
    const min = 0;
    arrayMaxValue.push(max);
    arrayMinValue.push(min);
    const obj = {
      max: Math.max(...arrayMaxValue),
      min: Math.min(...arrayMinValue),
      maxSalinity,
      minSalinity,
    };
    return obj;
  }

  function findMaxValuePCD(data, c) {
    const arrayMaxValue = [];
    const arrayMinValue = [];
    const dataDoSeries = [];
    const dataPhSeries = [];
    const dataSaltSeries = [];
    const dataEcSeries = [];
    const dataCodSeries = [];
    const dataNh3Series = [];
    let valDo = null;
    let valPh = null;
    let valSalt = null;
    let valEc = null;
    let valCod = null;
    let valNh3 = null;

    data.forEach(element => {
      valDo = parseFloat(element.do);
      valPh = parseFloat(element.ph);
      valSalt = parseFloat(element.salt);
      valEc = parseFloat(element.ec);
      valCod = parseFloat(element.cod);
      valNh3 = parseFloat(element.nh3);
      dataDoSeries.push(valDo);
      dataPhSeries.push(valPh);
      dataSaltSeries.push(valSalt);
      dataEcSeries.push(valEc);
      dataCodSeries.push(valCod);
      dataNh3Series.push(valNh3);
    });

    const maxSalt = maxBy(dataSaltSeries);
    const minSalt = 0;
    const arrayAllSeries = dataSaltSeries.concat(
      dataDoSeries, dataPhSeries, dataEcSeries, dataCodSeries, dataNh3Series, c,
    );
    const dataCleanValid = arrayAllSeries.filter(x => x !== null);
    const max = maxBy(dataCleanValid);
    const min = 0;
    arrayMaxValue.push(max);
    arrayMinValue.push(min);
    const obj = {
      max: Math.max(...arrayMaxValue),
      min,
      maxSalt,
      minSalt,
    };
    return obj;
  }

  const url = stationType === 'RID' ? 'https://script.google.com/macros/s/AKfycbypAq3zQ8gpHGDW9FgZ11F-cLDJEUdNSii1mq6AdQVBeMfYgc0YcA7oboe0zWnQFHZs/exec?id='
    : 'https://script.google.com/macros/s/AKfycbzjPl2RbsKP090C3nXMlDeRS8apw7HnCrCFx6XiE7pBhKxBe4bZBAstlrgRN4VHAcBpog/exec?id=';
  function fetchData() {
    fetch(`${url}${stationId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        if (result[0].result === 'OK') {
          setSettingChart(
            {
              ...settingChart,
              title: {
                text: result[0].data.station_th || '',
              },
            },
          );
          const { series } = result[0].data;
          const dataSalinitySeries = [];
          const dataDoSeries = [];
          const dataNh3Series = [];
          const criticallevelSalt = result[0].data.criterion[0].max;
          const criticallevelDo = result[0].data.criterion[1].max;
          const criticallevelNh3 = result[0].data.criterion[2].max;
          const critical = [criticallevelSalt, criticallevelDo, criticallevelNh3];
          let valSalinity = null;
          let valDo = null;
          let valNh3 = null;
          let ts = null;
          const findMaxMinData = findMaxValue(series, critical);
          const {
            max,
            min,
            maxSalinity,
            minSalinity,
          } = findMaxMinData;
          series.map(element => {
            valSalinity = parseFloat(element.salt);
            valDo = parseFloat(element.do);
            valNh3 = parseFloat(element.nh3);
            ts = moment(`${element.date} ${element.time}`, 'YYYY/MM/DD').add(7, 'hours').valueOf();
            if (valSalinity === maxSalinity || valSalinity === minSalinity) {
              const marker = {
                enabled: true,
                symbol: 'triangle',
                fillColor: '#06C',
                radius: 5,
              };
              dataSalinitySeries.push({ x: ts, y: valSalinity, marker });
            } else {
              dataSalinitySeries.push([ts, valSalinity]);
              dataDoSeries.push([ts, valDo]);
              dataNh3Series.push([ts, valNh3]);
            }

            return {
              dataSalinitySeries,

            };
          });
          setSettingChart(
            {
              ...settingChart,
              series: [
                {
                  name: 'ค่าความเค็ม',
                  data: dataSalinitySeries,
                  type: 'line',
                  yAxis: 0,
                  tooltip: {
                    valueSuffix: ' กรัม / ลิตร',
                  },
                  events: {
                    show: debounce(() => {
                      chartRef.current.chart.yAxis[0].addPlotLine({
                        id: 'plot-criticallevelSalt',
                        value: criticallevelSalt || null,
                        color: 'red',
                        dashStyle: 'shortdash',
                        width: 2,
                        label: {
                          text: `เกณฑ์ความเค็มปกติ <strong>${parseFloat(criticallevelSalt).toFixed(2)}</strong> กรัม/ลิตร`,
                          align: 'left',
                        },
                      });
                    }),
                    hide() {
                      chartRef.current.chart.yAxis[0].removePlotLine('plot-criticallevelSalt');
                    },
                  },
                },
                {
                  name: 'ค่าออกซิเจนที่ละลายในน้ำ',
                  data: dataDoSeries,
                  type: 'line',
                  yAxis: 0,
                  tooltip: {
                    valueSuffix: '',
                  },
                  events: {
                    show: debounce(() => {
                      chartRef.current.chart.yAxis[0].addPlotLine({
                        id: 'plot-criticallevelDo',
                        value: criticallevelDo || null,
                        color: 'blue',
                        dashStyle: 'shortdash',
                        width: 2,
                        label: {
                          text: `เกณฑ์ปริมาณออกซิเจนที่ละลายในน้ำ <strong>${parseFloat(criticallevelDo).toFixed(2)}</strong> `,
                          align: 'right',
                        },
                      });
                    }),
                    hide() {
                      chartRef.current.chart.yAxis[0].removePlotLine('plot-criticallevelDo');
                    },
                  },
                },
                {
                  name: 'ค่าแอมโมเนีย NH3',
                  data: dataNh3Series,
                  type: 'line',
                  yAxis: 0,
                  tooltip: {
                    valueSuffix: '',
                  },
                  events: {
                    show: debounce(() => {
                      chartRef.current.chart.yAxis[0].addPlotLine({
                        id: 'plot-criticallevelNh3',
                        value: criticallevelNh3 || null,
                        color: 'green',
                        dashStyle: 'shortdash',
                        width: 2,
                        label: {
                          text: `เกณฑ์แอมโมเนีย NH3 <strong>${parseFloat(criticallevelNh3).toFixed(2)}</strong>`,
                          align: 'center',
                        },
                      });
                    }),
                    hide() {
                      chartRef.current.chart.yAxis[0].removePlotLine('plot-criticallevelNh3');
                    },
                  },
                },
              ],
              yAxis: [
                {
                  labels: {
                    format: '{value:.2f}',
                  },
                  title: {
                    text: 'ค่าความเค็ม (กรัม / ลิตร)',
                  },
                  plotLines: [
                    {
                      id: 'plot-criticallevelSalt',
                      value: criticallevelSalt || null,
                      color: 'red',
                      dashStyle: 'shortdash',
                      width: 2,
                      label: {
                        text: `เกณฑ์ความเค็มปกติ <strong>${parseFloat(criticallevelSalt).toFixed(2)}</strong> กรัม/ลิตร`,
                        align: 'left',
                      },
                    },
                    {
                      id: 'plot-criticallevelDo',
                      value: criticallevelDo || null,
                      color: 'blue',
                      dashStyle: 'shortdash',
                      width: 2,
                      label: {
                        text: `เกณฑ์ปริมาณออกซิเจนที่ละลายในน้ำ <strong>${parseFloat(criticallevelDo).toFixed(2)}</strong> `,
                        align: 'right',
                      },
                    },
                    {
                      id: 'plot-criticallevelNh3',
                      value: criticallevelNh3 || null,
                      color: 'green',
                      dashStyle: 'shortdash',
                      width: 2,
                      label: {
                        text: `เกณฑ์แอมโมเนีย NH3 <strong>${parseFloat(criticallevelNh3).toFixed(2)}</strong>`,
                        align: 'center',
                      },
                    },
                  ],
                  max,
                  min,
                  opposite: false,
                },
              ],
            },
          );
        }
      })
      .catch(error => setError(error));
  }

  function fetchDataPCD() {
    fetch(`${url}${stationId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        if (result[0].result === 'OK') {
          setSettingChart(
            {
              ...settingChart,
              title: {
                text: result[0].data.station_th || '',
              },
            },
          );
          const { series } = result[0].data;
          const saltMax = result[0].data.criterion[0].max;
          const doMax = result[0].data.criterion[1].max;
          const nh3Max = result[0].data.criterion[2].max;
          const critical = [saltMax, doMax, nh3Max];
          const dataDoSeries = [];
          const dataPhSeries = [];
          const dataEcSeries = [];
          const dataCodSeries = [];
          const dataSaltSeries = [];
          const dataNh3Series = [];
          let valDo = null;
          let valPh = null;
          let valEc = null;
          let valCod = null;
          let valSalt = null;
          let valNh3 = null;
          let ts = null;
          const findMaxMinData = findMaxValuePCD(series, critical);
          const {
            max,
            min,
            maxSalt,
            minSalt,
          } = findMaxMinData;
          series.map(element => {
            valDo = parseFloat(element.do);
            valPh = parseFloat(element.ph);
            valEc = parseFloat(element.ec);
            valCod = parseFloat(element.cod);
            valSalt = parseFloat(element.salt);
            valNh3 = parseFloat(element.nh3);
            ts = moment(`${element.date} ${element.time}`, 'YYYY/MM/DD').valueOf();
            if (valSalt === maxSalt || valSalt === minSalt) {
              const marker = {
                enabled: true,
                symbol: 'triangle',
                fillColor: '#06C',
                radius: 5,
              };
              dataSaltSeries.push({ x: ts, y: valSalt, marker });
            } else {
              dataSaltSeries.push([ts, valSalt]);
            }
            dataCodSeries.push([ts, valCod]);
            dataDoSeries.push([ts, valDo]);
            dataPhSeries.push([ts, valPh]);
            dataEcSeries.push([ts, valEc]);
            dataNh3Series.push([ts, valNh3]);
            return {
              dataSaltSeries,
              dataDoSeries,
              dataPhSeries,
              dataEcSeries,
              dataCodSeries,
              dataNh3Series,
            };
          });
          setSettingChart(
            {
              ...settingChart,
              series: [
                {
                  name: 'Salt',
                  data: dataSaltSeries,
                  type: 'line',
                  yAxis: 0,
                  tooltip: {
                    valueSuffix: '',
                  },
                  events: {
                    show: debounce(() => {
                      chartRef.current.chart.yAxis[0].addPlotLine({
                        id: 'plot-saltMax',
                        value: saltMax || null,
                        color: 'red',
                        dashStyle: 'shortdash',
                        width: 2,
                        label: {
                          text: `เกณฑ์ความเค็มปกติ <strong>${parseFloat(saltMax).toFixed(2)}</strong> กรัม/ลิตร`,
                          align: 'left',
                        },
                      });
                    }),
                    hide() {
                      chartRef.current.chart.yAxis[0].removePlotLine('plot-saltMax');
                    },
                  },
                },
                {
                  name: 'DO',
                  data: dataDoSeries,
                  type: 'line',
                  yAxis: 0,
                  visible: true,
                  tooltip: {
                    valueSuffix: '',
                  },
                  events: {
                    show: debounce(() => {
                      chartRef.current.chart.yAxis[0].addPlotLine({
                        id: 'plot-doMax',
                        value: doMax || null,
                        color: 'blue',
                        dashStyle: 'shortdash',
                        width: 2,
                        label: {
                          text: `เกณฑ์ปริมาณออกซิเจนที่ละลายในน้ำ <strong>${parseFloat(doMax).toFixed(2)}</strong> `,
                          align: 'right',
                        },
                      });
                    }),
                    hide() {
                      chartRef.current.chart.yAxis[0].removePlotLine('plot-doMax');
                    },
                  },
                },
                {
                  name: 'pH',
                  data: dataPhSeries,
                  type: 'line',
                  yAxis: 0,
                  visible: false,
                  tooltip: {
                    valueSuffix: '',
                  },
                },
                {
                  name: 'COD',
                  data: dataCodSeries,
                  type: 'line',
                  yAxis: 0,
                  visible: false,
                  tooltip: {
                    valueSuffix: '',
                  },
                },
                {
                  name: 'EC',
                  data: dataEcSeries,
                  type: 'line',
                  yAxis: 1,
                  visible: false,
                  tooltip: {
                    valueSuffix: '',
                  },
                },
                {
                  name: 'NH3',
                  data: dataNh3Series,
                  type: 'line',
                  yAxis: 0,
                  visible: true,
                  tooltip: {
                    valueSuffix: '',
                  },
                  events: {
                    show: debounce(() => {
                      chartRef.current.chart.yAxis[0].addPlotLine({
                        id: 'plot-nh3Max',
                        value: nh3Max || null,
                        color: 'green',
                        dashStyle: 'shortdash',
                        width: 2,
                        label: {
                          text: `เกณฑ์แอมโมเนีย NH3 <strong>${parseFloat(nh3Max).toFixed(2)}</strong>`,
                          align: 'center',
                        },
                      });
                    }, 100),
                    hide() {
                      chartRef.current.chart.yAxis[0].removePlotLine('plot-nh3Max');
                    },
                  },
                },
              ],
              yAxis: [
                {
                  labels: {
                    format: '{value:.2f}',
                  },
                  title: {
                    text: 'ค่าความเค็ม',
                  },
                  plotLines: [
                    {
                      id: 'plot-saltMax',
                      value: saltMax || null,
                      color: 'red',
                      dashStyle: 'shortdash',
                      width: 2,
                      label: {
                        text: `เกณฑ์ความเค็มปกติ <strong>${parseFloat(saltMax).toFixed(2)}</strong> กรัม/ลิตร`,
                        align: 'left',
                      },
                    },
                    {
                      id: 'plot-doMax',
                      value: doMax || null,
                      color: 'blue',
                      dashStyle: 'shortdash',
                      width: 2,
                      label: {
                        text: `เกณฑ์ปริมาณออกซิเจนที่ละลายในน้ำ <strong>${parseFloat(doMax).toFixed(2)}</strong> `,
                        align: 'right',
                      },
                    },
                    {
                      id: 'plot-nh3Max',
                      value: nh3Max || null,
                      color: 'green',
                      dashStyle: 'shortdash',
                      width: 2,
                      label: {
                        text: `เกณฑ์แอมโมเนีย NH3 <strong>${parseFloat(nh3Max).toFixed(2)}</strong>`,
                        align: 'center',
                      },
                    },
                  ],
                  max: maxSalt,
                  min: minSalt,
                  opposite: false,
                }, {
                  labels: {
                    format: '{value:.2f}',
                  },
                  title: {
                    text: 'EC',
                  },
                  max,
                  min,
                  opposite: true,
                },
              ],
            },
          );
        }
      })
      .catch(error => setError(error));
  }

  useEffect(() => {
    if (stationType === 'RID') {
      fetchData();
    } else {
      fetchDataPCD();
    }
  }, []);

  return (
    <>
      {getError && (
        <div className="text-center">
          {getError.message}
        </div>
      )}
      <div>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box py={{ xs: 0, sm: 2 }} className={classes.boxGraphDam}>
                <Box className={classes.graphDam}>
                  <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    options={settingChart}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ color: '#A2A2A2' }}>
            *สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
          </div>
        </Grid>
      </div>
    </>
  );
}

ReportSalinityChart.propTypes = {
  stationId: PropTypes.number.isRequired,
  stationType: PropTypes.string.isRequired,
};
