export const damsRid = [
  { id: 1, name: 'ภูมิพล' },
  { id: 2, name: 'อุบลรัตน์' },
  { id: 3, name: 'สิรินธร' },
  { id: 4, name: 'จุฬาภรณ์' },
  { id: 5, name: 'ห้วยหลวง' },
  { id: 6, name: 'ลำนางรอง' },
  { id: 7, name: 'มูลบน' },
  { id: 8, name: 'น้ำพุง' },
  { id: 9, name: 'ลำแซะ' },
  { id: 11, name: 'ป่าสักชลสิทธิ์' },
  { id: 12, name: 'สิริกิติ์' },
  { id: 13, name: 'แก่งกระจาน' },
  { id: 14, name: 'ศรีนครินทร์' },
  { id: 15, name: 'วชิราลงกรณ' },
  { id: 16, name: 'ปราณบุรี' },
  { id: 17, name: 'กระเสียว' },
  { id: 18, name: 'ทับเสลา' },
  { id: 19, name: 'บางพระ' },
  { id: 23, name: 'แม่งัดสมบูรณ์ชล' },
  { id: 24, name: 'หนองปลาไหล' },
  { id: 25, name: 'รัชชประภา' },
  { id: 26, name: 'บางลาง' },
  { id: 30, name: 'คลองสียัด' },
  { id: 32, name: 'ขุนด่านปราการชล' },
  { id: 33, name: 'ประแสร์' },
  { id: 34, name: 'กิ่วลม' },
  { id: 35, name: 'กิ่วคอหมา' },
  { id: 36, name: 'แควน้อยบำรุงแดน' },
  { id: 37, name: 'นฤบดินทรจินดา' },
  { id: 38, name: 'แม่กวงอุดมธารา' },
  { id: 39, name: 'ลำปาว' },
  { id: 40, name: 'ลำตะคอง' },
  { id: 41, name: 'ลำพระเพลิง' },
  { id: 42, name: 'น้ำอูน' },
  { id: 230, name: 'แม่มอก' },
];

export const damsMediumRid = [
  {
    id: 1,
    name: 'ห้วยแม่ฮ่องสอน',
  },
  {
    id: 2,
    name: 'อ่างเก็บน้ำห้วยพุง',
  },
  {
    id: 3,
    name: 'อ่างเก็บน้ำลำฉมวก',
  },
  {
    id: 4,
    name: 'อ่างเก็บน้ำบึงกระโตน',
  },
  {
    id: 5,
    name: 'อ่างเก็บน้ำห้วยยาง',
  },
  {
    id: 6,
    name: 'อ่างเก็บน้ำห้วยซับประดู่',
  },
  {
    id: 7,
    name: 'อ่างเก็บน้ำห้วยบ้านยาง',
  },
  {
    id: 8,
    name: 'อ่างเก็บน้ำห้วยทับครัว',
  },
  {
    id: 9,
    name: 'ลำเชียงไกร',
  },
  {
    id: 10,
    name: 'อ่างเก็บน้ำห้วยยางพะไล',
  },
  {
    id: 11,
    name: 'ห้วยปราสาทใหญ',
  },
  {
    id: 12,
    name: 'อ่างเก็บน้ำลำสำลาย',
  },
  {
    id: 13,
    name: 'อ่างเก็บน้ำห้วยทา',
  },
  {
    id: 14,
    name: 'อ่างเก็บน้ำห้วยน้ำคำ',
  },
  {
    id: 15,
    name: 'อ่างเก็บน้ำห้วยด่านไอ',
  },
  {
    id: 16,
    name: 'สวนสมเด็จพระศรีนครินทร์',
  },
  {
    id: 17,
    name: 'หนองทะเลสาป',
  },
  {
    id: 18,
    name: 'บ้านสระกลอย',
  },
  {
    id: 19,
    name: 'บ้านลำทหาร',
  },
  {
    id: 20,
    name: 'บ้านโป่งนก',
  },
  {
    id: 21,
    name: 'ทำนบดินและขุดลอกห้วยขลุง1',
  },
  {
    id: 22,
    name: 'ทำนบดินห้วยขลุง2',
  },
  {
    id: 23,
    name: 'ทำนบดินห้วยขลุง3',
  },
  {
    id: 24,
    name: 'อ่างเก็บน้ำห้วยหอย',
  },
  {
    id: 25,
    name: 'ทำนบดินห้วยขลุง4',
  },
  {
    id: 26,
    name: 'ทำนบดินห้วยขลุง5',
  },
  {
    id: 27,
    name: 'ทำนบดินห้วยขลุง6',
  },
  {
    id: 28,
    name: 'ทำนบดินห้วยขลุง7',
  },
  {
    id: 29,
    name: 'ทำนบดินห้วยขลุง8',
  },
  {
    id: 30,
    name: 'ทำนบดินห้วยขลุง 9',
  },
  {
    id: 31,
    name: 'ทำนบดินห้วยขลุง10',
  },
  {
    id: 32,
    name: 'ทำนบดินห้วยขลุง11',
  },
  {
    id: 33,
    name: 'เขาพระหุงดิน',
  },
  {
    id: 34,
    name: 'ป่าผาก',
  },
  {
    id: 35,
    name: 'อ่างเก็บน้ำห้วยขี้เหล็ก (ตอนบน)',
  },
  {
    id: 36,
    name: 'อ่างเก็บน้ำห้วยตะเคียน',
  },
  {
    id: 37,
    name: 'บ้านหนองจั่น',
  },
  {
    id: 38,
    name: 'บ้านหนองไก่เหลือง',
  },
  {
    id: 39,
    name: 'บ้านบึงหล่ม',
  },
  {
    id: 40,
    name: 'เขาสีเสียด',
  },
  {
    id: 41,
    name: 'บ้านโกรกสมอ',
  },
  {
    id: 42,
    name: 'บ้านอ่างหิน',
  },
  {
    id: 43,
    name: 'ลำอีซู',
  },
  {
    id: 44,
    name: 'หนองนาทะเล',
  },
  {
    id: 45,
    name: 'ห้วยตายอด',
  },
  {
    id: 46,
    name: 'อ่างเก็บน้ำเขาระกำ',
  },
  {
    id: 47,
    name: 'ทุ่งก้างย่างล่าง',
  },
  {
    id: 48,
    name: 'บ้านทุ่งก้างย่าง',
  },
  {
    id: 49,
    name: 'บ้านท้ายเหมือง',
  },
  {
    id: 50,
    name: 'บ้านมะเซอย่อ สร้างระบบปี44',
  },
  {
    id: 51,
    name: 'ห้วยลำสมอ',
  },
  {
    id: 52,
    name: 'บ้านพุหว้า',
  },
  {
    id: 53,
    name: 'บ้านลุ่มผึ้ง',
  },
  {
    id: 54,
    name: 'เขาเขียว สร้างระบบปี44',
  },
  {
    id: 55,
    name: 'ห้วยกระทะแตก',
  },
  {
    id: 56,
    name: 'บ้านหนองศรีมงคล',
  },
  {
    id: 57,
    name: 'อ่างเก็บน้ำบ้านมะนาว',
  },
  {
    id: 58,
    name: 'บ้านห้วยเสือ',
  },
  {
    id: 59,
    name: 'ท่าข้ามสุด สร้างระบบปี44',
  },
  {
    id: 60,
    name: 'บ้านปลังกาสี',
  },
  {
    id: 61,
    name: 'วังวิเวกการาม',
  },
  {
    id: 62,
    name: 'เขาเขียว พร้อมระบบส่งน้ำ',
  },
  {
    id: 63,
    name: 'บ้านพุช่องกระบอก',
  },
  {
    id: 64,
    name: 'ลำห้วยยวย',
  },
  {
    id: 65,
    name: 'หนองหินดาด',
  },
  {
    id: 66,
    name: 'ห้วยสมจิตร',
  },
  {
    id: 67,
    name: 'บ้านวังยาง(ท่อผันน้ำและบ่อพักน้ำบ้านวังยางปี42)',
  },
  {
    id: 68,
    name: 'อ่างเก็บน้ำด่านชุมพล',
  },
  {
    id: 69,
    name: 'บ้านหนองผักแว่น',
  },
  {
    id: 70,
    name: 'เขาพุช้างหมอบ',
  },
  {
    id: 71,
    name: 'ห้วยพุช้างหมอบ',
  },
  {
    id: 72,
    name: 'ห้วยแม่ระวัง',
  },
  {
    id: 73,
    name: 'ห้วยหวาย',
  },
  {
    id: 74,
    name: 'ห้วยลำทรายตอนบน  (ปชด.)',
  },
  {
    id: 75,
    name: 'ห้วยแห้ง',
  },
  {
    id: 76,
    name: 'เหมืองแร่จรินทร์',
  },
  {
    id: 77,
    name: 'บ้านพุม่วง',
  },
  {
    id: 78,
    name: 'ตะเคียนงาม',
  },
  {
    id: 79,
    name: 'อ่างเก็บน้ำห้วยแร้ง',
  },
  {
    id: 80,
    name: 'บ้านพุน้ำร้อน',
  },
  {
    id: 81,
    name: 'บ้านตะเคียนงาม 2',
  },
  {
    id: 82,
    name: 'บ้านห้วยน้ำขาว พร้อมระบบส่งน้ำ',
  },
  {
    id: 83,
    name: 'เขาวง',
  },
  {
    id: 84,
    name: 'เหมืองปิล๊อก',
  },
  {
    id: 85,
    name: 'บ้านปะไรโหนก พร้อมระบบส่งน้ำ',
  },
  {
    id: 86,
    name: 'บ้านด่านเจดีย์สามองค์',
  },
  {
    id: 87,
    name: 'บ้านหนองตาก้าย',
  },
  {
    id: 88,
    name: 'บ้านเขาวงพระจันทร์',
  },
  {
    id: 89,
    name: 'บ่อทอง',
  },
  {
    id: 90,
    name: 'อ่างเก็บน้ำวังปลาหมอ',
  },
  {
    id: 91,
    name: 'ห้วยลำขลุง',
  },
  {
    id: 92,
    name: 'หนองกวาง',
  },
  {
    id: 93,
    name: 'ขุนนิชิต',
  },
  {
    id: 94,
    name: 'ทุ่งหญ้าเลี้ยงสัตว์',
  },
  {
    id: 95,
    name: 'บ้านบ่อหว้า',
  },
  {
    id: 96,
    name: 'บ้านสามัคีธรรม(สร้างระบบปี41 ปชด)',
  },
  {
    id: 97,
    name: 'บ้านพุเตย',
  },
  {
    id: 98,
    name: 'บ้านน้ำพุล่าง',
  },
  {
    id: 99,
    name: 'ห้วยเทียน',
  },
  {
    id: 100,
    name: 'ชัฎป่าหวาย',
  },
  {
    id: 101,
    name: 'อ่างเก็บน้ำห้วยสามเขา',
  },
  {
    id: 102,
    name: 'ห้วยสำนักไม้เต็ง(พร้อมระบบ)',
  },
  {
    id: 103,
    name: 'ห้วยท่าเคย',
  },
  {
    id: 104,
    name: 'ห้วยต้นห้าง',
  },
  {
    id: 105,
    name: 'หนองโก',
  },
  {
    id: 106,
    name: 'ห้วยพุเกตุ',
  },
  {
    id: 107,
    name: 'บ้านห้วยศาลา 1',
  },
  {
    id: 108,
    name: 'บ้านไทประจันต์',
  },
  {
    id: 109,
    name: 'บ้านหินสี',
  },
  {
    id: 110,
    name: 'ห้วยศาลา 2',
  },
  {
    id: 111,
    name: 'บ้านวังปลาช่อน',
  },
  {
    id: 112,
    name: 'ม่อนตะแลง',
  },
  {
    id: 113,
    name: 'อ่างเก็บน้ำห้วยสงสัย',
  },
  {
    id: 114,
    name: 'บ้านลานคา',
  },
  {
    id: 115,
    name: 'ห้วยพุกรูด',
  },
  {
    id: 116,
    name: 'เขาหัวแดง',
  },
  {
    id: 117,
    name: 'บ้านตากแดด',
  },
  {
    id: 118,
    name: 'หินสีตอนบน',
  },
  {
    id: 119,
    name: 'บ้านหนองตาหลวง',
  },
  {
    id: 120,
    name: 'บ้านรางม่วง',
  },
  {
    id: 121,
    name: 'พุคา',
  },
  {
    id: 122,
    name: 'พุเสือเต้น',
  },
  {
    id: 123,
    name: 'บ้านหนองปากชัฏ',
  },
  {
    id: 124,
    name: 'อ่างเก็บน้ำห้วยทราย',
  },
  {
    id: 125,
    name: 'หุบพ่อเต่า',
  },
  {
    id: 126,
    name: 'ห้วยอ้ายหลิว',
  },
  {
    id: 127,
    name: 'รางไข่เน่า',
  },
  {
    id: 128,
    name: 'ห้วยกระชาย',
  },
  {
    id: 129,
    name: 'บ้านห้วยทุ่งหญ้า',
  },
  {
    id: 130,
    name: 'บ้านทุ่งหินสี',
  },
  {
    id: 131,
    name: 'บ้านทุ่งศาลา',
  },
  {
    id: 132,
    name: 'บ้านสวนผึ้ง',
  },
  {
    id: 133,
    name: 'ฝายบ้านทุ่งแฝก',
  },
  {
    id: 134,
    name: 'บ้านตะเคียนทอง',
  },
  {
    id: 135,
    name: 'อ่างเก็บน้ำทุ่งขาม',
  },
  {
    id: 136,
    name: 'ห้วยอะนะ',
  },
  {
    id: 137,
    name: 'บ้านโป่งแห้ง 1',
  },
  {
    id: 138,
    name: 'ห้วยคอกหมู',
  },
  {
    id: 139,
    name: 'อ่างเก็บน้ำห้วยผาก',
  },
  {
    id: 140,
    name: 'บ้านตะโกล่าง',
  },
  {
    id: 141,
    name: 'บ้านถ้ำหินพร้อมระบบ',
  },
  {
    id: 142,
    name: 'บ้านห้วยสุด',
  },
  {
    id: 143,
    name: 'บ้านตะโกล่าง 2',
  },
  {
    id: 144,
    name: 'บ้านพุขี้เหล็ก',
  },
  {
    id: 145,
    name: 'บ้านบึงใต้',
  },
  {
    id: 146,
    name: 'อ่างเก็บน้ำห้วยตะแปด',
  },
  {
    id: 147,
    name: 'บ้านบึงเหนือ',
  },
  {
    id: 148,
    name: 'บ้านหนองน้ำขุ่น',
  },
  {
    id: 149,
    name: 'บ้านห้วยไทร',
  },
  {
    id: 150,
    name: 'บ้านร่องเจริญ',
  },
  {
    id: 151,
    name: 'ลำพะ',
  },
  {
    id: 152,
    name: 'โป่งกระทิงบน',
  },
  {
    id: 153,
    name: 'อ่างเก็บน้ำบ้านพุน้ำร้อน',
  },
  {
    id: 154,
    name: 'พุตะเคียน',
  },
  {
    id: 155,
    name: 'อ่างเก็บน้ำห้วยน้ำใส',
  },
  {
    id: 156,
    name: 'ห้วยมะกรูด',
  },
  {
    id: 157,
    name: 'อ่างเก็บน้ำแม่ออน',
  },
  {
    id: 158,
    name: 'บ้านดงยาง',
  },
  {
    id: 159,
    name: 'ห้วยมะหาดตอนล่าง',
  },
  {
    id: 160,
    name: 'ห้วยมะหาดตอนบน',
  },
  {
    id: 161,
    name: 'ทุ่งหมูปล่อย',
  },
  {
    id: 162,
    name: 'ผาชลแดน',
  },
  {
    id: 163,
    name: 'บ้านพุยาง',
  },
  {
    id: 164,
    name: 'ห้วยพุไทร',
  },
  {
    id: 165,
    name: 'อ่างเก็บน้ำกุดลิงง้อ อ.เมือง',
  },
  {
    id: 166,
    name: 'อ่างเก็บน้ำหนองสำโรง อ.เมือง',
  },
  {
    id: 167,
    name: 'อ่างเก็บน้ำโสกน้ำขาว อ.เมือง',
  },
  {
    id: 168,
    name: 'อ่างเก็บน้ำแม่จอกหลวง',
  },
  {
    id: 169,
    name: 'อ่างเก็บน้ำลำป่าค้าว อ.เมือง',
  },
  {
    id: 170,
    name: 'อ่างเก็บน้ำหนองโอน อ.เมือง',
  },
  {
    id: 171,
    name: 'อ่างเก็บน้ำหนองตาไก้ อ.เมือง',
  },
  {
    id: 172,
    name: 'อ่างเก็บน้ำห้วยน้ำเที่ยง อ.เพ็ญ',
  },
  {
    id: 173,
    name: 'อ่างเก็บน้ำห้วยทราย อ.น้ำโสม',
  },
  {
    id: 174,
    name: 'หนองบ่อ อ.หนองหาน',
  },
  {
    id: 175,
    name: 'อ่างเก็บน้ำห้วยเพลียก',
  },
  {
    id: 176,
    name: 'อ่างเก็บน้ำห้วยสะกาด',
  },
  {
    id: 177,
    name: 'อ่างเก็บน้ำห้วยบง',
  },
  {
    id: 178,
    name: 'อ่างเก็บน้ำห้วยตะคร้อ',
  },
  {
    id: 179,
    name: 'อ่างเก็บน้ำห้วยมะนาว',
  },
  {
    id: 180,
    name: 'อ่างเก็บน้ำลำตะโคง',
  },
  {
    id: 181,
    name: 'อ่างเก็บน้ำห้วยยาง',
  },
  {
    id: 182,
    name: 'อ่างเก็บน้ำห้วยตาเขียว',
  },
  {
    id: 183,
    name: 'อ่างเก็บน้ำห้วยตะโก',
  },
  {
    id: 184,
    name: 'อ่างเก็บน้ำห้วยสวาย',
  },
  {
    id: 185,
    name: 'อ่างเก็บน้ำห้วยตลาด',
  },
  {
    id: 186,
    name: 'อ่างเก็บน้ำห้วยจระเข้มาก',
  },
  {
    id: 187,
    name: 'อ่างเก็บน้ำห้วยเมฆา',
  },
  {
    id: 188,
    name: 'อ่างเก็บน้ำหนองทะลอก',
  },
  {
    id: 189,
    name: 'หนองตาหมู่',
  },
  {
    id: 190,
    name: 'อ่างเก็บน้ำโป่งจ้อ',
  },
  {
    id: 191,
    name: 'อ่างเก็บน้ำห้วยขี้หนู',
  },
  {
    id: 192,
    name: 'อ่างเก็บน้ำห้วยน้อย',
  },
  {
    id: 193,
    name: 'อ่างเก็บน้ำห้วยใหญ่',
  },
  {
    id: 194,
    name: 'ฝายราษีไศล',
  },
  {
    id: 195,
    name: 'โสกรัง อ.โนนสะอาด',
  },
  {
    id: 196,
    name: 'อ่างเก็บน้ำห้วยสามพาด อ.หนองแสง',
  },
  {
    id: 197,
    name: 'อ่างเก็บน้ำหนองปะโค อ.กุมภวาปี',
  },
  {
    id: 198,
    name: 'อ่างเก็บน้ำบ้านจั่น อ.เมือง',
  },
  {
    id: 199,
    name: 'เขื่อนทดน้ำพญาแมน',
  },
  {
    id: 200,
    name: 'สระเก็บน้ำบ้านปชด.',
  },
  {
    id: 201,
    name: 'อ่างเก็บน้ำแม่ตูบ',
  },
  {
    id: 202,
    name: 'เขื่อนขุนด่านปราการชล',
  },
  {
    id: 203,
    name: 'เขื่อนปราณบุรี',
  },
  {
    id: 204,
    name: 'เขื่อนนเรศวร',
  },
  {
    id: 205,
    name: 'สิริกิติ์',
  },
  {
    id: 206,
    name: 'สิรินธร',
  },
  {
    id: 207,
    name: 'ลำนางรอง',
  },
  {
    id: 208,
    name: 'ลำพระเพลิง',
  },
  {
    id: 209,
    name: 'เขื่อนวชิราลงกรณ',
  },
  {
    id: 210,
    name: 'เขื่อนศรีนครินทร์',
  },
  {
    id: 211,
    name: 'เขื่อนท่าทุ่งนา',
  },
  {
    id: 212,
    name: 'อ่างเก็บน้ำแม่โก๋น',
  },
  {
    id: 213,
    name: 'เขื่อนแม่กลอง',
  },
  {
    id: 214,
    name: 'ลำตะคอง',
  },
  {
    id: 215,
    name: 'มูลบน',
  },
  {
    id: 216,
    name: 'ลำแซะ',
  },
  {
    id: 217,
    name: 'บ้านหินลาด',
  },
  {
    id: 218,
    name: 'ห้วยเค่าเล่า',
  },
  {
    id: 219,
    name: 'บ้านซำตะเคียน',
  },
  {
    id: 220,
    name: 'ห้วยทรายใต้',
  },
  {
    id: 221,
    name: 'บ้านหมั่นแสวง',
  },
  {
    id: 222,
    name: 'ห้วยน้ำจวง',
  },
  {
    id: 223,
    name: 'อ่างเก็บน้ำห้วยขี้เหล็ก',
  },
  {
    id: 224,
    name: 'อ่างเก็บน้ำห้วยเดื่อ',
  },
  {
    id: 225,
    name: 'บ้านร่มเกล้า',
  },
  {
    id: 226,
    name: 'ห้วยน้ำคับ',
  },
  {
    id: 227,
    name: 'อ่างเก็บน้ำหนองกก',
  },
  {
    id: 228,
    name: 'อ่างเก็บน้ำห้วยปราสาทใหญ่',
  },
  {
    id: 229,
    name: 'ห้วยน้ำเค็ม',
  },
  {
    id: 230,
    name: 'เขื่อนพิมาย',
  },
  {
    id: 231,
    name: 'ฝายชุมพวง',
  },
  {
    id: 232,
    name: 'อ่างเก็บน้ำห้วยบะอีแตน',
  },
  {
    id: 233,
    name: 'ลําเชียงไกรตอนบน',
  },
  {
    id: 234,
    name: 'เขื่อนภูมิพล',
  },
  {
    id: 235,
    name: 'อ่างเก็บน้ำแม่แหลงหลวง',
  },
  {
    id: 236,
    name: 'เขื่อนแม่งัดสมบูรณ์ชล',
  },
  {
    id: 237,
    name: 'เขื่อนแม่กวง',
  },
  {
    id: 238,
    name: 'เขื่อนกิ่วลม',
  },
  {
    id: 239,
    name: 'เขื่อนเจ้าพระยา',
  },
  {
    id: 240,
    name: 'เขื่อนพระรามหก',
  },
  {
    id: 241,
    name: 'เขื่อนทับเสลา',
  },
  {
    id: 242,
    name: 'เขื่อนกระเสียว',
  },
  {
    id: 243,
    name: 'เขื่อนกิ่วคอหมา',
  },
  {
    id: 244,
    name: 'เขื่อนแควน้อย',
  },
  {
    id: 245,
    name: 'เขื่อนป่าสัก',
  },
  {
    id: 246,
    name: 'อ่างเก็บน้ำสันหนอง',
  },
  {
    id: 247,
    name: 'ลำปาว',
  },
  {
    id: 248,
    name: 'น้ำอูน',
  },
  {
    id: 249,
    name: 'อุบลรัตน์',
  },
  {
    id: 250,
    name: 'จุฬาภรณ์',
  },
  {
    id: 251,
    name: 'อ่างเก็บน้ำห้วยหลวง',
  },
  {
    id: 252,
    name: 'น้ำพุง',
  },
  {
    id: 253,
    name: 'อ่างเก็บน้ำลำปลายมาศ',
  },
  {
    id: 254,
    name: 'แก่งกระจาน',
  },
  {
    id: 255,
    name: 'บางพระ',
  },
  {
    id: 256,
    name: 'อ่างเก็บน้ำหนองค้อ',
  },
  {
    id: 257,
    name: 'อ่างเก็บน้ำแม่ทะลบหลวง',
  },
  {
    id: 258,
    name: 'อ่างเก็บน้ำมาบประชัน',
  },
  {
    id: 259,
    name: 'อ่างเก็บน้ำดอกกราย',
  },
  {
    id: 260,
    name: 'หนองปลาไหล',
  },
  {
    id: 261,
    name: 'รัชชประภา',
  },
  {
    id: 262,
    name: 'บางลาง',
  },
  {
    id: 263,
    name: 'อ่างเก็บน้ำพระปรง',
  },
  {
    id: 264,
    name: 'คลองสียัด',
  },
  {
    id: 265,
    name: 'อ่างเก็บน้ำบางวาด',
  },
  {
    id: 266,
    name: 'ประแสร์',
  },
  {
    id: 267,
    name: 'อ่างเก็บน้ำแม่ตะไคร้',
  },
  {
    id: 268,
    name: 'อ่างเก็บน้ำห้วยสมัย',
  },
  {
    id: 269,
    name: 'อ่างเก็บน้ำห้วยแม่ข้อน',
  },
  {
    id: 270,
    name: 'ฝายเหมืองใหม่',
  },
  {
    id: 271,
    name: 'ฝายแม่สาว',
  },
  {
    id: 272,
    name: 'อ่างเก็บน้ำแม่สาน',
  },
  {
    id: 273,
    name: 'อ่างเก็บน้ำแม่ตีบ',
  },
  {
    id: 274,
    name: 'อ่างเก็บน้ำแม่ธิ',
  },
  {
    id: 275,
    name: 'อ่างเก็บน้ำแม่เมย',
  },
  {
    id: 276,
    name: 'ฝายวังตอง',
  },
  {
    id: 277,
    name: 'ฝายมหาโชค',
  },
  {
    id: 278,
    name: 'ฝายเหมืองดั้ง',
  },
  {
    id: 279,
    name: 'อ่างเก็บน้ำแม่อาบ',
  },
  {
    id: 280,
    name: 'ฝายหนองสลีก',
  },
  {
    id: 281,
    name: 'ฝายชลขันธพินิจ',
  },
  {
    id: 282,
    name: 'ฝายแม่แฝก',
  },
  {
    id: 283,
    name: 'แม่แพง',
  },
  {
    id: 284,
    name: 'ฝายน้ำยวม',
  },
  {
    id: 285,
    name: 'ฝายขุนยวม',
  },
  {
    id: 286,
    name: 'ฝายแม่สะเรียงตอนบน',
  },
  {
    id: 287,
    name: 'อ่างเก็บน้ำแม่สะกึด',
  },
  {
    id: 288,
    name: 'ฝายปางมะผ้า',
  },
  {
    id: 289,
    name: 'เขื่อนแม่กวงอุดมธารา',
  },
  {
    id: 290,
    name: 'อ่างเก็บน้ำห้วยทา',
  },
  {
    id: 291,
    name: 'ฝายแม่แตง',
  },
  {
    id: 292,
    name: 'อ่างเก็บน้ำแม่ทาน',
  },
  {
    id: 293,
    name: 'อ่างเก็บน้ำห้วยแม่สอด',
  },
  {
    id: 294,
    name: 'อ่างเก็บน้ำห้วยลึก',
  },
  {
    id: 295,
    name: 'แม่มอก',
  },
  {
    id: 296,
    name: 'ห้วยทาแพ',
  },
  {
    id: 297,
    name: 'อ่างเก็บน้ำห้วยแม่สูง',
  },
  {
    id: 298,
    name: 'อ่างเก็บน้ำคลองข้างใน',
  },
  {
    id: 299,
    name: 'ฝายศรีเชลียง',
  },
  {
    id: 300,
    name: 'ฝายยางบ้านกง',
  },
  {
    id: 301,
    name: 'อ่างเก็บน้ำห้วยตาจู',
  },
  {
    id: 302,
    name: 'อ่างเก็บน้ำแม่มาน',
  },
  {
    id: 303,
    name: 'อ่างเก็บน้ำแม่สอง',
  },
  {
    id: 304,
    name: 'อ่างเก็บน้ำแม่ถาง',
  },
  {
    id: 305,
    name: 'อ่างเก็บน้ำแม่คำปอง',
  },
  {
    id: 306,
    name: 'ห้วยป่าบง',
  },
  {
    id: 307,
    name: 'น้ำอูน',
  },
  {
    id: 308,
    name: 'อ่างเก็บน้ำห้วยเดียก อ.เมือง',
  },
  {
    id: 309,
    name: 'อ่างเก็บน้ำห้วยโทห้วยยาง',
  },
  {
    id: 310,
    name: 'อ่างเก็บน้ำห้วยหวด อ.เต่างอย',
  },
  {
    id: 311,
    name: 'อ่างเก็บน้ำห้วยขี้หิน อ.ภูพาน',
  },
  {
    id: 312,
    name: 'อ่างเก็บน้ำหนองสิ',
  },
  {
    id: 313,
    name: 'อ่างเก็บน้ำห้วยกระเฌอ อ.กุดบาก',
  },
  {
    id: 314,
    name: 'ห้วยหินชะแนนใหญ่',
  },
  {
    id: 315,
    name: 'อ่างเก็บน้ำห้วยโป่ง อ.พังโคน',
  },
  {
    id: 316,
    name: 'อ่างเก็บน้ำภูเพ็ก อ.พรรณานิคม',
  },
  {
    id: 317,
    name: 'ห้วยน้ำหยาด อ.วาริชภูมิ',
  },
  {
    id: 318,
    name: 'อ่างเก็บน้ำห้วยโทง อ.วานรนิวาส',
  },
  {
    id: 319,
    name: 'ห้วยไร่ อ.บ้านม่วง',
  },
  {
    id: 320,
    name: 'คำผักหนาม อ.บ้านม่วง',
  },
  {
    id: 321,
    name: 'อ่างเก็บน้ำห้วยทรายสว่าง อ.สว่างแดนดิน',
  },
  {
    id: 322,
    name: 'ห้วยนกเค้า อ.เจริญศิลป์',
  },
  {
    id: 323,
    name: 'อ่างเก็บน้ำห้วยคล้า',
  },
  {
    id: 324,
    name: 'อ่างเก็บน้ำห้วยก้านเหลือง อ.ส่องดาว',
  },
  {
    id: 325,
    name: 'อ่างเก็บน้ำห้วยคำ อ.วานรนิวาส',
  },
  {
    id: 326,
    name: 'อ่างเก็บน้ำห้วยซวง อ.วานรนิวาส',
  },
  {
    id: 327,
    name: 'อ่างเก็บน้ำห้วยรังแร้ง อ.บ้านม่วง',
  },
  {
    id: 328,
    name: 'ห้วยหินแตก อ. พนัสนิคม',
  },
  {
    id: 329,
    name: 'ห้วยน้ำบ่อ อ. พนัสนิคม',
  },
  {
    id: 330,
    name: 'อ่างเก็บน้ำห้วยแข้ อ.ภูพาน',
  },
  {
    id: 331,
    name: 'อ่างเก็บน้ำห้วยนาอ่าง อ.เต่างอย',
  },
  {
    id: 332,
    name: 'อ่างเก็บน้ำน้ำซับคำโรงสี อ. บ้านม่วง',
  },
  {
    id: 333,
    name: 'อ่างเก็บน้ำบ้านดงมะไฟ อ. บ้านม่วง',
  },
  {
    id: 334,
    name: 'อ่างเก็บน้ำห้วยชะโนด',
  },
  {
    id: 335,
    name: 'ห้วยซัน',
  },
  {
    id: 336,
    name: 'อ่างเก็บน้ำห้วยทรายขมิ้น อ.เมือง',
  },
  {
    id: 337,
    name: 'ห้วยคำบากน้อย อ.สว่างฯ',
  },
  {
    id: 338,
    name: 'อ่างเก็บน้ำห้วยบังพวน อ.ท่าบ่อ',
  },
  {
    id: 339,
    name: 'อ่างเก็บน้ำหนองสองห้อง อ.เมือง',
  },
  {
    id: 340,
    name: 'อ่างเก็บน้ำห้วยเปลวเงือก อ.โพนพิสัย',
  },
  {
    id: 341,
    name: 'ห้วยซ่า อ.โซ่พิสัย',
  },
  {
    id: 342,
    name: 'อ่างเก็บน้ำห้วยสหาย อ.บุ่งคล้า',
  },
  {
    id: 343,
    name: 'ห้วยทอน บน กิ่งอ.โพธิ์ตาก',
  },
  {
    id: 344,
    name: 'ห้วยหินแก้ว (พร้อมระบบ)',
  },
  {
    id: 345,
    name: 'อ่างเก็บน้ำห้วยเหล่ายาง อ.เมือง',
  },
  {
    id: 346,
    name: 'อ่างเก็บน้ำห้วยตามาย',
  },
  {
    id: 347,
    name: 'อ่างเก็บน้ำห้วยน้ำหมาน อ.เมือง',
  },
  {
    id: 348,
    name: 'อ่างเก็บน้ำห้วยน้ำพาว อ.เมือง',
  },
  {
    id: 349,
    name: 'อ่างเก็บน้ำห้วยน้อย อ.เมือง',
  },
  {
    id: 350,
    name: 'อ่างเก็บน้ำห้วยแห้ว อ.เมือง',
  },
  {
    id: 351,
    name: 'อ่างเก็บน้ำห้วยน้ำวัก อ.ท่าลี่',
  },
  {
    id: 352,
    name: 'อ่างเก็บน้ำห้วยโป่ง อ.ปากชม',
  },
  {
    id: 353,
    name: 'อ่างเก็บน้ำห้วยชม อ.ปากชม',
  },
  {
    id: 354,
    name: 'อ่างเก็บน้ำห้วยไร่ อ.ปากชม',
  },
  {
    id: 355,
    name: 'อ่างเก็บน้ำห้วยน้ำสวย อ.นาด้วง',
  },
  {
    id: 356,
    name: 'อ่างเก็บน้ำห้วยลิ้นควาย อ.นาด้วง',
  },
  {
    id: 357,
    name: 'อ่างเก็บน้ำห้วยขนุน',
  },
  {
    id: 358,
    name: 'อ่างเก็บน้ำห้วยยาง อ.ภูกระดึง',
  },
  {
    id: 359,
    name: 'อ่างเก็บน้ำห้วยอีเลิศ อ.วังสะพุง',
  },
  {
    id: 360,
    name: 'อ่างเก็บน้ำห้วยหลวง',
  },
  {
    id: 361,
    name: 'อ่างเก็บน้ำคำตะเคียน',
  },
  {
    id: 362,
    name: 'อ่างเก็บน้ำบ้านดง',
  },
  {
    id: 363,
    name: 'อ่างเก็บน้ำห้วยหินขาว',
  },
  {
    id: 364,
    name: 'อ่างเก็บน้ำคลองเพรียว',
  },
  {
    id: 365,
    name: 'อ่างเก็บน้ำห้วยป่าแดง',
  },
  {
    id: 366,
    name: 'อ่างเก็บน้ำคลองเฉลียงลับ',
  },
  {
    id: 367,
    name: 'อ่างเก็บน้ำห้วยขอนแก่น',
  },
  {
    id: 368,
    name: 'อ่างเก็บน้ำห้วยช้าง',
  },
  {
    id: 369,
    name: 'อ่างเก็บน้ำห้วยขุนแก้ว',
  },
  {
    id: 370,
    name: 'อ่างเก็บน้ำห้วยท่าเดื่อ',
  },
  {
    id: 371,
    name: 'กระเสียว',
  },
  {
    id: 372,
    name: 'อ่างเก็บน้ำโป่งกระทิง',
  },
  {
    id: 373,
    name: 'ชัฎปาหวาย',
  },
  {
    id: 374,
    name: 'ห้วยสำนักไม้เต็ง',
  },
  {
    id: 375,
    name: 'ลำตะเพิน',
  },
  {
    id: 376,
    name: 'ห้วยพุตะเคียน',
  },
  {
    id: 377,
    name: 'ฝายกุมภวาปี อ.กุมภวาปี',
  },
  {
    id: 378,
    name: 'อ่างเก็บน้ำห้วยทราย อ. โคกศรีสุพรรณ',
  },
  {
    id: 379,
    name: 'อ่างเก็บน้ำแม่สรวย',
  },
  {
    id: 380,
    name: 'ห้วยมะหาด',
  },
  {
    id: 381,
    name: 'เขาช้างหมอบ',
  },
  {
    id: 382,
    name: 'ขรน. พิมาย',
  },
  {
    id: 383,
    name: 'ห้วยใหญ',
  },
  {
    id: 384,
    name: 'อ่างเก็บน้ำห้วยยาง',
  },
  {
    id: 385,
    name: 'อ่างเก็บน้ำห้วยทราย',
  },
  {
    id: 386,
    name: 'อ่างเก็บน้ำห้วยโพธิ์',
  },
  {
    id: 387,
    name: 'อ่างเก็บน้ำหนองบ่อ',
  },
  {
    id: 388,
    name: 'อ่างเก็บน้ำห้วยหินชะแนน',
  },
  {
    id: 389,
    name: 'อ่างเก็บน้ำห้วยบัง',
  },
  {
    id: 390,
    name: 'อ่างเก็บน้ำแม่ต๊าก',
  },
  {
    id: 391,
    name: 'อ่างเก็บน้ำห้วยบง',
  },
  {
    id: 392,
    name: 'อ่างเก็บน้ำห้วยน้ำเค็ม',
  },
  {
    id: 393,
    name: 'อ่างเก็บน้ำห้วยยาง',
  },
  {
    id: 394,
    name: 'อ่างเก็บน้ำห้วยลึก',
  },
  {
    id: 395,
    name: 'อ่างเก็บน้ำห้วยก้านเหลือง',
  },
  {
    id: 396,
    name: 'อ่างเก็บน้ำห้วยตะเคียน',
  },
  {
    id: 397,
    name: 'อ่างเก็บน้ำห้วยน้ำใส',
  },
  {
    id: 398,
    name: 'ฝายชลขันธ์พินิจ',
  },
  {
    id: 399,
    name: 'ห้วยท่าแพร',
  },
  {
    id: 400,
    name: 'ฝายหนองหวาย',
  },
  {
    id: 401,
    name: 'แม่ปืม',
  },
  {
    id: 402,
    name: 'อ่างเก็บน้ำโสกสำอางค์',
  },
  {
    id: 403,
    name: 'อ่างเก็บน้ำหนองนาวัว',
  },
  {
    id: 404,
    name: 'อ่างเก็บน้ำห้วยเตย',
  },
  {
    id: 405,
    name: 'อ่างเก็บน้ำโสกรวก',
  },
  {
    id: 406,
    name: 'อ่างเก็บน้ำหนองกรองแก้ว',
  },
  {
    id: 407,
    name: 'อ่างเก็บน้ำห้วยเสียว',
  },
  {
    id: 408,
    name: 'อ่างเก็บน้ำกกม่วง',
  },
  {
    id: 409,
    name: 'อ่างเก็บน้ำแก่งละว้า',
  },
  {
    id: 410,
    name: 'บึงกุดเค้า',
  },
  {
    id: 411,
    name: 'อ่างเก็บน้ำห้วยลอมไผ่',
  },
  {
    id: 412,
    name: 'แม่ต๋ำ',
  },
  {
    id: 413,
    name: 'อ่างเก็บน้ำละเลิงหวาย',
  },
  {
    id: 414,
    name: 'ปตร.น้ำเชิญ',
  },
  {
    id: 415,
    name: 'อ่างเก็บน้ำห้วยจุมจัง',
  },
  {
    id: 416,
    name: 'อ่างเก็บน้ำหนองบ้านสา',
  },
  {
    id: 417,
    name: 'อ่างเก็บน้ำห้วยแกง',
  },
  {
    id: 418,
    name: 'อ่างเก็บน้ำห้วยฝา',
  },
  {
    id: 419,
    name: 'อ่างเก็บน้ำห้วยวังลิ้นฟ้า',
  },
  {
    id: 420,
    name: 'อ่างเก็บน้ำห้วยสีทน',
  },
  {
    id: 421,
    name: 'อ่างเก็บน้ำห้วยผึ้ง',
  },
  {
    id: 422,
    name: 'อ่างเก็บน้ำห้วยมะโน',
  },
  {
    id: 423,
    name: 'อ่างเก็บน้ำห้วยแฮต',
  },
  {
    id: 424,
    name: 'อ่างเก็บน้ำบึงอร่าม',
  },
  {
    id: 425,
    name: 'อ่างเก็บน้ำห้วยสายนาเวียง',
  },
  {
    id: 426,
    name: 'อ่างเก็บน้ำร่องเกษตร',
  },
  {
    id: 427,
    name: 'อ่างเก็บน้ำหนองหญ้าม้า',
  },
  {
    id: 428,
    name: 'อ่างเก็บน้ำห้วยจาน',
  },
  {
    id: 429,
    name: 'อ่างเก็บน้ำห้วยสะทด',
  },
  {
    id: 430,
    name: 'อ่างเก็บน้ำหนองหมาจอก',
  },
  {
    id: 431,
    name: 'อ่างเก็บน้ำห้วยสังเคียบ',
  },
  {
    id: 432,
    name: 'อ่างเก็บน้ำลำพะยัง(ตอนบน)',
  },
  {
    id: 433,
    name: 'อ่างเก็บน้ำห้วยประดู่',
  },
  {
    id: 434,
    name: 'อ่างเก็บน้ำคลองตรอน',
  },
  {
    id: 435,
    name: 'อ่างเก็บน้ำห้วยเชียงคำ',
  },
  {
    id: 436,
    name: 'อ่างเก็บน้ำห้วยคะคาง',
  },
  {
    id: 437,
    name: 'อ่างเก็บน้ำห้วยจอกขวาง',
  },
  {
    id: 438,
    name: 'เอกสัตย์สุนทร',
  },
  {
    id: 439,
    name: 'อ่างเก็บน้ำแก่งเลิงจาน',
  },
  {
    id: 440,
    name: 'อ่างเก็บน้ำหนองไฮ',
  },
  {
    id: 441,
    name: 'อ่างเก็บน้ำหนองบัว',
  },
  {
    id: 442,
    name: 'อ่างเก็บน้ำหนองกระทุ่ม',
  },
  {
    id: 443,
    name: 'อ่างเก็บน้ำฮองซองแมว',
  },
  {
    id: 444,
    name: 'อ่างเก็บน้ำหนองเทวราช',
  },
  {
    id: 445,
    name: 'อ่างเก็บน้ำห้วยมุก',
  },
  {
    id: 446,
    name: 'อ่างเก็บน้ำพุทธอุทธยาน',
  },
  {
    id: 447,
    name: 'อ่างเก็บน้ำหนองแกดำ',
  },
  {
    id: 448,
    name: 'อ่างเก็บน้ำห้วยค้อ',
  },
  {
    id: 449,
    name: 'อ่างเก็บน้ำหนองขอนสัก',
  },
  {
    id: 450,
    name: 'อ่างเก็บน้ำร่องหัวช้าง',
  },
  {
    id: 451,
    name: 'อ่างเก็บน้ำหนองคูขาด',
  },
  {
    id: 452,
    name: 'อ่างเก็บน้ำหนองท่าจอก',
  },
  {
    id: 453,
    name: 'อ่างเก็บน้ำห้วยแล้ง',
  },
  {
    id: 454,
    name: 'อ่างเก็บน้ำห้วยแอ่ง',
  },
  {
    id: 455,
    name: 'อ่างเก็บน้ำหนองผือ',
  },
  {
    id: 456,
    name: 'อ่างเก็บน้ำห้วยจานใต้',
  },
  {
    id: 457,
    name: 'อ่างเก็บน้ำห้วยโพธิ์',
  },
  {
    id: 458,
    name: 'อ่างเก็บน้ำธวัชชัย',
  },
  {
    id: 459,
    name: 'อ่างเก็บน้ำห้วยกุดแดง',
  },
  {
    id: 460,
    name: 'อ่างเก็บน้ำหนองแวง',
  },
  {
    id: 461,
    name: 'อ่างเก็บน้ำห้วยวังนอง',
  },
  {
    id: 462,
    name: 'อ่างเก็บน้ำห้วยกุดแคน',
  },
  {
    id: 463,
    name: 'หนองปลาเฒ่า',
  },
  {
    id: 464,
    name: 'หนองผักปัง',
  },
  {
    id: 465,
    name: 'อ่างเก็บน้ำน้ำพรม',
  },
  {
    id: 466,
    name: 'อ่างเก็บน้ำห้วยทองหลาง',
  },
  {
    id: 467,
    name: 'หนองบัวโคก',
  },
  {
    id: 468,
    name: 'อ่างเก็บน้ำห้วยสีโท',
  },
  {
    id: 469,
    name: 'อ่างเก็บน้ำบ้านเพชร',
  },
  {
    id: 470,
    name: 'อ่างเก็บน้ำห้วยหินลับมีด',
  },
  {
    id: 471,
    name: 'อ่างเก็บน้ำห้วยส้มป่อย',
  },
  {
    id: 472,
    name: 'อ่างเก็บน้ำลำคันฉู',
  },
  {
    id: 473,
    name: 'อ่างเก็บน้ำบาซ่าน',
  },
  {
    id: 474,
    name: 'อ่างเก็บน้ำลำช่อระกา',
  },
  {
    id: 475,
    name: 'เขื่อนสิรินธร',
  },
  {
    id: 476,
    name: 'อ่างเก็บน้ำหนองช้างใหญ่',
  },
  {
    id: 477,
    name: 'อ่างเก็บน้ำห้วยถ้ำแข้',
  },
  {
    id: 478,
    name: 'อ่างเก็บน้ำห้วยวังใหญ่',
  },
  {
    id: 479,
    name: 'อ่างเก็บน้ำคลองมะนาว',
  },
  {
    id: 480,
    name: 'อ่างเก็บน้ำหนองเหล่าหิน',
  },
  {
    id: 481,
    name: 'อ่างเก็บน้ำหัวยวังแดง',
  },
  {
    id: 482,
    name: 'อ่างเก็บน้ำห้วยเดือนห้า',
  },
  {
    id: 483,
    name: 'อ่างเก็บน้ำสระสมิง',
  },
  {
    id: 484,
    name: 'ห้วยพลาญเสือ (ห้วยผึ้ง-ห้วยบอน)',
  },
  {
    id: 485,
    name: 'ห้วยเดือนสะพงน้อย',
  },
  {
    id: 486,
    name: 'อ่างเก็บน้ำห้วยจันลา',
  },
  {
    id: 487,
    name: 'อ่างเก็บน้ำห้วยโดน',
  },
  {
    id: 488,
    name: 'อ่างเก็บน้ำห้วยส้มโฮง',
  },
  {
    id: 489,
    name: 'อ่างเก็บน้ำบุ่งหมากโมง',
  },
  {
    id: 490,
    name: 'อ่างเก็บน้ำลำปะเทีย',
  },
  {
    id: 491,
    name: 'อ่างเก็บน้ำห้วยผักดอก',
  },
  {
    id: 492,
    name: 'อ่างเก็บน้ำห้วยแคน',
  },
  {
    id: 493,
    name: 'อ่างเก็บน้ำห้วยนางออ',
  },
  {
    id: 494,
    name: 'อ่างเก็บน้ำห้วยกกคูณ',
  },
  {
    id: 495,
    name: 'อ่างเก็บน้ำห้วยศรีคุณ',
  },
  {
    id: 496,
    name: 'อ่างเก็บน้ำห้วยวังม่วง',
  },
  {
    id: 497,
    name: 'ร่องกระเบา',
  },
  {
    id: 498,
    name: 'อ่างเก็บน้ำบ้านดงน้อย',
  },
  {
    id: 499,
    name: 'อ่างเก็บน้ำห้วยกะเบา',
  },
  {
    id: 500,
    name: 'อ่างเก็บน้ำห้วยเชียงยืน',
  },
  {
    id: 501,
    name: 'อ่างเก็บน้ำลำจังหัน',
  },
  {
    id: 502,
    name: 'อ่างเก็บน้ำบ้านดอนพัฒนา',
  },
  {
    id: 503,
    name: 'อ่างเก็บน้ำบ้านดงหมู',
  },
  {
    id: 504,
    name: 'อ่างเก็บน้ำห้วยดงน้อย',
  },
  {
    id: 505,
    name: 'อ่างเก็บน้ำห้วยนางยอด',
  },
  {
    id: 506,
    name: 'อ่างเก็บน้ำห้วยตะไถ',
  },
  {
    id: 507,
    name: 'อ่างเก็บน้ำห้วยสิงห์',
  },
  {
    id: 508,
    name: 'อ่างเก็บน้ำบ้านสานแว้',
  },
  {
    id: 509,
    name: 'อ่างเก็บน้ำร่องน้ำซับ',
  },
  {
    id: 510,
    name: 'ฝายลำเซบาย',
  },
  {
    id: 511,
    name: 'อ่างเก็บน้ำห้วยลิงโจน',
  },
  {
    id: 512,
    name: 'ห้วยน้ำฮิ',
  },
  {
    id: 513,
    name: 'อ่างเก็บน้ำห้วยสะแบก',
  },
  {
    id: 514,
    name: 'ฝายยโสธร - พนมไพร',
  },
  {
    id: 515,
    name: 'ลำแชะ',
  },
  {
    id: 516,
    name: 'ฝายวังไม้โกลน',
  },
  {
    id: 517,
    name: 'อ่างพักน้ำ (กฟผ.)',
  },
  {
    id: 518,
    name: 'ขรน. กันผม',
  },
  {
    id: 519,
    name: 'ฝายพะโค',
  },
  {
    id: 520,
    name: 'ฝายปะคำ',
  },
  {
    id: 521,
    name: 'เขื่อนลำนางรอง',
  },
  {
    id: 522,
    name: 'ปตร.ลำพังชู',
  },
  {
    id: 523,
    name: 'อ่างเก็บน้ำน้ำแหง',
  },
  {
    id: 524,
    name: 'ฝายบ้านไพศาล',
  },
  {
    id: 525,
    name: 'ฝายบ้านยางน้อย',
  },
  {
    id: 526,
    name: 'สถานีสูบน้ำลำปลายมาศ',
  },
  {
    id: 527,
    name: 'ฝายกุดชุมแสง',
  },
  {
    id: 528,
    name: 'ฝายบ้านเศวต',
  },
  {
    id: 529,
    name: 'ห้วยชัน',
  },
  {
    id: 530,
    name: 'ฝายห้วยทับทัน',
  },
  {
    id: 531,
    name: 'ฝายบ้านลุมพุก (2)',
  },
  {
    id: 532,
    name: 'ฝายหัวนา',
  },
  {
    id: 533,
    name: 'อ่างเก็บน้ำห้วยระหาร',
  },
  {
    id: 534,
    name: 'อ่างเก็บน้ำน้ำพง',
  },
  {
    id: 535,
    name: 'ฝายบ้านเลิศอรุณ',
  },
  {
    id: 536,
    name: 'ฝายบ้านทุ่งราม',
  },
  {
    id: 537,
    name: 'ฝายยางตระแสง',
  },
  {
    id: 538,
    name: 'หนองกา',
  },
  {
    id: 539,
    name: 'ฝายยางทุ่งมน',
  },
  {
    id: 540,
    name: 'สุวรรณาภา',
  },
  {
    id: 541,
    name: 'ฝายยางเมืองลีง',
  },
  {
    id: 542,
    name: 'ลุงปุง',
  },
  {
    id: 543,
    name: 'ฝายยางบ้านตลุง',
  },
  {
    id: 544,
    name: 'อ่างเก็บน้ำลุ่มน้ำโจน 16',
  },
  {
    id: 545,
    name: 'อ่างเก็บน้ำแม่ทะ',
  },
  {
    id: 546,
    name: 'อ่างเก็บน้ำลาดกระทิง',
  },
  {
    id: 547,
    name: 'อ่างเก็บน้ำคลองระบม',
  },
  {
    id: 548,
    name: 'อ่างเก็บน้ำห้วยสะพาน',
  },
  {
    id: 549,
    name: 'อ่างเก็บน้ำมาบฟักทอง 1',
  },
  {
    id: 550,
    name: 'อ่างเก็บน้ำหนองกลางดง',
  },
  {
    id: 551,
    name: 'อ่างเก็บน้ำมาบฟักทอง 2',
  },
  {
    id: 552,
    name: 'อ่างเก็บน้ำห้วยขุนจิต',
  },
  {
    id: 553,
    name: 'อ่างเก็บน้ำห้วยตู้ 1',
  },
  {
    id: 554,
    name: 'อ่างเก็บน้ำซากนอก',
  },
  {
    id: 555,
    name: 'บ้านบึงขยาย',
  },
  {
    id: 556,
    name: 'อ่างเก็บน้ำห้วยหินลับ',
  },
  {
    id: 557,
    name: 'แม่เลียงพัฒนา',
  },
  {
    id: 558,
    name: 'อ่างเก็บน้ำห้วยตู้ 2',
  },
  {
    id: 559,
    name: 'อ่างเก็บน้ำคลองระโอก',
  },
  {
    id: 560,
    name: 'อ่างเก็บน้ำคลองใหญ่',
  },
  {
    id: 561,
    name: 'อ่างเก็บน้ำทรายทอง',
  },
  {
    id: 562,
    name: 'อ่างเก็บน้ำคลองสีเสียด',
  },
  {
    id: 563,
    name: 'คลองท่าด่าน',
  },
  {
    id: 564,
    name: 'อ่างเก็บน้ำคลองโบด',
  },
  {
    id: 565,
    name: 'อ่างเก็บน้ำบ้านวังม่วง',
  },
  {
    id: 566,
    name: 'อ่างเก็บน้ำห้วยปรือ',
  },
  {
    id: 567,
    name: 'อ่างเก็บน้ำคลองวังบอน',
  },
  {
    id: 568,
    name: 'แม่พริก',
  },
  {
    id: 569,
    name: 'อ่างเก็บน้ำเขาอีโต้ 1',
  },
  {
    id: 570,
    name: 'อ่างเก็บน้ำเขาอีโต้ 2',
  },
  {
    id: 571,
    name: 'อ่างเก็บน้ำทับลาน',
  },
  {
    id: 572,
    name: 'อ่างเก็บน้ำท่ากะบาก',
  },
  {
    id: 573,
    name: 'อ่างเก็บน้ำคลองทราย',
  },
  {
    id: 574,
    name: 'อ่างเก็บน้ำคลองสามสิบ',
  },
  {
    id: 575,
    name: 'อ่างเก็บน้ำคลองเกลือ',
  },
  {
    id: 576,
    name: 'อ่างเก็บน้ำช่องกล่ำบน',
  },
  {
    id: 577,
    name: 'อ่างเก็บน้ำเขาดิน',
  },
  {
    id: 578,
    name: 'อ่างเก็บน้ำช่องกล่ำล่าง',
  },
  {
    id: 579,
    name: 'ห้วยน้ำลาด (อุปโภค-บริโภค)',
  },
  {
    id: 580,
    name: 'อ่างเก็บน้ำเขารัง',
  },
  {
    id: 581,
    name: 'อ่างเก็บน้ำคลองส้มป่อย',
  },
  {
    id: 582,
    name: 'เขื่อนป่าสักชลสิทธิ์',
  },
  {
    id: 583,
    name: 'อ่างเก็บน้ำห้วยพุหวาย',
  },
  {
    id: 584,
    name: 'อ่างเก็บน้ำโป่งทะลุ',
  },
  {
    id: 585,
    name: 'อ่างเก็บน้ำหุบกะพง',
  },
  {
    id: 586,
    name: 'อ่างเก็บน้ำห้วยวังยาว',
  },
  {
    id: 587,
    name: 'อ่างเก็บน้ำห้วยพุน้อย',
  },
  {
    id: 588,
    name: 'อ่างเก็บน้ำยางชุม',
  },
  {
    id: 589,
    name: 'อ่างเก็บน้ำห้วยอ่างหิน',
  },
  {
    id: 590,
    name: 'อ่างเก็บน้ำห้วยสำราญ',
  },
  {
    id: 591,
    name: 'อ่างเก็บน้ำคลองกะทูน',
  },
  {
    id: 592,
    name: 'อ่างเก็บน้ำเสม็ดจวน',
  },
  {
    id: 593,
    name: 'อ่างเก็บน้ำคลองดินแดง',
  },
  {
    id: 594,
    name: 'อ่างเก็บน้ำบางกำปรัด',
  },
  {
    id: 595,
    name: 'อ่างเก็บน้ำห้วยน้ำเขียว',
  },
  {
    id: 596,
    name: 'อ่างเก็บน้ำคลองหยา',
  },
  {
    id: 597,
    name: 'บางทรายนวล',
  },
  {
    id: 598,
    name: 'เขื่อนบางลาง',
  },
  {
    id: 599,
    name: 'อ่างเก็บน้ำคลองจำไหร',
  },
  {
    id: 600,
    name: 'อ่างเก็บน้ำคลองหลา',
  },
  {
    id: 601,
    name: 'อ่างเก็บน้ำห้วยศาลา',
  },
  {
    id: 602,
    name: 'คลองสะเตา',
  },
  {
    id: 603,
    name: 'ใกล้บ้าน',
  },
  {
    id: 604,
    name: 'อ่างเก็บน้ำลุ่มน้ำโจน 2',
  },
  {
    id: 605,
    name: 'อ่างเก็บน้ำหนองหญ้าม้า',
  },
  {
    id: 606,
    name: 'อ่างเก็บน้ำห้วยเตย',
  },
  {
    id: 607,
    name: 'ฝายปะคำ',
  },
  {
    id: 608,
    name: 'อ่างเก็บน้ำหนองกระทุ่ม',
  },
  {
    id: 609,
    name: 'ลุมพุก',
  },
  {
    id: 610,
    name: 'อ่างเก็บน้ำห้วยชัน',
  },
  {
    id: 611,
    name: 'ขรน. พิมาย',
  },
  {
    id: 612,
    name: 'อ่างเก็บน้ำห้วยติ๊กชู',
  },
  {
    id: 613,
    name: 'กุดเข้า',
  },
  {
    id: 614,
    name: 'กุดเลยหลง',
  },
  {
    id: 615,
    name: 'ขุมปูน',
  },
  {
    id: 616,
    name: 'ห้วยม่าน',
  },
  {
    id: 617,
    name: 'ห้วยฮ่องไคร้',
  },
  {
    id: 618,
    name: 'เขื่อนคลองโพธิ์',
  },
  {
    id: 619,
    name: 'แม่เลียบ',
  },
  {
    id: 620,
    name: 'เขื่อนแม่วงก์',
  },
  {
    id: 621,
    name: 'ชากนอก',
  },
  {
    id: 622,
    name: 'อ่างเก็บน้ำบ้านบึง',
  },
  {
    id: 623,
    name: 'อ่างเก็บน้ำห้วยโอตาลัต',
  },
  {
    id: 624,
    name: 'คลองประแสร์',
  },
  {
    id: 625,
    name: 'ห้วยตะเดียน',
  },
  {
    id: 626,
    name: 'ห้วยเขาดิน',
  },
  {
    id: 627,
    name: 'อ่างเก็บน้ำคลองพันโป้',
  },
  {
    id: 628,
    name: 'อ่างเก็บน้ำคลองกลาง',
  },
  {
    id: 629,
    name: 'วังม่วง',
  },
  {
    id: 630,
    name: 'อ่างเก็บน้ำห้วยใหญ่',
  },
  {
    id: 631,
    name: 'อ่างเก็บน้ำห้วยก้านเหลือง',
  },
  {
    id: 632,
    name: 'อ่างเก็บน้ำหนองสอางค์',
  },
  {
    id: 633,
    name: 'คลองศาลทราย',
  },
  {
    id: 634,
    name: 'อ่างเก็บน้ำห้วยแก้ว',
  },
  {
    id: 635,
    name: 'อ่างเก็บน้ำห้วยไร่',
  },
  {
    id: 636,
    name: 'อ่างเก็บน้ำห้วยเชิง',
  },
  {
    id: 637,
    name: 'อ่างเก็บน้ำห้วยขนาดมอญ',
  },
  {
    id: 638,
    name: 'อ่างเก็บน้ำอำปึล',
  },
  {
    id: 639,
    name: 'อ่างเก็บน้ำห้วยเสนง',
  },
  {
    id: 640,
    name: 'อ่างเก็บน้ำห้วยลำพอก',
  },
  {
    id: 641,
    name: 'อ่างเก็บน้ำห้วยด่าน',
  },
  {
    id: 642,
    name: 'อ่างเก็บน้ำบ้านทำนบ',
  },
  {
    id: 643,
    name: 'อ่างเก็บน้ำบ้านจรัส',
  },
  {
    id: 644,
    name: 'อ่างเก็บน้ำบ้านเกาะแก้ว',
  },
  {
    id: 645,
    name: 'ห้วยตาเกาว์',
  },
  {
    id: 646,
    name: 'อ่างเก็บน้ำห้วยไผ่',
  },
  {
    id: 647,
    name: 'สุวรรณาภา',
  },
  {
    id: 648,
    name: 'ห้วยกะเลงเวก',
  },
  {
    id: 649,
    name: 'อ่างเก็บน้ำคลองบอน',
  },
  {
    id: 650,
    name: 'อ่างเก็บน้ำศาลทราย',
  },
  {
    id: 651,
    name: 'อ่างเก็บน้ำคลองพระพุทธ',
  },
  {
    id: 652,
    name: 'อ่างเก็บน้ำซับตะเคียน',
  },
  {
    id: 653,
    name: 'อ่างเก็บน้ำห้วยโป่ง',
  },
  {
    id: 654,
    name: 'อ่างเก็บน้ำกุดตาเพชร',
  },
  {
    id: 655,
    name: 'อ่างเก็บน้ำห้วยใหญ่ (วังแขม)',
  },
  {
    id: 656,
    name: 'อ่างเก็บน้ำห้วยซับประดู่',
  },
  {
    id: 657,
    name: 'อ่างเก็บน้ำห้วยพุ',
  },
  {
    id: 658,
    name: 'ห้วยสีดา',
  },
  {
    id: 659,
    name: 'อ่างเก็บน้ำห้วยหิน',
  },
  {
    id: 660,
    name: 'อ่างเก็บน้ำคลองท่างิ้ว',
  },
  {
    id: 661,
    name: 'อ่างเก็บน้ำคลองป่าบอน',
  },
  {
    id: 662,
    name: 'อ่างเก็บน้ำป่าพะยอม',
  },
  {
    id: 663,
    name: 'อ่างเก็บน้ำคลองช่องลม',
  },
  {
    id: 664,
    name: 'อ่างเก็บน้ำคลองจะกระ',
  },
  {
    id: 665,
    name: 'อ่างเก็บน้ำคลองบึง',
  },
  {
    id: 666,
    name: 'อ่างเก็บน้ำห้วยไทรงาม',
  },
  {
    id: 667,
    name: 'อ่างเก็บน้ำห้วยหิน',
  },
  {
    id: 669,
    name: 'อ่างเก็บน้ำแม่ฮ่องสอน',
  },
  {
    id: 670,
    name: 'อ่างเก็บน้ำดอยงู',
  },
  {
    id: 671,
    name: 'อ่างเก็บน้ำแม่พริก',
  },
  {
    id: 672,
    name: 'อ่างเก็บน้ำแม่เลียง',
  },
  {
    id: 673,
    name: 'อ่างเก็บน้ำแม่ทรายคำ',
  },
  {
    id: 674,
    name: 'อ่างเก็บน้ำห้วยหลวงวังวัว',
  },
  {
    id: 675,
    name: 'อ่างเก็บน้ำแม่ค่อม',
  },
  {
    id: 676,
    name: 'อ่างเก็บน้ำแม่ต๋ำน้อย',
  },
  {
    id: 677,
    name: 'อ่างเก็บน้ำแม่เฟือง',
  },
  {
    id: 678,
    name: 'อ่างเก็บน้ำแม่ไฮ',
  },
  {
    id: 679,
    name: 'อ่างเก็บน้ำห้วยแม่งอน',
  },
  {
    id: 680,
    name: 'อ่างเก็บน้ำแม่ต่ำ',
  },
  {
    id: 681,
    name: 'อ่างเก็บน้ำแม่วะ',
  },
  {
    id: 682,
    name: 'อ่างเก็บน้ำแม่ล้อหัก',
  },
  {
    id: 683,
    name: 'อ่างเก็บน้ำแม่ทก',
  },
  {
    id: 684,
    name: 'อ่างเก็บน้ำแม่เรียง',
  },
  {
    id: 685,
    name: 'อ่างเก็บน้ำแม่กึ๊ด',
  },
  {
    id: 686,
    name: 'อ่างเก็บน้ำห้วยเกี๋ยง',
  },
  {
    id: 687,
    name: 'อ่างเก็บน้ำแม่ปอน',
  },
  {
    id: 688,
    name: 'อ่างเก็บน้ำแม่ยาว',
  },
  {
    id: 689,
    name: 'อ่างแม่ไพร',
  },
  {
    id: 690,
    name: 'อ่างเก็บน้ำห้วยแม่สัน',
  },
  {
    id: 691,
    name: 'อ่างเก็บน้ำแม่พริกผาวิ่งชู้',
  },
  {
    id: 692,
    name: 'อ่างเก็บน้ำแม่ฟ้า',
  },
  {
    id: 693,
    name: 'อ่างเก็บน้ำห้วยน้ำฮิ',
  },
  {
    id: 694,
    name: 'อ่างเก็บน้ำแม่ต๋ำ',
  },
  {
    id: 695,
    name: 'อ่างเก็บน้ำแม่ปืม',
  },
  {
    id: 696,
    name: 'อ่างเก็บน้ำแม่ใจ',
  },
  {
    id: 697,
    name: 'อ่างเก็บน้ำห้วยแม่เฉย',
  },
  {
    id: 698,
    name: 'อ่างเก็บน้ำห้วยป่าบง',
  },
  {
    id: 699,
    name: 'อ่างเก็บน้ำคลองน้ำไหล',
  },
  {
    id: 700,
    name: 'อ่างเก็บน้ำแม่สาย',
  },
  {
    id: 701,
    name: 'อ่างเก็บน้ำห้วยท่าแพ',
  },
  {
    id: 702,
    name: 'อ่างเก็บน้ำห้วยทรวง',
  },
  {
    id: 703,
    name: 'อ่างเก็บน้ำแม่กองค่าย',
  },
  {
    id: 704,
    name: 'อ่างเก็บน้ำทุ่งทะเลหลวง',
  },
  {
    id: 705,
    name: 'อ่างเก็บน้ำห้วยแม่ท้อ',
  },
  {
    id: 706,
    name: 'อ่างเก็บน้ำห้วยน้ำลาด',
  },
  {
    id: 707,
    name: 'อ่างเก็บน้ำคลองโพธิ์',
  },
  {
    id: 708,
    name: 'อ่างเก็บน้ำน้ำเลย',
  },
  {
    id: 709,
    name: 'อ่างเก็บน้ำห้วยศอก',
  },
  {
    id: 710,
    name: 'อ่างเก็บน้ำห้วยทอน (ตอนบน) อ.โพธิ์ตาก',
  },
  {
    id: 711,
    name: 'อ่างเก็บน้ำห้วยหินแก้ว อ.โพธิ์ตาก',
  },
  {
    id: 712,
    name: 'อ่างเก็บน้ำห้วยซำ อ.โซ่พิสัย',
  },
  {
    id: 713,
    name: 'อ่างเก็บน้ำห้วยโทห้วยยาง อ.โคกศรีสุพรรณ',
  },
  {
    id: 714,
    name: 'อ่างเก็บน้ำห้วยหินแตก อ.พรรณานิคม',
  },
  {
    id: 715,
    name: 'อ่างเก็บน้ำห้วยน้ำบ่อ อ.เมือง',
  },
  {
    id: 716,
    name: 'อ่างเก็บน้ำห้วยคำบากน้อย อ.สว่างแดนดิน',
  },
  {
    id: 717,
    name: 'อ่างเก็บน้ำห้วยหินชะแนนใหญ่ อ.กุสุมาลย์',
  },
  {
    id: 718,
    name: 'อ่างเก็บน้ำห้วยคำผักหนาม อ.บ้านม่วง',
  },
  {
    id: 719,
    name: 'อ่างเก็บน้ำหนองบัว อ.พังโคน',
  },
  {
    id: 720,
    name: 'อ่างเก็บน้ำตาดโตน อ.เมือง',
  },
  {
    id: 721,
    name: 'อ่างเก็บน้ำบ้านสร้างแก้ว อ.ภูพาน',
  },
  {
    id: 722,
    name: 'อ่างเก็บน้ำโคกภูใหม่ 2 อ.ภูพาน',
  },
  {
    id: 723,
    name: 'อ่างเก็บน้ำคำประมง อ.พรรณานิคม',
  },
  {
    id: 724,
    name: 'อ่างเก็บน้ำห้วยวังกอไผ่ อ.เจริญศิลป์',
  },
  {
    id: 725,
    name: 'อ่างเก็บน้ำห้วยเคลิ้ม อ.เมือง',
  },
  {
    id: 726,
    name: 'อ่างเก็บน้ำห้วยเรือ อ.เมือง',
  },
  {
    id: 727,
    name: 'อ่างเก็บน้ำห้วยอ่าง อ.เมือง',
  },
  {
    id: 728,
    name: 'อ่างเก็บน้ำห้วยทรายตอนบน 1 อ.เมือง',
  },
  {
    id: 729,
    name: 'อ่างเก็บน้ำหนองบ่อ อ.หนองหาน',
  },
  {
    id: 730,
    name: 'อ่างเก็บน้ำโสกรัง อ.โนนสะอาด',
  },
  {
    id: 731,
    name: 'อ่างเก็บน้ำลำพันชาด อ.วังสามหมอ',
  },
  {
    id: 732,
    name: 'อ่างเก็บน้ำลำพันชาดน้อย อ.วังสามหมอ',
  },
  {
    id: 733,
    name: 'อ่างเก็บน้ำห้วยน้ำบอง',
  },
  {
    id: 734,
    name: 'อ่างเก็บน้ำเอกสัตย์สุนทร',
  },
  {
    id: 735,
    name: 'อ่างเก็บน้ำห้วยพุงใหญ่',
  },
  {
    id: 736,
    name: 'อ่างเก็บน้ำห้วยไร่2',
  },
  {
    id: 737,
    name: 'อ่างเก็บน้ำห้วยหมากไฟ',
  },
  {
    id: 738,
    name: 'อ่างเก็บน้ำห้วยขาหน้า',
  },
  {
    id: 739,
    name: 'อ่างเก็บน้ำห้วยไม้ดู่',
  },
  {
    id: 740,
    name: 'อ่างเก็บน้ำห้วยหินลาด',
  },
  {
    id: 741,
    name: 'อ่างเก็บน้ำฝายน้ำล้นห้วยปุ่ง',
  },
  {
    id: 742,
    name: 'อ่างเก็บน้ำห้วยคันแทใหญ่',
  },
  {
    id: 743,
    name: 'อ่างเก็บน้ำห้วยพลาญเสือ(ห้วยผึ้ง)',
  },
  {
    id: 744,
    name: 'อ่างเก็บน้ำห้วยสะพงน้อย',
  },
  {
    id: 745,
    name: 'อ่างเก็บน้ำห้วยละมืด',
  },
  {
    id: 746,
    name: 'อ่างเก็บน้ำห้วยหินกอง',
  },
  {
    id: 747,
    name: 'อ่างเก็บน้ำห้วยโพงตอนบน',
  },
  {
    id: 748,
    name: 'อ่างเก็บน้ำลำเชียงสา',
  },
  {
    id: 749,
    name: 'อ่างเก็บน้ำบ้านสันกำแพง',
  },
  {
    id: 750,
    name: 'อ่างเก็บน้ำห้วยตะแบง',
  },
  {
    id: 751,
    name: 'อ่างเก็บน้ำห้วยตาเกาว์',
  },
  {
    id: 752,
    name: 'อ่างเก็บน้ำสุวรรณาภา',
  },
  {
    id: 753,
    name: 'อ่างเก็บน้ำห้วยกะเลงเวก',
  },
  {
    id: 754,
    name: 'อ่างเก็บน้ำหนองกา',
  },
  {
    id: 755,
    name: 'อ่างเก็บน้ำลุมพุก',
  },
  {
    id: 756,
    name: 'อ่างเก็บน้ำลุงปุง',
  },
  {
    id: 757,
    name: 'อ่างเก็บน้ำลำเชียงไกร ตอนบน',
  },
  {
    id: 758,
    name: 'อ่างเก็บน้ำลำเชียงไกร ตอนล่าง',
  },
  {
    id: 759,
    name: 'อ่างเก็บน้ำคลองหลวง รัชชโลทร',
  },
  {
    id: 760,
    name: 'อ่างเก็บน้ำคลองไม้ปล้อง',
  },
  {
    id: 761,
    name: 'อ่างเก็บน้ำคลองสะพานหิน',
  },
  {
    id: 762,
    name: 'อ่างเก็บน้ำคลองโสน',
  },
  {
    id: 763,
    name: 'อ่างเก็บน้ำคลองตาด้วง',
  },
  {
    id: 764,
    name: 'อ่างเก็บน้ำคลองพระสทึง',
  },
  {
    id: 765,
    name: 'อ่างเก็บน้ำห้วยป่าเลา',
  },
  {
    id: 766,
    name: 'อ่างเก็บน้ำห้วยน้ำก้อ',
  },
  {
    id: 767,
    name: 'อ่างเก็บน้ำห้วยนา',
  },
  {
    id: 768,
    name: 'อ่างเก็บน้ำคลองลำกง',
  },
  {
    id: 769,
    name: 'อ่างเก็บน้ำห้วยน้ำชุนใหญ่',
  },
  {
    id: 770,
    name: 'อ่างเก็บน้ำห้วยเล็ง',
  },
  {
    id: 771,
    name: 'อ่างฯ ห้วยท่าพล',
  },
  {
    id: 772,
    name: 'อ่างเก็บน้ำสีดา',
  },
  {
    id: 773,
    name: 'อ่างเก็บน้ำซับประดู่',
  },
  {
    id: 774,
    name: 'อ่างเก็บน้ำมวกเหล็ก',
  },
  {
    id: 775,
    name: 'อ่างเก็บน้ำลำตะเพิน',
  },
  {
    id: 776,
    name: 'อ่างเก็บน้ำห้วยเทียน',
  },
  {
    id: 777,
    name: 'อ่างเก็บน้ำพุตะเคียน',
  },
  {
    id: 778,
    name: 'อ่างเก็บน้ำชัฎป่าหวาย',
  },
  {
    id: 779,
    name: 'อ่างเก็บน้ำห้วยสำนักไม้เต็ง',
  },
  {
    id: 780,
    name: 'อ่างเก็บน้ำห้วยมะหาด',
  },
  {
    id: 781,
    name: 'อ่างเก็บน้ำท่าเคย',
  },
  {
    id: 782,
    name: 'อ่างเก็บน้ำกระหร่างสาม',
  },
  {
    id: 783,
    name: 'อ่างเก็บน้ำห้วยแม่ประจันต์',
  },
  {
    id: 784,
    name: 'อ่างเก็บน้ำห้วยวังเต็น',
  },
  {
    id: 785,
    name: 'อ่างเก็บน้ำห้วยมงคล อันเนื่องมาจากพระราชดำริ',
  },
  {
    id: 786,
    name: 'อ่างเก็บน้ำคลองแห้ง',
  },
  {
    id: 787,
    name: 'อ่างเก็บน้ำบางเหนียวดำ',
  },
  {
    id: 788,
    name: 'อ่างเก็บน้ำบ้านพรุเตย',
  },
  {
    id: 789,
    name: 'อ่างเก็บน้ำบ้านทุ่งหลวง',
  },
  {
    id: 790,
    name: 'อ่างเก็บน้ำคลองหัวช้าง',
  },
  {
    id: 791,
    name: 'อ่างเก็บน้ำคลองสะเดา',
  },
  {
    id: 792,
    name: 'อ่างเก็บน้ำบางทรายนวล',
  },
  {
    id: 793,
    name: 'อ่างเก็บน้ำคลองสวนหนัง',
  },
  {
    id: 794,
    name: 'อ่างเก็บน้ำใกล้บ้าน',
  },
  {
    id: 795,
    name: 'อ่างเก็บน้ำคลองหาดส้มแป้น',
  },
];

export const damsEgat = [
  { id: 43, name: 'ภูมิพล' },
  { id: 44, name: 'สิรินธร' },
  { id: 45, name: 'จุฬาภรณ์' },
  { id: 46, name: 'น้ำพุง' },
  { id: 47, name: 'ห้วยกุ่ม' },
  { id: 48, name: 'ปากมูล' },
  { id: 49, name: 'รัชชประภา' },
  { id: 50, name: 'บางลาง' },
  { id: 51, name: 'เขื่อนแม่ปิงตอนล่าง' },
  { id: 52, name: 'สิริกิติ์' },
  { id: 53, name: 'แม่งัด' },
  { id: 54, name: 'ศรีนครินทร์' },
  { id: 55, name: 'ท่าทุ่งนา' },
  { id: 56, name: 'วชิราลงกรณ' },
  { id: 57, name: 'แก่งกระจาน' },
  { id: 58, name: 'คิรีธารี' },
  { id: 59, name: 'อุบลรัตน์' },
  { id: 231, name: 'แม่จาง' },
];

export const regionTmd = [
  { id: 1, name: 'ภาคกลาง' },
  { id: 2, name: 'ภาคตะวันออก' },
  { id: 3, name: 'ภาคเหนือ' },
  { id: 4, name: 'ภาคตะวันออกเฉียงเหนือ' },
  { id: 5, name: 'ภาคใต้ฝั่งตะวันออก' },
  // { id: 6, name: 'ภาคใต้ฝั่งตะวันตก' },
  // { id: 9, name: 'อื่นๆ' },
];

export const regionRid = [
  { id: 1, name: 'ภาคเหนือ' },
  { id: 2, name: 'ภาคตะวันออกเฉียงเหนือ' },
  { id: 3, name: 'ภาคกลาง' },
  { id: 4, name: 'ภาคตะวันตก' },
  { id: 5, name: 'ภาคตะวันออก' },
  { id: 6, name: 'ภาคใต้' },
];
