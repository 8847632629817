import React, { Component } from 'react';
import PropTypes from 'prop-types';

// moment
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// lodash
import {
  uniqueId,
} from 'lodash';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
// icon .
import {
  FaPlay,
  FaSpinner,
  FaCalendarAlt,
} from '../../../utils/Fontawesome';

import ImageAnimation from '../ImageAnimation';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

// credit tooltip
import CreditTooltip from '../../../utils/CreditTooltip';

const initPath1 = process.env.MIX_APP_PATH;
const imgWidth = '90%';

// lightbox
const images = [];

class Sst extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      imgs: [],

      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
      open: false,
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({
      isLoading: true,
    });

    // get data from ws
    this.getData();
  }

  getData() {
    fetch(`${process.env.MIX_API_URL}public/weather_img/sst_ocean_weather`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '40%';
    let heightChart = 'max-content';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = 'max-content';
    }
    if (isWidthUp('md', width)) {
      widthChart = '40%';
      heightChart = 'max-content';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  };

  handleModalAnimation = (agencyId, mediaTypeId, imageType) => e => {
    const { t } = this.props;
    e.preventDefault();
    const params = {
      header: t('ภาพเคลื่อนไหว'),
      content:
      (
        <div style={{
          width: '550px',
        }}
        >
          <ImageAnimation agencyId={agencyId} mediaTypeId={mediaTypeId} imageType={imageType} />
        </div>

      ),
    };

    this.handleModalOpen(params);
  }

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, t } = this.props;
    const {
      isLoading,
      error,
      imgs,
      photoIndex,
      isOpen,
      modalHeader,
      modalSubHeader,
      modalContent,
      open,
    } = this.state;

    return (
      <>
        {error && <Grid className="text-center">{error.message}</Grid>}

        {isLoading === true && (
        <Grid className="text-center">
          <FaSpinner size={35} />
        </Grid>
        )}

        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid style={{ width: '100%' }}>
              <Box mt={3} mb={2} flexGrow={1} display="flex" alignItems="center" className={classes.boxTopRadiusPrimary} p={1}>
                <Box p={1} className={classes.titleBar}>
                  {t('แผนภาพอุณหภูมิผิวน้ำทะเล')}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          { imgs.map((row, i) => {
            let realImage = '';
            if (row.cover_image.media_path !== '') {
              images.push({
                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.media_path}`,
                title: `${row.description.description_name.th.replace('โดย Ocean Weather inc.', '')} ${moment(row.cover_image.media_datetime).format('ll')}`,
              });
              realImage = (
                <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                  <Img
                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.thumbnail_media_path}`}
                    width={imgWidth}
                    alt={moment(row.cover_image.media_datetime).format('ll')}
                    className={classes.responsive}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src={row.src}
                  width={imgWidth}
                  alt="No Data"
                  className={classes.responsive}
                  style={{ height: 185 }}
                />
              );
            }
            return (
              <Grid item xs={12} md={3} lg={3} key={uniqueId()}>
                <Box display="flex" className={classes.boxTopRadius} alignItems="center">
                  <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
                    {t(row.detail[0].description.th)}
                  </Box>
                  <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                    <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
                      <Button
                        className={classes.outlinedOldLeftPrimary}
                        onClick={this.handleModalAnimation(row.agency.agency_id,
                          row.detail[0].media_type_id, '')}
                      >
                        <FaPlay style={{ fontSize: '1em' }} />
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                    <Tooltip title={t('ดูภาพย้อนหลัง')}>
                      <Button href={`${initPath1}weather/sea/sst/history/${row.detail[0].media_type_id}`}>
                        <FaCalendarAlt style={{ fontSize: '1.3em' }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display="flex" className={classes.boxBottomFixibleRadius} alignItems="center" justifyContent="center">
                  <Box>
                    <CreditTooltip
                      title={row.agency.agency_name.th}
                      link={row.agency.agency_link}
                    >
                      {realImage}
                    </CreditTooltip>
                    <Box p={1} display="flex" alignItems="center" justifyContent="center">
                      <Typography className={classes.dateTime}>
                        {`${t('ล่าสุด')} ${moment(row.cover_image.media_datetime).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.cover_image.media_datetime).format('HH:mm')} ${t('น.')}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleModalClose}
        >
          <div style={this.getModalStyle()} className={classes.paperModal}>
            <Typography variant="h6" id="modal-title">
              {modalHeader}
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              {modalSubHeader}
            </Typography>
            <Box mt={3}>
              <div className={classes.paper} style={{ padding: '0px !important' }}>
                {modalContent}
              </div>
            </Box>
          </div>
        </Modal>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

Sst.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles, { withTheme: true }), withTranslation(),
)(Sst);
