export const hiiSituation = [
    {
        'id': 1,
        'name': 'แจ้งเตือนสถานการณ์',
        'type': 'cover',
        'title': '01_Situation Alert/cover',
        'folderId': '1pcyNBB8KuawgIykk2QaofUsYcgAOlnGm',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1pcyNBB8KuawgIykk2QaofUsYcgAOlnGm?usp=sharing',
    },
    {
        'id': 2,
        'name': 'อินโฟกราฟฟิก',
        'type': 'cover',
        'title': '02_Infographic',
        'folderId': '1WpeBDv8P8RcS_3ZJoeAW3THTxtzmb7et',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1WpeBDv8P8RcS_3ZJoeAW3THTxtzmb7et?usp=sharing',
    },
    {
        'id': 3,
        'name': 'รายงานประจำวัน',
        'type': 'cover',
        'title': '03_DaliyReport',
        'folderId': '1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1nshyi62gSoLnFwiBsUSQ7C-_IeBB0DCY?usp=sharing',
    },
]

export const hiiSituation01 = [
    {
        'id': 1,
        'name': 'แจ้งเตือนสถานการณ์',
        'yearThai': '2567',
        'yearEng': '2024',
        'type': 'cover',
        'title': '01_Situation',
        'folderId': '1NPzhZJkEv1p7IIPjW9NYMhALx_KJyW1K',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1WpeBDv8P8RcS_3ZJoeAW3THTxtzmb7et?usp=sharing',
    },
    {
        'id': 2,
        'name': 'แจ้งเตือนสถานการณ์',
        'yearThai': '2568',
        'yearEng': '2025',
        'type': 'cover',
        'title': '01_Situation Alert/cover',
        'folderId': '1jyQ0iQFcWKzhgLUvg3Fapr2IY6YCL5bJ',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1jyQ0iQFcWKzhgLUvg3Fapr2IY6YCL5bJ?usp=sharing',
    },
]

export const hiiSituation02 = [
    {
        'id': 1,
        'name': 'อินโฟกราฟฟิก',
        'yearThai': '2567',
        'yearEng': '2024',
        'type': 'cover',
        'title': '01_Situation Alert/cover',
        'folderId': '1MmaUywLZnaEIPF29erNMslVWmORvTF6R',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1MmaUywLZnaEIPF29erNMslVWmORvTF6R?usp=sharing',
    },
    {
        'id': 2,
        'name': 'อินโฟกราฟฟิก',
        'yearThai': '2568',
        'yearEng': '2025',
        'type': 'cover',
        'title': '01_Situation Alert/cover',
        'folderId': '1F5ZKid9forL_eia2US7bLbyUK3kv9EtK',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1F5ZKid9forL_eia2US7bLbyUK3kv9EtK?usp=sharing',
    },
]

export const hiiSituation03 = [
    {
        'id': 1,
        'name': 'รายงานประจำวัน',
        'yearThai': '2567',
        'yearEng': '2024',
        'type': 'cover',
        'title': '01_Situation Alert/cover',
        'folderId': '1a5isXYacymkHyaU98zqfpbvBTWKYh-h-',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1eQYgEbzSud5trJXLKjhxDAm-PbkxUDFu?usp=sharing',
    },
    {
        'id': 2,
        'name': 'รายงานประจำวัน',
        'yearThai': '2568',
        'yearEng': '2025',
        'type': 'cover',
        'title': '01_Situation Alert/cover',
        'folderId': '1fmUk_40GNKePFXEdx0MEvQXZD4L3hmJg',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1XJVavZZ-aoy_aUFkzYbxnIOMfzQrdWMh',
        'history': 'https://drive.google.com/drive/folders/1fgPTM9WNclKdyOg7OIVTPS-aZQ2O0h4j?usp=sharing',
    },
]

export const gistdaSituation = [
    {
        'id': 1,
        'name': 'ภาพถ่ายดาวเทียมพื้นที่น้ำท่วม',
        'type': 'cover',
        'title': '01_Radar Image',
        'folderId': '1KlcAwo_aoPZ57BeCsf1aO-CXcWEmN6EA',
        'full_url': 'https://drive.google.com/drive/folders/1KlcAwo_aoPZ57BeCsf1aO-CXcWEmN6EA',
        'history': 'https://drive.google.com/drive/folders/1KlcAwo_aoPZ57BeCsf1aO-CXcWEmN6EA',
    },
    {
        'id': 2,
        'name': 'สรุปสถานการณ์จากภาพถ่ายดาวเทียม',
        'type': 'cover',
        'title': '02_Onepage',
        'folderId': '1sO5nfTXWPGGoSkIW5toaj_cwJ-xj7PXY',
        'full_url': 'https://drive.google.com/drive/folders/1sO5nfTXWPGGoSkIW5toaj_cwJ-xj7PXY',
        'history': 'https://drive.google.com/drive/folders/1sO5nfTXWPGGoSkIW5toaj_cwJ-xj7PXY',
    }
]

//ภาพ highlight
export const newsOperation01 = [
    {
        'id': 1,
        'name': 'ภารกิจศูนย์ปฏิบัติการฯ',
        'yearThai': '2568',
        'yearEng': '2025',
        'type': 'highlight',
        'title': 'News Operations Center',
        'folderId': '1jzeAMTcIWJDgzAcMjx2p0Ywvmt8tH6VU',
        'full_url': 'https://drive.google.com/drive/folders/1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
        'history': 'https://drive.google.com/drive/folders/1pcyNBB8KuawgIykk2QaofUsYcgAOlnGm?usp=sharing',
    },
    {
        'id': 2,
        'name': 'ภารกิจศูนย์ปฏิบัติการฯ',
        'yearThai': '2568',
        'yearEng': '2025',
        'type': 'news',
        'title': 'News Operations Center',
        'folderId': '1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
        'full_url': 'https://drive.google.com/drive/folders/1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
        'history': 'https://drive.google.com/drive/folders/1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
    },
];

export const newsOperation02 = [
    {
        'id': 1,
        'name': 'ภารกิจศูนย์ปฏิบัติการฯ',
        'yearThai': '2567',
        'yearEng': '2024',
        'type': 'news',
        'title': 'News Operations Center',
        'folderId': '1Hayv26b4W2MMjIPrO8EHfYP_NEtoK85R',
        'full_url': 'https://drive.google.com/drive/folders/1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
        'history': 'https://drive.google.com/drive/folders/1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
    },
    {
        'id': 2,
        'name': 'ภารกิจศูนย์ปฏิบัติการฯ',
        'yearThai': '2568',
        'yearEng': '2025',
        'type': 'news',
        'title': 'News Operations Center',
        'folderId': '1yxrBQ7ZNSiE0Ov78eHPeYgJoVHRU_50g',
        'full_url': 'https://drive.google.com/drive/folders/1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
        'history': 'https://drive.google.com/drive/folders/1iH2bW9CSGEr-2PQ61EpoTCf_-Tp7SjEz',
    },
]

export const popupSituation = [
    {
        'id': 1,
        'name': 'หน้า popup',
        'type': 'popup',
        'title': 'popup',
        'folderId': '1Fv6QDd0j4oDlwG4l74mcRA33Ffnc2Nng',
        'full_url': 'https://drive.google.com/drive/u/0/folders/1Fv6QDd0j4oDlwG4l74mcRA33Ffnc2Nng',
        'history': 'https://drive.google.com/drive/folders/1pcyNBB8KuawgIykk2QaofUsYcgAOlnGm?usp=sharing',
    },
]
// key จริง
// export const apiKeyGoogle = 'AIzaSyCJTxB2Zoq2wz-bv4k1qS7xG-74aSsnBMU'; 
export const apiKeyGoogle = 'mhesi-warroom'; //key ที่แสดงให้เห็น จากหน้าเว็ป