import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';

import { withTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/moment';
// import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { FaSpinner } from '../../utils/Fontawesome';

import tJson from '../../utils/TJson';

import {
  styles,
} from '../../styles/Style';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);
/**
 * ComponentName
 */
class SealevelChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      max: 0,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      provinceId: props.provinceId,
      teleStation: [],
      startDate: moment()
        .subtract(3, 'days')
        .locale('th')
        .format('YYYY-MM-DD'),
      endDate: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      today: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getProvince();
    this.getStation();
    this.renderChart();
  }

  static getDerivedStateFromProps(props, state) {
    let shouldRerenderChart = false;
    const { id } = state;
    shouldRerenderChart = shouldRerenderChart || id !== props.id;
    if (shouldRerenderChart) {
      this.setState({ id: props.id });
    }
    return null;
  }

  // componentWillReceiveProps(nextProps) {
  //   this.renderChart();
  //   let shouldRerenderChart = false;

  //   const { id } = this.state;
  //   shouldRerenderChart = shouldRerenderChart || id !== nextProps.id;

  //   if (shouldRerenderChart) {
  //     this.setState({ id: nextProps.id });
  //     this.renderChart();
  //   }
  // }

  getChartOptions() {
    const { t } = this.props;

    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      annotations: [
        {
          labels: [
            {
              point: { x: 5, y: 5 },
              useHTML: true,
              text: `&#x25B2; ${t('จุดที่มีระดับน้ำสูงสุด')}/${t('ต่ำสุด')}`,
            },
          ],
        },
      ],
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: `${t('ระดับน้ำ')} (${t('ม.รทก.')})`,
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: 'black',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
        plotBands: [
          {
            // > 100
            from: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำล้นตลิ่ง')} ( > 100% )</strong>`,
              style: {
                color: '#ff0000',
              },
            },
          },
          {
            // > 70-100
            from: null,
            to: null,
            color: 'rgba(0, 0, 0, 0)',
            label: {
              text: `<strong>${t('น้ำมาก')} ( > 70-100% )</strong>`,
              style: {
                color: '#003cfa',
              },
            },
          },
          {
            // > 30-70
            from: null,
            to: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำปานกลาง')} ( > 30-70% )</strong>`,
              style: {
                color: '#00b050',
              },
            },
          },
          {
            // > 10-30
            from: null,
            to: null,
            color: 'rgba(0, 0, 0, 0)',
            label: {
              text: `<strong>${t('น้ำน้อย')} ( > 10-30% )</strong>`,
              style: {
                color: '#ffc000',
              },
            },
          },
          {
            // <= 10
            from: null,
            to: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำน้อยวิกฤต')} ( < 10% )</strong>`,
              style: {
                color: '#db802b',
              },
            },
          },
        ],
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: t('ระดับน้ำ'),
          data: [],
          tooltip: {
            valueSuffix: t('ม.รทก.'),
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getProvince() {
    fetch(`${process.env.MIX_API_URL}iframe/waterlevel`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataProvince = result.data.filter(d => (
          d.province_code === '76'
          || d.province_code === '74'
          || d.province_code === '11'
        )).sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));
        this.setState({
          dataProvince,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getStation() {
    const { provinceId } = this.state;
    fetch(
      `${
        process.env.MIX_API_URL
      }frontend/shared/tele_canal_station?province_code=${provinceId}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const teleStation = result.data.tele_waterlevel.filter(d => (
          d.station_id === 770
          || d.station_id === 753
          || d.station_id === 17
        ));

        this.setState({
          teleStation,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value }, () => {
      this.getStation();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => {
      this.handleChart();
    });
  };

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const { t } = this.props;
    const { id, startDate, endDate } = this.state;
    const currentTimeStamp = moment().format('HH:mm');
    fetch(
      `${
        process.env.MIX_API_URL
      }public/waterlevel_graph?station_type=tele_waterlevel&station_id=${id}&start_date=${startDate}&end_date=${endDate} ${currentTimeStamp}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const dataSeries = [];
        let ts = null;
        let val = null;

        // find max, min
        const max = maxBy(result.data.graph_data, 'value') === undefined
          ? 0
          : maxBy(result.data.graph_data, 'value').value;
        const min = minBy(result.data.graph_data, 'value') === undefined
          ? 0
          : minBy(result.data.graph_data, 'value').value;
        this.setState({
          max,
        });

        result.data.graph_data.map(n => {
          val = parseFloat(n.value);
          ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss')
            .add(7, 'hours')
            .valueOf();

          if (val === max || val === min) {
            const marker = {
              enabled: true,
              symbol: 'triangle',
              fillColor: '#06C',
              radius: 5,
            };

            dataSeries.push({ x: ts, y: val, marker });
          } else {
            dataSeries.push([ts, val]);
          }

          return dataSeries;
        });

        // set chart options
        const chartOptions = this.getChartOptions();
        const depth = parseFloat(
          result.data.min_bank - result.data.ground_level,
        );

        chartOptions.yAxis.max = max;
        chartOptions.yAxis.min = min;
        chartOptions.series[0].data = dataSeries;

        // plotLines
        if (result.data.min_bank) {
          chartOptions.yAxis.plotLines[0].value = result.data.min_bank;
          chartOptions.yAxis.plotLines[0].label.text = `${t('ระดับตลิ่ง')} <strong>${
            result.data.min_bank
          }</strong> ${t('ม.รทก.')}`;
        }

        if (result.data.ground_level) {
          chartOptions.yAxis.plotLines[1].value = result.data.ground_level;
          chartOptions.yAxis.plotLines[1].label.text = `${t('ระดับท้องน้ำ')} <strong>${parseFloat(
            Math.round(result.data.ground_level * 100) / 100,
          ).toFixed(2)}</strong> ${t('ม.รทก.')}`;
        }

        // plotBands
        if (result.data.min_bank && result.data.ground_level) {
          chartOptions.yAxis.plotBands[0].from = result.data.min_bank;
          chartOptions.yAxis.plotBands[1].from = result.data.ground_level + depth * 0.7;
          chartOptions.yAxis.plotBands[1].to = result.data.min_bank;
          chartOptions.yAxis.plotBands[2].from = result.data.ground_level + depth * 0.3;
          chartOptions.yAxis.plotBands[2].to = result.data.ground_level + depth * 0.7;
          chartOptions.yAxis.plotBands[3].from = result.data.ground_level + depth * 0.1;
          chartOptions.yAxis.plotBands[3].to = result.data.ground_level + depth * 0.3;
          chartOptions.yAxis.plotBands[4].from = result.data.ground_level;
          chartOptions.yAxis.plotBands[4].to = result.data.ground_level + depth * 0.1;
        }

        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  };

  render() {
    const {
      dataProvince, id, max, provinceId, teleStation, startDate, endDate, today,
    } = this.state;
    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      );
    }

    let chart = '';
    let info = '';
    if (max === 0) {
      chart = (
        <Paper className={classes.paper}>{t('ไม่มีข้อมูลในช่วงเวลาที่ท่านเลือก')}</Paper>
      );
      info = '';
    } else {
      chart = (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      );
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {'*'}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    }

    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select value={provinceId} onChange={this.handleProvinceChange}>
                {dataProvince.map(row => (
                  <MenuItem key={row.province_code} value={row.province_code}>
                    {tJson(row.province_name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <Select value={id} onChange={this.handleStationChange}>
                {teleStation.map(row => (
                  <option key={row.station_id} value={row.station_id}>
                    {tJson(row.station_name)}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {chart}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

SealevelChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(SealevelChart));
