import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Slider from 'react-slick';
import './slick.css';

const useStyles = makeStyles(theme => ({
  content: {
    // padding: theme.spacing(3),
    width: '100%',
    color: 'white',
    // backgroundColor: theme.palette.background.paper,
    zIndex: 999999,
  },
}));

// img
const initPath = process.env.MIX_APP_PATH;

export default function Slide() {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Box display="block" justifyContent="center" className={classes.content} padding={{ xs: 0, sm: 0, md: 3 }}>
      <Slider {...settings}>
        <div className="slider">
          <div className="slide">
            <img src={`${initPath}images/theme/bma/instruction/instruction_01.jpg`} alt="BMA" />
          </div>
        </div>
        <div className="slider">
          <div className="slide">
            <img src={`${initPath}images/theme/bma/instruction/instruction_02.jpg`} alt="BMA" />
          </div>
        </div>
        <div className="slider">
          <div className="slide">
            <img src={`${initPath}images/theme/bma/instruction/instruction_03.jpg`} alt="BMA" />
          </div>
        </div>
      </Slider>
    </Box>
  );
}
