/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

// meterial ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// lodash
import {
  uniqueId,
} from 'lodash';

// images
import Lightbox from 'react-image-lightbox';
import Img from 'react-image';
import {
  FaSpinner,
} from '../../utils/Fontawesome';

// style ,css
import { styles } from '../../styles/Style';

const currentDate = new Date(moment());

class GsImageHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(moment()),
      mvalue: { year: moment().format('YYYY'), month: moment().format('M') },
      HistoryData: [],
      isOpen: false,
      photoIndex: 0,
      isLoading: false,
      lightboxImage: [],
    };
    this.initialState = { ...this.state };
  }

  componentDidMount() {
    const { startDate } = this.state;
    this.getGsmapHistoryData(moment().toDate());
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  getGsmapHistoryData(dateCurrent) {
    this.setState({ isLoading: true });
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }
    const toDay = dateCurrent;
    const monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const months = toDay.getMonth() + 1;
    const formatMonths = ((`${months}`).length < 2 ? '0' : '') + months;
    const years = toDay.getFullYear();
    const maxDay = daysInMonth(months, years);
    const dataHis = [];
    const dataLightbox = [];

    const url = `${process.env.MIX_APP_URL}proxy/json.php?file=${process.env.MIX_API_HII_URL}/v2/ws/${process.env.MIX_API_HII_TOKEN}/gsmap-asean/history/${years}/${formatMonths}`;
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(result => {
        if (result.data.length !== 0) {
          let isYear = '';
          let isMonths = '';
          for (let i = 1; i <= maxDay; i += 1) {
            if (i <= result.data.length) {
              const [year, month, day] = result.data[i - 1].date.split('-');
              isYear = year;
              isMonths = month;
              dataHis.push({
                url: result.data[i - 1].url,
                date: `${monthsName[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`,
              });
            } else {
              dataHis.push({
                url: '',
                date: `${monthsName[parseInt(isMonths, 10) - 1]} ${i}, ${isYear}`,
              });
            }
          }
          dataHis.map(datas => {
            if (datas.url !== '') {
              dataLightbox.push({
                url: datas.url,
                date: datas.date,
              });
            }
          });
          this.setState({ lightboxImage: dataLightbox });
          this.setState({ HistoryData: dataHis });
          this.setState({ isLoading: false });
        } else {
          this.setState({ HistoryData: [] });
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    this.forceUpdate();
  }

  handleDateChange = date => {
    const day = date._d;
    this.getGsmapHistoryData(day);
    this.setState({ startDate: day });
    this.forceUpdate();
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate;
    if (change === '1') {
      selectedDate = moment(this.state.startDate).add(1, 'months');
    } else if (change === '-1') {
      selectedDate = moment(this.state.startDate).add(-1, 'months');
    }
    const day = selectedDate._d;
    this.getGsmapHistoryData(day);
    this.setState({
      startDate: selectedDate,
    });
    this.forceUpdate();
  }

  render() {
    const { classes } = this.props;
    const {
      startDate, lightboxImage, HistoryData, isOpen, photoIndex, isLoading,
    } = this.state;
    return (
      <>
        <Grid container justifyContent="center" spacing={4} style={{ paddingTop: 10, marginTop: 20 }}>
          <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={3} md={3}>
            <Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale="en">
                <KeyboardDatePicker
                  openTo="month"
                  views={['month', 'year']}
                  value={startDate}
                  onChange={this.handleDateChange}
                  style={{ fontSize: 14 }}
                  maxDate={currentDate}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Grid>
          <Grid item><Button onClick={e => this.handleDateChangeButton('1')} disabled={currentDate < startDate}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>
        {isLoading ? (
          <>
            <Grid container justifyContent="center" spacing={4} style={{ paddingTop: 10, marginTop: 20 }}>
              <div className="text-center">
                <FaSpinner size={30} />
              </div>
            </Grid>
          </>
        ) : (HistoryData.length !== 0) ? (
          <Grid container spacing={4} style={{ paddingTop: 20 }}>
            {HistoryData.map((result, i) => (
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                <Box mt={1} className={classes.boxWeatherHistory}>
                  <Box display="block" align="center">
                    <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })} disabled={result.url === ''}>
                      <Img
                        src={`${process.env.MIX_APP_URL}proxy/png.php?file=${result.url}`}
                        className={classes.responsive}
                        alt="GsMapImageHistory"
                      // onError={e => { e.target.onerror = null; e.target.src = 'https://via.placeholder.com/204x375&text=No%20Image'; }}
                        unloader={(
                          <Img
                            src="https://via.placeholder.com/576x516?text=No%20Image"
                            alt="No Image"
                            className={classes.responsive}
                          />
                      )}
                      />
                    </Button>
                  </Box>
                  <Box p={2} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      {result.date}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            <div className="text-center">No data for the selected time period.</div>
          </>
        )}
        {
          isOpen && (
            <Lightbox
              mainSrc={lightboxImage[photoIndex].url}
              nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length].url}
              prevSrc={
                lightboxImage[
                  (photoIndex + lightboxImage.length - 1) % lightboxImage.length
                ].url
              }
              imageTitle={lightboxImage[photoIndex].date}
              reactModalStyle={{ overlay: { zIndex: '1000000' } }}
              onCloseRequest={() => this.setState({ isOpen: false })}
              imagePadding={50}
              onMovePrevRequest={() => (
                this.setState({
                  photoIndex: (photoIndex + lightboxImage.length - 1) % lightboxImage.length,
                }))}
              onMoveNextRequest={() => (
                this.setState({
                  photoIndex: (photoIndex + 1) % lightboxImage.length,
                }))}
            />
          )
        }
      </>
    );
  }
}

GsImageHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(GsImageHistory);
