import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
// ui, styles //
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

import { BootstrapInput, StyledTableRow, styles } from "../../../styles/Style";

import WeatherContext from "../../../contexts/weatherContext";
// translate
import tJson from "../../../utils/TJson";
import Modal from "../../modal/Modal";
import {
  Breadcrumbs,
  Divider,
  FormControl,
  FormControlLabel,
  Hidden,
  IconButton,
  InputBase,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  TablePagination,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TemperatureLegend from "./TemperatureLegend";
import TablePaginationActionsWrapped from "../../widgets/table/TablePaginationActions";
import TableHeaderEnhance from "../../widgets/table/TableHeaderEnhance";
import { get, orderBy, uniqBy, uniqueId } from "lodash";
import moment from "moment";
import { FaCalendarAlt, FaChartLine, FaHome, FaSpinner } from "../../../utils/Fontawesome";
import TemperatureChart from "../../dashboard/TemperatureChart";
import { Link } from "react-router-dom";
import { HiiClimateIcon } from "../../../utils/Icon";
import SidebarWeather from "../../navigation/SidebarWeather";
import WeatherListMap from "./WeatherListMap";
import HumidLegend from "./HumidLegend";
import PressureLegend from "./PressureLegend";
import TemperatureStatusColor from "./TemperatureStatusColor";
import WeatherListChart from "./WeatherListChart";

const initPath = process.env.MIX_APP_PATH;

const style = {
  container: {
    maxHeight: 700,
    borderBottom: "1px solid #ccc",
  },
};

const basinData = [
  {
    "id": 29,
    "code": 29,
    "name": "นอกประเทศไทย"
  },
  {
    "id": 28,
    "code": 99,
    "name": "ไม่ระบุ"
  },
  {
    "id": 3,
    "code": 3,
    "name": "ลุ่มน้ำโขงตะวันออกเฉียงเหนือ"
  },
  {
    "id": 2,
    "code": 2,
    "name": "ลุ่มน้ำโขงเหนือ"
  },
  {
    "id": 10,
    "code": 10,
    "name": "ลุ่มน้ำเจ้าพระยา"
  },
  {
    "id": 18,
    "code": 17,
    "name": "ลุ่มน้ำชายฝั่งทะเลตะวันออก"
  },
  {
    "id": 4,
    "code": 4,
    "name": "ลุ่มน้ำชี"
  },
  {
    "id": 17,
    "code": 16,
    "name": "ลุ่มน้ำโตนเลสาบ"
  },
  {
    "id": 24,
    "code": 20,
    "name": "ลุ่มน้ำทะเลสาบสงขลา"
  },
  {
    "id": 14,
    "code": 13,
    "name": "ลุ่มน้ำท่าจีน"
  },
  {
    "id": 9,
    "code": 9,
    "name": "ลุ่มน้ำน่าน"
  },
  {
    "id": 16,
    "code": 15,
    "name": "ลุ่มน้ำบางปะกง"
  },
  {
    "id": 13,
    "code": 12,
    "name": "ลุ่มน้ำป่าสัก"
  },
  {
    "id": 6,
    "code": 6,
    "name": "ลุ่มน้ำปิง"
  },
  {
    "id": 20,
    "code": 18,
    "name": "ลุ่มน้ำเพชรบุรี-ประจวบคีรีขันธ์"
  },
  {
    "id": 27,
    "code": 22,
    "name": "ลุ่มน้ำภาคใต้ฝั่งตะวันตก"
  },
  {
    "id": 22,
    "code": 19,
    "name": "ลุ่มน้ำภาคใต้ฝั่งตะวันออกตอนบน"
  },
  {
    "id": 26,
    "code": 21,
    "name": "ลุ่มน้ำภาคใต้ฝั่งตะวันออกตอนล่าง"
  },
  {
    "id": 5,
    "code": 5,
    "name": "ลุ่มน้ำมูล"
  },
  {
    "id": 15,
    "code": 14,
    "name": "ลุ่มน้ำแม่กลอง"
  },
  {
    "id": 8,
    "code": 8,
    "name": "ลุ่มน้ำยม"
  },
  {
    "id": 7,
    "code": 7,
    "name": "ลุ่มน้ำวัง"
  },
  {
    "id": 12,
    "code": 11,
    "name": "ลุ่มน้ำสะแกกรัง"
  },
  {
    "id": 1,
    "code": 1,
    "name": "ลุ่มน้ำสาละวิน"
  }
];

const statusHumid = (value) => {
  if (value >= 100) {
    return {
      bg: "rgba(255, 0, 0, 0.8)",
      text: ">= 100",
      fcolor: "#FFF",
      label: "",
    }
  } else if (value > 85) {
    return {
      bg: "rgba(183, 67, 0, 0.8)",
      text: "< 100",
      fcolor: "#FFF",
      label: "",
    }
  } else if (value > 70) {
    return {
      bg: "rgba(255, 183, 0, 0.8)",
      text: "< 85",
      fcolor: "#000",
      label: "",
    }
  } else if (value > 40) {
    return {
      bg: "rgba(255, 255, 48, 0.8)",
      text: "< 70",
      fcolor: "#000",
      label: "",
    }
  } else if (value > 10) {
    return {
      bg: "rgba(0, 218, 4, 0.8)",
      text: "< 40",
      fcolor: "#000",
      label: "",
    }
  } else if (value > 5) {
    return {
      bg: "rgba(93, 246, 187, 0.8)",
      text: "< 10",
      fcolor: "#000",
      label: "",
    }
  } else {
    return {
      bg: "rgba(17, 195, 255, 0.8)",
      text: "< 5",
      fcolor: "#000",
      label: "",
    }
  }
}

const statusPress = (value) => {
  if (value >= 1015) {
    return {
      bg: "rgba(255, 0, 0, 0.8)",
      text: ">= 1015",
      fcolor: "#FFF",
      label: "",
    }
  } else if (value > 1010) {
    return {
      bg: "rgba(183, 67, 0, 0.8)",
      text: "< 1015",
      fcolor: "#FFF",
      label: "",
    }
  } else if (value > 1008) {
    return {
      bg: "rgba(255, 183, 0, 0.8)",
      text: "< 1010",
      fcolor: "#000",
      label: "",
    }
  } else if (value > 1000) {
    return {
      bg: "rgba(255, 255, 48, 0.8)",
      text: "< 1008",
      fcolor: "#000",
      label: "",
    }
  } else if (value > 800) {
    return {
      bg: "rgba(0, 218, 4, 0.8)",
      text: "< 1000",
      fcolor: "#000",
      label: "",
    }
  } else if (value > 400) {
    return {
      bg: "rgba(93, 246, 187, 0.8)",
      text: "< 800",
      fcolor: "#000",
      label: "",
    }
  } else {
    return {
      bg: "rgba(17, 195, 255, 0.8)",
      text: "< 400",
      fcolor: "#000",
      label: "",
    }
  }
}

// const columnData = listType => ;
class WeatherList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // _isMounted: true,
      isLoading: true, // loading status
      error: null, // error
      map: null,
      zoom: 5.65,
      location: [12.9, 101.6],
      dataProvince: [],
      regionId: "",
      markers: [],
      listType: "temperature",
      datas: [],
      order: "desc",
      orderByState: "temperature",
      page: 0,
      rowsPerPage: 10,
      dataInit: [],
      dataInitLastest: [],
      searchData: [],
      searchTerm: '',
      areaType: "0",
      dataCondition: [],
      areaId: '',
      areaText: 'ทั่วประเทศ',
      latestDate: '',

      agencyName: 'ทุกหน่วยงาน',
      dataAgency: [],
      dataAgencyHumid: [],
      dataAgencyPress: [],
      agencyId: '',

      datasTemp: [],
      dataProvinceTemp: [],
      dataBasinTemp: [],
      dataConditionTemp: [],
      latestDateTemp: [],

      datasHumid: [],
      dataProvinceHumid: [],
      // dataBasinHumid: [],
      dataConditionHumid: [],
      latestDateHumid: [],

      datasPress: [],
      dataProvincePress: [],
      // dataBasinPress: [],
      dataConditionPress: [],
      latestDatePress: [],

      // modal
      modalData: {
        open: false,
        header: "",
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: "",
        subheader: null,
        content: null,
      },
    };
    this.handleSetMap = this.handleSetMap.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getData(); // temperature
    this.getDataHumid();
    this.getDataPressure();

  }

  handleSetMap = (map) => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => (e) => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    setTimeout(() => {
      map.setZoom(10);
      // delay 500 ms to openpopup
      setTimeout(() => {
        currentMarker.openPopup();
      }, 500);
    }, 200);
  };

  handleModalOpen = (params) => {
    const { modalData } = this.state;
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    modalData.content = params.content;
    this.setState({ modalData });
  };

  handleListChange = (event) => {
    const {
      datasTemp,
      dataProvinceTemp,
      dataBasinTemp,
      dataConditionTemp,
      datasHumid,
      dataProvinceHumid,
      dataBasinHumid,
      dataConditionHumid,
      datasPress,
      dataProvincePress,
      dataBasinPress,
      dataConditionPress,
      searchData,
      latestDateHumid,
      latestDatePress,
      latestDateTemp,
      dataAgency,
      dataAgencyHumid,
      dataAgencyPress
    } = this.state;
    if (event.target.value === "temperature") {
      this.setState({
        isLoading: false,
        dataInit: datasTemp,
        dataInitLastest: datasTemp,
        searchData: datasTemp,
        dataProvince: dataProvinceTemp,
        dataBasin: dataBasinTemp,
        dataAgency: dataAgency,
        dataCondition: dataConditionTemp,
        listType: event.target.value,
        markers: [],
        page: 0,
        rowsPerPage: 10,
        order: "desc",
        orderByState: "temperature",
        areaText: 'ทั่วประเทศ',
        areaType: "0",
        agencyId: '',
      }, () => {
        this.filterDatas();
      });
    } else if (event.target.value === "humid") {
      this.setState({
        isLoading: false,
        dataInit: datasHumid,
        dataInitLastest: datasHumid,
        searchData: datasHumid,
        dataProvince: dataProvinceHumid,
        // dataBasin:dataBasinHumid,
        dataAgency: dataAgencyHumid,
        dataCondition: dataConditionHumid,
        listType: event.target.value,
        markers: [],
        page: 0,
        rowsPerPage: 10,
        order: "desc",
        orderByState: "humid",
        areaText: 'ทั่วประเทศ',
        areaType: "0",
        agencyId: '',
      }, () => {
        this.filterDatas();
      });
    } else if (event.target.value === "pressure") {
      this.setState({
        isLoading: false,
        dataInit: datasPress,
        dataInitLastest: datasPress,
        searchData: datasPress,
        dataProvince: dataProvincePress,
        // dataBasin:dataBasinPress,
        dataAgency: dataAgencyPress,
        dataCondition: dataConditionPress,
        listType: event.target.value,
        markers: [],
        page: 0,
        rowsPerPage: 10,
        order: "desc",
        orderByState: "pressure",
        areaText: 'ทั่วประเทศ',
        areaType: "0",
        agencyId: '',
      }, () => {
        this.filterDatas();
      });
    }
  };

  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = "desc";

    if (orderByState === property && order === "desc") {
      orderLocal = "asc";
    }

    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSearchChange = event => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm }, () => {
      this.filterDatas();
    });
  };

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;
    const { listType } = this.state;
    let textDisplay = '';
    if (listType === 'temperature') {
      textDisplay = t('อุณหภูมิ');
    } else if (listType === 'humid') {
      textDisplay = t('ความชื้นสัมพัทธ์');
    } else if (listType === 'pressure') {
      textDisplay = t('ความกดอากาศ');
    }
    const params = {
      header: textDisplay,
      content: <WeatherListChart id={data.station.id} provinceId={data.geocode.province_code} listType={listType} />,
    };
    handler(params);
  }

  handleAreaTypeChange = (event, value) => {
    const { dataProvince, dataBasin, dataInit } = this.state;
    let dataCondition = dataProvince;
    let areaText = 'ทั่วประเทศ';
    if (value === '1') {
      dataCondition = dataBasin;
      areaText = 'ทุกลุ่มน้ำ';
    }

    this.setState({
      areaType: value,
      dataCondition,
      areaId: '',
      areaText,
      dataInitLastest: dataInit,
      searchData: dataInit,
    }, () => {
      this.filterDatas();
    });
  };

  handleFilterAreaChange = areaId => event => {
    this.setState({ [areaId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  handleFilterAgencyChange = agencyId => event => {
    this.setState({ [agencyId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  resetModal = () => {
    const { modalDataReset, modalData } = this.state;
    this.setState({ modalData: modalDataReset });
    modalData.open = false;
    this.setState({ modalData });
  };

  getData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/thaiwater/temperature`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("get data Something went wrong ...");
      })
      .then((result) => {
        const currentDateTime = new Date();
        const last24hrs = `${currentDateTime.getFullYear()}-${String(currentDateTime.getMonth() + 1).padStart(2, '0')}-${String(currentDateTime.getDate()).padStart(2, '0')}`
        const filterDatas = result.data.data
          .filter(d => {
            const time = new Date(d.temperature_datetime);
            const formattime = `${time.getFullYear()}-${String(time.getMonth() + 1).padStart(2, '0')}-${String(time.getDate()).padStart(2, '0')}`
            return last24hrs === formattime;
          }).filter(d => d.geocode.amphoe_name.th !== undefined && d.geocode.tumbon_name.th !== undefined && d.geocode.province_name.th !== undefined && d.station.basin_id !== undefined);

        let dataProvince = filterDatas
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        dataProvince = uniqBy(dataProvince, 'id');

        let dataBasin = filterDatas
          .map(d => {
            return basinData.find(fid => d.station.basin_id === fid.id)
          })
          .sort((a, b) => a.name.localeCompare(b.name));

        dataBasin = uniqBy(dataBasin, 'id');

        let dataAgency = filterDatas.map(d => ({
          id: d.agency.agency_name.th,
          name: d.agency.agency_name,
        }));
        dataAgency = uniqBy(dataAgency.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th')), 'id');

        this.setState({
          isLoading: false,
          datasTemp: filterDatas,
          dataProvinceTemp: dataProvince,
          dataBasinTemp: dataBasin,
          dataConditionTemp: dataProvince,

          //setmaindata
          dataInit: filterDatas,
          dataInitLastest: filterDatas,
          searchData: filterDatas,
          dataProvince,
          dataBasin,
          dataAgency,
          dataCondition: dataProvince,
        });
      })
      .catch((error) => this.setState({ error, isLoading: true }));
    return true;
  }

  getDataHumid() {
    fetch(`${process.env.MIX_API_URL}public/thaiwater/humid`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("get data Something went wrong ...");
      })
      .then((result) => {
        const currentDateTime = new Date();
        const last24hrs = `${currentDateTime.getFullYear()}-${String(currentDateTime.getMonth() + 1).padStart(2, '0')}-${String(currentDateTime.getDate()).padStart(2, '0')}`
        const filterDatasHumid = result.data
          .filter(d => {
            const time = new Date(d.humid_datetime);
            const formattime = `${time.getFullYear()}-${String(time.getMonth() + 1).padStart(2, '0')}-${String(time.getDate()).padStart(2, '0')}`
            return last24hrs === formattime;
          }).filter(d => d.geocode.amphoe_name.th !== undefined && d.geocode.tumbon_name.th !== undefined && d.geocode.province_name.th !== undefined);

        let dataProvinceHumid = filterDatasHumid
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        dataProvinceHumid = uniqBy(dataProvinceHumid, 'id');

        //   let dataBasin = filterDatas
        //   .map(d => {
        //     return basinData.find(fid => d.station.basin_id === fid.id)
        //   })
        //   .sort((a, b) => a.name.localeCompare(b.name));

        // dataBasin = uniqBy(dataBasin, 'id');

        let dataAgencyHumid = filterDatasHumid.map(d => ({
          id: d.agency.agency_name.th,
          name: d.agency.agency_name,
        }));
        dataAgencyHumid = uniqBy(dataAgencyHumid.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th')), 'id');

        this.setState({
          isLoading: false,
          datasHumid: filterDatasHumid,
          dataProvinceHumid,
          // dataBasinHumid,
          dataAgencyHumid,
          dataConditionHumid: dataProvinceHumid,
        });
      })
      .catch((error) => this.setState({ error, isLoading: true }));
    return true;
  }

  getDataPressure() {
    fetch(`${process.env.MIX_API_URL}public/thaiwater/pressure`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("get data Something went wrong ...");
      })
      .then((result) => {
        const currentDateTime = new Date();
        const last24hrs = `${currentDateTime.getFullYear()}-${String(currentDateTime.getMonth() + 1).padStart(2, '0')}-${String(currentDateTime.getDate()).padStart(2, '0')}`
        const filterDatasPress = result.data
          .filter(d => {
            const time = new Date(d.pressure_datetime);
            const formattime = `${time.getFullYear()}-${String(time.getMonth() + 1).padStart(2, '0')}-${String(time.getDate()).padStart(2, '0')}`
            return last24hrs === formattime;
          }).filter(d => d.geocode.amphoe_name.th !== undefined && d.geocode.tumbon_name.th !== undefined && d.geocode.province_name.th !== undefined);

        let dataProvincePress = filterDatasPress
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        dataProvincePress = uniqBy(dataProvincePress, 'id');

        //   let dataBasin = filterDatas
        //   .map(d => {
        //     return basinData.find(fid => d.station.basin_id === fid.id)
        //   })
        //   .sort((a, b) => a.name.localeCompare(b.name));

        // dataBasin = uniqBy(dataBasin, 'id');

        let dataAgencyPress = filterDatasPress.map(d => ({
          id: d.agency.agency_name.th,
          name: d.agency.agency_name,
        }));
        dataAgencyPress = uniqBy(dataAgencyPress.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th')), 'id');


        this.setState({
          isLoading: false,
          datasPress: filterDatasPress,
          dataProvincePress,
          // dataBasinPress,
          dataAgencyPress,
          dataConditionPress: dataProvincePress,
        });
      })
      .catch((error) => this.setState({ error, isLoading: true }));
    return true;
  }

  getMaxDate = (datas, type) => {
    const { t } = this.props;
    if (type === "temperature") {
      if (datas !== "") {
        const dates = datas.map(
          (x) => new Date(x.temperature_datetime.replace(/-/g, "/"))
        );
        const maxdate = new Date(Math.max.apply(null, dates));
        return `${moment(maxdate).format("YYYY-MM-DD")} ${t("เวลา")} : ${moment(
          maxdate
        ).format("HH:mm")}`;
      }
      return moment().format("YYYY-MM-DD");
    } else if (type === "humid") {
      if (datas !== "") {
        const dates = datas.map(
          (x) => new Date(x.humid_datetime.replace(/-/g, "/"))
        );
        const maxdate = new Date(Math.max.apply(null, dates));
        return `${moment(maxdate).format("YYYY-MM-DD")} ${t("เวลา")} : ${moment(
          maxdate
        ).format("HH:mm")}`;
      }
      return moment().format("YYYY-MM-DD");
    } else if (type === "pressure") {
      if (datas !== "") {
        const dates = datas.map(
          (x) => new Date(x.pressure_datetime.replace(/-/g, "/"))
        );
        const maxdate = new Date(Math.max.apply(null, dates));
        return `${moment(maxdate).format("YYYY-MM-DD")} ${t("เวลา")} : ${moment(
          maxdate
        ).format("HH:mm")}`;
      }
      return moment().format("YYYY-MM-DD");
    }
  };

  filterDatas() {
    const {
      searchTerm,
      dataInit,
      rowsPerPage,
      page,
      areaId,
      areaType,
      agencyId,
      listType
    } = this.state;
    const searchData = dataInit.filter((d) => {
      if (searchTerm !== '') {
        if (!(tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1
          || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
          || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
          || d.geocode.province_name.th.indexOf(searchTerm) > -1
          || d.station.tele_station_oldcode.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1
        )) {
          return false
        }
      }
      if (areaId !== '') {
        if (areaType === '1' ? !(d.station.basin_id.toString() === areaId) : !(d.geocode.province_code.toString() === areaId)) {
          return false
        }
      }
      if (agencyId !== '') {
        if (!(d.agency.agency_name.th === agencyId)) {
          return false
        }
      }
      return d;
    });

    const pageCurrent = searchData.length < rowsPerPage ? 0 : page;

    this.setState({
      searchData,
      page: pageCurrent,
    });
  }

  render() {
    const { classes, t, i18n, theme } = this.props;
    const {
      modalData,
      location,
      zoom,
      regionId,
      markers,
      listType,
      isLoading,
      order,
      orderByState,
      searchData,
      page,
      rowsPerPage,
      areaType,
      areaId,
      areaText,
      dataCondition,
      latestDate,
      datasTemp,
      dataInit,
      dataAgency,
      agencyName,
      agencyId
    } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, searchData.length - page * rowsPerPage);

    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    dataCondition.sort(function (a, b) {
      if (a.id > 100) {
        return 1;
      }
      if (b.id > 100) {
        return -1;
      }
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return (
      <div className={classes.root}>
        <WeatherContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <SidebarWeather />
          <div className={classes.layout}>

            <Grid container spacing={4}>
              <Grid item xs>
                <Box mt={3} display="flex">
                  <Breadcrumbs aria-label="breadcrumb" className={classes.Breadcrumbs}>
                    <Link to={`${initPath}weather`}>
                      <FaHome className={classes.iconSub} />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                        {' '}
                        {t('ติดตามสภาพอากาศ')}
                      </Typography>
                    </Link>
                    <Link to={`${initPath}weather`}>
                      <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                        {t('อากาศ')}
                      </Typography>
                    </Link>
                    <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                      {t('สถานีตรวจวัดอากาศ')}
                    </Typography>
                  </Breadcrumbs>
                </Box>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box display="flex" pt={1} width="100%">
                  <Box display="inline" p={1} alignItems="center">
                    <HiiClimateIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">{t("สถานีตรวจวัดอากาศ")}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={5} lg={4}>
                <Box mt={2} width="100%">
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <WeatherListMap
                      {...this.state}
                      datas={searchData}
                      markers={markers}
                      location={location}
                      zoom={zoom}
                      classes={classes}
                      handleSetMap={this.handleSetMap}
                      regionId={regionId}
                      language={i18n.language}
                    />
                  </React.Suspense>
                  {listType === "temperature" && (
                    <TemperatureLegend />
                  )}
                  {listType === "humid" && (
                    <HumidLegend />
                  )}
                  {listType === "pressure" && (
                    <PressureLegend />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={7} lg={8}>
                <Box mt={2} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41 }}>
                      <Box>
                        <FormControl variant="outlined" component="fieldset">
                          <RadioGroup aria-label="position" id="position" name="position" value={areaType} onChange={this.handleAreaTypeChange} row>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="primary" />}
                              label={(
                                <Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>
                                  {t('ข้อมูลจังหวัด')}
                                </Typography>
                              )}
                              labelPlacement="end"
                              fontFamily="Kanit"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label={(
                                <Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>
                                  {t('ข้อมูลลุ่มน้ำ')}
                                </Typography>
                              )}
                              labelPlacement="end"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                              disabled={listType !== "temperature"}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined" className={classes.formControl} width="100%">
                        <NativeSelect
                          id="select"
                          value={areaId.toString()}
                          onChange={this.handleFilterAreaChange('areaId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit', fontSize: 14 }}>{t(areaText)}</option>
                          <optgroup label={t('ประเทศไทย')} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                            {dataCondition.filter(val => val.id <= 100).map(val => (
                              <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                            ))}
                          </optgroup>
                          <optgroup label={t('ต่างประเทศ')} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                            {dataCondition.filter(val => val.id > 100).map(val => (
                              <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                            ))}
                          </optgroup>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>

                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      fontFamily="Kanit"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        width="100%"
                      >
                        <NativeSelect
                          onChange={this.handleListChange}
                          // disabled
                          input={
                            <BootstrapInput
                              name="listType"
                              id="regend-customized-native-simple"
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                          }
                        >
                          <option value="temperature">{t('อุณหภูมิ')}</option>
                          <option value="humid">{t('ความชื้นสัมพัทธ์')}</option>
                          <option value="pressure">{t('ความกดอากาศ')}</option>
                          {/* <option value="wind" disabled>ความเร็วลม</option> */}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* here */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined">
                        <NativeSelect
                          id="agencySelect"
                          value={agencyId.toString()}
                          onChange={this.handleFilterAgencyChange('agencyId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit' }}>{t(agencyName)}</option>
                          {dataAgency.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{tJson(val.name, 'หน่วยงาน')}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <Paper className={classes.rootSearch} elevation={0}>
                        <InputBase
                          display="flex"
                          className={classes.inputSearch}
                          placeholder={t('ค้นหา')}
                          id="search"
                          onChange={this.handleSearchChange}
                        />
                        <IconButton className={classes.iconButton} aria-label="Search">
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box mt={2} />
                    <TableContainer component={Paper}>
                      <Table
                        className={[classes.table, "table-custom-sticky"].join(
                          " "
                        )}
                      >
                        <TableHeaderEnhance
                          columnData={[
                            {
                              id: "station.tele_station_name.th",
                              numeric: false,
                              disablePadding: true,
                              label: "สถานี",
                              sortable: true,
                            },
                            {
                              id: "address",
                              numeric: false,
                              disablePadding: true,
                              label: "ที่ตั้ง",
                              sortable: false,
                            },
                            {
                              id: `${listType}`,
                              align: "center",
                              numeric: true,
                              disablePadding: true,
                              label: (listType === "temperature" ? "อุณหภูมิ" : (listType === "humid" ? "ความชื้นสัมพัทธ์" : (listType === "pressure" ? "ความกดอากาศ" : "ความเร็วลม")))
                              ,
                              sortable: true,
                            },
                            {
                              id: "datetime",
                              align: "center",
                              numeric: false,
                              disablePadding: true,
                              label: "เวลา",
                              sortable: false,
                            },
                            {
                              id: "chart",
                              align: "center",
                              numeric: false,
                              disablePadding: true,
                              label: "",
                            },
                          ]}
                          order={order}
                          orderBy={orderByState}
                          onRequestSort={this.handleRequestSort}
                          firstColumnSticky
                        />
                        {searchData.length === 0 ?
                          <TableBody>
                            <StyledTableRow style={{ height: 49 * emptyRows }}>
                              <TableCell colSpan={10} align="center">
                                {datasTemp.length === 0 ?
                                  <strong>{t('กำลังโหลดข้อมูล')}</strong>
                                  :
                                  <strong>{t('ไม่พบข้อมูล')}</strong>
                                }
                              </TableCell>
                            </StyledTableRow>
                          </TableBody>
                          :
                          <TableBody>
                            {orderBy(
                              searchData,
                              [
                                (item) =>
                                  orderByState === "station.tele_station_name.th"
                                    ? item.station.tele_station_name.th.toLowerCase()
                                    : parseFloat(get(item, orderByState) || 0),
                              ],
                              [order]
                            )
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, i) => {
                                let mapLink = row.station.tele_station_name.th;
                                let time = '--:--';
                                let val = '-';
                                const stationName = row.station.tele_station_name.th
                                  ? `${tJson(
                                    row.station.tele_station_name,
                                    "สถานี"
                                  )}`
                                  : row.station.tele_station_oldcode;
                                if (
                                  this.handleSearchMarker &&
                                  row.station.tele_station_lat &&
                                  row.station.tele_station_long
                                ) {
                                  mapLink = (
                                    <Button
                                      style={{ textAlign: "left" }}
                                      onClick={this.handleSearchMarker(
                                        row.station.id,
                                        row.station.tele_station_lat,
                                        row.station.tele_station_long
                                      )}
                                      title={`${tJson(
                                        row.agency.agency_name,
                                        "หน่วยงาน"
                                      )}`}
                                    >
                                      {stationName}
                                    </Button>
                                  );
                                  if (listType === "temperature") {
                                    const status = TemperatureStatusColor(row.temperature);
                                    time = (`${moment(row.temperature_datetime).format("HH:mm")} ${t("น.")}`);
                                    val = (
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <Box
                                            ml={2}
                                            borderRadius={4}
                                            style={{
                                              backgroundColor: status.color.statusColor,
                                              color: status.color.constrastColor,
                                              lineHeight: 2,
                                              width: 50,
                                            }}
                                          >
                                            {row.temperature.toFixed(2)}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    )
                                  } else if (listType === "humid") {
                                    const status = statusHumid(row.humid);
                                    time = (`${moment(row.humid_datetime).format("HH:mm")} ${t("น.")}`);
                                    val = (
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <Box
                                            ml={2}
                                            borderRadius={4}
                                            style={{
                                              backgroundColor: status.bg,
                                              color: status.fcolor,
                                              lineHeight: 2,
                                              width: 50,
                                            }}
                                          >
                                            {row.humid.toFixed(2)}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    )
                                  } else if (listType === "pressure") {
                                    const status = statusPress(row.pressure);
                                    time = (`${moment(row.pressure_datetime).format("HH:mm")} ${t("น.")}`);
                                    val = (
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <Box
                                            ml={2}
                                            borderRadius={4}
                                            style={{
                                              backgroundColor: status.bg,
                                              color: status.fcolor,
                                              lineHeight: 2,
                                              width: 55,
                                            }}
                                          >
                                            {row.pressure.toFixed(2)}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    )
                                  }
                                } else {
                                  mapLink = stationName;
                                }

                                return (
                                  <StyledTableRow key={`${row.station.id}${listType}`}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="left"
                                      className="first-column-sticky"
                                    >
                                      {mapLink}
                                    </TableCell>
                                    <TableCell align="left">
                                      {`${tJson(
                                        row.geocode.tumbon_name,
                                        "ต."
                                      )} ${tJson(row.geocode.amphoe_name, "อ.")}
                                    ${(row.geocode.province_code === "999" ? tJson(row.geocode.province_name, '') : tJson(row.geocode.province_name, 'จ.'))}`}
                                    </TableCell>
                                    <TableCell align="center">
                                      {val}
                                    </TableCell>
                                    <TableCell align="center">
                                      {time}
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>
                                      <WeatherContext.Consumer>
                                        {({ handleModalOpen }) => (
                                          <>
                                            <Tooltip title={t("กราฟอุณภูมิ")}>
                                              <IconButton
                                                className={classes.button}
                                                aria-label="Chart"
                                                onClick={this.handleChart(
                                                  handleModalOpen,
                                                  row
                                                )}
                                              >
                                                <FaChartLine fontSize="small" />
                                              </IconButton>
                                            </Tooltip>
                                          </>
                                        )}
                                      </WeatherContext.Consumer>
                                    </TableCell>
                                  </StyledTableRow>
                                );
                              })}
                            {emptyRows > 0 && (
                              <StyledTableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={10} />
                              </StyledTableRow>
                            )}
                          </TableBody>
                        }
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      className={classes.hiddenLine}
                      count={searchData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                      labelRowsPerPage={t("จำนวนแถวต่อหน้า")}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${t("แสดงรายการ")} ${from}-${to} ${t(
                          "จาก"
                        )} ${count} ${t("รายการ")}`
                      }
                    />
                    <Box
                      width="100%"
                      p={1}
                      align="right"
                      fontSize="small"
                      fontFamily="Kanit"
                      color={theme.palette.secondary.main}
                    >
                      {`${t("ข้อมูลล่าสุดวันที่")} ${this.getMaxDate(dataInit, listType)} ${t("น.")}`}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Modal
                open={modalData.open}
                modalHeader={modalData.header}
                modalContent={modalData.content}
                resetModal={this.resetModal}
              />
            </Grid>
          </div>
        </WeatherContext.Provider>
      </div>
    );
  }
}

WeatherList.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
  theme: PropTypes.object.isRequired,
};
export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation()
)(WeatherList);
