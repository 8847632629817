import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    maxHeight: 440,
    borderRadius: 6,
    border: '1px solid #ccc',
  },
  table: {
    minWidth: 650,
  },
});

function createData(no, set, nameEn, nameTh, meaning, from) {
  return { no, set, nameEn, nameTh, meaning, from };
}

const rows = [
  createData(1,1,'DAMREY','ด็อมเร็ย','ช้าง','ราชอาณาจักรกัมพูชา'),
  createData(2,1,'HAIKUI','ไห่ขุย','ชื่อดอกไม้ทะเลชนิดหนึ่ง','สาธารณรัฐประชาชนจีน'),
  createData(3,1,'KIROGI','คีโรกี','ห่านป่า','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(4,1,'YUN-YEUNG','ยุนยาง','เป็ดแมนดาริน','เขตบริหารพิเศษฮ่องกง'),
  createData(5,1,'KOINU','โคอินุ','ลูกสุนัข','ญี่ปุ่น'),
  createData(6,1,'BOLAVEN','บอละเวน','ที่ราบสูงทางตอนใต้ของประเทศสาธารณรัฐประชาธิปไตยประชาชนลาว','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(7,1,'SANBA','ซันปา','ชื่อสถานที่ในเขตบริหารพิเศษมาเก๊า','เขตบริหารพิเศษมาเก๊า'),
  createData(8,1,'JELAWAT','เจอลาวัต','ชื่อปลาน้ำจืดชนิดหนึ่ง','มาเลเซีย'),
  createData(9,1,'EWINIAR','เอวิเนียร์','ชื่อเทพเจ้าแห่งพ[ภาษาพื้นเมืองของเกาะชุก (Chuuk)]','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(10,1,'MALIKSI','มาลิกซี','เร็ว','ฟิลิปปินส์'),
  createData(11,1,'GAEMI','แคมี','มด','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(12,1,'PRAPIROON','พระพิรุณ','ชื่อเทพเจ้าแห่งฝน','ไทย'),
  createData(13,1,'MARIA','มาเรีย','ชื่อผู้หญิง [ภาษาของชนเผ่าชามอร์โรในมาเรียนา (Mariana)]','สหรัฐอเมริกา'),
  createData(14,1,'SON-TINH','เซินติญ','ชื่อเทพเจ้าแห่งขุนเขาในเรื่องปรัมปราของสาธารณรัฐสังคมนิยมเวียดนาม','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(15,1,'AMPIL','อ็อมปึล','มะขาม','ราชอาณาจักรกัมพูชา'),
  createData(16,1,'WUKONG','อู๋คง','พญาวานรที่มีชื่อเสียงในเรื่องทางพระพุทธศาสนาของสาธารณรัฐประชาชนจีน','สาธารณรัฐประชาชนจีน'),
  createData(17,1,'JONGDARI','ชงดารี','นกจาบฝน','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(18,1,'SHANSHAN','ชานชาน','ชื่อของเด็กหญิง','เขตบริหารพิเศษฮ่องกง'),
  createData(19,1,'YAGI','ยางิ','ราศีมังกร, แพะ','ญี่ปุ่น'),
  createData(20,1,'LEEPI','หลี่ผี','น้ำตกที่สวยที่สุดทางตอนใต้ของประเทศสาธารณรัฐประชาธิปไตยประชาชนลาว','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(21,1,'BEBINCA','เบบินคา','ชื่อขนมพุดดิงของเขตบริหารพิเศษมาเก๊า','เขตบริหารพิเศษมาเก๊า'),
  createData(22,1,'PULASAN','ปูลาซัน','ผลไม้ชนิดหนึ่งคล้ายเงาะ','มาเลเซีย'),
  createData(23,1,'SOULIK','ซูลิก','ต้าแหน่งหัวหน้าเผ่าโบราณของเกาะโปนเป (Pohnpei)','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(24,1,'CIMARON','ซีมารอน','วัวป่าของฟิลิปปินส์','ฟิลิปปินส์'),
  createData(25,1,'JEBI','เชบี','นกนางแอ่น','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(26,1,'KRATHON','กระท้อน','ชื่อผลไม้ชนิดหนึ่ง','ไทย'),
  createData(27,1,'BARIJAT','บารีจัต','ชายฝั่งที่มีคลื่นลม [ภาษาพื้นเมืองของหมู่เกาะมาร์แซล (Marshall)]','สหรัฐอเมริกา'),
  createData(28,2,'TRAMI','จ่ามี','ชื่อดอกไม้ชนิดหนึ่งในสกุลดอกกุหลาบ','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(29,2,'KONG-REY','กองเร็ย','ชื่อสาวงามในต้านานเขมร, ชื่อภูเขา','ราชอาณาจักรกัมพูชา'),
  createData(30,2,'YINXING','หยินซิ่ง','ต้นไม้ท้องถิ่นในประเทศจีน','สาธารณรัฐประชาชนจีน'),
  createData(31,2,'TORAJI','โทราจี','ชื่อต้นไม้มีดอกสวยงามพบในเกาหลี รากใช้ทำอาหารและยา','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(32,2,'MAN-YI','หม่านหยี่','ชื่อช่องแคบ ปัจจุบันเป็นอ่างเก็บน้ำ','เขตบริหารพิเศษฮ่องกง'),
  createData(33,2,'USAGI','อูซางิ','กระต่าย','ญี่ปุ่น'),
  createData(34,2,'PABUK','ปาบึก','ชื่อปลาน้ำจืดขนาดใหญ่ (ปลาบึก) อยู่ในแม่น้ำโขง','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(35,2,'WUTIP','หวู่ติบ','ผีเสื้อ','เขตบริหารพิเศษมาเก๊า'),
  createData(36,2,'SEPAT','เซอปัต','ปลาชนิดหนึ่ง (ปลาตีน)','มาเลเซีย'),
  createData(37,2,'MUN','มูน','เดือนมิถุนายน [ภาษาพื้นเมืองของหมู่เกาะยาป (Yap)]','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(38,2,'DANAS','ดานัส','ประสบการณ์และความรู้สึก','ฟิลิปปินส์'),
  createData(39,2,'NARI','นารี','ดอกลิลี','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(40,2,'WIPHA','วิภา','ชื่อของผู้หญิง','ไทย'),
  createData(41,2,'FRANCISCO','ฟรานซิสโก','ชื่อของผู้ช[ภายษาของชนเผ่าชามอร์โรในหมู่เกาะมาเรียนา (Mariana)]','สหรัฐอเมริกา'),
  createData(42,2,'CO-MAY','ก๋อมัย','หญ้าเจ้าชู้','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(43,2,'KROSA','กรอซา','นกกระสา, นกกระเรียน','ราชอาณาจักรกัมพูชา'),
  createData(44,2,'BAILU','ไป๋ลู่','กวางขาว','สาธารณรัฐประชาชนจีน'),
  createData(45,2,'PODUL','โพดุล','ต้นหลิว','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(46,2,'LINGLING','เหล่งเหล่ง','ชื่อของเด็กหญิง','เขตบริหารพิเศษฮ่องกง'),
  createData(47,2,'KAJIKI','คาจิกิ','ชื่อปลาทะเลที่มีสีสวยงาม','ญี่ปุ่น'),
  createData(48,2,'NONGFA','หนองฟ้า','ชื่อทะเลสาบ','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(49,2,'PEIPAH','เผ่ย์ผ่า','ปลาที่เลี้ยงไว้ดูเล่นชนิดหนึ่งในมาเก๊า','เขตบริหารพิเศษมาเก๊า'),
  createData(50,2,'TAPAH','ตาปะฮ์','ชื่อปลาน้ำจืดชนิดหนึ่ง','มาเลเซีย'),
  createData(51,2,'MITAG','มิแทก','ชื่อของผู้หญิง [ภาษาพื้นเมืองของเกาะแยป (Yap)]','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(52,2,'RAGASA','รากาซา','กระโชก','ฟิลิปปินส์'),
  createData(53,2,'NEOGURI','นอกูรี','ชื่อสุนัขพันธุ์หนึ่งในสาธารณรัฐเกาหลีลักษณะคล้ายตัวแร็กคูน','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(54,2,'BUALOI','บัวลอย','ขนมชนิดหนึ่ง','ไทย'),
  createData(55,2,'MATMO','แมตโม','ฝนที่ตกหนัก','สหรัฐอเมริกา'),
  createData(56,2,'HALONG','หะลอง','ชื่ออ่าวที่สวยงามแห่งหนึ่งของสาธารณรัฐสังคมนิยมเวียดนาม','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(57,3,'NAKRI','นากรี','ชื่อดอกไม้ชนิดหนึ่ง','ราชอาณาจักรกัมพูชา'),
  createData(58,3,'FENGSHEN','เฟิงเฉิน','ชื่อเทพเจ้าแห่งลม','สาธารณรัฐประชาชนจีน'),
  createData(59,3,'KALMAEGI','คัลแมกี','นกนางนวล','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(60,3,'FUNG-WONG','ฟงวอง','ฟีนิกซ์ (ชื่อยอดเขา)','เขตบริหารพิเศษฮ่องกง'),
  createData(61,3,'KOTO','โคโตะ','พิณโคโตะ','ญี่ปุ่น'),
  createData(62,3,'NOKAEN','นกแอ่น','ชื่อนกชนิดหนึ่ง','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(63,3,'VONGFONG','หว่องฟ้ง','ตัวต่อ','เขตบริหารพิเศษมาเก๊า'),
  createData(64,3,'NURI','นูรี','ชื่อนกแก้วหัวสีน้ำเงินในภาษามลายู','มาเลเซีย'),
  createData(65,3,'SINLAKU','ซินลากู','ชื่อเทพธิดาในนิยายของเกาะคอสไร','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(66,3,'HAGUPIT','ฮากูปิต','เฆี่ยนตี','ฟิลิปปินส์'),
  createData(67,3,'JANGMI','ชังมี','กุหลาบ','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(68,3,'MEKKHALA','เมขลา','ชื่อเทพธิดาประจ้าสมุทรในเทพนิยายไทย','ไทย'),
  createData(69,3,'HIGOS','ฮีโกส','ผลมะเดื่อ[ภาษาของชนเผ่าชามอร์โรในหมู่เกาะมาเรียนา (Mariana)]','สหรัฐอเมริกา'),
  createData(70,3,'BAVI','บาหวี่','ทิวเขาในภาคเหนือของสาธารณรัฐสังคมนิยมเวียดนาม','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(71,3,'MAYSAK','ไมสัก','ชื่อต้นไม้ชนิดหนึ่ง','ราชอาณาจักรกัมพูชา'),
  createData(72,3,'HAISHEN','ไห่เฉิน','ชื่อเทพเจ้าแห่งท้องทะเล','สาธารณรัฐประชาชนจีน'),
  createData(73,3,'NOUL','โนอึล','เรืองแสง, ท้องฟ้าสีแดง','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(74,3,'DOLPHIN','ดอลฟิน','โลมาสีขาวซึ่งอาศัยอยู่ในเขตน่านน้ำฮ่องกงและเป็นตัวนำโชคของฮ่องกง','เขตบริหารพิเศษฮ่องกง'),
  createData(75,3,'KUJIRA','คูจิระ','กลุ่มดาวซีตัส, วาฬ','ญี่ปุ่น'),
  createData(76,3,'CHAN-HOM','จันหอม','ชื่อต้นไม้ชนิดหนึ่ง','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(77,3,'LINFA','หลิ่นฟา','ดอกบัว','เขตบริหารพิเศษมาเก๊า'),
  createData(78,3,'NANGKA','นังกา','ขนุน','มาเลเซีย'),
  createData(79,3,'SAUDEL','โซเดล','ชื่อนายทหารองครักษ์ของหัวหน้าเผ่าบนโปนเป สาธารณรัฐสหพันธ์ไมโครนีเซีย','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(80,3,'MOLAVE','โมลาเบ','ชื่อไม้เนื้อแข็งชนิดหนึ่งใช้ทำเครื่องเรือน','ฟิลิปปินส์'),
  createData(81,3,'GONI','โคนี','หงส์','สาธารณรัฐเกาหลี(เกาหลีใต้)'),
  createData(82,3,'ATSANI','อัสนี','สายฟ้า','ไทย'),
  createData(83,3,'ETAU','เอตาว','เมฆฝน [ภาษาพื้นเมืองของเกาะปาเลา(Palua)]','สหรัฐอเมริกา'),
  createData(84,3,'VAMCO','หว่ามก๋อ','ชื่อแม่น้ำในสาธารณรัฐสังคมนิยมเวียดนามตอนใต้','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(85,4,'KROVANH','กรอวาญ','ชื่อต้นไม้ชนิดหนึ่ง (กระวาน)','ราชอาณาจักรกัมพูชา'),
  createData(86,4,'DUJUAN','ตู้เจวียน','กุหลาบพันปี','สาธารณรัฐประชาชนจีน'),
  createData(87,4,'SURIGAE','ซูรีแค','ชื่อนกอินทรีชนิดหนึ่ง','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(88,4,'CHOI-WAN','ฉอยหวั่น','เมฆซึ่งมีสีสันสวยงาม','เขตบริหารพิเศษฮ่องกง'),
  createData(89,4,'KOGUMA','โคะงุมะ','ดาวหมีน้อย','ญี่ปุ่น'),
  createData(90,4,'CHAMPI','จ้าปี','ชื่อดอกไม้ชนิดหนึ่ง','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(91,4,'IN-FA','ยีนฟ้า','ดอกไม้ไฟ','เขตบริหารพิเศษมาเก๊'),
  createData(92,4,'CEMPAKA','เจิมปากา','ดอกจำปา','มาเลเซีย'),
  createData(93,4,'NEPARTAK','เนพาร์ตัก','นักรบที่มีชื่อเสียงของชาวเกาะคอสไร','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(94,4,'LUPIT','ลูปิต','โหดร้ายทารุณ','ฟิลิปปินส์'),
  createData(95,4,'MIRINAE','มีรีแน','ทางช้างเผือก','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(96,4,'NIDA','นิดา','ชื่อของผู้หญิง','ไทย'),
  createData(97,4,'OMAIS','โอไมส์','พเนจร [ภาษาพื้นเมืองของเกาะปาเลา(Palua)]','สหรัฐอเมริกา'),
  createData(98,4,'CONSON','โกนเซิน','ชื่อโบราณสถานแห่งหนึ่งในสาธารณรัฐสังคมนิยมเวียดนาม','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(99,4,'CHANTHU','จันทู','ชื่อดอกไม้ชนิดหนึ่ง','ราชอาณาจักรกัมพูชา'),
  createData(100,4,'DIANMU','เตี้ยนหมู่','เจ้าแม่แห่งสายฟ้า','สาธารณรัฐประชาชนจีน'),
  createData(101,4,'MINDULLE','มินดุลเล','ชื่อดอกไม้ชนิดหนึ่ง','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(102,4,'LIONROCK','ไลออนร็อก','ชื่อยอดเขาในฮ่องกง','เขตบริหารพิเศษฮ่องกง'),
  createData(103,4,'KOMPASU','คมปาซุ','กลุ่มดาววงเวียน','ญี่ปุ่น'),
  createData(104,4,'NAMTHEUN','น้ำเทิน','ชื่อแม่น้ำซึ่งเป็นแควของแม่น้ำโขง','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(105,4,'MALOU','หมาเหล่า','อะเกต, หินโมรา','เขตบริหารพิเศษมาเก๊า'),
  createData(106,4,'NYATOH','ญาโตะฮ์','ชื่อไม้เนื้อแข็งชนิดหนึ่ง','มาเลเซีย'),
  createData(107,4,'RAI','ราอี','เงินตราพื้นเมืองของเกาะแยป','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(108,4,'MALAKAS','มาลากัส','แข็งแรง, มีอำนาจ','ฟิลิปปินส์'),
  createData(109,4,'MEGI','เมกี','ชื่อปลาชนิดหนึ่ง','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(110,4,'CHABA','ชบา','ชื่อดอกไม้ชนิดหนึ่ง','ไทย'),
  createData(111,4,'AERE','แอรี','พายุ [ภาษาพื้นเมืองของหมู่เกาะมาร์แชล (Marshall)]','สหรัฐอเมริกา'),
  createData(112,4,'SONGDA','ซงด่า','ชื่อแม่น้ำทางด้านตะวันตกเฉียงเหนือของสาธารณรัฐสังคมนิยมเวียดนาม','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(113,5,'TRASES','ตรอแซะฮ์','นกหัวขวาน','ราชอาณาจักรกัมพูชา'),
  createData(114,5,'MULAN','มู่หลาน','ชื่อดอกไม้ชนิดหนึ่งในตระกูลแม็กโนเลีย','สาธารณรัฐประชาชนจีน'),
  createData(115,5,'MEARI','เมอารี','เสียงสะท้อน','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(116,5,'MA-ON','หมาอ๊อน','ชื่อยอดเขาในฮ่องกง มีความหมายว่า อานม้า','เขตบริหารพิเศษฮ่องกง'),
  createData(117,5,'TOKAGE','โทกาเงะ','ชื่อกลุ่มดาวลาเซอร์ตา, กิ้งก่า','ญี่ปุ่น'),
  createData(118,5,'HINNAMNOR','หินหนามหน่อ','ห่านป่า','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(119,5,'MUIFA','หมุ่ยฟ้า','ดอกบ๊วย','เขตบริหารพิเศษมาเก๊า'),
  createData(120,5,'MERBOK','เมอร์บก','ชื่อนกชนิดหนึ่ง (นกเขา)','มาเลเซีย'),
  createData(121,5,'NANMADOL','นันมาดอล','โบราณสถานที่มีชื่อเสียงของเกาะโปนเป (Pohnpei) ได้รับสมญาว่า เวนิสแห่งแปซิฟิก','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(122,5,'TALAS','ตาลัส',"ความคม, ความเฉียบแหลม",'ฟิลิปปินส์'),
  createData(123,5,'NORU','โนรู','ชื่อกวางชนิดหนึ่ง','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(124,5,'KULAP','กุหลาบ','ชื่อดอกไม้ชนิดหนึ่ง','ไทย'),
  createData(125,5,'ROKE','โรคี','ชื่อของผู้ชาย [ภาษาของชนเผ่าชามอร์โรในหมู่เกาะมาเรียนา (Mariana)]','สหรัฐอเมริกา'),
  createData(126,5,'SONCA','เซินกา','ชื่อนกชนิดหนึ่งมีเสียงไพเราะ','สาธารณรัฐสังคมนิยมเวียดนาม'),
  createData(127,5,'NESAT','เนสาท','การประมง','ราชอาณาจักรกัมพูชา'),
  createData(128,5,'HAITANG','ไห่ถาง','ชื่อผลไม้ชนิดหนึ่งของสาธารณรัฐประชาชนจีน ผลคล้ายแอ็ปเปิ้ลขนาดเล็ก','สาธารณรัฐประชาชนจีน'),
  createData(129,5,'NALGAE','นัลแก','ปีก','สาธารณรัฐประชาธิปไตยประชาชนเกาหลี'),
  createData(130,5,'BANYAN','บันยัน','ชื่อต้นไม้ชนิดหนึ่ง (ไทร)','เขตบริหารพิเศษฮ่องกง'),
  createData(131,5,'YAMANEKO','ยามาเนโกะ','แมวป่า','ญี่ปุ่น'),
  createData(132,5,'PAKHAR','ปาข่า','ชื่อปลาน้ำจืดชนิดหนึ่งอยู่ในแม่น้ำโขงตอนล่าง','สาธารณรัฐประชาธิปไตยประชาชนลาว'),
  createData(133,5,'SANVU','ซ้านหวู่','ปะการัง','เขตบริหารพิเศษมาเก๊า'),
  createData(134,5,'MAWAR','มาวาร์','กุหลาบ','มาเลเซีย'),
  createData(135,5,'GUCHOL','กูโชล','ขมิ้นชัน [ภาษาพื้นเมืองของเกาะแยป(Yap)]','สาธารณรัฐสหพันธ์ไมโครนีเซีย'),
  createData(136,5,'TALIM','ตาลิม','คม, คมมีด','ฟิลิปปินส์'),
  createData(137,5,'DOKSURI','ทกซูรี','นกอินทรี','สาธารณรัฐเกาหลี (เกาหลีใต้)'),
  createData(138,5,'KHANUN','ขนุน','ชื่อผลไม้ชนิดหนึ่งของไทย','ไทย'),
  createData(139,5,'LAN','แลง','พายุ [ภาษาพื้นเมืองของหมู่เกาะมาร์แชล (Marshall)]','สหรัฐอเมริกา'),
  createData(140,5,'SAOLA','เซาลา','ชื่อสัตว์ชนิดหนึ่ง ตระกูลวัวสาธารณรัฐพบในสังคมนิยมเวียดนาม','สาธารณรัฐสังคมนิยมเวียดนาม'),
];

export default function AcccessibleTable() {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('ลำดับที่')}</TableCell>
            <TableCell align="center">{t('ชุดที่')}</TableCell>
            <TableCell align="center">{t('ชื่อภาษาอังกฤษ')}</TableCell>
            <TableCell align="center">{t('ชื่อพายุ')}</TableCell>
            <TableCell align="center">{t('ความหมาย')}</TableCell>
            <TableCell align="center">{t('ประเทศที่มาของชื่อ')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow key={row.no} style={{ backgroundColor: (row.set % 2 == 0) ? '#ecf0f1' : '#fff' }}>
              <TableCell component="th" scope="row" align="center">{row.no}</TableCell>
              <TableCell component="th" scope="row" align="center">{row.set}</TableCell>
              <TableCell component="th" scope="row" align="left">{row.nameEn}</TableCell>
              <TableCell component="th" scope="row" align="left">{row.nameTh}</TableCell>
              <TableCell component="th" scope="row" align="left">{row.meaning}</TableCell>
              <TableCell component="th" scope="row" align="left">{row.from}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
