import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Img from 'react-image';

import { withTranslation } from 'react-i18next';

// ui, styles
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import Lightbox from 'react-image-lightbox';
import { FaCalendarAlt } from '../../utils/Fontawesome';
import 'react-image-lightbox/style.css';

// icon .
import { HiiRadarIcon } from '../../utils/Icon';

// component
import RadarAgency from '../radar/RadarAgency';

import { styles } from '../../styles/Style';
// import { searchBoxMaxWidth } from '../../utils/SearchBoxStatus';

import SidebarWeather from '../navigation/SidebarWeather';

// credit tooltip
import CreditTooltip from '../../utils/CreditTooltip';
import imageSource from '../../../../public/json/contents/image_source.json';

const initPath = process.env.MIX_APP_PATH;
// import tmdRadar from '../../../../public/images/theme/banner/tmd_radar.jpg';

class WeatherRadar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenHimawari: false,
      isOpenRadar: false,

    };
  }

  render() {
    const { classes, theme, t } = this.props;
    const {
      isOpenHimawari,
      isOpenRadar,
    } = this.state;

    const imageHimawari = [];
    imageHimawari.push({
      src: 'https://weather.tmd.go.th/composite/loop_radarcomposite.gif',
      url: 'http://www.satda.tmd.go.th/Himawari/',

    });

    const imageRadar = [];
    imageRadar.push({
      src: `${process.env.MIX_LIVE_URL}/product/latest/radar/plot/composite_radar.png`,
      url: `${process.env.MIX_LIVE_URL}/product/latest/radar/daily_radar_th.html`,
    });

    return (
      <>

        {/* Sidebar */}
        <SidebarWeather />

        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={3} display="flex">
                <Typography variant="h6" gutterBottom>
                  {t('ติดตามสภาพอากาศ')}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Box display="flex" py={1} width="100%">
                <Box display="inline" p={1} alignItems="center">
                  <HiiRadarIcon fontSize={24} />
                </Box>
                <Box display="inline" pt={1} alignItems="center">
                  <Typography variant="h4">
                    {t('เรดาร์')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <div style={{ width: '100%' }}>
                {/* กรอบ COMPOSITE RADAR  */}
                <Box display="flex">
                  <Box flexGrow={1} p={0.5} display="flex" alignItems="center" justifyContent="center" style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}>
                    <Box className={classes.titleBar}>
                      RADAR COMPOSITE
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" style={{ border: '1px solid #ccc' }} alignItems="center" justifyContent="center">
                  <Box style={{ width: '90%', textAlign: 'center', borderRight: '1px solid #ccc' }}>
                    <Button
                      target="_blank"
                      href={imageRadar[0].url}
                    >
                      <Box fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
                        {t('ภาพเรดาร์ COMPOSITE')}
                      </Box>
                      <IconButton className={classes.button}>
                        <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                      </IconButton>
                    </Button>
                  </Box>
                  <Box style={{ width: '100%', textAlign: 'center' }} alignItems="center" justifyContent="center">
                    <Button
                      target="_blank"
                      /* href={`${process.env.MIX_LIVE_URL}/product/latest/radar/compradar.html`} */
                      href={`${process.env.MIX_APP_PATH}forecast/compradar`}
                      style={{ textTransform: 'capitalize' }}
                    >
                      <Box fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
                        {t('ระบบประเมินน้ำฝนด้วย COMPOSITE RADAR')}
                      </Box>
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" className={classes.boxBottomRadiusNoPadding} alignItems="center" justifyContent="center">
                  <Box p={2}>
                    <Button onClick={() => this.setState({ isOpenRadar: true })}>
                      <Img
                        src={imageRadar[0].src}
                        alt={t('ภาพเรดาร์ COMPOSITE')}
                        className={classes.imgRadarComposit}
                      />
                    </Button>
                    {isOpenRadar && (
                      <Lightbox
                        mainSrc={imageRadar[0].src}
                        imagePadding={50}
                        reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                        onCloseRequest={() => this.setState({ isOpenRadar: false })}
                      />
                    )}
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div style={{ width: '100%' }}>
                {/* กรอบ กลุ่มเมฆฝน ณ ปัจจุบัน จากภาพถ่ายดาวเทียม  */}
                <Box display="flex">
                  <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}>
                    <Box className={classes.titleBar}>
                      TMD RADAR COMPOSITE
                    </Box>
                  </Box>
                  <Box border={1} style={{ border: '1px solid #ccc', borderTopRightRadius: 6 }}>
                    <CreditTooltip
                      title={imageSource[3].tmd.title}
                      link={imageSource[3].tmd.url}
                      placement="bottom-end"
                    >
                      <Button
                        target="_blank"
                        href={imageHimawari[0].url}
                        size="small"
                        className={classes.iconButtonText}
                        rel="noopener noreferrer"
                      >
                        <IconButton className={classes.button}>
                          <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                        </IconButton>
                      </Button>
                    </CreditTooltip>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.boxBottomRadiusNoPaddingRadar}>
                  <Box p={2} display="block" width="100%" className={classes.boxTmdRadar}>
                    {/* <CreditTooltip
                      title={imageSource[3].tmd.title}
                      link={imageSource[3].tmd.url}
                    >
                      <Button onClick={() => this.setState({ isOpenHimawari: true })}>
                        <Img
                          src={imageHimawari[0].src}
                          alt="ภาพ HIMAWARI SATELLITE IR"
                          className={classes.imgRadar}
                        />
                      </Button>
                    </CreditTooltip> */}

                    {/* ภาพใหม่ */}
                    <a target="_blank" rel="noopener noreferrer" href="https://weather.tmd.go.th/composite/index_composite.html">
                      <Box display="flex" justifyContent="center" alignItems="center" style={{ backgroundImage: `url(${initPath}images/theme/banner/tmd_radar.jpg)` }} className={classes.imgRadar} />
                    </a>
                    {isOpenHimawari && (
                      <Lightbox
                        mainSrc={imageHimawari[0].src}
                        imagePadding={50}
                        reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                        onCloseRequest={() => this.setState({ isOpenHimawari: false })}
                      />
                    )}
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <RadarAgency />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

WeatherRadar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(WeatherRadar));
