import PropTypes from 'prop-types';
import React, { Component } from 'react';

// lodash
import {
  uniqueId,
} from 'lodash';

// moment
import moment from 'moment';

// material-ui
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';
import { FaSpinner } from '../../../utils/Fontawesome';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

let images = [];
const width = '90%';
const imageNull = 'https://via.placeholder.com/304x418?text=No%20Image&italic=true';
// const time = ['02:00 UTC', '05:00 UTC', '08:00 UTC', '11:00 UTC', '14:00 UTC', '17:00 UTC', '20:00 UTC', '23:00 UTC'];
const time = ['00:00 น.', '03:00 น.', '06:00 น.', '09:00 น.', '12:00 น.', '15:00 น.', '18:00 น.', '21:00 น.'];
class VaporHistoryList extends Component {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      imageThumb: [],
      currentTimeFromCurrentData: '',
    };
  }

  componentDidMount() {
    const { period, displayState } = this.props;
    // set loading status
    this.setState({
      isLoading: true,
    });
    // get data from ws
    this.fetchData(period, displayState);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.period !== state.period) {
      return {
        period: props.period,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { period, displayState } = this.props;
    if ((prevProps.period.year !== period.year)
      || (prevProps.period.month !== period.month)
      || (prevProps.period.day !== period.day)) {
      this.fetchData(period, displayState);
    }
  }

  fetchData = (period, displayState) => {
    let selectedMonth = period.month;
    images = [];
    if (selectedMonth.toString().length < 2) {
      selectedMonth = `0${selectedMonth}`;
    }
    const url = (displayState === 'latest') ? 'https://api.hii.or.th/tiservice/v1/ws/MBtOTp6IUXbjaCxhQoFQNrFgZUCzNgbo/pwv/latest' : `https://api.hii.or.th/tiservice/v1/ws/MBtOTp6IUXbjaCxhQoFQNrFgZUCzNgbo/pwv/history/${period.year}-${selectedMonth}-${period.day}`;

    // get data from service
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const data = Object.entries(result.image).filter(o => Object.keys(o).some(k => o[k].toLowerCase().includes('pwv')));
        const image = data.filter(o => !o[0].includes('thumb'));
        const currentTimeFromCurrentData = (displayState === 'latest') ? moment(Object.entries(result.image)[3][1]).add(7, 'hours').format('HH:00 น.') : '';

        image.forEach(i => {
          images.push({
            src: i[1],
            title: i[0],
          });
        });
        const imageThumb = data.filter(o => o[0].includes('thumb'));
        this.setState({
          imageThumb,
          currentTimeFromCurrentData,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      error,
      imageThumb,
      photoIndex,
      isOpen,
      currentTimeFromCurrentData,
    } = this.state;

    const timeLightBox = (currentTimeFromCurrentData !== '') ? currentTimeFromCurrentData : time[photoIndex];
    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={35} />
          </div>
        )}
        <Grid container spacing={4} direction="row">
          {imageThumb.map((row, i) => {
            const showTime = (currentTimeFromCurrentData !== '') ? currentTimeFromCurrentData : time[i];
            return (
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                <Box mt={1} p={4} className={classes.boxAccumulatHistory}>
                  <Box display="block" align="center">
                    <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                      <Img
                        key={uniqueId()}
                        src={[row[1], imageNull]}
                        width={width}
                        className={classes.responsive}
                      />
                    </Button>
                  </Box>
                  <Box p={2} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      เวลา : {showTime}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={`GNSS-Precipitable Water Vapor ${timeLightBox}`}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imageLoadErrorMessage="No Image at this Time"
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

VaporHistoryList.propTypes = {
  classes: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  displayState: PropTypes.string.isRequired,
};

export default withStyles(styles)(VaporHistoryList);
