import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const styles = {
  mainmenu: {
    fontFamily: 'kanit',
    fontSize: '20px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  menuControlStyle: {
    padding: '5px',
    zIndex: 99999,
    borderRadius: '5px',
    position: 'fixed',
    right: '0.4%',
    top: '12%',
    width: '18em',
  },
};

export default function WrfRomsNavigation(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [_mounted, setMounted] = useState(true);
  const [value, setValue] = useState('https://live1.hii.or.th/product/latest/wrfroms/tiff/fcst_rain24hr_d03_day1.tif');
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [dateObj, setDateObj] = useState({});
  const filename = [
    'https://live1.hii.or.th/product/latest/wrfroms/tiff/fcst_rain24hr_d03_day1.tif',
    'https://live1.hii.or.th/product/latest/wrfroms/tiff/fcst_rain24hr_d03_day2.tif',
    'https://live1.hii.or.th/product/latest/wrfroms/tiff/fcst_rain24hr_d03_day3.tif',
  ];

  const [getOpenLayer, setOpenLayer] = useState(true);
  function handleChange(event) {
    // setLoading(true);
    setValue(event.target.value);
    props.handler(event.target.value, dateObj[event.target.value]);
    // setLoading(false);
  }

  async function fetchData() {
    const url = 'https://live1.hii.or.th/product/latest/wrfroms/tiff/description_wrfroms.json';

    fetch(url)
      .then(response => response.json())
      .then(result => {
        // get date and convert to date array
        const d = [];
        setData(result);

        result.forEach(element => {
          d.push(`${moment(element[0].date).add(543, 'y').format('DD MMMM YY')} (${element[0].fcst_time} UTC)`);
        });

        filename.map((k, index) => {
          dateObj[k] = d[index];
          return null;
        });

        setDateObj(dateObj);
        // setDate(d);

        // init month name
        props.handlerInitDate(d[0]);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    // get forecast date
    fetchData();

    if (_mounted) {
      setLoading(false);
    }
    // componentWillUnmount
    return () => {
      setMounted(false);
    };
  }, []);

  const handleClickLayer = () => {
    if (getOpenLayer === false) {
      setOpenLayer(true);
    } else {
      setOpenLayer(false);
    }
  };

  return (
    <>
      {isLoading === true && (
        <div className="text-center">
          Loading...
        </div>
      )}
      <Box style={styles.menuControlStyle}>
        <FormControl component="fieldset">
          <Box display="flex" justifyContent="flex-end" component="span" m={1}>
            <Button
              variant="contained"
              style={{
                zIndex: 99999,
                backgroundColor: 'white',
              }}
              aria-haspopup="true"
              onClick={handleClickLayer}
            >
              {t('คาดการณ์')}
              {getOpenLayer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
          </Box>
          <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
            {
              getOpenLayer ? (
                <RadioGroup
                  aria-label="date"
                  name="ForecastDate"
                  value={value}
                  onChange={handleChange}
                >
                  {data.map(val => (
                    <FormControlLabel
                      key={val[0].date}
                      value={val[0].filename}
                      control={<Radio />}
                      label={`${val[0].date} (${val[0].fcst_time})`}
                    />
                  ))}
                </RadioGroup>
              ) : null
            }
          </div>
        </FormControl>
      </Box>
    </>
  );
}

WrfRomsNavigation.propTypes = {
  handler: PropTypes.func.isRequired,
  handlerInitDate: PropTypes.func.isRequired,
};
