/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { uniqueId } from 'lodash';

// Icon
import { HIIForecastRaindaylyIcon } from '../../utils/Icon';

const initPath = process.env.MIX_APP_URL;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: 400,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    fontSize: '1.2em',
    fontWeight: 500,
    color: '#3980C0',
  },
  subTitle: {
    fontSize: '1.1em',
    fontWeight: 500,
  },
  detail: {
    fontSize: '1em',
    color: '#717171',
    whiteSpace: 'normal',
  },
  date: {
    fontSize: '0.8em',
    color: '#717171',
    marginTop: 5,
  },
  dateIcon: {
    fontSize: '1em',
    marginRight: 8,
  },
  line: {
    borderTop: '1px solid #183547',
  },
});
class CompNoti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNoti: [],
    };
    this.renderListNoti = this.renderListNoti.bind(this);
  }

  componentDidMount() {
    const { rainfall, floodroad, dataUrbanWarning } = this.props;
    const resultRainfall24h = [];
    const resultWaterFloodRoad = [];
    const resultUrbanWarning = [];
    // สถานีฝนที่มีฝนตก >= 60 mm
    rainfall.map(data => data.rain_24h >= 60 && resultRainfall24h.push(data));
    // สถานีน้ำท่วมถนนที่มีระดับน้ำ >=20 cm.
    floodroad.map(data => {
      if (data.floodroad_value >= 20) {
        const Data = data;
        Data.station_type = 'floodroad'; // สร้างสถานะข้อมูลเพือแยกแสดงผล icon
        resultWaterFloodRoad.push(data);
        return resultWaterFloodRoad;
      }
      return false;
    });
    // มีข้อมูลแจ้งเตือนจากเรดาร์กทม
    if (dataUrbanWarning.length !== 0) {
      dataUrbanWarning.map(data => {
        const Data = data;
        Data.station_type = 'urban_warning'; // สร้างสถานะข้อมูลเพือแยกแสดงผล icon
        Data.rainfall_datetime = Data.event_date;
        resultUrbanWarning.push(Data);
        return resultUrbanWarning;
      });
    }

    let notiDataRaw = resultWaterFloodRoad.concat(
      resultRainfall24h,
    ).sort((a, b) => a.rainfall_datetime - b.rainfall_datetime);

    if (resultUrbanWarning.length !== 0) {
      notiDataRaw = resultUrbanWarning.concat(notiDataRaw);
    }

    this.setState({ listNoti: notiDataRaw });
  }

  renderListNoti = data => {
    const { classes } = this.props;
    const addressData = result => {
      if (result.station_type !== 'urban_warning') {
        if (result.geocode.province_name.th === 'กรุงเทพมหานคร') {
          return ` ข.${result.geocode.amphoe_name.th}  ขว.${result.geocode.tumbon_name.th}  จ.${result.geocode.province_name.th}`;
        }
        return ` อ.${result.geocode.amphoe_name.th}  ต.${result.geocode.tumbon_name.th}  จ.${result.geocode.province_name.th}`;
      }
      return result.markpoint;
    };
    const iconList = result => {
      if (result.station_type === 'rainfall_24h') {
        return <HIIForecastRaindaylyIcon style={{ color: 'red' }} />;
      }
      if (result.station_type === 'urban_warning') {
        return <img src={`${initPath}/images/marker/bma/Warning/warningBma.png`} className={classes.icon} style={{ width: '3em' }} alt="Icon" />;
      }
      return (
        <Avatar>
          <img src={`${initPath}images/theme/bma/icon/icon-flood-pink.png`} className={classes.icon} alt="Icon" />
        </Avatar>
      );
    };
    return (
      <React.Fragment key={uniqueId()}>
        {data.map(result => (
          <React.Fragment key={uniqueId()}>
            <ListItem>
              <ListItemAvatar>
                {iconList(result)}
              </ListItemAvatar>
              <Box>
                <Box className={classes.subTitle}>
                  {result.station_type === 'rainfall_24h' || result.station_type === 'urban_warning' ? 'ฝนหนักมาก' : `น้ำท่วมมากกว่า ${result.floodroad_value} ซม.`}
                </Box>
                <Box className={classes.detail}>
                  {addressData(result)}
                </Box>
                <Box className={classes.date} display="flex" alignItems="center">
                  <AccessTimeIcon className={classes.dateIcon} />
                  {`${moment(result.rainfall_datetime).fromNow()}`}
                </Box>
              </Box>
            </ListItem>
            <Divider className={classes.line} />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const { listNoti } = this.state;
    return (
      <List className={classes.root}>
        <Box className={classes.title}>แจ้งเตือน</Box>
        {listNoti.length !== 0 ? this.renderListNoti(listNoti) : 'ยังไม่มีข้อมูลแจ้งเตือน'}
      </List>
    );
  }
}

CompNoti.propTypes = {
  classes: PropTypes.object.isRequired,
  rainfall: PropTypes.array.isRequired,
  floodroad: PropTypes.array.isRequired,
  dataUrbanWarning: PropTypes.array.isRequired,
};

export default withStyles(styles)(CompNoti);
