import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _, { orderBy, find } from 'lodash';
import moment from 'moment';

// ui
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import DamChart from './DamChart';
// import { damsMediumRid } from '../../store/dam';

import { styles } from '../../styles/Style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
const years = yearsRange(currentYear, 2011);

class DamMediumChart extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      selectedType: 'mediumdam_storage',
      selectedYear: [`${currentYear}`, `${currentYear - 1}`, `${currentYear - 2}`],
      selectedDam: '',
      selectedDamSize: 'medium',
      selectedDamRidOffice: 'all',
      dataRidType: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, name: `สำนักชลประทานที่ ${i + 1}` })),
      damsMediumRidFilter: props.damsMediumRid,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props.id + '-' + state.selectedDam);
    if (state.selectedDam === '') {
      return {
        selectedDam: props.id,
      };
      // console.log(props.id)
    }

    return null;
  }

  componentDidMount() {
    const { rid_office, damsMediumRid } = this.props;
    if(rid_office){
      const filteredDamMedium = damsMediumRid.filter(item => item.rid_office === rid_office);
      this.setState({
        selectedDamRidOffice: rid_office, 
        damsMediumRidFilter: filteredDamMedium 
      })
    }
  }

  handleChange = event => {
    // console.log(event.target);
  
    if (event.target.name === 'selectedYear') {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      // if (value.length <= 15) {
      this.setState({ [event.target.name]: value });
      // } else {
      //   alert(t('เลือกปีได้ สูงสุด 15 ปีย้อนหลัง'));
      // }
    } else if(event.target.name === 'selectedDamRidOffice'){
      const { name, value } = event.target;
      const { damsMediumRid } = this.props;
      let filteredDamMedium;
      if(value === 'all'){
        filteredDamMedium = damsMediumRid;
      } else {
        filteredDamMedium = damsMediumRid.filter(item => item.rid_office === value);
      }

      this.setState({ 
        [name]: value , 
        damsMediumRidFilter : filteredDamMedium, 
        selectedDam: filteredDamMedium[0]?.id ?? '',
      });
    } else {

      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChart = () => {
    const { selectedYear } = this.state;
    // alert(selectedType + ' - ' + selectedYear + ' - ' + selectedDam);
    if (_.isEmpty(selectedYear)) {
      this.setState({ YearError: true });
    } else {
      this.setState({ YearError: false });
      this.child.current.resetChart();
      this.child.current.renderChart();
    }
  }

  render() {
    const { classes, damsMediumRid, t } = this.props;
    const {
      damsMediumRidFilter,
      selectedType,
      selectedYear,
      selectedDam,
      selectedDamSize,
      selectedDamRidOffice,
      YearError,
    } = this.state;
    return (
      <div>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={10} lg={10} xl={10}>
              <Box py={{ xs: 0, sm: 2 }} className={classes.boxGraphDam}>
                <Box className={classes.graphDam}>
                  <DamChart
                    ref={this.child}
                    type={selectedType}
                    id={selectedDam}
                    year={selectedYear}
                    size={selectedDamSize}
                    ridOffice={selectedDamRidOffice}
                    name={find(damsMediumRid, ['id', selectedDam]).name}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type">{t('ประเภทข้อมูล')}</InputLabel>
                    <Select
                      value={selectedType}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedType',
                        id: 'type',
                      }}
                    >
                      <MenuItem value="mediumdam_storage">{t('ปริมาณน้ำเก็บกัก')}</MenuItem>
                      <MenuItem value="mediumdam_inflow">{t('ปริมาณน้ำไหลลง')}</MenuItem>
                      <MenuItem value="mediumdam_released">{t('ปริมาณน้ำระบาย')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl} error={YearError}>
                    <InputLabel htmlFor="year">{t('ปี')}</InputLabel>
                    <Select
                      multiple
                      native
                      value={selectedYear}
                      onChange={this.handleChange}
                      input={<Input id="year" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                      inputProps={{
                        name: 'selectedYear',
                        id: 'year',
                      }}
                    >
                      {years.map(year => (
                        <option key={year} value={year} defaultValue={(selectedYear.indexOf(year) > -1) ? 'selected' : 'false'}>
                          {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="year">{t('สปช.')}</InputLabel>
                    <Select
                      value={selectedDamRidOffice}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedDamRidOffice',
                        id: 'id',
                      }}
                    >
                      <MenuItem key={0} value={'all'}>
                        {"ทั้งหมด"} {/* "ทั้งหมด" means "All" */}
                      </MenuItem>
                      {this.state.dataRidType.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="year">{t('อ่างเก็บน้ำ')}</InputLabel>
                    <Select
                      value={selectedDam}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedDam',
                        id: 'dam_id',
                      }}
                    >
                      {orderBy(damsMediumRidFilter, ['name'], ['asc']).map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {t(item.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <Button variant="outlined" color="primary" size="small" onClick={this.handleChart}>
                      {t('แสดง')}
                    </Button>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ color: '#A2A2A2' }}>
            *สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
          </div>
        </Grid>
      </div>
    );
  }
}

DamMediumChart.propTypes = {
  id: PropTypes.number.isRequired,
  rid_office: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  damsMediumRid: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DamMediumChart);
