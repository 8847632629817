/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Box, Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import JSZip from 'jszip';

import {
  FaSpinner,
} from '../../utils/Fontawesome';

const useStyles = makeStyles({
  boxList: {
    border: '1px solid #ccc',
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#0064AF',
    color: 'white',
  },
  buttonDownload: {
    borderRadius: 20,
    padding: '2px 10px',
    fontSize: '0.8em',
    '&:hover': {
      backgroundColor: '#0064AF',
      color: 'white',
    },
  },
  wordWrap: {
    wordWrap: 'break-word',
  },
});

export default function GsFile() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [datas, setDatas] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currYear, setCurrYear] = useState(moment().format('YYYY'));
  const [isZipping, setZipping] = useState(false);
  const [isAllfile, setAllfile] = useState();

  function getFiles() {
    const url = `${process.env.MIX_APP_URL}proxy/json.php?file=${process.env.MIX_API_HII_URL}/v2/ws/${process.env.MIX_API_HII_TOKEN}/gsmap-asean/download/${Number(currYear)}`;
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        if (result.data) {
          setDatas(result.data);
          setAllfile(result.data.months.length);
        } else {
          setDatas([]);
        }
        setLoading(false);
      })
      .catch(error => error);
  }

  useEffect(() => {
    getFiles();
  }, [currYear]);

  const handleToggle = fileId => {
    const currentIndex = selectedFiles.indexOf(fileId);
    const newSelected = [...selectedFiles];

    if (currentIndex === -1) {
      newSelected.push(fileId);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    if (isAllfile === newSelected.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    setSelectedFiles(newSelected);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedFiles([]);
    } else {
      const allFileIds = datas.months.map(file => file.name);
      setSelectedFiles(allFileIds);
    }
    setSelectAll(!selectAll);
  };

  const downloadSelectedFiles = async () => {
    setZipping(true);
    const postData = [];
    const selectedFilesData = datas.months.filter(file => selectedFiles.includes(file.name));
    const fetchPromises = selectedFilesData.map(file => fetch(`${process.env.MIX_APP_URL}proxy/zip.php?file=${file.url}`)
      .then(response => response.blob())
      .then(blob => ({ name: file.name, blob }))
      .catch(error => console.error('Error fetching file:', error)));

    Promise.all(fetchPromises)
      .then(fileBlobs => {
        const zip = new JSZip();

        fileBlobs.forEach(({ name, blob }) => {
          zip.file(name, blob);
        });

        return zip.generateAsync({ type: 'blob' });
      })
      .then(zipBlob => {
        const url = window.URL.createObjectURL(zipBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `gsmap_download_${currYear}_files.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setZipping(false);
      })
      .catch(error => {
        console.error('Error creating zip file:', error);
        setZipping(false);
      });
    // ${process.env.MIX_APP_URL}proxy/api.php?file=
    selectedFilesData.map(item => {
      postData.push({ name: item.name });
    });
    try {
      const url = `${process.env.MIX_APP_URL}proxy/api-aseanwater.php?file=${process.env.MIX_API_HII_URL}/v2/ws/${process.env.MIX_API_HII_TOKEN}/gsmap-asean/count-download&body=${JSON.stringify({ postData })}`;
      const response = await fetch(url);
      if (response.ok) {
        console.log('update download count succeeded');
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  const handleYearChange = date => {
    setCurrYear(String(date.year()));
    setSelectedFiles([]);
    setSelectAll(false);
    setLoading(true);
  };

  const renderFiles = fileList => {
    const columns = [];
    if (fileList) {
      columns.push(fileList.months);
    }
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'between' }} width="100%">
          <Box p={3} className={classes.boxList} width="100%">
            <ListItem button onClick={handleSelectAll}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectAll}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </ListItem>
            <Grid container spacing={2}>
              {columns.map(column => (
                <Grid item xs={12} sm={6} md={12} key={column[0].name}>
                  {column.map(file => (
                    <ListItem key={file.name} button onClick={() => handleToggle(file.name)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedFiles.indexOf(file.name) !== -1}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                        secondary={`Downloads: ${file.download_count}`}
                        classes={{ primary: classes.wordWrap }}
                      />
                    </ListItem>
                  ))}
                </Grid>
              ))}
            </Grid>
            <Button variant="contained" color="primary" onClick={downloadSelectedFiles} disabled={selectedFiles.length === 0} style={{ width: 200 }}>
              {isZipping ? <FaSpinner size={20} /> : 'Download Selected Files'}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Grid container justifyContent="center" spacing={4} style={{ paddingTop: 10, marginTop: 20, marginBottom: 20 }}>
        <Grid item xs sm={3} md={3}>
          <Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                openTo="year"
                views={['year']}
                value={currYear}
                onChange={handleYearChange}
                style={{ fontSize: 14 }}
                maxDate={new Date(moment())}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container justifyContent="center" spacing={4} style={{ paddingTop: 10, marginTop: 20 }}>
          <div className="text-center">
            <FaSpinner size={30} />
          </div>
        </Grid>
      ) : (isEmpty(datas))
        ? (<div className="text-center">No data for the selected time period.</div>)
        : renderFiles(datas)}
    </>
  );
}
