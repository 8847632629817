import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId,
} from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// ui
import Img from 'react-image';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import Lightbox from 'react-image-lightbox';
import { FaSpinner } from '../../../utils/Fontawesome';
import 'react-image-lightbox/style.css';

import { styles } from '../../../styles/Style';

const width = '90%';

// lightbox
const images = [];

class UpperWindLatest extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      imgs: [],
      keyData: this.props.keyData,
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({
      isLoading: true,
    });

    // get data from ws
    this.getData();
  }

  getData() {
    fetch(`${process.env.MIX_API_URL}analyst/upper_wind_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes, t, i18n } = this.props;
    const {
      isLoading,
      error,
      imgs,
      photoIndex,
      isOpen,
      keyData,
    } = this.state;
    const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;
    // moment.locale(languageI18n);

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    return (
      <>
        {error && <div className="text-center">{error.message}</div>}
        {isLoading === true && (
        <div className="text-center">
          <FaSpinner size={35} />
        </div>
        )}
        <Grid container spacing={4}>
          <>
            {keyData === ' 0.6 ' ? (
              imgs.slice(0, 1).map((row, i) => {
                let realImage = '';
                if (row.media_path !== '') {
                  images.push({
                    src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                    title: `${moment(row.datetime).locale(languageI18n).format('LLL')} ${t('น.')}`,
                  });
                  realImage = (
                    <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                      <Img
                        src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                        width={width}
                        alt={moment(row.datetime).locale(languageI18n).format('ll')}
                        className={classes.responsive}
                        // style={{ height: 400 }}
                      />
                    </Button>
                  );
                } else {
                  realImage = (
                    <Img
                      src={row.src}
                      width={width}
                      alt="No Image"
                      className={classes.responsive}
                      // style={{ height: 400 }}
                    />
                  );
                }
                return (
                  <Grid item xs={12} md={4} lg={4} key={uniqueId()}>
                    <Box p={0} display="block">
                      <Box display="flex" className={classNames(classes.boxRadiusForecast, classes.statusNull)} align="center">
                        <Box p={1} width="100%" style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                          <Typography variant="h6">{moment(row.datetime).locale(languageI18n).format('ll')}</Typography>
                        </Box>
                      </Box>
                      <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                        {realImage}
                      </Box>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              imgs.slice(1, 2).map((row, i) => {
                let realImage = '';
                if (row.media_path !== '') {
                  images.push({
                    src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                    title: `${moment(row.datetime).locale(languageI18n).format('LLL')} ${t('น.')}`,
                  });
                  realImage = (
                    <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                      <Img
                        src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                        width={width}
                        alt={moment(row.datetime).locale(languageI18n).format('ll')}
                        className={classes.responsive}
                        // style={{ height: 400 }}
                      />
                    </Button>
                  );
                } else {
                  realImage = (
                    <Img
                      src={row.src}
                      width={width}
                      alt="No Image"
                      className={classes.responsive}
                      // style={{ height: 400 }}
                    />
                  );
                }
                return (
                  <Grid item xs={12} md={4} lg={4} key={uniqueId()}>
                    <Box p={0} display="block">
                      <Box display="flex" className={classNames(classes.boxRadiusForecast, classes.statusNull)} align="center">
                        <Box p={1} width="100%" style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                          <Typography variant="h6">{moment(row.datetime).locale(languageI18n).format('ll')}</Typography>
                        </Box>
                      </Box>
                      <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                        {realImage}
                      </Box>
                    </Box>
                  </Grid>
                );
              })
            )}
          </>
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

UpperWindLatest.propTypes = {
  classes: PropTypes.object.isRequired,
  keyData: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(UpperWindLatest);
