/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
    marginBottom: 4,
  },
  label: {
    color: '#eee',
    fontSize: '0.9em',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  detail: {
    paddingTop: 5,
    color: 'white',
    fontSize: '0.9em',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
});

const RainLegend = () => {
  const classes = useStyles();

  return (
    <div>
      <Box p={1}>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#d32f2f',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>&gt; 90 </Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#e91e63',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>&gt; 35 - 90</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#ffc107',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>&gt; 10 - 35</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#ffee58',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>&gt; 0.5 - 10</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#69b9a0',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>&gt; 0 - 0.5</Box>
        </Box>
        <Box className={classes.detail}>เกณฑ์ปริมาณน้ำฝน</Box>
      </Box>
    </div>
  );
};

export default RainLegend;
