import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _, { orderBy, find } from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// ui
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import DamChartDailyCompare from './DamChartDailyCompare';
import { regionRid } from '../../store/dam';

import { styles } from '../../styles/Style';

// style for drop down multiple checkbox
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currentDate = moment().date();
const currentMonth = moment().month() + 1;

// initialize multiple drop down checkbox data
const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
const selectedYears = yearsRange(currentYear, 2011);
const years = yearsRange(currentYear, 1993);

class DamDailyCompareChartSumByRegion extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      // มีการเรียกกราฟจากหน้าแรกโดยส่งค่ามาจาก props
      // selectedType: 'total_dam_storage',
      selectedType: props.type,
      selectedDate: `${currentDate}`,
      selectedMonth: `${currentMonth}`,
      selectedYear: selectedYears,
      selectedRegion: '',
    };
  }

  componentDidMount() {
    this.handleChart();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.selectedRegion === '') {
      return {
        selectedRegion: props.id.toString(),
      };
      // console.log(props.id)
    }

    return null;
  }

  handleChange = event => {
    // console.log(event.target);
    if (event.target.name === 'selectedYear') {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      // if (value.length <= 15) {
      this.setState({ [event.target.name]: value });
      // } else {
      //   alert(t('เลือกปีได้ สูงสุด 15 ปีย้อนหลัง'));
      // }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChart = () => {
    const { selectedYear } = this.state;
    // alert(selectedType + ' - ' + selectedYear + ' - ' + selectedDam);
    if (_.isEmpty(selectedYear)) {
      this.setState({ YearError: true });
    } else {
      this.setState({ YearError: false });
      this.child.current.resetChart();
      this.child.current.renderChartSum();
    }
  }

  render() {
    const { classes, t } = this.props;
    const {
      selectedType,
      selectedDate,
      selectedMonth,
      selectedYear,
      selectedRegion,
      YearError,
    } = this.state;

    return (
      <div>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={10} lg={10} xl={10}>
              <Box py={{ xs: 0, sm: 2 }} className={classes.boxGraphDam}>
                <Box className={classes.graphDam}>
                  <DamChartDailyCompare
                    ref={this.child}
                    type={selectedType}
                    id={selectedRegion.toString()}
                    date={selectedDate}
                    month={selectedMonth}
                    year={selectedYear}
                    name={(selectedRegion && parseInt(selectedRegion, 10) !== 0) ? find(regionRid, ['id', parseInt(selectedRegion, 10)]).name : 'รวมทั้งประเทศ'}
                    chartCalType="sum"
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type">{t('ประเภทข้อมูล')}</InputLabel>
                    <Select
                      value={selectedType}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedType',
                        id: 'type',
                      }}
                    >
                      <MenuItem value="total_dam_storage">{t('ปริมาณน้ำเก็บกัก')}</MenuItem>
                      <MenuItem value="total_dam_uses_water">{t('ปริมาณน้ำใช้การ')}</MenuItem>
                      <MenuItem value="total_dam_inflow">{t('ปริมาณน้ำไหลลง')}</MenuItem>
                      <MenuItem value="total_dam_released">{t('ปริมาณน้ำระบาย')}</MenuItem>
                      <MenuItem value="total_dam_inflow_acc">{t('ปริมาณน้ำไหลลงสะสม')}</MenuItem>
                      <MenuItem value="total_dam_released_acc">{t('ปริมาณน้ำระบายสะสม')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl>
                    <InputLabel htmlFor="year">{t('ภาค')}</InputLabel>
                    <Select
                      value={selectedRegion}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedRegion',
                        id: 'id',
                      }}
                    >
                      <MenuItem key={99} value={0}>
                        {t('รวมทั้งประเทศ')}
                      </MenuItem>
                      {orderBy(regionRid, ['name'], ['asc']).map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {t(item.name)}
                          {/* {item.name} */}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl>
                    <InputLabel htmlFor="type">{t('วันที่')}</InputLabel>
                    <Select
                      value={selectedDate}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedDate',
                        id: 'date',
                      }}
                    >
                      {[...Array(31)].map((x, i) => <MenuItem key={`${x}i`} value={`${i + 1}`}>{i + 1}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type">{t('เดือน')}</InputLabel>
                    <Select
                      value={selectedMonth}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedMonth',
                        id: 'month',
                      }}
                    >
                      <MenuItem value="1">{t('มกราคม')}</MenuItem>
                      <MenuItem value="2">{t('กุมภาพันธ์')}</MenuItem>
                      <MenuItem value="3">{t('มีนาคม')}</MenuItem>
                      <MenuItem value="4">{t('เมษายน')}</MenuItem>
                      <MenuItem value="5">{t('พฤษภาคม')}</MenuItem>
                      <MenuItem value="6">{t('มิถุนายน')}</MenuItem>
                      <MenuItem value="7">{t('กรกฎาคม')}</MenuItem>
                      <MenuItem value="8">{t('สิงหาคม')}</MenuItem>
                      <MenuItem value="9">{t('กันยายน')}</MenuItem>
                      <MenuItem value="10">{t('ตุลาคม')}</MenuItem>
                      <MenuItem value="11">{t('พฤศจิกายน')}</MenuItem>
                      <MenuItem value="12">{t('ธันวาคม')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl} error={YearError}>
                    <InputLabel htmlFor="year">{t('ปี')}</InputLabel>
                    <Select
                      multiple
                      value={selectedYear.sort().reverse()}
                      onChange={this.handleChange}
                      input={<Input id="year" />}
                      renderValue={selected => selected.sort().reverse().join(', ')}
                      MenuProps={MenuProps}
                      native
                      inputProps={{
                        name: 'selectedYear',
                        id: 'year',
                      }}
                    >
                      {years.map(year => (
                        <option key={year} value={year} selected={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                          {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <Button variant="outlined" color="primary" size="small" onClick={this.handleChart}>
                      {t('แสดง')}
                    </Button>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classes.info}>
            *
            {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
          </Box>
        </Grid>
      </div>
    );
  }
}

DamDailyCompareChartSumByRegion.defaultProps = {
  type: 'total_dam_storage',
};

DamDailyCompareChartSumByRegion.propTypes = {
  id: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation('translations', { withRef: true }))(DamDailyCompareChartSumByRegion);
