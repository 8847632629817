/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { orderBy } from 'lodash';
import { withTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

// ui,
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';

// color
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import DamStatusColor from '../dam/DamStatusColor';
import DamMainDailyChart from '../dam/DamMainContainer';
import DashboardContext from '../../contexts/dashboardContext';

// icon
import {
  HiiDam12Icon,
  HiiDamL2Icon,
  HiiPlaningIcon,
  HiiDamUseIcon,
  HiiDrainageIcon,
} from '../../utils/Icon';
import { FaCalendarAlt } from '../../utils/Fontawesome';
import {
  styles,
} from '../../styles/Style';
import '../../styles/Chart.css';

// init highcharts module
Highcharts.setOptions({
  lang: {
    numericSymbols: null,
  },
});
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const lineBox = grey[300];

class DamUseWater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      season: {
        current: this.getCurrentSeason(),
        day: 0,
        dayLeft: 0,
        dayPercent: 0,
        damUseWater: 0,
        damUseWaterPercent: 0,
        damReleased: 0,
        dayRemain: 0,
        damLastDate: null,
        drougth: {
          name: 'ฤดูแล้ง',
          startDate: (moment().format('M') >= 11 && moment().format('M') <= 12) ? `${moment().locale('th').format('YYYY')}-11-01` : `${moment().add(-1, 'Y').locale('th').format('YYYY')}-11-01`,
          endDate: (moment().format('M') >= 11 && moment().format('M') <= 12) ? `${moment().add(+1, 'Y').locale('th').format('YYYY')}-04-30` : `${moment().locale('th').format('YYYY')}-04-30`,
          labelColor: '#AA5D00',
          waterUse: 8500,
        },
        rainy: {
          name: 'ฤดูฝน',
          startDate: `${moment().locale('th').format('YYYY')}-05-01`,
          endDate: `${moment().locale('th').format('YYYY')}-10-31`,
          labelColor: '#2A7AB0',
          waterUse: 12000,
        },
      },
    };

    this.afterChartCreated = this.afterChartCreated.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.seasonDiffDay();
    this.getData();
  }

  // 2022-06-01 เพิ่ม componentDidUpdate เพื่อแก้ไขปัญหาตอนเปลี่ยนภาษาเมนูด้านบนแล้ว chart ไม่เปลี่ยนภาษา
  componentDidUpdate(prevProps) {
    const { language } = this.props;
    if (language !== prevProps.language) {
      this.validateSeasonRenderChart();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getData() {
    // get data from service
    const { season } = this.state;
    const seasons = season[season.current];
    Promise.all([
      fetch(`${process.env.MIX_API_URL}provinces/dam_uses_water?dam_id=1,11,12,36`),
      fetch(`${process.env.MIX_API_URL}provinces/dam_uses_water?dam_id=1,11,12,36&start_date=${moment(seasons.startDate).add(+1, 'days').format('YYYY-MM-DD')}`),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([result1, result2]) => {
        const damUseWater = result1.data[0].dam_uses_water;
        const damUseWaterPercent = result1.data[0].dam_uses_water_percent;
        const damLastDate = result1.data[0].dam_end_date;
        const damReleased = result2.data[0] ? result2.data[0].dam_released : null;
        // console.log('damUseWater : ', damUseWater, ' | damUseWaterPercent : ', damUseWaterPercent, ' | damLastDate : ', damLastDate, ' | damReleased : ', damReleased);
        this.setState(prevState => ({
          season: {
            ...prevState.season,
            damUseWater,
            damUseWaterPercent,
            damLastDate,
            damReleased,
          },
        }));
        this.validateSeasonRenderChart();
      })
      .catch(error => error);
  }

  setDateFormmat = date => {
    let newdate = moment(date).locale('th').format('D MMM YYYY');

    if (date === '' || date === 'now') {
      newdate = moment().locale('th').format('D MMM YYYY');
    }

    return newdate;
  }

  setWidthChart() {
    const { width } = this.props;
    let widthChart = '570';
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      widthChart = '570';
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      widthChart = '570';
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      widthChart = '570';
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      widthChart = '570';
    }
    return widthChart;
  }

  setMarginChart() {
    const { width } = this.props;
    let maginTop = '65';
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      maginTop = '80';
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      maginTop = '80';
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      maginTop = '80';
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      maginTop = '105';
    }
    return [maginTop, 20, 55, 80];
  }

  setYearThai() {
    const { language } = this.props;

    moment.locale('th-TH');
    let varPlusForYearThai = 543;
    if (language !== 'th') {
      moment.locale('en-EN');
      varPlusForYearThai = 0;
    }
    return varPlusForYearThai;
  }

  getChartOptions() {
    const { t } = this.props;
    const varPlusForYearThai = this.setYearThai();

    this.options = {
      chart: {
        width: this.setWidthChart(),
        margin: this.setMarginChart(),
        zoomType: 'xz', // whatever string containg x
        plotBorderWidth: 1,
        style: {
          fontFamily: 'Kanit',
          fontSize: '14px',
          overflowX: 'auto',
        },
      },
      title: {
        text: '',
      },
      subtitle: {
        text: `${t('เขื่อน')}${t('ภูมิพล')} ${t('เขื่อน')}${t('สิริกิติ์')} ${t('เขื่อน')}${t('ป่าสักชลสิทธิ์')} ${t('เขื่อน')}${t('แควน้อยบำรุงแดน')}`,
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          dataLabels: {
            enabled: true,
            formatter() {
              if (this.series.name === t('ระบายน้ำสะสม')) {
                const { points } = this.series;
                if (this.x === points[points.length - 1].x) {
                  return `${t('ระบายน้ำไปแล้ว')} ${Highcharts.numberFormat(this.y, 0)} (${t('ล้าน ลบ.ม.')})`;
                }
              } else if (this.series.name === t('น้ำไหลลงสะสม')) {
                const { points } = this.series;
                if (this.x === points[points.length - 1].x) {
                  return `${t('มีน้ำไหลลงแล้ว')} ${Highcharts.numberFormat(this.y, 0)} (${t('ล้าน ลบ.ม.')})`;
                }
              }
              return null;
            },
          },
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
            ],
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },
      tooltip: {
        formatter: function formatter() {
          return `<b> ${moment(this.x).add(varPlusForYearThai, 'years').format('DD MMM YYYY')} </b> <br/> ${this.series.name}  : ${Highcharts.numberFormat(this.y, 0)} ${t('ล้าน ลบ.ม.')}`;
        },
      },
      xAxis: {
        title: {
          text: '',
        },
        tickInterval: 30 * 24 * 3600 * 1000,
        type: 'datetime',
        labels: {
          step: 1,
          formatter: function formatter() {
            return moment(this.value).add(varPlusForYearThai, 'years').format('MMM YYYY');
          },
          style: {
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: `${t('ปริมาณน้ำ')} (${t('ล้าน ลบ.ม.')})`,
        },
        labels: {
          formatter() {
            return Highcharts.numberFormat(this.value, 0, '', ',');
          },
        },
        minorGridLineWidth: 0,
        plotLines: [
          {
            value: null,
            color: '',
            dashStyle: 'dash',
            width: 2,
            label: {
              text: null,
              style: {
                color: '',
                fontWeight: 'bold',
              },
              align: 'left',
              zIndex: 9999,
            },
          },
        ],
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'bottom',
        itemMarginBottom: 40,
        itemStyle: {
          fontSize: '14px',
        },
      },
      credits: {
        enabled: false,
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
          },
        },
      },
    };
    return this.options;
  }

  handleChart = handler => e => {
    const { t } = this.props;
    e.preventDefault();
    const header = `${t('ข้อมูลปริมาณน้ำใช้การ')} 4 ${t('เขื่อนหลักลุ่มน้ำเจ้าพระยา')} ${t('ย้อนหลัง')}`;
    const params = {
      header,
      content: <DamMainDailyChart type="total_dam_storage" />,
    };

    handler(params);
  }

  getCurrentSeason = () => {
    const currentMonth = moment().format('M');
    const params = new URLSearchParams(window.location.search);
    const seasonParam = params.get('season');
    const currentSeason = (currentMonth >= 11 || currentMonth <= 4) ? 'drougth' : 'rainy';
    return seasonParam || currentSeason;
  }

  numberWithCommas = x => {
    // fix bug check x = null set new value is zero.
    // const data = x === '%!f(<nil>)' ? 0 : x;
    const data = x === null ? 0 : x;
    const rep = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return (rep === 'nil') ? 0 : rep;
  }

  widgetTitle = () => {
    const { classes, t } = this.props;
    const { season } = this.state;
    const seasons = season[season.current];

    const varPlusForYearThai = this.setYearThai();

    return (
      <Box display="flex">
        <Box display="flex" width="100%" mt={5} p={1} style={{ borderColor: lineBox }} border={1} alignItems="center" fontFamily="Kanit">
          <Box width="100%">
            <Box pr={2} display="inline" fontSize={18}>
              <Box display="inline" style={{ position: 'absolute' }}><HiiDamUseIcon /></Box>
              <Box ml={4} display="inline">
                {t('ข้อมูลปริมาณน้ำใช้การ')}
              </Box>
              <Box pl={1} display={{ xs: 'block', sm: 'inline' }} fontWeight={500} fontSize={18}>
                4
                {' '}
                {t('เขื่อนหลักลุ่มน้ำเจ้าพระยา')}
              </Box>
              {' '}
              {t('วันที่')}
              {' '}
              <Box px={1} mr={1} display="inline" fontWeight={500} borderRadius={3} fontSize={18} bgcolor="#00000014">
                {moment().add(varPlusForYearThai, 'years').format('D MMM YYYY')}
              </Box>
              <Box display="inline">
                {t('ใน')}
              </Box>
            </Box>
            <Box px={1} display="inline" borderRadius={3} fontWeight={500} fontSize={16} bgcolor={seasons.labelColor} color="#fff" flexWrap="nowrap">
              {t(seasons.name)}
            </Box>
          </Box>

          {/* <Box pl={2} display="inline" fontSize={16}>ระหว่างวันที่</Box>
          <Box pl={1} display="inline" fontWeight="fontWeightBold" fontSize={18}>
            {`${this.setDateFormmat(seasons.startDate)} - ${this.setDateFormmat(seasons.endDate)}`}
          </Box> */}
        </Box>

        <Box flexShrink={0} mt={5} style={{ borderColor: lineBox, borderLeft: 0 }} border={1}>
          <DashboardContext.Consumer>
            {({ handleModalOpen }) => (
              <Button
                className={classes.outlinedOldRightPrimary}
                onClick={this.handleChart(handleModalOpen)}
                title={t('ดูข้อมูล 4 เขื่อนหลักย้อนหลัง')}
              >
                <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                <Hidden xsDown>
                  <Typography variant="h6" style={{ marginLeft: 8 }}>
                    {t('ดูข้อมูลย้อนหลัง')}
                  </Typography>
                </Hidden>
              </Button>
            )}
          </DashboardContext.Consumer>
        </Box>
      </Box>
    );
  };

  seasonDiffDay = () => {
    const { season } = this.state;
    const seasons = season[season.current];
    const today = moment().format('YYYY-MM-DD');
    const start = moment(seasons.startDate, 'YYYY-MM-DD');
    const end = moment(seasons.endDate, 'YYYY-MM-DD');
    const day = end.diff(start, 'days') + 1;
    const dayLeft = day - end.diff(today, 'days') - 1;
    const dayRemain = day - dayLeft;
    const dayPercent = (dayLeft * 100) / day;

    this.setState(prevState => ({
      season: {
        ...prevState.season,
        day,
        dayLeft,
        dayPercent,
        dayRemain,
      },
    }));
  }

  storageStatus = percent => {
    const status = DamStatusColor(parseFloat(percent).toFixed(0));
    return status.color.statusColor;
  }

  // 2023-03-15 แยก function ออกมาสำหรับ
  validateSeasonRenderChart() {
    const { season } = this.state;
    // console.log('season.current => ', season.current);
    if (season.current === 'rainy') {
      this.renderRainyChart();
    } else {
      this.renderDrougthChart();
    }
    this.internalChart.reflow();
  }

  afterChartCreated(chart) {
    this.internalChart = chart;
    this.forceUpdate();
  }

  renderRainyChart() {
    const { dataDam, t } = this.props;
    const { season } = this.state;
    const seasons = season[season.current];
    const dataDamUseWater = [];
    const dataDamInflow = [];
    const dataSeries = [];
    let ts = null;
    let val = null;
    const storageStatus = this.storageStatus(
      parseFloat(season.damUseWaterPercent).toFixed(0),
    );

    if (dataDam.hasOwnProperty('summary4dam')) {
      orderBy(dataDam.summary4dam.data, ['dam_date'], ['asc']).map(n => {
        val = parseFloat(n.dam_uses_water);
        ts = moment(n.dam_date).valueOf();
        if (moment(n.dam_date).isBetween(seasons.startDate, seasons.endDate)) {
          dataDamUseWater.push([ts, val]);
        }
        return dataDamUseWater;
      });

      let totalDamInflow = 0;
      orderBy(dataDam.summary4dam.data, ['dam_date'], ['asc']).map(n => {
        val = parseFloat(n.dam_inflow);

        ts = moment(n.dam_date).valueOf();
        if (moment(n.dam_date).isBetween(seasons.startDate, seasons.endDate)) {
          totalDamInflow += val;
          dataDamInflow.push([ts, totalDamInflow]);
        }
        return dataDamInflow;
      });
    }
    const chartOptions = this.getChartOptions();

    // plotLines
    chartOptions.title.text = `${t('กราฟติดตามปริมาณน้ำ 4 เขื่อนหลักที่ต้องกักเก็บในฤดูฝน (สำหรับเป็นน้ำใช้การช่วงฤดูแล้งและต้นฤดูฝนของปี')} )`;
    chartOptions.xAxis.title.text = t('เดือนในฤดูฝน พ.ค - ต.ค');
    chartOptions.yAxis.plotLines[0].value = seasons.waterUse;
    chartOptions.yAxis.plotLines[0].label.text = `${t('ประมาณการความต้องการใช้น้ำ')} ${this.numberWithCommas(seasons.waterUse)} ${t('ล้าน ลบ.ม.')}`;
    chartOptions.yAxis.plotLines[0].label.style.color = seasons.labelColor;
    chartOptions.yAxis.plotLines[0].color = seasons.labelColor;
    chartOptions.plotOptions.series.dataLabels.color = '#9e9e9e';

    chartOptions.xAxis.min = moment(seasons.startDate).valueOf();
    chartOptions.xAxis.max = moment(seasons.endDate).valueOf();

    chartOptions.yAxis.minRange = seasons.waterUse;

    // set series
    dataSeries.push({
      name: t('น้ำใช้การ'),
      data: dataDamUseWater,
      color: storageStatus, // '#3573c2',
      tooltip: {
        valueSuffix: ` ${t('ล้าน ลบ.ม.')}`,
      },
      marker: { symbol: 'square' },
    });

    dataSeries.push({
      name: t('น้ำไหลลงสะสม'),
      type: 'area',
      data: dataDamInflow,
      color: '#9e9e9e',
      tooltip: {
        valueSuffix: ` ${t('ล้าน ลบ.ม.')}`,
      },
      marker: { symbol: 'square' },
    });

    chartOptions.series = dataSeries;
    chartOptions.chart.width = this.setWidthChart();

    this.setState({ chartOptions });

    return true;
  }

  renderDrougthChart() {
    const { dataDam, t } = this.props;
    const { season } = this.state;
    const seasons = season[season.current];
    const dataDamReleased = [];
    const dataDamUseWater = [];
    const dataSeries = [];
    let ts = null;
    let val = null;
    const storageStatus = this.storageStatus(
      parseFloat(season.damUseWaterPercent).toFixed(0),
    );

    // Highcharts error #15:
    // orderBy(dataDam.summary4dam.data, ['dam_date'], ['desc']).map(n => {
    // This happens when creating a line series or a stock chart where the data is not sorted
    // in ascending X order.
    // For performance reasons, Highcharts does not sort the data, instead it requires
    // that the implementer pre-sorts the data.

    try {
      orderBy(dataDam.summary4dam.data, ['dam_date'], ['asc']).map(n => {
        val = parseFloat(n.dam_uses_water);
        ts = moment(n.dam_date).valueOf();
        if (moment(n.dam_date).isBetween(seasons.startDate, seasons.endDate)) {
          dataDamUseWater.push([ts, val]);
        }
        return dataDamUseWater;
      });

      let totalDamReleased = 0;
      orderBy(dataDam.summary4dam.data, ['dam_date'], ['asc']).map(n => {
        val = parseFloat(n.dam_released);

        ts = moment(n.dam_date).valueOf();
        if (moment(n.dam_date).isBetween(seasons.startDate, seasons.endDate)) {
          totalDamReleased += val;
          // dataDamReleased.push([ts, totalDamReleased]);
          // dataDamReleased.push([ts, parseInt(totalDamReleased, 10)]);
          // 2024-01-02 แก้ไข totalDamReleased เป็นเลขจำนวจเต็ม เช่น 1984.6500000000005 เป็น 1985
          dataDamReleased.push([ts, Math.round(totalDamReleased)]);
        }
        return dataDamReleased;
      });
    } catch (error) {
      return '';
    }

    const chartOptions = this.getChartOptions();
    // plotLines
    chartOptions.title.text = `${t('กราฟแสดงการระบายน้ำสะสมของ 4 เขื่อนหลัก')} ${t('ฤดูแล้ง')}`;
    chartOptions.xAxis.title.text = t('เดือนในฤดูแล้ง พ.ย - เม.ย');
    chartOptions.yAxis.plotLines[0].value = seasons.waterUse;
    chartOptions.yAxis.plotLines[0].label.text = `${t('แผนการใช้น้ำฤดูแล้ง')} ${this.numberWithCommas(seasons.waterUse)} ${t('ล้าน ลบ.ม.')}`;
    chartOptions.yAxis.plotLines[0].label.style.color = seasons.labelColor;
    chartOptions.yAxis.plotLines[0].color = seasons.labelColor;
    chartOptions.plotOptions.series.dataLabels.color = '#3573c2';

    chartOptions.xAxis.min = moment(seasons.startDate).valueOf();
    chartOptions.xAxis.max = moment(seasons.endDate).valueOf();

    chartOptions.yAxis.minRange = seasons.waterUse;

    // set series
    dataSeries.push({
      name: t('น้ำใช้การ'),
      data: dataDamUseWater,
      color: storageStatus, // '#3573c2',
      tooltip: {
        valueSuffix: ` ${t('ล้าน ลบ.ม.')}`,
      },
      marker: { symbol: 'square' },
    });

    dataSeries.push({
      name: t('ระบายน้ำสะสม'),
      type: 'area',
      data: dataDamReleased,
      tooltip: {
        valueSuffix: ` ${t('ล้าน ลบ.ม.')}`,
      },
      marker: { symbol: 'square' },
    });

    chartOptions.series = dataSeries;
    chartOptions.chart.width = this.setWidthChart();
    this.setState({ chartOptions });
    return true;
  }

  render() {
    const { classes, t } = this.props;
    const { season, chartOptions } = this.state;
    const seasons = season[season.current];
    const storageStatus = this.storageStatus(
      parseFloat(season.damUseWaterPercent).toFixed(0),
    );
    const labelText = (season.current === 'rainy') ?
      seasons.waterUse - season.damUseWater > 0 ? t('ต้องการน้ำเก็บกักเพิ่ม') : t('ปริมาณน้ำเพียงพอต่อการใช้น้ำในช่วงฤดูแล้งและช่วง ต้นฤดูฝน ของปี 2568')
      : t('ระบายน้ำสะสม');
    // const chart = this.internalChart;

    const varPlusForYearThai = this.setYearThai();

    return (
      <div className={classes.layout}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {this.widgetTitle()}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box p={2} style={{ borderColor: lineBox }} border={1} borderTop={0} align="center" display="flex" justifyContent="center" alignItems="center" fontFamily="Kanit" height={{ sm: 230, md: 230, lg: 230 }}>
              <Box width={{
                xs: '50%', sm: '50%', md: '60%', lg: '174px',
              }}
              >
                {parseInt(season.dayLeft, 10) === 0 && (
                  <>
                    <Typography component="h5">
                      {moment().locale('th').format('D MMM YYYY')}
                    </Typography>
                    <br />
                    <Typography variant="h4" style={{ color: red[800] }}>
                      {t('สิ้นสุดการระบายน้ำตามแผนแล้ว')}
                    </Typography>
                  </>
                )}
                {parseInt(season.dayLeft, 10) !== 0 && (
                  <>
                    <CircularProgressbarWithChildren
                      value={parseInt(season.dayPercent, 10)}
                      strokeWidth="12"
                      // className={classes.circularProgress}
                      styles={{
                        text: {
                          fill: seasons.labelColor,
                        },
                        path: {
                          stroke: '#ababab',
                          strokeLinecap: 'butt',
                        },
                        trail: {
                          stroke: seasons.labelColor,
                        },
                        position: 'absolute',
                        marginTop: '50%',
                      }}
                    >
                      <Box>
                        <Box fontSize={18}>
                          {t('อีก')}
                        </Box>
                        <Box mt={-1} py={0} fontWeight={500} fontSize={42}>
                          {Number.isNaN(parseInt(season.dayRemain, 10))
                            ? '--'
                            : parseInt(season.dayRemain, 10)}
                          <Box fontSize={16} display="inline" color={seasons.labelColor}>
                            /
                            {Number.isNaN(parseInt(season.day, 10))
                              ? '--'
                              : parseInt(season.day, 10)}
                          </Box>
                        </Box>
                        <Box fontSize={25} mt={-1}>{t('วัน')}</Box>
                      </Box>
                    </CircularProgressbarWithChildren>
                    <Box p={1} fontSize={14}>
                      {t('จะสิ้นสุด')}
                      {t(seasons.name)}
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box
              py={0}
              border={1}
              borderTop={0}
              style={{ borderColor: lineBox }}
              align="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontFamily="Kanit"
              height={{
                xs: 230, sm: 230, md: 230, lg: 230,
              }}
            >
              {season.current === 'rainy' ? (
                <>
                  <Box>
                    <Box mb={-2} pb={-2}><HiiPlaningIcon style={{ fontSize: '4rem' }} /></Box>
                    <Box my={0} pt={1} fontSize={16}>
                      <Box>
                        {t('ประมาณการความต้องการใช้น้ำ')}
                        {t('ในช่วง')}
                      </Box>
                      <Box fontWeight={500} display="inline" color="#AA5D00">
                        {' '}
                        {t('ฤดูแล้ง')}
                        {' '}
                      </Box>
                      {t('และช่วง')}
                      <Box fontWeight={500} display="inline" color={seasons.labelColor}>
                        {' '}
                        {t('ต้นฤดูฝน')}
                        {' '}
                      </Box>
                      <Box>
                        {t('ของปี')}
                        {' '}
                        {moment().locale('th').add(varPlusForYearThai + 1, 'years').format('YYYY')}
                      </Box>
                    </Box>
                    <Box
                      my={-1}
                      py={-1}
                      fontWeight={500}
                      fontSize={48}
                      color={seasons.labelColor}
                    >
                      {this.numberWithCommas(seasons.waterUse)}
                    </Box>
                    <Box py={0} fontSize={18}>
                      {' '}
                      {t('ล้านลูกบาศก์เมตร')}
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    <Box mb={-2} pb={-2}><HiiPlaningIcon style={{ fontSize: '4rem' }} /></Box>
                    <Box my={0} pt={1} fontSize={27}>
                      {t('แผนการใช้น้ำ')}
                      <Box display="inline">
                        {' '}
                        {t(seasons.name)}
                      </Box>
                    </Box>
                    <Box my={-1} py={0} px={1} fontSize={16}>
                      {t('เพื่ออุปโภค-บริโภค')}
                    </Box>
                    <Box my={-1} py={0} px={1} fontSize={16}>
                      {t('การเกษตรและรักษาระบบนิเวศ')}
                    </Box>
                    <Box
                      my={-1}
                      py={-1}
                      fontWeight={500}
                      fontSize={48}
                      color={seasons.labelColor}
                    >
                      {this.numberWithCommas(seasons.waterUse)}
                    </Box>
                    <Box py={0} fontSize={18}>
                      {' '}
                      {t('ล้านลูกบาศก์เมตร')}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box
              py={2}
              border={1}
              borderLeft={{
                xs: 1, sm: 0, md: 0, lg: 0,
              }}
              style={{ borderColor: lineBox }}
              borderTop={0}
              align="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontFamily="Kanit"
              height={{
                sm: 230, md: 230, lg: 230,
              }}
            >
              <Box>
                <Box display="flex" alignItems="center" mt={0} pt={0} mb={-1} style={{ width: '100%' }}>
                  <Box mt={-2} mb={-2} pt={0} p={1} style={{ width: '50%' }}><HiiDamL2Icon style={{ fontSize: '5em' }} /></Box>
                  <Box mt={-2} mb={-2} pt={0} p={1} fontWeight={500} fontSize={60} color={storageStatus} style={{ width: '50%' }} align="left">
                    {season.damUseWaterPercent}
                    %
                  </Box>
                </Box>
                <Box fontSize={18} fontcolor="primary">
                  {' '}
                  {t('น้ำใช้การของ 4 เขื่อนหลัก')}
                </Box>
                <Box
                  my={-1}
                  py={-1}
                  fontWeight={500}
                  fontSize={48}
                  color={storageStatus}
                >
                  {this.numberWithCommas(season.damUseWater)}
                </Box>
                <Box py={0} fontSize={18}>
                  {' '}
                  {t('ล้านลูกบาศก์เมตร')}
                </Box>
                <Box pt={1} fontSize={11}>
                  {t('ล่าสุด')}
                  {' '}
                  :
                  {' '}
                  {moment(season.damLastDate).add(varPlusForYearThai, 'years').locale('th').format('D MMM YYYY')}
                </Box>
              </Box>
            </Box>
            <Box
              p={2}
              px={3}
              border={1}
              borderLeft={{
                xs: 1, sm: 0, md: 0, lg: 0,
              }}
              borderTop={0}
              style={{ borderColor: lineBox }}
              align="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontFamily="Kanit"
              height={{
                xs: 230, sm: 230, md: 230, lg: 230,
              }}
            >
              <Box>
                <Box mb={0} pb={0}>
                  {season.current === 'rainy' ? (
                    <HiiDam12Icon style={{ fontSize: '5rem' }} />

                  ) : (
                    <HiiDrainageIcon style={{ fontSize: '5rem' }} />
                  )}
                </Box>
                <Box fontSize={15}>
                  {/* ตั้งแต่
                  {' '}
                  {this.setDateFormmat(seasons.startDate)}
                  {' '}
                  ถึง
                  <Box component="div" display="inline" color={statusGreen}>
                    {' '}
                    {moment().locale('th').format('D MMM YYYY')}
                    {' '}
                  </Box> */}
                  <Box component="div" display="block">
                    {t(labelText)}
                  </Box>
                </Box>
                <Box my={-1} py={-1} fontWeight={500} fontSize={48}>
                  {season.current === 'rainy' ? (
                    seasons.waterUse - season.damUseWater > 0 ? <Box color={seasons.labelColor}>
                      <>{this.numberWithCommas(seasons.waterUse - season.damUseWater)}</>
                    </Box> : null
                  ) : (
                    <Box style={{ color: '#9E9E9E' }}>
                      <>{this.numberWithCommas(season.damReleased)}</>
                    </Box>
                  )}
                </Box>
                <Box py={0} fontSize={18}>
                  {seasons.waterUse - season.damUseWater > 0 ? ' ' : null}
                  {seasons.waterUse - season.damUseWater > 0 ? t('ล้านลูกบาศก์เมตร') : null}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              p={0}
              borderLeft={{
                xs: 1, sm: 1, md: 0, lg: 0,
              }}
              align="center"
              border={1}
              borderTop={0}
              style={{ borderColor: lineBox, fontSize: '0.8em' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={{ md: 460, lg: 460 }}
            >
              {/* {console.log('chartOptions => ', chartOptions)} */}
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                callback={this.afterChartCreated}
                containerProps={{ className: 'mobile-responsive' }}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DamUseWater.propTypes = {
  classes: PropTypes.object.isRequired,
  dataDam: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  language: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation('translations', { withRef: true }))(DamUseWater);
