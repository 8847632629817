import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';
import { latLngBounds } from 'leaflet';

// import {
//   orderBy, // uniqBy, uniqueId,
// } from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
// import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


// table .
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

// width
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';

// icon .
// import IconButton from '@material-ui/core/IconButton';
import { HiiOceanSeaIcon } from '../../utils/Icon';

import SealevelMap from './SealevelMap';
import SealevelList from './SealevelList';
import SealevelChart from './SealevelChart';

import SealevelForcastChart from './SealevelForcastChart';
import SealevelForcastList from './SealevelForcastList';
import SealevelForcastMap from './SealevelForcastMap';

// sidebar
import SidebarWater from '../navigation/SidebarWater';

import {
  styles,
} from '../../styles/Style';

// modal
import Modal from '../modal/Modal';

const TabContainer = ({ children }) => (
  <Typography component="div">
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Sealevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true, // loading status
      error: null, // error
      search: '',
      searchSealevel: [],

      // map
      mapForcast: null,
      map: null,
      zoom: 5.7,
      location: [12.9, 101.6],
      markers: [],
      bounds: null,

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      filterDatasSealevel: [],
      latestDate: '',
      value: 0,

      // tide
      tideDataStation: [],
      searchSealevelForcast: [],
    };

    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleSetMap = this.handleSetMap.bind(this);
    this.handleSetMapForcast = this.handleSetMapForcast.bind(this);
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
    this.fetchTideCsv();
  }

  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line react/prop-types
    if (props.filterDatasSealevel !== state.datas) {
      return {
        // eslint-disable-next-line react/prop-types
        datas: props.filterDatasSealevel,
        // eslint-disable-next-line react/prop-types
        searchSealevel: props.filterDatasSealevel,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  getData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const searchSealevel = result.waterlevel_data.data.filter(
          d => d.station.id === 770 || d.station.id === 753 || d.station.id === 17,
        );

        const bounds = latLngBounds([]);
        if (searchSealevel.length > 0) {
          searchSealevel.forEach(data => {
            bounds.extend([data.station.tele_station_lat, data.station.tele_station_long]);
          });
        }

        this.setState({
          searchSealevel,
          filterDatasSealevel: searchSealevel,
          isLoading: false,
          latestDate: this.getMaxDate(searchSealevel),
          bounds,
        });
      })
      .catch(error => this.setState({ error, isLoading: true }));

    return true;
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSetMapForcast = mapForcast => {
    this.setState({
      mapForcast,
    });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleSearchMarker = (id, latitude, longitude) => e => {
    const { map, markers } = this.state;
    e.preventDefault();
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
      const LatLng = { lat: latitude, lng: longitude };
      const px = map.project(LatLng);
      px.y -= 213 / 2;
      map.panTo(map.unproject(px), { animate: true });
    }
    // e.preventDefault();
    // map.panTo([parseFloat(lat) + 3, parseFloat(lng)]);
    // const currentMarker = markers[id];
    // const currentZoom = map.getZoom();
    // if (currentMarker) {
    //   currentMarker.openPopup();
    // }
    // if (currentZoom > 6) {
    //   map.setZoom(5);
    // }
  };

  handleSearchMarkerForcast = (id, lat, lng) => e => {
    const { mapForcast, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    mapForcast.panTo([parseFloat(lat) + 3, parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];

    const currentZoom = mapForcast.getZoom();
    if (currentMarker) {
      currentMarker.openPopup();
    }
    if (currentZoom > 6) {
      mapForcast.setZoom(5);
    }
  };

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.waterlevel_datetime));
      const maxdate = new Date(Math.max.apply(null, dates));

      return maxdate.toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
    return 'ไม่มีข้อมูล';
  };

  handleModalOpen = data => {
    const { classes, t } = this.props;
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = t('กราฟระดับน้ำบริเวณอ่าวไทยและอันดามัน');
    modalData.subheader = null;
    modalData.content = (
      <SealevelChart
        id={data.id}
        provinceId={data.provinceId}
        classes={classes}
      />
    );
    this.setState({ modalData });
  };

  handleModalForcastOpen = data => {
    const { classes, t } = this.props;
    const { tideDataStation } = this.state;

    // console.log('data : ', data);

    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = t('กราฟระดับน้ำคาดการณ์');
    modalData.subheader = null;
    modalData.content = (
      <SealevelForcastChart
        id={data.id}
        tideDataStation={tideDataStation}
        tideStationCode={data.code}
        classes={classes}
      />
    );
    this.setState({ modalData });
  };

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  fetchTideCsv() {
    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/tide_table/summary.txt`)
      .then(resp => resp.text())
      .then(results => {
        const list = Papa.parse(results).data.slice(1);
        const arrayList = [];
        list.slice(0, -1).map((a, i) => arrayList.push({
          id: i,
          tide_code: a[0],
          tide_station_th: a[1],
          tide_station_en: a[2],
          tide_station_lat: a[3],
          tide_station_long: a[4],
          max_value: a[5],
          max_time: a[6],
          min_value: a[7],
          min_time: a[8],
          time_0000: a[9],
          time_0400: a[10],
          time_0800: a[11],
          time_1200: a[12],
          time_1600: a[13],
          time_2000: a[14],
        }));

        this.setState({
          isLoading: false,
          tideDataStation: arrayList,
          searchSealevelForcast: arrayList,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      classes, theme, t, i18n,
    } = this.props;
    const {
      searchSealevel,
      value,
      tideDataStation,
      searchSealevelForcast,
      modalData,
    } = this.state;

    return (
      <Fragment key="key">

        {/* Sidebar  */}
        <SidebarWater />

        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Typography variant="h6" gutterBottom>
                    {t('ติดตามสถานการณ์น้ำ')}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5} lg={5}>
              <Box display="flex" py={1} width="100%">
                <Box display="inline" p={1} alignItems="center">
                  <HiiOceanSeaIcon fontSize={24} />
                </Box>
                <Box display="inline" pt={2} alignItems="center">
                  <Typography variant="h4">
                    {t('ระดับน้ำบริเวณปากแม่น้ำ')}
                  </Typography>
                </Box>
              </Box>
              <Box mt={2} width="100%">
                {value === 0 && (
                  <SealevelMap
                    language={i18n.language}
                    {...this.state}
                    datas={searchSealevel}
                    handleSetMap={this.handleSetMap}
                    handleModalOpen={this.handleModalOpen}
                  />
                )}

                {value === 1 && (
                  <SealevelForcastMap
                    {...this.state}
                    datas={searchSealevelForcast}
                    handleSetMapForcast={this.handleSetMapForcast}
                    handleModalForcastOpen={this.handleModalForcastOpen}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={7}>
              <Hidden smDown><Box height="20px" /></Hidden>
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                className={classes.boxTab}
              >
                <Tab label={t('ระดับน้ำตรวจวัดจริง')} className={value === 0 ? classes.active_tab : ''} />
                <Tab label={t('ระดับน้ำคาดการณ์')} className={value === 1 ? classes.active_tab : ''} />
              </Tabs>
              {value === 0 && (
                <TabContainer component={Paper}>
                  <Box mt={2} />
                  <SealevelList
                    datas={searchSealevel}
                    handleSearchMarker={this.handleSearchMarker}
                    handleModalOpen={this.handleModalOpen}
                  />
                </TabContainer>
              )}

              {value === 1 && (
                <TabContainer component={Paper}>
                  <Box mt={2} />
                  <SealevelForcastList
                    datas={tideDataStation}
                    handleSearchMarkerForcast={this.handleSearchMarkerForcast}
                    handleModalForcastOpen={this.handleModalForcastOpen}
                    theme={theme}
                  />
                </TabContainer>
              )}
            </Grid>
          </Grid>
          <Modal
            open={modalData.open}
            modalHeader={modalData.header}
            modalContent={modalData.content}
            resetModal={this.resetModal}
          />
        </div>
      </Fragment>
    );
  }
}

Sealevel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(Sealevel);
