/**
 * *
 * @version 1.0.0
 * @author [Manorot]
 * Modify by Thitiporn
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  flow, uniqueId, groupBy, orderBy,
} from 'lodash/fp';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
} from 'react-router-dom';
import Img from 'react-image';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { SEO } from '../../../utils/Seo';

// ui
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import grey from '@material-ui/core/colors/grey';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import {
  FaHome, FaSpinner, FaPlay,
} from '../../../utils/Fontawesome';
import {
  styles,
} from '../../../styles/Style';

import VerticalWindAnimation from './VerticalWindAnimation';
import VerticalWindLatest from './VerticalWindLatest';

const initPath = process.env.MIX_APP_PATH;

const hourList = [
  { time: '07:00' },
  { time: '19:00' },
];

// lightbox
let images = [];

const currentDate = new Date(moment().subtract(1, 'day'));

class VerticalWindHistory extends Component {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef();

    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      datas: [],
      groupDatas: [],
      displayState: 'history',
    };
  }

  componentDidMount() {
    const { match: { params: { month, year } } } = this.props;

    // latest data available (minus 1 days from current date)
    const startDate = new Date(moment().subtract(1, 'day'));

    let selectedMonth = moment(startDate).format('MM');
    let selectedYear = moment(startDate).format('YYYY');

    if (month && year) {
      selectedMonth = month;
      selectedYear = year;
    }

    this.setState({
      isLoading: true,
      startDate,
    });

    this.fetchData(selectedMonth, selectedYear);
  }

  fetchData = (selectedMonth, selectedYear) => {
    fetch(`${process.env.MIX_API_URL}analyst/vertical_wind_history_img?month=${selectedMonth}&year=${selectedYear}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // const dataArray = _(result.data).orderBy(['datetime'], ['desc'])
        //   .groupBy(d => moment(d.datetime).format('YYYY-MM-DD'))
        //   .map((value, key) => ({ date: key, datas: value }))
        //   .value();
        const groupData = flow(
          orderBy(['datetime'], ['desc']),
          groupBy(d => moment(d.datetime).format('YYYY-MM-DD')),
        )(result.data);
        const dataArray = Object.entries(groupData)
          .map(([key, val]) => ({ date: key, datas: val }));

        this.setState({
          isLoading: false,
          datas: result.data,
          groupDatas: dataArray,

        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    });

    this.fetchData(moment(date).format('MM'), moment(date).format('YYYY'));
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate;
    if (change === '1') {
      selectedDate = moment(this.state.startDate).add(1, 'months');
    } else if (change === '-1') {
      selectedDate = moment(this.state.startDate).add(-1, 'months');
    }
    this.setState({
      startDate: selectedDate,
    });
    this.fetchData(moment(selectedDate).format('MM'), moment(selectedDate).format('YYYY'));
  }

  renderHistory(startDate, classes, datas, groupDatas) {
    let currentIndex = 0;
    const { i18n, t } = this.props;
    const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
          <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={3} md={3} lg={3}>
            <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
              <KeyboardDatePicker
                openTo="month"
                views={['year', 'month']}
                value={startDate}
                onChange={this.handleChange}
                style={{ fontSize: 14 }}
                maxDate={currentDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>

        {datas.length <= 0
          && (
            <Grid container spacing={4}>
              <Grid item xs>
                <Typography variant="h6">
                  {t('ไม่พบข้อมูลในช่วงเวลาที่เลือก')}
                </Typography>
              </Grid>
            </Grid>
          )}

        {groupDatas.map(item => (
          <Grid container spacing={4} key={uniqueId()}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box p={1} mt={2} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                {moment(item.date).locale(languageI18n).format('LL')}
              </Box>
            </Grid>
            {hourList.map(meta => {
              const total = item
                .datas
                .filter(row => moment(row.datetime).locale(languageI18n).format('HH:mm') === meta.time)
                .length;
              if (total === 0) {
                return (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                    <Box className={classes.boxWeatherHistory}>
                      <Box display="block" align="center">
                        <Img
                          src="https://via.placeholder.com/300x250.png?text=No+Image"
                          width="300"
                          height="250"
                          className={classes.responsive}
                          alt="No Image"
                        />
                      </Box>
                      <Box p={1} display="flex" alignItems="center" justifyContent="center">
                        <Typography className={classes.dateTime}>
                          {`${t('เวลา')} : `}
                          {' '}
                          {meta.time}
                          {' '}
                          {t('น.')}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              }

              return (
                <Fragment key={uniqueId()}>
                  {item
                    .datas
                    .filter(row => moment(row.datetime).format('HH:mm') === meta.time)
                    .map(row => {
                      let realImage = '';
                      const currentImgIndex = currentIndex;
                      if (row.media_path_thumb !== null) {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                          title: moment(row.datetime).locale(languageI18n).format('LLL'),
                        });

                        realImage = (
                          <Button
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                              width="300"
                              height="250"
                              className={classes.responsive}
                              alt={moment(row.datetime).locale(languageI18n).format('LLL')}
                            />
                          </Button>
                        );
                        currentIndex += 1;
                      } else {
                        realImage = (
                          <Img
                            src="https://via.placeholder.com/300x250.png?text=No+Image"
                            width="300"
                            height="250"
                            className={classes.responsive}
                            alt="No Image"
                          />
                        );
                      }

                      return (
                        <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                          <Box className={classes.boxWeatherHistory}>
                            <Box display="block" align="center">
                              {realImage}
                            </Box>
                            <Box p={1} display="flex" alignItems="center" justifyContent="center">
                              <Typography className={classes.dateTime}>
                                {`${t('เวลา')} : `}
                                {' '}
                                {meta.time}
                                {' '}
                                {t('น.')}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Fragment>
              );
            })}
          </Grid>
        ))}
      </>
    );
  }

  render() {
    images = [];

    const { classes, t } = this.props;
    const {
      isLoading,
      error,
      datas,
      groupDatas,
      photoIndex,
      isOpen,
      startDate,
      displayState,
    } = this.state;

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {isLoading === true && (
                <FaSpinner size={25} />
              )}

              {error && (
                <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>
          <SEO 
            title={t('แผนที่ลมแนวดิ่ง ที่ระดับความสูง 5 กม.')}
            description={t('แผนที่ลมแนวดิ่ง ที่ระดับความสูง 5 กม.')}
            keywords={t('แผนที่ลมแนวดิ่ง ที่ระดับความสูง 5 กม.')}
          />
          <Grid container spacing={4} style={{ marginTop: 12 }}>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb" className={classes.Breadcrumbs}>
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('อากาศ')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('แผนที่ลมแนวดิ่ง ที่ระดับความสูง 5 กม.')}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 24 }}>
            <Grid item xs={12} md={7}>
              <Box m={1} className={classes.titleName}>
                {t('แผนที่ลมแนวดิ่ง ที่ระดับความสูง 5 กม.')}
              </Box>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพล่าสุด')}>
                <Button
                  className={classes.outlinedLeftPrimary}
                  color={displayState === 'latest' ? 'primary' : 'default'}
                  variant={displayState === 'latest' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'latest' })}
                >
                  <ImageOutlinedIcon fontSize="small" />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}> {t('ดูภาพล่าสุด')}</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <Button
                  className={classes.outlinedPrimary}
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'history' })}
                >
                  <PhotoLibraryOutlinedIcon fontSize="small" />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูภาพย้อนหลัง')}</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
                <Button
                  className={classes.outlinedRightPrimary}
                  color={displayState === 'animation' ? 'primary' : 'default'}
                  variant={displayState === 'animation' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'animation' })}
                >
                  <FaPlay style={{ fontSize: '0.8em' }} />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูภาพเคลื่อนไหว')}</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          {displayState === 'latest' && (
            <VerticalWindLatest />
          )}
          {displayState === 'animation' && (
            <VerticalWindAnimation />
          )}

          {displayState === 'history' && (
            this.renderHistory(startDate, classes, datas, groupDatas)
          )}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

VerticalWindHistory.defaultProps = {
  match: {
    params: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  },
};

VerticalWindHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      month: PropTypes.string,
      year: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(VerticalWindHistory);
