/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { withLeaflet, MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-geotiff';
import 'leaflet-geotiff/leaflet-geotiff-plotty';

class GeotiffLayer extends MapLayer {
  // eslint-disable-next-line class-methods-use-this
  createLeafletElement(props) {
    const { url, options } = props;
    return L.leafletGeotiff(url, options);
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    const { name, active } = this.props;
    const Layer = this.leafletElement.addTo(map);
    Layer.setStyle({
      opacity: 0.5,
    });

    const control = L.control.layers(null, null, { collapsed: false, position: 'topleft', hideSingleBase: true }).addTo(map);
    control.addOverlay(Layer, name);

    if (!active) map.removeLayer(Layer);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const PlottyGeotiffLayer = withLeaflet(props => {
  const { options, layerRef } = props;
  // eslint-disable-next-line global-require
  const plotty = require('plotty');
  plotty.addColorScale('fcstRaincolorscale', ['#FFFFFF', '#CBE3FF', '#AED3FF', '#97E5AB', '#6AC805', '#F9D601', '#FD8B02', '#CC6602', '#FE3003', '#FE3003', '#881A1B', '#9630D1'],
    [0, 0.01, 0.02, 0.04, 0.07, 0.1, 0.14, 0.18, 0.3, 0.4, 0.6, 1]);

  plotty.addColorScale('obsRaincolorscale', ['#e7f4b5', '#e19c51', '#e15153', '#6267f0', '#272ae8', '#232b78'], [0, 0.2, 0.6, 0.8999, 0.7, 1]);

  plotty.addColorScale('droughtFore_7days',
    ['#1C6EEE', '#2782F5', '#3C96F6', '#50A5F5', '#76BAFB', '#98D1FE', '#AEF3F8', '#E0FFFF', '#78F573', '#96F58D', '#B5FAAA', '#C8FFBE', '#FFFAA9', '#FFE878', '#FDC238', '#FF9F00', '#FE6102', '#FC3305', '#DD1601', '#C00002', '#A40000'],
    // eslint-disable-next-line max-len
    [0, 0.001, 0.002, 0.005, 0.01, 0.015, 0.02, 0.04, 0.06, 0.08, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.5, 0.6, 0.7, 1].reverse());
  plotty.addColorScale('drougth_dvi', ['#d70004', '#d70004', '#ee7b4a', '#ee7b4a', '#f3f024', '#f3f024', '#7bc624', '#7bc624', '#00920c','#00920c'], [0.0001, 0.2,
    0.2, 0.4,
    0.4, 0.5995,
    0.5995, 0.7985,
    0.7985, 1.0]);
  options.renderer = new L.LeafletGeotiff.Plotty(options);
  return <GeotiffLayer ref={layerRef} {...props} />;
});
