/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  uniqBy, orderBy, uniqueId, get,
} from 'lodash';
import Link from '@material-ui/core/Link';

import moment from 'moment';
import {
} from 'react-router-dom';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import NativeSelect from '@material-ui/core/NativeSelect';

// table .
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

// lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// width
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
// icon .
import Modal from '../modal/Modal';
import {
  FaChartLine, FaSpinner, FaCaretUp, FaCaretDown, FaEquals, FaChartArea,
} from '../../utils/Fontawesome';
import { HiiiSeaIcon } from '../../utils/Icon';

// modal
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';

import {
  StyledTableRow, styles, BootstrapInput,
} from '../../styles/Style';

import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import WlStatusColor from '../wl/WlStatusColor';
import WlContext from '../../contexts/wlContext';
import RainfallContext from '../../contexts/rainfallContext';
import WaterlevelMap from './WaterlevelMap';
import WlChart from '../wl/WlChart';
import RainfallLegend from '../rainfall/RainfallLegend';

// json
import tJson from '../../utils/TJson';
import WlLegend from '../wl/WlLegend';
import formatNumber from '../../utils/Number';

// json
const crossSection = require('../../store/json/crosssection.json');

let arrayCrosssectionImage = [];
const initPath = process.env.MIX_APP_PATH;
// const banner = `${initPath}images/models/banner-storm-surge.png`;
// table Header

const columnData = [
  {
    id: 'station.tele_station_name.th', numeric: false, disablePadding: true, label: 'สถานี', sortable: true, width: '167px',
  },
  {
    id: 'river_name', numeric: false, disablePadding: true, label: 'แม่น้ำ', sortable: true,
  },
  {
    id: 'address', numeric: false, disablePadding: true, label: 'ที่ตั้ง', sortable: false,
  },
  {
    id: 'waterlevel_msl', align: 'right', numeric: true, disablePadding: true, label: 'ระดับน้ำ', unit: '(ม.รทก)', sortable: true,
  },
  {
    id: 'storage_percent', align: 'center', numeric: true, disablePadding: true, label: 'สถานการณ์น้ำ', sortable: true,
  },
  {
    id: 'diff_wl_bank', align: 'center', numeric: true, disablePadding: true, label: '', sortable: false,
  },
  {
    id: 'compare', numeric: true, disablePadding: true, label: '', sortable: false,
  },
  {
    id: 'datetime', align: 'center', numeric: false, disablePadding: true, label: 'เวลา', sortable: false,
  },
  {
    id: 'chart', align: 'center', numeric: false, disablePadding: true, label: '',
  },
];
class Waterlevel extends Component {
  constructor(props) {
    super(props);
    const { filText } = this.props;
    this.state = {
      isLoading: false, // loading status
      error: null, // error

      datas: [],
      datasWatergate: [],
      datasRainfall24h: [],
      datasRainfall24hinit: [],
      photoIndex: 0,
      isOpen: false,
      page: 0,
      rowsPerPage: 10,
      dataCondition: [],
      dataProvince: [],
      dataBasin: [],
      areaText: filText,

      // for search
      dataInit: [],
      dataInitLastest: [],
      searchData: [],
      searchTerm: '',

      // map
      map: null,
      zoom: 6.5,
      location: [15.006042, 85.400886],
      markers: [],

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      // radio group
      areaType: '0',
      areaId: '',

      // order: 'asc',
      order: 'desc',
      // orderByState: 'station.tele_station_name.th',
      // orderByState: 'waterlevel_msl',
      orderByState: 'storage_percent',

      isOpenWlImage: false,
      wlImage: '',

      sizeWindown: {
        width: window.innerWidth,
        height: window.innerHeight,
      },

      // geojson
      geojsonBasin: [],

    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
    this.getWlImage();
    this.getRainfall24h();
    this.fetchWatergate();
    this.getBasinGeoJson();
  }

  handleResize() {
    this.setState({
      sizeWindown: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  getBasinGeoJson() {
    const { geojson } = this.props;
    fetch(`${initPath}${geojson}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({ geojsonBasin: result });
      })
      .catch(error => this.setState({ error }));
  }

  getData = () => {
    const { basinCode } = this.props;
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load?basin_code=${basinCode}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        // const last24hrs = (new Date().getTime() / 1000) - (24 * 3600);
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        const filterDatas = orderBy(result.waterlevel_data.data, {
          storage_percent: Number,
        })
          .filter(d => {
            const time = new Date(d.waterlevel_datetime.replace(/-/g, '/')).getTime();
            return ((last24hrs < time) && (d.waterlevel_msl != null));
          })
          .filter(d => (d.storage_percent != null) || (d.station.tele_station_oldcode.indexOf('GOT') === 0))
        // เพชรบุรี-ประจวบคีรีขันธ์ 18
        // ภาคใต้ฝั่งตะวันออกตอนบน 19
        // ทะเลสาบสงขลา 20
        // ภาคใต้ฝั่งตะวันออกตอนล่าง 21
        // ภาคใต้ฝั่งตะวันตก 22
        //   .sort((a, b) => b.waterlevel_msl-a.waterlevel_msl);
        // 2021-01-27 ข้อมูลจากกรมชลส่วนใหญ่ไม่มีข้อมูลตลิ่งซ้าย ขวา ทำให้คำนวน %ความจุลำน้ำไม่ได้
        // ถ้าไม่ filter storage_percent สถานีสีเทาบนแผนที่จะทับกับข้อมูลที่มี

        // basin and province dropdown
        let dataProvince = result.waterlevel_data.data
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvince = uniqBy(dataProvince.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        let dataBasin = result.waterlevel_data.data
          .map(d => ({
            id: d.basin.basin_code,
            name: d.basin.basin_name.th,
          }));
        dataBasin = uniqBy(dataBasin.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        // set state
        this.setState({
          isLoading: false,
          dataInit: filterDatas,
          dataInitLastest: filterDatas,
          datas: filterDatas,
          dataProvince,
          dataBasin,
          dataCondition: dataProvince,
          latestDate: this.getMaxDate(filterDatas),
          searchData: filterDatas,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getWlImage() {
    fetch(`${process.env.MIX_API_URL}public/weather_img/image_generate`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const link = `${process.env.MIX_APP_PATH}weather/${moment(result.data[1][0].datetime).format('YYYY-MM-DD')}/64/175`;
        const wlImage = {
          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[1][0].media_path}`,
          link,
        };
        this.setState({
          wlImage,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getRainfall24h = () => {
    const { basinCode } = this.props;
    fetch(`${process.env.MIX_API_URL}public/rain_24h?basin_code=${basinCode}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        const rainfall24Sort = orderBy(result.data, ['rain_24h'], ['desc']);
        const dataRainfall24h = rainfall24Sort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (
            (last24hrs < time)
            && (d.rain_24h >= 0.1)
          );
        });
        this.setState({ datasRainfall24h: dataRainfall24h, datasRainfall24hinit: dataRainfall24h });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    const { modalData } = this.state;
    // const modalData = { ...modalData }
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;
    const params = {
      header: t('กราฟระดับน้ำ'),
      content: <WlChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }

  handleSearchMarker = (id, latitude, longitude) => e => {
    const { map, markers } = this.state;
    e.preventDefault();
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
      const LatLng = { lat: latitude, lng: longitude };
      const px = map.project(LatLng);
      px.y -= 320 / 2;
      map.panTo(map.unproject(px), { animate: true });
    }
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.waterlevel_datetime.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format('YYYY-MM-DD')} เวลา : ${moment(maxdate).format('HH:mm')}`;
    }
    return moment().format('YYYY-MM-DD');
  }

  // compare current water level with previous data
  compareWl = (currentWl, previousWl, storagePercent) => {
    let text = '';
    let component = '';
    const status = WlStatusColor(storagePercent);

    if (currentWl === previousWl) {
      text = 'ทรงตัว';
      component = (
        <Tooltip title={`${text}ที่ระดับ ${currentWl} ม.รทก.`}>
          <IconButton>
            <FaEquals size={15} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    } else if (currentWl > previousWl) {
      text = 'เพิ่มขึ้น';
      component = (
        <Tooltip title={`${text} ${parseFloat(currentWl - previousWl).toFixed(2)} ม.รทก.`}>
          <IconButton>
            <FaCaretUp size={20} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    } else if (currentWl < previousWl) {
      text = 'ลดลง';
      component = (
        <Tooltip title={`${text} ${parseFloat(previousWl - currentWl).toFixed(2)} ม.รทก.`}>
          <IconButton>
            <FaCaretDown size={20} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    }
    return [{ text, component }];
  }

  // get water level status
  wlStatus = storagePercent => {
    const status = WlStatusColor(storagePercent);
    return (
      <span
        style={{
          color: status.color.statusColor,
        }}
      >
        {status.color.label}
      </span>
    );
  }

  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByState === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  handleAreaTypeChange = (event, value) => {
    const {
      dataProvince,
      dataBasin,
      dataInit,
    } = this.state;
    const { basinName, filText } = this.props;
    let dataCondition = dataProvince;
    let areaText = filText;
    if (value === '1') {
      dataCondition = dataBasin;
      areaText = basinName;
    }
    // console.log(dataBasin);
    // console.log(dataProvince);

    this.setState({
      areaType: value,
      dataCondition,
      areaId: '',
      areaText,
      dataInitLastest: dataInit,
      searchData: dataInit,
    }, () => {
      this.filterDatas();
    });
  };

  handleSearchChange = event => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm }, () => {
      this.filterDatas();
    });
  };

  handleFilterAreaChange = areaId => event => {
    this.setState({ [areaId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  formatWl = value => formatNumber(value, 2, '-')

  // *** water gate ***/
  async fetchWatergate() {
    const { basinCode } = this.props;
    await fetch(`${process.env.MIX_API_URL}public/watergate_load?basin_code=${basinCode}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        const filterWatergateDatas = result.watergate_data.data
          .filter(d => {
            const time = new Date(d.watergate_datetime_in.replace(/-/g, '/')).getTime();
            return ((last24hrs < time) && (d.watergate_in != null));
          });

        this.setState({
          datasWatergate: filterWatergateDatas,
        });
      });
  }

  filterDatas() {
    const {
      searchTerm, areaId, dataInit, rowsPerPage, page, areaType, datasRainfall24hinit,
    } = this.state;
    const searchData = dataInit
      .filter(d => {
        // search by keyword
        if (searchTerm !== '') {
          if (areaId !== '') {
            return (tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1
              || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
              || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
              || d.geocode.province_name.th.indexOf(searchTerm) > -1)
              && (d.basin.basin_code.toString() === areaId || d.geocode.province_code === areaId);
          }
          return tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1;
        }

        // // search by area type
        if (areaId !== '') {
          return (areaType === '1' ? d.basin.basin_code.toString() === areaId : d.geocode.province_code === areaId);
        }
        return d;
      });
    const searchDataRain = datasRainfall24hinit
      .filter(d => {
        // search by keyword
        if (searchTerm !== '') {
          if (areaId !== '') {
            return (tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1
              || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
              || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
              || d.geocode.province_name.th.indexOf(searchTerm) > -1)
              && (d.basin.basin_code.toString() === areaId || d.geocode.province_code === areaId);
          }
          return tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1;
        }

        // // search by area type
        if (areaId !== '') {
          return (areaType === '1' ? d.basin.basin_code.toString() === areaId : d.geocode.province_code === areaId);
        }
        return d;
      });
    const pageCurrent = (searchData.length < rowsPerPage) ? 0 : page;

    this.setState({
      searchData,
      datasRainfall24h: searchDataRain,
      page: pageCurrent,
    });
  }

  useWindowSize() {
    const { sizeWindown } = this.state;
    window.addEventListener('resize', this.handleResize());
    window.removeEventListener('resize', this.handleResize());
    return sizeWindown;
  }

  // eslint-disable-next-line class-methods-use-this
  WindowScreen() {
    const width = 'lg'; // desktop screen
    const fullScreen = (localStorage.fullScreen && localStorage.fullScreen !== '') ? JSON.parse(localStorage.fullScreen) : '';
    const maxWidth = (fullScreen.maxWidth) ? fullScreen.maxWidth : width;

    return maxWidth;
  }

  render() {
    const {
      classes, t, i18n, theme, basinName, locationZone,
    } = this.props;
    const {
      isLoading, error, latestDate, photoIndex, isOpen,
      searchData, datasWatergate, geojsonBasin, datasRainfall24h,
      areaType, modalData, rowsPerPage, page, order, orderByState,
      areaId, areaText, dataCondition,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, searchData.length - page * rowsPerPage);

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }
    arrayCrosssectionImage = [];

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <Box>
        <WlContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <RainfallContext.Provider
            value={{
              handleModalOpen: this.handleModalOpen,
            }}
          >
            <Grid container spacing={4}>
              {/* Map */}
              <Grid item xs={12} md={5} lg={4}>
                <Box mt={2} width="100%">
                  <WaterlevelMap
                    {...this.state}
                    geojsonBasin={geojsonBasin}
                    datas={searchData}
                    datasWatergate={datasWatergate}
                    datasRainfall24h={datasRainfall24h}
                    handleSetMap={this.handleSetMap}
                    language={i18n.language}
                    locationZone={locationZone}
                  />
                  <WlLegend />
                  <Divider />
                  <RainfallLegend />
                  <Grid container component="span">
                    <Grid item xs component="span">
                      <Typography component="span" variant="caption" gutterBottom>
                        {t('เกณฑ์ฝน 24 ชม. (มม.)')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box display="flex" pt={1} width="100%">
                    <Box display="flex" width="40%">
                      <Box><img src={`${initPath}images/theme/icon/up-icon.png`} alt="น้ำเพิ่มขึ้น" /></Box>
                      <Box pl={0.5} fontSize="13px">{`${t('ระดับน้ำเพิ่มสูงขึ้น')}`}</Box>
                    </Box>
                    <Box display="flex">
                      <Box><img src={`${initPath}images/theme/icon/station-icon.png`} alt="น้ำเพิ่มขึ้น" /></Box>
                      <Box pl={0.5} fontSize="13px">{`${t('สถานีวัดระดับน้ำ')}`}</Box>
                    </Box>
                  </Box>
                  <Box display="flex" width="100%">
                    <Box display="flex" width="40%">
                      <Box><img src={`${initPath}images/theme/icon/down-icon.png`} alt="น้ำเพิ่มขึ้น" /></Box>
                      <Box pl={0.5} fontSize="13px">{`${t('ระดับน้ำลดลง')}`}</Box>
                    </Box>
                    <Box display="flex">
                      <Box><img src={`${initPath}images/theme/icon/water-gate-icon.png`} alt="น้ำเพิ่มขึ้น" /></Box>
                      <Box pl={0.5} fontSize="13px">{`${t('ประตูระบายน้ำ')} / ${t('ฝาย')}`}</Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {/* Table and filter controls */}
              <Grid item xs={12} md={7} lg={8}>
                {/* ระบบคาดการณ์ระดับน้ำทะเลในอ่าวไทย  */}
                {basinName === 'ลุ่มน้ำภาคใต้' ? (
                  <Grid item xs={12} sm={6} md={5} lg={5}>
                    <Box mt={2} fontFamily="Kanit">
                      <Link
                        title={t('ระบบคาดการณ์ระดับน้ำทะเลในอ่าวไทย')}
                        href="http://hydro-hims.hii.or.th/ocean/model.php"
                        target="_blank"
                      >
                        <Box display="flex" alignItems="center" className={classes.seaForecast}>
                          <HiiiSeaIcon />
                          <span style={{ marginLeft: 9 }}>
                            {t('ระบบคาดการณ์ระดับน้ำทะเลในอ่าวไทย')}
                          </span>
                        </Box>
                      </Link>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
                <Box mt={3} />
                <Grid container spacing={2}>
                  {/* Filter provinces or basins  */}
                  <Grid item xs={12} sm={6} md={5} lg={5}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41, backgroundColor: 'white' }}>
                      <Box>
                        <FormControl variant="outlined" component="fieldset">
                          <RadioGroup aria-label="position" id="position" name="position" value={areaType} onChange={this.handleAreaTypeChange} row>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลจังหวัด')}</Typography>}
                              labelPlacement="end"
                              fontFamily="Kanit"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลลุ่มน้ำ')}</Typography>}
                              labelPlacement="end"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  {/* Dropdown provinces */}
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined" className={classes.formControl} width="100%" style={{ backgroundColor: 'white' }}>
                        <NativeSelect
                          id="select"
                          value={areaId.toString()}
                          onChange={this.handleFilterAreaChange('areaId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit' }}>{areaText}</option>
                          {dataCondition.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* Search stations */}
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <Paper className={classes.rootSearch} elevation={0}>
                        <InputBase
                          display="flex"
                          className={classes.inputSearch}
                          placeholder={t('ค้นหา')}
                          id="search"
                          onChange={this.handleSearchChange}
                        />
                        <IconButton className={classes.iconButton} aria-label="Search">
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                        <TableHeaderEnhance
                          columnData={columnData}
                          order={order}
                          orderBy={orderByState}
                          onRequestSort={this.handleRequestSort}
                          firstColumnSticky
                        />
                        <TableBody>
                          {orderBy(searchData,
                            [
                              item => (orderByState === 'storage_percent'
                                ? parseFloat(item.storage_percent)
                                : parseFloat(get(item, orderByState) || 0)),
                            ],
                            [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                              let mapLink = row.station.tele_station_name.th;
                              let stationName = (row.station.tele_station_name.th)
                                ? `${tJson(row.station.tele_station_name, 'สถานี')}`
                                : row.station.tele_station_oldcode;
                              if (stationName.length > 22) {
                                stationName = `${stationName.substring(0, 24)}...`;
                              }

                              if (row.station.tele_station_lat && row.station.tele_station_long
                              ) {
                                mapLink = (
                                  <Button
                                    style={{ textAlign: 'left' }}
                                    onClick={this.handleSearchMarker(
                                      row.id,
                                      row.station.tele_station_lat,
                                      row.station.tele_station_long,
                                    )}
                                    title={`${row.station.tele_station_name.th}\n${row.agency.agency_name.th}`}
                                  >
                                    {stationName}
                                  </Button>
                                );
                              } else {
                                mapLink = stationName;
                              }

                              let crossSectionLink = '';
                              arrayCrosssectionImage.push(`${initPath}images/crosssection/${row.station.id}.png`);
                              if (crossSection.some(item => item.id === row.station.id.toString())) {
                                crossSectionLink = (
                                  <Button
                                    target="_blank"
                                    alt="ภาพตัดลำน้ำ"
                                    title={t('แสดงภาพตัดลำน้ำ')}
                                    onClick={() => this.setState({ isOpen: true, photoIndex: i })}
                                    rel="noopener noreferrer"
                                  >
                                    <FaChartArea />
                                  </Button>
                                );
                              }

                              // comment because we hide bank coloum in table
                              // let bank = '';
                              // if (row.station.left_bank !== null
                              //   && row.station.right_bank !== null) {
                              //   bank = parseFloat(Math.min(row.station.left_bank,
                              //     row.station.right_bank)).toFixed(2);
                              // }

                              const status = WlStatusColor(row.storage_percent);

                              return (
                                <StyledTableRow key={row.id}>
                                  <TableCell component="th" scope="row" style={{ whiteSpace: 'nowrap' }} align="left" className="first-column-sticky">
                                    <Box width="167px">
                                      {mapLink}
                                      {' '}
                                      {crossSectionLink}
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.river_name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {`${tJson(row.geocode.tumbon_name, 'ต.')} ${tJson(row.geocode.amphoe_name, 'อ.')}
                                  ${tJson(row.geocode.province_name, 'จ.')}`}
                                  </TableCell>
                                  <TableCell align="right">{this.formatWl(row.waterlevel_msl)}</TableCell>
                                  {/* <TableCell align="right">{bank}</TableCell> */}
                                  {/* <TableCell  /> */}
                                  {/* <TableCell align="right">{row.storage_percent}</TableCell> */}
                                  <TableCell align="center">
                                    {/* {status} */}
                                    <Box
                                      borderRadius={3}
                                      style={{
                                        backgroundColor: status.color.statusColor,
                                        color: status.color.constrastColor,
                                      }}
                                    >
                                      {t(status.color.label)}
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Grid item xs={12}>
                                      <Box
                                        sx={{ gridArea: 'main' }}
                                        style={{ fontSize: '0.8em' }}
                                      >
                                        {t(row.diff_wl_bank_text)}
                                      </Box>
                                      <Box
                                        display="flex"
                                        sx={{ gridArea: 'main' }}
                                        style={{
                                          color: status.color.statusColor,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {row.diff_wl_bank}
                                      </Box>
                                    </Grid>
                                  </TableCell>
                                  <TableCell align="center">
                                    {this.compareWl(row.waterlevel_msl,
                                      row.waterlevel_msl_previous,
                                      row.storage_percent)[0].component}
                                  </TableCell>
                                  <TableCell align="center">
                                    {moment(row.waterlevel_datetime).format('HH:mm')}
                                    {' '}
                                    น.
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    <WlContext.Consumer>
                                      {({ handleModalOpen }) => (
                                        <>
                                          <Tooltip title={t('แสดงกราฟ')}>
                                            <IconButton className={classes.button} aria-label="Chart" onClick={this.handleChart(handleModalOpen, row)}>
                                              <FaChartLine fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </WlContext.Consumer>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <StyledTableRow style={{ height: 49 * emptyRows }}>
                              <TableCell colSpan={9} />
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      className={classes.hiddenLine}
                      count={searchData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                      labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
                      labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to} ${t('จาก')} ${count} ${t('รายการ')}`}
                    />
                    <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                      {`${t('ข้อมูลล่าสุดวันที่')} ${latestDate} ${t('น.')}`}
                    </Box>
                  </Grid>
                </Grid>

                {isOpen && (
                  <Lightbox
                    mainSrc={arrayCrosssectionImage[photoIndex]}
                    nextSrc={arrayCrosssectionImage[(photoIndex + 1)
                      % arrayCrosssectionImage.length]}
                    prevSrc={
                      arrayCrosssectionImage[
                      (photoIndex + arrayCrosssectionImage.length - 1)
                      % arrayCrosssectionImage.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() => this.setState({
                      photoIndex: (photoIndex + arrayCrosssectionImage.length - 1)
                        % arrayCrosssectionImage.length,
                    })}
                    onMoveNextRequest={() => this.setState({
                      photoIndex: (photoIndex + 1) % arrayCrosssectionImage.length,
                    })}
                    reactModalStyle={{
                      overlay: { zIndex: '10000000' },
                    }}
                    imagePadding={85}
                  />
                )}
              </Grid>
            </Grid>
            <Modal
              open={modalData.open}
              modalHeader={modalData.header}
              modalContent={modalData.content}
              resetModal={this.resetModal}
            />
          </RainfallContext.Provider>
        </WlContext.Provider>
      </Box>
    );
  }
}

Waterlevel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }), withTranslation())(Waterlevel);
