import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import 'leaflet-kmz';
import 'leaflet.fullscreen/Control.FullScreen';
import zipObject from 'lodash/zipObject';
import PropTypes from 'prop-types';
import FullscreenControl from 'react-leaflet-fullscreen';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

// styles
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import Currentlocation from '../currentlocation/CurrentLocation';
import WindowScreen from '../../utils/WindowsFullScreen';
import StromKmz from './StromKmz';

const initPath = process.env.MIX_APP_PATH;

const mapStyle = (height, windowsWidth) => ({
  left: windowsWidth >= 1160 && (WindowScreen() !== 'xl') ? (1160 - windowsWidth) / 2 : 'auto',
  width: windowsWidth >= 1160 && (WindowScreen() !== 'xl') ? (windowsWidth - 5) : '100%',
  height,
});

class StormJtwc extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      windowsWidth: window.innerWidth,
      kmzurl: [],
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.setState({ windowsWidth: this.handleResize }));
    this.handleResize();
    this.getData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setState({ windowsWidth: this.handleResize }));
  }

  handleResize() {
    this.setState({ windowsWidth: window.innerWidth });
  }

  getData() {
    fetch('https://www.metoc.navy.mil/jtwc/rss/jtwc.rss')
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
      .then(data => {
        const items = data.querySelectorAll('item');
        let matchesAll = [];
        let nameMatchesAll = [];
        let kmzFilename = [];
        let stormName = [];

        items.forEach(el => {
          const desc = el.querySelector('description').innerHTML;
          const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
          const matches = desc.match(expression);

          if (matches != null) {
            matchesAll = matchesAll.concat(matches);
          }

          const nameSentenceExp = /<b>(.*?)<\/b>/g;
          const nameSentenceMatches = desc.match(nameSentenceExp);

          if (nameSentenceMatches != null) {
            nameSentenceMatches.forEach(val => {
              nameMatchesAll = nameMatchesAll.concat(val);
            });
          }
        });

        const stormCurrent = matchesAll.filter(url => url.includes('.kmz') && (url.includes('/wp') || url.includes('/io')));

        kmzFilename = stormCurrent.map(url => url.substring(url.lastIndexOf('/') + 1));
        stormName = zipObject(kmzFilename, nameMatchesAll);

        if (stormCurrent.length > 0) {
          stormCurrent.forEach(stormUrl => {
            this.setState(prevState => ({
              kmzurl: [...prevState.kmzurl, `${initPath}proxy/kmz.php?file=${stormUrl}`],
            }));
          });
        }
      });
  }

  render() {
    const { height } = this.props;
    const { windowsWidth, kmzurl } = this.state;

    return (
      <Map
        id="map_jtwc"
        style={mapStyle(height, windowsWidth)}
        center={[13, 108]}
        zoom={5}
        ref={this.mapRef}
        gestureHandling
      >
        <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {kmzurl.map(url => <StromKmz key={url} url={url} />)}
        <FullscreenControl
          position="topleft"
          forcePseudoFullscreen
          content={LeafletFullScreenFix()}
        />
        <Currentlocation mapRef={this.mapRef} showOnly />
      </Map>
    );
  }
}

StormJtwc.defaultProps = {
  height: 400,
};

StormJtwc.propTypes = {
  height: PropTypes.number,
};

export default StormJtwc;
