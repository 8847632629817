import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

// ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, Hidden } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';

import {
  Link,
} from 'react-router-dom';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  FaPlay, FaHome,
} from '../../../utils/Fontawesome';

import { styles } from '../../../styles/Style';

import WrfHistoryList from './WrfHistoryList';
import WrfAnimation from './WrfAnimation';
import WrfLatest from './WrfLatest';
import VerticalWindAnimation from '../../weather/upperwind/VerticalWindAnimation';
import UpperWindAnimation from '../../weather/upperwind/UpperWindAnimation';

const TabContainer = ({ children }) => (
  <>
    {children}
  </>
);

const initPath = process.env.MIX_APP_PATH;

class WrfHistory extends Component {
  constructor(props) {
    super(props);

    const { match: { params: { status } } } = this.props;
    this.state = {
      startDate: new Date(moment()),
      // for get data condition
      mValue: { year: moment().format('YYYY'), month: moment().format('M') },
      // tab
      value: 0,
      displayState: status === 'animation' ? 'animation' : 'history_list',
      // displayState: status === 'animation' ? 'animation' : 'history',

    };
    // console.log('status : ', status);
  }

  componentDidMount() {
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDateChange = date => {
    this.setState({
      startDate: date,
      mValue: { year: moment(date).format('YYYY'), month: moment(date).format('M') },
    });
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate;
    const selectedYear = moment(this.state.startDate).year();
  
    if ((selectedYear >= 2011 && selectedYear <= 2017 && this.state.value !== 3 )  ) {
      // ถ้าเป็นปีระหว่าง 2011 - 2017 ไปทีละวัน
      if (change === '1') {
        selectedDate = moment(this.state.startDate).add(1, 'days');
      } else if (change === '-1') {
        selectedDate = moment(this.state.startDate).add(-1, 'days');
      }
    } else {
      // ถ้าไม่ใช่ปีระหว่าง 2011 - 2017 ไปทีละเดือน 
      if (change === '1') {
        selectedDate = moment(this.state.startDate).add(1, 'months');
      } else if (change === '-1') {
        selectedDate = moment(this.state.startDate).add(-1, 'months');
      }
    }
  
    this.setState({
      startDate: selectedDate,
      mValue: { year: moment(selectedDate).format('YYYY'), month: moment(selectedDate).format('M') },
    });
  };
  
  renderHistory(startDate, value, mValue, classes) {
    return (
      <>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ marginTop: 24, marginBottom: 24 }}>
          <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {/* ถ้าเป็นปี 2011-2016 ให้แสดงช่องวันที่ */}
                {(moment(startDate).year() >= 2011 && moment(startDate).year() <= 2017 && value !== 3) ?  (
                  <KeyboardDatePicker
                    openTo="date"
                    views={['year', 'month', 'date']}
                    value={startDate}
                    format="D MMMM yyyy"
                    onChange={this.handleDateChange}
                    style={{ fontSize: 14 }}
                    maxDate={moment()}
                  />
                ) : (
                  // ถ้าไม่ใช่ปี 2011-2016 แสดงแค่ปีและเดือน
                  <KeyboardDatePicker
                    openTo="month"
                    views={['year', 'month']}
                    value={startDate}
                    format="MMMM yyyy"
                    onChange={this.handleDateChange}
                    style={{ fontSize: 14 }}
                    maxDate={moment()}
                  />
                )}
              </MuiPickersUtilsProvider>
            </Grid>
          <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" variant="fullWidth" className={classes.boxTab}>
              <Tab label="Thailand (3km x 3km)" className={value === 0 ? classes.active_tab : ''} />
              <Tab label="Southeast Asia (9km x 9km)" className={value === 1 ? classes.active_tab : ''} />
              <Tab label="Asia (27km x 27km)" className={value === 2 ? classes.active_tab : ''} />
              <Tab label="Thailand Basin (3km x 3km)" className={value === 3 ? classes.active_tab : ''} />
            </Tabs>
            {value === 0 && (
              <TabContainer>
                <WrfHistoryList 
                  period={mValue} 
                  domain="thailand" 
                  startDate={startDate} 
                />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <WrfHistoryList 
                  period={mValue} 
                  domain="southeast asia" 
                  startDate={startDate} 
                />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <WrfHistoryList 
                  period={mValue} 
                  domain="asia" 
                  startDate={startDate} 
                />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <WrfHistoryList 
                  period={mValue} 
                  domain="thailand basin" 
                  startDate={startDate} 
                />
              </TabContainer>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    const { classes, t } = this.props;
    const {
      startDate,
      value,
      mValue,
      displayState,
    } = this.state;

    return (
      <>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to={`${initPath}weather`}>
                <FaHome className={classes.iconSub} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('ติดตามสภาพอากาศ')}
                </Typography>
              </Link>
              <Link to={`${initPath}weather/rainfall`}>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('ฝน')}
                </Typography>
              </Link>
              <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                {t('แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน')}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Box m={1} className={classes.titleName}>
              {t('แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน')}
            </Box>
          </Grid>
          <Grid item xs>
            <Button
              size="small"
              className={classes.outlinedLeftPrimary}
              style={{ borderRadius: 0 }}
              color={displayState === 'latest' ? 'primary' : 'default'}
              variant={displayState === 'latest' ? 'contained' : 'text'}
              onClick={() => this.setState({ displayState: 'latest' })}
            >
              <ImageOutlinedIcon fontSize="small" />
              <Hidden xsDown>
                <Typography variant="h6" style={{ marginLeft: 8 }}>
                  {t('ดูภาพล่าสุด')}
                </Typography>
              </Hidden>
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              size="small"
              className={classes.outlinedPrimary}
              style={{ borderRadius: 0 }}
              color={displayState === 'history_list' ? 'primary' : 'default'}
              variant={displayState === 'history_list' ? 'contained' : 'text'}
              onClick={() => this.setState({ displayState: 'history_list' })}
            >

              <PhotoLibraryOutlinedIcon fontSize="small" />
              <Hidden xsDown>
                <Typography variant="h6" style={{ marginLeft: 8 }}>
                  {t('ดูภาพย้อนหลัง')}
                </Typography>
              </Hidden>
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              size="small"
              className={classes.outlinedRightPrimary}
              color={displayState === 'animation' ? 'primary' : 'default'}
              variant={displayState === 'animation' ? 'contained' : 'text'}
              onClick={() => this.setState({ displayState: 'animation' })}
            >
              <FaPlay style={{ fontSize: '0.8em' }} />
              <Hidden xsDown>
                <Typography variant="h6" style={{ marginLeft: 8 }}>
                  {t('ดูภาพเคลื่อนไหว')}
                </Typography>
              </Hidden>
            </Button>
          </Grid>
        </Grid>

        {displayState === 'latest' && (
          <WrfLatest />
        )}
        {displayState === 'animation' && (
          <>
          <WrfAnimation />
          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
            <Grid item xs={12} md={5}>
              <Box m={1} className={classes.titleName}>
                {t('แผนที่อากาศ และลมแนวดิ่ง')}
              </Box>
            </Grid>
            </Grid>
          {/* tab */}
          <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs>
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" variant="fullWidth" className={classes.boxTab}>
                <Tab label={t('แผนที่อากาศ ที่ระดับความสูง 0.6 กม.')} key={'0.6map'} className={value === 0 ? classes.active_tab : ''} />
                <Tab label={t('แผนที่อากาศ ที่ระดับความสูง 1.5 กม.')} key={'1.5map'} className={value === 1 ? classes.active_tab : ''} />
                <Tab label={t('แผนที่ลมแนวดิ่ง ที่ระดับความสูง 5 กม.')} key={'5map'} className={value === 2 ? classes.active_tab : ''} />
            </Tabs>
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={4}>
          <Grid item xs>
            {value === 0 && (
              <TabContainer>
                <UpperWindAnimation keyData={' 0.6 '} />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <UpperWindAnimation keyData={' 1.5 '} />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <VerticalWindAnimation />
              </TabContainer>
            )}
          </Grid>
        </Grid>
          </>
        )}

        {displayState === 'history_list' && (
          this.renderHistory(startDate, value, mValue, classes)
        )}
      </>
    );
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

WrfHistory.defaultProps = {
  match: {
    params: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  },
};

WrfHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      month: PropTypes.string,
      year: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(WrfHistory));
