/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
  *
 */

// reference : เกณฑ์อุณหภูมิ https://www.tmd.go.th/info/info.php?FileID=68

import iconTemperature from '../../widgets/map/iconTemperature';
// import iconTemperature from '../../widgets/map/iconCircle';

const TemperatureColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'หนาวจัด',
    rangeTitle: '<=8',
    statusColor: '#0080FF',
    constrastColor: '#FFF',
    fontSize: '12px',
  },
  {
    label: 'หนาว',
    rangeTitle: '>8',
    statusColor: '#58D3F7',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ค่อนข้างหนาว',
    rangeTitle: '>16',
    statusColor: '#00FFBF',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เย็น',
    rangeTitle: '>18',
    statusColor: '#40FF00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ร้อน',
    rangeTitle: '>35',
    statusColor: '#FFBF00',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ร้อนจัด',
    rangeTitle: '>40',
    statusColor: '#FA5858',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const TemperatureStatusColor = value => {
  let color;
  let icon;

  if (value === null) {
    color = TemperatureColor['0'];
    icon = iconTemperature.grey;
  } else if (value > 40) {
    color = TemperatureColor['6'];
    icon = iconTemperature.red;
  } else if (value > 35) {
    // orange
    color = TemperatureColor['5'];
    icon = iconTemperature.yellow;
  } else if (value > 18) {
    color = TemperatureColor['4'];
    icon = iconTemperature.green;
  } else if (value > 16) {
    color = TemperatureColor['3'];
    // light blue
    icon = iconTemperature.blue;
  } else if (value > 8) {
    // light navy
    color = TemperatureColor['2'];
    icon = iconTemperature.blue;
  } else if (value <= 8) {
    // navy
    color = TemperatureColor['1'];
    icon = iconTemperature.blue;
  } else {
    color = TemperatureColor['0'];
    icon = iconTemperature.grey;
  }

  return { color, icon };
};

export default TemperatureStatusColor;
