import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// icon .
import { FaCalendarAlt, FaSpinner } from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class ReportMonthlyDrought extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      reportMonthlyStartDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      fileName: moment().subtract(1, 'months').endOf('month').format('YYYYMM'),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  handleChange = date => {
    this.setState({
      reportMonthlyStartDate: moment(date).format('YYYY-MM-DD'),
      fileName: moment(date).format('YYYYMM'),
    });
  };

  urlEncode = url => Buffer.from(url).toString('base64');

  render() {
    const { classes, t } = this.props;
    const {
      isLoading, error, reportMonthlyStartDate,
    } = this.state;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // drought report will avaliable on month 1,2,3,4,11,12
    let reportMonth = moment(reportMonthlyStartDate).format('M');
    if ((reportMonth > 4) && (reportMonth < 10)) {
      reportMonth = 4;
    }

    let reportMonthlyStartDateFormat;
    let fileName;

    if (reportMonth === 4) {
      reportMonthlyStartDateFormat = moment(`${new Date().getFullYear()}-04-01`).endOf('month').add(543, 'years')
        .format('MMMM YYYY');
      fileName = moment(`${new Date().getFullYear()}-04-01`).endOf('month').format('YYYYMM');
    } else {
      reportMonthlyStartDateFormat = moment().subtract(1, 'months').endOf('month').add(543, 'years')
        .format('MMMM YYYY');
      fileName = moment().subtract(1, 'months').endOf('month').format('YYYYMM');
    }

    const reportLink = `${process.env.MIX_LIVE_URL}/product/latest/dashboard/docs/drought/drought_${fileName}.pdf`;
    const monthlyReportImg = `${initPath}images/theme/banner/report/monthly/drought/${reportMonth}.png`;

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={6} md={5} lg={5}>
            <a href={reportLink} target="_blank" rel="noopener noreferrer">
              <img src={`${monthlyReportImg}`} width="100%" alt={t('รายงานข้อมูลน้ำรายเดือนภัยแล้ง')} />
              <img src={`${initPath}images/theme/banner/report/book-shadow.png`} width="100%" style={{ marginTop: -6 }} alt="เงา" />
            </a>
          </Grid>
          <Grid item xs={6} md={7} lg={7}>
            <Box display="flex" alignItems="center" justifyContent="flex-center" style={{ height: '100%' }}>
              <Box display="block">
                <Box>
                  <Typography variant="h5">
                    {t('รายงานข้อมูลน้ำภัยแล้ง')}
                    <br />
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <br />
                    {reportMonthlyStartDateFormat}
                  </Typography>
                  <hr />
                </Box>
                <Box mt={1}>
                  <Button
                    href={`${initPath}report/monthly/drought/history`}
                    className={classes.buttonOutlined}
                  >
                    <FaCalendarAlt />
                    <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูย้อนหลัง')}</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

ReportMonthlyDrought.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(ReportMonthlyDrought));
