import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
} from 'react-router-dom';

import { HashLink } from 'react-router-hash-link';

// ui
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Hidden, Button } from '@material-ui/core';

import { FaHome, FaSpinner } from '../../utils/Fontawesome';

// Lightbox
import 'react-image-lightbox/style.css';

// Icon .
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const initPath = process.env.MIX_APP_PATH;
const styles = theme => ({
  layout: {
    justifyContent: 'space-around',
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      width: 1160,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
});

class Sitemap extends Component {
  render() {
    return (
      <>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>
                  {' '}
                  หน้าแรก
                </Typography>
              </Button>
              <Button href={`${initPath}sitemap`}>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 16 }}>
                  แผนผังเว็บไซต์
                </Typography>
              </Button>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Box
              p={{ xs: 0, md: 3 }}
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="center"
              align="center"
              borderBottom={1}
            >
              <Box mt={{ xs: 2, md: 0 }} width={{ xs: '100%', md: '40%' }} display="flex" alignItems="center" justifyContent={{ xs: 'left', md: 'center' }} title="ติดตามสภาพอากาศ">
                <Box fontWeight="fontWeightMedium" pr={3} fontSize={20}>
                  ติดตามสภาพอากาศ
                </Box>
              </Box>
              <Box mt={{ xs: 2, md: 0 }} width={{ xs: '100%', md: '60%' }} display="block" justifyContent="flex-start">
                <ListItem button component={Link} to={`${initPath}weather`} onClick={this.handleClose} title="อากาศ">
                  <ArrowRightIcon />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>อากาศ</Typography>
                </ListItem>
                {/* <Box ml={3}>
                  <ListItem button component={Link} to={`${initPath}weather/weather-station`} onClick={this.handleClose} title="สถานีตรวจวัดอากาศ">
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-สถานีตรวจวัดอากาศ</Typography>
                  </ListItem>
                  <HashLink to={`${initPath}weather#weather-station-anchor`} onClick={this.handleClose}>
                    <ListItem button title="แผนที่คุณภาพอากาศ">
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-แผนที่คุณภาพอากาศ</Typography>
                    </ListItem>
                  </HashLink>
                  <HashLink to={`${initPath}weather#weather-map-anchor`} onClick={this.handleClose}>
                    <ListItem button title="แผนที่อากาศ">
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-แผนที่อากาศ</Typography>
                    </ListItem>
                  </HashLink>
                  <HashLink to={`${initPath}weather#temp-anchor`} onClick={this.handleClose}>
                    <ListItem button title="แผนที่แสดงการกระจายตัว">
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-แผนที่แสดงการกระจายตัว</Typography>
                    </ListItem>
                  </HashLink>
                  <HashLink to={`${initPath}weather#temp-anchor`} onClick={this.handleClose}>
                    <ListItem button title="ค่าความชื้นในดิน (Soil Moisture)">
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-ค่าความชื้นในดิน (Soil Moisture)</Typography>
                    </ListItem>
                  </HashLink>
                </Box> */}
                <ListItem button component={Link} to={`${initPath}weather/storm`} onClick={this.handleClose} title="พายุ">
                  <ArrowRightIcon />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>พายุ</Typography>
                </ListItem>
                <Box ml={3}>
                  <ListItem button component={Link} to={`${initPath}storm/history/jtwc`} onClick={this.handleClose} title="ภาพพายุหมุนในอดีต">
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-ภาพพายุหมุนในอดีต</Typography>
                  </ListItem>
                </Box>
                <ListItem button component={Link} to={`${initPath}weather/rainfall`} onClick={this.handleClose} title="ฝน">
                  <ArrowRightIcon />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ฝน</Typography>
                </ListItem>
                <Box ml={3}>
                  <ListItem button component={Link} to={`${initPath}situation/district`} onClick={this.handleClose} title="ระบบติดตามสถานการณ์ฝนรายอำเภอ">
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-ระบบติดตามสถานการณ์ฝนรายอำเภอ</Typography>
                  </ListItem>
                  <ListItem button component={Link} to={`${initPath}forecast/wrfroms`} onClick={this.handleClose} title="ระบบคาดการณ์ฝนล่วงหน้า 3 วัน ด้วยแบบจำลอง WRF-ROMS">
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-ระบบคาดการณ์ฝนล่วงหน้า 3 วัน ด้วยแบบจำลอง WRF-ROMS</Typography>
                  </ListItem>
                  <ListItem button component={Link} to={`${initPath}forecast/rainfall_month`} onClick={this.handleClose} title="ระบบแสดงแผนที่ ฝนคาดการณ์รายเดือน">
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-ระบบแสดงแผนที่ ฝนคาดการณ์รายเดือน</Typography>
                  </ListItem>
                </Box>
                <ListItem button component={Link} to={`${initPath}weather/radar`} onClick={this.handleClose} title="เรดาร์">
                  <ArrowRightIcon />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>เรดาร์</Typography>
                </ListItem>
                <ListItem button component={Link} to={`${initPath}weather/sea`} onClick={this.handleClose} title="ทะเล">
                  <ArrowRightIcon />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ทะเล</Typography>
                </ListItem>
                <Box ml={3}>
                  <ListItem button component={Link} to={`${initPath}forecast/swan/history/forecastByStation`} onClick={this.handleClose} title="แผนภาพคาดการณ์ความสูงและทิศทางคลื่นล่วงหน้า 7 วัน จากแบบจำลองคลื่นทะเล (SWAN)">
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-แผนภาพคาดการณ์ความสูงและทิศทางคลื่นล่วงหน้า 7 วัน จากแบบจำลองคลื่นทะเล (SWAN)</Typography>
                  </ListItem>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Box
              p={{ xs: 0, md: 3 }}
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="center"
              align="center"
              borderBottom={1}
            >
              <Box mt={{ xs: 2, md: 0 }} width={{ xs: '100%', md: '40%' }} display="flex" alignItems="center" justifyContent={{ xs: 'left', md: 'center' }} title="ติดตามสถานการณ์น้ำ">
                <Box fontWeight="fontWeightMedium" pr={3} fontSize={20}>
                  ติดตามสถานการณ์น้ำ
                </Box>
              </Box>
              <Box mt={{ xs: 2, md: 0 }} width={{ xs: '100%', md: '60%' }} display="block">
                <Box display={{ xs: 'block', md: 'flex' }}>
                  <Box width="100%">
                    <ListItem button component={Link} to={`${initPath}water`} onClick={this.handleClose} title="อ่างเก็บน้ำ">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>อ่างเก็บน้ำ</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}water/wl`} onClick={this.handleClose} title="ระดับน้ำ">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระดับน้ำ</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}water/gate`} onClick={this.handleClose} title="ระดับน้ำที่ ปตร./ฝาย">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระดับน้ำที่ ปตร./ฝาย</Typography>
                    </ListItem>

                    <ListItem button component={Link} to={`${initPath}water/cctv`} onClick={this.handleClose} title="กล้อง CCTV">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>กล้อง CCTV</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}water/quality`} onClick={this.handleClose} title="คุณภาพน้ำ">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>คุณภาพน้ำ</Typography>
                    </ListItem>
                    <Box ml={3}>
                      <ListItem button component={Link} to={`${initPath}salinity`} onClick={this.handleClose} title="ระบบคาดการณ์ ความเค็มในแม่น้ำเจ้าพระยา">
                        <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>-ระบบคาดการณ์ ความเค็มในแม่น้ำเจ้าพระยา</Typography>
                      </ListItem>
                    </Box>
                    <ListItem button component={Link} to={`${initPath}water/ocean`} onClick={this.handleClose} title="ระดับน้ำบริเวณปากแม่น้ำ">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระดับน้ำบริเวณปากแม่น้ำ</Typography>
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${initPath}FlashFlood`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ระบบเตือนภัยน้ำท่วมฉับพลัน"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระบบเตือนภัยน้ำท่วมฉับพลัน</Typography>
                    </ListItem>
        
                    <ListItem button component={Link} to={`${initPath}bma`} onClick={this.handleClose}>
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>สถานการณ์น้ำ กทม.</Typography>
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${initPath}floodforecast`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ระบบคาดการณ์น้ำท่วม"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระบบคาดการณ์น้ำท่วม</Typography>
                    </ListItem>
                      <ListItem
                        button
                        component="a"
                        href={`${initPath}floodplain`}
                        target="_blank"
                        onClick={this.handleClose}
                      >
                        <ArrowRightIcon />
                        <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระบบติดตามน้ำทุ่ง</Typography>
                      </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${initPath}drought/monitoring`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ระบบติดตามภัยแล้ง"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระบบติดตามภัยแล้ง</Typography>
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${initPath}storm_surge`}
                      target="_blank"
                      onClick={this.handleClose}
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ระบบคาดการณ์และเตือนภัยล่วงหน้าบริเวณอ่าวไทย</Typography>
                    </ListItem>
                  <ListItem
                    button
                    component="div"
                    disabled
                    style={{ marginTop: 30 }}
                    title="ผังน้ำ"
                  >
                    {'\u00A0 \u00A0\u00A0'}
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ผังน้ำ</Typography>
                  </ListItem>
                    <ListItem
                      button
                      component="a"
                      href="https://waterchart.thaiwater.net/basin/chaophraya"
                      target="_blank"
                      onClick={this.handleClose}
                      title="ลุ่มน้ำเจ้าพระยา ( ย่อ )"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ลุ่มน้ำเจ้าพระยา ( ย่อ ) </Typography>
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/chaopraya/small/chaopraya.php`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ลุ่มน้ำเจ้าพระยา ( ย่อ ) [เก่า]"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ลุ่มน้ำเจ้าพระยา ( ย่อ ) [เก่า]</Typography>
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/chaopraya/2013/chaopraya.php`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ลุ่มน้ำเจ้าพระยา ( เต็ม )"
                    >
                      <ArrowRightIcon />
                        <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ลุ่มน้ำเจ้าพระยา ( เต็ม )</Typography>
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${process.env.MIX_THAIWATER_URL}/v3/bangpakong`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ผังน้ำบางปะกง"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ผังน้ำบางปะกง</Typography>
                    </ListItem>

                    <ListItem
                      button
                      component="a"
                      href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/2014/chi_mun`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ลุ่มน้ำชี-ลุ่มน้ำมูล"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ลุ่มน้ำชี-ลุ่มน้ำมูล</Typography>
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/khong/2013/khong.php`}
                      target="_blank"
                      onClick={this.handleClose}
                      title="ลุ่มน้ำโขง"
                    >
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>ลุ่มน้ำโขง</Typography>
                    </ListItem>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Box
              p={{ xs: 0, md: 3 }}
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="center"
              align="center"
              borderBottom={0}
            >
              <Box mt={{ xs: 2, md: 0 }} width={{ xs: '100%', md: '40%' }} display="flex" alignItems="center" justifyContent={{ xs: 'left', md: 'center' }} title="เพิ่มเติม">
                <Box fontWeight="fontWeightMedium" pr={3} fontSize={20}>
                  เพิ่มเติม
                </Box>
              </Box>
              <Box mt={{ xs: 2, md: 0 }} width={{ xs: '100%', md: '60%' }} display="block">
                <Box display={{ xs: 'block', md: 'flex' }}>
                  <Box width="100%">
                    <ListItem button component="a" href="http://http://ntw.onwr.go.th" target="_blank" rel="noopener noreferrer" onClick={this.handleClose}>
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>แผนที่ติดตามสถานการณ์น้ำ</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}about`} onClick={this.handleClose} title="เกี่ยวกับโครงการ">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>เกี่ยวกับโครงการ</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}media/book/view/dXBsb2Fkcy9jb250ZW50cy9UaGFpd2F0ZXJfbWFudWFsIGd1aWRlXzIwMjEucGRm`} onClick={this.handleClose} title="คู่มือการใช้งานเว็บไซต์">
                      <ArrowRightIcon />
                       <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>คู่มือการใช้งาน</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}report`} onClick={this.handleClose} title="รายงานสถานการณ์">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>รายงานสถานการณ์</Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}media`} onClick={this.handleClose} title="คลังสื่อ-เผยแพร่">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>คลังสื่อ-เผยแพร่ </Typography>
                    </ListItem>
                      <ListItem button href="https://sites.google.com/view/thaiwater-standard/home" onClick={this.handleClose} title="Thaiwater Standard ">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>Thaiwater Standard  </Typography>
                    </ListItem>
                    <ListItem>
                      <HashLink
                        to={`${initPath}report#flood`}
                        onClick={this.handleClose}
                        style={{
                          marginTop: '-1px',
                          marginBottom: '2px',
                          color: '#000000de',
                        }}
                        title="บันทึกเหตุการณ์น้ำ"
                      >
                        <ArrowRightIcon style={{
                          marginBottom: '-7px',
                        }}
                        />
                        <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>บันทึกเหตุการณ์น้ำ </Typography>
                      </HashLink>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}research`} onClick={this.handleClose} title="งานวิจัย-ความรู้">
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>งานวิจัย-ความรู้ </Typography>
                    </ListItem>
                    <ListItem button component={Link} to={`${initPath}statistics-report`} onClick={this.handleClose}>
                      <ArrowRightIcon />
                      <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 16 }}>สถิติการใช้งาน </Typography>
                    </ListItem>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Sitemap);
