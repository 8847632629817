import React, { PureComponent, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { uniqueId } from 'lodash';
import moment from 'moment';
// import Button from '@material-ui/core/Button';
// import SvgIcon from '@material-ui/core/SvgIcon';
// import TwitterIcon from '@material-ui/icons/Twitter';

import { withTranslation } from 'react-i18next';

// color.
import grey from '@material-ui/core/colors/grey';

// icon.
import {
  YtIcon, FbIcon, IgIcon, StatIcon,
} from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';
import { Tooltip } from '@material-ui/core';

const initPath = process.env.MIX_APP_PATH;

// const styles = theme => ({
//   root: {
//     width: '100%',
//     // flexGrow: 1,
//     fontFamily: 'Kanit',
//   },
//   layout: {
//     justifyContent: 'space-around',
//     width: 'auto',
//     fontFamily: 'Kanit',
//     marginLeft: theme.spacing(3),
//     marginRight: theme.spacing(3),
//     [theme.breakpoints.down('sm')]: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//     },
//     [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
//       width: 1160,
//       marginLeft: 'auto',
//       marginRight: 'auto',
//     }
//   },

//   media: {
//     height: 0,
//     paddingTop: '56.25%', // 16:9
//   },
//   responsive: {
//     width: '100%',
//     height: '100%',
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   },

//   subtitle1: {
//     color: '#fff',
//   },
//   inline: {
//     display: 'inline',
//   },
// });

class Copyright extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // open: false,
      // openMenu: false,
      // openMenuWeather: false,
      // openMenuWater: false,
      // value: 0,
      // mobileMoreAnchorEl: null,
      // subMenu: false,
      // subMenuWater: false,
      fullScreenStatus: this.handleGetFullscreen(),
      downloaded: [],
    };

    this.handleSetFullscreen = this.handleSetFullscreen.bind(this);
    this.handleGetFullscreen = this.handleGetFullscreen.bind(this);
  }

  componentDidMount() {
    fetch(`${initPath}json/download/application.json`)
      .then(res => {
        if (res.ok) {
          return res.json();
        }

        return null;
      })
      .then(response => {
        this.setState({ downloaded: response });
      });
  }

  handleSetFullscreen = () => {
    let fullScreen = { status: 'enable', maxWidth: 'xl' };

    if (localStorage.fullScreen && localStorage.fullScreen !== '') {
      const getfullScreen = JSON.parse(localStorage.fullScreen);
      fullScreen = {
        status: (getfullScreen.status === 'disable') ? 'enable' : 'disable',
        maxWidth: (getfullScreen.status === 'disable') ? 'xl' : 'lg',
      };
    }

    localStorage.setItem('fullScreen', JSON.stringify(fullScreen));
    const fullScreenStatus = JSON.parse(localStorage.fullScreen);
    this.setState({ fullScreenStatus });

    window.location.reload();
  };

  handleGetFullscreen = () => {
    let fullScreen = { status: 'disable', maxWidth: 'lg' };

    if (localStorage.fullScreen && localStorage.fullScreen !== '') {
      const getfullScreen = JSON.parse(localStorage.fullScreen);
      fullScreen = {
        status: (getfullScreen.status === '') ? 'disable' : getfullScreen.status,
        maxWidth: (getfullScreen.maxWidth === '') ? 'lg' : getfullScreen.maxWidth,
      };
    }
    return fullScreen;
  };

  render() {
    const { classes, t } = this.props;
    const { fullScreenStatus, downloaded } = this.state;
    return (
      <Fragment key="key">
        <div className={classes.root}>
          <Box height={120} />
          <div className={classes.mobileApp}>
            <div className={classes.layout}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6} align="center">
                  <img src={`${initPath}images/theme/banner/img-responsive.png`} width="580" height="281" alt="thaiwater.net" style={{ maxWidth: '100%' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} align="center">
                  <Box display="flex" justifyContent="center">
                    <Box display="inline" p={1}>
                      <a href="https://www.hii.or.th/" target="_blank" title="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)">
                        <img src={`${initPath}images/theme/banner/icon-app-thaiwater.png`} width="79" height="79" alt="Thaiwater App" />
                      </a>
                    </Box>
                    <Box p={1} display="flex" alignItems="center" align="center" fontSize={38} fontWeight="fontWeightMedium">
                      <Box>
                        {' '}
                        {' "THAIWATER" '}
                        {' '}
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" p={1} fontSize={24} fontWeight={500} align="center" justifyContent="center">
                    Support All Devices Anywhere, Anytime
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Box display="flex" justifyContent="center" fontSize={18}>
                      {t('ยอดดาวน์โหลด')}
                    </Box>
                  </Box>
                  <Box display="flex" width="100%">
                    {downloaded.map(value => (
                      <Fragment key={uniqueId()}>
                        <Box display="inline" p={1} align="right" width="50%">
                          <Tooltip title={`ข้อมูล ณ วันที่ ${moment(value.date).locale('th').format('LL')}`}>
                            <Typography
                              component="span"
                              inline="true"
                              style={{
                                fontSize: '15px', fontWeight: 'bold', color: grey[900], paddingRight: '15%',
                              }}
                            >
                              {`${value.ios} +`}
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box display="inline" p={1} align="left" width="50%">
                          <Tooltip title={`ข้อมูล ณ วันที่ ${moment(value.date).locale('th').format('LL')}`}>
                            <Typography
                              component="span"
                              inline="true"
                              style={{
                                fontSize: '15px', fontWeight: 'bold', color: grey[900], paddingLeft: '15%',
                              }}
                            >
                              {`${value.android} +`}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Fragment>
                    ))}
                  </Box>
                  <Box display="flex" width="100%">
                    <Box display="inline" p={1} align="right" width="50%">
                      <Link href="https://apps.apple.com/th/app/thaiwater/id1097487200?l=th" target="_blank" rel="noopener noreferrer">
                        <img src={`${initPath}images/theme/banner/banner-appstore.png`} width="140" height="44" alt="Thaiwater App Store" />
                      </Link>
                    </Box>
                    <Box display="inline" p={1} align="left" width="50%">
                      <Link href="https://play.google.com/store/apps/details?id=mobile.nhc.thaiwater&hl=th" target="_blank" rel="noopener noreferrer">
                        <img src={`${initPath}images/theme/banner/banner-googleplay.png`} width="140" height="43" alt="Thaiwater Google Play" />
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box height={70} />
            </div>
          </div>
          <div className={classes.footer}>
            <div className={classes.layout}>
              <Grid container spacing={2}>
                <Hidden smDown>
                  <Grid item xs={12} md={5} lg={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box mt={4} display="flex" alignItems="center">
                          <Box component="span" display="block" pr={3} align="right">
                            <a href="https://www.hii.or.th/" target="_blank" rel="noreferrer" title="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)">
                              <img src={`${initPath}images/theme/logo/logo-hii-name.png`} alt="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)" />
                            </a>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            p={1}
                            ml={{
                              xs: 1, sm: 1, md: 1, lg: 1,
                            }}
                          >
                            <a href="https://www.mhesi.go.th/" target="_blank" rel="noreferrer" title="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)">
                              <img src={`${initPath}images/theme/logo/logo-mhesi.png`} width="60px" alt="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)" />
                            </a>
                            <Box p={1} color="white" fontWeight="fontWeightMedium" fontSize={'12px'}>
                              <Box>กระทรวงการอุดมศึกษา</Box>
                              <Box>วิทยาศาสตร์ วิจัยและนวัตกรรม</Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box style={{ width: '100%' }} pl={0} color="white">
                          <Box component="span" display="block" fontSize={14}>{'เลขที่ 901 ถนนงามวงศ์วาน แขวงลาดยาว'}</Box>
                          <Box component="span" display="block" fontSize={14}>{'เขตจตุจักร กรุงเทพมหานคร 10900'}</Box>
                          <Box component="span" display="block" mt={3} fontSize={14}>{`${t('โทรศัพท์')} : 0-2158-0901`}</Box>
                          <Box component="span" display="block" fontSize={14}>{`${t('แฟกซ์')} : 0-2158-0910`}</Box>
                          <Box component="span" display="block" fontSize={14}>
                            {`${t('อีเมล์')} : `}
                            <Link href="mailto:info_thaiwater@hii.or.th" style={{ color: '#fff' }}>info_thaiwater@hii.or.th</Link>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Hidden smDown>
                    <Box p={1} px={2} color="white" fontWeight="fontWeightMedium" borderLeft={1} borderColor={grey[600]} style={{ width: '100%', borderRight: '1px solid rgb(117, 117, 117)' }}>
                      <Box component="span" display="block" p={0} mt={3} fontSize={18}>{t('เว็บไซต์ผลงาน')}</Box>
                      <Box component="span" display="block" pl={0} mt={2} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}>
                        <Link href="https://www.hii.or.th" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                          {`${t('สถาบันสารสนเทศทรัพยากรน้ำ')} (${t('องค์การมหาชน')})`}
                        </Link>
                      </Box>
                      {/* <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="http://www2.hii.or.th/thailandwaterchallenge/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('Thailand Water Challenge')}</Link></Box> */}
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://portal-mms.hii.or.th/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('ระบบแสดงผลข้อมูลแผนที่ 3 มิติ')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://pwrc.thaiwater.net/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('ศูนย์ข้อมูลน้ำระดับจังหวัด')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://hydrolaw.thaiwater.net/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('กฏหมายด้านบริหารจัดการน้ำ')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="http://www.utokapat.org/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('มูลนิธิอุทกพัฒน์ ในพระบรมราชูปถัมภ์')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="http://tiwrm.hii.or.th/v3/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{`${t('คลังข้อมูลน้ำแห่งชาติ')} (${t('เวอร์ชั่นเก่า')})`}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://rhc.thaiwater.net/uppernorth/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('คลังข้อมูลน้ำแห่งชาติส่วนหน้า อว.(ภาคเหนือตอนบน)')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://www.aseanwater.net/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('ASEAN Hydroinformatics Data Centre (AHC)')}</Link></Box>
                    </Box>
                  </Hidden>
                  <Hidden mdUp>
                    <Box p={1} px={2} style={{ width: '100%' }} color="white" fontWeight="fontWeightMedium">
                      <Box component="span" display="block" p={0} mt={3} fontSize={18}>{t('เว็บไซต์ผลงาน')}</Box>
                      <Box component="span" display="block" pl={0} mt={2} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}>
                        <Link href="https://www.hii.or.th" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                          {`${t('สถาบันสารสนเทศทรัพยากรน้ำ')} (${t('องค์การมหาชน')})`}
                        </Link>
                      </Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="http://www2.hii.or.th/thailandwaterchallenge/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('Thailand Water Challenge')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://hydrolaw.thaiwater.net/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('กฏหมายด้านบริหารจัดการน้ำ')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="http://www.utokapat.org/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('มูลนิธิอุทกพัฒน์ ในพระบรมราชูปถัมภ์')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="http://tiwrm.hii.or.th/v3/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{`${t('คลังข้อมูลน้ำแห่งชาติ')} (${t('เวอร์ชั่นเก่า')})`}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://rhc.thaiwater.net/uppernorth/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('คลังข้อมูลน้ำแห่งชาติส่วนหน้า อว.(ภาคเหนือตอนบน)')}</Link></Box>
                      <Box component="span" display="block" pl={0} p={1} borderTop={1} borderColor={grey[600]} fontSize={14}><Link href="https://www.aseanwater.net/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>{t('ASEAN Hydroinformatics Data Centre (AHC)')}</Link></Box>
                    </Box>
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box p={2} pl={0} alignItems="center" justifyContent="center" align="center" color="white" fontFamily="Kanit" fontWeight="fontWeightMedium">
                    <Box display="block" mt={4} fontSize={18}>{t('สนับสนุนข้อมูลโดย')}</Box>
                    <Box display="block" fontSize={35}>53</Box>
                    <Box display="block" fontSize={14}>{t('หน่วยงานเข้าสู่')}</Box>
                    <Box display="block" fontSize={14}>{t('คลังข้อมูลน้ำแห่งชาติ')}</Box>

                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Box p={1} display="inline"><Link href="https://www.facebook.com/hii.mhesi/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }} aria-label="Facebook"><FbIcon /></Link></Box>
                      <Box p={1} display="inline"><Link href="https://www.instagram.com/hii.mhesi/" target="_blank" rel="noopener noreferrer" aria-label="IG" style={{ color: '#fff' }}><IgIcon /></Link></Box>
                      {/* <Box p={1} display="inline"><Link href="https://twitter.com/HII_ThaiWater" target="_blank" ><TwitterIcon/></Link></Box> */}
                      <Box p={1} display="inline"><Link href="https://www.youtube.com/channel/UCZsoQJ2H5cjViaNj44CjuEg/videos" target="_blank" rel="noopener noreferrer" aria-label="Youtube" style={{ color: '#fff' }}><YtIcon /></Link></Box>
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Box p={0} display="inline"><Link href="https://datastudio.google.com/reporting/ad493041-f4b2-4a4e-b113-d6a21ec25ef1/page/EiSAC" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', fontSize: 'smaller' }} aria-label={t('สถิติเว็บไซต์')}><StatIcon /></Link></Box>
                      <Box p={1} display="inline"><Link href="https://datastudio.google.com/reporting/ad493041-f4b2-4a4e-b113-d6a21ec25ef1/page/EiSAC" target="_blank" rel="noopener noreferrer" aria-label="IG" style={{ color: '#fff', fontSize: 'smaller' }}>{t('สถิติเว็บไซต์')}</Link></Box>
                      |
                      <Box p={1} display="inline"><Link href={`${initPath}statistics-report`} target="_blank" rel="noopener noreferrer" aria-label="IG" style={{ color: '#fff', fontSize: 'smaller' }}>{t('สถิติการใช้งานคลังข้อมูล')}</Link></Box>
                    </Box>
                  </Box>
                </Grid>
                <Hidden mdUp>
                  {/* moblie */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box mt={2} display={{ xs: 'block', sm: 'flex' }} alignItems="center" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                          <Box component="span" display="flex" alignItems="center" justifyContent="center">
                            <a href="https://www.hii.or.th/" target="_blank" rel="noreferrer" title="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)">
                              <img src={`${initPath}images/theme/logo/logo-hii-name.png`} alt="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)" />
                            </a>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p={1}
                            mt={1}
                            ml={{ xs: 0, sm: 2 }}
                          >
                            <a href="https://www.mhesi.go.th/" target="_blank" rel="noreferrer" title="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)">
                              <img src={`${initPath}images/theme/logo/logo-mhesi.png`} width="60px" alt="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)" />
                            </a>
                            <Box p={1} color="white" fontSize={'12px'}>
                              <Box>กระทรวงการอุดมศึกษา</Box>
                              <Box>วิทยาศาสตร์ วิจัยและนวัตกรรม</Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box style={{ width: '100%' }} pl={2} color="white">
                          <Box component="span" display="block" fontSize={14}>{'เลขที่ 901 ถนนงามวงศ์วาน แขวงลาดยาว'}</Box>
                          <Box component="span" display="block" fontSize={14}>{'เขตจตุจักร กรุงเทพมหานคร 10900'}</Box>
                          <Box component="span" display="block" mt={3} fontSize={14}>{`${t('โทรศัพท์')} : 0-2158-0901`}</Box>
                          <Box component="span" display="block" fontSize={14}>{`${t('แฟกซ์')} : 0-2158-0910`}</Box>
                          <Box component="span" display="block" fontSize={14}>
                            {`${t('อีเมล์')} : `}
                            <Link href="mailto:info_thaiwater@hii.or.th" style={{ color: '#fff' }}>info_thaiwater@hii.or.th</Link>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
              <Box height={20} />
            </div>
          </div>
          <div className={classes.copyright}>
            <div className={classes.layout}>
              <Grid container spacing={2} className={classes.root} wrap="nowrap">
                <Grid item xs={12}>
                  <Box alignItems="center" justifyContent="center" align="center">
                    <Box display="block" fontSize={12}>
                      {'Copyright © 2019  Hydro - Informatics Institute,'}
                      {'All rights reserved.'}
                      &nbsp;&nbsp; | &nbsp;&nbsp;
                      <Link href={`${initPath}sitemap`} style={{ color: '#9e9e9e' }} title={t('แผนผังเว็บไซต์')}>{t('แผนผังเว็บไซต์')}</Link>
                      &nbsp;&nbsp; | &nbsp;&nbsp;
                      {/* {process.env.MIX_APP_ENV} {'Enviromnent'}  */}
                      {/* {' | '} */}
                      {fullScreenStatus.status === 'enable' ? (
                        <Link href="#" onClick={this.handleSetFullscreen} style={{ color: '#9e9e9e' }} title={t('ยกเลิกการแสดงผลเต็มหน้าจอ')}>
                          {t('ยกเลิกการแสดงผลเต็มหน้าจอ')}
                        </Link>
                      ) : (
                        <Link href="#" onClick={this.handleSetFullscreen} style={{ color: '#9e9e9e' }} title={t('แสดงผลเต็มหน้าจอ')}>
                          {t('แสดงผลเต็มหน้าจอ')}
                        </Link>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Copyright.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(Copyright);
export default withTranslation()(withStyles(styles, { withTheme: true })(Copyright));
