import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import {
  FaSpinner,
} from '../../../utils/Fontawesome';

import {
  styles,
} from '../../../styles/Style';

import WlContext from '../../../contexts/wlContext';
import SalinityMap from './SalinityMap';

class Salinity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error

      datas: [],

      // map
      map: null,
      zoom: 10,
      location: [13.8, 100.55],
      markers: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,

    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    this.getCsvData();
  }

  async getCsvData() {
    const { match: { params: { showType } } } = this.props;
    await this.fetchCsv(showType);
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '80%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '70%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  fetchCsv(showType) {
    fetch(`${process.env.MIX_APP_URL}proxy/csv.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/metadata/mwa_salinity.csv`)
      .then(resp => resp.text())
      .then(results => {
        const rawData = Papa.parse(results).data.slice(1);
        const onwrFilter = rawData.filter(n => n[15] === 't');
        const varData = (typeof showType !== 'undefined' && showType === 'onwr') ? onwrFilter : rawData;
        this.setState({
          isLoading: false,
          datas: varData,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes, t } = this.props;

    const {
      isLoading, error, datas, modalHeader, modalSubHeader,
      modalContent, open, map, zoom, location, markers,
    } = this.state;

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <WlContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('ความเค็มคาดการณ์ในแม่น้ำเจ้าพระยาตอนล่าง')}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} width="100%">
                  <SalinityMap
                    map={map}
                    zoom={zoom}
                    location={location}
                    markers={markers}
                    datas={datas}
                    handleSetMap={this.handleSetMap}
                    handleModalOpen={this.handleModalOpen}
                  />
                </Box>
              </Grid>
              <Modal
                id="modal-chart"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={this.handleModalClose}
                style={{ align: 'center', justifyContent: 'center' }}
              >
                <div style={this.getModalStyle()} className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Typography variant="h5" id="modal-title">
                    {modalHeader}
                  </Typography>

                  <Typography variant="subtitle1" id="simple-modal-description">
                    {modalSubHeader}
                  </Typography>
                  <Box mt={3}>
                    {modalContent}
                  </Box>
                </div>
              </Modal>
            </Grid>
          </div>
        </WlContext.Provider>
      </div>
    );
  }
}

Salinity.defaultProps = {
  match: {
    params: {
      showType: '',
    },
  },
};

Salinity.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      showType: PropTypes.string.isRequired,
    }),
  }),
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles, { withTheme: true }), withTranslation(),
)(Salinity);
