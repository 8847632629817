// React
import React, { useState } from 'react';
// leaflet
import Box from '@material-ui/core/Box';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import Img from 'react-image';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from 'react-tooltip';
import ReportSalinityChart from './ReportSalinityChart';

import 'react-tooltip/dist/react-tooltip.css';

const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles({
  data: {
    position: 'absolute',
    width: '64px',
    height: '24px',
    cursor: 'pointer',
    borderRadius: '30px',
    border: '2px solid',
    opacity: '1',
  },
  noblink: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    borderRadius: '60px',
    border: '2px solid',
  },
  noblinkstatic: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    borderRadius: '60px',
  },
  blink: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    borderRadius: '60px',
    border: '2px solid',
    animationName: '$fade',
  },
  '@keyframes fade': {
    '0%': { opacity: '0.8', transform: 'scale(0.9)' },
    '50%': { opacity: '0.3', transform: 'scale(1.2)' },
    '100%': { opacity: '0', transform: 'scale(1.4)' },
  },
});
export default function ReportSalinityMap(props) {
  const classes = useStyles();
  const { listStationRID, listStationPCD, handleModalOpen } = props;
  const [showPCD, setshowPCD] = useState(true);
  const [showRID, setshowRID] = useState(true);

  const handleChart = (stationId, stationType) => {
    const params = {
      header: 'กราฟแสดงสถานการณ์ค่าความเค็ม',
      modalSubHeader: '',
      content: <ReportSalinityChart stationId={stationId} stationType={stationType} />,
    };
    handleModalOpen(params);
  };

  const eachDataRID = (data, i) => {
    const dataToHtml = `ID: <b>${data.id}</b> <br />
    สถานี : <b>${data.station.name}</b> <br />
    ตําบล : <b>${data.station.tambon}</b> <br />
    อําเภอ : <b>${data.station.amphoe}</b> <br />
    จังหวัด : <b>${data.station.province}</b> <br />
    สถานการณ์ความเค็ม : <b>${data.salinity.criterion.text}</b> <br />
    ข้อมูลวันที่ : <b>${data.salinity.date}</b> <br />
    สถานการณ์ปริมาณออกซิเจนที่ละลายในน้ำ : <b>${data.DO.criterion.text}</b> <br />
    ข้อมูลวันที่ : <b>${data.DO.date}</b> <br />
    สถานการณ์แอมโมเนีย NH3 : <b>${data.NH3.criterion.text}</b> <br />
    ข้อมูลวันที่ : <b>${data.NH3.date}</b>`;
    const anchor = {
      UP: [-30, -18],
      DOWN: [30, -18],
      LEFT: [0, -74],
      RIGHT: [0, 30],
      '': [30, -18],
    };
    return data.salinity.color.code === '#E8544A'
      ? (
        <>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={i}
            style={{
              animationDuration: `${1}s`,
              animationDelay: `${2}s`,
              animationIterationCount: 'infinite',
              backgroundColor: data.salinity.measure > data.salinity.criterion.max ? '#FF0000' : '#0000FF',
              top: data.station.coordinates.y - 3,
              left: data.station.coordinates.x - 3,
            }}
            className={classes.blink}
            textAlign="center"
            onClick={() => handleChart(data.id, 'RID')}
          />
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={`${i}-static`}
            style={{
              backgroundColor: data.salinity.measure > data.salinity.criterion.max ? '#FF0000' : '#0000FF',
              top: data.station.coordinates.y,
              left: data.station.coordinates.x,
            }}
            className={classes.noblinkstatic}
            textAlign="center"
            onClick={() => handleChart(data.id, 'RID')}
          >
            <span style={{ color: 'white' }}>
              {data.id}
            </span>
          </Box>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={`${i}-data`}
            style={{
              backgroundColor: data.salinity.color.code,
              top: data.station.coordinates.y + anchor[data.station.coordinates.anchor][0],
              left: data.station.coordinates.x + anchor[data.station.coordinates.anchor][1],
            }}
            className={classes.data}
            textAlign="center"
            onClick={() => handleChart(data.id, 'RID')}
          >
            <span style={{ color: 'black' }}>
              {data.salinity.measure}
            </span>
          </Box>
        </>

      ) : (
        <>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={i}
            style={{
              backgroundColor: data.salinity.measure > data.salinity.criterion.max ? '#FF0000' : '#0000FF',
              top: data.station.coordinates.y,
              left: data.station.coordinates.x,
            }}
            className={classes.noblink}
            textAlign="center"
            onClick={() => handleChart(data.id, 'RID')}
          >
            <span style={{ color: 'white' }}>
              {data.id}
            </span>
          </Box>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={`${i}-data`}
            style={{
              backgroundColor: data.salinity.color.code,
              top: data.station.coordinates.y + anchor[data.station.coordinates.anchor][0],
              left: data.station.coordinates.x + anchor[data.station.coordinates.anchor][1],
            }}
            className={classes.data}
            textAlign="center"
            onClick={() => handleChart(data.id, 'RID')}
          >
            <span style={{ color: 'black' }}>
              {data.salinity.measure}
            </span>
          </Box>
        </>
      );
  };

  const eachDataPCD = (data, i) => {
    const dataToHtml = `ID: <b>${data.id}</b> <br /> 
    สถานี : <b>${data.station.name}</b> <br /> 
    สถานการณ์ความเค็ม : <b>${data.criterion[0].text}</b> <br />
    สถานการณ์ปริมาณออกซิเจนที่ละลายในน้ำ : <b>${data.criterion[1].text}</b> <br />
    สถานการณ์แอมโมเนีย NH3 : <b>${data.criterion[2].text}</b> <br />
    ข้อมูลวันที่ : <b>${data.date}</b>`;
    const anchor = {
      UP: [-30, -18],
      DOWN: [30, -18],
      LEFT: [0, -74],
      RIGHT: [0, 30],
      '': [30, -18],
    };
    return data.color.code === '#E8544A'
      ? (
        <>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={i}
            style={{
              animationDuration: `${1}s`,
              animationDelay: `${2}s`,
              animationIterationCount: 'infinite',
              backgroundColor: data.measure.salt > data.criterion[0].max ? '#FF0000' : '#0000FF',
              top: data.station.coordinates.y - 3,
              left: data.station.coordinates.x - 3,
            }}
            className={classes.blink}
            textAlign="center"
            onClick={() => handleChart(data.id, 'PCD')}
          />
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={`${i}-static-pcd`}
            style={{
              backgroundColor: data.measure.salt > data.criterion[0].max ? '#FF0000' : '#0000FF',
              top: data.station.coordinates.y,
              left: data.station.coordinates.x,
            }}
            className={classes.noblinkstatic}
            textAlign="center"
            onClick={() => handleChart(data.id, 'PCD')}
          >
            <span style={{ color: 'white' }}>
              {data.id}
            </span>
          </Box>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={`${i}-data-pcd`}
            style={{
              backgroundColor: data.color.code,
              top: data.station.coordinates.y + anchor[data.station.coordinates.anchor][0],
              left: data.station.coordinates.x + anchor[data.station.coordinates.anchor][1],
            }}
            className={classes.data}
            textAlign="center"
            onClick={() => handleChart(data.id, 'PCD')}
          >
            <span style={{ color: 'black' }}>
              {data.measure.salt}
            </span>
          </Box>
        </>

      ) : (
        <>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={`${i}-pcd`}
            style={{
              backgroundColor: data.measure.salt > data.criterion[0].max ? '#FF0000' : '#0000FF',
              top: data.station.coordinates.y,
              left: data.station.coordinates.x,
            }}
            className={classes.noblink}
            textAlign="center"
            onClick={() => handleChart(data.id, 'PCD')}
          >
            <span style={{ color: 'white' }}>
              {data.id}
            </span>
          </Box>
          <Box
            data-tooltip-id="tooltip"
            data-tooltip-html={dataToHtml}
            data-tooltip-place="top"
            key={`${i}-data`}
            style={{
              backgroundColor: data.color.code,
              top: data.station.coordinates.y + anchor[data.station.coordinates.anchor][0],
              left: data.station.coordinates.x + anchor[data.station.coordinates.anchor][1],
            }}
            className={classes.data}
            textAlign="center"
            onClick={() => handleChart(data.id, 'PCD')}
          >
            <span style={{ color: 'black' }}>
              {data.measure.salt}
            </span>
          </Box>
        </>
      );
  };

  const htmlcontent = `<!-- Histats.com  (div with counter) --><div id="histats_counter"></div>
<!-- Histats.com  START  (aync)-->
<script type="text/javascript">var _Hasync= _Hasync|| [];
_Hasync.push(['Histats.start', '1,4862029,4,2047,280,25,00011111']);
_Hasync.push(['Histats.fasi', '1']);
_Hasync.push(['Histats.track_hits', '']);
(function() {
var hs = document.createElement('script'); hs.type = 'text/javascript'; hs.async = true;
hs.src = ('//s10.histats.com/js15_as.js');
(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hs);
})();</script>
<noscript><a href="/" target="_blank"><img  src="//sstatic1.histats.com/0.gif?4862029&101" alt="" border="0"></a></noscript>
<!-- Histats.com  END  -->`;
  return (
    <>

      <Box style={{
        width: '1280px',
        position: 'relative',
        margin: 'auto',
      }}
      >
        <Img
          src={`${initPath}images/report-salinity/basemap.jpg`}
          width={1000}
          alt="No Image"

        />
        <Tooltip id="tooltip" style={{ zIndex: 999 }} />
        <Box style={{
          position: 'absolute',
          top: 25,
          left: 25,
          width: 175,
          height: 75,
          backgroundColor: 'white',
          borderRadius: '15px',
          border: '2px solid',
        }}
        >
          <FormControlLabel
            style={{ position: 'absolute' }}
            label="กรมชลประทาน"
            control={(
              <Checkbox
                checked={showRID}
                onChange={event => setshowRID(event.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            )}
          />
          <FormControlLabel
            label="กรมควบคุมมลพิษ"
            style={{ position: 'absolute', top: 30 }}
            control={(
              <Checkbox
                checked={showPCD}
                onChange={event => setshowPCD(event.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            )}
          />
        </Box>
        <Box sx={{ display: !showRID ? 'none' : 'block' }}>
          {listStationRID.map(eachDataRID)}
        </Box>
        <Box sx={{ display: !showPCD ? 'none' : 'block' }}>
          {listStationPCD.map(eachDataPCD)}
        </Box>
        <div dangerouslySetInnerHTML={{ __html: htmlcontent }} />
      </Box>
    </>
  );
}

ReportSalinityMap.propTypes = {
  listStationRID: PropTypes.array.isRequired,
  listStationPCD: PropTypes.array.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
};
