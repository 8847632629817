import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
// import CustomTableHeader from './TableCustomHeader';

import { styles, StyledTableCell } from '../../../../../public/dist/js/Style';

class TableHeaderEnhance extends Component {
  createSortHandler = property => event => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      order, orderBy, columnData, firstColumnSticky, t,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map((column, i) => {
            if (column.sortable) {
              return (
                <StyledTableCell
                  key={column.id}
                  align={column.align ? column.align : 'left'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === column.id ? order : false}
                  className={firstColumnSticky && i === 0 ? 'first-table-sticky-head' : ''}
                >
                  <Tooltip
                    title={t('เรียงลำดับ')}
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                    // style={{ paddingLeft: 15 }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      <Box display="block">
                        <Box>
                          <Typography variant="subtitle2" noWrap>
                            {t(column.label)}
                          </Typography>
                        </Box>
                        {column.unit && (
                          <Box>
                            <Typography variant="caption" noWrap>
                              {t(column.unit)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </TableSortLabel>
                  </Tooltip>
                </StyledTableCell>
              );
            }

            return (
              <StyledTableCell key={column.id} align={column.align ? column.align : 'left'}>
                {t(column.label)}
              </StyledTableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

TableHeaderEnhance.defaultProps = {
  firstColumnSticky: false,
};

TableHeaderEnhance.propTypes = {
  columnData: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  firstColumnSticky: PropTypes.bool,
  // theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(TableHeaderEnhance);
