import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  orderBy, uniqBy, uniqueId,
} from 'lodash';

import moment from 'moment';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

// width
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import tJson from '../../utils/TJson';
import { FaSpinner } from '../../utils/Fontawesome';

import Modal from '../modal/Modal';
import RainfallMap from './RainfallMap';
import Rainfall24h from './Rainfall24hList';
import RainfallContext from '../../contexts/rainfallContext';
import RainfallToday from './RainfallTodayList';
import RainfallYesterdayList from './RainfallYesterdayList';
import RainfallMonthList from './RainfallMonthList';
import RainfallYearList from './RainfallYearList';
import Rainfall3DayList from './Rainfall3DayList';
import Rainfall7DayList from './Rainfall7DayList';

import {
  styles, BootstrapInput,
} from '../../styles/Style';
import Rainfall5DayList from './Rainfall5DayList';
import Rainfall15DayList from './Rainfall15DayList';

const TabContainer = ({ children }) => (
  <Typography component="div">
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const basinIdToNameMap = [
  { id: 1, name: 'ลุ่มน้ำสาละวิน' },
  { id: 2, name: 'ลุ่มน้ำโขงเหนือ' },
  { id: 3, name: 'ลุ่มน้ำโขงตะวันออกเฉียงเหนือ' },
  { id: 4, name: 'ลุ่มน้ำชี' },
  { id: 5, name: 'ลุ่มน้ำมูล' },
  { id: 6, name: 'ลุ่มน้ำปิง' },
  { id: 7, name: 'ลุ่มน้ำวัง' },
  { id: 8, name: 'ลุ่มน้ำยม' },
  { id: 9, name: 'ลุ่มน้ำน่าน' },
  { id: 10, name: 'ลุ่มน้ำเจ้าพระยา' },
  { id: 11, name: 'ลุ่มน้ำสะแกกรัง' },
  { id: 12, name: 'ลุ่มน้ำป่าสัก' },
  { id: 13, name: 'ลุ่มน้ำท่าจีน' },
  { id: 14, name: 'ลุ่มน้ำแม่กลอง' },
  { id: 15, name: 'ลุ่มน้ำบางปะกง' },
  { id: 16, name: 'ลุ่มน้ำโตนเลสาบ' },
  { id: 17, name: 'ลุ่มน้ำชายฝั่งทะเลตะวันออก' },
  { id: 18, name: 'ลุ่มน้ำเพชรบุรี-ประจวบคีรีขันธ์' },
  { id: 19, name: 'ลุ่มน้ำภาคใต้ฝั่งตะวันออกตอนบน' },
  { id: 20, name: 'ลุ่มน้ำทะเลสาบสงขลา' },
  { id: 21, name: 'ลุ่มน้ำภาคใต้ฝั่งตะวันออกตอนล่าง' },
  { id: 22, name: 'ลุ่มน้ำภาคใต้ฝั่งตะวันตก' },
];

const initPath = process.env.MIX_APP_PATH;

class Rainfall extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      search: '',
      isLoading: false,
      error: null,
      filterDatas: [],
      latest: new Date().toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
      dataRainfall24h: [],
      datasRainYear: [],
      lastSearchRainYear: [],
      searchRainYear: [],
      datasRainMonth: [],
      lastSearchRainMonth: [],
      searchRainMonth: [],
      datasRainYesterday: [],
      searchRainYesterday: [],
      lastSearchYesterday: [],
      datasRainToday: [],
      searchRainToday: [],
      lastSearchRainToday: [],
      datasRain3Day: [],
      searchRain3Day: [],
      lastSearchRain3Day: [],
      datasRain5Day: [],
      searchRain5Day: [],
      lastSearchRain5Day: [],
      datasRain15Day: [],
      searchRain15Day: [],
      lastSearchRain15Day: [],
      datasRain7Day: [],
      searchRain7Day: [],
      lastSearchRain7Day: [],
      type: 'rainfall24h',
      searchRainfall24: [],
      lastSearchRainfall24: [],
      dataProvince: [],
      dataBasin: [],
      dataAgency: [],
      dataPosition: [],
      agencyOptions: [],
      dataProvinceRainToday: [],
      dataBasinRainToday: [],
      dataAgencyRainToday: [],
      dataBasinRainYesterday: [],
      dataProvinceRainYesterday: [],
      dataAgencyRainYesterday: [],
      dataBasinRainMonth: [],
      dataProvinceRainMonth: [],
      dataAgencyRainMonth: [],
      dataBasinRainYear: [],
      dataProvinceRainYear: [],
      dataAgencyRainYear: [],
      dataBasinRain3Day: [],
      dataProvinceRain3Day: [],
      dataAgencyRain3Day: [],
      dataBasinRain7Day: [],
      dataProvinceRain7Day: [],
      dataAgencyRain7Day: [],
      dataBasinRain5Day: [],
      dataProvinceRain5Day: [],
      dataAgencyRain5Day: [],
      dataBasinRain15Day: [],
      dataProvinceRain15Day: [],
      dataAgencyRain15Day: [],

      // map
      map: null,
      zoom: 5,
      location: [12.9, 101.6],
      markers: [],

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      // tab
      value: '0',
      valueTab: 0,
      areaName: '',

      areaText: 'ทุกสถานี',
      defaultAgency: 'ทุกหน่วยงาน',

      //agency select
      agencyName: '',
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
  }

  getData() {
    let fopData = [];

    function matchDataFOP(paramRain, paramFop, type) {
      const updatedRainData = paramRain.map(rain => {
        const stationId = (type === 'rain_yesterday') ? rain.tele_station_id : rain.station.id;
        const matchingFop = paramFop.find(fop => fop.station_id === stationId);
        
        return {
          ...rain,
          sponsored_by: matchingFop ? matchingFop.sponsored_by : null
        };
      });
    
      return updatedRainData;
    }

    // get data fop station
    fetch(`${initPath}json/fop/FOP_MOU.json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        fopData = result;
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service rain 24hr
    fetch(`${process.env.MIX_API_URL}public/rain_24h`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // fopData ข้อมูลสถานีที่มาจากมูลนิธิเพื่อนพึ่งพาฯ
        const FOP_DATA = fopData;
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // sorting and filter, rainfall24
        const rainfall24Sort = orderBy(result.data, ['rain_24h'], ['desc']);

        // https://devsupport.hii.or.th/?support_page=open_ticket&ticket_id=523&auth_code=hA0apG5G1o
        let dataRainfall24h = rainfall24Sort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rain_24h >= 0.1);
        });

        let dataBasin = dataRainfall24h
          .filter(d => d.basin.basin_name.th !== undefined)
          .map(d => ({
            id: d.basin.basin_code,
            name: d.basin.basin_name.th,
          }));
        dataBasin = uniqBy(orderBy(dataBasin, ['name'], ['asc']), 'id');

        // [Ticket #424] รบกวนตรวจสอบข้อมูลของจังหวัดอุบลราชธานีและฉะเชิงเทรา
        // แก้ไข ไม่ต้อง filter จังหวัดที่ไม่มีข้อมูล ให้แสดงผล 77 จังหวัด
        let dataProvince = rainfall24Sort
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvince = uniqBy(dataProvince.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataAgency = dataRainfall24h
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgency = uniqBy(dataAgency.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
        dataRainfall24h = matchDataFOP(dataRainfall24h, FOP_DATA, 'rainfall24h');

        this.setState({
          dataProvince,
          dataBasin,
          dataAgency,
          agencyOptions: dataAgency,
          dataPosition: dataProvince,
          dataRainfall24h,
          isLoading: false,
          lastSearchRainfall24: dataRainfall24h,
          searchRainfall24: dataRainfall24h,
          latest: this.getMaxDate(dataRainfall24h),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service rain today
    fetch(`${process.env.MIX_API_URL}public/rain_today`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // sorting and filter, rainToday
        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        // https://devsupport.hii.or.th/?support_page=open_ticket&ticket_id=523&auth_code=hA0apG5G1o
        let filterDatas = rainTodaySort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rainfall_value >= 0.1);
        });

        let dataBasinRainToday = filterDatas
          .filter(d => d.basin.basin_name.th !== undefined)
          .map(d => ({
            id: d.basin.basin_code,
            name: d.basin.basin_name.th,
          }));
        dataBasinRainToday = uniqBy(orderBy(dataBasinRainToday, ['name'], ['asc']), 'id');

        let dataProvinceRainToday = filterDatas
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvinceRainToday = uniqBy(dataProvinceRainToday.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        
        let dataAgencyRainToday = filterDatas
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgencyRainToday = uniqBy(dataAgencyRainToday.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');

        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain_today');

        this.setState({
          dataProvinceRainToday,
          dataBasinRainToday,
          dataAgencyRainToday,
          datasRainToday: filterDatas,
          isLoading: false,
          lastSearchRainToday: filterDatas,
          searchRainToday: filterDatas,
          latestRainToday: this.getMaxDate(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service rain yesterday
    fetch(`${process.env.MIX_API_URL}public/rain_yesterday`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // sorting and filter, rainToday
        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        // https://devsupport.hii.or.th/?support_page=open_ticket&ticket_id=523&auth_code=hA0apG5G1o
        let filterDatas = rainTodaySort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rainfall_value >= 0.1);
        });

        let dataBasinRainYesterday = filterDatas
          .filter(d => d.basin.basin_name.th !== undefined)
          .map(d => ({
            id: d.basin.basin_code,
            name: d.basin.basin_name.th,
          }));
        dataBasinRainYesterday = uniqBy(orderBy(dataBasinRainYesterday, ['name'], ['asc']), 'id');

        let dataProvinceRainYesterday = filterDatas
          .filter(d => d.province_name.th !== undefined)
          .map(d => ({
            id: d.province_code,
            name: d.province_name.th,
          }));
        dataProvinceRainYesterday = uniqBy(dataProvinceRainYesterday.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        
        let dataAgencyRainYesterday = filterDatas
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgencyRainYesterday = uniqBy(dataAgencyRainYesterday.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
        
        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain_yesterday');

        this.setState({
          dataBasinRainYesterday,
          dataProvinceRainYesterday,
          dataAgencyRainYesterday,
          datasRainYesterday: filterDatas,
          isLoading: false,
          lastSearchRainYesterday: filterDatas,
          searchRainYesterday: filterDatas,
          latestRainYesterday: this.getMaxDate(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service rain month
    fetch(`${process.env.MIX_API_URL}public/rain_monthly`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        // sorting and filter, rainMonth
        const rainMonthSort = orderBy(result.data, ['rainfall_value'], ['desc']);
        // https://devsupport.hii.or.th/?support_page=open_ticket&ticket_id=523&auth_code=hA0apG5G1o
        let filterDatasMonth = rainMonthSort.filter(d => (d.rainfall_value >= 0.1));

        let dataBasinRainMonth = filterDatasMonth
          .filter(d => d.basin.basin_name.th !== undefined)
          .map(d => ({
            id: d.basin.basin_code,
            name: d.basin.basin_name.th,
          }));

        dataBasinRainMonth = uniqBy(dataBasinRainMonth.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataProvinceRainMonth = filterDatasMonth
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvinceRainMonth = uniqBy(dataProvinceRainMonth.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        
        let dataAgencyRainMonth = filterDatasMonth
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgencyRainMonth = uniqBy(dataAgencyRainMonth.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
        
        filterDatasMonth = matchDataFOP(filterDatasMonth, FOP_DATA, 'rain_monthly');

        this.setState({
          dataBasinRainMonth,
          dataProvinceRainMonth,
          dataAgencyRainMonth,
          datasRainMonth: filterDatasMonth,
          lastSearchRainMonth: filterDatasMonth,
          searchRainMonth: filterDatasMonth,
          isLoading: false,
          latestRainMonth: this.getMaxDate(filterDatasMonth),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service rain year
    fetch(`${process.env.MIX_API_URL}public/rain_yearly`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        // sorting and filter, rainToday
        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        // https://devsupport.hii.or.th/?support_page=open_ticket&ticket_id=523&auth_code=hA0apG5G1o
        let filterDatas = rainTodaySort.filter(d => {
          // const time = new Date(d.rainfall_datetime).getTime();
          const rainfallValue = d.rainfall_value;
          return (rainfallValue >= 0.1); // last24hrs < time &&
        });

        let dataBasinRainYear = filterDatas
          .filter(d => d.basin.basin_name.th !== undefined)
          .map(d => ({
            id: d.basin.basin_code,
            name: d.basin.basin_name.th,
          }));
        dataBasinRainYear = uniqBy(dataBasinRainYear.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataProvinceRainYear = filterDatas
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvinceRainYear = uniqBy(dataProvinceRainYear.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        
        let dataAgencyRainYear = filterDatas
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgencyRainYear = uniqBy(dataAgencyRainYear.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
        
        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain_yearly');

        this.setState({
          dataBasinRainYear,
          dataProvinceRainYear,
          dataAgencyRainYear,
          datasRainYear: filterDatas,
          lastSearchRainYear: filterDatas,
          searchRainYear: filterDatas,
          isLoading: false,
          latestRainYear: this.getMaxDate(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service rain 3 day
    fetch(`${process.env.MIX_API_URL}provinces/rain3d`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        // Sorting and filtering rain3Day data
        const rain3DaySort = orderBy(result.data, ['rain_3d'], ['desc']);
        const filterData = rain3DaySort.filter(d => d.rain_3d >= 0.1);

        // Mapping basin names to filterDatas
        let filterDatas = filterData.map(data => {
          const basin = basinIdToNameMap.find(b => String(b.id) === String(data.station.basin_id));
          // console.log(basin);

          return {
            ...data,
            station: {
              ...data.station,
              basin_name: basin ? basin.name : '',
              basin_code: basin ? basin.id : 0,
            },
          };
        })
          .filter(data => data.station.basin_name && data.station.basin_code);

        // Mapping and sorting basin data
        let dataBasinRain3Day = filterDatas
          .filter(d => d.station.basin_name !== undefined)
          .map(d => ({
            id: d.station.basin_id,
            name: d.station.basin_name,
          }));
        dataBasinRain3Day = uniqBy(dataBasinRain3Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        // Mapping and sorting province data
        let dataProvinceRain3Day = filterDatas
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvinceRain3Day = uniqBy(dataProvinceRain3Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        
        let dataAgencyRain3Day = filterDatas
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgencyRain3Day = uniqBy(dataAgencyRain3Day.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
        
        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain3d');

        // Updating component state
        this.setState({
          dataBasinRain3Day,
          dataProvinceRain3Day,
          dataAgencyRain3Day,
          datasRain3Day: filterDatas,
          lastSearchRain3Day: filterDatas,
          searchRain3Day: filterDatas,
          isLoading: false,
          latestRain3Day: this.getMaxDate2(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // get data from service rain 5 day
      fetch(`${process.env.MIX_API_URL}provinces/rain5d`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          const FOP_DATA = fopData;
          // Sorting and filtering rain5Day data
          const rain5DaySort = orderBy(result.data, ['rain_5d'], ['desc']);
          const filterData = rain5DaySort.filter(d => d.rain_5d >= 0.1);
  
          // Mapping basin names to filterDatas
          let filterDatas = filterData.map(data => {
            const basin = basinIdToNameMap.find(b => String(b.id) === String(data.station.basin_id));
            // console.log(basin);
  
            return {
              ...data,
              station: {
                ...data.station,
                basin_name: basin ? basin.name : '',
                basin_code: basin ? basin.id : 0,
              },
            };
          })
            .filter(data => data.station.basin_name && data.station.basin_code);
  
          // Mapping and sorting basin data
          let dataBasinRain5Day = filterDatas
            .filter(d => d.station.basin_name !== undefined)
            .map(d => ({
              id: d.station.basin_id,
              name: d.station.basin_name,
            }));
          dataBasinRain5Day = uniqBy(dataBasinRain5Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
  
          // Mapping and sorting province data
          let dataProvinceRain5Day = filterDatas
            .filter(d => d.geocode.province_name.th !== undefined)
            .map(d => ({
              id: d.geocode.province_code,
              name: d.geocode.province_name.th,
            }));
          dataProvinceRain5Day = uniqBy(dataProvinceRain5Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
          
          let dataAgencyRain5Day = filterDatas
            .map(d => ({
              id: d.agency.agency_name.th,
              name: d.agency.agency_name,
            }));
          dataAgencyRain5Day = uniqBy(dataAgencyRain5Day.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
          
          filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain5d');
  
          // Updating component state
          this.setState({
            dataBasinRain5Day,
            dataProvinceRain5Day,
            dataAgencyRain5Day,
            datasRain5Day: filterDatas,
            lastSearchRain5Day: filterDatas,
            searchRain5Day: filterDatas,
            isLoading: false,
            latestRain5Day: this.getMaxDate2(filterDatas),
          });
        })
        .catch(error => this.setState({ error, isLoading: false }));
      
    // get data from service rain 7 day
    fetch(`${process.env.MIX_API_URL}provinces/rain7d`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        // Sorting and filtering rain7Day data
        const rain7DaySort = result.data.slice().sort((a, b) => parseFloat(b) - parseFloat(a));
        // console.log('f,dl', rain7DaySort);
        const filterData = rain7DaySort.filter(d => d.rain_7d >= 0.1);

        // Mapping basin names to filterDatas
        let filterDatas = filterData.map(data => {
          const basin = basinIdToNameMap.find(b => String(b.id) === String(data.station.basin_id));

          return {
            ...data,
            station: {
              ...data.station,
              basin_name: basin ? basin.name : '',
              basin_code: basin ? basin.id : 0,
            },
          };
        })
          .filter(data => data.station.basin_name && data.station.basin_code);

        // Mapping and sorting basin data
        let dataBasinRain7Day = filterDatas
          .filter(d => d.station.basin_name !== undefined)
          .map(d => ({
            id: d.station.basin_id,
            name: d.station.basin_name,
          }));
        dataBasinRain7Day = uniqBy(dataBasinRain7Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        // Mapping and sorting province data
        let dataProvinceRain7Day = filterDatas
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvinceRain7Day = uniqBy(dataProvinceRain7Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        
        let dataAgencyRain7Day = filterDatas
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgencyRain7Day = uniqBy(dataAgencyRain7Day.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
        
        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain7d');
        // Updating component state
        this.setState({
          dataBasinRain7Day,
          dataProvinceRain7Day,
          dataAgencyRain7Day,
          datasRain7Day: filterDatas,
          lastSearchRain3Day: filterDatas,
          searchRain7Day: filterDatas,
          isLoading: false,
          latestRain7Day: this.getMaxDate2(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service rain 15 day
    fetch(`${process.env.MIX_API_URL}provinces/rain15d`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        // Sorting and filtering rain15Day data
        const rain15DaySort = orderBy(result.data, ['rain_15d'], ['desc']);
        const filterData = rain15DaySort.filter(d => d.rain_15d >= 0.1);

        // Mapping basin names to filterDatas
        let filterDatas = filterData.map(data => {
          const basin = basinIdToNameMap.find(b => String(b.id) === String(data.station.basin_id));
          // console.log(basin);

          return {
            ...data,
            station: {
              ...data.station,
              basin_name: basin ? basin.name : '',
              basin_code: basin ? basin.id : 0,
            },
          };
        })
          .filter(data => data.station.basin_name && data.station.basin_code);

        // Mapping and sorting basin data
        let dataBasinRain15Day = filterDatas
          .filter(d => d.station.basin_name !== undefined)
          .map(d => ({
            id: d.station.basin_id,
            name: d.station.basin_name,
          }));
        dataBasinRain15Day = uniqBy(dataBasinRain15Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        // Mapping and sorting province data
        let dataProvinceRain15Day = filterDatas
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvinceRain15Day = uniqBy(dataProvinceRain15Day.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');
        
        let dataAgencyRain15Day = filterDatas
          .map(d => ({
            id: d.agency.agency_name.th,
            name: d.agency.agency_name,
          }));
        dataAgencyRain15Day = uniqBy(dataAgencyRain15Day.sort((a, b) => a.id.localeCompare(b.id, 'th')), 'id');
        
        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain15d');

        // Updating component state
        this.setState({
          dataBasinRain15Day,
          dataProvinceRain15Day,
          dataAgencyRain15Day,
          datasRain15Day: filterDatas,
          lastSearchRain15Day: filterDatas,
          searchRain15Day: filterDatas,
          isLoading: false,
          latestRain15Day: this.getMaxDate2(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }


  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.rainfall_datetime.replace(/-/g, '/')));
      const maxDate = new Date(Math.max.apply(null, dates));
      return `${moment(maxDate).format('YYYY-MM-DD')} เวลา : ${moment(maxDate).format('HH:mm')}`;
    }
    return new Date();
  }

  getMaxDate2 = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.rainfall_end_date.replace(/-/g, '/')));
      const maxDate = new Date(Math.max.apply(null, dates));
      return `${moment(maxDate).format('YYYY-MM-DD')} เวลา : ${moment(maxDate).format('HH:mm')}`;
    }
    return new Date();
  }

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleData = () => {
    const { dataAgency } = this.state;
    this.setState({
      agencyOptions: dataAgency,
      type: 'rainfall24h',
    });
  };

  handleDataRainfallToday = () => {
    const { dataAgencyRainToday } = this.state;
    this.setState({
      agencyOptions: dataAgencyRainToday,
      type: 'rainfallToday',
    });
  };

  handleDataRainfallYesterday = () => {
    const { dataAgencyRainYesterday } = this.state;
    this.setState({
      agencyOptions: dataAgencyRainYesterday,
      type: 'rainfallYesterday',
    });
  };

  handleDataRainfallMonth = () => {
    const { dataAgencyRainMonth } = this.state;
    this.setState({
      agencyOptions: dataAgencyRainMonth,
      type: 'rainfallMonth',
    });
  };

  handleDataRainfallYear = () => {
    const { dataAgencyRainYear } = this.state;
    this.setState({
      agencyOptions: dataAgencyRainYear,
      type: 'rainfallYear',
    });
  };

  handleDataRainfall3Day = () => {
    const { dataAgencyRain3Day } = this.state;
    this.setState({
      agencyOptions: dataAgencyRain3Day,
      type: 'rainfall3Day',
    });
  };

  handleDataRainfall5Day = () => {
    const { dataAgencyRain5Day } = this.state;
    this.setState({
      agencyOptions: dataAgencyRain5Day,
      type: 'rainfall5Day',
    });
  };
  
  handleDataRainfall7Day = () => {
    const { dataAgencyRain7Day } = this.state;
    this.setState({
      agencyOptions: dataAgencyRain7Day,
      type: 'rainfall7Day',
    });
  };

  handleDataRainfall15Day = () => {
    const { dataAgencyRain15Day } = this.state;
    this.setState({
      agencyOptions: dataAgencyRain15Day,
      type: 'rainfall15Day',
    });
  };

  handlePositionChange = (event, value) => {
    const {
      dataProvince,
      dataBasin,
      dataRainfall24h,
      datasRainToday,
      datasRainYesterday,
      datasRainMonth,
      datasRainYear,
      datasRain3Day,
      datasRain5Day,
      datasRain7Day,
      datasRain15Day,
      dataProvinceRainToday,
      dataBasinRainToday,
      dataBasinRainYesterday,
      dataProvinceRainYesterday,
      dataBasinRainMonth,
      dataProvinceRainMonth,
      dataBasinRainYear,
      dataProvinceRainYear,
      dataBasinRain3Day,
      dataProvinceRain3Day,
      dataBasinRain5Day,
      dataProvinceRain5Day,
      dataBasinRain7Day,
      dataProvinceRain7Day,
      dataBasinRain15Day,
      dataProvinceRain15Day,
      valueTab,
    } = this.state;
    let areaText = 'ทั่วประเทศ';
    if (value === '1') {
      areaText = 'ทุกลุ่มน้ำ';
    }
    let dataPosition = [];
    if (valueTab === 0) dataPosition = (value === '1') ? dataBasin : dataProvince;
    if (valueTab === 1) dataPosition = (value === '1') ? dataBasinRainToday : dataProvinceRainToday;
    if (valueTab === 2) dataPosition = (value === '1') ? dataBasinRainYesterday : dataProvinceRainYesterday;
    if (valueTab === 3) dataPosition = (value === '1') ? dataBasinRain3Day : dataProvinceRain3Day;
    if (valueTab === 4) dataPosition = (value === '1') ? dataBasinRain5Day : dataProvinceRain5Day;
    if (valueTab === 5) dataPosition = (value === '1') ? dataBasinRain7Day : dataProvinceRain7Day;
    if (valueTab === 6) dataPosition = (value === '1') ? dataBasinRain15Day : dataProvinceRain15Day;
    if (valueTab === 7) dataPosition = (value === '1') ? dataBasinRainMonth : dataProvinceRainMonth;
    if (valueTab === 8) dataPosition = (value === '1') ? dataBasinRainYear : dataProvinceRainYear;

    this.setState({
      areaName: '',
      value,
      dataPosition,
      searchRainfall24: dataRainfall24h,
      lastSearchRainfall24: dataRainfall24h,
      searchRainToday: datasRainToday,
      lastSearchRainToday: datasRainToday,
      searchRainYesterday: datasRainYesterday,
      lastSearchRainYesterday: datasRainYesterday,
      searchRainMonth: datasRainMonth,
      lastSearchRainMonth: datasRainMonth,
      searchRainYear: datasRainYear,
      lastSearchRainYear: datasRainYear,
      searchRain3Day: datasRain3Day,
      lastSearchRain3Day: datasRain3Day,
      searchRain5Day: datasRain5Day,
      lastSearchRain5Day: datasRain5Day,
      searchRain7Day: datasRain7Day,
      lastSearchRain7Day: datasRain7Day,
      searchRain15Day: datasRain15Day,
      lastSearchRain15Day: datasRain15Day,
      areaText,
    }, () => {
      this.filterDatas2();
    });
  };

  handleChangeSearch = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      this.filterDatas2();
    });
  };

  handlePositionFilterChange = event => {
    this.setState({ areaName: event.target.value }, () => {
      this.filterDatas2();
    });
  }

  handleFilterAgencyChange = agencyName => event => {
    this.setState({ [agencyName]: event.target.value }, () => {
      this.filterDatas2();
    });
  }

  handleSearchClick = () => {
    this.filterDatas2();
  };

  handleChange = (event, valueTab) => {
    const { value, agencyName } = this.state;
    this.setState({ valueTab }, () => {
      this.handlePositionChange(null, value)
    });
    this.setState({ agencyName: "" }, () => {
      this.filterDatas2();
    });
  }

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  filterDatas2() {
    const {
      search,
      areaName,
      agencyName,
      dataRainfall24h,
      datasRainToday,
      datasRainYesterday,
      datasRainMonth,
      datasRainYear,
      datasRain3Day,
      datasRain5Day,
      datasRain7Day,
      datasRain15Day,
      value,
    } = this.state;
    let searchFilterYesterday = [];
    let searchFilterRainfall24h = [];
    let searchFilterRainToday = [];
    let searchFilterRainMonth = [];
    let searchFilterRainYear = [];
    let searchFilterRain3Day = [];
    let searchFilterRain5Day = [];
    let searchFilterRain7Day = [];
    let searchFilterRain15Day = [];

    searchFilterYesterday = datasRainYesterday.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.basin.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRainfall24h = dataRainfall24h.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.basin.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRainToday = datasRainToday.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.basin.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRainMonth = datasRainMonth.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.basin.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRainYear = datasRainYear.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.basin.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRain3Day = datasRain3Day.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.station.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRain5Day = datasRain5Day.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.station.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRain7Day = datasRain7Day.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.station.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    searchFilterRain15Day = datasRain15Day.filter(d => {
      if (search !== '') {
        if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(search) > -1 
            || d.geocode.amphoe_name.th.indexOf(search) > -1
            || d.geocode.tumbon_name.th.indexOf(search) > -1
            || d.geocode.province_name.th.indexOf(search) > -1
            || d.station.tele_station_oldcode.toUpperCase().indexOf(search.toUpperCase()) > -1
          )){
          return false
        }
      }
      if (areaName !== '') {
        if(value === '1' ? !(d.station.basin_code.toString() === areaName) : !(d.geocode.province_code === areaName)){
          return false
        }
      }
      if (agencyName !== '') {
        if(!(d.agency.agency_name.th === agencyName)){
          return false
        }
      }
      return d;
    });

    this.setState({
      lastSearchRainfall24: searchFilterRainfall24h,
      searchRainfall24: searchFilterRainfall24h,
      lastSearchRainToday: searchFilterRainToday,
      searchRainToday: searchFilterRainToday,
      lastSearchRainYesterday: searchFilterYesterday,
      searchRainYesterday: searchFilterYesterday,
      lastSearchRainMonth: searchFilterRainMonth,
      searchRainMonth: searchFilterRainMonth,
      LastSearchRainYear: searchFilterRainYear,
      searchRainYear: searchFilterRainYear,
      lastSearchRain3Day: searchFilterRain3Day,
      searchRain3Day: searchFilterRain3Day,
      lastSearchRain5Day: searchFilterRain5Day,
      searchRain5Day: searchFilterRain5Day,
      lastSearchRain7Day: searchFilterRain7Day,
      searchRain7Day: searchFilterRain7Day,
      lastSearchRain15Day: searchFilterRain15Day,
      searchRain15Day: searchFilterRain15Day,
    });
  }

  render() {
    const { classes, t } = this.props;

    // get required data from state
    const {
      isLoading,
      error,
      value,
      open,
      lastSearchRainfall24,
      latest,
      searchRainToday,
      latestRainToday,
      searchRainYesterday,
      latestRainYesterday,
      searchRainMonth,
      latestRainMonth,
      searchRainYear,
      latestRainYear,
      searchRain3Day,
      latestRain3Day,
      searchRain5Day,
      latestRain5Day,
      searchRain7Day,
      latestRain7Day,
      searchRain15Day,
      latestRain15Day,
      type,
      areaName,
      defaultAgency,
      agencyName,
      agencyOptions,
      dataPosition,
      search,
      searchRainfall24,
      valueTab,
      areaText,
      modalData,
    } = this.state;

    
    dataPosition.sort(function(a,b) {
      if (a.id > 100) {
        return 1; 
      }
      if (b.id > 100) {
        return -1; 
      }
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return (
      <Fragment key="key">
        {error && <div className="text-center">{error.message}</div>}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={70} />
          </div>
        )}

        <RainfallContext.Provider
          value={{
            datas: lastSearchRainfall24,
            handleModalOpen: this.handleModalOpen,
            type,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {isLoading === false && (
                <RainfallMap
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...this.state}
                  handleSetMap={this.handleSetMap}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41 }}>
                    <Box>
                      <FormControl variant="outlined" component="fieldset">
                        <RadioGroup aria-label="position" name="position" value={value} onChange={this.handlePositionChange} row>
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลจังหวัด')}</Typography>}
                            labelPlacement="end"
                            fontFamily="Kanit"
                            style={{ height: 31, fontFamily: 'Kanit' }}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลลุ่มน้ำ')}</Typography>}
                            labelPlacement="end"
                            style={{ height: 31, fontFamily: 'Kanit' }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={7}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                    <FormControl variant="outlined" className={classes.formControl} width="100%">
                      <NativeSelect
                        value={areaName}
                        onChange={this.handlePositionFilterChange}
                        input={<BootstrapInput name="areaName" className={classes.outLinedRadius} />}
                        style={{ paddingLeft: 16 }}
                      >
                        <option value="" style={{ fontFamily: 'Kanit', fontSize: 14 }}>{t(areaText)}</option>
                        <optgroup label={t('ประเทศไทย')} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                        {dataPosition.filter(val => val.id <= 100).map(val => (
                          <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                        ))}
                        </optgroup>
                        <optgroup label={t('ต่างประเทศ')} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                        {dataPosition.filter(val => val.id > 100).map(val => (
                          <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                        ))}
                        </optgroup>
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                    <FormControl variant="outlined">
                      <NativeSelect
                        id="agencySelect"
                        value={agencyName.toString()}
                        onChange={this.handleFilterAgencyChange('agencyName')}
                        input={(
                          <BootstrapInput
                            className={classes.outLinedRadius}
                            style={{ paddingLeft: 10 }}
                          />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit', fontSize: 14 }}>{t(defaultAgency)}</option>
                          {agencyOptions.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{tJson(val.name, 'หน่วยงาน')}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                    <Paper className={classes.rootSearch} elevation={0}>
                      <InputBase
                        display="flex"
                        className={classes.inputSearch}
                        id="search"
                        label={t('ค้นหา')}
                        type="search"
                        margin="none"
                        value={search}
                        onChange={this.handleChangeSearch('search')}
                        placeholder={t('ค้นหา')}
                      />
                      <IconButton className={classes.iconButton} aria-label="Search" onClick={this.handleSearchClick}>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
              <Paper style={{ marginTop: 16 }}>
                <Tabs
                  id="valueTab"
                  value={valueTab}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  className={classes.boxTabScroll}
                >
                  <Tooltip title={`${t('ปริมาณฝนสะสมตั้งแต่เวลาปัจจุบันย้อนหลังไป 24 ชั่วโมง')}`}>
                    <Tab label={t('ฝน 24 ชั่วโมง')} className={valueTab === 0 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ปริมาณฝนสะสมตั้งแต่เวลา 7.00 น. ของวันนี้ จนถึงเวลาปัจจุบันของวันนี้')}`}>
                    <Tab label={t('ฝนวันนี้')} className={valueTab === 1 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ปริมาณฝนสะสมที่เกิดขึ้นตั้งแต่ 7.00 น. ของวันก่อนหน้าถึง 7.00 น. ของวันปัจจุบัน')}`}>
                    <Tab label={t('ฝนวานนี้')} className={valueTab === 2 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ผลรวมของฝนรายวันจำนวน 3 วัน')}`}>
                    <Tab label={t('ฝน 3 วัน')} className={valueTab === 3 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ผลรวมของฝนรายวันจำนวน 5 วัน')}`}>
                    <Tab label={t('ฝน 5 วัน')} className={valueTab === 4 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ผลรวมของฝนรายวันจำนวน 7 วัน')}`}>
                    <Tab label={t('ฝน 7 วัน')} className={valueTab === 5 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ผลรวมของฝนรายวันจำนวน 15 วัน')}`}>
                    <Tab label={t('ฝน 15 วัน')} className={valueTab === 6 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ผลรวมของฝนรายเดือน')}`}>
                    <Tab label={t('ฝนรายเดือน')} className={valueTab === 7 ? classes.active_tab : ''} />
                  </Tooltip>
                  <Tooltip title={`${t('ผลรวมของฝนรายปี')}`}>
                    <Tab label={t('ฝนรายปี')} className={valueTab === 8 ? classes.active_tab : ''} />
                  </Tooltip>
                </Tabs>
                {/* {valueTab === 0 && console.log(searchRainfall24)}  */}
                {valueTab === 0 && (
                  <TabContainer component="div">
                    <Rainfall24h
                      datas={searchRainfall24}
                      latestDate={latest}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleData}
                    />
                  </TabContainer>
                )}
                {valueTab === 1 && (
                  <TabContainer>
                    <RainfallToday
                      datas={searchRainToday}
                      latestDate={latestRainToday}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfallToday}
                    />
                  </TabContainer>
                )}
                {valueTab === 2 && (
                  <TabContainer>
                    <RainfallYesterdayList
                      datas={searchRainYesterday}
                      latestDate={latestRainYesterday}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfallYesterday}
                    />
                  </TabContainer>
                )}
                {valueTab === 3 && (
                  <TabContainer>
                    <Rainfall3DayList
                      datas={searchRain3Day}
                      latestDate={latestRain3Day}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfall3Day}
                    />
                  </TabContainer>
                )}
                {valueTab === 4 && (
                  <TabContainer>
                    <Rainfall5DayList
                      datas={searchRain5Day}
                      latestDate={latestRain5Day}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfall5Day}
                    />
                  </TabContainer>
                )}
                {valueTab === 5 && (
                  <TabContainer>
                    <Rainfall7DayList
                      datas={searchRain7Day}
                      latestDate={latestRain7Day}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfall7Day}
                    />
                  </TabContainer>
                )}
                {valueTab === 6 && (
                  <TabContainer>
                    <Rainfall15DayList
                      datas={searchRain15Day}
                      latestDate={latestRain15Day}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfall15Day}
                    />
                  </TabContainer>
                )}
                {valueTab === 7 && (
                  <TabContainer>
                    <RainfallMonthList
                      datas={searchRainMonth}
                      latestDate={latestRainMonth}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfallMonth}
                    />
                  </TabContainer>
                )}
                {valueTab === 8 && (
                  <TabContainer>
                    <RainfallYearList
                      datas={searchRainYear}
                      latestDate={latestRainYear}
                      handleSearchMarker={this.handleSearchMarker}
                      handleData={this.handleDataRainfallYear}
                    />
                  </TabContainer>
                )}

              </Paper>
            </Grid>
            <Modal
              open={modalData.open}
              modalHeader={modalData.header}
              modalContent={modalData.content}
              resetModal={this.resetModal}
            />
          </Grid>
        </RainfallContext.Provider>
      </Fragment>
    );
  }
}

Rainfall.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }), withTranslation('translations', { withRef: true }))(Rainfall);
