/**
 * *
 * @description set show header footer [show or hidden]
 * @version 1.0.0
 * @author [Peerapong Srisom]
 */

const routes = [
  { path: '/bma', show: false },
  { path: '/drought/monitoring', show: false },
  { path: '/floodforecast', show: false },
  { path: '/FlashFlood', show: false },
  { path: '/floodplain', show: false },
  { path: '/situation/district', show: false },
  { path: '/forecast/rainfall_month', show: false },
  { path: '/water/dam/chart', show: false },
  { path: '/water/wl/chart', show: false },
  { path: '/water/gate/chart', show: false },
  { path: '/storm_surge', show: false },
  { path: '/storm_surge/forecast', show: false },
  { path: 'climate-change', show: false },
  { path: 'forecast/wrfroms', show: false },
  { path: 'gsmap-asean', show: false },
  { path: '/report-salinity', show: false },
  { path: '/report-wrf-vortex', show: false },
  { path: '/comparing-rain-predictions', show: false },
  { path: 'mhesi-warroom', show: false },
  { path: '/situation/district', show: false },
  { path: '/demo-site', show: false },
];

const currentPath = window.location.pathname;
const routeMap = routes.filter(route => currentPath.includes(route.path));
const showStatus = routeMap.map(route => route.show);
const showHeaderFooter = (showStatus.length > 0) ? showStatus[0] : true;

export default showHeaderFooter;
