import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Img from "react-image";
import useStyles from "./Style";
import { Typography } from "@material-ui/core";
import moment from "moment";

//component
import DemoSiteChart from "./DemoSiteChart";
import DemoSiteTable from "./DemoSiteTable";

import Lightbox from "react-image-lightbox";

import { isNull } from "lodash";
import heightMeasurementJSON from "./json/heightMeasurement.json";
import HiiFixedGraph from "./fixedGraph/HiiFixedGraph";

const initPath = process.env.MIX_APP_PATH;

export default function DemoSiteTele() {
  const classes = useStyles();

  const [currentData, setCurrentData] = useState(null);
  const [currentPicName, setCurrentPicName] = useState({ hii: "", pathum: "" });
  const [latestDataPoint, setLatestDataPoint] = useState({
    hii: { datetime: 0, height: 0, volume: 0 },
    pathum: { datetime: 0, height: 0, volume: 0 },
  });

  const [startDate, setStartDate] = useState(
    moment().subtract(2, "days").locale("th").format("YYYY-MM-DD HH:mm")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).locale("th").format("YYYY-MM-DD HH:mm")
  );

  const [lightboxIsOpen1, setLightboxIsOpen1] = useState(false);
  const [lightboxIsOpen2, setLightboxIsOpen2] = useState(false);
  const [currentPictureName, setCurrentPictureName] = useState("239_000.jpg");
  const [currentPicturePrevName, setCurrentPicturePrevName] =
    useState("239_000");
  const [currentPictureNextName, setCurrentPictureNextName] =
    useState("239_200");
  const [currentPictureNumber, setCurrentPictureNumber] = useState(239.0);
  const [currentPictureTitle, setCurrentPictureTitle] = useState(
    "จำลองภาพ 3 มิติที่ระดับน้ำ 239.0 ม.รทก. (ระดับน้ำห่างกันทุก 20 ซม.)"
  );

  const handleMovePrevRequest = () => {
    if (currentPictureNumber <= 239.0) {
      return;
    } else {
      const newCurrentPictureNumber =
        Math.round((currentPictureNumber - 0.2) * 10) / 10;
      const newPrevPictureNumber =
        Math.round((currentPictureNumber - 0.4) * 10) / 10;
      const [meter, centimeter] = newCurrentPictureNumber.toFixed(1).split(".");
      const [prevMeter, prevCentimeter] = newPrevPictureNumber
        .toFixed(1)
        .split(".");
      setCurrentPictureNumber(newCurrentPictureNumber);
      setCurrentPictureName(`${meter}_${centimeter}00.jpg`);
      setCurrentPicturePrevName(`${prevMeter}_${prevCentimeter}00`);
      setCurrentPictureTitle(
        `จำลองภาพ 3 มิติที่ระดับน้ำ ${meter}.${centimeter} ม.รทก. (ระดับน้ำห่างกันทุก 20 ซม.) | ปริมาณน้ำในอ่าง ${calculateWithHeight(
          newCurrentPictureNumber
        ).toLocaleString(undefined, { maximumFractionDigits: 0 })} ลบ.ม`
      );
    }
  };

  const handleMoveNextRequest = () => {
    if (currentPictureNumber >= 251.4) {
      return;
    } else {
      const newCurrentPictureNumber =
        Math.round((currentPictureNumber + 0.2) * 10) / 10;
      const newNextPictureNumber =
        Math.round((currentPictureNumber + 0.4) * 10) / 10;
      const [meter, centimeter] = newCurrentPictureNumber.toFixed(1).split(".");
      const [nextMeter, nextCentimeter] = newNextPictureNumber
        .toFixed(1)
        .split(".");
      setCurrentPictureNumber(newCurrentPictureNumber);
      setCurrentPictureName(`${meter}_${centimeter}00.jpg`);
      setCurrentPictureNextName(`${nextMeter}_${nextCentimeter}00`);
      setCurrentPictureTitle(
        `จำลองภาพ 3 มิติที่ระดับน้ำ ${meter}.${centimeter} ม.รทก. (ระดับน้ำห่างกันทุก 20 ซม.) | ปริมาณน้ำในอ่าง ${calculateWithHeight(
          newCurrentPictureNumber
        ).toLocaleString(undefined, { maximumFractionDigits: 0 })} ลบ.ม`
      );
    }
  };

  const calculateWithHeight = (height) => {
    if (isNull(height)) {
      return "not a number";
    }
    var rounded = Math.round(height * 100) / 100;
    let closestVolume = null;
    let closestMargin = 100;
    heightMeasurementJSON.forEach((row) => {
      let currentMargin = Math.abs(rounded - row.MSL_Height);
      if (currentMargin < closestMargin) {
        closestMargin = currentMargin;
        closestVolume = row.volume;
      }
    });
    return closestVolume;
  };

  return (
    <>
      <Box className={classes.body}>
        <Box className={classes.layout}>
          <Grid
            container
            direction="column"
            spacing={3}
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item>
              <Box className={classes.boxNav}>
                <Box display="flex" flexWrap="wrap" alignItems="center">
                  <Box>
                    <Box display="flex">
                      <Img
                        src={`${initPath}images/theme/logo/logo-mhesi.png`}
                        className={classes.logo}
                        alt="demo-site"
                      />
                      <Img
                        src={`${initPath}images/theme/logo/logo-hii.png`}
                        className={classes.logo}
                        alt="demo-site"
                        style={{ marginLeft: 10 }}
                      />
                    </Box>
                  </Box>
                  <Box flexGrow={1}>
                    <Box display="block" ml={1}>
                      <Typography
                        variant="h6"
                        className={classes.projectName}
                        display="block"
                      >
                        ระบบเทคโนโลยีและนวัตกรรมตรวจวัดระดับน้ำอัตโนมัติ
                      </Typography>
                      <Typography
                        variant="h6"
                        className={classes.subProjectName}
                        display="block"
                      >
                        อ่างเก็บน้ำหัวยแม่ปะยาง ต.สรอย อ.วังชิ้น จ.แพร่
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Button className={classes.btn} href="#data">
                      <Img
                        src={`${initPath}images/demo_site_tele/icon-wl.png`}
                        className={classes.icon}
                        alt="demo-site"
                      />
                      ข้อมูลปริมาณน้ำ
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" justifyContent="flex-end">
                <Box
                  sx={{
                    width: "300px",
                    height: "200px",
                    overflow: "hidden",
                    border: "1px solid #ccc",
                  }}
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4357.415987768885!2d99.41494360317402!3d17.772264371739084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30deb323fe91132b%3A0x44f84d33adbe03a7!2z4Lit4LmI4Liy4LiH4LmA4LiB4LmH4Lia4LiZ4LmJ4Liz4Lir4LmJ4Lin4Lii4LmB4Lih4LmI4Lib4Liw4Lii4Liy4LiH!5e1!3m2!1sen!2sth!4v1731999889746!5m2!1sen!2sth"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ background: "#fff", marginTop: 10, padding: 20 }}
          >
            <Grid item xs={12}>
              <Box display="flex">
                <Typography
                  variant="h6"
                  style={{ fontSize: "2.2em", color: "#18a0fb" }}
                >
                  3 เทคโนโลยี
                  <span style={{ color: "#D1D1D1" }}>
                    และเครื่องมือการตรวจวัด
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className={classes.boxItem}>
                <a
                  href="https://www.hii.or.th/งานของเรา/งานวิจัยและพัฒนา/2019/10/02/ระบบโทรมาตรคืออะไร/"
                  target="_blank"
                >
                  <Img
                    src={`${initPath}images/demo_site_tele/img-tele.jpg`}
                    className={classes.itemLink}
                    alt="demo-site"
                  />
                  <Box p={4}>
                    <Typography variant="h4">
                      สถานีโทรมาตรตรวจวัดระดับน้ำอัตโนมัติ
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className={classes.boxItem}>
                <a
                  href="https://www.hii.or.th/research_development/%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%AA%E0%B8%B3%E0%B8%A3%E0%B8%A7%E0%B8%88%E0%B8%A0%E0%B8%B9%E0%B8%A1%E0%B8%B4%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8-mobile-mapping-system-mms/"
                  target="_blank"
                >
                  <Img
                    src={`${initPath}images/demo_site_tele/USV.jpg`}
                    className={classes.itemLink}
                    alt="demo-site"
                  />
                  <Box p={4}>
                    <Typography variant="h4">
                      เรือสำรวจความลึกอัตโนมัติแบบหลายความถี่ (Unmanned Surface
                      Vessels: USV)
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className={classes.boxItem}>
                <a
                  href="https://www.hii.or.th/research_development/%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%AA%E0%B8%B3%E0%B8%A3%E0%B8%A7%E0%B8%88%E0%B8%A0%E0%B8%B9%E0%B8%A1%E0%B8%B4%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8-mobile-mapping-system-mms/"
                  target="_blank"
                >
                  <Img
                    src={`${initPath}images/demo_site_tele/LiDAR.png`}
                    className={classes.itemLink}
                    alt="demo-site"
                  />
                  <Box p={4}>
                    <Typography variant="h4">
                      ระบบจัดทำแผนที่ 3 มิติ ด้วยเทคโนโลยี Drone LiDAR
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ background: "#F5F5F5", padding: 20, paddingBottom: 30 }}
          >
            <Grid id="data" item xs={12}>
              <Box mb={1} display="flex" alignItems="center">
                <Img
                  src={`${initPath}images/demo_site_tele/icon-wl-b.png`}
                  className={classes.icon}
                  alt="demo-site"
                />
                <Typography variant="h4" className={classes.title}>
                  กราฟข้อมูลปริมาณน้ำ
                  <span className={classes.subTitle}>
                    วันที่{" "}
                    {moment(startDate)
                      .locale("th")
                      .format("DD/MM/YYYY HH:mm น.")}{" "}
                    ถึง{" "}
                    {moment(endDate).locale("th").format("DD/MM/YYYY HH:mm น.")}{" "}
                  </span>
                </Typography>
              </Box>
              <Box p={4} style={{ background: "#fff" }}>
                <DemoSiteChart
                  setCurrentDataFunc={setCurrentData}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ background: "#fff", padding: 20 }}
          >
            <Grid item xs={12}>
              <Box mb={1} display="flex" alignItems="center">
                <Img
                  src={`${initPath}images/demo_site_tele/icon-wl-b.png`}
                  className={classes.icon}
                  alt="demo-site"
                />
                <Typography variant="h4" className={classes.title}>
                  ตารางข้อมูลปริมาณน้ำ
                  <span className={classes.subTitle}>
                    วันที่{" "}
                    {moment(startDate)
                      .locale("th")
                      .format("DD/MM/YYYY HH:mm น.")}{" "}
                    ถึง{" "}
                    {moment(endDate).locale("th").format("DD/MM/YYYY HH:mm น.")}{" "}
                  </span>
                </Typography>
              </Box>
              <DemoSiteTable
                currentData={currentData}
                setCurrentPicNameFunc={setCurrentPicName}
                setLatestDataPointFunc={setLatestDataPoint}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ background: "#F5F5F5", padding: 20 }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.titleItem}>
                ภาพสามมิติจำลองปริมาณน้ำในอ่างจากสถานีโทรมาตร
              </Typography>
              <Box border={1}>
                <Typography
                  variant="h5"
                  style={{ fontSize: "1.2em", fontWeight: 300, marginLeft: 10 }}
                >
                  ข้อมูลล่าสุดวันที่{" "}
                  {moment(latestDataPoint.hii.datetime)
                    .locale("th")
                    .format("DD/MM/YYYY HH:mm น.")}{" "}
                  | ระดับน้ำในอ่าง <strong>{latestDataPoint.hii.height}</strong>{" "}
                  ม.รทก
                </Typography>
              </Box>
              <Img
                src={`${initPath}images/demo_site_tele/dam/${currentPicName.hii}.jpg`}
                className={classes.bannerLink}
                alt="3d"
                onClick={() => {
                  setLightboxIsOpen2(true);
                  setCurrentPictureName(currentPicName.hii + ".jpg");
                  setCurrentPictureTitle(
                    `ภาพสามมิติจำลองปริมาณน้ำในอ่างจากสถานีโทรมาตร ${moment(
                      latestDataPoint.hii.datetime
                    )
                      .locale("th")
                      .format("DD/MM/YYYY HH:mm น.")} | ระดับน้ำในอ่าง ${
                      latestDataPoint.hii.height
                    } ม.รทก | ปริมาณน้ำในอ่าง ${latestDataPoint.hii.volume.toLocaleString()} ลบ.ม`
                  );
                }}
              />
              <Typography variant="h5" className={classes.subTitleImg}>
                ปริมาณน้ำในอ่าง{" "}
                <strong>
                  {" "}
                  {latestDataPoint.hii.volume
                    ? latestDataPoint.hii.volume.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })
                    : "no data"}
                </strong>{" "}
                ลบ.ม
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                onClick={() => {
                  setLightboxIsOpen1(true);
                  setCurrentPictureTitle(
                    `จำลองภาพ 3 มิติที่ระดับน้ำ ${currentPictureNumber.toFixed(
                      1
                    )} ม.รทก. (ระดับน้ำห่างกันทุก 20 ซม.) | ปริมาณน้ำในอ่าง ${calculateWithHeight(
                      currentPictureNumber
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })} ลบ.ม`
                  );
                }}
              >
                จำลองภาพ 3 มิติ ระดับน้ำห่างกันทุก 20 ซม.
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                onClick={() => {
                  setLightboxIsOpen1(true);
                  setCurrentPictureTitle(`หน้าตัดอ่างเก็บน้ำห้วยแม่ปะยาง `);
                  setCurrentPictureName("../crossSection.png");
                }}
              >
                หน้าตัดอ่างเก็บน้ำห้วยแม่ปะยาง
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ background: "#fff", padding: 20 }}
          >
            <Grid item xs={12}>
              <Box mb={1} display="flex" alignItems="center">
                <Img
                  src={`${initPath}images/demo_site/icon-wl-b.png`}
                  className={classes.icon}
                  alt="demo-site"
                />
                <Typography variant="h4" className={classes.title}>
                  กราฟความสัมพันธ์
                  <span className={classes.subTitle}>
                    ระหว่างปริมาณน้ำกับระดับน้ำ
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} direction="column">
              <Box mb={1} justifyItems="center">
                <Typography>เรือสำรวจความลึกอัตโนมัติ</Typography>
                <HiiFixedGraph></HiiFixedGraph>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{
              background: "#424242",
              padding: 15,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <Grid item xs={12} md="auto">
              <Img
                src={`${initPath}images/demo_site_tele/logo-hii-th.png`}
                className={classes.logoFooter}
                alt="3d"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ color: "#fff" }}>
              <Typography variant="h6">
                เลขที่ 901 ถนนงามวงศ์วาน แขวงลาดยาว{" "}
              </Typography>
              <Typography variant="h6">
                เขตจตุจักร กรุงเทพมหานคร 10900
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ color: "#fff" }}>
              <Typography variant="h6">โทรศัพท์ : 0-2158-0901</Typography>
              <Typography variant="h6">แฟกซ์ : 0-2158-0910 </Typography>
              <Typography variant="h6">
                อีเมล์ :info_thaiwater@hii.or.th
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            style={{ background: "#212121" }}
          >
            <Grid item xs="auto" style={{ color: "#9E9E9E" }}>
              Copyright © 2024 Hydro - Informatics Institute,All rights
              reserved.
            </Grid>
            <Grid item xs="auto">
              <Box display="flex">
                <Img
                  src={`${initPath}images/demo_site_tele/youtube-logo.png`}
                  className={classes.icon}
                  alt="demo-site"
                />
                <Img
                  src={`${initPath}images/demo_site_tele/fb-hii.png`}
                  className={classes.icon}
                  alt="demo-site"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {lightboxIsOpen2 && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: "1000000" } }}
          mainSrc={`${initPath}images/demo_site_tele/dam/${currentPictureName}`}
          onCloseRequest={() => setLightboxIsOpen2(false)}
          imageTitle={currentPictureTitle}
        />
      )}
      {lightboxIsOpen1 && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: "1000000" } }}
          mainSrc={`${initPath}images/demo_site_tele/dam/${currentPictureName}`}
          nextSrc={`${initPath}images/demo_site_tele/dam/${currentPicturePrevName}.jpg`}
          prevSrc={`${initPath}images/demo_site_tele/dam/${currentPictureNextName}.jpg`}
          onCloseRequest={() => setLightboxIsOpen1(false)}
          imageTitle={currentPictureTitle}
          onMovePrevRequest={() => handleMovePrevRequest()}
          onMoveNextRequest={() => handleMoveNextRequest()}
        />
      )}
    </>
  );
}
