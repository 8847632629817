import moment from 'moment';
import MomentUtils from "@date-io/moment";
import 'moment/locale/th';
moment.locale("th"); // ตั้งค่าให้เป็นภาษาไทย
const momentUtils = new MomentUtils({ locale: "th" });

const apiKey = 'AIzaSyCJTxB2Zoq2wz-bv4k1qS7xG-74aSsnBMU';
const apiKeyGoogle = 'report';
const APP_ENV = process.env.NODE_ENV;
export const processDataAndFilterLatest = async (data) => {

  const processedData = data.files.map(item => {
    const [date, index, description, dateStart, dateEnd] = item.name.replace(/\.(pdf|png|jpg|jpeg|docx)$/i, '')  // Remove specified extensions
      .split('_');;
    const thaiDate = moment(date, 'MMDD')
      .locale('th')
      .add(543, 'years')
      .format('D MMMM YYYY');
    const shortDate = momentUtils.format(moment(thaiDate, "DD MMMM YYYY"), "D MMM");
      
    const commonFields = {
      date,
      index,
      title: description, 
      datethai: thaiDate,
      shortDate, 
      dateStart,
      dateEnd
    };
    return {
      ...item,
      ...commonFields,
      url: item.webViewLink,
    };
  });

  const currentYear = new Date().getFullYear();

  const sortFilesByDate = (files) => {
    return files.sort((a, b) => {
      // Convert date strings to Date objects
      const aDate = new Date(currentYear, parseInt(a.date.substring(0, 2), 10) - 1, parseInt(a.date.substring(2, 4), 10));
      const bDate = new Date(currentYear, parseInt(b.date.substring(0, 2), 10) - 1, parseInt(b.date.substring(2, 4), 10));

      const dateComparison = bDate - aDate;

      if (dateComparison === 0) {
        return b.index - a.index; // Sort indices in descending order
      }

      return dateComparison; // Sort by date in descending order
    });
  };

  // Sort the processed data by date
  const sortedFiles = sortFilesByDate(processedData);

  // Return the sorted data
  return sortedFiles;
};

export const fetchFiles = async (currentYear, type, pageToken = null) => {
  try {
    const param = type.find(v => v.yearEng === currentYear);

    if (!param) return null;

    // let url = `https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents and mimeType contains 'image/'` +
    //   `&fields=nextPageToken,files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)` +
    //   `&key=${apiKey}` +
    //   `&orderBy=createdTime desc`;

    // if (pageToken) {
    //   url += `&pageToken=${pageToken}`;
    // }

    let url = '';
    if (APP_ENV === 'local') {
      url = `https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents` +
        `&key=${apiKey}` +
        `&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
    } else {
      url = `${process.env.MIX_APP_URL}proxy/api-report.php?file=https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents` +
        `&key=${apiKeyGoogle}` +
        `&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error(`Error fetching files for id ${id}:`, error);
    return null;
  }
}
export const fetchOneFile = async (id, type, pageSize, currentYear) => {
  try {
    // const param = type.find(v => v.id === id);
    const param = type.find(v => v.yearEng == currentYear);
    
    if (!param) return null;
    let url = '';
    if (APP_ENV === 'local') {
      url = `https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents` +
        `&key=${apiKey}` +
        `&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`+
        `&orderBy=createdTime desc`;
    } else {
      url = `${process.env.MIX_APP_URL}proxy/api-report.php?file=https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents ` +
        `&key=${apiKeyGoogle}` +
        `&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)` +
        `&orderBy=createdTime desc`;
    }

    if (pageSize) {
      url += `&pageSize=1`;
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error(`Error fetching files for id ${id}:`, error);
    return null;
  }
};
