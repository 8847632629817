/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import tJson from '../../utils/TJson';
import { FaSpinner } from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

class WaterGateChartDischarge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      max: 0,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      provinceId: props.provinceId,
      startDateParam: props.startDate,
      dataStation: [],
      startDate: moment().subtract(3, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getProvince();
    this.getStation();
    this.renderChart();
  }

  getChartOptions() {
    const { t } = this.props;
    this.options = {
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: t('ระดับน้ำ (ม.รทก)'),
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: 'black',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: t('ม.รทก.'),
      },
      series: [
        {
          name: t('ปริมาณน้ำ'),
          data: [],
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getProvince() {
    fetch(`${process.env.MIX_API_URL}public/watergate_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          dataProvince: result.province.data.sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th')),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getStation() {
    const { provinceId } = this.state;
    fetch(`${process.env.MIX_API_URL}frontend/shared/watergate_station?province_code=${provinceId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          dataStation: result.data,
        });
        this.handleChart();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value }, () => {
      this.getStation();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => {
      this.handleChart();
    });
  };

  handleChart = () => {
    const { id, dataStation } = this.state;
    if (dataStation.length > 0) {
      const stationByClick = dataStation.filter(
        d => parseInt(d.station_id, 10) === parseInt(id, 10),
      )[0];
      const stationId = stationByClick === undefined
        ? dataStation[0].station_id : stationByClick.station_id;
      if (stationId !== undefined) {
        this.setState({ id: stationId });
      }
    }
    this.resetChart();
    this.renderChart();
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const {
      id,
      startDate,
      endDate,
      startDateParam,
    } = this.state;

    let startDateValue = startDate;
    let endDateValue = endDate;
    if (startDateParam) {
      const startDateFormat = moment(startDateParam).subtract(3, 'days').locale('th').format('YYYY-MM-DD');
      const endDateFormat = moment(startDateParam).locale('th').format('YYYY-MM-DD');

      startDateValue = startDateFormat;
      endDateValue = endDateFormat;

      this.setState({ startDate: startDateFormat, endDate: endDateFormat });
    }
    fetch(
      `${process.env
        .MIX_API_URL}public/watergate_graph?station_id=${id}&start_date=${startDateValue}&end_date=${endDateValue}`,
    ).then(response => {
      if (response.ok) {
        return response.json();
      }
      return 'Something went wrong.';
    }).then(result => {
      // transform datas
      const dataInSeries = [];
      const dataOutSeries = [];
      const dataIn = result.data[0].data;
      const dataOut = result.data[1].data;
      const groundLevel = result.data[0].data.ground_level;
      const criticallevel = result.data[0].data.critical_level;
      let ts = null;
      let val = null;

      // find max, min
      const max = maxBy(dataIn, 'discharge') === undefined ? 0 : maxBy(dataIn, 'discharge').discharge;
      this.setState({
        max,
      });

      dataIn.map(n => {
        val = parseFloat(n.discharge);
        ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
        dataInSeries.push([ts, val]);
        return dataInSeries;
      });

      // dataOut.map(n => {
      //   val = parseFloat(n.discharge);
      //   ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
      //   dataOutSeries.push([ts, val]);
      //   return dataOutSeries;
      // });

      // set chart options
      const chartOptions = this.getChartOptions();
      chartOptions.series[0].data = dataInSeries;
      // chartOptions.series[1].data = dataOutSeries;
      chartOptions.yAxis.min = groundLevel;

      // plotLines
      if (criticallevel) {
        chartOptions.yAxis.plotLines[0].value = criticallevel;
        chartOptions.yAxis.plotLines[0].label.text = `ค่าระดับแถบเตือนภัยสูงสุด <strong>${parseFloat(criticallevel).toFixed(2)}</strong> ม.รทก.`;
      }

      if (groundLevel) {
        chartOptions.yAxis.plotLines[1].value = groundLevel;
        chartOptions.yAxis.plotLines[1].label.text = `ระดับท้องน้ำ <strong>${parseFloat(groundLevel).toFixed(2)}</strong> ม.รทก.`;
      }

      this.setState({ chartOptions, isLoading: false });
    })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      dataProvince, id, max, provinceId, dataStation, startDate, endDate, today,
      isLoading, error, chartOptions,
    } = this.state;
    const { classes, t } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
            {error}
          </Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      );
    }

    let chart = '';
    let info = '';
    if (max === 0) {
      chart = <Paper className={classes.paper}>{t('ไม่มีข้อมูลในช่วงเวลาที่ท่านเลือก')}</Paper>;
      info = '';
    } else {
      chart = <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {`*${t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}`}
        </div>
      );
    }

    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select value={provinceId} onChange={this.handleProvinceChange}>
                {dataProvince.map(row => (
                  <MenuItem key={row.id} value={row.province_code}>
                    {t(row.province_name.th)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <Select value={id} onChange={this.handleStationChange}>
                {dataStation.map(row => (
                  <MenuItem key={row.station_id} value={row.station_id}>
                    {tJson(row.station_name, 'สถานี')}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {chart}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

WaterGateChartDischarge.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

WaterGateChartDischarge.defaultProps = {
  startDate: null,
};

export default compose(withStyles(styles), withTranslation())(WaterGateChartDischarge);
