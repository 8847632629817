/* eslint-disable prefer-destructuring */
import iconRect from '../widgets/map/iconRect';
import iconCircle from '../widgets/map/iconCircle';

const damColor = [
  {
    label: '',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#bdbdbd',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้ำน้อยวิกฤติ',
    rangeTitle: '<=30',
    statusColor: '#f6c73d',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้ำน้อย',
    rangeTitle: '>30-50',
    statusColor: '#599e5f',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>50-80',
    statusColor: '#1042b5',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'น้ำมาก',
    rangeTitle: '>80-100',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'เกินความจุเก็บกัก',
    rangeTitle: '>100',
    statusColor: '#a52227',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const DamStatusColorMainScreen = (wl, type = 'large') => {
  let color;
  let icon;
  let damIcon = iconRect;

  if (type === 'medium') {
    damIcon = iconCircle;
  }

  if (wl > 100) {
    color = damColor[5];
    icon = damIcon.red;
  } else if (wl >= 81 && wl <= 100) {
    color = damColor[4];
    icon = damIcon.red;
  } else if (wl >= 51 && wl <= 80) {
    color = damColor[3];
    icon = damIcon.blue;
  } else if (wl >= 31 && wl <= 50) {
    color = damColor[2];
    icon = damIcon.green;
  } else if (wl <= 30 && wl >= 0) {
    color = damColor[1];
    icon = damIcon.yellow;
  } else {
    color = damColor[0];
    icon = damIcon.grey;
  }
  return { color, icon };
};

export default DamStatusColorMainScreen;
