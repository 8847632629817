import React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const WlLegend = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        zIndex: 99999,
        left: '1%',
        bottom: '6%',
        // width: '8.5%',
      }}
    >
      <Paper
        style={{
          opacity: '1',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          <Grid container>
            <div style={{
              height: '25px',
              width: '60px',
              backgroundColor: '#FF0000',
            }}
            >
              <b style={{ paddingLeft: '10px', opacity: '1', color: 'white' }}>&gt;100</b>
            </div>
            <b style={{ paddingLeft: '10px', opacity: '1' }}>{t('ล้นตลิ่ง')}</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '25px',
              width: '60px',
              backgroundColor: '#003CFA',
            }}
            >
              <b style={{ paddingLeft: '10px', opacity: '1', color: 'white' }}>&gt;70-100</b>
            </div>
            <b style={{ paddingLeft: '10px', opacity: '1' }}>{t('มาก')}</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '25px',
              width: '60px',
              backgroundColor: '#00B060',
            }}
            >
              <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;30-70</b>
            </div>
            <b style={{ paddingLeft: '10px', opacity: '1' }}>{t('ปกติ')}</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '25px',
              width: '60px',
              backgroundColor: '#FFC000',
            }}
            >
              <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;10-30</b>
            </div>
            <b style={{ paddingLeft: '10px', opacity: '1' }}>{t('น้อย')}</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '25px',
              width: '60px',
              backgroundColor: '#DB802B',
            }}
            >
              <b style={{ paddingLeft: '10px', opacity: '1' }}>&lt;=10</b>
            </div>
            <b style={{ paddingLeft: '10px', opacity: '1' }}>{t('น้อยวิกฤต')}</b>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};

export default WlLegend;
