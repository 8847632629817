import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import heightMeasurementJSON from "../json/heightMeasurement.json";
import { isNull } from "lodash";

export default function PathumFixedGraph() {
  const getChartOptions = () => {
    return {
      title: {
        text: "",
      },
      chart: {
        type: "line",
        zoomType: "xy",
        panning: true,
        panKey: "shift",
      },
      lang: {
        thousandsSep: ",",
      },
      exporting: {
        buttons: {
          contextButton: {
            align: "left",
          },
        },
      },
      time: {
        timezoneOffset: -7 * 60,
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
          connectNulls: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        crosshairs: true,
        formatter: function () {
          const toolTipText = this.points.map((point) => {
            if (point.series.name === "ปริมาตร") {
              return `<div>${point.series.name} ${point.x.toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 0,
                }
              )} ลบ.ม. ที่ระดับน้ำ ${this.y} ม.รทก.</div>`;
            } else {
              return `<div>${point.series.name} ${point.x.toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 0,
                }
              )} ตรม. ที่ระดับน้ำ ${this.y} ม.รทก.</div>`;
            }
          });
          return toolTipText;
        },
      },
      xAxis: [
        {
          title: {
            text: `${"ปริมาณน้ำ (ลบ.ม.)"}`,
          },
        },
        {
          opposite: true,
          reversed: true,
          title: {
            text: `${"พื้นที่ (ตรม.)"}`,
          },
        },
      ],
      yAxis: {
        min: 238,
        max: 247,
        type: "number",
        labels: {
          format: "{value:.2f}",
        },
        title: {
          text: `${"ความสูง (เมตร.รทก.)"}`,
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      legend: {
        enabled: true,
      },
      series: [
        {
          name: "ปริมาตร",
          data: [
            { x: 420091.0, y: 248 },
            { x: 402731.0, y: 247 },
            { x: 380131.0, y: 246 },
            { x: 345755.0, y: 245 },
            { x: 311110.0, y: 244 },
            { x: 275841.0, y: 243 },
            { x: 233353.0, y: 242 },
            { x: 173658.0, y: 241 },
            { x: 106132.0, y: 240 },
            { x: 5691.0, y: 239 },
            { x: 0, y: 238 },
          ],
        },
        {
          name: "พื้นที่",
          data: [
            { x: 117273, y: 248 },
            { x: 87834, y: 247 },
            { x: 70208, y: 246 },
            { x: 56324, y: 245 },
            { x: 46382, y: 244 },
            { x: 38509, y: 243 },
            { x: 30815, y: 242 },
            { x: 21627, y: 241 },
            { x: 12684, y: 240 },
            { x: 623, y: 239 },
            { x: 0, y: 238 },
          ],
          xAxis: 1,
        },
      ],
    };
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={getChartOptions()} />
    </>
  );
}
