import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

// ui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Hidden } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// map
import L from 'leaflet';
import {
  Map, CircleMarker, Polyline, Popup, Tooltip, LayerGroup, TileLayer,
} from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';
import { FaChartLine } from '../../utils/Fontawesome';
// import 'leaflet-polylinedecorator';

// component
import { StormStatusColor } from './StormColor';
import StormChart from './StormChart';

// context
import StormContext from '../../contexts/stormContext';

// styles
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/dist/styles.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import {
  styles,
} from '../../styles/Style';

const useStyles = makeStyles(styles);
// import withLeaflet first
// const PolylineDecorator = withLeaflet(props => {
//   const polyRef = useRef();
//   useEffect(() => {
//     const polyline = polyRef.current.leafletElement; // get native Leaflet polyline
//     const { map } = polyRef.current.props.leaflet; // get native Leaflet map

//     L.polylineDecorator(polyline, {
//       patterns: props.patterns,
//     }).addTo(map);
//   }, []);
//   return <Polyline ref={polyRef} {...props} />;
// });

const StormHistoryMapJtwc = props => {
  const ctx = useContext(StormContext);
  const classes = useStyles();
  const { data } = props;
  const renderCanvas = L.canvas({ padding: 0.5 });

  // const arrow = [
  //   {
  //     offset: '100%',
  //     repeat: 0,
  //     symbol: L.Symbol.arrowHead({
  //       pixelSize: 10,
  //       polygon: false,
  //       pathOptions: {
  //         stroke: true,
  //         // color: '#42424',
  //         // fillOpacity: 0.8,
  //         // weight: 0,
  //       },
  //     }),
  //   },
  // ];

  const handleChart = (meta, d) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟข้อมูลพายุ',
      content: <StormChart meta={meta} datas={d} />,
    };

    ctx.handleModalOpen(params);
  };

  // const eachStormPointData = (meta, datas, totalPoint) => (d, i) => {
  const eachStormPointData = (meta, datas, totalPoint) => (d, i) => {
    if (d.lat && d.long) {
      // marker position
      let line = [];
      const position = [parseFloat(d.lat), parseFloat(d.long)];

      if (d.to_lat && d.to_long) {
        line = [
          [parseFloat(d.lat), parseFloat(d.long)],
          [parseFloat(d.to_lat), parseFloat(d.to_long)],
        ];
      }

      const color = StormStatusColor(d.wind);
      const statusStyle = {
        backgroundColor: color.color.statusColor,
        color: color.color.constrastColor,
      };

      return (
        <CircleMarker
          id={d.id}
          key={`point_${d.id}`}
          center={position}
          radius={i === parseInt(totalPoint - 1, 10) ? 6 : 4}
          color="#424242"
          weight={1}
          fill
          fillColor={d.color}
          fillOpacity={0.75}
        >
          <Polyline
            key={`line_${d.id}`}
            positions={line}
            color={d.color}
            dashArray={meta.attack_thailand === 'true' ? '' : '5, 5'}
            renderer={renderCanvas}
          />
          <Popup autoPan maxWidth="200" autoClose={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center">
                    <Typography variant="h5" style={{ marginBottom: 10 }}>
                      <strong>{`${meta.name}`}</strong>
                      <IconButton aria-label="Chart" onClick={handleChart(meta, datas)}>
                        <FaChartLine fontSize="small" />
                      </IconButton>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none">
                    {`Date: ${d.datetime}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none">
                    {`Latitude: ${d.lat.toFixed(3)}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none">
                    {`Longitude: ${d.long.toFixed(3)}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none">
                    Wind Speed:
                    <span
                      className={classes.status}
                      style={statusStyle}
                    >
                      {` ${d.wind} (kt)`}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none">
                    {'Pressure: '}
                    {d.pressure ? d.pressure : '--'}
                    {' mb'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <strong>
                <Typography variant="h5">
                  {`${meta.name}`}
                </Typography>
                --------------------
                <br />
                {`${d.datetime}`}
                <br />
                Wind Speed:
                <span
                  className={classes.status}
                  style={statusStyle}
                >
                  {` ${d.wind} (kt)`}
                </span>
                <br />
                {'Pressure: '}
                {d.pressure ? d.pressure : '--'}
                {' mb'}
              </strong>
            </Tooltip>
          </Hidden>
        </CircleMarker>
      );
    }
    return false;
  };

  useEffect(() => {
    // console.log(props.activeLayer);
    // map reference
    // map = refMap.current.leafletElement;

    // gesture handling
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  }, [data]);

  return (
    <>
      <Map
        id="map"
        center={[12.9, 105.6]}
        zoom={4}
        style={{ height: '450px' }}
        gestureHandling
        closePopupOnClick={false}
      >
        <FullscreenControl />
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        />
        {data.map(storm => {
          const totalPoint = storm.data.length;
          // check active layers
          // let checked = true;

          // if (activeLayer[storm.metadata.name] !== true) {
          //   checked = false;
          // }
          return (
            <div key={`${storm.metadata.name}-${storm.metadata.code}`}>
              {storm.isChecked && (
                <LayerGroup id={storm.metadata.name}>
                  {storm.data.map(eachStormPointData(storm.metadata, storm.data, totalPoint))}
                </LayerGroup>
              )}
            </div>
          );
        })}
      </Map>
    </>
  );
}

StormHistoryMapJtwc.propTypes = {
  data: PropTypes.array.isRequired,
  // activeLayer: PropTypes.object,
};
export default React.memo(StormHistoryMapJtwc);
