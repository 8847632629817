import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import './style.css';
import L from 'leaflet';
import MapWrf from './mapWrf';
import withWidth from '@material-ui/core/withWidth';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { compose } from 'recompose';

const initPath = process.env.MIX_APP_PATH;

class ContentImageWrf extends Component {
  constructor(props, context) {
    super(props, context);
    this.refMap = createRef();
    this.rainAmphoeRef = createRef();
    this.state = {
      domainData: props.domainData,
      data: props.data,
      index: props.index,
      mediaDateTime: props.mediaDateTime,
      uriImage: '',
      geoJsonAmphoe: null,
      geoJsonProvince: null,
      typeArea: '1',
      widthScreen: props.width
    };
  }

  componentDidMount() {
    // date time for selected image
    const { mediaDateTime } = this.state;

    function initAll() {
      const selectedDate = moment(mediaDateTime);
      const refDate = moment('2022-11-30');
      const dateDiff = selectedDate.diff(refDate, 'days');
      // thailand
      const mySVGThailand = document.getElementById('thailand');
      if (mySVGThailand) {
        mySVGThailand.addEventListener('load', () => {
          const svgDocThailand = mySVGThailand.getSVGDocument();
          if (svgDocThailand) {
            const svgRootThailand = svgDocThailand.querySelector('svg');
            // Position for WRF 3.0
            // svgRootThailand.setAttribute('viewBox', '51 -130 1360 1420');

            // Position for WRF 4.0, since 2022-12-01 thailand and se asia change image dimension
            // console.log(dateDiff);
            if (dateDiff <= 0) {
              svgRootThailand.setAttribute('viewBox', '5 -135 1445 1460');
            } else {
              svgRootThailand.setAttribute('viewBox', '42 -135 1445 1460');
            }
          }
        });
      }

      // se asia
      const mySVGSeAsia = document.getElementById('se-asia');
      if (mySVGSeAsia) {
        mySVGSeAsia.addEventListener('load', () => {
          const svgDocSeAsia = mySVGSeAsia.getSVGDocument();
          if (svgDocSeAsia) {
            const svgRootSeAsia = svgDocSeAsia.querySelector('svg');
            // Position for WRF 3.0
            // svgRootSeAsia.setAttribute('viewBox', '-330 -335 2200 2100');

            // Position for WRF 4.0
            if (dateDiff <= 0) {
              svgRootSeAsia.setAttribute('viewBox', '-210 -820 2900 2900');
            } else {
              svgRootSeAsia.setAttribute('viewBox', '121 -820 2900 2900');
            }
          }
        });
      }

      // asia
      const mySVGAsia = document.getElementById('asia');
      if (mySVGAsia) {
        mySVGAsia.addEventListener('load', () => {
          const svgDocAsia = mySVGAsia.getSVGDocument();
          if (svgDocAsia) {
            const svgRootAsia = svgDocAsia.querySelector('svg');
            // Position for WRF 3.0
            // svgRootAsia.setAttribute('viewBox', '-900 -585 3200 2600');

            // Position for WRF 4.0
            svgRootAsia.setAttribute('viewBox', '-1000 -1120 4500 3600');
          }
        });
      }

      // basin
      const mySVGBasin = document.getElementById('basin');
      if (mySVGBasin) {
        mySVGBasin.addEventListener('load', () => {
          const svgDocBasin = mySVGBasin.getSVGDocument();
          if (svgDocBasin) {
            const svgRootBasin = svgDocBasin.querySelector('svg');
            // Position for WRF 3.0
            // svgRootBasin.setAttribute('viewBox', '-60 -175 1330 1500');

            // Position for WRF 4.0
            if (dateDiff <= 0) {
              svgRootBasin.setAttribute('viewBox', '100 -200 1250 1600');
            } else {
              svgRootBasin.setAttribute('viewBox', '139 -200 1250 1600');
            }
          }
        });
      }
    }

    const fetchGeoJson = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Something went wrong ...');
        }
        return response.json();
      } catch (error) {
        console.error(error);
      }
    };

    fetchGeoJson(`${initPath}json/boundary/amphoe.json`)
      .then((result) => {
        this.setState({ geoJsonAmphoe: result });
        this.resizeLayerGeoJson();
      });

    fetchGeoJson(`${initPath}json/boundary/thailand.json`)
      .then((result) => {
        this.setState({ geoJsonProvince: result });
        this.resizeLayerGeoJson();
      });

    this.matchDataIndex();
    window.onload = initAll();
  }

  resizeLayerGeoJson = () => {
    const { domainData, widthScreen } = this.state;
    const layerThailand = document.getElementsByClassName('leaflet-zoom-animated');
    let className = '';

    switch (domainData) {
      case 'thailand':
        className = widthScreen === 'xs' ? 'leaflet-zoom-animated-thailand-history-mobile' : 'leaflet-zoom-animated-thailand-history';
        break;
      case 'southeast asia':
        className = widthScreen === 'xs' ? 'leaflet-zoom-animated-southeast-asia-history-mobile' : 'leaflet-zoom-animated-southeast-asia-history';
        break;
      case 'asia':
        className = widthScreen === 'xs' ? 'leaflet-zoom-animated-asia-history-mobile' : 'leaflet-zoom-animated-asia-history';
        break;
      default:
        className = '';
        break;
    }

    if (className) {
      for (let i = 0; i < layerThailand.length; i += 1) {
        layerThailand[i].classList.add(className);
      }
    }
  }

  onEachFeature = (feature, layer) => {
    const { PROV_NAM_T, AMPHOE_T } = layer.feature.properties;
    const province = PROV_NAM_T.includes('.') ? PROV_NAM_T.split('.')[1] : PROV_NAM_T;
    const amphoe = AMPHOE_T.includes('.') ? AMPHOE_T.split('.')[1] : AMPHOE_T;
    const popupContent = `<b>อำเภอ: </b>${amphoe} <b>จังหวัด: </b>${province}`;
    layer.on({
      mouseover: this.handleMouseOver,
      mouseout: this.handleMouseOut,
    });
    layer.bindPopup(popupContent);
  };

  handleMouseOver = e => {
    const layer = e.target;
    layer.setStyle({
      weight: 1,
      opacity: 1,
      color: 'red',
      dashArray: '0',
      fillOpacity: 0.5,
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };

  handleMouseOut = e => {
    this.rainAmphoeRef.current.leafletElement.resetStyle(e.layer);
  };

  getRainAmphoeStyle = () => ({
    stroke: true,
    color: 'black',
    weight: 0.5,
    fillColor: '',
    opacity: 0.3,
    fillOpacity: 0,
  })

  matchDataIndex() {
    const { data, index } = this.state;
    this.setState({ uriImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${data[index].media_path}` });
  }

  plusDivs(n) {
    const { data, index } = this.state;

    if (n > 0) {
      const countIndex = index !== (data.length - 1) ? index + 1 : 0;
      this.setState({
        index: countIndex,
        mediaDateTime: data[countIndex].datetime,
        uriImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${data[countIndex].media_path}`,
      });
    }

    if (n < 0) {
      const countIndex = index !== 0 ? index - 1 : (data.length - 1);
      this.setState({
        index: countIndex,
        mediaDateTime: data[countIndex].datetime,
        uriImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${data[countIndex].media_path}`,
      });
    }
  }

  handleMap = (geoJson, uriImage, keyword, mode) => {
    return (
      <MapWrf
        boundary={geoJson}
        uriImage={uriImage}
        keyword={keyword}
        mode={mode}
      />
    );
  }

  handleTypeChange = event => {
    const selectedType = event.target.value;
    this.setState({ typeArea: selectedType }, this.resizeAndForceUpdate);
  }

  resizeAndForceUpdate = () => {
    setTimeout(() => {
      this.resizeLayerGeoJson();
      this.forceUpdate();
    });
  }

  render() {
    const {
      domainData, uriImage, mediaDateTime, geoJsonProvince, typeArea, geoJsonAmphoe,
    } = this.state;
    const imageBounds = [[12.0, 100.0], [15.0, 102.0]];

    return (
      <>
        <Box display="flex" justifyContent="center">
          <Box>
            {domainData === 'thailand basin' && (
              <Grid container spacing={0}>
                <Grid item xs>
                  <Grid style={{ textAlign: 'center' }}>
                    {` ${moment(mediaDateTime).format('ll')} `}
                  </Grid>
                  <Grid container spacing={0} direction="row" alignItems="center">
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                    </Grid>
                    <Grid item xs>
                      <div className="svg-container" style={{ maxWidth: '600px' }}>
                        <img src={uriImage} width="100%" alt="forecast" />
                        <embed
                          type="image/svg+xml"
                          id="basin"
                          src={`${initPath}svg/thailand.svg`}
                          className="svg svg-content svg-transform"
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {domainData === 'asia' && (
              <Grid container spacing={0}>
                <Grid item xs>
                  <Grid style={{ textAlign: 'center' }}>
                    {` ${moment(mediaDateTime).format('ll')} `}
                  </Grid>
                  <Grid container spacing={0} direction="row" alignItems="center">
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                    </Grid>
                    <Grid item xs>
                      <div>
                        {geoJsonProvince && this.handleMap(geoJsonProvince, uriImage, 'asia-history', 'province')}
                      </div>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {domainData === 'southeast asia' && (
              <Grid container spacing={0}>
                <Grid item xs>
                  <Grid style={{ textAlign: 'center' }}>
                    {` ${moment(mediaDateTime).format('ll')} `}
                  </Grid>
                  <Grid container spacing={0} direction="row" alignItems="center">
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                    </Grid>
                    <Grid item xs>
                      <div>
                        {geoJsonProvince && this.handleMap(geoJsonProvince, uriImage, 'southeast asia-history', 'province')}
                      </div>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {domainData === 'thailand' && (
              <Grid>
                <Fragment>
                  <FormControl>
                    <InputLabel shrink htmlFor="station-label-placeholder">
                      ประเภท
                    </InputLabel>
                    <NativeSelect value={typeArea} onChange={this.handleTypeChange}>
                      <option value="1">อำเภอ</option>
                      <option value="2">จังหวัด</option>
                    </NativeSelect>
                  </FormControl>
                  <Grid style={{ textAlign: 'center' }}>
                    {`ข้อมูลวันที่ ${moment(mediaDateTime).format('ll')} `}
                  </Grid>
                  {typeArea === '1' && (
                    <Grid container spacing={0} direction="row" alignItems="center">
                      <Grid item>
                        <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                      </Grid>
                      <Grid item xs>
                        <div>
                          {geoJsonAmphoe && this.handleMap(geoJsonAmphoe, uriImage, 'thailand-history', 'amphoe')}
                        </div>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                      </Grid>
                    </Grid>
                  )}
                  {typeArea === '2' && (
                    <Grid container spacing={0} direction="row" alignItems="center">
                      <Grid container spacing={0} direction="row" alignItems="center">
                        <Grid item>
                          <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                        </Grid>
                        <Grid item xs>
                          <div>
                            {geoJsonProvince && this.handleMap(geoJsonProvince, uriImage, 'thailand-history', 'province')}
                          </div>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              </Grid>
            )}
          </Box>
        </Box>
      </>
    );
  }
}

ContentImageWrf.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  domainData: PropTypes.string.isRequired,
  mediaDateTime: PropTypes.string.isRequired,
};

export default compose(withWidth())(ContentImageWrf);
