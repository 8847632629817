import React from 'react';
import { useParams } from 'react-router-dom';
import WlChart from '../../wl/WlChart';

const wlChart = () => {
  const { stationId, provinceId } = useParams();
  return (
    <>
      <WlChart id={parseInt(stationId, 10)} provinceId={provinceId} />
    </>
  );
};

export default wlChart;
