import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _, { orderBy, find } from 'lodash';
import moment from 'moment';

// ui
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import DamChart from './DamChart';
import { damsRid, damsEgat } from '../../store/dam';

import { styles } from '../../styles/Style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());

class DamDailyChart extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      // มีการเรียกกราฟจากหน้าแรกโดยส่งค่ามาจาก props
      // selectedType: 'dam_storage',
      selectedType: props.type,
      selectedYear: [`${currentYear}`, `${currentYear - 1}`, `${currentYear - 2}`],
      selectedDam: '',
      selectedDamSize: 'large',
      selectedDamMultiple: [`${props.id}`],
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props.id + '-' + state.selectedDam);
    if (state.selectedDam === '') {
      return {
        selectedDam: props.id,
      };
      // console.log(props.id)
    }

    return null;
  }

  handleChange = event => {
    if (event.target.name === 'selectedYear') {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      this.setState({ selectedYear: value });
    } else if (event.target.name === 'selectedDamMultiple') {
      const { options } = event.target;

      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      this.setState({ selectedDamMultiple: value });
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }
    // console.log(event.target);
    // limit total years select , disabled this feature by user requirement
    // if (event.target.name === 'selectedYear') {
    //   if (event.target.value.length <= 8) {
    //     this.setState({ [event.target.name]: event.target.value });
    //   }
    // } else {
    //   this.setState({ [event.target.name]: event.target.value });
    // }

    // this.setState({ [event.target.name]: event.target.value });
  };

  handleChart = () => {
    const { selectedYear } = this.state;
    // alert(selectedType + ' - ' + selectedYear + ' - ' + selectedDam);
    if (_.isEmpty(selectedYear)) {
      this.setState({ YearError: true });
    } else {
      this.setState({ YearError: false });
      this.child.current.resetChart();
      this.child.current.renderChart();
    }
  }

  render() {
    const {
      classes,
      agencyId,
      t,
      i18n,
    } = this.props;
    const {
      selectedType,
      selectedYear,
      selectedDam,
      selectedDamSize,
      selectedDamMultiple,
      YearError,
    } = this.state;

    // rid year start at 1994, egat start at 1964
    let dams = damsRid;
    let years = yearsRange(currentYear, 1993);
    if (agencyId === 8) {
      dams = damsEgat;
      years = yearsRange(currentYear, 1963);
    }

    return (
      <div>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={10} lg={10} xl={10}>
              <Box py={{ xs: 0, sm: 2 }} className={classes.boxGraphDam}>
                <Box className={classes.graphDam}>
                  <DamChart
                    ref={this.child}
                    type={selectedType}
                    id={selectedDam}
                    year={selectedYear}
                    size={selectedDamSize}
                    selectedDamMultiple={selectedDamMultiple}
                    name={find(dams, ['id', selectedDam]).name}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl>
                    <InputLabel htmlFor="type">{t('ประเภทข้อมูล')}</InputLabel>
                    <Select
                      value={selectedType}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedType',
                        id: 'type',
                      }}
                    >
                      <MenuItem value="dam_storage">{t('ปริมาณน้ำเก็บกัก')}</MenuItem>
                      <MenuItem value="dam_uses_water">{t('ปริมาณน้ำใช้การ')}</MenuItem>
                      <MenuItem value="dam_inflow">{t('ปริมาณน้ำไหลลง')}</MenuItem>
                      <MenuItem value="dam_released">{t('ปริมาณน้ำระบาย')}</MenuItem>
                      <MenuItem value="dam_inflow_acc">{t('ปริมาณน้ำไหลเข้าสะสม')}</MenuItem>
                      <MenuItem value="dam_released_acc">{t('ปริมาณน้ำระบายสะสม')}</MenuItem>
                      <MenuItem value="dam_level">{t('ระดับน้ำกักเก็บ')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              {/* <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="year">{t('อ่างเก็บน้ำ')}</InputLabel>
                    <Select
                      value={selectedDam}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedDam',
                        id: 'dam_id',
                      }}
                    >
                      {orderBy(dams, ['name'], ['asc']).map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {t(item.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box> */}
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="dam_id">{t('อ่างเก็บน้ำ')}</InputLabel>
                    <Select
                      multiple
                      native
                      value={selectedDamMultiple}
                      onChange={this.handleChange}
                      input={<Input id="dam_id" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                      inputProps={{
                        name: 'selectedDamMultiple',
                        id: 'dam_id',
                      }}
                    >
                      {orderBy(dams, ['name'], ['asc']).map(item => (
                        <option key={item.id} value={item.id}>
                          {t(item.name)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl} error={YearError}>
                    <InputLabel htmlFor="year">{t('ปี')}</InputLabel>
                    <Select
                      multiple
                      native
                      value={selectedYear}
                      onChange={this.handleChange}
                      input={<Input id="year" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                      inputProps={{
                        name: 'selectedYear',
                        id: 'year',
                      }}
                    >
                      {years.map(year => (
                        <option key={year} value={year} defaultValue={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                          {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <Button variant="outlined" color="primary" size="small" title={t('แสดงกราฟ')} onClick={this.handleChart}>
                      {t('แสดง')}
                    </Button>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classes.info}>
            *
            {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
          </Box>
        </Grid>
      </div>
    );
  }
}

DamDailyChart.defaultProps = {
  type: 'dam_storage',
};

DamDailyChart.propTypes = {
  id: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  agencyId: PropTypes.number.isRequired,
  type: PropTypes.string,
  i18n: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DamDailyChart);
