import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';

import {
  orderBy,
} from 'lodash';
// width
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import moment from 'moment';
import { Helmet } from 'react-helmet';
import {
  FaSpinner,
} from '../../utils/Fontawesome';

import BmaWaterMapNew from './BmaWaterMap';
import {
  styles,
} from '../../styles/Style';
import BmaContext from '../../contexts/BmaContext';

const initPath = process.env.MIX_APP_PATH;
class BmaWater extends Component {
  _mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      provinceBkkCode: '10',
      metropolitanArea: ['10', '11', '12', '13', '73', '74'],
      stylePath: 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
      // map
      map: null,
      zoom: 11,
      location: [13.72, 100.6],
      currentPos: null,
      markers: [],
      // data
      dataRainfall24h: [],
      dataWaterLevel: [],
      dataWaterCanal: [],
      dataWaterFlow: [],
      dataWaterFloodRoad: [],
      dataTwitterNews: [],
      dataRainGeotiff: [],

      dataRadarBkk: [],
      metaDataRadarBkk: {},

      geojsonProvinceBkk: [],
      geojsonProvince: [],

      getGeoJsonAmphoe: [],
      getGeoJsonRainAmphoe: [],
      // modal

      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
    };
  }

  componentDidMount() {
    this._mounted = true;
    // get data from ws
    this.getData();
    this.getDataTwitter();
    this.getDataRadarBkk();
    this.getRainfallGeotiffData();
    this.fetchRainAmphoe();
    setInterval(() => {
      this.getDataRadarBkk();
    }, 360000);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  resetModal = () => {
    const { modalDataReset, modalData } = this.state;
    this.setState({ modalData: modalDataReset });
    modalData.open = false;
    this.setState({ modalData });
  }

  handleModalOpen = params => {
    const { modalData } = this.state;
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '80%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
      zIndex: 9999999,
    };
  }

  getData = () => {
    const { metropolitanArea } = this.state;
    Promise.all([
      // fetch(`${process.env.MIX_API_URL}public/waterlevel_load`, { cache: 'no-store' }),
      fetch(`${process.env.MIX_API_URL}public/waterlevel?province_code=10,11,12,13,73,74`, { cache: 'no-store' }),
      fetch(`${process.env.MIX_API_URL}public/rain_24h?province_code=10,11,12,13,73,74`, { cache: 'no-store' }),
      fetch(`${process.env.MIX_API_URL}public/canal_waterlevel`, { cache: 'no-store' }),
      fetch(`${process.env.MIX_API_URL}public/flow`, { cache: 'no-store' }),
      fetch(`${process.env.MIX_API_URL}public/flood_road`, { cache: 'no-store' }),
    ])
      .then(([res1, res2, res3, res4, res5]) => Promise.all([
        res1.json(), res2.json(), res3.json(), res4.json(), res5.json()]))
      .then(([WaterleveLoad, Rain24h, WaterlevelCanal, WarterFlow,
        WarterFloodRoad]) => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        const WaterLevel = orderBy(WaterleveLoad.data, {
          storage_percent: Number,
        })
          .filter(d =>
          // const time = new Date(d.waterlevel_datetime.replace(/-/g, '/')).getTime();
          // eslint-disable-next-line implicit-arrow-linebreak
            (
              // (last24hrs < time) &&
              (d.waterlevel_msl != null)
            ))
          .filter(d => d.storage_percent != null);

        const rainfall24Sort = orderBy(Rain24h.data, ['rain_24h'], ['desc']);
        const Rainfall24h = rainfall24Sort.filter(d => (metropolitanArea.includes(
          d.geocode.province_code,
        )));

        // 2023-04-05 ยกเลิกเงื่อนไขการแสดงผลเพื่อให้มีข้อมูลตำแหน่งสถานีโทรมาตรแสดง
        // const Rainfall24h = rainfall24Sort.filter(d => (metropolitanArea.includes(
        //   d.geocode.province_code,
        // )) && (d.rain_24h >= 35));

        // const Rainfall24h = rainfall24Sort.filter(d => {
        //   const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
        //   return (
        //     last24hrs < time && d.rain_24h > 0 && d.rain_24h >= 60
        //     metropolitanArea.includes(d.geocode.province_code));
        // });

        const Canal = WaterlevelCanal.data.filter(d => {
          const time = new Date(d.canal_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.canal_value != null
            && metropolitanArea.includes(d.geocode.province_code));
        });

        const Flow = WarterFlow.data.filter(d => (
          // last24hrs < time &&
          d.flow_datetime != null
          && metropolitanArea.includes(d.geocode.province_code)));

        WaterLevel.map(ele => {
          // New properties to be added
          const newPropsObj = {
            lat: ele.station.tele_station_lat,
            long: ele.station.tele_station_long,
            station_name: ele.station.tele_station_name.th,
            wl_out: 0, // ระดับน้ำข้างนอก
          };
          // Assign new properties and return
          return Object.assign(ele, newPropsObj);
        });

        Canal.map(ele => {
          // New properties to be added
          const newPropsObj = {
            waterlevel_datetime: ele.canal_datetime,
            waterlevel_msl: ele.canal_value,
            station_type: 'canal',
            lat: ele.station.canal_lat,
            long: ele.station.canal_long,
            station_name: ele.station.canal_name.th,
            wl_out: ele.canal_out, // ระดับน้ำข้างนอก
            warning_level: ele.station.warning_level,
            critical_level: ele.station.critical_level,
          };
          // Assign new properties and return
          return Object.assign(ele, newPropsObj);
        });

        const teleXcanal = [...WaterLevel, ...Canal];

        const FloodRoad = WarterFloodRoad.data.filter(d => {
          const time = new Date(d.floodroad_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.floodroad_value != null
            && metropolitanArea.includes(d.geocode.province_code));
        });

        this.setState({
          dataRainfall24h: Rainfall24h,
          dataWaterLevel: teleXcanal,
          dataWaterCanal: Canal,
          dataWaterFloodRoad: FloodRoad,
          dataWaterFlow: Flow,
        });
      })
      .catch(error => console.log(error));
  }

  getRainfallGeotiffData = () => {
    const { width } = this.props;

    fetch('https://api.hii.or.th/tiservice/v1/ws/MBtOTp6IUXbjaCxhQoFQNrFgZUCzNgbo/isohyet/daily/latest/province/10', { cache: 'no-store' })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const rain = result.geotiff;
        const arr = [];
        if (rain) {
          for (const key in rain) {
            const day = key.replace(/[^0-9]/g, '');
            if (isWidthDown('xs', width)) {
              const obj = {
                rain_day: `${day} วัน`,
                pic_geotiff: rain[key],
              };
              arr.push(obj);
            } else {
              const obj = {
                rain_day: `ฝนสะสม ${day} วัน`,
                pic_geotiff: rain[key],
              };
              arr.push(obj);
            }
          }
        }
        this.setState({ dataRainGeotiff: arr });
      })
      .catch(error => console.log(error));
  }

  getDataTwitter = () => {
    const { metropolitanArea } = this.state;
    fetch(`./proxy/hims.php?file=${process.env.MIX_HYDRO_HIMS_URL}twitter/latestData?token=${process.env.MIX_HYDRO_HIMS_TOKEN}`, { cache: 'no-store' })
      .then(res => res.json()).then(twitter => {
        const TwitterMetropolitanArea = twitter.filter(t => {
          let result = '';
          if (t.geocode.toString().length === 6) {
            result = t.geocode.toString().substr(0, 2);
          }

          return (metropolitanArea.includes(result));
        });
        this.setState({ dataTwitterNews: TwitterMetropolitanArea });
      }).catch(error => console.log('twitter ', error));
  }

  getDataRadarBkk = () => {
    fetch(`./proxy/hims.php?file=${process.env.MIX_HYDRO_HIMS_URL}urban/data?token=${process.env.MIX_HYDRO_HIMS_TOKEN}`, { cache: 'no-store' })
      .then(res => res.json()).then(RadarBkk => {
        const step = Math.floor(100 / RadarBkk.data.filter(dat => dat.status === true).length);
        const { width } = this.props;
        let StepLabelCalculate = 1;
        if (width === 'xs') {
          StepLabelCalculate = 6;
        }
        if (width === 'md' || width === 'sm') {
          StepLabelCalculate = 4;
        }
        const RadarBkkSlide = RadarBkk.data.filter(dat => dat.status === true).map((ele, i) => {
          const varLabel = (Number.isInteger(i / StepLabelCalculate)) ? moment(ele.time).format('HH:mm') : '';

          // New properties to be added
          const newPropsObj = {
            value: step * i,
            label: varLabel,
          };
          // Assign new properties and return
          return Object.assign(ele, newPropsObj);
        });

        this.setState({
          dataRadarBkk: RadarBkkSlide,
          metaDataRadarBkk: RadarBkk,
        });
        // console.log(RadarBkk);
      }).catch(error => console.log('radar bkk ', error));
  }

  fetchRainAmphoe = () => {
    Promise.all([
      fetch(`${initPath}json/boundary/bkk_amphoe.json`, { cache: 'no-store' }),
      fetch(`${process.env.MIX_LIVE_URL}/product/latest/rain/rain_amphoe.json`, { cache: 'no-store' }),
    ])
      .then((
        [
          res1,
          res2,
        ],
      ) => Promise.all(
        [
          res1.json(),
          res2.json(),
        ],
      ))
      .then((
        [
          amphoeJson,
          rainAmpoeJson,
        ],
      ) => {
        const amjson = amphoeJson.features.filter(d => d.properties.PROV_NAM_T === 'กรุงเทพมหานคร');
        this.setState({
          getGeoJsonAmphoe: amjson,
          getGeoJsonRainAmphoe: rainAmpoeJson,
        });
      })
      .catch(error => error);
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading, error, dataRainfall24h, modalData, stylePath,
    } = this.state;

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Helmet>
          <link rel="preconnect" href="https://live1.hii.or.th" />
          <link rel="preconnect" href="https://fews2.hii.or.th" />
        </Helmet>
        <BmaContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <link rel="stylesheet" type="text/css" href={stylePath} />
          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      สถานการณ์น้ำใน กทม
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} width="100%">
                  <BmaWaterMapNew
                    {...this.state}
                    handleSetMap={this.handleSetMap}
                    dataRainfall24h={dataRainfall24h}
                    handleModalOpen={this.handleModalOpen}
                  />
                </Box>
              </Grid>

              <Modal
                id="modal-chart"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={modalData.open}
                onClose={this.resetModal}
                style={{ align: 'center', justifyContent: 'center' }}
              >
                <div style={this.getModalStyle()} className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      <IconButton onClick={this.resetModal} style={{ padding: 0 }}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Typography variant="h5" id="modal-title" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', padding: '8px 4px' }}>
                    {modalData.header}
                  </Typography>

                  <Box mt={3}>
                    {modalData.content}
                  </Box>
                </div>
              </Modal>
            </Grid>
          </div>
        </BmaContext.Provider>
      </div>
    );
  }
}

BmaWater.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }))(BmaWater);
