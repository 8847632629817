import React from 'react';

import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function PressureLegend(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = props;
  let size = 7;
  const range = [1016, 1011, 1009, 1001, 801, 401, 0];
  const arrays = [];

  // show legend 3 columns for small devices
  if (!matches) {
    size = 3;
  }

  while (range.length > 0) { arrays.push(range.splice(0, size)); }

  const divStyle = {
    borderLeft: '1px solid #ccc',
  };
  const divStyleNon = {
    fontSize: 11,
  };

  const statusResult = (value) =>{
    if (value >= 1015) {
        return {
            bg: "rgba(255, 0, 0, 0.8)",
            text:">= 1015",
            fcolor:"#FFF",
            label:"",
        }
      } else if (value > 1010) {
        return {
            bg: "rgba(183, 67, 0, 0.8)",
            text:"< 1015",
            fcolor:"#FFF",
            label:"",
        }
      } else if (value > 1008) {
        return {
            bg: "rgba(255, 183, 0, 0.8)",
            text:"< 1010",
            fcolor:"#000",
            label:"",
        }
      } else if (value > 1000) {
        return {
            bg: "rgba(255, 255, 48, 0.8)",
            text:"< 1008",
            fcolor:"#000",
            label:"",
        }
      } else if (value > 800) {
        return {
            bg: "rgba(0, 218, 4, 0.8)",
            text:"< 1000",
            fcolor:"#000",
            label:"",
        }
      } else if (value > 400) {
        return {
            bg: "rgba(93, 246, 187, 0.8)",
            text:"< 800",
            fcolor:"#000",
            label:"",
        }
      } else {
        return {
            bg: "rgba(17, 195, 255, 0.8)",
            text:"< 400",
            fcolor:"#000",
            label:"",
        }
      }
  }

  return (
    <>
      <Grid container spacing={0} style={{ border: '1px solid #ccc', fontSize: 11 }}>
        {arrays.slice(0).reverse().map(r => (
          <Grid container direction="row" spacing={0} key={Math.floor(Math.random() * 1000000)}>
            {r.reverse().map((d, i) => {
              const status = statusResult(d);
              const styleConditon = (i > 0) ? divStyle : divStyleNon;
              return (
                <Grid item xs key={d} style={styleConditon}>
                  <Box display="flex">
                    <Box
                      flexGrow={1}
                      display="block"
                      align="center"
                      style={{
                        background: status.bg,
                        color: status.fcolor,
                        textAlign: 'center',
                      }}
                    >
                      {status.text}
                      {/* {'hPa'} */}
                    </Box>
                  </Box>
                  <Box flexGrow={1} display="block" align="center">
                    {t(status.label)}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </>
  );
}

PressureLegend.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PressureLegend);
