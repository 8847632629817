import React, { Component, Fragment } from 'react';
import 'typeface-roboto';

// partial react components
import Header from '../components/navigation/Header';
import Footer from '../components/navigation/Footer';
import SidebarExpand from '../components/navigation/SidebarExpand';
import showHeaderFooter from '../utils/ShowHeaderFooter';

class ThaiwaterApp extends Component {
  render() {
    return (
      <Fragment key="key">
        <Header />
        {!showHeaderFooter ? <></> : (
          <>
            <SidebarExpand />
            <Footer />
          </>
        )}
      </Fragment>
    );
  }
}

export default ThaiwaterApp;
