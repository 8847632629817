/* eslint-disable prefer-destructuring */

const stormColor = [
  {
    label: 'Tropical Depression',
    abbr: 'TD',
    rangeTitle: '< 34',
    statusColor: '#00CCFF',
    constrastColor: '#000',
  },
  {
    label: 'Tropical Storm',
    abbr: 'TS',
    rangeTitle: '<= 63',
    statusColor: '#00FF00',
    constrastColor: '#000',
  },
  {
    label: 'Cyclonic Storm Cat 1',
    abbr: 'Cat 1',
    rangeTitle: '<= 82',
    statusColor: '#FFFF00',
    constrastColor: '#000',
  },
  {
    label: 'Cyclonic Storm Cat 2',
    abbr: 'Cat 2',
    rangeTitle: '<= 95',
    statusColor: '#FFCC00',
    constrastColor: '#000',
  },
  {
    label: 'Cyclonic Storm Cat 3',
    abbr: 'Cat 3',
    rangeTitle: '<= 113',
    statusColor: '#FF6600',
    constrastColor: '#fff',
  },
  {
    label: 'Cyclonic Storm Cat 4',
    abbr: 'Cat 4',
    rangeTitle: '<= 135',
    statusColor: '#FF0000',
    constrastColor: '#fff',
  },
  {
    label: 'Cyclonic Storm Cat 5',
    abbr: 'Cat 5',
    rangeTitle: '> 135',
    statusColor: '#CC00CC',
    constrastColor: '#fff',
  },
];

const StormStatusColor = wind => {
  let color;

  if (wind > 135) {
    color = stormColor[6];
  } else if (wind > 113 && wind <= 135) {
    color = stormColor[5];
  } else if (wind > 95 && wind <= 113) {
    color = stormColor[4];
  } else if (wind > 82 && wind <= 95) {
    color = stormColor[3];
  } else if (wind > 63 && wind <= 82) {
    color = stormColor[2];
  } else if (wind > 34 && wind <= 63) {
    color = stormColor[1];
  } else if (wind < 34) {
    color = stormColor[0];
  }
  return { color };
};

export {
  stormColor,
  StormStatusColor,
};
