import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { withTranslation } from 'react-i18next';

import { FaSpinner } from '../../utils/Fontawesome';
import { withModal } from '../../contexts/ModalContext';
// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

// initialize multiple drop down checkbox data
const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
const years = yearsRange(currentYear, 1993);

class DamChartDailyCompare extends Component {
  // static whyDidYouRender = true;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      chartOptions: this.getChartOptions(),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const { chartCalType } = this.props;

    if (chartCalType !== 'sum') {
      this.renderChart();
    } else {
      this.renderChartSum();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalSize !== this.props.modalSize ||
      prevProps.isFullScreen !== this.props.isFullScreen) {
      this.props.updateChartSize();
    }
  }

  getChartOptions() {
    const {
      name, date, month, t,
    } = this.props;
    const monthName = moment.months(month - 1);

    this.options = {
      chart: {
        type: 'column',
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: `${t('อ่างเก็บน้ำ')} ${t(name)} (${t('เปรียบเทียบวันที่')} ${date} ${t(monthName)})`,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          borderWidth: 0,
          showInNavigator: true,
          gapSize: 6,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f}',
          },
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'category',
        categories: [],
      },
      yAxis: { // Primary yAxis
        min: 0,
        title: {
          text: t('ล้าน ลบ.ม.'),
        },
        labels: {
          format: '{value:.0f}',
        },
      },
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
        itemMarginBottom: 40,
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart() {
    const {
      id,
      type,
      date,
      month,
      year,
      name,
      t,
    } = this.props;

    const url = `dam_daily_graph?data_type=${type}&dam_id=${id}&day=${date}&month=${month}&year=${year}`;
    const chartOptions = this.getChartOptions();

    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const dataSeries = [];
        const xYears = [];

        let val = null;

        // data series
        const data = orderBy(result.data, ['year'], ['asc']).map(n => {
          const series = [];

          if (n.data !== null) {
            val = parseFloat(n.data).toFixed(2);
          } else {
            val = null;
          }
          // console.log(`${n.year.toString()}-${val}`);
          series.push(parseFloat(val));
          xYears.push(n.year);

          return series;
        });

        dataSeries.push({
          name: `${t(name)}`,
          data,
          tooltip: {
            valueSuffix: t(' ล้าน ลบ.ม.'),
          },
        });

        // set chart options
        year.sort().map(d => {
          chartOptions.xAxis.categories.push(d);

          return null;
        });

        // chartOptions.series[0].data = upperRuleCurveSeries;
        // chartOptions.series[1].data = lowerRuleCurveSeries;
        chartOptions.series = dataSeries;

        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderChartSum() {
    const {
      id,
      date,
      month,
      year,
      type,
      name,
      t,
    } = this.props;

    const url = `dam_daily_compare_sum_region_rid?region_id=${id}&day=${date}&month=${month}&year=${year}`;
    const chartOptions = this.getChartOptions();

    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const dataSeries = [];
        let val = null;

        // data series
        const data = orderBy(result.data, ['year'], ['asc']).map(n => {
          if (type === 'total_dam_storage') {
            val = n.total_dam_storage;
          } else if (type === 'total_dam_inflow') {
            val = n.total_dam_inflow;
          } else if (type === 'total_dam_released') {
            val = n.total_dam_released;
          } else if (type === 'total_dam_uses_water') {
            val = n.total_dam_uses_water;
          } else if (type === 'total_dam_inflow_acc') {
            val = n.total_dam_inflow_acc;
          } else if (type === 'total_dam_released_acc') {
            val = n.total_dam_released_acc;
          }

          const series = [];

          if ((val === 0) || (val === null)) {
            val = null;
          } else {
            val = parseFloat(val).toFixed(0);
          }

          // console.log(`${n.year.toString()}-${val}`);
          // series.push(n.year.toString(), parseFloat(val));
          series.push(parseFloat(val));

          return series;
        });

        dataSeries.push({
          name: `${t(name)}`,
          data,
          tooltip: {
            valueSuffix: t('ล้าน ลบ.ม.'),
          },
        });

        // set chart options
        year.sort().map(d => {
          chartOptions.xAxis.categories.push(d);

          return null;
        });
        chartOptions.series = dataSeries;

        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { isLoading, error, chartOptions } = this.state;

    // if error, show error message
    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      );
    }

    return (
      <>
        <HighchartsReact
          ref={this.props.chartRef}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </>
    );
  }
}

DamChartDailyCompare.defaultProps = {
  id: '',
  chartCalType: '',
  year: years,
};

DamChartDailyCompare.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.array,
  name: PropTypes.string.isRequired,
  chartCalType: PropTypes.string,
  t: PropTypes.func.isRequired,
};

// export default withTranslation('translations', { withRef: true })(DamChartDailyCompare);
export default compose(withModal, withTranslation('translations', { withRef: true }))(DamChartDailyCompare);
