import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#006fb6',
    color: '#fff',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(Index, Name, Definition) {
  return { Index, Name, Definition };
}

const rows = [
  createData('CWD', 'Consecutive wet days', 'Maximum number of consecutive days with RR ≥ 1 mm, days'),
  createData('CDD', 'Consecutive dry days', 'Maximum number of consecutive days with RR ≥1 mm, days'),
  createData('R10mm', 'Heavy rainfall days', 'Annual count of days where rainfall &gt;10 mm, days'),
  createData('R20mm', 'Very heavy rainfall days', 'Annual count of days where rainfall &gt;20 mm, days'),
  createData('R35mm', 'Extreme rainfall days', 'Annual count of days where rainfall &gt;35 mm, days'),
  createData('R90mm', 'Very extreme rainfall days', 'Annual count of days where rainfall &gt;90 mm, days'),
  createData('Rx1D', 'Max 1-day rainfall', 'Seasonal maximum 1-day rainfall, mm'),
  createData('Rx3D', 'Max 3-day rainfall', 'Seasonal maximum 3-day rainfall, mm'),
  createData('Rx5D', 'Max 5-day rainfall', 'Seasonal maximum 5-day rainfall, mm'),
  createData('R95pT', 'Annual contribution from very wet days', 'Total annual rainfall from wet days (&gt;= 1 mm) with rainfall above the 95th percentile for wet days, divided by the annual rainfall'),
  createData('SDII', 'Simple daily intensity index', 'The ratio of annual total rainfall to the number of wet days (≥1 mm)'),
  createData('PRCPTOT', 'Annual contribution from wet days', 'Annual total precipitation from days &gt;= 1 mm, mm/year'),
];

export default function ExtremeIndexDetail() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow className={classes.head}>
            <StyledTableCell component="th" scope="row" align="left">Index</StyledTableCell>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Definition</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.Index}>
              <TableCell component="th" scope="row">
                {row.Index}
              </TableCell>
              <TableCell align="left">{row.Name}</TableCell>
              <TableCell align="left">
                <div dangerouslySetInnerHTML={{ __html: row.Definition }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
