import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import DateFnsUtils from '@date-io/moment';

// icon
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FaHome } from '../../utils/Fontawesome';
import { HiiPresentIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class ReportDaily extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      startDate: moment().locale('th').format('YYYY-MM-DD'),
      filePDF: '',
    };
  }

  componentDidMount() {
    const { startDate } = this.state;
    this.getData(startDate);
  }

  handleChange = date => {
    this.setState({
      startDate: moment(date).locale('th').format('YYYY-MM-DD'),
    });
    this.getData(moment(date).locale('th').format('YYYY-MM-DD'));
  };

  getData = date => {
    const dateCurrent = `${moment(date).format('YYYY')}/${moment(date).format('MM')}/${moment(date).format('YYYYMMDD')}`;
    this.setState({ filePDF: dateCurrent });
  };

  render() {
    const { classes, t, i18n } = this.props;
    const {
      startDate,
      filePDF,
      // error,
    } = this.state;

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}report`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('รายงานสถานการณ์')}
                </Typography>
              </Button>
              <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                {t('รายวัน')}
              </Typography>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiPresentIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4" gutterBottom>
                      {t('รายงานสถานการณ์น้ำประจำวัน')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" py={5} width="100%" alignItems="center" justifyContent="center" bgcolor="background.paper">
              <Box display="inline" fontFamily="Kanit">
                <Typography variant="h6" style={{ marginRight: 10 }} noWrap>
                  {t('วันที่')}
                  {' '}
                </Typography>
              </Box>
              <Box display="inline" fontFamily="Kanit">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog-report-daily"
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    value={startDate}
                    maxDate={moment().locale('th').format('YYYY-MM-DD')}
                    onChange={this.handleChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box display="inline" fontFamily="Kanit">
                <Button
                  href={`https://hdrive.hii.or.th/WaterSituation/${filePDF}_1000_WaterSituation.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.buttonOutlined}
                >
                  <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดาวน์โหลด')}</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ReportDaily.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(ReportDaily));
