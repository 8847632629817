import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
// import LegendTableCell from '@material-ui/core/LegendTableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  LegendTableCell,
} from '../../styles/Style';

export default function StormHistoryLegendTable() {
  return (
    <Grid container spacing={4}>
      <Grid item xs>
        <Box mt={3}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <LegendTableCell colSpan={5}>
                        ข้อมูลเกณฑ์พายุ  :  Tropical Cyclone Windspeed Scale
                      </LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell colSpan={2}> </LegendTableCell>
                      <LegendTableCell colSpan={3}>
                        1 Minute Maximum Sustained Winds
                      </LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell scope="col">Strength</LegendTableCell>
                      <LegendTableCell scope="col">Category</LegendTableCell>
                      <LegendTableCell scope="col">knots</LegendTableCell>
                      <LegendTableCell scope="col">mph</LegendTableCell>
                      <LegendTableCell scope="col">km/h</LegendTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <LegendTableCell align="left">Tropical Depression</LegendTableCell>
                      <LegendTableCell bgcolor="#00CCFF">TD</LegendTableCell>
                      <LegendTableCell>&lt;34</LegendTableCell>
                      <LegendTableCell>&lt;39</LegendTableCell>
                      <LegendTableCell>&lt;63</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Tropical Storm</LegendTableCell>
                      <LegendTableCell bgcolor="#00FF00">TS</LegendTableCell>
                      <LegendTableCell>34-63</LegendTableCell>
                      <LegendTableCell>39-73</LegendTableCell>
                      <LegendTableCell>63-118</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Severe Cyclonic Storm Cat 1</LegendTableCell>
                      <LegendTableCell bgcolor="#FFFF00">Cat 1</LegendTableCell>
                      <LegendTableCell>64-82</LegendTableCell>
                      <LegendTableCell>74-95</LegendTableCell>
                      <LegendTableCell>119-153</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Severe Cyclonic Storm Cat 2</LegendTableCell>
                      <LegendTableCell bgcolor="#FFCC00">Cat 2</LegendTableCell>
                      <LegendTableCell>83-95</LegendTableCell>
                      <LegendTableCell>96-110</LegendTableCell>
                      <LegendTableCell>154-177</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Severe Cyclonic Storm Cat 3</LegendTableCell>
                      <LegendTableCell bgcolor="#FF6600">Cat 3</LegendTableCell>
                      <LegendTableCell>96-113</LegendTableCell>
                      <LegendTableCell>111-130</LegendTableCell>
                      <LegendTableCell>178-210</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Super Cyclonic Storm Cat 4</LegendTableCell>
                      <LegendTableCell bgcolor="#FF0000">Cat 4</LegendTableCell>
                      <LegendTableCell>114-135</LegendTableCell>
                      <LegendTableCell>131-155</LegendTableCell>
                      <LegendTableCell>211-250</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Super Cyclonic Storm Cat 5</LegendTableCell>
                      <LegendTableCell bgcolor="#CC00CC">Cat 5</LegendTableCell>
                      <LegendTableCell>&gt;135</LegendTableCell>
                      <LegendTableCell>&gt;155</LegendTableCell>
                      <LegendTableCell>&gt;250</LegendTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TableContainer>
                <Table size="small" align="center" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <LegendTableCell colSpan={5}>
                        ข้อมูลเกณฑ์พายุ  :  Tropical Typhoon Windspeed Scale
                      </LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell colSpan={2}> </LegendTableCell>
                      <LegendTableCell colSpan={3}>
                        1 Minute Maximum Sustained Winds
                      </LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell scope="col">Strength</LegendTableCell>
                      <LegendTableCell scope="col">Category</LegendTableCell>
                      <LegendTableCell scope="col">knots</LegendTableCell>
                      <LegendTableCell scope="col">mph</LegendTableCell>
                      <LegendTableCell scope="col">km/h</LegendTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <LegendTableCell align="left">Tropical Depression</LegendTableCell>
                      <LegendTableCell bgcolor="#00CCFF">TD</LegendTableCell>
                      <LegendTableCell>&lt;34</LegendTableCell>
                      <LegendTableCell>&lt;39</LegendTableCell>
                      <LegendTableCell>&lt;63</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Tropical Storm</LegendTableCell>
                      <LegendTableCell bgcolor="#00FF00">TS</LegendTableCell>
                      <LegendTableCell>34-63</LegendTableCell>
                      <LegendTableCell>39-73</LegendTableCell>
                      <LegendTableCell>63-118</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Typhoon Cat 1</LegendTableCell>
                      <LegendTableCell bgcolor="#FFFF00"> Cat 1</LegendTableCell>
                      <LegendTableCell>64-82</LegendTableCell>
                      <LegendTableCell>74-95</LegendTableCell>
                      <LegendTableCell>119-153</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Typhoon Cat 2</LegendTableCell>
                      <LegendTableCell bgcolor="#FFCC00">Cat 2</LegendTableCell>
                      <LegendTableCell>83-95</LegendTableCell>
                      <LegendTableCell>96-110</LegendTableCell>
                      <LegendTableCell>154-177</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Typhoon Cat 3</LegendTableCell>
                      <LegendTableCell bgcolor="#FF6600">Cat 3</LegendTableCell>
                      <LegendTableCell>96-113</LegendTableCell>
                      <LegendTableCell>111-130</LegendTableCell>
                      <LegendTableCell>178-210</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Typhoon Cat 4</LegendTableCell>
                      <LegendTableCell bgcolor="#FF0000">Cat 4</LegendTableCell>
                      <LegendTableCell>114-135</LegendTableCell>
                      <LegendTableCell>131-155</LegendTableCell>
                      <LegendTableCell>211-250</LegendTableCell>
                    </TableRow>
                    <TableRow>
                      <LegendTableCell align="left">Super Typhoon Cat 5</LegendTableCell>
                      <LegendTableCell bgcolor="#CC00CC">Cat 5</LegendTableCell>
                      <LegendTableCell>&gt;135</LegendTableCell>
                      <LegendTableCell>&gt;155</LegendTableCell>
                      <LegendTableCell>&gt;250</LegendTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
