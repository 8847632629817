// React
import React, { useEffect, useState } from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Img from 'react-image';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';


// images
import Lightbox from 'react-image-lightbox';
// style
import 'react-image-lightbox/style.css';
import { styles } from '../../styles/Style';

import { uniqueId } from 'lodash';

// const useStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  ...styles,
  downloadButton: {
    margin: theme.spacing(1),
    backgroundColor: 'blue',
    color: 'white',
    padding: theme.spacing(1),
  },
  tabButton: {
    margin: 10
  },
  img: {
    height: '490px',
    [theme.breakpoints.up('lg')]: {
      height: '490px',
    },
    [theme.breakpoints.only('md')]: {
      height: '100%',
      width: '257px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '58%',
    },


  }
}));
const initPathApi = process.env.MIX_API_URL;
const initPath = process.env.MIX_APP_PATH;
const hostImage = `${initPathApi}shared/image?_csrf=&image=`;
const imageNullNhc = 'https://placehold.co/1024x1830?text=No%20Image&italic=true';
const imageNullWrf = 'https://placehold.co/727x792?text=No%20Image&italic=true';
export default function CPFOMain() {
  const classes = useStyles();
  const [dateValue, setDateValue] = useState(moment().locale('th').set('hour', '07').set('minute', '00'));
  const [maxDate, setMaxDate] = useState(moment().locale('th').set('minute', 0).format('YYYY-MM-DD'));
  const [initLoad, setInitLoad] = useState(true);

  const [nhc, setNhc] = useState({
    day1: { url: imageNullNhc, loading: false },
    day2: { url: imageNullNhc, loading: false },
    day3: { url: imageNullNhc, loading: false },
  });
  const [wrf, setWrf] = useState({
    day1: { url: imageNullWrf, loading: false },
    day2: { url: imageNullWrf, loading: false },
    day3: { url: imageNullWrf, loading: false },
  });
  const downloadImage = (url) => {
    const link = document.createElement('a');
    const formattedDate = moment().format('YYYYMMDD');
    link.href = url;
    link.download = `wrf_vortex_hii_${formattedDate}.gif`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateDate = (date) => {
    if (date !== null) {
      if (date.isValid()) {
        setDateValue(date);
      }
    }

  };

  const updateTime = (time) => {
    setDateValue(moment(dateValue).set('hour', time).set('minute', 0));
  };
  useEffect(() => {
    let fetchLink = `${initPathApi}analyst/cpfo`;
    if (initLoad) {
      fetchLink = `${initPathApi}analyst/cpfo`;
    } else {
      fetchLink = `${initPathApi}analyst/cpfo?date=${dateValue.format('YYYY-MM-DD')}&hour=${dateValue.format('HH')}`;
    }
    fetch(`${fetchLink}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        console.log(result);

        if (result.result === 'OK') {
          setInitLoad(false);
          return result;
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(result => {
        console.log('result', result);
        if (initLoad) {
          console.log('initLoad', initLoad);
          setMaxDate(moment(result.data.init_datetime).locale('th'));
          setDateValue(moment(result.data.init_datetime).locale('th'));

        }
        if (result.data.nhc !== undefined) {
          setNhc({
            day1: {
              url: result.data.nhc.length >= 1 ? hostImage + result.data.nhc[0].media_path : imageNullNhc,
              loading: !result.data.nhc.length >= 1,
              isOpen: false,
              date: result.data.nhc.length >= 1 ? result.data.nhc[0].datetime : '',
            },
            day2: {
              url: result.data.nhc.length >= 2 ? hostImage + result.data.nhc[1].media_path : imageNullNhc,
              loading: !result.data.nhc.length >= 2,
              isOpen: false,
              date: result.data.nhc.length >= 2 ? result.data.nhc[1].datetime : '',
            },
            day3: {
              url: result.data.nhc.length >= 3 ? hostImage + result.data.nhc[2].media_path : imageNullNhc,
              loading: !result.data.nhc.length >= 3,
              isOpen: false,
              date: result.data.nhc.length >= 3 ? result.data.nhc[2].datetime : '',
            },
          });
        } else {
          setNhc({
            day1: {
              url: imageNullNhc, loading: true,
              isOpen: false,
              date: '',
            },
            day2: {
              url: imageNullNhc, loading: true,
              isOpen: false,
              date: '',
            },
            day3: {
              url: imageNullNhc, loading: true,
              isOpen: false,
              date: '',
            },
          });
        }

        if (result.data.wrf !== undefined) {
          setWrf({
            day1: {
              url: result.data.wrf.length >= 1 ? hostImage + result.data.wrf[0].media_path : imageNullWrf,
              loading: !result.data.wrf.length >= 1,
              isOpen: false,
              date: result.data.wrf.length >= 1 ? result.data.wrf[0].datetime : '',
            },
            day2: {
              url: result.data.wrf.length >= 2 ? hostImage + result.data.wrf[1].media_path : imageNullWrf,
              loading: !result.data.wrf.length >= 2,
              isOpen: false,
              date: result.data.wrf.length >= 2 ? result.data.wrf[1].datetime : '',
            },
            day3: {
              url: result.data.wrf.length >= 3 ? hostImage + result.data.wrf[2].media_path : imageNullWrf,
              loading: !result.data.wrf.length >= 3,
              isOpen: false,
              date: result.data.wrf.length >= 3 ? result.data.wrf[2].datetime : '',
            },
          });
        } else {
          setWrf({
            day1: {
              url: imageNullWrf, loading: true,
              isOpen: false,
              date: '',
            },
            day2: {
              url: imageNullWrf, loading: true,
              isOpen: false,
              date: '',
            },
            day3: {
              url: imageNullWrf, loading: true,
              isOpen: false,
              date: '',
            },
          });
        }

      })
      .catch(error => {
        console.error(error);
      });
  }, [dateValue]);

  return (
    <>
      {initLoad ? (
        <Box style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.5)', zIndex: 9999 }} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </Box>
      ) : null}
      <div className={classes.layout}>
        <Box>
          <Box p={1} pt={0} mb={3}>
            <Img
              src={`${initPath}images/cpfo/topbar.png`}
              width={'100%'}
              alt="No Image"
            />
          </Box>
          <Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm="auto">
                  <KeyboardDatePicker
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    margin="normal"
                    id="date-picker-inline"
                    label="วันที่"
                    value={dateValue}
                    maxDate={maxDate}
                    onChange={updateDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                    <Button
                      variant="contained"
                      color={dateValue.format('HH') === '07' ? 'primary' : 'default'}
                      className={classes.tabButton}
                      onClick={() => updateTime(7)}
                    >
                      07:00
                    </Button>
                    <Button
                      variant="contained"
                      color={dateValue.format('HH') === '19' ? 'primary' : 'default'}
                      className={classes.tabButton}
                      onClick={() => updateTime(19)}
                    >
                      19:00
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
          {['day1', 'day2', 'day3'].map(day => (
            <Box m={3} border={1} borderColor={'#ccc'} key={day}>
              <Grid container spacing={0} direction="row" justifyContent="space-around">
                <Grid item xs={5}>
                  <div style={{ textAlign: 'right', objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }}>
                    <Box py={1} display="flex" justifyContent="flex-end">
                      <Button
                        width="100%"
                        disabled={nhc[day].loading}
                        onClick={() => setNhc(prevState => ({ ...prevState, [day]: { ...prevState[day], isOpen: true } }))}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <img src={nhc[day].url} key={uniqueId()} className={classes.img} alt="NULL" />
                      </Button>
                      {nhc[day].isOpen && <Lightbox
                        mainSrc={nhc[day].url}
                        imagePadding={50}
                        imageTitle={`แบบจำลอง WRF - ROMS ${moment(nhc[day].date).locale('th').format('LLLL')}`}
                        reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                        onCloseRequest={() => setNhc(prevState => ({ ...prevState, [day]: { ...prevState[day], isOpen: false } }))}
                      />}
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <div style={{ textAlign: 'left', objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }}>
                    <Box display="flex" justifyContent="flex-start">
                      <Button
                        disabled={wrf[day].loading}
                        onClick={() => setWrf(prevState => ({ ...prevState, [day]: { ...prevState[day], isOpen: true } }))}
                        style={{ display: 'flex', justifyContent: 'flex-start' }}
                      >
                        <img src={wrf[day].url} key={uniqueId()} width="90%" alt="NULL" />
                      </Button>
                      {wrf[day].isOpen && <Lightbox
                        mainSrc={wrf[day].url}
                        imagePadding={50}
                        imageTitle={`ภาพปริมาณฝนสะสม 24 ชั่วโมง ${moment(wrf[day].date).locale('th').format('LLLL')}`}
                        reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                        onCloseRequest={() => setWrf(prevState => ({ ...prevState, [day]: { ...prevState[day], isOpen: false } }))}
                      />}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Divider style={{ marginTop: 40, marginBottom: 40 }} />
          {/* <Box my={4} display="flex" justifyContent="center" color={'#888'} width={'100%'}>Disclaimer : Something</Box>
          <Box my={3} display="flex">
            <Box><strong>STRONG</strong>Typical Text</Box>
          </Box> */}
        </Box>
      </div>

    </>
  );
}