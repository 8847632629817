import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';
import { orderBy } from 'lodash';
import moment from 'moment';

// translate
import { withTranslation } from 'react-i18next';

// material-ui //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

// style
import { compose } from 'recompose';
import { FaSpinner } from '../../../utils/Fontawesome';
import { styles } from '../../../styles/Style';

import WlContext from '../../../contexts/wlContext';
import FloodPlainForecastMap from './FloodPlainForecastMap';

class FloodPlainForecast extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error

      datas: [],
      datasDischarge: [],
      dataWl: [],
      dataGeojsonWaterFiled: [],
      // map
      map: null,
      zoom: 7,
      location: [16.3, 100.05],
      markers: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,

      geojsonRiver: [],
      geojsonBasin: [],
      datasWaterGate: [],
      dataWaterCanal: [],
      dataWlPlain: [],

      metropolitanArea: ['10', '11', '12', '13', '73', '74'],
    };
  }

  componentDidMount() {
    const { match: { params: { showType } } } = this.props;
    this._mounted = true;
    // set loading status
    this.setState({ isLoading: true });
    this.fetchCsv(showType);
    this.getData();
    this.fetchWaterGateLoad();
    this.fetchWaterGateCPY();
    this.fetchWaterFiled();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '80%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '73%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  getData() {
    // 2022-10-18 ที่ นน. กรองสถานีโทรมาตรเหล่านี้ออก TCP001 - TCP011
    const stationCode = ['TCP001', 'TCP002', 'TCP003', 'TCP004', 'TCP005', 'TCP006', 'TCP007', 'TCP008', 'TCP009', 'TCP010', 'TCP011', 'TCP012'];
    Promise.all([
      fetch(`${process.env.MIX_API_URL}public/waterlevel_load`),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([wl]) => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // filter wl 24 hrs from current
        const filterDatas = orderBy(wl.waterlevel_data.data, {
          storage_percent: Number,
        })
          .filter(d => {
            const time = new Date(d.waterlevel_datetime.replace(/-/g, '/')).getTime();
            return ((last24hrs < time) && (d.waterlevel_msl != null));
          })
          .filter(d => d.storage_percent != null)
          .filter(value => !stationCode.includes(value.station.tele_station_oldcode));

        const filterDatasCutOff = orderBy(wl.waterlevel_data.data, {
          storage_percent: Number,
        })
          .filter(d => {
            const time = new Date(d.waterlevel_datetime.replace(/-/g, '/')).getTime();
            return ((last24hrs < time) && (d.waterlevel_msl != null));
          })
          .filter(d => d.storage_percent != null)
          .filter(value => stationCode.includes(value.station.tele_station_oldcode));

        this.setState({
          dataWl: filterDatas,
          dataWlPlain: filterDatasCutOff,
        });
      })
      .catch(error => this.setState({ error }));
  }

  fetchWaterGateLoad = () => {
    fetch(`${process.env.MIX_API_URL}public/watergate_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 1);
        const filterDatas = result.watergate_data.data.filter(d => {
          const time = new Date(d.watergate_datetime_in.replace(/-/g, '/')).getTime();
          return last24hrs < time && d.watergate_in != null;
        });
        filterDatas.map(ele => {
          // New properties to be added
          const newPropsObj = {
            lat: ele.station.tele_station_lat,
            long: ele.station.tele_station_long,
            station_name: ele.station.tele_station_name.th,
            station_type: 'watergate_load',
          };
          // Assign new properties and return
          return Object.assign(ele, newPropsObj);
        });
        this.setState({ datasWaterGate: filterDatas });
      })
      .catch(error => this.setState({ error }));
  }

  fetchWaterGateCPY = () => {
    const { metropolitanArea } = this.state;
    // 2022-10-11 ที่ นน. ลิสรายการประตูระบายน้ำใน กทม เฉพาะริมแม่น้ำเจ้าพระยา
    const stationOldCode = ['WL.BNA.01', 'WL.BOA.01', 'WL.BJK.01', 'WL.KJK.01', 'WL.PKN.01', 'WL.CNS.01', 'WL.KDN.01', 'WL.JRN.01', 'WL.DKN.01', 'WL.BSK.01', 'WL.SRE.01', 'WL.STR.01', 'WL.KKS.04', 'WL.PKG.01', 'WL.BKY.01', 'WL.KMN.01', 'WL.BYK.02', 'WL.KKS.01', 'WL.SSN.01', 'WL.BKB.01', 'WL.BSU.01', 'WL.BKA.01'];
    fetch(`${process.env.MIX_API_URL}public/canal_waterlevel`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(WaterlevelCanal => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        const Canal = WaterlevelCanal.data.filter(d => {
          const time = new Date(d.canal_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.canal_value != null
            && metropolitanArea.includes(d.geocode.province_code));
        })
          .filter(value => stationOldCode.includes(value.station.canal_oldcode));

        Canal.map(ele => {
          // New properties to be added
          const newPropsObj = {
            waterlevel_datetime: ele.canal_datetime,
            waterlevel_msl: ele.canal_value,
            station_type: 'canal',
            lat: ele.station.canal_lat,
            long: ele.station.canal_long,
            station_name: ele.station.canal_name.th,
            wl_out: ele.canal_out, // ระดับน้ำข้างนอก
            warning_level: ele.station.warning_level,
            critical_level: ele.station.critical_level,
          };
          // Assign new properties and return
          return Object.assign(ele, newPropsObj);
        });
        this.setState({ dataWaterCanal: Canal });
      })
      .catch(error => this.setState({ error }));
  }

  fetchWaterFiled =() => {
    const CurrentDate = moment().add(-1, 'days').format('YYYY-MM-DD');
    Promise.all([
      fetch(`https://partners.thaiwater.net:3002/webApi/getTCPFloodData?measure_datetime=${CurrentDate}`),
      fetch('json/boundary/floodplain.json'),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([waterFiled, jsonFloodPlain]) => {
        let properties = {};
        const geojsonFp = jsonFloodPlain.features.map(d => {
          const objWaterFiled = waterFiled.data.find(item => item.area === d.properties.NO);
          properties = { ...d.properties };
          if (objWaterFiled) {
            properties = { ...d.properties, ...objWaterFiled };
          }
          return { ...d, properties };
        });
        this.setState({ dataGeojsonWaterFiled: geojsonFp });
      })
      .catch(error => this.setState({ error }));
  }

  fetchCsv(showType) {
    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/metadata/hii_waterlevel.csv`)
      .then(resp => resp.text())
      .then(results => {
        const rawData = Papa.parse(results).data.slice(1);
        const onwrFilter = rawData.filter(n => n[15] === 't');
        const varData = (typeof showType !== 'undefined' && showType === 'onwr') ? onwrFilter : rawData;
        this.setState({
          isLoading: false,
          datas: varData,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/metadata/rid_discharge.csv`)
      .then(resp => resp.text())
      .then(results => {
        const rawDischarge = Papa.parse(results).data.slice(1);
        const dischargeOnwrFilter = rawDischarge.filter(n => n[15] === 't');
        const dischargeData = (typeof showType !== 'undefined' && showType === 'onwr') ? dischargeOnwrFilter : rawDischarge;

        this.setState({
          isLoading: false,
          datasDischarge: dischargeData,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes, t } = this.props;

    const {
      isLoading, error, datas, datasDischarge, dataWl, dataGeojsonWaterFiled,
      modalHeader, modalSubHeader,
      modalContent, open, datasWaterGate, dataWlPlain,
    } = this.state;

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <WlContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('คาดการณ์น้ำท่า-ระดับน้ำในแม่น้ำสายหลัก')}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} width="100%">
                  <FloodPlainForecastMap
                    {...this.state}
                    datas={datas}
                    datasDischarge={datasDischarge}
                    dataWl={dataWl}
                    dataGeojsonWaterFiled={dataGeojsonWaterFiled}
                    dataWlPlain={dataWlPlain}
                    handleSetMap={this.handleSetMap}
                    handleModalOpen={this.handleModalOpen}
                    datasWaterGate={datasWaterGate}
                  />
                </Box>
              </Grid>
              <Modal
                id="modal-chart"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={this.handleModalClose}
                style={{ align: 'center', justifyContent: 'center' }}
              >
                <div style={this.getModalStyle()} className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Typography variant="h5" id="modal-title">
                    {modalHeader}
                  </Typography>

                  <Typography variant="subtitle1" id="simple-modal-description">
                    {modalSubHeader}
                  </Typography>
                  <Box mt={3}>
                    {modalContent}
                  </Box>
                </div>
              </Modal>
            </Grid>
          </div>
        </WlContext.Provider>
      </div>
    );
  }
}

FloodPlainForecast.defaultProps = {
  match: {
    params: {
      showType: '',
    },
  },
};

FloodPlainForecast.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      showType: PropTypes.string,
    }),
  }),
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(FloodPlainForecast);
