import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import SidebarWeather from '../navigation/SidebarWeather';
import WeatherClimate from './WeatherClimate';
import WeatherRadar from './WeatherRadar';
import WeatherRain from './WeatherRain';
import WeatherStorm from './WeatherStorm';
import WeatherSea from './WeatherSea';
// import WeatherStormSearch from './WeatherStormSearch';
import WeatherRainList from './WeatherRainList';

import UpperWindHistory from './upperwind/UpperWindHistory';
import VerticalWindHistory from './upperwind/VerticalWindHistory';
import WeatherImageHistory from './weather-image/WeatherImageHistory';
import RadarHistory from '../radar/RadarHistory';
import WindHistory from './wind/WindHistory';
import TroughHistory from './trough/TroughHistory';

import ScrollToTop from '../../utils/ScrollToTop';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // displayState: 'weather',
    };
  }

  render() {
    // const { classes } = this.props;

    return (
      <Router>
        <ScrollToTop>
          <>
            <CssBaseline />

            {/* Sidebar */}
            <SidebarWeather />

            <Switch>
              <Route
                key="weather"
                exact
                path={`${initPath}weather`}
                render={() => (
                  <WeatherClimate />
                )}
              />
              <Route
                key="weatherstorm"
                exact
                path={`${initPath}weather/storm`}
                render={() => (
                  <WeatherStorm />
                )}
              />
              <Route
                key="weatherrain"
                exact
                path={`${initPath}weather/rainfall`}
                render={() => (
                  <WeatherRain />
                )}
              />
              <Route
                key="weatherradar"
                exact
                path={`${initPath}weather/radar`}
                render={() => (
                  <WeatherRadar />
                )}
              />
              <Route
                key="weatherRadarHistory"
                exact
                path={`${initPath}weather/radar/history/:radarType/:date?`}
                component={RadarHistory}
              />
              <Route
                key="weathersea"
                exact
                path={`${initPath}weather/sea`}
                render={() => (
                  <WeatherSea />
                )}
              />
              {/* <Route
                key="weatherstormsearch"
                exact
                path={`${initPath}weather/weatherstormsearch`}
                render={() => (
                  <WeatherStormSearch />
                )}
              /> */}
              <Route
                key="weatherrainlist"
                exact
                path={`${initPath}weather/weatherrainlist`}
                render={() => (
                  <WeatherRainList />
                )}
              />
              <Route
                key="verticalWindHistory"
                exact
                path={`${initPath}weather/upperwind/history/5km`}
                component={VerticalWindHistory}
              />
              <Route
                key="upperWindHistory"
                exact
                path={`${initPath}weather/upperwind/history/:height`}
                component={UpperWindHistory}
              />
              <Route
                key="windHistory"
                exact
                path={`${initPath}weather/wind/history`}
                component={WindHistory}
              />
              <Route
                key="troughHistory"
                exact
                path={`${initPath}weather/trough/history`}
                component={TroughHistory}
              />
              <Route
                key="history"
                exact
                path={`${initPath}weather/:date/:agencyId/:mediaTypeId`}
                component={WeatherImageHistory}
              />
            </Switch>
          </>
        </ScrollToTop>
      </Router>
    );
  }
}

// Weather.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles, { withTheme: true })(Weather);
