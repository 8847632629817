import React, { useState, useEffect } from 'react';
import { 
  newsOperation01, 
  newsOperation02,
  apiKeyGoogle 
} from './path/drivepath';
import { handleClick } from './utils/Utils'

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import useStyles from './style';
import moment from 'moment';
import 'moment/locale/th';


function BoxNewsOperation() {
  const classes = useStyles();
  const [imageHighlight, setImageHighlight] = useState([]);
  const [imageHighlightText, setImageHighlightText] = useState([]);
  const [files02, setFiles02] = useState([]);

  const linkAll = newsOperation01.find(v => v.id === 2).history;


  useEffect(() => {

    const fetchFiles = async (id, type) => {
      try {
        let currentYear = new Date().getFullYear();
        if(id === 3){
          currentYear = currentYear - 1
        }
        const param01 = type.find(v => v.id === id);
        const param02 = type.filter(v => v.yearEng == currentYear)[0];
        const APP_ENV = process.env.NODE_ENV;
        let param = '';
        let url = '';
        
    
        
        if(id === 1 ){
          param = param01;
        }else{
          param = param02;
        }
       
        if (!param) return null;

        if (APP_ENV === 'local') {
          url = `https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
        } else {
          url = `${process.env.MIX_APP_URL}proxy/api-mhesiwarroom.php?file=https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
        }
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error(`Error fetching files for id ${id}:`, error);
        return null;
      }
    };

    const processAndSetFiles = async () => {
      const data01 = await fetchFiles(1, newsOperation01);
      const data02 = await fetchFiles(2, newsOperation02);

      if (data01.files) {

        const image1 = data01.files
          .filter(file => ['image/png', 'image/jpg', 'image/jpeg'].includes(file.mimeType))
          .map(file => {
            const parts = file.name.split('_');

            // Check if the file name has exactly 4 parts
            if (parts.length === 4 || (parts.length === 3)) {
              const [date, index, description, url] = parts;

              const thaiDate = moment(date, 'MMDD')
                .locale('th')
                .add(543, 'years')
                .format('D MMMM YYYY');

              return {
                ...file,
                date: date,
                index: index,
                title: description,
                url: url || '',  // Use the url part or an empty string if it's not present
                datethai: thaiDate,
              };
            } else {
              return null; // Skip files with incorrect format
            }
          })
          .filter(file => file !== null); // Filter out any null values

        const imageTxt1 = await processFiles(data01)

        const image1Sort = await FilterLatest(image1)
        setImageHighlight(image1Sort);
        setImageHighlightText(imageTxt1);
      }

      if (data02.files) {
        const typeFiles02 = await processFiles(data02);
        const sortedFiles02 = await FilterLatest(typeFiles02);

        let sortedFiles03 = [];
        if(sortedFiles02.length < 5 ){
          const data03 = await fetchFiles(3, newsOperation02);
          const typeFiles03 = await processFiles(data03);
          sortedFiles03 = await FilterLatest(typeFiles03);
        }
        let combinedArray = [...sortedFiles02, ...sortedFiles03];
        setFiles02(combinedArray);
      }
    };

    processAndSetFiles();
  }, []);

  const processFiles = async (data) => {
    // Example data structure: data.files might be an array of file objects
    const files = data.files;
    // Loop through each file and check its MIME type
    const processedFiles = files
      .filter(file =>
        file.mimeType === 'application/pdf' ||
        file.mimeType === 'application/vnd.google-apps.document'
      )
      .map(file => {
        const parts = file.name.split('_');

        // Check if the file name has 3 or 4 parts
        if (parts.length === 4 || parts.length === 3) {
          const [date, index, description, url] = parts;

          const thaiDate = moment(date, 'MMDD')
            .locale('th')
            .add(543, 'years')
            .format('D MMMM YYYY');

          const commonFields = {
            date,
            index,
            title: description.replace('.pdf', ''),  // Remove '.pdf' from the title
            datethai: thaiDate,
          };

          if (file.mimeType === 'application/pdf') {
            return {
              ...file,
              ...commonFields,
              url: file.webViewLink,
            };
          } else if (file.mimeType === 'application/vnd.google-apps.document') {
            return {
              ...file,
              ...commonFields,
              url,
            };
          }
        } else {
          return null;
        }
      })
      .filter(file => file !== null);
    // Return or do something with the processed files
    return processedFiles;
  };

  const FilterLatest = async (data) => {

    const currentYear = new Date().getFullYear();
    // Function to sort files by date in descending order
    const sortFilesByDate = (files) => {
      return files.sort((a, b) => {
        // Convert date strings to Date objects
        const aDate = new Date(currentYear, parseInt(a.date.substring(0, 2), 10) - 1, parseInt(a.date.substring(2, 4), 10));
        const bDate = new Date(currentYear, parseInt(b.date.substring(0, 2), 10) - 1, parseInt(b.date.substring(2, 4), 10));

        const dateComparison = bDate - aDate;

        // If dates are the same, compare by index or another property
        if (dateComparison === 0) {
          // Assuming `index` or another property is available for tie-breaking
          return b.index - a.index; // Sort indices in descending order
        }

        return dateComparison; // Sort by date in descending order
      });
    };

    // Sort the processed data by date
    const sortedFiles = sortFilesByDate(data);

    // Return the sorted data
    return sortedFiles;
  };



  const handleClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="center" style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid item xs>
          <Box className={classes.title} style={{ backgroundColor: '#3F97FF' }}>
            ภารกิจศูนย์ปฏิบัติการฯ
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" justifyContent="center" style={{ marginBottom: 30 }}>

        {imageHighlight && imageHighlight.slice(0, 3).map((img1, i) => {
          const matchingText = imageHighlightText.find(img2 => img2.index === img1.index && img2.date === img1.date);

          return (
            <Grid item xs={12} sm={4} lg={4} key={i}>
              <Box m={{ xs: 3, sm: 1, md: 3 }} key={img1.id}>
                <Button onClick={() => handleClick(matchingText.url)} className={classes.imgWrapper}>
                  <img
                    src={`https://drive.google.com/thumbnail?sz=w500&id=${img1.id}`}
                    alt={img1.name}
                    className={classes.imgLink}
                    style={{ borderRadius: 9, border: '7px solid #3F97FF' }}
                  />
                </Button>
                {
                  matchingText && (
                    <>
                      <a href="#" onClick={() => handleClick(matchingText.url)}>
                        <Box className={classes.date} mt={1}>{matchingText.datethai}</Box>
                        <Box className={classes.link}>{matchingText.title}</Box>
                      </a>
                    </>
                  )
                }
              </Box>
            </Grid>
          );
        })}

        <Grid item xs={12} sm={12}>
          <Box mx={{ xs: 0, md: 3 }}>
            {files02 &&
              files02.slice(0, 5).map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <Box mt={2} className={classes.date}>{item.datethai}</Box>
                    <Button component="a"
                      style={{ marginBottom: 20 }}
                      onClick={() => handleClick(item.url)}
                    >
                      {item.title}
                    </Button>
                    <Divider />
                  </React.Fragment>
                );
              })}
            <Box width={'100%'} display="flex" justifyContent="flex-end" mt={2}>
              <Button
                component="a"
                color="secondary"
                title="แสดงทั้งหมด"
                className={classes.btn}
                style={{ background: '#E1E1E2' }}
                onClick={() => handleClick(linkAll)}
              >
                แสดงทั้งหมด
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid >
    </>
  );
}

export default BoxNewsOperation;