/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Hidden } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

// map
import L from 'leaflet';
import {
  Map, Marker, TileLayer, GeoJSON, LayerGroup, LayersControl, Tooltip, Popup, WMSTileLayer,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
// import 'leaflet-kmz';
// import zipObject from 'lodash/zipObject';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { styles } from '../../styles/Style';
import FlashFloodChart from './FlashFloodChart';
import FlashFloodList from './FlashFloodList';
import LegendFcstRain from './LegendFcstRain';
import LegendRisk from './LegendRisk';
import LegendObsRain from './LegendObsRain';
import LegendRainfall from './LegendRainfall';

// wrf wrf
import { PlottyGeotiffLayer } from './GeotiffLayer';
import 'plotty';
import 'leaflet-geotiff';
import './LeafletGeotiffPlotty';

import { StormTwcLayer } from './StormTwcLayer';

// icon
import { HiiRainIcon } from '../../utils/Icon';
import { FaChartLine } from '../../utils/Fontawesome';

// css
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import RainfallStatusColor from '../rainfall/RainfallStatusColor';

import tJson from '../../utils/TJson';
import CurrentLocation from '../currentlocation/CurrentLocation';

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;
const { BaseLayer, Overlay } = LayersControl;

const banner = `${initPath}images/models/banner-ffpi.png`;

const fcstRainOptions = {
  name: 'WRF',
  band: 0,
  opacity: 0.5, // not work
  displayMin: 1,
  displayMax: 500,
  colorScale: 'fcstRaincolorscale',
  clampLow: false,
  clampHigh: true,
};

const obsRainOptions = {
  name: 'WRF',
  band: 0,
  opacity: 0.5, // not work
  displayMin: 50,
  displayMax: 500,
  colorScale: 'obsRaincolorscale',
  clampLow: false,
  clampHigh: true,
};

class FlashFloodMap extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.layer = createRef();
    this.layer1 = createRef();
    this.layer3 = createRef();
    this.refGeotiff1 = createRef();
    this.refGeotiff2 = createRef();
    this.refStrom = createRef();

    this.state = {
      error: '',
      // geojsonRiver: [],
      geojsonTambon: [],
      openLegendRisk: false,
      openLegendFcstRain: false,
      openLegendObsRain: false,
      openLegendRainfall: false,
      // hover: false,
      // tifValue: 'fcst_rain.tif',
      // currentMap: false,
      isBoxVisible: true,
      initActive: false,
      isBoxDetail: true,
      openBoxDetail: true,
    };

    this.getGeoJson = this.getGeoJson.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.highlightFeature = this.highlightFeature.bind(this);
    this.resetHighlight = this.resetHighlight.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.bindMarker = this.bindMarker.bind(this);
    this.handleBoxToggle = this.handleBoxToggle.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  componentDidMount() {
    const { handleSetMap, width } = this.props;
    this.getGeoJson();
    this._mounted = true;
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '15%';
    // document.getElementsByClassName('leaflet-overlay-pane')[0].style.opacity = 0.5;
    document.getElementsByClassName('leaflet-top leaflet-right')[0].style.top = '11%';

    this.map = this.refMap.current.leafletElement;
    handleSetMap(this.map);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onEachRiskAreaFeature = (feature, layer) => {
    const { datasRiskArea, t } = this.props;
    let popupContent = '';

    datasRiskArea.map(data => {
      // console.log(layer.feature.properties.TAMBON_IDN, ' | ',  data.tambon_id);
      // console.log('data : ', data);
      if (layer.feature.properties.TAMBON_IDN === data.tambon_id) {
        popupContent = `
          ${t('ตำบล')} : ${layer.feature.properties.TAM_NAM_T} <br/>
          ${t('อำเภอ')} : ${layer.feature.properties.AMPHOE_T} <br/>
          ${t('จังหวัด')} : ${layer.feature.properties.PROV_NAM_T} <br/>
          ${t('ปริมาณฝนล่วงหน้า 24 ชม.')} : <b>${data.accrf} มม.</b>
        `;
        layer.bindTooltip(popupContent);
      }
      // eslint-disable-next-line no-useless-return
      return;
    });
  }

  // set marker icon based on criteria
  setIcon = (val, type) => {
    const status = RainfallStatusColor(val, type);

    return status.icon;
  }

  getRiskAreaStyle = datas => feature => {
    const arrayRiskArea = datas;
    if (arrayRiskArea.indexOf(feature.properties.TAMBON_IDN) !== -1) {
      return {
        fillColor: '#c70000',
        weight: 0.5,
        opacity: 1,
        color: '#000',
        fillOpacity: 0.7,
      };
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  handleMouseOut() {
    this.setState({ hover: false });
  }

  getGeoJson() {
    // Promise.all([
    //   fetch('json/river/river_main.json'),
    //   fetch('json/boundary/tambon.json'),
    // ])
    //   .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    //   .then(([river, tambon]) => {
    //     this.setState({ geojsonRiver: river, geojsonTambon: tambon });
    //   })
    //   .catch(error => this.setState({ error }));

    Promise.all([
      fetch('json/boundary/tambon.json'),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([tambon]) => {
        this.setState({ geojsonTambon: tambon });
      })
      .catch(error => this.setState({ error }));
  }

  // get dam status
  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(2), type);
    return status;
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle = () => ({
    stroke: true,
    color: '#434348',
    weight: 1,
    fillColor: '#b1b1f7',
    opacity: 1,
    fillOpacity: 0.3,
  })

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  handleDatasFFPIReport = (datas, header) => {
    const {
      handleModalOpen,
    } = this.props;

    const params = {
      header: <p align="center">{`Flash Flood Potential Index ${header[0]}`}</p>,
      content: <FlashFloodList
        datas={datas}
        header={header}
      />,
    };
    handleModalOpen(params);
  }

  handleRainfallChart = (handler, data, type, filterData) => e => {
    const { t } = this.props;
    e.preventDefault();
    const params = {
      header: `${t('กราฟฝน')} ${data.station.tele_station_name.th}`,
      content: <FlashFloodChart
        id={data.station.id}
        provinceId={data.geocode.province_code}
        selectedType={type}
        filterData={filterData}
        year={(data.rainfall_datetime).substring(0, 4)}
        month={(data.rainfall_datetime).substring(5, 7)}
      />,
    };
    handler(params);
  }

  eachData = (data, i) => {
    const {
      classes, datasRainToday,
      handleModalOpen, t,
    } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rainfall_value != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rainfall_value), 'rainfallToday');
      // status colors
      const rainfall = parseFloat(data.rainfall_value).toFixed(0);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfallToday').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfallToday').color.constrastColor;

      return (
        <Marker
          id={data.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, ' ')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {` ${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {` ${tJson(data.geocode.province_name, 'จ.')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${data.rainfall_value}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝนสะสมรายวัน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name, ' ')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} ${t('น.')}`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <Button
                      color="secondary"
                      onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfallYesterday', datasRainToday)}
                      title={t('แสดงกราฟ')}
                      style={{ padding: 0 }}
                    >
                      <FaChartLine className={classes.rightIcon} />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} ${tJson(data.geocode.province_name, 'จ.')}`}
                <br />
                {`${t('ข้อมูล')}:${tJson(data.agency.agency_name, ' ')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  highlightFeature = e => {
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 0.6,
      stroke: true,
      color: '#434348',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    layer.bindTooltip(`<h4>${layer.feature.properties.BASIN_T}</h4>`).openTooltip();
  }

  resetHighlight = e => {
    this.refBoundaryBasin.current.leafletElement.resetStyle(e.target);
  }

  onEachBasinFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });
  }

  handleBoxToggle = () => this.setState({ hover: true });

  handleSetURL = event => {
    const val = event.target.value;
    // this.setState({ tifValue: val });
    this.refCompRadar.current.leafletElement.setURL(`${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/${val}`);
  }

  handleClickLegendRisk = () => {
    const { openLegendRisk } = this.state;
    if (openLegendRisk === false) {
      this.setState({ openLegendRisk: true });
    } else {
      this.setState({ openLegendRisk: false });
    }
  };

  handleClickLegendFcstRain = () => {
    const { openLegendFcstRain } = this.state;
    if (openLegendFcstRain === false) {
      this.setState({ openLegendFcstRain: true });
    } else {
      this.setState({ openLegendFcstRain: false });
    }
  };

  handleClickLegendObsRain = () => {
    const { openLegendObsRain } = this.state;
    if (openLegendObsRain === false) {
      this.setState({ openLegendObsRain: true });
    } else {
      this.setState({ openLegendObsRain: false });
    }
  };

  handleClickLegendRainfall = () => {
    const { openLegendRainfall } = this.state;
    if (openLegendRainfall === false) {
      this.setState({ openLegendRainfall: true });
    } else {
      this.setState({ openLegendRainfall: false });
    }
  };

  toggleBox = () => {
    const { isBoxVisible } = this.state;
    this.setState({ isBoxVisible: isBoxVisible === false });

    const layers = document.getElementsByClassName('leaflet-control-layers-expanded');
    if (isBoxVisible === true) {
      for (let index = 0; index < layers.length; index++) {
        document.getElementsByClassName('leaflet-control-layers-expanded')[index].style.display = 'none';
      }
    } else {
      for (let index = 0; index < layers.length; index++) {
        document.getElementsByClassName('leaflet-control-layers-expanded')[index].style.display = 'block';
      }
    }
  };

  handleClickBoxDetail = () => {
    const { openBoxDetail } = this.state;
    if (openBoxDetail === false) {
      this.setState({ openBoxDetail: true });
    } else {
      this.setState({ openBoxDetail: false });
    }
  };

  render() {
    const {
      error, geojsonTambon, openLegendRisk, openLegendFcstRain,
      openLegendObsRain, openLegendRainfall, hover, isBoxVisible, initActive, isBoxDetail, openBoxDetail,
    } = this.state;
    const {
      location, zoom, datas, header, datasRainToday, arrayTambonId, dataStorm, t, width,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <div className="text-center">{error.message}</div>
      );
    }
    const wrfUrlObsRain = `${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/obs_rain.tif`;
    const wrfUrlFcstRain = `${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/fcst_rain.tif`;

    const layers = document.getElementsByClassName('leaflet-control-layers-expanded');
    if (isWidthDown('sm', width) && initActive === false) {
      for (let index = 0; index < layers.length; index++) {
        document.getElementsByClassName('leaflet-control-layers-expanded')[index].style.display = 'none';
        if(index > 2 ){
        this.setState({ initActive: true, isBoxVisible: false });
        }
      }
    }

    const tooltipStyle = {
      display: hover ? 'block' : 'none',
      maxWidth: '337px',
      padding: '10px',
      wordBreak: 'break-word',
    };

    const { classes } = this.props;
    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          ref={this.refMap}
          style={{
            height: window.height,
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
          }}
        >
          <CurrentLocation mapRef={this.refMap} showOnly={false} />

          <LayersControl position="topleft">
            <BaseLayer name="OSM (default)" checked>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                id="OpenStreetMap.Mapnik"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </BaseLayer>
            <BaseLayer name="ESRI">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
              />
            </BaseLayer>
            <BaseLayer name="Carto">
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              />
            </BaseLayer>
            <Overlay name={t('เส้นแม่น้ำ')} checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
          </LayersControl>

          <Control position="topleft">
            <Hidden mdUp>
              <Button
                onClick={this.toggleBox}
                style={{
                  display: 'flex',
                  backgroundColor: 'white',
                  zIndex: 99999,
                }}
              >
                {t('ชั้นข้อมูล')}
                {isBoxVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            </Hidden>
            <LayersControl position="topleft" collapsed={false}>
              <Overlay key="RiskArea" name="Risk Area" checked>
                <LayerGroup ref={this.layer}>
                  <GeoJSON
                    key={uniqueId()}
                    data={geojsonTambon}
                    style={this.getRiskAreaStyle(arrayTambonId)}
                    onEachFeature={this.onEachRiskAreaFeature}
                  />
                </LayerGroup>
              </Overlay>
            </LayersControl>
            <StormTwcLayer
              layerRef={this.refStrom}
              datas={dataStorm}
            />
            <PlottyGeotiffLayer
              layerRef={this.refGeotiff1}
              layerMap={this.refMap}
              url={wrfUrlFcstRain}
              options={fcstRainOptions}
              name="Acc. Fcst. Rain 1 Day"
            />
            <PlottyGeotiffLayer
              layerRef={this.refGeotiff2}
              url={wrfUrlObsRain}
              options={obsRainOptions}
              name="Acc. Obs. Rain 5 Day"
            />
            <LayersControl position="topleft" collapsed={false}>
              <Overlay key="floodForecast1" name="Telemetering Station">
                <LayerGroup ref={this.layer}>
                  {datasRainToday.map(this.eachData)}
                </LayerGroup>
              </Overlay>
            </LayersControl>
          </Control>

          <Control position="topleft">
            <div
              style={{
                backgroundColor: 'white', padding: '8px', borderRadius: '9px', width: '3em',
              }}
              onBlur={() => this.setState({ hover: true })}
              onFocus={() => this.setState({ hover: false })}
              onMouseEnter={this.handleBoxToggle}
              onMouseOut={this.handleMouseOut}
            >
              Info
            </div>
            <Paper>
              <div style={tooltipStyle}>
                {'ระบบเตือนภัยน้ำท่วมฉับพลัน เป็นระบบที่ใช้คาดการณ์พื้นที่เสี่ยงน้ำท่วมฉับพลัน ที่จะเกิดขึ้นใน 24 ชม. ข้างหน้า โดยใช้เทคนิคการซ้อนทับข้อมูล 3 ข้อมูล ได้แก่ 1) ดัชนีศักยภาพการเกิดน้ำท่วมฉับพลัน (FFPI Index) 2) สถานะระดับความชื้นสะสมในดิน (AMC Class) และ 3) ฝนคาดการณ์ (WRF-ROMS) โดยระบบมีการประมวลผลรายวัน และแจ้งเตือนพื้นที่ถึงระดับตำบล พร้อมมีตารางและกราฟสรุปพื้นที่เสี่ยงน้ำท่วมฉับพลัน'}
              </div>
            </Paper>
          </Control>
          <img src={banner} alt="banner" className={classes.styleBanner} />
          <Control position="topright" style={{ marginTop: 30 }}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                position="right"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  backgroundColor: 'white',
                  zIndex: 99999,
                  // textTransform: 'none',
                  // marginTop: '40%',
                }}
                aria-haspopup="true"
                onClick={this.handleClickBoxDetail}
              >
                {t('รายละเอียด')}
                {openBoxDetail ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            </Box>
            {openBoxDetail ? (
              <>
                <Box
                  style={{
                    backgroundColor: 'white', padding: '8px', borderRadius: '9px', marginBottom: 20, marginTop: '5px',
                  }}
                >
                  <strong>Flash Flood Potential Index</strong>
                  <br />
                  {header[0]}
                  <br />
                  {header[1]}
                </Box>

                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'white',
                    zIndex: 99999,
                    textTransform: 'none',
                  }}
                  aria-haspopup="true"
                  onClick={() => this.handleDatasFFPIReport(datas, header)}
                  startIcon={<FormatListBulletedIcon />}
                >
                  Detail of Flash Flood Risk Area
                </Button>
              </>
            )
              : null}
          </Control>
          <Control position="bottomright">
            {/* Legend */}
            {openLegendRisk ? <LegendRisk /> : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={this.handleClickLegendRisk}
              endIcon={<ArrowDropUpIcon />}
            >
              Risk Area (mm.)
            </Button>
          </Control>
          <Control position="bottomright">
            {openLegendFcstRain ? <LegendFcstRain /> : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={this.handleClickLegendFcstRain}
              endIcon={<ArrowDropUpIcon />}
            >
              Fcst Rain (mm)
            </Button>
          </Control>
          <Control position="bottomright">
            {openLegendObsRain ? <LegendObsRain /> : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={this.handleClickLegendObsRain}
              endIcon={<ArrowDropUpIcon />}
            >
              Obs Rain (mm)
            </Button>
          </Control>
          <Control position="bottomright">
            {openLegendRainfall ? <LegendRainfall /> : null}
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={this.handleClickLegendRainfall}
              endIcon={<ArrowDropUpIcon />}
            >
              Rainfall (mm)
            </Button>
          </Control>
        </Map>
      </>
    );
  }
}

FlashFloodMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  datasRiskArea: PropTypes.array.isRequired,
  dataStorm: PropTypes.array.isRequired,
  arrayTambonId: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  header: PropTypes.array.isRequired,
  datasRainToday: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(FlashFloodMap);
