/* eslint-disable no-unused-vars */
import React, { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import moment from 'moment';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';
// import CircularProgressbar from 'react-circular-progressbar';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

// map
import L from 'leaflet';
import {
  Map, Marker, Popup, TileLayer, Tooltip, GeoJSON, LayerGroup, LayersControl, WMSTileLayer,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
import 'react-leaflet-fullscreen/dist/styles.css';
// import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';
import FullscreenControl from 'react-leaflet-fullscreen';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { withTranslation } from 'react-i18next';
import tJson from '../../utils/TJson';
import { FaChartLine, FaSpinner } from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

// color
import RainfallStatusColor from './RainfallStatusColor';
import RainfallContext from '../../contexts/rainfallContext';
import RainfallChart from './RainfallChart';

// icon
import { HiiRainIcon } from '../../utils/Icon';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import { styles } from '../../styles/Style';
// import RainfallMonthLegend from './RainfallMonthLegend';
// import RainfallYearLegend from './RainfallYearLegend';
import RainfallLegend from './RainfallLegend';
import RainfallLegend3Day from './RainfallLegend3Day';
import CurrentLocation from '../currentlocation/CurrentLocation';

const { BaseLayer, Overlay } = LayersControl; // BaseLayer

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;

class RainfallMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.rainfall24hLayer = createRef();
    this.rainfall24hLayer1 = createRef();
    this.rainfall24hLayer2 = createRef();
    this.rainfall24hLayer3 = createRef();
    this.rainfall24hLayer4 = createRef();
    this.rainfall24hLayer5 = createRef();
    this.rainfall24hLayer6 = createRef();
    this.rainfall24hLayer7 = createRef();
    this.rainfallTodayLayer = createRef();
    this.rainfallYesterdayLayer = createRef();
    this.rainfallMonthLayer = createRef();
    this.rainfallYearLayer = createRef();
    this.rainfall3DayLayer = createRef();
    this.rainfall5DayLayer = createRef();
    this.rainfall7DayLayer = createRef();
    this.rainfall15DayLayer = createRef();

    this.state = {
      error: null,
      currentHover: 'ชี้เส้นลำน้ำเพื่อแสดงชื่อ',
      height: this.setHeightMap(),
      zoom: this.setZoomMap(),
      // rainfallYesterdayDatas: props.datasRainYesterday,
      // rainMonthlyDatas: props.datasRainMonth,
      // rainfallYearDatas: props.datasRainYear,
      geojsonRiver: [],
      geojsonProvince: [],
      geojsonBasin: [],
      loading: false,
    };

    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.getGeoJson = this.getGeoJson.bind(this);
    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;
    this.getGeoJson();
    this._mounted = true;
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  shouldComponentUpdate(nextProps) {
    const {
      searchRainfall24,
      searchRainToday,
      searchRainYesterday,
      searchRainMonth,
      searchRainYear,
      searchRain3Day,
      searchRain5Day,
      searchRain7Day,
      searchRain15Day,
      value,
      areaName,
      type,
    } = this.props;
    if ((searchRainfall24 === nextProps.searchRainfall24)
      && (searchRainToday === nextProps.searchRainToday)
      && (searchRainYesterday === nextProps.searchRainYesterday)
      && (searchRainMonth === nextProps.searchRainMonth)
      && (searchRainYear === nextProps.searchRainYear)
      && (searchRain3Day === nextProps.searchRain3Day)
      && (searchRain5Day === nextProps.searchRain5Day)
      && (searchRain7Day === nextProps.searchRain7Day)
      && (searchRain15Day === nextProps.searchRain15Day)
      && (value === nextProps.value)
      && (areaName === nextProps.areaName)
      && (type === nextProps.type)) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const { value, areaName } = this.props;

    // eslint-disable-next-line no-underscore-dangle
    let obj = this.refBoundaryProvince.current.leafletElement._layers;

    // thailand
    if (areaName === '') {
      const { location, zoom } = this.props;

      this.map.options.zoomSnap = 1.8;
      // this.map.options.zoomSnap = 5.65;
      this.map.setView(location, zoom);

      return;
    }

    if (value !== prevProps.value || areaName !== prevProps.areaName) {
      if (value === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }

      // get feature
      // eslint-disable-next-line no-underscore-dangle
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          this.map.options.zoomSnap = 0.8;

          // province
          if (value === '0') {
            const provCode = obj[key].feature.properties.prov_code;
            if (provCode.toString() === areaName) {
              this.map.fitBounds(obj[key].getBounds());
            }
          } else { // basin
            const basinCode = obj[key].feature.properties.BASIN_CODE;
            if (basinCode === areaName) {
              this.map.fitBounds(obj[key].getBounds());
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onEachRiverFeature = (feature, layer) => {
    layer.on({
      mouseover: e => {
        const myLayer = e.target;

        if (!L.Browser.ie && !L.Browser.opera) {
          myLayer.bringToFront();
        }
        this.setState({ currentHover: layer.feature.properties.STR_NAMT });
      },
    });
  }

  setHeightMap() {
    const { width } = this.props;
    // let heightMap = 724;
    let heightMap = 641;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 641;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 641;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 550;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 5;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 4;
    }
    return zoom;
  }

  // set marker icon based on criteria
  setIcon = (val, type) => {
    const status = RainfallStatusColor(val, type);

    return status.icon;
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle(feature) {
    const { value, areaName } = this.props;

    if (value === '1' && areaName) {
      if (feature.properties.BASIN_CODE === areaName) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getProvinceStyle(feature) {
    const { value, areaName } = this.props;
    if (value === '0' && areaName) {
      if (feature.properties.prov_code === areaName) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getGeoJson() {
    Promise.all([
      fetch(`${initPath}json/river/river_main.json`),
      fetch(`${initPath}json/boundary/thailand.json`),
      fetch(`${initPath}json/boundary/basin.json`),
    ])
      .then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
      .then(([river, province, basin]) => {
        this.setState({ geojsonRiver: river, geojsonProvince: province, geojsonBasin: basin });
      })
      .catch(error => this.setState({ error }));
  }

  // get dam status
  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(1), type);
    return status;
  }

  handleRainfallChart = (handler, data, type, filterData) => e => {
    e.preventDefault();
    let params;
    const { t } = this.props;
    if (type === 'rainfallYesterday') {
      params = {
        header: `${t('กราฟฝน')} ${tJson(data.tele_station_name, 'สถานี')}`,
        content: <RainfallChart
          id={data.tele_station_id}
          provinceId={data.province_code}
          selectedType={type}
          filterData={filterData}
        />,
      };
    } else if (type === 'rainfall3Day' || type === 'rainfall7Day') {
      params = {
        header: `${t('กราฟฝน')} ${tJson(data.station.tele_station_name, 'สถานี')}`,
        content: <RainfallChart
          filterData={filterData}
          id={data.station.id}
          provinceId={data.geocode.province_code}
          selectedType={type}
          year={moment(data.rainfall_datetime).format('YYYY')}
          month={moment(data.rainfall_datetime).format('MM')}
        />,
      };
    } else {
      params = {
        header: `${t('กราฟฝน')} ${tJson(data.station.tele_station_name, 'สถานี')}`,
        content: <RainfallChart
          id={data.station.id}
          provinceId={data.geocode.province_code}
          selectedType={type}
          filterData={filterData}
          year={(data.rainfall_datetime).substring(0, 4)}
          month={(data.rainfall_datetime).substring(5, 7)}
        />,
      };
    }
    handler(params);
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  handleModalRainfallMonth = handler => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      open: true,
      header: `${t('เกณฑ์ฝนสะสมรายเดือน')}`,
      content: <Grid item xs align="center"><img src={`${initPath}images/legend/legend_rainfallMonth.png`} alt={t('เกณฑ์ฝนสะสมรายเดือน')} /></Grid>,
    };
    handler(params);
  };

  handleModalRainfallYear = handler => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      open: true,
      header: `${t('เกณฑ์ฝนสะสมรายปี')}`,
      content: <Grid item xs align="center"><img src={`${initPath}images/legend/legend_rainfallYear.png`} alt={t('เกณฑ์ฝนสะสมรายปี')} /></Grid>,
    };
    handler(params);
  };

  handleModalRainfall3Day = handler => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      open: true,
      header: `${t('เกณฑ์ฝนสะสม')}`,
      content: <Grid item xs align="center"><img src={`${initPath}images/legend/legend_rainfall3day.png`} alt={t('เกณฑ์ฝนสะสม')} style={{ width: '100%' }} /></Grid>,
    };
    handler(params);
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  eachData = (data, i) => {
    const { classes, searchRainfall24, t } = this.props;
    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rain_24h >= 0 && data.station.id > 0) { // && data.rain_24h != null
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rain_24h).toFixed(1), 'rainfall24h');
      // status colors
      // const statusColor = RainfallStatusColor(data.rain_24h);
      const rainfall24h = parseFloat(data.rain_24h).toFixed(1);
      const textRainfallStatus = this.rainfallStatus(rainfall24h, 'rainfall24h').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall24h, 'rainfall24h').color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {' '}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rain_24h).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝนสะสม 24 ย้อนหลัง')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} น.`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    {data.agency.agency_shortname.en !== 'DWR' ? (
                      <RainfallContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfall24h', searchRainfall24)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </RainfallContext.Consumer>
                    ) : (
                      <RainfallContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            title={t('ไม่มีกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} style={{ color: '#9e9e9e' }} />
                          </Button>
                        )}
                      </RainfallContext.Consumer>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.station.sponsor_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.station.sponsor_by }
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {/* {`${tJson(data.geocode.province_name, 'จ.')}`} */}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                {data.station.sponsor_by && <div>{`สนับสนุนโดย ${data.station.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfallToday = (data, i) => {
    const { classes, searchRainToday, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rainfall_value != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rainfall_value).toFixed(1), 'rainfallToday');
      // status colors
      // const statusColor = RainfallStatusColor(data.rainfall_value);
      const rainfall = parseFloat(data.rainfall_value).toFixed(1);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfallToday').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfallToday').color.constrastColor;

      return (
        <Marker
          id={data.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center">
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {/* {console.log(data.geocode.province_name)} */}
                      {/* {`${tJson(data.geocode.province_name, 'จ.')}`} */}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rainfall_value).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} น.`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    {data.agency.agency_shortname.en !== 'DWR' ? (
                      <RainfallContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfallToday', searchRainToday)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </RainfallContext.Consumer>
                    ) : (
                      <RainfallContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            title={t('ไม่มีกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} style={{ color: '#9e9e9e' }} />
                          </Button>
                        )}
                      </RainfallContext.Consumer>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.station.sponsor_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.station.sponsor_by }
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')}${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')}${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')}${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                {data.station.sponsor_by && <div>{`สนับสนุนโดย ${data.station.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfallYesterday = (data, i) => {
    const { classes, searchRainYesterday, t } = this.props;

    if (data.tele_station_lat && data.tele_station_long
      && data.rainfall_value != null) {
      // marker position
      const position = [parseFloat(data.tele_station_lat),
      parseFloat(data.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rainfall_value).toFixed(1), 'rainfallYesterday');
      // status colors
      const rainfall = parseFloat(data.rainfall_value).toFixed(1);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfallYesterday').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfallYesterday').color.constrastColor;

      return (
        <Marker
          id={data.tele_station_id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.tele_station_id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.amphoe_name, 'อ.')}`}
                      {`${tJson(data.province_name, 'จ.', data.area_code, data.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rainfall_value).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} น.`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <RainfallContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfallYesterday', searchRainYesterday)}
                          title={t('ไม่มีกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </RainfallContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.sponsor_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.sponsor_by }
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.province_name, 'จ.', data.area_code, data.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency_name, 'หน่วยงาน')}`}
                <br />
                {data.sponsor_by && <div>{`สนับสนุนโดย ${data.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfallMonth = (data, i) => {
    const { classes, searchRainMonth, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rainfall_value != null && data.rainfall_value > 0) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rainfall_value).toFixed(1), 'rainfallMonth');
      // status colors
      // const statusColor = RainfallStatusColor(data.rainfall_value);
      const rainfall = parseFloat(data.rainfall_value).toFixed(1);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfallMonth').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfallMonth').color.constrastColor;

      return (
        <Marker
          id={data.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rainfall_value).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} น.`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <RainfallContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfallMonth', searchRainMonth)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </RainfallContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.station.sponsor_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.station.sponsor_by }
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                {data.station.sponsor_by && <div>{`สนับสนุนโดย ${data.station.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfallYear = (data, i) => {
    const { classes, searchRainYear, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rainfall_value != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rainfall_value).toFixed(0), 'rainfallYear');
      // status colors
      const rainfall = parseFloat(data.rainfall_value).toFixed(0);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfallYear').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfallYear').color.constrastColor;

      return (
        <Marker
          id={data.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rainfall_value).toFixed(0)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} น.`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <RainfallContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfallYear', searchRainYear)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </RainfallContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.station.sponsor_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.station.sponsor_by }
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                {data.station.sponsor_by && <div>{`สนับสนุนโดย ${data.station.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfall3Day = (data, i) => {
    const { classes, searchRain3Day, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rain_3d != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rain_3d).toFixed(2), 'rainfall3Day');
      // status colors
      const rainfall = parseFloat(data.rain_3d).toFixed(2);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfall3Day').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfall3Day').color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rain_3d).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_end_date} `}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <RainfallContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfall3Day', searchRain3Day)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </RainfallContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.station.sponsor_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.station.sponsor_by }
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                {data.station.sponsor_by && <div>{`สนับสนุนโดย ${data.station.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfall5Day = (data, i) => {
    const { classes, searchRain5Day, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rain_5d != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rain_5d).toFixed(2), 'rainfall5Day');
      // status colors
      const rainfall = parseFloat(data.rain_5d).toFixed(2);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfall5Day').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfall5Day').color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rain_5d).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_end_date} `}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <RainfallContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfall5Day', searchRain5Day)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </RainfallContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.sponsored_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.sponsored_by}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                <div>{data.sponsored_by && `สนับสนุนโดย ${data.sponsored_by}`}</div>
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfall7Day = (data, i) => {
    const { classes, searchRain7Day, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rain_7d != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rain_7d).toFixed(2), 'rainfall7Day');
      // status colors
      const rainfall = parseFloat(data.rain_7d).toFixed(2);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfall7Day').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfall7Day').color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rain_7d).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_end_date} `}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <RainfallContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfall7Day', searchRain7Day)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </RainfallContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.station.sponsor_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.station.sponsor_by }
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                {data.station.sponsor_by && <div>{`สนับสนุนโดย ${data.station.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfall15Day = (data, i) => {
    const { classes, searchRain15Day, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rain_15d != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rain_15d).toFixed(2), 'rainfall5Day');
      // status colors
      const rainfall = parseFloat(data.rain_15d).toFixed(2);
      const textRainfallStatus = this.rainfallStatus(rainfall, 'rainfall15Day').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfall15Day').color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rain_15d).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝน')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_end_date} `}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <RainfallContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfall15Day', searchRain15Day)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </RainfallContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data.sponsored_by && (
              <>
                <Divider />
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`สนับสนุนโดย`}<br />
                      {data.sponsored_by}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                <br />
                <div>{data.sponsored_by && `สนับสนุนโดย ${data.sponsored_by}`}</div>
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  renderLayer(context) {
    let layer = '';
    const {
      searchRainfall24,
      searchRainToday,
      searchRainYesterday,
      searchRainMonth,
      searchRainYear,
      searchRain3Day,
      searchRain5Day,
      searchRain7Day,
      searchRain15Day,
      type,
      t,
    } = this.props;

    // [Ticket #424] แก้ไขให้แสดงผลแผนที่ในสถานะที่ไม่มีข้อมูลในแค่ละจังหวัด
    // searchRainfall24.length > 0
    if (type === 'rainfall24h') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfall24h" name={t('ฝน24ชั่วโมง')} checked="true">
            <LayerGroup ref={this.rainfall24hLayer}>
              {searchRainfall24.sort((a, b) => a.rain_24h - b.rain_24h).map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }
    // searchRainToday.length > 0
    if (type === 'rainfallToday') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfallToday" name={t('ฝนวันนี้')} checked="true">
            <LayerGroup ref={this.rainfallTodayLayer}>
              {searchRainToday.sort((a, b) => a.rainfall_value - b.rainfall_value)
                .map(this.eachDataRainfallToday)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }
    // searchRainYesterday.length > 0
    if (type === 'rainfallYesterday') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfallYesterday" name={t('ฝนวานนี้')} checked="true">
            <LayerGroup ref={this.rainfallYesterdayLayer}>
              {searchRainYesterday.sort((a, b) => a.rainfall_value - b.rainfall_value)
                .map(this.eachDataRainfallYesterday)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }
    // searchRainMonth.length > 0
    if (type === 'rainfallMonth') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfallMonth" name={t('ฝนรายเดือน')} checked="true">
            <LayerGroup ref={this.rainfallMonthLayer}>
              {searchRainMonth.sort((a, b) => a.rainfall_value - b.rainfall_value)
                .map(this.eachDataRainfallMonth)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }
    // searchRainYear.length > 0
    if (type === 'rainfallYear') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfallYear" name={t('ฝนรายปี')} checked="true">
            <LayerGroup ref={this.rainfallYearLayer}>
              {searchRainYear.sort((a, b) => a.rainfall_value - b.rainfall_value)
                .map(this.eachDataRainfallYear)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }
    // searchRain3Day.length > 0;
    if (type === 'rainfall3Day') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfall3Day" name={t('ฝน 3 วัน')} checked="true">
            <LayerGroup ref={this.rainfall3DayLayer}>
              {searchRain3Day.sort((a, b) => a.rain_3d - b.rain_3d)
                .map(this.eachDataRainfall3Day)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }

    if (type === 'rainfall5Day') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfall5Day" name={t('ฝน 5 วัน')} checked="true">
            <LayerGroup ref={this.rainfall5DayLayer}>
              {searchRain5Day.sort((a, b) => a.rain_5d - b.rain_5d)
                .map(this.eachDataRainfall5Day)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }

    // searchRain7Day.length > 0;
    if (type === 'rainfall7Day') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfall7Day" name={t('ฝน 7 วัน')} checked="true">
            <LayerGroup ref={this.rainfall7DayLayer}>
              {searchRain7Day.sort((a, b) => a.rain_7d - b.rain_7d)
                .map(this.eachDataRainfall7Day)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }

    if (type === 'rainfall15Day') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="rainfall15Day" name={t('ฝน 15 วัน')} checked="true">
            <LayerGroup ref={this.rainfall15DayLayer}>
              {searchRain15Day.sort((a, b) => a.rain_15d - b.rain_15d)
                .map(this.eachDataRainfall15Day)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }

    if (type === 'dashBoard') {
      layer = (
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri_WorldStreetMap">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <Overlay key="th2" name={t('ภาคกลาง')}>
            <LayerGroup ref={this.rainfall24hLayer2}>
              {context.datas.filter(data => {
                const geocode = data.geocode.area_code;
                return (geocode === '2');
              }).map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay key="th3" name={t('ภาคเหนือ')}>
            <LayerGroup ref={this.rainfall24hLayer3}>
              {context.datas.filter(data => {
                const geocode = data.geocode.area_code;
                return (geocode === '3');
              }).map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay key="th4" name={t('ภาคตะวันออกเฉียงเหนือ')}>
            <LayerGroup ref={this.rainfall24hLayer4}>
              {context.datas.filter(data => {
                const geocode = data.geocode.area_code;
                return (geocode === '4');
              }).map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay key="th5" name={t('ภาคตะวันตก')}>
            <LayerGroup ref={this.rainfall24hLayer5}>
              {context.datas.filter(data => {
                const geocode = data.geocode.area_code;
                return (geocode === '2');
              }).map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay key="th6" name={t('ภาคตะวันออก')}>
            <LayerGroup ref={this.rainfall24hLayer6}>
              {context.datas.filter(data => {
                const geocode = data.geocode.area_code;
                return (geocode === '2');
              }).map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay key="th7" name={t('ภาคใต้')}>
            <LayerGroup ref={this.rainfall24hLayer7}>
              {context.datas.filter(data => {
                const geocode = data.geocode.area_code;
                return (geocode === '5');
              }).map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay key="th" name={t('ภาพรวมประเทศไทย')} checked="true">
            <LayerGroup ref={this.rainfall24hLayer1}>
              {context.datas.map(this.eachData)}
            </LayerGroup>
          </Overlay>
          <Overlay name={t('เส้นแม่น้ำ')} checked>
            <WMSTileLayer
              url={`${initPathWMS}wms`}
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:river_thailand"
              srs="EPSG:4326"
              format="image/png"
              tiled
            />
          </Overlay>
        </LayersControl>
      );
    }
    return layer;
  }

  render() {
    const {
      error, height, geojsonRiver, currentHover, zoom, geojsonProvince, geojsonBasin, loading,
    } = this.state;
    const {
      location, classes, type, t,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }
    let loadingMsg = '';
    if (loading === true) {
      loadingMsg = (
        <FaSpinner size={70} />
      );
    }

    return (
      <Fragment key="key">
        {errorMsg}
        {loadingMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomSnap={5.65}
          ref={this.refMap}
          style={{ height }}
          gestureHandling
        >
          {/* <Control position="bottomleft">
            <div className="info">
              <Typography variant="body1">{currentHover}</Typography>
            </div>
          </Control> */}
          <GeoJSON
            key={uniqueId()}
            data={geojsonBasin}
            style={this.getBasinStyle}
            ref={this.refBoundaryBasin}
            onEachFeature={this.onEachBasinFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            onEachFeature={this.onEachProvinceFeature}
          />
          {/* <GeoJSON
            key={uniqueId()}
            data={geojsonRiver}
            style={this.getRiverStyle()}
            onEachFeature={this.onEachRiverFeature}
          /> */}
          <RainfallContext.Consumer>
            {context => (
              this.renderLayer(context)
            )}
          </RainfallContext.Consumer>
          {/* <FullscreenControl position="topleft" /> */}
          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />
        </Map>
        {(type === 'rainfall24h' || type === 'rainfallToday' || type === 'rainfallYesterday') && (
          <RainfallLegend classes={classes} />
        )}
        {(type === 'rainfall3Day' || type === 'rainfall7Day' || type === 'rainfall5Day' || type === 'rainfall15Day') && (
          <RainfallLegend3Day classes={classes} />
        )}
        {type === 'rainfallMonth' && (
          <RainfallContext.Consumer>
            {({ handleModalOpen }) => (
              <Button className={classes.button} variant="outlined" onClick={this.handleModalRainfallMonth(handleModalOpen)} title={t('แสดงเกณฑ์')} style={{ width: '100%' }}>
                แสดงเกณฑ์
              </Button>
            )}
          </RainfallContext.Consumer>
        )}
        {type === 'rainfallYear' && (
          <RainfallContext.Consumer>
            {({ handleModalOpen }) => (
              <Button className={classes.button} variant="outlined" onClick={this.handleModalRainfallYear(handleModalOpen)} title={t('แสดงเกณฑ์')} style={{ width: '100%' }}>
                แสดงเกณฑ์
              </Button>
            )}
          </RainfallContext.Consumer>
        )}
        {(type === 'rainfall3Day' || type === 'rainfall7Day' || type === 'rainfall5Day' || type === 'rainfall15Day') && (
          <RainfallContext.Consumer>
            {({ handleModalOpen }) => (
              <Button className={classes.button} variant="outlined" onClick={this.handleModalRainfall3Day(handleModalOpen)} title={t('แสดงเกณฑ์')} style={{ width: '100%' }}>
                แสดงเกณฑ์
              </Button>
            )}
          </RainfallContext.Consumer>
        )}
      </Fragment>
    );
  }
}

RainfallMap.propTypes = {
  classes: PropTypes.object.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  searchRainfall24: PropTypes.array.isRequired,
  searchRainToday: PropTypes.array.isRequired,
  searchRainYesterday: PropTypes.array.isRequired,
  searchRainMonth: PropTypes.array.isRequired,
  searchRainYear: PropTypes.array.isRequired,
  searchRain3Day: PropTypes.array.isRequired,
  searchRain5Day: PropTypes.array.isRequired,
  searchRain7Day: PropTypes.array.isRequired,
  searchRain15Day: PropTypes.array.isRequired,
  datasRainToday: PropTypes.array.isRequired,
  datasRainYesterday: PropTypes.array.isRequired,
  datasRainMonth: PropTypes.array.isRequired,
  datasRainYear: PropTypes.array.isRequired,
  datasRain3Day: PropTypes.array.isRequired,
  datasRain5Day: PropTypes.array.isRequired,
  datasRain7Day: PropTypes.array.isRequired,
  datasRain15Day: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  areaName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation('translations', { withRef: true }))(RainfallMap);
