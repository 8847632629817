import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: '100%',
    padding: 10,
  },
  label: {
    fontWeight: 300,
    fontSize: '0.9em',
  },
});
class CompLayer extends Component {
  render() {
    const {
      classes,
      handlBaseMapChecked,
      basemapChecked,
    } = this.props;
    const handleChange = event => {
      handlBaseMapChecked(event.target.value);
    };

    return (
      <List className={classes.root}>
        <RadioGroup aria-label="rain" name="rain" value={basemapChecked} onChange={handleChange}>
          <FormControlLabel
            value="0"
            control={
              <Radio style={{ color: basemapChecked === '0' ? '#3980c0' : '#ccc' }} />
            }
            label={
              <Box className={classes.label}>OpenStreetMap</Box>
            }
          />
          <FormControlLabel
            value="1"
            control={
              <Radio style={{ color: basemapChecked === '1' ? '#3980c0' : '#ccc' }} />
            }
            label={
              <Box className={classes.label}>Esri_WorldStreetMap</Box>
            }
          />
          <FormControlLabel
            value="2"
            control={
              <Radio style={{ color: basemapChecked === '2' ? '#3980c0' : '#ccc' }} />
            }
            label={
              <Box className={classes.label}>Google Street</Box>
            }
          />
          <FormControlLabel
            value="3"
            control={
              <Radio style={{ color: basemapChecked === '3' ? '#3980c0' : '#ccc' }} />
            }
            label={
              <Box className={classes.label}>Google Hybrid</Box>
            }
          />
        </RadioGroup>
        <Divider />
      </List>
    );
  }
}

CompLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  handlBaseMapChecked: PropTypes.func.isRequired,
  basemapChecked: PropTypes.string.isRequired,
};

export default withStyles(styles)(CompLayer);
