import React, { Component } from 'react';
import PropTypes from 'prop-types';

// moment
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// lodash
import {
  uniqueId,
} from 'lodash';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// icon .
import {
  FaSpinner,
  FaCalendarAlt,
} from '../../../utils/Fontawesome';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

const initPath1 = process.env.MIX_APP_PATH;

const width = '90%';

// lightbox
const images = [];

class SstHii extends Component {
  constructor(props) {
    super(props);
    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      imgs: [],
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({
      isLoading: true,
    });
    // get data from ws
    this.getData();
  }

  getData() {
    fetch(`${process.env.MIX_API_URL}public/weather_img/sst_2w_haii`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: [result.data],
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes, t } = this.props;
    const {
      isLoading,
      error,
      imgs,
      photoIndex,
      isOpen,
    } = this.state;

    const link = `${initPath1}weather/sea/sst/hii/history/`;

    return (
      <>
        {error && <Grid className="text-center">{error.message}</Grid>}

        <Grid item xs={12} md={6} lg={6}>
          <Box mt={3} display="flex" className={classes.boxTopRadius}>
            <Box flexGrow={1} p={1} display="flex" alignItems="center" justifyContent="center" className={classes.boxTitlePrimary}>
              <Box className={classes.titleBar}>
                {t('แผนภาพการเปลี่ยนแปลงของอุณหภูมิผิวน้ำทะเล')}
              </Box>
            </Box>
            <Box p={1}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <Button
                  href={link}
                  className={classes.outlinedOldPrimary}
                >
                  <FaCalendarAlt style={{ fontSize: '1.2em' }} />
                </Button>
              </Tooltip>
            </Box>
          </Box>

          {isLoading === true && (
          <Grid className="text-center">
            <FaSpinner size={35} />
          </Grid>
          )}

          { imgs.map((row, i) => {
            let realImage = '';
            if (row.cover_image.media_path !== '') {
              images.push({
                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.media_path}`,
                title: `${row.description.description_name.th} ${moment(row.cover_image.media_datetime).format('ll')}`,
              });
              realImage = (
                <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                  <Img
                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.media_path}`}
                    width={width}
                    alt={moment(row.cover_image.media_datetime).format('ll')}
                    // className={classes.responsive}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src="https://via.placeholder.com/562x416?text=No+Image"
                  alt="No Image"
                  className={classes.responsive}
                />
              );
            }
            return (
              <Box display="block" className={classes.boxBottomFixibleRadius} alignItems="center" key={uniqueId()}>
                <Box p={1}>
                  {realImage}
                </Box>
                <Box p={1}>
                  <Typography className={classes.dateTime}>
                    {`${t('ล่าสุด')} ${moment(row.cover_image.media_datetime).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.cover_image.media_datetime).format('HH:mm')} ${t('น.')}`}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

SstHii.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(SstHii));
