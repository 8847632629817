import React, { Component } from 'react';
import PropTypes from 'prop-types';

// moment
import moment from 'moment';

// lodash
import {
  uniqueId,
} from 'lodash';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';
import { withTranslation } from 'react-i18next';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

// icon
import {
  FaPlay,
  FaSpinner,
  FaCalendarAlt,
} from '../../../utils/Fontawesome';

import ImageAnimation from '../ImageAnimation';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

// credit tooltip
import CreditTooltip from '../../../utils/CreditTooltip';

const mediaType = [];
mediaType[24] = { index: 0, thumbSize: { w: 506, h: 375 } };
mediaType[156] = { index: 1, thumbSize: { w: 521, h: 387 } };
mediaType[23] = { index: 2, thumbSize: { w: 506, h: 375 } };
mediaType[155] = { index: 3, thumbSize: { w: 506, h: 375 } };

const imageWidth = '100%';
const height = '100%';

// lightbox
let images = [];

class Wave extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      imgs: [],
      valueDropDown: 156,

      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
      open: false,
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({
      isLoading: true,
    });

    // get data from ws
    this.getData();
  }

  getData() {
    fetch(`${process.env.MIX_API_URL}public/weather_img/wave_height_ocean_weather`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleDropDownChange = event => {
    this.setState({
      valueDropDown: event.target.value,
    });
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '50%';
    let heightChart = 'max-content';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = 'max-content';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = 'max-content';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  };

  handleModalAnimation = (agencyId, mediaTypeId, imageType) => e => {
    e.preventDefault();
    const params = {
      header: 'ภาพเคลื่อนไหว',
      content:
        (
          <div style={{
            width: '755px',
            justifyContent: 'center',
          }}
          >
            <ImageAnimation agencyId={agencyId} mediaTypeId={mediaTypeId} imageType={imageType} />
          </div>

        ),
    };

    this.handleModalOpen(params);
  }

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  render() {
    images = [];
    let latestImg = {};
    const { classes, t } = this.props;
    const {
      isLoading,
      error,
      imgs,
      photoIndex,
      isOpen,
      valueDropDown,
      modalHeader,
      modalSubHeader,
      modalContent,
      open,
    } = this.state;
    const { w: thumbWidth, h: thumbHeight } = mediaType[valueDropDown].thumbSize;

    if (imgs.length > 0) {
      latestImg = {
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${imgs[mediaType[valueDropDown].index].cover_image.media_path}`,
        datetime: moment(imgs[mediaType[valueDropDown].index].cover_image.media_datetime).format('LL'),
        agency_name: imgs[mediaType[valueDropDown].index].agency.agency_name.th,
        agency_link: imgs[mediaType[valueDropDown].index].agency.agency_link,
      };

      images.push({
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${imgs[mediaType[valueDropDown].index].cover_image.media_path}`,
        title: `${imgs[mediaType[valueDropDown].index].description.description_name.th.replace('โดย Ocean Weather inc.', '')} ${moment(imgs[mediaType[valueDropDown].index].cover_image.media_datetime).format('ll')}`,
      });
    }

    return (
      <>
        {error && <div className="text-center">{error.message}</div>}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={35} />
          </div>
        )}

        <Grid container spacing={4}>
          <Grid item xs>
            <Box flexGrow={1} p={1} display="flex" alignItems="center" className={classes.boxTopRadiusPrimary}>
              <Box className={classes.titleBar}>
                {t('แผนภาพความสูงและทิศทางของคลื่นทะเล')}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <Box mt={2} display="flex" className={classes.boxTopRadius} alignItems="center">
              <Box flexGrow={1} p={1} display="flex" alignItems="center" justifyContent="center" className={classes.titleBarSelectSea}>
                <Typography variant="h6" style={{ fontFamily: 'Kanit', fontWeight: 500, fontSize: 16 }}>
                  {t('ประเทศไทย')}
                </Typography>
              </Box>
              {imgs.slice(0, 1).map(row => (
                <Box
                  p={1}
                  style={{ borderRight: '1px solid #ccc' }}
                  key={uniqueId()}
                >
                  <Tooltip title="ดูภาพเคลื่อนไหว">
                    <Button
                      className={classes.outlinedOldLeftPrimary}
                      onClick={this.handleModalAnimation(row.agency.agency_id,
                        row.detail[0].media_type_id, '')}
                    >
                      <FaPlay style={{ fontSize: '1em' }} />
                    </Button>
                  </Tooltip>
                </Box>
              ))}
              <Box p={1}>
                <Button
                  href={`${process.env.MIX_APP_PATH}weather/sea/sst/history/24`}
                  className={classes.outlinedOldPrimary}
                >
                  <FaCalendarAlt style={{ fontSize: '1.2em' }} />
                </Button>
              </Box>
            </Box>
            {imgs.slice(0, 1).map(row => {
              let realImage = '';
              const agency = (row.agency.agency_name.th) ? row.agency.agency_name.th : '';
              const link = (row.agency.agency_link) ? row.agency.agency_link : '';
              if (row.cover_image.media_path !== '') {
                images.push({
                  src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.media_path}`,
                  title: `${row.description.description_name.th.replace('โดย Ocean Weather inc.', '')} ${moment(row.cover_image.media_datetime).format('ll')}`,
                });
                realImage = (
                  <CreditTooltip
                    title={agency}
                    link={link}
                  >
                    <Button onClick={() => this.setState({ isOpen: true, photoIndex: 1 })}>
                      <Img
                        src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.media_path}`}
                        width={imageWidth}
                        alt={moment(row.cover_image.media_datetime).format('ll')}
                        className={classes.responsive}
                      />
                    </Button>
                  </CreditTooltip>
                );
              } else {
                realImage = (
                  <Img
                    src="https://via.placeholder.com/506x735?text=No+Image"
                    alt="No Image"
                    className={classes.responsive}
                  />
                );
              }
              return (
                <Box
                  display="block"
                  className={classes.boxBottomRadius}
                  align="center"
                  key={uniqueId()}
                >
                  {realImage}
                  <Typography className={classes.dateTime}>
                    {`${moment(row.cover_image.media_datetime).format('LL')}`}
                  </Typography>
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box mt={2} display="flex" className={classes.boxTopRadius} alignItems="center">
              <Box flexGrow={1} p={1} display="flex" alignItems="center" justifyContent="center" className={classes.titleBarSelectSea}>
                <Select
                  value={valueDropDown}
                  onChange={this.handleDropDownChange}
                  style={{ fontFamily: 'Kanit', fontWeight: 500, fontSize: 16 }}
                >
                  <MenuItem value={156}>{t('มหาสมุทรแปซิฟิกเหนือ')}</MenuItem>
                  <MenuItem value={23}>{t('มหาสมุทรอินเดีย')}</MenuItem>
                  <MenuItem value={155}>{t('ทั่วโลก')}</MenuItem>
                </Select>
              </Box>
              <Box
                p={1}
                style={{ borderRight: '1px solid #ccc' }}
                key={uniqueId()}
              >
                <Tooltip title="ดูภาพเคลื่อนไหว">
                  <Button
                    className={classes.outlinedOldLeftPrimary}
                    onClick={this.handleModalAnimation(56, valueDropDown, '')}
                  >
                    <FaPlay style={{ fontSize: '1em' }} />
                  </Button>
                </Tooltip>
              </Box>
              <Box p={1}>
                <Button
                  href={`${process.env.MIX_APP_PATH}weather/sea/sst/history/${valueDropDown}`}
                  className={classes.outlinedOldPrimary}
                >
                  <FaCalendarAlt style={{ fontSize: '1.2em' }} />
                </Button>
              </Box>
            </Box>
            <Box display="block" className={classes.boxBottomRadius} align="center">
              <CreditTooltip
                title={(latestImg.agency_name) ? latestImg.agency_name : ''}
                link={(latestImg.agency_link) ? latestImg.agency_link : ''}
              >
                <Button onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}>
                  <Img
                    src={latestImg.src}
                    className={classes.responsive}
                    alt="image-last-rain"
                    key={uniqueId()}
                    width={imageWidth}
                    height={height}
                    unloader={(
                      <Img
                        src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                        alt="No Image"
                        className={classes.responsive}
                      />
                    )}
                  />
                </Button>
              </CreditTooltip>
              <Typography className={classes.dateTime}>
                {latestImg.datetime}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleModalClose}
          style={{ align: 'center', justifyContent: 'center' }}
        >
          <div style={this.getModalStyle()} className={classes.paperModal}>
            <Typography variant="h6" id="modal-title">
              {modalHeader}
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              {modalSubHeader}
            </Typography>
            {modalContent}
          </div>
        </Modal>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

Wave.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(Wave);
