// Data : https://fews2.hii.or.th/model-output/data_portal/mwa_salinity/forecast/
// https://fews2.hii.or.th/model-output/data_portal/mwa_salinity/observe/
// โดยค่าใน folder observe สามารถใช้ข้อมูลจาก webservice ได้เป็นข้อมูลชุดเดียวกัน
// Metadata : https://fews2.hii.or.th/model-output/data_portal/metadata/mwa_salinity.csv

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Papa from 'papaparse';
import { uniqueId, maxBy } from 'lodash';

import { withTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { FaSpinner } from '../../../utils/Fontawesome';

require('highcharts/modules/annotations')(Highcharts);
// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class SalinityChart extends Component {
  // static whyDidYouRender = true;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      name: props.name,
      chartOptions: this.getChartOptions(),
      teleStation: [],
      observeData: [],
      forecastData: [],
      statusChartForecast: true,
      statusChartObserve: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getCsvData();
  }

  getChartOptions() {
    const { t } = this.props;

    this.options = {
      title: {
        text: '',
        style: {
          fontSize: '15px',
        },
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: false,
      },
      annotations: [
        {
          xValue: 100,
          yValue: 0.31,
          title: {
            text: t('เฝ้าระวังน้ำดิบ 0.25 กรัม/ลิตร'),
          },
        },
        {
          xValue: 100,
          yValue: 0.56,
          title: {
            text: t('เกณฑ์เพื่อน้ำประปา 0.5 กรัม/ลิตร'),
          },
        },
        {
          xValue: 100,
          yValue: 2.06,
          title: {
            text: t('เกณฑ์เพื่อการเกษตร 2 กรัม/ลิตร'),
          },
        },
      ],
      xAxis: {
        type: 'category',
        categories: [],
        labels: {
          rotation: 270,
        },
        tickInterval: 24,
        plotLines: [
          {
            label: {
              text: 'Time of Forecast',
            },
            color: '#8A8A8A',
            width: 2,
            value: 168,
          },
        ],
        min: 0,
        max: 337,
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        labels: {
          format: '{value:.2f}',
        },
        title: {
          text: t('ความเค็ม (กรัม/ลิตร)'),
        },
        min: 0,
        plotLines: [
          {
            label: {
              text: '',
            },
            color: '#FFA500',
            width: 2,
            value: 0.5,
          },
          {
            label: {
              text: '',
            },
            color: '#FF0000',
            width: 2,
            value: 2,
          },
        ],
        plotBands: [
          {
            from: 0,
            to: 0.25,
            color: 'rgba(0, 100, 0, 0.2)',
            label: {
              text: '<strong>เฝ้าระวังน้ำดิบ 0.25 กรัม/ลิตร</strong>',
              style: {
                color: '#000000',
              },
            },
          },
          {
            from: 0.25,
            to: 0.5,
            color: 'rgba(255, 255, 0, 0.2)',
            label: {
              text: '<strong>เกณฑ์เพื่อน้ำประปา 0.5 กรัม/ลิตร</strong>',
              style: {
                color: '#000000',
              },
            },
          },
          {
            from: 0.5,
            to: 2,
            color: 'rgba(255, 128, 0, 0.2)',
            label: {
              text: '<strong>เกณฑ์พื่อการเกษตร 2 กรัม/ลิตร</strong>',

              style: {
                color: '#000000',
              },
            },
          },
          {
            from: 2,
            to: 'Infinity',
            color: 'rgba(255, 0, 0, 0.2)',
          },
        ],
      },
      legend: {
        enabled: true,
      },
      annotationsOptions: {
        enabledButtons: false,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  async getCsvData() {
    await this.fetchCsv();
  }

  handleStationChange = event => {
    this.setState({ name: event.target.value }, () => {
      this.getCsvData();
    });
  };

  // metadata
  // 0: "S01"
  // 1: "WatPhoTaengNuea"
  // 2: "วัดโพธิ์แตงเหนือ"
  // 3: "14.1306"
  // 4: "100.5238"
  // 5: "cpy"
  // 6: "mwa_salinity"
  // 7: "0"
  // 8: "0.25"
  // 9: "0.5"
  // 10: "2"
  // 11: "ต.โพธิ์แตง"
  // 12: "อ.บางไทร"
  // 13: "จ.พระนครศรีอยุธยา"
  // 14: "t"

  // data
  // 0: "KhlongLatPho"
  // 1: "2020-05-19"
  // 2: "14:00:00"
  // 3: "6.25"

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  fetchCsv() {
    // KhlongLatPho.txt
    // Pakkret.txt
    // PhraNangKlaoBridge.txt
    // Samlae.txt
    // SirirajHospital.txt
    // SouthBangkokPowerPlant.txt
    // WatMaKham.txt
    // WatPhaiLom.txt
    // WatPhoTaengNuea.txt
    const { name } = this.state;

    const files = [
      `${process.env.MIX_APP_URL}proxy/txt.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/mwa_salinity/observe/${name}.txt`,
      `${process.env.MIX_APP_URL}proxy/txt.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/mwa_salinity/forecast/${name}.txt`,
      `${process.env.MIX_APP_URL}proxy/csv.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/metadata/mwa_salinity.csv`,
    ];

    const promises = files.map(file => fetch(file).then(resp => resp.text()));
    Promise.all(promises)
      .then(results => {
        // console.log(JSON.stringify(results));
        this.setState({
          observeData: Papa.parse(results[0]).data,
          forecastData: Papa.parse(results[1]).data,
          teleStation: Papa.parse(results[2]).data,
        }, () => {
          this.resetChart();
          this.renderChart();
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderChart = () => {
    const {
      observeData, forecastData, teleStation, name,
    } = this.state;

    const { t } = this.props;

    // transform datas
    const series = [];
    const series1 = [];
    const seriesVal = [];
    const dataSeries = [];
    const observeArray = [];
    let val = null;
    let observeVal = null;

    // set chart options
    const chartOptions = this.getChartOptions();
    // ตรวจวัด
    observeData.slice(1)
      .filter(n => moment(n[1]) >= moment(forecastData[1][1]))
      .filter(n => n[3] !== undefined)
      .map(n => {
        // ข้อมูลวันที่ใช้จากข้อมูล forecast เป็นหลัก เวลาเริ่มต้นข้อมูล observe ไม่เท่า forecast
        val = (n[3]) !== '' ? parseFloat(n[3]) : null;
        if (n[3] !== '') {
          seriesVal.push(parseFloat(n[3]));
          observeArray[`${n[1]} ${n[2]}`] = parseFloat(n[3]);
        }
        if (seriesVal.length > 0) {
          this.setState({ statusChartObserve: true });
        } else if (seriesVal.length === 0) {
          this.setState({ statusChartObserve: false });
        }
        return series;
      });
    // คาดการณ์
    forecastData.slice(1)
      .filter(n => n[3] !== undefined)
      .map(n => {
        val = (n[3]) !== '' ? parseFloat(n[3]) : null;
        chartOptions.xAxis.categories.push(moment(`${n[1]} ${n[2]}`).format('D MMM HH:mm'));
        series1.push([val]);
        seriesVal.push(val);
        observeVal = (observeArray[`${n[1]} ${n[2]}`] === undefined) ? null : observeArray[`${n[1]} ${n[2]}`];
        series.push(observeVal);
        if (seriesVal.length > 0) {
          this.setState({ statusChartForecast: true });
        } else if (seriesVal.length === 0) {
          this.setState({ statusChartForecast: false });
        }
        return series1;
      });

    dataSeries.push({
      name: t('ตรวจวัด'),
      type: 'line',
      dashStyle: 'ShortDot',
      color: '#000000',
      data: series,
    });

    const title = teleStation.filter(n => n[1] === name);

    if (title[0][14] === 't') {
      // ตรวจสอบ field publish ตามที่ model แจ้งว่าจะให้แสดงผลข้อมูลคาดการณ์หรือไม่
      dataSeries.push({
        name: t('คาดการณ์'),
        type: 'spline',
        color: '#4682B4',
        data: series1,
      });
    }

    chartOptions.title.text = `${title[0][0]} ${title[0][2]} ${title[0][11]} ${title[0][12]} ${title[0][13]}`;
    chartOptions.series = dataSeries;
    chartOptions.yAxis.max = maxBy([maxBy(seriesVal), 2.06]);

    this.setState({ chartOptions, isLoading: false });
  }

  render() {
    const {
      name, teleStation,
    } = this.state;
    const {
      isLoading,
      error,
      chartOptions,
      statusChartForecast,
      statusChartObserve,
    } = this.state;
    const { classes, t } = this.props;

    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    let chart = '';
    let info = '';
    chart = <HighchartsReact highcharts={Highcharts} constructorType="chart" options={chartOptions} />;
    if (!statusChartForecast && !statusChartObserve) {
      info = '';
    } else {
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {'*'}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    }
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <NativeSelect value={name} onChange={this.handleStationChange}>
                {teleStation.slice(1).filter(row => row[0] !== '').map(row => (
                  <option key={uniqueId()} value={row[1]}>
                    {row[2]}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                title={t('แสดง')}
                onClick={() => this.getCsvData()}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          {!statusChartForecast && !statusChartObserve ? (
            <Grid item xs={12}>
              {t('ไม่มีข้อมูล')}
            </Grid>
          ) : (
            <Grid item xs={12}>
              {chart}
            </Grid>
          )}
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

SalinityChart.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(SalinityChart));
