// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Grid, AppBar, Divider,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

// lodash
import { uniqueId } from 'lodash';

// moment
import moment from 'moment';

// icon
import { FaImage, FaVideo } from '../../../../utils/Fontawesome';

// component
import SessionVideo from './ForecastWaterLevelVdo';

// styles
import { StyleComp, StyledTabs, StyledTab } from '../style';
import '../styles.css';

// env url api config
const initPath = process.env.MIX_API_HII_URL;
const useStyles = makeStyles(StyleComp);

const TabContainer = ({ children }) => (
  <Box component="div">
    {children}
  </Box>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`forecast-rain-detail-tab-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `forecast-rain-detail-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ForecastWaterLevel() {
  const classes = useStyles();
  const [getError, setError] = useState();
  const [getData, setData] = useState([]);
  const [getCurrentData, setCurrentData] = useState([]);
  const [getListDate, setListDate] = useState([]);
  const [value, setValue] = useState(0);
  const [getCurrentDate, setCurrentDate] = useState();
  const [dateTab, setDatetab] = useState();
  const [isOpen, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  function fetchData() {
    // APIs : ภาพแผนที่ความสูงและทิศทางคลื่น 7 วัน
    fetch(`${initPath}/tiservice/v1/ws/MBtOTp6IUXbjaCxhQoFQNrFgZUCzNgbo/stromsurge/wave/images_lastest`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        setListDate(result.data.list_day);
        setData(result.data.detail);
        setCurrentDate(result.data.list_day[0]);
        setDatetab(result.data.list_day[0]);
      })
      .catch(error => setError(error));
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabs = (e, v) => {
    getListDate.map(element => {
      if (element === v) {
        setDatetab(v);
        setCurrentDate(v);
      }
      return false;
    });
  };

  const handleModal = (e, data, index) => {
    setOpen(true);
    setPhotoIndex(index);
  };

  function renderImageSet(paramData) {
    return paramData
      .filter(element => element.date === dateTab)
      .map(element => {
        setCurrentData(element.series);
        return element.series.map((elementSeries, index) => (
          <Grid item xs={6} sm={3} md={3} key={uniqueId()}>
            <Box className={classes.linkStyle} onClick={e => handleModal(e, elementSeries, index)}>
              <Box className={classes.titleBox}>
                {`${moment(element.date).locale('th').format('ll')} เวลา ${elementSeries.time} น.`}
              </Box>
              <Img
                src={elementSeries.image_thumbnail}
                alt={elementSeries.image_thumbnail}
                style={{ width: '100%' }}
                unloader={(
                  <Img
                    src="https://via.placeholder.com/232x425?text=No%20Image"
                    alt="No Image"
                    className={classes.fullImg}
                  />
                )}
                className={classes.fullImg}
              />
            </Box>
          </Grid>
        ));
      });
  }

  function TabImage() {
    return (
      <>
        {/* View desktop */}
        <Hidden xsDown>
          <Box
            mt={4}
            mb={3}
            display="flex"
            key={uniqueId()}
            value={getCurrentDate}
          >
            {getListDate.map(element => (
              <Box
                key={element}
                mt={4}
                mb={3}
                display="flex"
                value={element}
              >
                <Button
                  key={element}
                  value={element}
                  size="small"
                  className={classes.button}
                  startIcon={<DateRangeIcon />}
                  onClick={e => handleTabs(e, element)}
                  variant={element === getCurrentDate ? 'outlined' : 'text'}
                  color={element === getCurrentDate ? 'primary' : 'default'}
                >
                  {moment(element).locale('th').format('ll')}
                </Button>
              </Box>
            ))}
          </Box>
        </Hidden>
        {/* View Mobile */}
        <Hidden smUp>
          <Box mt={4} mb={3} display="flex" justifyContent="center" width="100%">
            <Box width="60%" display="block" justifyContent="center">
              <FormControl className={classes.formControl}>
                <Select
                  labelId="date-outlined-label"
                  id="date-select-outlined"
                  value={getCurrentDate}
                  onClick={e => handleTabs(e)}
                  label="Date"
                  className={classes.selectForm}
                  disableUnderline
                >
                  {getListDate.map(element => (
                    <MenuItem
                      value={element}
                      key={uniqueId()}
                      onClick={e => handleTabs(e, element)}
                    >
                      <Box display="flex" justifycontent="center">
                        <DateRangeIcon className={classes.iconDateSelect} />
                        <Box pt={0.5}>
                          {element}
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Hidden>
        {/* Display image from click button date */}
        <Grid container spacing={3} justifycontent="flex-start" key="key">
          {getData.length > 0 && renderImageSet(getData)}
        </Grid>
        {/* Show modal */}
        {isOpen && (
          <Lightbox
            mainSrc={getCurrentData[photoIndex].image}
            nextSrc={getCurrentData[(photoIndex + 1) % getCurrentData.length].image}
            prevSrc={
              getCurrentData[
                (photoIndex + getCurrentData.length - 1) % getCurrentData.length].image
              }
            onCloseRequest={() => setOpen(false)}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '9999999' } }}
            onMovePrevRequest={
              () => setPhotoIndex(
                (photoIndex + getCurrentData.length - 1) % getCurrentData.length,
              )
            }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % getCurrentData.length)}
          />
        )}
      </>
    );
  }

  return (
    <>
      {getError && (
        <div className="text-center">
          {getError.message}
        </div>
      )}
      <AppBar position="static" color="inherit" className={classes.tabStyle}>
        <StyledTabs position="static" value={value} onChange={handleChange} aria-labelledby="simple tabs example">
          <StyledTab
            className={value === 0 ? classes.active_sub_tab : classes.sub_tab}
            label={(
              <Box display="flex">
                <Box mr={1}><FaImage /></Box>
                <Hidden xsDown><Box className={classes.titleTabsSub} display="flex">แผนที่คาดการณ์ระดับน้ำทะเล</Box></Hidden>
                <Hidden smUp><Box className={classes.titleTabsSub}>ภาพ</Box></Hidden>
              </Box>
            )}
            {...a11yProps(0)}
          />
          <StyledTab
            className={value === 1 ? classes.active_sub_tab : classes.sub_tab}
            label={(
              <Box display="flex" alignItems="center">
                <Box mr={1}><FaVideo /></Box>
                <Hidden xsDown>
                  <Box className={classes.titleTabsSub}>
                    วีดีโอแผนที่คาดการณ์ระดับน้ำทะเล
                  </Box>
                </Hidden>
                <Hidden smUp>
                  <Box className={classes.titleTabsSub}>
                    วีดีโอ
                  </Box>
                </Hidden>
              </Box>
            )}
            {...a11yProps(1)}
          />
        </StyledTabs>
        <Divider />
      </AppBar>
      <TabContainer component="div">
        <TabPanel value={value} index={0}>
          <TabImage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SessionVideo />
        </TabPanel>
      </TabContainer>

    </>
  );
}
