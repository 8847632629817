import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ui
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FaSpinner } from '../../../utils/Fontawesome';

import { styles } from '../../../styles/Style';

const animationDomain = [
  'Thailand 3km x 3km',
  'Southeast Asia 9km x 9km',
  'Asia 27km x 27km',
];

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 30 }}>
    {children}
  </Typography>
);

class WrfAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      animation: [],

      // tab
      value: 0,

    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.fetchData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  fetchData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/rain_forecast_animation_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const resultData = result.data;
        const resultIncludes = resultData.filter(item => !item.file_path.includes('wind') && item);
        this.setState({
          animation: resultIncludes,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      isLoading,
      error,
      animation,
      value,
    } = this.state;
    const { classes } = this.props;
    // console.log(isLoading, ' | ', error, animation, ' | ', value);
    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={70} />
          </div>
        )}
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs>
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" variant="fullWidth" className={classes.boxTab}>
              {animationDomain.map((row, i) => (
                <Tab label={row} key={row} className={value === i ? classes.active_tab : ''} />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        <Grid container justify="center" spacing={4}>
          <Grid item xs>
            {animation.reverse().map((row, i) => {
              if (value === i) {
                return (
                  <TabContainer>
                    <video width="100%" controls autoPlay>
                      <source src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </TabContainer>
                );
              }
              return null;
            })}
          </Grid>
        </Grid>
      </>
    );
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

WrfAnimation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WrfAnimation);
