import React, { Component } from 'react';

class PageV3 extends Component {

  componentDidMount() {
    window.location = 'https://www.thaiwater.net';
  }

  render() {
    return (
      <>
      </>
    );
  }

}

export default PageV3;
