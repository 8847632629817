/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
    marginBottom: 4,
  },
  label: {
    color: '#eee',
    fontSize: '0.9em',
    fontWeight: 400,
  },
  detail: {
    paddingTop: 5,
    color: 'white',
    fontSize: '0.9em',
    fontWeight: 400,
  },
});

const WlLegend = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        zIndex: 99999,
        left: '1%',
        bottom: '6%',
      }}
    >
      <Box p={1}>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#e91e63',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>วิกฤต</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#ffc107',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>เตือนภัย</Box>
        </Box>
        <Box display="flex">
          <Box
            style={{
              backgroundColor: '#69b9a0',
            }}
            className={classes.legend}
          />
          <Box className={classes.label}>ปกติ</Box>
        </Box>
        <Box className={classes.detail}>เกณฑ์ระดับน้ำ</Box>
      </Box>
    </div>
  );
};

export default WlLegend;
