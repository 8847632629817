import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { HashLink } from 'react-router-hash-link';

import { withTranslation } from 'react-i18next';

import { InView } from 'react-intersection-observer';

// ui
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Menu from '@material-ui/core/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CookieConsent from 'react-cookie-consent';

// color .
import blue from '@material-ui/core/colors/blue';

// Icon .
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// GoogleAnalytics
import GA from '../../utils/tracking/GoogleAnalytics';

import {
  FaBars, FaSortDown, FaSortUp,
} from '../../utils/Fontawesome';
import { HiiThaiwaterIcon } from '../../utils/Icon';
import { searchBoxStatus, searchBoxMaxWidth } from '../../utils/SearchBoxStatus';
import WindowScreen from '../../utils/WindowsFullScreen';

import ScrollToTop from '../../utils/ScrollToTop';
import showHeaderFooter from '../../utils/ShowHeaderFooter';

// components
import Search from '../search/Search';

// route
import Routes from '../../routes/Routes';

import {
  styles, StyledTabNav,
} from '../../styles/Style';

// constant
const primary = blue[800];
const initPath = process.env.MIX_APP_PATH;

// current screen width
const windowsScreen = WindowScreen();

class Header extends Component {
  constructor(props) {
    super(props);

    const resolution = window.innerWidth;

    this.state = {
      open: false,
      openMenu: false,
      openMenuWeather: false,
      openMenuWater: false,
      openMenuLang: false,
      clickAwayActive: false,
      touchAwayActive: false,
      value: 0,
      mobileMoreAnchorEl: null,
      subMenu: false,
      subMenuWater: false,
      isMobileTablet: resolution < 1024,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleMobileClose = this.handleMobileClose.bind(this);
    this.handleClickWater = this.handleClickWater.bind(this);
    this.handleClickWeather = this.handleClickWeather.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      open, openMenu, openMenuWater, openMenuLang, clickAwayActive, touchAwayActive, value, mobileMoreAnchorEl, subMenu, subMenuWater, isMobileTablet,
    } = this.state;
    if (nextState.open !== open
      || nextState.openMenu !== openMenu
      || nextState.openMenuWater !== openMenuWater
      || nextState.openMenuLang !== openMenuLang
      || nextState.clickAwayActive !== clickAwayActive
      || nextState.touchAwayActive !== touchAwayActive
      || nextState.value !== value
      || nextState.mobileMoreAnchorEl !== mobileMoreAnchorEl
      || nextState.subMenu !== subMenu
      || nextState.subMenuWater !== subMenuWater
      || nextState.isMobileTablet !== isMobileTablet
      || nextProps !== this.props
    ) {
      return true;
    }
    return false;
  }

  handleClose = () => {
    this.setState({
      openMenu: false,
      openMenuWater: false,
      openMenuWeather: false,
      openMenuLang: false,
      clickAwayActive: false,
      touchAwayActive: false,
    });
  };

  handleMobileClose = () => {
    this.setState({
      subMenu: false,
      subMenuWater: false,
      mobileMoreAnchorEl: null,
    });
  };

  handleClickAway(entry) {
    const { isMobileTablet } = this.state;
    if (entry !== undefined) {
      if (entry.isVisible === true) {
        if (isMobileTablet) {
          this.setState({ touchAwayActive: 'onTouchEnd' });
        } else {
          this.setState({ clickAwayActive: 'onClick' });
        }
      } else {
        this.setState({ touchAwayActive: false });
        this.setState({ clickAwayActive: false });
      }
    } else {
      this.setState({ touchAwayActive: false });
      this.setState({ clickAwayActive: false });
    }
  }

  handleChange = (event, value) => {
    // clear all popper
    this.handleClose();

    // set active tabs
    this.setState({ value });

    const { isMobileTablet } = this.state;

    // open active popper
    if (value === 4) {
      if (isMobileTablet) {
        this.setState({ touchAwayActive: 'onTouchEnd' });
      } else {
        this.setState({ clickAwayActive: 'onClick' });
      }
      this.setState(prevState => ({ openMenuLang: !prevState.openMenuLang }));
    }
    if (value === 3) {
      if (isMobileTablet) {
        this.setState({ touchAwayActive: 'onTouchEnd' });
      } else {
        this.setState({ clickAwayActive: 'onClick' });
      }
      this.setState(prevState => ({ openMenu: !prevState.openMenu }));
    }
    if (value === 2) {
      if (isMobileTablet) {
        this.setState({ touchAwayActive: 'onTouchEnd' });
      } else {
        this.setState({ clickAwayActive: 'onClick' });
      }
      this.setState(prevState => ({ openMenuWater: !prevState.openMenuWater }));
    }
    if (value === 1) {
      if (isMobileTablet) {
        this.setState({ touchAwayActive: 'onTouchEnd' });
      } else {
        this.setState({ clickAwayActive: 'onClick' });
      }
      this.setState(prevState => ({ openMenuWeather: !prevState.openMenuWeather }));
    }
  };

  // handleMenuClose = () => {
  // --- May be not use, so comment out ---
  // this.setState({ anchorEl: null });
  // this.handleMobileMenuClose();
  // };

  handleClickWeather = () => {
    this.setState(prevState => ({
      subMenu: !prevState.subMenu,
      subMenuWater: false,
    }));
  };

  handleClickWater = () => {
    this.setState(prevState => ({
      subMenuWater: !prevState.subMenuWater,
      subMenu: false,
    }));
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  // handleMobileMenuClose = () => {
  //   this.setState({ mobileMoreAnchorEl: null });
  // };

  render() {
    //   currentLocationHome = window.location.pathname.substring(14, 18),
    //   currentLocationWeather = window.location.pathname.substring(14, 21),
    //   currentLocationWater = window.location.pathname.substring(14, 19),
    //   currentLocationMore = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),

    const { classes, t, i18n } = this.props;
    const currentLocationPath = window.location.pathname;
    const currentLocation = currentLocationPath.replace('/thaiwater_v4', '');
    const {
      open,
      openMenu,
      openMenuWeather,
      openMenuWater,
      openMenuLang,
      clickAwayActive,
      touchAwayActive,
      value,
      mobileMoreAnchorEl,
      subMenu,
      subMenuWater,
      currentLocationHome = currentLocation.substring(0, 6).replace('/', ''),
      currentLocationWeather = currentLocation.substring(0, 8).replace('/', ''),
      currentLocationWater = currentLocation.substring(0, 6).replace('/', ''),
      currentLocationMore = currentLocationPath.substring(currentLocationPath.lastIndexOf('/') + 1),
      isMobileTablet,
    } = this.state;
    // language
    const currentTH = i18n.language === 'th';
    const currentEN = i18n.language === 'en';
    const currentJP = i18n.language === 'jp';

    // eslint-disable-next-line no-nested-ternary
    const currentLang = currentTH ? {
      label: 'th', text: 'ไทย', alt: 'ภาษาไทย',
    } : (currentEN ? {
      label: 'en', text: 'ENG', alt: 'English',
    } : {
      label: 'jp', text: 'JP', alt: 'Japan',
    });

    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };

    const StyledMenu = withStyles({
      paper: {
        border: '1px solid #d3d4d5',
      },
    })(props => (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...props}
      />
    ));

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        open={Boolean(mobileMoreAnchorEl)}
        onClose={this.handleMobileClose}
        PaperProps={{
          style: {
            left: '0px!important',
            // transform: 'translateX(-0%) translateY(-1%)',
            minWidth: '100%',
            borderRadius: 0,
            display: 'contents',
            top: 50,
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
            width: '100%',
            height: '100%',
            background: blue[700],
            color: '#fff',
            fontFamily: 'Kanit',
            fontWeight: 'fontWeightBold',
            // fontWeight: 600,
            lineHeight: '2rem',
            left: '0 !important',
            overflowY: 'scroll',
            // zIndex: 99999,
          },
        }}
        style={{ top: 73 }}
        id="width-moblie"
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
          style={{ left: 0 }}
          id="menu-moblie"
        >
          <ListItem
            button
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            onClick={this.handleClickWeather}
          >
            <ListItemText primary={t('ติดตามสภาพอากาศ')} />
            {subMenu ? <FaSortUp fontSize="1.5rem" /> : <FaSortDown fontSize="1.5rem" style={{ marginTop: '-15px' }} />}
          </ListItem>
          <Divider variant="middle" />
          <Collapse in={subMenu} timeout="auto" id="submenu-moblie">
            <List component="div" disablePadding>
              <ListItem button className={classes.menuSub} align="right" component={Link} to={`${initPath}weather`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('อากาศ')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button component={Link} to={`${initPath}weather/storm`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('พายุ')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}weather/rainfall`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('ฝน')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}weather/radar`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('เรดาร์')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}weather/sea`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('ทะเล')} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={this.handleClickWater}
          >
            <ListItemText primary={t('ติดตามสถานการณ์น้ำ')} />
            {subMenuWater ? <FaSortUp fontSize="1.5rem" /> : <FaSortDown fontSize="1.5rem" style={{ marginTop: '-15px' }} />}
          </ListItem>
          <Divider variant="middle" />
          <Collapse in={subMenuWater} timeout="auto" id="submenu-moblie">
            <List component="div" disablePadding>
              <ListItem button className={classes.menuSub} component={Link} to={`${initPath}water`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('อ่างเก็บน้ำ')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}water/wl`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('ระดับน้ำ')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}water/gate`} onClick={this.handleMobileClose}>

                <ListItemText primary={t('ระดับน้ำที่ ปตร./ฝาย')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}water/cctv`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('CCTV')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}water/quality`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('คุณภาพน้ำ')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to={`${initPath}water/ocean`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('ระดับน้ำบริเวณปากแม่น้ำ')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${initPath}FlashFlood`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ระบบเตือนภัยน้ำท่วมฉับพลัน')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${initPath}floodforecast`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ระบบคาดการณ์น้ำท่วม')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${initPath}floodplain`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ระบบติดตามน้ำทุ่ง')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${initPath}drought/monitoring`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ระบบติดตามภัยแล้ง')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.menuSub} component={Link} to={`${initPath}bma`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('สถานการณ์น้ำ กทม.')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.menuSub} component={Link} to={`${initPath}storm_surge`} onClick={this.handleMobileClose}>
                <ListItemText primary={t('ระบบคาดการณ์และเตือนภัยล่วงหน้าบริเวณอ่าวไทย')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
              >
                <ListItemText
                  style={{
                    color: '#a2a1a1', marginTop: 40, borderBottom: '0.1px solid #ddd', fontSize: '0.7em',
                  }}
                  primary={t('ผังน้ำ')}
                />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href="https://waterchart.thaiwater.net/basin/chaophraya"
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ลุ่มน้ำเจ้าพระยา ( ย่อ )')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/chaopraya/small/chaopraya.php`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ลุ่มน้ำเจ้าพระยา ( ย่อ )')+ t(' [เก่า]')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/chaopraya/2013/chaopraya.php`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ลุ่มน้ำเจ้าพระยา ( เต็ม )')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/2014/chi_mun`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ลุ่มน้ำชี-ลุ่มน้ำมูล')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/khong/2013/khong.php`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ลุ่มน้ำโขง')} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href={`${process.env.MIX_THAIWATER_URL}/v3/bangpakong`}
                target="_blank"
                onClick={this.handleMobileClose}
              >
                <ListItemText primary={t('ผังน้ำบางปะกง')} />
              </ListItem>
            </List>
          </Collapse>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component="a" href="http://ntw.onwr.go.th" target="_blank" rel="noopener noreferrer">
              <ListItemText primary={t('แผนที่ติดตามสถานการณ์น้ำ')} />
            </ListItem>
            <Divider variant="middle" />
          </List>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to={`${initPath}about`} onClick={this.handleMobileClose}>
              <ListItemText primary={t('เกี่ยวกับโครงการ')} />
            </ListItem>
            <Divider variant="middle" />
          </List>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component="a" href="https://flipbook.hii.or.th/publishing/23Thaiwater-manual/" onClick={this.handleMobileClose}>
              <ListItemText primary={t('คู่มือการใช้งานเว็บไซต์')} />
            </ListItem>
            <Divider variant="middle" />
          </List>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to={`${initPath}report`} onClick={this.handleMobileClose}>
              <ListItemText primary={t('รายงานสถานการณ์')} />
            </ListItem>
            <Divider variant="middle" />
          </List>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to={`${initPath}media`} onClick={this.handleMobileClose}>
              <ListItemText primary={t('คลังสื่อ-เผยแพร่')} />
            </ListItem>
            <Divider variant="middle" />
          </List>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <HashLink
                to={`${initPath}report#flood`}
                onClick={this.handleMobileClose}
                style={{
                  width: '100%',
                  marginTop: '0px',
                  marginBottom: '0px',
                  padding: '0px',
                  color: '#FFF',
                }}
              >
                <ListItemText primary={t('บันทึกเหตุการณ์น้ำ')} />
              </HashLink>
            </ListItem>
            <Divider variant="middle" />
          </List>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to={`${initPath}research`} onClick={this.handleMobileClose}>
              <ListItemText primary={t('งานวิจัย-ความรู้')} />
            </ListItem>
            <Divider variant="middle" />
          </List>
          <List component="div" disablePadding>
            <ListItem button component={Link} to={`${initPath}statistics-report`} onClick={this.handleMobileClose}>
              <ListItemText primary={t('สถิติการใช้งาน')} />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <Box mt={2} mx={2} height="20px">Change Language</Box>
            <Box display="flex" justifyContent="flex-start">
              {currentTH && (
                <Box>
                  <MenuItem onClick={() => changeLanguage('en')}>
                    <Box mr={1} className={classes.fag}>
                      <img src={`${initPath}images/theme/fag/en.png`} width="24" height="16" alt="English" />
                    </Box>
                    <Typography className={classes.lang} noWrap>
                      ENG
                    </Typography>
                  </MenuItem>
                </Box>
              )}
              {currentTH && (
                <Box>
                  <MenuItem onClick={() => changeLanguage('jp')}>
                    <Box mr={1} className={classes.fag}>
                      <img src={`${initPath}images/theme/fag/jp.png`} width="24" height="16" alt="Japan" />
                    </Box>
                    <Typography className={classes.lang} noWrap>
                      JP
                    </Typography>
                  </MenuItem>
                </Box>
              )}
              {currentEN && (
                <Box>
                  <MenuItem onClick={() => changeLanguage('th')}>
                    <Box mr={1} className={classes.fag}>
                      <img src={`${initPath}images/theme/fag/th.png`} width="24" height="16" alt="ไทย" />
                    </Box>
                    <Typography className={classes.lang} noWrap>
                      ไทย
                    </Typography>
                  </MenuItem>
                </Box>
              )}
              {currentEN && (
                <Box>
                  <MenuItem onClick={() => changeLanguage('jp')}>
                    <Box mr={1} className={classes.fag}>
                      <img src={`${initPath}images/theme/fag/jp.png`} width="24" height="16" alt="Japan" />
                    </Box>
                    <Typography className={classes.lang} noWrap>
                      JP
                    </Typography>
                  </MenuItem>
                </Box>
              )}
              {currentJP && (
                <Box>
                  <MenuItem onClick={() => changeLanguage('th')}>
                    <Box mr={1} className={classes.fag}>
                      <img src={`${initPath}images/theme/fag/th.png`} width="24" height="16" alt="ไทย" />
                    </Box>
                    <Typography className={classes.lang} noWrap>
                      ไทย
                    </Typography>
                  </MenuItem>
                </Box>
              )}
              {currentJP && (
                <Box>
                  <MenuItem onClick={() => changeLanguage('en')}>
                    <Box mr={1} className={classes.fag}>
                      <img src={`${initPath}images/theme/fag/en.png`} width="24" height="16" alt="English" />
                    </Box>
                    <Typography className={classes.lang} noWrap>
                      ENG
                    </Typography>
                  </MenuItem>
                </Box>
              )}
            </Box>
          </List>
        </List>
      </Menu>
    );

    moment.locale(i18n.language === 'jp' ? 'ja' : i18n.language);
    return (
      <Router>
        <ScrollToTop>
          {!showHeaderFooter ? <></> : (
            <InView
              trackVisibility
              threshold={0}
              delay={100}
              onChange={(InView, entry) => this.handleClickAway(entry)}
            >
              {({ ref }) => (
                <div className={classes.root} ref={ref}>
                  <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                      [classes.appBarShift]: open,
                    })}
                    color="inherit"
                    style={{ borderBottom: '1px solid #ccc', height: 73 }}
                  >

                    <div className={classes.layout}>
                      <Toolbar disableGutters>
                        <Box mt={{ xs: 1.5, sm: 1.5, md: 0 }} display="flex" alignItems="center" justifyContent="center">
                          <Box color={primary}>
                            <a href="https://www.mhesi.go.th/" target="_blank" rel="noopener noreferrer" title="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)">
                              <img src={`${initPath}images/theme/logo/logo-mhesi.png`} width="45px" alt="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)" />
                            </a>
                          </Box>
                          <Box color={primary} ml={1}>
                            <a href="https://www.hii.or.th/" target="_blank" rel="noopener noreferrer" title="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)">
                              <img src={`${initPath}images/theme/logo/logo-hii.png`} width="45px" alt="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)" />
                            </a>
                          </Box>
                          <Link to={`${initPath}`}>
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <Box
                                px={2}
                                style={{
                                  borderRight: '1px solid #ccc', height: '35px', padding: '0px 4px', marginRight: '3px', marginLeft: '5px',
                                }}
                              />
                              <Box color={primary} ml={1}>
                                <HiiThaiwaterIcon />
                              </Box>
                              <Box
                                fontSize={{
                                  xs: 9, sm: 16, md: 19, lg: 24,
                                }}
                                m={0}
                                p={1}
                                component="span"
                                display="block"
                                fontWeight="fontWeightBold"
                                className={classes.nameSiteTH}
                                flexWrap="wrap"
                              >
                                <span style={{ letterSpacing: 1.5 }}>คลังข้อมูลน้ำแห่งชาติ</span>
                                <Box
                                  fontSize={{
                                    xs: 6, sm: 8, md: 9, lg: 12,
                                  }}
                                  m={0}
                                  p={-1}
                                  component="span"
                                  display="block"
                                  variant="caption"
                                  className={classes.nameSiteEN}
                                  letterSpacing={0.4}
                                >
                                  National Hydroinformatics Data Center
                                </Box>
                              </Box>
                            </Box>
                          </Link>
                        </Box>
                        <div className={classes.grow} />
                        {!isMobileTablet && (
                          <ClickAwayListener onClickAway={this.handleClose} mouseEvent={clickAwayActive} touchEvent={touchAwayActive}>
                            <div className={classes.sectionDesktop}>
                              <Tabs
                                // variant="fullWidth"
                                value={value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                className={classes.boxTab}
                              >
                                <StyledTabNav
                                  label={t('หน้าแรก')}
                                  component={Link}
                                  to={`${initPath}`}
                                  className={currentLocationHome === '' ? classes.active_tab : 'default'}
                                  onClick={() => this.setState({ currentLocation: '' })}
                                  style={{ fontWeight: 600, fontSize: 15, minWidth: 100 }}
                                />
                                <StyledTabNav
                                  label={t('ติดตามสภาพอากาศ')}
                                  ref={node => {
                                    this.anchorEl = node;
                                  }}
                                  icon={<ArrowDropDownIcon className={classes.iconRight} style={{ marginRight: 5 }} />}
                                  className={currentLocationWeather === 'weather' ? classes.active_tab : 'default'}
                                  style={{ paddingRight: 25, fontWeight: 600, fontSize: 15 }}
                                  onClick={() => this.setState({ currentLocation: 'weather' })}
                                />
                                <StyledTabNav
                                  label={t('ติดตามสถานการณ์น้ำ')}
                                  ref={node => {
                                    this.anchorEl = node;
                                  }}
                                  icon={<ArrowDropDownIcon className={classes.iconRight} style={{ marginRight: 5 }} />}
                                  className={currentLocationWater === 'water' ? classes.active_tab : 'default'}
                                  style={{ paddingRight: 25, fontWeight: 600, fontSize: 15 }}
                                />
                                <StyledTabNav
                                  label={t('เพิ่มเติม')}
                                  ref={node => {
                                    this.anchorEl = node;
                                  }}
                                  icon={<ArrowDropDownIcon className={classes.iconRight} style={{ marginRight: 30 }} />}
                                  className={
                                    currentLocationMore === 'about' ? classes.active_tab : currentLocationMore === 'media' ? classes.active_tab : currentLocationMore === 'report' ? classes.active_tab : currentLocationMore === 'research' ? classes.active_tab : 'default'
                                  }
                                  style={{ fontWeight: 600, fontSize: 15, minWidth: 150 }}
                                />
                                <StyledTabNav
                                  ref={node => {
                                    this.anchorEl = node;
                                  }}
                                  icon={(
                                    <>
                                      <Box display="flex" alignItems="center">
                                        <img src={`${initPath}images/theme/fag/${currentLang.label}.png`} width="24" height="16" alt={`${currentLang.text}`} />
                                        <Box ml={0.5} fontSize={12} fontWeight="500">{`${currentLang.text}`}</Box>
                                        <ArrowDropDownIcon />
                                      </Box>
                                    </>
                                  )}
                                  style={{
                                    fontWeight: 600, fontSize: 15, minWidth: 100, borderRight: 'none',
                                  }}
                                />
                              </Tabs>
                              {/* 1 เมนู sub menu ติดตามสภาพอากาศ */}
                              <Popper
                                open={openMenuWeather}
                                anchorEl={this.anchorEl}
                                disablePortal
                                id="tooltip"
                                modifiers={{ // fix firefox only, set offset
                                  offset: {
                                    enabled: true,
                                    offset: '-500, 0',
                                  },
                                }}
                                style={{ width: '100%', background: '#f5f5f5' }}
                              >
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Box
                                      p={3}
                                      display="flex"
                                      justifyContent="center"
                                      align="center"
                                      borderBottom={1}
                                    >
                                      <Box width="40%" display="flex" alignItems="center" justifyContent="center">
                                        <Box fontWeight="fontWeightMedium" pr={3} fontSize={20}>
                                          {t('ติดตามสภาพอากาศ')}
                                        </Box>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component={Link} to={`${initPath}weather`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('อากาศ')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}weather/storm`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('พายุ')}
                                        </ListItem>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component={Link} to={`${initPath}weather/rainfall`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('ฝน')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}weather/radar`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('เรดาร์')}
                                        </ListItem>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component={Link} to={`${initPath}weather/sea`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('ทะเล')}
                                        </ListItem>
                                        <Divider />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Popper>
                              {/* 2 เมนู sub menu ติดตามสถานการณ์น้ำ */}
                              <Popper
                                open={openMenuWater}
                                anchorEl={this.anchorEl}
                                disablePortal
                                id="tooltip"
                                modifiers={{ // fix firefox only, set offset
                                  offset: {
                                    enabled: true,
                                    offset: '-500, 0',
                                  },
                                }}
                                style={{ width: '100%', background: '#f5f5f5' }}
                              >
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Box
                                      p={3}
                                      display="flex"
                                      justifyContent="center"
                                      align="center"
                                      borderBottom={1}
                                    >
                                      <Box width="40%" display="flex" alignItems="center" justifyContent="center">
                                        <Box fontWeight="fontWeightMedium" pr={3} fontSize={20}>
                                          {t('ติดตามสถานการณ์น้ำ')}
                                        </Box>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component={Link} to={`${initPath}water`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('อ่างเก็บน้ำ')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}water/wl`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('ระดับน้ำ')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${initPath}FlashFlood`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ระบบเตือนภัยน้ำท่วมฉับพลัน')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}bma`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('สถานการณ์น้ำ กทม.')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="div"
                                          disabled
                                          style={{ marginTop: 30 }}
                                        >
                                          {'\u00A0 \u00A0\u00A0'}
                                          {t('ผังน้ำ')}
                                        </ListItem>

                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href="https://waterchart.thaiwater.net/basin/chaophraya"
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ลุ่มน้ำเจ้าพระยา ( ย่อ )')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/chaopraya/small/chaopraya.php`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ลุ่มน้ำเจ้าพระยา ( ย่อ )') + t(' [เก่า]')}
                                        </ListItem>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component={Link} to={`${initPath}water/gate`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('ระดับน้ำที่ ปตร./ฝาย')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}water/cctv`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('กล้อง CCTV')}
                                        </ListItem>
                                        <Divider />
                                        {/*  */}
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${initPath}floodforecast`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ระบบคาดการณ์น้ำท่วม')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${initPath}floodplain`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ระบบติดตามน้ำทุ่ง')}
                                        </ListItem>
                                        {/* <ListItem style={{ padding: 10 }}>
                                      {'\u00A0 \u00A0\u00A0'}
                                    </ListItem> */}
                                        <Divider />
                                        <ListItem
                                          button
                                          component="div"
                                          disabled
                                        >
                                          {'\u00A0'}
                                        </ListItem>
                                        <Divider style={{ marginTop: 30 }} />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/chaopraya/2013/chaopraya.php`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ลุ่มน้ำเจ้าพระยา ( เต็ม )')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${process.env.MIX_THAIWATER_URL}/v3/bangpakong`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ผังน้ำบางปะกง')}
                                        </ListItem>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component={Link} to={`${initPath}water/quality`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('คุณภาพน้ำ')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}water/ocean`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('ระดับน้ำบริเวณปากแม่น้ำ')}
                                        </ListItem>
                                        <Divider />
                                        {/* */}
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${initPath}drought/monitoring`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ระบบติดตามภัยแล้ง')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${initPath}storm_surge`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ระบบคาดการณ์และเตือนภัยล่วงหน้าบริเวณอ่าวไทย')}
                                        </ListItem>
                                        {/* <ListItem style={{ padding: 10 }}>
                                          {'\u00A0 \u00A0\u00A0'}
                                        </ListItem> */}
                                        <Divider />
                                        <ListItem
                                          button
                                          component="div"
                                          disabled
                                        >
                                          {'\u00A0'}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/2014/chi_mun`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ลุ่มน้ำชี-ลุ่มน้ำมูล')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                          button
                                          component="a"
                                          href={`${process.env.MIX_THAIWATER_URL}/DATA/REPORT/php/chart/khong/2013/khong.php`}
                                          target="_blank"
                                          onClick={this.handleClose}
                                        >
                                          <ArrowRightIcon />
                                          {t('ลุ่มน้ำโขง')}
                                        </ListItem>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Popper>
                              {/* 3 เมนู sub menu เพิ่มเติม */}
                              <Popper
                                open={openMenu}
                                anchorEl={this.anchorEl}
                                disablePortal
                                id="tooltip"
                                modifiers={{ // fix firefox only, set offset
                                  offset: {
                                    enabled: true,
                                    offset: '-500, 0',
                                  },
                                }}
                                style={{ width: '100%', background: '#f5f5f5' }}
                              >
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Box
                                      p={3}
                                      display="flex"
                                      justifyContent="center"
                                      align="center"
                                      borderBottom={1}
                                    >
                                      <Box width="40%" display="flex" alignItems="center" justifyContent="center">
                                        <Box fontWeight="fontWeightMedium" pr={3} fontSize={18}>
                                          {t('เพิ่มเติม')}
                                          {'+'}
                                        </Box>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component="a" href="http://ntw.onwr.go.th" target="_blank" rel="noopener noreferrer" onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('แผนที่ติดตามสถานการณ์น้ำ')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}about`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('เกี่ยวกับโครงการ')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component="a" href="https://flipbook.hii.or.th/publishing/23Thaiwater-manual/" onClick={this.handleClose} target="_blank">
                                          <ArrowRightIcon />
                                          {t('คู่มือการใช้งาน')}
                                        </ListItem>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem button component={Link} to={`${initPath}report`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('รายงานสถานการณ์')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}media`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('คลังสื่อ-เผยแพร่')}
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component="a" href="https://sites.google.com/view/thaiwater-standard/home" target="_blank" onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('Thaiwater Standard')}
                                        </ListItem>
                                      </Box>
                                      <Box width="20%">
                                        <ListItem>
                                          <HashLink
                                            to={`${initPath}report#flood`}
                                            onClick={this.handleClose}
                                            style={{
                                              marginTop: '-1px',
                                              marginBottom: '2px',
                                              color: '#000000de',
                                            }}
                                          >
                                            <ArrowRightIcon style={{
                                              marginBottom: '-7px',
                                            }}
                                            />
                                            {t('บันทึกเหตุการณ์น้ำ')}
                                          </HashLink>
                                        </ListItem>
                                        <Divider />
                                        <ListItem button component={Link} to={`${initPath}research`} onClick={this.handleClose}>
                                          <ArrowRightIcon />
                                          {t('งานวิจัย-ความรู้')}
                                        </ListItem>
                                        <Divider />
                                        <List component="div" disablePadding>
                                          <ListItem button component={Link} to={`${initPath}statistics-report`} onClick={this.handleClose}>
                                            <ArrowRightIcon />
                                            {t('สถิติการใช้งาน')}
                                          </ListItem>
                                        </List>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Popper>
                              <Popper
                                open={openMenuLang}
                                anchorEl={this.anchorEl}
                                disablePortal
                                id="tooltip"
                                modifiers={{ // fix firefox only, set offset
                                  offset: {
                                    enabled: true,
                                    offset: '-500, 0',
                                  },
                                }}
                                style={{ width: '100%', background: '#f5f5f5' }}
                              >
                                <Box ml={2} display="flex" justifyContent="flex-end">
                                  <StyledMenu
                                    id="customized-menu"
                                    anchorEl={this.anchorEl}
                                    keepMounted
                                    open={Boolean(this.anchorEl)}
                                    onClose={this.handleClose}
                                  >
                                    <Box px={1}>
                                      <Typography noWrap variant="caption">
                                        Change Language
                                      </Typography>
                                    </Box>
                                    <Divider />
                                    {currentTH && (
                                      <MenuItem onClick={() => changeLanguage('en')}>
                                        <Box mr={1} className={classes.fag}>
                                          <img src={`${initPath}images/theme/fag/en.png`} width="24" height="16" alt="English" />
                                        </Box>
                                        <Typography className={classes.lang} noWrap>
                                          ENG
                                        </Typography>
                                      </MenuItem>
                                    )}
                                    {currentTH && (
                                      <MenuItem onClick={() => changeLanguage('jp')}>
                                        <Box mr={1} className={classes.fag}>
                                          <img src={`${initPath}images/theme/fag/jp.png`} width="24" height="16" alt="Japan" />
                                        </Box>
                                        <Typography className={classes.lang} noWrap>
                                          JP
                                        </Typography>
                                      </MenuItem>
                                    )}
                                    {currentEN && (
                                      <MenuItem onClick={() => changeLanguage('th')}>
                                        <Box mr={1} className={classes.fag}>
                                          <img src={`${initPath}images/theme/fag/th.png`} width="24" height="16" alt="ไทย" />
                                        </Box>
                                        <Typography className={classes.lang} noWrap>
                                          ไทย
                                        </Typography>
                                      </MenuItem>
                                    )}
                                    {currentEN && (
                                      <MenuItem onClick={() => changeLanguage('jp')}>
                                        <Box mr={1} className={classes.fag}>
                                          <img src={`${initPath}images/theme/fag/jp.png`} width="24" height="16" alt="Japan" />
                                        </Box>
                                        <Typography className={classes.lang} noWrap>
                                          JP
                                        </Typography>
                                      </MenuItem>
                                    )}
                                    {currentJP && (
                                      <MenuItem onClick={() => changeLanguage('th')}>
                                        <Box mr={1} className={classes.fag}>
                                          <img src={`${initPath}images/theme/fag/th.png`} width="24" height="16" alt="ไทย" />
                                        </Box>
                                        <Typography className={classes.lang} noWrap>
                                          ไทย
                                        </Typography>
                                      </MenuItem>
                                    )}
                                    {currentJP && (
                                      <MenuItem onClick={() => changeLanguage('en')}>
                                        <Box mr={1} className={classes.fag}>
                                          <img src={`${initPath}images/theme/fag/en.png`} width="24" height="16" alt="English" />
                                        </Box>
                                        <Typography className={classes.lang} noWrap>
                                          ENG
                                        </Typography>
                                      </MenuItem>
                                    )}
                                  </StyledMenu>
                                </Box>
                              </Popper>
                            </div>
                          </ClickAwayListener>
                        )}
                        <div className={classes.sectionMobile}>
                          <Box p={1} mr={{ xs: 1, sm: 2, lg: 3 }} className={classes.outLinedRadius}>
                            <IconButton size="medium" aria-haspopup="true" aria-label="display more actions" onClick={this.handleMobileMenuOpen} color="inherit">
                              <FaBars />
                            </IconButton>
                          </Box>
                        </div>
                      </Toolbar>
                    </div>
                  </AppBar>
                  {/* Cookie */}
                  <CookieConsent
                    location="bottom"
                    buttonText="ยอมรับ"
                    cookieName="PDPA-HII"
                    style={{ background: 'rgb(255, 255, 255, 0.9)', borderTop: '1px solid #ccc', zIndex: 9999999 }}
                    buttonStyle={{
                      background: '#1876D2', color: '#fff', fontSize: '0.9em', borderRadius: 20, padding: '3px 25px', marginleft: 20, fontFamily: 'Kanit',
                    }}
                    expires={150}
                  >
                    <h3 style={{ color: '#000', lineHeight: '100%' }}>นโยบายและคำประกาศเกี่ยวกับความเป็นส่วนตัว</h3>
                    <p style={{ color: '#000', fontSize: '1em' }}>เราให้ความสำคัญต่อข้อมูลส่วนบุคคลของท่าน เพื่อการพัฒนาประสิทธิภาพ และประสบการณ์ที่ดีในการใช้งานเว็บไซต์ของคุณ หากท่านใช้บริการเว็บไซต์นี้ </p>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md="auto"><span style={{ color: '#000' }}>ดูรายละเอียดเพิ่มเติม :  </span></Grid>
                      <Grid item xs={12} sm="auto"><a href="https://pdpa.hii.or.th/cookies-policy/thaiwater-cookies-policy" target="_blank" title="นโยบายคุกกี้" rel="noreferrer"><span className={classes.pdpaPopupLink}>นโยบายคุกกี้</span></a></Grid>
                      <Grid item xs={12} sm="auto"><a href="https://pdpa.hii.or.th/privacy-policy/thaiwater-privacy-policy" target="_blank" title="นโยบายการคุ้มครองข้อมูลส่วนบุคคล" rel="noreferrer"><span className={classes.pdpaPopupLink}>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</span></a></Grid>
                      <Grid item xs={12} sm="auto">
                        {' '}
                        <a href="https://pdpa.hii.or.th/privacy-notice/thaiwater-privacy-notice" target="_blank" title="คำประกาศเกี่ยวกับความเป็นส่วนตัว" rel="noreferrer"><span className={classes.pdpaPopupLink}>คำประกาศเกี่ยวกับความเป็นส่วนตัว</span></a>
                      </Grid>
                    </Grid>
                  </CookieConsent>

                  {/* {renderMenu} */}
                  {renderMobileMenu}
                </div>
              )}
            </InView>
          )}
          {/* Search */}
          {!searchBoxStatus || !showHeaderFooter ? (<></>) : (<Search />)}
          {!searchBoxStatus ? (<Box mt={9} />) : (<></>)}

          <main>
            <Container maxWidth={(!searchBoxStatus) ? searchBoxMaxWidth : windowsScreen}>
              <Switch>
                {Routes().map(route => (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
              {GA.init('www') && <GA.RouteTracker />}
            </Container>
          </main>
        </ScrollToTop>
      </Router>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(Header));
