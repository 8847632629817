
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import {
  uniqueId, orderBy,
} from 'lodash';

import { withTranslation } from 'react-i18next';

// color .
// import grey from '@material-ui/core/colors/grey';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

// icon .
import { FaHome } from '../../utils/Fontawesome';
import { HiiVideoIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class VideoAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoading: false,
      youtubeList: [],
      error: null,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.getData();
  }

  getData() {
    Promise.all([
      fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLFtdoHir6hWchHRe14FUSu_fKEgQoLXr7&key=AIzaSyAog5tFTfrj2YS9RgnUHO96SuCdJqTRAmk'),
    ])
      .then(([youtube]) => Promise.all([youtube.json()]))
      .then(([youtubeList]) => {
        this.setState({ youtubeList: youtubeList.items });
      })
      .catch(error => this.setState({ error }));
  }

  render() {
    const { classes, t } = this.props;
    const {
      youtubeList, error,
    } = this.state;

    if (error) {
      return (<div className={classes.layout}>{error.message}</div>);
    }

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}media`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('คลังสื่อ เผยแพร่')}
                </Typography>
              </Button>
              <Button href={`${initPath}media/video/all`}>
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('วีดีทัศน์')}
                </Typography>
              </Button>
              <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                {t('ทั้งหมด')}
              </Typography>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiVideoIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4" gutterBottom>
                      {t('คลังสื่อ เผยแพร่')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/** youtube list */}
        <Grid container spacing={4}>
          {orderBy(youtubeList, 'snippet.publishedAt', 'desc')
            .map(row => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={uniqueId}>
                <Link href={`${initPath}media/video/view/${row.snippet.resourceId.videoId}`}>
                  <img src={`${row.snippet.thumbnails.maxres.url}`} style={{ width: '100%', margin: '12px 0px' }} alt={row.snippet.title} />
                </Link>
                <Typography variant="h6" gutterBottom>
                  {row.snippet.title}
                </Typography>
                {/* <Typography style={{ margin: 10 }} className={classes.fontBase}>
                  ดร.สุทัศน์ วีสกุล ผู้อำนวยการ สสนก. ชี้แผนที่ติดตั้งโทรมาตรวัดระดับน้ำ
                  ในลำน้ำที่ติดตั้งไว้ 320 สถานี พบระดับน้ำในแม่น้ำเพชรบุรี น่าเป็นห่วง
                  ขึ้นสัญญาณเตือนสีแดง น้ำเอ่อท้นตลิ่งแล้ว โดยเฉพาะจุดตำบลกลัดหลวง
                  แต่แม่น้ำเพชรบุรีช่วงที่ไหลผ่านตัวเมือง ยังต่ำกว่าตลิ่ง ต้องเฝ้าระวังใน
                  3 วันนี้ ที่มีฝนตกต่อเนื่อง เติมน้ำในเขื่อนแก่งกระจาน
                  โดยสสนก.ได้พัฒนาระบบติดตามระดับน้ำ อัปเดททุก 10 นาที
                  ผ่าน www.thaiwater.net ให้ประชาชนติดตามสถานการณ์
                </Typography> */}
                
                <Link component="button" to={`${initPath}/VideoAll`} color="inherit" underline="none">
                  <Typography variant="caption" className={classes.fontBaseWeight} style={{ fontFamily: 'Kanit' }}>
                    {t('อ่านต่อ')}
                  </Typography>
                </Link>
              </Grid>
            ))}
        </Grid>

        {/* manual */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/m-uiUPLLM4E?si=g-jNIV0tMPHHfMYg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/IIDI7nrOkOM?si=_uX1rDkI6I9z7j15" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/Gj2Fh1WrM-o?si=b4NrPsEWl0T1Uq-U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Grid>
        </Grid>
      </div>
    );
  }
}

VideoAll.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(VideoAll));
