import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const getChartOptions = () => {
  const options = {

    title: {
      text: 'Storm Name',
      type: 'datetime',
    },
    subtitle: {
      text: '',
    },
    yAxis: [{ // Primary yAxis
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
      labels: {
        format: '{value} Knot',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      title: {
        text: 'Wind Speed (Knot)',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      plotBands: [{
        from: 0,
        to: 34,
        color: 'rgba(68, 170, 213, 0.1)',
        dashStyle: 'shortdash',
        label: {
          text: 'Tropical Depression (<34 knots)',
        },
      }, {
        from: 34,
        to: 63,
        color: 'rgba(0, 0, 0, 0)',
        dashStyle: 'shortdash',
        label: {
          text: 'Tropical Storm (<=63 knots)',
        },
      }, {
        from: 63,
        to: 82,
        color: 'rgba(68, 170, 213, 0.1)',
        dashStyle: 'shortdash',
        label: {
          text: 'Cat 1 (<=82 knots)',
        },
      }, {
        from: 82,
        to: 95,
        color: 'rgba(0, 0, 0, 0)',
        dashStyle: 'shortdash',
        label: {
          text: 'Cat 2 (<=95 knots)',
        },
      }, {
        from: 95,
        to: 113,
        color: 'rgba(68, 170, 213, 0.1)',
        dashStyle: 'shortdash',
        label: {
          text: 'Cat 3 (<=113 knots)',
        },
      }, {
        from: 113,
        to: 135,
        color: 'rgba(0, 0, 0, 0)',
        dashStyle: 'shortdash',
        width: 2,
        label: {
          text: 'Cat 4 (<=135 knots)',
        },
      }, {
        from: 135,
        to: 500,
        color: 'rgba(68, 170, 213, 0.1)',
        dashStyle: 'shortdash',
        width: 2,
        label: {
          text: 'Cat 5 (>135 knots)',
        },
      }],
    }, { // Secondary yAxis
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
      min: 850,
      title: {
        text: 'Sea Level Pressure',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: '{value} mb',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      opposite: true,
    }],
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%d-%b-%y<br />%H:%M}',
      },
      accessibility: {
        rangeDescription: 'Date Time',
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        lineWidth: 3,
        states: {
          hover: {
            lineWidth: 4,
          },
        },
        marker: {
          enabled: false,
        },
      },
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      }],
    },
  };

  return options;
};

export default function StormChart(props) {
  const { meta, datas } = props;
  const chartOpt = getChartOptions();

  const renderChart = d => {
    const dataSeries = [];
    let ts = null;
    let valWind = null;
    let valPressure = null;

    const seriesWind = [];
    const seriesPressure = [];

    // data series
    orderBy(datas, ['datetime'], ['asc']).map(data => {
      // by pass 29 Feb
      if ((moment(data.datetime).format('M') !== 2) && (moment(data.datetime).date() !== 29)) {
        if (d.wind !== null) {
          valWind = parseInt(data.wind, 10).toFixed(0);
          valPressure = parseInt(data.pressure, 10).toFixed(0);
        } else {
          valWind = null;
          valPressure = null;
        }
        // console.log(data.datetime, valWind, valPressure);
        // console.log(moment(data.datetime).format('YYYY-MM-DD HH:00:00'), valWind, valPressure);
        ts = moment.utc(data.datetime).valueOf();
        // console.log(ts);
        seriesWind.push([ts, parseInt(valWind, 10)]);
        seriesPressure.push([ts, parseInt(valPressure, 10)]);
      }

      return null;
    });

    // wind speed
    dataSeries.push({
      name: 'Wind Speed',
      type: 'spline',
      data: seriesWind,
      color: Highcharts.getOptions().colors[1],
      tooltip: {
        valueSuffix: ' knots',
        xDateFormat: '%d %B %y %H:00 น.',
      },
    });

    // pressure
    dataSeries.push({
      name: 'Sea Pressure Level',
      type: 'spline',
      yAxis: 1,
      data: seriesPressure,
      color: Highcharts.getOptions().colors[0],
      tooltip: {
        valueSuffix: ' mb',
        xDateFormat: '%d %B %H:00 น.',
      },
    });

    return dataSeries;
  };

  const chartSeries = renderChart(datas);
  chartOpt.title.text = `${meta.name} (${meta.type})`;
  chartOpt.subtitle.text = `Code: ${meta.code}, Basin: ${meta.basin}, Max Wind Speed: ${meta.max_wind_speed} knots`;
  chartOpt.series = chartSeries;

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOpt}
      />
    </>
  );
}

StormChart.propTypes = {
  meta: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
};
