import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { orderBy as orderByLodash } from 'lodash';

// style, icons
import { withStyles } from '@material-ui/core/styles';

// ui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import tJson from '../../utils/TJson';
import { FaChartLine, FaChartBar, FaVideo } from '../../utils/Fontawesome';
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';
import { styles, StyledTableRow } from '../../styles/Style';
import DamStatusColor from './DamStatusColor';
import DamDailyChart from './DamDailyChart';
import DamDailyCompareChart from './DamDailyCompareChart';
import DamContext from '../../contexts/damContext';
import urlCheck from '../../utils/UrlstatusCheck';

const initPath = process.env.MIX_APP_PATH;
const tableSummaryColor = '#eee';

const columnData = [
  {
    id: 'dam.dam_name.th', align: 'left', numeric: false, disablePadding: true, label: 'อ่างเก็บน้ำ', sortable: true,
  },
  {
    id: 'dam_storage', align: 'right', numeric: true, disablePadding: false, label: 'ความจุ', sortable: true,
  },
  {
    id: 'dam_storage_percent', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาณน้ำ', sortable: true,
  },
  {
    id: 'dam_uses_water_percent', align: 'right', numeric: true, disablePadding: false, label: 'ใช้การได้จริง', sortable: true,
  },
  {
    id: 'dam_inflow', align: 'right', numeric: true, disablePadding: false, label: 'น้ำไหลลง', sortable: true,
  },
  {
    id: 'dam_released', align: 'right', numeric: true, disablePadding: false, label: 'น้ำระบาย', sortable: true,
  },
  {
    id: 'chart', numeric: false, disablePadding: false, label: '',
  },
];

const DamLargeList = ({
  classes, datas, handleSearchMarker, t,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dam_storage_percent');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: t('กราฟอ่างเก็บน้ำรายวัน กรมชลประทาน'),
      content: <DamDailyChart id={data.dam.id} agencyId={12} />,
    };

    handler(params);
  };

  const handleChartCompareDaily = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: t('กราฟเปรียบเทียบอ่างเก็บน้ำรายวัน กรมชลประทาน'),
      content: <DamDailyCompareChart id={data.dam.id} agencyId={12} />,
    };

    handler(params);
  };

  // const urlStatus = async urlpath => {
  //   let endtime = null;
  //   let checktf = false;

  //   setTimeout(() => {
  //     if (endtime === null) {
  //       window.open(`${urlpath}`, '_blank');
  //       endtime = performance.now();
  //     }
  //   }, 2500);
  //   await fetch(`${initPath}proxy/cgi.php?file=${urlpath}`)
  //     .then(response => {
  //       if (response.status === 200) {
  //         window.open(`${urlpath}`, '_blank');
  //       } else {
  //         checktf = true;
  //       }
  //     });
  //   if (checktf || endtime === null) {
  //     alert('ไม่สามารถเปิดหน้าเว็บได้ในขณะนี้กรุณาลองใหม่ในภายหลัง');
  //     endtime = performance.now();
  //   }
  // };

  const damStatus = (data, percent) => {
    const status = DamStatusColor(parseFloat(percent).toFixed(0));

    let statusText = '';
    if (parseFloat(percent) === 0) {
      statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} <b>(--)</b>`;
    } else {
      statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} <span style='color:${status.color.statusColor}'> <b>(${parseFloat(percent).toFixed(0)}%)</b></span>`;
    }
    return statusText;
  };

  // summary
  let totalDamStorage = 0;
  let totalDamUseWater = 0;
  let totalNormalStorage = 0;
  let totalReleased = 0;
  let totalInflow = 0;

  datas.forEach(n => {
    totalDamStorage += n.dam_storage;
    totalDamUseWater += n.dam_uses_water;
    totalNormalStorage += n.dam.normal_storage;
    totalReleased += n.dam_released;
    totalInflow += n.dam_inflow;
  });

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, datas.length - page * rowsPerPage);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
          <TableHeaderEnhance
            columnData={columnData}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            firstColumnSticky
          />
          <TableBody>
            {orderByLodash(datas, orderBy, order)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                const storageStatus = damStatus(
                  row.dam_storage,
                  row.dam_storage_percent.toFixed(0),
                );

                let mapLink = null;
                if (handleSearchMarker && row.dam.dam_lat && row.dam.dam_long) {
                  mapLink = (
                    <Button
                      onClick={handleSearchMarker(row.id, row.dam.dam_lat, row.dam.dam_long)}
                      title={t('แสดงตำแหน่งบนแผนที่')}
                      style={{
                        textAlign: 'left', paddingLeft: 5, border: '1px solid #ccc', textTransform: 'none',
                      }}
                    >
                      {`${tJson(row.dam.dam_name, 'เขื่อน')}`}
                    </Button>
                  );
                } else {
                  mapLink = tJson(row.dam.dam_name, 'เขื่อน');
                }

                return (
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                      <Box display="flex">
                        {mapLink}

                        {row.cctv.url && (
                          <div>
                            <Tooltip title="CCTV">
                              {/* <IconButton aria-label="CCTV" onClick={() => urlStatus(row.cctv.url)}> */}
                              <IconButton aria-label="CCTV" onClick={async () => (await urlCheck(row.cctv.url, 'type_cgi', 2500) ? window.open(`${row.cctv.url}`, '_blank') : alert('ไม่สามารถเปิดหน้าเว็บได้ในขณะนี้กรุณาลองใหม่ในภายหลัง'))}>
                                <FaVideo fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {row.dam.normal_storage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </TableCell>
                    <TableCell align="right" dangerouslySetInnerHTML={{ __html: storageStatus }} />
                    <TableCell align="right">
                      {row.dam_uses_water_percent > 0 ? (
                        <div>
                          {`${row.dam_uses_water.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${row.dam_uses_water_percent.toFixed(0)}%)`}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </TableCell>
                    <TableCell align="right">{row.dam_inflow}</TableCell>
                    <TableCell align="right">{row.dam_released}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <DamContext.Consumer>
                        {({ handleModalOpen }) => (
                          <>
                            <Tooltip title={t('กราฟรายปี')}>
                              <IconButton className={classes.button} aria-label="Chart" onClick={handleChart(handleModalOpen, row)}>
                                <FaChartLine fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('กราฟเปรียบเทียบรายวัน')}>
                              <IconButton className={classes.button} aria-label="Chart" onClick={handleChartCompareDaily(handleModalOpen, row)}>
                                <FaChartBar fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </DamContext.Consumer>
                    </TableCell>
                  </StyledTableRow>
                );
              })}

            {datas.length === 0 && (
              <StyledTableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={7} align="center">
                  {t('ไม่มีข้อมูลอ่างขนาดใหญ่ตามเงื่อนไข')}
                </TableCell>
              </StyledTableRow>
            )}

            <StyledTableRow style={{ color: '#FFF', backgroundColor: tableSummaryColor }}>
              <TableCell component="th" scope="row" align="left" className="first-column-sticky-total">
                <strong>
                  {`${t('รวม')} ${datas.length} ${t('อ่าง')}`}
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  {totalNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  {totalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  <span
                    style={{
                      color: DamStatusColor(
                        parseFloat(totalNormalStorage).toFixed(0),
                      ).color.statusColor,
                    }}
                  >
                    {!Number.isNaN((totalDamStorage * 100) / totalNormalStorage) && (
                      <span>
                        {' ('}
                        {((totalDamStorage * 100) / totalNormalStorage).toFixed(0)}
                        {'%)'}
                      </span>
                    )}
                  </span>
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  {totalDamUseWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  {!Number.isNaN((totalDamUseWater * 100) / totalNormalStorage) && (
                    <span>
                      {' ('}
                      {((totalDamUseWater * 100) / totalNormalStorage).toFixed(0)}
                      {'%)'}
                    </span>
                  )}
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  {totalInflow.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  {totalReleased.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </strong>
              </TableCell>
              <TableCell />
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        className={classes.hiddenLine}
        count={datas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActionsWrapped}
        labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
        labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to} ${t('จาก')} ${count} ${t('รายการ')}`}
      />
    </>
  );
};

DamLargeList.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  handleSearchMarker: PropTypes.func.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DamLargeList);
