/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { withLeaflet, MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-geotiff';
import 'leaflet-geotiff/leaflet-geotiff-plotty';

class GeotiffLayer extends MapLayer {
  // eslint-disable-next-line class-methods-use-this
  createLeafletElement(props) {
    const { url, options } = props;
    return L.leafletGeotiff(url, options);
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    const { name } = this.props;
    const Layer = this.leafletElement.addTo(map);
    Layer.setStyle({
      opacity: 0.5,
    });
    const control = L.control.layers(null, null, { collapsed: false, position: 'topleft', hideSingleBase: true }).addTo(map);
    control.addOverlay(Layer, name);
    // if (name === 'ESAN_MAP_MAX') map.removeLayer(Layer);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const PlottyGeotiffLayer = withLeaflet(props => {
  const { options, layerRef } = props;
  // eslint-disable-next-line global-require
  const plotty = require('plotty');
  plotty.addColorScale('cpyFloodPlainScale', ['#33ECFF', '#33ECFF', '#339FFF', '#339FFF', '#333FFF', '#333FFF', '#6833FF', '#6833FF', '#E6083E', '#E6083E'], [0.0005, 0.01, 0.01, 0.025, 0.025, 0.05, 0.05, 0.1, 0.1, 1]);
  options.renderer = new L.LeafletGeotiff.Plotty(options);
  return <GeotiffLayer ref={layerRef} {...props} />;
});
