
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Link from '@material-ui/core/Link';

import { withTranslation } from 'react-i18next';

// color .
// import grey from '@material-ui/core/colors/grey';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

// icon .
import { FaHome } from '../../utils/Fontawesome';
import { HiiMediaIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class VideoView extends Component {
  constructor(props) {
    super(props);

    this.urlDecode = this.urlDecode.bind(this);
  }

  urlDecode = url => Buffer.from(url, 'base64').toString('ascii');

  render() {
    const { match: { params: { videoUrl } }, classes, t } = this.props;

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}media`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('คลังสื่อ เผยแพร่')}
                </Typography>
              </Button>
              <Button href={`${initPath}media/video/all`}>
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('วีดีทัศน์')}
                </Typography>
              </Button>
              <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                {t('รายละเอียด')}
              </Typography>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiMediaIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4" gutterBottom>
                      {t('คลังสื่อ เผยแพร่')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Box
              style={{
                position: 'relative',
                paddingBottom: '50%' /* 16:9 */,
                height: 0,
                width: '100%',
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  display: 'block',
                  width: '100%',
                  // height: '652.5px',
                  height: '100%',
                  margin: '0 auto',
                }}
                src={`https://www.youtube.com/embed/${videoUrl}?autoplay=1`}
                frameBorder="0"
                title="ViewIframe"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

VideoView.defaultProps = {
  match: {
    params: {
      videoUrl: '',
    },
  },
};

VideoView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      videoUrl: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(VideoView));
