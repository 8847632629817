/* eslint-disable react/jsx-props-no-spreading */
import { withLeaflet, MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-kmz';

class StromKmz extends MapLayer {
  // eslint-disable-next-line class-methods-use-this
  createLeafletElement(props) {
    const { url } = props;
    const { map } = this.props.leaflet;
    // console.log("url: ", url);

    const myKmzLayer = new L.kmzLayer().addTo(map);
    myKmzLayer.load(url);

    return myKmzLayer;
  }

  // componentDidMount() {
  //   const { map } = this.props.leaflet;
  //   this.leafletElement.addTo(map);
  // }
}

export default withLeaflet(StromKmz);
