import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

// ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { Hidden } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { FaCalendarAlt, FaPlay, FaCloudSunRain } from '../../../utils/Fontawesome';

import WrfLatest from './WrfLatest';

import { styles } from '../../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class Wrf extends Component {
  render() {
    const { classes, theme, t } = this.props;
    return (
      <>
        <Grid
          container
          spacing={0}
          className={classes.boxTopRadius}
          style={{ marginTop: 50, marginBottom: 20 }}
        >
          <Grid
            item
            xs={12}
            sm={9}
            md={7}
            lg={7}
            style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}
          >
            <Box m={1} className={classes.titleBar}>
              <Hidden xsDown>
                {t('แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน ด้วย WRF-ROMS Model')}
              </Hidden>
              <Hidden smUp><Typography variant="h6">{t('แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน')}</Typography></Hidden>
            </Box>
          </Grid>
          <Grid item xs>
            <Tooltip title={t('ระบบคาดการณ์ฝนล่วงหน้า 3 วัน')}>
              <Button
                href={`${initPath}forecast/wrfroms`}
                className={classes.outlinedLeftDesktopPrimary}
                target="_blank"
              >
                <FaCloudSunRain />
                <Hidden smDown>
                  <Typography variant="h6" style={{ marginLeft: 8 }}>
                    {t('ระบบคาดการณ์')}
                  </Typography>
                </Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
              <Button
                // href={`${initPath}forecast/wrf/history/animation`}
                href={`${initPath}forecast/wrf/history/animation`}
                className={classes.outlinedLeftDesktopPrimary}
              >
                <FaPlay style={{ fontSize: '0.8em' }} />
                <Hidden smDown>
                  <Typography variant="h6" style={{ marginLeft: 8 }}>
                    {t('ภาพเคลื่อนไหว')}
                  </Typography>
                </Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
              <Button
                className={classes.outlinedLeftDesktopPrimary}
                href={`${initPath}forecast/wrf/history/history_list`}
              >
                <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                <Hidden smDown>
                  <Typography variant="h6" style={{ marginLeft: 8 }}>
                    {t('ภาพย้อนหลัง')}
                  </Typography>
                </Hidden>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <WrfLatest />
        <br />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mt={3}>
            โดยทั่วไปความแม่นยำของการคาดการณ์สภาวะฝนจะลดลงเมื่อระยะเวลาคาดการณ์ยาวขึ้น โดยจะมีความแม่นยำสูงสุดที่ 3 วันข้างหน้า ข้อมูลคาดการณ์สภาวะฝนเป็นผลงานในระยะวิจัยและพัฒนา<br/>
            โปรดใช้วิจารณญาณในการนำข้อมูลไปใช้
          </Box>
        </Grid>
        <br />
        <Grid item xs>
          <Box width="100%" p={1} align="right" fontSize="12px" fontFamily="Kanit" color={theme.palette.secondary.main}>
            *แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน ด้วย WRF-ROMS Model มีงานวิจัยที่เกี่ยวข้อง :
            {' '}
            <Link href="https://www.mdpi.com/2073-4433/14/10/1574" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.secondary.main }}>
              https://www.mdpi.com
            </Link>
          </Box>
        </Grid>
        <br />
      </>
    );
  }
}

Wrf.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(Wrf));
