import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;
const sizeIcon = 18;
const WlGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Waterlevel/icon_waterlevel32px-3.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const WlOrange = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Waterlevel/icon_waterlevel32px-1.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const WlRed = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Waterlevel/icon_waterlevel32px-5.png`,
  iconSize: [sizeIcon, sizeIcon],
});


const wlColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อยวิกฤต',
    rangeTitle: '<=10',
    statusColor: '#db802b',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อย',
    rangeTitle: '>10-30',
    statusColor: '#ffc000',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปกติ',
    rangeTitle: '>30-70',
    statusColor: '#00b050',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'มาก',
    rangeTitle: '>70-100',
    statusColor: '#003cfa',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'ล้นตลิ่ง',
    rangeTitle: '>100',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const WlStatusColor = wl => {
  let color;
  let icon;
  const [zero, one, two, three, four, five] = wlColor;

  if (wl === null) {
    color = zero;
    icon = WlGreen;
  } else if (wl > 10) {
    color = five;
    icon = WlRed;
  } else if (wl > 5) {
    color = two;
    icon = WlOrange;
  } else {
    color = three;
    icon = WlGreen;
  }

  return { color, icon };
};

export default WlStatusColor;
