import React, { useEffect, useState } from 'react';
// Material ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
// lodash
import { uniqueId } from 'lodash';

// Lightbox
import 'react-image-lightbox/style.css';

// Styles
import { StyleComp } from '../style';
import '../styles.css';

const useStyles = makeStyles(StyleComp);
const initPath = process.env.MIX_API_HII_URL;

export default function ForecastWaterLevelVideo() {
  const classes = useStyles();
  const [dataGif, setDataGif] = useState();
  // const [dataMp4, setDataMp4] = useState();
  // const [fileSize, setFileSize] = useState(0);

  const [getError, setError] = useState();
  const [loading, setLoading] = useState(true);

  async function fetchDataAndFileSize(URL) {
    const response = await fetch(URL);
    if (response.ok) {
      const data = await response.json();
      const fileSize = await response.headers.get('Content-Length');
      return { data, fileSize };
    }
    setError('Something went wrong ...');
    throw new Error('Something went wrong ...');
  }
  useEffect(() => {
    async function fetchData() {
      const URL = `${initPath}/tiservice/v1/ws/cEniGCuZcTBSa3xj4A8PY187BhpExTfE/stromsurge/wave/antimation/high_wave/lastest`
      const result = await fetchDataAndFileSize(URL);
      // setDataMp4(result.data.data[0]?.Mp4);
      setDataGif(result.data.data[1]?.Gif);
      setLoading(false);
      // setFileSize(result.fileSize);
    }

    fetchData();
  }, []);

  return (
    <>
      {getError && (
        <div className="text-center">
          {getError.message}
        </div>
      )}
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs sm md key={uniqueId()}>
            <Box display="flex" width="100%" my={6} className={classes.bodyBox} justifyContent="center">
              <div
                style={{ display: loading ? 'block' : 'none' }}
              >
                Loading Video ...
              </div>
              {/* {dataMp4 && (
                <video
                  src={dataMp4.file}
                  width="50%"
                  controls
                />
              )} */}
              <div>
                {dataGif && (
                  <img
                    src={dataGif.file}
                    alt={dataGif.file}
                    style={{ display: 'block' }}
                    width="50%"
                    onLoad={() => setLoading(false)}
                    className={classes.fullImg}
                  />
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
