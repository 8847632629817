import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ui
import { FaSpinner } from '../../../utils/Fontawesome';

class UpperWindAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      animation: '',
      keyData: this.props.keyData,
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.fetchData();
  }

  fetchData() {
    const { keyData } = this.state;
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/weather_img/animation?agency_id=9&media_type_id=${keyData === ' 0.6 ' ? '70' : '71'}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          animation: result.data.media_path,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      isLoading,
      error,
      animation,
    } = this.state;

    return (
      <div>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={25} />
          </div>
        )}

        { animation && (
        <video width="100%" controls autoPlay>
          <source src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${animation}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        )}
      </div>
    );
  }
}

UpperWindAnimation.propTypes = {
  keyData: PropTypes.string.isRequired,
};

export default UpperWindAnimation;
