import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Link,
} from 'react-router-dom';
import { uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { SEO } from '../../utils/Seo';

// material-ui
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Img from 'react-image';
import DateFnsUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

// import { DatePicker } from 'material-ui';

// Lightbox
import Lightbox from 'react-image-lightbox';
import { FaSpinner, FaHome } from '../../utils/Fontawesome';

// style or css
import 'react-image-lightbox/style.css';
import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

// lightbox
let images = [];
let counter = 0;

class RadarHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      datas: [],
      interval: 60,
      selectedDate: moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    const { match: { params: { radarType, date } } } = this.props;
    let selectedDate = moment().format('YYYY-MM-DD');
    if (date) {
      selectedDate = date;
    }
    this.setState({
      isLoading: true,
      selectedDate: date,
    });
    this.fetchData(radarType, selectedDate);
  }

  handleDateChange = date => {
    if (date) {
      if (date._isValid) {
        const { match: { params: { radarType } } } = this.props;
        let selectedDate = moment().format('YYYY-MM-DD');
        if (date) {
          selectedDate = moment(date).format('YYYY-MM-DD');
        }
        this.setState({
          isLoading: true,
          selectedDate,
        });
        this.fetchData(radarType, selectedDate, this.state.interval);
      }
    }
  };

  handleDateChangeButton = change => {
    const { match: { params: { radarType, date } } } = this.props;
    const { selectedDate, interval } = this.state;
    let selectedDateVal = selectedDate;
    if (change === '1' && date !== selectedDate) {
      selectedDateVal = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD');
    } else if (change === '-1') {
      selectedDateVal = moment(selectedDate).add(-1, 'days').format('YYYY-MM-DD');
    }
    this.setState({
      isLoading: true,
      selectedDate: selectedDateVal,
    });
    this.fetchData(radarType, selectedDateVal, interval);
  }

  changeInterval = change => {
    const { match: { params: { radarType, date } } } = this.props;
    this.setState(
      {
        interval: change
      }
    )
    this.fetchData(radarType, this.state.selectedDate, change)
  }

  fetchData = (radarType, selectedDate, interval = 60) => {
    fetch(`${process.env.MIX_API_URL}analyst/radar_history_img?date=${selectedDate}&radar_type=${radarType}&interval=${interval}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          isLoading: false,
          datas: result.data,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    images = [];
    counter = 0;
    const {
      match: { params: { date } },
      classes, t, i18n,
    } = this.props;

    const {
      isLoading,
      error,
      datas,
      photoIndex,
      isOpen,
      selectedDate,
      interval
    } = this.state;

    const radarName = (datas && datas.length > 0) ? datas[0].radar_name.replace('เรดาร์', '')
      .replace('กม.', '')
      .replace('รัศมี', '')
      .replace('240', '')
      .replace('160', '')
      .replace('150', '')
      .replace('120', '')
      .trim() : '';

    const radarCircle = (datas && datas.length > 0)
      ? datas[0].radar_name.replace(datas[0].radar_name
        .replace('กม.', '')
        .replace('รัศมี', '')
        .replace('240', '')
        .replace('160', '')
        .replace('150', '')
        .replace('120', '')
        .trim(), '')
        .trim() : '';

    const radarNameCircle = t('เรดาร์') + t(radarName) + ' ' + t(radarCircle) + ' ' + selectedDate;

    return (
      <>
        <SEO
          title={radarNameCircle}
          description={radarNameCircle}
          keywords={radarNameCircle}
        />
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {isLoading === true && (
                <FaSpinner size={25} />
              )}

              {error && (
                <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 12 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline">
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/radar`}>
                  <Typography variant="h6" color="textSecondary">
                    {t('เรดาร์')}
                  </Typography>
                </Link>
                {(datas && datas.length > 0) && (
                  <Typography variant="h6" color="textSecondary">
                    {t(radarName)}
                    {' '}
                    {t(radarCircle)}
                  </Typography>
                )}
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid item sm={3} md={3} lg={3}>
            {isLoading === true && (
              <FaSpinner size={25} />
            )}

            {error && (
              <div className="text-center">{error.message}</div>
            )}
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 24 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" p={0} className={classes.boxTopRadius} alignItems="center" height={53}>
                <Box flexGrow={1}>
                  <Box className={classes.titleName}>
                    {t(radarName)}
                    {' '}
                    {t(radarCircle)}
                  </Box>
                </Box>
                <Box className={classes.titleName}>
                  <Tooltip  title={<p style={{ fontSize: 14 }}>บางชุดข้อมูลอาจ ไม่มีข้อมูล ในความถี่ที่ได้เลือก</p>} placement="top" style={{ zIndex: 1 }}>
                    <Typography variant="p">
                      {t('ความถี่ข้อมูล')}
                      {' : '}
                    </Typography>
                  </Tooltip>
                </Box>
                <Button
                  variant={interval === 15 ? 'contained' : 'outlined'}
                  className={interval === 15 ? classes.activeButton : classes.button}
                  style={{ marginRight: 5 }}
                  onClick={() => this.changeInterval(15)}
                >
                  <Typography variant="p">15</Typography>
                </Button>
                <Button
                  variant={interval === 30 ? 'contained' : 'outlined'}
                  className={interval === 30 ? classes.activeButton : classes.button}
                  style={{ marginRight: 5 }}
                  onClick={() => this.changeInterval(30)}
                >
                  <Typography variant="p">30</Typography>
                </Button>                <Button
                  variant={interval === 60 ? 'contained' : 'outlined'}
                  className={interval === 60 ? classes.activeButton : classes.button}
                  style={{ marginRight: 5 }}
                  onClick={() => this.changeInterval(60)}
                >
                  <Typography variant="p">60</Typography>
                </Button>
                <Box className={classes.titleName}>
                  <Typography variant="p">
                    {t('นาที')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
            <Button onClick={() => this.handleDateChangeButton('-1')}>
              <KeyboardArrowLeftIcon />
            </Button>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                  <KeyboardDatePicker
                    format="YYYY-MM-DD"
                    value={selectedDate}
                    views={['year', 'month', 'date']}
                    maxDate={date}
                    onChange={this.handleDateChange}
                    style={{ fontSize: 14 }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Button onClick={() => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button>
          </Grid>

          {isLoading === false && datas.length <= 0
            && (
              <Grid container spacing={4}>
                <Grid item xs>
                  <Typography variant="h6">
                    {t('ไม่พบข้อมูล')}
                  </Typography>
                </Grid>
              </Grid>
            )}

          <Grid container spacing={4}>
            {datas.map((row, j) => (
              row.data.map((radar, i) => {
                let realImage = '';
                let currentIndex = counter;
                if (radar.media_path !== null) {
                  images.push({ src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${radar.media_path}`, title: `${t(radarName)}  ${t(radarCircle)} |  ${radar.media_datetime} UTC` });

                  realImage = (
                    <Button onClick={() => this.setState({ isOpen: true, photoIndex: currentIndex })}>
                      <Img
                        src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${radar.media_path_thumb}`}
                        width="188"
                        height="188"
                        className={classes.responsive}
                        alt={row.datetime}
                      />
                    </Button>
                  );
                  counter += 1;
                } else {
                  realImage = (
                    <Img
                      src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${radar.media_path}`}
                      width="188"
                      height="188"
                      className={classes.responsive}
                      alt="No Data"
                    />
                  );
                }

                return (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                    <Box className={classes.boxRadarHistory}>
                      <Box display="block" align="center">
                        {realImage}
                      </Box>
                      <Box p={1} display="flex" alignItems="center" justifyContent="center">
                        <Typography className={classes.dateTime}>
                          {t('ล่าสุด')}
                          {' '}
                          {moment(radar.media_datetime).format('YYYY-MM-DD')}
                          {' '}
                          {t('เวลา')}
                          {' : '}
                          {' '}
                          {moment(radar.media_datetime).format('HH:mm')}
                          {' '}
                          {/* {row.timezone} */}
                          {'GMT'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })
            ))}
          </Grid>

        </div>

        {isOpen && (
          <Lightbox
            imagePadding={50}
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

RadarHistory.defaultProps = {
  match: {
    params: {
      radarType: 'phs240',
      date: '2019-01-01',
    },
  },
};

RadarHistory.propTypes = {
  // theme: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      radarType: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  }),
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(RadarHistory));
