// Legend
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    position: 'fixed',
    left: '1%',
    bottom: '6%',
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
  },
});

export default function Legend() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper style={{ opacity: '0.8', backgroundColor: '#EAEAEA' }}>
      <Box p={1}>
        <b>{t('เกณฑ์ฝนรายเดือน')}</b>
        <Grid container>
          <div
            style={{
              backgroundColor: '#218291',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>200</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#3C8D96',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>180</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#52999C',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>160</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#68A6A1',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>140</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#7BB0A3',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>120</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#91BDA8',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>100</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#A7C9AD',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>80</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#BED9B4',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>60</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#D4E6B8',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>40</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#EAF2BB',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>20</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#FCFCFC',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>0</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#F5EAAB',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-20</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#EDD898',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-40</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#E3C586',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-60</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#D9B375',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-80</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#CFA263',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-100</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#C49054',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-120</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#BA8045',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-140</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#B07137',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-160</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#A6622B',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-180</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#9C551F',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>-200</b>
        </Grid>
      </Box>
    </Paper>
  );
}
