/**
 * *
 * @version 1.0.0
 * @author [Phithakkhet@hii.or.th]
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiAccordionDetails-root": {
      paddingTop: 0,
    },
  },
  ul: {
    marginTop: 0,
  },
  li: {
    color: '#fff',
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    listStylePosition: 'outside',
    listStyle: 'disc',
    margin: 5,
    marginLeft: -23,
  },
}));

const CompMeaning = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ul className={classes.ul}>
        <li className={classes.li}>ฝนสะสม 1 วัน หมายถึง ปริมาณฝนรวมตั้งแต่เวลา 07.00 น. ถึง เวลา 07.00 น. ของวันถัดไป</li>
        <li className={classes.li}>ฝนสะสม 3 วัน หมายถึง ผลรวมของ “ฝนสะสม 1 วัน” จำนวน 3 วัน</li>
        <li className={classes.li}>ฝนสะสม 7 วัน หมายถึง ผลรวมของ “ฝนสะสม 1 วัน” จำนวน 7 วัน </li>
        <li className={classes.li}>ฝนสะสม 15 วัน หมายถึง ผลรวมของ “ฝนสะสม 1 วัน” จำนวน 15 วัน </li>
        <li className={classes.li}>ฝนสะสม 30 วัน หมายถึง ผลรวมของ “ฝนสะสม 1 วัน” จำนวน 30 วัน </li>
        <li className={classes.li}>ฝนตรวจวัดในอดีต หมายถึง ปริมาณฝนที่เกิดขึ้นจริง โดยในระบบนี้แสดงปริมาณและการกระจายตัวของฝนในเชิงพื้นที่ โดยใช้ระดับสีเพื่อระบุความรุนแรงของฝน และมีการคำนวณปริมาณฝนสะสมย้อนหลัง 1 วัน 3 วัน 7 วัน 15 วัน และ 30 วัน เพื่อใช้พิจารณามวลน้ำสะสมจากฝนในช่วงเวลาที่ผ่านมา</li>
        <li className={classes.li}>ฝนตรวจวัดในอดีต หมายถึง ปริมาณฝนที่เกิดขึ้นจริง โดยในระบบนี้แสดงปริมาณและการกระจายตัวของฝนในเชิงพื้นที่ โดยใช้ระดับสีเพื่อระบุความรุนแรงของฝน และมีการคำนวณปริมาณฝนสะสมย้อนหลัง 1 วัน 3 วัน 7 วัน 15 วัน และ 30 วัน เพื่อใช้พิจารณามวลน้ำสะสมจากฝนในช่วงเวลาที่ผ่านมา</li>
        <li className={classes.li}>ฝนพยากรณ์รายวัน หมายถึง การคาดการณ์ “ฝนสะสม 1 วัน” ซึ่งในระบบนี้มีการคาดการณ์ “ฝนสะสม 1 วัน” ล่วงหน้า 3 วัน แสดงผลเป็นรายเขต และใช้สีเพื่อแสดงความรุนแรงของฝนในแต่ละเขต </li>
        <li className={classes.li}>อัตราการไหล หมายถึง ความเร็วของน้ำที่ไหลผ่านจุดใดจุดหนึ่งในลำน้ำ มีหน่วยวัดเป็น ลูกบาศก์เมตรต่อวินาที</li>
      </ul>
    </div>
  );
};

export default CompMeaning;
