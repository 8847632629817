import React, { useState, useEffect, } from 'react';
import { processDataAndFilterLatest, fetchFiles } from './utils/Utils'
import { hiiReportInfographic } from './path/drivepath';
import moment from 'moment';
// ui, styles
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Lightbox from "react-image-lightbox";
import Divider from '@material-ui/core/Divider';
import Pagination from '@material-ui/lab/Pagination';
import FormControl from '@material-ui/core/FormControl';
// icon .
import { FaHome } from '../../../utils/Fontawesome';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import "./style/report.css";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 1280,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));
const initPath = process.env.MIX_APP_PATH;

function BoxInfographicHistory(props) {
  const classes = useStyles();
  const [images, setImages] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
  const [currentPage, setCurrentPage] = useState(1);
  const [photoIndex, setPhotoIndex] = useState(0);
  const itemsPerPage = 30;

  const loadImagesForPage = async () => {
    setLoading(true);
    const data = await fetchFiles(currentYear, hiiReportInfographic);
    if (data && data.files) {
      const sortedImages = await processDataAndFilterLatest(data);
      const filterImages = await sortedImages.filter((file) => file.datethai !== 'Invalid date');

      const updatedImages = filterImages.map((image) => ({
        ...image,
        src: `https://drive.google.com/thumbnail?sz=w1000&id=${image.id}`,
      }));
      setImages(updatedImages);
    } else {
      setImages([]);
    }
    setLoading(false);
  };
  // Initial load
  useEffect(() => {
    loadImagesForPage();
  }, [currentYear]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const openModalImage = (id) => {
    const index = images.findIndex((img) => img.id === id);
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleChangeYear = date => {
    console.log(moment(date).format('YYYY'));

    setStartDate(date);
    setCurrentYear(moment(date).format('YYYY'));
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(images.length / itemsPerPage);

  const indexOfLastImage = currentPage * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);
  return (
    <>
      <Grid container spacing={4} style={{ marginTop: 10 }}>
        <Grid item xs>
          <Breadcrumbs aria-label="breadcrumb">
            <Button href={`${initPath}report`}>
              <FaHome fontSize="small" style={{ marginRight: 5 }} />
              <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                {' '}
                {'อินโฟกราฟิกสถานการณ์น้ำ'}
              </Typography>
            </Button>
            <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
              {'รายวัน'}
            </Typography>
          </Breadcrumbs>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" py={2} width="100%" alignItems="center" justifyContent="center" bgcolor="background.paper">
            <Box display="inline" fontFamily="Kanit">
              <Typography variant="h6" style={{ marginRight: 10 }} noWrap>ปี</Typography>
            </Box>
            <Box display="inline">
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <KeyboardDatePicker
                    openTo="year"
                    views={['year']}
                    value={startDate}
                    maxDate={moment().format('YYYY-MM-DD')}
                    minDate={moment('2023-01-01').toDate()} // Convert moment date to JavaScript Date
                    onChange={handleChangeYear}
                    style={{ fontSize: 14 }}
                    format="yyyy" // Format to display only the year
                    disableFuture // Disable selection of future dates
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        {currentImages
          .map((file) => {
            return (
              <Grid item xs={12} sm={3} key={file.id}> {/* Use file.id for unique key */}
                <Box className={classes.imgLinkDaily}>
                  <p 
                    style={{ marginLeft: '6.5rem'}}
                    className={classes.subTitle}
                  >
                    {file.shortDate}
                  </p>
                </Box>
                <Button onClick={() => openModalImage(file.id)} className={classes.imgWrapper}>
                  <div>
                    <Box className={classes.imgLinkDaily}>
                      <img
                        src={`https://drive.google.com/thumbnail?id=${file.id}&sz=w1000`}
                        onError={(e) => e.target.src = "https://via.placeholder.com/180x126.png?text=No+Image"}
                        alt={file.name}
                        style={{ width: '100%', height: '350px' }}
                      />
                    </Box>
                  </div>
                </Button>
              </Grid>
            );
          })}
        {loading && <p>โหลดรูปภาพเพิ่มเติม...</p>}
      </Grid>
      {/* MUI Pagination Component */}
      <Grid container justifyContent="center" style={{ margin: '20px 0' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Grid>
      {isOpen && images[photoIndex].src ? (
        <Lightbox
          style={{ backgroundColor: "white" }}
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          imageTitle={`${photoIndex + 1} / ${images.length}`}
          imagePadding={50}
          reactModalStyle={{ overlay: { zIndex: '1000000' } }}
          wrapperClassName="custom-lightbox"
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      ) : null}
    </>
  )
}

export default BoxInfographicHistory;
