import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    height: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  formControl: {
    width: '100%',
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const styles = {
  mainmenu: {
    fontFamily: 'kanit',
    fontSize: '20px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  menuControlStyle: {
    padding: '5px',
    zIndex: 99999,
    backgroundColor: 'white',
    borderRadius: '5px',
    position: 'fixed',
    right: '0.4%',
    top: '12%',
    width: '18em',
  },
};

const initPath = process.env.MIX_APP_PATH;

export default function CompRadarNavigation(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [_mounted, setMounted] = useState(true);
  const [value, setValue] = useState('');
  const [getTime, setTime] = useState([]);
  const [forecastDate, setForecastDate] = useState([]);
  const [isLoading, setLoading] = useState(true);

  function handleChange(event) {
    // setLoading(true);
    setValue(event.target.value);
    props.handler(event.target.value, getTime[event.target.value]);
    // setLoading(false);
  }

  function initUrl(val) {
    // setLoading(true);
    props.handler(val.filename, val.time);
    // setLoading(false);
  }

  function fetchCsv() {
    const file = `${initPath}proxy/txt.php?file=https://live1.hii.or.th/product/latest/comp_radar/description.txt`;
    // const file = 'https://live1.hii.or.th/product/latest/comp_radar/file_description.txt';

    return fetch(file).then(response => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      return reader.read().then(result => decoder.decode(result.value));
    });
  }

  async function getForecastDate() {
    const csvData = await fetchCsv();

    Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
      complete(result) {
        // console.log(result.data);
        if (_mounted) {
          setForecastDate(result.data);

          const arr = [];
          result.data.map((val, i) => {
            if (i === 2) {
              setValue(val.filename);
              initUrl(val);
            }

            if (i === 0) {
              const d = `${t('ตรวจวัด')} ${val.time.substring(0, 16)}`;
              arr[val.filename] = d;
              props.handlerInitDate(d);
            } else {
              arr[val.filename] = `${t('คาดการณ์')} ${val.time.substring(0, 16)}`;
            }

            return null;
          });

          setTime(arr);
        }
      },
    });
  }

  useEffect(() => {
    // get forecast date
    getForecastDate();

    if (_mounted) {
      setLoading(false);
    }
    // componentWillUnmount
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <>
      {isLoading === true && (
        <div className="text-center">
          Loading...
        </div>
      )}
      <Paper style={styles.menuControlStyle}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Box component="span" m={1} style={styles.mainmenu}>
            {t('ตรวจวัด')}
          </Box>
          <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
            <RadioGroup
              aria-label="date"
              name="ForecastDate"
              className={classes.group}
              value={value}
              onChange={handleChange}
            >
              {forecastDate.slice(0, 3).map(val => (
                <FormControlLabel
                  key={val.filename}
                  value={val.filename}
                  control={<Radio />}
                  label={`${t('ตรวจวัด')} ${val.time.substr(11, 5)}`}
                />
              ))}
            </RadioGroup>
          </div>
          <Box component="span" m={1} style={styles.mainmenu}>
            {t('คาดการณ์')}
          </Box>
          <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
            <RadioGroup
              aria-label="date"
              name="ForecastDate"
              className={classes.group}
              value={value}
              onChange={handleChange}
            >
              {forecastDate.slice(3, 6).map(val => (
                <FormControlLabel
                  key={val.filename}
                  value={val.filename}
                  control={<Radio />}
                  label={`${t('คาดการณ์')} ${val.time.substr(11, 5)}`}
                />
              ))}
            </RadioGroup>
          </div>
        </FormControl>
      </Paper>
    </>
  );
}

CompRadarNavigation.propTypes = {
  handler: PropTypes.func.isRequired,
  handlerInitDate: PropTypes.func.isRequired,
};
