import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';

import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import Modal from '../modal/Modal';
import PmMap from './PmMap';
import RainfallContext from '../../contexts/rainfallContext';
import RainfallStatusColor from '../rainfall/RainfallStatusColor';
import PmChart from '../rainfall/PmChart';
import PmLegend from './PmLegend';

// translate
import tJson from '../../utils/TJson';

import { styles } from '../../styles/Style';

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const style = {
  container: {
    maxHeight: 635,
    borderBottom: '1px solid #ccc',
  },
};

class Pm extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false,
      error: null,
      latest: moment().format('YYYY-MM-DD'),
      dataRainfall: [],
      type: 'dashBoard',

      // map
      map: null,
      zoom: 5.65,
      location: [12.9, 101.6],
      markers: [],

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      df: [],
    };

    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleSetMap = this.handleSetMap.bind(this);
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
  }

  getData() {
    // get data from service rain 24hr
    fetch(`${process.env.MIX_API_URL}public/rain_24h`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        // sorting and filter, rainfall24
        const rainfall24Sort = orderBy(result.data, ['rain_24h'], ['desc']);
        const filterDatas = rainfall24Sort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return ((last24hrs < time) && d.rain_24h > 0);
        });
        this.setState({
          dataRainfall: filterDatas,
          isLoading: false,
          latest: this.getMaxDate(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.rainfall_datetime.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format('YYYY-MM-DD')} `;
    }
    return new Date();
  }

  // get rainfall status
  rainfallStatus = data => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(0), 'rainfall24h');
    return status;
  }

    handleModalClose = () => {
      this.setState({ open: false });
    };

  handleModalOpen = params => {
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleChart = (handler, data, type) => e => {
    const { t } = this.props;
    e.preventDefault();
    const params = {
      header: t(data.station.tele_station_name, 'สถานี'),
      content: <PmChart
        id={data.station.id}
        provinceId={data.geocode.province_code}
        selectedType={type}
      />,
    };
    handler(params);
  }

  handleData = () => {
    this.setState({
      // filterDatas: data,
      type: 'rainfall24h',
    });
  };

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  render() {
    const { classes, t, i18n } = this.props;

    // get required data from state
    const {
      isLoading,
      error,
      open,
      dataRainfall,
      latest,
      type,
      map,
      markers,
      zoom,
      location,
      df,
      modalData,
    } = this.state;

    return (
      <div>
        {error && <div className="text-center">{error.message}</div>}

        <div className={classNames(classes.layoutPm, classes.paperGrid)}>
          <RainfallContext.Provider
            value={{
              datas: dataRainfall,
              handleModalOpen: this.handleModalOpen,
              type,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box display="block">
                  {/* <Box borderBottom="2" border="1" display="flex" justifyContent="flex-end">
                    <Button
                      aria-owns={anchorEl ? 'simple-menu' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                      variant="outlined"
                      size="small"
                      color="default"
                      align="right"
                      className={classes.button}
                      style={{ fontFamily: 'Kanit', fontSize: 14, marginRight: 5 }}
                    >
                      ภาพรวมประเทศไทย
                      <i className="material-icons">arrow_drop_down</i>
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                      style={{ fontFamily: 'Kanit', fontSize: 14 }}
                    >
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคเหนือ</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคตะวันออกเฉียงเหนือ</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคกลาง</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.lableColor} style={{ backgroundColor: '#fff', color: '#555', fontFamily: 'Kanit' }}>ภาคใต้</MenuItem>
                    </Menu>
                    <Button
                      variant="outlined"
                      size="small"
                      color="default"
                      align="right"
                      className={classes.button}
                      style={{ fontFamily: 'Kanit', fontSize: 14 }}
                    >
                      ขยายแผนที่
                      <FaTimes style={{ marginLeft: 5 }}/>
                    </Button>
                  </Box> */}
                  <Box width="100%">
                    <PmMap
                      value=""
                      areaName=""
                      language={i18n.language}
                      datasRainToday={df}
                      datasRainYesterday={df}
                      datasRainMonth={df}
                      datasRainYear={df}
                      searchRainfall24={dataRainfall}
                      searchRainToday={df}
                      searchRainYesterday={df}
                      searchRainMonth={df}
                      searchRainYear={df}
                      dataRainfall={dataRainfall}
                      error={error}
                      isLoading={isLoading}
                      latest={latest}
                      map={map}
                      markers={markers}
                      modalHeader={modalData.header}
                      modalSubHeader={modalData.subheader}
                      modalContent={modalData.content}
                      open={open}
                      type={type}
                      // value={value}
                      zoom={zoom}
                      location={location}
                      handleSetMap={this.handleSetMap}
                    />
                  </Box>
                </Box>
                <PmLegend />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" borderBottom={1}>
                  <Box pb={1}>
                    <Box display="block" fontSize={16} fontWeight="fontWeightBold">
                      {t('สถานีคุณภาพอากาศที่มีมลพิษสูงที่สุด')}
                    </Box>
                    <Box display="block" fontSize={12}>
                      {t('ล่าสุด')}
                      {' '}
                      {`: ${latest} `}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <TableContainer style={style.container}>
                    <Table stickyHeader className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" style={{ backgroundColor: '#e0e0e0' }}>{t('ที่ตั้ง')}</TableCell>
                          <TableCell align="center" style={{ backgroundColor: '#e0e0e0' }}>{t('PM 2.5')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataRainfall
                          .slice(0, 15).map(data => {
                            let mapLink = data.station.tele_station_name.th;
                            if (data.station.tele_station_lat
                              && data.station.tele_station_long
                            ) {
                              mapLink = (
                                <Button
                                  onClick={this.handleSearchMarker(
                                    data.station.id,
                                    data.station.tele_station_lat,
                                    data.station.tele_station_long,
                                  )}
                                  title={`${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                                  style={{ textAlign: 'left', paddingLeft: 15 }}
                                >
                                  {`${tJson(data.station.tele_station_name, 'สถานี')} ${tJson(data.geocode.amphoe_name, 'อ.')} ${tJson(data.geocode.province_name, 'จ.')}`}
                                </Button>
                              );
                            }
                            const rain24h = parseFloat(data.rain_24h).toFixed(0);
                            const rainfallStatusC = this.rainfallStatus(rain24h, 'rainfall24h').color.statusColor;
                            const rainfallStatusFontColor = this.rainfallStatus(rain24h, 'rainfall24h').color.constrastColor;

                            return (
                              <TableRow key={data.id}>
                                <TableCell component="th" scope="row" style={{ whiteSpace: 'normal', paddingLeft: 6, padding: 0 }}>
                                  {mapLink}
                                </TableCell>
                                <TableCell align="center">
                                  <Box display="flex" alignItems="center" justifyContent="center">
                                    <Box
                                      borderRadius={50}
                                      style={{
                                        backgroundColor: rainfallStatusC,
                                        color: rainfallStatusFontColor,
                                        width: '45px',
                                      }}
                                    >
                                      {data.rain_24h}
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Modal
                open={modalData.open}
                modalHeader={modalData.header}
                modalContent={modalData.content}
                resetModal={this.resetModal}
              />
            </Grid>
          </RainfallContext.Provider>
        </div>
      </div>
    );
  }
}

Pm.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(Pm);
