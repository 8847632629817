import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import L from 'leaflet';
import {
  Marker, Popup, Tooltip, LayerGroup,
} from 'react-leaflet';
// material ui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Hidden } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
// icons and style
import { styles } from '../../../styles/Style';

const useStyles = makeStyles(styles);

export default function CommunityMarker() {
  const refCommunityStation = useRef(null);
  const [datas, setData] = useState([]);
  const classes = useStyles();
  const initPath = process.env.MIX_APP_PATH;
  const stationIcon = L.icon({
    iconUrl: '../images/marker/triangle_new/white-up.png',
    iconSize: [8, 8],
  });

  useEffect(() => {
    function fetchData() {
      fetch(`${initPath}json/community/community.json`)
        .then(response => response.json())
        .then(result => setData(result))
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
        });
    }

    fetchData();
  }, []);

  const eachRainfallStation = (data, i) => {
    if (data.lat && data.long) {
      const position = [parseFloat(data.lat), parseFloat(data.long)];
      return (
        <Marker
          id={data.FID}
          key={i}
          position={position}
          icon={stationIcon}
        >
          <Popup autoPan>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {`ชุมชน ${data.name} `}
                        <br />
                        {`อ.${data.amphoe} `}
                        {`ต.${data.tambon} `}
                        {`จ.${data.province} `}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>ลุ่มน้ำ</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{data.basin}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>ภาค</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{data.region}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>พิกัด</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{`${data.lat}, ${data.long}`}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>ปี</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{data.Year === '0' ? '-' : data.Year}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {`ชุมชน ${data.name} `}
                </strong>
                <br />
                {`${`อ.${data.amphoe} `} ${`ต.${data.tambon} `} `}
                <br />
                {`${`จ.${data.province} `}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  };

  return (
    <LayerGroup ref={refCommunityStation}>
      {datas.map(eachRainfallStation)}
    </LayerGroup>
  );
}
