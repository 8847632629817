import React, { useState, useEffect } from 'react';
import {
    hiiSituation,
    hiiSituation01,
    hiiSituation02,
    hiiSituation03,
    apiKeyGoogle
} from './path/drivepath';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useStyles from './style';
import { processDataAndFilterLatest, handleClick } from './utils/Utils'
import moment from 'moment';

const initPath = process.env.MIX_APP_PATH;

function BoxHii(props) {
    const { openModalImage } = props;
    const classes = useStyles();
    const [files01, setFiles01] = useState([]);
    const [files02, setFiles02] = useState([]);
    const [files03, setFiles03] = useState([]);
    const link01 = hiiSituation.find(v => v.id === 1).history;
    const link02 = hiiSituation.find(v => v.id === 2).history;
    const link03 = hiiSituation.find(v => v.id === 3).history;
    const APP_ENV = process.env.NODE_ENV;
    useEffect(() => {

        const fetchFiles = async (id, type) => {
            try {
                const currentYear = new Date().getFullYear();
                const param01 = type.filter(v => v.yearEng == currentYear)[0];
                let url = '';
                if (!param01) return null;
                // const url = `${process.env.MIX_APP_URL}proxy/api-mhesiwarroom.php?file=https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                // const url = `https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                if (APP_ENV === 'local') {
                    url = `https://www.googleapis.com/drive/v3/files?q='${param01.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                } else {
                    url = `${process.env.MIX_APP_URL}proxy/api-mhesiwarroom.php?file=https://www.googleapis.com/drive/v3/files?q='${param01.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                }
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                return data;
            } catch (error) {
                console.error(`Error fetching files for id ${id}:`, error);
                return null;
            }
        };

        const processAndSetFiles = async () => {
            const data01 = await fetchFiles(1, hiiSituation01);
            const data02 = await fetchFiles(2, hiiSituation02);
            const data03 = await fetchFiles(3, hiiSituation03);

            if (data01.files) {
                const sortedFiles01 = await processDataAndFilterLatest(data01);

                setFiles01(sortedFiles01);
            }

            if (data02.files) {
                const sortedFiles02 = await processDataAndFilterLatest(data02);
                setFiles02(sortedFiles02);
            }

            if (data03.files) {
                const sortedFiles03 = await processDataAndFilterLatest(data03);
                setFiles03(sortedFiles03);
            }
        };

        processAndSetFiles();
    }, []);

    return (
        <>
            <Grid container spacing={3} justifyContent="center" style={{ marginTop: 30 }}>
                <Grid item xs>
                    <Box className={classes.title} style={{ backgroundColor: '#E1E1E2' }}>
                        สถานการณ์น้ำประจำวัน
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="center" style={{ marginBottom: 30 }}>
                <Grid item xs={12} sm>
                    <p className={classes.subTitle}>แจ้งเตือนสถานการณ์</p>
                    {files01.length > 0 ?
                        files01.slice(0, 1).map((file, i) => {
                            return (
                                <Button onClick={() => openModalImage(`https://drive.google.com/thumbnail?sz=w1000&id=${file.id}`)} className={classes.imgWrapper} key={i}>
                                    <div style={{ borderRadius: 9, border: '7px solid #FE4127' }}>
                                        <Box className={classes.imgLinkDaily}>
                                            <img src={`https://drive.google.com/thumbnail?sz=w500&id=${file.id}`}
                                                alt={file.name}
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </div>
                                </Button>
                            );
                        })
                        :
                        (
                            <div style={{ borderRadius: 9, border: '7px solid #AEA9A5' }}>
                                <Box className={classes.imgLinkDaily}>
                                    <img
                                        src="https://via.placeholder.com/180x126.png?text=No+Image"
                                        alt="NoImage"
                                        style={{ width: '100%', height: '100%'}}
                                    />
                                </Box>
                            </div>
                        )
                    }

                    <Box width={'100%'} display="flex" justifyContent="flex-end">
                        <Button
                            component="a"
                            color="secondary"
                            title="ดูย้อนหลัง"
                            className={classes.btn}
                            style={{ background: '#FCBEB6' }}
                            onClick={() => handleClick(link01)}
                        >
                            ดูย้อนหลัง
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm>
                    <p className={classes.subTitle}>อินโฟกราฟิก</p>

                    {files02 &&
                        files02.slice(0, 1).map((file, i) => {
                            return (
                                <Button onClick={() => openModalImage(`https://drive.google.com/thumbnail?sz=w1000&id=${file.id}`)} className={classes.imgWrapper} key={i}>
                                    <div style={{ borderRadius: 9, border: '7px solid #AEA9A5' }}>
                                        <Box className={classes.imgLinkDaily}>
                                            <img src={`https://drive.google.com/thumbnail?sz=w500&id=${file.id}`}
                                                alt={file.name}
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </div>
                                </Button>
                            );
                        })}

                    <Box width={'100%'} display="flex" justifyContent="flex-end">
                        <Button
                            component="a"
                            color="secondary"
                            title="ดูย้อนหลัง"
                            className={classes.btn}
                            style={{ background: '#E1E1E2' }}
                            onClick={() => handleClick(link02)}
                        >
                            ดูย้อนหลัง
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm>
                    <p className={classes.subTitle}>รายงานประจำวัน</p>

                    {files03 &&
                        files03.slice(0, 1).map((file, i) => {
                            return (
                                <Button onClick={() => openModalImage(`https://drive.google.com/thumbnail?sz=w1000&id=${file.id}`)} className={classes.imgWrapper} key={i}>
                                    <div style={{ borderRadius: 9, border: '7px solid #3CCA41' }}>
                                        <Box className={classes.imgLinkDailyReport}>
                                            <img src={`https://drive.google.com/thumbnail?sz=w500&id=${file.id}`}
                                                alt={file.name}
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </div>
                                </Button>
                            );
                        })}


                    <Box width={'100%'} display="flex" justifyContent="flex-end">
                        <Button
                            component="a"
                            color="secondary"
                            title="ดูย้อนหลัง"
                            className={classes.btn}
                            style={{ background: '#B6F9B9' }}
                            onClick={() => handleClick(link03)}
                        >
                            ดูรายงานย้อนหลัง
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default BoxHii;