import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

// color .
// import grey from '@material-ui/core/colors/grey';

// icon .
import { HiiThaiwaterIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

// img .
const initPath = process.env.MIX_APP_PATH;

class Error404 extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment key="key">
        <div className={classes.layout}>
          <Grid container spacing={{ xs: 0, sm: 4, md: 6 }} justifycontent="center" alignItems="center">
            <Grid item xs={12} sm={5} md={4}>
              <Box ml={{ xs: 0, sm: 3, md: 9 }} mt={6} display="block" justifyContent={{ xs: 'center', sm: 'flex-start' }} width="100%">
                <Box textAlign={{ xs: 'center', sm: 'left' }}><Typography style={{ fontSize: '5em', lineHeight: '100%', fontWeight: 300 }} color="primary">ขออภัย,</Typography></Box>
                <Box textAlign={{ xs: 'center', sm: 'left' }}><Typography style={{ fontSize: '2.5em', lineHeight: '150%', fontWeight: 300 }}>ไม่พบหน้าที่ต้องการ</Typography></Box>
                <Box mt={2} textAlign={{ xs: 'center', sm: 'left' }}><Button
                  variant="contained"
                  color="primary"
                  href={`${initPath}`}
                >
                  <Typography variant="h6">กลับสู่หน้าหลัก</Typography>
              </Button></Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7} md={8}>
              <img src={`${initPath}images/theme/banner/Macos_404.png`} className={classes.responsiveAbout} alt="ไม่พบหน้าที่ต้องการ" />
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

Error404.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Error404);
