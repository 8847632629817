import React, { Component } from 'react';
import PropTypes from 'prop-types';
// lodash
import {
  orderBy,
} from 'lodash';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';

import { FaSpinner } from '../../utils/Fontawesome';
import AnimationImage from '../../utils/AnimationImage';

// array by media type id
const mediaType = [];
mediaType[24] = { title: 'แผนภาพความสูงและทิศทางของคลื่นทะเล ประเทศไทย', imgSize: { w: 755, h: 560 } };
mediaType[156] = { title: 'แผนภาพความสูงและทิศทางของคลื่นทะเล มหาสมุทรแปซิฟิค', imgSize: { w: 755, h: 560 } };
mediaType[155] = { title: 'แผนภาพความสูงและทิศทางของคลื่นทะเล ทั่วโลก', imgSize: { w: 755, h: 560 } };
mediaType[23] = { title: 'แผนภาพความสูงและทิศทางของคลื่นทะเล มหาสมุทรอินเดีย', imgSize: { w: 755, h: 560 } };
mediaType[143] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล ประเทศไทย', imgSize: { w: 550, h: 755 } };
mediaType[144] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล มหาสมุทรแปซิฟิค', imgSize: { w: 550, h: 755 } };
mediaType[145] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล ทั่วโลก', imgSize: { w: 550, h: 755 } };
mediaType[146] = { title: 'แผนภาพอุณหภูมิผิวน้ำทะเล มหาสมุทรอินเดีย', imgSize: { w: 550, h: 755 } };
mediaType[219] = { title: 'แผนภาพค่าเบี่ยงเบนความสูงระดับน้ำทะเล', imgSize: { w: 1035, h: 687 } };
mediaType[30] = { title: 'ภาพเรดาร์', imgSize: { w: 650, h: 900 } };

class ImageAnimation extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      images: [],

    };
  }

  componentDidMount() {
    const { agencyId, mediaTypeId, imageType } = this.props;
    // get data from ws
    this.getData(agencyId, mediaTypeId, imageType);
  }

  getData(agencyId, mediaTypeId, imageType) {
    const startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
    const startDateRadar = moment().subtract(1, 'd').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const currentYear = moment().format('YYYY');
    const currentMonth = moment().format('MM');
    const width = mediaType[mediaTypeId].imgSize.w;

    if (imageType === '') {
      // wave high
      if (mediaTypeId === 23 || mediaTypeId === 24 || mediaTypeId === 155 || mediaTypeId === 156) {
        fetch(`${process.env.MIX_API_URL}public/weather_history_img/month?agency_id=${agencyId}&media_type_id=${mediaTypeId}&year=${currentYear}&month=${currentMonth}`)
          .then(response => {
            if (response.ok) {
              this.setState({ isLoading: true });
              return response.json();
            }
            throw new Error('Something went wrong ...');
          })
          .then(result => {
            const m = [];
            const dataSort = orderBy(result.data, ['media_datetime'], ['asc']);
            const dataDistinct = dataSort
              .filter((v, i, a) => a.findIndex(t => (t.media_path === v.media_path)) === i);

            dataDistinct.forEach(d => {
              if (d.media_path !== '') m.push(`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${d.media_path}`);
            });

            this.setState({
              isLoading: false,
              images: m,
              width,
            });
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else {
        // sst
        fetch(`${process.env.MIX_API_URL}public/weather_history_img/date_range?agency_id=${agencyId}&media_type_id=${mediaTypeId}&start_date=${startDate}&end_date=${endDate}`)
          .then(response => {
            if (response.ok) {
              this.setState({ isLoading: true });
              return response.json();
            }
            throw new Error('Something went wrong ...');
          })
          .then(result => {
            const m = [];
            const dataSort = orderBy(result.data, ['media_datetime'], ['asc']);
            dataSort.forEach(d => {
              if (d.media_path !== '') m.push(`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${d.media_path}`);
            });

            this.setState({
              isLoading: false,
              images: m,
              width,
            });
          })
          .catch(error => this.setState({ error, isLoading: false }));
      }
    } else {
      // for radar history
      fetch(`${process.env.MIX_API_URL}analyst/radar_history_img?date=${startDateRadar}&end_date=${endDate}&radar_type=${imageType}&interval=15`)
        .then(response => {
          if (response.ok) {
            this.setState({ isLoading: true });
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          const m = [];
          const dataSort = orderBy(result.data, ['datetime'], ['asc']);
          const maxEndDate = moment(dataSort[dataSort.length - 1]?.datetime);
          const past24Hours = moment(maxEndDate).subtract(24, 'hours');
          const filteredData = dataSort.filter(d => {
            return moment(d.datetime).isSameOrAfter(past24Hours) && moment(d.datetime).isSameOrBefore(maxEndDate);
          });
          filteredData.forEach(d => {
            d.data.forEach(row => {
              if (row.media_path !== null) m.push(`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`);
            });
          });
          this.setState({
            isLoading: false,
            images: m,
            width,
          });
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }
  }

  render() {
    const {
      isLoading,
      error,
      images,
      width,
    } = this.state;

    return (
      <>
        { error && (<Grid className="text-center">{error.message}</Grid>) }

        { isLoading === true && (
        <Grid className="text-center">
          <FaSpinner size={35} />
        </Grid>
        ) }

        { width && (
          <AnimationImage
            images={images}
            imagesWidth={width}
          />
        ) }
      </>
    );
  }
}

ImageAnimation.propTypes = {
  mediaTypeId: PropTypes.number.isRequired,
  agencyId: PropTypes.number.isRequired,
  imageType: PropTypes.string.isRequired,
};

export default ImageAnimation;
