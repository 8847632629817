/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import moment from 'moment';

// style, icons
import {
  FaAngleRight, FaAngleLeft,
} from 'react-icons/fa';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

// ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Slider from 'react-slick';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

// icon
import {
  HiiTemperatureIcon,
  HiiTempHighIcon,
  HiiTempLowIcon,
  HiiRainHighIcon,
  HiiRainLowIcon,
  HiiHumidityIcon,
  HiiWindIcon,
  HiiAirPressureIcon,
} from '../../../utils/Icon';

import '../../../../../public/css/slick.css';
import { styles } from '../../../styles/Style';

import weatherContext from '../../../contexts/weatherContext';

// import WeatherMapCanvas from './WeatherMapCanvas';
// import WeatherMapChart from './WeatherMapChart';
import TemperatureLegend from './TemperatureLegend';

const WeatherMapCanvas = React.lazy(() => import('./WeatherMapCanvas'));

// translate
import tJson from '../../../utils/TJson';

function NextArrow(props) {
  const { onClick } = props; // className
  return (
    <FaAngleRight
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#555',
        top: 170,
        right: 7,
        fontSize: '1.5em',
        '&:hover': {
          backgroundColor: '#404095 !important',
          color: '#fff !important',
        },
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { onClick } = props; // className,
  return (
    <FaAngleLeft
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#555',
        top: 170,
        right: 27,
        fontSize: '1.5em',
        zIndex: 1,
        '&:hover': {
          backgroundColor: '#404095',
          color: '#fff',
        },
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

class WeatherBox extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      // map
      map: null,
      zoom: 5.65,
      location: [12.9, 101.6],
      markers: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
    };

    this.handleSetMap = this.handleSetMap.bind(this);
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = '67%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '67%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  renderTemperature = (dataTemperature, settings, classes, theme) => {
    const currentMonth = moment().format('M');
    let temp;
    let tambon;
    let amphoe;
    let province;
    const { t } = this.props;
    return (
      <Slider {...settings}>
        {dataTemperature.map((row, i) => {
          if (currentMonth >= 11 || currentMonth <= 2) {
            temp = row.min.temperature;
            tambon = (row.min.geocode.tumbon_name.th !== undefined) ? ` ${tJson(row.min.geocode.tumbon_name, 'ต.')}` : '';
            amphoe = (row.min.geocode.amphoe_name.th !== undefined) ? ` ${tJson(row.min.geocode.amphoe_name, 'อ.')} ` : '';
            province = (row.min.geocode.province_name.th !== undefined) ? ` ${tJson(row.min.geocode.province_name, 'จ.')}` : '';
          } else {
            temp = row.max.temperature;
            tambon = (row.max.geocode.tumbon_name.th !== undefined) ? ` ${tJson(row.max.geocode.tumbon_name, 'ต.')}` : '';
            amphoe = (row.max.geocode.amphoe_name.th !== undefined) ? ` ${tJson(row.max.geocode.amphoe_name, 'อ.')} ` : '';
            province = (row.max.geocode.province_name.th !== undefined) ? ` ${tJson(row.max.geocode.province_name, 'จ.')}` : '';
          }

          return (
            <div key={row.max.station.id}>
              <Box py={2} display="block" alignItems="center" justifyContent="center" width="100%" height={200} className={classes.box}>
                <Box mr={1} align="right" style={{ color: '#aaa' }}>
                  {i + 1}
                  {' '}
                  / 10
                </Box>
                <Box>
                  <Box align="center">
                    <Typography variant="h6">
                      {t('อุณหภูมิ')}
                    </Typography>
                  </Box>
                  <Box display="flex" py={1}>
                    <Box width="40%" align="center" pt={1}>
                      <HiiTemperatureIcon color="secondary" style={{ fontSize: '60px' }} />
                    </Box>
                    <Box width="60%" align="left" alignItems="center" justifyContent="center">
                      <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
                        {`${temp.toFixed(0)}`}
                      </Typography>
                      <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>&#8451;</span>
                    </Box>
                  </Box>
                  <Box p={1} align="center" height={35} style={{ lineHeight: '15px' }}>
                    <Typography variant="caption" style={{ lineHeight: '15px' }}>
                      {tambon}
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: '15px' }}>
                      {amphoe}
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: '15px' }}>
                      {province}
                    </Typography>
                  </Box>
                  <Box p={1} align="center">
                    <Typography className={classes.dateTime}>
                      {`${t('เวลา')}: ${moment(row.temperature_datetime).format('HH:mm')} ${t('น.')}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </div>
          );
        })}
      </Slider>
    );
  };

  renderWind = (dataWind, settings, classes, theme) => {
    const { t } = this.props;
    return (
      <Slider {...settings}>
        {dataWind.map((row, i) => (
          <div key={row.station.id}>
            <Box py={2} display="block" alignItems="center" justifyContent="center" width="100%" height={200} className={classes.box}>
              <Box mr={1} align="right" style={{ color: '#aaa' }}>
                {i + 1}
                {' '}
                / 10
              </Box>
              <Box>
                <Box align="center">
                  <Typography variant="h6">
                    {t('ความเร็วลม')}
                  </Typography>
                </Box>
                <Box display="flex" py={1}>
                  <Box width="40%" align="center" pt={1}>
                    <HiiWindIcon color="secondary" style={{ fontSize: '60px' }} />
                  </Box>
                  <Box width="60%" align="left" alignItems="center" justifyContent="center">
                    <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
                      {typeof row.wind_speed !== 'undefined' && (
                        `${row.wind_speed.toFixed(1)}`
                      )}
                    </Typography>
                    <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>km/hr</span>
                  </Box>
                </Box>
                <Box p={1} align="center" height={35}>
                  <Typography variant="caption" style={{ lineHeight: '15px' }}>
                    {(row.geocode.tumbon_name.th !== undefined) ? ` ${tJson(row.geocode.tumbon_name, 'ต.')}` : ''}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: '15px' }}>
                    {(row.geocode.amphoe_name.th !== undefined) ? ` ${tJson(row.geocode.amphoe_name, 'อ.')} ` : ''}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: '15px' }}>
                    {(row.geocode.province_name.th !== undefined) ? ` ${tJson(row.geocode.province_name, 'จ.')}` : ''}
                  </Typography>
                </Box>
                <Box p={1} align="center">
                  <Typography className={classes.dateTime}>
                    {`${t('เวลา')}: ${moment(row.wind_datetime).format('HH:mm')} ${t('น.')}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
        ))}
      </Slider>
    );
  };

  renderPressure = (dataPressure, settings, classes, theme) => {
    const { t } = this.props;
    return (
      <Slider {...settings}>
        {dataPressure.map((row, i) => (
          <div key={row.station.id}>
            <Box py={2} display="block" alignItems="center" justifyContent="center" width="100%" height={200} className={classes.box}>
              <Box mr={1} align="right" style={{ color: '#aaa' }}>
                {i + 1}
                {' '}
                / 10
              </Box>
              <Box>
                <Box align="center">
                  <Typography variant="h6">
                    {t('ความกดอากาศ')}
                  </Typography>
                </Box>
                <Box display="flex" py={1}>
                  <Box width="30%" align="center" pt={1}>
                    <HiiAirPressureIcon color="secondary" style={{ fontSize: '60px' }} />
                  </Box>
                  <Box width="70%" align="left" alignItems="center" justifyContent="center">
                    <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
                      {typeof row.pressure !== 'undefined' && (
                        `${row.pressure.toFixed(1)}`
                      )}
                    </Typography>
                    <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>hPa</span>
                  </Box>
                </Box>
                <Box p={1} align="center" height={35} style={{ lineHeight: '15px' }}>
                  <Typography variant="caption" style={{ lineHeight: '15px' }}>
                    {(row.geocode.tumbon_name.th !== undefined) ? ` ${tJson(row.geocode.tumbon_name, 'ต.')}` : ''}
                    {(row.geocode.amphoe_name.th !== undefined) ? ` ${tJson(row.geocode.amphoe_name, 'อ.')} ` : ''}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: '15px' }}>
                    {(row.geocode.province_name.th !== undefined) ? ` ${tJson(row.geocode.province_name, 'จ.')}` : ''}
                  </Typography>
                </Box>
                <Box p={1} align="center">
                  <Typography className={classes.dateTime}>
                    {`${t('เวลา')}: ${moment(row.pressure_datetime).format('HH:mm')} ${t('น.')}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
        ))}
      </Slider>
    );
  }

  renderTemperatureMax = (dataTemperature, classes, theme) => (
    <>
      <Box display="flex" py={1}>
        <Box width="40%" align="center" pt={1}>
          <HiiTempHighIcon color="secondary" style={{ fontSize: '60px' }} />
        </Box>
        <Box width="60%" align="left" alignItems="center" justifyContent="center">
          <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
            {typeof dataTemperature[0].max.temperature !== 'undefined' && (
              `${dataTemperature[0].max.temperature.toFixed(0)}`
            )}
          </Typography>
          <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>&#8451;</span>
        </Box>
      </Box>
      <Box p={1} align="center">
        <Typography variant="caption">
          {(dataTemperature[0].max.geocode.tumbon_name.th !== undefined) ? ` ${tJson(dataTemperature[0].max.geocode.tumbon_name, 'ต.')}` : ''}
          {(dataTemperature[0].max.geocode.amphoe_name.th !== undefined) ? ` ${tJson(dataTemperature[0].max.geocode.amphoe_name, 'อ.')} ` : ''}
        </Typography>
        <Typography variant="caption">
          {(dataTemperature[0].max.geocode.province_name.th !== undefined) ? ` ${tJson(dataTemperature[0].max.geocode.province_name, 'จ.')}` : ''}
        </Typography>
      </Box>
      {/* <Box p={1} align="center">
          <Typography className={classes.dateTime}>
            {`เวลา: ${moment(dataTemperature[0].max.temperature_datetime).format('HH:mm')} น.`}
          </Typography>
        </Box> */}
    </>
  )

  renderTemperatureMin = (dataTemperature, classes, theme) => {
    const { t } = this.props;
    return (
      <>
        <Box display="flex" py={1}>
          <Box width="40%" align="center" pt={1}>
            <HiiTempLowIcon color="secondary" style={{ fontSize: '60px' }} />
          </Box>
          <Box width="60%" align="left" alignItems="center" justifyContent="center">
            <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
              {typeof dataTemperature[0].min.temperature !== 'undefined' && (
                `${dataTemperature[0].min.temperature.toFixed(0)}`
              )}
            </Typography>
            <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>&#8451;</span>
          </Box>
        </Box>
        <Box p={1} align="center" height={30}>
          <Typography variant="caption">
            {(dataTemperature[0].min.geocode.tumbon_name.th !== undefined) ? ` ${tJson(dataTemperature[0].min.geocode.tumbon_name, 'ต.')}` : ''}
            {(dataTemperature[0].min.geocode.amphoe_name.th !== undefined) ? ` ${tJson(dataTemperature[0].min.geocode.amphoe_name, 'อ.')} ` : ''}
          </Typography>
          <Typography variant="caption">
            {(dataTemperature[0].min.geocode.province_name.th !== undefined) ? ` ${tJson(dataTemperature[0].min.geocode.province_name, 'จ.')}` : ''}
          </Typography>
        </Box>
        <Box pt={3} align="center">
          <Typography className={classes.dateTime}>
            {`${t('เวลา')}: ${moment(dataTemperature[0].min.temperature_datetime).format('HH:mm')} ${t('น.')}`}
          </Typography>
        </Box>
      </>
    );
  }

  renderRainMax = (dataRain, classes, theme) => (
    <>
      <Box display="flex" py={1}>
        <Box width="40%" align="center" pt={1}>
          <HiiRainHighIcon color="secondary" style={{ fontSize: '60px' }} />
        </Box>
        <Box width="60%" align="left" alignItems="center" justifyContent="center">
          <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
            {typeof dataRain.max.rainfall !== 'undefined' && (
              `${dataRain.max.rainfall.toFixed(0)}`
            )}
          </Typography>
          <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>mm</span>
        </Box>
      </Box>
      <Box p={1} align="center" height={30}>
        <Typography variant="caption">
          {(dataRain.max.geocode.tumbon_name.th !== undefined) ? `${tJson(dataRain.max.geocode.tumbon_name, 'ต.')}` : ''}
          {(dataRain.max.geocode.amphoe_name.th !== undefined) ? ` ${tJson(dataRain.max.geocode.amphoe_name, 'อ.')} ` : ''}
        </Typography>
        <Typography variant="caption">
          {(dataRain.max.geocode.province_name.th !== undefined) ? ` ${tJson(dataRain.max.geocode.province_name, 'จ.')}` : ''}
        </Typography>
      </Box>
    </>
  )

  renderRainMin = (dataRain, classes, theme) => {
    const { t } = this.props;
    return (
      <>
        <Box display="flex" py={1}>
          <Box width="40%" align="center" pt={1}>
            <HiiRainLowIcon color="secondary" style={{ fontSize: '60px' }} />
          </Box>
          <Box width="60%" align="left" alignItems="center" justifyContent="center">
            <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
              {typeof dataRain.min.rainfall !== 'undefined' && (
                `${dataRain.min.rainfall.toFixed(0)}`
              )}
            </Typography>
            <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>mm</span>
          </Box>
        </Box>
        <Box p={1} align="center" height={30}>
          <Typography variant="caption">
            {(dataRain.min.geocode.tumbon_name.th !== undefined) ? ` ${tJson(dataRain.min.geocode.tumbon_name, 'ต.')}` : ''}
            {(dataRain.min.geocode.amphoe_name.th !== undefined) ? ` ${tJson(dataRain.min.geocode.amphoe_name, 'อ.')} ` : ''}
          </Typography>
          <Typography variant="caption">
            {(dataRain.min.geocode.province_name.th !== undefined) ? ` ${tJson(dataRain.min.geocode.province_name, 'จ.')}` : ''}
          </Typography>
        </Box>
        <Box pt={3} align="center">
          <Typography className={classes.dateTime}>
            {`${t('เวลา')}: ${moment(dataRain.min.rainfall_datetime).format('HH:mm')} ${t('น.')}`}
          </Typography>
        </Box>
      </>
    );
  }

  renderHumid = (dataHumid, settings, classes, theme) => {
    const { t } = this.props;
    return (
      <Slider {...settings}>
        {dataHumid.map((row, i) => (
          <div key={row.station.id}>
            <Box py={2} display="block" alignItems="center" justifyContent="center" width="100%" height={200} className={classes.box}>
              <Box mr={1} align="right" style={{ color: '#aaa' }}>
                {i + 1}
                {' '}
                / 10
              </Box>
              <Box>
                <Box align="center">
                  <Typography variant="h6">
                    {t('ความชื้นสัมพัทธ์')}
                  </Typography>
                </Box>
                <Box display="flex" py={1}>
                  <Box width="40%" align="center" pt={1}>
                    <HiiHumidityIcon color="secondary" style={{ fontSize: '60px' }} />
                  </Box>
                  <Box width="60%" align="left" alignItems="center" justifyContent="center">
                    <Typography variant="h2" style={{ color: theme.palette.primary.main }} fontWeight="fontWeightMedium" display="inline">
                      {typeof row.humid !== 'undefined' && (
                        `${row.humid.toFixed(0)}`
                      )}
                    </Typography>
                    <span style={{ color: theme.palette.primary.main, fontFamily: 'Kanit', paddingLeft: 5 }}>%</span>
                  </Box>
                </Box>
                <Box p={1} align="center" height={35} style={{ lineHeight: '15px' }}>
                  <Typography variant="caption" style={{ lineHeight: '15px' }}>
                    {(row.geocode.tumbon_name.th !== undefined) ? ` ${tJson(row.geocode.tumbon_name, 'ต.')}` : ''}
                    {(row.geocode.amphoe_name.th !== undefined) ? ` ${tJson(row.geocode.amphoe_name, 'อ.')} ` : ''}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: '15px' }}>
                    {(row.geocode.province_name.th !== undefined) ? `${tJson(row.geocode.province_name, 'จ.')}` : ''}
                  </Typography>
                </Box>
                <Box p={1} align="center">
                  <Typography className={classes.dateTime}>
                    {`${t('เวลา')}: ${moment(row.humid_datetime).format('HH:mm')} ${t('น.')}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
        ))}
      </Slider>
    );
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  render() {
    const {
      markers, location, zoom, modalHeader, modalSubHeader, modalContent, open,
    } = this.state;
    const {
      classes,
      theme,
      dataHumid,
      dataPressure,
      dataRain,
      dataTemperature,
      dataWind,
      regionId,
      t,
      i18n,
    } = this.props;

    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      fade: true,
      autoplay: true,
      speed: 350,
      pauseOnFocus: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const settingsNotAuto = {
      dots: false,
      // fade: true,
      infinite: true,
      arrows: true,
      autoplay: false,
      speed: 2200,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
      <>
        <Grid container justifyContent="space-between" spacing={4}>
          <weatherContext.Provider
            value={{
              handleModalOpen: this.handleModalOpen,
            }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Box width="100%">
                {/* <WeatherMapCanvas
                  markers={markers}
                  location={location}
                  zoom={zoom}
                  classes={classes}
                  handleSetMap={this.handleSetMap}
                  regionId={regionId}
                  language={i18n.language}
                /> */}
                <React.Suspense fallback={<div>Loading...</div>}>
                  <WeatherMapCanvas
                    markers={markers}
                    location={location}
                    zoom={zoom}
                    classes={classes}
                    handleSetMap={this.handleSetMap}
                    regionId={regionId}
                    language={i18n.language}
                  />
                </React.Suspense>
                {/* <WeatherMapChart regionId={regionId} /> */}
                <TemperatureLegend />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {(!isEmpty(dataTemperature))
                    && this.renderTemperature(dataTemperature, settings, classes, theme)}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {(!isEmpty(dataPressure))
                    && this.renderPressure(dataPressure, settings, classes, theme)}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box
                    p={{
                      xs: 0, sm: 1, md: 1, lg: 2,
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height={350}
                    className={classes.box}
                  >
                    <Box>
                      <Box align="center">
                        <Typography variant="h6">
                          {t('อุณหภูมิสูงสุด')}
                        </Typography>
                      </Box>
                      {(!isEmpty(dataTemperature))
                        && this.renderTemperatureMax(dataTemperature, classes, theme)}
                      <Box p={1} pt={3}>
                        <Divider />
                      </Box>
                      <Box align="center">
                        <Typography variant="h6">
                          {t('อุณหภูมิต่ำสุด')}
                        </Typography>
                      </Box>
                      {(!isEmpty(dataTemperature))
                        && this.renderTemperatureMin(dataTemperature, classes, theme)}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box
                    p={{
                      xs: 0, sm: 1, md: 1, lg: 2,
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height={350}
                    className={classes.box}
                  >
                    <Box>
                      <Box align="center">
                        <Typography variant="h6">
                          {t('ฝนสูงสุด')}
                        </Typography>
                      </Box>
                      {(!isEmpty(dataRain)) && this.renderRainMax(dataRain, classes, theme)}
                      <Box p={1} pt={3}>
                        <Divider />
                      </Box>
                      <Box align="center">
                        <Typography variant="h6">
                          {t('ฝนต่ำสุด')}
                        </Typography>
                      </Box>
                      {(!isEmpty(dataRain)) && this.renderRainMin(dataRain, classes, theme)}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {(!isEmpty(dataHumid))
                    && this.renderHumid(dataHumid, settings, classes, theme)}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {(!isEmpty(dataWind))
                    && this.renderWind(dataWind, settings, classes, theme)}
                </Grid>
              </Grid>
            </Grid>
            <Modal
              id="modal-chart"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={this.handleModalClose}
              style={{ align: 'center', justifyContent: 'center' }}
            >
              <div style={this.getModalStyle()} className={classes.paperModal}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={0}
                >
                  <Grid item>
                    <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography variant="h5" id="modal-title">
                  {modalHeader}
                </Typography>
                <Typography variant="subtitle1" id="simple-modal-description">
                  {modalSubHeader}
                </Typography>
                <Box mt={3}>
                  {modalContent}
                </Box>
              </div>
            </Modal>
          </weatherContext.Provider>
        </Grid>
      </>
    );
  }
}

NextArrow.defaultProps = {
  // className: '',
  onClick: () => { },
};

PrevArrow.defaultProps = {
  // className: '',
  onClick: () => { },
};

NextArrow.propTypes = {
  // className: PropTypes.string,
  onClick: PropTypes.func,
};

PrevArrow.propTypes = {
  // className: PropTypes.string,
  onClick: PropTypes.func,
};

WeatherBox.propTypes = {
  classes: PropTypes.object.isRequired,
  dataHumid: PropTypes.any.isRequired,
  dataPressure: PropTypes.any.isRequired,
  dataRain: PropTypes.any.isRequired,
  dataTemperature: PropTypes.any.isRequired,
  dataWind: PropTypes.any.isRequired,
  theme: PropTypes.object.isRequired,
  regionId: PropTypes.string.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(WeatherBox);
