import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import { findIndex } from 'lodash';

class GoogleAnalytics extends Component {
  componentDidMount() {
    const { location } = this.props;
    this.logPageChange(
      location.pathname,
      location.search,
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      // eslint-disable-next-line react/destructuring-assignment
      ...this.props.options,
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.object,
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const provinceArray = [
  {
    subDomain: 'web-v4',
    googleID: 'UA-83737647-7',
  },
  {
    subDomain: 'www',
    googleID: 'UA-83737647-2',
  },
];

const init = (subDomain = {}) => {
  const isGAEnabled = true;
  const findGA = findIndex(provinceArray, ['subDomain', subDomain]);
  const gaID = provinceArray[findGA];

  if (isGAEnabled && gaID) {
    // eslint-disable-next-line no-console
    ReactGA.initialize(gaID.googleID);
  }

  return isGAEnabled;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
