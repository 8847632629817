import React, { useState, useEffect } from 'react';
import { uniqueId, isEmpty } from 'lodash';
import Img from 'react-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Tooltip as TooltipMui } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Divider from '@material-ui/core/Divider';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
  FaSpinner,
} from '../../utils/Fontawesome';

import ExtremeIndexDetail from './ExtremeIndexDetail';

const useStyles = makeStyles({
  boxImg: {
    border: '1px solid #ccc',
    marginBottom: 10,
    // minWidth: 300,
    // display: 'flex',
    width: '100% !important',
    // height: '40%',
  },
  responsive: {
    width: '100%',
    height: '100%',
  },
  button: {
    backgroundColor: '#0064AF',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0064AF',
      color: 'white',
    },
  },

  buttonDownload: {
    borderRadius: 20,
    padding: '2px 10px',
    fontSize: '0.8em',
    '&:hover': {
      backgroundColor: '#0064AF',
      color: 'white',
    },
  },
  dividerStyle: {
    borderBottom: '2px solid #006FB6',
  },
});

export default function CmImage() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    time: false,
    index: false,
  });
  const [index, setIndex] = useState([]);
  const [indexList, setIndexList] = useState([]);
  const [images, setImages] = useState([]);
  const [datas, setDatas] = useState([]);
  const [parameter, setParameter] = useState('Rainfall');
  const [model, setModel] = useState('01ACCESS-ESM1-5');
  const [dc, setDc] = useState('Statistics');
  const [dcList, setDcList] = useState(['Extreme', 'Statistics']);
  const [time, setTime] = useState('20150101-20441231');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const modelList = ['01ACCESS-ESM1-5', '02BCC-CSM2-MR', '03CanESM5', '04CMCC-ESM2', '05FGOALS-g3_MIP', '06IPSL-CM6A-LR', '07MIROC6', '08MPI-ESM1-2-LR'];
  const timeList = ['20150101-20441231', '20450101-20721231', '20730101-21001231'];

  useEffect(() => {
    if (dc === 'Statistics') {
      setIndex([]);
    }
  }, [dc]);

  useEffect(() => {
    const list = (parameter === 'Temperature') ? ['Statistics'] : ['Extreme', 'Statistics'];
    setDcList(list);
    if (parameter === 'Temperature') {
      setDc('Statistics');
    }
  }, [parameter]);

  function getImage() {
    let formErrors = {};
    setImages([]);
    setDatas([]);

    if (time.trim() === '') {
      formErrors = { ...formErrors, time: true };
    } else {
      setErrors({ time: false });
    }
    if (parameter.trim() === '') {
      formErrors = { ...formErrors, parameter: true };
    } else {
      setErrors({ parameter: false });
    }
    if (model.trim() === '') {
      formErrors = { ...formErrors, model: true };
    } else {
      setErrors({ model: false });
    }
    if (dc.trim() === '') {
      formErrors = { ...formErrors, dc: true };
    } else if (dc.trim() === 'Extreme') {
      if (isEmpty(index)) {
        formErrors = { ...formErrors, index: true };
      } else {
        setErrors({ index: false });
      }
    }
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      const url = `${process.env.MIX_APP_URL}proxy/json.php?file=${process.env.MIX_API_HII_URL}/v2/${process.env.MIX_API_HII_TOKEN}/climate_change_img/param=model=${model}%26dc=${dc}%26time=${time}%26index=${index}%26parameter=${parameter}`;
      fetch(url)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          if (result.status === 'ok') {
            setDatas(result.data);
          } else {
            setDatas([]);
          }
          setLoading(false);
        })
        .catch(error => error);
    }
  }

  useEffect(() => {
    getImage();
    setLoading(false);
  }, []);

  useEffect(() => {
    const iList = (dc === 'Extreme') ? ['CDD', 'CWD', 'PRCPTOT', 'R10mm', 'R20mm', 'R30mm', 'R35mm', 'R90mm', 'R95PT', 'Rx1D', 'Rx2D', 'Rx3D', 'Rx4D', 'Rx5D', 'SDII'] : [];
    setIndexList(iList);
  }, [dc]);

  const handleInputChange = (event, setter) => {
    setter(event.target.value);
  };

  const onErrorImg = e => {
    e.target.src = 'https://via.placeholder.com/167x146?text=No%20Image';
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openLightbox = varCount => {
    console.log('openLightbox', varCount);
    setPhotoIndex(varCount);
    setIsOpen(true);
  };

  const renderImage = data => data.map((items, dataIndex) => (
    <>
      <Box>
        <Box my={{ xs: 2, md: 5 }}>
          <Box><Typography variant="h6">{items.title}</Typography></Box>
          <Divider className={classes.dividerStyle} />
        </Box>
        <Grid container spacing={2} key={uniqueId()}>
          {items.main.map((item, i) => {
            images.push({
              url: `${process.env.MIX_APP_URL}proxy/png.php?file=${item.url}`,
              title: `${items.title} ${item.title}`,
            });
            return (
              <Grid item xs={12} sm={6} md={3} key={uniqueId()}>
                <Box sx={{ display: 'flex', justifyContent: 'between' }} width="100%">
                  <Box width="100%">
                    <Box className={classes.boxImg} width="100%">
                      <Button
                        onClick={() => openLightbox(dataIndex * 9 + i)}
                      >
                        <Img
                          src={`${process.env.MIX_APP_URL}proxy/png.php?file=${item.url}`}
                          className={classes.responsive}
                          unloader={(
                            <Img
                              src="https://via.placeholder.com/167x146?text=No%20Image"
                              alt="No Data"
                              className={classes.responsive}
                            />
                            )}
                          loader={(
                            <div className="text-center">
                              <FaSpinner size={10} />
                            </div>
                            )}
                        />
                      </Button>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography>
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={2} key={uniqueId()}>
          {items.compare.map((item, i) => {
            images.push({
              url: `${process.env.MIX_APP_URL}proxy/png.php?file=${item.url}`,
              title: `${items.title} ${item.title}`,
            });
            return (
              <Grid item xs={12} sm={6} md={3} key={uniqueId()}>
                <Box sx={{ display: 'flex', justifyContent: 'between' }} width="100%">
                  <Box width="100%">
                    <Box className={classes.boxImg} width="100%">
                      <Button
                        onClick={() => openLightbox(dataIndex * 9 + items.main.length + i)}
                      >
                        <img
                          src={`${process.env.MIX_APP_URL}proxy/png.php?file=${item.url}`}
                          className={classes.responsive}
                          onError={onErrorImg}
                          onLoad={handleImageLoad}
                          alt={item.title}
                        />
                      </Button>
                      <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
                        <Typography>
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  ));

  const handleIndexChange = event => {
    const {
      target: { value },
    } = event;
    setIndex(typeof value === 'string' ? value.split(',') : value);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box my={3} sx={{ width: { xs: '100%', md: '70%' } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.formControl} error={errors.parameter}>
              <InputLabel htmlFor="parameter">Parameter *</InputLabel>
              <Select
                aria-label="parameter"
                name="parameter"
                value={parameter}
                onChange={e => handleInputChange(e, setParameter)}
                inputProps={{
                  name: 'parameter',
                  id: 'parameter',
                }}
              >
                <MenuItem value="Rainfall">Rainfall</MenuItem>
                <MenuItem value="Temperature">Temperature</MenuItem>
              </Select>
              {errors.parameter && <FormHelperText> Please select Parameter</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.formControl} error={errors.dc}>
              <InputLabel htmlFor="dc">Dataset Category *</InputLabel>
              <Select
                aria-label="dc"
                name="dc"
                value={dc}
                onChange={e => handleInputChange(e, setDc)}
                inputProps={{
                  name: 'dc',
                  id: 'dc',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="">Select</MenuItem>
                {dcList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.dc && <FormHelperText> Please select Dataset Category</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box display="flex" alignItems="flex-end">
              <FormControl className={classes.formControl} error={errors.index}>
                <InputLabel htmlFor="index">Extreme Index</InputLabel>
                <Select
                  aria-label="index"
                  name="index"
                  multiple
                  value={index}
                  onChange={handleIndexChange}
                  renderValue={selected => selected.join(', ')}
                  inputProps={{
                    name: 'index',
                    id: 'index',
                  }}
                  style={{ minWidth: 140 }}
                >
                  {indexList.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={index.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TooltipMui title="Definition of Extreme Rainfall Indices" placement="left-start" style={{ zIndex: 1 }}>
                <Button onClick={handleOpenDialog}>
                  <InfoIcon size="small" />
                </Button>
              </TooltipMui>
            </Box>
            <Modal
              id="modal"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ align: 'center', justifyContent: 'center' }}
              open={open}
              onClose={handleClose}
            >
              <div className={classes.paper}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={0}
                >
                  <Grid item>
                    <IconButton style={{ padding: 0 }}>
                      <CloseIcon fontSize="small" style={{ color: 'white' }} onClick={handleClose} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography variant="h5" id="modal-title">
                  Definition of Extreme Rainfall Indices
                </Typography>
                <Box mt={{ xs: 0, sm: 3 }}>
                  <ExtremeIndexDetail />
                </Box>
              </div>
            </Modal>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.formControl} error={errors.model}>
              <InputLabel htmlFor="Model">Model *</InputLabel>
              <Select
                aria-label="model"
                name="model"
                value={model}
                onChange={e => handleInputChange(e, setModel)}
                inputProps={{
                  name: 'model',
                  id: 'model',
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {modelList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.model && <FormHelperText> Please select Model</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.formControl} error={errors.time}>
              <InputLabel htmlFor="time">Time Period *</InputLabel>
              <Select
                aria-label="time"
                name="time"
                value={time}
                onChange={e => handleInputChange(e, setTime)}
                inputProps={{
                  name: 'time',
                  id: 'time',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="">Select</MenuItem>
                {timeList.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.time && <FormHelperText> Please select Time Period</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.formControl}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                style={{ fontFamily: 'Kanit !important' }}
                title="แสดงผล"
                onClick={() => getImage()}
              >
                Submit
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {isLoading === true && (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      )}
      <Grid container justifyContent="flex-start" spacing={3} key={uniqueId()}>
        {(isEmpty(datas))
          ? (<div className="text-center">NO DATA according to the conditions you selected.</div>)
          : renderImage(datas)}
      </Grid>
      {
        isEmpty(images[photoIndex]) === false && isOpen && (
          <Lightbox
            mainSrc={`${process.env.MIX_APP_URL}proxy/png.php?file=${images[photoIndex].url}`}
            nextSrc={`${process.env.MIX_APP_URL}proxy/png.php?file=${images[(photoIndex + 1) % images.length].url}`}
            prevSrc={`${process.env.MIX_APP_URL}proxy/png.php?file=${images[(photoIndex + images.length - 1) % images.length].url}`}
            imageTitle={images[photoIndex].title}
            imageCaption={images[photoIndex].caption}
            onCloseRequest={() => { setIsOpen(false); setPhotoIndex(1); }}
            onMovePrevRequest={() => {
              setPhotoIndex((photoIndex + images.length - 1) % images.length);
            }}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            reactModalStyle={{
              overlay: { zIndex: '10000000' },
            }}
          />
        )
      }

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        maxWidth="md"
      >
        <DialogTitle id="modal-title">Definition of Extreme Rainfall Indices</DialogTitle>
        <DialogContent>
          <ExtremeIndexDetail />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
