/* eslint-disable arrow-body-style */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy, uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';
// import FormControl from '@material-ui/core/FormControl';
// import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Paper from '@material-ui/core/Paper';
// import InputBase from '@material-ui/core/InputBase';
// import IconButton from '@material-ui/core/IconButton';
// import SearchIcon from '@material-ui/icons/Search';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import { FaSpinner } from '../../utils/Fontawesome';

import {
  styles, StyledTableRow,
} from '../../styles/Style';

// table Header
const columnData = [
  {
    id: 'province', disablePadding: false, label: 'จังหวัด', sortable: true, align: 'left',
  },
  {
    id: 'district', disablePadding: false, label: 'อำเภอ', unit: '', sortable: true, align: 'right',
  },
  {
    id: 'tambon', disablePadding: false, label: 'ตำบล', sortable: true, align: 'right',
  },
  {
    id: 'd_score', disablePadding: false, label: 'คะแนนความเสี่ยงภัยแล้ง', sortable: true, align: 'right',
  },
  {
    id: 'd_meaning', disablePadding: false, label: 'ระดับความเสี่ยงภัยแล้ง', sortable: true, align: 'right',
  },
];

class DroughtList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      order: 'desc',
      orderByState: 'drought_report',
      page: 0,
      rowsPerPage: 10,
      search: '',
    };
  }

  componentDidMount() {
    this._mounted = true;
    const { datas } = this.props;
    this.setState({
      lastSearchDatas: datas,
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  // for sort table
  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';
    if (orderByState === property && order === 'desc') {
      orderLocal = 'asc';
    }
    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  // ไม่ได้ใช้เพราะข้อมูลที่ส่งมาเป็นชื่อจังหวัดอยู่แล้ว
  // getProvinceName = id => {
  //   const { geojsonThailand } = this.props;
  //   let provinceName = '';
  //   geojsonThailand.features.map(dd => {
  //     if (dd.properties.prov_code === id) {
  //       provinceName = dd.properties.title;
  //     }
  //     // eslint-disable-next-line no-useless-return
  //     return;
  //   });
  //   return provinceName;
  // }

  handleChangeSearch = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      this.filterDatas();
    });
  };

  filterDatas() {
    const {
      search,
    } = this.state;
    const { datas } = this.props;

    const newDatas = [];
    datas.map(a => {
      return newDatas.push({
        // eslint-disable-next-line max-len
        tambon_id: a[0], province: a[1], district: a[2], tambon: a[3], d_score: a[4], d_meaning: a[5],
      });
    });

    // console.log(newDatas);
    // console.log(datas);

    let searchFilterDatas = [];

    searchFilterDatas = newDatas.filter(d => {
      if (search !== '' && typeof d.province !== 'undefined' && d.province !== '') {
        return d.province.indexOf(search) > -1
          || d.district.indexOf(search) > -1
          || d.tambon.indexOf(search) > -1
          || d.d_score.indexOf(search) > -1
          || d.d_meaning.indexOf(search) > -1;
      }
      return d;
    });

    this.setState({
      lastSearchDatas: searchFilterDatas,
    });
  }

  render() {
    const {
      isLoading,
      error,
      order,
      orderByState,
      rowsPerPage,
      page,
      // search,
      lastSearchDatas,
    } = this.state;
    const { classes, datas, t } = this.props;

    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }
    return (
      <>
        {/* <Grid container spacing={4} pb={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
              <Paper className={classes.rootSearch} elevation={0}>
                <InputBase
                  display="flex"
                  className={classes.inputSearch}
                  id="search"
                  label={t('ค้นหา')}
                  type="search"
                  margin="none"
                  value={search}
                  onChange={this.handleChangeSearch('search')}
                  placeholder={t('ค้นหา')}
                />
                // eslint-disable-next-line max-len
                <IconButton className={classes.iconButton} aria-label="Search"
                  onClick={this.handleSearchClick}>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
          </Grid>
        </Grid> */}
        <TableContainer>
          <Table className={classes.table}>
            <TableHeaderEnhance
              columnData={columnData}
              order={order}
              orderBy={orderByState}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {orderBy(lastSearchDatas, orderByState, order)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(data => {
                  return (
                    <StyledTableRow key={uniqueId()}>
                      <TableCell align="left">{data[1].replace('จ.', '')}</TableCell>
                      <TableCell align="left">{data[2].replace('อ.', '')}</TableCell>
                      <TableCell align="left">{data[3].replace('ต.', '')}</TableCell>
                      <TableCell align="right">{data[4]}</TableCell>
                      <TableCell align="right">{data[5]}</TableCell>
                    </StyledTableRow>
                  );
                })}
              {datas.length === 0 && (
                <StyledTableRow>
                  <TableCell colSpan={7} align="center">{t('ไม่มีข้อมูล')}</TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          className={classes.hiddenLine}
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActionsWrapped}
          labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
          labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to} ${t('จาก')} ${count} ${t('รายการ')}`}
        />

        <Box color="red" pt={1}>
          {t('หมายเหตุ')}
          {' : '}
          {t('พื้นที่เสี่ยงภัยแล้งจากดัชนีชี้วัดความเสี่ยงต่ำกว่าเกณฑ์ที่กำหนด')}
        </Box>
      </>
    );
  }
}

DroughtList.propTypes = {
  classes: PropTypes.object.isRequired,
  // geojsonThailand: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(DroughtList));
