import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
// ui, styles //
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';

import { styles } from '../../../styles/Style';

import PmMap from './PmMap';
import PmLegend from './PmLegend';
import { PmStatusColor } from './PmStatusColor';
import WeatherContext from '../../../contexts/weatherContext';
// translate
import tJson from '../../../utils/TJson';
import Modal from '../../modal/Modal';
import { delay } from 'lodash';

const style = {
  container: {
    maxHeight: 700,
    borderBottom: '1px solid #ccc',
  },
};
class PmBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // _isMounted: true,
      isLoading: true, // loading status
      error: null, // error
      latest: moment().format('YYYY-MM-DD'),
      selectedLayer: 'layerPm25',
      // map
      map: null,
      zoom: 5.65,
      location: [12.9, 101.6],
      markersPm25: [],
      markersPm10: [],

      // modal
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
    };

    this.handleSetMap = this.handleSetMap.bind(this);
    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleBaseLayerChange = this.handleBaseLayerChange.bind(this);
  }

  componentDidMount() {
    // this.getData();
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const {
      map, markersPm25, markersPm10, selectedLayer,
    } = this.state;
    e.preventDefault();

    map.panTo([parseFloat(lat), parseFloat(lng)]);
    // open Popup
    let currentMarker;
    if (selectedLayer === 'layerPm10') {
      currentMarker = markersPm10[id];
      if (currentMarker && currentMarker.getPopup()) {
        // const LatLng = { lat, lng };
        // const px = map.project(LatLng);
        // px.y -= 213 / 2;

        // pan to view marker click
        // map.panTo(map.unproject(px), { animate: true });
        map.panTo(currentMarker.getLatLng(), { animate: true });

        // delay 200 ms to zoom map level 10
        setTimeout(() => {
          map.setZoom(10);
          // delay 500 ms to openpopup
          setTimeout(() => {
            currentMarker.openPopup();
          }, 500);
        }, 200);
      }
    } else {
      currentMarker = markersPm25[id];
      if (currentMarker && currentMarker.getPopup()) {
        // pan to view marker click
        map.panTo(currentMarker.getLatLng(), { animate: true });

        // delay 200 ms to zoom map level 10
        setTimeout(() => {
          map.setZoom(10);

          // delay 500 ms to openpopup
          setTimeout(() => {
            currentMarker.openPopup();
          }, 500);
        }, 200);
      }
    }
  }

  handleModalOpen = params => {
    const { modalData } = this.state;
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  resetModal = () => {
    const { modalDataReset, modalData } = this.state;
    this.setState({ modalData: modalDataReset });
    modalData.open = false;
    this.setState({ modalData });
  }

  handleBaseLayerChange = event => {
    this.setState({ selectedLayer: event.target.value });
  };

  render() {
    const {
      classes, t, i18n, regionId, dataPm25LowToHigh, dataPm25HighToLow,
      dataPm10LowToHigh, dataPm10HighToLow,
    } = this.props;
    const {
      modalData,
      zoom,
      location,
      map,
      markersPm25,
      markersPm10,
      latest,
      selectedLayer,
    } = this.state;

    let arrSelect = dataPm25HighToLow;
    let tbHeadRow = t('PM 2.5');
    if (selectedLayer === 'layerPm10') {
      arrSelect = dataPm10HighToLow;
      tbHeadRow = t('PM 10');
    }

    return (
      <>
        <Grid container spacing={4}>
          <WeatherContext.Provider
            value={{
              handleModalOpen: this.handleModalOpen,
            }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <PmMap
                dataPm25={dataPm25LowToHigh}
                dataPm10={dataPm10LowToHigh}
                regionId={regionId}
                handleSetMap={this.handleSetMap}
                language={i18n.language}
                zoom={zoom}
                location={location}
                map={map}
                markersPm25={markersPm25}
                markersPm10={markersPm10}
                handleBaseLayerChange={this.handleBaseLayerChange}
                selectedLayer={selectedLayer}
              />
              <PmLegend
                selectedLayer={selectedLayer}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box display="flex" pt={1} borderBottom={1}>
                <Box p={1}>
                  <Box display="block" fontSize={16} fontWeight="fontWeightBold">
                    {t('สถานีคุณภาพอากาศที่มีมลพิษสูงที่สุด')}
                  </Box>
                  <Box display="block" fontSize={12}>
                    {t('ล่าสุด')}
                    {' '}
                    {`: ${latest} `}
                  </Box>
                </Box>
              </Box>
              <Box>
                <TableContainer style={style.container}>
                  <Table stickyHeader className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{ backgroundColor: '#e0e0e0' }}>{t('ที่ตั้ง')}</TableCell>
                        <TableCell align="left" style={{ backgroundColor: '#e0e0e0' }}>{t('เวลา')}</TableCell>
                        <TableCell align="center" style={{ backgroundColor: '#e0e0e0' }}>{tbHeadRow}</TableCell>
                        <TableCell align="center" style={{ backgroundColor: '#e0e0e0' }}>{t('เฉลี่ย 24ชม.')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        arrSelect.slice(0, 10).map((data, i) => {
                          let mapLink = data.station.tele_station_name.th;
                          const pmValue = (selectedLayer === 'layerPm10') ? data.pm10_value : data.pm25_value;
                          const pmAvgValue = (selectedLayer === 'layerPm10') ? data.pm10_avg_24h : data.pm25_avg_24h;
                          const pmTime = (selectedLayer === 'layerPm10') ? `${moment(data.pm10_datetime).format('HH:mm')} ${t('น.')}` : `${moment(data.pm25_datetime).format('HH:mm')} ${t('น.')}`;           
                          const colorStatus = PmStatusColor(pmValue, selectedLayer);
                          if (data.station.tele_station_lat
                          && data.station.tele_station_long
                          ) {
                            mapLink = (
                              <Button
                                onClick={this.handleSearchMarker(
                                  data.station.id,
                                  data.station.tele_station_lat,
                                  data.station.tele_station_long,
                                )}
                                title={`${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                                style={{ textAlign: 'left', paddingLeft: 5 }}
                              >
                                {`${tJson(data.station.tele_station_name, 'สถานี')} ${tJson(data.geocode.amphoe_name, 'อ.')} ${tJson(data.geocode.province_name, 'จ.')}`}
                              </Button>
                            );
                          }
                          return (
                            <TableRow key={data.station.id}>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ whiteSpace: "normal", paddingLeft: 6 }}
                              >
                                {mapLink}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {pmTime}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Box
                                  borderRadius={50}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  style={{
                                    backgroundColor:
                                      colorStatus.color.statusColor,
                                    color: colorStatus.color.textColorAvgTb,
                                    width: "45px",
                                    justifySelf: "center",
                                  }}
                                >
                                  {pmValue}
                                </Box>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {pmAvgValue != null && (
                                  <Box
                                    borderRadius={50}
                                    border={2}
                                    borderColor={colorStatus.color.statusColor}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                      color: colorStatus.color.textColorAvgTb,
                                      width: "45px",
                                      justifySelf: "center",
                                    }}
                                  >
                                    {Math.round(pmAvgValue)}
                                  </Box>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Modal
              open={modalData.open}
              modalHeader={modalData.header}
              modalContent={modalData.content}
              resetModal={this.resetModal}
            />
          </WeatherContext.Provider>
        </Grid>

      </>
    );
  }
}

PmBox.propTypes = {
  dataPm25LowToHigh: PropTypes.array.isRequired,
  dataPm25HighToLow: PropTypes.array.isRequired,
  dataPm10LowToHigh: PropTypes.array.isRequired,
  dataPm10HighToLow: PropTypes.array.isRequired,
  regionId: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
};
export default compose(withStyles(styles, { withTheme: true }), withTranslation())(PmBox);
