import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _, { orderBy, find } from 'lodash';
import moment from 'moment';

// ui
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import DamChart from './DamChart';
import { regionRid } from '../../store/dam';

import { styles } from '../../styles/Style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
const years = yearsRange(currentYear, 1993);
// console.log(years(currentYear, currentYear-15));

class DamDailyChartSumByRegion extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      selectedType: 'total_dam_storage',
      selectedYear: [`${currentYear}`, `${currentYear - 1}`, `${currentYear - 2}`],
      selectedRegion: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props.id + '-' + state.selectedDam);
    if (state.selectedRegion === '') {
      return {
        selectedRegion: parseInt(props.id, 10),
      };
      // console.log(props.id)
    }

    return null;
  }

  handleChange = event => {
    // console.log(event.target);
    if (event.target.name === 'selectedYear') {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      // if (value.length <= 15) {
      this.setState({ [event.target.name]: value });
      // } else {
      //   alert(t('เลือกปีได้ สูงสุด 15 ปีย้อนหลัง'));
      // }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChart = () => {
    const { selectedYear } = this.state;
    // alert(selectedType + ' - ' + selectedYear + ' - ' + selectedDam);
    if (_.isEmpty(selectedYear)) {
      this.setState({ YearError: true });
    } else {
      this.setState({ YearError: false });
      this.child.current.resetChart();
      this.child.current.renderChartSum();
    }
  }

  render() {
    const { classes } = this.props;
    const {
      selectedType,
      selectedYear,
      selectedRegion,
      YearError,
    } = this.state;

    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={9} md={10} lg={10} xl={10}>
              <Box py={{ xs: 0, sm: 2 }} className={classes.boxGraphDam}>
                <Box className={classes.graphDam}>
                  <DamChart
                    ref={this.child}
                    type={selectedType}
                    id={selectedRegion}
                    year={selectedYear}
                    chartCalType="sum"
                    name={selectedRegion ? find(regionRid, ['id', selectedRegion]).name : 'รวมทั้งประเทศ'}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="type">ประเภทข้อมูล</InputLabel>
                    <Select
                      value={selectedType}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedType',
                        id: 'type',
                      }}
                    >
                      <MenuItem value="total_dam_storage">ปริมาณน้ำเก็บกัก</MenuItem>
                      <MenuItem value="total_dam_uses_water">ปริมาณน้ำใช้การ</MenuItem>
                      <MenuItem value="total_dam_inflow">ปริมาณน้ำไหลลง</MenuItem>
                      <MenuItem value="total_dam_released">ปริมาณน้ำระบาย</MenuItem>
                      <MenuItem value="total_dam_inflow_acc">ปริมาณน้ำไหลลงสะสม</MenuItem>
                      <MenuItem value="total_dam_released_acc">ปริมาณน้ำระบายสะสม</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl} error={YearError}>
                    <InputLabel htmlFor="year">ปี</InputLabel>
                    <Select
                      multiple
                      value={selectedYear}
                      onChange={this.handleChange}
                      input={<Input id="year" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                      native
                      inputProps={{
                        name: 'selectedYear',
                        id: 'year',
                      }}
                    >
                      {years.map(year => (
                        <option key={year} value={year} selected={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                          {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="year">ภาค</InputLabel>
                    <Select
                      value={selectedRegion}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'selectedRegion',
                        id: 'id',
                      }}
                    >
                      <MenuItem key={99} value={0}>
                        รวมทั้งประเทศ
                      </MenuItem>
                      {orderBy(regionRid, ['name'], ['asc']).map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box pt={3}>
                <Grid item xs={12} sm md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <Button variant="outlined" color="primary" size="small" onClick={this.handleChart}>
                      แสดง
                    </Button>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classes.info}>
            * สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
          </Box>
        </Grid>
      </div>
    );
  }
}

DamDailyChartSumByRegion.propTypes = {
  id: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DamDailyChartSumByRegion);
