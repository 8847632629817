/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';

import {
  HiiWaterLevelV2Icon, HiiRainIcon,
} from '../../../utils/IconBma';

// img
const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: '100%',
  },
  title: {
    fontSize: '1em',
    fontFamily: 'sans-serif',
    color: 'white',
    fontWeight: 500,
    border: 'none !important',
    padding: '10px 0px',
    '&:nth-child(2)': {
      borderLeft: '1px solid #253b47 !important',
      borderRight: '1px solid #253b47 !important',
    },
  },
  block: {
    border: 'none !important',
    verticalAlign: 'initial !important',
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(2)': {
        paddingLeft: '30px',
        borderLeft: '1px solid #253b47 !important',
        borderRight: '1px solid #253b47 !important',
      },
      '&:nth-child(3)': {
        paddingLeft: '30px',
      },
    },
  },
  detail: {
    fontSize: '1em',
    color: 'white',
    padding: 15,
  },
  icon: {
    width: 30,
    height: 30,
    [theme.breakpoints.down('xs')]: {
      width: 30,
      height: 30,
    },
  },
  label: {
    width: 140,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      paddingBottom: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.only('sm')]: {
      width: 'none',
      paddingBottom: theme.spacing(2),
    },
    marginRight: theme.spacing(3),
  },
  labelRain: {
    width: 120,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      paddingBottom: theme.spacing(2),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.only('sm')]: {
      width: 'none',
      paddingBottom: theme.spacing(2),
    },
    marginRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  labelTwitter: {
    width: 140,
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      paddingBottom: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },
  row: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    borderBottom: '1px solid #0f3045',
  },
  body: {
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    color: 'white',
    margin: 0,
    paddingLeft: 15,
  },
  body2: {
    fontFamily: 'sans-serif',
    fontSize: '1.1em',
    color: 'white',
    margin: 0,
    padding: 15,
  },
}));

const CompInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box pl={{ xs: 0, sm: 5, md: 3 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={2}>
            <Box className={classes.title}>
              <Box pr={{ md: '12px' }}>ข้อความแจ้งเตือนภัย</Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <div style={{ width: '100%' }}>
              <Box display="flex" flexWrap="wrap">
                <Box display="flex" alignItems="center" className={classes.labelTwitter}>
                  <Box><img src={`${initPath}images/marker/bma/Traffy/fondue.png`} className={classes.icon} alt="Icon" /></Box>
                  <Box className={classes.body2}>{'Fondue'}</Box>
                </Box>
                <Box display="flex" alignItems="center" className={classes.labelTwitter}>
                  <Box><img src={`${initPath}images/marker/bma/twitter/fm91_25x25.png`} className={classes.icon} alt="Icon" /></Box>
                  <Box className={classes.body2}>{'สวพ.'}</Box>
                </Box>
                <Box display="flex" alignItems="center" className={classes.labelTwitter}>
                  <Box><img src={`${initPath}images/marker/bma/twitter/twitter.png`} className={classes.icon} alt="Icon" /></Box>
                  <Box className={classes.body2}>{'ทวิตเตอร์'}</Box>
                </Box>
                <Box display="flex" alignItems="center" className={classes.labelTwitter}>
                  <Box><img src={`${initPath}images/marker/bma/twitter/js100_25x25.png`} className={classes.icon} alt="Icon" /></Box>
                  <Box className={classes.body2}>{'จส.100'}</Box>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.row} />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={2}>
            <Box display="flex" alignItems="center" className={classes.title}>
              ฝน
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Box display="flex" flexWrap="wrap">
              <Box display="flex" alignItems="center" className={classes.labelRain}>
                <Box><HiiRainIcon className={classes.icon} style={{ color: '#AFD3FF' }} alt="Icon" /></Box>
                <Box display="block">
                  <Box className={classes.body}> >0-10 </Box>
                  <Box className={classes.body}>ฝนเล็กน้อย</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.labelRain}>
                <Box><HiiRainIcon className={classes.icon} style={{ color: '#AEECB7' }} alt="Icon" /></Box>
                <Box display="block">
                  <Box className={classes.body}> >10-20 </Box>
                  <Box className={classes.body}>ฝนเล็กน้อย</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.labelRain}>
                <Box><HiiRainIcon className={classes.icon} style={{ color: '#7FC63C' }} alt="Icon" /></Box>
                <Box display="block">
                  <Box className={classes.body}> >20-35 </Box>
                  <Box className={classes.body}>ฝนปานกลาง</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.labelRain}>
                <Box><HiiRainIcon className={classes.icon} style={{ color: '#F0D447' }} alt="Icon" /></Box>
                <Box display="block">
                  <Box className={classes.body}> >35-50 </Box>
                  <Box className={classes.body}>ฝนหนัก</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.labelRain}>
                <Box><HiiRainIcon className={classes.icon} style={{ color: '#EF9036' }} alt="Icon" /></Box>
                <Box display="block">
                  <Box className={classes.body}> >50-70 </Box>
                  <Box className={classes.body}>ฝนหนัก</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.labelRain}>
                <Box><HiiRainIcon className={classes.icon} style={{ color: '#BD6B26' }} alt="Icon" /></Box>
                <Box display="block">
                  <Box className={classes.body}> >70-90 </Box>
                  <Box className={classes.body}>ฝนหนัก</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.labelRain}>
                <Box><HiiRainIcon className={classes.icon} style={{ color: '#EE131F' }} alt="Icon" /></Box>
                <Box display="block">
                  <Box className={classes.body}> >90 </Box>
                  <Box className={classes.body}>ฝนหนักมาก</Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider className={classes.row} />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={2}>
            <Box display="flex" alignItems="center" className={classes.title}>
              ระดับน้ำ
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Box display="flex" flexWrap="wrap">
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><HiiWaterLevelV2Icon className={classes.icon} style={{ color: '#DB802C' }} alt="Icon" /></Box>
                <Box className={classes.body}>น้อยวิกฤต</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><HiiWaterLevelV2Icon className={classes.icon} style={{ color: '#FFC000' }} alt="Icon" /></Box>
                <Box className={classes.body}>น้อย</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><HiiWaterLevelV2Icon className={classes.icon} style={{ color: '#00B050' }} alt="Icon" /></Box>
                <Box className={classes.body}>ปกติ</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><HiiWaterLevelV2Icon className={classes.icon} style={{ color: '#023CFA' }} alt="Icon" /></Box>
                <Box className={classes.body}>มาก</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><HiiWaterLevelV2Icon className={classes.icon} style={{ color: '#FF0101' }} alt="Icon" /></Box>
                <Box className={classes.body}>ล้นตลิ่ง</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider className={classes.row} />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={2}>
            <Box display="flex" alignItems="center" className={classes.title}>
              ประตูน้ำ
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Box display="flex" flexWrap="wrap">
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-gate-close.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>ปิด</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-gate-open.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>เปิด</Box>
              </Box>
            </Box>
            <Hidden smUp><Divider style={{ borderBottom: '1px solid #0f3045' }} /></Hidden>
          </Grid>
          <Grid item md={2}>
            <Box display="flex" alignItems="center" className={classes.title}>
              ปั๊มน้ำ
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Box display="flex" flexWrap="wrap">
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-pump-close.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>ปิด</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-pump-open.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>เปิด</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-pump-red.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>เสีย</Box>
              </Box>
            </Box>
            {/* <Hidden smUp><Divider style={{ borderBottom: '1px solid #0f3045' }} /></Hidden> */}
          </Grid>
        </Grid>
        <Divider className={classes.row} />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={2}>
            <Box display="flex" alignItems="center" className={classes.title}>
              น้ำท่วมถนน
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Box display="flex" flexWrap="wrap">
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-flood-green.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>{'<= 10 ซม.'}</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-flood-yellow.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>{'> 10-20 ซม.'}</Box>
              </Box>
              <Box display="flex" alignItems="center" className={classes.label}>
                <Box><img src={`${initPath}images/theme/bma/icon/icon-flood-pink.png`} className={classes.icon} alt="Icon" /></Box>
                <Box className={classes.body}>{'> 20 ซม.'}</Box>
              </Box>
            </Box>
            <Hidden smUp><Divider style={{ borderBottom: '1px solid #0f3045' }} /></Hidden>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CompInfo;
