import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;
const sizeIcon = 18;
const canalGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Waterlevel/icon_waterlevel32px-3.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const canalOrange = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Waterlevel/icon_waterlevel32px-1.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const canalRed = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/Waterlevel/icon_waterlevel32px-5.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const canalColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'เฝ้าระวัง',
    rangeTitle: '>10-30',
    statusColor: '#ffc000',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปกติ',
    rangeTitle: '>30-70',
    statusColor: '#00b050',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ระดับวิกฤต',
    rangeTitle: '>100',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const CanalStatusColor = (val, warningLevel, criticalLevel) => {
  let color;
  let icon;
  const [zero, one, two, three] = canalColor;

  if (val === null) {
    color = zero;
    icon = canalGreen;
  } else if (val >= criticalLevel) {
    color = three;
    icon = canalRed;
  } else if (val >= warningLevel) {
    color = one;
    icon = canalOrange;
  } else {
    color = two;
    icon = canalGreen;
  }

  return { color, icon };
};

export default CanalStatusColor;
