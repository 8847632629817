import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import moment from 'moment';

// ui
import Img from 'react-image';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import Lightbox from 'react-image-lightbox';
import { FaSpinner } from '../../../utils/Fontawesome';
import 'react-image-lightbox/style.css';

import { styles } from '../../../styles/Style';

const width = '90%';

// lightbox
const images = [];

class TroughLatest extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      imageTrough: [],
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({
      isLoading: true,
    });

    // get data from ws
    this.getData();
  }

  getData() {
    const timeNow = moment().hours();
    // 00utc เสร็จ 15.00
    // 12utc เสร็จ 03.00
    let startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    let time = '07:00';
    const [morning, evening] = [15, 3];
    const [picM, picE] = ['https://live1.hii.or.th/product/latest/wrfroms/trough/trough_00.png', 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_00.png'];
    let srcImageTrough = picM;

    if (timeNow >= morning && evening < timeNow) {
      srcImageTrough = picM;
      startDate = moment().format('YYYY-MM-DD');
    } else {
      srcImageTrough = picE;
      startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
      time = '19:00';
    }
    const obj = {
      image: srcImageTrough,
      date: `${startDate}`,
      time,
    };
    const arr = [];
    arr.push(obj);
    this.setState({
      imageTrough: arr,
      isLoading: false,
    });
  }

  render() {
    const { classes, t, i18n } = this.props;
    const {
      isLoading,
      error,
      photoIndex,
      isOpen,
      imageTrough,
    } = this.state;
    const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;
    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    return (
      <>
        {error && <div className="text-center">{error.message}</div>}

        {isLoading === true && (
        <div className="text-center">
          <FaSpinner size={35} />
        </div>
        )}

        <Grid container spacing={4} style={{ marginTop: 18, marginBottom: 24 }}>
          { imageTrough.map((row, i) => {
            let realImage = '';
            if (row.image !== '') {
              images.push({
                src: `${row.image}`,
                title: `${t('วันที่')} ${moment(row.date).format('YYYY-MM-DD')} ${t('เวลา')} : ${row.time} ${t('น.')}`,
              });
              realImage = (
                <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                  <Img
                    src={`${row.image}`}
                    width="300"
                    height="250"
                    alt={moment(row.image).locale(languageI18n).format('ll')}
                    className={classes.responsive}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src="https://via.placeholder.com/180x126.png?text=No+Image"
                  width={width}
                  alt="No Image"
                  className={classes.responsive}
                  style={{ height: 400 }}
                />
              );
            }
            return (
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                <Box p={0} display="block">
                  <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                    {realImage}
                    <Typography className={classes.dateTime}>
                      {`${t('วันที่')} ${row.date} ${t('เวลา')} : ${row.time} ${t('น.')}`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

TroughLatest.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TroughLatest);
