import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { orderBy } from 'lodash';
import DamDailyChart from '../../dam/DamDailyChart';
import DamMediumChart from '../../dam/DamMediumChart';
import { FaSpinner } from '../../../utils/Fontawesome';

const damChart = () => {
  const { damtype, id } = useParams();
  const [damId, setDamId] = useState(parseInt(id, 10));
  const [damsMediumRidList, setDamsMediumRidList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/dam`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        // sorting and filter, medium dam
        const damMediumSort = orderBy(result.data.dam_medium, ['dam_storage_percent'], ['desc']);
        const datasDamMedium = damMediumSort.filter(d => {
          const time = new Date(d.dam_date).getTime();
          const row = d;
          row.dam.dam_name.th = d.dam.dam_name.th;
          row.geocode.province_name.th = d.geocode.province_name.th;
          row.geocode.amphoe_name.th = d.geocode.amphoe_name.th;
          row.geocode.tumbon_name.th = d.geocode.tumbon_name.th;
          return (last24hrs < time);
        });

        // dam medium dropdown
        const damsMediumRidLists = orderBy(datasDamMedium, ['dam.dam_name.th'], ['asc']).map(d => ({
          id: d.dam.id,
          name: d.dam.dam_name.th,
        }));

        setDamsMediumRidList(damsMediumRidLists);
      })
      .catch(setIsLoading(false));
  };

  useEffect(() => {
    if (damtype === 'medium_dam') { getData(); }
    setDamId(parseInt(id, 10));
  }, [id]);

  return (
    <>
      {isLoading === true && (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      )}
      { damtype === 'big_dam' ? <DamDailyChart id={damId} agencyId={12} /> : null}
      { damtype === 'medium_dam' && damsMediumRidList.length > 0 ? <DamMediumChart id={damId} damsMediumRid={damsMediumRidList} /> : null}
    </>
  );
};

export default damChart;
