import React, {
  useState, useRef,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// ui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// components
import DamMainDailyChartCompareHC from './DamMainDailyChartCompareHC';

import { styles } from '../../styles/Style';

const useStyles = makeStyles(styles);

const currentDate = moment().date();
const currentMonth = moment().month() + 1;
const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());

export default function DamMainDailyChartCompare() {
  const { t } = useTranslation();
  const classes = useStyles();
  const childRef = useRef();
  const years = yearsRange(currentYear, 1993);
  const [selectedType, setSelecetedType] = useState('total_dam_storage');
  const [selectedDate, setSelecetedDate] = useState(`${currentDate}`);
  const [selectedMonth, setSelecetedMonth] = useState(`${currentMonth}`);
  const [selectedYear, setSelectedYear] = useState([
    `${currentYear}`,
    `${currentYear - 1}`,
    `${currentYear - 2}`,
  ]);

  const handleChange = event => {
    // console.log(event.target);
    if (event.target.name === 'selectedYear') {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      // if (value.length <= 15) {
      setSelectedYear(value);
      // } else {
      //   alert(t('เลือกปีได้ สูงสุด 15 ปีย้อนหลัง'));
      // }
    } else if (event.target.name === 'selectedMonth') {
      setSelecetedMonth(event.target.value);
    } else if (event.target.name === 'selectedDate') {
      setSelecetedDate(event.target.value);
    } else if (event.target.name === 'selectedType') {
      setSelecetedType(event.target.value);
    }
  };

  const handleChart = () => {
    /*
    *   CALL A METHOD FROM THE CHILD
    */
    childRef.current.resetChart();
    childRef.current.renderChart();
  };

  return (
    <>
      <form autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} md={10} lg={10} xl={10}>
            <Box py={{ xs: 0, sm: 2 }} className={classes.boxGraphDam}>
              <Box className={classes.graphDam}>
                <DamMainDailyChartCompareHC
                  ref={childRef}
                  type={selectedType}
                  date={selectedDate}
                  month={selectedMonth}
                  year={selectedYear}
                />
              </Box>
            </Box>
          </Grid>
          {/* Menu control */}
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <InputLabel htmlFor="type">{t('ประเภทข้อมูล')}</InputLabel>
                  <Select
                    value={selectedType}
                    onChange={handleChange}
                    inputProps={{
                      name: 'selectedType',
                      id: 'type',
                    }}
                  >
                    <MenuItem value="total_dam_storage">{t('ปริมาณน้ำเก็บกัก')}</MenuItem>
                    <MenuItem value="total_dam_uses_water">{t('ปริมาณน้ำใช้การ')}</MenuItem>
                    <MenuItem value="total_dam_inflow">{t('ปริมาณน้ำไหลลง')}</MenuItem>
                    <MenuItem value="total_dam_released">{t('ปริมาณน้ำระบาย')}</MenuItem>
                    <MenuItem value="total_dam_inflow_acc">{t('ปริมาณน้ำไหลลงสะสม')}</MenuItem>
                    <MenuItem value="total_dam_released_acc">{t('ปริมาณน้ำระบายสะสม')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <InputLabel htmlFor="type">{t('วันที่')}</InputLabel>
                  <Select
                    value={selectedDate}
                    onChange={handleChange}
                    inputProps={{
                      name: 'selectedDate',
                      id: 'date',
                    }}
                  >
                    {[...Array(31)].map((x, i) => <MenuItem key={`${i + 1}`} value={`${i + 1}`}>{i + 1}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <InputLabel htmlFor="type">{t('เดือน')}</InputLabel>
                  <Select
                    value={selectedMonth}
                    onChange={handleChange}
                    inputProps={{
                      name: 'selectedMonth',
                      id: 'month',
                    }}
                  >
                    <MenuItem value="1">{t('มกราคม')}</MenuItem>
                    <MenuItem value="2">{t('กุมภาพันธ์')}</MenuItem>
                    <MenuItem value="3">{t('มีนาคม')}</MenuItem>
                    <MenuItem value="4">{t('เมษายน')}</MenuItem>
                    <MenuItem value="5">{t('พฤษภาคม')}</MenuItem>
                    <MenuItem value="6">{t('มิถุนายน')}</MenuItem>
                    <MenuItem value="7">{t('กรกฎาคม')}</MenuItem>
                    <MenuItem value="8">{t('สิงหาคม')}</MenuItem>
                    <MenuItem value="9">{t('กันยายน')}</MenuItem>
                    <MenuItem value="10">{t('ตุลาคม')}</MenuItem>
                    <MenuItem value="11">{t('พฤศจิกายน')}</MenuItem>
                    <MenuItem value="12">{t('ธันวาคม')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <InputLabel htmlFor="year">
                    {t('ปี')}
                  </InputLabel>
                  <Select
                    multiple
                    native
                    value={selectedYear}
                    onChange={handleChange}
                    input={<Input id="year" />}
                    renderValue={selected => selected.join(', ')}
                    inputProps={{
                      name: 'selectedYear',
                      id: 'year',
                    }}
                  >
                    {years.map(year => (
                      <option key={year} value={year} selected={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                        {year}
                      </option>
                    ))}
                    {/* {years.map(year => (
                      <MenuItem key={year} value={year}>
                        <Checkbox checked={selectedYear.indexOf(year) > -1} />
                        <ListItemText primary={year} />
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <Box pt={3}>
              <Grid item xs={12} sm md={12} lg={12}>
                <FormControl>
                  <Button variant="outlined" color="primary" size="small" onClick={handleChart}>
                    {t('แสดง')}
                  </Button>
                </FormControl>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className={classes.info}>
          *
          {' '}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </Box>
        <Box className={classes.info}>
          **
          {' '}
          {t('เนื่องจากทั้ง 4 เขื่อนมีข้อมูลสมบูรณ์ในช่วงเวลาที่แตกต่างกัน สามารถอ่านรายละเอียดเงื่อนไขการแสดงผลเพิ่มเติมได้ที่แถบ “คำอธิบาย”')}
        </Box>
      </Grid>
    </>
  );
}
