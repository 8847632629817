export const hiiReportSituation = [
    {
        'id': 1,
        'name': 'แจ้งเตือนสถานการณ์',
        'yearThai': '2567',
        'yearEng': '2024',
        'type': 'cover',
        'title': '01_pic',
        'folderId': '13DRGtjo05rXdoftPJVoUexlOJ2ONa27J',
        'fullUrl': 'https://drive.google.com/drive/u/2/folders/1_Z6eKj-q2PGCbTYDDa3n6jcqGnkWFBK2',
        'history': 'https://drive.google.com/drive/folders/1pcyNBB8KuawgIykk2QaofUsYcgAOlnGm?usp=sharing',
    },
    {
        'id': 2,
        'name': 'แจ้งเตือนสถานการณ์',
        'yearThai': '2568',
        'yearEng': '2025',
        'title': '01_pic',
        'folderId': '1GKs70qpyqV-9ib-NWwZ5cSz2RmGUxMjk',
        'full_url': 'https://drive.google.com/drive/u/2/folders/1_Z6eKj-q2PGCbTYDDa3n6jcqGnkWFBK2',
        'history': 'https://drive.google.com/drive/folders/1GKs70qpyqV-9ib-NWwZ5cSz2RmGUxMjk?usp=sharing',
    },
]

export const hiiReportInfographic = [
    {
        'id': 1,
        'name': 'Infographic สถานการณ์น้ำ',
        'yearThai': '2566',
        'yearEng': '2023',
        'type': 'cover',
        'title': '01_pic',
        'folderId': '10hMbkn275L4Z2MuoNjqkAz6AotMCyICD',
        'full_url': 'https://drive.google.com/drive/u/2/folders/1_Z6eKj-q2PGCbTYDDa3n6jcqGnkWFBK2',
        'history': 'https://drive.google.com/drive/folders/1pcyNBB8KuawgIykk2QaofUsYcgAOlnGm?usp=sharing',
    },
    {
        'id': 2,
        'name': 'Infographic สถานการณ์น้ำ',
        'yearThai': '2567',
        'yearEng': '2024',
        'type': 'cover',
        'title': '01_pic',
        'folderId': '1Im0nV4l3vkML77w8CMoDQg8npP-4WyAX',
        'full_url': 'https://drive.google.com/drive/u/2/folders/1_Z6eKj-q2PGCbTYDDa3n6jcqGnkWFBK2',
        'history': 'https://drive.google.com/drive/folders/1pcyNBB8KuawgIykk2QaofUsYcgAOlnGm?usp=sharing',
    },
    {
        'id': 3,
        'name': 'Infographic สถานการณ์น้ำ',
        'yearThai': '2568',
        'yearEng': '2025',
        'type': 'cover',
        'title': '01_pic',
        'folderId': '1yuXOjTvnqYTv4M6OZNqBafJYui7NOH6k',
        'full_url': 'https://drive.google.com/drive/u/2/folders/1_Z6eKj-q2PGCbTYDDa3n6jcqGnkWFBK2',
        'history': 'https://drive.google.com/drive/folders/1yuXOjTvnqYTv4M6OZNqBafJYui7NOH6k?usp=sharing',
    },
]
 
export const apiKeyGoogle = 'report'; //key ที่แสดงให้เห็น จากหน้าเว็ป