import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;

const iconRainRed = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/red.png`,
  iconSize: [10, 10],
});

const iconRainBlue = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/blue.png`,
  iconSize: [10, 10],
});

const iconRainCeladon = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/celadon.png`,
  iconSize: [10, 10],
});

const iconRainGold = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/gold.png`,
  iconSize: [10, 10],
});

const iconRainGreen = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/green.png`,
  iconSize: [10, 10],
});

const iconRainOrange = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/orange.png`,
  iconSize: [10, 10],
});

const iconRainTawny = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/tawny.png`,
  iconSize: [10, 10],
});

const iconRainGrey = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/gray.png`,
  iconSize: [10, 10],
});

const iconfffff5 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/fffff5.png`,
  iconSize: [10, 10],
});

const iconffffcc = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ffffcc.png`,
  iconSize: [10, 10],
});

const iconffff99 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ffff99.png`,
  iconSize: [10, 10],
});

const iconffff66 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ffff66.png`,
  iconSize: [10, 10],
});

const iconffff33 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ffff33.png`,
  iconSize: [10, 10],
});

const iconffff0d = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ffff0d.png`,
  iconSize: [10, 10],
});

const iconffff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ffff00.png`,
  iconSize: [10, 10],
});

const iconccff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ccff00.png`,
  iconSize: [10, 10],
});

const icon99ff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/99ff00.png`,
  iconSize: [10, 10],
});

const icon66ff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/66ff00.png`,
  iconSize: [10, 10],
});

const icon33ff00 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/33ff00.png`,
  iconSize: [10, 10],
});

const icon00ff33 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/00ff33.png`,
  iconSize: [10, 10],
});

const icon00ff66 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/00ff66.png`,
  iconSize: [10, 10],
});

const icon00ff99 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/00ff99.png`,
  iconSize: [10, 10],
});

const icon00ffcc = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/00ffcc.png`,
  iconSize: [10, 10],
});

const icon00ffff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/00ffff.png`,
  iconSize: [10, 10],
});

const icon00ccff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/00ccff.png`,
  iconSize: [10, 10],
});

const icon0099ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/0099ff.png`,
  iconSize: [10, 10],
});

const icon0066ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/0066ff.png`,
  iconSize: [10, 10],
});

const icon0033ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/0033ff.png`,
  iconSize: [10, 10],
});

const icon0000ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/0000ff.png`,
  iconSize: [10, 10],
});

const icon4300f8 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/4300f8.png`,
  iconSize: [10, 10],
});

const icon6600ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/6600ff.png`,
  iconSize: [10, 10],
});

const icon9900ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/9900ff.png`,
  iconSize: [10, 10],
});

const iconcc00ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/cc00ff.png`,
  iconSize: [10, 10],
});

const iconff00ff = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ff00ff.png`,
  iconSize: [10, 10],
});

const iconff00cc = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ff00cc.png`,
  iconSize: [10, 10],
});

const iconff0099 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ff0099.png`,
  iconSize: [10, 10],
});

const iconff0066 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ff0066.png`,
  iconSize: [10, 10],
});

const iconec0420 = new L.Icon({
  iconUrl: `${initPath}images/marker/rain/ec0420.png`,
  iconSize: [10, 10],
});

const iconRain = {
  red: iconRainRed,
  blue: iconRainBlue,
  celadon: iconRainCeladon,
  gold: iconRainGold,
  green: iconRainGreen,
  yellow: iconRainOrange,
  tawny: iconRainTawny,
  grey: iconRainGrey,
  ifffff5: iconfffff5,
  iffffcc: iconffffcc,
  iffff99: iconffff99,
  iffff66: iconffff66,
  iffff33: iconffff33,
  iffff0d: iconffff0d,
  iffff00: iconffff00,
  iccff00: iconccff00,
  i99ff00: icon99ff00,
  i66ff00: icon66ff00,
  i33ff00: icon33ff00,
  i00ff33: icon00ff33,
  i00ff66: icon00ff66,
  i00ff99: icon00ff99,
  i00ffcc: icon00ffcc,
  i00ffff: icon00ffff,
  i00ccff: icon00ccff,
  i0099ff: icon0099ff,
  i0066ff: icon0066ff,
  i0033ff: icon0033ff,
  i0000ff: icon0000ff,
  i4300f8: icon4300f8,
  i6600ff: icon6600ff,
  i9900ff: icon9900ff,
  icc00ff: iconcc00ff,
  iff00ff: iconff00ff,
  iff00cc: iconff00cc,
  iff0099: iconff0099,
  iff0066: iconff0066,
  iec0420: iconec0420,
};

export default iconRain;
