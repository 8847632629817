/* eslint-disable no-irregular-whitespace */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import * as Parser from 'rss-parser';
import moment from 'moment';
import { uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// color
import grey from '@material-ui/core/colors/grey';

// icon .
import { FaCalendarAlt } from '../../utils/Fontawesome';
import { HiiPresentIcon } from '../../utils/Icon';
import BoxReport from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

import ReportSituation from './ReportSituation';
import ReportYearly from './ReportYearly';
import ReportMonthlyDrought from './ReportMonthlyDrought';
import ReportRainForecastSixMonth from './ReportRainForecastSixMonth';
import BoxSituation from './drive/BoxSituation';
import BoxInfographic from './drive/BoxInfographic';


const initPath = process.env.MIX_APP_PATH;
const tiwrmPath = process.env.MIX_TIWRM_URL;

const parser = new Parser();

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportMonthlyStartDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      weeklyReportData: [],

      photoIndex: 0,
      isOpen: false,

      dailyReportLatestDate: moment().format('YYYY-MM-DD'),
      reportDailySummaryStartDate: moment().format('YYYY-MM-DD'),
      maxDate: null,
      maxDateDailyReportLatestDate: moment().format('YYYY-MM-DD'),
    };

    this.getDailyReportLatest(-1);
  }

  componentDidMount() {
    this.getWeeklyReportData();
  }

  getDailyReportData = dateCurrent => fetch(`./proxy/pdf.php?file=https://hdrive.hii.or.th/WaterSituation/${dateCurrent}_1000_WaterSituation.pdf`);

  async getDailyReportLatest(day) {
    const date = moment().add(-day, 'day').format('YYYYMMDD');

    const dateCurrent = `${moment(date).format('YYYY')}/${moment(date).format('MM')}/${moment(date).format('YYYYMMDD')}`;
    const res = await this.getDailyReportData(dateCurrent);
    const loop = day + 1;
    if (res.status !== 200) {
      this.getDailyReportLatest(loop);
    } else {
      this.setState({
        dailyReportLatestDate: moment(date),
        maxDateDailyReportLatestDate: moment(date),
      });
    }
  }

  async getWeeklyReportData() {
    const datas = await parser.parseURL(`./proxy/feed.php?file=${tiwrmPath}/web/index.php/archive-report.feed?type=rss`);

    this.setState({
      weeklyReportData: datas.items.slice(0, 4),
      maxDate: moment(datas.lastBuildDate).locale('th').format('YYYY-MM-DD'),
    });
  }

  handleDailySummaryChange = date => {
    this.setState({ reportDailySummaryStartDate: date });
  };

  handleDailyChange = date => {
    this.setState({ dailyReportLatestDate: date });
  };

  render() {
    const { classes, t, i18n } = this.props;
    const {
      isOpen,
      photoIndex,
      weeklyReportData,
      reportMonthlyStartDate,
      reportDailySummaryStartDate,
      dailyReportLatestDate,
      maxDate,
      maxDateDailyReportLatestDate,
    } = this.state;
    const dateCurrent = `${moment(reportDailySummaryStartDate).format('YYYY')}/${moment(reportDailySummaryStartDate).format('MM')}/${moment(reportDailySummaryStartDate).format('YYYYMMDD')}`;
    const dateCurrentDaily = `${moment(dailyReportLatestDate).format('YYYY')}/${moment(dailyReportLatestDate).format('MM')}/${moment(dailyReportLatestDate).format('YYYYMMDD')}`;

    const images = [
      { src: 'https://hdrive.hii.or.th/WaterSituation/latest_WaterSituation01.JPG', title: 'สรุปสถานการณ์ เฝ้าระวัง' },
      { src: 'https://hdrive.hii.or.th/WaterSituation/latest_WaterSituation02.JPG', title: 'สรุปสถานการณ์ ปัจจุบัน' },
      { src: 'https://hdrive.hii.or.th/WaterSituation/latest_WaterSituation03.JPG', title: 'สรุป คาดการณ์' },
    ];

    const reportMonthlyLink = `${process.env.MIX_THAIWATER_PRODUCTION_URL}/uploads/contents/report/${moment(reportMonthlyStartDate).format('YYYYMM')}.pdf`;
    const monthlyReportImg = `${initPath}images/theme/banner/report/monthly/${moment(reportMonthlyStartDate).format('M')}.jpg`;
    const reportMonthlyStartDateFormat = moment().subtract(1, 'months').endOf('month').add(543, 'years')
      .format('MMMM YYYY');

    return (
      <Fragment key="key">
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={3} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Box display="flex" width="100%">
                    <Box display="inline" pt={1}>
                      <HiiPresentIcon fontSize={24} />
                    </Box>
                    <Box display="inline" pt={1} pl={1}>
                      <Typography variant="h4" gutterBottom>
                        {t('รายงานสถานการณ์')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 50 }}>
            <BoxSituation /> 
            <BoxInfographic />
          </Grid>

          <Grid container justifyContent="space-between" spacing={4}>
            {/* <Grid item xs={12} md={6} lg={5}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box pb={0} style={{ width: '100%' }}>
                    <Box display="flex" pb={0} mb={0}>
                      <Typography variant="h5" display="inline">
                        {t('สรุปสถานการณ์น้ำประจำวัน')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} md={5} lg={5} style={{ marginTop: 16 }}>
                  <a
                    href="https://hdrive.hii.or.th/WaterSituation/latest_WaterSituation.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={`${initPath}images/theme/banner/report/daily/report-daily-summary.png`} width="100%" alt={t('สรุปสถานการณ์น้ำประจำวัน')} />
                    <img src={`${initPath}images/theme/banner/report/book-shadow.png`} width="100%" style={{ marginTop: -6 }} alt="Hii" />
                  </a>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Box display="flex" alignItems="center" justifyContent="flex-center" style={{ height: '100%' }}>
                    <Box display="block">
                      <Box py={1}>
                        <FormControl className={classes.formControl}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                            <KeyboardDatePicker
                              openTo="date"
                              format="D MMMM YYYY"
                              views={['year', 'month', 'date']}
                              value={reportDailySummaryStartDate}
                              // maxDate={reportDailySummaryStartDate}
                              maxDate={maxDate}
                              minDate={moment('2018-01-01').format('YYYY-MM-DD')}
                              onChange={this.handleDailySummaryChange}
                              style={{ fontSize: 14 }}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Box>
                      <Box mt={1}>
                        <Button
                          href={`https://hdrive.hii.or.th/WaterSituation/${dateCurrent}_1000_WaterSituation.pdf`}
                          className={classes.buttonOutlined}
                          target="_blank"
                        >
                          <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดาวน์โหลด')}</Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <Grid item xs={12} md={12} lg={12}>
                <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                  <Box display="flex" pb={0} mb={0}>
                    <Typography variant="h5" display="inline">
                      {t('รายวัน')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={12} md={4} lg={6}>
                  <Box style={{ width: '100%' }}>
                    <Box display="flex" pb={0} mb={0}>
                      <Typography variant="h5" display="inline">
                        {t('รายงานสถานการณ์น้ำประจำวัน')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <Box display="flex">
                    <FormControl className={classes.formControl}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                        <KeyboardDatePicker
                          openTo="date"
                          format="D MMMM YYYY"
                          views={['year', 'month', 'date']}
                          value={dailyReportLatestDate}
                          maxDate={maxDateDailyReportLatestDate}
                          // maxDate={dailyReportLatestDate}
                          minDate={moment('2018-01-01').format('YYYY-MM-DD')}
                          onChange={this.handleDailyChange}
                          style={{ fontSize: 14 }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <Box display="flex" flexDirection="row-reverse">
                    <Button
                      href={`https://hdrive.hii.or.th/WaterSituation/${dateCurrentDaily}_1000_WaterSituation.pdf`}
                      className={classes.buttonOutlined}
                      target="_blank"
                    >
                      <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดาวน์โหลด')}</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4} style={{ marginTop: 14 }}>
                <Grid item xs={6} sm={4} md={4} lg={4} align="center">
                  <Link to={`${initPath}report`} onClick={() => this.setState({ isOpen: true, photoIndex: 0 })} style={{ backgroundColor: '#fff', border: 'none' }}>
                    <img src={`${initPath}images/theme/banner/report/daily/report-01.png`} style={{ width: '100%' }} alt="เฝ้าระวัง" />
                    <img src={`${initPath}images/theme/banner/report/book-shadow.png`} style={{ width: '100%', marginTop: -6 }} alt="เงา" />
                  </Link>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} align="center">
                  <Link to={`${initPath}report`} onClick={() => this.setState({ isOpen: true, photoIndex: 1 })} style={{ backgroundColor: '#fff', border: 'none' }}>
                    <img src={`${initPath}images/theme/banner/report/daily/report-02.png`} style={{ width: '100%' }} alt="ปัจจุบัน" />
                    <img src={`${initPath}images/theme/banner/report/book-shadow.png`} style={{ width: '100%', marginTop: -6 }} alt="เงา" />
                  </Link>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} align="center">
                  <Link to={`${initPath}report`} onClick={() => this.setState({ isOpen: true, photoIndex: 2 })} style={{ backgroundColor: '#fff', border: 'none' }}>
                    <img src={`${initPath}images/theme/banner/report/daily/report-03.png`} style={{ width: '100%' }} alt="คาดการณ์" />
                    <img src={`${initPath}images/theme/banner/report/book-shadow.png`} style={{ width: '100%', marginTop: -6 }} alt="เงา" />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            {/* weekly report */}
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={0}>
                <Grid item xs={7} sm={8} md={8}>
                  <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                    <Box display="flex" pb={0} mb={0}>
                      <Typography variant="h5" display="inline">
                        {t('รายสัปดาห์')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={5} sm={4} md={4}>
                  <Box mt={3} display="flex" flexDirection="row-reverse" style={{ height: '100%' }}>
                    <Box display="block">
                      <Box mb={1} ml={1}>
                        <Button
                          href={`${initPath}report_weekly`}
                          className={classes.buttonOutlined}
                        >
                          <FaCalendarAlt />
                          <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูย้อนหลัง')}</Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                {weeklyReportData.map(row => (
                  <Fragment key={uniqueId()}>
                    <Grid item xs={6} sm={6} md={3} lg={3} align="center">
                      <Box p={2} fontSize={{ xs: 11, sm: 14, md: 15 }} fontFamily="Kanit">
                        {t(row.title.replace('รายงานข้อมูลน้ำรายสัปดาห์', '').split('(ระหว่าง')[1].replace(')', ''))}
                      </Box>
                      <Box>
                        <Link to={`${initPath}report/report/view?url=${row.link}`} color="inherit" underline="none">
                          <img src={`${initPath}images/theme/banner/report/report-weekly-history.png`} style={{ width: '100%' }} className={classes.outLined} alt={t('รายงานข้อมูลน้ำรายสัปดาห์')} />
                          <img src={`${initPath}images/theme/banner/report/book-shadow.png`} style={{ width: '100%', marginTop: -6 }} alt="Hii" />
                        </Link>
                      </Box>

                      {/* <Typography variant="body2" gutterBottom>
                          {row.contentSnippet}
                        </Typography> */}

                      {/* 2020-05-20 เนื่องจากยังไม่มีระบบ backoffice สนับสนุน ทำให้ไฟล์ download
                        ไม่มีตัวไฟล์อยู่ในโพสของระบบเก่า จึงได้ปิดไว้ก่อน เมื่อมีระบบพร้อมแล้ว
                        จึงจะเปิดมาแสดงผลตาม design */}
                      {/* <Grid item xs={12} md={1} lg={1} className={classes.boxDownload}>
                          <Box display="flex" p={0} alignItems="center" justifyContent="center">
                            <Box p={0}>
                              <IconButton className={classes.button} aria-label="Delete">
                                <FaFileDownload />
                              </IconButton>
                              <Typography gutterBottom style={{ fontSize: 12, color: grey[700] }}>
                                ดาวน์โหลด
                              </Typography>
                            </Box>
                          </Box>
                        </Grid> */}
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 50 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Typography variant="h5" display="inline">
                    {t('รายเดือน')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={4}>
                <Grid item xs={6} md={5} lg={5}>
                  <a href={reportMonthlyLink} target="_blank" rel="noopener noreferrer">
                    <img src={monthlyReportImg} width="100%" alt={t('รายงานข้อมูลน้ำ รายเดือน')} className={classes.outLined} />
                    <img src={`${initPath}images/theme/banner/report/book-shadow.png`} width="100%" style={{ marginTop: -6 }} alt="เงา" />
                  </a>
                </Grid>
                <Grid item xs={6} md={7} lg={7}>
                  <Box display="flex" alignItems="center" justifyContent="flex-center" style={{ height: '100%' }}>
                    <Box display="block">
                      <Box>
                        <Typography variant="h5">
                          {t('รายงานข้อมูลน้ำ รายเดือน')}
                          <br />
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <br />
                          {reportMonthlyStartDateFormat}
                        </Typography>
                        <hr />
                      </Box>
                      {/* <Box py={1}>
                        <FormControl className={classes.formControl}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              openTo="month"
                              views={['year', 'month']}
                              value={reportMonthlyStartDate}
                              maxDate={reportMonthlyEndDate}
                              minDate={moment('2018-01-01').format('YYYY-MM-DD')}
                              onChange={this.handleChange}
                              style={{ fontSize: 14 }}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Box> */}
                      <Box mt={2}>
                        <Button
                          href={`${initPath}report_monthly`}
                          className={classes.buttonOutlined}
                        >
                          <FaCalendarAlt />
                          <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูย้อนหลัง')}</Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ReportMonthlyDrought />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <ReportRainForecastSixMonth />
            </Grid>

            <Grid container spacing={4} style={{ marginTop: 14 }}>
              <Grid item xs={12} md={6} lg={6}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                    <Box display="flex" pb={0} mb={0}>
                      <Typography variant="h5" display="inline">
                        {t('รายปี')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <ReportYearly />
              </Grid>
            </Grid>
          </Grid>
          <Grid id="flood" />
        </div>

        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 30 }}>
            <Grid item xs>
              <Box mt={3} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Box display="flex" width="100%">
                    <Box display="inline" pt={1}>
                      <HiiPresentIcon fontSize={24} />
                    </Box>
                    <Box display="inline" pt={1} pl={1}>
                      <Typography variant="h5" gutterBottom>
                        {t('บันทึกเหตุการณ์น้ำท่วม น้ำแล้งในอดีต')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        {/* manual  */}
        <Divider />
        <div className={classes.layout}>
          <Grid container spacing={4} bgcolor={grey[600]} style={{ paddingBottom: 24 }}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                <Box display="flex" justifyContent="center" m={1} p={0}>
                  <Typography variant="h6" display="inline" noWrap>
                    เดือนนี้ในอดีต
                  </Typography>
                  <Typography variant="h6" display="inline" className={classes.boxBgPrimary}>
                    มกราคม
                  </Typography>
                </Box>
              </Box>
              <Box p={2} className={classes.outLined} style={{ backgroundColor: 'white' }}>
                <Link
                  to={`${initPath}uploads/contents/current/2017/floodsouthjan2017/floodsouth_jan2017.html`}
                  // onClick={() => window.open(`${process.env.MIX_APP_URL}uploads/contents/current/2017/floodsouthjan2017/floodsouth_jan2017.html`, '_blank')}
                  target="_blank"
                  color="inherit"
                  underline="none"
                >
                  <img
                    src={`${initPath}images/theme/banner/report/report-month01.png`}
                    className={classes.responsive}
                    alt="บันทึกเหตุการณ์น้ำท่วมภาคใต้จากอิทธิพลของมรสุมตะวันออกเฉียงเหนือกำาลังแรง และหย่อมความกดอากาศต่ำ"
                  />
                  <Box p={1} height={80} fontSize={{ xs: 12, sm: 12, md: 14 }} color="#000" fontFamily="sans-serif">
                    บันทึกเหตุการณ์น้ำท่วมภาคใต้จากอิทธิพลของมรสุม
                    ตะวันออกเฉียงเหนือกำาลังแรง และหย่อมความกดอากาศต่ำ
                  </Box>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                <Box display="flex" justifyContent="center" m={1} p={0}>
                  <Typography variant="h6" display="inline" noWrap>
                    เดือนนี้ในอดีต
                  </Typography>
                  <Typography variant="h6" display="inline" className={classes.boxBgPrimary}>
                    ธันวาคม
                  </Typography>
                </Box>
              </Box>
              <Box p={2} className={classes.outLined} style={{ backgroundColor: 'white' }}>
                <Link
                  to={`${initPath}uploads/contents/current/2016/floodsouthdec2016/floodsouth_dec2016.html`}
                  // onClick={() => window.open(`${process.env.MIX_APP_URL}uploads/contents/current/2016/floodsouthdec2016/floodsouth_dec2016.html`, '_blank')}
                  target="_blank"
                  color="inherit"
                  underline="none"
                >
                  <img src={`${initPath}images/theme/banner/report/report-month02.png`} className={classes.responsive} alt="Hii" />
                  <Box p={1} height={80} fontSize={{ xs: 12, sm: 12, md: 14 }} color="#000" fontFamily="sans-serif">
                    บันทึกเหตุการณ์น้ำท่วมภาคใต้จากอิทธิพลของหย่อมความกดอากาศต่ำ (1-10 ธ.ค. 59)
                  </Box>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                <Box display="flex" justifyContent="center" m={1} p={0}>
                  <Typography variant="h6" display="inline" noWrap>
                    เดือนนี้ในอดีต
                  </Typography>
                  <Typography variant="h6" display="inline" className={classes.boxBgPrimary}>
                    พฤศจิกายน
                  </Typography>
                </Box>
              </Box>
              <Box p={2} className={classes.outLined} style={{ backgroundColor: 'white' }}>
                <Link
                  to={`${initPath}uploads/contents/current/1989/gay/gay2532.html`}
                  // onClick={() => window.open(`${process.env.MIX_APP_URL}uploads/contents/current/1989/gay/gay2532.html`, '_blank')}
                  target="_blank"
                  color="inherit"
                  underline="none"
                >
                  <img src={`${initPath}images/theme/banner/report/report-month03.png`} className={classes.responsive} alt="Hii" />
                  <Box p={1} height={80} fontSize={{ xs: 12, sm: 12, md: 14 }} color="#000" fontFamily="sans-serif">
                    พายุไต้ฝุ่น เกย์ (GAY) ปี 2532
                  </Box>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </div>
        <ReportSituation />

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </Fragment>
    );
  }
}

Report.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(Report));
