// https://github.com/freshmilkdev/react-render-optimization
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// import Box from '@material-ui/core/Box';
import red from '@material-ui/core/colors/red';

const textRed = red[900];
const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);


export default function StormListItem(props) {
  const { item, onCheckItem } = props;
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(true);
  }, [item]);

  const handleChange = event => {
    onCheckItem(event);
    if (event.target.checked === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  return (
    <CustomTooltip
      key={`${item.metadata.name}-tooltip`}
      title={(
        <div>
          {item.metadata.name}
          <br />
          ----------------
          <br />
          Code:
          {' '}
          {item.metadata.code}
          <br />
          Basin:
          {' '}
          {item.metadata.basin}
          <br />
          Period:
          {' '}
          {item.metadata.start_date}
          {' - '}
          {item.metadata.end_date}
          <br />
          Max Wind Speed:
          {' '}
          {item.metadata.max_wind_speed}
          {' knots'}
          <br />
          Type:
          {' '}
          {item.metadata.type}
          <br />
          Arrive Thailand:
          {' '}
          {item.metadata.attack_thailand}
        </div>
      )}
      aria-label={item.metadata.name}
    >
      <FormControlLabel
        key={item.metadata.name}
        control={(
          <Checkbox
            id={`${item.metadata.name}`}
            name={`${item.metadata.code}`}
            color="primary"
            // checked={checked}
            checked={item.isChecked}
            onChange={handleChange}
          />
          )}
        label={(
          <span style={{ fontSize: '0.8rem' }}>
            {
                {
                  true: <span style={{ color: textRed }}>{item.metadata.name}</span>,
                  false: item.metadata.name,
                }[item.metadata.attack_thailand]
              }
            {' '}
            {/* <Box
              component="span"
              borderRadius={3}
              style={{
                textAlign: 'center',
                margin: '3px',
                display: 'inline-block',
                backgroundColor: statusStyle.backgroundColor,
                color: statusStyle.color,
                width: '40px',
              }}
            >
              {`  ${item.metadata.type}  `}
            </Box> */}
            {/* {` (${item.metadata.basin})`} */}
          </span>
          )}
      />
    </CustomTooltip>
  );
}

StormListItem.propTypes = {
  item: PropTypes.object.isRequired,
  // statusStyle: PropTypes.object.isRequired,
  onCheckItem: PropTypes.func.isRequired,
};
