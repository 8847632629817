/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */
import React from 'react';

const DashboardContext = React.createContext({});

export default DashboardContext;
