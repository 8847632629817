import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
  },
});

export default function DroughtRainLegend() {
  const classes = useStyles();

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.8',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          {/* <Grid container>
            <Grid item><Typography variant="subtitle2"> Fcst Rain (mm) </Typography></Grid>
          </Grid> */}
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#1C6EEE',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> &gt; 800 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#2782F5',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 700 - 800 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#3C96F6',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 600 – 700 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#50A5F5',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 500 – 600 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#76BAFB',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 400 – 500 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#98D1FE',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 350 – 400 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#AEF3F8',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 300 – 350 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#E0FFFF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 250 – 300 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#78F573',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 200 – 250 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#96F58D',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 150 – 200 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#B5FAAA',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 100 – 150 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#C8FFBE',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  80 - 100 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FFFAA9',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  60 - 80 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FFE878',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  40 - 60 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FDC238',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  20 - 40 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FF9F00',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  15 - 20 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FE6102',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  10 - 15 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FC3305',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  5 - 10 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#DD1601',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  2 - 5 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#C00002',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2">  1 - 2 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#A40000',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'< 1'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
