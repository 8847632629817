const LevelColor = val => {
  let color;

  switch (val) {
    case 3:
      color = '#0016bf';
      break;
    case 2:
      color = '#4199fd';
      break;
    case 1:
      color = '#b2eef0';
      break;
    case 0:
      color = '#ffffff';
      break;
    case -1:
      color = '#f2c47f';
      break;
    case -2:
      color = '#ff7c43';
      break;
    case -3:
      color = '#c71b1b';
      break;
    default:
      color = 'transparent';
  }

  return color;
};

export default LevelColor;
