/**
 * *
 * @description set status searchbox componet [show or hidden]
 * @version 1.0.0
 * @author [Peerapong Srisom]
 */

const initPath = process.env.MIX_APP_PATH;
const routes = [
  { path: 'report/chart/chaopraya', show: false, maxWidth: 'xl' },
  { path: 'report/view', show: true, maxWidth: 'xl' },
];

const currentPath = window.location.pathname;
const routeMap = routes.filter(route => route.path === currentPath.replace(initPath, ''));
const maxWidthMap = routeMap.map(route => route.maxWidth);
const showStatus = routeMap.map(route => route.show);

const searchBoxStatus = (showStatus.length > 0) ? showStatus[0] : true;
const searchBoxMaxWidth = (maxWidthMap.length > 0) ? maxWidthMap[0] : 'lg';

export { searchBoxStatus, searchBoxMaxWidth };
