import React from 'react';
import { useParams } from 'react-router-dom';
import DischargeChart from '../../wl_rid/DischargeChart';
import WaterGateChartDischarge from '../../water_gate/WaterGateChartDischarge';

const wgChart = () => {
  const { stationId, provinceId } = useParams();
  return (
    <>
      <WaterGateChartDischarge id={parseInt(stationId, 10)} provinceId={provinceId}/>
      </>
  );
};

export default wgChart;
