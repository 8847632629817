import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Img from 'react-image';
import Box from '@material-ui/core/Box';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import IconButton from '@material-ui/core/IconButton';

import './Styles.css';
import { Typography } from '@material-ui/core';

const initPath = process.env.MIX_APP_PATH;
// const initPath = 'https://www.thaiwater.net/';

class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      selectedImage: null,
      currentIndex: 0,
    };
  }

  componentDidMount() {
    const { data, folder } = this.props;
    const images = data[folder];
    if (images && images.length > 0) {
      this.setState({ images, selectedImage: images[0] });
    }
  }

  selectImage = index => {
    const { images } = this.state;
    this.setState({ selectedImage: images[index], currentIndex: index });
  };

  navigateImage = direction => {
    const { currentIndex, images } = this.state;
    let newIndex = currentIndex + direction;
    if (newIndex < 0) {
      newIndex = images.length - 1;
    } else if (newIndex >= images.length) {
      newIndex = 0;
    }
    this.selectImage(newIndex);
  };

  render() {
    const {
      images, selectedImage, currentIndex,
    } = this.state;

    const { folder } = this.props;

    return (
      <Box m={{ xs: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                {selectedImage && (
                  <TransformWrapper
                    initialScale={1}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <Box className="gallery-box">
                        <Box m={{ xs: 0.5, md: 2 }} className="tools" display="flex">
                          <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => zoomIn()} className="thumbnail-btn-gallery"><Box display="flex" justifyContent="center" alignItems="center"><ZoomInIcon /></Box></IconButton>
                          <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => zoomOut()} className="thumbnail-btn-gallery"><Box display="flex" justifyContent="center" alignItems="center"><ZoomOutIcon /></Box></IconButton>
                          <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => resetTransform()} className="thumbnail-btn-gallery"><Typography variant="body2" className="thumbnail-btn-gallery-text">Reset</Typography></IconButton>
                        </Box>
                        <Box className="tools btn-navigate">
                          <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => this.navigateImage(-1)} alt="ก่อนหน้า" className="thumbnail-btn-gallery"><ChevronLeftIcon fontSize="large" /></IconButton>
                          <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => this.navigateImage(1)} alt="ถัดไป" className="thumbnail-btn-gallery"><ChevronRightIcon fontSize="large" /></IconButton>
                        </Box>
                        <TransformComponent style={{ height: '470px !important' }}>
                          <Img src={`${initPath}images/telestation/${folder}/${selectedImage}`} alt="Selected" id="selected-image" width="100%" height="auto" zoom="200" />
                        </TransformComponent>
                      </Box>
                    )}
                  </TransformWrapper>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display={{ xs: 'flex', md: 'block' }} justifyContent={{ xs: 'flex-start', md: 'center' }} className="thumbnail-tele-box">
              {images.map((image, index) => (
                <Img
                  key={index}
                  src={`${initPath}images/telestation/${folder}/${image}`}
                  alt={`Thumbnail ${index}`}
                  className={`thumbnail-tele-item ${currentIndex === index ? 'active primary' : ''}`}
                  onClick={() => this.selectImage(index)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default ImageGallery;
