import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Marker, Popup, Tooltip, LayerGroup,
} from 'react-leaflet';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { useTranslation } from 'react-i18next';

// material ui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Hidden } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

// components
import DamStatusColor from '../../dam/DamStatusColor';
import DamMediumChart from '../../dam/DamMediumChart';
import { damsMediumRid } from '../../../store/dam';

import tJson from '../../../utils/TJson';

// icons and style
import {
  HiiDamL0Icon,
  HiiDamL1Icon,
  HiiDamL2Icon,
  HiiDamL3Icon,
  HiiDamL4Icon,
  HiiDamL5Icon,
} from '../../../utils/Icon';
import { FaChartLine } from '../../../utils/Fontawesome';
import {
  styles,
} from '../../../styles/Style';

const useStyles = makeStyles(styles);

export default function MediumDamMarker(props) {
  const { t } = useTranslation();
  const refDamLarge = useRef(null);
  const [datas, setData] = useState([]);
  const classes = useStyles();

  function fetchDam() {
    fetch(`${process.env.MIX_API_URL}analyst/dam`)
      .then(response => response.json())
      .then(result => {
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        // sorting and filter, large dam
        const damDailySort = orderBy(result.data.dam_medium, ['dam_storage_percent'], ['desc']);
        const datasDamDailyRid = damDailySort.filter(d => {
          const time = new Date(d.dam_date).getTime();
          return ((last24hrs < time) && (d.agency.id === 12));
        });

        setData(datasDamDailyRid);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      });
  }

  function setIcon(val, type) {
    const status = DamStatusColor(val, type);

    return status.icon;
  }

  function damStatus(data, percent) {
    const status = DamStatusColor(parseFloat(percent).toFixed(0));
    const statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (<span style='color:${status.color.statusColor}'>${parseFloat(percent).toFixed(0)}% รนก.</span>)`;

    return statusText;
  }

  function setHighlightIcon(val) {
    const status = DamStatusColor(val);
    let icon = '';

    // setup icon
    if (val > 80) {
      icon = <HiiDamL5Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 60) {
      icon = <HiiDamL4Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 40) {
      icon = <HiiDamL3Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 20) {
      icon = <HiiDamL2Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else if (val > 0) {
      icon = <HiiDamL1Icon style={{ fontSize: '60px', fill: status.color.statusColor }} />;
    } else {
      icon = <HiiDamL0Icon style={{ fontSize: '60px' }} />;
    }

    return icon;
  }

  const handleChart = data => e => {
    e.preventDefault();
    const { handler } = props;
    const params = {
      header: t('กราฟอ่างเก็บน้ำขนาดกลางรายปี กรมชลประทาน'),
      content: <DamMediumChart id={data.dam.id} damsMediumRid={damsMediumRid} />,
    };

    handler(params);
  };

  useEffect(() => {
    fetchDam();
  }, []);

  function eachMediumDamData(data, i) {
    if (data.dam.dam_lat && data.dam.dam_long) {
      // marker position
      const position = [parseFloat(data.dam.dam_lat), parseFloat(data.dam.dam_long)];

      // marker icons
      const icon = setIcon(parseFloat(data.dam_storage_percent).toFixed(0), 'medium');

      // highlight icon
      const highlightIcon = setHighlightIcon(parseFloat(data.dam_storage_percent).toFixed(0));
      const color = DamStatusColor(parseFloat(data.dam_storage_percent).toFixed(0));
      const damStorageStatus = damStatus(
        data.dam_storage,
        parseFloat(data.dam_storage_percent)
          .toFixed(0),
      );

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
        >
          <Popup autoPan>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {`${tJson(data.dam.dam_name, 'อ่างเก็บน้ำ')} `}
                        {' '}
                        {`${tJson(data.geocode.province_name, 'จ.')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={2} align="center">
                      <Box display="flex" justifyContent="center">
                        <Box>{highlightIcon}</Box>
                        <Box>
                          <Typography variant="h3" style={{ marginTop: '10px' }}>
                            <span style={{ color: color.color.statusColor }}>
                              <strong>
                                {`${parseFloat(data.dam_storage_percent).toFixed(0)}%`}
                              </strong>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <strong>{t('ปริมาณน้ำกักเก็บ (% รนก.)')}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('น้ำไหลลง / ระบาย')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{parseFloat(data.dam_inflow).toFixed(2)}</strong>
                      {' '}
                      /
                      {' '}
                      <strong>{data.dam_released}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ปริมาณน้ำกักเก็บ')}</TableCell>
                    <TableCell className={classes.hiddenLine} colSpan={2} align="right">
                      <strong>
                        <div
                          className="text-center"
                          style={{ whiteSpace: 'nowrap' }}
                          dangerouslySetInnerHTML={{ __html: damStorageStatus }}
                        />
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ปริมาณน้ำใช้การได้จริง')}</TableCell>
                    {isEmpty(data.dam_uses_water_percent) === true && (
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>{`${data.dam_uses_water.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${t('รนก.')})`}</strong>
                      </TableCell>
                    )}
                    {isEmpty(data.dam_uses_water_percent) === false && (
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>-</strong>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      {t('หน่วย (ล้าน ลบ.ม.)')}
                    </TableCell>
                    <TableCell className={classes.hiddenLine} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`${t('วันที่')} ${data.dam_date}`}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <Button color="secondary" onClick={handleChart(data)} title={t('แสดง')}>
                        <FaChartLine />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.dam.dam_name, 'อ่างเก็บน้ำ')}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  return (
    <LayerGroup ref={refDamLarge}>
      {datas
        .map(eachMediumDamData)}
    </LayerGroup>
  );
}

MediumDamMarker.propTypes = {
  handler: PropTypes.func.isRequired,
};
