import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { uniqueId, orderBy } from 'lodash';

import { withTranslation } from 'react-i18next';

// color .
import grey from '@material-ui/core/colors/grey';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
// import { Hidden } from '@material-ui/core';

// icon .
import { FaCaretRight, FaSpinner } from '../../utils/Fontawesome';
import { HiiMediaIcon, HiiBookIcon, HiiVideoIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      bookList: [],
      // videoList: [],
      youtubeList: [],
      error: null,
      isiPhone: '',
    };

    this.youtubeThumb = this.youtubeThumb.bind(this);
    this.youtubeCode = this.youtubeCode.bind(this);
    this.urlEncode = this.urlEncode.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.getData();
    const isiPhone = /iPhone/.test(navigator.userAgent);
    this.setState({ isiPhone });
  }

  getData() {
    Promise.all([
      fetch(`${initPath}json/contents/medias/book.json`),
      // fetch(`${initPath}json/contents/medias/video.json`),
      fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLFtdoHir6hWchHRe14FUSu_fKEgQoLXr7&key=AIzaSyAog5tFTfrj2YS9RgnUHO96SuCdJqTRAmk'),
    ])
      .then(([book, youtube]) => Promise.all([book.json(), youtube.json()]))
      .then(([bookList, youtubeList]) => {
        this.setState({ bookList, youtubeList: youtubeList.items });
      })
      .catch(error => this.setState({ error }));
  }

  youtubeThumb = url => {
    if (url === null) {
      return 'http://img.youtube.com/vi/3FqF_REf9EE/mqdefault.jpg';
    }

    const results = url.match('[\\?&]v=([^&#]*)');
    const video = (results === null) ? url : results[1];

    return `http://img.youtube.com/vi/${video}/0.jpg`;
  }

  youtubeCode = url => {
    if (url === null) {
      return '';
    }

    const results = url.match('[\\?&]v=([^&#]*)');
    return (results === null) ? url : results[1];
  }

  urlEncode = url => Buffer.from(url).toString('base64');

  checkUrlOpen = url => {
    const { isiPhone } = this.state;
    if (isiPhone) {
      return `${url}`;
    }
    return `${initPath}media/book/view/${this.urlEncode(url)}`;
  }

  render() {
    const { classes, t } = this.props;
    const {
      bookList, youtubeList, isLoading, error, isiPhone,
    } = this.state;

    return (
      <div className={classes.layout}>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={3} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="inline" pt={1}>
                  <HiiMediaIcon fontSize={24} />
                </Box>
                <Box display="inline" pt={1} pl={1}>
                  <Typography variant="h4" gutterBottom>
                    {t('คลังสื่อ เผยแพร่')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box display="flex">
              <Box display="inline" pt={1}>
                <HiiBookIcon color={grey[400]} fontSize={14} />
              </Box>
              <Box display="inline" pl={1} pt={1}>
                <Typography variant="h6" display="inline">
                  {t('หนังสือ')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {(error) && (
            <Grid item xs={12} md={12} lg={12}>
              <Box alignItems="center">{error.message}</Box>
            </Grid>
          )}

          {(isLoading) && (
            <Grid item xs={12} md={12} lg={12}>
              <Box className="text-center">
                <FaSpinner size={30} />
              </Box>
            </Grid>
          )}

          <Grid item xs={6} sm={4} md={3} lg={3} key={uniqueId()}>
            <Link
              display="block"
              underline="none"
              title={t('คู่มือการใช้งานเว็บไซต์ ThaiWater.net')}
              // href={`${initPath}media/book/view/${this.urlEncode('uploads/contents/Thaiwater_manual guide_2021.pdf')}`}
              href="https://flipbook.hii.or.th/publishing/25ManualWebsiteThaiWater/#p=1"
              target="_blank"
            >
              <Box p={2} className={classes.outLined}>
                <img
                  src={`${initPath}images/theme/banner/cover-thaiwater-manual25.png`}
                  style={{ margin: 16, maxWidth: '-webkit-fill-available' }}
                  width="100%"
                  alt={t('คู่มือการใช้งานเว็บไซต์ ThaiWater.net')}
                />
                <Box px={2} pt={0} alignItems="center" justifyContent="center">
                  <Box style={{ minHeight: '50px' }} fontSize={{ xs: 12, md: 14 }} color={grey[800]}>
                    คู่มือการใช้งานเว็บไซต์ ThaiWater.net
                  </Box>
                </Box>
                <Link
                  display="block"
                  underline="none"
                  title={t('คู่มือการใช้งานเว็บไซต์ ThaiWater.net')}
                  href="https://flipbook.hii.or.th/publishing/25ManualWebsiteThaiWater/#p=1"
                  target="_blank"
                >
                  <Box className={classes.buttonDownload} align="center">{t('อ่าน อีบุ๊ค')}</Box>
                </Link>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={6} sm={4} md={3} lg={3} key={uniqueId()}>
            <Link
              display="block"
              underline="none"
              title={t(' คู่มือ ThaiWater เพื่อการติดตามและเฝ้าระวังสถานการณ์น้ำ')}
              // href={`${initPath}media/book/view/${this.urlEncode('uploads/contents/Thaiwater_manual guide_2021.pdf')}`}
              href="https://flipbook.hii.or.th/publishing/23Thaiwater-manual/#p=1"
              target="_blank"
            >
              <Box p={2} className={classes.outLined}>
                <img
                  src={`${initPath}images/theme/banner/Cover_Thaiwater_manual_guide.jpg`}
                  style={{ margin: 16, maxWidth: '-webkit-fill-available' }}
                  width="100%"
                  alt={t(' คู่มือ ThaiWater เพื่อการติดตามและเฝ้าระวังสถานการณ์น้ำ')}
                />
                <Box px={2} pt={0} alignItems="center" justifyContent="center">
                  <Box style={{ minHeight: '50px' }} fontSize={{ xs: 12, md: 14 }} color={grey[800]}>
                    คู่มือ ThaiWater เพื่อการติดตามและเฝ้าระวังสถานการณ์น้ำ
                  </Box>
                </Box>
                <Link
                  display="block"
                  underline="none"
                  title={t(' คู่มือ ThaiWater เพื่อการติดตามและเฝ้าระวังสถานการณ์น้ำ')}
                  href="https://flipbook.hii.or.th/publishing/23Thaiwater-manual/#p=1"
                  target="_blank"
                >
                  <Box className={classes.buttonDownload} align="center">{t('อ่าน อีบุ๊ค')}</Box>
                </Link>
              </Box>
            </Link>
          </Grid>

          {bookList.map(row => (
            <Grid item xs={6} sm={4} md={3} lg={3} key={uniqueId()}>
              <Link
                display="block"
                underline="none"
                title={t(row.title)}
                // href={`${initPath}media/book/view/${this.urlEncode(`${row.link}`)}
                href={this.checkUrlOpen(row.link)}
                target={isiPhone ? '_blank' : null}
              >
                <Box p={2} className={classes.outLined}>
                  <img
                    src={`${initPath}${row.thumbnail}`}
                    style={{ margin: 16, maxWidth: '-webkit-fill-available' }}
                    width="100%"
                    alt={t(row.title)}
                  />
                  <Box px={2} pt={0} alignItems="center" justifyContent="center">
                    <Box style={{ minHeight: '50px' }} fontSize={{ xs: 12, md: 14 }} color={grey[800]}>
                      {t(row.title)}
                    </Box>
                  </Box>
                  <Link display="block" underline="none" title={row.title} href={`${initPath}${row.link}`} download={`${t(row.title)}`}>
                    <Box className={classes.buttonDownload} align="center">{t('ดาวน์โหลด')}</Box>
                  </Link>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4} style={{ marginTop: 50 }}>
          <Grid item xs>
            <Divider style={{ marginBottom: '-19px' }} />
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={4} sm={2} md={2} lg={2}>
                <Link target="_blank" href={`${initPath}media/book/all`} underline="none" rel="noopener noreferrer">
                  <Button variant="outlined" className={classes.button} align="center" style={{ backgroundColor: 'white', width: '100%', alignContent: 'center' }}>
                    {t('เพิ่มเติม')}
                    <FaCaretRight fontSize="small" style={{ marginLeft: 10 }} />
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container spacing={4} style={{ marginTop: 50 }}>
          <Grid item xs>
            <Box display="flex" mb={2}>
              <Box><HiiVideoIcon size={24} color={grey[500]} /></Box>
              <Box pl={1}>
                <Box display="block" fontSize={18} fontFamily="Kanit" fontWeight="fontWeightMedium">
                  วีดีทัศน์
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          {orderBy(videoList, 'title', 'desc')
            .slice(0, 1)
            .map(row => (
              <Grid item xs={12} md={6} lg={6} key={uniqueId()}>
                <Link href={`${initPath}media/video/view/${this.youtubeCode(row.link)}`}>
                  // eslint-disable-next-line max-len
                  <img src={`${this.youtubeThumb(row.link)}`}
                    style={{ width: '100%', margin: '12px 0px' }} alt="Hii" />
                </Link>
                <Typography variant="h4" gutterBottom>
                  {row.title}
                </Typography>

                <Link component="button" to={`${initPath}/media`} color="inherit" underline="none">
                  <Typography variant="caption" className={classes.fontBaseWeight}>
                    อ่านต่อ
                  </Typography>
                </Link>
              </Grid>
            ))}
          <Grid item xs={12} md={6} lg={6}>
            {orderBy(videoList, 'title', 'desc')
              .slice(1, 9999)
              .map((row, i) => (
                <Grid key={uniqueId()}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Link href={`${initPath}media/video/view/${this.youtubeCode(row.link)}`}>
                        <img src={`${this.youtubeThumb(row.link)}`}
                          style={{ width: '100%', margin: 10 }} alt="Hii" />
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                      <Typography variant="h6" gutterBottom>
                        {row.title}
                      </Typography>
                      <Link target="_blank"
                        href={`${initPath}media/video/view/${this.youtubeCode(row.link)}`}
                        underline="none">
                        <Typography variant="caption" className={classes.fontBaseWeight}>
                          อ่านต่อ
                        </Typography>
                      </Link>
                    </Grid>
                    <Divider />
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid> */}
        {/** youtube list */}
        <Grid container spacing={4} style={{ marginTop: 50 }}>
          <Grid item xs>
            <Box display="flex" mb={2}>
              <Box><HiiVideoIcon size={24} color={grey[500]} /></Box>
              <Box pl={1}>
                <Box display="block" fontSize={18} fontFamily="Kanit" fontWeight="fontWeightMedium">
                  {t('วีดีทัศน์')}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <Grid container spacing={4}>
          {orderBy(youtubeList, 'snippet.publishedAt', 'desc')
            .slice(0, 1)
            .map(row => (
              <Grid item xs={12} sm={12} md={6} lg={6} key={uniqueId()}>
                <Link href={`${initPath}media/video/view/${row.snippet.resourceId.videoId}`} underline="none" rel="noopener noreferrer">
                  <img src={`${row.snippet.thumbnails.maxres.url}`} style={{ width: '100%' }} alt={row.snippet.title} />
                </Link>
                <Typography variant="h6" gutterBottom>
                  {row.snippet.title}
                </Typography> */}
                {/* <Typography style={{ margin: 10 }} className={classes.fontBase}>
                  ดร.สุทัศน์ วีสกุล ผู้อำนวยการ สสนก. ชี้แผนที่ติดตั้งโทรมาตรวัดระดับน้ำ
                  ในลำน้ำที่ติดตั้งไว้ 320 สถานี พบระดับน้ำในแม่น้ำเพชรบุรี น่าเป็นห่วง
                  ขึ้นสัญญาณเตือนสีแดง น้ำเอ่อท้นตลิ่งแล้ว โดยเฉพาะจุดตำบลกลัดหลวง
                  แต่แม่น้ำเพชรบุรีช่วงที่ไหลผ่านตัวเมือง ยังต่ำกว่าตลิ่ง ต้องเฝ้าระวังใน
                  3 วันนี้ ที่มีฝนตกต่อเนื่อง เติมน้ำในเขื่อนแก่งกระจาน
                  โดยสสนก.ได้พัฒนาระบบติดตามระดับน้ำ อัปเดททุก 10 นาที
                  ผ่าน www.thaiwater.net ให้ประชาชนติดตามสถานการณ์
                </Typography> */}
                {/* <Link component="button" to={`${initPath}/media`} color="inherit" underline="none">
                  <Typography variant="caption" className={classes.fontBaseWeight} style={{ fontFamily: 'Kanit' }}>
                    {t('อ่านต่อ')}
                  </Typography>
                </Link>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </Grid>
            ))}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {orderBy(youtubeList, 'snippet.publishedAt', 'desc')
              .slice(1, 9999)
              .map(row => (
                <Grid key={uniqueId()}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Link href={`${initPath}media/video/view/${row.snippet.resourceId.videoId}`} underline="none" rel="noopener noreferrer">
                        <img src={`${row.snippet.thumbnails.maxres.url}`} style={{ width: '100%' }} alt={row.snippet.title} />
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <Typography variant="h6" gutterBottom>
                        {row.snippet.title}
                      </Typography>
                      {/* <Typography className={classes.fontBase} gutterBottom align="justify">
                        ชี้แผนที่ติดตั้งโทรมาตรวัดระดับน้ำ ในลำน้ำที่ติดตั้งไว้
                        320 สถานี พบระดับน้ำในแม่น้ำเพชรบุรี น่าเป็นห่วง ขึ้นสัญญาณเตือนสีแดง
                      </Typography> */}
                      {/* <Link target="_blank" href={`${initPath}media/video/view/${row.snippet.resourceId.videoId}`} underline="none" rel="noopener noreferrer">
                        <Typography variant="caption" className={classes.fontBaseWeight} style={{ fontFamily: 'Kanit' }}>
                          {t('อ่านต่อ')}
                        </Typography>
                      </Link>
                    </Grid>
                    <Divider />
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs>
                      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>  */}

        {/* manual */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/m-uiUPLLM4E?si=g-jNIV0tMPHHfMYg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/IIDI7nrOkOM?si=_uX1rDkI6I9z7j15" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/Gj2Fh1WrM-o?si=b4NrPsEWl0T1Uq-U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Grid>
        </Grid>

        <Grid container spacing={4} style={{ marginTop: 30 }}>
          <Grid item xs>
            <Box display="flex" justifyContent="flex-end" bgcolor="background.paper">
              <Box>
                <Link underline="none" href={`${initPath}media/video/all`}>
                  <Button variant="outlined" align="center" style={{ backgroundColor: 'white', fontFamily: 'Kanit' }}>
                    {t('เพิ่มเติม')}
                    <FaCaretRight style={{ marginLeft: 10 }} />
                  </Button>
                </Link>
              </Box>
            </Box>
            <Divider style={{ marginTop: -16 }} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Media.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(Media));
