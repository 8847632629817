/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '17px',
    width: '31px',
    [theme.breakpoints.up('xs')]: {
      width: '33px',
    },
  },
  label: {
    color: 'white',
    fontSize: '0.7em',
    fontWeight: 400,
    textAlign: 'center',
  },
  detail: {
    color: 'white',
    fontSize: '0.8em',
    fontWeight: 400,
  },
}));

const RadarBkkLegend = props => {
  const classes = useStyles();
  const { metaDataRadarBkk } = props;

  return (
    <div>
      <Grid container direction="row-reverse" justifyContent="flex-end" spacing={0} wrap="nowrap">
        {metaDataRadarBkk.colorbar.map(row => (
          <Grid item key={row.color_code}>
            <Box className={classes.label}>{row.label}</Box>
            <Box
              style={{
                backgroundColor: row.color_code,
              }}
              className={classes.legend}
            />
          </Grid>
        ))}
      </Grid>
      <Box className={classes.detail}>เรดาร์ (mm/hr)</Box>
    </div>
  );
};

export default RadarBkkLegend;
RadarBkkLegend.propTypes = {
  metaDataRadarBkk: PropTypes.object.isRequired,
};
