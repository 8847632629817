/**
 * *
 * @version 1.0.0
 * @author [phithakkhet@hii.or.th]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 4,
    marginBottom: 4,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  legendWL: {
    fontSize: '0.8em',
    textAlign: 'center',
    height: '18px',
    width: '50px',
    marginRight: 4,
    marginBottom: 4,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  boxWL: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  label: {
    color: '#eee',
    fontSize: '0.9em',
    fontWeight: 400,
    marginRight: 30,
    fontFamily: 'sans-serif',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      marginRight: 23,
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: 13,
    },
  },
  labelFlood: {
    color: '#eee',
    fontSize: '0.9em',
    fontWeight: 400,
    marginRight: 30,
    fontFamily: 'sans-serif',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      marginRight: 23,
      width: 35,
    },
  },
  title: {
    paddingTop: 5,
    color: 'white',
    fontSize: '1em',
    fontWeight: 400,
    fontFamily: 'sans-serif',
    marginBottom: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    borderBottom: '1px solid #0f3045',
  },
}));

const AllLegend = () => {
  const classes = useStyles();

  return (
    <Box display="block" px={{ xs: 0, sm: 3 }}>
      <Box>
        <Box className={classes.title}>เกณฑ์น้ำท่วม</Box>
        <Box display="flex" flexWrap="wrap">
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#33ECFF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>0-0.2</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#339FFF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>0.2-0.5</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#333FFF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>0.5-1</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#6833FF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>1-2</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#E6083E',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}> >2 </Box>
          </Box>
        </Box>
      </Box>
      <Divider className={classes.row} />
      <Box>
        <Box className={classes.title}>เกณฑ์ระดับน้ำในคลอง</Box>
        <Box display="flex" flexWrap="wrap">
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#7CB7A1',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>ปกติ</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#F7C244',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>เตือนภัย</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#D73964',
              }}
              className={classes.legend}
            />
            <Box className={classes.label}>วิกฤต</Box>
          </Box>
        </Box>
      </Box>
      <Divider className={classes.row} />
      <Box className={classes.title}>เกณฑ์ระดับน้ำในแม่น้ำ</Box>
      <Box display={{ xs: 'flex', sm: 'flex' }} flexWrap="wrap">
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#000',
              backgroundColor: '#DB802B',
            }}
            className={classes.legendWL}
          >
            {'<=10'}
          </Box>
          <Box className={classes.label}>น้อยวิกฤต</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#000',
              backgroundColor: '#FFC000',
            }}
            className={classes.legendWL}
          >
            {'>10-30'}
          </Box>
          <Box className={classes.label}>น้อย</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#000',
              backgroundColor: '#00B050',
            }}
            className={classes.legendWL}
          >
            {'>30-70'}
          </Box>
          <Box className={classes.label}>ปกติ</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#fff',
              backgroundColor: '#003CFA',
            }}
            className={classes.legendWL}
          >
            {'>70-100'}
          </Box>
          <Box className={classes.label}>มาก</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#fff',
              backgroundColor: '#FF0000',
            }}
            className={classes.legendWL}
          >
            {'>100'}
          </Box>
          <Box className={classes.label}>ล้นตลิ่ง</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllLegend;
