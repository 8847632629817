import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import meta from '../store/SeoMeta';

export const seo = meta;

// eslint-disable-next-line import/prefer-default-export
export const SEO = ({
  // eslint-disable-next-line react/prop-types
  title, description, keywords,
}) => (
  <Helmet
    defaultTitle="คลังข้อมูลน้ำแห่งชาติ - Thaiwater.net | National Hydroinformatics Data Center"
    titleTemplate="%s | คลังข้อมูลน้ำแห่งชาติ - Thaiwater.net"
    title={title}
    meta={[
      {
        name: 'description',
        content: description,
      },
      {
        name: 'keywords',
        content: keywords,
      },
    ]}
  />
);

// withSeo function
export const withSeo = ({
  component: Component, meta,
}) => class Seo extends PureComponent {
  render() {
    return (
      <>
        <SEO
          title={meta.title}
          description={meta.description}
          keywords={meta.keywords}
        />
        <Component {...this.props} />
      </>
    );
  }
};
