// React
import React, { useEffect, useState, useRef } from 'react';
// leaflet
import {
  Map, Marker, TileLayer, Tooltip, LayersControl, LayerGroup,
} from 'react-leaflet';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { DivIcon } from 'leaflet';
import iconCircle from '../widgets/map/iconCircle';
import CurrentLocation from '../currentlocation/CurrentLocation';

import ReportSalinityChart from './ReportSalinityChart';
const { BaseLayer, Overlay } = LayersControl; // BaseLayer

// const initPath = process.env.MIX_API_HII_URL;

const useStyles = makeStyles({
  noblinkstatic: {
    width: '44px',
    height: '24px',
    cursor: 'pointer',
    borderRadius: '60px',
  },
});

export default function ReportSalinityFullMap(props) {
  const classes = useStyles();
  const { listStationPCD, listStationRID, handleModalOpen } = props;
  const [settingMap] = useState({ position: [13.615, 100.974], zoom: 10 });
  const mapRef = useRef(null);
  const [showPCD, setshowPCD] = useState(false);
  const [showRID, setshowRID] = useState(true);

  const handleChart = (stationId, stationType) => {
    const params = {
      header: 'กราฟแสดงสถานการณ์ค่าความเค็ม',
      modalSubHeader: '',
      content: <ReportSalinityChart stationId={stationId} stationType={stationType} />,
    };
    handleModalOpen(params);
  };
  const eachDataPCD = (data, i) => {
    const position = [parseFloat(data.station.lat), parseFloat(data.station.long)];
    const icon = new DivIcon({
      className: classes.noblinkstatic,
      html: `<div 
      style="
      width: 44px;
      height: 24px;
      margin-left: -16px;
      border-radius: 60px;
      border: 2px solid;
      opacity: 1;
      cursor: pointer;
      background-color: ${data.color.code};
      text-align: center;
      ">
      <span style="color: black;">
      ${data.measure.salt}
      </span>
      </div>`,
    });
    return (
      <Marker
        id={i}
        key={i}
        position={position}
        icon={icon}
        onClick={() => handleChart(data.id, 'PCD')}
        location={[13.8, 100.55]}
        zoom={6}
        datas={listStationPCD}
      >
        <Hidden smDown>
          <Tooltip title={data.station.name}>
            <span>
              สถานี :
              <strong>
                {data.station.name}
              </strong>
              <br />
              {/* ตําบล :
              <strong>
                {data.station.tambon}
              </strong>
              <br />
              อําเภอ :
              <strong>
                {data.station.amphoe}
              </strong>
              <br />
              จังหวัด :
              <strong>
                {data.station.province}
              </strong>
              <br />
              สถานการณ์ความเค็ม :
              <strong>
                {data.criterion.text}
              </strong>
              <br /> */}
              สถานการณ์ความเค็ม :
              <strong>
                {data.criterion[0].text}
              </strong>
              <br />
              สถานการณ์ปริมาณออกซิเจนที่ละลายในน้ำ :
              <strong>
                {data.criterion[1].text}
              </strong>
              <br />
              สถานการณ์แอมโมเนีย NH3 :
              <strong>
                {data.criterion[2].text}
              </strong>
              <br />
              ข้อมูลวันที่ :
              <strong>
                {data.date}
              </strong>
              <br />
            </span>
          </Tooltip>
        </Hidden>
      </Marker>
    );
  };
  const eachDataRID = (data, i) => {
    const position = [parseFloat(data.station.lat), parseFloat(data.station.long)];
    const icon = new DivIcon({
      className: classes.noblinkstatic,
      html: `<div 
      style="
      width: 44px;
      height: 24px;
      margin-left: -16px;
      border-radius: 60px;
      border: 2px solid;
      opacity: 1;
      cursor: pointer;
      background-color: ${data.salinity.color.code};
      text-align: center;
      ">
      <span style="color: black;">
      ${data.salinity.measure}
      </span>
      </div>`,
    });
    return (
      <Marker
        id={i}
        key={i}
        position={position}
        icon={icon}
        onClick={() => handleChart(data.id, 'RID')}
        location={[13.8, 100.55]}
        zoom={6}
        datas={listStationRID}
      >
        <Hidden smDown>
          <Tooltip title={data.station.name}>
            <span>
              สถานี :
              <strong>
                {data.station.name}
              </strong>
              <br />
              ตําบล :
              <strong>
                {data.station.tambon}
              </strong>
              <br />
              อําเภอ :
              <strong>
                {data.station.amphoe}
              </strong>
              <br />
              จังหวัด :
              <strong>
                {data.station.province}
              </strong>
              <br />
              สถานการณ์ความเค็ม :
              <strong>
                {data.salinity.criterion.text}
              </strong>
              <br />
              ข้อมูลวันที่ :
              <strong>
                {data.salinity.date}
              </strong>
              <br />
              สถานการณ์ปริมาณออกซิเจนที่ละลายในน้ำ :
              <strong>
                {data.DO.criterion.text}
              </strong>
              <br />
              ข้อมูลวันที่ :
              <strong>
                {data.DO.date}
              </strong>
              <br />
              สถานการณ์แอมโมเนีย NH3 :
              <strong>
                {data.NH3.criterion.text}
              </strong>
              <br />
              ข้อมูลวันที่ :
              <strong>
                {data.NH3.date}
              </strong>
              <br />
            </span>
          </Tooltip>
        </Hidden>
      </Marker>
    );
  };
  useEffect(() => {
  }, []);

  return (
    <>
      <Map
        id="map"
        center={settingMap.position}
        zoom={settingMap.zoom}
        ref={mapRef}
        style={{
          height: window.height,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1300,
        }}
      >
        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          id="OpenStreetMap.HOT"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
        />
        <LayersControl position="topleft">
          <Overlay key="RID" name="กรมชลประทาน" checked="true">
            <LayerGroup>
              {listStationRID.map(eachDataRID)}
            </LayerGroup>
          </Overlay>
          <Overlay key="PCD" name="กรมควบคุมมลพิษ" checked="false">
            <LayerGroup>
              {listStationPCD.map(eachDataPCD)}
            </LayerGroup>
          </Overlay>
        </LayersControl>
        <CurrentLocation mapRef={mapRef} showOnly={false} />
      </Map>
    </>
  );
}

ReportSalinityFullMap.propTypes = {
  listStationPCD: PropTypes.array.isRequired,
  listStationRID: PropTypes.array.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
};
