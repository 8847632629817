import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export default function WaterQualityLegend(props) {
  const { t } = props;
  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                fontSize: 'x-small',
                width: '5%',
              }}
            >
              <strong>
                {t('สี')}
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                fontSize: 'x-small',
                width: '1%',
              }}
            >
              <strong>
                Salinity
                <br />
                (g/L)
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                fontSize: 'x-small',
                width: '1%',
              }}
            >
              <strong>
                pH
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                fontSize: 'x-small',
                width: '1%',
              }}
            >
              <strong>
                Do
                <br />
                (mg/L)
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                width: '20%',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {t('คำอธิบาย')}
              </strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#b02318',
                color: '#FFFFFF',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {'> 2'}
              </strong>
            </TableCell>
            <TableCell style={{ textAlign: 'center', border: '0.1px solid', background: '#d9d9d9' }}>
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell style={{ textAlign: 'center', border: '0.1px solid', background: '#d9d9d9' }}>
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell style={{
              textAlign: 'left', border: '0.1px solid', width: '20%', fontSize: 'x-small',
            }}
            >
              <strong style={{ whiteSpace: 'pre-line' }}>{t('เกินเกณฑ์มาตรฐานน้ำใช้เพื่อการเกษตร')}</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#ea3323',
                color: '#FFFFFF',
                borderColor: 'black',
                fontSize: 'x-small',
                height: '30px',
              }}
            >
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {'> 0.5'}
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {'<5, >9'}
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {'< 2'}
              </strong>
            </TableCell>
            <TableCell style={{
              textAlign: 'left', border: '0.1px solid', width: '20%', fontSize: 'x-small',
            }}
            >
              <strong style={{ whiteSpace: 'pre-line' }}>{t('เกินเกณฑ์คุณภาพน้ำเพื่อการผลิตน้ำประปา')}</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#f6c143',
                color: '#FFFFFF',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {'> 0.25'}
              </strong>
            </TableCell>
            <TableCell style={{ textAlign: 'center', border: '0.1px solid', background: '#d9d9d9' }}>
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell style={{ textAlign: 'center', border: '0.1px solid', background: '#d9d9d9' }}>
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell style={{
              textAlign: 'left', border: '0.1px solid', width: '20%', fontSize: 'x-small',
            }}
            >
              <strong style={{ whiteSpace: 'pre-line' }}>{t('เกณฑ์เฝ้าระวังคุณภาพน้ำเพื่อการผลิตน้ำประปา')}</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#4eac5b',
                color: '#FFFFFF',
                borderColor: 'black',
                fontSize: 'x-small',
                height: '30px',
              }}
            >
              <strong>
                &nbsp;
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {'< 0.25'}
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                5 - 9
              </strong>
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                border: '0.1px solid',
                background: '#FFFFFF',
                color: '#000000',
                borderColor: 'black',
                fontSize: 'x-small',
              }}
            >
              <strong>
                {'> 2'}
              </strong>
            </TableCell>
            <TableCell style={{
              textAlign: 'left', border: '0.1px solid', width: '20%', fontSize: 'x-small',
            }}
            >
              <strong style={{ whiteSpace: 'pre-line' }}>{t('เกณฑ์ปกติ')}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

WaterQualityLegend.propTypes = {
  t: PropTypes.func.isRequired,
};
