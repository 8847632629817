/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

// ui
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
// import FormHelperText from '@material-ui/core/FormHelperText';

// lib
import moment from 'moment';

// style
import {
  styles, // BootstrapInput,
} from '../../styles/Style';
import './StormHistoryStyle.css';

const useStyles = makeStyles(styles);

// year range criteria
const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
const years = yearsRange(currentYear, 1950);
const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
  'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

export default function StormHistoryCriteriaJtwc(props) {
  const { handleActiveLayer, handleData, setSelectAll } = props;
  const { t } = useTranslation();
  const isMounted = React.useRef(false);
  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [enableTimeSearch, setEnableTimeSearch] = useState(false);
  const [state, setState] = useState({
    keywordStorm: '',
    startMonth: [],
    startYear: [moment().year().toString(), moment().subtract(1, 'year').year().toString()], // 2024, 2023
    basin: '',
    code: '',
    stormType: '',
    minWindSpeed: '',
    maxWindSpeed: '',
    attackThailand: false,
  });

  // fetch data
  const fetchData = async () => {
    if (state.startYear.length === 0) {
      handleData([]);
      return;
    }
    setLoading(true);
    fetch(`${process.env.MIX_API_URL}analyst/storm_history_v2?name=${state.keywordStorm.toUpperCase()}&month=${state.startMonth}&year=${state.startYear}&code=${state.code}&basin=${state.basin}&storm_type=${state.stormType}&attack_thailand=${state.attackThailand === false ? '' : true}&min_wind_speed=${state.minWindSpeed}&max_wind_speed=${state.maxWindSpeed}`)
      .then(response => response.json())
      .then(result => {
        if (!isMounted.current) {
          // console.log(result.data);

          let active = {};
          // result.data.map(d => {
          //   const pair = { [d.metadata.name]: true };
          //   active = { ...active, ...pair };

          //   return null;
          // });
          const dataActive = result.data.map(d => {
            const pair = { [d.metadata.name]: true };
            active = { ...active, ...pair };
            return {
              ...d,
              text: d.metadata.name,
              isChecked: true,
            };
          });
          // handleData(result.data);
          handleData(dataActive);
          setSelectAll(true)
          // handleActiveLayer(active);

          // hide loading
          setLoading(false);
        }
      })
      .catch(() => setErrors(true));
  };

  const handleChange = event => {
    const { name, value } = event.target;

    // search by keyword, disabled time criteria
    if (name === 'keywordStorm') {
      if (value.trim().length > 0) {
        setEnableTimeSearch(true);
      } else {
        setEnableTimeSearch(false);
      }
    }
    

    // limit 5 years
    if (name === 'startYear') {
      if (value.length === 0) {
        // alert(t('ต้องเลือกอย่างน้อย 1 ปี'));
        setState({
          ...state,
          [name]: value,
        });

      } else if (value.length <= 5) {
        setState({
          ...state,
          [name]: value,
        });
      } else if (value.length > 5) {
        // alert(t('เลือกได้สูงสุด 5 ปี'));
        setState({
          ...state,
          [name]: value,
        });
 
      }
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    fetchData();
  };

  // const handleCheckBox = event => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  useEffect(() => {
    fetchData();

    return () => {
      isMounted.current = true;
    };
  }, []);
  // console.log(data);

  return (
    <>
      {hasError && (
        <div>
          Error Occured
        </div>
      )}

      {isLoading && (
        <div className="Loading">
          <CircularProgress />
        </div>
      )}

      <form method="GET" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: 20 }} alignItems="flex-end">
          <Grid item xs={12} md={10}>
            <Grid container spacing={2} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="keywordStorm"
                  onChange={handleChange}
                  placeholder={t("ค้นหาพายุ")}
                  InputLabelProps={{
                    style: { fontSize: '0.8em' },
                  }}
                  InputProps={{
                    name: 'keywordStorm',
                    id: 'keywordStorm',
                    style: {
                      marginTop: '12px',
                      fontFamily: 'Kanit',
                      fontSize: 16,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl variant="outlined" className={classes.formControl} width="100%">
                  <InputLabel htmlFor="month" className={classes.textField}>{t('เดือน (แสดงทุกเดือน)')}</InputLabel>
                  <Select
                    disabled={enableTimeSearch}
                    multiple
                    value={state.startMonth}
                    onChange={handleChange}
                    input={<Input id="startMonth" />}
                    renderValue={selected => {
                      const displayMonth = selected.map(d => months[d - 1]);
                      return (displayMonth.join(', '));
                    }}
                    inputProps={{
                      name: 'startMonth',
                      id: 'startMonth',
                    }}
                  >
                    {months.map((m, i) => (
                      <MenuItem key={m} value={i + 1}>
                        <Checkbox checked={state.startMonth.indexOf(i + 1) > -1} />
                        <ListItemText primary={`${m}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="year">{t('ปี')}</InputLabel>
                    <Select
                      disabled={enableTimeSearch}
                      multiple
                      value={state.startYear}
                      onChange={handleChange}
                      input={<Input id="startYear" />}
                      renderValue={selected => selected.join(', ')}
                      inputProps={{
                        name: 'startYear',
                        id: 'startYear',
                      }}
                    >
                      {years.map(year => (
                        <MenuItem key={year} value={year}>
                          <Checkbox checked={state.startYear.indexOf(year) > -1} />
                          <ListItemText primary={`${year} (${parseInt(year, 10) + 543})`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 15 }} alignItems="flex-end">
              <Grid item xs={12} sm={4} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="age-native-label-placeholder">
                    All Basin
                  </InputLabel>
                  <NativeSelect
                    id="basin"
                    value={state.basin}
                    onChange={handleChange}
                    inputProps={{
                      name: 'basin',
                      id: 'basin',
                    }}
                  >
                    <option value="" style={{ fontFamily: 'Kanit' }}>{t('เลือก')}</option>
                    <option key="wp" value="WP" style={{ fontFamily: 'Kanit' }}>West Pacific</option>
                    <option key="io" value="IO" style={{ fontFamily: 'Kanit' }}>Indian Ocean</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="age-native-label-placeholder">
                    All Storm Type
                  </InputLabel>
                  <NativeSelect
                    id="basin"
                    value={state.stormType}
                    onChange={handleChange}
                    inputProps={{
                      name: 'stormType',
                      id: 'stormType',
                    }}
                  >
                    <option value="" style={{ fontFamily: 'Kanit' }}>{t('เลือก')}</option>
                    <option key="td" value="TD" style={{ fontFamily: 'Kanit' }}>Tropical Depression (&lt;= 34 Knots)</option>
                    <option key="ts" value="TS" style={{ fontFamily: 'Kanit' }}>Tropical Storm (&lt;= 63 Knots)</option>
                    <option key="cat1" value="Cat 1" style={{ fontFamily: 'Kanit' }}>Storm Cat 1 (&lt;= 82 Knots)</option>
                    <option key="cat2" value="Cat 2" style={{ fontFamily: 'Kanit' }}>Storm Cat 2 (&lt;= 95 Knots)</option>
                    <option key="cat3" value="Cat 3" style={{ fontFamily: 'Kanit' }}>Storm Cat 3 (&lt;= 113 Knots)</option>
                    <option key="cat4" value="Cat 4" style={{ fontFamily: 'Kanit' }}>Storm Cat 4 (&lt;= 135 Knots)</option>
                    <option key="cat5" value="Cat 5" style={{ fontFamily: 'Kanit' }}>Storm Cat 5  (&gt; 135 Knots)</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <TextField
                  id="minWindSpeed"
                  name="minWindSpeed"
                  label="Min Wind Speed (Knot)"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{
                    style: { fontSize: '0.8em' },
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <TextField
                  id="maxWindSpeed"
                  name="maxWindSpeed"
                  label="Max Wind Speed (Knot)"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{
                    style: { fontSize: '0.8em' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {/* <FormControlLabel
                  control={(
                    <Checkbox
                      id="attackThailand"
                      checked={state.attackThailand}
                      onChange={handleCheckBox}
                      name="attackThailand"
                    />
                  )}
                  label={
                    <Box component="div" fontSize={12} fontWeight={500}>
                      Arrive Thailand
                    </Box>
                  }
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ width: '100%', fontFamily: 'Kanit' }}
            >
              <Typography variant="h6">{t('แสดงผล')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

StormHistoryCriteriaJtwc.propTypes = {
  handleActiveLayer: PropTypes.func.isRequired,
  handleData: PropTypes.func.isRequired,
  setSelectAll: PropTypes.func.isRequired,
};
