/**
 * *
 * @version 1.0.0
 * @author [Manorot]
 * Modify by Thitiporn
 * Modify by Permporn
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  uniqueId,
} from 'lodash/fp';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
} from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// ui
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import grey from '@material-ui/core/colors/grey';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import { FaHome, FaSpinner, FaPlay } from '../../../utils/Fontawesome';

import {
  styles,
} from '../../../styles/Style';

import TroughAnimation from './TroughAnimation';
import TroughLatest from './TroughLatest';

const initPath = process.env.MIX_APP_PATH;

let images = [];

const currentDate = new Date(moment());
class TroughHistory extends Component {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      datas: [],
      groupDatas: [],
      groupDatasThrough: [],
      displayState: 'history',
      dateToDay: moment().format('YYYY-MM-DD'),
      chkBtndisabledRight: false,
      chkBtndisabledLeft: false,
    };
  }

  componentDidMount() {
    // latest data available (minus 1 days from current date)
    const startDate = new Date(moment().subtract(1, 'day'));

    this.setState({
      isLoading: true,
      startDate,
    });

    this.fetchDataTrough(moment(startDate).format('YYYY-MM-DD'));
  }

    fetchDataTrough = date => {
      const { dateToDay } = this.state;
      const s = date.split('-');
      const stringDate1 = `${s[0]}/${s[1]}/${s[2]}`;
      const stringDate2 = s[0] + s[1] + s[2];
      const liveUrl = 'https://live1.hii.or.th/product/history/wrfroms/v1/domain01';
      // const Ex1 = [`${liveUrl}/${stringDate1}/07/trough/trough_init${stringDate2}_00.png`];
      let Ex1;
      let text = '00';
      const groupDate = [];
      const arr1 = [];
      for (let i = 0; i < 4; i += 1) {
        Ex1 = `${liveUrl}/${stringDate1}/07/trough/trough_init${stringDate2}_0${i}.png`;
        switch (i) {
          case 0: text = '00'; break;
          case 1: text = '06'; break;
          case 2: text = '12'; break;
          case 3: text = '18'; break;
          default: text = '00';
        }
        const obj = {
          image: Ex1,
          timeImg: `${text}:00`,
        };
        groupDate.push(obj);
      }
      const objGroup = {
        datas: groupDate,
        date,
      };
      arr1.push(objGroup);

      let datediffR = false;
      let datediffL = false;
      if (date >= dateToDay) {
        datediffR = true;
      }
      if (date <= '2022-11-07') {
        datediffL = true;
      }
      this.setState({
        groupDatasThrough: arr1,
        isLoading: false,
        chkBtndisabledRight: datediffR,
        chkBtndisabledLeft: datediffL,
      });
    }

    handleChange = date => {
      this.setState({
        startDate: date,
        isLoading: true,
      });

      this.fetchDataTrough(moment(date).format('YYYY-MM-DD'));
    };

    handleDateChangeButton = change => {
      this.setState({
        isLoading: true,
      });

      const { startDate } = this.state;
      let selectedDate = startDate;

      if (change === '1') {
        selectedDate = moment(selectedDate).add(1, 'days');
      } else if (change === '-1') {
        selectedDate = moment(selectedDate).add(-1, 'days');
      }

      this.setState({
        startDate: selectedDate,
      });

      this.fetchDataTrough(moment(selectedDate).format('YYYY-MM-DD'));
    }

   onErrorImg = e => {
     e.target.src = 'https://via.placeholder.com/180x126.png?text=No+Image';
   }

   renderHistory(startDate, classes, datas, groupDatas, currentIndex, groupDatasThrough) {
     const {
       isLoading, chkBtndisabledRight, chkBtndisabledLeft,
     } = this.state;
     const { t, i18n } = this.props;
     const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;
     return (
       <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 15, marginBottom: 0 }}>
         <Grid item><Button onClick={() => this.handleDateChangeButton('-1')} disabled={chkBtndisabledLeft}><KeyboardArrowLeftIcon /></Button></Grid>
         <Grid item xs sm={3} md={3} lg={3}>
           <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
             <KeyboardDatePicker
               format="LL"
               views={['year', 'month', 'date']}
               value={startDate}
               onChange={this.handleChange}
               style={{ fontSize: 14 }}
               minDate="2022-11-07" // วันที่แสดงภาพวันแรกของ api
               maxDate={currentDate}
             />
           </MuiPickersUtilsProvider>
         </Grid>
         <Grid item><Button onClick={() => this.handleDateChangeButton('1')} disabled={chkBtndisabledRight}><KeyboardArrowRightIcon /></Button></Grid>

         {isLoading === true && (
           <FaSpinner size={60} />
         )}

         {groupDatasThrough.length <= 0
           && (
             <Grid container spacing={4}>
               <Grid item xs>
                 <Typography variant="h6">
                   {t('ไม่พบข้อมูลในช่วงเวลาที่เลือก')}
                 </Typography>
               </Grid>
             </Grid>
           )}

         {
           groupDatasThrough.map(item => (
             <Grid container spacing={4} key={uniqueId()}>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                 <Box p={1} mt={2} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                   {moment(item.date).locale(languageI18n).format('LL')}
                 </Box>
               </Grid>
               {
                   item.datas.map(row => {
                     const currentImgIndex = currentIndex;
                     currentIndex += 1;
                     if (row.image) {
                       images.push({
                         src: `${row.image}`,
                         title: `${t('วันที่')} ${moment(item.date).locale(languageI18n).format('LL')} : ${row.timeImg}  ${t('น.')}`,
                       });
                     }
                     return (
                       <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                         <Box className={classes.boxWeatherHistory} display="block" align="center">
                           <Box display="block" align="center">
                             <Button
                               onClick={() => this.setState({
                                 isOpen: true,
                                 photoIndex: currentImgIndex,
                               })}
                             >
                               <img
                                 src={row.image}
                                 width="180"
                                 height="126"
                                 className={classes.responsive}
                                 onError={this.onErrorImg}
                                 alt={`pic-${currentIndex}`}
                               />
                             </Button>
                           </Box>
                           <Box p={1} display="flex" alignItems="center" justifyContent="center">
                             <Typography className={classes.dateTime}>
                               {`${t('เวลา')} :`}
                               {' '}
                               {row.timeImg}
                               {' '}
                               {t('น.')}
                             </Typography>
                           </Box>
                         </Box>
                       </Grid>
                     );
                   })
                 }
             </Grid>
           ))
         }
       </Grid>
     );
   }

   render() {
     images = [];
     const { classes, t } = this.props;
     const {
       isLoading,
       error,
       datas,
       groupDatas,
       photoIndex,
       isOpen,
       startDate,
       displayState,
       groupDatasThrough,
     } = this.state;
     const currentIndex = 0;
     return (
       <div>
         <div className={classes.layout}>
           <Grid container spacing={4}>
             <Grid item xs>
               {isLoading === true && (
               <FaSpinner size={25} />
               )}

               {error && (
               <div className="text-center">{error.message}</div>
               )}
             </Grid>
           </Grid>

           <Grid container spacing={4} style={{ marginTop: 10 }}>
             <Grid item>
               <Breadcrumbs aria-label="breadcrumb">
                 <Link to={`${initPath}weather`}>
                   <FaHome className={classes.iconSub} />
                   <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                     {' '}
                     {t('ติดตามสภาพอากาศ')}
                   </Typography>
                 </Link>
                 <Link to={`${initPath}weather`}>
                   <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                     {t('อากาศ')}
                   </Typography>
                 </Link>
                 <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                   {t('แผนที่อากาศ ระดับน้ำทะเล และร่องความกดอากาศต่ำ')}
                 </Typography>
               </Breadcrumbs>
             </Grid>
           </Grid>

           <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
             <Grid item xs={12} md={6}>
               <Box m={1} className={classes.titleName}>
                 {t('แผนที่อากาศ ระดับน้ำทะเล และร่องความกดอากาศต่ำ')}
               </Box>
             </Grid>
             <Grid item xs>
               <Tooltip title={t('ดูภาพล่าสุด')}>
                 <Button
                   className={classes.outlinedLeftPrimary}
                   color={displayState === 'latest' ? 'primary' : 'default'}
                   variant={displayState === 'latest' ? 'contained' : 'text'}
                   onClick={() => this.setState({ displayState: 'latest' })}
                 >
                   <ImageOutlinedIcon />
                   <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูภาพล่าสุด')}</Typography></Hidden>
                 </Button>
               </Tooltip>
             </Grid>
             <Grid item xs>
               <Tooltip title={t('ดูภาพย้อนหลัง')}>
                 <Button
                   className={classes.outlinedPrimary}
                   color={displayState === 'history' ? 'primary' : 'default'}
                   variant={displayState === 'history' ? 'contained' : 'text'}
                   onClick={() => this.setState({ displayState: 'history' })}
                 >
                   <PhotoLibraryOutlinedIcon />
                   <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูภาพย้อนหลัง')}</Typography></Hidden>
                 </Button>
               </Tooltip>
             </Grid>
             <Grid item xs>
               <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
                 <Button
                   className={classes.outlinedPrimary}
                   color={displayState === 'animation' ? 'primary' : 'default'}
                   variant={displayState === 'animation' ? 'contained' : 'text'}
                   onClick={() => this.setState({ displayState: 'animation' })}
                 >
                   <FaPlay />
                   <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูภาพเคลื่อนไหว')}</Typography></Hidden>
                 </Button>
               </Tooltip>
             </Grid>
           </Grid>

           {displayState === 'latest' && (
           <TroughLatest />
           )}
           {displayState === 'animation' && (
           <TroughAnimation />
           )}

           {displayState === 'history' && (
             this.renderHistory(startDate, classes, datas, groupDatas, currentIndex, groupDatasThrough)
           )}
         </div>
         {isOpen && (
         <Lightbox
           mainSrc={images[photoIndex].src}
           nextSrc={images[(photoIndex + 1) % images.length].src}
           prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
           imageTitle={images[photoIndex].title}
           imagePadding={50}
           reactModalStyle={{ overlay: { zIndex: '1000000' } }}
           onCloseRequest={() => this.setState({ isOpen: false })}
           onMovePrevRequest={() => (
             this.setState({
               photoIndex: (photoIndex + images.length - 1) % images.length,
             }))}
           onMoveNextRequest={() => (
             this.setState({
               photoIndex: (photoIndex + 1) % images.length,
             }))}
         />
         )}
       </div>
     );
   }
}

TroughHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TroughHistory);
