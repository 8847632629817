import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

// component
import ForecastWaterLevel from './ForecastSeaLevel/ForecastWaterLevel';
// import ForecastWave from './ForecastWave/ForecastWave';

// style
import { StyleComp } from './style';

const useStyles = makeStyles(StyleComp);

function TabPanel(props) {
  const {
    children,
    value,
    index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
const TabContainer = ({ children }) => (
  <Box p={{ xs: 0, sm: 2 }} component="div" boxShadow={1} style={{ backgroundColor: '#fff' }}>
    {children}
  </Box>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Forecast() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.contentStorm}>
      {/* <HeaderStorm /> */}
      <Box py={13} className={classes.layout}>
        <Box>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto" aria-label="rainforecasttab">
            <Tab
              {...a11yProps(0)}
              className={value === 0 ? classes.active_tab : classes.tab}
              label={(
                <>
                  <Box ml={1} className={classes.titleTabs}>แผนที่คาดการณ์ระดับน้ำทะเล</Box>
                </>
              )}
            />
            {/* <Tab
              {...a11yProps(1)}
              className={value === 1 ? classes.active_tab : classes.tab}
              label={(
                <>
                  <Box ml={1} className={classes.titleTabs}>{'แผนที่ความสูงและทิศทางคลื่น'}</Box>
                </>
              )}
            /> */}
          </Tabs>
          <Divider />
          <TabContainer component="div">
            <TabPanel value={value} index={0} className={classes.content}>
              <ForecastWaterLevel />
            </TabPanel>
            {/* <TabPanel value={value} index={1} className={classes.content}>
              <ForecastWave />
            </TabPanel> */}
          </TabContainer>
        </Box>
      </Box>
    </div>
  );
}
