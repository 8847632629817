import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from "@material-ui/core/Box";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './Style.css';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import { useModal } from '../../contexts/ModalContext';
import { useLocation } from 'react-router-dom';
export default function ModalComp(props) {
  const [scroll] = React.useState('paper');
  const {
    open,
    size,
    modalHeader,
    modalSubHeader,
    modalContent,
    resetModal,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { modalSize, handleClickSize, isFullScreen, handleClickFullScreen } = useModal();
  const location = useLocation();
  const currentPath =  location.pathname;
  const newPath = currentPath.replace('/thaiwater_v4', '');
  
  const showFullscreen = ['/water/wl/rid', '/water/dam/large','/water'] // Object with route parameters
  const isShowIconFullScreen = showFullscreen.some(path => newPath.includes(path));

  useEffect(() => {
    setIsOpen(open);
  }, [open, props]);

  function handleModalClose() {
    setIsOpen(false);
    handleClickFullScreen(false);
    resetModal();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleModalClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      // maxWidth={size}
      fullScreen={isFullScreen}
      maxWidth={modalSize || size}
      id="modal-dialog"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"

          spacing={0}
        >
          <Grid item xs md>
            {modalHeader}
          </Grid>
          <Grid item xs md="auto">
            {isShowIconFullScreen ? (
              <Box display={"flex"} alignItems="center">
                  {!isFullScreen && (
                    modalSize !== 'xl' ? (
                      <FullscreenIcon onClick={() => handleClickSize('xl')} />
                    ) : (
                      <FullscreenExitIcon onClick={() => handleClickSize('md')} />
                    )
                  )}
                  {isFullScreen ? (
                    <FullscreenExitIcon onClick={() => handleClickFullScreen(false)} />
                  ) : (
                    <CropSquareIcon onClick={() => handleClickFullScreen(true)} />
                  )}
                </Box>)
              : null}
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
              <CloseIcon id="modal-close-button" fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <Typography variant="subtitle1" id="modal-subheader">
          {modalSubHeader}
        </Typography>
        {modalContent}
      </DialogContent>
    </Dialog>
  );
}

ModalComp.defaultProps = {
  open: false,
  size: 'md',
  modalHeader: '',
  modalSubHeader: '',
  modalContent: '',
  resetModal: () => { },
};

ModalComp.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  modalHeader: PropTypes.node,
  modalSubHeader: PropTypes.string,
  modalContent: PropTypes.node,
  resetModal: PropTypes.func,
};
