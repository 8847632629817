/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@material-ui/core';

const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: '100%',
    padding: 10,
  },
  label: {
    fontSize: '0.9em',
    fontWeight: 300,
    fontFamily: 'sans-serif',
  },
  icon: {
    fontSize: 25,
    marginRight: 5,
    padding: 2,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  img: {
    width: 10,
    height: 10,
    marginRight: 7,
  },
});

const CompForecastSelect = props => {
  const classes = useStyles();

  const {
    handleMapChecked,
    waterLevelMapChecked,
    // floodStationMapChecked,
    // waterFlowMapChecked,
    // rainStationMapChecked,
  } = props;

  const handleChange = event => {
    handleMapChecked(event.target.name, event.target.checked);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormGroup aria-label="water" name="water">
          <FormControlLabel value="1" name="waterLevelMapChecked" control={<Checkbox checked={waterLevelMapChecked} style={{ color: waterLevelMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box className={classes.label}><img src={`${initPath}images/theme/floodplain/Waterlevel_M.png`} className={classes.img} alt="floodplain" />  ระดับน้ำ (ม.รทก.)</Box>} />
          <FormControlLabel value="2" name="waterLevelMapChecked" control={<Checkbox checked={waterLevelMapChecked} style={{ color: waterLevelMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box className={classes.label}><img src={`${initPath}images/theme/floodplain/Waterlevel_CM.png`} className={classes.img} alt="floodplain" />  ปริมาณน้ำท่า (ลบ.ม./วินาที)</Box>} />
          <FormControlLabel value="3" name="waterLevelMapChecked" control={<Checkbox checked={waterLevelMapChecked} style={{ color: waterLevelMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box className={classes.label}><Box style={{ marginLeft: 20 }}>คาดการณ์พื้นที่น้ำท่วมสูงสุด (ลุ่มเจ้าพระยา)</Box></Box>} />
          <FormControlLabel value="4" name="waterLevelMapChecked" control={<Checkbox checked={waterLevelMapChecked} style={{ color: waterLevelMapChecked ? '#3980c0' : '#ccc' }} onChange={handleChange} />} label={<Box className={classes.label}><Box style={{ marginLeft: 20 }}>คาดการณ์พื้นที่น้ำท่วมสูงสุด (ลุ่มภาคอีสาน)</Box></Box>} />
        </FormGroup>
      </FormControl>
    </div>
  );
};

CompForecastSelect.propTypes = {
  handleMapChecked: PropTypes.func.isRequired,
  waterLevelMapChecked: PropTypes.bool.isRequired
};

export default CompForecastSelect;
