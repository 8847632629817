/* มี 2 type
**type_cgi สำหรับ  URL ที่เป็นประเภท Streamming หรือวิดีโอ
**type_any สำหรับ URL ทั่วไป
******
***การเรียใช้ ให้สร้าง async function มารับ return โดยรับเป็น await เพื่อดึงค่า true/false ออกมา
*/
const initPath = process.env.MIX_APP_PATH;

export default async function urlCheck(urlpath, typeurl, timeout) {
  if (typeurl === 'type_cgi') {
    try {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error(''));
        }, timeout);
      });
      const response = await Promise.race([fetch(`${initPath}proxy/cgi.php?file=${urlpath}`), timeoutPromise]);
      if (!response.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return true;
    }
  }

  if (typeurl === 'type_any') {
    try {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error('Time out'));
        }, timeout);
      });
      const response = await Promise.race([fetch(`${initPath}proxy/png.php?file=${urlpath}`), timeoutPromise]);
      if (!response.ok) {
        return false;
      }
      return true;
    } catch (error) {
      console.error('Fecth Error is : ', error);
    }
  }

  return null;
}
