import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

// lodash
import {
  flow, uniqueId, groupBy, orderBy,
} from 'lodash/fp';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

// material-ui
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import grey from '@material-ui/core/colors/grey';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';

// icon
import {
  FaHome, FaSpinner,
} from '../../../utils/Fontawesome';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

// lightbox
let images = [];
const width = '100%';
const height = '100%';
const initPath = process.env.MIX_APP_PATH;
const mediaType = [];
mediaType[14] = { index: 0, title: 'แผนภาพการเปลี่ยนแปลงของอุณหภูมิผิวน้ำทะเล ราย 2 สัปดาห์', thumbSize: { w: 173, h: 128 } };

const currentDate = new Date(moment());
class SstHiiHistory extends Component {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      groupDatas: [],
      latestImage: [],
      displayState: 'history',
      photoIndex: 0,
      isOpen: false,
      isOpenLatestImage: false,
      mediaTypeId: 14,
      dateNow: moment(moment().format('YYYY-MM-DD')),
    };
  }

  componentDidMount() {
    const startDate = new Date(moment().subtract(33, 'day'));
    this.setState({
      isLoading: true,
      startDate,
    });
    this.fetchData(startDate);
  }

  fetchData = date => {
    Promise.all([
      fetch(`${process.env.MIX_API_URL}public/weather_history_img/year?year=${moment(date).format('YYYY')}&agency_id=9&media_type_id=14`),
      fetch(`${process.env.MIX_API_URL}public/weather_img/sst_2w_haii`),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([res1, res2]) => {
        const groupData = flow(
          orderBy(['media_datetime'], ['desc']),
          groupBy(d => moment(d.media_datetime).format('YYYY-MM')),
        )(res1.data);

        const dataArray = Object.entries(groupData)
          .map(([key, val]) => ({ date: key, datas: val }));

        // filter limit current month
        const monthNow = moment(moment().format('YYYY-MM'));
        const dataArrayFilter = dataArray.filter(d => {
          const monthEnd = moment(d.date);
          const duration = moment.duration(monthNow.diff(monthEnd));
          return duration.asDays() >= 0;
        });

        console.log('groupDataFilter : ', dataArrayFilter);

        this.setState({
          isLoading: false,
          groupDatas: dataArrayFilter,
          latestImage: [res2.data],
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    });

    this.fetchData(date);
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate;
    if (change === '1') {
      selectedDate = moment(this.state.startDate).add(1, 'years');
    } else if (change === '-1') {
      selectedDate = moment(this.state.startDate).add(-1, 'years');
    }
    this.setState({
      startDate: selectedDate,
    });
    this.fetchData(selectedDate);
  }

  fillterDataByCurrentDate = date => {
    const now = moment(moment().format('YYYY-MM-DD'));
    const end = moment(date);
    const duration = moment.duration(now.diff(end));
    return duration.asDays();
  }

  renderHistory(startDate, groupDatas, thumbWidth, thumbHeight, classes) {
    let currentIndex = 0;
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 24 }}>
          <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={4} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  openTo="year"
                  views={['year']}
                  value={startDate}
                  onChange={this.handleChange}
                  style={{ fontSize: 14 }}
                  maxDate={currentDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>

        {groupDatas.map(item => (
          <Grid container spacing={4} key={uniqueId()} style={{ marginTop: 16 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box p={1} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                {moment(item.date).format('MMMM YYYY')}
              </Box>
            </Grid>
            {item
              .datas
              .filter(row => (moment(row.media_datetime).format('YYYY-MM') === item.date && this.fillterDataByCurrentDate(moment(row.media_datetime).format('YYYY-MM-DD')) > 0))
              .map(row => {
                let realImage = '';
                const currentImgIndex = currentIndex;

                const showImg = (row.media_path_thumb !== null)
                  ? row.thumbnail_media_path : row.media_path;

                const timeShow = (row.filename !== '') ? `${moment(row.filename.replace('diff_', '').split('_')[0]).format('D MMM')} to ${moment(row.filename.replace('diff_', '').replace('.png', '').split('_')[1]).format('D MMM')}` : moment(row.media_datetime).format('LL');

                if (row.media_path !== '') {
                  images.push({
                    src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${row.media_path}`,
                    title: timeShow,
                  });

                  realImage = (
                    <Button
                      key={uniqueId()}
                      onClick={() => this.setState({
                        isOpen: true,
                        photoIndex: currentImgIndex,
                      })}
                    >
                      <Img
                        src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`}
                        width={width}
                        height={height}
                        alt={timeShow}
                        className={classes.responsive}
                        key={uniqueId()}
                        unloader={(
                          <Img
                            src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt="No Image"
                            className={classes.responsive}
                          />
                        )}
                      />
                    </Button>
                  );
                  currentIndex += 1;
                } else {
                  realImage = (
                    <Img
                      src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                      alt={timeShow}
                      className={classes.responsive}
                    />
                  );
                }

                return (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                    <Box display="flex" alignItems="center" justifyContent="center" className={classes.boxWeatherHistory}>
                      <Box display="block">
                        <Box display="block" align="center">
                          {realImage}
                        </Box>
                        <Box p={1} display="flex" alignItems="center" justifyContent="center">
                          <Typography className={classes.dateTime}>
                            <Box p={1} pb={0} align="center" fontFamily="Kanit" fontWeight="fontWeightMedium">
                              {timeShow}
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        ))}
      </>
    );
  }

  render() {
    images = [];
    const { classes, t } = this.props;
    let latestImg = {};
    const {
      isLoading,
      error,
      groupDatas,
      photoIndex,
      isOpen,
      startDate,
      displayState,
      mediaTypeId,
      isOpenLatestImage,
      latestImage,
    } = this.state;

    const { w: thumbWidth, h: thumbHeight } = mediaType[mediaTypeId].thumbSize;

    if (latestImage.length > 0) {
      const { filename } = latestImage[mediaType[mediaTypeId].index].cover_image;
      latestImg = {
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${latestImage[mediaType[mediaTypeId].index].cover_image.media_path}`,
        datetime: `${moment(filename.replace('diff_', '').split('_')[0]).format('D MMM')} to ${moment(filename.replace('diff_', '').replace('.png', '').split('_')[1]).format('D MMM')}`,
      };
    }

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {isLoading === true && (
                <FaSpinner size={25} />
              )}

              {error && (
                <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/sea`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('ทะเล')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t(mediaType[mediaTypeId].title)}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.boxTopRadius}
            style={{ marginTop: 41, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={12} md lg>
              <Box m={1} className={classes.titleName}>
                {t(mediaType[mediaTypeId].title)}
              </Box>
            </Grid>
            <Grid item xs md={2} lg={2}>
              <Tooltip title={t('ดูภาพล่าสุด')}>
                <Button
                  className={classes.outlinedLeftPrimary}
                  color={displayState === 'latest' ? 'primary' : 'default'}
                  variant={displayState === 'latest' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'latest' })}
                >
                  <ImageOutlinedIcon fontSize="small" />
                  <Hidden xsDown>
                    <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>
                      {t('ดูภาพล่าสุด')}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs md={2} lg={2}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <Button
                  className={classes.outlinedRightPrimary}
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'history' })}
                >
                  <PhotoLibraryOutlinedIcon fontSize="small" />
                  <Hidden xsDown>
                    <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>
                      {t('ดูภาพย้อนหลัง')}
                    </Typography>
                  </Hidden>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          {displayState === 'history'
            && this.renderHistory(startDate, groupDatas, thumbWidth, thumbHeight, classes)}

          {displayState === 'latest' && (
            <Grid container direction="row" spacing={4}>
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()} justify="center">
                <Box mt={2} className={classes.boxWeatherSstHistory}>
                  <Button onClick={() => this.setState({ isOpenLatestImage: true })}>
                    <Box display="block" align="center">
                      <Img
                        src={latestImg.src}
                        className={classes.responsive}
                        alt={latestImg.datetime}
                        key={uniqueId()}
                        width={width}
                        height={height}
                        unloader={(
                          <Img
                            src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt="No Image"
                            className={classes.responsive}
                          />
                        )}
                      />
                    </Box>
                  </Button>
                  <Box p={1} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      {latestImg.datetime}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </div>

        {isOpenLatestImage && (
          <Lightbox
            mainSrc={latestImg.src}
            imageTitle={latestImg.datetime}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpenLatestImage: false })}
          />
        )}

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

SstHiiHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(SstHiiHistory));
