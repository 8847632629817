/**
 * *
 * @version 1.0.0
 * @author [Peerapong Srisom]
 */

/**
#### Code Example ####
  <AnimationImage
    images={[
      'https://api-v3.thaiwater.net/api/v1/thaiwater30//shared/image?_csrf=&image=AAECAwQFBgcICQoLDA0ODz2sq-vcpj7lylQ7-UPJH96ww2dizhvnn2G1DlIrhCEmSpglaJfOqnI387zW1A==',
      'https://api-v3.thaiwater.net/api/v1/thaiwater30//shared/image?_csrf=&image=AAECAwQFBgcICQoLDA0ODz2sq-vcpj7lylQ7-UPJH96ww2dizhvnn2G1DlIrhCEmSpglaJfOqnAz87zW1A==',
      'https://api-v3.thaiwater.net/api/v1/thaiwater30//shared/image?_csrf=&image=AAECAwQFBgcICQoLDA0ODz2sq-vcpj7lylQ7-UPJH96ww2dizhvnn2G1DlIrhCEmSpglaJfOqnU187zW1A==',
      'https://api-v3.thaiwater.net/api/v1/thaiwater30//shared/image?_csrf=&image=AAECAwQFBgcICQoLDA0ODz2sq-vcpj7lylQ7-UPJH96ww2dizhvnn2G1DlIrhCEmSpglaJfOqnsx87zW1A==',
    ]}
    width={500}
  />
#### Code Example ####
*** */

import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import Box from '@material-ui/core/Box';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const AnimationImage = props => {
  const { images, imagesWidth } = props;

  const zoomInProperties = {
    infinite: true,
    duration: 500,
    scale: 1,
    transitionDuration: 1,
    pauseOnHover: false,
    arrows: false,
  };
  return (
    <>
      {images.length === 0 && (
      <Box style={{ width: '100%', textAlign: 'center' }}>
        ไม่มีภาพเคลื่อนไหว
      </Box>
      )}
      <Zoom {...zoomInProperties}>
        {images.map(each => (
          <Box key={uniqueId()} style={{ width: '100%', textAlign: 'center' }}>
            <img key={uniqueId()} style={{ objectFit: '', width: '100%' }} src={each} alt="" />
          </Box>
        ))}
      </Zoom>
    </>
  );
};

AnimationImage.propTypes = {
  images: PropTypes.array.isRequired,
  imagesWidth: PropTypes.number.isRequired,
};

export default AnimationImage;
