/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { FaSpinner } from '../../utils/Fontawesome';

// translate
import tJson from '../../utils/TJson';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

class WeatherChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      max: 0,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      provinceId: props.provinceId,
      teleStation: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getProvince();
    this.getStation();
    this.renderChart();
  }

  getChartOptions() {
    const { t } = this.props;
    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: false,
      },
      annotations: [
        {
          labels: [
            {
              point: { x: 5, y: 5 },
              useHTML: true,
              text: `&#x25B2; ${t('จุดที่มีอุณหภูมิสูงสุด/ต่ำสุด')}`,
            },
          ],
        },
      ],
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: [{
        // Primary yAxis
        opposite: false,
        title: {
          text: t('อุณหภูมิ (°C)'),
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: '{value:.2f}',
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: t('ความชื้นสัมพัทธ์'),
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          format: '{value} %RH',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      { // third yAxis
        gridLineWidth: 0,
        title: {
          text: t('ความกดอากาศ'),
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        labels: {
          format: '{value} hPa',
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        opposite: true,
      },
      ],
      legend: {
        enabled: false,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      series: [
        {
          name: t('อุณหภูมิ'),
          type: 'area',
          lineWidth: 0,
          fillOpacity: 0.2,
          yAxis: 0,
          data: [],
          marker: {
            enabled: false,
          },
          tooltip: {
            valueSuffix: ' °C',
          },
        },
        {
          name: t('ความชื้นสัมพัทธ์'),
          type: 'spline',
          yAxis: 1,
          data: [],
          marker: {
            enabled: false,
          },
          tooltip: {
            valueSuffix: ' %RH',
          },
        },
        {
          name: t('ความกดอากาศ'),
          type: 'spline',
          yAxis: 2,
          data: [],
          marker: {
            enabled: false,
          },
          tooltip: {
            valueSuffix: ' hPa',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getProvince() {
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataProvince = result.province.data.sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));
        this.setState({
          dataProvince,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getStation() {
    const { provinceId } = this.state;
    fetch(`${process.env.MIX_API_URL}frontend/shared/station_all?province_code=${provinceId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const teleStation = result.data;

        this.setState({
          teleStation,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value }, () => {
      this.getStation();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  renderChart = () => {
    const { id } = this.state;

    fetch(
      `${process.env
        .MIX_API_URL}public/weather_graph?station_id=${id}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const dataSeries = [];
        const dataHumid = [];
        const dataPressure = [];
        let ts = null;
        let val = null;

        const temperature = result.data.temperature.data.graph_data;
        const humid = result.data.humid.data.graph_data;
        const pressure = result.data.pressure.data.graph_data;
        // find max, min
        const max = maxBy(temperature, 'value') === undefined ? 0 : maxBy(temperature, 'value').value;
        this.setState({
          max,
        });

        temperature.map(n => {
          val = parseFloat(n.value);
          ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
          dataSeries.push([ts, val]);

          return dataSeries;
        });

        humid.map(n => {
          val = parseFloat(n.value);
          ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
          dataHumid.push([ts, val]);
          return dataHumid;
        });

        pressure.map(n => {
          val = parseFloat(n.value);
          ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
          dataPressure.push([ts, val]);
          return dataPressure;
        });

        // set chart options
        const chartOptions = this.getChartOptions();
        chartOptions.series[0].data = dataSeries;
        chartOptions.series[1].data = dataHumid;
        chartOptions.series[2].data = dataPressure;

        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      dataProvince, id, max, provinceId, teleStation,
    } = this.state;
    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
            {error}
          </Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    let chart = '';
    let info = '';
    if (max === 0) {
      chart = <Paper className={classes.paper}>{t('ไม่มีข้อมูลในช่วงเวลาที่ท่านเลือก')}</Paper>;
      info = '';
    } else {
      chart = <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {'*'}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    }

    return (
      <Fragment key="key">
        {errorMsg}
        <Grid container spacing={4}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select value={provinceId} onChange={this.handleProvinceChange}>
                {dataProvince.map(row => (
                  <MenuItem key={row.province_code} value={row.province_code}>
                    {`${tJson(row.province_name, 'จ.')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <Select value={id} onChange={this.handleStationChange}>
                {teleStation.map(row => (
                  <MenuItem key={row.station_id} value={row.station_id}>
                    {`${tJson(row.station_name, 'สถานี')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {chart}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

WeatherChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(WeatherChart);
