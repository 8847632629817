/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import {
  orderBy, uniqueId,
} from 'lodash';
// import Papa from 'papaparse';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import { CircularProgressbar } from 'react-circular-progressbar';
import Paper from '@material-ui/core/Paper';
// map
import L from 'leaflet';
import {
  Map, Marker, Popup, TileLayer, WMSTileLayer,
  Tooltip, LayerGroup, LayersControl, GeoJSON, ImageOverlay,
} from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';
import Control from 'react-leaflet-control';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
// color
import { FaChartLine } from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';
import tJson from '../../utils/TJson';
// import { PlottyGeotiffLayer } from '../widgets/map/GeotiffLayer';
import WaterGateChart from '../water_gate/WaterGateChart';

import { styles } from '../../styles/Style';
// import iconRect from '../widgets/map/iconRect';
import iconCircle from '../widgets/map/iconCircle';
import { HiiRainIcon } from '../../utils/Icon';
import RainfallContext from '../../contexts/rainfallContext';
import RainfallStatusColor from '../rainfall/RainfallStatusColor';
import RainfallChart from '../rainfall/RainfallChart';

// color
import WlStatusColorDB from '../wl/WlStatusColorDB';
import WlStatusColor from '../wl/WlStatusColor';
import WlContext from '../../contexts/wlContext';
import WlChart from '../wl/WlChart';
import RainLegend from './legend/RainLegend';
import WlLegend from './legend/WlLegend';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import '../../../../public/css/Geolocate.css';
import CurrentLocation from '../currentlocation/CurrentLocation';

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;
const { BaseLayer, Overlay } = LayersControl;

// const geotiffOptions = {
//   band: 0,
//   name: 'Rainfall Accumulation',
//   displayMin: 0,
//   displayMax: 400,
//   colorScale: 'rainfallAccumulationScale',
//   clampLow: false,
//   clampHigh: true,
// };

class WaterlevelMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMapWL = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.refBoundaryBasinJson = createRef();
    this.waterLevelLayer = createRef();
    this.watergateLayer = createRef();
    this.rainfallTodayLayer = createRef();

    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    this.state = {
      error: '',
      height: this.setHeightMap(),
      radarType: '24hrs',
      hover: false,
      openLegend1: false,
      openLegend2: false,
    };

    // this.onEachBasinFeature = this.onEachBasinFeature.bind(this);
    this.handleBoxToggle = this.handleBoxToggle.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.handleClickLegend1 = this.handleClickLegend1.bind(this);
    this.handleClickLegend2 = this.handleClickLegend2.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;

    this._mounted = true;

    // init map
    this.map = this.refMapWL.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
    L.control.scale().addTo(this.map);
    // add opacity for composite radar
    // document.getElementsByClassName('leaflet-overlay-pane')[0].style.opacity = 0.6;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      datas, language, datasRainfall24h,
    } = this.props;
    const { radarType } = this.state;

    if (datas === nextProps.datas
      && radarType === nextState.radarType
      && language === nextProps.i18n.language
      && datasRainfall24h === nextProps.datasRainfall24h) {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleMouseOut() {
    this.setState({ hover: false });
    this.forceUpdate();
  }

  handleClickLegend1() {
    const { openLegend1 } = this.state;
    if (openLegend1 === false) {
      this.setState({ openLegend1: true });
    } else {
      this.setState({ openLegend1: false });
    }
    this.forceUpdate();
  }

  handleClickLegend2() {
    const { openLegend2 } = this.state;
    if (openLegend2 === false) {
      this.setState({ openLegend2: true });
    } else {
      this.setState({ openLegend2: false });
    }
    this.forceUpdate();
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 600;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 600;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 600;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 450;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  // set marker icon based on criteria
  setIcon = (currentWl, previousWl, storagePercent) => {
    const status = WlStatusColorDB(currentWl, previousWl, storagePercent);
    // if (currentWl > previousWl) {
    //   status.icon = iconRect.up;
    // } else if (currentWl < previousWl) {
    //   status.icon = iconRect.down;
    // }
    return status.icon;
  }
  // setIcon = val => {
  //   const status = WlStatusColor(val);

  //   return status.icon;
  // }

  // set marker icon based on criteria
  setIconRailfall = (val, type) => {
    const status = RainfallStatusColor(val, type);

    return status.icon;
  }

  getBasinStyle() {
    return {
      stroke: true,
      color: '#ff5722',
      weight: 3,
      fillColor: '#dc3545',
      fillOpacity: 0,
    };
  }

  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(1), type);
    return status;
  }

  // *** radar composite layer ***/
  // fetchRadarCompositeCsv() {
  //   const file = `${initPath}proxy/csv.php?file=https://live1.hii.or.th/product/latest/comp_radar/description.txt`;

  //   return fetch(file).then(response => {
  //     const reader = response.body.getReader();
  //     const decoder = new TextDecoder('utf-8');

  //     return reader.read().then(result => decoder.decode(result.value));
  //   });
  // }

  // async getRadarCompositeDate() {
  //   const csvData = await this.fetchRadarCompositeCsv();
  //   const parseData = Papa.parse(csvData, {
  //     header: true,
  //     skipEmptyLines: true,
  //   });

  //   // console.log(parseData.data[2].filename);
  //   this.setState({
  //     radarCompositeUrl: parseData.data[2].filename,
  //   });
  // }
  // *** END radar composite layer ***/

  // setRadarType(type) {
  //   this.setState({ radarType: type });
  // }

  // *** water gate ***/
  eachWatergateData = (data, i) => {
    const { classes, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long) {
      const position = [parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long)];

      // marker icons, white rectangle
      const icon = iconCircle.ifffff5;

      // set location
      let location = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        location = `${tJson(data.geocode.tumbon_name, 'ต.')}`;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        location += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        location += `${tJson(data.geocode.province_name, 'จ.')}`;
      }

      const station = (data.station.tele_station_name.th).split(' ');
      let stationList = tJson(data.station.tele_station_name, 'สถานี');
      if (station.length > 1 && station[0].search('สถานีโทรมาตร') > -1) {
        stationList = station[1];
      }
      if (stationList === '') {
        stationList = data.station.tele_station_name.th;
      }

      // debug
      // console.log(i, data);

      return (
        <Marker
          key={i}
          position={position}
          icon={icon}
          zIndexOffset={-i}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} className={classes.hiddenLine} padding="none" align="center">
                    <Typography variant="subtitle2">
                      {/* {`${data.station.tele_station_name.th}`} */}
                      {`${stationList}`}
                    </Typography>
                    <Typography variant="subtitle2">
                      {location}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}>{`${t('ระดับน้ำเหนือ')}`}</TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <strong>
                      {this.formatWl(data.watergate_in)}
                    </strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}>{`${t('ระดับน้ำท้าย')}`}</TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <strong>
                      {this.formatWl(data.watergate_out)}
                    </strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}>
                    <Typography variant="caption" color="secondary">
                      {`${t('หน่วย')} : (${t('ม.รทก.')})`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name, ' ')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}>
                    <Typography variant="caption" color="secondary">
                      {data.watergate_datetime_in}
                      {' '}
                      {'น.        '}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    <WlContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Button
                          color="secondary"
                          onClick={this.handleWatergateChart(handleModalOpen, data)}
                          title={`${t('แสดงกราฟ')}`}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      )}
                    </WlContext.Consumer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {`${t('ประตูระบายน้ำ')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')}`}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataRainfall24h = (data, i) => {
    const { classes, datasRainfall24h, t } = this.props;
    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
      && data.rain_24h >= 0 && data.station.id > 0
    ) {
      const position = [parseFloat(data.station.tele_station_lat), parseFloat(data.station.tele_station_long)];
      const icon = this.setIconRailfall(parseFloat(data.rain_24h).toFixed(1), 'rainfall24h');
      const rainfall24h = parseFloat(data.rain_24h).toFixed(1);
      const textRainfallStatus = this.rainfallStatus(rainfall24h, 'rainfall24h').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall24h, 'rainfall24h').color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                      {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                      {`${tJson(data.geocode.province_name, 'จ.')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} align="center" colSpan={2}>
                    <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                      <Box display="flex" width="50%">
                        <Box width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              color: textRainfallStatus,
                              fontSize: '2em',
                            }}
                          >
                            <HiiRainIcon style={{ fontSize: 60 }} />
                          </Typography>
                        </Box>
                        <Box mt={1} width="50%">
                          <Typography
                            variant="h6"
                            style={{
                              backgroundColor: textRainfallStatus,
                              color: rainfallStatusFontColor,
                              fontSize: 16,
                              borderRadius: 3,
                            }}
                          >
                            {`${parseFloat(data.rain_24h).toFixed(1)}`}
                          </Typography>
                          <Typography variant="h6">
                            {t('มม.')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="h6">
                      {t('ปริมาณฝนสะสม 24 ย้อนหลัง')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                    <Typography variant="caption" color="secondary">
                      {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.hiddenLine}><Typography variant="caption" color="secondary">{`${data.rainfall_datetime} น.`}</Typography></TableCell>
                  <TableCell className={classes.hiddenLine} align="right">
                    {data.agency.agency_shortname.en !== 'DWR' ? (
                      <RainfallContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfall24h', datasRainfall24h)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </RainfallContext.Consumer>
                    ) : (
                      <RainfallContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            title={t('ไม่มีกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} style={{ color: '#9e9e9e' }} />
                          </Button>
                        )}
                      </RainfallContext.Consumer>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${t('สถานี')} ${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {' '}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {' '}
                {`${tJson(data.geocode.province_name, 'จ.')}`}
                {' '}
                <br />
                {`${t('ข้อมูล:')} ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  handleWatergateChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;
    const params = {
      header: t('กราฟระดับน้ำที่ปตร./ฝาย'),
      content: <WaterGateChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }
  // *** END water gate ***/

  handleRainfallChart = (handler, data, type, filterData) => e => {
    e.preventDefault();
    const { t } = this.props;
    const params = {
      header: `${t('กราฟฝน')} ${tJson(data.station.tele_station_name, 'สถานี')}`,
      content: <RainfallChart
        id={data.station.id}
        provinceId={data.geocode.province_code}
        selectedType={type}
        filterData={filterData}
        year={(data.rainfall_datetime).substring(0, 4)}
        month={(data.rainfall_datetime).substring(5, 7)}
      />,
    };
    handler(params);
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      if (this.refMapWL.current.leafletElement) {
        const map = this.refMapWL.current.leafletElement;
        // map.on('popupopen', e => {
        //   // eslint-disable-next-line no-underscore-dangle
        //   const px = map.project(e.target._popup._latlng);

        //   // eslint-disable-next-line no-underscore-dangle
        //   px.y -= e.target._popup._container.clientHeight / 2;
        //   map.panTo(map.unproject(px), { animate: true });
        // });
      }
    }
    // if (ref) {
    //   markers[id] = ref.leafletElement;
    //   markers[id].addEventListener('click', e => {
    //     const { lat, lng } = e.target.getLatLng();
    //     this.map.panTo([parseFloat(lat+4), parseFloat(lng)]);
    //   });
    // }
  }

  handleBoxToggle = () => {
    this.setState({ hover: true });
    this.forceUpdate();
  };

  handleWaterLevelChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;
    const params = {
      header: `${t('กราฟระดับน้ำ')}`,
      content: <WlChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }

  formatWl = value => (typeof value === 'undefined' || value == null ? '-' : parseFloat(value).toFixed(2))

  eachData = (data, i) => {
    const {
      location, classes, t,
    } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.waterlevel_msl != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long)];

      // marker icons row.waterlevel_msl,row.waterlevel_msl_previous,row.storage_percent
      const icon = this.setIcon(parseFloat(data.waterlevel_msl),
        parseFloat(data.waterlevel_msl_previous),
        parseFloat(data.storage_percent));
      // console.log('msl => ', data.waterlevel_msl);
      // gconsole.log('icon => ', icon);
      // status colors
      const statusColor = WlStatusColor(data.storage_percent);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = ` ${tJson(data.geocode.tumbon_name, 'ต.')}`;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += ` ${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += ` ${tJson(data.geocode.province_name, 'จ.')}`;
      }

      return (
        <Marker
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zIndexOffset={99999}
        >
          <Popup autoPan={false}>
            <Table aria-labelledby="Info">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                    <Typography variant="subtitle2">
                      {data.station.tele_station_name.th}
                    </Typography>
                    <Typography variant="subtitle2">
                      {locationText}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <div>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.hiddenLine} align="center" rowSpan={2} style={{ width: '50%', fontSize: '1.2em' }}>
                                <Box display="flex" alignItems="center" justifyContent="center" style={{ width: '100%', height: '100%' }}>
                                  <Box
                                    borderRadius={4}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                      backgroundColor: statusColor.color.statusColor,
                                      color: statusColor.color.constrastColor,
                                      lineHeight: 3,
                                      width: '40%',
                                      height: 50,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {`${t(`น้ำ${statusColor.color.label}`)}`}
                                  </Box>
                                  <Box pl={2} display="block" justifyContent="flex-start">
                                    <Box
                                      style={{
                                        fontSize: '1em',
                                      }}
                                    >
                                      {t(data.diff_wl_bank_text)}
                                    </Box>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      style={{
                                        color: statusColor.color.statusColor,
                                        fontWeight: 'bold',
                                        fontSize: '2em',
                                        lineHeight: 1,
                                      }}
                                    >
                                      {data.diff_wl_bank}
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableBody>
                <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับน้ำ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>
                        {this.formatWl(data.waterlevel_msl)}
                        {' '}
                      </strong>
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>
                      (
                      {t('ม.รทก.')}
                      )
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับตลิ่ง')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.station.min_bank)}</strong>
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>
                      (
                      {t('ม.รทก.')}
                      )
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับท้องน้ำ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{this.formatWl(data.station.ground_level)}</strong>
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>
                      (
                      {t('ม.รทก.')}
                      )
                    </TableCell>
                  </TableRow>
                {/* <TableRow>
                   <TableCell>พิกัด</TableCell>
                   <TableCell colSpan={2}>
                     <strong>
                       {`${data.station.tele_station_lat},${data.station.tele_station_long}`}
                     </strong>
                   </TableCell>
                 </TableRow> */}
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={4}>
                      <Typography variant="caption" color="secondary">
                        {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={2}>
                      <Typography variant="caption" color="secondary">
                        {`ล่าสุด: ${data.waterlevel_datetime}`}
                        {' '}
                        {t('น.')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <WlContext.Consumer>
                        {({ handleModalOpen }) => (
                          <>
                            <Button
                              color="secondary"
                              onClick={this.handleWaterLevelChart(handleModalOpen, data)}
                              title={t('กราฟระดับน้ำ')}
                              style={{ padding: 0 }}
                            >
                              <FaChartLine className={classes.rightIcon} style={{ fontSize: '2em' }} />
                            </Button>
                          </>
                        )}
                      </WlContext.Consumer>
                    </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')}`}
                <br />
                {`${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  mapLayerControl = () => {
    const {
      datas, datasWatergate, t, datasRainfall24h,
    } = this.props;
    return (
      <LayersControl position="topright">
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Esri_WorldStreetMap">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <BaseLayer name="Google Street">
          <TileLayer
            url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </BaseLayer>
        <BaseLayer name="Google Hybrid">
          <TileLayer
            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </BaseLayer>
        <Overlay key="railfall24h" name={t('ฝน 24 ชม.')}>
          <LayerGroup ref={this.rainfallTodayLayer}>
            {datasRainfall24h.sort((a, b) => a.rain_24h - b.rain_24h).map(this.eachDataRainfall24h)}
          </LayerGroup>
        </Overlay>
        <Overlay key="watergate" name={t('ประตูระบายน้ำ')} checked="true">
          <LayerGroup ref={this.watergateLayer}>
            {datasWatergate.map(this.eachWatergateData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="waterlevel" name={t('สถานีวัดระดับน้ำ')} checked="true">
          <LayerGroup ref={this.waterLevelLayer}>
            {orderBy(datas.filter(d => d.storage_percent != null), ['storage_percent'], ['desc']).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay name={t('พื้นที่น้ำท่วมในรอบ 7 วัน')}>
          <WMSTileLayer
            url="./proxy/tile-gistda.php"
            version="1.1.0"
            opacity={1}
            transparent
            layers="layers=flood:flood7day"
            srs="EPSG:4326"
            format="image/png"
            tiled
            attribution="&copy; <a href=&quot;http://www.gistda.or.th&quot; target='_blank'>Gistda</a> contributors"
          />
        </Overlay>
        <Overlay name={t('ภาพฝนสะสม 24 ชม. ที่ผ่านมา')}>
          <ImageOverlay
            bounds={[[5.433545, 95.909762], [20.59362, 106.04254]]}
            opacity={0.5}
            url="https://fews2.hii.or.th/model-output/data_portal/radar/latest/png/rain24hrs.png"
            name="Composite Radar 24 hrs"
          />
        </Overlay>
        <Overlay name={t('ขอบเขตจังหวัด')}>
          <WMSTileLayer
            url="https://igis.thaiwater.net/geoserver/wms"
            version="1.1.0"
            opacity={1}
            transparent
            layers="thailand_boundary"
            srs="EPSG:4326"
            format="image/png"
            tiled
          />
        </Overlay>
        <Overlay name={t('ขอบเขตลุ่มน้ำ')}>
          <WMSTileLayer
            url="https://igis.thaiwater.net/geoserver/wms"
            version="1.1.0"
            opacity={1}
            transparent
            layers="thailand_basin_22"
            srs="EPSG:4326"
            format="image/png"
            tiled
          />
        </Overlay>
        <Overlay name={t('เส้นแม่น้ำ')} checked>
          <WMSTileLayer
            url={`${initPathWMS}wms`}
            version="1.1.0"
            opacity={1}
            transparent
            layers="layers=thaiwater30:river_thailand"
            srs="EPSG:4326"
            format="image/png"
            tiled
          />
        </Overlay>
      </LayersControl>
    );
  }

  render() {
    const {
      error, height, hover, openLegend1, openLegend2, // radarType,
    } = this.state;
    const {
      zoom, geojsonBasin, t, i18n, locationZone,
    } = this.props;

    const tooltipStyle = {
      display: hover ? 'block' : 'none',
      maxWidth: '337px',
      padding: '10px',
      wordBreak: 'break-all',
    };

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // console.log('rerender', radarType);
    return (
      <Map
        id="map"
        zoom={zoom}
        zoomSnap={0}
        zoomDelta={0.5}
        ref={this.refMapWL}
        style={{ height }}
        gestureHandling
        center={locationZone}
      >

        {/* get current location  */}
        {/* <LocateControl options={locateOptions} /> */}

        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          id="OpenStreetMap.HOT"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
        />
        <GeoJSON
          key={uniqueId()}
          data={geojsonBasin}
          style={this.getBasinStyle}
          ref={this.refBoundaryBasinJson}
        // onEachFeature={this.onEachBasinFeature}
        />
        {(i18n.language === 'th') && (
          this.mapLayerControl()
        )}

        {(i18n.language === 'en') && (
          this.mapLayerControl()
        )}

        {(i18n.language === 'jp') && (
          this.mapLayerControl()
        )}

        <FullscreenControl
          position="topleft"
          forcePseudoFullscreen
          content={LeafletFullScreenFix()}
        />
        <Control position="topleft">
          <div>
            <img
              src={`${initPath}images/marker/icon/info.png`}
              alt="icon_infomation"
              style={{ width: '7%' }}
              onBlur={() => this.setState({ hover: true })}
              onFocus={() => this.setState({ hover: false })}
              onMouseEnter={this.handleBoxToggle}
              onMouseOut={this.handleMouseOut}
            />
          </div>
          <Paper>
            <div style={tooltipStyle}>
              <p>
                {t('ระบบติดตามเฉพาะกิจนี้พัฒนาขึ้นเพื่อใช้ติดตามสถานการณ์')}
                <br />
                {t('น้ำท่วมปี 2567 ซึ่งเป็นส่วนหนึ่งของคลังข้อมูลน้ำแห่งชาติ')}
                <br />
                {t('และขอขอบพระคุณหน่วยงานงานดังต่อไปนี้ ที่ให้ข้อมูลและ')}
                <br />
                {t('การสนับสนุนในระบบเฉพาะกิจนี้ ได้แก่')}
                <br />
                {`► ${t('กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)')}`}
                <br />
                {`► ${t('สำนักงานทรัพยากรน้ำแห่งชาติ (สทนช.)')}`}
                <br />
                {`► ${t('สถาบันสารสนเทศทรัพยากรน้ำ (สสน.)')}`}
                <br />
                {`► ${t('กรมชลประทาน (ชป.)')}`}
                <br />
                {`► ${t('สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (สทอภ.)')}`}
                <br />
                {`► ${t('กรมฝนหลวงและการบินเกษตร (ฝล.)')}`}
                <br />
                {`► ${t('บริษัท MappointAsia (Thailand)')}`}
              </p>
            </div>
          </Paper>
        </Control>
        <Control position="bottomleft">
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            width: '105%',
          }}
          >
            <div style={{ bottom: 0 }}>
              {openLegend1 ? (
                <div>
                  <RainLegend />
                </div>
              ) : null}
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'white',
                  zIndex: 99999,
                  bottom: '1%',
                  left: '1%',
                  fontSize: '12px',
                }}
                aria-haspopup="true"
                onClick={this.handleClickLegend1}
              >
                {`${t('เกณฑ์ฝนสะสม')} ${t('(มม.)')}`}
              </Button>
            </div>
            <div style={{ bottom: 0, paddingLeft: '2%', width: 'auto' }}>
              {openLegend2 ? (
                <div>
                  <WlLegend />
                </div>
              ) : null}
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'white',
                  zIndex: 99999,
                  bottom: '1%',
                  left: '1%',
                  fontSize: '12px',
                }}
                aria-haspopup="true"
                onClick={this.handleClickLegend2}
              >
                {` ${t('เกณฑ์ระดับน้ำ')} ${t('(มม.)')}`}
              </Button>
            </div>
          </div>
        </Control>
        <CurrentLocation mapRef={this.refMapWL} showOnly />
      </Map>
    );
  }
}

WaterlevelMap.defaultProps = {
  geojsonBasin: [],
};

WaterlevelMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  datasWatergate: PropTypes.array.isRequired,
  datasRainfall24h: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  geojsonBasin: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]),
  handleSetMap: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(WaterlevelMap);
