import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

// images
import Img from 'react-image';

const initPath = process.env.MIX_APP_PATH;

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
    width: 160,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
  },
});

export default function DroughtDamLegend() {
  const classes = useStyles();

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.8',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          <Grid container>
            <Grid item>
              <Img src={`${initPath}images/marker/icon/model/drought_monitor/dam-30.png`} width="20px" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'< 30%'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Img src={`${initPath}images/marker/icon/model/drought_monitor/dam-30-50.png`} width="20px" />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 30% – 50% </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Img src={`${initPath}images/marker/icon/model/drought_monitor/dam-50-80.png`} width="20px" />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 50% - 80% </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Img src={`${initPath}images/marker/icon/model/drought_monitor/dam-80-100.png`} width="20px" />
            </Grid>
            <Grid item><Typography variant="subtitle2">  80% - 100% </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Img src={`${initPath}images/marker/icon/model/drought_monitor/dam-100.png`} width="20px" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 100%'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
