import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

// ui
import Img from 'react-image';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

// modal
import Modal from '@material-ui/core/Modal';
import { compose } from 'recompose';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
// import ContentImageWrfLasted from './ContentImageWrfLasted';
import WrfLatestTab from './WrfLatestTab';

// import Lightbox from 'react-image-lightbox';
import { FaSpinner } from '../../../utils/Fontawesome';
import 'react-image-lightbox/style.css';

import { styles } from '../../../styles/Style';

const width = '48%';

// lightbox
const images = [];

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class WrfLatest extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      imgs: [],
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
      thailand3km: [],
      southEastasia9km: [],
      asia27km: [],
      thailandBasin3km: [],
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({
      isLoading: true,
    });

    // get data from ws
    this.getData();
  }

  handleModalOpen(i, data, mediaDateTime, domainData) {
    const {
      thailand3km, southEastasia9km, asia27km, thailandBasin3km,
    } = this.state;

    this.setState({
      open: true,
      modalHeader: '',
      // modalContent: <ContentImageWrfLasted
      //   index={i}
      //   data={data}
      //   domainData={domainData}
      //   mediaDateTime={mediaDateTime}
      // />,
      modalContent: <WrfLatestTab
        thailand3km={thailand3km}
        southEastasia9km={southEastasia9km}
        asia27km={asia27km}
        thailandBasin3km={thailandBasin3km}
        index={i}
        data={data}
        domainData={domainData}
        mediaDateTime={mediaDateTime}
      />,
    });
  }

  getData() {
    fetch(`${process.env.MIX_API_URL}public/rain7day_forecast`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: [...result.data.pre_rain.data, ...result.data.pre_rain_sea.data],
          thailand3km: [...result.data.pre_rain.data],
          southEastasia9km: [...result.data.pre_rain_sea.data],
          asia27km: [...result.data.pre_rain_asia.data],
          thailandBasin3km: [...result.data.pre_rain_basin.data],
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '81%';

    if (isWidthUp('md', width)) {
      widthChart = '100%';
      heightChart = '95%';
    }
    if (isWidthDown('sm', width)) {
      widthChart = '100%';
      heightChart = '80%';
    }
    if (isWidthDown('xs', width)) {
      widthChart = '100%';
      heightChart = '70%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      error,
      imgs,
      // photoIndex,
      // isOpen,
      open,
      modalHeader,
      modalSubHeader,
      modalContent,
    } = this.state;

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    return (
      <>
        {error && <div className="text-center">{error.message}</div>}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={35} />
          </div>
        )}

        <Grid container spacing={4} justifyContent="space-between" style={{ marginTop: 15 }}>
          {imgs.slice(0, 3).map((row, i) => {
            let realImage = '';
            if (row.media_path !== '') {
              images.push({
                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                title: `${row.media_datetime}`,
              });
              realImage = (
                // <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                <Button onClick={() => this.handleModalOpen(i, imgs, row.media_datetime, 'thailand')}>
                  <Img
                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                    width={width}
                    alt={moment(row.media_datetime).format('ll')}
                    className={classes.responsive}
                  // style={{ height: 400 }}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src={row.src}
                  width={width}
                  alt="No Data"
                  className={classes.responsive}
                // style={{ height: 400 }}
                />
              );
            }
            return (
              <Grid item xs={12} sm={12} md={4} lg={4} key={uniqueId()}>
                <Box p={0} display="block">
                  <Box display="flex" className={classNames(classes.boxRadiusForecast, classes.statusNull)} align="center">
                    <Box p={1} width="35%" style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                      <Typography variant="h6" style={{ fontWeight: 400 }}>{moment(row.media_datetime).format('ll')}</Typography>
                    </Box>
                    <Box p={1} width="65%">{' '}</Box>
                  </Box>
                  <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                    {realImage}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={4} justifyContent="space-between" style={{ marginTop: 15 }}>
          {imgs.slice(6, 10).map((row, i) => {
            let realImage = '';
            if (row.media_path !== '') {
              images.push({
                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                title: `${row.media_datetime}`,
              });
              realImage = (
                // <Button onClick={() => this.setState({ isOpen: true, photoIndex: i + 3 })}>
                <Button onClick={() => this.handleModalOpen(i + 3, imgs, row.media_datetime, 'southeast asia')}>
                  <Img
                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                    width={width}
                    alt={moment(row.media_datetime).format('ll')}
                    className={classes.responsive}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                  width={width}
                  alt="No Data"
                  className={classes.responsive}
                />
              );
            }
            return (
              <Grid item xs={6} md={3} lg={3} key={uniqueId()}>
                <Box p={0} display="block">
                  <Box display="flex" className={classNames(classes.boxRadiusForecast, classes.statusNull)} align="center">
                    <Box p={1} width="100%" style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                      <Typography variant="h6" style={{ fontWeight: 400 }}>{moment(row.media_datetime).format('ll')}</Typography>
                    </Box>
                  </Box>
                  <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
                    {realImage}
                  </Box>
                </Box>
              </Grid>
            );
          })}
          <Modal
            id="modal-chart"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={this.handleModalClose}
            style={{ align: 'center', justifyContent: 'center' }}
          >
            <div style={this.getModalStyle()} className={classes.paper}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                <Grid item>
                  <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography variant="h5" id="modal-title">
                {modalHeader}
              </Typography>
              <Typography variant="subtitle1" id="simple-modal-description">
                {modalSubHeader}
              </Typography>
              <Box mt={3}>
                {modalContent}
              </Box>
            </div>
          </Modal>
        </Grid>
      </>
    );
  }
}

WrfLatest.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }))(WrfLatest);
