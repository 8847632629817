// React
import React, { useEffect, useState } from 'react';
// material-ui
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Img from 'react-image';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
// component
import { CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import ReportSalinityMap from './ReportSalinityMap';
// style
import { styles } from '../../styles/Style';
import ReportSalinityFullMap from './ReportSalinityFullMap';

const useStyles = makeStyles(styles);
const initPath = process.env.MIX_APP_PATH;

export default function ReportSalinity() {
  // const { width } = props;
  const classes = useStyles();
  const [getError, setError] = useState();
  const [Loading, setLoading] = useState(true);
  const [getListStationRID, setListStationRID] = useState([]);
  const [getListStationPCD, setListStationPCD] = useState([]);
  const [mode, setMode] = useState(false);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  const getModalStyle = () => {
    const { dynamicWidth } = screenSize;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '70%';
    if (isWidthDown('xl', dynamicWidth)) {
      widthChart = '90%';
      heightChart = '67%';
    }
    if (isWidthUp('md', dynamicWidth)) {
      widthChart = '80%';
      heightChart = '80%';
    }
    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
    // const top = 0;
    // const left = 0;
    // const right = 0;
    // const bottom = 0;
    // return {
    //   top: `${top}%`,
    //   left: `${left}%`,
    //   right: `${right}%`,
    //   bottom: `${bottom}%`,
    //   margin: 'auto',
    //   width: '90%',
    //   height: '65%',
    //   overflowY: 'auto',
    //   backgroundColor: '#FFF',
    //   padding: '20px',
    //   position: 'absolute',
    // };
  };
  const [modalStyle] = useState(getModalStyle);
  const [getModal, setModal] = useState({
    open: false,
    modalHeader: '',
    modalSubHeader: '',
    modalContent: '',
  });

  function fetchDataRID() {
    fetch('https://script.googleusercontent.com/macros/echo?user_content_key=l7jtNyNhmS__xYoWEM7SamePfWJQf3yEPmm0OuxDVS_T8AZIsfaeoFE-2AiSvVEbVoNPmeTxLDacOVQoBzQRNdfAl2HYkjFFm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnNuL_Fiz0X1cLq4zl1V4NX1dILUo9uIeGL5xMM37Oc6FJf4JGSdHVyrgRqBSfuMvvbnWwuciMmNnmC1YAkQq-Ayo4hYa3WPm7w&lib=MJigFE6SzsOAqOnRi5O-i_3YI0XB3EwPt')
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        setLoading(false);
        setListStationRID(result);
      })
      .catch(error => setError(error));
  }

  function fetchDataPCD() {
    fetch('https://script.googleusercontent.com/macros/echo?user_content_key=1dYtfol5V1dyNkcrL3tHFH3XQlCg1mHT7yDfrAWpa7g3bzlevW6KuvBY7-c7bAbu0SNZQ8Sf3fXPQ_6KbMrQTA9_xtd938VLm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnDtqhkwWwa2CUUn79OX7Gr-YuQ2lI0_yPg8Qv-ORq2F8-WaxpPlZ1sXTsunTHZ5E6IGLfM46aakSjhohxN_s1TtaTYw_SAMaSdz9Jw9Md8uu&lib=MVy6-GxHRdIN-9-SQ_2p7og3resVMYSIy')
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        setLoading(false);
        setListStationPCD(result);
      })
      .catch(error => setError(error));
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    return (() => {
      window.removeEventListener('resize', setDimension);
    });
  }, [screenSize]);

  useEffect(() => {
    fetchDataRID();
    fetchDataPCD();
  }, []);

  const handleModalClose = () => {
    const settingModal = {
      open: false,
      modalHeader: '',
      modalSubHeader: '',
      modalContent: '',
    };
    setModal(settingModal);
  };

  const handleModalOpen = params => {
    const settineModal = {
      open: true,
      modalHeader: params.header,
      modalContent: params.content,
    };
    setModal(settineModal);
  };

  return (
    <>
      {getError && (
        <div className="text-center">
          {getError.message}
        </div>
      )}

      <div className={classes.layout}>
        <Box style={{
          width: '1280px',
          position: 'relative',
          margin: 'auto',
        }}
        >
          <Img
            src={`${initPath}images/report-salinity/topbar.png`}
            width={1000}
            alt="No Image"
          />
          <Box
            style={{
              backgroundColor: '#ffffff00',
              top: 20,
              left: 20,
              width: 50,
              height: 50,
              position: 'absolute',
              cursor: 'pointer',
            }}
            textAlign="center"
            onClick={() => window.open('http://www.onwr.go.th', '_blank')}
          />
          <Box
            style={{
              backgroundColor: '#ffffff00',
              top: 20,
              left: 75,
              width: 50,
              height: 50,
              position: 'absolute',
              cursor: 'pointer',
            }}
            textAlign="center"
            onClick={() => window.open('https://www.rid.go.th', '_blank')}
          />
          <Box
            style={{
              backgroundColor: '#ffffff00',
              top: 20,
              left: 135,
              width: 50,
              height: 50,
              position: 'absolute',
              cursor: 'pointer',
            }}
            textAlign="center"
            onClick={() => window.open('https://www.pcd.go.th', '_blank')}
          />
          <Box
            style={{
              backgroundColor: '#ffffff00',
              top: 20,
              left: 200,
              width: 50,
              height: 50,
              position: 'absolute',
              cursor: 'pointer',
            }}
            textAlign="center"
            onClick={() => window.open('https://www.hii.or.th', '_blank')}
          />
        </Box>

        <Box
          style={{
            color: 'black',
            zIndex: 999999,
            position: 'absolute',
            top: 20,
            right: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: mode ? '15px' : '',
            border: mode ? '2px solid' : '',
          }}
        >
          <Typography style={{ marginLeft: 5 }}>
            แผนที่เฉพาะจุด
          </Typography>
          <FormControlLabel
            control={(
              <Switch
                checked={mode}
                onChange={event => setMode(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
          />
          <Typography style={{ marginRight: 5 }}>
            แผนที่ทั้งประเทศ
          </Typography>
        </Box>

        {
          mode ? (
            <ReportSalinityFullMap
              listStationRID={getListStationRID}
              listStationPCD={getListStationPCD}
              handleModalOpen={handleModalOpen}
            />
          ) : (
            !Loading && (
              <ReportSalinityMap
                listStationRID={getListStationRID}
                listStationPCD={getListStationPCD}
                handleModalOpen={handleModalOpen}
              />
            )
          )
        }
        <Modal
          id="modal-chart"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={getModal.open}
          onClose={handleModalClose}
          style={{ align: 'center', justifyContent: 'center' }}
        >
          <div style={modalStyle} className={classes.paper}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              <Grid item>
                <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Typography variant="h5" id="modal-title">
              {getModal.modalHeader}
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              {getModal.modalSubHeader}
            </Typography>
            <Box mt={3}>
              {getModal.modalContent}
            </Box>
          </div>
        </Modal>
        {Loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              กำลังโหลดข้อมูล
            </Typography>
            <CircularProgress size={60} />
          </div>
        )}
      </div>
    </>
  );
}

// StormSurgeForecast.propTypes = {
//   width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
// };
