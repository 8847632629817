/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import { withTranslation } from "react-i18next";

import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Img from "react-image";
import Button from "@material-ui/core/Button";

// icon .
import { FaImage } from "../../utils/Fontawesome";

const styles = (theme) => ({
  paper: {
    // padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  progress: {
    margin: theme.spacing(2),
  },
});

class CctvMedia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cctvMedia: "",
    };
  }

  componentDidMount() {
    const { data } = this.props;
    // get data from ws
    this.checkMedia(data);
  }

  checkMedia = (data) => {
    const { classes, t } = this.props;
    let url = data.cctv_url.split(/[?#]/)[0];

    // remove url querystring

    if (data.cctv_url === "") {
      url = data.cctv_flash;
    }

    let media = (
      <Img
        src={url}
        loader={<CircularProgress className={classes.progress} />}
        className={classes.responsive}
        style={{ maxHeight: 500, width: "100%" }}
        unloader={
          <img
            src="https://via.placeholder.com/300x250.png?text=No+Data"
            width="100%"
            height="100%"
            alt="No Data"
          />
        }
      />
    );
    if (data.media_type === "vdo") {
      media = (
        <iframe
          name="CCTV"
          src={url}
          title={data.title}
          width="100%"
          height="600"
          frameBorder="0"
          border="0"
          cellSpacing="0"
        >
          CCTV
        </iframe>
      );
    }

    if (data.media_type === "url" || data.media_type === "img") {
      if (data.cctv_flash !== "") {
        const regex = /<embed[^>]+src="([^"]+)"/;
        const match = data.cctv_flash.match(regex);
        url = match ? match[1] : null;
      }
        media = (
          <Link
            target="_blank"
            href={url}
            underline="none"
            rel="noopener noreferrer"
          >
            <Button
              variant="outlined"
              className={classes.button}
              align="center"
            >
              {t("ดูวีดีโอ CCTV")}
              <FaImage fontSize="small" style={{ marginLeft: 10 }} />
            </Button>
          </Link>
        );
      
    }
console.log(url);

    this.setState({
      cctvMedia: media,
    });
  };

  render() {
    const { classes } = this.props;
    const { cctvMedia } = this.state;
    return (
      <>
        <Grid item xs={12}>
          <Paper className={classes.paper}>{cctvMedia}</Paper>
        </Grid>
      </>
    );
  }
}

CctvMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(CctvMedia));
