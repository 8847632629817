import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';

// icon
import { FaHome } from '../../../utils/Fontawesome';

// style, css
import { styles } from '../../../styles/Style';

// componens
import PrecipitationHistoryList from './PrecipitationHistoryList';
// import WrfAnimation from './WrfAnimation';
// import WrfLatest from './WrfLatest';

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 8 }}>
    {children}
  </Typography>
);

const currentDate = new Date(moment());

const initPath = process.env.MIX_APP_PATH;
const initPathApi = process.env.MIX_API_URL;

class PrecipitationHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(moment()),
      // for get data condition
      mvalue: { year: moment().format('YYYY'), month: moment().format('M') },
      // tab
      value: 0,
      displayState: 'history',
      // historyStatButton: 'primary',
      // lastestStatButton: '',
      wmoLatest1: new Date(moment()),
      wmoLatest2: new Date(moment()),
      afwaLatest1: new Date(moment()),
      afwaLatest2: new Date(moment()),
    };
  }

  componentDidMount() {
    // get data from ws
    this.fetchData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDateChange = date => {
    this.setState({
      startDate: moment(date).format('YYYY-MM-DD'),
      mvalue: { year: moment(date).format('YYYY'), month: moment(date).format('M') },
    });
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate;
    if (change === '1') {
      selectedDate = moment(this.state.startDate).add(1, 'months').format('YYYY-MM-DD');
    } else if (change === '-1') {
      selectedDate = moment(this.state.startDate).add(-1, 'months').format('YYYY-MM-DD');
    }
    this.setState({
      startDate: selectedDate,
      mvalue: { year: moment(selectedDate).format('YYYY'), month: moment(selectedDate).format('M') },
    });
  }

  handleLastest = () => {
    const {
      value,
      afwaLatest1,
      afwaLatest2,
      wmoLatest1,
      wmoLatest2,
    } = this.state;
    // let startDate;
    let mvalue;
    if (value === 0) {
      mvalue = { year: moment(afwaLatest1).format('YYYY'), month: moment(afwaLatest1).format('M') };
      // startDate = afwaLatest1;
    } else if (value === 1) {
      mvalue = { year: moment(afwaLatest2).format('YYYY'), month: moment(afwaLatest2).format('M') };
      // startDate = afwaLatest2;
    } else if (value === 2) {
      mvalue = { year: moment(wmoLatest1).format('YYYY'), month: moment(wmoLatest1).format('M') };
      // startDate = wmoLatest1;
    } else if (value === 3) {
      mvalue = { year: moment(wmoLatest2).format('YYYY'), month: moment(wmoLatest2).format('M') };
      // startDate = wmoLatest2;
    }
    this.setState({
      displayState: 'latest',
      // startDate,
      mvalue,
      value,
    });
  }

  handleHistory = () => {
    const {
      value,
      startDate,
    } = this.state;

    const mvalue = { year: moment(startDate).format('YYYY'), month: moment(startDate).format('M') };

    this.setState({
      displayState: 'history',
      mvalue,
      value,
    });
  }

  fetchData() {
    // get data from service
    fetch(`${initPathApi}public/weather_img/modis_precipitation_usda`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const wmo151 = result.data.filter(d => {
          const mediaTypeId = d.detail[0].media_type_id;
          return (mediaTypeId === 151);
        });
        const wmo151Date = moment(wmo151[0].cover_image.media_datetime).format('YYYY-MM-DD');

        const wmo152 = result.data.filter(d => {
          const mediaTypeId = d.detail[0].media_type_id;
          return (mediaTypeId === 152);
        });
        const wmo152Date = moment(wmo152[0].cover_image.media_datetime).format('YYYY-MM-DD');

        const afwa149 = result.data.filter(d => {
          const mediaTypeId = d.detail[0].media_type_id;
          return (mediaTypeId === 149);
        });
        const afwa149Date = moment(afwa149[0].cover_image.media_datetime).format('YYYY-MM-DD');

        const afwa150 = result.data.filter(d => {
          const mediaTypeId = d.detail[0].media_type_id;
          return (mediaTypeId === 150);
        });
        const afwa150Date = moment(afwa150[0].cover_image.media_datetime).format('YYYY-MM-DD');

        this.setState({
          wmoLatest1: afwa149Date,
          wmoLatest2: afwa150Date,
          afwaLatest1: wmo151Date,
          afwaLatest2: wmo152Date,
          startDate: afwa149Date,
          mvalue: { year: moment(afwa149Date).format('YYYY'), month: moment(afwa149Date).format('M') },
        });
      });
  }

  renderHistory(classes) {
    const { startDate, mvalue, value } = this.state;
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 30, marginBottom: 16 }}>
          <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={3} md={3} lg={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                openTo="month"
                views={['year', 'month']}
                value={startDate}
                format={moment(startDate).format('MMMM yyyy')}
                onChange={this.handleDateChange}
                style={{ fontSize: 14 }}
                maxDate={currentDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" variant="fullWidth" className={classes.boxTab} style={{ marginBottom: 20 }}>
              <Tab label="AFWA" className={value === 0 ? classes.active_tab : ''} />
              <Tab label="AFWA DECADAL PERCENT NORMAL" className={value === 1 ? classes.active_tab : ''} />
              <Tab label="WMO" className={value === 2 ? classes.active_tab : ''} />
              <Tab label="WMO DECADAL PERCENT NORMAL" className={value === 3 ? classes.active_tab : ''} />
            </Tabs>
            {value === 0 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="149" />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="150" />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="151" />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="152" />
              </TabContainer>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  renderLastest() {
    const {
      mvalue,
      value,
    } = this.state;
    const { classes } = this.props;

    return (
      <>
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" variant="fullWidth" className={classes.boxTab} style={{ marginBottom: 20 }}>
              <Tab label="AFWA" className={value === 0 ? classes.active_tab : ''} />
              <Tab label="AFWA DECADAL PERCENT NORMAL" className={value === 1 ? classes.active_tab : ''} />
              <Tab label="WMO" className={value === 2 ? classes.active_tab : ''} />
              <Tab label="WMO DECADAL PERCENT NORMAL" className={value === 3 ? classes.active_tab : ''} />
            </Tabs>
            {value === 0 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="149" />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="150" />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="151" />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <PrecipitationHistoryList period={mvalue} agencyId="55" mediaTypeId="152" />
              </TabContainer>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    const { classes, t } = this.props;
    const {
      startDate,
      displayState,
      // historyStatButton,
      // lastestStatButton,
    } = this.state;

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/rainfall`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('ฝน')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('ปริมาณและการกระจายตัวของฝน')}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 24 }}>
            <Grid item xs={12} sm={6} md={8}>
              <Box m={1} className={classes.titleName}>
                {t('ปริมาณและการกระจายตัวของฝน')}
              </Box>
            </Grid>
            <Grid item xs>
              <Button
                size="small"
                color={displayState === 'latest' ? 'primary' : 'default'}
                variant={displayState === 'latest' ? 'contained' : 'text'}
                className={classes.outlinedLeftXsPrimary}
                onClick={this.handleLastest}
              >
                <ImageOutlinedIcon fontSize="small" />
                <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูภาพล่าสุด')}</Typography>
              </Button>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <Button
                  size="small"
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                  className={classes.outlinedRightXsPrimary}
                  onClick={this.handleHistory}
                >
                  <PhotoLibraryOutlinedIcon fontSize="small" />
                  <Typography variant="h6" style={{ marginLeft: 8 }}>
                    {t('ดูภาพย้อนหลัง')}
                  </Typography>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          { startDate && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              { (displayState === 'history') && (
                this.renderHistory(classes)
              )}
              { (displayState === 'latest') && (
                this.renderLastest()
              )}
            </Grid>
          </Grid>
          )}
        </div>
      </>
    );
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

PrecipitationHistory.defaultProps = {
  match: {
    params: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  },
};

PrecipitationHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      month: PropTypes.string,
      year: PropTypes.string,
    }),
  }),
  // theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(PrecipitationHistory));
