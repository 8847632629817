import grey from '@material-ui/core/colors/grey';

const drawerWidth = 500;

// const useStyles = makeStyles((theme) => ({
export const styles = theme => ({
  breakpoints: {
    xs: 0, sm: 375, md: 768, lg: 1024, xl: 1920,
  },
  topDevice: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '78px',
    },
    [theme.breakpoints.up(1024)]: {
      marginTop: '100px',
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      marginTop: '100px',
    },
  },
  layout: {
    justifycontent: 'space-around',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      marginLeft: theme.spacing(2 * 4),
      marginRight: theme.spacing(2 * 4),
    },
  },
  imgLogo: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
  titleMenu: {
    fontWeight: 400,
    fontSize: '1.1em',
    fontFamily: theme.typography.fontKanit,
    paddingBottom: theme.spacing(2),
  },
  radioStyle: {
    fontSize: '0.8em',
    fontFamily: theme.typography.fontKanit,
    fontWeight: 400,
  },
  titleMapTH: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '1.2rem',
    fontWeight: 400,
    color: theme.palette.primary.main,
    lineHeight: '30px',
    paddingLeft: '10px',
    marginTop: -10,
    textAlign: 'left',
  },
  titleMapEN: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '0.8rem',
    fontWeight: 300,
    color: grey[800],
    lineHeight: '100%',
    paddingLeft: '10px',
  },
  titleAppTH: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '0.7rem',
    fontWeight: 400,
    color: theme.palette.primary.main,
    lineHeight: '30px',
    paddingLeft: '10px',
    marginTop: -10,
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
    },
  },
  titleAppEN: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '0.4rem',
    fontWeight: 300,
    color: grey[800],
    lineHeight: '100%',
    paddingLeft: '10px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
    },
  },
  titleTHMobile: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '1.2rem',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  buttonNavbar: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '0.9rem',
    fontWeight: 400,
  },
  buttonMenuMoblie: {
    [theme.breakpoints.down('sm')]: {
      right: 15,
      zIndex: 4,
      position: 'absolute',
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      right: 5,
    },
  },
  icon: {
    color: 'white',
  },
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  list: {
    width: 320,
  },
  fullList: {
    width: 'auto',
  },
  boxViewLeft: {
    left: 10,
    zIndex: 2,
    position: 'absolute',
    display: 'block',
  },
  boxViewRight: {
    right: 10,
    zIndex: 3,
    position: 'absolute',
  },
  lang: {
    fontFamily: 'Kanit',
    color: '#666',
  },
  contentIntro: {
    fontFamily: theme.typography.fontKanit,
    background: 'linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)',
    left: 0,
    bottom: '20%',
    zIndex: 1,
    lineHeight: '100%',
    [theme.breakpoints.only('sm')]: {
      left: 4,
      fontSize: '0.9rem',
      width: '70%',
      background: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 8,
      left: 1,
      fontSize: '0.6rem',
      width: '60%',
      background: 'none',
    },
  },
  btnExpand: {
    fontSize: '2.8rem',
  },
  appBar: {
    boxShadow: '2px 2px 2px #ccc',
  },
});