import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsMore from 'highcharts/highcharts-more';

import { FaSpinner } from '../../utils/Fontawesome';

// init highcharts module
HighchartsMore(Highcharts);
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const DamMainDailyChartCompareHC = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [highchartOptions, setHighchartOptions] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    date, month, year, type,
  } = props;

  const getChartOptions = () => {
    const options = {
      chart: {
        type: 'column',
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          borderWidth: 0,
          showInNavigator: true,
          gapSize: 6,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f}',
          },
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'category',
        categories: [],
      },
      yAxis: { // Primary yAxis
        min: 0,
        title: {
          text: ` ${t('ล้าน ลบ.ม.')}`,
        },
        labels: {
          format: '{value:.0f}',
        },
      },
      legend: {
        enabled: false,
      },
      series: {},
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return options;
  };

  const resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  };

  const renderChart = () => {
    const url = `dam_daily_sum_main_dam_compare_by_date?day=${date}&month=${month}&year=${year}`;

    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const chartOptions = getChartOptions();
        const dataSeries = [];

        let val = null;

        const data = orderBy(result.data, ['date'], ['asc']).map(n => {
          const series = [];
          if (n[type] !== null) {
            val = parseFloat(n[type]).toFixed(2);
          } else {
            val = null;
          }

          series.push(parseFloat(val));
          chartOptions.xAxis.categories.push(n.year);
          // series.push(parseFloat(val));

          return series;
        });

        // set up series name
        let seriesName = '';
        if (type === 'total_dam_storage') {
          seriesName = t('ปริมาณน้ำเก็บกัก');
        } else if (type === 'total_dam_uses_water') {
          seriesName = t('ปริมาณน้ำใช้การ');
        } else if (type === 'total_dam_inflow') {
          seriesName = t('ปริมาณน้ำไหลลง');
        } else if (type === 'total_dam_released') {
          seriesName = t('ปริมาณน้ำระบาย');
        }

        dataSeries.push({
          name: seriesName,
          data,
          tooltip: {
            valueSuffix: ` ${t('ล้าน ลบ.ม.')}`,
          },
        });

        chartOptions.series = dataSeries;
        setHighchartOptions(chartOptions);
        setIsLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    resetChart: () => {
      resetChart();
    },
    renderChart: () => {
      renderChart();
    },
  }));

  useEffect(() => {
    renderChart();
  }, []);

  // if error, show error message
  if (error) {
    return (
      <div className="text-center">
        {error.message}
      </div>
    );
  }

  // if still loading, show spinner
  if (isLoading) {
    return (
      <div className="text-center">
        <FaSpinner size={70} />
      </div>
    );
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={highchartOptions}
      />
    </>
  );
});

DamMainDailyChartCompareHC.propTypes = {
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.array.isRequired,
};

export default DamMainDailyChartCompareHC;
