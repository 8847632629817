/**
 * *
 * @version 1.0.0
 * @author [Phithakkhet@hii.or.th]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// img
const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    width: '100%',
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
    },
  },
  title: {
    fontStyle: 'italic',
    alignItems: 'center',
  },
  titleEng: {
    paddingBottom: theme.spacing(2),
    fontStyle: 'italic',
    alignItems: 'center',
    color: '#555',
  },
  subTitle: {
    fontFamily: 'sans-serif',
    fontWeight: 600,
    fontSize: '0.9em',
    margin: 0,
    alignItems: 'center',
  },
  subTitle2: {
    fontFamily: 'sans-serif',
    fontWeight: 600,
    fontSize: '1em',
    margin: 0,
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
  },
  body: {
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    color: 'white',
    margin: 0,
  },
  img: {
    width: '90%',
  },
  bmaLogo: {
    color: '#fff',
    width: 100,
    height: 70,
    marginTop: -30,
    [theme.breakpoints.only('sm')]: {
      marginTop: 0,
    },
  },
  li: {
    color: '#fff',
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    listStylePosition: 'outside',
    listStyle: 'disc',
    margin: 5,
    marginLeft: -23,
  },
}));

const CompInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box px={1}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="block">
              <Box><img src={`${initPath}images/theme/bma/banner/about/bma-site.png`} className={classes.img} alt="BMA" /></Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" className={classes.title}>ระบบติดตามสถานการณ์น้ำกรุงเทพ</Typography>
            <Typography variant="h5" className={classes.titleEng}>Flood Plain Forecasting</Typography>
            <p className={classes.subTitle2}>ระบบใช้ทำอะไร?</p>
            <p className={classes.body}>ระบบคาดการณ์น้ำท่วม พัฒนาด้วยแบบจำลองอุทกพลศาสตร์แบบ1 มิติ แสดงผลคาดการณ์น้ำท่าและระดับน้ำ ณ สถานีตรวจวัดหลักโดยคัดเลือกจากสถานีวัดน้ำท่าของกรมชลประทานและ สถานีวัดระดับน้ำของสถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน) ที่มีการรายงานข้อมูลปริมาณน้ำท่าหรือระดับน้ำแบบ real time ทั้งรายชั่วโมงหรือรายวัน </p>
            <Box mt={2}><p className={classes.body}>ระบบทำการประมวลผลวันละ 1 ครั้ง โดยคาดการณ์น้ำท่าและระดับน้ำ 7 วันล่วงหน้า เพื่อนำไปใช้ในการสนับสนุนการตัดสินใจเพื่อบริหาร จัดการอุทกภัยได้ทันต่อเหตุการณ์และมีประสิทธิภาพ</p></Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CompInfo;
