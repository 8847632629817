import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import styles from './Style';

export default function RainForecastMonthNavigation(props) {
  const { t } = useTranslation();
  const [_mounted, setMounted] = useState(true);
  const [value, setValue] = useState('anomaly_rain_1m.tif');
  const [isLoading, setLoading] = useState(true);
  const [monthName, setMonth] = useState([]);
  const [monthObj, setMonthObj] = useState({});

  const filename = ['anomaly_rain_1m.tif', 'anomaly_rain_2m.tif', 'anomaly_rain_3m.tif', 'anomaly_rain_4m.tif', 'anomaly_rain_5m.tif', 'anomaly_rain_6m.tif'];

  function handleChange(event) {
    // setLoading(true);
    setValue(event.target.value);
    props.handler(event.target.value, monthObj[event.target.value]);
    // setLoading(false);
  }

  useEffect(() => {
    if (_mounted) {
      setLoading(false);
    }

    async function fetchData() {
      const url = 'https://live1.hii.or.th/product/latest/rain/one_map/data/anomaly_rain_6m.json';

      fetch(url)
        .then(response => response.json())
        .then(data => {
          const m = [];
          data.slice(0).forEach(element => {
            element.value.forEach(val => {
              val.map(key => {
                m.push(moment(key.date).add(543, 'y').format('MMMM YY'));
                return null;
              });
              return null;
            });
          });

          filename.map((k, index) => {
            monthObj[k] = m[index];
            return null;
          });

          setMonthObj(monthObj);
          setMonth(m);

          // init month name
          props.handlerInitMonth(m[0]);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
        });
    }

    // fetch data
    fetchData();

    // componentWillUnmount
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <>
      {isLoading === true && (
        <div className="text-center">
          Loading...
        </div>
      )}
      <Paper style={styles.menuControlStyle}>
        <FormControl component="fieldset">
          <Box component="span" m={1} style={styles.mainmenu}>
            {t('ความผิดปกติของปริมาณฝน')}
          </Box>
          <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
            <RadioGroup
              aria-label="forecast_volumn"
              name="ForecastVolume"
              value={value}
              onChange={handleChange}
            >
              {filename.map((val, i) => (
                <FormControlLabel
                  key={val}
                  value={val}
                  control={<Radio />}
                  label={monthName[i]}
                />
              ))}
            </RadioGroup>
          </div>
        </FormControl>
      </Paper>
    </>
  );
}

RainForecastMonthNavigation.propTypes = {
  handler: PropTypes.func.isRequired,
  handlerInitMonth: PropTypes.func.isRequired,
};
