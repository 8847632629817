import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

import { useTranslation } from 'react-i18next';

// leaflet
import L from 'leaflet';
import 'plotty';
import 'leaflet-geotiff';
import '../../widgets/map/leaflet-geotiff-plotty';

// style
import './Style.css';
import 'leaflet/dist/leaflet.css';

// components
import Legend from './Legend';
import WrfRomsNavigation from './WrfRomsNavigation';

const initPathWMS = process.env.MIX_APP_WMS;
const useStyles = makeStyles(theme => ({
  styleBanner: {
    zIndex: 99999,
    textAlign: 'center',
    position: 'fixed',
    top: '30px',
    left: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function WrfRomsMap() {
  // set variable env.
  const initPath = process.env.MIX_APP_PATH;
  const banner = `${initPath}images/models/banner_model-wrfroms.png`;
  const { t } = useTranslation();

  // variable reference.
  const refMap = useRef(null);
  const refWrfRoms = useRef(null);

  const [modal, setModal] = useState({
    open: false,
    modalHeader: null,
    modalSubHeader: null,
    modalContent: null,
  });
  const [getOpenLegend, setOpenLegend] = useState(false);
  const [getDate, _setDate] = useState('ตรวจวัด');
  const myStateRef = React.useRef(getDate);
  const setDate = data => {
    myStateRef.current = data;
    _setDate(data);
  };

  const handleModalOpen = params => {
    setModal({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  };

  const handleModalClose = () => {
    setModal({ open: false });
  };

  const getModalStyle = () => {
    const width = 'lg';
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = '60%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '60%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      zIndex: 2147483647,
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  };

  const handleClickLegend = () => {
    if (getOpenLegend === false) {
      setOpenLegend(true);
    } else {
      setOpenLegend(false);
    }
  };

  function handleInitDate(val) {
    setDate(val);
  }

  function handleDateChange(url, txt) {
    setDate(txt);
    refWrfRoms.current.setURL(url);
  }

  // open rainfall popup when click map
  function handleGeotiffLayerClick(e) {
    const { lat, lng } = e.latlng;

    // set boundary
    if (lat > 21.84 || lat < 4.3 || lng < 96.54 || lng > 108) {
      // console.log(lat, lng)
    } else {
      let val = parseFloat(refWrfRoms.current.getValueAtLatLng(lat, lng))
        .toFixed(1);

      // clean up no rainfall data
      if (Number.isNaN(val) || parseInt(val, 10) < 0) {
        val = '--';
      }

      const html = `<strong>${myStateRef.current}</strong><hr /><span class="popupText">ปริมาณฝน <strong>${val}</strong> มม.<br />Lat: ${lat.toFixed(3)}, Lng: ${lng.toFixed(3)}</span>`;
      L.popup().setLatLng(e.latlng).setContent(html).openOn(refMap.current);
    }
  }

  // create map
  useEffect(() => {
    // base map
    // https://gis.stackexchange.com/questions/225098/using-google-maps-static-tiles-with-leaflet
    // https://stackoverflow.com/questions/33343881/leaflet-in-google-maps
    const baseMaps = {
      'OpenStreetMap.Mapnik': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { checked: true }),
      'ESRI World Topo': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'),
      BlackAndWhite: L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'),
      'Google Streets': L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      'Google Hybrid': L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      'Google Sattlite': L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      'Google Terrain': L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    };

    // add wms layer
    const boundary = L.tileLayer.wms(`${initPathWMS}wms`, {
      layers: 'thaiwater30:boundary_province',
      format: 'image/png',
      transparent: true,
      tiled: true,
    });

    // init map
    refMap.current = L.map('map', {
      center: [13, 101.6],
      zoom: 6,
      layers: [
        baseMaps['OpenStreetMap.Mapnik'], boundary
      ],
    });

    refMap.current.on('click', e => {
      handleGeotiffLayerClick(e);
    });

    // map control
    L.control.layers(baseMaps, null, { position: 'topleft' }).addTo(refMap.current);
    const infoControl = L.control({ position: 'topleft' });

    let div;
    infoControl.onAdd = function add() {
      div = L.DomUtil.create('div', 'info');
      infoControl.update();

      return div;
    };

    infoControl.update = function update() {
      div.innerHTML = 'Info';

      document.body.addEventListener('click', e => {
        if (e.target.className === 'info leaflet-control') {
          handleModalOpen(
            {
              header: t('การคาดการณ์ฝนล่วงหน้า 3 วัน ด้วยแบบจำลอง WRF-ROMS'),
              content: t('เนื้อหา'),
            },
          );
        }
      });
    };
    infoControl.addTo(refMap.current);

    // add geotiff layer
    const geotiffUrl = 'https://live1.hii.or.th/product/latest/wrfroms/tiff/fcst_rain24hr_d03_day1.tif';
    const geotiffOptions = {
      band: 0,
      name: 'Composite Radar',
      renderer: new L.LeafletGeotiff.Plotty({
        displayMin: 1.01,
        displayMax: 400,
        colorScale: 'wrfcolorscale',
        clampLow: false,
        clampHigh: false,
      }),
    };

    refWrfRoms.current = L.leafletGeotiff(geotiffUrl, geotiffOptions)
      .addTo(refMap.current);

    // add top margin to leaflet control
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '100px';

    // add opacity
    document.getElementsByClassName('leaflet-overlay-pane')[0].style.opacity = 0.5;
  }, []);

  const classes = useStyles();
  return (
    <>
      {/* map */}
      <div
        id="map"
        style={{
          height: window.height,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 99999,
        }}
      >
        {/* Legend */}
        {getOpenLegend ? <Legend /> : null }
        <Button
          variant="contained"
          style={{
            backgroundColor: 'white',
            zIndex: 99999,
            position: 'fixed',
            bottom: '1%',
            left: '1%',
          }}
          aria-haspopup="true"
          onClick={handleClickLegend}
        >
          {t('ปริมาณฝน (มม.)')}
        </Button>

        <WrfRomsNavigation handler={handleDateChange} handlerInitDate={handleInitDate} />

        <img src={banner} alt="banner" className={classes.styleBanner} />

      </div>

      <Modal
        id="modal-chart"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modal.open}
        onClose={handleModalClose}
      >
        <div style={getModalStyle()}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="h5" id="modal-title">
            {modal.modalHeader}
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
            {modal.modalSubHeader}
          </Typography>
          <Box mt={3}>
            {modal.modalContent}
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default withWidth()(WrfRomsMap);
