
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';

// color .
import grey from '@material-ui/core/colors/grey';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

// icon .
import { FaHome, FaSpinner } from '../../utils/Fontawesome';
import { HiiMediaIcon, HiiPresentIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class BookAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      bookList: [],
      error: null,
    };

    this.urlEncode = this.urlEncode.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.getData();
  }

  getData() {
    Promise.all([
      fetch(`${initPath}json/contents/medias/book.json`),
    ])
      .then(([book]) => Promise.all([book.json()]))
      .then(([bookList]) => {
        this.setState({ bookList });
      })
      .catch(error => this.setState({ error }));
  }

  urlEncode = url => Buffer.from(url).toString('base64');

  render() {
    const { classes, t } = this.props;
    const {
      bookList, isLoading, error,
    } = this.state;

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}media`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('คลังสื่อ เผยแพร่')}
                </Typography>
              </Button>
              <Button href={`${initPath}media/book/all`}>
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('หนังสือ')}
                </Typography>
              </Button>
              <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                {t('ทั้งหมด')}
              </Typography>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiMediaIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4" gutterBottom>
                      {t('คลังสื่อ เผยแพร่')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {(error) && (
            <Grid item xs={12} md={12} lg={12}>
              <Box alignItems="center">{error.message}</Box>
            </Grid>
          )}

          {(isLoading) && (
            <Grid item xs={12} md={12} lg={12}>
              <Box className="text-center">
                <FaSpinner size={30} />
              </Box>
            </Grid>
          )}

          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={3} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Box display="flex" width="100%">
                    <Box display="inline" pt={1}>
                      <HiiPresentIcon fontSize={24} />
                    </Box>
                    <Box display="inline" pt={1} pl={1}>
                      <Typography variant="h4" gutterBottom>
                        {t('คู่มือการใช้งาน')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Grid>
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={3} key={uniqueId()}>
            <Link display="block" underline="none" title={t('คู่มือการใช้งานเว็บไซต์')} href="https://flipbook.hii.or.th/publishing/23Thaiwater-manual/#p=1">
              <Box p={2} className={classes.outLined}>
                <img
                  src={`${initPath}images/theme/banner/Cover_Thaiwater_manual_guide.jpg`}
                  style={{ margin: 16, maxWidth: '-webkit-fill-available' }}
                  width="100%"
                  alt={t('คู่มือการใช้งานเว็บไซต์')}
                />
                <Box px={2} pt={0} alignItems="center" justifyContent="center">
                  <Box style={{ minHeight: '50px' }} fontSize={{ xs: 12, md: 14 }} color={grey[800]}>
                    {t('คู่มือการใช้งานเว็บไซต์')}
                  </Box>
                </Box>
                <Link display="block" underline="none" title={t('คู่มือการใช้งานเว็บไซต์')} href="https://flipbook.hii.or.th/publishing/23Thaiwater-manual/#p=1" target="_blank">
                  <Box px={2} className={classes.buttonDownload} align="center">{t('อ่านอีบุ๊ค')}</Box>
                </Link>
              </Box>
            </Link>
          </Grid>

          {bookList.map(row => (
            <Grid item xs={6} sm={6} md={3} lg={3} key={uniqueId()}>
              <Link display="block" underline="none" title={t(row.title)} href={`${initPath}media/book/view/${this.urlEncode(`${row.link}`)}`}>
                <Box p={2} className={classes.outLined}>
                  <img
                    src={`${initPath}${row.thumbnail}`}
                    style={{ margin: 16, maxWidth: '-webkit-fill-available' }}
                    width="100%"
                    alt={t(row.title)}
                  />
                  <Box px={2} pt={0} alignItems="center" justifyContent="center">
                    <Box style={{ minHeight: '50px' }} fontSize={{ xs: 12, md: 14 }} color={grey[800]}>
                      {t(row.title)}
                    </Box>
                  </Box>
                  <Link display="block" underline="none" title={row.title} href={`${initPath}${row.link}`} download={`${t(row.title)}`}>
                    <Box px={2} className={classes.buttonDownload} align="center">{t('ดาวน์โหลด')}</Box>
                  </Link>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

BookAll.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(BookAll));
