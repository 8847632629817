import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import blue from '@material-ui/core/colors/blue';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { FaAngleRight, FaAngleLeft } from '../../utils/Fontawesome';
import { PmStatusColor } from '../weather/pm2.5/PmStatusColor';
// translate
import tJson from '../../utils/TJson';

// icon
import {
  HiiThailandIcon,
  HiiStormIcon,
  HiiForecastRainIcon,
  HiiWaveIcon,
  HiiTakeMaskIcon,
} from '../../utils/Icon';

import '../../../../public/css/slick.css';

import {
  styles,
} from '../../styles/Style';

const RainforecastStatusColor = type => {
  let color = '';
  if (type === 4) {
    color = '#f0ad4e';
  } else if (type === 5) {
    color = '#ff0000';
  }
  return color;
};

const RainforecastStatusTextColor = type => {
  let color = '';
  if (type === 4) {
    color = '#000';
  } else if (type === 5) {
    color = '#fff';
  }
  return color;
};

const WaveStatusColor = type => {
  let color = '';
  if (type > 2) {
    color = '#FF0000';
  } else if (type >= 1) {
    color = '#6AC904';
  } else if (type < 1) {
    color = '#FFFFFF';
  }
  return color;
};

const WaveStatusTextColor = type => {
  let color = '';
  if (type > 2) {
    color = '#fff';
  } else if (type >= 1) {
    color = '#000';
  } else if (type < 1) {
    color = '#fff';
  }
  return color;
};

const primary = blue[800];

function NextArrow(props) {
  const { onClick } = props; // className
  return (
    <FaAngleRight
      style={{
        position: 'absolute',
        backgroundColor: primary,
        borderColor: '#555',
        color: '#fff',
        bottom: -30,
        right: 22,
        fontSize: '1.5em',
      }}
      onClick={onClick}
    />
  );
}
function PrevArrow(props) {
  const { onClick } = props; // className,
  return (
    <FaAngleLeft
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#555',
        bottom: -30,
        right: 48,
        fontSize: '1.5em',
      }}
      onClick={onClick}
    />
  );
}

class Situation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storm: [],
      preRain: [],
      wave: [],
      Pm25: [],
      windowsWidth: 0,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.getData();
    this.getDataPm25();
    window.addEventListener('resize', this.setState({ windowsWidth: window.innerWidth }));
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener('resize', this.setState({ windowsWidth: window.innerWidth }));
  }

  getData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/thailand`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const storm = result.storm.data.data;
        const preRain = result.pre_rain.data.data;
        const wave = result.wave.data.data;

        this.setState({
          storm,
          preRain,
          wave,
        });
      })
      .catch(
        // error => this.setState({ error, isLoading: false })
      );
  }

  getDataPm25() {
    fetch(`${process.env.MIX_API_URL}public/thaiwater/weather`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataPm25 = result.pm25.data || [];
        const uniqueDataPm25 = [...new Set(dataPm25.map(item => item.geocode.province_code))].map(provinceCode => {
          const filteredData = dataPm25.filter(item => item.geocode.province_code === provinceCode);
          const { ...rest } = filteredData[0];
          return { ...rest };
        });
        this.setState({
          Pm25: uniqueDataPm25,
        });
      });
  }

  render() {
    const { classes, t } = this.props;
    const {
      storm, preRain, wave, windowsWidth, Pm25,
    } = this.state;

    const settings = {
      autoplay: false,
      autoplaySpeed: 2000,
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 786,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 414,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };

    return (
      <Fragment key="key">
        <div className={classes.layout}>
          <Box boxShadow={3} borderRadius={5} border={1} borderColor="#ccc">
            {windowsWidth <= 960 && (
              <Slider {...settings}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Box display="flex" p={0} alignItems="center" justifyContent="center" width="100%">
                      <Box display="inline" p={1} pt={0} pb={0} mb={-1} width="30%">
                        <Box p={0} pt={0} pb={0} mb={-1} align="center">
                          <HiiThailandIcon style={{ fontSize: 40 }} />
                        </Box>
                      </Box>
                      <Box display="inline" p={1} px={0} m={1} alignItems="center" justifyContent="center" width="70%">
                        <Box display="flex">
                          <Box mt={0.5} fontSize={{ xs: 16, lg: 22 }} fontFamily="Kanit" flexWrap="wrap">
                            {t('สถานการณ์')}
                          </Box>
                          <Box
                            ml={1}
                            mt={0.8}
                            borderRadius={3}
                            style={{
                              backgroundColor: '#f10909',
                              color: '#fff',
                              height: '16px',
                              fontSize: '10px',
                              fontWeight: '600',
                              padding: '0px 6px 0px 6px',
                              borderRadius: '11px',
                              letterSpacing: '1px',
                              width: 40,
                            }}
                          >
                            {t('ล่าสุด')}
                          </Box>
                        </Box>
                        <Box fontSize={12} fontFamily="Kanit" flexWrap="wrap">
                          {t('ประเทศไทย')}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={2} lg={2} className={classes.borderLeft}>
                    <Box display="flex" p={0} alignItems="center" justifyContent="center" width="100%">
                      <Box display="inline" pt={1} p={0} width="30%">
                        <Box p={1} pb={0} mb={-2} align="center">
                          <HiiStormIcon />
                        </Box>
                        <Box fontSize={12} m={1} p={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit">
                          {t('พายุ')}
                        </Box>
                      </Box>
                      <Box display="block" pt={1} pl={0} flexWrap="wrap" width="70%">
                        {storm.length === 0 && (
                          <>
                            <Button variant="outlined" href="#outlined-buttons" className={classes.buttonWidget}>
                              {t('ไม่มีพายุ')}
                            </Button>
                            <Box p={1}>{t('เข้าไกล้ประเทศไทย')}</Box>
                          </>
                        )}

                        {storm.slice(0, 6).map((row, i) => {
                          const key = i;
                          return (
                            <Button key={key} variant="contained" href="#outlined-buttons" color="primary" className={classes.buttonStorm}>
                              {row.storm_name}
                            </Button>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={4} lg={4} className={classes.borderLeft}>
                    <Box display="flex" p={0} alignItems="center" justifyContent="center" width="100%">
                      <Box display="inline" p={1} width="30%">
                        <Box p={1} pr={0} pb={0} mb={-1} align="center">
                          <HiiForecastRainIcon />
                        </Box>
                        <Box fontSize={12} m={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit" flexWrap="wrap">
                          {t('คาดการณ์ฝน')}
                        </Box>
                      </Box>
                      <Box display="inline" p={0} width="70%">
                        {preRain.length === 0 && (
                          <Button variant="outlined" href="#outlined-buttons" className={classes.buttonWidget}>
                            {t('ปกติ')}
                          </Button>
                        )}
                        {preRain.sort((a, b) => b.rainforecast_level - a.rainforecast_level)
                          .slice(0, 6).map((row, i) => {
                            const bgColor = RainforecastStatusColor(row.rainforecast_level);
                            const txtColor = RainforecastStatusTextColor(row.rainforecast_level);
                            const key = i;
                            return (
                              <Button key={key} variant="contained" href="#outlined-buttons" className={classes.buttonWidget} style={{ backgroundColor: bgColor, color: txtColor }}>
                                {`${tJson(row.province_name, 'จ.')}`}
                              </Button>
                            );
                          })}
                        {/* <Button variant="outlined" href="#outlined-buttons"
                        className={classes.buttonWidget}>{'+ เพิ่มเติม'} </Button> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={4} lg={4} className={classes.borderLeft}>
                    <Box display="flex" p={0} alignItems="center" justifyContent="center">
                      <Box display="inline" p={1} pr={0} m={0} width="30%">
                        <Box m={0} p={1} pb={0} mb={-1} align="center"><HiiWaveIcon /></Box>
                        <Box fontSize={12} m={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit" flexWrap="wrap">
                          {t('คาดการณ์คลื่น')}
                        </Box>
                      </Box>
                      <Box display="inline" alignItems="center" justifyContent="center" p={0} width="70%">
                        {wave.length === 0 && (
                          <Button variant="outlined" href="#outlined-buttons" className={classes.buttonWidget}>
                            {t('ปกติ')}
                          </Button>
                        )}

                        {wave.slice(0, 6).map((row, i) => {
                          const bgColor = WaveStatusColor(row.highsig);
                          const key = i;
                          return (
                            <Button key={key} variant="contained" href="#outlined-buttons" className={classes.buttonWidget} style={{ backgroundColor: bgColor }}>
                              {`${tJson(row.swan_station.province_name, 'จ.')}`}
                            </Button>
                          );
                        })}
                        {/* <Button variant="outlined" href="#outlined-buttons"
                        className={classes.buttonWidget}>+ เพิ่มเติม</Button> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={4} lg={4} className={classes.borderLeft}>
                    <Box display="flex" p={0} alignItems="center" justifyContent="flex-start">
                      <Box display="inline" p={1} pr={0} m={0} width="30%">
                        <Box m={0} p={1} pb={0} mb={-1} align="center"><HiiTakeMaskIcon style={{ fontSize: '3em' }} /></Box>
                        <Box fontSize={12} m={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit" flexWrap="wrap">
                          {t('คุณภาพอากาศ')}
                        </Box>
                      </Box>
                      <Box display="inline" alignItems="center" justifyContent="center" p={0} width="70%">
                        {/* สถานะปกติ */}
                        {/* {wave.length === 0 && (
                          <Box className={classes.buttonWidgetPmOutlined}>
                            {t('ปกติ')}
                          </Box>
                        )} */}

                        {/* สถานะมีข้อมูล */}
                        {/* {wave.slice(0, 6).map((row, i) => {
                          const bgColor = WaveStatusColor(row.highsig);
                          const txtColor = WaveStatusTextColor(row.highsig);
                          const key = i;
                          return (
                            <Box
                              display="inline-block"
                              key={key}
                              className={classes.buttonWidget}
                              style={{ backgroundColor: bgColor, color: txtColor }}
                            >
                              {`${tJson(row.swan_station.province_name, 'จ.')}`}
                            </Box>
                          );
                        })} */}

                        {/* template */}
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#EE141F', color: '000' }}>เชียงใหม่</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#EE141F', color: '000' }}>ตาก</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#FFFD54', color: '000' }}>น่าน</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#9ECC63', color: '000' }}>แพร่</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#9ECC63', color: '000' }}>ลำพูน</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#EE141F', color: '000' }}>อยุธยา</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#EE141F', color: '000' }}>พิษณุโลก</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#FFFD54', color: '000' }}>ลำปาง</Box>
                        <Box display="inline-block" className={classes.buttonWidget} style={{ backgroundColor: '#9ECC63', color: '000' }}>พะเยา</Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Slider>
            )}
            {windowsWidth > 960 && (
              <Slider {...settings} style={{ flexDirection: 'column', width: '100%' }}>
                <Box>
                  <Grid container spacing={0} style={{ height: 100 }}>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <Box display="flex" pt={1} alignItems="center" justifyContent="center" width="100%">
                        <Box display="inline" p={1} pt={0} pb={0} mb={-1} width="30%">
                          <Box p={0} pt={0} pb={0} mb={-1} align="center">
                            <HiiThailandIcon style={{ fontSize: 40 }} />
                          </Box>
                        </Box>
                        <Box display="inline" p={1} px={0} m={1} alignItems="center" justifyContent="center" width="70%">
                          <Box display="flex">
                            <Box mt={0.5} fontSize={{ xs: 16, lg: 22 }} fontFamily="Kanit" flexWrap="wrap">
                              {t('สถานการณ์')}
                            </Box>
                            <Box
                              pl={0.5}
                              mt={-2}
                              borderRadius={3}
                              style={{
                                backgroundColor: '#f10909',
                                color: '#fff',
                                height: '16px',
                                fontSize: '10px',
                                fontWeight: '600',
                                padding: '0px 6px 0px 6px',
                                borderRadius: '11px',
                                margin: '-10px 0px 0px -18px',
                                letterSpacing: '1px',
                                width: 40,
                              }}
                            >
                              {t('ล่าสุด')}
                            </Box>
                          </Box>
                          <Box fontSize={12} fontFamily="Kanit" flexWrap="wrap">
                            {t('ประเทศไทย')}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2} className={classes.borderLeft}>
                      <Box display="flex" pt={1} alignItems="center" justifyContent="center" width="100%">
                        <Box display="inline" pt={1} p={0} width="30%">
                          <Box p={1} pb={0} mb={-2} align="center">
                            <HiiStormIcon />
                          </Box>
                          <Box fontSize={12} m={1} p={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit">
                            {t('พายุ')}
                          </Box>
                        </Box>
                        <Box display="inline" p={1} pl={0} flexWrap="wrap" width="70%">
                          {storm.length === 0 && (
                            <>
                              <Box mb={1} className={classes.buttonWidgetStormOutlined}>
                                {t('ไม่มีพายุ')}
                              </Box>
                              {t('เข้าไกล้ประเทศไทย')}
                            </>
                          )}

                          {storm.slice(0, 6).map((row, i) => {
                            const key = i;
                            return (
                              <Box
                                display="inline-block"
                                key={key}
                                className={classes.buttonWidget}
                              >
                                {row.storm_name}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} className={classes.borderLeft}>
                      <Box display="flex" pt={1} alignItems="center" justifyContent="center" width="100%">
                        <Box display="inline" p={1} width="30%">
                          <Box p={1} pr={0} pb={0} mb={-1} align="center">
                            <HiiForecastRainIcon />
                          </Box>
                          <Box fontSize={12} m={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit" flexWrap="wrap">
                            {t('คาดการณ์ฝน')}
                          </Box>
                        </Box>
                        <Box display="inline" p={0} width="70%">
                          {preRain.length === 0 && (
                            <Box className={classes.buttonWidgetOutlined}>
                              {t('ปกติ')}
                            </Box>
                          )}

                          {preRain.sort((a, b) => b.rainforecast_level - a.rainforecast_level)
                            .slice(0, 6).map((row, i) => {
                              const bgColor = RainforecastStatusColor(row.rainforecast_level);
                              const txtColor = RainforecastStatusTextColor(row.rainforecast_level);
                              const key = i;
                              return (
                                <Box
                                  display="inline-block"
                                  key={key}
                                  className={classes.buttonWidget}
                                  style={{ backgroundColor: bgColor, color: txtColor }}
                                >
                                  {`${tJson(row.province_name, 'จ.')}`}
                                </Box>
                              );
                            })}
                          {/* <Button variant="outlined" href="#outlined-buttons"
                          className={classes.buttonWidget}>{'+ เพิ่มเติม'} </Button> */}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} className={classes.borderLeft}>
                      <Box display="flex" pt={1} alignItems="center" justifyContent="center">
                        <Box display="inline" p={1} pr={0} m={0} width="30%">
                          <Box m={0} p={1} pb={0} mb={-1} align="center"><HiiWaveIcon /></Box>
                          <Box fontSize={12} m={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit" flexWrap="wrap">
                            {t('คาดการณ์คลื่น')}
                          </Box>
                        </Box>
                        <Box display="inline" alignItems="center" justifyContent="center" p={0} width="70%">
                          {wave.length === 0 && (
                            <Box className={classes.buttonWidgetOutlined}>
                              {t('ปกติ')}
                            </Box>
                          )}

                          {wave.slice(0, 6).map((row, i) => {
                            const bgColor = WaveStatusColor(row.highsig);
                            const txtColor = WaveStatusTextColor(row.highsig);
                            const key = i;
                            return (
                              <Box
                                display="inline-block"
                                key={key}
                                className={classes.buttonWidget}
                                style={{ backgroundColor: bgColor, color: txtColor }}
                              >
                                {`${tJson(row.swan_station.province_name, 'จ.')}`}
                              </Box>
                            );
                          })}
                          {/* <Button variant="outlined" href="#outlined-buttons"
                          className={classes.buttonWidget}>+ เพิ่มเติม</Button> */}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container direction="row" spacing={0} style={{ height: 100 }}>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Box display="flex" pt={1} alignItems="center" justifyContent="center" width="100%">
                        <Box display="inline" p={1} pt={0} pb={0} mb={-1} width="30%">
                          <Box p={0} pt={0} pb={0} mb={-1} align="center">
                            <HiiThailandIcon style={{ fontSize: 40 }} />
                          </Box>
                        </Box>
                        <Box display="inline" p={1} px={0} m={1} alignItems="center" justifyContent="center" width="70%">
                          <Box display="flex">
                            <Box mt={0.5} fontSize={{ xs: 16, lg: 22 }} fontFamily="Kanit" flexWrap="wrap">
                              {t('สถานการณ์')}
                            </Box>
                            <Box
                              pl={0.5}
                              mt={-2}
                              borderRadius={3}
                              style={{
                                backgroundColor: '#f10909',
                                color: '#fff',
                                height: '16px',
                                fontSize: '10px',
                                fontWeight: '600',
                                padding: '0px 6px 0px 6px',
                                borderRadius: '11px',
                                margin: '-10px 0px 0px -18px',
                                letterSpacing: '1px',
                                width: 40,
                              }}
                            >
                              {t('ล่าสุด')}
                            </Box>
                          </Box>
                          <Box fontSize={12} fontFamily="Kanit" flexWrap="wrap">
                            {t('ประเทศไทย')}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10} className={classes.borderLeft}>
                      <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" style={{ paddingTop: 5 }}>
                        <Box display="block" p={1} pr={0} m={0} width="15%">
                          <Box m={0} p={1} pb={0} mb={-1} align="center"><HiiTakeMaskIcon style={{ fontSize: '3.5em' }} /></Box>
                          <Box fontSize={13} m={0} align="center" fontWeight="fontWeightMedium" fontFamily="Kanit" flexWrap="wrap">
                            {t('คุณภาพอากาศ')}
                          </Box>
                        </Box>
                        <Box display="flex" flexWrap="wrap" alignItems="center" p={0} width="85%">
                          {/* สถานะปกติ */}
                          {/* {wave.length === 0 && (
                            <Box className={classes.buttonWidgetPmOutlined}>
                              {t('ปกติ')}
                            </Box>
                          )} */}

                          {/* สถานะมีข้อมูล */}
                          {/* {wave.slice(0, 6).map((row, i) => {
                            const bgColor = WaveStatusColor(row.highsig);
                            const txtColor = WaveStatusTextColor(row.highsig);

                            return (
                              <Box
                                display="inline-block"
                                key={key}
                                className={classes.buttonWidget}
                                style={{ backgroundColor: bgColor, color: txtColor }}
                              >
                                {`${tJson(row.swan_station.province_name, 'จ.')}`}
                              </Box>
                            );
                          })} */}
                          {Pm25.slice(0, 14).map((row, i) => {
                            const key = i;
                            const statusColor = PmStatusColor(row.pm25_value, 'layerPm25');
                            return (
                              <Box key={key} className={classes.buttonWidgetPm} style={{ backgroundColor: statusColor.color.bgColorSitComp, color: '000' }}>
                                {' '}
                                {`${tJson(row.geocode.province_name, 'จ.')}`}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Slider>
            )}
          </Box>
        </div>
      </Fragment>
    );
  }
}

Situation.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

NextArrow.defaultProps = {
  // className: '',
  onClick: () => {},
};

PrevArrow.defaultProps = {
  // className: '',
  onClick: () => {},
};

NextArrow.propTypes = {
  // className: PropTypes.string,
  onClick: PropTypes.func,
};

PrevArrow.propTypes = {
  // className: PropTypes.string,
  onClick: PropTypes.func,
};

export default compose(withStyles(styles), withTranslation())(Situation);
