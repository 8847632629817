/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import index from 'react-leaflet-fullscreen';
import WindowsSize from '../../utils/WindowsSize';
import WindowScreen from '../../utils/WindowsFullScreen';
// error404
import Error404 from '../error404/Error404';
// ติดตามสถานการณ์น้ำ
import Waterlevel from './Waterlevel';
import { styles } from '../../styles/Style';
import Img from 'react-image';
import Link from '@material-ui/core/Link';

// const basinGroup = [
//   {
//     isShow: false,
//     name: 'ลุ่มน้ำเจ้าพระยาตอนล่าง',
//     areatext: 'จังหวัดในลุ่มน้ำเจ้าพระยาตอนล่าง',
//     basincode: [10, 13, 12, 15],
//     location_zone: [14.779185903256655, 100.69189333485738],
//     geojson: 'json/boundary/basin_chaophraya_merge.json',
//   },
//   {
//     isShow: true,
//     name: 'ลุ่มน้ำชี-มูล',
//     areatext: 'จังหวัดในลุ่มน้ำชี-มูล',
//     basincode: [4, 5],
//     location_zone: [16.087111954219314, 103.4293411452528],
//     geojson: 'json/boundary/basin_chimun_merge.json',
//   },
//   {
//     isShow: true,
//     name: 'ลุ่มน้ำภาคใต้',
//     areatext: 'จังหวัดภาคใต้',
//     basincode: [18, 19, 20, 21, 22],
//     location_zone: [9.279185903256655, 100.09189333485738],
//     geojson: 'json/boundary/south_merge.json',
//   },
// ];

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const initPath = process.env.MIX_APP_PATH;
export default function WaterlevelTab(props) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [basinGroup, setBasinGroup] = React.useState([]);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { category } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${initPath}json/dashboard/waterlevel.json`);

        if (!response.ok) {
          throw new Error('Something went wrong ...');
        }
        const result = await response.json();
        // console.log('result: ', result);
        setBasinGroup(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const windowSize = WindowsSize();
  const windowsScreen = WindowScreen();

  // map style
  const mapStyle = {
    left: (windowSize.width >= 1160 && windowsScreen !== 'xl') ? (1160 - windowSize.width) / 2 : 'auto',
    width: (windowSize.width >= 1160 && windowsScreen !== 'xl') ? (windowSize.width + 30) : '100%',
    minHeight: '850px',
    position: 'relative',
    backgroundColor: props.warroom_tab ? '#ffffff00' : '#f3f3f3',
    borderBottom: '4px solid #ccc',
    paddingBottom: 35,
    // zIndex: 99999,
  };

  const renderTab = () => {
    // category -> chaophaya, chimun, south
    // example -> https://www.thaiwater.net/latest-situation/south
    let text;
    // console.log('category: ', category);
    if (category === undefined) {
      text = (
        // {/* 2023-09-27 รับสั่งจาก วรรธนศักดิ์ สุปะกิ่ง ให้เปิดการใช้งาน ติดตามสถานการณ์น้ำท่วม 66 - 67 เฉพาะ tab ชี-มูล */}
        // {/* 2023-09-28 รับสั่งจาก วรรธนศักดิ์ สุปะกิ่ง ให้เพิ่มการใช้งานสถานีฝน 24 ชม. เฉพาะ tab ชี-มูล */}
        // {/* 2023-10-02 รับสั่งจาก วรรธนศักดิ์ สุปะกิ่ง ให้เปิดแสดง ติดตามสถานการณ์น้ำท่วม ลุ่มน้ำเจ้าพระยา */}
        // {/* 2023-11-22 รับสั่งจาก วรรธนศักดิ์ สุปะกิ่ง ให้เปิดแสดง ติดตามสถานการณ์น้ำท่วม เฉพาะ ภาคใต้ และเพิ่มการใช้งานสถานีฝน 24 ชม. */}
        // 2024-09-16 รับสั่งจาก วาสุกรี แซ่เตีย ในกลุ่มตรวจสอบ ให้เพิ่ม tab สถานการณ์น้ำ ภาคตะวันออกเฉียงเหนือ
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="nav tabs example"
              className={classes.boxTabWaterStyle}
            >
              {basinGroup
                .filter(basin => basin.isShow)
                .map((data, i) => (
                  <LinkTab
                    key={i}
                    label={t(data.title)}
                    href="#"
                    {...a11yProps(i)}
                    className={value === i ? classes.active_link_tab : classes.link_tab}
                  />
                ))}
            </Tabs>
          </AppBar>
          {basinGroup
            .filter(basin => basin.isShow)
            .map((data, i) => (
              <TabPanel key={i} value={value} index={i}>
                <Waterlevel
                  basinName={data.name}
                  filText={data.areatext}
                  basinCode={data.basincode}
                  locationZone={data.location_zone}
                  geojson={data.geojson}
                />
              </TabPanel>
            ))}
        </>
      );
    } else if (category === 'chimun') {
      text = (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="nav tabs example"
              className={classes.boxTabWaterStyle}
            >
              <LinkTab
                label={t('ลุ่มน้ำชี-มูล')}
                href="#"
                {...a11yProps(0)}
                className={value === 0 ? classes.active_link_tab : classes.link_tab}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Waterlevel
              basinName={basinGroup[1].name}
              filText={basinGroup[1].areatext}
              basinCode={basinGroup[1].basincode}
              locationZone={basinGroup[1].location_zone}
              geojson={basinGroup[1].geojson}
            />
          </TabPanel>
        </>
      );
    } else if (category === 'chaophaya') {
      text = (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="nav tabs example"
              className={classes.boxTabWaterStyle}
            >
              <LinkTab
                label={t('ลุ่มน้ำเจ้าพระยาตอนล่าง')}
                href="#"
                {...a11yProps(0)}
                className={value === 0 ? classes.active_link_tab : classes.link_tab}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Waterlevel
              basinName={basinGroup[0].name}
              filText={basinGroup[0].areatext}
              basinCode={basinGroup[0].basincode}
              locationZone={basinGroup[0].location_zone}
              geojson={basinGroup[0].geojson}
            />
          </TabPanel>
        </>
      );
    } else if (category === 'south') {
      text = (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="nav tabs example"
              className={classes.boxTabWaterStyle}
            >
              <LinkTab
                label={t('ภาคใต้')}
                href="#"
                {...a11yProps(0)}
                className={value === 0 ? classes.active_link_tab : classes.link_tab}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Waterlevel
              basinName={basinGroup[2].name}
              filText={basinGroup[2].areatext}
              basinCode={basinGroup[2].basincode}
              locationZone={basinGroup[2].location_zone}
              geojson={basinGroup[2].geojson}
            />
          </TabPanel>
        </>
      );
    } else if (category === 'north') {
      text = (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="nav tabs example"
              className={classes.boxTabWaterStyle}
            >
              <LinkTab
                label={t('ภาคเหนือ')}
                href="#"
                {...a11yProps(0)}
                className={value === 0 ? classes.active_link_tab : classes.link_tab}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Waterlevel
              basinName={basinGroup[3].name}
              filText={basinGroup[3].areatext}
              basinCode={basinGroup[3].basincode}
              locationZone={basinGroup[3].location_zone}
              geojson={basinGroup[3].geojson}
            />
          </TabPanel>
        </>
      );
    } else {
      text = (
        <Error404 />
      );
    }
    return (
      <>
        {text}
      </>
    );
  };

  return (
    <div style={mapStyle}>
      <div className={classes.layout}>
        <Box mt={{ xs: 8, sm: 8, md: 7 }} ml={{ md: 0, lg: 2 }} width="100%">
          {!props.warroom_tab && (
            <Grid container spacing={0}>
              <Grid item xs>
                <Box mt={{ xs: -4, sm: -3, md: -5 }}>
                  {/* Mobile */}
                  <Hidden mdUp>
                    <Box
                      display="block"
                      style={{
                        display: 'block', flexDirection: 'row', position: 'relative', color: 'white', width: '100%', height: 70,
                      }}
                    >
                      <Box style={{
                        display: 'inline-flex', position: 'absolute', color: 'white', width: '100%', height: '100%',
                      }}
                      >

                        <img style={{ width: '100%', height: 60 }} src={`${initPath}images/theme/banner/water/tab_water_situration_mobile.png`} alt="ติดตามสถานการณ์ระดับน้ำ" />
                      </Box>
                      <Box style={{
                        position: 'absolute', left: 25, top: '29%', alignItems: 'center', fontSize: '0.9rem', fontWeight: 500, width: '100%', height: '70px', fontStyle: 'italic',
                      }}
                      >
                        {`${t('ติดตามสถานการณ์น้ำท่วม')} 68`}
                      </Box>
                    </Box>
                  </Hidden>
                  {/* Desktop */}
                  <Hidden smDown>
                    <Box
                      display="block"
                      style={{
                        display: 'block', flexDirection: 'row', position: 'relative', color: 'white', width: '100%', height: 70,
                      }}
                    >
                      <Box style={{
                        display: 'inline-flex', position: 'absolute', color: 'white', width: '100%', height: 70,
                      }}
                      >
                        <img style={{ width: '100%', height: 70 }} src={`${initPath}images/theme/banner/water/tab_water_situration_wide.png`} alt="ติดตามสถานการณ์ระดับน้ำ" />
                      </Box>
                      <Box style={{
                        position: 'absolute', left: 40, top: 23, alignItems: 'center', fontSize: '1rem', fontWeight: 500, width: '100%', height: 70, fontStyle: 'italic',
                      }}
                      >
                        {`${t('ติดตามสถานการณ์น้ำท่วม')} 68`}
                      </Box>
                    </Box>
                  </Hidden>
                </Box>
              </Grid>
            </Grid>
          )}
          {renderTab()}

          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item md={4}><Link href="https://doh.go.th/content/index/page?g=114353" target="_blank" title="สรุปสถานการณ์อุทกภัยบนทางหลวง กรมทางหลวง"><img style={{ width: '100%' }} src={`${initPath}images/theme/banner/Banner-doh.jpg`} alt="สรุปสถานการณ์อุทกภัยบนทางหลวง กรมทางหลวง" /></Link></Grid>
            <Grid item md={4}><Link href="https://fms.drr.go.th/" target="_blank" title="ระบบบริหารจัดการงานอุทกภัย กรมทางหลวงชนบน"><img style={{ width: '100%' }} src={`${initPath}images/theme/banner/Banner-fms-drr.jpg`} alt="ระบบบริหารจัดการงานอุทกภัย กรมทางหลวงชนบน" /></Link></Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
