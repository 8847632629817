import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Control from 'react-leaflet-control';

import { withTranslation } from 'react-i18next';

// map
import L from 'leaflet';
import {
  Map, Marker, TileLayer, Tooltip, LayerGroup,
} from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import { styles } from '../../../styles/Style';

import iconImage from '../../widgets/map/iconImage';

import SalinityChart from './SalinityChart';

// css
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

const initPath = process.env.MIX_APP_PATH;
const banner = `${initPath}images/models/banner-salinity.png`;
class SalinityMap extends PureComponent {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.salinityLayer = createRef();
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    this.state = {
      error: '',
      // geojsonRiver: [],
      hover: false,
    };

    // this.getGeoJson = this.getGeoJson.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;

    this._mounted = true;
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '15%';
    // this.getGeoJson();
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleMouseOut() {
    this.setState({ hover: false });
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 10;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 10;
    }
    return zoom;
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle = () => ({
    stroke: false,
    fillColor: '#FFF',
    fillOpacity: 0,
  })

  // getGeoJson() {
  //   fetch(`${initPath}json/river/river_main.json`)
  //     .then(([river]) => {
  //       this.setState({ geojsonRiver: river });
  //     })
  //     .catch(error => this.setState({ error }));
  // }

  // add marker reference
  bindMarker = (id, data) => ref => {
    const { markers, handleModalOpen, t } = this.props;
    if (ref) {
      const params = {
        header: t('กราฟความเค็มคาดการณ์ในแม่น้ำเจ้าพระยาตอนล่าง'),
        content: <SalinityChart name={data[1]} />,
      };
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
        handleModalOpen(params);
      });
    }
  }

  // metadata
  // 0: "S01"
  // 1: "WatPhoTaengNuea"
  // 2: "วัดโพธิ์แตงเหนือ"
  // 3: "14.1306"
  // 4: "100.5238"
  // 5: "cpy"
  // 6: "mwa_salinity"
  // 7: "0"
  // 8: "0.25"
  // 9: "0.5"
  // 10: "2"
  // 11: "ต.โพธิ์แตง"
  // 12: "อ.บางไทร"
  // 13: "จ.พระนครศรีอยุธยา"

  eachData = (data, i) => {
    const {
      zoom, datas, classes, t,
    } = this.props;

    if (data[3] && data[4] != null) {
      // marker position
      const position = [parseFloat(data[3]), parseFloat(data[4])];

      // marker icons
      const icon = iconImage.raindroupUp;

      const location = '';

      return (
        <Marker
          id={data.code}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data[0], data)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
        >
          <Tooltip title={`${t('สถานี')} ${data[0]} ${data[2]}`}>
            <span>
              <strong>
                {`${t('สถานี')} ${data[0]} ${data[2]}`}
              </strong>
              <br />
              {`${data[11]} ${data[12]}`}
              <br />
              {data[13]}
            </span>
          </Tooltip>
        </Marker>
      );
    }

    return false;
  }

  handleBoxToggle = () => this.setState({ hover: true });

  render() {
    const {
      error, hover,
    } = this.state;
    const {
      location, zoom, datas, t, classes,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <div className="text-center">{error.message}</div>
      );
    }

    const tooltipStyle = {
      display: hover ? 'block' : 'none',
      maxWidth: '337px',
      padding: '10px',
      wordBreak: 'break-all',
    };

    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          ref={this.refMap}
          style={{
            height: window.height,
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
          }}
          gestureHandling
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          />
          {/* <GeoJSON
            key={uniqueId()}
            data={geojsonRiver}
            style={this.getRiverStyle()}
            onEachFeature={this.onEachRiverFeature}
          /> */}
          {/* <LayersControl position="topleft">
            <Overlay name={t('เส้นแม่น้ำ')} checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
          </LayersControl> */}
          <LayerGroup ref={this.salinityLayer}>
            {datas.map(this.eachData)}
          </LayerGroup>

          <img src={banner} alt="banner" className={classes.styleBanner} />
          <Control position="topleft">
            <div
              style={{
                backgroundColor: 'white', padding: '8px', borderRadius: '9px', width: '3em',
              }}
              onBlur={() => this.setState({ hover: true })}
              onFocus={() => this.setState({ hover: false })}
              onMouseEnter={this.handleBoxToggle}
              onMouseOut={this.handleMouseOut}
            >
              Info
            </div>
            <Paper>
              <div style={tooltipStyle}>
                {t('ระบบคาดการณ์ความเค็มในแม่น้ำเจ้าพระยา พัฒนาด้วยแบบจำลองอุทกพลศาสตร์แบบ 1 มิติ ร่วมกับการจำลองการพัดพาและการแพร่ของความเค็มโดยเชื่อมโยงข้อมูลสภาพการไหลจาก แบบจำลองคาดการณ์น้ำท่าในลุ่มเจ้าพระยา เพื่อคาดการณ์การรุกตัวของความเค็มใน แม่น้ำเจ้าพระยา ระบบทำการประมวลผลวันละ 2 ครั้ง โดยคาดการณ์ความเค็ม 7 วันล่วงหน้า เพื่อใช้เป็นข้อมูลในการติดตามเฝ้าระวังค่าความเค็มและวาง แผนการสูบน้ำดิบเพื่อการ ผลิตน้ำประปารวมถึงวางการแผนการระบายน้ำจืดเพื่อผลักดันน้ำเค็มในฤดูแล้ง')}
              </div>
            </Paper>
          </Control>
        </Map>
      </>
    );
  }
}

SalinityMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles), withTranslation(),
)(SalinityMap);
