import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsMore from 'highcharts/highcharts-more';

import { FaSpinner } from '../../utils/Fontawesome';

// init highcharts module
HighchartsMore(Highcharts);
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const DamMainDailyChartHC = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [highchartOptions, setHighchartOptions] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { year, type } = props;

  const getChartOptions = () => {
    const options = {
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      chart: {
        zoomType: 'x',
        width: '100%',
        height: '55%',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%b}',
        },
        tickInterval: 30 * 24 * 3600 * 1000,
        showLastLabel: false,
      },
      yAxis: { // Primary yAxis
        title: {
          text: ` ${t('ล้าน ลบ.ม.')}`,
        },
        labels: {
          format: '{value:,.0f}',
        },
        plotLines: [{
          value: null,
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'right',
          },
        }, {
          value: null,
          color: 'black',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'right',
          },
        }, {
          value: null,
          color: '#f9a825',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'left',
          },
        }],
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ` ${t('ล้าน ลบ.ม.')}`,
        xDateFormat: '%d %B',
      },
      colors: ['#F62C2C', '#a14535', '#deb245', '#c7d161', '#b8de45', '#6fd18b', '#45dea1', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#d173bd', '#EA7369', '#F0A58F'],
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
        itemMarginBottom: 40,
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return options;
  };

  const resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  };

  const renderChart = () => {
    const url = `dam_daily_sum_main_dam?year=${year}`;

    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const chartOptions = getChartOptions();
        const dataSeries = [];

        let ts = null;
        let val = null;

        // data series
        // const colors = ['#F62C2C', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#EA7369', '#F0A58F'];
        const colors = ['#F62C2C', '#a14535', '#deb245', '#c7d161', '#b8de45', '#6fd18b', '#45dea1', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#d173bd', '#EA7369', '#F0A58F'];
        result.data.graph_data.map((d, i) => {
          const series = [];

          orderBy(d.data, ['dam_date'], ['asc']).map(n => {
            // by pass 29 Feb
            // if ((moment(n.dam_date).format('M') !== 2) && (moment(n.dam_date).date() !== 29)) {
            if (moment(n.dam_date).format('MM-DD') !== '02-29') {
              if (n[type] !== null) {
                val = parseFloat(n[type]).toFixed(2);
              } else {
                val = null;
              }
              // console.log(`2018-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`);
              ts = moment(`2018-${moment(n.dam_date).format('MM')}-${moment(n.dam_date).format('DD')}`, 'YYYY/MM/DD').add(1, 'days').valueOf();
              series.push([ts, parseFloat(val)]);
            }

            return series;
          });

          dataSeries.push({
            name: `${d.year}`,
            data: series,
            color: colors[i],
            dashStyle: 'solid',
            tooltip: {
              valueSuffix: ` ${t('ล้าน ลบ.ม.')}`,
              xDateFormat: '%d %B',
            },
          });

          return null;
        });

        chartOptions.series = dataSeries;
        setHighchartOptions(chartOptions);
        setIsLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    resetChart: () => {
      resetChart();
    },
    renderChart: () => {
      renderChart();
    },
  }));

  useEffect(() => {
    renderChart();
  }, []);

  // if error, show error message
  if (error) {
    return (
      <div className="text-center">
        {error.message}
      </div>
    );
  }

  // if still loading, show spinner
  if (isLoading) {
    return (
      <div className="text-center">
        <FaSpinner size={70} />
      </div>
    );
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={highchartOptions}
      />
    </>
  );
});

DamMainDailyChartHC.propTypes = {
  year: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default DamMainDailyChartHC;
