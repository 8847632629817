/**
 * *
 * @version 1.0.0
 * @author [Peerapong Srisom]
 */

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '17px',
    width: '20px',
    [theme.breakpoints.up('xs')]: {
      width: '22px',
    },
  },
  label: {
    color: 'white',
    fontSize: '0.7em',
    fontWeight: 400,
    textAlign: 'right',
    marginRight: '-3px',
  },
  detail: {
    color: 'white',
    fontSize: '0.8em',
    fontWeight: 400,
  },
}));

const Rainfall30DayLegend = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container alignItems="flex-end" justifyContent="flex-start" wrap="nowrap" spacing={0}>
        <Grid item>
          <Box className={classes.label}>700</Box>
          <Box
            style={{ backgroundColor: '#' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>500</Box>
          <Box
            style={{ backgroundColor: '#ec0720' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>300</Box>
          <Box
            style={{ backgroundColor: '#ff0166' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>280</Box>
          <Box
            style={{ backgroundColor: '#ff0099' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>270</Box>
          <Box
            style={{ backgroundColor: '#ff00ff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>260</Box>
          <Box
            style={{ backgroundColor: '#cc00ff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>250</Box>
          <Box
            style={{ backgroundColor: '#9900ff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>240</Box>
          <Box
            style={{ backgroundColor: '#6600ff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>230</Box>
          <Box
            style={{ backgroundColor: '#4301f8' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>220</Box>
          <Box
            style={{ backgroundColor: '#0834ff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>210</Box>
          <Box
            style={{ backgroundColor: '#0266ff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>200</Box>
          <Box
            style={{ backgroundColor: '#0099ff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>190</Box>
          <Box
            style={{ backgroundColor: '#00ccff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>180</Box>
          <Box
            style={{ backgroundColor: '#00ffff' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>170</Box>
          <Box
            style={{ backgroundColor: '#00ff99' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>160</Box>
          <Box
            style={{ backgroundColor: '#01ff66' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>150</Box>
          <Box
            style={{ backgroundColor: '#00ff33' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>140</Box>
          <Box
            style={{ backgroundColor: '#33ff00' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>130</Box>
          <Box
            style={{ backgroundColor: '#99ff00' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>120</Box>
          <Box
            style={{ backgroundColor: '#ccff00' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>100</Box>
          <Box
            style={{ backgroundColor: '#ffff00' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>80</Box>
          <Box
            style={{ backgroundColor: '#ffff0b' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>40</Box>
          <Box
            style={{ backgroundColor: '#ffff33' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>20</Box>
          <Box
            style={{ backgroundColor: '#ffff99' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>10</Box>
          <Box
            style={{ backgroundColor: '#ffffcc' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box
            style={{ backgroundColor: '#ffffff' }}
            className={classes.legend}
          />
        </Grid>
      </Grid>
      <Box className={classes.detail}>ปริมาณน้ำฝน (mm)</Box>
    </div>
  );
};

export default Rainfall30DayLegend;
