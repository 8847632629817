import iconRect from '../widgets/map/iconRect';

const wqColor = {
  salinity: [
    {
      label: 'ไม่มีข้อมูล',
      rangeTitle: 'ข้อมูลไม่เพียงพอ',
      statusColor: '#BDBDBD',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '> 2',
      rangeTitle: '> 2',
      statusColor: '#B02318',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '> 0.5 - 1.9',
      rangeTitle: '> 0.5',
      statusColor: '#EE141F',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '0.25 - 0.5',
      rangeTitle: '0.25 - 0.5',
      statusColor: '#FFD966',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '< 0.25',
      rangeTitle: '< 0.25',
      statusColor: '#66c803',
      constrastColor: '#000',
      fontSize: '12px',
    },
  ],
  do: [
    {
      label: '< 2',
      rangeTitle: '< 2',
      statusColor: '#EE141F',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '> = 2',
      rangeTitle: '> = 2',
      statusColor: '#66C803',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: 'ข้อมูลไม่เพียงพอ',
      rangeTitle: 'ข้อมูลไม่เพียงพอ',
      statusColor: '#BDBDBD',
      constrastColor: '#000',
      fontSize: '12px',
    },
  ],
  ph: [
    {
      label: '> 9',
      rangeTitle: '> 9',
      statusColor: '#EE141F',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '< 5',
      rangeTitle: '< 5',
      statusColor: '#EE141F',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '5 - 9',
      rangeTitle: '5 - 9',
      statusColor: '#66C803',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: 'ข้อมูลไม่เพียงพอ',
      rangeTitle: 'ข้อมูลไม่เพียงพอ',
      statusColor: '#BDBDBD',
      constrastColor: '#000',
      fontSize: '12px',
    },
  ],
  eq: [
    {
      label: '> 500',
      rangeTitle: '> 500',
      statusColor: '#EE141F',
      constrastColor: '#000',
      fontSize: '12px',
    },
    {
      label: '< 500',
      rangeTitle: '< 500',
      statusColor: '#66C803',
      constrastColor: '#000',
      fontSize: '12px',
    },
  ],
};

// const wqStatusColorSalinity = wq => {
//   const [salinity0, salinity1, salinity2, salinity3, salinity4] = wqColor.salinity;
//   let color;
//   let icon;

//   if (wq > 2) {
//     color = salinity1;
//     icon = iconRect.red;
//   } else if (wq > 0.5) {
//     color = salinity2;
//     icon = iconRect.red;
//   } else if (wq > 0.25) {
//     color = salinity3;
//     icon = iconRect.yellow;
//   } else if (wq > 0) {
//     color = salinity4;
//     icon = iconRect.green;
//   } else {
//     color = salinity0;
//     icon = iconRect.grey;
//   }

//   return { color, icon };
// };

// const wqStatusColorDo = wq => {
//   const [do0, do1] = wqColor.do;
//   let color;
//   let icon;

//   if (wq < 3) {
//     color = do1;
//     icon = iconRect.red;
//   } else {
//     color = do0;
//     icon = iconRect.green;
//   }

//   return { color, icon };
// };

// const wqStatusColorPh = wq => {
//   const [ph0, ph1, ph2] = wqColor.ph;
//   let color;
//   let icon;

//   if (wq > 9) {
//     color = ph0;
//     icon = iconRect.red;
//   } else if (wq >= 5) {
//     color = ph1;
//     icon = iconRect.red;
//   } else {
//     color = ph2;
//     icon = iconRect.green;
//   }

//   return { color, icon };
// };


const WaterQualityStatusColor = (wq, type) => {
  let status;

  function wqStatusColorSalinity(value) {
    const [salinity0, salinity1, salinity2, salinity3, salinity4] = wqColor.salinity;
    let color;
    let icon;
    if (value >= 2) {
      color = salinity1;
      icon = iconRect.crimson;
    } else if ((value > 0.5) && (value <= 1.9)) {
      color = salinity2;
      icon = iconRect.red;
    } else if ((value >= 0.25) && (value <= 0.5)) {
      color = salinity3;
      icon = iconRect.yellow;
    } else if (value < 0.25) {
      color = salinity4;
      icon = iconRect.green;
    } else {
      color = salinity0;
      icon = iconRect.grey;
    }
    return { color, icon };
  }

  function wqStatusColorph(value) {
    const [ph0, ph1, ph2, ph3] = wqColor.ph;
    let color;
    let icon;
    if (value < 5) {
      color = ph1;
      icon = iconRect.red;
    } else if (value > 9) {
      color = ph0;
      icon = iconRect.red;
    } else if (value > 5 || value < 9) {
      color = ph2;
      icon = iconRect.green;
    } else {
      color = ph3;
      icon = iconRect.grey;
    }
    return { color, icon };
  }

  function wqStatusColordo(value) {
    const [do0, do1, do2] = wqColor.do;
    let color;
    let icon;
    if (value < 2) {
      color = do0;
      icon = iconRect.red;
    } else if (value >= 2) {
      color = do1;
      icon = iconRect.green;
    } else {
      color = do2;
      icon = iconRect.grey;
    }
    return { color, icon };
  }

  function wqStatusColorEc(value) {
    const [eq0, eq1] = wqColor.eq;
    let color;
    let icon;
    if (value > 500) {
      color = eq0;
      icon = iconRect.red;
    } else {
      color = eq1;
      icon = iconRect.green;
    }
    return { color, icon };
  }

  function NanData() {
    const [do2] = wqColor.do;
    const color = do2;
    const icon = iconRect.grey;
    return { color, icon };
  }

  if (type === 'salinity') {
    status = wqStatusColorSalinity(wq);
  } else if (type === 'ph') {
    status = wqStatusColorph(wq);
  } else if (type === 'do') {
    status = wqStatusColordo(wq);
  } else if (type === 'ec') {
    status = wqStatusColorEc(wq);
  } else {
    status = NanData();
  }
  return status;
};

// const WaterQualityStatusColor = (wq, type = 'salinity') => {
//   let status;
//   if (type === 'salinity') {
//     status = wqStatusColorSalinity(wq);
//   } else if (type === 'do') {
//     status = wqStatusColorDo(wq);
//   } else if (type === 'ph') {
//     status = wqStatusColorPh(wq);
//   }
//   return status;
// };

export default WaterQualityStatusColor;
