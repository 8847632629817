/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/moment';
import Input from '@material-ui/core/Input';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { FaSpinner } from '../../../utils/Fontawesome';

import {
  styles,
} from '../../../styles/Style';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
const years = yearsRange(currentYear, 2011);

class RainfallChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      max: 0,
      chartOptions: this.getChartOptions('rainfall24h'),
      // dataProvince: [],
      provinceId: props.provinceId,
      stations: props.filterData,
      teleStation: [],
      startDate: moment().subtract(7, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      type: props.selectedType,
      dataSeries: [],
      dataSeries2: [],
      yearSelect: props.year,
      monthSelect: props.month,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { selectedType } = this.props;
    // this.getProvince();
    this.getStation();
    this.setState({ type: selectedType }, () => {
      this.renderChart();
    });
  }

  getChartOptions(type) {
    let formatDateX = '{value:%d-%b<br />%H:%M}';
    let formatDateTooltip = '%d %B %y %H:%m น.';
    if (type === 'rainfallMonth' || type === 'rainfallYesterday') {
      formatDateX = '{value:%d-%b-%y}';
    }
    if (type === 'rainfallMonth' || type === 'rainfallYesterday') {
      formatDateTooltip = '%d %B %Y';
    }
    if (type === 'rainfallYear') {
      formatDateTooltip = '%B %Y';
    }

    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      rangeSelector: {
        // enabled: false
        // inputDateFormat: '%Y-%d-%m',
        // inputEditDateFormat: '%Y-%d-%m',
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: formatDateX,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          enabled: true,
          text: 'ปริมาณฝน (มม.)',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        alternateGridColor: null,
        labels: {
          format: '{value:.1f}',
        },
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' มม.',
        xDateFormat: formatDateTooltip,
      },
      series: [
        {
          name: '',
          data: [],
          type: 'column',
          color: '#0066FF',
          tooltip: {
            valueSuffix: ' มม.',
            format: '{value:.1f}',
          },
        },
        {
          name: '',
          data: [],
          type: 'spline',
          tooltip: {
            valueSuffix: ' มม.',
            format: '{value:.1f}',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getChartOptions2(type) {
    let formatDateX = '{value:%d-%b<br />%H:%M}';
    let formatDateTooltip = '%d %B %y %H:%m น.';
    if (type === 'rainfallMonth' || type === 'rainfallYesterday') {
      formatDateX = '{value:%d-%b-%y}';
    }
    if (type === 'rainfallMonth' || type === 'rainfallYesterday') {
      formatDateTooltip = '%d %B %Y';
    }
    if (type === 'rainfallYear') {
      formatDateTooltip = '%B %Y';
    }

    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 10,

        },

      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: formatDateX,
        },
      },
      yAxis: {
        title: {
          enabled: true,
          text: 'ปริมาณฝน (มม.)',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        alternateGridColor: null,
        labels: {
          format: '{value:.1f}',
        },
      },

      legend: {
        enabled: true,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' มม.',
        xDateFormat: formatDateTooltip,
      },
      series: [
        {
          name: '',
          data: [],
          type: 'column',
          color: '#0066FF',
          tooltip: {
            valueSuffix: ' มม.',
            format: '{value:.1f}',
          },
        },
        // {
        //   name: '',
        //   data: [],
        //   type: 'spline',
        //   tooltip: {
        //     valueSuffix: ' มม.',
        //     format: '{value:.1f}',
        //   },
        // },
      ],
    };

    return this.options;
  }

  getStation() {
    this.setState({ isLoading: true });
    const {
      provinceId, id, type, stations,
    } = this.state;
    const teleStation = [];
    const teleStationAll = [];
    const selectedType = type;

    if (selectedType === 'rainfallMonth' || selectedType === 'rainfallYear' || selectedType === 'rainfallYesterday') {
      stations.map(row => {
        if (selectedType === 'rainfallMonth' || selectedType === 'rainfallYear') {
          if (row.geocode.province_code === provinceId) {
            teleStation.push(row.station);
          }
          teleStationAll.push(row.station);
        }
        if (selectedType === 'rainfallYesterday') {
          if (row.province_code === provinceId) {
            teleStation.push(row);
          }
          teleStationAll.push(row);
        }
        return null;
      });
      if (!id) {
        if (teleStation.length > 0) {
          if (selectedType === 'rainfallMonth' || selectedType === 'rainfallYear') {
            this.handleStationChangeDefault(teleStation[0].id);
          }
          if (selectedType === 'rainfallYesterday') {
            this.handleStationChangeDefault(teleStation[0].tele_station_id);
          }
        }
      }
      this.setState({
        teleStation,
        isLoading: false,
      });
      this.renderChart();
    } else {
      fetch(`${process.env.MIX_API_URL}public/thailand_main_rain?province_code=${provinceId}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          result.data.map(row => {
            if (row.agency.agency_shortname.en !== 'DWR') {
              teleStation.push(row.station);
            }
            teleStationAll.push(row.station);
            return null;
          });
          if (!id) {
            if (teleStation.length > 0) {
              this.setState({
                id: teleStation[0].id,
              });
            }
          }
          this.setState({
            teleStation,
            isLoading: false,
          });
          this.renderChart();
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }
  }

  // getProvince() {
  //   this.setState({ isLoading: true });
  //   fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error('Something went wrong ...');
  //     })
  //     .then(result => {
  //       const dataProvince = result.province.data.sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));
  //       this.setState({
  //         dataProvince,
  //         isLoading: false,
  //       });
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value, id: '' }, () => {
      this.getStation();
    });
  };

  handleTypeChange = event => {
    this.setState({ type: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStationChangeDefault = idNum => {
    this.setState({ id: idNum }, () => {
      this.handleChart();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => {
      this.handleChart();
    });
  };

  handleMonthChange = event => {
    this.setState({ monthSelect: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleYearChange = event => {
    this.setState({ yearSelect: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const {
      id, type, startDate, endDate, yearSelect, monthSelect,
    } = this.state;
    this.setState({ isLoading: true });
    if (id !== '') {
      let link = 'rain_24h_graph';
      if (type === 'rainfall24h') {
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            const ds1 = [];
            const ds2 = [];
            let ts = null;
            let val = null;
            let val2 = 0;

            const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
            this.setState({
              max,
            });

            result.data.map(n => {
              val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(2)) * 1;

              ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();

              ds1.push([ts, val]);

              val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(2) * 1) : null;

              ds2.push([ts, val2]);

              this.setState({ dataSeries: ds1, dataSeries2: ds2 });

              return null;
            });
            // const { dataSeries2, dataSeries } = this.state;
            const { dataSeries } = this.state;
            const chartOptions = this.getChartOptions2(type);
            chartOptions.series[0].data = dataSeries;
            chartOptions.series[0].name = 'ปริมาณฝนรายชั่วโมง';
            // chartOptions.series[1].data = dataSeries2;
            // chartOptions.series[1].name = 'ปริมาณสะสม 24 ชั่วโมง';

            this.setState({ chartOptions, isLoading: false });
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallToday') {
        link = 'rain_today_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const dataSeries = [];
              let ts = null;
              let val = null;

              const max = maxBy(result.data, 'rainfall') === undefined ? 0 : maxBy(result.data, 'rainfall').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall) === '' || (n.rainfall) === null) ? null : (parseFloat(n.rainfall).toFixed(2)) * 1;
                // val = (parseFloat(n.rainfall).toFixed(2)) * 1;
                ts = moment(n.date_time, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
                dataSeries.push([ts, parseFloat(val)]);
                return null;
              });

              const chartOptions = this.getChartOptions2(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = 'ปริมาณฝนวันนี้';
              // chartOptions.series[1].visible = false;

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallYesterday') {
        link = 'rain_yesterday_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}&start_date=${startDate}&end_date=${endDate}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              const ds2 = [];
              let ts = null;
              let val = null;
              let val2 = 0;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(2)) * 1;
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
                ds1.push([ts, val]);
                val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(2) * 1) : null;
                ds2.push([ts, val2]);
                this.setState({ dataSeries: ds1, dataSeries2: ds2 });
                return null;
              });

              const { dataSeries2, dataSeries } = this.state;
              const chartOptions = this.getChartOptions(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = 'ปริมาณฝนรายวัน';
              chartOptions.series[1].data = dataSeries2;
              chartOptions.series[1].name = 'ปริมาณฝนสะสมรายวัน';

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallMonth') {
        link = 'rain_monthly_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}&month=${monthSelect}&year=${yearSelect}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              const ds2 = [];
              let ts = null;
              let val = null;
              let val2 = 0;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(2)) * 1;
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD').add(7, 'hours').valueOf();
                ds1.push([ts, val]);
                val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(2) * 1) : null;
                ds2.push([ts, val2]);
                this.setState({ dataSeries: ds1, dataSeries2: ds2 });
                return null;
              });

              const { dataSeries2, dataSeries } = this.state;
              const chartOptions = this.getChartOptions(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = 'ปริมาณฝน';
              chartOptions.series[1].data = dataSeries2;
              chartOptions.series[1].name = 'ปริมาณฝนสะสม';

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallYear') {
        link = 'rain_yearly_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}&year=${yearSelect}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              const ds2 = [];
              let ts = null;
              let val = null;
              let val2 = 0;
              const max = maxBy(result.data, 'rainfall') === undefined ? 0 : maxBy(result.data, 'rainfall').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall) === '' || (n.rainfall) === null) ? null : (parseFloat(n.rainfall).toFixed(2)) * 1;
                val = (parseFloat(n.rainfall).toFixed(0)) * 1;
                ts = moment(n.date_time, 'YYYY/MM/DD').add(7, 'hours').valueOf();
                ds1.push([ts, val]);
                val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(2) * 1) : null;
                ds2.push([ts, val2]);
                this.setState({ dataSeries: ds1, dataSeries2: ds2 });
                return null;
              });
              const { dataSeries2, dataSeries } = this.state;
              const chartOptions = this.getChartOptions(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = 'ปริมาณฝน';
              chartOptions.series[1].data = dataSeries2;
              chartOptions.series[1].name = 'ปริมาณฝนสะสม';

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      }
    } else {
      const chartOptions = 0;
      this.setState({ chartOptions, isLoading: false });
    }
  }

  render() {
    const {
      // dataProvince,
      id,
      teleStation,
      type,
      isLoading,
      error,
      chartOptions,
      max,
      startDate,
      endDate,
      today,
      yearSelect,
      monthSelect,
    } = this.state;
    const {
      classes, provinceName, amphoeName,
    } = this.props;
    // if error, show error message
    let name = '';
    let stationName = '';
    // let proviceName = '';
    let NameTele = '';
    // let NameProv = '';
    if (teleStation.length > 0) {
      stationName = teleStation.find(t => t.id === id);
      // proviceName = dataProvince.find(e => e.province_code === provinceId);
      NameTele = `สถานี ${stationName.tele_station_name.th} `;
    }
    // if (dataProvince.length > 0) {
    //   proviceName = dataProvince.find(e => e.province_code === provinceId);
    //   if (proviceName) {
    //     proviceName = proviceName.province_name.th;
    //     NameProv = `จ.${proviceName}`;
    //   }
    // }
    // name = NameTele + NameProv;
    name = `${NameTele + amphoeName} ${provinceName}`;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
            {error}
          </Typography>
        </Paper>
      );
    }
    let chart = '';
    let info = '';
    if (chartOptions === 0 || max === 0) {
      chart = <Paper className={classes.paper}>ไม่มีข้อมูล</Paper>;
      info = '';
    } else {
      chart = <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
      info = (
        <div style={{ color: '#A2A2A2' }}>
          *สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
        </div>
      );
    }
    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl} style={{ whiteSpace: 'nowrap' }}>
              <InputLabel htmlFor="type">ประเภทข้อมูล</InputLabel>
              <Select
                value={type}
                onChange={this.handleTypeChange}
                inputProps={{
                  name: 'selectedType',
                  id: 'type',
                }}
                style={{ minWidth: 140 }}
              >
                { type === 'rainfall24h' && (
                  <MenuItem value="rainfall24h">ฝน 24 ชั่วโมง</MenuItem>
                )}
                { type === 'rainfallToday' && (
                  <MenuItem value="rainfallToday">ฝนวันนี้</MenuItem>
                )}
                { type === 'rainfallYesterday' && (
                  <MenuItem value="rainfallYesterday">ฝนวานนี้</MenuItem>
                )}
                { type === 'rainfallMonth' && (
                  <MenuItem value="rainfallMonth">ฝนรายเดือน</MenuItem>
                )}
                { type === 'rainfallYear' && (
                  <MenuItem value="rainfallYear">ฝนรายปี</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                จังหวัด
              </InputLabel>
              {/* <Select
                value={provinceId}
                onChange={this.handleProvinceChange}
                style={{ minWidth: 140 }}
              >
                {dataProvince.map(row => (
                  <MenuItem key={row.province_code} value={row.province_code}>
                    {row.province_name.th}
                  </MenuItem>
                ))}
              </Select> */}
              <Input
                value={provinceName}
                inputProps={{
                  readOnly: true,
                  disabled: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                สถานี
              </InputLabel>
              <Select value={id} onChange={this.handleStationChange} style={{ minWidth: 200 }}>
                {(type === 'rainfallMonth' || type === 'rainfallYear') && (
                  teleStation.map(row => (
                    <MenuItem key={row.id} value={row.id}>
                      {`${row.tele_station_name.th} (${row.tele_station_oldcode})`}
                    </MenuItem>
                  ))
                )}
                {(type === 'rainfall24h' || type === 'rainfallToday') && (
                  teleStation.map(row => (
                    <MenuItem key={row.id} value={row.id}>
                      {`${row.tele_station_name.th} (${row.tele_station_oldcode})`}
                    </MenuItem>
                  ))
                )}
                {(type === 'rainfallYesterday') && (
                  teleStation.map(row => (
                    <MenuItem key={row.tele_station_id} value={row.tele_station_id}>
                      {`${row.tele_station_name.th}`}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            {type === 'rainfallMonth' && (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="type">เดือน</InputLabel>
              <Select
                value={monthSelect}
                onChange={this.handleMonthChange}
                inputProps={{
                  name: 'monthSelect',
                  id: 'monthSelect',
                }}
              >
                <MenuItem value="01">มกราคม</MenuItem>
                <MenuItem value="02">กุมภาพันธ์</MenuItem>
                <MenuItem value="03">มีนาคม</MenuItem>
                <MenuItem value="04">เมษายน</MenuItem>
                <MenuItem value="05">พฤษภาคม</MenuItem>
                <MenuItem value="06">มิถุนายน</MenuItem>
                <MenuItem value="07">กรกฎาคม</MenuItem>
                <MenuItem value="08">สิงหาคม</MenuItem>
                <MenuItem value="09">กันยายน</MenuItem>
                <MenuItem value="10">ตุลาคม</MenuItem>
                <MenuItem value="11">พฤศจิกายน</MenuItem>
                <MenuItem value="12">ธันวาคม</MenuItem>
              </Select>
            </FormControl>
            )}
            {(type === 'rainfallMonth' || type === 'rainfallYear') && (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="type">ปี</InputLabel>
              <Select
                value={yearSelect}
                onChange={this.handleYearChange}
                inputProps={{
                  name: 'yearSelect',
                  id: 'yearSelect',
                }}
                style={{ minWidth: 140 }}
              >
                {years.map(y => (
                  <MenuItem key={y} value={y}>{y}</MenuItem>
                ))}
              </Select>
            </FormControl>
            )}
            {type === 'rainfallYesterday' && (
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="ข้อมูลวันที่เริ่ม"
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            )}
            {type === 'rainfallYesterday' && (
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="ข้อมูลวันที่สิ้นสุด"
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            )}
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title="แสดงกราฟ"
              >
                แสดง
              </Button>
            </FormControl>
          </Grid>
          {isLoading && (
            <div className="text-center">
              <FaSpinner size={20} />
            </div>
          )}
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} align="center" style={{ fontFamily: 'Lucida Grande,Lucida Sans Unicode,Arial,Helvetica, sans-serif', fontSize: '14px', fontWeight: 'bold' }}>
            {name}
          </Grid>
          <Grid item xs={12}>
            {chart}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {info}
        </Grid>
      </>
    );
  }
}

RainfallChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  provinceName: PropTypes.string.isRequired,
  amphoeName: PropTypes.string.isRequired,
  selectedType: PropTypes.string.isRequired,
  filterData: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
};

export default withStyles(styles)(RainfallChart);
