/**
 * *
 * @version 1.0.0
 * @author [Phithakkhet]
 * Modify by Thitiporn
 */
import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { compose } from "recompose";
import { withTranslation } from "react-i18next";

import { uniqueId, uniqBy, orderBy, get } from "lodash";

// ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import NativeSelect from "@material-ui/core/NativeSelect";
import Modal from "@material-ui/core/Modal";
import FormControl from "@material-ui/core/FormControl";
// icon .
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { HiiClimateIcon } from "../../utils/Icon";
import { FaSpinner } from "../../utils/Fontawesome";

import WeatherPM from "../dashboard/Pm";

import WeatherLatest from "./weather/WeatherLatest";
import WeatherImageLatest from "./weather-image/WeatherImageLatest";

import ForecastContext from "../../contexts/forecastContext";

import provinceJSON from "../../../../public/json/boundary/province_with_bound.json";
import PmBox from "./pm2.5/PmBox";

import { styles, BootstrapInput } from "../../styles/Style";
import { Button } from "@material-ui/core";
import moment from "moment";
import { data } from "jquery";

class WeatherClimate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // _isMounted: true,
      isLoading: true, // loading status
      error: null, // error
      datas: {},
      datasPm: {},
      dataPm25LowToHigh: [], // sorted array in ascending order
      dataPm25HighToLow: [], // sorted array in descending order
      dataPm10LowToHigh: [],
      dataPm10HighToLow: [],
      regionId: "",
      regionIdPm: "",
      dataProvince: provinceJSON,
      dataProvinceOutsideTemp: [],
      dataProvinceOutsidePm: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
    };
  }

  componentDidMount() {
    // this.getData();
    // this.getDataPm();
    this.getDataAll();
    this.getDataTemp();
    this.forceUpdate();
  }

  getModalStyle = () => {
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: "auto",
      width: "fit-content",
      height: "fit-content",
      overflowY: "auto",
      backgroundColor: "#FFF",
      padding: "20px",
      position: "absolute",
    };
  };

  getDataAll() {
    fetch(`${process.env.MIX_API_URL}public/thaiwater/weather`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong ...");
      })
      .then(async (result) => {
        const datas = result;

        let dataProvinceOutsidePm = datas.pm25.data.map((d) => ({
          id: d.geocode.province_name.th,
          name: d.geocode.province_name.th,
          code: d.geocode.province_code,
        }));
        dataProvinceOutsidePm = uniqBy(
          dataProvinceOutsidePm.sort((a, b) =>
            a.name.localeCompare(b.name, "th")
          ),
          "id"
        );
        dataProvinceOutsidePm = dataProvinceOutsidePm.filter(
          (d) => d.code > 100
        );

        this.setState({
          datas,
          dataProvinceOutsidePm: dataProvinceOutsidePm,
          isLoading: false,
        });
        if (
          result.pm25.data !== null &&
          result.pm25.data.length > 0 &&
          result.pm25?.data
        ) {
          const maxdate = this.getMaxDate(result.pm25.data);
          const datasPm = result.pm25?.data.filter((d) => {
            return (
              new Date(d.pm25_datetime).getHours() >=
              new Date(maxdate).getHours() - 1
            );
          });
          const dataPmAsc = await [...datasPm].sort(
            (a, b) => a.pm25_value - b.pm25_value
          );
          const dataPmDesc = await [...datasPm].sort(
            (a, b) => b.pm25_value - a.pm25_value
          );

          this.setState({
            dataPm25LowToHigh: dataPmAsc,
            dataPm25HighToLow: dataPmDesc,
            isLoading: false,
          });

          if (result.pm10?.data) {
            const maxdate = this.getMaxDatePm10(result.pm10.data);
            const datasPm = result.pm10?.data.filter((d) => {
              return (
                new Date(d.pm10_datetime).getHours() >=
                new Date(maxdate).getHours() - 1
              );
            });
            const pm10L = await [...datasPm].sort(
              (a, b) => a.pm10_value - b.pm10_value
            );
            const pm10H = await [...datasPm].sort(
              (a, b) => b.pm10_value - a.pm10_value
            );
            this.setState({
              dataPm10LowToHigh: pm10L,
              dataPm10HighToLow: pm10H,
              isLoading: false,
            });
          }
        }
      })
      .catch((error) => this.setState({ error, isLoading: true }));
  }

  getData() {
    const { regionId } = this.state;

    let condition = "";
    if (regionId !== "") {
      if (regionId.length > 1) {
        condition = `?province_code=${regionId}`;
      } else {
        condition = `?region_code_tmd=${regionId}`;
      }
    }

    // get data from service
    fetch(`${process.env.MIX_API_URL}public/thaiwater/weather${condition}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong ...");
      })
      .then((result) => {
        const datas = result;
        this.setState({
          datas,
          isLoading: false,
        });
      })
      .catch((error) => this.setState({ error, isLoading: true }));
    return true;
  }

  getMaxDate = (datas) => {
    if (datas !== "") {
      const dates = datas.map(
        (x) => new Date(x.pm25_datetime.replace(/-/g, "/"))
      );
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format("YYYY-MM-DD")} ${moment(maxdate).format(
        "HH:mm"
      )}`;
    }
    return moment().format("YYYY-MM-DD");
  };

  getMaxDatePm10 = (datas) => {
    if (datas !== "") {
      const dates = datas.map(
        (x) => new Date(x.pm10_datetime.replace(/-/g, "/"))
      );
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format("YYYY-MM-DD")} ${moment(maxdate).format(
        "HH:mm"
      )}`;
    }
    return moment().format("YYYY-MM-DD");
  };

  getDataPm() {
    const { regionIdPm } = this.state;

    let condition = "";
    if (regionIdPm !== "") {
      if (regionIdPm.length > 1) {
        condition = `?province_code=${regionIdPm}`;
      } else {
        condition = `?region_code_tmd=${regionIdPm}`;
      }
    }

    // get data from service
    fetch(`${process.env.MIX_API_URL}public/thaiwater/weather${condition}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong ...");
      })
      .then(async (result) => {
        if (
          result.pm25.data !== null &&
          result.pm25.data.length > 0 &&
          result.pm25?.data
        ) {
          const datasPm = result.pm25?.data;
          let dataPmAsc = [];
          let dataPmDesc = [];

          dataPmAsc = await [...datasPm].sort(
            (a, b) => a.pm25_value - b.pm25_value
          );
          dataPmDesc = await [...datasPm].sort(
            (a, b) => b.pm25_value - a.pm25_value
          );
          this.setState({
            datasPm: result,
            dataPm25LowToHigh: dataPmAsc,
            dataPm25HighToLow: dataPmDesc,
            isLoading: false,
          });

          if (result.pm10?.data) {
            const pm10L = await [...result.pm10?.data].sort(
              (a, b) => a.pm10_value - b.pm10_value
            );
            const pm10H = await [...result.pm10?.data].sort(
              (a, b) => b.pm10_value - a.pm10_value
            );
            this.setState({
              dataPm10LowToHigh: pm10L,
              dataPm10HighToLow: pm10H,
              isLoading: false,
            });
          }
        }
      })
      .catch((error) => this.setState({ error, isLoading: true }));
    return true;
  }

  getDataTemp() {
    fetch(`${process.env.MIX_API_URL}public/thaiwater/temperature`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("get data Something went wrong ...");
      })
      .then((result) => {
        let datas = [];
        const currentDateTime = new Date();
        const last24hrs = `${currentDateTime.getFullYear()}-${String(
          currentDateTime.getMonth() + 1
        ).padStart(2, "0")}-${String(currentDateTime.getDate()).padStart(
          2,
          "0"
        )}`;
        if (result.data.data !== null) {
            datas = result.data.data
            .filter((d) => {
              const time = new Date(d.temperature_datetime);
              const formattime = `${time.getFullYear()}-${String(
                time.getMonth() + 1
              ).padStart(2, "0")}-${String(time.getDate()).padStart(2, "0")}`;
              return last24hrs === formattime;
            })
            .filter(
              (d) =>
                d.geocode.amphoe_name.th !== undefined &&
                d.geocode.tumbon_name.th !== undefined &&
                d.geocode.province_name.th !== undefined
            );
        }

        let dataProvinceOutsideTemp = datas.map((d) => ({
          id: d.geocode.province_name.th,
          name: d.geocode.province_name.th,
          code: d.geocode.province_code,
        }));
        dataProvinceOutsideTemp = uniqBy(
          dataProvinceOutsideTemp.sort((a, b) => {
            if (a.name && b.name) {
              return a.name.localeCompare(b.name, "th");
            }
          }),
          "id"
        );
        dataProvinceOutsideTemp = dataProvinceOutsideTemp.filter(
          (d) => d.code > 100
        );

        this.setState({
          isLoading: false,
          // datas,
          dataProvinceOutsideTemp: dataProvinceOutsideTemp,
        });
      })
      .catch((error) => this.setState({ error, isLoading: true }));
  }

  handleModalOpen = (params) => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    this.setState({ regionId: event.target.value }, () => {
      this.getData();
    });
  };

  handleChangePm25 = (event) => {
    this.setState({ regionIdPm: event.target.value }, () => {
      this.getDataPm();
    });
  };

  render() {
    const { classes, theme, t } = this.props;
    const {
      datas,
      datasPm,
      dataProvince,
      dataProvinceOutsideTemp,
      dataProvinceOutsidePm,
      regionId,
      regionIdPm,
      isLoading,
      error,
      open,
      modalHeader,
      modalSubHeader,
      modalContent,
      dataPm25LowToHigh,
      dataPm25HighToLow,
      dataPm10LowToHigh,
      dataPm10HighToLow,
    } = this.state;

    return (
      <>
        {error && <div className="text-center">{error.message}</div>}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={30} />
          </div>
        )}
        <ForecastContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs>
                <Box mt={2} mb={0} pb={0} style={{ width: "100%" }}>
                  <Box display="flex" pb={0} mb={0}>
                    <Typography variant="h6" gutterBottom>
                      {t("ติดตามสภาพอากาศ")}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" p={1} alignItems="center">
                    <HiiClimateIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} alignItems="center">
                    <Typography variant="h4">{t("อากาศ")}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display="flex">
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      borderTopLeftRadius: 6,
                    }}
                  >
                    <Box p={2} className={classes.titleBar}>
                      {t("สถานีตรวจวัดอากาศ")}
                    </Box>
                  </Box>
                  <Box
                    p={1}
                    border={1}
                    style={{
                      border: "1px solid #ccc",
                      borderTopRightRadius: 0,
                      padding: "10px",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Button
                        style={{ textAlign: "center", fontSize: 16 }}
                        onClick={() =>
                          window.open(
                            `${process.env.MIX_APP_PATH}weather/weather-station`,
                            "_self"
                          )
                        }
                      >
                        {t("เพิ่มเติม")}
                      </Button>
                    </FormControl>
                  </Box>
                  <Box
                    p={1}
                    border={1}
                    style={{
                      border: "1px solid #ccc",
                      borderTopRightRadius: 6,
                      padding: "0px",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <NativeSelect
                        onChange={this.handleChange}
                        input={
                          <BootstrapInput
                            name="region"
                            id="regend-customized-native-simple"
                            style={{ zoom: 1.2, transform: "scale(0.9)" }}
                          />
                        }
                      >
                        <option
                          fontFamily="Kanit"
                          value=""
                          style={{ fontFamily: "Kanit" }}
                        >
                          {t("ภาพรวมประเทศไทย")}
                        </option>
                        <optgroup
                          label={t("ภาค")}
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontWeight: "bold",
                          }}
                        >
                          <option
                            fontFamily="Kanit"
                            value={3}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคเหนือ")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={1}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคกลาง")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={2}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคตะวันออก")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={4}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคตะวันออกเฉียงเหนือ")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={5}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคใต้ฝั่งตะวันออก")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={6}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคใต้ฝั่งตะวันตก")}
                          </option>
                        </optgroup>
                        <optgroup
                          label={t("ประเทศไทย")}
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontWeight: "bold",
                          }}
                        >
                          {dataProvince.map((row) => (
                            <option key={row.code} value={row.code}>
                              {t(row.name)}
                            </option>
                          ))}
                        </optgroup>
                        {dataProvinceOutsideTemp.length ? (
                          <optgroup
                            label={t("ต่างประเทศ")}
                            style={{
                              backgroundColor: "#f5f5f5",
                              fontWeight: "bold",
                            }}
                          >
                            {dataProvinceOutsideTemp.map((row) => (
                              <option key={row.code} value={row.code}>
                                {t(row.name)}
                              </option>
                            ))}
                          </optgroup>
                        ) : (
                          <></>
                        )}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* weather box */}
            {isLoading === false && (
              <WeatherLatest datas={datas} regionId={regionId} />
            )}
            {/* PM2.5 box */}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display="flex">
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      borderTopLeftRadius: 6,
                    }}
                  >
                    <Box
                      id="weather-station-anchor"
                      p={2}
                      className={classes.titleBar}
                    >
                      {t("แผนที่คุณภาพอากาศ")}
                    </Box>
                  </Box>
                  <Box
                    p={1}
                    border={1}
                    style={{
                      border: "1px solid #ccc",
                      borderTopRightRadius: 6,
                      padding: "0px",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <NativeSelect
                        onChange={this.handleChangePm25}
                        input={
                          <BootstrapInput
                            name="region"
                            id="regend-customized-native-simple"
                            style={{ zoom: 1.2, transform: "scale(0.9)" }}
                          />
                        }
                      >
                        <option
                          fontFamily="Kanit"
                          value=""
                          style={{ fontFamily: "Kanit" }}
                        >
                          {t("ภาพรวมประเทศไทย")}
                        </option>
                        <optgroup
                          label={t("ภาค")}
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontWeight: "bold",
                          }}
                        >
                          <option
                            fontFamily="Kanit"
                            value={3}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคเหนือ")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={1}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคกลาง")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={2}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคตะวันออก")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={4}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคตะวันออกเฉียงเหนือ")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={5}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคใต้ฝั่งตะวันออก")}
                          </option>
                          <option
                            fontFamily="Kanit"
                            value={6}
                            style={{ fontFamily: "Kanit" }}
                          >
                            {t("ภาคใต้ฝั่งตะวันตก")}
                          </option>
                        </optgroup>
                        <optgroup
                          label={t("ประเทศไทย")}
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontWeight: "bold",
                          }}
                        >
                          {dataProvince.map((row) => (
                            <option key={row.code} value={row.code}>
                              {t(row.name)}
                            </option>
                          ))}
                        </optgroup>
                        {dataProvinceOutsidePm.length ? (
                          <optgroup
                            label={t("ต่างประเทศ")}
                            style={{
                              backgroundColor: "#f5f5f5",
                              fontWeight: "bold",
                            }}
                          >
                            {dataProvinceOutsidePm.map((row) => (
                              <option key={row.code} value={row.code}>
                                {t(row.name)}
                              </option>
                            ))}
                          </optgroup>
                        ) : (
                          <></>
                        )}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {isLoading === false && (
              <PmBox
                datas={datasPm}
                regionId={regionIdPm}
                dataPm25LowToHigh={dataPm25LowToHigh}
                dataPm25HighToLow={dataPm25HighToLow}
                dataPm10LowToHigh={dataPm10LowToHigh}
                dataPm10HighToLow={dataPm10HighToLow}
              />
            )}

            {/* PM title */}
            {/* <Grid container spacing={4} style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display="flex">
                  <Box flexGrow={1} display="flex" alignItems="center" style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}>
                    <Box p={2} className={classes.titleBar}>
                      {t('แผนที่คุณภาพอากาศPO')}
                    </Box>
                  </Box>
                  <Box p={1} border={1} style={{ border: '1px solid #ccc', borderTopRightRadius: 6, padding: '0px' }}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <NativeSelect
                        onChange={this.handleChange}
                        input={(
                          <BootstrapInput
                            name="region"
                            id="regend-customized-native-simple"
                            style={{ zoom: 1.2, transform: 'scale(0.9)' }}
                          />
                        )}
                      >
                        <option fontFamily="Kanit" value="" style={{ fontFamily: 'Kanit' }}>{t('ภาพรวมประเทศไทย')}</option>
                        <optgroup label={t('ภาค')}>
                          <option fontFamily="Kanit" value={3} style={{ fontFamily: 'Kanit' }}>{t('ภาคเหนือ')}</option>
                          <option fontFamily="Kanit" value={1} style={{ fontFamily: 'Kanit' }}>{t('ภาคกลาง')}</option>
                          <option fontFamily="Kanit" value={2} style={{ fontFamily: 'Kanit' }}>{t('ภาคตะวันออก')}</option>
                          <option fontFamily="Kanit" value={4} style={{ fontFamily: 'Kanit' }}>{t('ภาคตะวันออกเฉียงเหนือ')}</option>
                          <option fontFamily="Kanit" value={5} style={{ fontFamily: 'Kanit' }}>{t('ภาคใต้ฝั่งตะวันออก')}</option>
                          <option fontFamily="Kanit" value={6} style={{ fontFamily: 'Kanit' }}>{t('ภาคใต้ฝั่งตะวันตก')}</option>
                        </optgroup>
                        <optgroup label={t('จังหวัด')}>
                          {dataProvince.map(row => (
                            <option key={row.code} value={row.code}>
                              {t(row.name)}
                            </option>
                          ))}
                        </optgroup>
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <WeatherPM /> */}
          </div>

          <WeatherImageLatest />
          {/* end content  */}
          <Modal
            id="modal-chart"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={this.handleModalClose}
            style={{ align: "center", justifyContent: "center" }}
          >
            <div style={this.getModalStyle()} className={classes.paperModal}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                <Grid item>
                  <IconButton
                    onClick={this.handleModalClose}
                    style={{ padding: 0 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography variant="h5" id="modal-title">
                {modalHeader}
              </Typography>
              <Typography variant="subtitle1" id="simple-modal-description">
                {modalSubHeader}
              </Typography>
              <Box m={1}>{modalContent}</Box>
            </div>
          </Modal>
        </ForecastContext.Provider>
      </>
    );
  }
}

WeatherClimate.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation()
)(WeatherClimate);
