/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  styles,
} from '../../../styles/Style';

import WeatherBox from './WeatherBox';

class Weather extends Component {
  constructor(props) {
    super(props);
    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleSetMap = this.handleSetMap.bind(this);
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  };

  render() {
    const { datas, regionId } = this.props;
    const dataRain = datas.rain?.data[0] || [];
    // console.log(datas.rain);
    // const dataRain = datas.rain.result === "OK" ? datas.rain.data[0] : [];
    const dataTemperature = datas.temperature?.data || [];
    const dataHumid = datas.humid?.data || [];
    const dataPressure = datas.pressure?.data || [];
    const dataWind = datas.wind?.data || [];

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            {/* พบปัญหาใช้คู่กับ react-slick slider แล้ว slider กระพริบ */}
            {/* <Paper className={classes.root}> */}
            <WeatherBox
              dataHumid={dataHumid}
              dataPressure={dataPressure}
              dataRain={dataRain}
              dataTemperature={dataTemperature}
              dataWind={dataWind}
              regionId={regionId}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

Weather.propTypes = {
  datas: PropTypes.object.isRequired,
  regionId: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(Weather);
