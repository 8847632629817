import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Link,
} from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';

import {
  FaHome,
} from '../../../utils/Fontawesome';

import {
  styles,
} from '../../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

const pageTitle = [
  { type: 'drought', name: 'บันทึกเหตุการณ์ภัยแล้ง' },
  { type: 'flood', name: 'บันทึกเหตุการณ์น้ำท่วม' },
  { type: 'research', name: 'งานวิจัย-ความรู้' },
];
class IframeReport extends Component {
  render() {
    const { classes, t } = this.props;
    const params = new URLSearchParams(window.location.search);
    const paramUrl = params.get('url');
    const { match: { params: { reportType } } } = this.props;

    // check domain
    const domain = new URL(paramUrl);
    const paramHost = (domain.host !== null) ? domain.host : '';

    // replace www.
    const domainFilter = paramHost.replace('www.', '');
    let frameUrl = '';

    // domain whitelist
    if (domainFilter === 'thaiwater.net' || domainFilter === 'tiwrm.hii.or.th' || domainFilter === 'hii.or.th' || domainFilter === 'tiwrmdev.hii.or.th') {
      frameUrl = paramUrl;
    } else {
      frameUrl = 'javascript:document.write("ไม่พบข้อมูล");';
    }

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item>
              {reportType === 'view' && (
                <Breadcrumbs aria-label="breadcrumb">
                  <Button to={`${initPath}research`}>
                    <FaHome fontSize="small" style={{ marginRight: 5 }} />
                    <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                      {' '}
                      {t(pageTitle[2].name)}
                    </Typography>
                  </Button>
                </Breadcrumbs>
              )}
              {reportType !== 'view' && (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to={`${initPath}report`}>
                    <FaHome fontSize="small" style={{ marginRight: 5 }} color="#000" />
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      display="inline"
                      style={{ fontSize: 14 }}
                    >
                      {' '}
                      {t('รายงานสถานการณ์')}
                    </Typography>
                  </Link>
                  {pageTitle.map(row => (reportType === row.type && (
                    <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                      {row.name}
                    </Typography>
                  )))}
                </Breadcrumbs>
              )}
            </Grid>
          </Grid>
          <Box
            style={{
              position: 'relative',
              paddingBottom: '50%' /* 16:9 */,
              paddingTop: 25,
              marginTop: 25,
              height: 0,
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              src={frameUrl}
              frameBorder="0"
              title="ViewIframe"
            />
          </Box>
        </div>
      </>
    );
  }
}

IframeReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

IframeReport.defaultProps = {
  match: {
    params: {
      reportType: '',
    },
  },
};

IframeReport.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportType: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withStyles(styles, { withTheme: true })(IframeReport));
