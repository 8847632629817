import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const RainLegend = () => (
  <div
    style={{
      zIndex: 99999,
      left: '1%',
      bottom: '6%',
      // width: '8.5%',
    }}
  >
    <Paper
      style={{
        opacity: '1',
        backgroundColor: '#EAEAEA',
      }}
    >
      <Box p={1}>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#9c34c7',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}> &gt;300 </b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#851a1e',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>200-300</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#ca242b',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>150-200</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#fe2b1f',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>90-150</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#cc6524',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>70-90</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#ff8933',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>50-70</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#f8d649',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>35-50</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#72bd45',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>20-35</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#95e6af',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>10-20</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#add4fb',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>5-10</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#cbe2fc',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>1-5</b>
        </Grid>
        <Grid container>
          <div style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#FFFFFF',
          }}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>0-1</b>
        </Grid>
      </Box>
    </Paper>
  </div>
);


export default RainLegend;
