/**
 * *
 * @version 1.0.2
 * @author [phithakkhet@hii.or.th]
 */

import { withStyles } from '@material-ui/core/styles';

// table .
import InputBase from '@material-ui/core/InputBase';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tab from '@material-ui/core/Tab';

import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

// color .
import { blue, grey } from '@material-ui/core/colors';
import { object } from 'prop-types';

export const initPath = process.env.MIX_APP_PATH;

// get fullscreen status form localStorage
const fullScreen = (localStorage.fullScreen && localStorage.fullScreen !== '') ? JSON.parse(localStorage.fullScreen) : '';
const fullScreenStatus = (fullScreen.status) ? fullScreen.status : '';

export const BootstrapInput = withStyles(theme => ({
  root: {
    height: 41,
    'label + &': {
      marginTop: theme.spacing(3),
    },

  },
  input: {
    position: 'relative',
    width: '100%',
    fontFamily: 'Kanit',
    fontSize: 14,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);

export const StyledTabNav = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      borderBottom: 'none',
      textTransform: 'none',
    },
  },
  textColorInherit: {
    opacity: 1,
  },
}))(Tab);

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: grey[100],
      paddingLeft: 26,
    },
  },
}))(TableRow);

export const StyledTableCell = withStyles(theme => ({
  root: {
    color: grey[900],
    padding: theme.spacing(2),
  },
  head: {
    '&:nth-of-type(1)': {
      paddingLeft: 26,
    },
    backgroundColor: grey[300],
    fontWeight: 400,
    fontSize: '0.9rem',
    height: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
  body: {
    color: grey[800],
    height: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontFamily: 'sans-serif',
  },
  footer: {
    color: grey[900],
    fontWeight: 500,
    fontSize: '1rem',
    height: theme.spacing(2),
    borderBottom: '2px solid #999',
  },

}))(TableCell);

export const LegendTableCell = withStyles(theme => ({
  root: {
    color: grey[900],
    border: '1px solid #ccc',
  },
  head: {
    '&:nth-of-type(1)': {
      textAlign: 'left',
    },
    backgroundColor: grey[300],
    fontWeight: 400,
    fontSize: '0.8rem',
    height: theme.spacing(1),
    textAlign: 'center',
    fontFamily: 'Kanit',
  },
  // fagTab: {
  //   minWidth: '50px !important',
  // },
  body: {
    '&:nth-of-type(1)': {
      textAlign: 'left',
    },
    color: grey[800],
    height: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontSize: '0.7rem',
    textAlign: 'center',
  },
  footer: {
    color: grey[900],
    fontWeight: 500,
    fontSize: '1rem',
    height: theme.spacing(2),
    borderBottom: '2px solid #999',
  },

}))(TableCell);

export const SmallTableCell = withStyles(theme => ({
  root: {
    color: grey[900],
    padding: theme.spacing(1),
  },
  head: {
    '&:nth-of-type(1)': {
      paddingLeft: 26,
    },
    backgroundColor: grey[300],
    fontWeight: 400,
    fontSize: '0.9em',
    height: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
  body: {
    '&:nth-of-type(1)': {
      paddingLeft: 26,
    },
  },

}))(TableCell);
export const SmallDamTableCell = withStyles(theme => ({
  root: {
    color: grey[900],
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    fontSize: '0.85em',
  },
  head: {
    '&:nth-of-type(1)': {
      paddingLeft: 26,
      fontSize: '0.85em',
    },
    backgroundColor: grey[300],
    fontWeight: 400,
    fontSize: '0.9em',
    height: theme.spacing(0),
    paddingLeft: theme.spacing(4),
  },
  body: {
    '&:nth-of-type(1)': {
      // paddingLeft: 26,
    },
  },

}))(TableCell);

export const StyledFootTableCell = withStyles(theme => ({
  footer: {
    color: grey[900],
    fontWeight: 500,
    fontSize: '1rem',
    height: theme.spacing(2),
    backgroundColor: grey[300],
  },

}))(TableCell);


export const lineBox = grey[300];

// กราฟวงกลมหน้าแรก
export const CircleProgressbarStyle = () => ({
  textProgressbarCircle: {
    fill: '#2f2c2c',
  },
});

export const MapRain24Style = () => ({
  imageBtn: {
    image: `url( ${initPath}images/theme/banner/LayersControlOverview.jpg)`,
  },
});
const estTime = new Date();
const currentDateTimeCentralTimeZone = new Date(estTime.toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }));
const hours = currentDateTimeCentralTimeZone.getHours();
// hours = 18
let imageTimeHeader;
if (hours >= 6 && hours < 18) {
  imageTimeHeader = `url( ${initPath}images/theme/banner/bg-search-day.webp) center center`;
} else {
  imageTimeHeader = `url( ${initPath}images/theme/banner/bg-search-night.jpg) center center`;
}
// console.log('เวลา:', hours);
export const styles = theme => ({

  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  hiddenLine: {
    borderBottom: 0,
    fontSize: 'small',
    whiteSpace: 'normal',
  },
  layout: {
    justifyContent: 'space-around',
    width: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      width: (fullScreenStatus === 'enable') ? '98%' : 1160,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  layoutPm: {
    justifyContent: 'space-around',
    width: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      width: (fullScreenStatus === 'enable') ? '98%' : 1160,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  layoutSearch: {
    justifyContent: 'space-around',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
  },
  layoutGrey: {
    backgroundColor: grey[100],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  layoutColor: {
    backgroundColor: grey[200],
    backgroundSize: 'cover',
    width: '100%',
    align: 'center',
  },
  maskIcon: {
    fontSize: '4em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '3em',
    },
  },

  circleMarkerPm: {
    border: '1px solid #000',
    borderRadius: '100%',
    width: '30px',
    height: '30px',
    fontSize: '1em',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  markerTooltipPm25: {
    backgroundColor: '#69C8FA',
  },
  markerStatusPm50: {
    backgroundColor: '#9ECC63',
  },
  markerStatusPm100: {
    backgroundColor: '#FFFD54',
  },
  markerStatusPm200: {
    backgroundColor: '#F2A43C',
  },
  markerStatusPmOver200: {
    backgroundColor: '#DD554D',
  },

  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    border: '1px solid #ccc',
  },
  borderBottom_tab: {
    borderBottom: '2px solid #006FB6',
  },
  active_tab: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
  },
  active_link_tab: {
    color: 'white',
    backgroundColor: '#524D4D',
    opacity: 1,
    fontSize: '1.3em',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    fontStyle: 'italic',
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: 400,
  },
  link_tab: {
    fontFamily: theme.typography.fontKanit,
    fontStyle: 'italic',
    opacity: 1,
    fontWeight: 400,
    background: '#ccc',
    color: '#000',
    fontSize: '1.3em',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottom: 'none',
  },
  active_tab_style: {
    // color: theme.palette.primary.main,
    borderBottom: '4px solid',
    borderBottomColor: theme.palette.primary.main,
    borderRight: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    opacity: 1,
  },
  tab_style: {
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    opacity: 1,
  },
  boxTabWaterStyle: {
    background: '#f3f3f3',
    borderBottom: '1px solid #ccc',
  },
  boxTab: {
    borderLeft: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
  },
  boxTabScroll: {
    borderLeft: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    opacity: 1,
  },
  boxTabStyle: {
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: '1px solid #ccc',
  },
  selectDateElem: {
    marginTop: '-15px!important',
    marginBottom: '15px!important',
  },
  menuMoblie: {
    top: 50,
    position: 'none',
    left: 0,
  },
  listItemText: {
    fontSize: '1em',
  },
  textField: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '1em !important',
  },
  menuSub: {
    color: '#fff',
  },
  circularProgress: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  bgSearch: {
    background: imageTimeHeader,
    backgroundSize: 'cover',
    width: '100%',
    align: 'center',
    marginTop: 73,
    marginLeft: 0,
    marginRight: 0,
    zIndex: 1200,
  },
  rootSearchHeader: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 48,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    fontFamily: 'Kanit',
  },
  toolbar: {
    display: 'flex',
    align: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    [theme.breakpoints.down('lg')]: {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  MenuItem: {
    fontFamily: 'Kanit',
    fontSize: 16,
    color: grey[900],
  },
  MuiTab: {
    fontFamily: 'Kanit',
    height: 80,
    fontSize: 16,
    borderLeft: '1px solid #ccc',
    paddingTop: 0,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up(1024)]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up(1024)]: {
      display: 'none',
    },
  },
  nameSiteTH: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  nameSiteEN: {
    color: theme.palette.secondary.main,
  },
  HiiThaiwaterIcon: {
    fontSize: 150,
  },
  chart: {
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
    },

  },
  typography: { useNextVariants: true },
  dateTime: {
    fontSize: '0.9em',
    fontWeight: 600,
    color: grey[500],
    fontFamily: 'Kanit',
    textAlign: 'center',
  },
  rootSearch: {
    border: '1px solid #ced4da',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 41,
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    color: 'white',
    fontFamily: 'Kanit',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    marginLeft: '-4px',
    height: '48px',
  },
  buttonDownload: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    color: grey[900],
    border: '1px solid #ced4da',
    backgroundColor: 'white',
    align: 'center',
    alignItems: 'center',
    marginBottom: -12,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 500,
    fontFamily: 'Kanit',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      textDecoration: 'none',
    },
  },
  buttonModal: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'blue',
      color: 'white',
      textDecoration: 'none',
    },
  },
  buttonPopup: {
    fontSize: '1em',
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#000',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
  seaForecast: {
    backgroundColor: 'white',
    border: '1px solid #ccc',
    fontStyle: 'italic',
    padding: '0px 10px',
    borderRadius: 8,
    height: 38,
    fontFamily: 'Kanit !important',
    fontSize: '0.9em',
    fontWeight: 400,
    color: '#000',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      textDecoration: 'none',
    },
  },
  responsiveAbout: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  buttonSlick: {
    position: 'absolute',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    color: '#555',
    fontSize: '1.5em',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      textDecoration: 'none',
    },
  },
  boxTitlePrimary: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 6,
    height: 51,
  },
  boxTitleMutiPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 6,
    height: 51,
    [theme.breakpoints.down('xs')]: {
      borderTopRightRadius: 6,
    },
  },
  boxTitleRadius: {
    backgroundColor: blue[700],
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  boxTitleOutlineRadius: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    border: '1px solid #ccc',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  paperTempSlide: {
    padding: theme.spacing(2),
    background: theme.palette.background.blue,
    borderBottom: '5px solid #a2a1a1',
    height: 419,
  },
  paperWeather: {
    padding: theme.spacing(3),
    background: theme.palette.background.blue,
    borderBottom: '5px solid #a2a1a1',
    height: 470,
    width: 250,
  },
  paperGrid: {
    padding: `${theme.spacing(2)}px 0`,
  },
  paperModal: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  inline: {
    display: 'inline',
  },
  margin: {
    margin: 0,
  },
  iconButton: {
    fontSize: 16,
    padding: theme.spacing(2),
  },
  iconSub: {
    color: grey[500],
  },
  input: {
    margin: theme.spacing(1),
    fontFamily: 'Kanit',
  },
  inputSearch: {
    marginLeft: 8,
    flex: 1,
    fontFamily: 'Kanit',
  },
  title: {
    fontFamily: 'Kanit',
    fontWeight: 600,
  },
  iconRight: {
    paddingTop: 0,
    right: 0,
    position: 'absolute',
  },
  textShadow: {
    textShadow: '1px 2px 2px rgba(23, 22, 22, 0.67)',
  },
  fontTitle: {
    padding: theme.spacing(1),
    fontFamily: 'Kanit',
  },
  boxBgGrey: {
    padding: '3px 7px',
    backgroundColor: grey[300],
    borderRadius: '5px',
  },
  boxBgPrimary: {
    padding: '0px 7px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    color: 'white',
    margin: '0px 5px',
  },
  link: {
    margin: theme.spacing(1),
  },
  lableColor: {
    fontSize: 12,
    color: 'white',
    height: 30,
    align: 'center',
    padding: '0 40px',
  },
  outLinedRadius: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontSize: 16,
    padding: 0,
  },
  outLinedRadiusForm: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontSize: 16,
    padding: 0,
    height: 41,
  },
  outLinedSelect: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontSize: 16,
    paddingLeft: 10, 
    backgroundColor: '#f5f5f5', 
    height: 30
  },
  linkInline: {
    marginLeft: 5,
    fontSize: 11,
    padding: 0,
    color: grey[900],
    fontWeight: 700,
  },
  fontBase: {
    fontSize: 16,
    color: grey[900],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
    lineHeight:2,
  },
  fontBaseWeight: {
    fontWeight: 900,
    fontSize: 16,
    paddingLeft: 5,
    paddingRight: 5,
    color: grey[900],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  },
  fontBaseWeightAbout: {
    fontWeight: 900,
    fontSize: 16,
    color: grey[900],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  },
  fontWeight: {
    fontWeight: 600,
  },
  outLined: {
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '0px',
  },
  outLinedAbout: {
    border: '1px solid #ced4da',
    padding: theme.spacing(2),
  },
  outLinedReport: {
    border: '1px solid #ced4da',
    fontSize: 16,
  },
  outLinedReportContent: {
    border: '1px solid #ced4da',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      border: 'none',
    },
  },

  customBoxImage: {
    zIndex: 999,
    backgroundColor: 'white',
  },
  customBoxContent: {
    border: '1px solid #ccc',
    zIndex: 100,
  },
  boxTitle: {
    fontSize: '16px',
    color: grey[900],
  },
  boxImages: {
    marginLeft: -10,
    marginTop: -47,
    zIndex: 999,
    backgroundColor: 'white',
  },
  boxDownload: {
    borderLeft: '1px solid #ddd',
    paddingTop: 20,
  },
  boxContent: {
    fontSize: '14px',
    color: grey[600],
  },
  tabsIndicator: {
    backgroundColor: 'primary',
  },
  tableStyle: {
    width: '100%',
    fontFamily: 'Kanit',
    border: '1px solid #aaa',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
  },
  tableMore: {
    backgroundColor: '#ddd',
    fontFamily: 'Kanit',
    fontWeight: 600,
    color: '#555',
    height: 20,
  },
  tableSmall: {
    padding: theme.spacing(1),
  },
  formControl: {

    root: {
      margin: '0px!important',
      width: '100%',
    },
    width: '100%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  regend: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    textAlign: 'center',
    fullWidth: '100%',
  },
  regendDam: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    fullWidth: '100%',
  },
  regendLevel: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    fullWidth: '100%',
  },
  regendOcean: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    fullWidth: '100%',
  },
  regendQuality: {
    padding: '2px 7px',
    fontSize: 11,
    alignItems: 'center',
    align: 'center',
    color: 'white',
    fullWidth: '100%',
  },
  heading: {
    backgroundColor: '#428FCA',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  inactive: {
    backgroundColor: 'white!important',
    textDecoration: 'none',
    fontSize: 12,
    color: '#000',
    width: '100%',
  },
  active: {
    width: '100%',
    color: 'white',
    backgroundColor: '#ff0000!important',
    textDecoration: 'none',
    fontSize: 12,
  },

  box: {
    border: '1px solid #ccc',
    borderRadius: 6,
  },
  boxSqua: {
    border: '1px solid #ccc',
  },
  boxRadiusPrimary: {
    border: '1px solid #ccc',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: theme.palette.primary.main,
  },
  boxRadiusSecondary: {
    border: '1px solid #ccc',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: grey[300],
  },
  boxTopRadiusPrimary: {
    border: '1px solid #ccc',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: theme.palette.primary.main,
  },
  boxTopRadiusOutline: {
    border: '1px solid #ccc',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: 'white',
  },
  boxTopRadius: {
    border: '1px solid #ccc',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: 'white',
  },
  boxTopRadiusWeather: {
    border: '1px solid #ccc',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: 'white',
    height: 56,
    overflow: 'hidden',
  },
  boxBottomGsRadius: {
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    minHeight: 300,
    display: 'inline-table',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      // height: 846,
    },
  },
  boxBottomRadarRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    minHeight: 300,
    display: 'inline-table',
    width: '100% !important',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      // height: 846,
    },
  },
  boxMarginTop: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },
  },
  boxDailyMarginTop: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 'none',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 64,
    },
  },

  boxBottomGsMapRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    minHeight: 300,
    [theme.breakpoints.up('md')]: {
      height: 590,
    },
    [theme.breakpoints.up('lg')]: {
      // height: 800,
    },
  },

  boxBottomRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 300,
  },
  boxBottomWeatherWindRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 333,
  },
  boxBottomWindRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 333,
    [theme.breakpoints.down('sm')]: {
      minHeight: 405,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
    },
  },
  boxBottomRoverRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 240,
    [theme.breakpoints.down('sm')]: {
      minHeight: 340,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
    },
  },
  boxBottomWeatherRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 450,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    // [theme.breakpoints.up('lg')]: {
    //   height: 550,
    // },
  },
  boxBottomFixibleRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 'max-content',
  },
  boxWeatherHistory: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 'none',
    [theme.breakpoints.up('md')]: {
      minHeight: 180,
    },
  },
  boxWeatherSstHistory: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 'none',
  },
  boxStormHistory: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 'none',
    [theme.breakpoints.up('sm')]: {
      minHeight: 207,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 357,
    },
  },
  boxAccumulatHistory: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 'none',
  },
  boxRadarHistory: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 263,
    },
  },
  boxSoilHistory: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 263,
    },
  },
  boxIndexHistory: {
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 263,
    },
  },
  boxRadarBottomRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 206,
  },
  boxStormBottomRadius: {
    padding: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    height: 'max-content',
    minHeight: 380,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
    },
  },
  boxBottomRadiusNoPadding: {
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
  },
  boxBottomRadiusNoPaddingRadar: {
    borderTop: 'none',
    border: '1px solid #ccc',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: 'white',
    // display: 'block',
  },
  boxTmdRadar: {
    height: 845,
    [theme.breakpoints.down('xs')]: {
      height: 400,
    },
  },
  imgRadar: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  imgRadarComposit: {
    width: '100%',
    height: 750,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  button: {
    color: grey[900],
    fontFamily: 'Kanit',
    fontWeight: 400,
    margin: theme.spacing(0),
    cursor: 'pointer',
    '&:visited': {
      color: '#000',
    },
  },
  buttonOutlined: {
    color: theme.palette.primary.main,
    fontFamily: 'Kanit',
    fontWeight: 400,
    margin: theme.spacing(0),
    border: `'1px solid ${theme.palette.primary.main}'`,
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    '&:visited': {
      color: '#000',
    },
  },
  buttonDam: {
    color: grey[900],
    fontFamily: 'Kanit',
    fontWeight: 400,
    margin: theme.spacing(0),
    cursor: 'pointer',
    padding: '0px 12px',
    textAlign: 'left',
  },
  buttonDamName: {
    padding: '0px',
    fontSize: '1em',
  },
  sidebarExpand: {
    right: 10,
    position: 'fixed',
    align: 'center',
    // zIndex: 99999,
    zIndex: 1200,
    top: 250,
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: '-55px',
    //   marginRight: 'auto',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   marginLeft: '-90px',
    //   marginRight: 'auto',
    // },
  },
  sidebar: {
    position: 'fixed',
    border: '1px solid #ced4da',
    borderRadius: 12,
    align: 'center',
    backgroundColor: 'white',
    zIndex: 1201,
    width: 80,
    top: 250,
    [theme.breakpoints.down('md')]: {
      marginLeft: '-55px',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '-90px',
      marginRight: 'auto',
    },
  },
  sidebarTopButton: {
    display: 'block !important',
    height: '100%',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: '6px  6px',
    width: '100%',
    fontFamily: 'Kanit',
    fontSize: 16,
  },
  sidebarButton: {
    display: 'block !important',
    height: '100%',
    padding: '6px  6px',
    width: '100%',
    fontFamily: 'Kanit',
    fontSize: 16,
    borderRadius: 0,
  },
  sidebarBottomButton: {
    display: 'block !important',
    height: '100%',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: '6px  6px',
    width: '100%',
    fontFamily: 'Kanit',
    fontSize: 16,
  },
  outlinedOldPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    fontFamily: 'Kanit',
    fontSize: 16,
  },
  outlinedOldLeftPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    fontFamily: 'Kanit',
    fontSize: 16,
    borderTopLeftRadius: 6,
  },
  outlinedOldRightPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    fontFamily: 'Kanit',
    fontSize: 16,
    borderTopRightRadius: 6,
    [theme.breakpoints.down('sm')]: {
      // borderTop: '1px solid #ccc',
      padding: 16,
    },
  },

  outlinedPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    borderLeft: '1px solid #ccc',
    fontFamily: 'Kanit',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      // borderTop: '1px solid #ccc',
      padding: 16,
    },
  },
  outlinedLeftDesktopPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    borderLeft: '1px solid #ccc',
    fontFamily: 'Kanit',
    fontSize: 16,
  },
  outlinedLeftPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    borderLeft: '1px solid #ccc',
    fontFamily: 'Kanit',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      // borderTop: '1px solid #ccc',
      //borderLeft: 'none',
      borderLeft: '1px solid #ccc',
    },
  },
  outLinedTitlePrimary: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 6,
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: 6,
    },
  },
  outlinedRightPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    borderLeft: '1px solid #ccc',
    fontFamily: 'Kanit',
    fontSize: 16,
    borderTopRightRadius: 6,
    // [theme.breakpoints.down('sm')]: {
    //   borderTop: '1px solid #ccc',
    //   borderTopRightRadius: 0,
    // },
  },
  outlinedLeftXsPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    borderLeft: '1px solid #ccc',
    fontFamily: 'Kanit',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      borderTop: '1px solid #ccc',
      borderLeft: 'none',
    },
  },
  outlinedRightXsPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    borderLeft: '1px solid #ccc',
    fontFamily: 'Kanit',
    fontSize: 16,
    borderTopRightRadius: 6,
    [theme.breakpoints.down('xs')]: {
      borderTop: '1px solid #ccc',
      borderTopRightRadius: 0,
    },
  },
  outlinedDesktopPrimary: {
    height: '100%',
    borderRadius: 0,
    padding: '6px  16px',
    width: '100%',
    borderLeft: '1px solid #ccc',
    fontFamily: 'Kanit',
    fontSize: 16,
  },
  outlinedTopRadius: {
    height: '100%',
    borderTopRightRadius: 5,
    borderRadius: 0,
    padding: '6px  16px',
    width: 'inherit',
  },
  activeButton: {
    color: '#fff',
    backgroundColor: '#006fb6',
    fontFamily: 'Kanit',
    fontWeight: 400,
    margin: theme.spacing(0),
    textTransform: 'none',
  },
  buttonIcon: {
    margin: theme.spacing(1),
    fontFamily: 'Kanit',
    fontWeight: 400,
  },
  buttonStorm: {
    fontSize: 10,
    margin: 2,
    padding: '0px 5px',
  },
  buttonWidget: {
    padding: '0px 0px',
    fontSize: 10,
    marginLeft: 4,
    textAlign: 'center',
    width: '31%',
    fontFamily: 'Kanit',
    lineHeight: '1.75',
    borderRadius: 4,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  buttonWidgetPm: {
    margin: 5,
    fontSize: 10,
    marginLeft: 4,
    textAlign: 'center',
    width: '100px',
    fontFamily: 'Kanit',
    lineHeight: '1.75',
    borderRadius: 4,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  buttonWidgetOutlined: {
    padding: '0px 0px',
    fontSize: 10,
    marginLeft: 4,
    textAlign: 'center',
    width: '31%',
    fontFamily: 'Kanit',
    lineHeight: '1.75',
    border: '1px solid #ccc',
    borderRadius: 4,
  },
  buttonWidgetStormOutlined: {
    padding: '0px 0px',
    fontSize: 10,
    marginLeft: 4,
    textAlign: 'center',
    fontFamily: 'Kanit',
    lineHeight: '1.75',
    border: '1px solid #ccc',
    borderRadius: 4,
    width: '31%',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  buttonWidgetPmOutlined: {
    padding: '0px 0px',
    fontSize: 10,
    marginLeft: 4,
    textAlign: 'center',
    width: '100px',
    fontFamily: 'Kanit',
    lineHeight: '1.75',
    border: '1px solid #ccc',
    borderRadius: 4,
  },
  buttonOutlined: {
    border: '1px solid #ccc',
    padding: '0px 10px',
  },
  borderSituation: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontFamily: 'Kanit',
  },
  borderLeft: {
    borderLeft: '1px solid #ced4da',
    [theme.breakpoints.down('sm')]: {
      borderLeft: '0px solid #ced4da',
    },
  },
  boxLink: {
    width: '100%',
    height: 120,
    border: '1px solid #ccc',
  },
  iconButtonText: {
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
  },
  iconButtonTextSearch: {
    width: '100%',
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: grey[300],
  },
  titleBarPWV: {
    color: 'white',
    fontFamily: 'Kanit',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7em',
      paddingLeft: theme.spacing(1),
    },
  },
  titleBar: {
    color: 'white',
    fontFamily: 'Kanit',
    fontSize: 16,
    padding: theme.spacing(1),
  },
  titleBarList: {
    borderTopLeftRadius: 6,
    border: '1px solid #ccc',
  },
  titleName: {
    fontFamily: 'Kanit',
    fontSize: 16,
    padding: theme.spacing(1),
  },
  titleSub: {
    color: grey[600],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
  },
  titleSubList: {
    color: grey[600],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
    align: 'center',
    margin: theme.spacing(2),
  },
  titleSubSea: {
    color: grey[600],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 14,
    align: 'center',
    margin: theme.spacing(2),
  },
  bgPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  boxTop: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
  },
  boxSquaHead: {
    backgroundColor: grey[300],
    fontFamily: 'Kanit',
    fontSize: 15,
    fontWeight: 500,
  },
  boxRadius: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: grey[300],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 16,
    padding: theme.spacing(1),
  },
  boxSearchLayer: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    fontFamily: 'Kanit',
    fontWeight: 500,
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    fontSize: 12,
  },
  boxRadiusForecast: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: grey[300],
    fontFamily: 'Kanit',
    fontWeight: 500,
    fontSize: 16,
  },
  titleBarSelect: {
    fontFamily: 'Kanit',
    fontSize: 14,
    fontWeight: 500,
    height: 47,
    border: '1px solid #ccc',
  },
  titleBarSelectClimate: {
    backgroundColor: 'white',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  titleBarSelectFix: {
    fontFamily: 'Kanit',
    fontSize: 14,
    fontWeight: 500,
    height: 50,
    border: '1px solid #ccc',
  },
  statusNull: {
    backgroundColor: '#aaa',
  },
  statuslight: {
    backgroundColor: '#AED4FF',
  },
  statusMedium: {
    backgroundColor: '#67CA00',
  },
  statusHeavy: {
    backgroundColor: '#EC2F04',
  },
  status: {
    padding: '0px 10px',
    borderRadius: 4,
  },
  normal: {
    backgroundColor: '#931910',
    color: 'white',
  },
  stay: {
    backgroundColor: '#EF8000',
    color: 'white',
  },
  critical: {
    backgroundColor: '#EC2F00',
    color: 'white',
  },
  severe: {
    backgroundColor: '#931910',
    color: 'white',
  },
  boxLinkSea: {
    width: '100%',
    height: 120,
    padding: theme.spacing(2),
    border: '1px solid #ccc',
  },
  titleBarSelectSea: {
    fontFamily: 'Kanit',
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: grey[300],
    height: 50,
    borderRight: '1px solid #ccc',
  },
  boxImg: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
  },
  btnSubmit: {
    width: '100%',
    backgroundColor: grey[300],
    border: '1px solid #ced4da',
    borderRadius: 5,
    fontWeight: 500,
    align: 'center',
    fontFamily: 'Kanit',
    fontSize: 16,
    height: 41,
  },
  formControlSearch: {
    width: '100%',
    border: '1px solid #ced4da',
    borderRadius: 5,
    padding: '3px 14px',
    align: 'center',
    margin: 'auto',
  },

  // footer
  logoHaii: {
    width: '100px',
    height: '100px',
  },
  mobileApp: {
    background: `url( ${initPath}images/theme/banner/bg-app-moblie.png) center center`,
    backgroundSize: 'cover',
    width: '100%',
    align: 'center',
    paddingTop: 70,

    [theme.breakpoints.up('md')]: {
      background: `url( ${initPath}images/theme/banner/bg-app.webp) top center`,
      backgroundSize: 'cover',
      width: '100%',
      align: 'center',
      paddingTop: 70,
    },

  },
  footer: {
    justifyContent: 'space-around',
    backgroundColor: grey[800],
    width: '100%',
    fontFamily: 'Kanit',
  },
  copyright: {
    justifyContent: 'space-around',
    backgroundColor: grey[900],
    color: grey[500],
    fontFamily: 'Kanit',
  },
  responsive: {
    width: '100%',
    height: '100%',
  },
  imgRadarFix: {
    width: 250,
    height: 250,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  responsiveHeight: {
    width: '80%',
    height: '100%',
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      margin: 15,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  responsiveImg: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  responsiveWeather: {

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      minHeight: 'max-content',
    },
  },
  rootSearchDam: {
    border: '1px solid #ced4da',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 32,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  info: {
    fontWeight: 300,
    fontFamily: 'Kanit',
    fontSize: '0.8rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
      fontSize: '0.7rem',
    },
  },
  boxGraphDam: {
    overflow: 'auto',
  },
  graphDam: {
    [theme.breakpoints.down('xs')]: {
      width: 500,
    },
  },
  boxChartStormSurge: {
    overflow: 'auto',
  },
  chartStormSurge: {
    [theme.breakpoints.down('xs')]: {
      width: 500,
    },
  },
  modalChartStormSurge: {
    height: '65%',
  },
  bmaLogo: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 80,

    },
    [theme.breakpoints.up('md')]: {
      width: 130,
      margin: 30,
    },
  },
  sidebarBmaGroup: {
    borderRadius: 30,
    color: '#fff',
    backgroundColor: 'rgba(6, 30, 44, 0.7)',
  },
  sidebarBma: {
    borderRadius: 30,
    color: '#fff',
    backgroundColor: 'rgba(6, 30, 44, 0.7)',
    width: 40,
    height: 40,
    marginBottom: 8,
  },
  sidebarBmaOutline: {
    borderRadius: 30,
    color: '#fff',
    width: 40,
    height: 40,
    marginBottom: 8,
  },
  iconBma: {
    color: '#fff',
    fontSize: '1em !important',
  },
  bmalagend: {
    // width: 350,
    right: 90,
    bottom: 30, 
    position: 'absolute', 
    zIndex: 999,
  },
  customWidthTooltip: {
    maxWidth: 500,
  },
  pdpaPopupLink: {
    border: '1px solid #ccc',
    borderRadius: '50px',
    padding: '2px 10px',
    color: '#333',
    fontSize: '0.9em',
  },

  // style popup
  hiddenLinePopup: {
    borderBottom: 0,
    fontSize: 'small',
    whiteSpace: 'normal',
    fontFamily: 'Kanit',
    padding: '3px 11px',
  },
  titleLogoPopup: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  popupTitle: {
    fontFamily: 'Kanit',
    fontSize: '1.2em',
    color: '#000',
    fontWeight: 400,
    padding: '0px 20px',
  },
  popupSubtitle: {
    fontFamily: 'Kanit',
    fontSize: '1em',
    color: '#777',
    marginBottom: 10,
    fontWeight: 400,
  },
  popupList: {
    fontFamily: 'sans-serif',
    fontSize: '1.1em',
    color: '#000',
    fontWeight: 600,
  },
  popupListBody: {
    fontFamily: 'sans-serif',
    fontSize: '1em',
    color: '#000',
    fontWeight: 400,
  },
  popupValue: {
    fontFamily: 'sans-serif',
    fontSize: '1.1em',
    color: '#000',
    fontWeight: 400,
  },
  popupValueBold: {
    fontFamily: 'sans-serif',
    fontSize: '1.1em',
    color: '#000',
    fontWeight: 600,
  },
  popupUnit: {
    fontFamily: 'sans-serif',
    fontSize: '1em',
    color: '#000',
    marginBottom: 10,
  },
  popupUnitGrey: {
    fontFamily: 'sans-serif',
    fontSize: '1em',
    color: '#999',
    marginBottom: 10,
  },
  popupRef: {
    fontFamily: 'sans-serif',
    fontSize: '0.7em',
    color: '#555',
    marginTop: 10,
  },
  popupDate: {
    fontFamily: 'sans-serif',
    fontSize: '0.7em',
    color: '#555',
    borderBottom: 0,
    whiteSpace: 'normal',
    padding: '0px',
  },
  feedback: {
    position: 'absolute',
    bottom: 0,
    right: 20,
    width: 100,
    height: 100,
    zIndex: 999,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  styleBanner: {
    zIndex: 99999,
    textAlign: 'center',
    position: 'fixed',
    top: '30px',
    left: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  visible: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  btnToggleBox: {
    background: '#fff',
    borderRadius: 6,
    marginBottom: 5,
  },
});

export const getModalStyle = () => {

  const { width } = this.props;
  const top = 0;
  const left = 0;
  const right = 0;
  const bottom = 0;
  let widthChart = '80%';
  let heightChart = '60%';

  if (isWidthDown('xs', width)) {
    widthChart = '90%';
    heightChart = '90%';
  }
  if (isWidthUp('md', width)) {
    widthChart = '50%';
    heightChart = '60%';
  }

  return {
    top: `${top}%`,
    left: `${left}%`,
    right: `${right}%`,
    bottom: `${bottom}%`,
    margin: 'auto',
    width: widthChart,
    height: heightChart,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    padding: '20px',
    position: 'absolute',
    zIndex: 9999988,
  };
};
