import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
  },
});

export default function LegendObsRain() {
  const classes = useStyles();

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.8',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          {/* <Grid container>
            <Grid item><Typography variant="subtitle2"> Obs Rain (mm) </Typography></Grid>
          </Grid> */}
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FCFEAF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 50 – 100 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#F59032',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 100 – 200 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#F53234',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 200 – 300 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#564EF9',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 300 – 400 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#0C01EF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 400 – 500 </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#070363',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> 500 – Inf </Typography></Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
