import React, { useState, useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import { Button, Tooltip } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { Marker, Tooltip as Tooltipmarker } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Control from 'react-leaflet-control';

const initPath = process.env.MIX_APP_PATH;

const CurrentLocationHook = ({
  t, themeDL, mapRef, showOnly,
}) => {
  const [position, setPosition] = useState(null);
  const [error, setError] = useState(null);
  const [showLocation, setShowLocation] = useState(false);
  const watchIdRef = useRef(null);

  useEffect(() => {
    const successHandler = position => {
      const { latitude, longitude } = position.coords;
      setPosition([latitude, longitude]);
    };

    const errorHandler = error => {
      setError(error.message);
    };

    watchIdRef.current = navigator.geolocation.watchPosition(successHandler, errorHandler);

    return () => {
      if (watchIdRef.current) {
        navigator.geolocation.clearWatch(watchIdRef.current);
      }
    };
  }, []);

  const clickToPan = () => {
    if (!showLocation) {
      setShowLocation(true);
      if (mapRef.current && position) {
        if (!showOnly) {
          mapRef.current.leafletElement.setView(position, 13);
        }
      }
    } else {
      setShowLocation(false);
    }
  };

  const customIcon = new L.Icon({
    iconUrl: `${initPath}images/pin-red.gif`,
    iconSize: [60, 60],
    iconAnchor: [30, 58],
    className: 'marker-icon',
  });

  return (
    <div>
      <Control position={themeDL ? 'topright' : 'topleft'}>
        <Tooltip title={t('ตำแหน่งปัจจุบัน')}>
          <Button
            onClick={clickToPan}
            style={
              themeDL
                ? {
                  top: '-5px',
                  height: '40px',
                  width: '40px',
                  backgroundColor: 'rgba(6, 30, 44, 0.7)',
                  borderRadius: '50%',
                }
                : {
                  top: '5px',
                  bottom: '5px',
                  left: '2px',
                  height: '30px',
                  width: '30px',
                  backgroundColor: 'white',
                }
            }
          >
            {showLocation ? (
              <GpsFixedIcon style={themeDL ? { color: '#79b5f1' } : { color: '#1976d2' }} />
            ) : (
              <LocationSearchingIcon style={themeDL ? { color: 'white' } : { color: '#151a1c' }} />
            )}
          </Button>
        </Tooltip>
      </Control>
      {showLocation && position && (
        <Marker position={position} icon={customIcon}>
          <Tooltipmarker direction="top" permanent offset={[0, -35]}>
            {t('ตำแหน่งปัจจุบัน')}
          </Tooltipmarker>
        </Marker>
      )}
      {error && (
      <p>
        Error:
        {error}
      </p>
      )}
    </div>
  );
};

CurrentLocationHook.propTypes = {
  t: PropTypes.func.isRequired,
  themeDL: PropTypes.func.isRequired,
  mapRef: PropTypes.func.isRequired,
  showOnly: PropTypes.func.isRequired,
};

export default withTranslation()(CurrentLocationHook);
