const styles = {
  mainmenu: {
    fontFamily: 'kanit',
    fontSize: '20px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  menuControlStyle: {
    padding: '5px',
    zIndex: 99999,
    backgroundColor: 'white',
    borderRadius: '5px',
    position: 'relative',
    right: '0.4%',
    width: '18em',
    marginBottom: '10px',
  },
};

export default styles;
