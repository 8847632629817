import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

// ui
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Img from 'react-image';
// modal
import Modal from '@material-ui/core/Modal';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// import Lightbox from 'react-image-lightbox';
import { FaSpinner } from '../../../utils/Fontawesome';
import 'react-image-lightbox/style.css';

import { styles } from '../../../styles/Style';
import grey from '@material-ui/core/colors/grey';
import ContentImageWrf from './contentImageWrf';

let images = [];
const width = '89%';

class WrfHistoryList extends Component {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef();

    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      // isOpen: false,
      imgs: [],
      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
    };
  }

  componentDidMount() {
    const { period, domain } = this.props;
    // get data from ws
    this.fetchData(period, domain);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.period !== state.period) {
      return {
        period: props.period,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { period, domain } = this.props;

    if ((prevProps.period.year !== period.year)
      || (prevProps.period.month !== period.month)
      || (prevProps.domain !== domain)) {
      this.fetchData(period, domain);
    }
  }

  fetchData = (period, domain) => {
    let selectedMonth = period.month;
    const selectedYear = period.year;

    // set loading status
    this.setState({
      isLoading: true,
    });

    if (selectedMonth.toString().length < 2) {
      selectedMonth = `0${selectedMonth}`;
    }
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/rain_forecast_history_img?month=${selectedMonth}&year=${selectedYear}&datatype=${domain}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }


    filterDataByDate = (startDate) => {

        return this.state.imgs.filter(item => {
          const itemYear = moment(item.datetime).year();
          const { domain } = this.props;

      if (domain === 'thailand' || domain === 'asia' || domain === 'southeast asia') {

          if (itemYear >= 2011 && itemYear <= 2017) {
            return moment(item.datetime).isSame(startDate, 'day');
          }
        }
          return true;
        });
      }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%'; // 90%
    let heightChart = 'max-content';

    if (isWidthUp('md', width)) {
      widthChart = '100%'; // 820
      heightChart = '96%';
    }

    if (isWidthDown('md', width)) {
      widthChart = '100%'; // 90%
      heightChart = 'max-content';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen(i, data, mediaDateTime) {
    const { domain } = this.props;
    this.setState({
      open: true,
      modalHeader: domain.toUpperCase(),
      // modalSubHeader: mediaDateTime,
      modalContent: <ContentImageWrf index={i} data={data} domainData={domain} mediaDateTime={mediaDateTime} />,
    });
  }

  render() {
    images = [];

    const { classes, theme } = this.props;
    const {
      isLoading,
      error,
      imgs,
      // photoIndex,
      // isOpen,
      modalHeader,
      modalSubHeader,
      modalContent,
      open,
    } = this.state;

    const filteredData = this.filterDataByDate(this.props.startDate);

    const groupedByDate = filteredData.reduce((acc, row) => {
      const match = row.filename.match(/_(\d{8})_(\d{6})/);
      
      if (match) {
        const date = match[1]; // 20110406
        const time = match[2]; // 080000
        const formattedDate = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
        
       
        const itemYear = moment(row.datetime).year();
        if (itemYear >= 2011 && itemYear <= 2017) {
          if (!acc[formattedDate]) {
            acc[formattedDate] = [];
          }
          acc[formattedDate].push({ ...row, time });
        }
      } else {

        const otherGroup = "Other";
        if (!acc[otherGroup]) {
          acc[otherGroup] = [];
        }
        acc[otherGroup].push(row); 
      }
      
      return acc;
    }, {});

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={35} />
          </div>
        )}

        <Grid container spacing={4} style={{ paddingTop: 20 }}>
        {Object.keys(groupedByDate).map(date => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={uniqueId()}>
            {['thailand', 'asia', 'southeast asia'].includes(this.props.domain) && moment(date).year() >= 2011 && moment(date).year() <= 2017 && (
              <Box p={1} mt={2} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 ,marginBottom: '30px'}}>
                {moment(date).format('LL')}
              </Box>
            )}

            <Grid container spacing={4} >
              {groupedByDate[date].map((row, i) => {
                let realImage = '';
                if (row.media_path_thumb !== null) {
                  images.push({
                    src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                    title: `${row.datetime}`,
                  });

                  realImage = (
                    <Button onClick={() => this.handleModalOpen(i, groupedByDate[date], row.datetime)}>
                      <Img
                        src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                        width={width}
                        alt={moment(row.datetime).format('ll')}
                        className={classes.responsive}
                      />
                    </Button>
                  );
                } else {
                  realImage = (
                    <Img
                      src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                      width={width}
                      alt="No Data"
                      className={classes.responsive}
                    />
                  );
                }

                return (
                  <Grid item xs={6} sm={4} md={3} lg={3} key={uniqueId()}>
                    <Box className={classes.boxAccumulatHistory}>
                      <Box display="block" align="center">
                        {realImage}
                      </Box>
                      <Box p={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography className={classes.dateTime}>
                          {row?.time 
                          ? `เวลา : ${moment(row?.time, 'HHmmss').format('HH:mm')} ` 
                          : row?.datetime 
                            ? moment(row.datetime).format('ll')
                            : 'No Date or Time'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ))}
          <Modal
            id="modal-chart"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={this.handleModalClose}
            style={{ align: 'center', justifyContent: 'center' }}
          >
            <div style={this.getModalStyle()} className={classes.paper}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                <Grid item>
                  <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography variant="h5" id="modal-title">
                <Grid style={{ textAlign: 'center' }}>{modalHeader}</Grid>
              </Typography>
              <Typography variant="subtitle1" id="simple-modal-description">
                {modalSubHeader}
              </Typography>
              <Box mt={3}>
                <div className={classes.paper} style={{ padding: '0px !important' }}>
                  {modalContent}
                </div>
              </Box>
            </div>
          </Modal>
        </Grid>
      </>
    );
  }
}

WrfHistoryList.propTypes = {
  classes: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  domain: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }))(WrfHistoryList);
