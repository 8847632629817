import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
// style, icons
import { withStyles } from '@material-ui/core/styles';

import { withTranslation, Trans } from 'react-i18next';

// ui
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { FaChartLine } from '../../utils/Fontawesome';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';

import tJson from '../../utils/TJson';

// ui
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';

import {
  styles, StyledTableRow, StyledTableCell,
} from '../../styles/Style';


class SealevelList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      page: 0,
      rowsPerPage: 10,
      order: 'asc',
      orderByPro: '',
    };
    this.handleChart = this.handleChart.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isLoading: false }), 500);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChart = data => e => {
    const { handleModalOpen } = this.props;
    e.preventDefault();

    const newData = {
      id: data.station.id,
      provinceId: data.geocode.province_code,
    };

    handleModalOpen(newData);
  };

  handleRequestSort = (event, property) => {
    const { order, orderByPro } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByPro === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByPro: orderByProperty });
  };

  render() {
    const { classes, datas, t } = this.props;
    const {
      rowsPerPage, page, isLoading, order, orderByPro,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, datas.length - page * rowsPerPage);

    // table Header
    const columnData = [
      {
        id: 'station.tele_station_name.th', disablePadding: false, label: 'ชื่อสถานี', sortable: true, align: 'left',
      },
      {
        id: 'waterlevel_msl', disablePadding: false, label: 'ระดับน้ำ', unit: '(ม.รทก)', sortable: true, align: 'right',
      },
      {
        id: 'waterlevel_datetime', disablePadding: false, label: 'วันที่', sortable: true, align: 'center',
      },
      {
        id: 'time', disablePadding: false, label: 'เวลา', sortable: false, align: 'center',
      },
      {
        id: 'chart', disablePadding: false, label: 'กราฟ', align: 'center',
      },
    ];

    // console.log(datas);

    return (
      <>
        <TableContainer component={Paper}>
          <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
            <TableHeaderEnhance
              columnData={columnData}
              order={order}
              orderBy={orderByPro}
              onRequestSort={this.handleRequestSort}
              firstColumnSticky
            />
            <TableBody>
              {orderBy(datas, orderByPro, order)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const { handleSearchMarker } = this.props;

                  let mapLink = null;
                  if (
                    handleSearchMarker
                    && row.station.tele_station_lat
                    && row.station.tele_station_long
                  ) {
                    mapLink = (
                      <Button
                        onClick={handleSearchMarker(
                          row.id,
                          row.station.tele_station_lat,
                          row.station.tele_station_long,
                        )}
                        title={tJson(row.agency.agency_name)}
                        style={{ textAlign: 'left', paddingLeft: 5 }}
                      >
                        {`${tJson(row.station.tele_station_name, ' ')}`}
                      </Button>
                    );
                  } else {
                    mapLink = tJson(row.station.tele_station_name, ' ');
                  }

                  // mapLink = tJson(row.station.tele_station_name, '');

                  return (
                    <StyledTableRow key={row.id}>
                      <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                        {mapLink}
                      </TableCell>
                      <TableCell align="right">
                        {row.waterlevel_msl.substring(
                          row.waterlevel_msl.length - 5,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.waterlevel_datetime.substring(10, -5)}
                      </TableCell>
                      <TableCell align="center">
                        {row.waterlevel_datetime.substring(
                          row.waterlevel_datetime.length - 5,
                        )}
                        {' '}
                        {t('น.')}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={t('แสดงกราฟ')}>
                          <IconButton
                            onClick={this.handleChart(row)}
                            className={classes.button}
                            aria-label="Chart"
                          >
                            <FaChartLine fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}

              {emptyRows <= 0 && !isLoading && (
                <StyledTableRow
                  style={{ height: 48 * emptyRows, backgroundColor: '#F7F7F7' }}
                >
                  <StyledTableCell colSpan={6} style={{ textAlign: 'center' }}>
                    {t('ไม่พบข้อมูล')}
                  </StyledTableCell>
                </StyledTableRow>
              )}

            </TableBody>
            {/* <TableFooter>
              <StyledTableRow>
                <TablePagination
                  className={classes.hiddenLine}
                  count={datas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                  labelRowsPerPage="จำนวนแถวต่อหน้า"
                  labelDisplayedRows={({ from, to, count }) =>
                  `แสดงรายการ ${from}-${to} จาก ${count} รายการ`}
                />
              </StyledTableRow>
            </TableFooter> */}
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          className={classes.hiddenLine}
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActionsWrapped}
          labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
          labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to}  ${count} ${t('รายการ')}`}
        />
      </>
    );
  }
}

SealevelList.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleSearchMarker: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(SealevelList));
