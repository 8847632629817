/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, minBy, orderBy } from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { withModal } from '../../contexts/ModalContext';


import tJson from '../../utils/TJson';
import { FaSpinner } from '../../utils/Fontawesome';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

/**
  * ComponentName
  */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
class RidChartYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      provinceId: props.provinceId,
      type: props.selectedType,
      teleStation: [],
      canalStation: [],
      startDate: moment().subtract(3, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD HH:mm'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      dataChart: undefined,
      selectedYear: [`${currentYear}`],
    };

  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { selectedType } = this.props;
    this.getProvince();
    this.getStation();
    this.setState({ type: selectedType }, () => {
      this.renderChart();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalSize !== this.props.modalSize ||
      prevProps.isFullScreen !== this.props.isFullScreen) {
      this.props.updateChartSize();
    }
  }

  getChartOptions() {
    const { t } = this.props;

    this.options = {
      chart: {
        zoomType: 'xy',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          // gapSize: 6,
          // marker: {
          //   enabled: undefined,
          //   enabledThreshold: 0.75,
          //   radius: 3,
          // },
        },
      },
      tooltip: {
        shared: true,
        // valueSuffix: t(' ล้าน ลบ.ม.'),
        // xDateFormat: '%d %B',
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          // format: '{value:%d-%b<br />%H:%M}',
          format: '{value:%d-%b<br />06:00 %p}',
          useHTML: true // This is required to properly render HTML line breaks and formats
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: `${t('ปริมาณน้ำท่า')} ${t('(ม.3/วิ.)')}`,
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
      },
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
        maxHeight: 150,
        navigation: {
          enabled: true,
        },
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
      // responsive: {
      //   rules: [{
      //     condition: {
      //       maxWidth: 500
      //     },
      //     chartOptions: {
      //       legend: {
      //         enabled: false
      //       }
      //     }
      //   }]
      // }
    };

    return this.options;
  }

  getWaterChartOptions() {
    const { t } = this.props;
    this.options = {
      chart: {
        zoomType: 'xy',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          // showInNavigator: true,
          // gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
        xAxis: {
          min: Date.UTC(2020, 0, 1), // Start date of your data
          max: Date.UTC(2020, 11, 31) // End date of your data
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          // format: '{value:%d-%b<br />%H:%M}',
          format: '{value:%d-%b<br />06:00 %p}',
          useHTML: true // This is required to properly render HTML line breaks and formats
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: `${t('ระดับน้ำ')} (${t('ม.รทก.')})`,
        },
        labels: {
          formatter() {
            return Highcharts.numberFormat(this.value, 2);
          },
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: 'black',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
        plotBands: [
          {
            // > 100
            from: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำล้นตลิ่ง')} ( > 100% )</strong>`,
              style: {
                color: '#ff0000',
              },
            },
          },
          {
            // > 70-100
            from: null,
            to: null,
            color: 'rgba(0, 0, 0, 0)',
            label: {
              text: `<strong>${t('น้ำมาก')}  ( > 70-100% )</strong>`,
              style: {
                color: '#003cfa',
              },
            },
          },
          {
            // > 30-70
            from: null,
            to: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำปานกลาง')} ( > 30-70% )</strong>`,
              style: {
                color: '#00b050',
              },
            },
          },
          {
            // > 10-30
            from: null,
            to: null,
            color: 'rgba(0, 0, 0, 0)',
            label: {
              text: `<strong>${t('น้ำน้อย')} ( > 10-30% )</strong>`,
              style: {
                color: '#ffc000',
              },
            },
          },
          {
            // <= 10
            from: null,
            to: null,
            color: 'rgba(68, 170, 213, 0.1)',
            label: {
              text: `<strong>${t('น้ำน้อยวิกฤต')} ( < 10% )</strong>`,
              style: {
                color: '#db802b',
              },
            },
          },
        ],
      },
      tooltip: {
        shared: true,
        valueSuffix: t(' ม.รทก.'),
        xDateFormat: '%d %B',
      },
      legend: {
        enabled: true,
      },
      series: [
        {
          name: t('ระดับน้ำ'),
          data: [],
          tooltip: {
            pointFormat: `{point.y:.2f} ${t('ม.รทก.')}`,
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getProvince() {
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataProvince = result.province.data.sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));

        this.setState({
          dataProvince,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getStation() {
    const { provinceId } = this.state;
    fetch(`${process.env.MIX_API_URL}frontend/shared/tele_canal_station?province_code=${provinceId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const teleStation = result.data.tele_waterlevel;
        const canalStation = result.data.canal_waterlevel;

        this.setState({
          teleStation,
          canalStation,
        });

        this.handleChart();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value }, () => {
      this.getStation();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    const { today } = this.state;

    if (moment(date).format('YYYY-MM-DD') === today) {
      this.setState({ endDate: moment(date).format('YYYY-MM-DD HH:mm') }, () => {
        this.handleChart();
      });
    } else {
      const dt = `${moment(date).format('YYYY-MM-DD')} 23:59`;
      this.setState({ endDate: moment(dt).format('YYYY-MM-DD HH:mm') }, () => {
        this.handleChart();
      });
    }
  };

  handleSelectYearChange = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    this.setState({ selectedYear: value });
  }

  handleTypeChange = event => {
    this.setState({ type: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleChart = () => {
    const { id, teleStation } = this.state;
    const stationByClick = teleStation.filter(
      d => parseInt(d.station_id, 10) === parseInt(id, 10),
    )[0];
    const stationId = stationByClick === undefined
      ? teleStation[0].station_id : stationByClick.station_id;
    if (stationId !== undefined) {
      this.setState({ id: stationId });
    }

    this.resetChart();
    this.renderChart();
  };

  findMaxValue = (data, key) => {
    const sortedData = orderBy(data, ['date'], ['asc']);
    const maxValue = maxBy(sortedData, key);
    return maxValue;
  };


  findMinValue = (data, key) => {
    const sortedData = orderBy(data, ['date'], ['asc']);
    const minByValue = minBy(sortedData, key);
    return minByValue;
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const {
      id, startDate, endDate, type, selectedYear,
    } = this.state;
    const { t } = this.props;

    let url = ''
    // url =  `${process.env.MIX_API_URL}public/waterlevel_graph?station_type=tele_waterlevel&station_id=${id}&start_date=${startDate}&end_date=${endDate}`;
    url = `${process.env.MIX_API_URL}public/waterlevel_graph_year?station_type=tele_waterlevel&station_id=${id}&year=${selectedYear}`;
    fetch(
      `${url}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // fix case all value is null
        // const dataClean = (type === 'waterlevel') ? result.data.graph_data.filter(x => x.value !== null) : result.data.graph_data.filter(x => x.discharge !== null);
        const seriesValue = [];
        // set chart options
        let chartOptions = this.getChartOptions();
        if (result.data.graph_data.length > 0) {
          // transform datas
          let dataSeries = [];
          let ts = null;
          let val = null;
          let max = {}
          let min = {}

          // result.data.graph_data.map(n => {
          //   val = parseFloat((type === 'waterlevel') ? n.value : n.discharge);
          //   ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
          //   dataSeries.push([ts, val]);
          //   return dataSeries;
          // });
          let waterlevel_type = type === 'waterlevel' ? 'waterlevel_msl' : 'discharge'
          let suffix_txt = type === 'waterlevel' ? t('ม.รทก.') : t('ม.3/วิ.')

          result.data.graph_data.reverse().map((d, i) => {
            const series = [];
            orderBy(d.data, ['date'], ['asc']).map(n => {
              // by pass 29 Feb
              // 2024-02-05 เปลียนจาก && เป็น || เนื่องจากข้อมูลวันที่ 29 ไม่แสดง
              max = this.findMaxValue(d.data, waterlevel_type)
              min = this.findMinValue(d.data, waterlevel_type)

              if ((moment(n.datetime).format('M') !== 2) || (moment(n.datetime).date() !== 29)) {
                if (n[waterlevel_type] !== null) {
                  val = parseFloat(n[waterlevel_type]).toFixed(2);
                  seriesValue.push(val);
                } else {
                  val = null;
                }
                // console.log(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`);
                // ts = moment(n.datetime, 'YYYY-MM/DD hh:mm:ss').add(7, 'hours').valueOf();
                ts = moment(`2020-${moment(n.datetime).format('MM')}-${moment(n.datetime).format('DD')}`, 'YYYY/MM/DD').add(1, 'days').valueOf();
                series.push([ts, parseFloat(val)]);
              }

              return series;
            });

            dataSeries.push({
              name: `${d.year}`,
              data: series,
              dashStyle: 'solid',
              tooltip: {
                shared: true,
                valueSuffix: ` ${suffix_txt}`,
                xDateFormat: '%d %B',
              },
            });

            return null;
          });

          const { qmax } = result.data;

          // let max = maxBy(result.data.graph_data, (type === 'waterlevel') ? 'value' : 'discharge');
          // let min = minBy(result.data.graph_data, (type === 'waterlevel') ? 'value' : 'discharge');
          chartOptions.series = dataSeries;
          let maxYAxis = null;
          let minYAxis = null;

          if (max && max.discharge) {
            maxYAxis = maxBy([max.discharge, qmax]) + 0.5;
          }

          if (min && min.discharge) {
            minYAxis = min.discharge - 0.5;
          }

          if (type == 'discharge' && maxYAxis == null) {
            dataSeries = undefined
          }

          chartOptions.yAxis.max = maxYAxis;
          chartOptions.yAxis.min = minYAxis;


          // plotLines
          if (qmax) {
            chartOptions.yAxis.plotLines[0].value = qmax;
            chartOptions.yAxis.plotLines[0].label.text = `${t('ความจุลำน้ำ')} <strong>${result.data
              .qmax}</strong> ${t('ม.3/วิ.')}`;
          }

          if (type === 'waterlevel') {
            // result.data.graph_data.map(n => {
            //   val = parseFloat(n.value);
            //   ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
            //   dataSeries.push([ts, val]);

            //   return dataSeries;
            // });

            // max = maxBy(result.data.graph_data, 'value');
            // min = minBy(result.data.graph_data, 'value');

            // set chart options
            chartOptions = this.getWaterChartOptions();
            const depth = parseFloat(result.data.min_bank - result.data.ground_level).toFixed(2);

            chartOptions.yAxis.max = maxBy([result.data.min_bank, max.waterlevel_msl + 0.5]);
            chartOptions.yAxis.min = minBy([result.data.ground_level, min.waterlevel_msl]);
            // chartOptions.series[0].data = dataSeries;
            chartOptions.series = dataSeries;

            // plotLines
            if (result.data.min_bank) {
              chartOptions.yAxis.plotLines[0].value = result.data.min_bank;
              chartOptions.yAxis.plotLines[0].label.text = `${t('ระดับตลิ่ง')} <strong>${parseFloat(result.data
                .min_bank).toFixed(2)}</strong> ${t('ม.รทก.')}`;
            }

            if (result.data.ground_level) {
              chartOptions.yAxis.plotLines[1].value = result.data.ground_level;
              chartOptions.yAxis.plotLines[1].label.text = `${t('ระดับท้องน้ำ')} <strong>${parseFloat(
                Math.round(result.data.ground_level * 100) / 100,
              ).toFixed(2)}</strong> ${t('ม.รทก.')}`;
            }

            // plotBands
            if (result.data.min_bank && result.data.ground_level) {
              chartOptions.yAxis.plotBands[0].from = result.data.min_bank;
              chartOptions.yAxis.plotBands[1].from = result.data.ground_level + depth * 0.7;
              chartOptions.yAxis.plotBands[1].to = result.data.min_bank;
              chartOptions.yAxis.plotBands[2].from = result.data.ground_level + depth * 0.3;
              chartOptions.yAxis.plotBands[2].to = result.data.ground_level + depth * 0.7;
              chartOptions.yAxis.plotBands[3].from = result.data.ground_level + depth * 0.1;
              chartOptions.yAxis.plotBands[3].to = result.data.ground_level + depth * 0.3;
              chartOptions.yAxis.plotBands[4].from = result.data.ground_level;
              chartOptions.yAxis.plotBands[4].to = result.data.ground_level + depth * 0.1;
            }

          }
          // console.log(chartOptions);
          this.setState({
            dataChart: dataSeries,
            chartOptions,
            isLoading: false,
          });

        } else {
          this.setState({ isLoading: false, dataChart: undefined });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      dataProvince, id, provinceId, teleStation, canalStation, startDate, endDate, today,
      dataChart, type, selectedYear,
    } = this.state;
    const { isLoading, error, chartOptions } = this.state;
    const { t, classes } = this.props;

    let name = '';
    if (teleStation.length > 0) {
      let provinceName = '';
      let stationName = '';
      const stationByClick = teleStation.filter(
        d => parseInt(d.station_id, 10) === parseInt(id, 10),
      )[0];
      const station = stationByClick === undefined ? teleStation[0] : stationByClick;

      if (station) {
        provinceName = station.province_name.th;
        stationName = (station.station_name.th)
          ? tJson(station.station_name, 'สถานี') : station.station_old_code;
      } else {
        const canal = canalStation.filter(d => parseInt(d.id, 10) === parseInt(id, 10))[0];

        provinceName = tJson(canal.province_name, 'จ.');
        stationName = tJson(canal.station_name, 'สถานี');
      }

      // check if station contain province name, omit show duplicate province name
      if (stationName.includes('จ.')) {
        name = `${t('สถานี')}${stationName}`;
      } else {
        name = `${t('สถานี')}${stationName} ${t('จ.')}${provinceName}`;
      }
    }

    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }
    let info = '';
    if (dataChart) {
      info = (
        <div style={{ color: '#A2A2A2' }}>
          *
          {''}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    } else {
      info = '';
    }
    let years = yearsRange(currentYear, 1993);
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl} style={{ whiteSpace: 'nowrap' }}>
              <InputLabel htmlFor="type">{t('ประเภทข้อมูล')}</InputLabel>
              <Select
                value={type}
                onChange={this.handleTypeChange}
                inputProps={{
                  name: 'selectedType',
                  id: 'type',
                }}
                style={{ minWidth: 140 }}
              >
                <MenuItem value="waterlevel">{t('ระดับน้ำ')}</MenuItem>
                <MenuItem value="discharge">{t('ปริมาณน้ำท่า')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select value={provinceId} onChange={this.handleProvinceChange}>
                {dataProvince.map(row => (
                  <MenuItem key={row.province_code} value={row.province_code}>
                    {tJson(row.province_name, 'จ.')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <NativeSelect value={id} onChange={this.handleStationChange}>
                <optgroup label={t('โทรมาตร')}>
                  {teleStation.map(row => (
                    <option key={row.station_id} value={row.station_id}>
                      {row.station_old_code}
                      {' '}
                      {tJson(row.station_name, 'สถานี')}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={t('คลอง')}>
                  {canalStation.map(row => (
                    <option key={row.id} value={row.id}>
                      {tJson(row.station_name, 'สถานี')}
                    </option>
                  ))}
                </optgroup>
              </NativeSelect>
            </FormControl>
          </Grid>
          {/* <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid> */}
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="year">{t('ปี')}</InputLabel>
              <Select
                multiple
                native
                value={selectedYear}
                onChange={this.handleSelectYearChange}
                input={<Input id="year" />}
                renderValue={selected => selected.join(', ')}
                // MenuProps={MenuProps}
                inputProps={{
                  name: 'selectedYear',
                  id: 'year',
                }}
              >
                {years.map(year => (
                  <option key={year} value={year} defaultValue={(selectedYear.indexOf(year) > -1) ? 'selected' : false}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>

          <Grid item xs={12} align="center" style={{ fontFamily: 'Lucida Grande,Lucida Sans Unicode,Arial,Helvetica, sans-serif', fontSize: '12px' }}>
            {name}
          </Grid>
          <Grid item xs={12}>
            {dataChart ? (
              <HighchartsReact
                ref={this.props.chartRef}
                highcharts={Highcharts}
                options={chartOptions}
                containerProps={{ style: { width: '100%', height: '100%' } }}
              />
            ) : (
              <Grid item xs={12} align="center">{t('ไม่มีข้อมูลในช่วงเวลาที่เลือก')}</Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

RidChartYear.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  selectedType: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
  modalSize: PropTypes.string.isRequired,
};

export default compose(withModal, withStyles(styles), withTranslation('translations', { withRef: true }))(RidChartYear);
