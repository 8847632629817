import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies

import { useTranslation } from 'react-i18next';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// leaflet
import L from 'leaflet';
import 'plotty';
import 'leaflet-geotiff';
import '../../widgets/map/leaflet-geotiff-plotty';

// style
import './Style.css';
import 'leaflet/dist/leaflet.css';

// components
import Legend from './Legend';
import CompRadarNavigation from './CompRadarNavigation';

const useStyles = makeStyles(theme => ({
  styleBanner: {
    zIndex: 99999,
    textAlign: 'center',
    position: 'fixed',
    top: '30px',
    left: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  infoStyle: {
    zIndex: 99999,
    textAlign: 'left',
    position: 'fixed',
    left: '10px',
    top: '300px',
    width: '20%',
  },
}));

export default function CompRadarMap() {
  const { t } = useTranslation();
  // set variable env.
  const initPath = process.env.MIX_APP_PATH;
  const banner = `${initPath}images/models/banner_model-compradar.png`;

  // variable reference.
  const refMap = useRef(null);
  const refCompRadar = useRef(null);

  const [getOpenLegend, setOpenLegend] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [getDate, _setDate] = useState('ตรวจวัด');
  const myStateRef = React.useRef(getDate);
  const setDate = data => {
    myStateRef.current = data;
    _setDate(data);
  };

  const handleClickLegend = () => {
    if (getOpenLegend === false) {
      setOpenLegend(true);
    } else {
      setOpenLegend(false);
    }
  };

  function handleInitDate(val) {
    setDate(val);
  }

  function handleDateChange(val, txt) {
    setDate(txt);
    refCompRadar.current.setURL(val);
    // refCompRadar.current.setURL(`https://live1.hii.or.th/product/latest/comp_radar/${val}`);
  }

  // open rainfall popup when click map
  function handleGeotiffLayerClick(e) {
    const { lat, lng } = e.latlng;

    // set boundary
    if (lat > 20 || lat < 10.4 || lng < 96 || lng > 105) {
      // not avaliable
    } else {
      let val = parseFloat(refCompRadar.current.getValueAtLatLng(lat, lng))
        .toFixed(1);

      // clean up no rainfall data
      if (Number.isNaN(val) || parseInt(val, 10) < 0) {
        val = '--';
      }

      const html = `<strong>${myStateRef.current}</strong><hr /><span class="popupText">ความเข้มฝน <strong>${val}</strong> มม./ชม.<br />Lat: ${lat.toFixed(3)}, Lng: ${lng.toFixed(3)}</span>`;
      L.popup().setLatLng(e.latlng).setContent(html).openOn(refMap.current);
    }
  }

  // create map
  useEffect(() => {
    // base map
    // https://gis.stackexchange.com/questions/225098/using-google-maps-static-tiles-with-leaflet
    // https://stackoverflow.com/questions/33343881/leaflet-in-google-maps
    const baseMaps = {
      'OpenStreetMap.Mapnik': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { checked: true }),
      'ESRI World Topo': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'),
      BlackAndWhite: L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'),
      'Google Streets': L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      'Google Hybrid': L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      'Google Sattlite': L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      'Google Terrain': L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    };

    // init map
    refMap.current = L.map('map', {
      center: [13, 101.6],
      zoom: 6,
      layers: [
        baseMaps['OpenStreetMap.Mapnik'],
      ],
    });

    refMap.current.on('click', e => {
      handleGeotiffLayerClick(e);
    });

    // map control
    L.control.layers(baseMaps, null, { position: 'topleft' }).addTo(refMap.current);
    const info = L.control({ position: 'topleft' });

    info.onAdd = function add() {
      this._div = L.DomUtil.create('div', 'info');
      info.update();

      return this._div;
    };

    info.update = function update() {
      this._div.innerHTML = '<h4>Info</h4>';

      document.body.addEventListener('mouseover', e => {
        if (e.target.innerText === 'Info') {
          setIsShown(true);
        }
      });

      document.body.addEventListener('mouseout', e => {
        if (e.target.innerText === 'Info') {
          setIsShown(false);
        }
      });
    };
    info.addTo(refMap.current);

    // add geotiff layer
    // const geotiffUrl = 'http://fews2.hii.or.th/model-output/data_portal/radar/observe/202008111025.tif';
    const geotiffUrl = 'http://fews2.hii.or.th/model-output/data_portal/radar/default.tif';
    const geotiffOptions = {
      band: 0,
      // opacity: 0.65,  // not work
      name: 'Composite Radar',
      renderer: new L.LeafletGeotiff.Plotty({
        displayMin: 0,
        displayMax: 200,
        colorScale: 'compRadarScale',
        clampLow: false,
        clampHigh: false,
      }),
    };

    refCompRadar.current = L.leafletGeotiff(geotiffUrl, geotiffOptions)
      .addTo(refMap.current);

    // add top margin to leaflet control
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '100px';

    // add opacity
    document.getElementsByClassName('leaflet-overlay-pane')[0].style.opacity = 0.5;
  }, []);

  const classes = useStyles();
  return (
    <>
      {/* map */}
      <div
        id="map"
        style={{
          height: window.height,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 99999,
        }}
      >
        {/* Legend */}
        {getOpenLegend ? <Legend /> : null }
        <Button
          variant="contained"
          style={{
            backgroundColor: 'white',
            zIndex: 99999,
            position: 'fixed',
            bottom: '1%',
            left: '1%',
          }}
          aria-haspopup="true"
          onClick={handleClickLegend}
        >
          {t('ความเข้มฝน (มม./ชม.)')}
        </Button>

        <CompRadarNavigation handler={handleDateChange} handlerInitDate={handleInitDate} />

        <img src={banner} alt="banner" className={classes.styleBanner} />

        {/* Info */}
        {isShown && (
        <div className={classes.infoStyle}>
          <Paper elevation={3} style={{ padding: '5px' }}>
            {t('ระบบประเมินฝนและคาดการณ์ฝนล่วงหน้า 1-3 ชั่วโมง ด้วยข้อมูลเรดาร์ตรวจอากาศ โครงการความร่วมมือระหว่าง สสน. และกรมฝนหลวงและการบินเกษตร')}
          </Paper>
        </div>
        )}
      </div>
    </>
  );
}
