/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */
import React from 'react';

const WlContext = React.createContext({});

export default WlContext;
