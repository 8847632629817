/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifycontent: 'center',
    backgroundColor: 'transparent',
    width: '100%',
    padding: 10,
    borderBottom: '3px solid #0C6AB6',
    fontSize: '1rem',
  },
}))(Tabs);

export const StyledTab = withStyles(theme => ({
  root: {
    lable: {
      fontSize: '0.8rem',
    },
    textTransform: 'none',
    color: '#000',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '0.8rem',
    margin: theme.spacing(1, 0),
    '&:focus': {
      opacity: 1,
    },
    whiteSpace: 'noWrap',
    [theme.breakpoints.down('xs')]: {
      width: 150,
      lineHeight: '20px',
      whiteSpace: 'normal',
    },

  },
}))(Tab);

export const StyleComp = theme => ({
  layout: {
    justifycontent: 'space-around',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      marginLeft: theme.spacing(2 * 4),
      marginRight: theme.spacing(2 * 4),
    },
  },
  contentStorm: {
    background: '#f5f5f5',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
  },
  shapeCircle: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  active_tab: {
    color: 'white',
    fontSize: '1rem',
    fontFamily: theme.typography.fontKanit,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    fontWeight: 400,
    borderBottom: '1px solid #ccc',
    [theme.breakpoints.down('xs')]: {
      // width: 200,
    },
    // maxWidth: '-webkit-fill-available',
    maxWidth: 'inherit',
  },
  tab: {
    backgroundColor: '#fff',
    fontFamily: theme.typography.fontKanit,
    opacity: 1,
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    '&:nth-of-type(even)': {
      borderRight: '1px solid #ccc',
      borderLeft: '0px solid #ccc',
    },
    border: '1px solid #ccc',
    borderBottom: 'none',

    // maxWidth: '-webkit-fill-available',
    maxWidth: 'inherit',
  },
  active_sub_tab: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontFamily: theme.typography.fontKanit,
    opacity: 1,
    fontWeight: 400,
    whiteSpace: 'noWrap',
    border: 'none',
    display: 'flex',
  },
  sub_tab: {
    color: '#000',
    fontSize: '1rem',
    fontFamily: theme.typography.fontKanit,
    opacity: 1,
    fontWeight: 400,
    border: 'none',
  },
  titleTabs: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  formControl: {
    fontFamily: theme.typography.fontKanit,
    margin: 0,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap',
    fontSize: '14px',
  },
  buttonSelect: {
    fontSize: '14px',
  },
  btnDam: {
    borderRadius: 30,
  },
  active_btnDam: {
    color: '#fff !important',
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
  },
  btnStyle: {
    borderRadius: 30,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  active_btnStyle: {
    color: '#fff !important',
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  formLableRadio: {
    fontWeight: 600,
  },
  fullImg: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  button: {
    marginRight: 20,
    fontFamily: theme.typography.fontKanit,
  },
  // active_tab: {
  //   color: 'white',
  //   fontSize: 16,
  //   fontFamily: theme.typography.fontKanit,
  //   backgroundColor: theme.palette.primary.main,
  //   opacity: 1,
  //   fontWeight: 400,
  //   borderBottom: '1px solid #ccc',
  //   [theme.breakpoints.down('xs')]: {
  //     // width: 200,
  //   },
  //   // maxWidth: '-webkit-fill-available',
  //   maxWidth: 'inherit',
  // },

  iconTabs: {
    marginRight: 10,
    marginTop: 5,
    color: '#555555',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
    },
  },
  // titleTabs: {
  //   fontSize: '1rem',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '1rem',
  //   },
  // },
  titleTabsSub: {
    fontSize: '0.9rem',
  },
  iconDateSelect: {
    marginRight: 8,
  },
  selectForm: {
    padding: 8,
    height: 44,
    border: '1px solid #ccc',
    borderRadius: 4,
  },

  contentImg: {
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  container: {
    maxHeight: 506,
  },
  icon: {
    padding: theme.spacing(0),
  },
  titleSub: {
    fontFamily: theme.typography.fontKanit,
    fontSize: 14,
    fontWeight: 400,
    paddingTop: theme.spacing(3),
  },
  titleBox: {
    fontFamily: theme.typography.fontKanit,
    fontSize: '0.8rem',
    fontWeight: 400,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(1),
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
  },
  content: {
    background: '#fff',
  },
  imgList: {
    width: '100%',
  },
  fileVdo: {
    width: '100%',
  },
  titleStatusGrey: {
    background: '#bdbdbd',
  },
  titleStatusSky: {
    background: '#add4ff',
  },
  titleStatusOrange: {
    background: '#FE8A15',
  },
  titleStatusGreen: {
    background: '#69c902',
  },
  titleStatusRed: {
    background: '#ff0000',
  },
  titleSecondBox: {
    fontFamily: theme.typography.fontKanit,
    fontSize: 16,
    fontWeight: 400,
    background: theme.palette.background.grey,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(1),
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  titleThirdBox: {
    fontFamily: theme.typography.fontKanit,
    fontSize: 16,
    fontWeight: 400,
    background: theme.palette.background.grey,
    textAlign: 'center',
    width: '40%',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    whiteSpace: 'noWrap',
  },
  tabStyle: {
    marginTop: theme.spacing(1),
    background: 'transparent',
    boxShadow: 'none',
  },
  tabModal: {
    width: '100%',
    fontFamily: theme.typography.fontKanit,
    fontSize: 12,
    marginTop: theme.spacing(0),
    background: 'transparent',
    boxShadow: 'none',
  },
  paperModal: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
  },
  responsive: {
    width: '100%',
    height: '100%',
  },
  img: {
    width: '100%',
  },
  Vdofile: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  logoMap: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  linkStyle: {
    borderRadius: 2,
    padding: theme.spacing(1),
    border: '1px solid #ddd',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
