/**
 * *
 * @description get window screen
 * @version 1.0.0
 * @author [Peerapong Srisom]
 */

export default function WindowScreen() {
  const width = 'lg'; // desktop screen
  const fullScreen = (localStorage.fullScreen && localStorage.fullScreen !== '') ? JSON.parse(localStorage.fullScreen) : '';
  const maxWidth = (fullScreen.maxWidth) ? fullScreen.maxWidth : width;

  return maxWidth;
}