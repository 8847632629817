/**
 * *
 * @version 1.0.0
 * @author [Manorot]
 * Modify by Thitiporn
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  flow, uniqueId, groupBy, orderBy,
} from 'lodash/fp';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import {
  Link,
} from 'react-router-dom';
import Img from 'react-image';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { SEO } from '../../../utils/Seo';

// ui
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import grey from '@material-ui/core/colors/grey';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import {
  FaHome, FaSpinner, FaPlay,
} from '../../../utils/Fontawesome';

import {
  styles,
} from '../../../styles/Style';

import UpperWindAnimation from './UpperWindAnimation';
import UpperWindLatest from './UpperWindLatest';
import WrfAnimation from '../../forecast/wrf/WrfAnimation';

const initPath = process.env.MIX_APP_PATH;

// mediaListMeta0dot6
// media_type_id 10 upper wind
// media_type_id 15 pressure
const mediaListMeta0dot6 = [
  { media_type_id: 10, time: '07:00' },
  { media_type_id: 15, time: '07:00' },
  { media_type_id: 10, time: '19:00' },
  { media_type_id: 15, time: '19:00' },
];
// mediaListMeta1dot5
// media_type_id 11 upper wind
// media_type_id 16 pressure
const mediaListMeta1dot5 = [
  { media_type_id: 11, time: '07:00' },
  { media_type_id: 16, time: '07:00' },
  { media_type_id: 11, time: '19:00' },
  { media_type_id: 16, time: '19:00' },
];

// lightbox
let images = [];

const currentDate = new Date(moment().subtract(1, 'day'));

class UpperWindHistory extends Component {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef();

    // console.log(this.props)
    const { match: { params: { height } } } = this.props;

    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      datas: [],
      groupDatas: [],
      displayState: 'history',
      height,
      valueDropDown: height,
    };
  }

  componentDidMount() {
    const { match: { params: { month, year } } } = this.props;
    const { height } = this.state;

    // latest data available (minus 1 days from current date)
    const startDate = new Date(moment().subtract(1, 'day'));

    let selectedHeight = '0dot6km';
    let selectedMonth = moment(startDate).format('MM');
    let selectedYear = moment(startDate).format('YYYY');

    if (height) selectedHeight = height;
    if (month && year) {
      selectedMonth = month;
      selectedYear = year;
    }

    this.setState({
      isLoading: true,
      height: selectedHeight,
      startDate,
      valueDropDown: selectedHeight,
    });

    this.fetchData(selectedHeight, selectedMonth, selectedYear);
  }

  fetchData = (height, selectedMonth, selectedYear) => {
    fetch(`${process.env.MIX_API_URL}analyst/upper_wind_history_img?height=${height}&month=${selectedMonth}&year=${selectedYear}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // const dataArray = _(result.data).orderBy(['datetime'], ['desc'])
        //   .groupBy(d => moment(d.datetime).format('YYYY-MM-DD'))
        //   .map((value, key) => ({ date: key, datas: value }))
        //   .value();
        const groupData = flow(
          orderBy(['datetime'], ['desc']),
          groupBy(d => moment(d.datetime).format('YYYY-MM-DD')),
        )(result.data);
        const dataArray = Object.entries(groupData)
          .map(([key, val]) => ({ date: key, datas: val }));

        this.setState({
          isLoading: false,
          datas: result.data,
          groupDatas: dataArray,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = date => {
    this.setState({
      startDate: date,
      isLoading: true,
    });

    const { height } = this.state;
    this.fetchData(height, moment(date).format('MM'), moment(date).format('YYYY'));
  };

  handleDateChangeButton = change => {
    this.setState({
      isLoading: true,
    });

    const { startDate } = this.state;
    let selectedDate = startDate;

    if (change === '1') {
      selectedDate = moment(startDate).add(1, 'months');
    } else if (change === '-1') {
      selectedDate = moment(startDate).add(-1, 'months');
    }
    this.setState({
      startDate: selectedDate,
    });

    const { height } = this.state;
    this.fetchData(height, moment(selectedDate).format('MM'), moment(selectedDate).format('YYYY'));
  }

  handleDropDownChange = event => {
    const { history } = this.props;
    const { value } = event.target;
    const { startDate } = this.state;
    this.setState({
      valueDropDown: value,
      height: value,

    });
    this.fetchData(value, moment(startDate).format('MM'), moment(startDate).format('YYYY'));

    const url = `${process.env.MIX_APP_PATH}weather/upperwind/history/${value}`;
    history.push(url);
    // window.location.reload();
    // window.location.href = url;
  }

  render() {
    images = [];
    const { classes, t, i18n } = this.props;
    const {
      isLoading,
      error,
      datas,
      groupDatas,
      photoIndex,
      isOpen,
      startDate,
      displayState,
      height,
      valueDropDown,
    } = this.state;

    const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;
    // moment.locale(languageI18n);

    let currentIndex = 0;
    let mediaListMeta = [];
    mediaListMeta = mediaListMeta1dot5;
    if (height === '0dot6km') {
      mediaListMeta = mediaListMeta0dot6;
    }

    const upperWindHistoryTitle = t('แผนที่อากาศ ที่ระดับความสูง') + ' ' + ((height === '0dot6km') ? ' 0.6 ' : ' 1.5 ') + ' ' + t('กม.');

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {error && (
                <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>

          <SEO 
            title={upperWindHistoryTitle}
            description={upperWindHistoryTitle}
            keywords={upperWindHistoryTitle}
          />

          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Link>
                <Link to={`${initPath}weather`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('อากาศ')}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('แผนที่อากาศ ที่ระดับความสูง')}
                  {' '}
                  {(height === '0dot6km') ? ' 0.6 ' : ' 1.5 '}
                  {' '}
                  {t('กม.')}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
            <Grid item xs={12} md={5}>
              <Box m={1} className={classes.titleName}>
                {t('แผนที่อากาศ ที่ระดับความสูง')}
                {' '}
                {(height === '0dot6km') ? ' 0.6 ' : ' 1.5 '}
                {' '}
                {t('กม.')}
              </Box>
            </Grid>
            <Grid item xs>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={valueDropDown}
                onChange={this.handleDropDownChange}
                className={classes.outlinedLeftPrimary}
              >
                <MenuItem value="0dot6km">{t('ความสูง 0.6 กม.')}</MenuItem>
                <MenuItem value="1dot5km">{t('ความสูง 1.5 กม.')}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพล่าสุด')}>
                <Button
                  className={classes.outlinedPrimary}
                  color={displayState === 'latest' ? 'primary' : 'default'}
                  variant={displayState === 'latest' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'latest' })}
                >
                  <ImageOutlinedIcon />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }} noWrap>{t('ดูภาพล่าสุด')}</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <Button
                  className={classes.outlinedPrimary}
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'history' })}
                >
                  <PhotoLibraryOutlinedIcon />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }} noWrap>{t('ดูภาพย้อนหลัง')}</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
                <Button
                  className={classes.outlinedPrimary}
                  color={displayState === 'animation' ? 'primary' : 'default'}
                  variant={displayState === 'animation' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'animation' })}
                >
                  <FaPlay />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }} noWrap>{t('ดูภาพเคลื่อนไหว')}</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 15, marginBottom: 0 }}>
            {displayState === 'history' && (
              <>
                <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
                <Grid item xs sm={4} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        openTo="month"
                        views={['year', 'month']}
                        value={startDate}
                        onChange={this.handleChange}
                        style={{ fontSize: 14 }}
                        maxDate={currentDate}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>

                {isLoading === true && (
                  <FaSpinner size={60} />
                )}
              </>
            )}
          </Grid>

          {displayState === 'history' && (
            <>
              {datas.length <= 0
                && (
                  <Grid container spacing={4}>
                    <Grid item xs>
                      <Typography variant="h6">
                        {t('ข้อมูล')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              {groupDatas.map(item => (
                <Grid container justify="center" spacing={4} key={uniqueId()}>
                  <Grid item xs={12} sm={12} md={12} lg={12} key={uniqueId()}>
                    <Box p={1} mt={2} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                      {moment(item.date).locale(languageI18n).format('LL')}
                    </Box>
                  </Grid>
                  {mediaListMeta.map(meta => {
                    const total = item
                      .datas
                      .filter(row => moment(row.datetime).format('HH:mm') === meta.time
                        && (row.media_type_id === meta.media_type_id))
                      .length;
                    if (total === 0) {
                      return (
                        <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                          <Box className={classes.boxWeatherHistory}>
                            <Box display="block" align="center">
                              <Img
                                src="https://via.placeholder.com/300x250.png?text=No+Data"
                                width="300"
                                height="250"
                                className={classes.responsive}
                                alt="No Data"
                              />
                            </Box>
                            <Box p={1} display="flex" alignItems="center" justifyContent="center">
                              <Typography className={classes.dateTime}>
                                {`${t('เวลา')} : `}
                                {' '}
                                {meta.time}
                                {' '}
                                {t('น.')}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }

                    return (
                      <Fragment key={uniqueId()}>
                        {item
                          .datas
                          .filter(row => moment(row.datetime).format('HH:mm') === meta.time
                            && (row.media_type_id === meta.media_type_id))
                          .map(row => {
                            const currentImgIndex = currentIndex;
                            let realImage = '';
                            if (row.media_path_thumb !== null) {
                              images.push({
                                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                                title: `${moment(row.datetime).locale(languageI18n).format('LLL')} ${t('น.')}`,
                              });

                              realImage = (
                                <Button
                                  onClick={() => this.setState({
                                    isOpen: true,
                                    photoIndex: currentImgIndex,
                                  })}
                                >
                                  <Img
                                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                                    width="300"
                                    height="250"
                                    className={classes.responsive}
                                    alt={row.datetime}
                                  />
                                </Button>
                              );

                              currentIndex += 1;
                            } else {
                              realImage = (
                                <Img
                                  src="https://via.placeholder.com/300x250.png?text=No+Data"
                                  width="300"
                                  height="250"
                                  className={classes.responsive}
                                  alt="No Data"
                                />
                              );
                            }

                            return (
                              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                                <Box className={classes.boxWeatherHistory}>
                                  <Box display="block" align="center">
                                    {realImage}
                                  </Box>
                                  <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                    <Typography className={classes.dateTime}>
                                      {`${t('เวลา')} :`}
                                      {' '}
                                      {meta.time}
                                      {' '}
                                      {t('น.')}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Fragment>
                    );
                  })}
                </Grid>
              ))}
            </>
          )}

          {displayState === 'animation' && (
            <>
            <UpperWindAnimation keyData={height === '0dot6km' ? ' 0.6 ' : ' 1.5 '} />
            <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
            <Grid item xs={12} md={5}>
              <Box m={1} className={classes.titleName}>
                {t('แผนภาพคาดการณ์ฝนล่วงหน้า 7 วัน')}
              </Box>
            </Grid>
            </Grid>
            <WrfAnimation />
            </>
          )}

          {displayState === 'latest' && (
            <UpperWindLatest keyData={height === '0dot6km' ? ' 0.6 ' : ' 1.5 '} />
          )}

        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            // imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

UpperWindHistory.defaultProps = {
  match: {
    params: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  },
};

UpperWindHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  // theme: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      height: PropTypes.string,
      month: PropTypes.string,
      year: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withRouter(
  compose(
    withStyles(styles, { withTheme: true }),
    withTranslation(),
  )(UpperWindHistory),
);
