// import './wdyr'; // why did you render

import React from 'react';
import ReactDOM from 'react-dom';

import Root from './containers/Root';
import { ModalProvider } from './contexts/ModalContext';
// import RootOnwr from './containers/RootOnwr';

// const { search } = window.location;
// const params = new URLSearchParams(search);
// const app = params.get('app');


if (document.getElementById('app')) {
  // debug container is found?
  // alert('Found app element');
  // if (app !== 'onwr') {
  ReactDOM.render(
    <ModalProvider>
      <Root />
    </ModalProvider>,
    document.getElementById('app'),
  );
  // } else {
  //   ReactDOM.render(
  //     <RootOnwr />,
  //     document.getElementById('app'),
  //   );
  // }
}
