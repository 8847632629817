import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

// ui
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';

// style and icons
import { withStyles } from '@material-ui/core/styles/';

// lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// color
import grey from '@material-ui/core/colors/grey';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import tJson from '../../utils/TJson';

import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import RainfallStatusColor from './RainfallStatusColor';
import RainfallContext from '../../contexts/rainfallContext';
import RainfallChart from './RainfallChart';
import {
  FaChartLine,
} from '../../utils/Fontawesome';
import {
  styles, StyledTableRow,
} from '../../styles/Style';

// table Header
const columnData = [
  {
    id: 'station.tele_station_name.th', disablePadding: false, label: 'ชื่อสถานี', sortable: true, align: 'left',
  },
  {
    id: 'location', disablePadding: false, label: 'ที่ตั้ง', unit: '', sortable: false, align: 'left',
  },
  {
    id: 'rainfall_datetime', disablePadding: false, label: 'เวลา', sortable: true, align: 'center',
  },
  {
    id: 'rainfall_value', disablePadding: false, label: 'ฝนล่าสุด (มม.)', sortable: true, align: 'center',
  },
  {
    id: 'chart', disablePadding: false, label: '', align: 'center',
  },
];
class RainfallTodayList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arrayCrosssectionImage: [],
      photoIndex: 0,
      isOpen: false,
      page: 0,
      rowsPerPage: 10,
      type: 'rainfallToday',
      order: 'desc',
      orderByState: 'rainfall_value',
    };
  }

  componentDidMount() {
    this._mounted = true;
    const { datas, handleData } = this.props;
    handleData(datas, 'rainfallToday');
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChart = (handler, data, type, filterData) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: `${t('กราฟฝน')} ${tJson(data.station.tele_station_name, 'สถานี')}`,
      content: <RainfallChart
        filterData={filterData}
        id={data.station.id}
        provinceId={data.geocode.province_code}
        selectedType={type}
        year={(data.rainfall_datetime).substring(0, 4)}
        month={(data.rainfall_datetime).substring(5, 7)}
      />,
    };
    handler(params);
  }

  // get rainfall status
  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(1), type);
    return status;
  }

  // for sort table
  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByState === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  render() {
    const {
      classes, handleSearchMarker, latestDate, datas, t,
    } = this.props;
    const {
      photoIndex, isOpen, arrayCrosssectionImage, type,
      order, orderByState,
    } = this.state;
    const { rowsPerPage, page } = this.state;

    return (
      <Fragment key="key">
        <TableContainer>
          <Table className={classes.table}>
            <TableHeaderEnhance
              columnData={columnData}
              order={order}
              orderBy={orderByState}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {orderBy(datas, orderByState, order)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(data => {
                  let mapLink = tJson(data.station.tele_station_name, 'สถานี');
                  if (
                    handleSearchMarker
                    && data.station.tele_station_lat
                    && data.station.tele_station_long
                  ) {
                    mapLink = (
                      <Button
                        onClick={handleSearchMarker(
                          data.id,
                          data.station.tele_station_lat,
                          data.station.tele_station_long,
                        )}
                        title={tJson(data.agency.agency_name, 'หน่วยงาน')}
                        style={{ textAlign: 'left', paddingLeft: 5 }}
                      >
                        {tJson(data.station.tele_station_name, 'สถานี')}
                      </Button>
                    );
                  }
                  const rainfall = parseFloat(data.rainfall_value).toFixed(1);
                  const rainfallStatusC = this.rainfallStatus(rainfall, type).color.statusColor;
                  const rainfallStatusFontColor = this.rainfallStatus(rainfall, 'rainfall24h').color.constrastColor;
                  return (
                    <StyledTableRow key={data.id}>
                      <TableCell component="th" scope="row" style={{ whiteSpace: 'pre-wrap' }}>
                        {mapLink}
                      </TableCell>
                      <TableCell align="left">
                        {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                        {' '}
                        {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                        {' '}
                        {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                        {' '}
                      </TableCell>
                      <TableCell align="left">
                        {`${data.rainfall_datetime.substring(data.rainfall_datetime.length - 5)} ${t('น.')}`}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          borderRadius={3}
                          style={{
                            align: 'center',
                            margin: '0 auto',
                            backgroundColor: rainfallStatusC,
                            color: rainfallStatusFontColor,
                            width: '45px',
                          }}
                        >
                          {parseFloat(data.rainfall_value).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Box>
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <RainfallContext.Consumer>
                          {({ handleModalOpen }) => (
                            <Fragment key="key">
                              <Tooltip title={`${tJson(data.station.tele_station_name, 'สถานี')} \n ${t('คลิ๊กเพื่อแสดงกราฟ')}`}>
                                <IconButton className={classes.button} aria-label="Chart" onClick={this.handleChart(handleModalOpen, data, type, datas)}>
                                  <FaChartLine fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )}
                        </RainfallContext.Consumer>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              {datas.length === 0 && (
                <StyledTableRow>
                  <TableCell colSpan={5} align="center">{t('ไม่มีข้อมูล')}</TableCell>
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={5}
                  count={datas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                  labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
                  labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to}  ${count} ${t('รายการ')}`}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={grey[600]}>
          {`${t('ล่าสุด')} ${latestDate} ${t('น.')}`}
        </Box>
        {isOpen && (
          <Lightbox
            mainSrc={arrayCrosssectionImage[photoIndex]}
            nextSrc={arrayCrosssectionImage[(photoIndex + 1) % arrayCrosssectionImage.length]}
            prevSrc={
              arrayCrosssectionImage[
              (photoIndex + arrayCrosssectionImage.length - 1) % arrayCrosssectionImage.length]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => this.setState({
              photoIndex: (photoIndex + arrayCrosssectionImage.length - 1)
                % arrayCrosssectionImage.length,
            })}
            onMoveNextRequest={() => this.setState({
              photoIndex: (photoIndex + 1) % arrayCrosssectionImage.length,
            })}
            reactModalStyle={{
              overlay: { zIndex: '10000000' },
            }}
            imagePadding={85}
          />
        )}
      </Fragment>
    );
  }
}

RainfallTodayList.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  latestDate: PropTypes.string.isRequired,
  handleData: PropTypes.func.isRequired,
  handleSearchMarker: PropTypes.func.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }), withTranslation('translations', { withRef: true }))(RainfallTodayList);
