import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  video: {
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 800,
    },
  },
});

class Animation extends Component {
  render() {
    const { media, classes } = this.props;

    return (
      <video controls autoPlay className={classes.video}>
        <source src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${media}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
}

Animation.propTypes = {
  media: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Animation);
