/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@material-ui/core/Box';
import {
  styles, lineBox,
} from '../../styles/Style';

const RainfallLegend = () => (
  <div>
    <Box display="flex" width="100%" justifyContent="center" alignContent="center">
      <Box m={0} pb={0} borderTop={0} border={1} borderColor={lineBox} display="inline" width="15%">
        <Box display="flex" borderTop={1} borderColor={lineBox}>
          <Box display="inline" style={{ backgroundColor: '#AED3FF' }} width="100%" py={0}>
            <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
              {'>0-10 '}
            </div>
          </Box>
        </Box>
        <Box display="block" pb={-3} borderTop={1} borderColor={lineBox} aling="center">
          <div className={styles.RainfallLegend} align="center" style={{ fontSize: 11 }}>
            เล็กน้อย
          </div>
        </Box>
      </Box>
      <Box m={0} pb={0} borderTop={0} borderLeft={0} border={1} borderColor={lineBox} display="inline" width="35%">
        <Box display="flex" borderTop={1} borderColor={lineBox} aling="center" width="100%">
          <Box display="inline" style={{ backgroundColor: '#aeecb7' }} width="50%" py={0}>
            <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
              {'>10-20 '}
            </div>
          </Box>
          <Box display="inline" style={{ backgroundColor: '#7fc63c' }} width="50%" py={0}>
            <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
              {'>20-35 '}
            </div>
          </Box>
        </Box>
        <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
          <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
            ปานกลาง
          </div>
        </Box>
      </Box>
      <Box m={0} pb={0} borderTop={0} borderLeft={0} border={1} borderColor={lineBox} display="inline" aling="center" width="35%">
        <Box display="flex" borderTop={1} borderColor={lineBox} aling="center" width="100%">
          <Box display="inline" style={{ backgroundColor: '#f0d447' }} width="50%" py={0}>
            <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
              {'>35-50 '}
            </div>
          </Box>
          <Box display="inline" style={{ backgroundColor: '#ef9036' }} width="50%" py={0}>
            <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
              {'>50-70 '}
            </div>
          </Box>
          <Box display="inline" style={{ backgroundColor: '#bd6a27' }} width="50%" py={0}>
            <div className={styles.regend} align="center" style={{ fontSize: 11, color: 'white' }}>
              {'>70-90 '}
            </div>
          </Box>
        </Box>
        <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
          <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
            หนัก
          </div>
        </Box>
      </Box>
      <Box m={0} pb={0} border={1} borderLeft={0} borderColor={lineBox} display="inline" aling="center" width="15%">
        <Box display="block" style={{ backgroundColor: '#da352e' }} py={0} width="100%">
          <div className={styles.regend} align="center" style={{ fontSize: 11, color: 'white' }}>
            {'>90 '}
          </div>
        </Box>
        <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
          <div className={styles.regend} align="center" style={{ fontSize: 11 }}>
            หนักมาก
          </div>
        </Box>
      </Box>
    </Box>
  </div>
);
export default RainfallLegend;
