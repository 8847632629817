import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import { Divider } from '@material-ui/core';

// style
import { styles } from './styleStormSurgeMap';

const useStyles = makeStyles(styles);
const initPath = process.env.MIX_APP_PATH;

export default function HeaderMap() {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const handleChange = event => {
    setValue(event.target.value);
    if (event.target.value === 'forecast') {
      window.location.href = `${initPath}storm_surge/forecast`;
    } else {
      window.location.href = `${initPath}storm_surge`;
    }
  };
  useEffect(() => {
    const parts = window.location.href.split('/');
    const lastSegment = parts.pop() || parts.pop();
    if (lastSegment === 'forecast') {
      setValue('forecast');
    } else {
      setValue('seaLevelMap');
    }
  }, []);

  return (
    <Box p={3}>
      <FormControl>
        <Box className={classes.titleMenu}>แสดงข้อมูลทะเลในอ่าวไทย</Box>
        <Divider />
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="seaLevelMap" control={<Radio />} label="แผนที่ระดับน้ำทะเลและเตือนภัย" />
          <Divider />
          <FormControlLabel value="forecast" control={<Radio />} label="แผนที่คาดการณ์ระดับน้ำทะเล" />
          <Divider />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
