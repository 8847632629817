/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
    marginBottom: 4,
  },
  label: {
    color: '#eee',
    fontSize: '0.9em',
    fontWeight: 400,
    width: '100%',
  },
  detail: {
    paddingTop: 5,
    color: 'white',
    fontSize: '0.9em',
    fontWeight: 400,
  },
  boxWL: {
    width: '100%',
  },
  legendWL: {
    fontSize: '0.8em',
    textAlign: 'center',
    height: '18px',
    width: '78px',
    marginRight: 8,
    marginBottom: 4,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
  },
});

const WaterLevelLegend = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        zIndex: 99999,
        left: '1%',
        bottom: '6%',
      }}
    >
      <Box p={1}>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            alignItems="center"
            style={{
              color: '#fff',
              backgroundColor: '#FF0000',
            }}
            className={classes.legendWL}
          >
            {'>100'}
          </Box>
          <Box className={classes.label}>ล้นตลิ่ง</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#fff',
              backgroundColor: '#003CFA',
            }}
            className={classes.legendWL}
          >
            {'>70-100'}
          </Box>
          <Box className={classes.label}>มาก</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#000',
              backgroundColor: '#00B050',
            }}
            className={classes.legendWL}
          >
            {'>30-70'}
          </Box>
          <Box className={classes.label}>ปกติ</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#000',
              backgroundColor: '#FFC000',
            }}
            className={classes.legendWL}
          >
            {'>10-30'}
          </Box>
          <Box className={classes.label}>น้อย</Box>
        </Box>
        <Box display="flex" className={classes.boxWL}>
          <Box
            justifyContent="center"
            style={{
              color: '#000',
              backgroundColor: '#DB802B',
            }}
            className={classes.legendWL}
          >
            {'<=10'}
          </Box>
          <Box className={classes.label}>น้อยวิกฤต</Box>
        </Box>
        <Box className={classes.detail}>ระดับน้ำในแม่น้ำ</Box>
      </Box>
    </div>
  );
};

export default WaterLevelLegend;
