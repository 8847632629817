import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import { compose } from 'recompose';
// import Link from '@material-ui/core/Link';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// icon
import {
  HiiClimateIcon, HiiStormIcon, HiiRain24Icon, HiiWaveIcon, HiiRadarIcon,
} from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

import WindowScreen from '../../utils/WindowsFullScreen';

const initPath = process.env.MIX_APP_PATH;

/**
 * Sidebar
 */
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // displayState: 'weather',
    };
  }

  render() {
    const { classes, t } = this.props;
    const displayState = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

    return WindowScreen() !== 'xl' && (
      <>
        <Hidden mdDown>
          <Grid container direction="column" spacing={0} className={classes.sidebar}>
            <Grid item md>
              <Button
                component={Link}
                to={`${initPath}weather`}
                className={classes.sidebarTopButton}
                color={displayState === 'weather' || displayState === 'weather-station' ? 'primary' : 'default'}
                variant={displayState === 'weather' || displayState === 'weather-station' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'weather' })}
              >
                <Box display="block">
                  <Box height={30} align="center">
                    <HiiClimateIcon />
                  </Box>
                  <Box align="center">
                    <Typography variant="caption">
                      {t('อากาศ')}
                    </Typography>
                  </Box>
                </Box>
              </Button>
              <Divider />
            </Grid>
            <Grid item md>
              <Button
                component={Link}
                to={`${initPath}weather/storm`}
                className={classes.sidebarButton}
                color={displayState === 'storm' ? 'primary' : 'default'}
                variant={displayState === 'storm' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'storm' })}
              >
                <Box display="block">
                  <Box height={30} align="center">
                    <HiiStormIcon />
                  </Box>
                  <Box align="center">
                    <Typography variant="caption">
                      {t('พายุ')}
                    </Typography>
                  </Box>
                </Box>
              </Button>
              <Divider />
            </Grid>
            <Grid item md>
              <Button
                component={Link}
                to={`${initPath}weather/rainfall`}
                className={classes.sidebarButton}
                color={displayState === 'rainfall' ? 'primary' : 'default'}
                variant={displayState === 'rainfall' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'rainfall' })}
              >
                <Box display="block">
                  <Box height={30} align="center">
                    <HiiRain24Icon />
                  </Box>
                  <Box align="center">
                    <Typography variant="caption">
                      {t('ฝน')}
                    </Typography>
                  </Box>
                </Box>
              </Button>
              <Divider />
            </Grid>
            <Grid item md>
              <Button
                component={Link}
                to={`${initPath}weather/radar`}
                className={classes.sidebarButton}
                color={displayState === 'radar' ? 'primary' : 'default'}
                variant={displayState === 'radar' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'radar' })}
              >
                <Box display="block">
                  <Box height={30} align="center">
                    <HiiRadarIcon />
                  </Box>
                  <Box align="center">
                    <Typography variant="caption">
                      {t('เรดาร์')}
                    </Typography>
                  </Box>
                </Box>
              </Button>
              <Divider />
            </Grid>
            <Grid item md>
              <Button
                component={Link}
                to={`${initPath}weather/sea`}
                className={classes.sidebarBottomButton}
                color={displayState === 'sea' ? 'primary' : 'default'}
                variant={displayState === 'sea' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'sea' })}
              >
                <Box display="block">
                  <Box height={30} align="center">
                    <HiiWaveIcon />
                  </Box>
                  <Box align="center">
                    <Typography variant="caption">
                      {t('ทะเล')}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Hidden>
      </>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};
// export default Sidebar;
export default compose(withStyles(styles, { withTheme: true }), withTranslation('translations', { withRef: true }))(Sidebar);