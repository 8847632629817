import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import L from 'leaflet';
import {
  Marker, Popup, Tooltip, LayerGroup,
} from 'react-leaflet';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';

import { useTranslation } from 'react-i18next';

// material ui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { Hidden } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

// icons and style
import {
  styles,
} from '../../../styles/Style';

const stationIcon = L.icon({
  iconUrl: '../images/marker/circle/whiteMarker.png',

  iconSize: [8, 8],
});

const useStyles = makeStyles(styles);

export default function RainfallMarker() {
  const { t } = useTranslation();
  const refRainfallStation = useRef(null);
  const [datas, setData] = useState([]);
  const classes = useStyles();

  function fetchData() {
    // popup data
    const url = 'https://live1.hii.or.th/product/latest/rain/one_map/data/anomaly_rain_6m.json';
    fetch(url).then(response => response.json())
      .then(result => {
        // transform data
        // code here
        // const d = transformData(result);
        // console.log(d)
        // const d = [
        //   {
        //     station_id: 300201,
        //     station_name: 'แม่ฮ่องสอน',
        //     amphoe: 'เมืองแม่ฮ่องสอน',
        //     tambon: 'จองคำ',
        //     latitude: 19.2999,
        //     longitude: 97.9726,
        //     value: [
        //       [
        //         {
        //           date: '202105',
        //           anomaly: 39,
        //         },
        //         {
        //           date: '202106',
        //           anomaly: 10,
        //         },
        //         {
        //           date: '202107',
        //           anomaly: 53,
        //         },
        //         {
        //           date: '202108',
        //           anomaly: 42,
        //         },
        //         {
        //           date: '202109',
        //           anomaly: -10,
        //         },
        //         {
        //           date: '202110',
        //           anomaly: -25,
        //         },
        //       ],
        //     ],
        //   },
        // ];

        setData(result);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function eachRainfallStation(data, i) {
    if (data.latitude && data.longitude) {
      // marker position
      const position = [parseFloat(data.latitude), parseFloat(data.longitude)];

      return (
        <Marker
          id={data.station_id}
          key={i}
          position={position}
          icon={stationIcon}
        >
          <Popup autoPan>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={4} align="center">
                      <Typography variant="subtitle2">
                        {`${data.station_name} `}
                        <br />
                        {`${data.tambon} `}
                        {`${data.amphoe} `}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.hiddenLine}>
                              {t('เดือน')}
                            </TableCell>
                            <TableCell className={classes.hiddenLine} align="right">
                              {t('ความผิดปกติของปริมาณฝน')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.value[0].map(val => (
                            <TableRow key={uniqueId()}>
                              <TableCell className={classes.hiddenLine}>
                                {moment(val.date).format('MMMM')}
                              </TableCell>
                              <TableCell className={classes.hiddenLine} align="right">
                                {val.anomaly}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={4}>
                      {t('หน่วย')}
                      {'('}
                      {t('มม.')}
                      {')'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {t('สถานีฝน')}
                  <hr />
                  {`${data.station_name} `}
                  <br />
                  {`${data.tambon} `}
                  {`${data.amphoe} `}
                </strong>
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  return (
    <LayerGroup ref={refRainfallStation}>
      {datas
        .map(eachRainfallStation)}
    </LayerGroup>
  );
}
