/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId, orderBy,
} from 'lodash';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import { CircularProgressbar } from 'react-circular-progressbar';
import Divider from '@material-ui/core/Divider';

// map
import L from 'leaflet';
import {
  Map, Marker, Popup, TileLayer, WMSTileLayer, Tooltip, GeoJSON, LayerGroup, LayersControl,
} from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';

import { withTranslation } from 'react-i18next';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

// color
import deepOrange from '@material-ui/core/colors/deepOrange';
import { FaChartLine } from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import tJson from '../../utils/TJson';
import { styles } from '../../styles/Style';

// color
import WlStatusColor from '../wl/WlStatusColor';
import WlStatusColorDB from '../wl/WlStatusColorDB';
import WlContext from '../../contexts/wlContext';
import WlChart from '../wl/WlChart';
import RidChartYear from '../wl_rid/RidChartYear';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import CurrentLocation from '../currentlocation/CurrentLocation';

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;
const { BaseLayer, Overlay } = LayersControl;

class WaterSitWaterLevelMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.waterLevelLayer = createRef();
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    this.state = {
      error: '',
      height: this.setHeightMap(),
      geojsonProvince: [],
      geojsonBasin: [],
    };

    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.getGeoJson = this.getGeoJson.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;

    this._mounted = true;

    this.getGeoJson();
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
    L.control.scale().addTo(this.map);
  }

  shouldComponentUpdate(nextProps) {
    const {
      datas,
      areaType,
      areaId,
      language,
    } = this.props;
    if ((datas === nextProps.datas)
      && (areaType === nextProps.areaType)
      && (areaId === nextProps.areaId)
      && (language === nextProps.i18n.language)
    ) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const { areaType, areaId } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    let obj = this.refBoundaryProvince.current.leafletElement._layers;

    // thailand
    if (areaId === '') {
      const { location, zoom } = this.props;
      this.map.setView(location, zoom);

      return;
    }

    if (areaType !== prevProps.areaType || areaId !== prevProps.areaId) {
      if (areaType === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }

      // get feature
      // eslint-disable-next-line no-underscore-dangle

      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          // change zoom snap to make fitBounds work

          // province
          if (areaType === '0') {
            const provCode = obj[key].feature.properties.prov_code;
            if (provCode.toString() === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          } else { // basin
            const basinCode = obj[key].feature.properties.BASIN_CODE;
            if (basinCode === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getGeoJson() {
    Promise.all([
      fetch(`${initPath}json/boundary/thailand.json`),
      fetch(`${initPath}json/boundary/basin.json`),
    ])
      .then(([res2, res3]) => Promise.all([res2.json(), res3.json()]))
      .then(([province, basin]) => {
        this.setState({ geojsonProvince: province, geojsonBasin: basin });
      })
      .catch(error => this.setState({ error }));
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 600;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 600;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 600;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 450;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  // set marker icon based on criteria
  setIcon = (currentWl, previousWl, storagePercent) => {
    // const status = WlStatusColor(val);
    const status = WlStatusColorDB(currentWl, previousWl, storagePercent);

    return status.icon;
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle(feature) {
    const { areaType, areaId } = this.props;

    if (areaType === '1' && areaId) {
      if (feature.properties.BASIN_CODE === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getProvinceStyle(feature) {
    const { areaType, areaId } = this.props;
    if (areaType === '0' && areaId) {
      if (feature.properties.prov_code === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  handleWaterLevelChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: `${t('กราฟระดับน้ำ')}`,
      content: <WlChart
        id={data.station.id}
        provinceId={data.geocode.province_code}
      />,
    };
    handler(params);
  }

  handleWaterLevelChartYear = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: t('กราฟระดับน้ำรายปี'),
      content: <RidChartYear
        id={data.station.id}
        provinceId={data.geocode.province_code}
        selectedType="waterlevel"
      />,
    };
    handler(params);
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      if (this.refMap.current.leafletElement) {
        // const map = this.refMap.current.leafletElement;
        // map.on('popupopen', function (e) {
        //   var px = map.project(e.target._popup._latlng);
        //   px.y -= e.target._popup._container.clientHeight / 2;
        //   map.panTo(map.unproject(px), { animate: true });
        // });
      }
    }
    // if (ref) {
    //   markers[id] = ref.leafletElement;
    //   markers[id].addEventListener('click', e => {
    //     const { lat, lng } = e.target.getLatLng();
    //     this.map.panTo([parseFloat(lat+4), parseFloat(lng)]);
    //   });
    // }
  }

  formatWl = value => (typeof value === 'undefined' || value == null ? '-' : parseFloat(value).toFixed(2))

  eachData = (data, i) => {
    const {
      location, zoom, datas, classes, t,
    } = this.props;
    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.waterlevel_msl != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];

      // marker icons
      const icon = this.setIcon(parseFloat(data.waterlevel_msl),
        parseFloat(data.waterlevel_msl_previous),
        parseFloat(data.storage_percent));

      // status colors
      const statusColor = WlStatusColor(data.storage_percent);

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)} `;
      }
      // zIndexOffset เรียงใหม่โดยเอา situationlevel*1000 มาเป็นตัวจัดการว่า marker ไหนควรสูงกว่า
      // eg. data 10 ตัว 1[0 6 4 5] 2[8 3] 3[1 7 2 9] -> zindex เก่าที่ได้ 0 1 2 3 4 5 6 7 8 9
      // เอา situationLevel มาเพิ่ม
      //     data 10 ตัว แบบ situationlevel 1-3
      //     situationlevel 1 -> 1000 1004 1005 1006
      //     situationlevel 2 -> 2003 2008
      //     situationlevel 3 -> 3001 3002 3007 3009
      // zindex ใหม่ 1000 1004 1005 1006 2003 2008 3001 3002 3007 3009

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
          zIndexOffset={(data.situation_level * 1000) + i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2" className={classes.popupTitle}>
                        {tJson(data.station.tele_station_name, 'สถานี')}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.popupSubtitle}>
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <div>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.hiddenLine} align="center" rowSpan={2} style={{ width: '50%', fontSize: '1.2em' }}>
                                <Box display="flex" alignItems="center" justifyContent="center" style={{ width: '100%', height: '100%' }}>
                                  <Box
                                    borderRadius={4}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                      backgroundColor: statusColor.color.statusColor,
                                      color: statusColor.color.constrastColor,
                                      lineHeight: 3,
                                      width: '40%',
                                      height: 50,
                                      fontWeight: 'bold',
                                      fontSize: t(`น้ำ${statusColor.color.label}`) === 'น้ำน้อยวิกฤต' ? '0.8em' : '1em',
                                    }}
                                  >
                                    {`${t(`น้ำ${statusColor.color.label}`)}`}
                                  </Box>
                                  <Box pl={2} display="block" justifyContent="flex-start">
                                    <Box
                                      style={{
                                        fontSize: '0.8em',
                                      }}
                                    >
                                      {t(data.diff_wl_bank_text)}
                                    </Box>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      style={{
                                        color: statusColor.color.statusColor,
                                        fontWeight: 'bold',
                                        fontSize: '2em',
                                        lineHeight: 1,
                                      }}
                                    >
                                      {data.diff_wl_bank}
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} />
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} className={classes.hiddenLine} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList].join(' ')}><strong>{t('ระดับน้ำ')}</strong></TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValue].join(' ')} align="right">
                      {this.formatWl(data.waterlevel_msl)}
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupUnit].join(' ')} align="right">
                      {t('ม.รทก.')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList].join(' ')}><strong>{t('ระดับตลิ่ง')}</strong></TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValue].join(' ')} align="right">
                      {this.formatWl(data.station.min_bank)}
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupUnit].join(' ')} align="right">
                      {t('ม.รทก.')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={[classes.hiddenLine, classes.popupList].join(' ')}><strong>{t('ระดับท้องน้ำ')}</strong></TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupValue].join(' ')} align="right">
                      {this.formatWl(data.station.ground_level)}
                    </TableCell>
                    <TableCell className={[classes.hiddenLine, classes.popupUnit].join(' ')} align="right">
                      {t('ม.รทก.')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} colSpan={5}>
                      <Typography variant="caption" color="secondary" className={classes.popupRef}>
                        {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                      </Typography>
                      <Box>
                        <Typography variant="caption" color="secondary" className={classes.popupDate}>
                          {`${t('ล่าสุด')}: `}
                          {data.waterlevel_datetime}
                          {' '}
                          {`${t('น.')}`}
                        </Typography>
                      </Box>
                      <WlContext.Consumer>
                        {({ handleModalOpen }) => (
                          <>
                            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" height="0px">
                              <Button
                                color="secondary"
                                onClick={this.handleWaterLevelChart(handleModalOpen, data)}
                                title={t('แสดงกราฟ')}
                                style={{ padding: 0, }}
                              >
                                <TrendingUpIcon style={{ fontSize: '1.5em' }} className={classes.rightIcon} />
                              </Button>
                              <Button
                                color="secondary"
                                onClick={this.handleWaterLevelChartYear(handleModalOpen, data)}
                                title={t('แสดงกราฟรายปี')}
                                style={{ padding: 0, marginLeft: '10px' }}
                              >
                                <FaChartLine style={{ fontSize: '1.5em' }} className={classes.rightIcon} />
                              </Button>
                            </Box>
                          </>
                        )}
                      </WlContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {data.station.sponsor_by && (
                <>
                  <Divider />
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                      <Typography variant="caption" color="secondary">
                        {`สนับสนุนโดย`}<br />
                        {data.station.sponsor_by}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={tJson(data.station.tele_station_name, 'สถานี')}>
              <span>
                <strong>
                  <div style={{ whiteSpace: 'normal', width: 150 }}>{tJson(data.station.tele_station_name, 'สถานี')}</div>
                </strong>
                {/* <br /> */}
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                <br />

                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                <br />
                {data.station.sponsor_by && <div style={{ whiteSpace: 'normal', width: 150 }}>{`สนับสนุนโดย ${data.station.sponsor_by}`}</div>}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  render() {
    const {
      error, height, geojsonProvince, geojsonBasin,
    } = this.state;
    const {
      location, datas, zoom,
    } = this.props;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    return (
      <>
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          gestureHandling
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonBasin}
            style={this.getBasinStyle}
            ref={this.refBoundaryBasin}
            onEachFeature={this.onEachBasinFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            onEachFeature={this.onEachProvinceFeature}
          />
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Esri_WorldStreetMap">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <Overlay key="waterlevel" name="ระดับน้ำ" checked="true">
              <LayerGroup ref={this.waterLevelLayer}>
                {/* {datas.map(this.eachData)} */}
                {orderBy(datas.filter(d => (d.storage_percent != null) || (d.station.tele_station_oldcode.indexOf('GOT') === 0)), ['storage_percent'], ['desc']).map(this.eachData)}
              </LayerGroup>
            </Overlay>
            <Overlay name="ภาพถ่ายดาวเทียม 0.7m ชัยนาท">
              <TileLayer
                url="http://119.59.110.217:8888/geoserver/gwc/service/tms/1.0.0/satellogic:chainat@EPSG:900913@png/{z}/{x}/{-y}.png"
                id="ภาพถ่ายดาวเทียม 0.7m ชัยนาท"
                attribution="&copy; <a href=&quot;http://www.mappointasia.com&quot;>Map Point Asia</a> contributors"
              />
            </Overlay>
            <Overlay name="SAR 15m">
              <TileLayer
                url="http://119.59.110.217:8888/geoserver/gwc/service/tms/1.0.0/iceye:centraltha@EPSG:900913@png/{z}/{x}/{-y}.png"
                id="SAR 15m"
                attribution="&copy; <a href=&quot;http://www.mappointasia.com&quot;>Map Point Asia</a> contributors"
              />
            </Overlay>
            <Overlay name="เส้นแม่น้ำ" checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>


          </LayersControl>
          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />
        </Map>
      </>
    );
  }
}

WaterSitWaterLevelMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  areaType: PropTypes.string.isRequired,
  areaId: PropTypes.string.isRequired,
  i18n: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation('translations', { withRef: true }))(WaterSitWaterLevelMap);
