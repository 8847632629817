import L from 'leaflet';

const initPath = process.env.MIX_APP_PATH;
const sizeIcon = 16;
const twitter = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/twitter/twitter.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const fm91 = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/twitter/fm91_25x25.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const js100 = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/twitter/js100_25x25.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const pm = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/twitter/pm_25x25.png`,
  iconSize: [sizeIcon, sizeIcon],
});

const prdoh = new L.Icon({
  iconUrl: `${initPath}images/marker/bma/twitter/prdoh_25x25.png`,
  iconSize: [sizeIcon, sizeIcon],
});


const frColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปกติ',
    rangeTitle: '>0-10',
    statusColor: '#00b050',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้ำท่วมขังเล็กน้อย',
    rangeTitle: '>10-20',
    statusColor: '#003cfa',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'น้ำท่วม',
    rangeTitle: '>20',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const TwitterStatusColor = text => {
  let color;
  let icon;
  const [zero, one, two, three] = frColor;

  if (text === 'fm91trafficpro') {
    color = zero;
    icon = fm91;
  } else if (text === 'js100radio') {
    color = three;
    icon = js100;
  } else if (text === 'DDPMNews') {
    color = two;
    icon = pm;
  } else if (text === 'prdoh1') {
    color = two;
    icon = prdoh;
  } else {
    color = one;
    icon = twitter;
  }

  return { color, icon };
};

export default TwitterStatusColor;
