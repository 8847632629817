import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, orderBy, uniq } from 'lodash';
import { compose } from 'recompose';
// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
// import HighchartsMore from 'highcharts/highcharts-more';
import { withTranslation } from 'react-i18next';

import { FaSpinner } from '../../utils/Fontawesome';
import { withModal } from '../../contexts/ModalContext';
// init highcharts module
// HighchartsMore(Highcharts);
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

class DamChart extends Component {
  constructor(props) {
    super(props);

    this.createNavigatorChart = this.createNavigatorChart.bind(this);
    this.state = {
      isLoading: false,
      error: null,
      chartOptions: this.getChartOptions(),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const { chartCalType } = this.props;

    if (chartCalType !== 'sum') {
      this.renderChart();
    } else {
      this.renderChartSum();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalSize !== this.props.modalSize ||
      prevProps.isFullScreen !== this.props.isFullScreen) {
      this.props.updateChartSize();
    }
  }

  createNavigatorChart(e) {
    const baseChart = e.target;
    const baseYAxis = baseChart.yAxis[0];

    Highcharts.stockChart('customNavigator', {
      chart: {
        inverted: true,
        events: {
          render: function () {
            // ซ่อน text
            // const style = document.createElement('style');
            // style.innerHTML = `
            //   .highcharts-axis-labels {
            //     display: none !important;
            //   }
            // `;
            // document.head.appendChild(style);
            let style = this.renderTo.querySelector('#customNavigator-style');
            if (!style) {
              style = document.createElement('style');
              style.id = 'customNavigator-style';
              style.innerHTML = `
                #${this.renderTo.id} .highcharts-axis-labels {
                  display: none !important;
                }
              `;
              this.renderTo.appendChild(style); // Attach only to this chart's container
            }
          }
        }
        // marginTop: baseChart.plotTop,
        // marginBottom: baseChart.chartHeight - (baseChart.plotTop + baseChart.plotHeight)
      },
      rangeSelector: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        lineWidth: 1,
        min: baseYAxis.min,
        max: baseYAxis.max,
        reversed: false,
        visible: false,
        labels: { enabled: false }, // Hides x-axis labels
        tickPositions: [],
        events: {
          setExtremes: function (e) {
            baseYAxis.setExtremes(e.min, e.max, true, false);
          }
        }
      },
      title: {
        text: ''
      },
      yAxis: {
        lineWidth: 1,
        visible: false,
        labels: { enabled: false }, // Hides y-axis labels
        tickPositions: [],
      },
      navigator: {
        enabled: true,
        xAxis: {
          reversed: false
        },
        series: [{
          type: 'scatter',
          color: 'transparent',
          showInLegend: false,
          dataLabels: { enabled: false },
          data: [
            [baseYAxis.min, 1],
            [baseYAxis.max, 2]
          ]
        }]
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      series: [{}]
    });
  }

  getChartOptions() {
    const { name, t } = this.props;
    this.options = {
      title: {
        text: `${t(name)}`,
        style: {
          fontSize: '18px',  // Adjust the font size
          // whiteSpace: 'normal',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',  // Add ellipsis for long text
        }
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          marker: {
            enabled: undefined,
            enabledThreshold: 0.75,
            radius: 3,
          },
        },
      },
      chart: {
        zoomType: 'xy',
        // width: '100%',
        // height: '60%',
        resetZoomButton: {
          text: 'Reset',
          position: {
            align: 'right',
            verticalAlign: 'bottom',
            x: 8,
            y: 120
          },
          theme: {
            fill: 'white', // Background color
            stroke: 'gray', // Border color
            'stroke-width': 1,
            r: 5, // Rounded corners
            style: {
              fontSize: '8px', // Font size
              color: 'black', // Font color
            },
          },
        },
        events: {
          load: this.createNavigatorChart
        }
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          // format: '{value:%b}',
          tickInterval: 1000 * 3600 * 24 * 31, // 1 month
          formatter() {
            // console.log(this.axis.getExtremes());
            if ((this.axis.getExtremes().max - this.axis.getExtremes().min) < 14821920000) {
              return Highcharts.dateFormat('%e %b', this.value);
            }
            return Highcharts.dateFormat('%b', this.value);
          },
        },
      },
      yAxis: { // Primary yAxis
        title: {
          text: t('ล้าน ลบ.ม.'),
        },
        labels: {
          format: '{value:,.0f}',
        },
        plotLines: [{
          value: null,
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'right',
          },
        }, {
          value: null,
          color: 'black',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'right',
          },
        }, {
          value: null,
          color: '#f9a825',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'left',
          },
        }],
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: t(' ล้าน ลบ.ม.'),
        xDateFormat: '%d %B',
      },
      colors: ['#F62C2C', '#a14535', '#deb245', '#c7d161', '#b8de45', '#6fd18b', '#45dea1', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#d173bd', '#EA7369', '#F0A58F'],
      // legend: {
      //   enabled: true,
      //   verticalAlign: 'bottom',
      // },
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
        maxHeight: 150,
        navigation: {
          enabled: true,
        },
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
      credits: {
        enabled: false,
      },
    };

    return this.options;
  }

  findMaxValue = (data, key) => {
    const sortedData = orderBy(data, ['date'], ['asc']);
    const maxValue = maxBy(sortedData, key);
    return maxValue;
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }


  renderChart() {
    const {
      id,
      type,
      year,
      size,
      t,
      selectedDamMultiple,
    } = this.props;

    let showBound = true;
    // let url = `dam_yearly_graph?data_type=${type}&dam_id=${id}&year=${year}&dam_multiple=${selectedDamMultiple}`;
    let url = "";
    if (size === 'large' && selectedDamMultiple.length == 1) {
      url = `dam_yearly_graph?data_type=${type}&dam_id=${selectedDamMultiple}&year=${year}`;
    } else if (size === 'large' && selectedDamMultiple.length > 1) {
      url = `dam_yearly_graph_multiple?data_type=${type}&dam_id=${selectedDamMultiple}&year=${year}`;
      showBound = false;
    } else if (size === 'medium') {
      url = `dam_medium_graph?data_type=${type}&medium_id=${id}&year=${year}`;
    }

    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const chartOptions = this.getChartOptions();
        const dataSeries = [];
        let ts = null;
        let val = null;
        const lengthData = result.data.graph_data ? result.data.graph_data.length : 0
        let maxValues = [];
        let groupDamName = [];

        // data series
        // const colors = ['#F62C2C', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#EA7369', '#F0A58F'];
        const colors = ['#F62C2C', '#a14535', '#deb245', '#c7d161', '#b8de45', '#6fd18b', '#45dea1', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#d173bd', '#EA7369', '#F0A58F'];

        const seriesValue = [];
        result.data.graph_data.reverse().map((d, i) => {
          const series = [];

          let max = this.findMaxValue(d.data, 'value');
          if (d.dam_name) {
            groupDamName = [...groupDamName, d.dam_name];
            maxValues = [...maxValues, max];
          }
          orderBy(d.data, ['date'], ['asc']).map(n => {
            // by pass 29 Feb
            // 2024-02-05 เปลียนจาก && เป็น || เนื่องจากข้อมูลวันที่ 29 ไม่แสดง
            if ((moment(n.date).format('M') !== 2) || (moment(n.date).date() !== 29)) {
              if (n.value !== null) {
                val = parseFloat(n.value).toFixed(2);
                seriesValue.push(val);
              } else {
                val = null;
              }
              // console.log(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`);
              ts = moment(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`, 'YYYY/MM/DD').add(1, 'days').valueOf();
              series.push([ts, parseFloat(val)]);
            }

            return series;
          });

          dataSeries.push({
            // name: `${d.year}`,
            name: `<span style="font-size: 10px; padding: 0 !important; margin: 0 !important;">${d.year}${showBound ? '' : '-' + d.dam_name}</span>`,
            data: series,
            marker: {
              radius: 0.1
            },
            color: colors[i],
            dashStyle: 'solid',
            tooltip: {
              valueSuffix: t(' ล้าน ลบ.ม.'),
              xDateFormat: '%d %B',
            },
          });

          return null;
        });

        if (showBound) {
          // show rule curve for dam storage
          if (type === 'dam_storage') {
            // lower rule curve
            const seriesLower = [];
            result.data.lower_rule_curve.map(n => {
              if (n.value !== null) {
                val = parseFloat(n.value).toFixed(2);
              } else {
                val = null;
              }

              ts = moment(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`, 'YYYY-MM-DD')
                .add(1, 'days')
                .valueOf();
              seriesLower.push([ts, parseFloat(val)]);

              return seriesLower;
            });

            const txtLower = lengthData >= 20 ? 'Lower' : 'Lower Rule Curve';
            dataSeries.push({
              // name: 'Lower Rule Curve',
              name: `<span style="font-weight: bold;font-size: 10px; padding: 0 !important; margin: 0 !important;">${txtLower}</span>`,
              data: seriesLower,
              marker: {
                radius: 0.1
              },
              tooltip: {
                valueSuffix: t('ล้าน ลบ.ม.'),
                xDateFormat: '%d %B',
              },
              dashStyle: 'shortdot',
              color: '#7a7777',
            });

            // Upper rule curve
            const seriesUpper = [];
            result.data.upper_rule_curve.map(n => {
              if (n.value !== null) {
                val = parseFloat(n.value).toFixed(2);
              } else {
                val = null;
              }

              ts = moment(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`, 'YYYY-MM-DD')
                .add(1, 'days')
                .valueOf();
              seriesUpper.push([ts, parseFloat(val)]);

              return seriesUpper;
            });
            const txtUpper = lengthData >= 20 ? 'Upper' : 'Upper Rule Curve';
            dataSeries.push({
              // name: 'Upper Rule Curve',
              name: `<span style="font-weight: bold;font-size: 10px; padding: 0 !important; margin: 0 !important;">${txtUpper}</span>`,
              data: seriesUpper,
              marker: {
                radius: 0.1
              },
              tooltip: {
                valueSuffix: t('ล้าน ลบ.ม.'),
                xDateFormat: '%d %B',
              },
              dashStyle: 'shortdot',
              color: '#000',
            });

            // plotLines
            if (result.data.upper_bound) {
              // chartOptions.yAxis.max = result.data.upper_bound;
              chartOptions.yAxis.max = result.data.lower_bound + Number(1.2) * (result.data.upper_bound - result.data.lower_bound);
              chartOptions.yAxis.plotLines[0].value = result.data.upper_bound;
              chartOptions.yAxis.plotLines[0].label.text = `${t('กักเก็บสูงสุด')} <strong>${result.data.upper_bound.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
            }

            if (result.data.lower_bound) {
              chartOptions.yAxis.min = result.data.lower_bound;
              chartOptions.yAxis.plotLines[1].value = result.data.lower_bound;
              chartOptions.yAxis.plotLines[1].label.text = `${t('กักเก็บต่ำสุด')} <strong>${result.data.lower_bound.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
            }

            if (result.data.normal_bound) {
              chartOptions.yAxis.plotLines[2].value = result.data.normal_bound;
              chartOptions.yAxis.plotLines[2].label.text = `${t('กักเก็บปกติ')} <strong>${result.data.normal_bound.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
            }
          } else if (type === 'dam_inflow_acc') {
            const seriesSumInflowAverage = [];
            result.data.sum_average_inflow.map(n => {
              if (n.value !== null) {
                val = parseFloat(n.value).toFixed(2);
              } else {
                val = null;
              }

              ts = moment(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`, 'YYYY-MM-DD')
                .add(1, 'days')
                .valueOf();
              seriesSumInflowAverage.push([ts, parseFloat(val)]);

              return seriesSumInflowAverage;
            });

            dataSeries.push({
              name: `${t('ปริมาณน้ำไหลลงเฉลี่ยสะสม')}`,
              data: seriesSumInflowAverage,
              tooltip: {
                valueSuffix: t('ล้าน ลบ.ม.'),
                xDateFormat: '%d %B',
              },
              dashStyle: 'shortdot',
              color: '#7a7777',
            });

            const maxInflow = Math.max(...seriesValue);
            chartOptions.yAxis.min = 0;
            chartOptions.yAxis.max = maxBy([result.data.upper_bound, maxInflow]) + Number(1.2);

            // force chart yAxis.min = 0
            chartOptions.startOnTick = false;
            chartOptions.endOnTick = false;
          } else {
            chartOptions.yAxis.max = null;
            chartOptions.yAxis.min = null;
          }
        } else {
          let maxs = this.findMaxValue(maxValues, 'value');
          chartOptions.yAxis.min = 0;
          chartOptions.yAxis.max = maxs.value;
        }

        let uniqDamName = uniq(groupDamName);
        chartOptions.title.text = uniqDamName;
        if (uniqDamName.length > 8) {
          chartOptions.title.style = {
            fontSize: '16px',  // Adjust the font size
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',  // Add ellipsis for long text
          }
        }


        chartOptions.series = dataSeries;

        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderChartSum() {
    const {
      id,
      year,
      type,
      t,
    } = this.props;

    const url = `dam_daily_sum_region_rid?region_id=${id}&year=${year}`;
    const chartOptions = this.getChartOptions();

    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const dataSeries = [];
        let ts = null;
        let val = null;

        // data series
        result.data.graph_data.map(d => {
          const series = [];

          orderBy(d.data, ['date'], ['asc']).map(n => {
            if (type === 'total_dam_storage') {
              val = n.total_dam_storage;
            } else if (type === 'total_dam_inflow') {
              val = n.total_dam_inflow;
            } else if (type === 'total_dam_released') {
              val = n.total_dam_released;
            } else if (type === 'total_dam_uses_water') {
              val = n.total_dam_uses_water;
            } else if (type === 'total_dam_inflow_acc') {
              val = n.total_dam_inflow_acc;
            } else if (type === 'total_dam_released_acc') {
              val = n.total_dam_released_acc;
            }

            // by pass 29 Feb
            // 2024-02-05 เปลียนจาก && เป็น || เนื่องจากข้อมูลวันที่ 29 ไม่แสดง
            if ((moment(n.dam_date).format('M') !== 2) || (moment(n.dam_date).date() !== 29)) {
              if (val === 0) {
                val = null;
              } else {
                val = parseFloat(val).toFixed(2);
              }

              // console.log(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`);
              ts = moment(`2020-${moment(n.dam_date).format('MM')}-${moment(n.dam_date).format('DD')}`, 'YYYY/MM/DD').add(1, 'days').valueOf();
              series.push([ts, parseFloat(val)]);
            }

            return series;
          });

          dataSeries.push({
            name: `${d.year}`,
            data: series,
            tooltip: {
              valueSuffix: t('ล้าน ลบ.ม.'),
              xDateFormat: '%d %B',
            },
          });

          return null;
        });

        // set chart options
        chartOptions.series = dataSeries;

        // plotLines
        if (type === 'total_dam_storage') {
          if (result.data.bound.upper) {
            chartOptions.yAxis.max = result.data.bound.upper;
            chartOptions.yAxis.plotLines[0].value = result.data.bound.upper;
            chartOptions.yAxis.plotLines[0].label.text = `${t('กักเก็บสูงสุด')} <strong>${result.data.bound.upper.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }

          if (result.data.bound.lower) {
            chartOptions.yAxis.min = result.data.bound.lower;
            chartOptions.yAxis.plotLines[1].value = result.data.bound.lower;
            chartOptions.yAxis.plotLines[1].label.text = `${t('กักเก็บต่ำสุด')} <strong>${result.data.bound.lower.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }

          if (result.data.bound.normal) {
            chartOptions.yAxis.plotLines[2].value = result.data.bound.normal;
            chartOptions.yAxis.plotLines[2].label.text = `${t('กักเก็บปกติ')} <strong>${result.data.bound.normal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }
        } else {
          chartOptions.yAxis.max = null;
          chartOptions.yAxis.min = null;
        }
        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { isLoading, error, chartOptions } = this.state;

    // if error, show error message
    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      );
    }

    return (
      <>
        <div style={{ position: 'relative' }}>
          <div id="customNavigator" style={{ height: '340px', position: 'absolute' }}></div>
          <div style={{ 'marginLeft': '55px' }}>
            <HighchartsReact
              ref={this.props.chartRef}
              highcharts={Highcharts}
              options={chartOptions}
            />
          </div>
        </div>
      </>
    );
  }
}

DamChart.defaultProps = {
  id: '',
  chartCalType: '',
  size: 'large',
};

DamChart.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  year: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  chartCalType: PropTypes.string,
  t: PropTypes.func.isRequired,
};
// export default withTranslation('translations', { withRef: true })(DamChart);
export default compose(withModal, withTranslation('translations', { withRef: true }))(DamChart);
