import React from 'react';
// import PropTypes from 'prop-types';
// import _ from 'lodash';

import {
  BrowserRouter as Router,
  // Switch,
  // Route,
} from 'react-router-dom';

import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// icon
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Hidden } from '@material-ui/core';

// style
import { styles } from './styleStormSurgeMap';

// context
// import ModeContext from '../../context/ModeContext';
import MenuStormSidebar from './MenuStormSurgeSidebar';

const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles(styles);

export default function HeaderMap() {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);
  const [open] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
    province: '10',
  });

  // const {
  //   selectList, currentSelectList, currentTextSelectList, handleSelectListChange, handleModeClick, currentMode,
  // } = useContext(ModeContext);


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const [value, setValue] = React.useState('sealevel');

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  return (
    <Router>
      <Box width="100%">
        <Grid container alignItems="center" direction="row" justifycontent="space-between">
          <Grid item xs="auto" md="auto">
            <Box p={1} display="flex" alignItems="center" className={classes.contentIntro}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Link href={`${initPath}storm_surge`}>
                  <img src={`${initPath}images/theme/storm_surge/hii-60.png`} alt="logo" className={classes.logoMap} />
                </Link>
              </Box>
              <Hidden smDown>
                <Box display="block" alignItems="center" justifyContent="flex-start">
                  <Link href={`${initPath}storm_surge`}>
                    <Typography noWrap className={classes.titleMapTH}>
                      ระบบคาดการณ์และเตือนภัยล่วงหน้าบริเวณอ่าวไทย
                    </Typography>
                    <Typography noWrap className={classes.titleMapEN}>
                      FORECASTING AND EARLY WARNING SYSTEM FOR THE GULF OF THAILAND
                    </Typography>
                  </Link>
                </Box>
              </Hidden>
            </Box>
          </Grid>
          <Grid item xs md>
            <Box pr={{ xs: 0, sm: 0, md: 5 }} display="flex" justifyContent="flex-end">
              {['right'].map((anchor) => (
                <IconButton
                  key="key"
                  color="inherit"
                  aria-label="open drawer"
                  // edge="end"
                  onClick={toggleDrawer(anchor, true)}
                  className={`${clsx(open && classes.hide)} ${classes.buttonMenu}`}
                >
                  <MenuIcon id="navbar-drawer-button" className={classes.btnExpand} />
                </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>


      {/* <main>
        <Container maxWidth="xl">
          <Switch>
              {Routes().map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
            </Switch>
        </Container>
      </main> */}

      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            style={{ zIndex: 999999 }}
          >
            <div className={classes.drawerHeader}>
              <IconButton id="navbar-drawer-close-button" onClick={toggleDrawer(anchor, false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <MenuStormSidebar />
          </Drawer>
        </React.Fragment>
      ))}
    </Router>
  );
}
