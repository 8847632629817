import React, { useState } from 'react';
import PropTypes from 'prop-types';

// ui
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { Hidden } from '@material-ui/core';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { FaCalendarAlt } from '../../utils/Fontawesome';

import GsFile from './GsFile';
import GsImageLatest from './GsImageLatest';
import GsImageHistory from './GsImageHistory';
import { styles } from '../../styles/Style';

const useStyles = makeStyles(styles);

const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

function TabPanel(props) {
  const {
    children,
    value,
    index,
    displayState,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          {children}
          {displayState === 'latest' && <GsImageLatest />}
          {displayState === 'history' && <GsImageHistory />}
        </Box>
      )}
    </div>
  );
}

const TabContainer = ({ children }) => (
  <Box component="div">
    {children}
  </Box>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  displayState: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `rainfall-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function GsMap() {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [displayState, setDisplayState] = useState('latest');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (tabIndex, stateValue) => {
    setDisplayState(stateValue);
  };

  return (
    <>
      <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
        <Grid item xs={12} sm={10} md={8}>
          <Box m={1} className={classes.titleName}>
            <Typography variant="h5">
              GSMaP satellite rainfall (10km x 10km)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Tooltip title="Latest">
            <Button
              className={classes.outlinedLeftPrimary}
              onClick={() => handleClick(0, 'latest')}
              color={displayState === 'latest' ? 'primary' : 'default'}
              variant={displayState === 'latest' ? 'contained' : 'text'}
            >
              <ImageOutlinedIcon fontSize="small" />
              <Hidden smDown>
                <Typography variant="h6" style={{ marginLeft: 8 }}>
                  Latest
                </Typography>
              </Hidden>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Tooltip title="Historical">
            <Button
              className={classes.outlinedRightPrimary}
              onClick={() => handleClick(1, 'history')}
              color={displayState === 'history' ? 'primary' : 'default'}
              variant={displayState === 'history' ? 'contained' : 'text'}
            >
              <FaCalendarAlt style={{ fontSize: '0.8em' }} />
              <Hidden smDown><Typography variant="h6" style={{ marginLeft: 8 }}>Historical</Typography></Hidden>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ marginTop: 41 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto" className={classes.borderBottom_tab}>
            <CustomTooltip title="Image">
              <Tab
                label="Image Files"
                {...a11yProps(0)}
                className={tabValue === 0 ? classes.active_tab : classes.tab}
              />
            </CustomTooltip>
            <CustomTooltip title="Download">
              <Tab
                label="Download ASCII Files"
                {...a11yProps(1)}
                className={tabValue === 1 ? classes.active_tab : ''}
              />
            </CustomTooltip>
          </Tabs>
          <TabContainer component="div">
            <TabPanel value={tabValue} index={0} displayState={displayState} />
          </TabContainer>
          <TabContainer component="div">
            <TabPanel value={tabValue} index={1}>
              <GsFile />
            </TabPanel>
          </TabContainer>
        </Grid>
      </Grid>
    </>
  );
}

TabPanel.defaultProps = {
  children: '',
};
