import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  uniqueId, orderBy, uniqBy,
} from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';

// icon .
import { FaSpinner, FaHome } from '../../utils/Fontawesome';
import { HiiPresentIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
moment.locale('th');
class ReportYearlyHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      yearlyList: [],
    };
  }

  componentDidMount() {
    this._mounted = true;

    this.getData();
  }

  getData() {
    Promise.all([
      fetch(`${initPath}json/contents/report_yearly.json`),
    ])
      .then(([yearly]) => Promise.all([yearly.json()]))
      .then(([yearlyLists]) => {
        const yearlyList = uniqBy(orderBy(yearlyLists, ['year'], ['desc']), 'year');
        // set state
        this.setState({
          isLoading: false,
          yearlyList,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  urlEncode = url => Buffer.from(url).toString('base64');

  render() {
    const { classes, t } = this.props;
    const {
      yearlyList, isLoading, error,
    } = this.state;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}report`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('รายงานสถานการณ์')}
                </Typography>
              </Button>
              <Button href={`${initPath}report/yearly/history`}>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('รายงานสถานการณ์รายปี')}
                </Typography>
              </Button>
              {/* <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                ทั้งหมด
              </Typography> */}
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiPresentIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4" gutterBottom>
                      {t('รายงานสถานการณ์รายปี')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          {yearlyList.map(item => (
            <Fragment key={uniqueId()}>
              <Grid item xs={12} sd={4} md={3} lg={3} align="center">
                <Box className={classes.fontTitle} fontSize={{ xs: 16, md: 16, lg: 17 }} color="secondary">
                  {t('ปี')}
                  {' '}
                  {item.year}
                </Box>
                <Link to={`${initPath}${item.link}`} title={`${t('รายงานสถานการณ์น้ำ')} ${t('ปี')} ${item.year}`} target="_blank">
                  <img src={`${initPath}images/theme/banner/report/yearly/${item.year}.png`} width="100%" alt={`${t('รายงานสถานการณ์น้ำ')} ${t('ปี')} ${item.year}`} className={classes.outLined} />
                  <img src={`${initPath}images/theme/banner/report/book-shadow.png`} width="100%" style={{ marginTop: -7 }} alt="" />
                </Link>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </div>
    );
  }
}

ReportYearlyHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(ReportYearlyHistory));
