import i18n from 'i18next';

// วิธีเรียกใช้ function
// {`${tJson(row.station.tele_station_name, 'สถานี')}`}
// objectData Object data json ที่สามารถใช้งาน .th .en .jp  เช่น row.station.tele_station_name
// key ข้อมูลที่ต้องการเพิ่มเติมหรือเปลี่ยนแปลง เช่น จ. ให้เพิ่มคำว่า Province ต่อท้ายภาษาที่แปลงแล้ว
export default function tJson(objectData, key, areaCode, areaName) {
  const { language } = i18n;
  // console.log(areaCode, areaName);
          
  // 2022-04-18 Review
  // บน tooltip ตัดตัวย่อ ต. อ. ออก
  // ชื่ออ่างเก็บน้ำ, เขื่อน เมื่อเปลี่ยนเป็นภาษาอังกฤษให้ตัดคำว่าเขื่อน,อ่างเก็บน้ำ
  let objectDataTh = objectData.th;
  if (
    (objectData[language] !== undefined)
    && (objectData[language].trim().length !== 0)
    && key
  ) {
    const keyAndObject = `${key}${objectData[language]}`;

    // ลองยุบเงื่อนไขต่าง ๆ ไว้ด้านบน ถ้าใช้งานแล้วมีส่วนไหนไม่ถูกต้อง แจ้งมาได้เลยค่ะ
    objectDataTh = keyAndObject;
    if (areaCode !== undefined) {
      if (areaCode > 99) {
        if(areaName !== undefined) {
          objectDataTh = areaName[language];
        } else {
          objectDataTh = objectData[language];
        }
      
      }
    } else {
      if (language !== 'th') {
        if (key === 'จ.') {
          objectDataTh = `${objectData[language]} Province`;
        } else {
          objectDataTh = objectData[language];
        }
      }
    }
    // // code เดิม เหมือนแต่ละเงื่อนไข return ซ้ำ ๆ กันน่าจะยุบเงื่อนไขต่าง ๆ ได้
    // if ((key === 'อ่างเก็บน้ำ' || key === 'เขื่อน') && (language !== 'th')) {
    //   objectDataTh = objectData[language];
    // }
    // if ((key === 'อ่างเก็บน้ำ' || key === 'เขื่อน') && (language === 'th')) {
    //   if (key === 'อ่างเก็บน้ำ') {
    //     objectDataTh = objectData[language];
    //   }
    //   objectDataTh = keyAndObject;
    // }

    // if (key === 'จ.' && (language === 'th')) {
    //   objectDataTh = keyAndObject;
    // }

    // if ((key === 'ต.' || key === 'อ.') && (language !== 'th')) {
    //   objectDataTh = `${objectData[language]}`;
    // }
    // if ((key === 'ต.' || key === 'อ.') && (language === 'th')) {
    //   objectDataTh = keyAndObject;
    // }

    // if ((key === 'agency') && (language !== 'th')) {
    //   objectDataTh = objectData[language];
    // }

    // if ((key === 'province') && (language !== 'th')) {
    //   objectDataTh = objectData[language];
    // }

    // if (key === 'ลุ่มน้ำ' && (language !== 'th')) {
    //   objectDataTh = `${objectData[language]}`;
    // }
    // if (key === 'ลุ่มน้ำ' && (language === 'th')) {
    //   objectDataTh = keyAndObject;
    // }
  }
  return objectDataTh;
  // ถ้าไม่ส่ง key มา จะ return กลับไปเป็นภาษาไทย -> น่าจะหมายถึงตัวแปร key เป็น ช่องว่างแต่ต้องใส่ตัวแปร key มาไม่งั้น func จะ error เพราะว่าไม่ได้กำหนดค่า defalt ของตัวแปร key ไว้
}
