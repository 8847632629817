import React, { useState } from 'react';
import PropTypes from 'prop-types';

// ui
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';

import CmImage from './CmImage';
import CmFile from './CmFile';

const useStyles = makeStyles({
  active_tab: {
    color: 'white',
    backgroundColor: '#006fb6',
  },
  tab: {
    border: '1px solid #ccc',
  },
  borderBottom: {
    borderBottom: '2px solid #006FB6',
  },
});
const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

function TabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
const TabContainer = ({ children }) => (
  <Box component="div">
    {children}
  </Box>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `rainfall-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ClimateChange() {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box pb={2}>
            <Typography variant="h4" gutterBottom>
              Bias correction of CMIP6 GCM climate and extreme indexes of ASEAN countries
            </Typography>
          </Box>
          <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto" className={classes.borderBottom}>
            <CustomTooltip title="Image">
              <Tab
                label="Climate and Extreme Indexes Maps"
                {...a11yProps(0)}
                className={tabValue === 0 ? classes.active_tab : classes.tab}
              />
            </CustomTooltip>
            <CustomTooltip title="Download">
              <Tab
                label="ASCII Format Files"
                {...a11yProps(1)}
                className={tabValue === 1 ? classes.active_tab : ''}
              />
            </CustomTooltip>
          </Tabs>
          <TabContainer component="div">
            <TabPanel value={tabValue} index={0}>
              <CmImage />
            </TabPanel>
          </TabContainer>
          <TabContainer component="div">
            <TabPanel value={tabValue} index={1}>
              <CmFile />
            </TabPanel>
          </TabContainer>
        </Grid>
      </Grid>
    </>
  );
}

TabPanel.defaultProps = {
  children: '',
};
