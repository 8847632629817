import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import heightMeasurementJSON from "../json/heightMeasurement.json";
import { isNull, reverse } from "lodash";
import { data } from "jquery";

export default function HiiFixedGraph() {
  const getChartOptions = () => {
    return {
      title: {
        text: "",
      },
      chart: {
        type: "line",
        zoomType: "xy",
        panning: true,
        panKey: "shift",
      },
      lang: {
        thousandsSep: ",",
      },
      exporting: {
        buttons: {
          contextButton: {
            align: "left",
          },
        },
      },
      time: {
        timezoneOffset: -7 * 60,
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
          connectNulls: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        crosshairs: true,
        formatter: function () {
          const toolTipText = this.points.map((point) => {
            if (point.series.name === "ปริมาตร") {
              return `<div>${
                point.series.name
              } ${point.x.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })} ลบ.ม. ที่ระดับน้ำ ${this.y} ม.รทก.</div>`;
            }else{
              return `<div>${
                point.series.name
              } ${point.x.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })} ตรม. ที่ระดับน้ำ ${this.y} ม.รทก.</div>`;
            }
          });
          return toolTipText;
        },
      },
      xAxis: [
        {
          title: {
            text: `${"ปริมาณน้ำ (ลบ.ม.)"}`,
          },
        },
        {
          opposite: true,
          reversed: true,
          title: {
            text: `${"พื้นที่ (ตรม.)"}`,
          },
        },
      ],
      yAxis: {
        min: 238,
        max: 247,
        type: "number",
        labels: {
          format: "{value:.2f}",
        },
        title: {
          text: `${"ความสูง (เมตร.รทก.)"}`,
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      legend: {
        enabled: true,
      },
      series: [
        {
          name: "ปริมาตร",
          data: [
            { x: 586619.4276, y: 247 },
            { x: 573052.2134, y: 246.9 },
            { x: 559678.788, y: 246.8 },
            { x: 546500.2964, y: 246.7 },
            { x: 533519.3516, y: 246.6 },
            { x: 520755.6623, y: 246.5 },
            { x: 508260.7651, y: 246.4 },
            { x: 496219.0039, y: 246.3 },
            { x: 484655.3687, y: 246.2 },
            { x: 473284.091, y: 246.1 },
            { x: 462029.8633, y: 246 },
            { x: 450882.5864, y: 245.9 },
            { x: 439841.82, y: 245.8 },
            { x: 428912.6, y: 245.7 },
            { x: 418112.64, y: 245.6 },
            { x: 407444.62, y: 245.5 },
            { x: 396892.16, y: 245.4 },
            { x: 386436.92, y: 245.3 },
            { x: 376077, y: 245.2 },
            { x: 365829.21, y: 245.1 },
            { x: 355665.11, y: 245 },
            { x: 345578.54, y: 244.9 },
            { x: 335563.46, y: 244.8 },
            { x: 325621.26, y: 244.7 },
            { x: 315754.68, y: 244.6 },
            { x: 305973.41, y: 244.5 },
            { x: 296275.75, y: 244.4 },
            { x: 286658.95, y: 244.3 },
            { x: 277124.42, y: 244.2 },
            { x: 267676.3, y: 244.1 },
            { x: 258307.28, y: 244 },
            { x: 249019.49, y: 243.9 },
            { x: 239821.33, y: 243.8 },
            { x: 230717.5, y: 243.7 },
            { x: 221716.67, y: 243.6 },
            { x: 212849.72, y: 243.5 },
            { x: 204122.75, y: 243.4 },
            { x: 195534.47, y: 243.3 },
            { x: 187092.46, y: 243.2 },
            { x: 178875.78, y: 243.1 },
            { x: 170948.19, y: 243 },
            { x: 163348.5, y: 242.9 },
            { x: 156129.58, y: 242.8 },
            { x: 149318.23, y: 242.7 },
            { x: 142955.36, y: 242.6 },
            { x: 136852.67, y: 242.5 },
            { x: 130901.66, y: 242.4 },
            { x: 125082.97, y: 242.3 },
            { x: 119372.46, y: 242.2 },
            { x: 113759.17, y: 242.1 },
            { x: 108244.58, y: 242 },
            { x: 102833.02, y: 241.9 },
            { x: 97583.81, y: 241.8 },
            { x: 92453.11, y: 241.7 },
            { x: 87438.72, y: 241.6 },
            { x: 82538.32, y: 241.5 },
            { x: 77756.56, y: 241.4 },
            { x: 73077.45, y: 241.3 },
            { x: 68499.78, y: 241.2 },
            { x: 64025.32, y: 241.1 },
            { x: 59652.77, y: 241 },
            { x: 55388.68, y: 240.9 },
            { x: 51240.99, y: 240.8 },
            { x: 47223.72, y: 240.7 },
            { x: 43322.74, y: 240.6 },
            { x: 39547.35, y: 240.5 },
            { x: 35922.43, y: 240.4 },
            { x: 32441.24, y: 240.3 },
            { x: 29088.76, y: 240.2 },
            { x: 25881.03, y: 240.1 },
            { x: 22840.31, y: 240 },
            { x: 19942.07, y: 239.9 },
            { x: 17183.59, y: 239.8 },
            { x: 14563.27, y: 239.7 },
            { x: 12119.84, y: 239.6 },
            { x: 9818.74, y: 239.5 },
            { x: 7684.77, y: 239.4 },
            { x: 5731.23, y: 239.3 },
            { x: 3996.31, y: 239.2 },
            { x: 2494.68, y: 239.1 },
            { x: 1291.03, y: 239 },
            { x: 456, y: 238.9 },
            { x: 56.52, y: 238.8 },
            { x: 0, y: 238.7 },
            { x: 0, y: 238.6 },
          ],
        },
        {
          name: "พื้นที่",
          data: [
            { x: 136634.2073, y: 247 },
            { x: 134727.147, y: 246.9 },
            { x: 132790.5386, y: 246.8 },
            { x: 130838.5024, y: 246.7 },
            { x: 128815.1975, y: 246.6 },
            { x: 126400.5419, y: 246.5 },
            { x: 123345.9054, y: 246.4 },
            { x: 117488.0756, y: 246.3 },
            { x: 114477.6505, y: 246.2 },
            { x: 113096.7802, y: 246.1 },
            { x: 112004.4794, y: 246 },
            { x: 110951.7653, y: 245.9 },
            { x: 109856.196, y: 245.8 },
            { x: 108701.0638, y: 245.7 },
            { x: 107314.4583, y: 245.6 },
            { x: 106058.583, y: 245.5 },
            { x: 105020.4192, y: 245.4 },
            { x: 104086.9336, y: 245.3 },
            { x: 103031.3127, y: 245.2 },
            { x: 102058.1118, y: 245.1 },
            { x: 101238.203, y: 245 },
            { x: 100512.3757, y: 244.9 },
            { x: 99792.73904, y: 244.8 },
            { x: 99058.17383, y: 244.7 },
            { x: 98245.01112, y: 244.6 },
            { x: 97385.38989, y: 244.5 },
            { x: 96556.60883, y: 244.4 },
            { x: 95772.14595, y: 244.3 },
            { x: 94899.43444, y: 244.2 },
            { x: 94094.55961, y: 244.1 },
            { x: 93291.87327, y: 244 },
            { x: 92467.71316, y: 243.9 },
            { x: 91535.75945, y: 243.8 },
            { x: 90537.21326, y: 243.7 },
            { x: 89333.02224, y: 243.6 },
            { x: 88011.51306, y: 243.5 },
            { x: 86591.17914, y: 243.4 },
            { x: 85165.13658, y: 243.3 },
            { x: 83612.2865, y: 243.2 },
            { x: 80435.15873, y: 243.1 },
            { x: 78110.06796, y: 243 },
            { x: 74377.35713, y: 242.9 },
            { x: 69922.68997, y: 242.8 },
            { x: 66258.59287, y: 242.7 },
            { x: 61898.70698, y: 242.6 },
            { x: 60271.68994, y: 242.5 },
            { x: 58799.10838, y: 242.4 },
            { x: 57598.8876, y: 242.3 },
            { x: 56613.5004, y: 242.2 },
            { x: 55644.71703, y: 242.1 },
            { x: 54663.30356, y: 242 },
            { x: 53513.3761, y: 241.9 },
            { x: 51868.82885, y: 241.8 },
            { x: 50723.66409, y: 241.7 },
            { x: 49588.19888, y: 241.6 },
            { x: 48376.67665, y: 241.5 },
            { x: 47302.43019, y: 241.4 },
            { x: 46261.84449, y: 241.3 },
            { x: 45297.68541, y: 241.2 },
            { x: 44236.36724, y: 241.1 },
            { x: 43214.38493, y: 241 },
            { x: 42058.45355, y: 240.9 },
            { x: 40843.31154, y: 240.8 },
            { x: 39538.09108, y: 240.7 },
            { x: 38367.17556, y: 240.6 },
            { x: 37128.9427, y: 240.5 },
            { x: 35554.78263, y: 240.4 },
            { x: 34157.52807, y: 240.3 },
            { x: 32867.932, y: 240.2 },
            { x: 31139.96537, y: 240.1 },
            { x: 29707.23672, y: 240 },
            { x: 28268.20409, y: 239.9 },
            { x: 26900.54755, y: 239.8 },
            { x: 25417.82997, y: 239.7 },
            { x: 23733.68794, y: 239.6 },
            { x: 22216.84909, y: 239.5 },
            { x: 20568.41114, y: 239.4 },
            { x: 18339.6266, y: 239.3 },
            { x: 16299.00169, y: 239.2 },
            { x: 13407.9622, y: 239.1 },
            { x: 10628.5371, y: 239 },
            { x: 6357.907543, y: 238.9 },
            { x: 1580.082328, y: 238.8 },
            { x: 0, y: 238.7 },
            { x: 0, y: 238.6 },
          ],
          xAxis: 1,
        },
      ],
    };
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={getChartOptions()} />
    </>
  );
}
