/**
 * *
 * @version 1.0.0
 * @author [Manorot]
 * Modify by Thitiporn
 * Modify by Permporn
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// moment
import moment from 'moment';

// lodash
// import _, { uniqueId } from 'lodash';
import {
  flow, uniqueId, groupBy, orderBy,
} from 'lodash/fp';

// images
import Img from 'react-image';

// material-ui
import { Typography, Hidden } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import grey from '@material-ui/core/colors/grey';
import Tooltip from '@material-ui/core/Tooltip';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

// images
import Lightbox from 'react-image-lightbox';

// style, css
import { withStyles } from '@material-ui/core/styles';
import { FaHome, FaSpinner } from '../../../utils/Fontawesome';
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

const hourList = [
  { time: '00:00:00+07:00' }, // 00.00น
  { time: '12:00:00+07:00' }, // 06.00น
];

// lightbox
let images = [];

const currentDate = new Date(moment().subtract(1, 'day'));

class RainAccumulationHistory extends Component {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      datas: [],
      groupDatas: [],
      displayState: 'history',
    };
  }

  componentDidMount() {
    const { match: { params: { month, year } } } = this.props;
    // latest data available (minus 1 days from current date)
    const startDate = new Date(moment().subtract(1, 'day'));
    let selectedMonth = moment(startDate).format('MM');
    let selectedYear = moment(startDate).format('YYYY');
    if (month && year) {
      selectedMonth = month;
      selectedYear = year;
    }
    this.setState({
      isLoading: true,
      startDate,
    });
    this.fetchData(selectedMonth, selectedYear);
  }

  fetchData = (selectedMonth, selectedYear) => {
    // get data from service
    // api : http://api-v3.thaiwater.net/api/v1/thaiwater30/analyst/rainaccumulat_img?year=2020&month=05
    fetch(`${process.env.MIX_API_URL}analyst/rainaccumulat_img?year=${selectedYear}&month=${selectedMonth}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // const data = _.orderBy(result.data, ['datetime'], ['desc']);
        // const dataArray = data._.groupBy(d => {
        //     const date = d.datetime;
        //     const s = date.split('T');
        //     return moment(s[0]).format('YYYY-MM-DD');
        //   })
        //   .map((value, key) => ({ date: key, datas: value }))
        //   .value();

        const groupData = flow(
          orderBy(['datetime'], ['desc']),
          groupBy(d => {
            const date = d.datetime;
            const s = date.split('T');
            return moment(s[0]).format('YYYY-MM-DD');
          }),
        )(result.data);
        const dataArray = Object.entries(groupData)
          .map(([key, val]) => ({ date: key, datas: val }));

        this.setState({
          datas: result.data,
          groupDatas: dataArray,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    });
    this.fetchData(moment(date).format('MM'), moment(date).format('YYYY'));
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate;
    if (change === '1') {
      selectedDate = moment(this.state.startDate).add(1, 'months');
    } else if (change === '-1') {
      selectedDate = moment(this.state.startDate).add(-1, 'months');
    }
    this.setState({
      startDate: selectedDate,
    });
    this.fetchData(moment(selectedDate).format('MM'), moment(selectedDate).format('YYYY'));
  }

  renderHistory(startDate, classes, datas, groupDatas, currentIndex) {
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
          <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={3} md={3} lg={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                openTo="month"
                views={['year', 'month']}
                value={startDate}
                onChange={this.handleChange}
                style={{ fontSize: 14 }}
                maxDate={currentDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>

        {datas.length <= 0
            && (
              <Grid container spacing={4}>
                <Grid item xs>
                  <Typography variant="h6">
                    ไม่พบข้อมูลในช่วงเวลาที่เลือก
                  </Typography>
                </Grid>
              </Grid>
            )}
        {groupDatas.map(item => (
          <Grid container spacing={4} key={uniqueId()}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box p={1} mt={2} fontFamily="Kanit" fontSize={16} fontWeight="fontWeightMedium" style={{ background: grey[300], borderRadius: 4 }}>
                {moment(item.date).format('LL')}
              </Box>
            </Grid>
            {hourList.map(meta => {
              const total = item
                .datas
                .filter(row => {
                  const date = row.datetime;
                  const s = date.split('T');
                  return s[1] === meta.time;
                })
                .length;
              if (total === 0) {
                return (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                    <Box className={classes.boxAccumulatHistory}>
                      <Box display="block" align="center">
                        <Img
                          src="https://via.placeholder.com/232x126?text=No%20Image"
                          alt="No Image"
                          width="300"
                          height="250"
                          className={classes.responsive}
                        />
                      </Box>
                      <Box p={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography className={classes.dateTime}>
                          {`เวลา : ${meta.time.substring(0, 5)} UTC`}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              }

              return (
                <Fragment key={uniqueId()}>
                  {item.datas
                    .filter(row => {
                      const date = row.datetime;
                      const s = date.split('T');
                      return s[1] === meta.time;
                    })
                    .map(row => {
                      let realImage = '';
                      let currentIndexResult = currentIndex;
                      const currentImgIndex = currentIndexResult;
                      if (row.media_path_thumb !== null) {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                          title: `วันที่ ${moment(row.date).format('YYYY-MM-DD')} เวลา : ${moment(row.datetime).format('HH:mm')} UTC`,
                        });

                        realImage = (
                          <Button
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                              width="300"
                              height="250"
                              className={classes.responsive}
                              alt={moment(row.datetime).format('LLL')}
                            />
                          </Button>
                        );
                        currentIndexResult += 1;
                      } else {
                        realImage = (
                          <Img
                            src="https://via.placeholder.com/300x250?text=No%20Image"
                            width="300"
                            height="250"
                            className={classes.responsive}
                            alt="No Image"
                          />
                        );
                      }

                      return (
                        <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                          <Box className={classes.boxAccumulatHistory}>
                            <Box display="block" align="center">
                              {realImage}
                            </Box>
                            <Box p={2} display="flex" alignItems="center" justifyContent="center">
                              <Typography className={classes.dateTime}>
                                {`เวลา : ${moment(row.datetime).format('HH:mm')} UTC`}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Fragment>
              );
            })}
          </Grid>
        ))}
      </>
    );
  }

  renderLatest(startDate, classes, datas, groupDatas, currentIndex) {
    return (
      <>
        {groupDatas.slice(0, 1).map(item => (
          <Grid
            container
            spacing={4}
            key={uniqueId()}
            style={{ marginTop: 18, marginBottom: 24 }}
          >
            {hourList.map(meta => {
              const total = item
                .datas
                .filter(row => {
                  const date = row.datetime;
                  const s = date.split('T');
                  return s[1] === meta.time;
                })
                .length;
              if (total === 0) {
                return (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                    <Box className={classes.boxAccumulatHistory}>
                      <Box display="block" align="center">
                        <Img
                          src="https://via.placeholder.com/232x126?text=No%20Image"
                          alt="No Image"
                          width="300"
                          height="250"
                          className={classes.responsive}
                        />
                      </Box>
                      <Box p={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography className={classes.dateTime}>
                          {`เวลา : ${meta.time.substring(0, 5)} UTC`}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              }

              return (
                <Fragment key={uniqueId()}>
                  {item.datas
                    .filter(row => {
                      const date = row.datetime;
                      const s = date.split('T');
                      return s[1] === meta.time;
                    })
                    .map(row => {
                      let realImage = '';
                      let currentIndexResult = currentIndex;
                      const currentImgIndex = currentIndexResult;
                      if (row.media_path_thumb !== null) {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                          title: `วันที่ ${moment(row.date).format('YYYY-MM-DD')} เวลา : ${moment(row.datetime).format('HH:mm')} UTC`,
                        });

                        realImage = (
                          <Button
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                              width="300"
                              height="250"
                              className={classes.responsive}
                              alt={moment(row.datetime).format('LLL')}
                            />
                          </Button>
                        );
                        currentIndexResult += 1;
                      } else {
                        realImage = (
                          <Img
                            src="https://via.placeholder.com/300x250?text=No%20Image"
                            width="300"
                            height="250"
                            className={classes.responsive}
                            alt="No Image"
                          />
                        );
                      }

                      return (
                        <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                          <Box className={classes.boxAccumulatHistory}>
                            <Box display="block" align="center">
                              {realImage}
                            </Box>
                            <Box p={2} display="flex" alignItems="center" justifyContent="center">
                              <Typography className={classes.dateTime}>
                                {`เวลา : ${moment(row.datetime).format('HH:mm')} UTC`}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Fragment>
              );
            })}
          </Grid>
        ))}
      </>
    );
  }

  render() {
    images = [];

    const { classes } = this.props;
    const {
      isLoading,
      error,
      datas,
      groupDatas,
      photoIndex,
      isOpen,
      startDate,
      displayState,
    } = this.state;

    const currentIndex = 0;
    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {isLoading === true && (
              <FaSpinner size={25} />
              )}

              {error && (
              <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`${initPath}weather`}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    ติดตามสภาพอากาศ
                  </Typography>
                </Link>
                <Link to={`${initPath}weather/rainfall`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    ฝน
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  ภาพฝนสะสมรายวัน
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.boxTopRadius} style={{ marginTop: 41 }}>
            <Grid item xs={12} sm={12} md={8}>
              <Box m={1} className={classes.titleName}>
                ภาพฝนสะสมรายวัน
              </Box>
            </Grid>
            <Grid item xs>
              <Tooltip title="ดูภาพล่าสุด">
                <Button
                  className={classes.outlinedLeftPrimary}
                  color={displayState === 'latest' ? 'primary' : 'default'}
                  variant={displayState === 'latest' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'latest' })}
                >
                  <ImageOutlinedIcon fontSize="small" className="outlinedRightPrimary" />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}> ดูภาพล่าสุด</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title="ดูภาพย้อนหลัง">
                <Button
                  className={classes.outlinedRightPrimary}
                  color={displayState === 'history' ? 'primary' : 'default'}
                  variant={displayState === 'history' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'history' })}
                >
                  <PhotoLibraryOutlinedIcon fontSize="small" className="rightRadius" />
                  <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>ดูภาพย้อนหลัง</Typography></Hidden>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          { displayState === 'history' && (
            this.renderHistory(startDate, classes, datas, groupDatas, currentIndex)
          )}
          { displayState === 'latest' && (
            this.renderLatest(startDate, classes, datas, groupDatas, currentIndex)
          )}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

RainAccumulationHistory.defaultProps = {
  match: {
    params: {
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    },
  },
};

RainAccumulationHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      month: PropTypes.string,
      year: PropTypes.string,
    }),
  }),
};

export default withStyles(styles)(RainAccumulationHistory);
