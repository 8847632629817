import React, { Component } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';

// icon .
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
const initPathUrl = process.env.MIX_APP_URL;

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // items: [],
      searchTerm: '',
      urlSet: [],
      arraySuggest: [],
    };
  }

  componentDidMount() {
    // console.log('initPath => ', `${initPathUrl}`);
    fetch(`${initPath}json/sitemap/sitemap.json`)
      .then(result => {
        if (result.ok) {
          return result.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({ urlSet: result.urlset.url });
      })
      .catch(error => error);
  }

  handleSearchChange = event => {
    const { urlSet } = this.state;
    const keypress = event.target.value;
    const suggessArray = [];
    urlSet.filter(value => {
      const keyword = value.keywords;
      if (keyword.search(event.target.value) !== -1) {
        const arraySplitKeyword = keyword.split(',');
        arraySplitKeyword.forEach(element => {
          if (element.search(keypress) !== -1) {
            const obj = {
              loc: value.loc,
              keywords: element,
            };
            suggessArray.push(obj);
          }
        });
      }
      this.setState({
        arraySuggest: suggessArray,
      });
      return value;
    });
    event.preventDefault();
    suggessArray.map(elementValue => {
      if (elementValue.keywords === keypress) {
        this.setState({ searchTerm: elementValue.loc });
      }
      return elementValue;
    });
  }

  handleEnter = event => {
    const { arraySuggest } = this.state;
    const inputValue = event.target.value;
    if (event.key === 'Enter') {
      arraySuggest.map(value => {
        if (value.keywords === inputValue) {
          window.open(`${initPathUrl}${value.loc}`);
        }
        return value;
      });
    }
  }

  render() {
    const { classes, t } = this.props;
    const { searchTerm, arraySuggest } = this.state;

    return (
      <div className={classes.bgSearch}>
        <div className={classes.layoutSearch}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center" height="130px" width="100%">
                <Paper className={classes.rootSearchHeader} elevation={1}>
                  <Hidden xsDown>
                    <Typography variant="h4" className={classes.primaryColor}>
                      <Box className={classes.textShadow}>
                        {t('ค้นหาข้อมูล')}
                      </Box>
                    </Typography>
                  </Hidden>
                  <Autocomplete
                    id="keyword"
                    className={classes.inputSearch}
                    noOptionsText={t('ไม่พบข้อมูลการค้นหา')}
                    options={arraySuggest}
                    getOptionLabel={option => String(option.keywords)}
                    style={{ width: 300 }}
                    onChange={(e, value) => value && window.open(value.loc)} // this.setState({ searchTerm: value.loc })
                    renderInput={params => {
                      const { InputLabelProps, InputProps, ...rest } = params;
                      return (
                        <InputBase
                          {...params.InputProps}
                          {...rest}
                          placeholder={t('เช่น อากาศ ฝน น้ำ ฯลฯ เป็นต้น')}
                          onChange={this.handleSearchChange}
                          onKeyPress={e => this.handleEnter(e, searchTerm)}
                        />
                      );
                    }}
                  />
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Search"
                    onClick={() => window.open(searchTerm, '_blank')}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter((withStyles(styles)(Search))));
