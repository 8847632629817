import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';

// material ui
// import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next';

// map
import L from 'leaflet';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  LayerGroup,
  LayersControl,
} from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';


// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { GestureHandling } from 'leaflet-gesture-handling';
import { FaChartLine } from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

import tJson from '../../utils/TJson';

import { styles } from '../../styles/Style';

// color
import SealevelStatusColor from './SealevelStatusColor';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import CurrentLocation from '../currentlocation/CurrentLocation';

const { BaseLayer, Overlay } = LayersControl;

/**
 * WaterQuality Map
 */
class SealevelMap extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.refMap = createRef();
    this.SealevelLayer = createRef();

    this.state = {
      height: this.setHeightMap(),
    };

    this.eachSealevelData = this.eachSealevelData.bind(this);
    this.handleChart = this.handleChart.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;
    this._mounted = true;

    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 600;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 600;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 600;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 550;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  });

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      if (this.refMap.current.leafletElement) {
        const map = this.refMap.current.leafletElement;
        map.on('popupopen', e => {
          const px = map.project(e.target._popup._latlng);
          px.y -= e.target._popup._container.clientHeight / 2;
          map.panTo(map.unproject(px), { animate: true });
        });
      }
    }
    // if (ref) {
    //   markers[id] = ref.leafletElement;
    //   markers[id].addEventListener('click', e => {
    //     const lat = e.target.getLatLng().lat + 3;
    //     const { lng } = e.target.getLatLng();
    //     this.map.panTo([lat, lng]);
    //   });
    // }
  };

  handleChart = data => e => {
    const { handleModalOpen } = this.props;
    e.preventDefault();

    const newData = {
      id: data.station.id,
      provinceId: data.geocode.province_code,
    };

    if (this._mounted) {
      handleModalOpen(newData);
    }
  };

  // set marker icon based on criteria
  setIcon = val => {
    const status = SealevelStatusColor(val);

    return status.icon;
  }

  handleZoom = () => {
    const currentZoom = this.map.getZoom();
    if (currentZoom <= 4) {
      // hide layers
      this.map.removeLayer(this.SealevelLayer.current);
    } else {
      // show layers
    }
  };

  mapLayerControl = () => {
    const { datas, t } = this.props;

    return (
      <LayersControl position="topright">
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Esri_WorldStreetMap">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <Overlay name={t('สถานีทั้งหมด')} checked="true">
          <LayerGroup ref={this.SealevelLayer}>
            {datas.map(this.eachSealevelData)}
          </LayerGroup>
        </Overlay>
        {/* <Overlay name={t('เส้นแม่น้ำ')} checked>
          <WMSTileLayer
            url={`${initPathWMS}wms`}
            version="1.1.0"
            opacity={1}
            transparent
            layers="layers=thaiwater30:river_thailand"
            srs="EPSG:4326"
            format="image/png"
            tiled
          />
        </Overlay> */}
      </LayersControl>
    );
  }

  eachSealevelData(data, i) {
    const { classes, t } = this.props;

    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
    ) {
      // marker position
      const position = [
        parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long),
      ];

      // marker icons
      const icon = this.setIcon(
        // parseFloat(data.storage_percent),
        parseFloat(31),
      );

      // status colors
      // const statusColor = SealevelStatusColor(data.storage_percent);

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          center={position}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <div>
              <TableContainer>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                        <Typography variant="subtitle2">
                          {tJson(data.station.tele_station_name, ' ')}
                        </Typography>
                        <Typography variant="subtitle2" display="block">
                          {tJson(data.geocode.amphoe_name, 'อ.')}
                          {tJson(data.geocode.tumbon_name, 'ต.')}
                          <br />
                          {tJson(data.geocode.province_name, 'จ.')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{t('ระดับน้ำ')}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.waterlevel_msl}
                        </strong>
                        {/* <Box
                          borderRadius={3}
                          style={{
                            backgroundColor: statusColor.color.statusColor,
                            color: statusColor.color.constrastColor,
                            width: '60px',
                          }}
                        >
                          <strong>
                            {(data.waterlevel_msl > 0) ? data.waterlevel_msl : '-'}
                          </strong>
                        </Box> */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{t('ระดับตลิ่ง')}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {(data.station.min_bank > 0) ? data.station.min_bank : '-'}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{t('ระดับท้องน้ำ')}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.station.ground_level}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>
                        <Typography variant="caption" color="secondary" style={{ whiteSpace: 'normal' }}>
                          {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name, ' ')}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>
                        <Typography variant="caption" color="secondary">
                          {`${data.waterlevel_datetime}`}
                          {' '}
                          {t('น.')}
                          {' '}
                          {'('}
                          {t('ม.รทก.')}
                          {')'}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <Button
                          color="secondary"
                          onClick={this.handleChart(data)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.station.tele_station_name, ' ')}
                </strong>
                <br />
                {tJson(data.geocode.province_name, 'จ.')}
                <br />
                {tJson(data.geocode.amphoe_name, 'อ.')}
                {tJson(data.geocode.tumbon_name, 'ต.')}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  render() {
    const {
      error,
      height,
    } = this.state;
    const {
      classes, i18n,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={[13.5, 100.4]}
          zoom={8}
          // center={location}
          // bounds={bounds}
          // boundsOptions={{ padding: [100, 100] }}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          onZoomEnd={this.handleZoom}
          gestureHandling
        >
          {/* <Control position="bottomleft">
            <div className="info">
              <Typography variant="body1">{currentHover}</Typography>
            </div>
          </Control> */}
          <TileLayer
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution='&copy; <a href="http://osm.org/copyright">ESRI World Street Map</a> contributors'
          />

          {(i18n.language === 'th') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'en') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'jp') && (
            this.mapLayerControl()
          )}

          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />
        </Map>
      </>
    );
  }
}

SealevelMap.defaultProps = {
  bounds: L.latLngBounds([13, 100], [14, 101]),
};

SealevelMap.propTypes = {
  classes: PropTypes.object.isRequired,
  markers: PropTypes.array.isRequired,
  bounds: PropTypes.object,
  handleSetMap: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  datas: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  location: PropTypes.array.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(SealevelMap);
