import React from 'react';
import { useParams } from 'react-router-dom';
import WlChart from '../../wl/WlChart';
import RidChart from '../../wl_rid/RidChart';

const wlChartDate = () => {
  const { stationId, provinceId, selectedType } = useParams();

  return (
    <>
      <RidChart id={parseInt(stationId, 10)} provinceId={provinceId} selectedType={selectedType} />
    </>
  );
};

export default wlChartDate;
