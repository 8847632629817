/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable no-underscore-dangle */
/* eslint-disable block-scoped-var */
/* eslint-disable global-require */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
// Depends on:
// https://github.com/santilland/plotty

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  // eslint-disable-next-line no-var
  // eslint-disable-next-line vars-on-top
  // eslint-disable-next-line global-require
  var L = require('leaflet-geotiff');
  var plotty = require('plotty');
}

L.LeafletGeotiff.Plotty = L.LeafletGeotiffRenderer.extend({

  options: {
    colorScale: 'viridis',
    clampLow: true,
    clampHigh: true,
    displayMin: 0,
    displayMax: 1,
  },

  initialize(options) {
    if (typeof (plotty) === 'undefined') {
      throw new Error('plotty not defined');
    }
    this.name = 'Plotty';

    L.setOptions(this, options);

    this._preLoadColorScale();
  },

  setColorScale(colorScale) {
    this.options.colorScale = colorScale;
    this.parent._reset();
  },

  setDisplayRange(min, max) {
    this.options.displayMin = min;
    this.options.displayMax = max;
    this.parent._reset();
  },

  _preLoadColorScale() {
    const canvas = document.createElement('canvas');
    const plot = new plotty.plot({
      canvas,
      data: [0],
      width: 1,
      height: 1,
      domain: [this.options.displayMin, this.options.displayMax],
      colorScale: this.options.colorScale,
      clampLow: this.options.clampLow,
      clampHigh: this.options.clampHigh,
    });
    this.colorScaleData = plot.colorScaleCanvas.toDataURL();
  },

  render(raster, canvas, ctx, args) {
    const plottyCanvas = document.createElement('canvas');
    const plot = new plotty.plot({
      data: raster.data,
      width: raster.width,
      height: raster.height,
      domain: [this.options.displayMin, this.options.displayMax],
      colorScale: this.options.colorScale,
      clampLow: this.options.clampLow,
      clampHigh: this.options.clampHigh,
      canvas: plottyCanvas,
      useWebGL: false,
    });
    plot.setNoDataValue('no data');
    plot.render();

    this.colorScaleData = plot.colorScaleCanvas.toDataURL();

    const rasterImageData = plottyCanvas.getContext('2d').getImageData(0, 0, plottyCanvas.width, plottyCanvas.height);
    const imageData = this.parent.transform(rasterImageData, args);
    ctx.putImageData(imageData, args.xStart, args.yStart);
  },

});

// eslint-disable-next-line space-before-function-paren
L.LeafletGeotiff.plotty = function(options) {
  return new L.LeafletGeotiff.Plotty(options);
};

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = L.LeafletGeotiff;
}
