export const processDataAndFilterLatest = async (data) => {
    // Process the data to extract date, index, and description
    const processedData = data.files.map(item => {
        const [date, index, description] = item.name.split('_');
        return {
            ...item,
            date,          // Extracted from the name
            index,         // Extracted from the name
            description    // Extracted from the name
        };
    });

    const currentYear = new Date().getFullYear();

    // Function to sort files by date in descending order
    const sortFilesByDate = (files) => {
        return files.sort((a, b) => {
            // Convert date strings to Date objects
            const aDate = new Date(currentYear, parseInt(a.date.substring(0, 2), 10) - 1, parseInt(a.date.substring(2, 4), 10));
            const bDate = new Date(currentYear, parseInt(b.date.substring(0, 2), 10) - 1, parseInt(b.date.substring(2, 4), 10));

            const dateComparison = bDate - aDate;

            // If dates are the same, compare by index or another property
            if (dateComparison === 0) {
                return b.index - a.index; // Sort indices in descending order
            }

            return dateComparison; // Sort by date in descending order
        });
    };

    // Sort the processed data by date
    const sortedFiles = sortFilesByDate(processedData);

    // Return the sorted data
    return sortedFiles;
};

export const handleClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

export const filterTypeImage = (data) =>{
    return data.filter(file => ['image/png', 'image/jpg', 'image/jpeg'].includes(file.mimeType));
}

