import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { countBy, isEmpty } from 'lodash';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

import {
  Link,
} from 'react-router-dom';

// color .
import grey from '@material-ui/core/colors/grey';
import Img from 'react-image';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
// import CloseIcon from '@material-ui/icons/Close';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Lightbox from 'react-image-lightbox';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import { FaPlay, FaCalendarAlt } from '../../utils/Fontawesome';
import ImageAnimation from '../weather/ImageAnimation';

import tJson from '../../utils/TJson';

import {
  styles,
} from '../../styles/Style';

// lightbox
const images = [
  { src: 'https://weather.tmd.go.th/composite/loop_radarcomposite.gif', title: 'Nationwide composite loop' },
  { src: 'http://weather.tmd.go.th/composite/compositeZC_VTBB_latest.png', title: 'Radar Composite จาก กรมอุตฯุ' },
  { src: 'images/radar/drraa_weather_radar.jpg', title: 'แผนที่จุดเรดาร์ กรมฝนหลวงและการบินเกษตร' },
];

// agency
const agencies = [
  { code: 'tmd', name: { th: 'กรมอุตุนิยมวิทยา', en: 'Meteorological Department of Thailand', jp: 'Meteorological Department of Thailand' } },
  { code: 'bma', name: { th: 'สำนักการระบายน้ำ กรุงเทพมหานคร', en: 'Department of Drainage and Sewerage', jp: 'Department of Drainage and Sewerage' } },
  { code: 'drraa', name: { th: 'กรมฝนหลวงและการบินเกษตร', en: 'Department of Royal Rainmaking and Agricultural Aviation', jp: 'Department of Royal Rainmaking and Agricultural Aviation' }, location_map: 'images/radar/drraa_weather_radar.jpg' },
];

const initPath = process.env.MIX_APP_PATH;

class RadarAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null, // error
      imgs: [],
      photoIndex: 0,
      isOpen: false,
      totalRadarByAgency: [],
      expanded: 'กรมอุตุนิยมวิทยา',

      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
      open: false,
    };
  }

  componentDidMount() {
    // get data from ws
    this.fetchData();
  }

  handleModalAnimation = (agencyId, mediaTypeId, imageType) => e => {
    e.preventDefault();
    const params = {
      // header: 'ภาพเคลื่อนไหว',
      content:
        (
          <div style={{
            width: '550px',
          }}
          >
            <ImageAnimation agencyId={agencyId} mediaTypeId={mediaTypeId} imageType={imageType} />
          </div>

        ),
    };

    this.handleModalOpen(params);
  }

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '40%';
    let heightChart = 'max-content';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = 'max-content';
    }
    if (isWidthUp('md', width)) {
      widthChart = '40%';
      heightChart = 'max-content';
    }
    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
    };
  }

  fetchData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/radar_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const total = countBy(result.data, 'agency');
        this.setState({
          imgs: result.data,
          totalRadarByAgency: total,
        });
      })
      .catch(error => this.setState({ error }));
  }

  render() {
    const { classes, theme, t } = this.props;
    const {
      error,
      imgs,
      photoIndex,
      isOpen,
      totalRadarByAgency,
      expanded,
      modalHeader,
      modalSubHeader,
      modalContent,
      open,
    } = this.state;

    const handleChange = panel => (event, newExpanded) => {
      this.setState({
        expanded: newExpanded ? panel : false,
      });
      if (newExpanded === true) {
        // wait 500ms and scroll to the top of accordian
        setTimeout(() => {
          const el = document.getElementById('accordian');
          el.scrollTop = 0;
          el.scrollIntoView({ behavior: 'smooth' });
        }, 10);
      }
    };

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}
        <div id="accordian" />
        {agencies
          .map((agency, i) => (
            <Accordion
              key={agency.code}
              square
              expanded={expanded === tJson(agency.name, ' ')}
              onChange={handleChange(tJson(agency.name, ' '))}
            >
              <AccordionSummary
                className={classes.boxRadius}
                style={{
                  backgroundColor: grey[300], height: 45, marginTop: (i > 0 ? theme.spacing(2) : 0),
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Box
                  style={{ fontWeight: 500, fontFamily: 'Kanit' }}
                  fontSize={{
                    xs: 15,
                    sm: 15,
                    md: 16,
                    lg: 16,
                  }}
                >
                  {tJson(agency.name, ' ')}
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <Grid container spacing={2} style={{ paddingTop: 15 }}>
                  {imgs.filter(row => row.agency === agency.code.toString())
                    .map((row, j) => {
                      let currentIndex;
                      const diff = (moment().diff(moment(row.media_datetime), 'hours')) > 72;
                      if (row.agency === 'tmd') {
                        currentIndex = j + 3;
                      } else if (row.agency === 'bma') {
                        currentIndex = j + 3 + totalRadarByAgency.tmd;
                      } else if (row.agency === 'drraa') {
                        currentIndex = j + 3 + totalRadarByAgency.tmd + totalRadarByAgency.bma;
                      }
                      let tempsrc = `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`;
                      if (isEmpty(row.media_datetime)) {
                        if (row.agency === 'tmd') {
                          tempsrc = `https://weather.tmd.go.th/${row.radar_type.slice(0, 3)}/${row.radar_type}_latest.jpg`;
                        } else if (row.agency === 'drraa') {
                          tempsrc = `https://file.royalrain.go.th/website/radar/${row.radar_type}_latest.png`;
                        }
                      }

                      const radarName = row.radar_name.replace('เรดาร์', '')
                        .replace('กม.', '')
                        .replace('รัศมี', '')
                        .replace('240', '')
                        .replace('160', '')
                        .replace('150', '')
                        .replace('120', '')
                        .trim();

                      const radarCircle = row.radar_name.replace(row.radar_name
                        .replace('กม.', '')
                        .replace('รัศมี', '')
                        .replace('240', '')
                        .replace('160', '')
                        .replace('150', '')
                        .replace('120', '')
                        .trim(), '')
                        .trim();

                      // https://weather.tmd.go.th/nan/nan120_latest.jpg
                      images.push({
                        src: tempsrc,
                        title: `${t(radarName)} ${t(radarCircle)} ${t('เวลา')} ${row.media_datetime} ${row.timezone} | ${t('ข้อมูลจาก')} : ${tJson(agency.name, ' ')}`,
                      });
                      return (
                        <Grid item xs={6} sm={3} md={4} lg={4} key={row.radar_type}>
                          <Box display="block" className={classes.boxTopRadius} alignItems="center">
                            <Box p={1} fontFamily="Kanit" fontSize={12} fontWeight="fontWeightMedium" style={{ backgroundColor: grey[300] }}>
                              {t(radarName)}
                              {' '}
                              {t(radarCircle)}
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <Box width="50%" align="center" style={{ borderRight: '1px solid #ccc' }}>
                                <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
                                  <span>
                                    <Button
                                      className={classes.outlinedOldLeftPrimary}
                                      onClick={this.handleModalAnimation(parseInt(row.agency_id, 10),
                                        parseInt(row.media_type_id, 10), row.radar_type)}
                                      disabled={isEmpty(row.media_datetime)}
                                    >
                                      <FaPlay style={{ fontSize: '0.8em' }} />
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Box>
                              <Box width="50%" align="center">
                                {isEmpty(row.media_datetime)
                                  ? (
                                    <Tooltip title={t('ดูภาพย้อนหลัง')}>
                                      <span>
                                        <IconButton className={classes.button}>
                                          <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  )
                                  : (
                                    <Link to={`${initPath}weather/radar/history/${row.radar_type}/${row.media_datetime.split(' ')[0]}`}>
                                      <Tooltip title={t('ดูภาพย้อนหลัง')}>
                                        <span>
                                          <IconButton className={classes.button}>
                                            <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </Link>
                                  )}
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" className={classes.boxRadarBottomRadius} alignItems="center" justifyContent="center">
                            <Box>
                              <Button
                                onClick={
                                  () => this.setState({ isOpen: true, photoIndex: currentIndex })
                                }
                              >
                                {isEmpty(row.media_datetime)
                                  ? (
                                    // <img
                                    //   src={tempsrc}
                                    //   alt={row.radar_name}
                                    //   width="250"
                                    //   height="250"
                                    //   className={classes.imgRadarFix}
                                    // />
                                    <Img
                                      src={tempsrc}
                                      alt={row.radar_name}
                                      unloader={(
                                        <Img
                                          src="https://via.placeholder.com/232x425?text=No%20Image"
                                          alt="No Image"
                                          className={classes.imgRadarFix}
                                        />
                                      )}
                                      className={classes.imgRadarFix}
                                    />
                                  )
                                  : (
                                    // <img
                                    //   src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                                    //   alt={row.radar_name}
                                    //   width="250"
                                    //   className={classes.imgRadarFix}
                                    // />
                                    <Img
                                      src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                                      alt={row.radar_name}
                                      unloader={(
                                        <Img
                                          src="https://via.placeholder.com/232x425?text=No%20Image"
                                          alt="No Image"
                                          className={classes.imgRadarFix}
                                        />
                                      )}
                                      className={classes.imgRadarFix}
                                    />
                                  )}
                              </Button>
                              <Box fontFamily="Kanit" fontSize={12} color={grey[600]} align="center">
                                {isEmpty(row.media_datetime)
                                  ? (
                                    <>
                                      <Typography className={classes.dateTime} style={{ color: '#ffffff00' }}>
                                        -
                                      </Typography>
                                      <Typography className={classes.dateTime} style={{ color: '#ffffff00' }}>
                                        -
                                      </Typography>
                                    </>
                                  )
                                  : (
                                    <>
                                      <Typography className={classes.dateTime} style={{ color: diff ? 'red' : '' }}>
                                        {diff ? `${t('วันที่')} ` : `${t('ล่าสุด')} `}
                                        {' '}
                                        {moment(row.media_datetime).format('YYYY-MM-DD')}
                                        {' '}
                                      </Typography>
                                      <Typography className={classes.dateTime} style={{ color: diff ? 'red' : '' }}>
                                        {t('เวลา')}
                                        {' : '}
                                        {' '}
                                        {moment(row.media_datetime).format('HH:mm')}
                                        {' '}
                                        {/* {row.timezone} */}
                                        {'GMT'}
                                      </Typography>
                                    </>
                                  )}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleModalClose}
          style={{ align: 'center', justifyContent: 'center' }}
        >
          <div style={this.getModalStyle()} className={classes.paper}>
            {/* <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={0}
            >
              <Grid item>
                <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid> */}
            <Typography variant="h5" id="modal-title">
              {modalHeader}
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              {modalSubHeader}
            </Typography>
            <Box mt={3}>
              <div className={classes.paper} style={{ padding: '0px !important' }}>
                {modalContent}
              </div>
            </Box>
          </div>
        </Modal>
        {isOpen && (
          <Lightbox
            imagePadding={50}
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

RadarAgency.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles, { withTheme: true }), withTranslation(),
)(RadarAgency);
