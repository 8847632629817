/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { sumBy, orderBy } from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { CircularProgressbar } from 'react-circular-progressbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
// Color
import grey from '@material-ui/core/colors/grey';
import Slider from 'react-slick';

// Icon
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  FaServer, FaAngleRight, FaAngleLeft, FaTint,
} from '../../utils/Fontawesome';
import { HiiDamL1Icon, HiiDam12Icon, HiiDamL5Icon } from '../../utils/Icon';

import DashboardContext from '../../contexts/dashboardContext';
import DamStatusColor from '../dam/DamStatusColor';
import DamStatusColorMainScreen from '../dam/DamStatusColorMainScreen';
import DamDailyChart from '../dam/DamDailyChart';
import DamDailyCompareChartSumByRegion from '../dam/DamDailyCompareChartSumByRegion';
import DamLegend from './DamLegend';

// translate
import tJson from '../../utils/TJson';

import {
  styles,
  CircleProgressbarStyle,
} from '../../styles/Style';
import '../../../../public/css/slick.css';

const lineBox = grey[300];

function NextArrow(props) {
  const { onClick } = props; // className
  return (
    <FaAngleRight
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#555',
        top: -43,
        right: 7,
        fontSize: '1.5em',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { onClick } = props; // className,
  return (
    <FaAngleLeft
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: '#555',
        top: -43,
        right: 32,
        fontSize: '1.5em',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

class Dam extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      damSummary: [], // รวมเขือน 6 ภาค
      damLow: [], // เขื่อนน้ำน้อยวิกฤต
      damHigh: [], // เขื่อนน้ำมากวิกฤต
      latestDate: moment().format('YYYY-MM-DD'), // latest data date
      anchorEl: null,
    };
  }

  componentDidMount() {
    this._mounted = true;

    // get data from ws
    this.setData();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataDam !== this.props.dataDam) {
      this.setData();
    }
  }

  setData() {
    const { dataDam } = this.props;
    try {
      if (dataDam.data !== undefined) {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        const datas = dataDam.data.data.filter(d => {
          const time = new Date(d.dam_date.replace(/-/g, '/')).getTime();
          return (last24hrs < time);
        });
        const damSummary = dataDam.summary.data;
        const damLow = orderBy(datas.filter(d => (d.dam_uses_water_percent <= 30)), ['dam_uses_water_percent'], ['asc']);
        const damHigh = datas.filter(d => (d.dam_storage_percent > 80));

        this.setState({
          damSummary,
          damLow,
          damHigh,
          latestDate: this.getMaxDate(datas),
        });
      }
      return '';
    } catch (error) {
      return '';
    }
  }

  handleChart = (handler, damId, agencyId = 12) => e => {
    e.preventDefault();
    const { t } = this.props;

    let header = `${t('กราฟอ่างเก็บน้ำรายปี')} ${t('กรมชลประทาน')}`;
    if (agencyId === 8) {
      header = `${t('กราฟอ่างเก็บน้ำรายปี')} ${t('การไฟฟ้าฝ่ายผลิต')}`;
    }

    const params = {
      header,
      content: <DamDailyChart id={damId} agencyId={agencyId} type="dam_storage" />,
    };

    handler(params);
  }

  handleChartCompareDailySumByRegion = (handler, regionId, agencyId = 12, type = 'total_dam_storage') => e => {
    const { t } = this.props;
    e.preventDefault();

    const params = {
      header: `${t('กราฟเปรียบเทียบอ่างเก็บน้ำรายวัน กรมชลประทาน')} ${t('รายภาค')}`,
      content: <DamDailyCompareChartSumByRegion
        id={parseInt(regionId, 10)}
        agencyId={agencyId}
        type={type}
      />,
    };

    handler(params);
  }

  damStatus = (data, percent) => {
    const status = DamStatusColor(parseFloat(percent).toFixed(0));

    let statusText = '';
    if (parseFloat(percent) === 0) {
      statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} <b>(--)</b>`;
    } else {
      statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} <span style='color:${status.color.statusColor}'> <b>(${parseFloat(percent).toFixed(0)}%)</b></span>`;
    }
    return statusText;
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.dam_date));
      const maxdate = new Date(Math.max.apply(null, dates));
      return moment(maxdate).format('YYYY-MM-DD');
    }
    return moment().format('YYYY-MM-DD');
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, t } = this.props;
    const {
      damSummary, damLow, damHigh, latestDate,
    } = this.state;

    const sumDamStorage = sumBy(damSummary, 'dam_storage');
    const sumNormalStorage = sumBy(damSummary, 'dam_normal_storage');
    const sumDamStoragePercent = (sumDamStorage * 100) / sumNormalStorage;
    const sumDamUsesWater = sumBy(damSummary, 'dam_uses_water');
    const sumDamUsesWaterPercent = (sumDamUsesWater * 100) / sumNormalStorage;

    // status colors
    const sumDamStoragePercentStatusColor = DamStatusColorMainScreen(
      parseFloat(sumDamStoragePercent).toFixed(0),
    );
    const sumDamUsesWaterPercentStatusColor = DamStatusColorMainScreen(
      parseFloat(sumDamUsesWaterPercent).toFixed(0),
    );

    const { anchorEl } = this.state;

    const damLowSlide = damLow.length >= 4 ? 4 : damLow.length;
    const damHighSlide = damHigh.length >= 4 ? 4 : damHigh.length;
    // slide size for ipad, mobile
    const damLowSlide3 = damLow.length >= 3 ? 3 : damLow.length;
    const damHighSlide3 = damHigh.length >= 3 ? 3 : damHigh.length;

    const settingsLow = {
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      speed: 2200,
      autoplaySpeed: 2200,
      slidesToShow: damLowSlide,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: damLowSlide,
            slidesToScroll: damLowSlide,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: damLowSlide3,
            slidesToScroll: damLowSlide3,
            initialSlide: damLowSlide3,
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: damLowSlide3,
            slidesToScroll: damLowSlide3,
          },
        },
      ],
    };

    const settingsHigh = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: damHighSlide,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: damHighSlide,
            slidesToScroll: damHighSlide,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: damHighSlide3,
            slidesToScroll: damHighSlide3,
            initialSlide: damHighSlide3,
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: damHighSlide3,
            slidesToScroll: damHighSlide3,

          },
        },
      ],
    };

    const titleText = `${t('กราฟเปรียบเทียบอ่างเก็บน้ำรายวัน กรมชลประทาน')} ${t('รายภาค')}`;

    return (
      <Fragment key="key">
        <div className={classes.layout}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Box mt={5} mb={1} display="flex" style={{ width: '100%' }}>
                <Box display="flex" width="100%">
                  <Box display="inline">
                    <HiiDam12Icon style={{ fontSize: '3rem' }} />
                  </Box>
                  <Box display="inline" pt={1} pl={1} fontWeight="fontWeightBold">
                    <Typography variant="h5" gutterBottom>
                      {t('ภาพรวมอ่างเก็บน้ำขนาดใหญ่ในประเทศ')}
                    </Typography>
                  </Box>
                </Box>
                <Box flexShrink={0} mt={1} borderBottom="2" border="1" alignItems="flex-end">
                  <Button
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    variant="outlined"
                    size="small"
                    // color="disabled"
                    align="center"
                    className={classes.button}
                    style={{ fontFamily: 'Kanit', fontSize: 12 }}
                  >
                    <span style={{ fontSize: 12, fontFamily: 'Kanit' }}>{t('แสดงเกณฑ์สี')}</span>
                    <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    style={{ fontFamily: 'Kanit', fontSize: 10 }}
                  >
                    <MenuItem onClick={this.handleClose}>
                      <DamLegend />
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box border={1} borderColor={lineBox} height={419}>
                <Box display="flex" fontFamily="Kanit" style={{ borderBottom: '1px solid #ccc' }}>
                  <Box p={2} align="center"><FaTint size={18} style={{ color: '#888' }} /></Box>
                  <Box p={1}>
                    <Box display="block" fontSize={18} fontWeight="fontWeightMedium">
                      {t('อ่างเก็บน้ำขนาดใหญ่')}
                    </Box>
                    <Box display="block" fontSize={12}>
                      {`${t('ล่าสุด')} : ${latestDate}`}
                    </Box>
                  </Box>
                </Box>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ paddingLeft: 15 }}>{t('ภาค')}</TableCell>
                      <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                        {t('ปริมาณน้ำ')}
                        <br />
                        <Typography variant="caption">
                          {t('(ล้าน ลบ.ม. / % รนก.)')}
                        </Typography>
                      </TableCell>
                      <Hidden smDown>
                        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                          {t('ใช้การได้จริง')}
                          <br />
                          <Typography variant="caption">{t('(ล้าน ลบ.ม. / % รนก.)')}</Typography>
                        </TableCell>
                      </Hidden>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {damSummary.map(row => {
                      const storageStatus = this.damStatus(
                        row.dam_storage,
                        parseFloat(row.dam_storage_percent).toFixed(0),
                      );

                      return (
                        <TableRow key={row.region_name} style={{ height: 50 }}>
                          <TableCell component="th" scope="row">
                            <DashboardContext.Consumer>
                              {({ handleModalOpen }) => (
                                <Fragment key="key">
                                  <Button
                                    onClick={
                                      this.handleChartCompareDailySumByRegion(
                                        handleModalOpen,
                                        row.region_id,
                                        12,
                                        'total_dam_storage',
                                      )
                                    }
                                    title={titleText}
                                    style={{ textAlign: 'left', paddingLeft: 5 }}
                                  >
                                    {`${t(row.region_name)}`}
                                  </Button>
                                </Fragment>
                              )}
                            </DashboardContext.Consumer>
                          </TableCell>
                          <TableCell align="right" dangerouslySetInnerHTML={{ __html: storageStatus }} />
                          <Hidden smDown>
                            <TableCell align="right">
                              {row.dam_uses_water_percent > 0 ? (
                                <div>
                                  {`${row.dam_uses_water.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${row.dam_uses_water_percent.toFixed(0)}%)`}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </TableCell>
                          </Hidden>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box p={1} border={1} borderColor={lineBox} height={419}>
                <Box py={2} display="flex" alignItems="center" justifyContent="center">
                  <Box mr={1} align="center"><FaServer size={18} style={{ color: '#888' }} /></Box>
                  <Typography variant="h5">{t('รวมปริมาณน้ำของอ่างเก็บน้ำขนาดใหญ่')}</Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <Box p={1} display="inline" align="center">
                    <DashboardContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Fragment key="key">
                          <Button
                            onClick={this.handleChartCompareDailySumByRegion(handleModalOpen, 0, 12, 'total_dam_storage')}
                            title={titleText}
                            style={{ textAlign: 'left', paddingLeft: 5 }}
                          >
                            <Box width={{ md: '60%', lg: '210px' }}>
                              <CircularProgressbar
                                value={sumDamStoragePercent.toFixed(0)}
                                text={`${Number.isNaN(sumDamStoragePercent.toFixed(0))
                                  ? 'n/a'
                                  : sumDamStoragePercent.toFixed(0)}%`}
                                // styles={{
                                //   text: {
                                //     fill: sumDamStoragePercentStatusColor.color.statusColor,
                                //   },
                                //   path: {
                                //     stroke: sumDamStoragePercentStatusColor.color.statusColor,
                                //   },
                                // }}
                                styles={{
                                  text: CircleProgressbarStyle().textProgressbarCircle,
                                  path: {
                                    stroke: sumDamStoragePercentStatusColor.color.statusColor,
                                  },
                                }}
                              />
                            </Box>
                          </Button>
                        </Fragment>
                      )}
                    </DashboardContext.Consumer>
                    <Typography variant="h6">
                      {t('ปริมาณน้ำในอ่างฯ (% รนก.)')}
                      {' '}
                    </Typography>
                    <Box
                      m={0}
                      p={0}
                      fontWeight="fontWeightMedium"
                      fontSize={24}
                      color={sumDamStoragePercentStatusColor.color.statusColor}
                      fontFamily="Kanit"
                    >
                      {sumDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Box>
                    <Box m={0} p={0} fontSize={14} fontFamily="Kanit">
                      {'('}
                      {t('ล้าน ลบ.ม.')}
                      {')'}
                    </Box>
                  </Box>
                  <Box p={1} display="inline" align="center">
                    <DashboardContext.Consumer>
                      {({ handleModalOpen }) => (
                        <Fragment key="key">
                          <Button
                            onClick={this.handleChartCompareDailySumByRegion(handleModalOpen, 0, 12, 'total_dam_uses_water')}
                            title={titleText}
                            style={{ textAlign: 'left', paddingLeft: 5 }}
                          >
                            <Box width={{ md: '60%', lg: '210px' }}>
                              <CircularProgressbar
                                value={sumDamUsesWaterPercent.toFixed(0)}
                                text={`${Number.isNaN(sumDamUsesWaterPercent.toFixed(0))
                                  ? 'n/a'
                                  : sumDamUsesWaterPercent.toFixed(0)}%`}
                                styles={{
                                  text: {
                                    // fill: sumDamUsesWaterPercentStatusColor.color.statusColor,
                                    fill: '#2f2c2c',
                                  },
                                  path: {
                                    stroke: sumDamUsesWaterPercentStatusColor.color.statusColor,
                                  },
                                }}
                              />
                            </Box>
                          </Button>
                        </Fragment>
                      )}
                    </DashboardContext.Consumer>
                    <Typography variant="h6">
                      {t('ใช้การได้จริง')}
                    </Typography>
                    <Box
                      m={0}
                      p={0}
                      fontWeight="fontWeightMedium"
                      fontSize={24}
                      color={sumDamUsesWaterPercentStatusColor.color.statusColor}
                      fontFamily="Kanit"
                    >
                      {sumDamUsesWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Box>
                    <Box m={0} p={0} fontSize={14} fontFamily="Kanit">
                      {'('}
                      {t('ล้าน ลบ.ม.')}
                      {')'}
                    </Box>
                  </Box>
                </Box>

                <Grid item xs={6}>
                  <div align="center" />
                </Grid>
                <Grid item xs={6}>
                  <div align="center" />
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* dam low, dam high */}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box border={1} p={1} pb={{ sm: 0 }} mt={2} borderColor={lineBox} fontFamily="Kanit" height={{ xs: 250, sm: 270, md: 250 }}>
                <Box display="flex" mb={2}>
                  <Box p={1} align="center">
                    <HiiDamL1Icon style={{ fontSize: '2em', marginTop: -2, color: '#888' }} />
                  </Box>
                  <Box pl={1}>
                    <Box display="block" fontSize={18} fontWeight="fontWeightMedium">
                      {t('น้ำน้อยวิกฤต (% ปริมาณน้ำใช้การได้จริง)')}
                    </Box>
                    <Box display="block" fontSize={12}>
                      {t('ล่าสุด')}
                      {' '}
                      {` : ${latestDate}`}
                    </Box>
                  </Box>
                </Box>
                <Box p={1}>
                  <Slider
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...settingsLow}
                  >
                    {damLow.length > 0 && (
                      damLow.map(row => {
                        const damLowStatusColor = DamStatusColor(
                          parseFloat(row.dam_uses_water_percent).toFixed(0),
                        );
                        return (
                          <div key={row.id}>
                            <Box style={{ width: '100px', margin: 'auto' }} align="center">
                              <DashboardContext.Consumer>
                                {({ handleModalOpen }) => (
                                  <Fragment key="key">
                                    <Button
                                      onClick={this.handleChart(handleModalOpen, row.dam.id, 12)}
                                      title={t('กราฟอ่างเก็บน้ำรายปี กรมชลประทาน')}
                                      style={{ textAlign: 'left', paddingLeft: 5 }}
                                    >
                                      <CircularProgressbar
                                        className={classes.progressDam}
                                        variant="static"
                                        size={100}
                                        key={row.id}
                                        value={parseInt(row.dam_uses_water_percent.toFixed(0), 10)}
                                        text={`${Number.isNaN(row.dam_uses_water_percent.toFixed(0))
                                          ? 'n/a'
                                          : row.dam_uses_water_percent.toFixed(0)}%`}
                                        styles={{
                                          text: CircleProgressbarStyle().textProgressbarCircle,
                                          path: {
                                            stroke: damLowStatusColor.color.statusColor,
                                          },
                                        }}
                                      />
                                    </Button>
                                  </Fragment>
                                )}
                              </DashboardContext.Consumer>
                            </Box>
                            <Box fontWeight="fontWeightMedium" fontSize={14} align="center">
                              {t('อ่างเก็บน้ำ')}
                              <br />
                              {row.dam.dam_name.th}
                            </Box>

                          </div>
                        );
                      })
                    )}
                  </Slider>
                  {damLow.length === 0 && (
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Box>{t('ไม่มีน้ำน้อยวิกฤต')}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box border={1} p={1} mt={{ xs: 0, sm: 2, md: 2 }} borderColor={lineBox} fontFamily="Kanit" height={{ xs: 250, sm: 270, md: 250 }}>
                <Box display="flex" mb={2}>
                  <Box p={1}>
                    <HiiDamL5Icon style={{ fontSize: '2em', marginTop: -2, color: '#888' }} />
                  </Box>
                  <Box pl={1}>
                    <Box display="block" fontSize={18} fontWeight="fontWeightMedium">
                      {t('น้ำมากวิกฤต (% รนก.)')}
                    </Box>
                    <Box display="block" fontSize={12}>
                      {t('ล่าสุด')}
                      {` : ${moment(latestDate).format('YYYY-MM-DD')}`}
                    </Box>
                  </Box>
                </Box>
                <Box p={1}>
                  <Slider
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...settingsHigh}
                  >
                    {damHigh.length > 0 && (
                      damHigh.map(row => {
                        const statusColor = DamStatusColor(
                          parseFloat(row.dam_storage_percent).toFixed(0),
                        );
                        return (
                          <Box display="inline" align="center" style={{ width: 100 }} key={row.id}>
                            <Box style={{ width: '100px' }} align="center">
                              <DashboardContext.Consumer>
                                {({ handleModalOpen }) => (
                                  <Fragment key="key">
                                    <Button
                                      onClick={this.handleChart(handleModalOpen, row.dam.id, 12)}
                                      title={titleText}
                                      style={{ textAlign: 'left', paddingLeft: 5 }}
                                    >
                                      <CircularProgressbar
                                        className={classes.progressDam}
                                        variant="static"
                                        size={100}
                                        key={row.id}
                                        value={parseInt(row.dam_storage_percent.toFixed(0), 10)}
                                        text={`${Number.isNaN(row.dam_storage_percent.toFixed(0))
                                          ? 'n/a'
                                          : row.dam_storage_percent.toFixed(0)}%`}
                                        styles={{
                                          text: CircleProgressbarStyle().textProgressbarCircle,
                                          path: {
                                            stroke: statusColor.color.statusColor,
                                          },
                                        }}
                                      />
                                    </Button>
                                  </Fragment>
                                )}
                              </DashboardContext.Consumer>
                            </Box>
                            <Box fontWeight="fontWeightMedium" fontSize={14}>
                              {t('อ่างเก็บน้ำ')}
                              <br />
                              {row.dam.dam_name.th}
                            </Box>
                          </Box>
                        );
                      })
                    )}
                  </Slider>
                  {damHigh.length === 0 && (
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Box>{t('ไม่มีน้ำมากวิกฤต')}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

NextArrow.defaultProps = {
  onClick: () => { },
};

PrevArrow.defaultProps = {
  onClick: () => { },
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

Dam.propTypes = {
  classes: PropTypes.object.isRequired,
  dataDam: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }), withTranslation())(Dam);
