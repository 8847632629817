import {
  Box,
  Button,
  FormControl,
  Grid,
  Select,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";
import { uniqueId } from "lodash";
import React, { useState, useEffect } from "react";
import Img from "react-image";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { FaSpinner } from "../../utils/Fontawesome";

const width = "90%";

export default function StormTrackingImage({ classes }) {
  const [selectedYear, setSelectedYear] = useState(moment());
  const [currentYearData, setCurrentYearData] = useState(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [maxYear, setMaxYear] = useState(moment());
  const [minYear, setMinYear] = useState(moment());
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const baseUrl = `${process.env.MIX_API_URL}analyst/typhoon_track_yearly`;

    try {
      const response = await fetch(baseUrl).then((response) => response);

      if (!response.ok) {
        throw new Error("Something went wrong while fetching data...");
      }

      const result = await response.json();
      setMaxYear(moment(result.data[result.data.length - 1].datetime));
      setMinYear(moment(result.data[0].datetime));
      if (result.data.length > 0) {
        setCurrentYearData(result);
      } else {
        setCurrentYearData(undefined);
      }
      setSelectedYear(moment(result.data[result.data.length - 1].datetime));
      setIsLoading(false);
    } catch (error) {
      console.error("Fetch error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddYear = () => {
    if (selectedYear <= moment(maxYear, "YYYY").add(-1, "y")) {
      setSelectedYear(moment(selectedYear).add(1, "y"));
    }
  };

  const handleSubtractYear = () => {
    if (selectedYear >= moment(minYear, "YYYY").add(1, "y")) {
      setSelectedYear(moment(selectedYear).add(-1, "y"));
    }
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      {isLoading === true ? (
        <FaSpinner size={25} />
      ) : (
        <>
          <Box
            p={2}
            sx={{
              alignItems: "center",
              flexDirection: "row",
              display: "flex",
            }}
          >
            {selectedYear <= moment(minYear, "YYYY") ? (
              <Button onClick={() => handleSubtractYear()} disabled>
                <KeyboardArrowLeftIcon />
              </Button>
            ) : (
              <Button onClick={() => handleSubtractYear()}>
                <KeyboardArrowLeftIcon />
              </Button>
            )}

            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  openTo="year"
                  views={["year"]}
                  value={selectedYear}
                  onChange={setSelectedYear}
                  style={{ fontSize: 14 }}
                  minDate={moment(minYear, "YYYY-MM-DD")}
                  maxDate={moment(maxYear, "YYYY-MM-DD")}
                />
              </MuiPickersUtilsProvider>
            </FormControl>

            {selectedYear >= moment(maxYear, "YYYY") ? (
              <Button onClick={() => handleAddYear()} disabled>
              <KeyboardArrowRightIcon />
            </Button>
            ) : (
                <Button onClick={() => handleAddYear()}>
                <KeyboardArrowRightIcon />
              </Button>
            )}
          </Box>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={8} key={uniqueId()}>
              <Box p={0} display="block">
                <Box
                  display="block"
                  className={classNames(
                    classes.boxRadiusForecast,
                    classes.statusNull
                  )}
                  align="center"
                >
                  <Box
                    p={1}
                    style={{ backgroundColor: "rgba(255, 255, 255, .5)" }}
                  >
                    <Typography variant="h6">{selectedYear.year()}</Typography>
                  </Box>
                </Box>
                <Box
                  p={1}
                  display="block"
                  align="center"
                  style={{ border: "1px solid #ccc" }}
                >
                  {currentYearData != undefined &&
                    currentYearData.data.map((data, index) => {
                      let haveImage = false;
                      if (moment(data.datetime).year() == selectedYear.year()) {
                        haveImage = true;
                      }
                      return (
                        <Box key={uniqueId()}>
                          {haveImage && (
                            <>
                              <Button
                                onClick={() => {
                                  setLightboxOpen(true);
                                }}
                              >
                                <Img
                                  src={`${process.env.MIX_API_URL}shared/image?_csrf=&image=${data.media_path}`}
                                  width={width}
                                  alt={selectedYear.year()}
                                  className={classes.responsive}
                                />
                              </Button>
                              {lightboxOpen && (
                                <Lightbox
                                  mainSrc={`${process.env.MIX_API_URL}shared/image?_csrf=&image=${data.media_path}`}
                                  imageTitle={data.filename}
                                  reactModalStyle={{
                                    overlay: { zIndex: "1000000" },
                                  }}
                                  onCloseRequest={() => setLightboxOpen(false)}
                                />
                              )}
                            </>
                          )}
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
