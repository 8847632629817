import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
// import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { FaSpinner } from '../../../utils/Fontawesome';

// init highcharts module
// HighchartsMore(Highcharts);
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

class SwanChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      chartOptions: this.getChartOptions(),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.renderChart();
  }

  getChartOptions() {
    const { datas } = this.props;

    this.options = {
      title: {
        text: `สถานี ${datas[0].station.swan_name.th}`,
      },
      chart: {
        zoomType: 'xy',
        pinchType: '',

      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d %b}',
        },
      },
      yAxis: { // Primary yAxis
        opposite: false,
        title: {
          text: 'Hsig (m)',
        },
        labels: {
          format: '{value:.2f}',
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' m',
        xDateFormat: '%d %B %H:%M',
      },
      // colors: ['#F62C2C'],
      series: [],
      legend: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart() {
    const dataSeries = [];
    const series = [];
    const { datas } = this.props;

    // data series
    datas[0].wave_forecast_data.map(n => {
      let ts = null;
      let val = null;

      if (n.swan_highsig !== null) {
        val = parseFloat(n.swan_highsig).toFixed(5);
      } else {
        val = null;
      }

      ts = moment(n.swan_datetime).valueOf();
      series.push([ts, parseFloat(val)]);

      return series;
    });

    dataSeries.push({
      data: series,
      tooltip: {
        valueSuffix: ' m',
      },
    });

    // set chart options
    const chartOptions = this.getChartOptions();
    chartOptions.series = dataSeries;

    this.setState({ chartOptions, isLoading: false });
  }

  render() {
    const { isLoading, error, chartOptions } = this.state;

    // if error, show error message
    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      );
    }

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </>
    );
  }
}

SwanChart.propTypes = {
  datas: PropTypes.array.isRequired,
};

export default SwanChart;
