import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// icon
import {
  HiiDamIcon, HiiWaterLevelIcon, HiiWaterGateIcon, HiiWaterQualityIcon, HiiCctvIcon, HiiOceanSeaIcon,
} from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

import WindowScreen from '../../utils/WindowsFullScreen';
import showHeaderFooter from '../../utils/ShowHeaderFooter';

const initPath = process.env.MIX_APP_PATH;

class SidebarWater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // displayState: 'weather',
    };
  }

  render() {
    const { classes, t } = this.props;
    const displayState = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

    return WindowScreen() !== 'xl' && (
      <>
        {!showHeaderFooter ? <></> : (
          <Hidden mdDown>
            <Grid container direction="column" spacing={0} className={classes.sidebar}>
              <Grid item md>
                <Button
                  component={Link}
                  to={`${initPath}water`}
                  className={classes.sidebarTopButton}
                  color={displayState === 'water' ? 'primary' : 'default'}
                  variant={displayState === 'water' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'water' })}
                >
                  <Box display="block">
                    <Box height={30} align="center">
                      <HiiDamIcon />
                    </Box>
                    <Box align="center">
                      <Typography variant="caption">
                        {t('อ่างเก็บน้ำ')}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                <Divider />
              </Grid>
              <Grid item md>
                <Button
                  component={Link}
                  to={`${initPath}water/gate`}
                  className={classes.sidebarButton}
                  color={displayState === 'gate' ? 'primary' : 'default'}
                  variant={displayState === 'gate' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'gate' })}
                >
                  <Box display="block">
                    <Box height={30} align="center">
                      <HiiWaterGateIcon />
                    </Box>
                    <Box mt={1} align="center">
                      <Typography variant="body1" style={{ fontSize: '0.75rem' }}>
                        {t('ระดับน้ำที่ ปตร./ฝาย')}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                <Divider />
              </Grid>
              <Grid item md>
                <Button
                  component={Link}
                  to={`${initPath}water/quality`}
                  className={classes.sidebarButton}
                  color={displayState === 'quality' ? 'primary' : 'default'}
                  variant={displayState === 'quality' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'quality' })}
                >
                  <Box display="block">
                    <Box height={30} align="center">
                      <HiiWaterQualityIcon />
                    </Box>
                    <Box align="center">
                      <Typography variant="caption">
                        {t('คุณภาพน้ำ')}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                <Divider />
              </Grid>
              <Grid item md>
                <Button
                  component={Link}
                  to={`${initPath}water/wl`}
                  className={classes.sidebarButton}
                  color={displayState === 'wl' ? 'primary' : 'default'}
                  variant={displayState === 'wl' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'wl' })}
                >
                  <Box display="block">
                    <Box height={30} align="center">
                      <HiiWaterLevelIcon />
                    </Box>
                    <Box align="center">
                      <Typography variant="caption">
                        {t('ระดับน้ำ')}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                <Divider />
              </Grid>
              <Grid item md>
                <Button
                  component={Link}
                  to={`${initPath}water/cctv`}
                  className={classes.sidebarButton}
                  color={displayState === 'cctv' ? 'primary' : 'default'}
                  variant={displayState === 'cctv' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'cctv' })}
                >
                  <Box display="block">
                    <Box height={30} align="center">
                      <HiiCctvIcon />
                    </Box>
                    <Box align="center">
                      <Typography variant="caption">
                        {t('CCTV')}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                <Divider />
              </Grid>
              <Grid item md>
                <Button
                  component={Link}
                  to={`${initPath}water/ocean`}
                  className={classes.sidebarBottomButton}
                  color={displayState === 'ocean' ? 'primary' : 'default'}
                  variant={displayState === 'ocean' ? 'contained' : 'text'}
                  onClick={() => this.setState({ displayState: 'ocean' })}
                >
                  <Box display="block">
                    <Box height={30} align="center">
                      <HiiOceanSeaIcon />
                    </Box>
                    <Box mt={1} align="center">
                      <Typography variant="body1" style={{ fontSize: '0.75rem' }}>
                        {t('ระดับน้ำบริเวณปากแม่น้ำ')}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        )}
      </>
    );
  }
}

SidebarWater.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }), withTranslation('translations', { withRef: true }))(SidebarWater);
