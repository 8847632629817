import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { maxBy, minBy, take } from 'lodash';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withTranslation } from 'react-i18next';

// material-ui
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from '@material-ui/pickers';

import tJson from '../../utils/TJson';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const graphType = [
  { id: 'station', name: 'เปรียบเทียบสถานีคุณภาพน้ำ' },
  { id: 'param', name: 'เปรียบเทียบพารามิเตอร์' },
  { id: 'waterlevel', name: 'เปรียบเทียบสถานีวัดระดับน้ำ' },
  { id: 'datetime', name: 'เปรียบเทียบวันที่เวลาและสถานี' },
];

const paramType = [
  {
    value: 'salinity',
    text: 'ความเค็ม(g/L)',
    name: {
      th: 'ความเค็ม(g/L)',
      en: 'salinity(g/L)',
    },
  },
  {
    value: 'do',
    text: 'ออกซิเจนในน้ำ(mg/L)',
    name: {
      th: 'ออกซิเจนในน้ำ(mg/L)',
      en: 'DO(mg/L)',
    },
  },
  {
    value: 'ph',
    text: 'กรด-ด่าง(pH)',
    name: {
      th: 'กรด-ด่าง(pH)',
      en: 'pH',
    },
  },
  {
    value: 'turbid',
    text: 'ความขุ่น(ntu)',
    name: {
      th: 'ความขุ่น(ntu)',
      en: 'turbid(ntu)',
    },
  },
  {
    value: 'conductivity',
    text: 'ความนำไฟฟ้า(µS/cm)',
    name: {
      th: 'ความนำไฟฟ้า(µS/cm)',
      en: 'ec(µS/cm)',
    },
  },
  {
    value: 'tds',
    text: 'สารละลาย(mg/L)',
    name: {
      th: 'สารละลาย(mg/L)',
      en: 'tds(mg/L)',
    },
  },
  {
    value: 'chlorophyll',
    text: 'คลอโรฟิลล์(qg/L)',
    name: {
      th: 'คลอโรฟิลล์(qg/L)',
      en: 'chlorophyll(qg/L)',
    },
  },
  {
    value: 'temp',
    text: 'อุณหภูมิ(c)',
    name: {
      th: 'อุณหภูมิ(c)',
      en: 'temp(c)',
    },
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class WaterQualityParameterChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      dataParam: [],
      dataStation: [],
      waterQualityLoad: [],
      selectedDate: new Date(),
      provinceId: props.provinceId,
      graphTypeSelect: 'param',
      graphTypeSelectName: graphType[1].name,
      paramSelect: ['salinity'],
      stationSelect: [props.id],
      startDate: moment()
        .subtract(3, 'days')
        .locale('th')
        .format('YYYY-MM-DD'),
      endDate: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      today: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      startTime: moment()
        .subtract(3, 'days')
        .locale('th'),
      endTime: moment(new Date())
        .locale('th'),
      dataChart: undefined,
    };

    this.renderChart = this.renderChart.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getWaterQualityLoad();
    this.renderChart();
  }

  static getDerivedStateFromProps(props, state) {
    let shouldRerenderChart = false;
    const { id } = state;
    shouldRerenderChart = shouldRerenderChart || id !== props.id;
    if (shouldRerenderChart) {
      this.setState({ id: props.id });
    }
    return null;
  }

  // componentWillReceiveProps(nextProps) {
  //   this.renderChart();
  //   let shouldRerenderChart = false;

  //   const { id } = this.state;
  //   shouldRerenderChart = shouldRerenderChart || id !== nextProps.id;

  //   if (shouldRerenderChart) {
  //     this.setState({ id: nextProps.id });
  //     this.renderChart();
  //   }
  // }

  getChartOptions() {
    const { t } = this.props;
    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: false,
      },
      // rangeSelector: {
      // enabled: false
      // inputDateFormat: '%Y-%d-%m',
      // inputEditDateFormat: '%Y-%d-%m',
      // },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
        crosshair: true,
      },
      yAxis: [
        {
          // Primary yAxis
          opposite: false,
          title: {
            text: `${t('ความเค็ม(g/L)')}`,
          },
          labels: {
            format: '{value:.2f}',
          },
          minorGridLineWidth: 1,
          gridLineWidth: 1,
          alternateGridColor: null,
          plotLines: [
            {
              value: null,
              color: 'rgba(255, 139, 4, 1)',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: null,
                align: 'left',
              },
            },
            {
              value: null,
              color: 'red',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: null,
                align: 'left',
              },
            },
          ],
          plotBands: [],
        },
        {
          tickInterval: 1,
          opposite: true,
          title: {
            text: '',
            rotation: -270,
          },
          labels: {
            format: '{value:.2f}',
          },
          minorGridLineWidth: 1,
          gridLineWidth: 1,
          alternateGridColor: null,
          plotLines: [
            {
              value: null,
              color: 'rgba(255, 139, 4, 1)',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: null,
                align: 'left',
              },
            },
            {
              value: null,
              color: 'red',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: null,
                align: 'left',
              },
            },
          ],
          plotBands: [],
        },
      ],
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: t('ความเค็ม'),
          data: [],
          tooltip: {
            valueSuffix: ' (g/L)',
          },
        },
      ],
      exporting: {
        allowHTML: true,
        fallbackToExportServer: false,
        chartOptions: {
          fallbackToExportServer: true,
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false,
              },
            },
          },
        },
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getWaterQualityLoad() {
    fetch(`${process.env.MIX_API_URL}analyst/waterquality_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataParam = result.waterquality_param.data;
        const dataStation = result.waterquality_station.data;
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(
          currentDateTime.getDate() - 2,
        );

        const waterQualityLoad = result.data.data.filter(d => {
          const time = new Date(d.waterquality_datetime.replace(/-/g, '/')).getTime();
          return last24hrs < time;
        });

        this.setState({
          dataParam,
          dataStation,
          waterQualityLoad,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
    this.renderChart();
  };

  handleEndDateChange = date => {
    this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => { });
    this.renderChart();
  };

  handleStartTimeChange = date => {
    this.setState({ startTime: moment(date) });
    this.renderChart();
  };

  handleEndTimeChange = date => {
    this.setState({ endTime: moment(date) });
    this.renderChart();
  };

  handleParamChange = event => {
    if (event.target.name === 'paramSelect') {
      if (event.target.value.length >= 1 && event.target.value.length <= 2) {
        this.setState({ [event.target.name]: event.target.value });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
    this.renderChart();
  };

  handleStationChange = event => {
    this.setState({ stationSelect: event.target.value });
    this.renderChart();
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleChart = () => {
    this.renderChart();
    this.resetChart();
  };

  handleGraphTypeChange = event => {
    graphType.map(type => {
      if (type.id === event.target.value) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.setState({ ...this.state, graphTypeSelectName: type.name }, () => this.showState());
      }
      return true;
    });

    this.setState({ isLoading: false });
    this.setState({ graphTypeSelect: event.target.value });
  };

  showState = () => {
    const { graphTypeSelect } = this.state;
    const {
      handleParameterModalOpen,
      handleDateTimeModalOpen,
      handleWaterlevelModalOpen,
      handleStationModalOpen,
    } = this.props;

    if (graphTypeSelect === 'param') {
      handleParameterModalOpen(this.state);
    } else if (graphTypeSelect === 'datetime') {
      handleDateTimeModalOpen(this.state);
    } else if (graphTypeSelect === 'waterlevel') {
      handleWaterlevelModalOpen(this.state);
    } else if (graphTypeSelect === 'station') {
      handleStationModalOpen(this.state);
    }
  };

  multiSelectParamRenderer = selected => {
    const { dataParam } = this.state;
    const stationName = [];
    selected.map(n => {
      const dd = dataParam.find(st => st.value === n);
      if (typeof dd !== 'undefined') {
        stationName.push(tJson(dd.name, 'พารามิเตอร์'));
      }
      return stationName;
    });

    return <span>{stationName.join(', ')}</span>;
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  findMaxValue = data => {
    const arrayMaxValue = [];
    const arrayMinValue = [];
    data.forEach(element => {
      const dataCleanValid = element.data.filter(x => x.value !== null);
      const max = maxBy(dataCleanValid, 'value').value;
      const min = minBy(dataCleanValid, 'value').value;
      arrayMaxValue.push(max);
      arrayMinValue.push(min);
    });
    const obj = {
      max: Math.max(...arrayMaxValue),
      min: Math.min(...arrayMinValue),
    };
    return obj;
  }

  renderChart() {
    const {
      stationSelect,
      startDate,
      endDate,
      startTime,
      endTime,
      paramSelect,
      dataParam,
    } = this.state;
    const { t } = this.props;

    fetch(
      `${process.env.MIX_API_URL}analyst/waterquality_compare_param_graph?waterquality_station_id=${stationSelect}&param=${paramSelect}&start_datetime=${startDate} ${moment(startTime).format('HH:mm')}&end_datetime=${endDate} ${moment(endTime).format('HH:mm')}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        if (result.data[0]) {
          // fix case all value is null
          const dataClean = result.data[0].data.filter(x => x.value !== null);
          if (dataClean.length > 0) {
            this.setState({ dataChart: result.data[0] });
            // transform datas
            const dataSeries = [];
            let ts = null;
            let val = null;
            let seriesName = '';
            const findMaxMinData = this.findMaxValue(result.data);
            // find max, min
            // const max = maxBy(result.data[0].data, 'value').value;
            // const min = minBy(result.data[0].data, 'value').value;
            const { max } = findMaxMinData;
            const { min } = findMaxMinData;
            result.data.map((data, index) => {
              const series = [];
              data.data.map(n => {
                val = parseFloat(n.value);
                ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss')
                  .add(7, 'hours')
                  .valueOf();

                if (val === max || val === min) {
                  const marker = {
                    enabled: false,
                    symbol: '',
                    fillColor: '#06C',
                    radius: 5,
                  };
                  series.push({ x: ts, y: val, marker });
                } else if (!Number.isNaN(val)) {
                  series.push([ts, val]);
                }
                return series;
              });

              paramType.map(n => {
                const p = `waterquality_${n.value}`;
                if (data.series_name === p) {
                  seriesName = n.text;
                }
                return seriesName;
              });

              dataSeries.push({
                name: `${t(seriesName)}`,
                data: series,
                type: 'line',
                yAxis: index,
                tooltip: {
                  valueSuffix: ' ',
                  xDateFormat: '%d %B %Y %H:%M',
                },
              });

              return dataSeries;
            });
            // set chart options
            const chartOptions = this.getChartOptions();
            chartOptions.series = dataSeries;
            if (paramSelect.length === 1) {
              dataParam.map(row => {
                if (row.value === paramSelect[0]) {
                  chartOptions.yAxis[0].title.text = tJson(row.name, 'พารามิเตอร์​');
                }
                return chartOptions;
              });
            }

            if (paramSelect.length === 2) {
              dataParam.map(row => {
                if (row.value === paramSelect[1]) {
                  chartOptions.yAxis[1].title.text = tJson(row.name, 'พารามิเตอร์​');
                }
                return chartOptions;
              });
            }
            // plotLines
            if (result.data.min_bank) {
              chartOptions.yAxis[0].plotLines[0].value = result.data.min_bank;
              chartOptions.yAxis[0].plotLines[0].label.text = ` > ${result.data.min_bank
                } ${t('เกินเกณฑ์มาตรฐานน้ำดิบเพื่อผลิตน้ำประปา')}`;
            }
            if (result.data.ground_level) {
              chartOptions.yAxis[0].plotLines[1].value = result.data.ground_level;
              chartOptions.yAxis[0].plotLines[1].label.text = `> ${parseFloat(
                Math.round(result.data.ground_level * 100) / 100,
              ).toFixed(2)} ${t('เฝ้าระวังคุณภาพน้ำดิบเพื่อผลิตน้ำประปา')}`;
            }
            this.setState({ chartOptions, isLoading: false });
          } else {
            this.setState({ dataChart: undefined });
          }
        } else {
          this.setState({ dataChart: undefined });
          const chartOptions = this.getChartOptions();
          this.setState({ chartOptions, isLoading: false });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      graphTypeSelect,
      paramSelect,
      stationSelect,
      selectedDate,
      dataParam,
      dataStation,
      startDate,
      endDate,
      today,
      dataChart,
    } = this.state;

    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return <div className="text-center">{/* <FaSpinner size={70} /> */}</div>;
    }

    let info = '';
    if (dataChart) {
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {`*${t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}`}
        </div>
      );
    } else {
      info = '';
    }

    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs={10} sm={4} md={4} lg={4}>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('ชนิดกราฟ')}
              </InputLabel>
              <Select
                value={graphTypeSelect}
                onChange={this.handleGraphTypeChange}
              >
                {graphType.map(row => (
                  <MenuItem key={row.id} value={row.id}>
                    {t(row.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={4} md={4} lg={4}>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('สถานีคุณภาพน้ำ')}
              </InputLabel>
              <Select value={stationSelect} onChange={this.handleStationChange}>
                {dataStation.map(row => (
                  <MenuItem key={row.id} value={row.id}>
                    {tJson(row.waterquality_station_name, 'สถานี')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={4} md={4} lg={4}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="param">
                {t('พารามิเตอร์')}
              </InputLabel>
              {dataParam && (
                <Select
                  multiple
                  value={paramSelect}
                  onChange={this.handleParamChange}
                  input={<Input id="param" />}
                  renderValue={this.multiSelectParamRenderer}
                  MenuProps={MenuProps}
                  inputProps={{
                    name: 'paramSelect',
                    id: 'param',
                  }}
                >
                  {dataParam.map(row => (
                    <MenuItem key={row.value} value={row.value}>
                      <Checkbox checked={paramSelect.indexOf(row.value) > -1} />
                      <ListItemText primary={tJson(row.name, 'พารามิเตอร์')} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={3} md={3} lg={3}>
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  label={t('เวลาเริ่มต้น')}
                  value={selectedDate}
                  onChange={this.handleStartTimeChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={3} md={3} lg={3}>
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  label={t('เวลาสิ้นสุด')}
                  value={selectedDate}
                  onChange={this.handleEndTimeChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <FormControl className={classes.formControl}>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                size="small"
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {dataChart ? (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
              />
            ) : (
              <div className="text-center">{t('ไม่มีข้อมูลในช่วงเวลาที่เลือก')}</div>
            )}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

WaterQualityParameterChart.propTypes = {
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  handleStationModalOpen: PropTypes.func.isRequired,
  handleParameterModalOpen: PropTypes.func.isRequired,
  handleDateTimeModalOpen: PropTypes.func.isRequired,
  handleWaterlevelModalOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(WaterQualityParameterChart);
