import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

// icon
import { FaHome } from '../../utils/Fontawesome';
import { HiiPresentIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class ReportRainForecastSixMonthView extends Component {
  urlDecode = url => Buffer.from(url, 'base64').toString('ascii');

  render() {
    const { classes, t } = this.props;
    const { match: { params: { month, url } } } = this.props;

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Button href={`${initPath}report`}>
                  <FaHome fontSize="small" style={{ marginRight: 5 }} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('รายงานสถานการณ์')}
                  </Typography>
                </Button>
                <Button href={`${initPath}report/monthly/rain_forecast_6m/history`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('รายงานข้อมูลน้ำคาดการณ์ฝน 6 เดือน')}
                  </Typography>
                </Button>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t('ประจำเดือน')}
                  {`${moment(`${month}`).format('MMMM')}`}
                  {' '}
                  {t('ปี')}
                  {' '}
                  {`${moment(`${month}`).format('Y')}`}
                </Typography>
              </Breadcrumbs>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Box display="flex" width="100%">
                    <Box display="inline" pt={1}>
                      <HiiPresentIcon fontSize={24} />
                    </Box>
                    <Box display="inline" pt={1} pl={1}>
                      <Typography variant="h4" gutterBottom>
                        {t('รายงานข้อมูลน้ำคาดการณ์ฝน 6 เดือน')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <div className={classes.root}>
                    <a href={this.urlDecode(url)} target="_blank" rel="noopener noreferrer">กดเพื่อดูข้อมูล</a>
                    {/* <Iframe
                      url={`${process.env.MIX_APP_URL}/proxy/pdf.php?file=${this.urlDecode(url)}`}
                      width="100%"
                      height="600px"
                      id="myPdf"
                      className="pdf"
                      display="initial"
                      position="relative"
                      // src={this.urlDecode(url)}
                      // frameBorder="0"
                      // title="ViewIframe"
                    /> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

ReportRainForecastSixMonthView.defaultProps = {
  match: {
    params: {
      month: '',
      url: '',
    },
  },
};

ReportRainForecastSixMonthView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      month: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
};
export default withTranslation()(
  withStyles(styles, { withTheme: true })(ReportRainForecastSixMonthView),
);
