/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FaSpinner } from '../../../utils/Fontawesome';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

/**
   * ComponentName
   */
class FloodRoadChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      floodroadStation: [],
      floodroadStationFilter: [],
      startDate: moment().subtract(3, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD HH:mm'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      dataChart: undefined,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getStation();
    this.renderChart();
  }

  getChartOptions() {
    this.options = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 1,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: 'น้ำท่วมถนน (ซม.)',
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        alternateGridColor: null,
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: 'น้ำท่วมถนน',
          data: [],
          tooltip: {
            valueSuffix: ' ซม.',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
          },
        },
      },
    };

    return this.options;
  }

  getStation() {
    fetch(`${process.env.MIX_API_URL}public/flood_road`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const floodroadStationFilter = result.data;
        const floodroadStation = floodroadStationFilter.map(f => f.station);

        this.setState({
          floodroadStation,
          floodroadStationFilter,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

    handleStationChange = event => {
      this.setState({ id: event.target.value }, () => {
        this.handleChart();
      });
    };

    handleStartDateChange = date => {
      this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
    };

    handleEndDateChange = date => {
      const { today } = this.state;

      if (moment(date).format('YYYY-MM-DD') === today) {
        this.setState({ endDate: moment(date).format('YYYY-MM-DD HH:mm') }, () => {
          this.handleChart();
        });
      } else {
        const dt = `${moment(date).format('YYYY-MM-DD')} 23:59`;
        this.setState({ endDate: moment(dt).format('YYYY-MM-DD') }, () => {
          this.handleChart();
        });
      }
    };

    handleChart = () => {
      this.resetChart();
      this.renderChart();
    };

    resetChart = () => {
      const chartCount = Highcharts.charts.length - 1;
      const charts = Highcharts.charts[chartCount];
      if (chartCount >= 0 && charts !== undefined) {
        if (charts.xAxis !== undefined) {
          charts.xAxis[0].setExtremes();
        }
      }
    }

    renderChart = () => {
      const { id, startDate, endDate } = this.state;
      fetch(
        `${process.env
          .MIX_API_URL}public/flood_road_graph?station_id=${id}&date_start=${startDate}&date_end=${endDate}`,
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
        .then(result => {
          // fix case all value is null
          const dataClean = result.data.filter(x => x.floodroad_value !== null);

          if (dataClean.length > 0) {
            // transform datas
            const dataSeries = [];
            let ts = null;
            let val = null;

            result.data.map(n => {
              val = parseFloat(n.floodroad_value);
              ts = moment(n.floodroad_datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
              dataSeries.push([ts, val]);

              return dataSeries;
            });

            const max = maxBy(result.data, 'floodroad_value');
            const min = minBy(result.data, 'floodroad_value');

            // set chart options
            const chartOptions = this.getChartOptions();

            chartOptions.yAxis.max = maxBy([result.data.min_bank, max.value + 0.5]);
            chartOptions.yAxis.min = minBy([result.data.ground_level, min.value]);
            chartOptions.series[0].data = dataSeries;

            this.setState({
              dataChart: dataClean,
              chartOptions,
              isLoading: false,
            });
          } else {
            this.setState({ isLoading: false, dataChart: undefined });
          }
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
      const {
        id, floodroadStation, floodroadStationFilter, startDate, endDate, today, dataChart,
      } = this.state;
      const { isLoading, error, chartOptions } = this.state;
      const { classes } = this.props;

      let name = '';
      let provinceName = '';
      let stationName = '';
      if (floodroadStation.length > 0) {
        const stationByClick = floodroadStationFilter
          .filter(d => d.station.id.toString() === id.toString())[0];
        const station = stationByClick === undefined ? floodroadStationFilter[0] : stationByClick;

        if (station) {
          provinceName = station.geocode.province_name.th;
          stationName = (station.station.floodroad_name.th)
            ? station.station.floodroad_name.th : station.station.floodroad_oldcode;
        }
        name = `สถานี ${stationName} จ.${provinceName}`;
      }

      if (error) {
        return (
          <div className="text-center">
            {error.message}
          </div>
        );
      }

      // if still loading, show spinner
      if (isLoading) {
        return (
          <div className="text-center">
            <FaSpinner size={30} />
          </div>
        );
      }
      let info = '';
      if (dataChart) {
        info = (
          <div style={{ color: '#A2A2A2' }}>
            *สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
          </div>
        );
      } else {
        info = '';
      }

      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="province-label-placeholder">
                  จังหวัด
                </InputLabel>
                <Input
                  value={provinceName}
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="station-label-placeholder">
                  สถานี
                </InputLabel>
                <NativeSelect value={id} onChange={this.handleStationChange}>
                  <optgroup label="สถานี">
                    {floodroadStation.map(row => (
                      <option key={row.id} value={row.id}>
                        {row.floodroad_name.th}
                      </option>
                    ))}
                  </optgroup>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="วันที่เริ่มต้น"
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    value={startDate}
                    maxDate={today}
                    onChange={this.handleStartDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="วันที่สิ้นสุด"
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    value={endDate}
                    maxDate={today}
                    onChange={this.handleEndDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={this.handleChart}
                  title="แสดงกราฟ"
                >
                  แสดง
                </Button>
              </FormControl>
            </Grid>
            <Grid item xs={12} align="center" style={{ fontFamily: 'Lucida Grande,Lucida Sans Unicode,Arial,Helvetica, sans-serif', fontSize: '14px', fontWeight: 'bold' }}>
              {name}
            </Grid>
            <Grid item xs={12}>
              {dataChart ? (
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
              ) : (
                <Grid item xs={12} align="center">ไม่มีข้อมูลในช่วงเวลาที่เลือก</Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {info}
            </Grid>
          </Grid>
        </>
      );
    }
}

FloodRoadChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(FloodRoadChart);
