import React, { Component } from 'react';
import PropTypes from 'prop-types';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

// marerial-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// lodash
import {
  uniqueId,
} from 'lodash';

// images
import Lightbox from 'react-image-lightbox';
import Img from 'react-image';

// style, ui
import { styles } from '../../../styles/Style';

const currentDate = new Date(moment());
class Gsmap10kmListHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startdate: new Date(moment()),
      mvalue: { year: moment().format('YYYY'), month: moment().format('M') },
      GSMap10KMHistory: props.data,
      isOpen: false,
      photoIndex: 0,
    };
    this.initialState = { ...this.state };
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  getGSMaP10KMArchive(dateCurrent, type) {
    function daysInMonth(Month, Year) {
      return new Date(Year, Month, 0).getDate();
    }
    const toDay = dateCurrent;
    const months = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค'];
    const month = toDay.getMonth();
    const year = toDay.getFullYear();
    const numDay = daysInMonth(month + 1, year);
    const maxDay = numDay;
    const domain = process.env.MIX_LIVE_URL;
    const URL1 = `${domain}/product/history/sat_rain/GSMaP_010_bias/`;
    const arrayGSMaP10KM = [];

    for (let i = 1; i <= maxDay; i += 1) {
      const d = new Date(toDay.getFullYear(), month, i);
      const currDay = d.getDate();
      const currMonth = d.getMonth() + 1;
      const currYear = d.getFullYear();
      const currMonthFull = ((`${currMonth}`).length < 2 ? '0' : '') + currMonth;
      const currDayFull = ((`${currDay}`).length < 2 ? '0' : '') + currDay;
      const imageDate = `${currYear}${currMonthFull}${currDayFull}`;
      const imagePath = `${currYear}/${currMonthFull}`;
      const dateName = `${currDayFull} ${months[d.getMonth()]} ${currYear + 543}`;
      // const urlImg = `${URL1 + imagePath}/${type}_${imageDate}_bias.png`;
      const urlImg = `${URL1 + imagePath}/gsm_nrt_bias_${imageDate}.png`;
      const obj10km = { url: urlImg, date: `${dateName} เวลา 07:00 น.` };
      arrayGSMaP10KM.push(obj10km);
    }
    this.setState({ GSMap10KMHistory: arrayGSMaP10KM });
    this.forceUpdate();
  }

  handleDateChange = date => {
    // eslint-disable-next-line no-underscore-dangle
    const day = date._d;
    this.getGSMaP10KMArchive(day, 'gsm_010');
    this.setState({ startdate: day });
    this.forceUpdate();
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.startDate
    if (change === '1') {
      selectedDate = moment(this.state.startDate).add(1, 'months');
    }
    else if (change === '-1') {
      selectedDate = moment(this.state.startDate).add(-1, 'months');
    }
    const day = selectedDate._d;
    this.getGSMaP10KMArchive(day, 'gsm_010');
    this.setState({
      startDate: selectedDate,
    });
    this.forceUpdate();
  }

  render() {
    const { classes } = this.props;
    const {
      startdate, GSMap10KMHistory, isOpen, photoIndex,
    } = this.state;
    return (
      <>
        <Grid container direction="row" justifyContent="center" spacing={4} style={{ paddingTop: 10, marginTop: 20 }}>
          <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
          <Grid item xs sm={3} md={3}>
            <Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  openTo="month"
                  views={['month', 'year']}
                  value={startdate}
                  onChange={this.handleDateChange}
                  style={{ fontSize: 14 }}
                  maxDate={currentDate}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Grid>
          <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
        </Grid>

        <Grid container spacing={4} style={{ paddingTop: 20 }}>
          {GSMap10KMHistory.map((result, i) => (
            <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
              <Box mt={1} className={classes.boxWeatherHistory}>
                <Box display="block" align="center">
                  <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                    <Img
                      src={result.url}
                      className={classes.responsive}
                      alt="imageGSMaP10KM History"
                      unloader={(
                        <Img
                          src="https://via.placeholder.com/232x425?text=No%20Image"
                          alt="No Image"
                          className={classes.responsive}
                        />
                      )}
                    />
                  </Button>
                </Box>
                <Box p={2} display="flex" alignItems="center" justifyContent="center">
                  <Typography className={classes.dateTime}>
                    {result.date}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={GSMap10KMHistory[photoIndex].url}
            nextSrc={GSMap10KMHistory[(photoIndex + 1) % GSMap10KMHistory.length].url}
            prevSrc={
              GSMap10KMHistory[
                (photoIndex + GSMap10KMHistory.length - 1) % GSMap10KMHistory.length].url
            }
            imageTitle={GSMap10KMHistory[photoIndex].date}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + GSMap10KMHistory.length - 1) % GSMap10KMHistory.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % GSMap10KMHistory.length,
              }))}
          />
        )}
      </>
    );
  }
}

Gsmap10kmListHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
};

export default withStyles(styles, { withTheme: true })(Gsmap10kmListHistory);
