/**
 * *
 * @version 1.0.0
 * @author [Permporn]
 * Modify by Thitiporn
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Link,
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Img from 'react-image';

import grey from '@material-ui/core/colors/grey';
import { FaPlay, FaSpinner, FaCalendarAlt } from '../../../utils/Fontawesome';
import Animation from '../../forecast/Animation';
import AnimationFromUrl from '../../forecast/AnimationFromUrl';
import ForecastContext from '../../../contexts/forecastContext';

// credit tooltip
import CreditTooltip from '../../../utils/CreditTooltip';

import {
  styles,
} from '../../../styles/Style';
// import SteamImage from '../steam-image/steamImage';

const initPath = process.env.MIX_API_URL;
const initPath1 = process.env.MIX_APP_PATH;
const hostImage = `${initPath}shared/image?_csrf=&image=`;
const imageNull = 'https://via.placeholder.com/322x230?text=No%20Image&italic=true';
// const height = ['0dot6km', '1dot5km', '5km', '0dot6km', '1dot5km'];
const height = ['5km', '', '0dot6km', '1dot5km'];
// const upperWindText = [
//   'ภาพคาดการณ์ลม ที่ความสูง 0.6 กม.',
//   'ภาพคาดการณ์ลม ที่ความสูง 1.5 กม.',
//   'แผนที่แนวดิ่ง ที่ความสูง 5 กม.',
//   'แผนที่อากาศ ที่ความสูง 0.6 กม.',
//   'แผนที่อากาศ ที่ความสูง 1.5 กม.'
// ];
const upperWindText = ['แผนที่ลมแนวดิ่ง ระดับความสูง 5 กม.', 'ภาพคาดการณ์ลม ระดับความสูง 10 เมตร', 'แผนที่อากาศ ระดับความสูง 0.6 กม.', 'แผนที่อากาศ ระดับความสูง 1.5 กม.'];
// const presSureText = ['แผนที่ลมแนวดิ่ง ระดับความสูง 5 กม.', 'แผนที่อากาศ ระดับความสูง 0.6 กม.', 'แผนที่อากาศ ระดับความสูง 1.5 กม.'];

class WeatherImageLatest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      imageUpperWind: [],
      imageContour: [],
      imageSeaSurface: [],
      // imageNdvi: [],
      imageWeatherMapTmd: [],
      imageWind10m: [],
      animationWind10m: '',

      // for lightbox default
      // photoIndex: 0,
      // isOpen: false,

      // wmo lightbox
      photoIndexWmo: 0,
      isOpenWmo: false,

      // afwa lightbox
      photoIndexAfwa: 0,
      isOpenAfwa: false,

      // Weather map lightbox
      photoIndexWeatherMap: 0,
      isOpenWeatherMap: false,

      // Distribution map lightbox
      photoIndexDistributionMap: 0,
      isOpenDistributionMap: false,

      // weather level map
      photoIndexWeatherLevelMap: 0,
      isOpenWeatherLevelMap: false,

      // Vegetation
      photoIndexVegetation: 0,
      isOpenVegetation: false,

      // trough image
      imageTrough: {
        image: 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_00.png',
        imageBig: 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_00.png',
        vdo: 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_fcst_00utc.mp4',
        imgDate: moment().format('YYYY-MM-DD'),
        imgTime: moment().format('HH:mm'),
      },
      isOpenImageTrough: false,

      // ภาพไอน้ำ
      // displayState: 'latest',
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: false });

    // get data from ws
    this.getImageUpperWind();
    this.getImageContour();
    this.getImageSeaSurface();
    this.getNdvi();
    this.getWeatherMap();
    this.getImageWind10m();
    this.getImageTrough();
  }

  getImageUpperWind() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/upper_wind_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imageUpperWind: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getImageWind10m() {
    fetch(`${process.env.MIX_API_URL}analyst/wind10m_forecast_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imageWind10m: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}analyst/wind10m_forecast_animation_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          animationWind10m: result.data[0].media_path,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getImageTrough() {
    const timeNow = moment().hours();
    // 00utc เสร็จ 15.00
    // 12utc เสร็จ 03.00
    let [picDateTr, picTimeTr] = ['', ''];
    const [morning, evening] = [15, 3];
    const [picM, picE] = ['https://live1.hii.or.th/product/latest/wrfroms/trough/tn_trough_00utc.png', 'https://live1.hii.or.th/product/latest/wrfroms/trough/tn_trough_12utc.png'];
    const [vdoM, vdoE] = ['https://live1.hii.or.th/product/latest/wrfroms/trough/trough_fcst_00utc.mp4', 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_fcst_12utc.mp4'];
    let srcImageTrough = picM;
    let srcVdoTrough = vdoM;
    if (timeNow >= morning && evening < timeNow) {
      srcImageTrough = picM;
      srcVdoTrough = vdoM;
      picDateTr = moment().format('YYYY-MM-DD');
      picTimeTr = '07:00';
    } else {
      srcImageTrough = picE;
      srcVdoTrough = vdoE;
      picDateTr = moment().subtract(1, 'days').format('YYYY-MM-DD');
      picTimeTr = '19:00';
    }

    this.setState({
      imageTrough: {
        image: srcImageTrough,
        imageBig: 'https://live1.hii.or.th/product/latest/wrfroms/trough/trough_00.png',
        vdo: srcVdoTrough,
        imgDate: picDateTr,
        imgTime: picTimeTr,
      },
    });
  }

  getImageContour() {
    let image = [];
    const imageArray = [];
    let imagePath = '';
    let imageThumbnail = '';

    // แผนที่แสดงการกระจายตัว
    fetch(`${process.env.MIX_API_URL}public/weather_img/contour_image`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        let linkSrc = '';
        for (let i = 0; i <= 2; i += 1) {
          if (result.data[i].cover_image.media_path) {
            imagePath = hostImage + result.data[i].cover_image.media_path;
          } else {
            imagePath = imageNull;
          }
          if (result.data[i].cover_image.thumbnail_media_path) {
            imageThumbnail = hostImage + result.data[i].cover_image.thumbnail_media_path;
          } else {
            imageThumbnail = imageNull;
          }
          let mediaTypeId;
          if (i === 2) {
            mediaTypeId = 3;
          }
          if (i === 1) {
            mediaTypeId = 1;
          }
          if (i === 0) {
            mediaTypeId = 2;
          }
          linkSrc = `${initPath1}weather/${moment(result.data[i].cover_image.media_datetime).format('YYYY-MM-DD')}/9/${mediaTypeId}`;
          image = {
            src: imageThumbnail,
            srcImage: imagePath,
            title: result.data[i].description.description_name.th.replace('แผนที่แสดงการกระจายตัว', ''),
            subtitle: result.data[i].agency.agency_name.th,
            link: linkSrc,
            imagetype: result.data[i].cover_image.filename,
            media_datetime: result.data[i].cover_image.media_datetime,
            mediaTypeId,
          };
          imageArray[i] = image;
          this.setState({
            imageContour: imageArray,
            isLoading: false,
          });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getImageSeaSurface() {
    let image = [];
    const imageArray = [];
    let imagePath = '';
    let imageThumbnail = '';

    // ค่าความชื้นในดิน AFWA, WMO Surface
    fetch(`${process.env.MIX_API_URL}public/weather_img/modis_soil_moisture_usda`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        let mediaType;
        const soiType = ['Surface', 'Sub-Surface', 'Surface', 'Sub-Surface'];
        for (let i = 0; i <= 3; i += 1) {
          if (result.data[i].cover_image.media_path) {
            imagePath = hostImage + result.data[i].cover_image.media_path;
          } else {
            imagePath = imageNull;
          }
          if (result.data[i].cover_image.thumbnail_media_path) {
            imageThumbnail = hostImage + result.data[i].cover_image.thumbnail_media_path;
          } else {
            imageThumbnail = imageNull;
          }
          if (i === 2) {
            mediaType = '162';
          } else if (i === 3) {
            mediaType = '163';
          } else {
            mediaType = result.data[i].detail[0].media_type_id;
          }

          image = {
            src: imageThumbnail,
            srcImage: imagePath,
            // title: result.data[i].description.description_name.th,
            title: soiType[i],
            subtitle: result.data[i].agency.agency_name.th,
            link: `${initPath1}weather/${moment(result.data[i].cover_image.media_datetime.split(' ')[0]).format('YYYY')}-${moment(result.data[i].cover_image.media_datetime.split(' ')[0]).format('MM')}/55/${mediaType}`,
            imagetype: result.data[i].cover_image.filename,
            media_datetime: result.data[i].cover_image.media_datetime,
            mediaTypeId: mediaType,
            agency_name: result.data[i].agency.agency_name.th,
            agency_link: result.data[i].agency.agency_link,
          };
          imageArray[i] = image;

          this.setState({
            imageSeaSurface: imageArray,
            isLoading: false,
          });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getNdvi() {
    let image = [];
    const imageArray = [];
    let imagePath = '';
    let imageThumbnail = '';

    // ค่าดัชนีพืชพรรณจากภาพถ่ายดาวเทียม
    fetch(`${process.env.MIX_API_URL}public/weather_img/modis_ndvi_usda`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        for (let i = 0; i <= 1; i += 1) {
          if (result.data[i].cover_image.media_path) {
            imagePath = imageNull;
          } else {
            imagePath = imageNull;
          }
          if (result.data[i].cover_image.thumbnail_media_path) {
            imageThumbnail = imageNull;
          } else {
            imageThumbnail = imageNull;
          }
          image = {
            src: imageThumbnail,
            srcImage: imagePath,
            title: result.data[i].description.description_name.th.replace('โดย  United States Department of Agriculture', ''),
            subtitle: result.data[i].agency.agency_name.th,
            link: `${initPath1}weather/${moment(result.data[i].cover_image.media_datetime.split(' ')[0]).format('YYYY')}-${moment(result.data[i].cover_image.media_datetime.split(' ')[0]).format('MM')}/${result.data[i].agency.agency_id}/${result.data[i].detail[0].media_type_id}`,
            imagetype: result.data[i].description.description_name.th,
            media_datetime: result.data[i].cover_image.media_datetime,
            mediaTypeId: result.data[i].detail[0].media_type_id,
            agency_name: result.data[i].agency.agency_name.th,
            agency_link: result.data[i].agency.agency_link,

          };
          imageArray[i] = image;
          this.setState({
            // imageNdvi: imageArray,
            isLoading: false,
          });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getWeatherMap() {
    let image = [];
    const imageArray = [];
    let imagePath = '';
    let imageThumbnail = '';

    // แผนที่อากาศ
    fetch(`${process.env.MIX_API_URL}public/weather_img/weather_map_tmd`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        for (let i = 0; i <= 2; i += 1) {
          if (result.data[i].cover_image.media_path) {
            imagePath = hostImage + result.data[i].cover_image.media_path;
          } else {
            imagePath = imageNull;
          }
          if (result.data[i].cover_image.thumbnail_media_path) {
            imageThumbnail = hostImage + result.data[i].cover_image.thumbnail_media_path;
          } else {
            imageThumbnail = imageNull;
          }
          image = {
            src: imageThumbnail,
            srcImage: imagePath,
            title: result.data[i].description.description_name.th.replace(' กรมอุตุนิยมวิทยา', ''),
            subtitle: result.data[i].agency.agency_name.th,
            link: `${initPath1}weather/${moment(result.data[i].cover_image.media_datetime).format('YYYY-MM-DD')}/13/${result.data[i].detail[0].media_type_id}`,
            imagetype: result.data[i].cover_image.filename,
            media_datetime: result.data[i].cover_image.media_datetime,
            mediaTypeId: result.data[i].detail[0].media_type_id,
            agency_name: result.data[i].agency.agency_name.th,
            agency_link: result.data[i].agency.agency_link,
          };
          imageArray[i] = image;

          this.setState({
            imageWeatherMapTmd: imageArray,
            isLoading: false,
          });
        }
        // if (imageArray.length === 3) {
        //   // ตัด ภาพคาดการณ์ลม ระดับความสูง 10 เมตร ออก
        //   upperWindText.splice(1, 1);
        // }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleModalAnimation = (handler, data) => e => {
    e.preventDefault();

    const params = {
      content: <Animation media={data} />,
    };

    handler(params);
  }

  handleModalAnimationFromUrl = (handler, data) => e => {
    e.preventDefault();

    const params = {
      content: <AnimationFromUrl media={data} />,
    };

    handler(params);
  }

  imageBox(classes, row, i) {
    const { t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={4} lg={4} key={row.mediaTypeId}>
        <Box display="flex" className={classes.boxTopRadius} alignItems="center">
          <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
            {`${t(row.title)}`}
          </Box>
          <Box p={0} style={{ borderLeft: '1px solid #ccc' }}>
            <Link to={`${row.link}`}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <IconButton className={classes.buttonIcon}>
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Box>
        <Box display="flex" className={classes.boxBottomRadius} alignItems="center" justifyContent="center">
          <Box>
            <Button
              onClick={
                () => this.setState({ isOpenDistributionMap: true, photoIndexDistributionMap: i })
              }
            >
              <Img
                src={`${row.src}`}
                alt={row.title}
                className={classes.responsive}
                width="100%"
                height="100%"
                unloader={(
                  <Img
                    src="https://via.placeholder.com/204x375?text=No%20Image"
                    alt="No Data"
                    className={classes.responsive}
                  />
                )}
              />
            </Button>
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.dateTime}>
                {`${t('ล่าสุด')} ${moment(row.media_datetime).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.media_datetime).format('HH:mm')} ${t('น.')}`}
              </Typography>
            </Box>
          </Box>
        </Box>

      </Grid>
    );
  }

  imageWeatherLevelBox(classes, row, i) {
    const { t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={3} lg={3} key={row.mediaTypeId}>
        <Box display="flex" className={classes.boxTopRadius} alignItems="center">
          <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
            {`${t(row.title)}`}
          </Box>
          <Box p={0} style={{ borderLeft: '1px solid #ccc' }}>
            <Link to={`${row.link}`}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <IconButton className={classes.buttonIcon}>
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Box>
        <Box display="flex" className={classes.boxBottomWindRadius} alignItems="center" justifyContent="center">
          <Box>
            <CreditTooltip
              title={t(row.agency_name)}
              link={row.agency_link}
            >
              <Button
                onClick={
                  () => this.setState({ isOpenWeatherLevelMap: true, photoIndexWeatherLevelMap: i })
                }
              >
                <Img
                  src={`${row.src}`}
                  alt={row.title}
                  className={classes.responsive}
                  width="100%"
                  height="100%"
                  unloader={(
                    <img
                      src="https://via.placeholder.com/204x375?text=No%20Image"
                      alt="No Data"
                    />
                  )}
                />
              </Button>
            </CreditTooltip>
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.dateTime}>
                {`${t('ล่าสุด')} ${moment(row.media_datetime).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.media_datetime).format('HH:mm')} ${t('น.')}`}
              </Typography>
            </Box>
          </Box>
        </Box>

      </Grid>
    );
  }

  imageWeatherLevelBox2(classes, row, i) {
    const { t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={3} lg={3} key={row.mediaTypeId}>
        <Box display="flex" className={classes.boxTopRadius} alignItems="center">
          <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
            {`${t(row.title)}`}
          </Box>
          <Box p={0} style={{ borderLeft: '1px solid #ccc' }}>
            <Link to={`${row.link}`}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <IconButton className={classes.buttonIcon}>
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Box>
        <Box display="flex" className={classes.boxBottomRoverRadius} alignItems="center" justifyContent="center">
          <Box>
            {/* {console.log(row.title, ' | ', i)} */}
            <CreditTooltip
              title={t(row.agency_name)}
              link={row.agency_link}
            >
              <Button
                onClick={
                  () => this.setState({ isOpenWeatherLevelMap2: true, photoIndexWeatherLevelMap2: i })
                }
              >
                <Img
                  src={`${row.src}`}
                  alt={t(row.title)}
                  className={classes.responsive}
                  width="100%"
                  height="100%"
                  unloader={(
                    <img
                      src="https://via.placeholder.com/204x375?text=No%20Image"
                      alt="No Data"
                    />
                  )}
                />
              </Button>
            </CreditTooltip>
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.dateTime}>
                {`${t('ล่าสุด')} ${moment(row.media_datetime).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.media_datetime).format('HH:mm')} ${t('น.')}`}
              </Typography>
            </Box>
          </Box>
        </Box>

      </Grid>
    );
  }

  imageVegetationBox(classes, row, i) {
    const { t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={4} lg={4} key={row.mediaTypeId}>
        <Box display="flex" className={classes.boxTopRadius} alignItems="center">
          <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
            {`${t(row.title)}`}
          </Box>
          <Box p={0} style={{ borderLeft: '1px solid #ccc' }}>
            <Link to={`${row.link}`}>
              <Tooltip title="ดูภาพย้อนหลัง">
                <IconButton className={classes.buttonIcon}>
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Box>
        <Box display="flex" className={classes.boxBottomRadius} alignItems="center" justifyContent="center">
          <Box>
            <CreditTooltip
              title={row.agency_name}
              link={row.agency_link}
            >
              <Button
                onClick={
                  () => this.setState({ isOpenVegetation: true, photoIndexVegetation: i })
                }
              >
                <Img
                  src={`${row.src}`}
                  alt={row.title}
                  className={classes.responsive}
                  width="100%"
                  height="100%"
                  unloader={(
                    <img
                      src="https://via.placeholder.com/204x375?text=No%20Image"
                      alt="No Data"
                    />
                  )}
                />
              </Button>
            </CreditTooltip>
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.dateTime}>
                {`${t('ล่าสุด')} ${moment(row.media_datetime).format('YYYY-MM-DD')} เวลา : ${moment(row.media_datetime).format('HH:mm')} ${t('น.')}`}
              </Typography>
            </Box>
          </Box>
        </Box>

      </Grid>
    );
  }

  imageBoxSoilMoisture(classes, row, i, type) {
    const { t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={6} lg={6} key={row.mediaTypeId}>
        <Box display="flex" className={classes.boxTopRadius} alignItems="center">
          <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
            {`${t(row.title)}`}
          </Box>
          <Box p={0} style={{ borderLeft: '1px solid #ccc' }}>
            <Link to={`${row.link}`}>
              <Tooltip title="ดูภาพย้อนหลัง">
                <IconButton className={classes.buttonIcon}>
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Box>
        <Box display="flex" className={classes.boxBottomRadius} alignItems="center" justifyContent="center">
          <Box>
            {type === 'afwa' && (
              {/* <CreditTooltip
                title={row.agency_name}
                link={row.agency_link}
              >
                <Button onClick={() => this.setState({ isOpenAfwa: true, photoIndexAfwa: i })}>
                  <Img
                    src={`${row.src}`}
                    alt={row.title}
                    className={classes.responsive}
                    width="100%"
                    unloader={(
                      <img
                        src="https://via.placeholder.com/204x375?text=No%20Image"
                        alt="No Data"
                      />
                    )}
                  />
                </Button>
              </CreditTooltip> */}
            )}

            {type === 'wmo' && (
              <CreditTooltip
                title={row.agency_name}
                link={row.agency_link}
              >
                <Button onClick={() => this.setState({ isOpenWmo: true, photoIndexWmo: i })}>
                  <Img
                    src={`${row.src}`}
                    alt={row.title}
                    className={classes.responsive}
                    width="100%"
                    unloader={(
                      <img
                        src="https://via.placeholder.com/204x375?text=No%20Image"
                        alt="No Data"
                      />
                    )}
                  />
                </Button>
              </CreditTooltip>
            )}
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.dateTime}>
                {`${t('ล่าสุด')} ${moment(row.media_datetime).format('YYYY-MM-DD')}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  renderImageUpperWind(classes, row, i) {
    const { t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={3} lg={3} key={`upper_${row.media_type}`}>
        <Box display="flex" className={classes.boxTopRadiusWeather} alignItems="center">
          <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight={500}>
            {t(upperWindText[i])}
          </Box>
          <Box p={1} style={{ borderRight: '1px solid #ccc', borderLeft: '1px solid #ccc' }}>
            <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
              <ForecastContext.Consumer>
                {({ handleModalOpen }) => (
                  <IconButton
                    className={classes.icon}
                    onClick={this.handleModalAnimation(
                      handleModalOpen,
                      row.media_path_animation,
                    )}
                  >
                    <FaPlay style={{ fontSize: '0.8em' }} />
                  </IconButton>
                )}
              </ForecastContext.Consumer>
            </Tooltip>
          </Box>
          <Box p={0}>
            <Link to={`${initPath1}weather/upperwind/history/${height[i]}`}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <IconButton className={classes.buttonIcon}>
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Box>
        <Box display="flex" className={classes.boxBottomRoverRadius} alignItems="center" justifyContent="center">
          <Box>
            <Button
              onClick={
                () => this.setState({ isOpenWeatherMap: true, photoIndexWeatherMap: i })
              }
            >
              <Img
                src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                // ${row.media_path_thumb}`}
                className={classes.responsiveHeight}
                alt={row.media_type}
                width="100%"
                unloader={(
                  <Img
                    src="https://via.placeholder.com/232x243?text=No%20Image"
                    alt="No Image"
                  />
                )}
              />
            </Button>
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.dateTime}>
                {`${t('ล่าสุด')} ${moment(row.datetime).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.datetime).format('HH:mm')} ${t('น.')}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  renderImageTrough(classes) {
    const { imageTrough } = this.state;
    const { t } = this.props;
    return (
      <Grid item xs={12} sm={6} md={3} lg={3} key="trough">
        <Box display="flex" className={classes.boxTopRadiusWeather} alignItems="center">
          <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={'0.9em'} fontWeight={500}>
            {t('แผนที่อากาศ ระดับน้ำทะเล และร่องความกดอากาศต่ำ')}
          </Box>
          <Box p={1} style={{ borderRight: '1px solid #ccc', borderLeft: '1px solid #ccc' }}>
            <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
              <ForecastContext.Consumer>
                {({ handleModalOpen }) => (
                  <IconButton
                    className={classes.icon}
                    onClick={this.handleModalAnimationFromUrl(
                      handleModalOpen,
                      imageTrough.vdo,
                    )}
                  >
                    <FaPlay style={{ fontSize: '0.8em' }} />
                  </IconButton>
                )}
              </ForecastContext.Consumer>
            </Tooltip>
          </Box>
          <Box p={0}>
            <Link to={`${initPath1}weather/trough/history`}>
              <Tooltip title={t('ดูภาพย้อนหลัง')}>
                <IconButton className={classes.buttonIcon}>
                  <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </Box>
        <Box display="flex" className={classes.boxBottomRoverRadius} alignItems="center" justifyContent="center">
          <Box>
            <Button
              onClick={
                () => this.setState({ isOpenImageTrough: true })
              }
            >
              <Img
                src={imageTrough.image}
                className={classes.responsiveHeight}
                alt="ภาพล่าสุด"
                width="100%"
                height="600px"
                unloader={(
                  <Img
                    src="https://via.placeholder.com/232x243?text=No%20Image"
                    alt="No Image"
                  />
                )}
              />
            </Button>
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.dateTime}>
                {`${t('ล่าสุด')} ${imageTrough.imgDate} ${t('เวลา')} : ${imageTrough.imgTime} ${t('น.')}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  render() {
    const { classes, theme, t } = this.props;
    const {
      isLoading,
      error,
      // photoIndex,
      photoIndexWmo,
      photoIndexAfwa,
      photoIndexWeatherMap,
      photoIndexDistributionMap,
      photoIndexWeatherLevelMap,
      photoIndexWeatherLevelMap2,
      photoIndexVegetation,

      // isOpen,
      isOpenWmo,
      isOpenAfwa,
      isOpenWeatherMap,
      isOpenDistributionMap,
      isOpenWeatherLevelMap,
      isOpenWeatherLevelMap2,
      isOpenVegetation,
      isOpenImageTrough,
      imageUpperWind,
      imageContour,
      imageSeaSurface,
      imageTrough,

      // imageNdvi,
      imageWeatherMapTmd,
      imageWind10m,
      animationWind10m,
      // displayState,
    } = this.state;
    // const images = [];
    const imagesAfwa = [];
    const imagesWmo = [];
    const imageWeatherMap = [];
    const imageDistributionMap = [];
    const imageWeatherLevelMap = [];
    const imageWeatherLevelMap2 = [];
    const imageVegetation = [];

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={70} />
          </div>
        )}

        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{ width: '100%' }}>
                <Box flexGrow={1} p={1} mt={3} display="flex" alignItems="center" className={classes.boxTopRadiusPrimary}>
                  <Box id="weather-map-anchor" p={1} className={classes.titleBar}>
                    {t('แผนที่อากาศ')}
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ width: '100%' }}>
              <Box flexGrow={1} p={0} my={1} display="flex" alignItems="center">
                <Box pt={2} style={{ color: 'gray' }}>
                  {t('แผนที่ลม')}
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid container spacing={4}>
            {imageWeatherMapTmd.map(element => {
              if (element.title.indexOf('ลม') !== -1) {
                imageWeatherLevelMap.push({
                  srcImage: `${element.srcImage}`,
                  title: `${t(element.title)}`,
                });
                return (
                  this.imageWeatherLevelBox(classes, element, imageWeatherLevelMap.map(
                    e => e.title,
                  ).indexOf(element.title))
                );
              }
              return null;
            })}
            {imageUpperWind.slice(2, 3).map((row, i) => {
              const mediaType = !!row.media_type.includes('Upper Wind');
              if (mediaType) {
                imageWeatherMap.push({
                  srcImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                  title: `${t(upperWindText[i])}`,
                });
                return (
                  this.renderImageUpperWind(classes, row, i)
                );
              }
              return null;
            })}
            {/* wind 10 m */}
            {imageWind10m.map(row => {
              imageWeatherMap.push({
                srcImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                title: `${t(upperWindText[1])}`,
              });
              const fileSplit = row.filepath.split('/');
              const stringDate = `${fileSplit[5]}-${fileSplit[6]}-${fileSplit[7]}`;
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} key={`upper_${row.media_type}`}>
                  <Box display="flex" className={classes.boxTopRadiusWeather} alignItems="center">
                    <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={'0.9em'} fontWeight={500}>
                      {t(upperWindText[1])}
                    </Box>
                    <Box p={1} style={{ borderRight: '1px solid #ccc', borderLeft: '1px solid #ccc' }}>
                      <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
                        <ForecastContext.Consumer>
                          {({ handleModalOpen }) => (
                            <IconButton
                              className={classes.icon}
                              onClick={this.handleModalAnimation(
                                handleModalOpen,
                                animationWind10m,
                              )}
                            >
                              <FaPlay style={{ fontSize: '0.8em' }} />
                            </IconButton>
                          )}
                        </ForecastContext.Consumer>
                      </Tooltip>
                    </Box>
                    <Box p={0}>
                      <Link to={`${initPath1}weather/wind/history`}>
                        <Tooltip title={t('ดูภาพย้อนหลัง')}>
                          <IconButton className={classes.buttonIcon}>
                            <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </Box>
                  </Box>
                  <Box display="flex" className={classes.boxBottomWindRadius} alignItems="center" justifyContent="center">
                    <Box>
                      <Button
                        onClick={
                          () => this.setState({ isOpenWeatherMap: true, photoIndexWeatherMap: 1 })
                        }
                      >
                        <Img
                          src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                          className={classes.responsive}
                          alt={row.media_type}
                          width="100%"
                          unloader={(
                            <Img
                              src="https://via.placeholder.com/204x375?text=No%20Image"
                              alt="No Image"
                            />
                          )}
                        />
                      </Button>
                      <Box p={1} display="flex" alignItems="center" justifyContent="center">
                        <Typography className={classes.dateTime}>
                          {`${t('ล่าสุด')} ${moment(stringDate).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.datetime).format('HH:mm')} ${t('น.')}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}

            {/* no image wind 10 m */}
            {imageWind10m.length <= 0 && (
              <Grid item xs={12} sm={6} md={3} lg={3} key="upper_wind10m">
                <Box display="flex" className={classes.boxTopRadiusWeather} alignItems="center">
                  <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14}>
                    {t(upperWindText[3])}
                  </Box>
                  <Box p={1} style={{ borderRight: '1px solid #ccc', borderLeft: '1px solid #ccc' }}>
                    <Tooltip title={t('ดูภาพเคลื่อนไหว')}>
                      <IconButton
                        className={classes.icon}
                      >
                        <FaPlay style={{ fontSize: '0.8em' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box p={0}>
                    <Link to={`${initPath1}weather/wind/history`}>
                      <Tooltip title={t('ดูภาพย้อนหลัง')}>
                        <IconButton className={classes.buttonIcon}>
                          <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Box>
                </Box>
                <Box display="flex" className={classes.boxBottomWindRadius} alignItems="center" justifyContent="center">
                  <Box>
                    <Img
                      src="https://via.placeholder.com/230x230?text=No%20Image"
                      alt="No Image"
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ width: '100%' }}>
              <Box flexGrow={1} p={0} my={1} display="flex" alignItems="center">
                <Box pt={2} style={{ color: 'gray' }}>
                  {t('แผนที่ความกดอากาศ')}
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid container spacing={4}>
            {imageWeatherMapTmd.map((element, index) => {
              if (element.title.indexOf('อากาศ') !== -1) {
                imageWeatherLevelMap2.push({
                  srcImage: `${element.srcImage}`,
                  title: `${t(element.title)}`,
                });
                return (
                  this.imageWeatherLevelBox2(classes, element, index)
                );
              }
              return null;
            })}

            {imageUpperWind.slice(1, 5).map((row, i) => {
              const mediaType = !!row.media_type.includes('Pressure');
              if (mediaType) {
                imageWeatherMap.push({
                  srcImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                  title: `${t(upperWindText[i])}`,
                });
                return (
                  this.renderImageUpperWind(classes, row, i)
                );
              }
              return null;
            })}

            {this.renderImageTrough(classes)}
          </Grid>
          <Grid id="temp-anchor" container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{ width: '100%' }}>
                <Box flexGrow={1} p={1} mt={3} display="flex" alignItems="center" className={classes.boxTopRadiusPrimary}>
                  <Box p={1} className={classes.titleBar}>
                    {t('แผนที่แสดงการกระจายตัว')}
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
            {imageContour.map((row, i) => {
              imageDistributionMap.push({
                srcImage: `${row.srcImage}`,
                title: `${row.title}`,
              });
              return (
                this.imageBox(classes, row, i)
              );
            })}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={3}>
              <b>ข้อมูลจากสถานีอัตโนมัติ (ปรับปรุงข้อมูลทุกชั่วโมง) ใช้วิธี Inverse Distance Weight</b><br />
              ด้วยความร่วมมือระหว่าง บริษัท แอดวานซ์ อินโฟร์ เซอร์วิส จำกัด (มหาชน) และ สถาบันสารสนเทศทรัพยากรน้ำ(องค์การมหาชน)
            </Box>
          </Grid>
        </div>

        <div className={classes.layout}>
          {/* ข้อมูลจากต้นทาง ไม่ udpate ปิดการแสดงผล */}
          {/* <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} mb={0} pb={0}>
              <Box flexGrow={1} p={1} mt={3} display="flex" alignItems="center" className={classes.boxTopRadiusPrimary}>
                <Box p={1} className={classes.titleBar}>
                  ค่าดัชนีพืชพรรณ | NDVI (NORMALIZED DIFFERENCE VEGETATION INDEX)
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container justify="flex-start" spacing={4}>
            {imageNdvi.map((row, i) => {
              imageVegetation.push({
                srcImage: `${row.srcImage}`,
                title: `${row.title}`,
              });
              return (
                this.imageVegetationBox(classes, row, i)
              );
            })}
          </Grid> */}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} mb={0}>
              <Box flexGrow={1} p={1} mt={3} display="flex" alignItems="center" className={classes.boxTopRadiusPrimary}>
                <Box p={1} className={classes.titleBar}>{t('ค่าความชื้นในดิน (Soil Moisture)')}</Box>
              </Box>
              <Grid container>
                {/* 2021-08-16 ข้อมูลจากต้นทาง ไม่ udpate ปิดการแสดงผล */}
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box p={1} display="flex" justifyContent="center" style={{ backgroundColor: grey[300], border: '1px solid #ccc' }}>AFWA</Box>
                  <Box
                    p={2}
                    borderLeft={{
                      xs: 0, sm: 0, md: 1, lg: 1,
                    }}
                    borderBottom={{
                      xs: 0, sm: 0, md: 1, lg: 1,
                    }}
                    style={{ border: '1px solid #ccc' }}
                  >
                    <Grid container direction="row" justify="space-evenly" spacing={4}>
                      {imageSeaSurface.slice(0, 2).map((row, i) => {
                        imagesAfwa.push({
                          srcImage: `${row.srcImage}`,
                          title: `AFWA ${row.title}`,
                        });
                        return (
                          this.imageBoxSoilMoisture(classes, row, i, 'afwa')
                        );
                      })}
                    </Grid>
                  </Box>
                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    p={1}
                    mt={{
                      xs: 2, sm: 2, md: 0, lg: 0,
                    }}
                    display="flex"
                    justifyContent="center"
                    style={{ backgroundColor: grey[300], border: '1px solid #ccc' }}
                  >
                    WMO
                  </Box>
                  <Box
                    p={2}
                    borderRight={{
                      xs: 0, sm: 0, md: 1, lg: 1,
                    }}
                    borderLeft={{
                      xs: 0, sm: 0, md: 1, lg: 1,
                    }}
                    borderBottom={{
                      xs: 0, sm: 0, md: 1, lg: 1,
                    }}
                    style={{ border: '1px solid #ccc' }}
                  >
                    <Grid container direction="row" justifyContent="space-evenly" spacing={4}>
                      {imageSeaSurface.slice(2, 4).map((row, i) => {
                        imagesWmo.push({
                          srcImage: `${row.srcImage}`,
                          title: `WMO ${row.title}`,
                        });
                        return (
                          this.imageBoxSoilMoisture(classes, row, i, 'wmo')
                        );
                      })}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* ภาพแผนที่ไอน้ำ */}
        {/* <SteamImage classes={classes} theme={theme} /> */}

        {isOpenWeatherMap && (
          <Lightbox
            mainSrc={imageWeatherMap[photoIndexWeatherMap].srcImage}
            nextSrc={imageWeatherMap[(photoIndexWeatherMap + 1) % imageWeatherMap.length].srcImage}
            prevSrc={
              imageWeatherMap[
                (photoIndexWeatherMap + imageWeatherMap.length - 1) % imageWeatherMap.length
              ].srcImage
            }
            imageTitle={t(imageWeatherMap[photoIndexWeatherMap].title)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenWeatherMap: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndexWeatherMap:
                  (photoIndexWeatherMap + imageWeatherMap.length - 1) % imageWeatherMap.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndexWeatherMap: (photoIndexWeatherMap + 1) % imageWeatherMap.length,
              }))}
          />
        )}

        {isOpenDistributionMap && (
          <Lightbox
            mainSrc={imageDistributionMap[photoIndexDistributionMap].srcImage}
            nextSrc={
              imageDistributionMap[
                (photoIndexDistributionMap + 1) % imageDistributionMap.length].srcImage
            }
            prevSrc={
              imageDistributionMap[
                (photoIndexDistributionMap
                  + imageDistributionMap.length - 1) % imageDistributionMap.length
              ].srcImage
            }
            imageTitle={t(imageDistributionMap[photoIndexDistributionMap].title)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenDistributionMap: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndexDistributionMap:
                  (
                    photoIndexDistributionMap + imageDistributionMap.length - 1
                  ) % imageDistributionMap.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndexDistributionMap:
                  (photoIndexDistributionMap + 1) % imageDistributionMap.length,
              }))}
          />
        )}

        {isOpenWeatherLevelMap && (
          <Lightbox
            mainSrc={imageWeatherLevelMap[photoIndexWeatherLevelMap].srcImage}
            nextSrc={
              imageWeatherLevelMap[
                (photoIndexWeatherLevelMap + 1) % imageWeatherLevelMap.length].srcImage
            }
            prevSrc={
              imageWeatherLevelMap[
                (photoIndexWeatherLevelMap
                  + imageWeatherLevelMap.length - 1) % imageWeatherLevelMap.length
              ].srcImage
            }
            imageTitle={t(imageWeatherLevelMap[photoIndexWeatherLevelMap].title)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenWeatherLevelMap: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndexWeatherLevelMap:
                  (
                    photoIndexWeatherLevelMap + imageWeatherLevelMap.length - 1
                  ) % imageWeatherLevelMap.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndexWeatherLevelMap:
                  (photoIndexWeatherLevelMap + 1) % imageWeatherLevelMap.length,
              }))}
          />
        )}

        {isOpenWeatherLevelMap2 && (
          <Lightbox
            mainSrc={imageWeatherLevelMap2[photoIndexWeatherLevelMap2].srcImage}
            nextSrc={
              imageWeatherLevelMap2[
                (photoIndexWeatherLevelMap2 + 1) % imageWeatherLevelMap2.length].srcImage
            }
            prevSrc={
              imageWeatherLevelMap2[
                (photoIndexWeatherLevelMap2
                  + imageWeatherLevelMap2.length - 1) % imageWeatherLevelMap2.length
              ].srcImage
            }
            imageTitle={t(imageWeatherLevelMap2[photoIndexWeatherLevelMap2].title)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenWeatherLevelMap2: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndexWeatherLevelMap2:
                  (
                    photoIndexWeatherLevelMap2 + imageWeatherLevelMap2.length - 1
                  ) % imageWeatherLevelMap2.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndexWeatherLevelMap2:
                  (photoIndexWeatherLevelMap2 + 1) % imageWeatherLevelMap2.length,
              }))}
          />
        )}
        {isOpenVegetation && (
          <Lightbox
            mainSrc={imageVegetation[photoIndexVegetation].srcImage}
            nextSrc={
              imageVegetation[
                (photoIndexVegetation + 1) % imageVegetation.length].srcImage
            }
            prevSrc={
              imageVegetation[
                (photoIndexVegetation + imageVegetation.length - 1) % imageVegetation.length
              ].srcImage
            }
            imageTitle={t(imageVegetation[photoIndexVegetation].title)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenVegetation: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndexVegetation:
                  (
                    photoIndexVegetation + imageVegetation.length - 1
                  ) % imageVegetation.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndexVegetation: (photoIndexVegetation + 1) % imageVegetation.length,
              }))}
          />
        )}

        {isOpenAfwa && (
          <Lightbox
            mainSrc={imagesAfwa[photoIndexAfwa].srcImage}
            nextSrc={imagesAfwa[(photoIndexAfwa + 1) % imagesAfwa.length].srcImage}
            prevSrc={imagesAfwa[(photoIndexAfwa
              + imagesAfwa.length - 1) % imagesAfwa.length].srcImage}
            imageTitle={t(imagesAfwa[photoIndexAfwa].title)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenAfwa: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndexAfwa: (photoIndexAfwa + imagesAfwa.length - 1) % imagesAfwa.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndexAfwa: (photoIndexAfwa + 1) % imagesAfwa.length,
              }))}
          />
        )}

        {isOpenWmo && (
          <Lightbox
            mainSrc={imagesWmo[photoIndexWmo].srcImage}
            nextSrc={imagesWmo[(photoIndexWmo + 1) % imagesWmo.length].srcImage}
            prevSrc={imagesWmo[(photoIndexWmo + imagesWmo.length - 1) % imagesWmo.length].srcImage}
            imageTitle={t(imagesWmo[photoIndexWmo].title)}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenWmo: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndexWmo: (photoIndexWmo + imagesWmo.length - 1) % imagesWmo.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndexWmo: (photoIndexWmo + 1) % imagesWmo.length,
              }))}
          />
        )}

        {isOpenImageTrough && (
          <Lightbox
            mainSrc={imageTrough.imageBig}
            imageTitle={t('แผนที่อากาศผิวพื้น และร่องความกดอากาศต่ำ')}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            imagePadding={50}
            onCloseRequest={() => this.setState({ isOpenImageTrough: false })}
          />
        )}
      </>
    );
  }
}

WeatherImageLatest.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(WeatherImageLatest);
