import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from '@material-ui/core/Link';
import Hidden from "@material-ui/core/Hidden";
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { popupSituation, apiKeyGoogle } from './path/drivepath';
import { processDataAndFilterLatest } from './utils/Utils'

import Img from "react-image";

import useStyles from "./style";
import WaterlevelTab from "../dashboard/WaterlevelTab";
import Lightbox from "react-image-lightbox";
import BoxHii from './BoxHii';
import BoxGistda from './BoxGistda';
import BoxNewsOperation from './BoxNewsOperation';
import BoxFondue from './BoxFondue';
import BoxMhesi from './BoxMhesi';

const initPath = process.env.MIX_APP_PATH;


export default function MhesiWarroom() {
    const classes = useStyles();
    const [modalImage, setModalImage] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const [time, setTime] = useState(new Date());
    const formatTime = `เวลา ${time
        .getHours()
        .toString()
        .padStart(2, "0")}:${time
            .getMinutes()
            .toString()
            .padStart(2, "0")} น.`;

    const formatCurrDate = `วันที่ ${time.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
    })}`;

    useEffect(() => {
        const timer = setInterval(() => setTime(new Date()), 60000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {

        const fetchFiles = async (id) => {
            try {
                const param = popupSituation.find(v => v.id === id);
                if (!param) return null;
                const url = `${process.env.MIX_APP_URL}proxy/api-mhesiwarroom.php?file=https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                // const url = `https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                return data;
            } catch (error) {
                console.error(`Error fetching files for id ${id}:`, error);
                return null;
            }
        };

        const processAndSetFiles = async () => {
            const data01 = await fetchFiles(1);

            if (data01.files.length > 0) {
                const sortedFiles01 = await processDataAndFilterLatest(data01);
                const firstArray = sortedFiles01[0];
                const urlPopup = `https://drive.google.com/thumbnail?sz=w1000&id=${firstArray.id}`
                console.log("urlPopup ", urlPopup);
                openModalImage(urlPopup);
            }
        };

        processAndSetFiles();
    }, []);

    const openModalImage = (url) => {
        setModalImage(url);
        setIsOpen(true);
    };

    return (
        <>
            <Box display="block">
                <Box container spacing={3} className={classes.navbar}>
                    <Box display="flex" className={classes.dateTime} alignItems="center">
                        <Box className={classes.dateBox} display="flex" alignItems="center">
                            <WatchLaterIcon style={{ marginRight: 10 }} />  {formatCurrDate}  {formatTime}
                        </Box>
                    </Box>
                </Box>

                <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    justifyContent="center"
                    className={classes.spacingHeader}
                >
                    <Grid item xs={12}>
                        <Box style={{ position: 'relative', width: '100%' }}>
                            <Img
                                src={`${initPath}images/mhesi_warroom/tab.png`}
                                width="100%"
                                height="50px"
                                style={{ position: 'relative' }}
                                alt="ติดตามสถานกาณณ์น้ำท่วม"
                            />
                            <Box className={classes.tabTitle} display="flex" justifyContent="space-between" alignItems="center">
                                <Box>สถานการณ์น้ำในพิ้นที่เฝ้าระวัง</Box>
                                <Hidden smDown><a href="https://www.thaiwater.net/" target="_blank" style={{ fontSize: '0.7em', color: 'white', marginRight: 120 }}>ดูข้อมูลเพิ่มเติม</a></Hidden>
                            </Box>
                            <Box style={{ position: 'absolute', right: -14, top: -7, display: 'flex' }}>
                                <a href="https://www.thaiwater.net/" className={classes.imgWrapper} target="_blank">
                                    <Img
                                        src={`${initPath}images/mhesi_warroom/tab-mobile-nhc.png`}
                                        width="100%"
                                        alt="คลังข้อมูลน้ำแห่งชาติ"
                                    />
                                </a>
                                <a href="https://www.hii.or.th/" className={classes.imgWrapper} target="_blank">
                                    <Img
                                        src={`${initPath}images/mhesi_warroom/tab-mobile-hii.png`}
                                        width="100%"
                                        alt="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)"
                                    />
                                </a>
                            </Box>
                        </Box>
                        <Hidden mdUp><a href="https://www.thaiwater.net/" title="หากต้องการดูข้อมูลสถานการณ์เพิ่มติม คลิ๊ก">เพิ่มเติม</a></Hidden>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    alignItems="start"
                    justifyContent="center"
                    style={{ marginBottom: 30 }}
                >
                    <Grid item xs={12}>
                        <WaterlevelTab warroom_tab={true} />
                    </Grid>
                </Grid>

                <BoxHii openModalImage={openModalImage} />

                <BoxGistda openModalImage={openModalImage} />

                <BoxFondue />

                <BoxMhesi />

                <BoxNewsOperation />

                <Box className={classes.footer}>
                    <Box className={classes.bgMobileApp}>
                        <Box className={classes.layout}>
                            <Grid
                                container
                                spacing={3}
                                justifyContent="center"
                            >
                                <Grid item xs={12} sm={6} md={3}>
                                    <Img
                                        src={`${initPath}images/mhesi_warroom/mobile-app.png`}
                                        width="100%"
                                        alt="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%" px={4}>
                                        <Box>
                                            <Box fontSize={32} fontWeight={600} color={'#3579d3'}>แอปพลิเคชัน Thaiwater</Box>
                                            <Box fontSize={18}>
                                                แอปพลิเคชัน ThaiWater ให้บริการข้อมูลเกี่ยวกับน้ำและอากาศของประเทศไทย ครอบคลุมข้อมูลตั้งแต่ฟ้าถึงทะเล
                                                มีทั้งข้อมูลพายุ ฝน ระดับน้ำ สถานการณ์เขื่อน และคลื่น ทั้งปัจจุบัน และคาดการณ์ล่วงหน้า
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Box display="flex" justifyContent="center" px={3}>
                                        <Box display="inline" p={1}>
                                            <a href="https://www.hii.or.th/" target="_blank" title="สถาบันสารสนเทศทรัพยากรน้ำ (องค์การมหาชน)">
                                                <img src={`${initPath}images/theme/banner/icon-app-thaiwater.png`} width="79" height="79"
                                                    alt="Thaiwater App" />
                                            </a>
                                        </Box>
                                        <Box p={1} display="flex" alignItems="center" align="center" fontSize={38} fontWeight="fontWeightMedium">
                                            <Box>
                                                {' '}
                                                {' "THAIWATER" '}
                                                {' '}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box display="flex" p={1} fontSize={24} fontWeight={500} align="center" justifyContent="center">
                                        Support All Devices Anywhere, Anytime
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                        <Box display="flex" justifyContent="center" fontSize={18}>
                                            ยอดดาวน์โหลด
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" width="100%">
                                        <Box display="block" p={1} align="center" fontWeight={400}>
                                            <Box fontWeight={400}>122,500 +</Box>
                                            <Link href="https://apps.apple.com/th/app/thaiwater/id1097487200?l=th" target="_blank"
                                                rel="noopener noreferrer">
                                            <img src={`${initPath}images/theme/banner/banner-appstore.png`} width="140" height="44"
                                                alt="Thaiwater App Store" />
                                            </Link>
                                        </Box>
                                        <Box display="block" p={1} align="center" fontWeight={400}>
                                            <Box fontWeight={400}>150,600 +</Box>
                                            <Link href="https://play.google.com/store/apps/details?id=mobile.nhc.thaiwater&hl=th" target="_blank"
                                                rel="noopener noreferrer">
                                            <img src={`${initPath}images/theme/banner/banner-googleplay.png`} width="140" height="43"
                                                alt="Thaiwater Google Play" />
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box className={classes.address}>
                        <Box className={classes.layout}>
                            <Grid
                                container
                                spacing={3}
                                justifyContent="center"

                            >
                                <Grid item xs="auto">
                                    <Img
                                        src={`${initPath}images/mhesi_warroom/logo-mhesi.png`}
                                        className={classes.logoFooter}
                                        alt="Logo"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Box style={{ fontSize: "1.2em", fontWeight: 600 }}>
                                        กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม
                                    </Box>
                                    <Box style={{ fontSize: "1.2em", fontWeight: 600 }}>
                                        ถนนโยธี (อาคารพระจอมเกล้า)
                                    </Box>
                                    <Box mb={3} style={{ fontSize: "1em" }}>
                                        75/47 อาคารพระจอมเกล้า ถ.พระราม 6 แขวงทุ่งพญาไท เขตราชเทวี
                                        กรุงเทพฯ 10400
                                    </Box>
                                    <Box style={{ fontSize: "1em" }}>โทรศัพท์ : 02 333 3700</Box>
                                    <Box style={{ fontSize: "1em" }}>แฟกซ์ : 02 333 3833</Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box className={classes.copyright}>
                        <Box className={classes.layout}>
                            <Grid
                                container
                                spacing={3}
                                justifyContent="center"
                            >
                                <Grid item xs>
                                    <Box>
                                        Copyright © 2024 Power by National Hydroinformatics Data Center,
                                        All rights reserved.  &nbsp;
                                        {/* <Link
                                            href="https://drive.google.com/drive/folders/1phs6A8A1B3aveZwjWwsE5BT0jDS9JotR"
                                            target="_blank"
                                        >
                                            User Guide
                                        </Link> */}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <a href={"tel:1313"}>
                    <Img
                        src={`${initPath}images/mhesi_warroom/hotline-mhesi-anima.gif`}
                        className={classes.hotline}
                        alt="สายด่วน อว. 1313"
                    />
                </a>
            </Box>
            {isOpen ? (
                <Lightbox
                    mainSrc={modalImage}
                    onCloseRequest={() => setIsOpen(false)}
                    imagePadding={50}
                    reactModalStyle={{ overlay: { zIndex: "9999999" } }}
                />
            ) : null}
        </>
    );
}
