import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy as orderByLodash } from 'lodash';

// style, icons
import { withStyles } from '@material-ui/core/styles';

// ui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { FaChartLine } from '../../utils/Fontawesome';
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';
import tJson from '../../utils/TJson';

import {
  styles, StyledTableRow,
} from '../../styles/Style';

// dam
import DamContext from '../../contexts/damContext';
import DamStatusColor from './DamStatusColor';
import DamMediumChart from './DamMediumChart';

const tableSummaryColor = '#eee';

// table Header
const columnData = [
  {
    id: 'dam.dam_name.th', numeric: false, disablePadding: false, label: 'อ่างเก็บน้ำ', sortable: true,
  },
  {
    id: 'geocode.province_name.th', numeric: false, disablePadding: false, label: 'จังหวัด', sortable: true,
  },
  {
    id: 'dam.normal_storage', align: 'right', numeric: true, disablePadding: false, label: 'กักเก็บปกติ', sortable: true,
  },
  {
    id: 'dam_storage_percent', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาณน้ำ', sortable: true,
  },
  {
    id: 'dam_inflow', align: 'right', numeric: true, disablePadding: false, label: 'น้ำไหลลง', sortable: true,
  },
  {
    id: 'dam_released', align: 'right', numeric: true, disablePadding: false, label: 'น้ำระบาย', sortable: true,
  },
  {
    id: 'chart', numeric: false, disablePadding: false, label: '',
  },
];

class DamMediumList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 10,

      // table sorting
      order: 'desc',
      orderBy: 'dam_storage_percent',
    };
    this.handleChart = this.handleChart.bind(this);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChart = (handler, data) => e => {
    e.preventDefault();

    const { damsMediumRidList, t } = this.props;
    const header = t('กราฟอ่างเก็บน้ำขนาดกลางรายปี กรมชลประทาน');

    const params = {
      header,
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamMediumChart id={data.dam.id} damsMediumRid={damsMediumRidList} t={t()} />,
    };

    handler(params);
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderBy === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderBy: orderByProperty });
  };

  damStatus = (data, percent) => {
    const status = DamStatusColor(parseFloat(percent).toFixed(0));
    let statusText = '';
    if (data) {
      statusText = `${data.toFixed(2)} (<span style='color:${status.color.statusColor}'><strong>${percent}%</strong></span>)`;
    }

    return statusText;
  }

  render() {
    const { classes, datas, t } = this.props;
    const {
      rowsPerPage, page, order, orderBy,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, datas.length - page * rowsPerPage);

    // summary
    let totalDamStorage = 0;
    let totalNormalStorage = 0;
    let totalReleased = 0;
    let totalInflow = 0;

    datas.forEach(n => {
      totalDamStorage += n.dam_storage;
      totalNormalStorage += n.dam.normal_storage;
      totalReleased += n.dam_released;
      totalInflow += n.dam_inflow;
    });

    return (
      <>
        <TableContainer>
          <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
            <TableHeaderEnhance
              columnData={columnData}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              firstColumnSticky
            />
            <TableBody>
              {orderByLodash(datas, orderBy, order)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  const { handleSearchMarker } = this.props;
                  const storageStatus = this.damStatus(
                    row.dam_storage,
                    parseFloat(row.dam_storage_percent).toFixed(0),
                  );

                  let mapLink = null;
                  if (handleSearchMarker && row.dam.dam_lat && row.dam.dam_long) {
                    mapLink = (
                      <Button
                        onClick={handleSearchMarker(row.id, row.dam.dam_lat, row.dam.dam_long)}
                        title={t('แสดงตำแหน่งบนแผนที่')}
                        style={{ textAlign: 'left', paddingLeft: 5, border: '1px solid #ccc' }}
                      >
                        {/* {row.dam.dam_name.th} */}
                        {`${tJson(row.dam.dam_name, ' ')}`}
                      </Button>
                    );
                  } else {
                    mapLink = (
                      <Button
                        disabled
                        title={t('ไม่มีข้อมูลพิกัด')}
                        style={{ textAlign: 'left', paddingLeft: 5 }}
                      >
                        {`${tJson(row.dam.dam_name, ' ')}`}
                        {/* {`${tJson(row.dam.dam_name, 'อ่างเก็บน้ำ')}`} */}
                      </Button>
                    );
                  }

                  return (
                    <StyledTableRow key={row.id}>
                      <TableCell component="th" scope="row" className="first-column-sticky">
                        {mapLink}
                      </TableCell>
                      <TableCell>{tJson(row.geocode.province_name, 'จ.')}</TableCell>
                      <TableCell align="right">{row.dam.normal_storage}</TableCell>
                      <TableCell align="right" dangerouslySetInnerHTML={{ __html: storageStatus }} />
                      <TableCell align="right">{row.dam_inflow}</TableCell>
                      <TableCell align="right">{row.dam_released}</TableCell>
                      <TableCell>
                        <DamContext.Consumer>
                          {({ handleModalOpen }) => (
                            <Tooltip title={t('แสดงกราฟ')}>
                              <IconButton className={classes.button} color="secondary" aria-label="Chart" onClick={this.handleChart(handleModalOpen, row)}>
                                <FaChartLine fontSize="small" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </DamContext.Consumer>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              {datas.length === 0 && (
                <StyledTableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={7} align="center">
                    {t('ไม่มีข้อมูลอ่างขนาดกลางตามเงื่อนไข')}
                  </TableCell>
                </StyledTableRow>
              )}

              <StyledTableRow style={{ color: '#FFF', backgroundColor: tableSummaryColor }}>
                <TableCell component="th" scope="row" align="left" className="first-column-sticky-total">
                  <strong>
                    {`${t('รวม')} ${datas.length} ${t('อ่าง')}`}
                  </strong>
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  <strong>
                    {totalNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {totalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    <span
                      style={{
                        color: DamStatusColor(
                          parseFloat(totalNormalStorage).toFixed(0),
                        ).color.statusColor,
                      }}
                    >
                      {!Number.isNaN((totalDamStorage * 100) / totalNormalStorage) && (
                        <span>
                          {' ('}
                          {((totalDamStorage * 100) / totalNormalStorage).toFixed(0)}
                          {'%)'}
                        </span>
                      )}
                    </span>
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {(totalInflow) ? totalInflow.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {(totalReleased) ? totalReleased.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                  </strong>
                </TableCell>
                <TableCell />
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          className={classes.hiddenLine}
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActionsWrapped}
          labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
          labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to} ${t('จาก')} ${count} ${t('รายการ')}`}
        />
      </>
    );
  }
}

DamMediumList.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  handleSearchMarker: PropTypes.func.isRequired,
  damsMediumRidList: PropTypes.array.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles), withTranslation())(DamMediumList);
