import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import {
  styles, lineBox,
} from '../../styles/Style';

const TabContainer = ({ children }) => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class PmLegend extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];

    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleSetMap = this.handleSetMap.bind(this);
  }

    handleModalClose = () => {
      this.setState({ open: false });
    };

  handleModalOpen = params => {
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  render() {
    const { classes, t, i18n } = this.props;
    return (
      <Box display="flex" width="100%" justifyContent="center" alignContent="center">
        <Box m={0} pb={0} borderTop={0} border={1} borderRight={0} borderColor={lineBox} display="inline" width="20%">
          <Box display="flex" borderTop={1} borderColor={lineBox}>
            <Box display="inline" style={{ backgroundColor: '#69C8FA' }} width="100%" py={0}>
              <div className={classes.regend}>
                {'0 - 25 '}
              </div>
            </Box>
          </Box>
          <Box display="block" pb={-3} borderColor={lineBox} aling="center">
            <div className={classes.regend}>
              {t('ดีมาก')}
            </div>
          </Box>
        </Box>
        <Box m={0} pb={0} borderTop={0} border={1} borderRight={0} borderColor={lineBox} display="inline" width="20%">
          <Box display="flex" borderTop={1} borderColor={lineBox}>
            <Box display="inline" style={{ backgroundColor: '#9ECC63' }} width="100%" py={0}>
              <div className={classes.regend}>
                {'26 - 50 '}
              </div>
            </Box>
          </Box>
          <Box display="block" pb={-3} borderColor={lineBox} aling="center">
            <div className={classes.regend}>
              {t('ดี')}
            </div>
          </Box>
        </Box>
        <Box m={0} pb={0} borderTop={0} border={1} borderRight={0} borderColor={lineBox} display="inline" width="20%">
          <Box display="flex" borderTop={1} borderColor={lineBox}>
            <Box display="inline" style={{ backgroundColor: '#FFFD54' }} width="100%" py={0}>
              <div className={classes.regend}>
                {'51 - 100'}
              </div>
            </Box>
          </Box>
          <Box display="block" pb={-3} borderColor={lineBox} aling="center">
            <div className={classes.regend}>
              {t('ปานกลาง')}
            </div>
          </Box>
        </Box>
        <Box m={0} pb={0} borderTop={0} border={1} borderRight={0} borderColor={lineBox} display="inline" width="20%">
          <Box display="flex" borderTop={1} borderColor={lineBox}>
            <Box display="inline" style={{ backgroundColor: '#F2A43C' }} width="100%" py={0}>
              <div className={classes.regend}>
                {'101 - 200 '}
              </div>
            </Box>
          </Box>
          <Box display="block" pb={-3} borderColor={lineBox} aling="center">
            <div className={classes.regend}>
              {t('เริ่มมีผลต่อสุขภาพ')}
            </div>
          </Box>
        </Box>
        <Box m={0} pb={0} borderTop={0} border={1} borderColor={lineBox} display="inline" width="20%">
          <Box display="flex" borderTop={1} borderColor={lineBox}>
            <Box display="inline" style={{ backgroundColor: '#DD554D' }} width="100%" py={0}>
              <div className={classes.regend}>
                {'>200 '}
              </div>
            </Box>
          </Box>
          <Box display="block" pb={-3} borderColor={lineBox} aling="center">
            <div className={classes.regend}>
              {t('เริ่มมีผลกระทบต่อสุขภาพ')}
            </div>
          </Box>
        </Box>
      </Box>
    );
  }
}

PmLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(PmLegend);
