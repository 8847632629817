import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import styles from './Style';

const useStyles = makeStyles(theme => ({
  root: {
    height: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  formControl: {
    width: '100%',
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

export default function RainForecastMonthNavigationSwitch(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState('volume');

  function handleChangeType(event) {
    setValue(event.target.value);
    props.handler(event.target.value);
  }

  return (
    <>
      <Paper style={styles.menuControlStyle}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="forecast_type"
            name="ForecastType"
            className={classes.group}
            value={value}
            onChange={handleChangeType}
          >
            <FormControlLabel
              key="volume"
              value="volume"
              control={<Radio />}
              label={t('คาดการณ์ฝน')}
            />
            {/* <FormControlLabel
              key="level"
              value="level"
              control={<Radio />}
              label="ระดับ"
            /> */}
            <FormControlLabel
              key="anomaly"
              value="anomaly"
              control={<Radio />}
              label={t('ความผิดปกติของปริมาณฝน')}
            />
          </RadioGroup>
        </FormControl>
      </Paper>
    </>
  );
}

RainForecastMonthNavigationSwitch.propTypes = {
  handler: PropTypes.func.isRequired,
};
