/**
 * *
 * @version 1.0.0
 * @author [Phithakkhet]
 * Modify by Thitiporn
 */
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Img from 'react-image';
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
// color .
// import grey from '@material-ui/core/colors/grey';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Hidden } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
// import LegendTableCell from '@material-ui/core/LegendTableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// icon
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  FaPlay,
  FaCalendarAlt,
  FaSpinner,
} from '../../utils/Fontawesome';
import { HiiStormIcon } from '../../utils/Icon';

// credit tooltip
import CreditTooltip from '../../utils/CreditTooltip';

import {
  styles, LegendTableCell,
} from '../../styles/Style';

import SidebarWeather from '../navigation/SidebarWeather';
import ForecastContext from '../../contexts/forecastContext';
import Animation from '../forecast/Animation';

import imageSource from '../../../../public/json/contents/image_source.json';

const custom = {
  fix: {
    fontSize: 50,
  },
};

const initPath = process.env.MIX_APP_PATH;

class WeatherStorm extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      isLoading: false, // loading status
      error: null, // error
      dataStormIndia: null, // data from services
      dataStormPacific: null, // data from services
      isOpen: false, // modal
      // cloudDigitalTyphoon: null,
      cloudKochi: null,
      animationCloud: null,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
      open: false,
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
  }

  getModalStyle = () => {
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: 'fit-content',
      height: 'fit-content',
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  getData() {
    let imagePath = '';
    const hostImage = `${process.env.MIX_API_URL}/shared/image?_csrf=&image=`;
    const imageNull = 'https://via.placeholder.com/322x230?text=No%20Image&italic=true';
    let image = [];
    let imageThumbnail = '';

    // get data from service storm india
    fetch(`${process.env.MIX_API_URL}public/weather_img/india_ocean_ucl`)
      .then(response => {
        if (response.ok) {
          this.setState({ isLoading: true });
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        imagePath = hostImage + result.data.cover_image.media_path;
        this.setState({ dataStormIndia: imagePath, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get data from service storm pacific
    fetch(`${process.env.MIX_API_URL}public/weather_img/pacific_ocean_ucl`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        imagePath = hostImage + result.data.cover_image.media_path;
        this.setState({ dataStormPacific: imagePath, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // ภาพเมฆ Digital Typhoon
    // fetch(`${process.env.MIX_API_URL}public/weather_img/cloud_digital_typhoon`)
    //   .then(response => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error('Something went wrong ...');
    //   })
    //   .then(result => {
    //     if (result.data.cover_image.media_path) {
    //       imagePath = hostImage + result.data.cover_image.media_path;
    //     } else {
    //       imagePath = imageNull;
    //     }
    //     if (result.data.cover_image.thumbnail_media_path) {
    //       imageThumbnail = hostImage + result.data.cover_image.thumbnail_media_path;
    //     } else {
    //       imageThumbnail = imageNull;
    //     }
    //     image = {
    //       src: imageThumbnail,
    //       srcImage: imagePath,
    //       title: result.data.description.description_name.th,
    //       subtitle: result.data.agency.agency_name.th,
    //       imagetype: result.data.cover_image.filename,
    //       media_datetime: result.data.cover_image.media_datetime,
    //     };
    //     this.setState({
    //       cloudDigitalTyphoon: image,
    //     });
    //   })
    //   .catch(error => this.setState({ error, isLoading: false }));

    // ภาพถ่ายล่าสุดจากดาวเทียม Himawari-8 - kochi
    fetch(`${process.env.MIX_API_URL}public/weather_img/cloud`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // if kochi has no image, use digital typhoon instead
        if (result.data.kochi) {
          if (result.data.kochi[0].media_path) {
            imagePath = hostImage + result.data.kochi[0].media_path;
          } else {
            imagePath = imageNull;
          }
          if (result.data.kochi[0].media_path_thumb) {
            imageThumbnail = hostImage + result.data.kochi[0].media_path_thumb;
          } else {
            imageThumbnail = imageNull;
          }
          image = {
            src: imageThumbnail,
            srcImage: imagePath,
            title: 'ภาพถ่ายล่าสุดจากดาวเทียม Himawari-8',
            subtitle: 'จัดทำโดย : Kochi',
            url: 'http://weather.is.kochi-u.ac.jp/index-e.html',
            imagetype: result.data.kochi[0].filename,
            media_datetime: result.data.kochi[0].media_datetime,
          };
          this.setState({
            cloudKochi: image,
          });
        } else if (result.data.typhoon) {
          if (result.data.typhoon[0].media_path) {
            imagePath = hostImage + result.data.typhoon[0].media_path;
          } else {
            imagePath = imageNull;
          }
          if (result.data.typhoon[0].media_path_thumb) {
            imageThumbnail = hostImage + result.data.typhoon[0].media_path_thumb;
          } else {
            imageThumbnail = imageNull;
          }
          image = {
            src: imageThumbnail,
            srcImage: imagePath,
            title: 'ภาพถ่ายล่าสุดจากดาวเทียม Himawari-8',
            subtitle: 'จัดทำโดย : Digital Typhoon',
            url: 'http://agora.ex.nii.ac.jp/digital-typhoon/index.html.en',
            imagetype: result.data.typhoon[0].filename,
            media_datetime: result.data.typhoon[0].media_datetime,
          };

          this.setState({
            cloudKochi: image,
          });
        } else {
          image = {
            src: 'https://via.placeholder.com/513x334.png?text=Communication+Interruption',
            srcImage: 'https://via.placeholder.com/513x334.png?text=Communication+Interruption',
            title: 'ภาพถ่ายล่าสุดจากดาวเทียม Himawari-8',
            subtitle: '',
            url: '',
            imagetype: 'latest.jpg',
            media_datetime: '',
          };

          this.setState({
            cloudKochi: image,
          });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));

    // get antimation image from service storm india
    fetch(`${process.env.MIX_API_URL}public/weather_img/animation?media_type_id=141&agency_id=50`)
      .then(response => {
        if (response.ok) {
          this.setState({ isLoading: true });
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        imagePath = result.data.media_path;
        this.setState({ animationCloud: imagePath, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleModalAnimation = (handler, data) => e => {
    e.preventDefault();

    const params = {
      content: <Animation media={data} />,
    };

    handler(params);
  }

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, t } = this.props;
    const {
      photoIndex,
      isOpen, dataStormIndia, dataStormPacific, isLoading, error, cloudKochi,
      open,
      animationCloud,
      modalHeader,
      modalSubHeader,
      modalContent,
    } = this.state;

    // push popup images
    const arrayImage = [];
    arrayImage.push(dataStormIndia, dataStormPacific);
    if (cloudKochi) {
      arrayImage.push(cloudKochi.srcImage);
    } else {
      arrayImage.push('https://via.placeholder.com/322x230?text=No%20Image&italic=true');
    }

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={30} />
          </div>
        )}

        <ForecastContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          {/* Sidebar */}
          <SidebarWeather />

          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs>
                <Box mt={2} display="flex">
                  <Typography variant="h6" gutterBottom>
                    {t('ติดตามสภาพอากาศ')}
                  </Typography>
                </Box>
                <Divider />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" p={1} alignItems="center">
                    <HiiStormIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} alignItems="center">
                    <Typography variant="h4">
                      {t('พายุ')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs>
                <div style={{ width: '100%' }}>
                  <Box flexGrow={1} p={1} display="flex" alignItems="center" className={classes.boxTopRadiusPrimary}>
                    <Box className={classes.titleBar}>
                      {t('แผนที่วิเคราะห์เส้นทางและความแรงของพายุ')}
                    </Box>
                  </Box>
                  <Box
                    p={2}
                    display="block"
                    className={classes.boxBottomRadiusNoPadding}
                    alignItems="center"
                  >
                    <Box display="flex" className={classes.boxRadiusSecondary}>
                      <Box flexGrow={1} p={1} display="flex" alignItems="center">
                        <Box p={1} className={classes.titleName}>
                          {t('เส้นทางพายุ')}
                        </Box>
                      </Box>
                      <Box
                        p={1}
                        style={{
                          borderLeft: '1px solid #ccc',
                          backgroundColor: '#fff',
                          borderBottomRightRadius: 6,
                          borderTopRightRadius: 6,
                        }}
                      >
                        <Tooltip title={t("ดูข้อมูลย้อนหลัง")}>
                          <Button
                            href={`${initPath}weather/${moment().format('YYYY-MM-DD')}/41/62`}
                            size="small"
                            className={classes.outlinedOldPrimary}
                          >
                            <FaCalendarAlt />
                            <Hidden xsDown>
                              <Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>
                                {t('ดูข้อมูลย้อนหลัง')}
                              </Typography>
                            </Hidden>
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box mt={3}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          {dataStormIndia && (
                            <CreditTooltip
                              title={imageSource[0].storm.title}
                              link={imageSource[0].storm.url}
                            >
                              <Button
                                onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}
                              >
                                {/* <img src={dataStormIndia} alt="มหาสมุทรอินเดีย" className={classes.responsive} /> */}
                                <Img
                                  src={dataStormIndia}
                                  alt={t("มหาสมุทรอินเดีย")}
                                  unloader={(
                                    <Img
                                      src="https://via.placeholder.com/600x300?text=No%20Image"
                                      alt="No Image"
                                      className={classes.responsive}
                                    />
                                  )}
                                  className={classes.responsive}
                                />
                              </Button>
                            </CreditTooltip>

                          )}
                          <Box p={1} display="flex" alignItems="center" justifyContent="center">
                            <Typography className={classes.dateTime}>
                              {t('มหาสมุทรอินเดีย')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          {dataStormPacific && (
                            <CreditTooltip
                              title={imageSource[0].storm.title}
                              link={imageSource[0].storm.url}
                              top="-50"
                            >
                              <Button onClick={() => this.setState({ isOpen: true, photoIndex: 1 })}>
                                {/* <img src={dataStormPacific} alt="มหาสมุทรแปซิฟิค" className={classes.responsive} /> */}
                                <Img
                                  src={dataStormPacific}
                                  alt={t("มหาสมุทรแปซิฟิค")}
                                  unloader={(
                                    <Img
                                      src="https://via.placeholder.com/600x300?text=No%20Image"
                                      alt="No Image"
                                      className={classes.responsive}
                                    />
                                  )}
                                  className={classes.responsive}
                                />
                              </Button>
                            </CreditTooltip>
                          )}
                          <Box p={1} display="flex" alignItems="center" justifyContent="center">
                            <Typography className={classes.dateTime}>
                              {t('มหาสมุทรแปซิฟิค')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={3}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <TableContainer>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <LegendTableCell colSpan={5}>
                                    {t('ข้อมูลเกณฑ์พายุ')}  :  Tropical Cyclone Windspeed Scale
                                  </LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell colSpan={2}> </LegendTableCell>
                                  <LegendTableCell colSpan={3}>
                                    1 Minute Maximum Sustained Winds
                                  </LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell scope="col">Strength</LegendTableCell>
                                  <LegendTableCell scope="col">Category</LegendTableCell>
                                  <LegendTableCell scope="col">knots</LegendTableCell>
                                  <LegendTableCell scope="col">mph</LegendTableCell>
                                  <LegendTableCell scope="col">km/h</LegendTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <LegendTableCell align="left">Tropical Depression</LegendTableCell>
                                  <LegendTableCell bgcolor="#00CCFF">TD</LegendTableCell>
                                  <LegendTableCell>&lt;34</LegendTableCell>
                                  <LegendTableCell>&lt;39</LegendTableCell>
                                  <LegendTableCell>&lt;63</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Tropical Storm</LegendTableCell>
                                  <LegendTableCell bgcolor="#00FF00">TS</LegendTableCell>
                                  <LegendTableCell>34-63</LegendTableCell>
                                  <LegendTableCell>39-73</LegendTableCell>
                                  <LegendTableCell>63-118</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Severe Cyclonic Storm Cat 1</LegendTableCell>
                                  <LegendTableCell bgcolor="#FFFF00">Cat 1</LegendTableCell>
                                  <LegendTableCell>64-82</LegendTableCell>
                                  <LegendTableCell>74-95</LegendTableCell>
                                  <LegendTableCell>119-153</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Severe Cyclonic Storm Cat 2</LegendTableCell>
                                  <LegendTableCell bgcolor="#FFCC00">Cat 2</LegendTableCell>
                                  <LegendTableCell>83-95</LegendTableCell>
                                  <LegendTableCell>96-110</LegendTableCell>
                                  <LegendTableCell>154-177</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Severe Cyclonic Storm Cat 3</LegendTableCell>
                                  <LegendTableCell bgcolor="#FF6600">Cat 3</LegendTableCell>
                                  <LegendTableCell>96-113</LegendTableCell>
                                  <LegendTableCell>111-130</LegendTableCell>
                                  <LegendTableCell>178-210</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Super Cyclonic Storm Cat 4</LegendTableCell>
                                  <LegendTableCell bgcolor="#FF0000">Cat 4</LegendTableCell>
                                  <LegendTableCell>114-135</LegendTableCell>
                                  <LegendTableCell>131-155</LegendTableCell>
                                  <LegendTableCell>211-250</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Super Cyclonic Storm Cat 5</LegendTableCell>
                                  <LegendTableCell bgcolor="#CC00CC">Cat 5</LegendTableCell>
                                  <LegendTableCell>&gt;135</LegendTableCell>
                                  <LegendTableCell>&gt;155</LegendTableCell>
                                  <LegendTableCell>&gt;250</LegendTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <TableContainer>
                            <Table className={classes.table} size="small" align="center" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <LegendTableCell colSpan={5}>
                                    {t('ข้อมูลเกณฑ์พายุ')}  :  Tropical Typhoon Windspeed Scale
                                  </LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell colSpan={2}> </LegendTableCell>
                                  <LegendTableCell colSpan={3}>
                                    1 Minute Maximum Sustained Winds
                                  </LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell scope="col">Strength</LegendTableCell>
                                  <LegendTableCell scope="col">Category</LegendTableCell>
                                  <LegendTableCell scope="col">knots</LegendTableCell>
                                  <LegendTableCell scope="col">mph</LegendTableCell>
                                  <LegendTableCell scope="col">km/h</LegendTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <LegendTableCell align="left">Tropical Depression</LegendTableCell>
                                  <LegendTableCell bgcolor="#00CCFF">TD</LegendTableCell>
                                  <LegendTableCell>&lt;34</LegendTableCell>
                                  <LegendTableCell>&lt;39</LegendTableCell>
                                  <LegendTableCell>&lt;63</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Tropical Storm</LegendTableCell>
                                  <LegendTableCell bgcolor="#00FF00">TS</LegendTableCell>
                                  <LegendTableCell>34-63</LegendTableCell>
                                  <LegendTableCell>39-73</LegendTableCell>
                                  <LegendTableCell>63-118</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Typhoon Cat 1</LegendTableCell>
                                  <LegendTableCell bgcolor="#FFFF00"> Cat 1</LegendTableCell>
                                  <LegendTableCell>64-82</LegendTableCell>
                                  <LegendTableCell>74-95</LegendTableCell>
                                  <LegendTableCell>119-153</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Typhoon Cat 2</LegendTableCell>
                                  <LegendTableCell bgcolor="#FFCC00">Cat 2</LegendTableCell>
                                  <LegendTableCell>83-95</LegendTableCell>
                                  <LegendTableCell>96-110</LegendTableCell>
                                  <LegendTableCell>154-177</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Typhoon Cat 3</LegendTableCell>
                                  <LegendTableCell bgcolor="#FF6600">Cat 3</LegendTableCell>
                                  <LegendTableCell>96-113</LegendTableCell>
                                  <LegendTableCell>111-130</LegendTableCell>
                                  <LegendTableCell>178-210</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Typhoon Cat 4</LegendTableCell>
                                  <LegendTableCell bgcolor="#FF0000">Cat 4</LegendTableCell>
                                  <LegendTableCell>114-135</LegendTableCell>
                                  <LegendTableCell>131-155</LegendTableCell>
                                  <LegendTableCell>211-250</LegendTableCell>
                                </TableRow>
                                <TableRow>
                                  <LegendTableCell align="left">Super Typhoon Cat 5</LegendTableCell>
                                  <LegendTableCell bgcolor="#CC00CC">Cat 5</LegendTableCell>
                                  <LegendTableCell>&gt;135</LegendTableCell>
                                  <LegendTableCell>&gt;155</LegendTableCell>
                                  <LegendTableCell>&gt;250</LegendTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box display="flex" className={classes.boxTopRadius}>
                            <Box flexGrow={1} p={1} display="flex" alignItems="center">
                              <Box p={1} className={classes.titleName}>
                                {t('ภาพเมฆ')}
                              </Box>
                            </Box>
                            <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                              <Tooltip title={t("ดูภาพเคลื่อนไหว")}>
                                <ForecastContext.Consumer>
                                  {({ handleModalOpen }) => (
                                    <Tooltip title={t("ดูภาพเคลื่อนไหว")}>
                                      <Button
                                        className={classes.outlinedOldLeftPrimary}
                                        href={`${initPath}weather/${moment().format('YYYY-MM-DD')}/50/141`}
                                        onClick={this.handleModalAnimation(
                                          handleModalOpen,
                                          animationCloud,
                                        )}
                                      >
                                        <FaPlay style={{ fontSize: '1em' }} />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </ForecastContext.Consumer>
                              </Tooltip>
                            </Box>
                            <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                              <Tooltip title={t("ดูข้อมูลย้อนหลัง")}>
                                <Button
                                  className={classes.outlinedOldPrimary}
                                  href={`${initPath}weather/${moment().format('YYYY-MM-DD')}/52/141`}
                                >
                                  <FaCalendarAlt style={{ fontSize: '1.2em' }} />
                                </Button>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display="block" alignItems="center" justifyContent="center" className={classes.boxStormBottomRadius}>
                            <Box>
                              {cloudKochi && (
                                <Button
                                  onClick={
                                    () => this.setState({ isOpen: true, photoIndex: 2 })
                                  }
                                >
                                  <CreditTooltip
                                    title={cloudKochi.subtitle}
                                    link={cloudKochi.url}
                                  >
                                    <img
                                      src={cloudKochi.srcImage}
                                      width="80%"
                                      height="100%"
                                      alt={t("ภาพถ่ายล่าสุด จากดาวเทียม Himawari-8")}
                                      className={classes.responsive}
                                    />
                                  </CreditTooltip>
                                </Button>
                              )}
                              <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                <Typography className={classes.dateTime}>
                                  {t('ภาพถ่ายล่าสุด จากดาวเทียม Himawari-8')}
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box
                              pl={2}
                              width="50%"
                              className={classes.titleSub}
                              align="center"
                            >
                              { cloudDigitalTyphoon && (
                              <Button
                                onClick={
                                  () => this.setState({ isOpen: true, photoIndex: 3 })
                                }
                              >
                                <img
                                  src={cloudDigitalTyphoon.srcImage}
                                  className={classes.responsive}
                                  alt="thaiwater"
                                />
                              </Button>
                              )}
                              ภาพเมฆที่ปกคลุมบริเวณประเทศไทย ภาพถ่ายล่าสุด จากดาวเทียม
                            </Box> */}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box display="block">
                            <Box flexGrow={1} p={1} className={classes.boxTopRadiusPrimary}>
                              <Box p={1} className={classes.titleBar}>
                                {t('ภาพพายุหมุนในอดีต')}
                              </Box>
                            </Box>
                          </Box>
                          <Box p={2} display="block" alignItems="center" justify="center" className={classes.boxStormBottomRadius}>
                            <Link href={`${initPath}storm/history/jtwc`}>
                              <img
                                src={`${initPath}images/theme/banner/weather/storm/storm-history.png`}
                                className={classes.responsive}
                                alt="thaiwater"
                              />
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* end content  */}
          <Modal
            id="modal-chart"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={this.handleModalClose}
            style={{ align: 'center', justifyContent: 'center' }}
          >
            <div style={this.getModalStyle()} className={classes.paperModal}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                <Grid item>
                  <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography variant="h5" id="modal-title">
                {modalHeader}
              </Typography>
              <Typography variant="subtitle1" id="simple-modal-description">
                {modalSubHeader}
              </Typography>
              <Box m={1}>
                {modalContent}
              </Box>
            </div>
          </Modal>
        </ForecastContext.Provider>
        {isEmpty(arrayImage[photoIndex]) === false && isOpen && (
          <Lightbox
            mainSrc={arrayImage[photoIndex]}
            nextSrc={arrayImage[(photoIndex + 1) % arrayImage.length]}
            prevSrc={arrayImage[(photoIndex + arrayImage.length - 1) % arrayImage.length]}
            onCloseRequest={() => this.setState({ isOpen: false, photoIndex: 0 })}
            onMovePrevRequest={() => this.setState({
              photoIndex: (photoIndex + arrayImage.length - 1) % arrayImage.length,
            })}
            onMoveNextRequest={() => this.setState({
              photoIndex: (photoIndex + 1) % arrayImage.length,
            })}
            reactModalStyle={{
              overlay: { zIndex: '10000000' },
            }}
          />
        )}
      </>
    );
  }
}

WeatherStorm.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation(), withStyles(styles, custom, { withTheme: true }))(WeatherStorm);
