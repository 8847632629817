import React, { Component, createRef } from 'react';
import {
    Map,
    TileLayer,
    GeoJSON,
    ImageOverlay,
} from 'react-leaflet';
import L, { CRS } from 'leaflet';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import './style.css';
// width
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';

class MapWrf extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            boundary: props.boundary,
            uriImage: props.uriImage,
            mode: props.mode,
            imageBounds: [[12.0, 100.0], [15.0, 102.0]],
            keyword: props.keyword,
            options: {
                height: null,
                center: null,
                zoom: null
            },
            widthScreen: props.width,
        };
        this.refMap = createRef();
        this.rainAmphoeRef = createRef();
    }

    componentDidMount() {
        this.handleSizeStyle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.uriImage !== this.props.uriImage) {
            this.setState({
                uriImage: this.props.uriImage
            });
        }
        this.setStyleImageWrfRom();
    }

    componentWillUnmount() {
        const map = this.refMap.current.leafletElement;
        const container = map.getContainer();

        container.removeEventListener('touchstart', this.preventTouchZoom);
        container.removeEventListener('touchmove', this.preventTouchZoom);
    }

    // ปรับขนาดแผนที่ให้ตรงกับแต่ละ type wrf-roms
    handleSizeStyle = () => {
        const { keyword, widthScreen } = this.state;
        let options = {};
        switch (keyword) {
            case 'thailand':
            case 'thailand-history':
                options = {
                    height: '54em',
                    center: [12.0, 102],
                    zoom: widthScreen === 'xs' ? 4 : 5
                };
                break;
            case 'southeast asia':
            case 'southeast asia-history':
                options = {
                    height: '34em', // 34em
                    center: widthScreen === 'xs' ? [10.5, 110.8] : [8, 130],
                    // center: widthScreen === 'xs' ? [10.5, 110.8] : [10.5, 117.8],
                    zoom: 3
                };
                break;
            case 'asia':
                options = {
                    height: '31em',
                    // center: widthScreen === 'xs' ? [12.0, 98] : [1, 130],
                    center: widthScreen === 'xs' ? [12.0, 98] : [12.0, 102],
                    zoom: widthScreen === 'xs' ? 2 : 3
                };
                break;
            case 'asia-history':
                options = {
                    height: '31em',
                    center: widthScreen === 'xs' ? [10.5, 108] : [10.5, 114],
                    // center: widthScreen === 'xs' ? [10.5, 108] : [1, 130],
                    zoom: 3
                };
                break;
            default:
                options = {
                    height: '31em',
                    center: [0, 0],
                    zoom: 2
                };
                break;
        }
        this.setState({ options });
    };

    onEachFeature = (feature, layer) => {
        const { mode } = this.state;
        const { handleMouseOver, handleMouseOut } = this;
        const { properties } = layer.feature;
        let popupContent;

        if (mode === 'amphoe') {
            const province = properties.PROV_NAM_T.includes('.') ? properties.PROV_NAM_T.split('.')[1] : properties.PROV_NAM_T;
            const amphoe = properties.AMPHOE_T.includes('.') ? properties.AMPHOE_T.split('.')[1] : properties.AMPHOE_T;
            popupContent = `
            <div id="divPopup" name="divPopup" style="text-align: center; width: fit-content;">
                <b>จ. </b>${province}<br><b>อ. </b>${amphoe}
            </div>
        `;
        } else {
            popupContent = `
            <div id="divPopup" name="divPopup" style="text-align: center; width: fit-content;">
                <b>จ. </b>${properties.title}
            </div>
        `;
        }

        layer.on({ mouseover: handleMouseOver, mouseout: handleMouseOut });
        layer.bindPopup(popupContent, {
            autoPan: false,
            closeButton: false,
            className: 'custom-popup'
        });
        // layer.bindPopup(popupContent, { autoPan: false, closeButton: false });
        // layer.bindTooltip(popupContent);
    };

    handleMouseOver = e => {
        const layer = e.target;
        layer.setStyle({
            weight: 1,
            opacity: 1,
            color: 'red',
            dashArray: '0',
            fillOpacity: 0.5,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    };

    handleMouseOut = e => {
        this.rainAmphoeRef.current.leafletElement.resetStyle(e.layer);
    };

    getRainAmphoeStyle = () => ({
        stroke: true,
        color: 'black',
        weight: 0, // default 0
        fillColor: '',
        opacity: 0.3, // default 0.3
        fillOpacity: 0,
    });

    getMapStyles = () => {
        const { widthScreen, options, keyword } = this.state;
        const optionMap = {
            height: options.height,
            width: '600px',
            position: 'static',
            zIndex: 1000,
        };
        // Support desktop screen.
        switch (keyword) {
            case 'southeast asia':
            case 'southeast asia-history':
                optionMap.width = '900px';
                optionMap.height = '51em';
                break;
            case 'asia':
                optionMap.width = '900px';
                optionMap.height = '46em';
                break;
            case 'asia-history':
                optionMap.width = '900px';
                optionMap.height = '50em';
                break;
        }

        // Support mobile screen.
        if (widthScreen === 'xs') {
            optionMap.width = '250px';
            switch (keyword) {
                case 'thailand':
                    optionMap.height = '24em';
                    break;
                case 'thailand-history':
                    optionMap.height = '23em';
                    break;
                case 'southeast asia':
                case 'southeast asia-history':
                    optionMap.height = '15em';
                    break;
                case 'asia':
                case 'asia-history':
                    optionMap.height = '14em';
                    break;
            }
        }

        return optionMap;
    }

    setNewClassName = (action, elements, className) => {
        for (let i = 0; i < elements.length; i++) {
            if (action === 'add') {
                elements[i].classList.add(className);
            } else if (action === 'remove') {
                elements[i].classList.remove(className);
            }
        }
    }

    setStyleImageWrfRom = () => {
        const { widthScreen, keyword } = this.state;
        const sanitizedKeyword = keyword.replaceAll(' ', '');
        const layerImageWrfRom = document.getElementsByClassName(`image-overlay-wrfrom-${sanitizedKeyword}`);

        if (widthScreen === 'xs') {
            let classNameToAdd = '';

            switch (keyword) {
                case 'thailand':
                    classNameToAdd = 'image-overlay-wrfrom-thailand-mobile';
                    break;
                case 'thailand-history':
                    classNameToAdd = 'image-overlay-wrfrom-thailand-history-mobile';
                    break;
                case 'southeast asia':
                    classNameToAdd = 'image-overlay-wrfrom-southeastasia-mobile';
                    break;
                case 'southeast asia-history':
                    classNameToAdd = 'image-overlay-wrfrom-southeastasia-history-mobile';
                    break;
                case 'asia':
                    classNameToAdd = 'image-overlay-wrfrom-asia-mobile';
                    break;
                case 'asia-history':
                    classNameToAdd = 'image-overlay-wrfrom-asia-history-mobile';
                    break;
            }

            if (classNameToAdd) {
                this.setNewClassName('add', layerImageWrfRom, classNameToAdd);
            }
        }
    }

    disableTouchZoom = (map) => {
        const container = map.target.getContainer();
        container.addEventListener('touchstart', this.preventTouchZoom, { passive: false });
        container.addEventListener('touchmove', this.preventTouchZoom, { passive: false });
    }

    preventTouchZoom = (e) => {
        if (e.touches.length > 1) {
            e.preventDefault();
        }
    }

    render() {
        const { boundary, uriImage, imageBounds, options, keyword } = this.state;
        return (
            <div className="map-container">
                <Map
                    id="map"
                    style={this.getMapStyles()}
                    center={options.center}
                    zoom={options.zoom}
                    dragging={false}
                    scrollWheelZoom={false}
                    doubleClickZoom={false}
                    boxZoom={false}
                    keyboard={false}
                    zoomControl={false}
                    touchZoom={false}
                    crs={CRS.Simple}
                    ref={this.refMap}
                    whenReady={this.disableTouchZoom}
                >
                    <GeoJSON
                        key={uniqueId()}
                        data={boundary}
                        onEachFeature={this.onEachFeature}
                        style={this.getRainAmphoeStyle}
                        ref={this.rainAmphoeRef}
                    />
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <ImageOverlay
                        className={`image-overlay-wrfrom-${keyword.replaceAll(' ', '')}`}
                        url={uriImage}
                        bounds={imageBounds}
                        opacity={1}
                    />
                </Map>

            </div>
        );
    }
}

MapWrf.propTypes = {
    boundary: PropTypes.object.isRequired,
    uriImage: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
};

export default compose(withWidth())(MapWrf);