import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import moment from 'moment';
import {
  Link,
} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
// ui
import { withStyles } from '@material-ui/core/styles';
import Img from 'react-image';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography, Hidden } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Lightbox from 'react-image-lightbox';
import Tooltip from '@material-ui/core/Tooltip';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import {
  FaSpinner,
  FaHome,
  FaRegMap,
  FaPlayCircle,
  FaImage,
} from '../../../utils/Fontawesome';
import 'react-image-lightbox/style.css';

import { styles } from '../../../styles/Style';

import SwanAnimation from './SwanAnimation';
import SwanLatest from './SwanLatest';
import SwanLocation from './SwanLocation';
import SwanAnimationMp4 from './SwanAnimationMp4';

const width = '90%';
const initPath = process.env.MIX_APP_PATH;
let images = [];

class SwanHistory extends Component {
  constructor(props, context) {
    super(props, context);
    // eslint-disable-next-line react/prop-types
    const { match: { params: { status } } } = this.props;

    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      imgs: [],
      selectedDate: new Date(moment().subtract(1, 'days')),
      photoIndex: 0,
      isOpen: false,

      displayState: status === 'forecastByStation' ? 'location' : 'history',
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });
    // get data from ws
    this.fetchData(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  }

  static getDerivedStateFromProps(props, state) {
    if (state.selectedDate === '') {
      return {
        selectedDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      };
    }

    return null;
  }

  handleDateChange = date => {
    this.setState({
      isLoading: true,
      selectedDate: date,
    });

    this.fetchData(moment(date).format('YYYY-MM-DD'));
  };

  handleDateChangeButton = change => {
    let selectedDate = this.state.selectedDate;
    if (change === '1' && moment().format('YYYY-MM-DD') != selectedDate) {
      selectedDate = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD');
    }
    else if (change === '-1') {
      selectedDate = moment(selectedDate).add(-1, 'days').format('YYYY-MM-DD');
    }
    this.setState({
      isLoading: true,
      selectedDate,
    });
    this.fetchData(selectedDate);
  }

  fetchData = date => {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/wave_forecast_history_img?year=${moment(date).format('YYYY')}&month=${moment(date).format('MM')}&day=${moment(date).format('DD')}&datatype=image`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderHistory(imgs, selectedDate, maxDate, classes) {
    images = [];
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" py={3} width="100%" alignItems="center" justifyContent="center" bgcolor="background.paper">
              <Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button>
              <Box display="inline" fontFamily="Kanit">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    format={moment(selectedDate).format('ll')}
                    views={["year", "month", "date"]}
                    maxDate={maxDate}
                    value={selectedDate}
                    onChange={this.handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {imgs.map((row, i) => {
            let realImage = '';
            if (row.media_path_thumb !== null) {
              images.push({
                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                title: `${moment(row.datetime).format('LLL')} น.`,
              });

              realImage = (
                <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                  <Img
                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path_thumb}`}
                    width={width}
                    alt={moment(row.datetime).format('HH:mm')}
                    className={classes.responsive}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                  width={width}
                  alt="No Data"
                  className={classes.responsive}
                />
              );
            }

            return (
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                <Box className={classes.boxWeatherSstHistory}>
                  <Box display="block" align="center">
                    {realImage}
                  </Box>
                  <Box p={1} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      {`เวลา : ${moment(row.datetime).format('HH:mm')} น.`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>

      </>
    );
  }

  render() {
    const { classes, t } = this.props;
    const {
      isLoading,
      error,
      imgs,
      photoIndex,
      isOpen,
      selectedDate,
      displayState,
    } = this.state;
    const maxDate = new Date(moment());

    const breadCrumbsPathLevel1 = () => (
      <Link to={`${initPath}weather`}>
        <FaHome className={classes.iconSub} />
        <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
          {' '}
          {t('ติดตามสภาพอากาศ')}
        </Typography>
      </Link>
    );
    const breadCrumbsPathLevel2 = () => (
      <Link to={`${initPath}weather/sea`}>
        <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
          {t('ทะเล')}
        </Typography>
      </Link>
    );
    const breadCrumbsPathLevel3 = () => (
      <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
        {t('แผนภาพคาดการณ์ความสูงและทิศทางคลื่นล่วงหน้า 7 วัน จากแบบจำลองคลื่นทะเล (SWAN)')}
      </Typography>
    );

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={35} />
          </div>
        )}

        {/* ======== Breadcrumbs ======== */}
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              {breadCrumbsPathLevel1()}
              {breadCrumbsPathLevel2()}
              {breadCrumbsPathLevel3()}
            </Breadcrumbs>
          </Grid>
        </Grid>
        {/* ======== End Breadcrumbs ======== */}

        {/* ======== Tab title ========= */}
        <Grid
          container
          spacing={0}
          className={classes.boxTopRadius}
          style={{ marginTop: 40, marginBottom: 20 }}
        >
          <Grid item xs>
            <Box m={1} className={classes.titleName}>
              {t('แผนภาพคาดการณ์ความสูงและทิศทางคลื่นล่วงหน้า 7 วัน')}
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="คาดการณ์รายสถานี">
              <Button
                className={classes.outlinedLeftPrimary}
                color={displayState === 'location' ? 'primary' : 'default'}
                variant={displayState === 'location' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'location' })}
              >
                <FaRegMap />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }} noWrap>{t('คาดการณ์รายสถานี')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="ดูภาพล่าสุด">
              <Button
                className={classes.outlinedPrimary}
                color={displayState === 'latest' ? 'primary' : 'default'}
                variant={displayState === 'latest' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'latest' })}
              >
                <ImageOutlinedIcon />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }} noWrap>{t('ดูภาพล่าสุด')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="ดูภาพย้อนหลัง">
              <Button
                className={classes.outlinedPrimary}
                color={displayState === 'history' ? 'primary' : 'default'}
                variant={displayState === 'history' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'history' })}
              >
                <PhotoLibraryOutlinedIcon />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }} noWrap>{t('ดูภาพย้อนหลัง')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="ภาพเคลื่อนไหว">
              <Button
                className={classes.outlinedPrimary}
                color={displayState === 'animation' ? 'primary' : 'default'}
                variant={displayState === 'animation' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'animation' })}
              >
                <FaImage />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }} noWrap>{t('ภาพเคลื่อนไหว')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="วิดีโอ">
              <Button
                className={classes.outlinedRightPrimary}
                color={displayState === 'animationmp4' ? 'primary' : 'default'}
                variant={displayState === 'animationmp4' ? 'contained' : 'text'}
                onClick={() => this.setState({ displayState: 'animationmp4' })}
              >
                <FaPlayCircle />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }} noWrap>{t('วิดีโอ')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        {/* ======== End Tab title ========= */}

        {/* ======== rander data ======== */}
        {displayState === 'location' && (
          <SwanLocation />
        )}
        {displayState === 'latest' && (
          <SwanLatest />
        )}
        {displayState === 'history' && (
          this.renderHistory(imgs, selectedDate, maxDate, classes)
        )}
        {displayState === 'animation' && (
          <SwanAnimation />
        )}
        {displayState === 'animationmp4' && (
          <SwanAnimationMp4 />
        )}
        {/* ======== End rander data ======== */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mt={3}>
            {t('แบบจำลอง SWAN พัฒนาขึ้นเพื่อใช้กับบริเวณน้ำตื้น และเหมาะสมกับลักษณะภูมิประเทศของอ่าวไทยที่มีความลึกเฉลี่ย 45 เมตร โดยระบบพยากรณ์คลื่นที่พัฒนาขึ้นนี้ ได้มีการพัฒนาให้เป็นระบบปฏิบัติการและเชื่อมโยงกับแบบจำลองสภาพภูมิอากาศ WRF เพื่อนำข้อมูลคาดการณ์ลมที่ความละเอียดสูงมาใช้เป็นข้อมูลนำเข้าให้กับแบบจำลอง SWAN ให้สามารถพยากรณ์คลื่นล่วงหน้าได้ 7 วัน')}
          </Box>
        </Grid>
        {/* ======= Popup Lightbox ======= */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
        {/* ======= End Popup Lightbox ======= */}
      </>
    );
  }
}

SwanHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }), withTranslation())(SwanHistory);
