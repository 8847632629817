/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

import { wrap } from 'highcharts';

// img
const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: '100%',
    padding: 10,
  },
  label: {
    fontSize: '1em',
    fontWeight: 300,
  }
});

const CompRainSelect = props => {
  const classes = useStyles();
  const { handletimeLineStatus, timelineStatus, handleMenuPopupClose } = props;
  const handleClick = event => {
    handletimeLineStatus(event.target.value);
    handleMenuPopupClose();
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="rain" name="rain" value={timelineStatus}>
          {/* ภาพเส้นชั้นน้ำฝน (Isohyet) */}
          <FormControlLabel value="1" control={<Radio onClick={handleClick} style={{ color: timelineStatus === '1' ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>ฝนตรวจวัดในอดีต</Box>} className={classes.label} />
          <FormControlLabel value="2" control={<Radio onClick={handleClick} style={{ color: timelineStatus === '2' ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>ฝนพยากรณ์รายวัน</Box>} className={classes.label} />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

CompRainSelect.propTypes = {
  handletimeLineStatus: PropTypes.func.isRequired,
  timelineStatus: PropTypes.string.isRequired,
  handleMenuPopupClose: PropTypes.func.isRequired,
};

export default CompRainSelect;
