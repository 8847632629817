/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 8,
    marginBottom: 4,
  },
  labelFlood: {
    color: '#eee',
    fontSize: '0.8em',
    fontWeight: 400,
  },
  detail: {
    paddingTop: 5,
    color: 'white',
    fontSize: '0.9em',
    fontWeight: 400,
  },
});

const FloodLegend = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        zIndex: 99999,
        left: '1%',
        bottom: '6%',
      }}
    >
      <Box p={1}>
        <Box display="block">
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#E6083E',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}> >2 </Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#6833FF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>1-2</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#333FFF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>0.5-1</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#339FFF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>0.2-0.5</Box>
          </Box>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: '#33ECFF',
              }}
              className={classes.legend}
            />
            <Box className={classes.labelFlood}>0-0.2</Box>
          </Box>
        </Box>
        <Box className={classes.detail}>น้ำท่วม (เมตร)</Box>
      </Box>
    </div>
  );
};

export default FloodLegend;
