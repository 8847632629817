import React, { Component } from 'react';
import PropTypes from 'prop-types';
// ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ContentImageWrfLasted from './contentImageWrfLasted';

import { styles } from '../../../styles/Style';

const TabContainer = ({ children }) => (
  <>
    {children}
  </>
);

class WrfLatestTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // tab
      value: props.domainData === 'southeast asia' ? 1 : 0,
    };
  }

  componentDidMount() {
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes, mediaDateTime, index, thailand3km, southEastasia9km, asia27km, thailandBasin3km,
    } = this.props;
    const {
      value,
    } = this.state;

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs>
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" className={classes.boxTab} variant="scrollable" scrollButtons="auto" style={{ width: '100%' }}>
              <Tab label="Thailand (3km x 3km)" className={value === 0 ? classes.active_tab : ''} />
              <Tab label="Southeast Asia (9km x 9km)" className={value === 1 ? classes.active_tab : ''} />
              <Tab label="Asia (27km x 27km)" className={value === 2 ? classes.active_tab : ''} />
              <Tab label="Thailand Basin (3km x 3km)" className={value === 3 ? classes.active_tab : ''} />
            </Tabs>
            <TabContainer>
              <Box style={{ marginTop: 15, overflowX: 'auto' }}>
                {value === 0 && (
                  <Box style={{ marginTop: 15, overflowX: 'auto' }}>
                    <ContentImageWrfLasted index={index} data={thailand3km} domainData="thailand" mediaDateTime={mediaDateTime} />
                  </Box>
                )}
                {value === 1 && (
                  <Box style={{ marginTop: 15, overflowX: 'auto' }}>
                    <ContentImageWrfLasted index={index} data={southEastasia9km} domainData="southeast asia" mediaDateTime={mediaDateTime} />
                  </Box>
                )}
                {value === 2 && (
                  <Box style={{ marginTop: 15, overflowX: 'auto' }}>
                    <ContentImageWrfLasted index={0} data={asia27km} domainData="asia" mediaDateTime={mediaDateTime} />
                  </Box>
                )}
                {value === 3 && (
                  <Box style={{ marginTop: 15, overflowX: 'auto' }}>
                    <ContentImageWrfLasted index={0} data={thailandBasin3km} domainData="thailand basin" mediaDateTime={mediaDateTime} />
                  </Box>
                )}
              </Box>
            </TabContainer>
          </Grid>
        </Grid>
      </>
    );
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

WrfLatestTab.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  thailand3km: PropTypes.array.isRequired,
  southEastasia9km: PropTypes.array.isRequired,
  asia27km: PropTypes.array.isRequired,
  thailandBasin3km: PropTypes.array.isRequired,
  domainData: PropTypes.string.isRequired,
  mediaDateTime: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(WrfLatestTab);
