import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, orderBy } from 'lodash';
import { compose } from 'recompose';
// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
// import HighchartsMore from 'highcharts/highcharts-more';
import { withTranslation } from 'react-i18next';

import { FaSpinner } from '../../utils/Fontawesome';
import { withModal } from '../../contexts/ModalContext';
// init highcharts module
// HighchartsMore(Highcharts);
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const cateData = [
  { value: 'dam_storage', typeName: 'ปริมาณกักเก็บน้ำ' },
  { value: 'dam_inflow', typeName: 'ปริมาณน้ำไหลลง' },
  { value: 'dam_released', typeName: 'ปริมาณน้ำระบาย' },
  { value: 'dam_inflow_acc', typeName: 'ปริมาณน้ำไหลเข้าสะสม' },
  { value: 'dam_level', typeName: 'ระดับน้ำกักเก็บ' },

];
class DamChartHourly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      chartOptions: this.getChartOptions(),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const { chartCalType } = this.props;

    if (chartCalType !== 'sum') {
      this.renderChart();
    } else {
      this.renderChartSum();
    }
  }

  componentDidUpdate(prevProps) {
    console.log('====================================');
    console.log(prevProps);
    console.log('====================================');
    if (prevProps.modalSize !== this.props.modalSize ||
      prevProps.isFullScreen !== this.props.isFullScreen) {
      this.props.updateChartSize();
    }
  }

  getChartOptions() {
    const { name, t } = this.props;
    this.options = {
      title: {
        text: `${t(name)}`,
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          // marker: {
          //   enabled: undefined,
          //   enabledThreshold: 0.75,
          //   radius: 3,
          // },
        },
      },
      chart: {
        zoomType: 'x',
        // width: '100%',
        height: '80%',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   // format: '{value:%b}',
        //   tickInterval: 1000 * 3600 * 24 * 31, // 1 month
        //   formatter() {
        //     // console.log(this.axis.getExtremes());
        //     if ((this.axis.getExtremes().max - this.axis.getExtremes().min) < 14821920000) {
        //       return Highcharts.dateFormat('%e %b', this.value);
        //     }
        //     return Highcharts.dateFormat('%b', this.value);
        //   },
        // },
        labels: {
          format: '{value:%d-%B<br/>%Y<br/>%H:%M}',
        },
      },

      yAxis: { // Primary yAxis
        title: {
          text: t('ล้าน ลบ.ม.'),
        },
        labels: {
          format: '{value:,.0f}',
        },
        plotLines: [{
          value: null,
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'right',
          },
        }, {
          value: null,
          color: 'black',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'right',
          },
        }, {
          value: null,
          color: '#f9a825',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: null,
            align: 'left',
          },
        }],
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: t(' ล้าน ลบ.ม.'),
        xDateFormat: '%d %B %H:%M',
      },
      colors: ['#F62C2C', '#a14535', '#deb245', '#c7d161', '#b8de45', '#6fd18b', '#45dea1', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#d173bd', '#EA7369', '#F0A58F'],
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  getDatesBetween = (startDateStr, endDateStr) => {
    const startDate = moment(startDateStr, 'YYYY-MM-DD');
    const endDate = moment(endDateStr, 'YYYY-MM-DD');
    let currentDate = startDate;
    const dates = [];

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'days');
    }

    return dates;
  };
  renderChart() {
    const {
      id,
      type,
      t,
      startDate,
      endDate,
    } = this.props;

    let url = `dam_hourly_graph?data_type=${type}&dam_id=${id}&start_date=${startDate}&end_date=${endDate}`;
    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const chartOptions = this.getChartOptions();
        const dataSeries = [];
        let ts = null;
        let val = null;

        // data series
        // const colors = ['#F62C2C', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#EA7369', '#F0A58F'];
        const colors = ['#F62C2C', '#a14535', '#deb245', '#c7d161', '#b8de45', '#6fd18b', '#45dea1', '#06C', '#39F', '#1AC9E6', '#7D3AC1', '#DB4CE2', '#d173bd', '#EA7369', '#F0A58F'];

        const seriesValue = [];

        const datesData = this.getDatesBetween(startDate, endDate);
        const formattedDates = datesData.map(date => moment(date).format('YYYY-MM-DD'));

        let upperRuleCurveData = [];
        let lowerRuleCurveData = [];

        const matchDates = (formattedDates, data, targetArray) => {
          formattedDates.forEach((formattedDate) => {
            const matchingElement = data.find(element =>
              moment(element.date).format('MM-DD') === moment(formattedDate).format('MM-DD')
            );

            if (matchingElement) {
              targetArray.push({
                date: formattedDate,
                value: matchingElement.value
              });
            }
          });
        };

        matchDates(formattedDates, result.data.upper_rule_curve, upperRuleCurveData);
        matchDates(formattedDates, result.data.lower_rule_curve, lowerRuleCurveData);

        result.data.graph_data.reverse().map((d, i) => {
          const series = [];

          orderBy(d.data, ['date'], ['asc'])
            .filter(a => a.value !== null)
            .map(n => {

              if ((moment(n.date).format('M') !== 2) || (moment(n.date).date() !== 29)) {
                if (n.value !== null) {
                  val = parseFloat(n.value).toFixed(2);
                  seriesValue.push(val);
                } else {
                  val = null;
                }
                // console.log(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`);
                // ts = moment(`${moment(n.date).format('YYYY')}-${moment(n.date).format('MM')}-${moment(n.date).format('DD hh:mm')}`, 'YYYY-MM-DD hh:mm').add(7, 'hours').valueOf();
                ts = moment(n.date, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
                series.push([ts, parseFloat(val)]);
              }

              return series;
            });

          dataSeries.push({
            name: `${cateData.find(v => v.value == type).typeName}` ?? `${type}`,
            data: series,
            color: colors[i],
            dashStyle: 'solid',
            tooltip: {
              valueSuffix: t(' ล้าน ลบ.ม.'),
              // xDateFormat: '%d %B %H:%M',
            },
          });

          return null;
        });
        // show rule curve for dam storage
        if (type === 'dam_storage') {
          // lower rule curve
          const seriesLower = [];
          lowerRuleCurveData.map(n => {
            if (n.value !== null) {
              val = parseFloat(n.value).toFixed(2);
            } else {
              val = null;
            }

            // ts = moment(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`, 'YYYY-MM-DD')
            //   .add(1, 'days')
            //   .valueOf();
            ts = moment(`${moment(n.date).format('YYYY-MM-DD')}`, 'YYYY-MM-DD hh:mm').add(7, 'hours').valueOf();
            seriesLower.push([ts, parseFloat(val)]);

            return seriesLower;
          });

          dataSeries.push({
            name: 'Lower Rule Curve',
            data: seriesLower,
            tooltip: {
              valueSuffix: t('ล้าน ลบ.ม.'),
              xDateFormat: '%d %B',
            },
            dashStyle: 'shortdot',
            color: '#7a7777',
          });

          // Upper rule curve
          const seriesUpper = [];
          upperRuleCurveData.map(n => {
            if (n.value !== null) {
              val = parseFloat(n.value).toFixed(2);
            } else {
              val = null;
            }

            // ts = moment(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`, 'YYYY-MM-DD')
            //   .add(1, 'days')
            //   .valueOf();
            ts = moment(`${moment(n.date).format('YYYY-MM-DD')}`, 'YYYY-MM-DD hh:mm').add(7, 'hours').valueOf();
            seriesUpper.push([ts, parseFloat(val)]);

            return seriesUpper;
          });

          dataSeries.push({
            name: 'Upper Rule Curve',
            data: seriesUpper,
            tooltip: {
              valueSuffix: t('ล้าน ลบ.ม.'),
              xDateFormat: '%d %B',
            },
            dashStyle: 'shortdot',
            color: '#000',
          });

          // plotLines
          if (result.data.upper_bound) {
            // chartOptions.yAxis.max = result.data.upper_bound;
            chartOptions.yAxis.max = result.data.lower_bound + Number(1.2) * (result.data.upper_bound - result.data.lower_bound);
            chartOptions.yAxis.plotLines[0].value = result.data.upper_bound;
            chartOptions.yAxis.plotLines[0].label.text = `${t('กักเก็บสูงสุด')} <strong>${result.data.upper_bound.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }

          if (result.data.lower_bound) {
            chartOptions.yAxis.min = result.data.lower_bound;
            chartOptions.yAxis.plotLines[1].value = result.data.lower_bound;
            chartOptions.yAxis.plotLines[1].label.text = `${t('กักเก็บต่ำสุด')} <strong>${result.data.lower_bound.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }

          if (result.data.normal_bound) {
            chartOptions.yAxis.plotLines[2].value = result.data.normal_bound;
            chartOptions.yAxis.plotLines[2].label.text = `${t('กักเก็บปกติ')} <strong>${result.data.normal_bound.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }
        } else if (type === 'dam_inflow_acc') {
          const seriesSumInflowAverage = [];
          result.data.sum_average_inflow.map(n => {
            if (n.value !== null) {
              val = parseFloat(n.value).toFixed(2);
            } else {
              val = null;
            }

            // ts = moment(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`, 'YYYY-MM-DD')
            //   .add(1, 'days')
            //   .valueOf();
            ts = moment(`${moment(n.date).format('YYYY-MM-DD 00:00')}`, 'YYYY-MM-DD hh:mm').add(7, 'hours').valueOf();
            seriesSumInflowAverage.push([ts, parseFloat(val)]);

            return seriesSumInflowAverage;
          });

          dataSeries.push({
            name: `${t('ปริมาณน้ำไหลลงเฉลี่ยสะสม')}`,
            data: seriesSumInflowAverage,
            tooltip: {
              valueSuffix: t('ล้าน ลบ.ม.'),
              xDateFormat: '%d %B',
            },
            dashStyle: 'shortdot',
            color: '#7a7777',
          });

          const maxInflow = Math.max(...seriesValue);
          chartOptions.yAxis.min = 0;
          chartOptions.yAxis.max = maxBy([result.data.upper_bound, maxInflow]) + Number(1.2);

          // force chart yAxis.min = 0
          chartOptions.startOnTick = false;
          chartOptions.endOnTick = false;
        } else {
          chartOptions.yAxis.max = null;
          chartOptions.yAxis.min = null;
        }

        chartOptions.series = dataSeries;
        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderChartSum() {
    const {
      id,
      year,
      type,
      t,
    } = this.props;

    const url = `dam_daily_sum_region_rid?region_id=${id}&year=${year}`;
    const chartOptions = this.getChartOptions();

    // fetch data
    fetch(`${process.env.MIX_API_URL}analyst/${url}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // transform datas
        const dataSeries = [];
        let ts = null;
        let val = null;

        // data series
        result.data.graph_data.map(d => {
          const series = [];

          orderBy(d.data, ['date'], ['asc']).map(n => {
            if (type === 'total_dam_storage') {
              val = n.total_dam_storage;
            } else if (type === 'total_dam_inflow') {
              val = n.total_dam_inflow;
            } else if (type === 'total_dam_released') {
              val = n.total_dam_released;
            } else if (type === 'total_dam_uses_water') {
              val = n.total_dam_uses_water;
            } else if (type === 'total_dam_inflow_acc') {
              val = n.total_dam_inflow_acc;
            } else if (type === 'total_dam_released_acc') {
              val = n.total_dam_released_acc;
            }

            // by pass 29 Feb
            // 2024-02-05 เปลียนจาก && เป็น || เนื่องจากข้อมูลวันที่ 29 ไม่แสดง
            if ((moment(n.dam_date).format('M') !== 2) || (moment(n.dam_date).date() !== 29)) {
              if (val === 0) {
                val = null;
              } else {
                val = parseFloat(val).toFixed(2);
              }

              // console.log(`2020-${moment(n.date).format('MM')}-${moment(n.date).format('DD')}`);
              ts = moment(`2020-${moment(n.dam_date).format('MM')}-${moment(n.dam_date).format('DD')}`, 'YYYY/MM/DD').add(1, 'days').valueOf();
              series.push([ts, parseFloat(val)]);
            }

            return series;
          });

          dataSeries.push({
            name: `${d.year}`,
            data: series,
            tooltip: {
              valueSuffix: t('ล้าน ลบ.ม.'),
              xDateFormat: '%d %B',
            },
          });

          return null;
        });

        // set chart options
        chartOptions.series = dataSeries;

        // plotLines
        if (type === 'total_dam_storage') {
          if (result.data.bound.upper) {
            chartOptions.yAxis.max = result.data.bound.upper;
            chartOptions.yAxis.plotLines[0].value = result.data.bound.upper;
            chartOptions.yAxis.plotLines[0].label.text = `${t('กักเก็บสูงสุด')} <strong>${result.data.bound.upper.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }

          if (result.data.bound.lower) {
            chartOptions.yAxis.min = result.data.bound.lower;
            chartOptions.yAxis.plotLines[1].value = result.data.bound.lower;
            chartOptions.yAxis.plotLines[1].label.text = `${t('กักเก็บต่ำสุด')} <strong>${result.data.bound.lower.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }

          if (result.data.bound.normal) {
            chartOptions.yAxis.plotLines[2].value = result.data.bound.normal;
            chartOptions.yAxis.plotLines[2].label.text = `${t('กักเก็บปกติ')} <strong>${result.data.bound.normal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> ${t('ล้าน ลบ.ม.')}`;
          }
        } else {
          chartOptions.yAxis.max = null;
          chartOptions.yAxis.min = null;
        }
        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { isLoading, error, chartOptions } = this.state;

    // if error, show error message
    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      );
    }

    return (
      <>
        <HighchartsReact
          ref={this.props.chartRef}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </>
    );
  }
}

DamChartHourly.defaultProps = {
  id: '',
  chartCalType: '',
  size: 'large',
};

DamChartHourly.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  year: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  chartCalType: PropTypes.string,
  t: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

// export default withTranslation('translations', { withRef: true })(DamChartHourly);
export default compose(withModal, withTranslation('translations', { withRef: true }))(DamChartHourly);
