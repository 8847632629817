import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// map
import L from 'leaflet';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  GeoJSON,
  LayerGroup,
  LayersControl,
  WMSTileLayer,
} from 'react-leaflet';

import { uniqueId } from 'lodash';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { GestureHandling } from 'leaflet-gesture-handling';
import FullscreenControl from 'react-leaflet-fullscreen';

// color
import deepOrange from '@material-ui/core/colors/deepOrange';
import { FaChartLine } from '../../utils/Fontawesome';
import WaterQualityStatusColor from './WaterQualityStatusColor';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import { styles } from '../../styles/Style';

import tJson from '../../utils/TJson';
import CurrentLocation from '../currentlocation/CurrentLocation';

const { BaseLayer } = LayersControl;

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;

class WaterQualityMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.WaterqualityLayer = createRef();
    this.refBoundaryBasin = createRef();

    this.state = {
      height: this.setHeightMap(),
      valueType: 'salinity',
      rendarData: null,
      isOpenCollapse: false,
      geojsonProvince: [],
      geojsonBasin: [],
    };

    this.eachDataSalinity = this.eachDataSalinity.bind(this);
    this.eachDataPH = this.eachDataPH.bind(this);
    this.eachDataDO = this.eachDataDO.bind(this);
    this.eachDataConductivity = this.eachDataConductivity.bind(this);
    this.handleChart = this.handleChart.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleClick = this.handleClick.bind(this);
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;
    this.getGeoJson();
    this._mounted = true;
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  shouldComponentUpdate(nextProps) {
    const {
      classes,
      handleSetMap,
      handleStationModalOpen,
      markers,
      datas,
      zoom,
      location,
      width,
      areaType,
      areaId,
      language,
    } = this.props;
    if ((classes === nextProps.classes)
      && (handleSetMap === nextProps.handleSetMap)
      && (handleStationModalOpen === nextProps.handleStationModalOpen)
      && (markers === nextProps.markers)
      && (datas === nextProps.datas)
      && (zoom === nextProps.zoom)
      && (location === nextProps.location)
      && (width === nextProps.width)
      && (areaType === nextProps.areaType)
      && (language === nextProps.i18n.language)
      && (areaId === nextProps.areaId)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { areaType, areaId } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    let obj = this.refBoundaryProvince.current.leafletElement._layers;

    if (areaId === '') {
      const { location, zoom } = this.props;
      this.map.setView(location, zoom);
      return;
    }
    if (areaType !== prevProps.areaType || areaId !== prevProps.areaId) {
      if (areaType === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }
      // eslint-disable-next-line no-underscore-dangle
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          // province
          if (areaType === '0') {
            const provCode = obj[key].feature.properties.prov_code;
            if (provCode.toString() === areaId) {
              this.map.fitBounds(obj[key].getBounds());
            }
          } else { // basin
            const basinCode = obj[key].feature.properties.BASIN_CODE;
            if (basinCode === areaId) {
              this.map.fitBounds(obj[key].getBounds());
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setHeightMap() {
    const { width } = this.props;
    // let heightMap = 932;
    // let heightMap = 747;
    // let heightMap = 526;
    let heightMap = 427;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 427;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 526;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 550;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 5;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 4;
    }
    return zoom;
  }

  getProvinceStyle(feature) {
    const { areaType, areaId } = this.props;
    if (areaType === '0' && areaId) {
      if (feature.properties.prov_code === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getBasinStyle(feature) {
    const { areaType, areaId } = this.props;

    if (areaType === '1' && areaId) {
      if (feature.properties.BASIN_CODE === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getGeoJson() {
    Promise.all([
      fetch(`${initPath}json/boundary/thailand.json`),
      fetch(`${initPath}json/boundary/basin.json`),
    ])
      .then((
        [
          res2,
          res3,
        ],
      ) => Promise.all(
        [
          res2.json(),
          res3.json(),
        ],
      ))
      .then((
        [
          province,
          basin,
        ],
      ) => {
        this.setState({
          geojsonProvince: province,
          geojsonBasin: basin,
        });
      })
      .catch(error => this.setState({ error }));
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      // if (this.refMap.current.leafletElement) {
      //   const map = this.refMap.current.leafletElement;
      //   map.on('popupopen', e => {
      //     // eslint-disable-next-line no-underscore-dangle
      //     const LatLng = e.target._popup._latlng;
      //     const px = map.project(LatLng);
      //     // eslint-disable-next-line no-underscore-dangle
      //     px.y -= e.target._popup._container.clientHeight / 2;
      //     map.panTo(map.unproject(px), { animate: true });
      //   });
      // }
    }
  };

  handleChart = data => e => {
    const { handleStationModalOpen } = this.props;
    e.preventDefault();

    const newData = {
      id: data.waterquality_station.id,
      provinceId: data.waterquality_station.province_code,
    };

    if (this._mounted) {
      handleStationModalOpen(newData);
    }
  };

  // get water quality status
  waterQualityStatusBadge = (data, value) => {
    const { classes } = this.props;
    const className = classNames(classes.status, classes.stay);
    const status = WaterQualityStatusColor(data, 'salinity');
    const statusText = `<span class="${className}" style='background:${status.color.statusColor
      }'>${Number(value).toFixed(2)}</span>`;

    return typeof value === 'undefined' || value == null ? '' : statusText;
  };

  // get water quality status
  waterQualityStatusBadgeDo = (data, value) => {
    const { classes } = this.props;
    const className = classNames(classes.status, classes.stay);
    const status = WaterQualityStatusColor(data, 'do');
    const statusText = `<span class="${className}" style='background:${status.color.statusColor
      }'>${Number(value).toFixed(2)}</span>`;

    return typeof value === 'undefined' || value == null ? '' : statusText;
  };

  // get water quality status
  waterQualityStatusBadgePh = (data, value) => {
    const { classes } = this.props;
    const className = classNames(classes.status, classes.stay);
    const status = WaterQualityStatusColor(data, 'ph');
    const statusText = `<span class="${className}" style='background:${status.color.statusColor
      }'>${Number(value).toFixed(2)}</span>`;
    return typeof value === 'undefined' || value == null ? '' : statusText;
  };

  waterQualityStatusBadgeEc = (data, value) => {
    const { classes } = this.props;
    const className = classNames(classes.status, classes.stay);
    const status = WaterQualityStatusColor(data, 'ec');
    const statusText = `<span class="${className}" style='background:${status.color.statusColor
      }'>${Number(value).toFixed(2)}</span>`;
    return typeof value === 'undefined' || value == null ? '' : statusText;
  };

  // set marker icon based on criteria
  setIcon = (val, type) => {
    const status = WaterQualityStatusColor(val, type);
    return status.icon;
  };

  handleZoom = () => {
    const currentZoom = this.map.getZoom();

    if (currentZoom <= 4) {
      // hide layers
      this.map.removeLayer(this.WaterqualityLayer.current);
    } else {
      // show layers
    }
  };

  handleClick = () => {
    const { isOpenCollapse } = this.state; // classes
    if (isOpenCollapse === false) {
      this.setState({ isOpenCollapse: true });
    } else {
      this.setState({ isOpenCollapse: false });
    }
  }

  eachDataSalinity(data, i) {
    const { classes, t } = this.props;
    if (
      data.waterquality_station.waterquality_station_lat
      && data.waterquality_station.waterquality_station_long
    ) {
      const position = [
        parseFloat(data.waterquality_station.waterquality_station_lat),
        parseFloat(data.waterquality_station.waterquality_station_long),
      ];
      const icon = this.setIcon(
        parseFloat(data.waterquality_salinity),
        'salinity',
      );
      const WqSalinity = this.waterQualityStatusBadge(
        data.waterquality_salinity,
        data.waterquality_salinity,
      );
      const WqPh = this.waterQualityStatusBadgePh(
        data.waterquality_ph,
        data.waterquality_ph,
      );
      const WqDo = this.waterQualityStatusBadgeDo(
        data.waterquality_do,
        data.waterquality_do,
      );
      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <Grid>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={3}>
                      <Typography variant="subtitle2">
                        {
                          (data.waterquality_station.waterquality_station_name.th).includes('สถานี') ? tJson(data.waterquality_station.waterquality_station_name, 'สถานี') : `สถานี${tJson(data.waterquality_station.waterquality_station_name)}`
                        }
                        <br />
                        {` ${tJson(data.waterquality_station.amphoe_name, 'อ.')} `}
                        {` ${tJson(data.waterquality_station.province_name, 'จ.')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('กรด-ด่าง')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqPh }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ความเค็ม')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqSalinity }}
                      />
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>(g/L)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ออกซิเจนละลายในน้ำ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqDo }}
                      />
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>(mg/L)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`${t('ข้อมูล')}: ${tJson(data.waterquality_station.agency_name, 'หน่วยงาน')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.waterquality_datetime}
                        {' '}
                        {'น.'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right" padding="none">
                      <Button
                        color="secondary"
                        onClick={this.handleChart(data)}
                        title={t('แสดงกราฟ')}
                      >
                        <FaChartLine className={classes.rightIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.waterquality_station.waterquality_station_name, 'สถานี')}
                </strong>
                <br />
                {`${tJson(data.waterquality_station.tumbon_name, 'ต.')}`}
                <br />
                {`${tJson(data.waterquality_station.amphoe_name, 'อ.')}`}
                <br />
                {`${tJson(data.waterquality_station.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return true;
  }

  eachDataPH(data, i) {
    const { classes, t } = this.props;
    if (
      data.waterquality_station.waterquality_station_lat
      && data.waterquality_station.waterquality_station_long
    ) {
      const position = [
        parseFloat(data.waterquality_station.waterquality_station_lat),
        parseFloat(data.waterquality_station.waterquality_station_long),
      ];
      const icon = this.setIcon(
        parseFloat(data.waterquality_ph),
        'ph',
      );
      const WqSalinity = this.waterQualityStatusBadge(
        data.waterquality_salinity,
        data.waterquality_salinity,
      );
      const WqPh = this.waterQualityStatusBadgePh(
        data.waterquality_ph,
        data.waterquality_ph,
      );
      const WqDo = this.waterQualityStatusBadgeDo(
        data.waterquality_do,
        data.waterquality_do,
      );

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <Grid>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={3}>
                      <Typography variant="subtitle2">
                        {
                          (data.waterquality_station.waterquality_station_name.th).includes('สถานี') ? tJson(data.waterquality_station.waterquality_station_name, 'สถานี') : `สถานี${tJson(data.waterquality_station.waterquality_station_name, 'สถานี')}`
                        }
                        <br />
                        {` ${tJson(data.waterquality_station.amphoe_name, 'อ.')} `}
                        {` ${tJson(data.waterquality_station.province_name, 'จ.')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('กรด-ด่าง')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqPh }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ความเค็ม')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqSalinity }}
                      />
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>(g/L)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ออกซิเจนละลายในน้ำ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqDo }}
                      />
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>(mg/L)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.waterquality_datetime}
                        {' '}
                        {'น.'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right" padding="none">
                      <Button
                        color="secondary"
                        onClick={this.handleChart(data)}
                        title={t('แสดงกราฟ')}
                      >
                        <FaChartLine className={classes.rightIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.waterquality_station.waterquality_station_name, 'สถานี')}
                </strong>
                <br />
                {` ${tJson(data.waterquality_station.tumbon_name, 'ต.')}`}
                <br />
                {` ${tJson(data.waterquality_station.amphoe_name, 'อ.')}`}
                <br />
                {` ${tJson(data.waterquality_station.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return true;
  }

  eachDataDO(data, i) {
    const { classes, t } = this.props;
    if (
      data.waterquality_station.waterquality_station_lat
      && data.waterquality_station.waterquality_station_long
    ) {
      const position = [
        parseFloat(data.waterquality_station.waterquality_station_lat),
        parseFloat(data.waterquality_station.waterquality_station_long),
      ];
      const icon = this.setIcon(
        parseFloat(data.waterquality_do),
        'do',
      );
      const WqSalinity = this.waterQualityStatusBadge(
        data.waterquality_salinity,
        data.waterquality_salinity,
      );
      const WqPh = this.waterQualityStatusBadgePh(
        data.waterquality_ph,
        data.waterquality_ph,
      );
      const WqDo = this.waterQualityStatusBadgeDo(
        data.waterquality_do,
        data.waterquality_do,
      );
      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <Grid>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={3}>
                      <Typography variant="subtitle2">
                        {
                          (data.waterquality_station.waterquality_station_name.th).includes('สถานี') ? tJson(data.waterquality_station.waterquality_station_name, 'สถานี') : `สถานี${tJson(data.waterquality_station.waterquality_station_name, 'สถานี')}`
                        }
                        <br />
                        {` ${tJson(data.waterquality_station.amphoe_name, 'อ.')}`}
                        {` ${tJson(data.waterquality_station.province_name, 'จ.')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('กรด-ด่าง')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqPh }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ความเค็ม')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqSalinity }}
                      />
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>(g/L)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ออกซิเจนละลายในน้ำ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <div
                        className="text-center"
                        style={{ whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: WqDo }}
                      />
                    </TableCell>
                    <TableCell className={classes.hiddenLine}>(mg/L)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.waterquality_datetime}
                        {' '}
                        {'น.'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right" padding="none">
                      <Button
                        color="secondary"
                        onClick={this.handleChart(data)}
                        title={t('แสดงกราฟ')}
                      >
                        <FaChartLine className={classes.rightIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {tJson(data.waterquality_station.waterquality_station_name, 'สถานี')}
                </strong>
                <br />
                {` ${tJson(data.waterquality_station.tumbon_name, 'ต.')}`}
                <br />
                {` ${tJson(data.waterquality_station.amphoe_name, 'อ.')}`}
                <br />
                {` ${tJson(data.waterquality_station.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return true;
  }

  eachDataConductivity(data, i) {
    const { classes, t } = this.props;
    const latitude = parseFloat(data.waterquality_station.waterquality_station_lat);
    const longitude = parseFloat(data.waterquality_station.waterquality_station_long);
    if (
      latitude && longitude
    ) {
      const position = [
        parseFloat(data.waterquality_station.waterquality_station_lat),
        parseFloat(data.waterquality_station.waterquality_station_long),
      ];
      const icon = this.setIcon(
        parseFloat(data.waterquality_conductivity),
        'ec',
      );
      const WqSalinity = this.waterQualityStatusBadge(
        data.waterquality_salinity,
        data.waterquality_salinity,
      );
      const WqPh = this.waterQualityStatusBadgePh(
        data.waterquality_ph,
        data.waterquality_ph,
      );
      const WqDo = this.waterQualityStatusBadgeDo(
        data.waterquality_do,
        data.waterquality_do,
      );
      const WqEc = this.waterQualityStatusBadgeEc(
        data.waterquality_conductivity,
        data.waterquality_conductivity,
      );
      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" align="center">
                      <Typography variant="subtitle2">
                        {`สถานี${tJson(data.waterquality_station.waterquality_station_name, 'สถานี')} `}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="none">
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>{`${t('กรด-ด่าง')} (pH)`}</TableCell>
                            <TableCell align="right">
                              <div
                                className="text-center"
                                style={{ whiteSpace: 'nowrap' }}
                                dangerouslySetInnerHTML={{ __html: WqPh }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{`${t('ความเค็ม')} (g/L)`}</TableCell>
                            <TableCell align="right">
                              <div
                                className="text-center"
                                style={{ whiteSpace: 'nowrap' }}
                                dangerouslySetInnerHTML={{ __html: WqSalinity }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {`${t('ออกซิเจน')}`}
                              {'(mg/L)'}
                            </TableCell>
                            <TableCell align="right">
                              <div
                                className="text-center"
                                style={{ whiteSpace: 'nowrap' }}
                                dangerouslySetInnerHTML={{ __html: WqDo }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{`${t('ความนำไฟฟ้า')} (µS/cm)`}</TableCell>
                            <TableCell align="right">
                              <div
                                className="text-center"
                                style={{ whiteSpace: 'nowrap' }}
                                dangerouslySetInnerHTML={{ __html: WqEc }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('พื้นที่')}</TableCell>
                            <TableCell align="right">
                              <strong>
                                {`${tJson(data.waterquality_station.province_name, 'จ.')} ${tJson(data.waterquality_station.amphoe_name, 'อ.')}`}
                              </strong>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('พิกัด')}</TableCell>
                            <TableCell align="right">
                              <strong>{`${data.waterquality_station.waterquality_station_lat}, ${data.waterquality_station.waterquality_station_long}`}</strong>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.waterquality_datetime}
                        {' '}
                        {'น.'}
                      </Typography>
                    </TableCell>
                    <TableCell padding="none">
                      <Button
                        color="primary"
                        onClick={this.handleChart(data)}
                        title={t('แสดงกราฟเขื่อน')}
                      >
                        <FaChartLine className={classes.rightIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
        </Marker>
      );
    }
    return true;
  }

  render() {
    const {
      error,
      height,
      valueType,
      rendarData,
      geojsonProvince,
      geojsonBasin,
    } = this.state;
    const {
      location,
      zoom,
      datas,
      classes,
      t,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          onZoomEnd={this.handleZoom}
          gestureHandling
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonBasin}
            style={this.getBasinStyle}
            ref={this.refBoundaryBasin}
            onEachFeature={this.onEachBasinFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            onEachFeature={this.onEachProvinceFeature}
          />
          <LayersControl position="topright">
            <BaseLayer name={t('เส้นแม่น้ำ')} checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </BaseLayer>
            <BaseLayer name={`${t('ความเค็ม')} (g/L)`} checked>
              <LayerGroup ref={this.WaterqualityLayer}>
                {datas.sort(
                  (a, b) => (
                    (a.waterquality_salinity > 2) - (b.waterquality_salinity > 2))
                    || ((a.waterquality_salinity > 0.5) - (b.waterquality_salinity > 0.5))
                    || ((a.waterquality_salinity > 0.25) - (b.waterquality_salinity > 0.25))
                    || ((a.waterquality_salinity < 0.25) - (b.waterquality_salinity < 0.25)),
                ).map(this.eachDataSalinity)}
              </LayerGroup>
            </BaseLayer>
            <BaseLayer name={t('กรด-ด่าง')}>
              <LayerGroup ref={this.WaterqualityLayer}>
                {datas.sort(
                  (a, b) => (
                    (a.waterquality_ph < 5) - (b.waterquality_ph < 5))
                    || ((a.waterquality_ph > 9) - (b.waterquality_ph > 9)),
                ).map(this.eachDataPH)}
              </LayerGroup>
            </BaseLayer>
            <BaseLayer name={`${t('ออกซิเจนละลายในน้ำ')} (mg/L)`}>
              <LayerGroup ref={this.WaterqualityLayer}>
                {datas.sort(
                  (a, b) => (
                    (a.waterquality_do < 2) - (b.waterquality_do < 2))
                    || ((a.waterquality_do > 2) - (b.waterquality_do > 2)),
                ).map(this.eachDataDO)}
              </LayerGroup>
            </BaseLayer>
          </LayersControl>
          {valueType === 'salinity' ? datas.map(this.eachDataSalinity) : rendarData}
          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />

        </Map>
      </>
    );
  }
}

WaterQualityMap.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleStationModalOpen: PropTypes.func.isRequired,
  markers: PropTypes.array.isRequired,
  datas: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  location: PropTypes.array.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  areaType: PropTypes.string.isRequired,
  areaId: PropTypes.string.isRequired,
  i18n: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(WaterQualityMap);
