/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { withLeaflet, MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-geotiff';
import 'leaflet-geotiff/leaflet-geotiff-plotty';

class GeotiffLayer extends MapLayer {
  // eslint-disable-next-line class-methods-use-this
  createLeafletElement(props) {
    const { url, options } = props;
    return L.leafletGeotiff(url, options);
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    const { name } = this.props;
    const Layer = this.leafletElement.addTo(map);
    Layer.setStyle({
      opacity: 0.5,
    });
    const control = L.control.layers(null, null, { collapsed: false, position: 'topleft', hideSingleBase: true }).addTo(map);
    control.addOverlay(Layer, name);
    // if (name === 'Acc. Fcst. Rain 1 Day') map.removeLayer(Layer);
    if (name === 'Acc. Obs. Rain 5 Day') map.removeLayer(Layer);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const PlottyGeotiffLayer = withLeaflet(props => {
  const { options, layerRef } = props;
  // eslint-disable-next-line global-require
  const plotty = require('plotty');
  plotty.addColorScale('fcstRaincolorscale', ['#FFFFFF', '#CBE3FF', '#AED3FF', '#97E5AB', '#6AC805', '#F9D601', '#FD8B02', '#CC6602', '#FE3003', '#FE3003', '#881A1B', '#9630D1'],
    [0, 0.01, 0.02, 0.04, 0.07, 0.1, 0.14, 0.18, 0.3, 0.4, 0.6, 1]);
  // plotty.addColorScale('obsRaincolorscale', ['#e7f4b5', '#e19c51', '#e15153', '#6267f0', '#272ae8', '#232b78'], [0.09, 0.30, 0.2, 0.2, 0.8, 1]);
  plotty.addColorScale('obsRaincolorscale', ['#e7f4b5', '#e19c51', '#e15153', '#6267f0', '#272ae8', '#232b78'], [0, 0.2, 0.6, 0.8999, 0.7, 1]);

  options.renderer = new L.LeafletGeotiff.Plotty(options);
  return <GeotiffLayer ref={layerRef} {...props} />;
});
