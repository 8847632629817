import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { maxBy, minBy } from 'lodash';

// moment
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import offlineOption from 'highcharts/modules/offline-exporting';

import { withTranslation } from 'react-i18next';

// material-ui
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import tJson from '../../utils/TJson';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

offlineOption(Highcharts);

const graphType = [
  { id: 'station', name: 'เปรียบเทียบสถานีคุณภาพน้ำ' },
  { id: 'param', name: 'เปรียบเทียบพารามิเตอร์' },
  { id: 'waterlevel', name: 'เปรียบเทียบสถานีวัดระดับน้ำ' },
  { id: 'datetime', name: 'เปรียบเทียบวันที่เวลาและสถานี' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class WaterQualityStationChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      dataParam: [],
      dataStation: [],
      waterQualityLoad: [],
      selectedDate: moment(new Date()),
      provinceId: props.provinceId,
      graphTypeSelect: 'station',
      graphTypeSelectName: graphType[0].name,
      paramSelect: 'salinity',
      stationSelect: [props.id],
      startDate: moment()
        .subtract(3, 'days')
        .locale('th')
        .format('YYYY-MM-DD'),
      endDate: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      today: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      startTime: moment()
        .subtract(3, 'days')
        .locale('th'),
      endTime: moment(new Date())
        .locale('th'),
      dataChart: undefined,
    };

    this.renderChart = this.renderChart.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getWaterQualityLoad();
    this.renderChart();
  }

  static getDerivedStateFromProps(props, state) {
    let shouldRerenderChart = false;
    const { id } = state;
    shouldRerenderChart = shouldRerenderChart || id !== props.id;
    if (shouldRerenderChart) {
      this.setState({ id: props.id });
    }
    return null;
  }

  // componentWillReceiveProps(nextProps) {
  //   this.renderChart();
  //   let shouldRerenderChart = false;
  //   const { id } = this.state;
  //   shouldRerenderChart = shouldRerenderChart || id !== nextProps.id;
  //   if (shouldRerenderChart) {
  //     this.setState({ id: nextProps.id });
  //     this.renderChart();
  //   }
  // }

  getChartOptions() {
    const { t } = this.props;
    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      rangeSelector: {
        // enabled: false
        // allButtonsEnabled: true,
        // selected: 5,
        // inputDateFormat: '%Y-%m-%d',
        // inputEditDateFormat: '%Y-%m-%d',
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        tickInterval: 0.2,
        title: {
          text: `${t('ความเค็ม')} (g/L)`,
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'rgba(255, 139, 4, 1)',
            dashStyle: 'shortdash',
            width: 1,
            label: {
              text: null,
              align: 'left',
            },
          },
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 1,
            label: {
              text: null,
              align: 'left',
            },
          },
          {
            value: null,
            color: '#4eac5b', // เขียว
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: '#f6c143', // เหลือง
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: '#ea3323', // แดง
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'left',
            },
          },
          {
            value: null,
            color: '#b02318', // เลือดหมู
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: '#ea3323', // แดง
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
        plotBands: [],
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: t('ความเค็ม'),
          data: [],
          tooltip: {
            valueSuffix: ' (g/L)',
          },
        },
      ],
      exporting: {
        allowHTML: true,
        fallbackToExportServer: false,
        chartOptions: {
          fallbackToExportServer: true,
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false,
              },
            },
          },
        },
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };
    return this.options;
  }

  getWaterQualityLoad() {
    fetch(`${process.env.MIX_API_URL}analyst/waterquality_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataParam = result.waterquality_param.data;
        const dataStation = result.waterquality_station.data;

        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(
          currentDateTime.getDate() - 2,
        );

        const waterQualityLoad = result.data.data.filter(d => {
          const time = new Date(d.waterquality_datetime.replace(/-/g, '/')).getTime();
          return last24hrs < time;
        });

        this.setState({
          dataParam,
          dataStation,
          waterQualityLoad,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    if (chartCount >= 0) {
      const charts = Highcharts.charts[chartCount];
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.renderChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
    this.renderChart();
  };

  handleEndDateChange = date => {
    this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => { });
    this.renderChart();
  };

  handleStartTimeChange = date => {
    this.setState({ startTime: moment(date) });
    this.renderChart();
  };

  handleEndTimeChange = date => {
    this.setState({ endTime: moment(date) });
    this.renderChart();
  };

  handleParamChange = event => {
    this.setState({ paramSelect: event.target.value });
    this.renderChart();
  };

  handleStationChange = event => {
    this.setState({ stationSelect: event.target.value });
    this.renderChart();
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleChange = event => {
    if (event.target.name === 'stationSelect') {
      if (event.target.value.length <= 5) {
        this.setState({ [event.target.name]: event.target.value });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  handleGraphTypeChange = event => {
    graphType.map(type => {
      if (type.id === event.target.value) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.setState({ ...this.state, graphTypeSelectName: type.name }, () => this.showState());
      }
      return true;
    });

    this.setState({ isLoading: false });
    this.setState({ graphTypeSelect: event.target.value });
  };

  showState = () => {
    const { graphTypeSelect } = this.state;
    const {
      handleParameterModalOpen,
      handleDateTimeModalOpen,
      handleWaterlevelModalOpen,
      handleStationModalOpen,
    } = this.props;

    if (graphTypeSelect === 'param') {
      handleParameterModalOpen(this.state);
    } else if (graphTypeSelect === 'datetime') {
      handleDateTimeModalOpen(this.state);
    } else if (graphTypeSelect === 'waterlevel') {
      handleWaterlevelModalOpen(this.state);
    } else if (graphTypeSelect === 'station') {
      handleStationModalOpen(this.state);
    }
  };

  multiSelectStationRenderer = selected => {
    const { dataStation } = this.state;
    const stationName = [];
    selected.map(n => {
      const dd = dataStation.find(st => st.id === n);
      if (typeof dd !== 'undefined') {
        stationName.push(tJson(dd.waterquality_station_name, 'สถานี'));
      }
      return stationName;
    });

    return <span>{stationName.join(', ')}</span>;
  };

  findMaxValue = data => {
    const arrayMaxValue = [];
    const arrayMinValue = [];
    data.forEach(element => {
      const dataCleanValid = element.data.filter(x => x.value !== null);
      const max = maxBy(dataCleanValid, 'value').value;
      const min = minBy(dataCleanValid, 'value').value;
      arrayMaxValue.push(max);
      arrayMinValue.push(min);
    });
    const obj = {
      max: Math.max(...arrayMaxValue),
      min: Math.min(...arrayMinValue),
    };
    return obj;
  }

  renderChart() {
    const {
      stationSelect,
      startDate,
      endDate,
      paramSelect,
      dataParam,
    } = this.state;
    const { t } = this.props;
    fetch(
      `${process.env.MIX_API_URL}analyst/waterquality_compare_station_graph?waterquality_station_id=${stationSelect}&param=${paramSelect}&start_datetime=${startDate} 00:00&end_datetime=${endDate} 23:59`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        if (result.data[0]) {
          // fix case all value is null
          const dataClean = result.data[0].data.filter(x => x.value !== null);

          if (dataClean.length > 0) {
            this.setState({ dataChart: result.data[0] });
            // transform datas
            const dataSeries = [];
            let ts = null;
            let val = null;
            const findMaxMinData = this.findMaxValue(result.data);
            // find max, min
            const { max } = findMaxMinData;
            const { min } = findMaxMinData;
            // const max = maxBy(dataClean, 'value').value;
            // const min = minBy(dataClean, 'value').value;
            result.data.map(data => {
              const series = [];
              data.data.map(n => {
                val = parseFloat(n.value);
                ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss')
                  .add(7, 'hours')
                  .valueOf();
                if (val === max || val === min) {
                  const marker = {
                    enabled: false,
                    symbol: '',
                    fillColor: '#06C',
                    radius: 5,
                  };
                  series.push({ x: ts, y: val, marker });
                } else if (!Number.isNaN(val)) {
                  series.push([ts, val]);
                }
                return series;
              });
              dataSeries.push({
                name: `${tJson(data.series_name, 'สถานี')}`,
                data: series,
                tooltip: {
                  valueSuffix: ' ',
                  xDateFormat: '%d %B %Y %H:%M',
                },
              });
              return dataSeries;
            });
            // set chart options
            const chartOptions = this.getChartOptions();
            // chartOptions.yAxis.min = 0;
            chartOptions.yAxis.min = min;
            chartOptions.series = dataSeries;
            dataParam.map(row => {
              if (row.value === paramSelect) {
                chartOptions.yAxis.title.text = tJson(row.name, 'สถานี');
              }
              return chartOptions;
            });
            // for another parameter
            chartOptions.yAxis.max = 1 + max;

            // ความเค็ม
            if (paramSelect === 'salinity') {
              // plotLines
              if (max < 1.9) {
                chartOptions.yAxis.max = 2.01;
              }
              chartOptions.yAxis.plotLines[5].value = 2;
              chartOptions.yAxis.plotLines[5].label.text = `<strong>${t('มากกว่า 2 เกินเกณฑ์มาตรฐานน้ำใช้เพื่อการเกษตร')}</strong>`;
              chartOptions.yAxis.plotLines[4].value = 0.5;
              chartOptions.yAxis.plotLines[4].label.text = `<strong>${t('มากกว่า 0.5 เกินเกณฑ์คุณภาพน้ำเพื่อการผลิตน้ำประปา')}</strong>`;
              chartOptions.yAxis.plotLines[3].value = 0.25;
              chartOptions.yAxis.plotLines[3].label.text = `<strong>${t('มากกว่า 0.25 เกณฑ์เฝ้าระวังคุณภาพน้ำเพื่อการผลิตน้ำประปา')}</strong>`;
            }
            // ออกซิเจนในน้ำ
            if (paramSelect === 'do') {
              if (Math.floor(max) === 0) {
                chartOptions.yAxis.max = 2.01;
              } else {
                chartOptions.yAxis.max = 1.5 + max;
              }
              chartOptions.yAxis.plotLines[4].value = 2;
              chartOptions.yAxis.plotLines[4].label.text = `<strong>${t('น้อยกว่า 2 เกินเกณฑ์คุณภาพน้ำเพื่อการผลิตน้ำประปา')}</strong>`;
            }
            // กรด-ด่าง
            if (paramSelect === 'ph') {
              if (max < 5) {
                chartOptions.yAxis.max = 5;
              } else {
                chartOptions.yAxis.max = 1 + max;
              }
              chartOptions.yAxis.plotLines[4].value = 5;
              chartOptions.yAxis.plotLines[4].label.text = `<strong>${t('น้อยกว่า 5 เกินเกณฑ์คุณภาพน้ำเพื่อการผลิตน้ำประปา')}</strong>`;
              chartOptions.yAxis.plotLines[6].value = 9;
              chartOptions.yAxis.plotLines[6].label.text = `<strong>${t('มากกว่า 9 เกินเกณฑ์คุณภาพน้ำเพื่อการผลิตน้ำประปา')}</strong>`;
            }
            this.setState({ chartOptions, isLoading: false });
          } else {
            this.setState({ dataChart: undefined });
          }
        } else {
          this.setState({ dataChart: undefined });
          const chartOptions = this.getChartOptions();
          this.setState({ chartOptions, isLoading: false });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      graphTypeSelect,
      paramSelect,
      stationSelect,
      dataParam,
      dataStation,
      startDate,
      endDate,
      today,
      dataChart,
    } = this.state;

    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return <div className="text-center">{/* <FaSpinner size={70} /> */}</div>;
    }
    let info = '';
    if (dataChart) {
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {`*${t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}`}
        </div>
      );
    } else {
      info = '';
    }

    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('ชนิดกราฟ')}
              </InputLabel>
              <Select
                value={graphTypeSelect}
                onChange={this.handleGraphTypeChange}
              >
                {graphType.map(row => (
                  <MenuItem key={row.id} value={row.id}>
                    {t(row.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <FormControl>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('พารามิเตอร์')}
              </InputLabel>
              <Select value={paramSelect} onChange={this.handleParamChange}>
                {dataParam.map(row => (
                  <MenuItem key={row.value} value={row.value}>
                    {tJson(row.name, 'พารามิเตอร์')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station">
                {t('สถานีคุณภาพน้ำ')}
              </InputLabel>
              <Select
                multiple
                value={stationSelect}
                onChange={this.handleChange}
                input={<Input id="station" />}
                renderValue={this.multiSelectStationRenderer}
                MenuProps={MenuProps}
                inputProps={{
                  name: 'stationSelect',
                  id: 'station',
                }}
              >
                <MenuItem disabled>
                  <ListItemText primary="การปะปานครหลวง" />
                </MenuItem>
                {dataStation.filter(i => (i.agency_id === 23)).map(row => (
                  <MenuItem key={row.id} value={row.id}>
                    <Checkbox checked={stationSelect.indexOf(row.id) > -1} />
                    <ListItemText primary={tJson(row.waterquality_station_name, 'สถานี')} />
                  </MenuItem>
                ))}
                <MenuItem disabled>
                  <ListItemText primary="กรมควมคุมมลพิษ" />
                </MenuItem>
                {dataStation.filter(i => (i.agency_id === 14)).map(row => (
                  <MenuItem key={row.id} value={row.id}>
                    <Checkbox checked={stationSelect.indexOf(row.id) > -1} />
                    <ListItemText primary={tJson(row.waterquality_station_name, 'สถานี')} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  minDate={startDate}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <FormControl className={classes.formControl}>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                size="small"
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {dataChart ? (
              // constructorType={'stockChart'}
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            ) : (
              <div className="text-center">{t('ไม่มีข้อมูลในช่วงเวลาที่เลือก')}</div>
            )}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

WaterQualityStationChart.propTypes = {
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  handleStationModalOpen: PropTypes.func.isRequired,
  handleParameterModalOpen: PropTypes.func.isRequired,
  handleDateTimeModalOpen: PropTypes.func.isRequired,
  handleWaterlevelModalOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(WaterQualityStationChart);
