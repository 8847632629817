/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// lodash
import {
  uniqueId,
} from 'lodash';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

// style, css
import { styles } from '../../styles/Style';

// utils
import urlCheck from '../../utils/UrlstatusCheck';

class GsImageLatest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
      data: [],
      lightboxImage: [],
    };
    this.initialState = { ...this.state };
  }

  componentDidMount() {
    this.getImageLasted();
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  getImageLasted() {
    const monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dataLasted = [];
    const dataLightbox = [];

    const url = `${process.env.MIX_APP_URL}proxy/json.php?file=${process.env.MIX_API_HII_URL}/v2/ws/${process.env.MIX_API_HII_TOKEN}/gsmap-asean/latest`;
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(async result => {
        for (let i = 0; i < result.data.length; i += 1) {
          const [year, month, day] = result.data[i].date.split('-');
          dataLasted.push({
            url: await urlCheck(result.data[i].url, 'type_any', 500) ? result.data[i].url : '',
            date: `${monthsName[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`,
          });
        }
        dataLasted.map(datas => {
          if (datas.url !== '') {
            dataLightbox.push({
              url: datas.url,
              date: datas.date,
            });
          }
        });
        this.setState({ lightboxImage: dataLightbox });
        this.setState({ data: dataLasted });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  render() {
    const { classes } = this.props;
    const {
      isOpen, photoIndex, data, lightboxImage,
    } = this.state;
    return (
      <>
        <Grid container spacing={2} style={{ paddingTop: 20 }}>
          {data.map((result, i) => (
            <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
              <Box mt={1} className={classes.boxWeatherHistory}>
                <Box display="block" align="center">
                  <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })} disabled={result.url === ''}>
                    <Img
                      src={`${process.env.MIX_APP_URL}proxy/png.php?file=${result.url}`}
                      className={classes.responsive}
                      alt="GsMapImageLasted"
                      unloader={(
                        <Img
                          src="https://via.placeholder.com/576x516?text=No%20Image"
                          alt="No Image"
                          className={classes.responsive}
                        />
                      )}
                    />
                  </Button>
                </Box>
                <Box p={2} display="flex" alignItems="center" justifyContent="center">
                  <Typography className={classes.dateTime}>
                    {result.date}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={lightboxImage[photoIndex].url}
            nextSrc={lightboxImage[(photoIndex + 1) % lightboxImage.length].url}
            prevSrc={lightboxImage[(photoIndex + lightboxImage.length - 1) % lightboxImage.length].url}
            imageTitle={lightboxImage[photoIndex].date}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + lightboxImage.length - 1) % lightboxImage.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % lightboxImage.length,
              }))}
          />
        )}
      </>
    );
  }
}

GsImageLatest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(GsImageLatest);
