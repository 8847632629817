/* eslint-disable prefer-destructuring */
/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId,
} from 'lodash';
import moment from 'moment';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';

// map
import L from 'leaflet';
import {
  Map, Marker, Popup, TileLayer, Tooltip, GeoJSON, LayerGroup, LayersControl, WMSTileLayer,
} from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import tJson from '../../utils/TJson';

// color
import deepOrange from '@material-ui/core/colors/deepOrange';
import { FaChartLine } from '../../utils/Fontawesome';
import { styles } from '../../styles/Style';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

import iconCircle from '../widgets/map/iconCircle';

import WlContext from '../../contexts/wlContext';
import WaterGateChart from '../water_gate/WaterGateChart';

// css
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import CurrentLocation from '../currentlocation/CurrentLocation';

const initPath = process.env.MIX_APP_PATH;
const { BaseLayer, Overlay } = LayersControl;
const initPathWMS = process.env.MIX_APP_WMS;

class WaterSitWaterGateMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.waterGateLayer = createRef();
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    this.state = {
      error: '',
      height: this.setHeightMap(),
      geojsonProvince: [],
      geojsonBasin: [],
    };

    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.getGeoJson = this.getGeoJson.bind(this);
    // this.layersControlFunc = this.layersControlFunc(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;

    this._mounted = true;

    this.getGeoJson();
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
    // L.control.scale().addTo(this.map);
  }

  shouldComponentUpdate(nextProps) {
    const {
      datas,
      areaType,
      areaId,
      language,
    } = this.props;
    if ((datas === nextProps.datas)
      && (areaType === nextProps.areaType)
      && (language === nextProps.i18n.language)
      && (areaId === nextProps.areaId)) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const { areaType, areaId } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    let obj = this.refBoundaryProvince.current.leafletElement._layers;

    // thailand
    if (areaId === '') {
      const { location, zoom } = this.props;
      // this.map.options.zoomSnap = 5.65;
      this.map.setView(location, zoom);

      return;
    }

    if (areaType !== prevProps.areaType || areaId !== prevProps.areaId) {
      if (areaType === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }

      // get feature
      // eslint-disable-next-line no-underscore-dangle

      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          // change zoom snap to make fitBounds work
          // this.map.options.zoomSnap = 0.8;

          // province
          if (areaType === '0') {
            const provCode = obj[key].feature.properties.prov_code;
            if (provCode.toString() === areaId) {
              this.map.fitBounds(obj[key].getBounds());
            }
          } else { // basin
            const basinCode = obj[key].feature.properties.BASIN_CODE;
            if (basinCode === areaId) {
              this.map.fitBounds(obj[key].getBounds());
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 593;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 593;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 593;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 550;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle(feature) {
    const { areaType, areaId } = this.props;

    if (areaType === '1' && areaId) {
      if (feature.properties.BASIN_CODE === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getProvinceStyle(feature) {
    const { areaType, areaId } = this.props;
    if (areaType === '0' && areaId) {
      if (feature.properties.prov_code === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getGeoJson() {
    Promise.all([
      fetch(`${initPath}json/boundary/thailand.json`),
      fetch(`${initPath}json/boundary/basin.json`),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([province, basin]) => {
        this.setState({ geojsonProvince: province, geojsonBasin: basin });
      })
      .catch(error => this.setState({ error }));
  }

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;
    const params = {
      header: t('กราฟระดับน้ำที่ปตร./ฝาย'),
      content: <WaterGateChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  formatWl = value => (typeof value === 'undefined' || value == null ? '-' : parseFloat(value).toFixed(2))

  eachData = (data, i) => {
    const { zoom, datas, classes, t } = this.props;

    if (data.station.tele_station_lat && data.station.tele_station_long != null) {
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
      parseFloat(data.station.tele_station_long)];

      // marker icons
      let icon = iconCircle.gold;
      if (data.watergate_datetime_in < moment().format('YYYY-MM-DD')) {
        icon = iconCircle.grey;
      }

      let location = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        location = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        location += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        location += `${tJson(data.geocode.province_name, 'จ.')}`;
      }

      const station = (tJson(data.station.tele_station_name, 'สถานี')).split(' ');
      let stationList = tJson(data.station.tele_station_name, 'สถานีั');
      if (station.length > 1 && station[0].search('สถานีโทรมาตร') > -1) {
        stationList = station[1];
      }
      if (stationList === '') {
        stationList = tJson(data.station.tele_station_name, 'สถานี');
      }

      return (
        <Marker
          id={data.station.id}
          key={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          classes={classes}
        >
          <Popup autoPan={false}>
            <div>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} className={classes.hiddenLine} padding="none" align="center">
                      <Typography variant="subtitle2">
                        {/* {`${data.station.tele_station_name.th}`} */}
                        {`${stationList}`}
                      </Typography>
                      <Typography variant="subtitle2">
                        {location}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับน้ำเหนือ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>
                        {this.formatWl(data.watergate_in)}
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ระดับน้ำท้าย')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>
                        {this.formatWl(data.watergate_out)}
                      </strong>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>ที่ตั้ง</TableCell>
                    <TableCell align="right" colSpan={2}>
                      {location}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>พิกัด</TableCell>
                    <TableCell align="right" colSpan={2}>
                      {`${parseFloat(data.station.tele_station_lat).toFixed(3)},
                      {`${parseFloat(data.station.tele_station_lat).toFixed(3)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}><hr /></TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`${t('หน่วย')} : ${t('(ม.รทก.)')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {`${t('ข้อมูล')}: ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {data.watergate_datetime_in}
                        {' '}
                        {'น.        '}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <WlContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleChart(handleModalOpen, data)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </WlContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={tJson(data.station.tele_station_name, 'สถานี')}>
              <span>
                <strong>
                  {tJson(data.station.tele_station_name, 'สถานี')}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                <br />
                {` ${tJson(data.geocode.province_name, 'จ.')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  render() {
    const {
      error, height, geojsonProvince, geojsonBasin,
    } = this.state;
    const {
      location, zoom, datas, t,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <div className="text-center">{error.message}</div>
      );
    }

    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          gestureHandling
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonBasin}
            style={this.getBasinStyle}
            ref={this.refBoundaryBasin}
            onEachFeature={this.onEachBasinFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            onEachFeature={this.onEachProvinceFeature}
          />
          {/* <GeoJSON
            key={uniqueId()}
            data={geojsonRiver}
            style={this.getRiverStyle()}
            onEachFeature={this.onEachRiverFeature}
          /> */}
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Esri_WorldStreetMap">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <Overlay key="watergate" name={t('ระดับน้ำปตร')} checked="true">
              <LayerGroup ref={this.waterGateLayer}>
                {datas.map(this.eachData)}
              </LayerGroup>
            </Overlay>
            <Overlay name={t('เส้นแม่น้ำ')} checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
          </LayersControl>
          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />
        </Map>
      </>
    );
  }
}

WaterSitWaterGateMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  areaType: PropTypes.string.isRequired,
  areaId: PropTypes.string.isRequired,
  language: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(WaterSitWaterGateMap);
