import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// map
import L from 'leaflet';
import {
  Map, Marker, TileLayer, GeoJSON, LayerGroup, LayersControl, Tooltip, WMSTileLayer,
} from 'react-leaflet';
import { Hidden } from '@material-ui/core';
import Control from 'react-leaflet-control';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';

import { styles } from '../../../styles/Style';

import iconImage from '../../widgets/map/iconImage';

import FloodForecastChart from './FloodForecastChart';

// css
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import CurrentLocation from '../../currentlocation/CurrentLocation';

const initPath = process.env.MIX_APP_PATH;
const initPathWMS = process.env.MIX_APP_WMS;
const { Overlay } = LayersControl;

const banner = `${initPath}images/models/banner-water-flash.png`;

class FloodForecastMap extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.layer = createRef();
    this.layer1 = createRef();
    this.layer3 = createRef();

    this.state = {
      error: '',
      geojsonBasin: [],
      hover: false,
    };

    this.getGeoJson = this.getGeoJson.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.highlightFeature = this.highlightFeature.bind(this);
    this.resetHighlight = this.resetHighlight.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.bindMarker = this.bindMarker.bind(this);
    this.handleBoxToggle = this.handleBoxToggle.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;
    this.getGeoJson();
    this._mounted = true;
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '15%';
    this.map = this.refMap.current.leafletElement;
    handleSetMap(this.map);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  getGeoJson() {
    // Promise.all([
    //   fetch('json/river/river_main.json'),
    //   fetch('json/boundary/basin.json'),
    // ])
    //   .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    //   .then(([basin]) => {
    //     this.setState({ geojsonBasin: basin });
    //   })
    //   .catch(error => this.setState({ error }));

    fetch('json/boundary/basin.json')
      .then(res => res.json())
      .then(basin => {
        this.setState({ geojsonBasin: basin });
      })
      .catch(error => this.setState({ error }));
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle = () => ({
    stroke: true,
    color: '#434348',
    weight: 1,
    fillColor: '#b1b1f7',
    opacity: 1,
    fillOpacity: 0.3,
  })

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  handleRainfallChart = (id, type, teleStationData, teleStationThis) => () => {
    const {
      handleModalOpen, t,
    } = this.props;
    const params = {
      header: t('กราฟคาดการณ์น้ำท่า-ระดับน้ำในแม่น้ำสายหลัก'),
      content: <FloodForecastChart
        name={id}
        type={type}
        teleStation={teleStationData}
        teleStationThis={teleStationThis}
      />,
    };
    handleModalOpen(params);
  }

  eachData = (data, i) => {
    const {
      zoom, datas, classes, t,
    } = this.props;

    if (data[3] && data[4] != null) {
      // marker position

      const position = [parseFloat(data[3]), parseFloat(data[4])];

      // marker icons
      const icon = iconImage.waterLevel;

      const location = '';

      return (
        <Marker
          id={data.code}
          key={i}
          position={position}
          icon={icon}
          onClick={this.handleRainfallChart(data[0], 'wl', datas, data)}
          ref={this.bindMarker(data[0])}
          location={location}
          zoom={zoom}
          classes={classes}
        >
          {/* <Popup autoPan={false}>
            <span>
              <strong>
                {`สถานี ${data[2]}`}
              </strong>
              <br />
              {`${data[11]} ${data[12]} ${data[13]}`}
            </span>
            <FloodForecastChart
              name={data[0]}
              type="wl"
              teleStation={datas}
              teleStationThis={data}
            />
          </Popup> */}
          <Hidden smDown>
            <Tooltip title={`${data[0]} ${t('สถานี')} ${data[2]}`}>
              <span>
                <strong>
                  {`${data[0]} ${t('สถานี')} ${data[2]}`}
                </strong>
                <br />
                {`${data[11]} ${data[12]} ${data[13]}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  eachDataDischarge = (data, i) => {
    const { zoom, classes, datasDischarge } = this.props;

    if (data[3] && data[4] != null) {
      // marker position
      const position = [parseFloat(data[3]), parseFloat(data[4])];

      // marker icons
      const icon = iconImage.WlDischarge;

      const location = '';

      return (
        <Marker
          id={data.code}
          key={i}
          position={position}
          icon={icon}
          onClick={this.handleRainfallChart(data[0], 'discharge', datasDischarge, data)}
          ref={this.bindMarker(data[0])}
          location={location}
          zoom={zoom}
          classes={classes}
        >
          {/* <Popup autoPan={false}>
            <span>
              <strong>
                {`สถานี ${data[2]}`}
              </strong>
              <br />
              {`${data[11]} ${data[12]} ${data[13]}`}
            </span>
            <FloodForecastChart
              name={data[0]}
              type="discharge"
              teleStation={datasDischarge}
              teleStationThis={data}
            />
          </Popup> */}
          <Hidden smDown>
            <Tooltip title={`${data[0]} สถานี ${data[2]}`}>
              <span>
                <strong>
                  {`${data[0]} สถานี ${data[2]}`}
                </strong>
                <br />
                {`${data[11]} ${data[12]} ${data[13]}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  highlightFeature = e => {
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 0.6,
      stroke: true,
      color: '#434348',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    layer.bindTooltip(`<h4>${layer.feature.properties.BASIN_T}</h4>`).openTooltip();
  }

  resetHighlight = e => {
    this.refBoundaryBasin.current.leafletElement.resetStyle(e.target);
  }

  onEachBasinFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });
  }

  handleBoxToggle = () => this.setState({ hover: true });

  handleMouseOut() {
    this.setState({ hover: false });
  }

  render() {
    const {
      error, hover, geojsonBasin,
    } = this.state;
    const {
      location, zoom, datas, datasDischarge, t,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <div className="text-center">{error.message}</div>
      );
    }
    // console.log('geojsonRiver', geojsonRiver);
    // console.log('geojsonBasin', geojsonBasin);

    const tooltipStyle = {
      display: hover ? 'block' : 'none',
      maxWidth: '337px',
      padding: '10px',
      wordBreak: 'break-all',
    };
    const { classes } = this.props;
    return (
      <>
        {errorMsg}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          ref={this.refMap}
          style={{
            height: window.height,
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
          }}
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          />
          <CurrentLocation mapRef={this.refMap} showOnly={false} />
          <LayersControl position="topleft">
            <Overlay key="floodForecast1" name={t('ระดับน้ำ (ม.รทก.)')} checked="true">
              <LayerGroup ref={this.layer}>
                {datas.map(this.eachData)}
              </LayerGroup>
            </Overlay>
            <Overlay key="floodForecast2" name={t('น้ำท่า (ลบ.ม./ว)')} checked="true">
              <LayerGroup ref={this.layer1}>
                {datasDischarge.map(this.eachDataDischarge)}
              </LayerGroup>
            </Overlay>
            <Overlay key="floodForecast3" name={t('ลุ่มน้ำ')} checked="true">
              <LayerGroup ref={this.layer3}>
                <GeoJSON
                  key={uniqueId()}
                  data={geojsonBasin}
                  style={this.getBasinStyle()}
                  ref={this.refBoundaryBasin}
                  onEachFeature={(feature, layer) => this.onEachBasinFeature(feature, layer)}
                />
              </LayerGroup>
            </Overlay>
            <Overlay name={t('เส้นแม่น้ำ')} checked>
              <WMSTileLayer
                url={`${initPathWMS}wms`}
                version="1.1.0"
                opacity={1}
                transparent
                layers="layers=thaiwater30:river_thailand"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
          </LayersControl>
          {/* <GeoJSON
            key={uniqueId()}
            data={geojsonRiver}
            style={this.getRiverStyle()}
          /> */}
          <img src={banner} alt="banner" className={classes.styleBanner} />
          <Control position="topleft">
            <div
              style={{
                backgroundColor: 'white', padding: '8px', borderRadius: '9px', width: '3em',
              }}
              onBlur={() => this.setState({ hover: true })}
              onFocus={() => this.setState({ hover: false })}
              onMouseEnter={this.handleBoxToggle}
              onMouseOut={this.handleMouseOut}
            >
              Info
            </div>
            <Paper>
              <div style={tooltipStyle}>
                {t(`- ระบบคาดการณ์น้ำท่วมเป็นระบบคาดการณ์ปริมาณน้ำท่า และระดับน้ำ ชึ่งทำงานอย่างอัตโนมัติภายใต้ระบบปฏิบัติการสนับสนุนการตัดสินใจ (DSS Platform)

-ระบบแบบจำลองประกอบด้วย แบบจำลองอุทกศาสตร์ (NAM model) และแบบจำลองอุทกพลศาสตร์ (MIKE 11 HD model)

- คาดการณ์ปริมาณน้ำท่าและระดับน้ำล่วงหน้าได้ 7 วัน ณ ตำแหน่งสถานีตรวจวัดที่สำคัญในลำน้ำสายหลักของ พื้นที่ลุ่มน้ำเจ้าพระยา, ลุ่มน้ำชี-มูล, ลุ่มน้ำภาคตะวันออก และลุ่มน้ำภาคตะวันตก-ภาคใต้  เพื่อใช้เป็นข้อมูลสนับสนุนการบริหารจัดการน้ำ ครอบคลุมพื้นที่ 77% ของประเทศไทย

-มีการประยุกต์ใช้เทคนิค Data assimilation (DA) ช่วยในการปรับปรุงความถูกต้องของการคาดการณ์ปริมาณน้ำท่าและระดับน้ำ จำนวน 91 สถานีตรวจวัดที่มีการคาดการณ์`)}
              </div>
            </Paper>
          </Control>
        </Map>
      </>
    );
  }
}

FloodForecastMap.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  datasDischarge: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  // geojsonRiver: PropTypes.object.isRequired,
  // geojsonBasin: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles), withTranslation(),
)(FloodForecastMap);
