import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    maxHeight: 440,
    borderRadius: 6,
    border: '1px solid #ccc',
  },
  table: {
    minWidth: 650,
  },
});

function createData(no, set, nameEn, nameTh, meaning, from) {
  return { no, set, nameEn, nameTh, meaning, from };
}

const rows = [
  createData(1,1,'นิสรรคะ','Nisarga','จักรวาล, ธรรมชาติ, การสร้างสรรค์','บังกลาเทศ'),
  createData(2,1,'คติ','Gati','การเคลื่อนที่, ความเร็ว, สถานการณ์ที่ไม่ดี','อินเดีย'),
  createData(3,1,'นีวอร์','Nivar','บรรยากาศ, อากาศที่หุ้มห่อโลก','อิหร่าน'),
  createData(4,1,'บูเรวี','Burevi','ฝาดขาว (ไม้ต้นชนิดหนึ่ง ขึ้นตามป่าชายเลนหรือป่าชายหาด)','มัลดีฟส์'),
  createData(5,1,'เตาะแต่','Tauktae','ตุ๊กแก','พม่า'),
  createData(6,1,'ยาส','Yaas','พืชดอกชนิดหนึ่ง มีชื่อวิทยาศาสตร์ว่า Myrtus communis อยู่ในวงศ์ชมพู่','โอมาน'),
  createData(7,1,'กุลาบ','Gulab','กุหลาบ, น้ำแช่กลีบกุหลาบ','ปากีสถาน'),
  createData(8,1,'ชาฮีน','Shaheen','เหยี่ยวเพเรกริน (Falco peregrinus)','กาตาร์'),
  createData(9,1,'ญะวาด','Jawad','ชื่อบุคคลชายในภาษาอาหรับ แปลว่า "ใจกว้าง", "สูงส่ง", ม้าตัวผู้ที่ใช้เป็นพ่อพันธุ์, "ม้าขี่", "ม้าแข่ง"','ซาอุดีอาระเบีย'),
  createData(10,1,'อสนิ','Asani','สายฟ้า, ฟ้าแลบ','ศรีลังกา'),
  createData(11,1,'ศรีตรัง','Sitrang','ไม้ต้น 2 ชนิดในวงศ์แคหางค่าง ใบเป็นฝอยเล็ก ชนิดหนึ่งมีดอกสีม่วง อีกชนิดหนึ่งมีดอกสีม่วงปนน้ำเงิน','ไทย'),
  createData(12,1,'มันดูส','Mandous','หีบไม้พื้นบ้านชนิดหนึ่ง ใช้ใส่ของมีค่า','สหรัฐอาหรับเอมิเรตส์'),
  createData(13,1,'มุคอ','Mocha','ชื่อเมืองท่าในเขตผู้ว่าการตะอิซซ์ เมล็ดกาแฟสายพันธุ์หนึ่งมีชื่อตามชื่อเมืองนี้','เยเมน'),
  createData(14,2,'พิปรรยัย','Biparjoy','ภัยพิบัติ','บังกลาเทศ'),
  createData(15,2,'เตช','Tej','วิญญาณ, พลัง, อำนาจ, ความสุกใส, ความเปล่งปลั่ง, ความร้อน, ไฟ','อินเดีย'),
  createData(16,2,'ฮอมูน','Hamoon','ที่ราบ, ที่ต่ำ, บริเวณแห้งแล้ง, คำเรียกหนองน้ำตื้น ๆ ในพื้นที่แห้งแล้งบริเวณพรมแดนระหว่างอิหร่าน อัฟกานิสถาน และปากีสถาน ในบางฤดูกาลน้ำอาจแห้งเหือดไปหมด','อิหร่าน'),
  createData(17,2,'มิดิลี','Midhili','หูกวาง (ไม้ต้นชนิดหนึ่ง ใบ ผล และเปลือกไม้ใช้เป็นยาแก้โรคบิด)','มัลดีฟส์'),
  createData(18,2,'มิจ้อง','Michaung','จระเข้','พม่า'),
  createData(19,2,'ริมาล','Remal','ผืนทราย, ทะเลทราย','โอมาน'),
  createData(20,2,'อัสนา','Asna','ชื่อบุคคลหญิงในภาษาอูรดู แปลว่า "ที่รัก", "ผู้ได้รับการยอมรับหรือยกย่อง"','ปากีสถาน'),
  createData(21,2,'ดานะฮ์','Dana','ชื่อบุคคลหญิงในภาษาอาหรับ แปลว่า "ไข่มุกเม็ดใหญ่"','กาตาร์'),
  createData(22,2,'ฟินญาล','Fengal','ถ้วยกาแฟ','ซาอุดีอาระเบีย'),
  createData(23,2,'ศักติ','Shakhti','อำนาจ, กำลัง, หอก, หลาว','ศรีลังกา'),
  createData(24,2,'มณฑา','Montha','ไม้พุ่มชนิดหนึ่ง ใบใหญ่ ดอกสีเหลืองนวล กลิ่นหอม','ไทย'),
  createData(25,2,'ซินยาร','Senyar','การออกเดินเรือทีละลำต่อกันเป็นขบวน','สหรัฐอาหรับเอมิเรตส์'),
  createData(26,2,'ดีฏวะห์','Ditwah','ชื่อทะเลสาบน้ำเค็มชายฝั่งบนเกาะโซโคตรา เป็นพื้นที่ชุ่มน้ำตามอนุสัญญาแรมซาร์','เยเมน'),
  createData(27,3,'อรรณพ','Arnab','ห้วงนํ้า, ทะเล, มหาสมุทร','บังกลาเทศ'),
  createData(28,3,'มุรจุ','Murasu','กลองชนิดหนึ่ง มีต้นกำเนิดจากรัฐทมิฬนาฑู','อินเดีย'),
  createData(29,3,'แอกวอน','Akvan','ชื่อตัวละครในมหากาพย์เรื่อง ชอฮ์นอเม ของเปอร์เซีย สามารถหายตัว จำแลงร่างเป็นม้า หรือกลายร่างเป็นพายุได้','อิหร่าน'),
  createData(30,3,'กานี','Kaani','หมันทะเล (ไม้ต้นชนิดหนึ่ง เนื้อไม้อ่อนแต่ทนปลวก เมล็ดกินได้)','มัลดีฟส์'),
  createData(31,3,'งะม่าน','Ngamann','ปลาฉลาม','พม่า'),
  createData(32,3,'ซัยล์','Sail','น้ำท่วม, กระแสน้ำหลาก','โอมาน'),
  createData(33,3,'ซะฮาบ','Sahab','เมฆ','ปากีสถาน'),
  createData(34,3,'ลุอ์ลุอ์','Lulu','ไข่มุก','กาตาร์'),
  createData(35,3,'เฆาะซีร','Ghazeer','มากมาย, ฝนห่าใหญ่, ฝนตกหนัก','ซาอุดีอาระเบีย'),
  createData(36,3,'คิคุม','Gigum','สายฟ้า','ศรีลังกา'),
  createData(37,3,'เทียนหยด','Thianyot','ไม้พุ่มชนิดหนึ่ง ดอกสีม่วง ผลเป็นเม็ดกลมขนาดเล็กคล้ายหยดเทียน','ไทย'),
  createData(38,3,'อาฟูร','Afoor','พายุหมุนทราย','สหรัฐอาหรับเอมิเรตส์'),
  createData(39,3,'ดีกซัม','Diksam','ชื่อที่ราบสูงบนเกาะโซโคตรา','เยเมน'),
  createData(40,4,'อุปกูล','Upakul','ชายทะเล, ชายฝั่ง, ฝั่งน้ำตลิ่ง','บังกลาเทศ'),
  createData(41,4,'อาค','Aag','ไฟ, เปลวไฟ','อินเดีย'),
  createData(42,4,'เซแพนด์','Sepand','พืชล้มลุกชนิดหนึ่ง มีชื่อวิทยาศาสตร์ว่า Peganum harmala ใช้เป็นยาแผนโบราณในอิหร่าน เอเชียกลาง ตะวันออกกลาง และแอฟริกาเหนือ, ชื่อภูเขาในมหากาพย์เรื่อง ชอฮ์นอเม ของเปอร์เซีย','อิหร่าน'),
  createData(43,4,'ออดี','Odi','เรือพื้นบ้านชนิดหนึ่งของชาวมัลดีฟส์','มัลดีฟส์'),
  createData(44,4,'จ้าติ','Kyarthit','เสือดาว','พม่า'),
  createData(45,4,'นะซีม','Naseem','ลมเฉื่อย, กลิ่น, กลิ่นหอม','โอมาน'),
  createData(46,4,'อัฟชาน','Afshan','ชื่อบุคคลหญิงในภาษาอูรดู แปลว่า "ที่กระจัดกระจาย", "ประปราย", "เปล่งปลั่ง", "สุกใส"','ปากีสถาน'),
  createData(47,4,'เมาจญ์','Mouj','คลื่น, กระแสน้ำเชี่ยวกราก','กาตาร์'),
  createData(48,4,'อาศิฟ','Asif',"'ที่มีลมพัดแรง, ที่มีลมกระโชก, (ทะเล) ที่มีคลื่นลมแรง'",'ซาอุดีอาระเบีย'),
  createData(49,4,'คคนะ','Gagana','ท้องฟ้า','ศรีลังกา'),
  createData(50,4,'บุหลัน','Bulan','พระจันทร์','ไทย'),
  createData(51,4,'นะฮ์ฮาม','Nahhaam','ลูกเรือหามุกที่มีหน้าที่ขับร้องเพื่อกระตุ้นและให้กำลังใจลูกเรือคนอื่น ๆ ขณะทำงานบนเรือหรือลงไปดำน้ำหามุก','สหรัฐอาหรับเอมิเรตส์'),
  createData(52,4,'ศีเราะฮ์','Sira','ชื่อป้อมเก่าแก่ในเขตผู้ว่าการเอเดน','เยเมน'),
  createData(53,5,'พรรษณ','Barshon','ฝน, ปริมาณฝน','บังกลาเทศ'),
  createData(54,5,'วโยม','Vyom','ท้องฟ้า','อินเดีย'),
  createData(55,5,'บูรอน','Booran','พายุหิมะ','อิหร่าน'),
  createData(56,5,'เกเนา','Kenau','เสื่อ','มัลดีฟส์'),
  createData(57,5,'ซะบะจี้','Sapakyee','งูหลาม','พม่า'),
  createData(58,5,'มุซน์','Muzn','เมฆฝน','โอมาน'),
  createData(59,5,'มะนาฮิล','Manahil','ชื่อบุคคลหญิงในภาษาอูรดู แปลว่า "แหล่งนํ้าพุ", "แหล่งนํ้าซับ"','ปากีสถาน'),
  createData(60,5,'ซุฮัยล์','Suhail','ดาวคาโนปัสในกลุ่มดาวกระดูกงูเรือ เป็นดาวนำทางที่สำคัญของชาวอาหรับมาตั้งแต่อดีต','กาตาร์'),
  createData(61,5,'ซิดเราะฮ์','Sidrah','ไม้ต้นชนิดหนึ่ง มีชื่อวิทยาศาสตร์ว่า Ziziphus spina-christi อยู่ในวงศ์พุทรา','ซาอุดีอาระเบีย'),
  createData(62,5,'เวรัมภะ','Verambha','พายุ, ลมหัวด้วน (ลมพายุที่เกิดขึ้นอย่างปัจจุบันทันด่วนแล้วหายไป)','ศรีลังกา'),
  createData(63,5,'ภูตลา','Phutala','พื้นโลก, แผ่นดิน','ไทย'),
  createData(64,5,'กุฟฟาล','Quffal','การกลับบ้าน, การกลับสู่ภูมิลำเนาของชาวเรือในช่วงสิ้นสุดฤดูทำประมงและหามุก (ประมาณเดือนกันยายน)','สหรัฐอาหรับเอมิเรตส์'),
  createData(65,5,'บะคูร','Bakhur','เครื่องหอมชนิดหนึ่ง ทำจากไม้หอมชิ้นเล็ก ๆ แช่น้ำมันหอมระเหยและผสมกำยานเป็นต้น ใช้วางบนถ่านที่เผาจนแดงเพื่อเอาควันอบห้อง','เยเมน'),
  createData(66,6,'รชนี','Rajani','เวลาคํ่า, กลางคืน','บังกลาเทศ'),
  createData(67,6,'ฌร','Jhar','พายุ, พายุหมุน','อินเดีย'),
  createData(68,6,'ออนอฮีทอ','Anahita','ชื่อเทพธิดาแห่งความอุดมสมบูรณ์ การรักษา และภูมิปัญญาในประมวลเรื่องปรัมปราเปอร์เซีย','อิหร่าน'),
  createData(69,6,'เอ็นเดรี','Endheri','กะละปังหาดำ (กะละปังหาชนิดต่าง ๆ ในอันดับ Antipatharia)','มัลดีฟส์'),
  createData(70,6,'แวะวูน','Wetwun','หมี','พม่า'),
  createData(71,6,'ซะดีม','Sadeem','‎‎‎‎หมอกน้ำค้าง, เนบิวลา','โอมาน'),
  createData(72,6,'ชุจานา','Shujana','ชื่อบุคคลหญิงในภาษาอูรดู แปลว่า "กล้าหาญ"','ปากีสถาน'),
  createData(73,6,'เศาะดัฟ','Sadaf','เปลือกหอย, หอยมุก','กาตาร์'),
  createData(74,6,'ฮะรีด','Hareed','ปลาทะเลชนิดหนึ่ง มีชื่อวิทยาศาสตร์ว่า Hipposcarus harid อยู่ในวงศ์ปลานกแก้ว (Scaridae), ชื่อสถานที่ในแคว้นฮาอิล','ซาอุดีอาระเบีย'),
  createData(75,6,'ครรชนะ','Garjana','เสียงคำราม, เสียงกระหึ่ม, สายฟ้า','ศรีลังกา'),
  createData(76,6,'ไอยรา','Aiyara','ช้าง','ไทย'),
  createData(77,6,'ดามัน','Daaman','เชือกหรือโซ่ที่ผูกมุมด้านล่างของใบเรือไว้กับตัวเรือเพื่อขึงหรือผ่อนใบเรือให้สัมพันธ์กับทิศทางลม','สหรัฐอาหรับเอมิเรตส์'),
  createData(78,6,'ฆุวัยซี','Ghwyzi','ชื่อป้อมเก่าแก่ในเขตผู้ว่าการฮัฎเราะเมาต์','เยเมน'),
  createData(79,7,'นิศีถ','Nishith','เที่ยงคืน, ดึกสงัด','บังกลาเทศ'),
  createData(80,7,'ประพาหะ','Probaho','การไหล, กระแส (ของน้ำหรืออากาศ)','อินเดีย'),
  createData(81,7,'ออแซร์','Azar','ไฟ, ชื่อเดือนที่เก้าตามปฏิทินสุริยคติของอิหร่านและอัฟกานิสถาน','อิหร่าน'),
  createData(82,7,'ริเยา','Riyau','ใบเรือ','มัลดีฟส์'),
  createData(83,7,'มเวเฮาะ','Mwaihout','งูเห่า','พม่า'),
  createData(84,7,'ดีมะฮ์','Dima','ฝนที่ตกอย่างต่อเนื่องโดยไม่มีฟ้าคะนอง, เมฆที่ก่อให้เกิดฝนตกต่อเนื่อง','โอมาน'),
  createData(85,7,'ปาร์วาซ','Parwaz','การบิน, เที่ยวบิน','ปากีสถาน'),
  createData(86,7,'รีม','Reem','สัตว์กีบคู่ชนิด Gazella marica และชนิด Gazella subgutturosa รูปร่างคล้ายกวาง แต่อยู่ในวงศ์วัวและควาย กวีนิพนธ์อาหรับมักพรรณนาความงามของดวงตาสัตว์สองชนิดนี้','กาตาร์'),
  createData(87,7,'ฟัยด์','Faid','ชื่อเมืองและแหล่งโบราณคดีในแคว้นฮาอิล','ซาอุดีอาระเบีย'),
  createData(88,7,'นีบา','Neeba','','ศรีลังกา'),
  createData(89,7,'สมิง','Saming','เสือที่เชื่อว่าเดิมเป็นคนที่มีวิชาอาคมแก่กล้าจนสามารถจำแลงร่างเป็นเสือได้, เสือที่กินคนมาก ๆ เข้า เชื่อกันว่าวิญญาณคนตายเข้าสิงจนสามารถจำแลงร่างเป็นคนได้','ไทย'),
  createData(90,7,'ดีม','Deem','ฝนที่ตกอย่างต่อเนื่องโดยไม่มีฟ้าคะนอง, เมฆที่ก่อให้เกิดฝนตกต่อเนื่อง','สหรัฐอาหรับเอมิเรตส์'),
  createData(91,7,'เฮาฟ์','Hawf','ชื่ออำเภอในเขตผู้ว่าการอัลมะฮ์เราะฮ์','เยเมน'),
  createData(92,8,'อูรมิ','Urmi','คลื่น','บังกลาเทศ'),
  createData(93,8,'นีระ','Neer','น้ำ','อินเดีย'),
  createData(94,8,'พูยอน','Pooyan','ชื่อบุคคลชายในภาษาเปอร์เซีย แปลว่า "ที่มีชีวิตชีวา", "ที่ยืนหยัด", "ที่ดื้อดึง", "ผู้เสาะหา"','อิหร่าน'),
  createData(95,8,'กูรูวา','Guruva','ปลาข้างตะเภาลายโค้ง (Terapon jarbua)','มัลดีฟส์'),
  createData(96,8,'จแว','Kywe','ควาย','พม่า'),
  createData(97,8,'มันญูร','Manjour','รอกไม้ขนาดใหญ่ ใช้ตักน้ำจากบ่อ, เครื่องดนตรีชนิดหนึ่ง ทำจากกีบเท้าแพะหลายกีบเย็บติดกับผืนผ้า','โอมาน'),
  createData(98,8,'ซันนาตา','Zannata','เสียงดังหวือ, เสียงวัตถุเสียดสีกับอากาศอย่างรวดเร็ว','ปากีสถาน'),
  createData(99,8,'ร็อยฮาน','Rayhan','โหระพา','กาตาร์'),
  createData(100,8,'กะซีร','Kaseer','หัก','ซาอุดีอาระเบีย'),
  createData(101,8,'นินนาทะ','Ninnada','ความกึกก้อง, การบันลือ','ศรีลังกา'),
  createData(102,8,'ไกรสร','Kraison','สิงโต','ไทย'),
  createData(103,8,'ก็อรกูร','Gargoor','อุปกรณ์ดักปลาพื้นบ้านชนิดหนึ่ง ทำจากลวดถักเป็นตาร่างแหรูปโดม ใช้วางไว้ที่พื้นทะเล','สหรัฐอาหรับเอมิเรตส์'),
  createData(104,8,'บาลฮาฟ','Balhaf','ชื่อเมืองท่าในเขตผู้ว่าการชับวะฮ์','เยเมน'),
  createData(105,9,'เมฆลา','Meghala','ที่มีเมฆมาก, ที่มีเมฆคลุม','บังกลาเทศ'),
  createData(106,9,'ประภัญชนะ','Prabhanjan','พายุหมุน','อินเดีย'),
  createData(107,9,'ออร์ชอม','Arsham','พระนามของพระอัยกาจักรพรรดิดาไรอัสมหาราชแห่งจักรวรรดิอะคีเมนิด (เปอร์เซียโบราณ) แปลว่า "ซึ่งมีกำลังของวีรบุรุษ"','อิหร่าน'),
  createData(108,9,'กูรันกี','Kurangi','นกทะเลชนิด Anous tenuirostris ในวงศ์นกนางนวล','มัลดีฟส์'),
  createData(109,9,'ปิ่นกู','Pinku','แมงมุม','พม่า'),
  createData(110,9,'รุกาม','Rukam','(ก้อนเมฆ, ผู้คน) ที่รวมตัวกันหนาแน่น','โอมาน'),
  createData(111,9,'ซาร์ซาร์','Sarsar','เสียงดังแซ่ก ๆ, เสียงดังกรอบแกรบ, เสียงสัตว์กำลังเลื้อยหรือคลาน','ปากีสถาน'),
  createData(112,9,'','','ลมร้อนหรือพายุหมุนร้อน',''),
  createData(113,9,'อันบัร','Anbar','อำพันขี้ปลา','กาตาร์'),
  createData(114,9,'นะคีล','Nakheel','ปาล์มชนิดต่าง ๆ ในสกุลอินทผลัม','ซาอุดีอาระเบีย'),
  createData(115,9,'วิทุลิ','Viduli','สายฟ้า, ฟ้าแลบ, ไฟฟ้า','ศรีลังกา'),
  createData(116,9,'มัจฉา','Matcha','ปลา','ไทย'),
  createData(117,9,'ค็อบบ์','Khubb','ความปั่นป่วน (ของทะเลหรืออากาศ)','สหรัฐอาหรับเอมิเรตส์'),
  createData(118,9,'บุรูม','Brom','ชื่อหมู่บ้านในเขตผู้ว่าการฮัฎเราะเมาต์','เยเมน'),
  createData(119,10,'สมีรณ','Samiron','ลม, ลมเฉื่อย, อากาศ','บังกลาเทศ'),
  createData(120,10,'ฆูรณิ','Ghurni','น้ำวน, พายุหมุน','อินเดีย'),
  createData(121,10,'เฮงกอเม','Hengame','ชื่อบุคคลหญิงในภาษาเปอร์เซีย แปลว่า "ชุมนุมชน", "ความโกลาหลหรือความอัศจรรย์ที่น่าทึ่ง"','อิหร่าน'),
  createData(122,10,'กูเรดี','Kuredhi','เทียนเล (ไม้ต้นขนาดเล็กชนิดหนึ่ง ขึ้นตามป่าชายหาด)','มัลดีฟส์'),
  createData(123,10,'ยีนกอง','Yinkaung','แมลงวัน','พม่า'),
  createData(124,10,'วะตัด','Watad','หมุดไม้, ลิ่มไม้, ซี่รั้วไม้','โอมาน'),
  createData(125,10,'บาดบาน','Badban','ใบเรือ, กลุ่มดาวใบเรือ','ปากีสถาน'),
  createData(126,10,'อูด','Oud','ไม้กฤษณา, เครื่องดนตรีประเภทเครื่องสายชนิดหนึ่ง นิยมเล่นในเอเชียตะวันตกเฉียงใต้','กาตาร์'),
  createData(127,10,'ฮะบูบ','Haboob','พายุ (โดยเฉพาะพายุฝุ่นหรือพายุทรายในบริเวณแห้งแล้ง)','ซาอุดีอาระเบีย'),
  createData(128,10,'โอฆะ','Ogha','น้ำท่วม, กระแสน้ำหลาก','ศรีลังกา'),
  createData(129,10,'มหิงสา','Mahingsa','ควาย','ไทย'),
  createData(130,10,'ดะก็อล','Degl','เสากระโดงเรือ','สหรัฐอาหรับเอมิเรตส์'),
  createData(131,10,'ชุกรออ์','Shuqra','ชื่อเมืองในเขตผู้ว่าการอับยัน','เยเมน'),
  createData(132,11,'ประติกูล','Pratikul','ที่เป็นปรปักษ์, ที่เป็นผลร้าย, ไม่เอื้ออำนวย','บังกลาเทศ'),
  createData(133,11,'อัมพุท','Ambud','"ผู้ให้นํ้า หมายถึง เมฆ','อินเดีย'),
  createData(134,11,'แซวอส','Savas','รองเท้าสานพื้นบ้านชนิดหนึ่งจากภูมิภาคบาลูจิสถาน ทำจากใบของปาล์มชนิด Nannorrhops ritchiana','อิหร่าน'),
  createData(135,11,'ฮอรันกู','Horangu','','มัลดีฟส์'),
  createData(136,11,'ลี่นโยน','Linyone','เหยี่ยวรุ้ง (เหยี่ยวขนาดใหญ่ชนิดหนึ่ง กินสัตว์ขนาดเล็ก โดยเฉพาะงู)','พม่า'),
  createData(137,11,'อัลญัรซ์','Al-jarz','ขวานพื้นบ้านชนิดหนึ่งจากเขตผู้ว่าราชการมุซันดัม ใบขวานเล็ก ด้ามขวานยาว, ที่โล่งเตียนเนื่องจากพืชพรรณบริเวณนั้นถูกแรงพายุพัดถอนรากถอนโคน','โอมาน'),
  createData(138,11,'ซะราบ','Sarrab','ภาพลวงตา (อย่างในทะเลทรายหรือบนถนนเป็นต้น)','ปากีสถาน'),
  createData(139,11,'บะฮัร','Bahar','ทะเล','กาตาร์'),
  createData(140,11,'บาริก','Bareq','แวบวับ, วาววับ, สุกใส, อร่าม','ซาอุดีอาระเบีย'),
  createData(141,11,'สลิตะ','Salitha','ที่เคลื่อนไหว, ที่สั่นไหว','ศรีลังกา'),
  createData(142,11,'แพรวา','Phraewa','ผ้าทอเป็นผืนที่มีความยาว 1 วา หรือ 1 ช่วงแขน ชาวผู้ไทใช้คลุมไหล่หรือห่มสไบเฉียง','ไทย'),
  createData(143,11,'อัษมัด','Athmad','พลวง, สินแร่พลวง, ผงทาขอบตาหรือเปลือกตาให้มีสีเข้ม มีส่วนผสมของพลวง','สหรัฐอาหรับเอมิเรตส์'),
  createData(144,11,'ฟัรตัก','Fartak','ชื่อภูเขาในเขตผู้ว่าการอัลมะฮ์เราะฮ์','เยเมน'),
  createData(145,12,'สโรพร','Sarobor','ทะเลสาบ, บึงหรือสระขนาดใหญ่','บังกลาเทศ'),
  createData(146,12,'ชลธี','Jaladhi','ผู้อุ้มน้ำไว้ หมายถึง ทะเล, มหาสมุทร','อินเดีย'),
  createData(147,12,'แทแฮมแทน','Tahamtan','ฉายานามของโรสแทม วีรบุรุษคนหนึ่งในประมวลเรื่องปรัมปราเปอร์เซีย แปลว่า "ที่มีรูปร่างกำยำ", "ที่มีพลัง"','อิหร่าน'),
  createData(148,12,'ตุนดี','Thundi','สันดอนจะงอยทราย','มัลดีฟส์'),
  createData(149,12,'จี้ก้าน','Kyeekan','นกกา','พม่า'),
  createData(150,12,'เราะบาบ','Rabab','เมฆขาว, เมฆที่เดี๋ยวครึ้มเดี๋ยวสว่าง, เครื่องดนตรีประเภทสายชนิดหนึ่ง นิยมเล่นในเอเชียตะวันตกเฉียงใต้และเอเชียกลาง','โอมาน'),
  createData(151,12,'กุลนาร์','Gulnar','ดอกทับทิม, สีแดงอย่างสีดอกทับทิม','ปากีสถาน'),
  createData(152,12,'ซีฟ','Seef','ชายทะเล, ชายฝั่ง, ฝั่งน้ำตลิ่ง','กาตาร์'),
  createData(153,12,'อัรรีม','Alreem','สัตว์กีบคู่ชนิด Gazella marica และชนิด Gazella subgutturosa รูปร่างคล้ายกวาง แต่อยู่ในวงศ์วัวและควาย กวีนิพนธ์อาหรับมักพรรณนาความงามของดวงตาสัตว์สองชนิดนี้','ซาอุดีอาระเบีย'),
  createData(154,12,'ริวิ','Rivi','พระอาทิตย์','ศรีลังกา'),
  createData(155,12,'อสุรี','Asuri','อสูร, ยักษ์','ไทย'),
  createData(156,12,'บูม','Boom','เรือใบพื้นบ้านชนิดหนึ่งของชาวอาหรับ ใช้ในการค้าขาย หามุก และทำประมงชายฝั่ง','สหรัฐอาหรับเอมิเรตส์'),
  createData(157,12,'ดัรซะฮ์','Darsah','ชื่อเกาะที่ไม่มีคนอยู่อาศัยในกลุ่มเกาะโซโคตรา','เยเมน'),
  createData(158,13,'มหานิศา','Mahanisha','เที่ยงคืน, ดึกสงัด','บังกลาเทศ'),
  createData(159,13,'เวค','Vega','ความเร็ว','อินเดีย'),
  createData(160,13,'ทูฟอน','Toofan','ไต้ฝุ่น, พายุหมุน, น้ำท่วมใหญ่','อิหร่าน'),
  createData(161,13,'ฟานา','Faana','ปลาทะเลหลายชนิดในวงศ์ปลาเก๋า','มัลดีฟส์'),
  createData(162,13,'เบาะพะ','Bautphat','หนอน','พม่า'),
  createData(163,13,'เราะอด์','Raad','สายฟ้า','โอมาน'),
  createData(164,13,'วาซิก','Waseq','แข็งแรง, ทนทาน, มั่นคง','ปากีสถาน'),
  createData(165,13,'ฟะนาร','Fanar','กระโจมไฟ, ประภาคาร','กาตาร์'),
  createData(166,13,'วาบิล','Wabil','ฝนห่าใหญ่, ฝนกระโชก','ซาอุดีอาระเบีย'),
  createData(167,13,'รุทุ','Rudu','โหดร้าย, ดุร้าย, รุนแรง','ศรีลังกา'),
  createData(168,13,'ธารา','Thara','สายนํ้า, ลำธาร, ห้วย, หยาดนํ้า, ท่อนํ้า','ไทย'),
  createData(169,13,'ซัฟฟาร','Saffar','เรือใบพื้นบ้านชนิดหนึ่งของชาวอาหรับ ใช้ในการค้าขายและเดินทางระยะไกล','สหรัฐอาหรับเอมิเรตส์'),
  createData(170,13,'ซัมฮะฮ์','Samhah','ชื่อเกาะที่ไม่มีคนอยู่อาศัยในกลุ่มเกาะโซโคตรา','เยเมน'),
];

export default function AcccessibleTable() {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('ลำดับที่')}</TableCell>
            <TableCell align="center">{t('ชุดที่')}</TableCell>
            <TableCell align="center">{t('ชื่อภาษาอังกฤษ')}</TableCell>
            <TableCell align="center">{t('ชื่อพายุ')}</TableCell>
            <TableCell align="center">{t('ความหมาย')}</TableCell>
            <TableCell align="center">{t('ประเทศที่มาของชื่อ')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row,index) => (
            <TableRow key={row.no} style={{ backgroundColor: (row.set % 2 == 0) ? '#ecf0f1' : '#fff' }}>
              <TableCell component="th" scope="row" align="center">{row.no}</TableCell>
              <TableCell component="th" scope="row" align="center">{row.set}</TableCell>
              <TableCell component="th" scope="row" align="left">{row.nameEn}</TableCell>
              <TableCell component="th" scope="row" align="left">{row.nameTh}</TableCell>
              <TableCell component="th" scope="row" align="left" style = {{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.meaning}</TableCell>
              <TableCell component="th" scope="row" align="left">{row.from}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
