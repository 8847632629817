import React, { useState, useEffect } from 'react';
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

function ImageSituation({ dataPopupReport }) {
  const history = useHistory();
  const [modalImage, setModalImage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const loadImage = async () => {
    if (dataPopupReport && Object.keys(dataPopupReport).length > 0) {
      const lastShown = getCookie('lightboxShow');
      if (!lastShown) {
        setIsOpen(true);
        setModalImage(`https://drive.google.com/thumbnail?sz=w1000&id=${dataPopupReport.id}`);
      }
    } else {
      setModalImage('');
    }
  };
  // Initial load
  useEffect(() => {
    loadImage();
  }, [dataPopupReport]);

  const setCookie = (name, value, minutes) => {
    // const expires = moment().add(hours, 'hours').utc().toString();
    const expires = moment().add(minutes, 'days').utc().toString();
    // const expires = moment().add(day, 'days').utc().toString();
    document.cookie = `${name}=${value};expires=${expires};path=/`;
  };

  const removeCookie = (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
  };

  // Function to get a cookie by name
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    if (newCheckedState) {
      setCookie('lightboxShow', 'true', 1);
    } else {
      removeCookie('lightboxShow');
    }
  };

  const navigateToReport = () => {
    const initPath = process.env.MIX_APP_PATH;
    history.push(`${initPath}report/situation`); // Navigate to the /report page
  };

  return (
    <>
      {
        modalImage &&
          isOpen ? (
          <Lightbox
            mainSrc={modalImage}
            imageCaption={[
              <Box display="flex" justifyContent="space-between">
                <Box>
                <label style={{ color: "white", lineHeight: "2" }}>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ marginRight: "8px",transform: "scale(1.3)" }}
                  />
                  ไม่แสดง 1 วัน
                </label>
                </Box>
                <Box>
                <Button
                style={{
                  backgroundColor: "orange",
                }}
                variant="contained"
                onClick={navigateToReport}>
                ไปหน้าแจ้งเตือนสถานการณ์
                </Button>
                </Box>
              </Box>
            ]}
            onCloseRequest={() => closeLightbox()}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: "9999999" } }}
            toolbarButtons={[
              <div>
                <div
                  key="custom-checkbox"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "20px",
                    zIndex: 2000,
                  }}
                >
                </div>
              </div>
            ]}
            
          />
        ) : null}
    </>
  )
}

export default ImageSituation;