// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';
// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { styles } from '../../../styles/Style';
// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

export default function StormSurgeForecastChart(props) {
  const { stationName } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const initPath = process.env.MIX_API_HII_URL;
  const [getError, setError] = useState();
  const [settingChart, setSettingChart] = useState({
    title: {
      text: 'Loading ...',
    },
    plotOptions: {
      line: {
        turboThreshold: 0,
      },
      series: {
        showInNavigator: true,
        gapSize: 6,
      },
    },
    chart: {
      zoomType: 'x',
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    navigator: {
      enabled: true,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%d-%b<br />%H:%M}',
      },
    },
    yAxis: [
      {
        labels: {
          format: '{value:.2f}  ม.',
        },
        title: {
          text: 'คลื่นพายุซัดฝั่ง (ม.) <br> คลื่นยกตัว (ม.)',
        },
        opposite: false,
      }, {
        gridLineWidth: 0,
        title: {
          text: 'ระดับน้ำทะเลสุทธิ (ม.รทก.) <br> ระดับน้ำขึ้นน้ำลง (ม.รทก.)',
        },
        labels: {
          format: '{value:.2f}  ม.รทก.',
        },
        opposite: true,
      },
    ],
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: ' ม.รทก.',
    },
    colors: ['#11625C', '#DFAE05', '#B6008C', '#7D02CD'],
    legend: {
      enabled: true,
      verticalAlign: 'bottom',
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: [
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
          ],
          y: -10,
          x: -10,
          align: 'left'
        },
      },
    },
    series: [],
  });

  function findMaxValue(data) {
    const arrayMaxValue = [];
    const arrayMinValue = [];
    const dataWlwsSeries = []; // ระดับน้ำทะเลสุทธิ
    const dataTideSeries = []; // ระดับน้ำขึ้นลง
    const dataSurgeSeries = []; // คลื่นพายุซัดฝั่ง
    const dataWaveSetupSeries = []; // คลื่นยกตัว
    let valWlWs = null;
    let valTide = null;
    let valSurge = null;
    let valWaveSetup = null;
    data.forEach(element => {
      valWlWs = parseFloat(element.wl_ws);
      valTide = parseFloat(element.tide);
      valSurge = parseFloat(element.surge);
      valWaveSetup = parseFloat(element.wave_setup);
      dataWlwsSeries.push(valWlWs);
      dataTideSeries.push(valTide);
      dataSurgeSeries.push(valSurge);
      dataWaveSetupSeries.push(valWaveSetup);
    });
    const maxWlWs = maxBy(dataWlwsSeries);
    const minWlWs = minBy(dataWlwsSeries);
    const arrayAllSeries = dataWlwsSeries.concat(
      dataTideSeries, dataSurgeSeries, dataWaveSetupSeries,
    );
    const dataCleanValid = arrayAllSeries.filter(x => x !== null);
    const max = maxBy(dataCleanValid);
    const min = minBy(dataCleanValid);
    arrayMaxValue.push(max);
    arrayMinValue.push(min);
    const obj = {
      max: Math.max(...arrayMaxValue),
      min: Math.min(...arrayMinValue),
      maxWlWs,
      minWlWs,
    };
    return obj;
  }

  function fetchData() {
    fetch(`${initPath}/tiservice/v1/ws/cEniGCuZcTBSa3xj4A8PY187BhpExTfE/model/stromsurge/station=${stationName}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        if (result.result === 'OK') {
          setSettingChart(
            {
              ...settingChart,
              title: {
                text: result.data.station_th || '',
              },
            },
          );
          const { series } = result.data;
          //   console.log('series => ', series);
          const dataWlwsSeries = []; // ระดับน้ำทะเลสุทธิ
          const dataTideSeries = []; // ระดับน้ำขึ้นลง
          const dataSurgeSeries = []; // คลื่นพายุซัดฝั่ง
          const dataWaveSetupSeries = []; // คลื่นยกตัว
          const criticallevel = series[0].warn_level;
          let valWlWs = null;
          let valTide = null;
          let valSurge = null;
          let valWaveSetup = null;
          let ts = null;
          const findMaxMinData = findMaxValue(series);
          const {
            max,
            min,
            maxWlWs,
            minWlWs,
          } = findMaxMinData;
          series.map(element => {
            valWlWs = parseFloat(element.wl_ws);
            valTide = parseFloat(element.tide);
            valSurge = parseFloat(element.surge);
            valWaveSetup = parseFloat(element.wave_setup);
            ts = moment(`${element.date} ${element.time}`, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
            if (valWlWs === maxWlWs || valWlWs === minWlWs) {
              const marker = {
                enabled: true,
                symbol: 'triangle',
                fillColor: '#06C',
                radius: 5,
              };
              dataWlwsSeries.push({ x: ts, y: valWlWs, marker });
            } else {
              dataWlwsSeries.push([ts, valWlWs]);
            }
            dataTideSeries.push([ts, valTide]);
            dataSurgeSeries.push([ts, valSurge]);
            dataWaveSetupSeries.push([ts, valWaveSetup]);
            return {
              dataWlwsSeries,
              dataTideSeries,
              dataSurgeSeries,
              dataWaveSetupSeries,
            };
          });
          setSettingChart(
            {
              ...settingChart,
              series: [
                {
                  name: 'ระดับน้ำทะเลสุทธิ',
                  data: dataWlwsSeries,
                  type: 'line',
                  yAxis: 1,
                  tooltip: {
                    valueSuffix: ' ม.รทก.',
                  },
                },
                {
                  name: 'ระดับน้ำขึ้นน้ำลง',
                  data: dataTideSeries,
                  type: 'line',
                  yAxis: 1,
                  visible: false,
                  tooltip: {
                    valueSuffix: ' ม.รทก.',
                  },
                },
                {
                  name: 'คลื่นพายุซัดฝั่ง',
                  data: dataSurgeSeries,
                  type: 'line',
                  yAxis: 0,
                  visible: false,
                  tooltip: {
                    valueSuffix: ' ม.',
                  },
                },
                {
                  name: 'คลื่นยกตัว',
                  data: dataWaveSetupSeries,
                  type: 'line',
                  yAxis: 0,
                  visible: false,
                  tooltip: {
                    valueSuffix: ' ม.',
                  },
                },
              ],
              yAxis: [
                {
                  labels: {
                    format: '{value:.2f}  ม.',
                  },
                  title: {
                    text: 'คลื่นพายุซัดฝั่ง (ม.) <br> คลื่นยกตัว (ม.)',
                  },
                  max,
                  min,
                  opposite: false,
                }, {
                  gridLineWidth: 0,
                  title: {
                    text: 'ระดับน้ำทะเลสุทธิ (ม.รทก.) <br> ระดับน้ำขึ้นน้ำลง (ม.รทก.)',
                  },
                  labels: {
                    format: '{value:.2f}  ม.รทก.',
                  },
                  plotLines: [
                    {
                      value: criticallevel || null,
                      color: 'red',
                      dashStyle: 'shortdash',
                      width: 2,
                      label: {
                        text: `เกณฑ์เฝ้าระวังระดับน้ำทะเลสุทธิ <strong>${parseFloat(criticallevel).toFixed(2)}</strong> ม.รทก.`,
                        align: 'left',
                      },
                    },
                  ],
                  opposite: true,
                },
              ],
            },
          );
        }
      })
      .catch(error => setError(error));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {getError && (
        <div className="text-center">
          {getError.message}
        </div>
      )}
      <div>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box py={{ xs: 0, sm: 2 }} className={classes.boxGraphDam}>
                <Box className={classes.graphDam}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={settingChart}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ color: '#A2A2A2' }}>
            *สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
          </div>
        </Grid>
      </div>
    </>
  );
}

StormSurgeForecastChart.propTypes = {
  stationName: PropTypes.string.isRequired,
};
