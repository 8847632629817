import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy,orderBy } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FaSpinner } from '../../utils/Fontawesome';

// translate
import tJson from '../../utils/TJson';

import {
  styles,
} from '../../styles/Style';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const currentYear = moment().year();
const yearsRange = (start, end) => Array.from({
  length: (start - end),
}, (v, k) => (start - k).toString());
const years = yearsRange(currentYear, 2011);

let enddate;
const currentTime = moment(new Date(), 'hh:mm');
const beforeTime = moment('00:00', 'hh:mm');
const afterTime = moment('08:00', 'hh:mm');
if (currentTime.isBetween(beforeTime, afterTime)) {
  enddate = moment(new Date()).locale('th').subtract(2, 'day').format('YYYY-MM-DD');
} else {
  enddate = moment(new Date()).locale('th').subtract(1, 'day').format('YYYY-MM-DD');
}
const initPath = process.env.MIX_APP_PATH;

class RainfallChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      max: 0,
      chartOptions: this.getChartOptions('rainfall24h'),
      dataProvince: [],
      provinceId: props.provinceId,
      stations: props.filterData,
      teleStation: [],
      startDate: moment().subtract(7, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: enddate,
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      type: props.selectedType,
      dataSeries: [],
      dataSeries2: [],
      yearSelect: props.year,
      monthSelect: props.month,
      dataRainfall24h:[],
      datasRainToday:[],
      datasRainYesterday:[],
      datasRainMonth:[],
      datasRainYear:[],
      datasRain3Day:[],
      datasRain5Day:[],
      datasRain7Day:[],
      datasRain15Day:[],

    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { selectedType } = this.props;
    this.getData();
    this.getProvince();
    this.getStation();
    this.setState({ type: selectedType }, () => {
      this.renderChart();
    });
  }

  // getStationFOP() {
  //   fetch(`${initPath}json/fop/FOP_MOU.json`)
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error('Something went wrong ...');
  //     })
  //     .then(result => {
  //       this.setState({ fopData: result });
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }
  
  getData() {
    let fopData = [];

    function matchDataFOP(paramRain, paramFop, type) {
      const updatedRainData = paramRain.map(rain => {
        const stationId = (type === 'rain_yesterday') ? rain.tele_station_id : rain.station.id;
        const matchingFop = paramFop.find(fop => fop.station_id === stationId);
        
        return {
          ...rain,
          sponsored_by: matchingFop ? matchingFop.sponsored_by : null
        };
      });
    
      return updatedRainData;
    }

    // get data fop station
    fetch(`${initPath}json/fop/FOP_MOU.json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        fopData = result;
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}public/rain_24h`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        const rainfall24Sort = orderBy(result.data, ['rain_24h'], ['desc']);

        let dataRainfall24h = rainfall24Sort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rain_24h >= 0.1);
        });

        dataRainfall24h = matchDataFOP(dataRainfall24h, FOP_DATA, 'rainfall24h');

        this.setState({
          dataRainfall24h,
          isLoading:false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}public/rain_today`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        let filterDatas = rainTodaySort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rainfall_value >= 0.1);
        });

        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain_today');

        this.setState({
          datasRainToday: filterDatas,
          isLoading:false,

        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}public/rain_yesterday`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        let filterDatas = rainTodaySort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rainfall_value >= 0.1);
        });

        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain_yesterday');

        this.setState({
          datasRainYesterday: filterDatas,
          isLoading:false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}public/rain_monthly`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const rainMonthSort = orderBy(result.data, ['rainfall_value'], ['desc']);
        let filterDatasMonth = rainMonthSort.filter(d => (d.rainfall_value >= 0.1));

        filterDatasMonth = matchDataFOP(filterDatasMonth, FOP_DATA, 'rain_monthly');

        this.setState({
          datasRainMonth: filterDatasMonth,
          isLoading:false,

        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}public/rain_yearly`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        let filterDatas = rainTodaySort.filter(d => {
          const rainfallValue = d.rainfall_value;
          return (rainfallValue >= 0.1);
        });

        filterDatas = matchDataFOP(filterDatas, FOP_DATA, 'rain_yearly');

        this.setState({
          datasRainYear: filterDatas,
          isLoading:false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}provinces/rain3d`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const rain3DaySort = orderBy(result.data, ['rain_3d'], ['desc']);
        const filterData = rain3DaySort.filter(d => d.rain_3d >= 0.1);

        let filterDatas = matchDataFOP(filterData, FOP_DATA, 'rain3d');

        this.setState({
          datasRain3Day: filterDatas,
          isLoading:false,

        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      fetch(`${process.env.MIX_API_URL}provinces/rain5d`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          const FOP_DATA = fopData;
          const rain5DaySort = orderBy(result.data, ['rain_5d'], ['desc']);
          const filterData = rain5DaySort.filter(d => d.rain_5d >= 0.1);
  
          let filterDatas = matchDataFOP(filterData, FOP_DATA, 'rain5d');
  
          this.setState({
            datasRain5Day: filterDatas,
          isLoading:false,

          });
        })
        .catch(error => this.setState({ error, isLoading: false }));
      
    fetch(`${process.env.MIX_API_URL}provinces/rain7d`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const rain7DaySort = result.data.slice().sort((a, b) => parseFloat(b) - parseFloat(a));
        const filterData = rain7DaySort.filter(d => d.rain_7d >= 0.1);

        let filterDatas = matchDataFOP(filterData, FOP_DATA, 'rain7d');

        this.setState({
          datasRain7Day: filterDatas,
          isLoading:false,
          
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_API_URL}provinces/rain15d`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const FOP_DATA = fopData;
        const rain15DaySort = orderBy(result.data, ['rain_15d'], ['desc']);
        const filterData = rain15DaySort.filter(d => d.rain_15d >= 0.1);

        let filterDatas = matchDataFOP(filterData, FOP_DATA, 'rain15d');

        this.setState({
          datasRain15Day: filterDatas,
          isLoading:false,

        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getChartOptions(type) {
    const { t } = this.props;
    let formatDateX = '{value:%d-%b<br />%H:%M}';
    let formatDateTooltip = '%d %B %y %H:%m น.';
    if (type === 'rainfallMonth' || type === 'rainfallYesterday') {
      formatDateX = '{value:%d-%b-%y}';
    }
    if (type === 'rainfallMonth' || type === 'rainfallYesterday' || type === 'rainfall3Day' || type === 'rainfall5Day' || type === 'rainfall7Day' || type === 'rainfall15Day') {
      formatDateTooltip = '%d %B %Y';
    }
    if (type === 'rainfallYear') {
      formatDateTooltip = '%B %Y';
    }

    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      rangeSelector: {
        // enabled: false
        // inputDateFormat: '%Y-%d-%m',
        // inputEditDateFormat: '%Y-%d-%m',
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: formatDateX,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          enabled: true,
          text: t('ปริมาณฝน (มม.)'),
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        alternateGridColor: null,
        labels: {
          format: '{value:.1f}',
        },
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: t('มม.'),
        xDateFormat: formatDateTooltip,
      },
      series: [
        {
          name: '',
          data: [],
          type: 'column',
          color: '#0066FF',
          tooltip: {
            valueSuffix: t('มม.'),
            format: '{value:.1f}',
          },
        },
        {
          name: '',
          data: [],
          type: 'spline',
          tooltip: {
            valueSuffix: t('มม.'),
            format: '{value:.1f}',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getChartOptions2(type) {
    const { t } = this.props;
    let formatDateX = '{value:%d-%b<br />%H:%M}';
    let formatDateTooltip = '%d %B %y %H:%m น.';
    if (type === 'rainfallMonth' || type === 'rainfallYesterday') {
      formatDateX = '{value:%d-%b-%y}';
    }
    if (type === 'rainfallMonth' || type === 'rainfallYesterday' || type === 'rainfall3Day' || type === 'rainfall5Day' || type === 'rainfall7Day' || type === 'rainfall15Day') {
      formatDateTooltip = '%d %B %Y';
    }
    if (type === 'rainfallYear') {
      formatDateTooltip = '%B %Y';
    }

    this.options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: formatDateX,
        },
      },
      yAxis: {
        title: {
          enabled: true,
          text: t('ปริมาณฝน (มม.)'),
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        alternateGridColor: null,
        labels: {
          format: '{value:.1f}',
        },
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: t('มม.'),
        xDateFormat: formatDateTooltip,
      },
      series: [
        {
          name: '',
          data: [],
          type: 'column',
          color: '#0066FF',
          tooltip: {
            valueSuffix: t('มม.'),
            format: '{value:.1f}',
          },
        },
        // {
        //   name: '',
        //   data: [],
        //   type: 'spline',
        //   tooltip: {
        //     valueSuffix: {t(''มม.')},
        //     format: '{value:.1f}',
        //   },
        // },
      ],
    };

    return this.options;
  }


  getStation() {
    this.setState({ isLoading: true });
    const {
      provinceId, id, type, stations,
    } = this.state;
    const teleStation = [];
    const teleStationAll = [];
    const selectedType = type;    
    
    if (selectedType === 'rainfallMonth' || selectedType === 'rainfallYear' || selectedType === 'rainfallYesterday' || selectedType === 'rainfall3Day' || selectedType === 'rainfall5Day' || selectedType === 'rainfall7Day' || selectedType === 'rainfall15Day') {            
      stations.map(row => {
        if (selectedType === 'rainfallMonth' || selectedType === 'rainfallYear' || selectedType === 'rainfall3Day' || selectedType === 'rainfall5Day' || selectedType === 'rainfall7Day' || selectedType === 'rainfall15Day' ) {
          if (row.geocode.province_code === provinceId) {
            teleStation.push(row.station);
          }
          teleStationAll.push(row.station);
        }
        if (selectedType === 'rainfallYesterday') {
          if (row.province_code === provinceId) {
            teleStation.push(row);
          }
          teleStationAll.push(row);
        }
        return null;
      });
      if (!id) {        
        if (teleStation.length > 0) {
          if (selectedType === 'rainfallMonth' || selectedType === 'rainfallYear' || selectedType === 'rainfall3Day' || selectedType === 'rainfall5Day' || selectedType === 'rainfall7Day' || selectedType === 'rainfall15Day') {
            this.handleStationChangeDefault(teleStation[0].id);
          }
          if (selectedType === 'rainfallYesterday') {
            this.handleStationChangeDefault(teleStation[0].tele_station_id);
            
          }
        }
      }
      this.setState({
        teleStation,
        isLoading: false,
      });
      this.renderChart();
    } else {
      fetch(`${process.env.MIX_API_URL}public/thailand_main_rain?province_code=${provinceId}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          result.data.map(row => {
            if (row.agency.agency_shortname.en !== 'DWR') {
              teleStation.push(row.station);
            }
            teleStationAll.push(row.station);
            return null;
          });
          if (!id) {
            if (teleStation.length > 0) {
                this.setState({
                  id: teleStation[0].id,
                });
            }
          }

          this.setState({
            teleStation,
            isLoading: false,
          });
          this.renderChart();
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }
  }

  getProvince() {
    this.setState({ isLoading: true });
    // fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
    fetch(`${process.env.MIX_API_URL}iframe/rain24`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataProvince = result.province.data
          .filter(d => d.province_name.th !== 'อื่นๆ')
          .sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));
        this.setState({
          dataProvince,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value, id: '' }, () => {
      this.getStation();
    });
  };

  handleTypeChange = event => {
    const { dataRainfall24h,datasRainToday,datasRain3Day,datasRain5Day,datasRain7Day,datasRain15Day,datasRainMonth,datasRainYear,datasRainYesterday } =this.state;
    const rainfallData = {
      rainfall24h: dataRainfall24h,
      rainfallToday: datasRainToday,
      rainfallYesterday: datasRainYesterday,
      rainfall3Day: datasRain3Day,
      rainfall5Day: datasRain5Day,
      rainfall7Day: datasRain7Day,
      rainfall15Day: datasRain15Day,
      rainfallMonth: datasRainMonth,
      rainfallYear: datasRainYear
    };
    this.setState({ type: event.target.value, stations: rainfallData[event.target.value]}, () => {
      this.getStation();
      this.handleChart();
    });
    
  };

  handleStationChangeDefault = idNum => {
    this.setState({ id: idNum }, () => {
      this.handleChart();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
      
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => {
      this.handleChart();
    });
  };

  handleMonthChange = event => {
    this.setState({ monthSelect: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleYearChange = event => {
    this.setState({ yearSelect: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const { t } = this.props;
    const {
      id, type, startDate, endDate, yearSelect, monthSelect,
    } = this.state;
    this.setState({ isLoading: true });

    if (id !== '') {
      let link = 'rain_24h_graph';
      if (type === 'rainfall24h') {
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            const ds1 = [];
            const ds2 = [];
            let ts = null;
            let val = null;
            let val2 = 0;

            const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
            this.setState({
              max,
            });

            result.data.map(n => {
              val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(1)) * 1;

              ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();

              ds1.push([ts, val]);

              val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(1) * 1) : null;

              ds2.push([ts, val2]);

              this.setState({ dataSeries: ds1, dataSeries2: ds2 });

              return null;
            });
            // const { dataSeries2, dataSeries } = this.state;
            const { dataSeries } = this.state;
            const chartOptions = this.getChartOptions2(type);
            chartOptions.series[0].data = dataSeries;
            chartOptions.series[0].name = t('ปริมาณฝนรายชั่วโมง');
            // chartOptions.series[1].data = dataSeries2;
            // chartOptions.series[1].name = 'ปริมาณสะสม 24 ชั่วโมง';

            this.setState({ chartOptions, isLoading: false });
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallToday') {
        link = 'rain_today_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const dataSeries = [];
              let ts = null;
              let val = null;

              const max = maxBy(result.data, 'rainfall') === undefined ? 0 : maxBy(result.data, 'rainfall').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall) === '' || (n.rainfall) === null) ? null : (parseFloat(n.rainfall).toFixed(1)) * 1;
                // val = (parseFloat(n.rainfall).toFixed(2)) * 1;
                ts = moment(n.date_time, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
                dataSeries.push([ts, parseFloat(val)]);
                return null;
              });

              const chartOptions = this.getChartOptions2(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝนวันนี้');
              // chartOptions.series[1].visible = false;

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallYesterday') {
        link = 'rain_yesterday_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}&start_date=${startDate}&end_date=${endDate}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              const ds2 = [];
              let ts = null;
              let val = null;
              let val2 = 0;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(1)) * 1;
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
                ds1.push([ts, val]);
                val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(1) * 1) : null;
                ds2.push([ts, val2]);
                this.setState({ dataSeries: ds1, dataSeries2: ds2 });
                return null;
              });

              const { dataSeries2, dataSeries } = this.state;
              const chartOptions = this.getChartOptions(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝนรายวัน');
              chartOptions.series[1].data = dataSeries2;
              chartOptions.series[1].name = t('ปริมาณฝนสะสมรายวัน');

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfall3Day') {
        fetch(
          `${process.env.MIX_API_URL}provinces/rain3d_graph?station_id=${id}&start_date=${startDate}&end_date=${moment(endDate).add(1, 'day').format('YYYY-MM-DD')}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              let ts = null;
              let val = null;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(1)) * 1;
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').subtract(17, 'hours').valueOf();

                ds1.push([ts, val]);
                this.setState({ dataSeries: ds1 });
                return null;
              });

              const { dataSeries } = this.state;
              const chartOptions = this.getChartOptions2(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝนสะสม 3 วัน');

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfall5Day') {
        fetch(
          `${process.env.MIX_API_URL}provinces/rain5d_graph?station_id=${id}&start_date=${startDate}&end_date=${moment(endDate).add(1, 'day').format('YYYY-MM-DD')}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              let ts = null;
              let val = null;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(1)) * 1;
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').subtract(17, 'hours').valueOf();

                ds1.push([ts, val]);
                this.setState({ dataSeries: ds1 });
                return null;
              });

              const { dataSeries } = this.state;
              const chartOptions = this.getChartOptions2(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝนสะสม 5 วัน');

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfall7Day') {
        fetch(
          `${process.env.MIX_API_URL}provinces/rain7d_graph?station_id=${id}&start_date=${startDate}&end_date=${moment(endDate).add(1, 'day').format('YYYY-MM-DD')}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              let ts = null;
              let val = null;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(1)) * 1;
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').subtract(17, 'hours').valueOf();

                ds1.push([ts, val]);
                this.setState({ dataSeries: ds1 });
                return null;
              });

              const { dataSeries } = this.state;
              const chartOptions = this.getChartOptions2(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝนสะสม 7 วัน');

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfall15Day') {
        fetch(
          `${process.env.MIX_API_URL}provinces/rain15d_graph?station_id=${id}&start_date=${startDate}&end_date=${moment(endDate).add(1, 'day').format('YYYY-MM-DD')}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              const ds2 = [];
              let ts = null;
              let val = null;
              let val2 = 0;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(1)) * 1;
                // ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD hh:mm:ss').subtract(17, 'hours').valueOf();
                ds1.push([ts, val]);
                val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(1) * 1) : null;
                ds2.push([ts, val2]);
                this.setState({ dataSeries: ds1, dataSeries2: ds2 });
                return null;
              });

              const { dataSeries } = this.state;
              const chartOptions = this.getChartOptions2(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝนสะสม 15 วัน');

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallMonth') {
        link = 'rain_monthly_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}&month=${monthSelect}&year=${yearSelect}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              const ds2 = [];
              let ts = null;
              let val = null;
              let val2 = 0;

              const max = maxBy(result.data, 'rainfall_value') === undefined ? 0 : maxBy(result.data, 'rainfall_value').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall_value) === '' || (n.rainfall_value) === null) ? null : (parseFloat(n.rainfall_value).toFixed(1)) * 1;
                ts = moment(n.rainfall_datetime, 'YYYY/MM/DD').add(7, 'hours').valueOf();
                ds1.push([ts, val]);
                val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(1) * 1) : null;
                ds2.push([ts, val2]);
                this.setState({ dataSeries: ds1, dataSeries2: ds2 });
                return null;
              });

              const { dataSeries2, dataSeries } = this.state;
              const chartOptions = this.getChartOptions(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝน');
              chartOptions.series[1].data = dataSeries2;
              chartOptions.series[1].name = t('ปริมาณฝนสะสม');

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      } else if (type === 'rainfallYear') {
        link = 'rain_yearly_graph';
        fetch(
          `${process.env.MIX_API_URL}public/${link}?station_id=${id}&year=${yearSelect}`,
        ).then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
          .then(result => {
            if (result.result === 'OK') {
              const ds1 = [];
              const ds2 = [];
              let ts = null;
              let val = null;
              let val2 = 0;
              const max = maxBy(result.data, 'rainfall') === undefined ? 0 : maxBy(result.data, 'rainfall').value;
              this.setState({
                max,
              });

              result.data.map(n => {
                val = ((n.rainfall) === '' || (n.rainfall) === null) ? null : (parseFloat(n.rainfall).toFixed(1)) * 1;
                val = (parseFloat(n.rainfall).toFixed(1)) * 1;
                ts = moment(n.date_time, 'YYYY/MM/DD').add(7, 'hours').valueOf();
                ds1.push([ts, val]);
                val2 = (val >= 0) ? (parseFloat(val + val2).toFixed(1) * 1) : null;
                ds2.push([ts, val2]);
                this.setState({ dataSeries: ds1, dataSeries2: ds2 });
                return null;
              });
              const { dataSeries2, dataSeries } = this.state;
              const chartOptions = this.getChartOptions(type);
              chartOptions.series[0].data = dataSeries;
              chartOptions.series[0].name = t('ปริมาณฝน');
              chartOptions.series[1].data = dataSeries2;
              chartOptions.series[1].name = t('ปริมาณฝนสะสม');

              this.setState({ chartOptions, isLoading: false });
            } else {
              const chartOptions = 0;
              this.setState({ chartOptions, isLoading: false });
            }
          })
          .catch(error => this.setState({ error, isLoading: false }));
      }
    } else {
      const chartOptions = 0;
      this.setState({ chartOptions, isLoading: false });
    }
  }

  renderSponsor(id) {
    const { teleStation, type } = this.state;
    let sponsorMode = false;
    let sponsorText = '';
    if (type === 'rainfallYesterday') {
      teleStation.forEach(element => {
        if (element.tele_station_id === id && element.sponsor_by) {
          sponsorMode = true;
          sponsorText = element.sponsor_by;
        }
      });
    } else {
      teleStation.forEach(element => {
        if (element.id === id && element.sponsor_by) {
          sponsorMode = true;
          sponsorText = element.sponsor_by;
        }
      });
    }
    return sponsorMode ? `สนับสนุนโดย ${sponsorText}` : null;
  }

  render() {
    const {
      dataProvince,
      id,
      provinceId,
      teleStation,
      type,
      isLoading,
      error,
      chartOptions,
      max,
      startDate,
      endDate,
      today,
      yearSelect,
      monthSelect,
    } = this.state;
    const {
      classes, t, i18n,
    } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
            {error}
          </Typography>
        </Paper>
      );
    }
    let chart = '';
    let info = '';

    if (chartOptions === 0 || max === 0) {
      chart = <Paper className={classes.paper}>{t('ไม่มีข้อมูล')}</Paper>;
      info = '';
    } else {
      chart = <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {this.renderSponsor(id)}
          <br />
          {'*'}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    }
    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl} style={{ whiteSpace: 'nowrap' }}>
              <InputLabel htmlFor="type">{t('ประเภทข้อมูล')}</InputLabel>
              <Select
                value={type}
                onChange={this.handleTypeChange}
                inputProps={{
                  name: 'selectedType',
                  id: 'type',
                }}
                style={{ minWidth: 140 }}
              >
                  <MenuItem value="rainfall24h">{t('ฝน 24 ชั่วโมง')}</MenuItem>
                  <MenuItem value="rainfallToday">{t('ฝนวันนี้')}</MenuItem>
                  <MenuItem value="rainfallYesterday">{t('ฝนวานนี้')}</MenuItem>
                  <MenuItem value="rainfall3Day">{t('ฝน 3 วัน')}</MenuItem>
                  <MenuItem value="rainfall5Day">{t('ฝน 5 วัน')}</MenuItem>
                  <MenuItem value="rainfall7Day">{t('ฝน 7 วัน')}</MenuItem>
                  <MenuItem value="rainfall15Day">{t('ฝน 15 วัน')}</MenuItem>
                  <MenuItem value="rainfallMonth">{t('ฝนรายเดือน')}</MenuItem>
                  <MenuItem value="rainfallYear">{t('ฝนรายปี')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select
                value={provinceId}
                onChange={this.handleProvinceChange}
                style={{ minWidth: 140 }}
              >
                {dataProvince.map(row => (
                  <MenuItem key={row.province_code} value={row.province_code}>
                    {`${tJson(row.province_name, 'จ.')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <Select value={id} onChange={this.handleStationChange} style={{ minWidth: 200 }}>
                {(type === 'rainfallMonth' || type === 'rainfallYear') && (
                  teleStation.map(row => (
                    <MenuItem key={row.id} value={row.id}>
                      {`${tJson(row.tele_station_name, 'สถานี')}`}
                      {` (${row.tele_station_oldcode})`}
                    </MenuItem>
                  ))
                )}
                {(type === 'rainfall24h' || type === 'rainfallToday' || type === 'rainfall3Day' || type === 'rainfall5Day' || type === 'rainfall7Day' || type === 'rainfall15Day') && (
                  teleStation.map(row => (
                    <MenuItem key={row.id} value={row.id}>
                      {`${tJson(row.tele_station_name, 'สถานี')}`}
                      {' '}
                      {` (${row.tele_station_oldcode})`}
                    </MenuItem>
                  ))
                )}
                {(type === 'rainfallYesterday') && (
                  teleStation.map(row => (
                    <MenuItem key={row.tele_station_id} value={row.tele_station_id}>
                      {`${tJson(row.tele_station_name, 'สถานี')}`}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            {type === 'rainfallMonth' && (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="type">เดือน</InputLabel>
                <Select
                  value={monthSelect}
                  onChange={this.handleMonthChange}
                  inputProps={{
                    name: 'monthSelect',
                    id: 'monthSelect',
                  }}
                >
                  <MenuItem value="01">{t('มกราคม')}</MenuItem>
                  <MenuItem value="02">{t('กุมภาพันธ์')}</MenuItem>
                  <MenuItem value="03">{t('มีนาคม')}</MenuItem>
                  <MenuItem value="04">{t('เมษายน')}</MenuItem>
                  <MenuItem value="05">{t('พฤษภาคม')}</MenuItem>
                  <MenuItem value="06">{t('มิถุนายน')}</MenuItem>
                  <MenuItem value="07">{t('กรกฎาคม')}</MenuItem>
                  <MenuItem value="08">{t('สิงหาคม')}</MenuItem>
                  <MenuItem value="09">{t('กันยายน')}</MenuItem>
                  <MenuItem value="10">{t('ตุลาคม')}</MenuItem>
                  <MenuItem value="11">{t('พฤศจิกายน')}</MenuItem>
                  <MenuItem value="12">{t('ธันวาคม')}</MenuItem>
                </Select>
              </FormControl>
            )}
            {(type === 'rainfallMonth' || type === 'rainfallYear') && (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="type">ปี</InputLabel>
                <Select
                  value={yearSelect}
                  onChange={this.handleYearChange}
                  inputProps={{
                    name: 'yearSelect',
                    id: 'yearSelect',
                  }}
                  style={{ minWidth: 140 }}
                >
                  {years.map(y => (
                    <MenuItem key={y} value={y}>{y}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(type === 'rainfallYesterday' || type === 'rainfall3Day' || type === 'rainfall5Day' || type === 'rainfall7Day' || type === 'rainfall15Day') && (
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18next.language}>
                  <DatePicker
                    label={t('วันที่เริ่มต้น')}
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    value={startDate}
                    maxDate={today}
                    onChange={this.handleStartDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
            {(type === 'rainfallYesterday' || type === 'rainfall3Day' || type === 'rainfall5Day' || type === 'rainfall7Day' || type === 'rainfall15Day') && (
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18next.language}>
                  <DatePicker
                    label={t('วันที่สิ้นสุด')}
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    value={endDate}
                    maxDate={today}
                    onChange={this.handleEndDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                // className={classes.buttonOutlined}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          {isLoading && (
            <div className="text-center">
              <FaSpinner size={20} />
            </div>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {chart}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {info}
        </Grid>
      </>
    );
  }
}

RainfallChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  selectedType: PropTypes.string.isRequired,
  filterData: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired
};

export default compose(withStyles(styles), withTranslation())(RainfallChart);
