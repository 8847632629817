import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Papa from 'papaparse';

import { withTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/moment';
// import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { FaSpinner } from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);
/**
 * ComponentName
 */
class SealevelForcastChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      tideCodeCurrent: props.tideStationCode,
      chartOptions: this.getChartOptions(),
      tideDataStation: [],
      startDate: moment()
        .subtract(0, 'days')
        .locale('th')
        .format('YYYY-MM-DD'),
      endDate: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      today: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
      endDateData: moment(new Date())
        .locale('th')
        .format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getStation();
    this.renderChart();
  }

  static getDerivedStateFromProps(props, state) {
    let shouldRerenderChart = false;
    const { id } = state;
    shouldRerenderChart = shouldRerenderChart || id !== props.id;
    if (shouldRerenderChart) {
      this.setState({ id: props.id });
    }
    return null;
  }

  getChartOptions() {
    const { t } = this.props;

    this.options = {
      responsive: {
        rules: [{
          condition: {
            maxWidth: 9999,
            minWidth: 1501,
          },
          chartOptions: {
            chart: {
              height: 400,
            },
          },
        }, {
          condition: {
            maxWidth: 1500,
            minWidth: 1440,
          },
          chartOptions: {
            chart: {
              // height: 480,
              height: 'auto',
            },
          },
        }, {
          condition: {
            maxWidth: 1439,
            minWidth: 1366,
          },
          chartOptions: {
            chart: {
              height: 'auto',
            },
          },
        }, {
          condition: {
            maxWidth: 1365,
            minWidth: 1152,
          },
          chartOptions: {
            chart: {
              height: 'auto',
            },
          },
        }, {
          condition: {
            maxWidth: 1151,
            minWidth: 1024,
          },
          chartOptions: {
            chart: {
              height: 400,
            },
          },
        }, {
          condition: {
            maxWidth: 1023,
            minWidth: 800,
          },
          chartOptions: {
            chart: {
              height: 500,
            },
          },
        }, {
          condition: {
            maxWidth: 768,
            minWidth: 0,
          },
          chartOptions: {
            chart: {
              height: 700,
            },
          },
        }, {
          condition: {
            maxWidth: 375,
            minWidth: 0,
          },
          chartOptions: {
            chart: {
              height: 300,
            },
          },
        }],
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
        height: 400,
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },

      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: t('ระดับน้ำดาการณ์'),
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: t('ระดับน้ำดาการณ์'),
          data: [],
          tooltip: {
            valueSuffix: ' ',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
            y: -10,
            x: -10,
            align: 'left'
          },
        },
      },
    };

    return this.options;
  }

  getStation() {
    const { tideDataStation } = this.props;
    this.setState({
      tideDataStation,
    });
  }

  handleStationChange = event => {
    this.setState({ tideCodeCurrent: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    this.setState({ endDate: moment(date).format('YYYY-MM-DD') }, () => {
      this.handleChart();
    });
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  handleChart = () => {
    this.resetChart();
    this.renderChart();
  };

  renderChart = () => {
    const {
      startDate, endDate, tideCodeCurrent,
    } = this.state;

    // https://fews2.hii.or.th/model-output/data_portal/tide_table/N01-N28.txt

    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/tide_table/${tideCodeCurrent}.txt`)
      .then(resp => resp.text())
      .then(results => {
        const list = Papa.parse(results).data.slice(1);
        const resultArray = [];

        list.slice(0, -1).map((a, i) => resultArray.push({
          id: i,
          tide_code: a[0],
          datetime: `${a[1]} ${a[2]}`,
          date: a[1],
          time: a[2],
          value: a[3],
        }));

        // filter between date
        const start = new Date(startDate);
        const end = new Date(endDate);

        const result = resultArray.filter(item => {
          const date = new Date(item.date);
          return date >= start && date <= end;
        });

        // resultArray.map((text, index, { length }) => {
        //   if (index + 1 === length) {
        //     this.setState({ endDateData: text.date });
        //   }
        // });
        // eslint-disable-next-line max-len
        resultArray.map((text, index, { length }) => index + 1 === length && this.setState({ endDateData: text.date }));

        // transform datas
        const dataSeries = [];
        let ts = null;
        let val = null;

        const chartOptions = this.getChartOptions();

        result.map(n => {
          val = parseFloat(n.value);
          ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss')
            .add(7, 'hours')
            .valueOf();

          dataSeries.push([ts, val]);

          return dataSeries;
        });

        chartOptions.series[0].data = dataSeries;

        this.setState({ chartOptions, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  };

  render() {
    const {
      tideDataStation, startDate, endDate, today, tideCodeCurrent, endDateData,
    } = this.state;
    const { isLoading, error, chartOptions } = this.state;
    const { classes, t } = this.props;

    // if error, show error message
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      );
    }

    const chart = (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    );

    return (
      <>
        {errorMsg}
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <Select defaultValue={tideCodeCurrent} onChange={this.handleStationChange}>
                {tideDataStation.map(row => (
                  <option key={row.tide_code} value={row.tide_code}>
                    {row.tide_station_th}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  value={endDate}
                  maxDate={endDateData}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title={t('แสดงกราฟ')}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {chart}
          </Grid>
          <Grid item xs={12}>
            <div style={{ color: '#A2A2A2' }}>
              {'*'}
              {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

SealevelForcastChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  tideDataStation: PropTypes.array.isRequired,
  tideStationCode: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(SealevelForcastChart));
