import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

class DamLegend extends Component {
  render() {
    const { t } = this.props;
    return (
      <Box display="block">
        <Box display="flex" m={1} my={2}>
          <Box display="flex">
            <Typography style={{ fontFamily: 'Kanit', lineHeight: '16px', fontSize: '18px' }}>
              {t('เกณฑ์สีอ่างเก็บน้ำ')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" m={1} my={2}>
          <Box style={{
            width: 30, height: 30, backgroundColor: '#a52227', fontFamily: 'Kanit', fontSize: '14px',
          }}
          />
          <Box pl={1}>
            <Typography style={{
              color: '#a52227', fontFamily: 'Kanit', lineHeight: '16px', fontSize: '16px',
            }}
            >
              >100
            </Typography>
            <Typography style={{ fontFamily: 'sans-serif', lineHeight: '20px', fontSize: '14px' }}>
              {t('เกินความจุเก็บกัก')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" m={1} my={2}>
          <Box style={{
            width: 30, height: 30, backgroundColor: '#d13f3f', fontFamily: 'Kanit', fontSize: '14px',
          }}
          />
          <Box pl={1}>
            <Typography style={{
              color: '#d13f3f', fontFamily: 'Kanit', lineHeight: '16px', fontSize: '16px',
            }}
            >
              >80-100
            </Typography>
            <Typography style={{ fontFamily: 'sans-serif', lineHeight: '20px', fontSize: '14px' }}>
              {t('น้ำมาก')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" m={1} my={2}>
          <Box style={{
            width: 30, height: 30, backgroundColor: '#1042b5', fontFamily: 'Kanit', fontSize: '14px',
          }}
          />
          <Box pl={1}>
            <Typography style={{
              color: '#1042b5', fontFamily: 'Kanit', lineHeight: '16px', fontSize: '16px',
            }}
            >
              >50-80
            </Typography>
            <Typography style={{ fontFamily: 'sans-serif', lineHeight: '20px', fontSize: '14px' }}>
              {t('น้ำปานกลาง')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" m={1} my={2}>
          <Box style={{
            width: 30, height: 30, backgroundColor: '#599e5f', fontFamily: 'Kanit', fontSize: '14px',
          }}
          />
          <Box pl={1}>
            <Typography style={{
              color: '#599e5f', fontFamily: 'Kanit', lineHeight: '16px', fontSize: '16px',
            }}
            >
              >30-50
            </Typography>
            <Typography style={{ fontFamily: 'sans-serif', lineHeight: '20px', fontSize: '14px' }}>
              {t('น้ำน้อย')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" m={1} my={2}>
          <Box style={{
            width: 30, height: 30, backgroundColor: '#f6c73d', fontFamily: 'Kanit', fontSize: '14px',
          }}
          />
          <Box pl={1}>
            <Typography style={{
              color: '#f6c73d', fontFamily: 'Kanit', lineHeight: '16px', fontSize: '16px',
            }}
            >
              {'<=30'}
            </Typography>
            <Typography style={{ fontFamily: 'sans-serif', lineHeight: '20px', fontSize: '14px' }}>
              {t('น้ำน้อยวิกฤต')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" m={1} mt={3}>
          <Box display="flex"><Typography style={{ fontFamily: 'Kanit', lineHeight: '16px', fontSize: '18px' }}>{t('อักษรย่อ')}</Typography></Box>
        </Box>
        <Box display="block" m={1} mt={2}>
          <Box display="flex">
            <Typography style={{
              fontFamily: 'sans-serif', lineHeight: '16px', fontSize: '14px', fontWeight: 'bold',
            }}
            >
              {t('รนก.')}
            </Typography>
            <Typography style={{
              fontFamily: 'sans-serif', marginLeft: 5, lineHeight: '15px', fontSize: '14px',
            }}
            >
              {t('ปริมาณน้ำเก็บกัก')}
            </Typography>
          </Box>
          <Box display="flex" mt={1}>
            <Typography style={{
              fontFamily: 'sans-serif', lineHeight: '16px', fontSize: '14px', fontWeight: 'bold',
            }}
            >
              {t('ลบม.')}
            </Typography>
            <Typography style={{
              fontFamily: 'sans-serif', marginLeft: 5, lineHeight: '15px', fontSize: '14px',
            }}
            >
              {t('ล้านลูกบาศก์เมตร')}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
}

DamLegend.propTypes = {
  t: PropTypes.any.isRequired,
};

export default withTranslation()(DamLegend);
