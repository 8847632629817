import React, { useState, useEffect } from "react";

import Highcharts, { time } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import axios from "axios";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import { Box, Grid } from "@material-ui/core";
import heightMeasurementJSON from "./json/heightMeasurement.json";
import { isNull } from "lodash";

export default function DemoSiteChart({
  setCurrentDataFunc,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const [hiiSeries, setHiiSeries] = useState([]);
  const [pathumSeries, setPathumSeries] = useState([]);

  //+238.47 มรทก
  const [minBank, setMinBank] = useState({ value: 0, label: `` });
  const [groundLevel, setGroundLevel] = useState({ value: 0, label: `` });

  const [today, setToday] = useState(
    moment(new Date()).locale("th").format("YYYY-MM-DD HH:mm")
  );

  useEffect(() => {
    const HiiUrl = `https://api-v3.thaiwater.net/api/v1/thaiwater30/public/waterlevel_graph?station_type=tele_waterlevel&station_id=1252774&start_date=${startDate}&end_date=${endDate}`;
    const PathumUrl = `https://pathummat.hii.or.th/data_graph/DU0004/${moment(
      startDate
    ).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}`;

    axios.get(HiiUrl).then((res) => {
      if (res.data.data.graph_data) {
        let newResponseSeries = res.data.data.graph_data.map((row) => {
          const currentPoint = [
            moment(row.datetime, "YYYY-M-D HH:mm").valueOf(),
            row.value,
          ];
          return currentPoint;
        });
        setHiiSeries(newResponseSeries);
        setMinBank({
          value: res.data.data.min_bank,
          label: `ระดับทางน้ำล้น <strong>${calculateWithHeight(
            res.data.data.min_bank
          )}</strong> ลบ.ม.`,
        });
        setGroundLevel({
          // value: res.data.data.ground_level,
          // label: `ระดับท้องน้ำ <strong>${parseFloat(
          //   Math.round(res.data.data.ground_level * 100) / 100
          // ).toFixed(2)}</strong> ลบ.ม.`,
          value: null,
          label: "",
        });
      }
    });

    axios.get(`./proxy/jsonPathummat.php?file=` + PathumUrl).then((res) => {
      if (res.data.data.graph_data) {
        let newResponseSeries = res.data.data.graph_data.map((row) => {
          const currentPoint = [
            moment(row.datetime, "YYYY-M-D HH:mm:ss").valueOf(),
            parseFloat(Number(row.value) + 238.47),
          ];
          return currentPoint;
        });
        setPathumSeries(newResponseSeries);
      }
    });
  }, [startDate, endDate]);

  useEffect(() => {
    setCurrentDataFunc({ hii: hiiSeries, pathum: pathumSeries });
  }, [hiiSeries, pathumSeries]);

  const getChartOptions = () => {
    return {
      title: {
        text: "",
      },
      chart: {
        type: "line",
        zoomType: "xy",
        panning: true,
        panKey: "shift",
      },
      exporting: {
        buttons: {
          contextButton: {
            align: "left",
          },
        },
      },
      time: {
        timezoneOffset: -7 * 60,
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
          connectNulls: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        crosshairs: true,
        // xDateFormat: '%e %b %Y %H:%M',
        // pointFormat: '<div>{series.name}: ปริมาณน้ำ {point.y} ลบ.ม. (ระดับน้ำ {point.height} ม.รทก.)</div>',
        formatter: function () {
          const pointDetail = this.points.map((point) => {
            return `<div>${
              point.series.name
            }: ปริมาณน้ำ ${point.y.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })} ลบ.ม. (ระดับน้ำ ${point.point.height} ม.รทก.)</div>`;
          });
          return (
            moment(this.x).locale("th").format("DD/MMM/YYYY HH:mm น.") +
            "<br />" +
            pointDetail.join("\n")
          );
        },
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%H:%M<br />%d-%b %Y}",
        },
      },
      yAxis: {
        // Primary yAxis
        min: 0,
        max: 586619.42756181,
        opposite: false,
        title: {
          text: `${"ปริมาณน้ำ (ลบ.ม.)"}`,
        },
        labels: {
          format: "{value:.2f}",
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: 586619.42756181,
            color: "red",
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "ความจุที่ระดับเก็บกัก(spillway): 586,619 ลบ.ม. (่ค่าที่ระดับเก็บกัก 247.0 ม.รทก.)",
              align: "right",
            },
          },
        ],
      },
      legend: {
        enabled: true,
      },
      series: [
        {
          name: "โทรมาตร",
          data: heightToVolume(hiiSeries),
        },
      ],
    };
  };

  const handleStartDateChange = (newDate) => {
    getChartOptions();
    setStartDate(newDate.format("YYYY-MM-DD"));
  };
  const handleEndDateChange = (newDate) => {
    getChartOptions();
    setEndDate(newDate.format("YYYY-MM-DD"));
  };

  const heightToVolume = (series) => {
    const newSeries = series.map((point) => {
      if (point[1] !== null) {
        return {
          x: point[0],
          y: calculateWithHeight(point[1]),
          height: point[1].toFixed(2),
        };
      }
      return { x: point };
    });
    return newSeries;
  };

  const calculateWithHeight = (height) => {
    if (isNull(height)) {
      return "not a number";
    }
    var rounded = Math.round(height * 100) / 100;
    let closestVolume = null;
    let closestMargin = 100;
    heightMeasurementJSON.forEach((row) => {
      let currentMargin = Math.abs(rounded - row.MSL_Height);
      if (currentMargin < closestMargin) {
        closestMargin = currentMargin;
        closestVolume = row.volume;
      }
    });
    return closestVolume;
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              label="วันที่เริ่มต้น"
              format="DD-MM-YYYY"
              views={["year", "month", "date"]}
              value={startDate}
              maxDate={today}
              onChange={handleStartDateChange}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              label="วันที่สิ้นสุด"
              format="DD-MM-YYYY"
              views={["year", "month", "date"]}
              value={endDate}
              maxDate={today}
              onChange={handleEndDateChange}
            />
          </Grid>
          <Grid item xs={12}>
            {hiiSeries.length > 0 && (
              <HighchartsReact
                highcharts={Highcharts}
                options={getChartOptions()}
              />
            )}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
}
