/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FaSpinner } from '../../../utils/Fontawesome';

// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

/**
   * ComponentName
   */
class CanalChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      provinceId: props.provinceId,
      // dataProvince: [],
      teleStation: [],
      canalStation: [],
      startDate: moment().subtract(3, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD HH:mm'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      dataChart: undefined,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    // this.getProvince();
    this.getStation();
    this.renderChart();
  }

  getChartOptions() {
    this.options = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        // opposite: false,
        title: {
          text: 'ระดับน้ำ (ม.รทก.)',
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        // gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'orange',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'left',
            },
          },
        ],
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
        crosshairs: true,
      },
      series: [
        {
          name: 'ระดับน้ำด้านใน',
          data: [],
          tooltip: {
            valueSuffix: ' ม.รทก.',
          },
        },
        {
          name: 'ระดับน้ำด้านนอก',
          data: [],
          color: 'black',
          tooltip: {
            valueSuffix: ' ม.รทก.',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
          },
        },
      },
    };

    return this.options;
  }

  getStation() {
    const { provinceId } = this.state;
    fetch(`${process.env.MIX_API_URL}frontend/shared/tele_canal_station?province_code=${provinceId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const teleStation = result.data.tele_waterlevel;
        const canalStation = result.data.canal_waterlevel;
        this.setState({
          teleStation,
          canalStation,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

    handleProvinceChange = event => {
      this.setState({ provinceId: event.target.value }, () => {
        this.getStation();
      });
    };

    handleStationChange = event => {
      this.setState({ id: event.target.value }, () => {
        this.handleChart();
      });
    };

    handleStartDateChange = date => {
      this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
    };

    handleEndDateChange = date => {
      const { today } = this.state;

      if (moment(date).format('YYYY-MM-DD') === today) {
        this.setState({ endDate: moment(date).format('YYYY-MM-DD HH:mm') }, () => {
          this.handleChart();
        });
      } else {
        const dt = `${moment(date).format('YYYY-MM-DD')} 23:59`;
        this.setState({ endDate: moment(dt).format('YYYY-MM-DD HH:mm') }, () => {
          this.handleChart();
        });
      }
    };

    handleChart = () => {
      const { id, teleStation } = this.state;

      const stationByClick = teleStation.filter(
        d => parseInt(d.station_id, 10) === parseInt(id, 10),
      )[0];

      const stationId = stationByClick === undefined
        ? teleStation[0].station_id : stationByClick.station_id;

      if (stationId !== undefined) {
        // this.setState({ id: stationId });
      }

      this.resetChart();
      this.renderChart();
    };

    resetChart = () => {
      const chartCount = Highcharts.charts.length - 1;
      const charts = Highcharts.charts[chartCount];
      if (chartCount >= 0 && charts !== undefined) {
        if (charts.xAxis !== undefined) {
          charts.xAxis[0].setExtremes();
        }
      }
    }

    renderChart = () => {
      const { id, startDate, endDate } = this.state;
      fetch(
        `${process.env
          .MIX_API_URL}public/waterlevel_graph?station_type=canal&station_id=${id}&start_date=${startDate}&end_date=${endDate}`,
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return 'Something went wrong.';
        })
        .then(result => {
          // fix case all value is null
          const dataClean = result.data.graph_data.filter(x => x.value !== null);
          if (dataClean.length > 0) {
            // transform datas
            const dataSeries = [];
            const dataSeriesOut = [];
            let ts = null;
            let val = null;
            let valOut = null;

            result.data.graph_data.map(n => {
              val = parseFloat(n.value);
              valOut = parseFloat(n.value_out);
              ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
              dataSeries.push([ts, val]);
              dataSeriesOut.push([ts, valOut]);

              return dataSeries;
            });

            let max = 0; let min = 0;
            const max1 = maxBy(result.data.graph_data, 'value');
            const min1 = minBy(result.data.graph_data, 'value');

            const max2 = maxBy(result.data.graph_data, 'value_out');
            const min2 = minBy(result.data.graph_data, 'value_out');

            if (max1.value > max2.value_out) {
              max = max1.value;
            } else {
              max = max2.value_out;
            }

            if (min1.value < min2.value_out) {
              min = min1.value;
            } else {
              min = min1.value_out;
            }

            // set chart options
            const chartOptions = this.getChartOptions();
            // const depth = parseFloat(result.data.min_bank - result.data.ground_level);
            chartOptions.yAxis.max = maxBy([result.data.critical_level, max + 0.35]);
            chartOptions.yAxis.min = min;
            chartOptions.series[0].data = dataSeries;
            chartOptions.series[1].data = dataSeriesOut;

            // เงื่อนไขแสดงผล สถานี้ระบายน้ำ / ระดับน้ำด้านใน-นอก
            if (!max2.value_out > 0 || !max2.value_out < 0) {
              chartOptions.series[0].name = 'ระดับน้ำ';
              chartOptions.series[1].data = [];
            }

            // plotLines
            if (!Number.isNaN(result.data.warning_level)) {
              chartOptions.yAxis.plotLines[0].value = result.data.warning_level;
              chartOptions.yAxis.plotLines[0].label.text = `ระดับเฝ้าระวัง <strong>${result.data.warning_level}</strong> ม.รทก.`;
            }

            if (!Number.isNaN(result.data.critical_level)) {
              chartOptions.yAxis.plotLines[1].value = result.data.critical_level;
              chartOptions.yAxis.plotLines[1].label.text = `ระดับวิกฤต <strong>${parseFloat(
                Math.round(result.data.critical_level * 100) / 100,
              ).toFixed(2)}</strong> ม.รทก.`;
            }

            // plotBands
            // if (result.data.min_bank && result.data.ground_level) {
            //   chartOptions.yAxis.plotBands[0].from = result.data.min_bank;
            //   chartOptions.yAxis.plotBands[1].from = result.data.ground_level + depth * 0.7;
            //   chartOptions.yAxis.plotBands[1].to = result.data.min_bank;
            //   chartOptions.yAxis.plotBands[2].from = result.data.ground_level + depth * 0.3;
            //   chartOptions.yAxis.plotBands[2].to = result.data.ground_level + depth * 0.7;
            //   chartOptions.yAxis.plotBands[3].from = result.data.ground_level + depth * 0.1;
            //   chartOptions.yAxis.plotBands[3].to = result.data.ground_level + depth * 0.3;
            //   chartOptions.yAxis.plotBands[4].from = result.data.ground_level;
            //   chartOptions.yAxis.plotBands[4].to = result.data.ground_level + depth * 0.1;
            // }

            this.setState({
              dataChart: dataClean,
              chartOptions,
              isLoading: false,
            });
          } else {
            this.setState({ isLoading: false, dataChart: undefined });
          }
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
      const {
        id, teleStation,
        canalStation, startDate, endDate, today, dataChart,
      } = this.state;
      const { isLoading, error, chartOptions } = this.state;
      const { classes } = this.props;

      let name = '';
      let provinceName = '';
      let stationName = '';
      if (teleStation.length > 0) {
        const stationByClick = teleStation.filter(
          d => parseInt(d.station_id, 10) === parseInt(id, 10),
        )[0];
        const canal = canalStation.filter(d => parseInt(d.id, 10) === parseInt(id, 10))[0];
        const stationAll = stationByClick === undefined ? canal : stationByClick;
        const station = stationAll === undefined ? teleStation[0] : stationAll;

        if (station) {
          provinceName = station.province_name.th;
          stationName = (station.station_name.th)
            ? station.station_name.th : station.station_old_code;
        }

        name = `สถานี${stationName} จ.${provinceName}`;
      }

      if (error) {
        return (
          <div className="text-center">
            {error.message}
          </div>
        );
      }

      // if still loading, show spinner
      if (isLoading) {
        return (
          <div className="text-center">
            <FaSpinner size={30} />
          </div>
        );
      }
      let info = '';
      if (dataChart) {
        info = (
          <div style={{ color: '#A2A2A2' }}>
            *สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม
          </div>
        );
      } else {
        info = '';
      }

      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="province-label-placeholder">
                  จังหวัด
                </InputLabel>
                <Input
                  value={provinceName}
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="station-label-placeholder">
                  สถานี
                </InputLabel>
                <NativeSelect value={id} onChange={this.handleStationChange}>
                  <optgroup label="โทรมาตร">
                    {teleStation.map(row => (
                      <option key={row.station_id} value={row.station_id}>
                        {row.station_old_code }
                        {' '}
                        {row.station_name.th}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="คลอง">
                    {canalStation.map(row => (
                      <option key={row.id} value={row.id}>
                        {row.station_name.th}
                      </option>
                    ))}
                  </optgroup>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="วันที่เริ่มต้น"
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    value={startDate}
                    maxDate={today}
                    onChange={this.handleStartDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="วันที่สิ้นสุด"
                    format="YYYY-MM-DD"
                    views={['year', 'month', 'date']}
                    value={endDate}
                    maxDate={today}
                    onChange={this.handleEndDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={this.handleChart}
                  title="แสดงกราฟ"
                >
                  แสดง
                </Button>
              </FormControl>
            </Grid>
            <Grid item xs={12} align="center" style={{ fontFamily: 'Lucida Grande,Lucida Sans Unicode,Arial,Helvetica, sans-serif', fontSize: '14px', fontWeight: 'bold' }}>
              {name}
            </Grid>
            <Grid item xs={12}>
              {dataChart ? (
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
              ) : (
                <Grid item xs={12} align="center">ไม่มีข้อมูลในช่วงเวลาที่เลือก</Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {info}
            </Grid>
          </Grid>
        </>
      );
    }
}

CanalChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
};

export default withStyles(styles)(CanalChart);
