import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';

import {
  orderBy, result,
} from 'lodash';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import {
  FaSpinner,
} from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

import WlContext from '../../contexts/wlContext';
import DroughtMonitoringMap from './DroughtMonitoringMap';

const initPath = process.env.MIX_APP_PATH;

class DroughtMonitoring extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error

      datas: [],
      datasDischarge: [],

      // map
      map: null,
      zoom: 6,
      location: [13.8, 100.55],
      markers: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,

      geojsonRiver: [],
      geojsonBasin: [],

      // data
      datasRainfallYesterday: [],
      dataWaterlevel: [],
      datasDam: [],
      datasDrought: [],

    };
  }

  componentDidMount() {
    this._mounted = true;
    // set loading status
    this.setState({ isLoading: true });
    this.getDroughtData();
    this.getRainfallYesterDayData();
    this.getWaterlevelData();
    this.getDamData();
    // this.getGeoJson();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '80%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '65%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  // getGeoJson() {
  //   Promise.all([
  //     fetch('json/river/river_main.json'),
  //     fetch('json/boundary/basin.json'),
  //   ])
  //     .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
  //     .then(([river, basin]) => {
  //       this.setState({ geojsonRiver: river, geojsonBasin: basin });
  //     })
  //     .catch(error => this.setState({ error }));
  // }

  getRainfallYesterDayData() {
    // get data from service rain yesterday
    fetch(`${process.env.MIX_API_URL}public/rain_yesterday`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // console.log(result);
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // sorting and filter, rainToday
        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        const filterDatas = rainTodaySort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rainfall_value > 0);
        });

        this.setState({
          datasRainfallYesterday: filterDatas,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getWaterlevelData = () => {
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 1);
        const filterDatas = orderBy(result.waterlevel_data.data, {
          storage_percent: Number,
        })
          .filter(d => {
            const time = new Date(d.waterlevel_datetime.replace(/-/g, '/')).getTime();
            return last24hrs < time && d.waterlevel_msl != null;
          })
          .filter(d => d.storage_percent != null);

        // set state
        this.setState({
          dataWaterlevel: filterDatas,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getDamData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}analyst/dam`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // sorting and filter, large dam
        const damDailySort = orderBy(result.data.dam_daily, ['dam_storage_percent'], ['desc']);
        const datasDamDailyRid = damDailySort.filter(d => {
          const time = new Date(d.dam_date).getTime();
          return ((last24hrs < time) && (d.agency.id === 12));
        });

        this.setState({
          datasDam: datasDamDailyRid,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    this._isMounted = false;
  }

  getDroughtData() {
    // ${initPath}proxy/csv.php?file=
    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/drought/drought_dri_report.txt`)
      .then(resp => resp.text())
      .then(results => {
        this.setState({
          isLoading: false,
          datasDrought: Papa.parse(results).data.slice(1),          
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, t } = this.props;

    const {
      isLoading, error, datas, datasDischarge, datasRainfallYesterday, dataWaterlevel, datasDam,
      datasDrought, modalHeader, modalSubHeader,
      modalContent, open,
    } = this.state;

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <WlContext.Provider
          value={{
            datasRainfallYesterday,
            dataWaterlevel,
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('ระบบติดตามภัยแล้ง')}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} width="100%">
                  <DroughtMonitoringMap
                    {...this.state}
                    datasRainfallYesterday={datasRainfallYesterday}
                    dataWaterlevel={dataWaterlevel}
                    datasDrought={datasDrought}
                    datasDam={datasDam}
                    datas={datas}
                    datasDischarge={datasDischarge}
                    handleSetMap={this.handleSetMap}
                    handleModalOpen={this.handleModalOpen}
                  />
                </Box>
              </Grid>
              <Modal
                id="modal-chart"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={this.handleModalClose}
                style={{ align: 'center', justifyContent: 'center' }}
              >
                <div style={this.getModalStyle()} className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Typography variant="h5" id="modal-title">
                    {modalHeader}
                  </Typography>

                  <Typography variant="subtitle1" id="simple-modal-description">
                    {modalSubHeader}
                  </Typography>
                  <Box mt={3}>
                    {modalContent}
                  </Box>
                </div>
              </Modal>
            </Grid>
          </div>
        </WlContext.Provider>
      </div>
    );
  }
}

DroughtMonitoring.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles, { withTheme: true }), withTranslation(),
)(DroughtMonitoring);
