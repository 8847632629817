import React, { Component, createRef, Fragment } from 'react';
// extention
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import withWidth from '@material-ui/core/withWidth';
// style
import './style.css';
// leaflet
import L from 'leaflet';
// additional conponent
import MapWrf from './mapWrf';
import { compose } from 'recompose';

const initPath = process.env.MIX_APP_PATH;

class ContentImageWrfLasted extends Component {
  constructor(props, context) {
    super(props, context);
    this.refMap = createRef();
    this.rainAmphoeRef = createRef();
    this.state = {
      domainData: props.domainData,
      data: props.data,
      index: props.index,
      // mediaDateTime: props.mediaDateTime,
      uriImage: '',
      dataConcat: [],
      geoJsonAmphoe: null,
      geoJsonProvince: null,
      widthScreen: props.width,
      typeArea: '1',
      geoJsonKey: 0,
      boundary: {
        amphoe: null,
        province: null,
      },
    };
  }

  componentDidMount() {
    this.setStyleSvg();

    const fetchGeoJson = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Something went wrong ...');
        }
        return response.json();
      } catch (error) {
        console.error(error);
      }
    };
    fetchGeoJson(`${initPath}json/boundary/amphoe.json`)
      .then((result) => {
        this.setState({ geoJsonAmphoe: result });
        this.resizeLayerGeoJson();
      });

    fetchGeoJson(`${initPath}json/boundary/thailand.json`)
      .then((result) => {
        this.setState({ geoJsonProvince: result });
        this.resizeLayerGeoJson();
      });



  }

  onEachFeature = (feature, layer) => {
    const { PROV_NAM_T, AMPHOE_T } = layer.feature.properties;
    const province = PROV_NAM_T.includes('.') ? PROV_NAM_T.split('.')[1] : PROV_NAM_T;
    const amphoe = AMPHOE_T.includes('.') ? AMPHOE_T.split('.')[1] : AMPHOE_T;
    const popupContent = `<b>อำเภอ: </b>${amphoe} <b>จังหวัด: </b>${province}`;
    layer.on({
      mouseover: this.handleMouseOver,
      mouseout: this.handleMouseOut,
    });
    layer.bindPopup(popupContent);
  };

  handleMouseOver = e => {
    const layer = e.target;
    layer.setStyle({
      weight: 1,
      opacity: 1,
      color: 'red',
      dashArray: '0',
      fillOpacity: 0.5,
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };

  handleMouseOut = e => {
    this.rainAmphoeRef.current.leafletElement.resetStyle(e.layer);
  };

  setStyleSvg() {
    const { domainData, data, index } = this.state;
    if (domainData === 'thailand') {
      this.getUrlImage(data, index);
      const mySVGThailand = document.getElementById('thailand');
      if (mySVGThailand) {
        mySVGThailand.addEventListener('load', () => {
          const svgDocThailand = mySVGThailand.getSVGDocument();
          if (svgDocThailand) {
            const svgRootThailand = svgDocThailand.querySelector('svg');
            // Position for WRF 3.0
            // svgRootThailand.setAttribute('viewBox', '51 -130 1360 1420');

            // adjust position for WRF V4.0
            // svgRootThailand.setAttribute('viewBox', '5 -80 1440 1350');

            // adjust postion domain thailand 2022-12-01
            svgRootThailand.setAttribute('viewBox', '45 -80 1440 1350');
          }
        });
      }
    }
    if (domainData === 'southeast asia') {
      this.getUrlImage(data, index);
      const mySVGSeAsia = document.getElementById('se-asia');
      if (mySVGSeAsia) {
        mySVGSeAsia.addEventListener('load', () => {
          const svgDocSeAsia = mySVGSeAsia.getSVGDocument();
          if (svgDocSeAsia) {
            const svgRootSeAsia = svgDocSeAsia.querySelector('svg');
            // Position for WRF 3.0
            // svgRootSeAsia.setAttribute('viewBox', '-330 -335 2200 2100');

            // adjust position for WRF V4.0
            // svgRootSeAsia.setAttribute('viewBox', '-780 -700 4000 2650');

            // adjust postion domain thailand 2022-12-01
            svgRootSeAsia.setAttribute('viewBox', '-466 -700 4000 2650');
          }
        });
      }
    }
    if (domainData === 'asia') {
      this.getUrlImage(data, index);
      const mySVGSeAsia = document.getElementById('asia');
      if (mySVGSeAsia) {
        mySVGSeAsia.addEventListener('load', () => {
          const svgDocAsia = mySVGSeAsia.getSVGDocument();
          if (svgDocAsia) {
            const svgRootAsia = svgDocAsia.querySelector('svg');

            // Position for WRF 3.0
            // svgRootAsia.setAttribute('viewBox', '-272 -1001 1927 3444');

            // adjust position for WRF V4.0
            svgRootAsia.setAttribute('viewBox', '150 -1140 2220 3700');

            // adjust postion domain thailand 2022-12-01
            // svgRootAsia.setAttribute('viewBox', '150 -1140 2220 3700');
          }
        });
      }
    }
    if (domainData === 'thailand basin') {
      this.getUrlImage(data, index);
      const mySVGSeAsia = document.getElementById('thailand-basin');
      if (mySVGSeAsia) {
        mySVGSeAsia.addEventListener('load', () => {
          const svgDocThailandBasin = mySVGSeAsia.getSVGDocument();
          if (svgDocThailandBasin) {
            const svgRootThailandBasin = svgDocThailandBasin.querySelector('svg');

            // Position for WRF 3.0
            // svgRootThailandBasin.setAttribute('viewBox', '-56 -62 1316 1249');

            // adjust position for WRF V4.0
            // svgRootThailandBasin.setAttribute('viewBox', '321 -206 560 1590');
            svgRootThailandBasin.setAttribute('viewBox', '321 -206 635 1590');

            // adjust postion domain thailand 2022-12-01
            // svgRootThailandBasin.setAttribute('viewBox', '321 -206 560 1590');
          }
        });
      }
    }
  }

  getUrlImage(data, index) {
    const { domainData } = this.props;
    const indexReset = domainData === ('thailand' || domainData === 'thailand basin') && index > 2 ? 0 : index;

    // if (indexReset === 0) {
    //   this.setState({
    //     mediaDateTime: data[indexReset].media_datetime,
    //   });
    // }
    this.setState({
      uriImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${data[indexReset].media_path}`,
      dataConcat: data,
    });
  }

  setNewClassName = (keyword, layerGetClassName, newClassName) => {
    if (keyword === 'add') {
      for (let i = 0; i < layerGetClassName.length; i += 1) {
        layerGetClassName[i].classList.add(newClassName);
      }
    }

    if (keyword === 'remove') {
      for (let i = 0; i < layerGetClassName.length; i += 1) {
        layerGetClassName[i].classList.remove(newClassName);
      }
    }
  }

  getRainAmphoeStyle = () => ({
    stroke: true,
    color: 'black',
    weight: 0.5,
    fillColor: '',
    opacity: 0.3,
    fillOpacity: 0,
  })

  resizeLayerGeoJson = () => {
    const { domainData, widthScreen } = this.state;
    // geoJson layer
    const layerThailand = document.getElementsByClassName('leaflet-zoom-animated');

    let className = '';
    switch (domainData) {
      case 'thailand':
        className = widthScreen === 'xs' ? 'leaflet-zoom-animated-thailand-mobile' : 'leaflet-zoom-animated-thailand';
        break;
      case 'southeast asia':
        className = widthScreen === 'xs' ? 'leaflet-zoom-animated-southeast-asia-mobile' : 'leaflet-zoom-animated-southeast-asia';
        break;
      case 'asia':
        className = widthScreen === 'xs' ? 'leaflet-zoom-animated-asia-mobile' : 'leaflet-zoom-animated-asia';
        break;
      default:
        className = '';
        break;
    }

    if (className) {
      for (let i = 0; i < layerThailand.length; i += 1) {
        layerThailand[i].classList.add(className);
      }
    }
  }

  plusDivs(n) {
    const { index, dataConcat } = this.state;
    let countIndex;
    if (n > 0) {
      if (index !== (dataConcat.length - 1)) {
        countIndex = index + 1;
      } else if ((index === 6) && (dataConcat.length - 1 === 6)) {
        countIndex = 3;
      } else {
        countIndex = 0;
      }
      // const countIndex = index !== (dataConcat.length - 1) ? index + 1 : 0;
      this.setState({
        index: countIndex,
        // mediaDateTime: dataConcat[countIndex].media_datetime,
        uriImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${dataConcat[countIndex].media_path}`,
      });
      // this.setState(() => this.setStyleSvg());
    }

    if (n < 0) {
      // const countIndex = index !== 0 ? index - 1 : (dataConcat.length - 1);
      if (index !== 0) {
        countIndex = index - 1;
      } else {
        countIndex = (dataConcat.length - 1);
      }
      this.setState({
        index: countIndex,
        // mediaDateTime: dataConcat[countIndex].media_datetime,
        uriImage: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${dataConcat[countIndex].media_path}`,
      });
      // this.setState(() => this.setStyleSvg());
    }
  }

  handleTypeChange = event => {
    const selectedType = event.target.value;
    this.setState({ typeArea: selectedType }, this.resizeAndForceUpdate);
  }

  resizeAndForceUpdate = () => {
    setTimeout(() => {
      this.resizeLayerGeoJson();
      this.forceUpdate();
    });
  }

  handleMap = (geoJson, uriImage, keyword, mode) => {
    return (
      <MapWrf
        boundary={geoJson}
        uriImage={uriImage}
        keyword={keyword}
        mode={mode}
      />
    );
  }

  render() {
    const { domainData, uriImage, geoJsonAmphoe, typeArea, geoJsonProvince } = this.state;
    // const imageBounds = [[12.0, 100.0], [15.0, 102.0]];

    return (
      <>
        <Box display="flex" justifyContent="center">
          <Box>
            {domainData === 'thailand' && (
              <Fragment>
                <FormControl>
                  <InputLabel shrink htmlFor="station-label-placeholder">
                    ประเภท
                  </InputLabel>
                  <NativeSelect value={typeArea} onChange={this.handleTypeChange}>
                    <option value="1">อำเภอ</option>
                    <option value="2">จังหวัด</option>
                  </NativeSelect>
                </FormControl>
                {typeArea === '1' && (
                  <Grid container spacing={0} direction="row" alignItems="center">
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                    </Grid>
                    <Grid item xs>
                      <div>
                        {geoJsonAmphoe && this.handleMap(geoJsonAmphoe, uriImage, 'thailand', 'amphoe')}
                      </div>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                    </Grid>
                  </Grid>
                )}
                {typeArea === '2' && (
                  <Grid container spacing={0} direction="row" alignItems="center">
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                    </Grid>
                    <Grid item xs>
                      <div>
                        {geoJsonProvince && this.handleMap(geoJsonProvince, uriImage, 'thailand', 'province')}
                      </div>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            )}
            {domainData === 'southeast asia' && (
              <Grid container spacing={0} direction="row" alignItems="center">
                <Grid item>
                  <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                </Grid>
                <Grid item xs>
                  <div>
                    {geoJsonProvince && this.handleMap(geoJsonProvince, uriImage, 'southeast asia', 'province')}
                  </div>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                </Grid>
              </Grid>
            )}
            {domainData === 'asia' && (
              <Grid container spacing={0} direction="row" alignItems="center">
                <Grid item>
                  <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                </Grid>
                <Grid item xs>
                  <div>
                    {geoJsonProvince && this.handleMap(geoJsonProvince, uriImage, 'asia', 'province')}
                  </div>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                </Grid>
              </Grid>
            )}
            {domainData === 'thailand basin' && (
              <Grid container spacing={0} direction="row" alignItems="center">
                <Grid item>
                  <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(-1)} title="ภาพก่อนหน้า">&#10094;</Button>
                </Grid>
                <Grid item xs>
                  <div className="svg-container" style={{ maxWidth: '600px' }}>
                    <img src={uriImage} width="100%" alt="forecast" />
                    <embed
                      type="image/svg+xml"
                      id="thailand-basin"
                      src={`${initPath}svg/basin.svg`}
                      className="svg svg-content"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" size="small" onClick={() => this.plusDivs(1)} title="ภาพถัดไป">&#10095;</Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </>
    );
  }
}

ContentImageWrfLasted.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  domainData: PropTypes.string.isRequired,
  // mediaDateTime: PropTypes.string.isRequired,
};

export default compose(withWidth())(ContentImageWrfLasted);
