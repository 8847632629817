import React, { useState, useEffect, } from 'react';
import { processDataAndFilterLatest, fetchOneFile } from './utils/Utils'
import { hiiReportSituation, } from './path/drivepath';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Lightbox from "react-image-lightbox";
// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
// icon .
import { FaHome } from '../../../utils/Fontawesome';
import { HiiPresentIcon } from '../../../utils/Icon';

import {
  styles,
} from '../../../styles/Style';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 1280,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down('lg')]: {

      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));

const initPath = process.env.MIX_APP_PATH;

function BoxSituation(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [image, setImage] = useState([]);
  const [modalImage, setModalImage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const fallbackSrc = "https://f.ptcdn.info/246/085/000/m057mos3i3h9hsYVOir-o.jpg";
  const currentYear = new Date().getFullYear();

  const loadImage = async () => {
    setLoading(true);
    const data = await fetchOneFile(1, hiiReportSituation, 1, currentYear);
    if (data && data.files) {
      const sortedimage = await processDataAndFilterLatest(data);
      const filterimage = await sortedimage.filter((file) => file.datethai !== 'Invalid date');
      setImage(filterimage);
    } else {
      setImage([]);
    }
    setLoading(false);
  };
  // Initial load
  useEffect(() => {
    loadImage();
  }, []);

  const handleImageError = (e) => {
    e.target.src = fallbackSrc;
  };

  const openModalImage = (url) => {
    setModalImage(url);
    setIsOpen(true);
  };

  return (
    <>
      <Grid item xs={6} md={6} lg={6}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9} lg={9}>
              <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Typography variant="h5" display="inline">
                    {t('แจ้งเตือนสถานการณ์')}
                  </Typography>
                </Box>
              </Box>
              {
                image
                  .map((file) => {
                    return (
                      <Button key={file.id} onClick={() => openModalImage(`https://drive.google.com/thumbnail?sz=w1000&id=${file.id}`)} className={classes.imgWrapper}>
                        <img
                          src={`https://drive.google.com/thumbnail?sz=w500&id=${file.id}`}
                          onError={handleImageError}
                          width="100%"
                          alt={t('แจ้งเตือนสถานการณ์')}
                          className={classes.outLined}
                          style={{ cursor: "pointer", height: '400px' }}
                        />
                      </Button>
                    )
                  })
              }
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Box mt={3} mb={2} pb={0} style={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  href={`${initPath}report/situation`}
                  className={classes.buttonOutlined}
                >
                  <Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูย้อนหลัง')}</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isOpen ? (
        <Lightbox
          mainSrc={modalImage}
          onCloseRequest={() => setIsOpen(false)}
          imagePadding={50}
          reactModalStyle={{ overlay: { zIndex: "9999999" } }}
        />
      ) : null}
    </>
  )
}

export default BoxSituation;