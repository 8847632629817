/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 *
 */

import iconRect from '../widgets/map/iconRect';

const wlColor = [
  {
    label: 'ไม่มีข้อมูล',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#BDBDBD',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อยวิกฤต',
    rangeTitle: '<=10',
    statusColor: '#db802b',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อย',
    rangeTitle: '>10-30',
    statusColor: '#ffc000',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปกติ',
    rangeTitle: '>30-70',
    statusColor: '#00b050',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'มาก',
    rangeTitle: '>70-100',
    statusColor: '#003cfa',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'ล้นตลิ่ง',
    rangeTitle: '>100',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const WlStatusColor = wl => {
  let color;
  let icon;
  const [zero, one, two, three, four, five] = wlColor;

  if (wl === null) {
    color = zero;
    icon = iconRect.grey;
  } else if (wl > 100) {
    color = five;
    icon = iconRect.red;
  } else if (wl > 70) {
    color = four;
    icon = iconRect.blue;
  } else if (wl > 30) {
    color = three;
    icon = iconRect.green;
  } else if (wl > 10) {
    color = two;
    icon = iconRect.yellow;
  } else if (wl <= 10) {
    color = one;
    icon = iconRect.brown;
  } else {
    color = zero;
    icon = iconRect.grey;
  }

  return { color, icon };
};

export default WlStatusColor;
