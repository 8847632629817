import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Img from "react-image";
import Hidden from "@material-ui/core/Hidden";

import useStyles from './style';
import { processDataAndFilterLatest, handleClick } from './utils/Utils'

const initPath = process.env.MIX_APP_PATH;

function BoxMhesi(props) {
    const { openModalImage } = props;
    const classes = useStyles();

    return (
        <>
            <Grid
                container
                spacing={3}
                alignItems="start"
                justifyContent="center"
                style={{ marginBottom: 30 }}
            >
                <Grid item xs={12}>
                    <Box style={{ position: "relative", width: "100%" }}>
                        <Img
                            src={`${initPath}images/mhesi_warroom/tab.png`}
                            width="100%"
                            height="50px"
                            style={{ position: "relative" }}
                            alt="Traffy fondue แจ้งปัญหาสำหรับประชาชน"
                        />

                        <Box className={classes.tabTitle}>
                            <Hidden xsDown>
                            อว. ส่วนหน้า : ศูนย์ปฏิบัติการสถานการณ์น้ำท่วม
                            อว. เพื่อประชาชน จังหวัด
                            </Hidden>
                            <Hidden smUp>
                            อว. ส่วนหน้า อว. เพื่อประชาชน จังหวัด
                            </Hidden>
                        </Box>
                        <Box
                            style={{
                                position: "absolute",
                                right: -31,
                                top: -23,
                                display: "flex",
                            }}
                        >
                            <a href="https://www.mhesi.go.th/" target='_blank'>
                                <Box className={classes.imgWrapper}>
                                    <Img
                                        src={`${initPath}images/mhesi_warroom/logo-mhesi-v1.png`}
                                        width="100%"
                                        alt="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)"
                                        className={classes.logoPartner}
                                    />
                                </Box>
                            </a>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                style={{ marginBottom: 30 }}
            >
                <Grid item xs={12} md={12}>
                    <Img
                        src={`${initPath}images/mhesi_warroom/mhesi-v1.png`}
                        className={classes.img}
                        style={{ borderRadius: 9 }}
                        alt="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)"
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default BoxMhesi;