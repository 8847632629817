/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import tJson from '../../utils/TJson';
import { FaSpinner } from '../../utils/Fontawesome';
import { withModal } from '../../contexts/ModalContext';
// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

/**
 * ComponentName
 */
class DischargeChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      id: props.id,
      chartOptions: this.getChartOptions(),
      dataProvince: [],
      provinceId: props.provinceId,
      teleStation: [],
      canalStation: [],
      startDate: moment().subtract(3, 'days').locale('th').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('th').format('YYYY-MM-DD HH:mm'),
      today: moment(new Date()).locale('th').format('YYYY-MM-DD'),
      dataChart: undefined,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getProvince();
    this.getStation();
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalSize !== this.props.modalSize ||
      prevProps.isFullScreen !== this.props.isFullScreen) {
      this.props.updateChartSize();
    }
  }

  getChartOptions() {
    const { t } = this.props;

    this.options = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b<br />%H:%M}',
        },
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        title: {
          text: `${t('ปริมาณน้ำท่า')} ${t('(ม.3/วิ.)')}`,
        },
        labels: {
          format: '{value:.2f}',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotLines: [
          {
            value: null,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: null,
              align: 'right',
            },
          },
        ],
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: t('ปริมาณน้ำท่า'),
          data: [],
          tooltip: {
            valueSuffix: ` ${t('ม.3/วิ.')}`,
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
          },
        },
      },
    };

    return this.options;
  }

  getProvince() {
    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const dataProvince = result.province.data.sort((a, b) => a.province_name.th.localeCompare(b.province_name.th, 'th'));

        this.setState({
          dataProvince,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getStation() {
    const { provinceId } = this.state;
    fetch(`${process.env.MIX_API_URL}frontend/shared/tele_canal_station?province_code=${provinceId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const teleStation = result.data.tele_waterlevel;
        const canalStation = result.data.canal_waterlevel;

        this.setState({
          teleStation,
          canalStation,
        });

        this.handleChart();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleProvinceChange = event => {
    this.setState({ provinceId: event.target.value }, () => {
      this.getStation();
    });
  };

  handleStationChange = event => {
    this.setState({ id: event.target.value }, () => {
      this.handleChart();
    });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: moment(date).format('YYYY-MM-DD') });
  };

  handleEndDateChange = date => {
    const { today } = this.state;

    if (moment(date).format('YYYY-MM-DD') === today) {
      this.setState({ endDate: moment(date).format('YYYY-MM-DD HH:mm') }, () => {
        this.handleChart();
      });
    } else {
      const dt = `${moment(date).format('YYYY-MM-DD')} 23:59`;
      this.setState({ endDate: moment(dt).format('YYYY-MM-DD HH:mm') }, () => {
        this.handleChart();
      });
    }
  };

  handleChart = () => {
    const { id, teleStation } = this.state;
    const stationByClick = teleStation.filter(
      d => parseInt(d.station_id, 10) === parseInt(id, 10),
    )[0];
    const stationId = stationByClick === undefined
      ? teleStation[0].station_id : stationByClick.station_id;
    if (stationId !== undefined) {
      this.setState({ id: stationId });
    }

    this.resetChart();
    this.renderChart();
  };

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const { id, startDate, endDate } = this.state;
    const { t } = this.props;

    fetch(
      `${process.env
        .MIX_API_URL}public/waterlevel_graph?station_type=tele_waterlevel&station_id=${id}&start_date=${startDate}&end_date=${endDate}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return 'Something went wrong.';
      })
      .then(result => {
        // fix case all value is null
        const dataClean = result.data.graph_data.filter(x => x.discharge !== null);

        if (dataClean.length > 0) {
          // transform datas
          const dataSeries = [];
          let ts = null;
          let val = null;

          result.data.graph_data.map(n => {
            val = parseFloat(n.discharge);
            ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
            dataSeries.push([ts, val]);
            return dataSeries;
          });

          const qmax = result.data.qmax;
          const max = maxBy(result.data.graph_data, 'discharge');
          const min = minBy(result.data.graph_data, 'discharge');

          // set chart options
          const chartOptions = this.getChartOptions();

          chartOptions.yAxis.max = maxBy([max.discharge, qmax]) + 0.5;
          chartOptions.yAxis.min = min.discharge - 0.5;
          chartOptions.series[0].data = dataSeries;

          // plotLines
          if (qmax) {
            chartOptions.yAxis.plotLines[0].value = qmax;
            chartOptions.yAxis.plotLines[0].label.text = `${t('ความจุลำน้ำ')} <strong>${result.data
              .qmax}</strong> ${t('ม.3/วิ.')}`;
          }

          this.setState({
            dataChart: dataClean,
            chartOptions,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false, dataChart: undefined });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      dataProvince, id, provinceId, teleStation, canalStation, startDate, endDate, today, dataChart,
    } = this.state;
    const { isLoading, error, chartOptions } = this.state;
    const { t, classes } = this.props;

    let name = '';
    if (teleStation.length > 0) {
      let provinceName = '';
      let stationName = '';
      const stationByClick = teleStation.filter(
        d => parseInt(d.station_id, 10) === parseInt(id, 10),
      )[0];
      const station = stationByClick === undefined ? teleStation[0] : stationByClick;

      if (station) {
        provinceName = station.province_name.th;
        stationName = (station.station_name.th)
          ? tJson(station.station_name, 'สถานี') : station.station_old_code;
      } else {
        const canal = canalStation.filter(d => parseInt(d.id, 10) === parseInt(id, 10))[0];

        provinceName = tJson(canal.province_name, 'จ.');
        stationName = tJson(canal.station_name, 'สถานี');
      }

      // check if station contain province name, omit show duplicate province name
      if (stationName.includes('จ.')) {
        name = `${t('สถานี')}${stationName}`;
      } else {
        name = `${t('สถานี')}${stationName} ${t('จ.')}${provinceName}`;
      }
    }

    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }
    let info = '';
    if (dataChart) {
      info = (
        <div style={{ color: '#A2A2A2' }}>
          *
          {''}
          {t('*สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    } else {
      info = '';
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="province-label-placeholder">
                {t('จังหวัด')}
              </InputLabel>
              <Select value={provinceId} onChange={this.handleProvinceChange}>
                {dataProvince.map(row => (
                  <MenuItem key={row.province_code} value={row.province_code}>
                    {tJson(row.province_name, 'จ.')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <NativeSelect value={id} onChange={this.handleStationChange}>
                <optgroup label="โทรมาตร">
                  {teleStation.map(row => (
                    <option key={row.station_id} value={row.station_id}>
                      {row.station_old_code }
                      {' '}
                      {tJson(row.station_name, 'สถานี')}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="คลอง">
                  {canalStation.map(row => (
                    <option key={row.id} value={row.id}>
                      {tJson(row.station_name, 'สถานี')}
                    </option>
                  ))}
                </optgroup>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่เริ่มต้น')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  maxDate={today}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label={t('วันที่สิ้นสุด')}
                  format="YYYY-MM-DD"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  maxDate={today}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                onClick={this.handleChart}
                title="แสดงกราฟ"
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12} align="center" style={{ fontFamily: 'Lucida Grande,Lucida Sans Unicode,Arial,Helvetica, sans-serif', fontSize: '12px' }}>
            {name}
          </Grid>
          <Grid item xs={12}>
            {dataChart ? (
              <HighchartsReact  ref={this.props.chartRef} highcharts={Highcharts} options={chartOptions} />
            ) : (
              <Grid item xs={12} align="center">{t('ไม่มีข้อมูลในช่วงเวลาที่เลือก')}</Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

DischargeChart.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  provinceId: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withModal, withStyles(styles), withTranslation('translations', { withRef: true }))(DischargeChart);
