import React from 'react';

import HeaderStorm from '../../navigation/HeaderStormSurge';
import Forecast from './Forecast';

// style

export default function StormSeaLevel() {
  return (
    <div>
      <HeaderStorm />
      <Forecast />
    </div>
  );
}
