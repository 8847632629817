/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  uniqueId, uniqBy, orderBy, get,
} from 'lodash';

import moment from 'moment';
import {
  Link,
} from 'react-router-dom';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Radio from '@material-ui/core/Radio';
import { Hidden } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

// table .
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

// lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// width
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// icon .
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import {
  FaChartLine, FaSpinner, FaCaretUp, FaCaretDown, FaEquals, FaChartArea, FaCalendarAlt, FaImage,
} from '../../utils/Fontawesome';
import { HiiWaterLevelIcon } from '../../utils/Icon';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// modal
import Modal from '../modal/Modal';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';

import {
  StyledTableRow, styles, BootstrapInput,
} from '../../styles/Style';

import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import WlStatusColor from '../wl/WlStatusColor';
import WlContext from '../../contexts/wlContext';
import WaterSitWaterLevelMap from './WaterSitWaterLevelMap';
import WlChart from '../wl/WlChart';
import WlLegend from '../wl/WlLegend';
import RidChartYear from '../wl_rid/RidChartYear';

// sidebar
import SidebarWater from '../navigation/SidebarWater';

// json
import tJson from '../../utils/TJson';
import formatNumber from '../../utils/Number';
import ImageGallery from '../imagegallery/ImageGallery';

const crossSection = require('../../store/json/crosssection.json');

let arrayCrosssectionImage = [];
const initPath = process.env.MIX_APP_PATH;

// table Header
const columnData = [
  {
    id: 'station.tele_station_name.th', numeric: false, disablePadding: true, label: 'สถานี', sortable: true,
  },
  {
    id: 'river_name', numeric: false, disablePadding: true, label: 'แม่น้ำ', sortable: true,
  },
  {
    id: 'address', numeric: false, disablePadding: true, label: 'ที่ตั้ง', sortable: false,
  },
  {
    id: 'waterlevel_msl', align: 'right', numeric: true, disablePadding: true, label: 'ระดับน้ำ', unit: '(ม.รทก)', sortable: true,
  },
  {
    id: 'station.min_bank', align: 'right', numeric: true, disablePadding: true, label: 'ระดับตลิ่ง', unit: '(ม.รทก)', sortable: true,
  },
  {
    id: 'storage_percent', align: 'center', numeric: true, disablePadding: true, label: 'สถานการณ์น้ำ', sortable: true,
  },
  {
    id: 'wl', numeric: true, disablePadding: true, label: '', sortable: false,
  },
  {
    id: 'compare', numeric: true, disablePadding: true, label: 'แนวโน้ม', sortable: false,
  },
  {
    id: 'datetime', align: 'center', numeric: false, disablePadding: true, label: 'เวลา', sortable: false,
  },
  {
    id: 'chart', align: 'center', numeric: false, disablePadding: true, label: '',
  },
  {
    id: 'image', align: 'center', numeric: false, disablePadding: true, label: 'รูปโทรมาตร', sortable: true,
  },
];

class WaterSitWaterLevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error

      datas: [],
      photoIndex: 0,
      isOpen: false,
      page: 0,
      rowsPerPage: 10,
      dataCondition: [],
      dataProvince: [],
      dataBasin: [],
      areaText: 'ทุกสถานี',
      agencyName: 'ทุกหน่วยงาน',
      dataAgency: [],

      // for search
      dataInit: [],
      dataInitLastest: [],
      searchData: [],
      searchTerm: '',

      // map
      map: null,
      zoom: 5.65,
      location: [12.9, 101.6],
      markers: [],

      // telestation images
      teleImageId: [],

      // modal
      open: false,
      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },

      // radio group
      areaType: '0',
      areaId: '',

      //agency select
      agencyId: '',

      order: 'asc',
      // order: 'desc',
      orderByState: 'station.tele_station_name.th',
      // orderByState: 'storage_percent',

      isOpenWlImage: false,
      wlImage: '',
    };
  }

  componentDidMount() {
    // set loading status
    this.getTeleimages();
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
    this.getWlImage();
  }

  getData = () => {

    fetch(`${process.env.MIX_API_URL}public/waterlevel_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        // const last24hrs = (new Date().getTime() / 1000) - (24 * 3600);
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);
        let filterDatas = orderBy(result.waterlevel_data.data, {
          storage_percent: Number,
        })
          .filter(d => {
            const time = new Date(d.waterlevel_datetime.replace(/-/g, '/')).getTime();
            return ((last24hrs < time) && (d.waterlevel_msl != null));
          })
          /**
          * 2023-01-30 มีสถานีบนทะเล สถานีวิจัยประมงศรีราชา
          * (tele_station_oldcode = GOT001) ไม่มีค่า min_bank,
          * ground_level ทำให้ไม่สามารถคำนวณ storage_percent ได้
          * ทำให้ต้องมีการปรับแก้ filter สถานีดั่งกล่าวให้แสดงผลบนเว็บไซต์
          */
          .filter(d => ((d.storage_percent != null) || (d.station.tele_station_oldcode.indexOf('GOT') === 0)));
        // 2021-01-27 ข้อมูลจากกรมชลส่วนใหญ่ไม่มีข้อมูลตลิ่งซ้าย ขวา ทำให้คำนวน %ความจุลำน้ำไม่ได้ ถ้าไม่ filter storage_percent สถานีสีเทาบนแผนที่จะทับกับข้อมูลที่มี
        // basin and province dropdown
        let dataProvince = result.waterlevel_data.data.map(d => ({
          id: d.geocode.province_code,
          name: d.geocode.province_name.th,
        }));
        dataProvince = uniqBy(dataProvince.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataBasin = result.waterlevel_data.data.map(d => ({
          id: d.basin.basin_code,
          name: d.basin.basin_name.th,
        }));
        dataBasin = uniqBy(dataBasin.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataAgency = result.waterlevel_data.data.map(d => ({
          id: d.agency.id,
          name: d.agency.agency_name,
        }));
        dataAgency = uniqBy(dataAgency.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th')), 'id');
        const { t } = this.props;
        const { areaText } = this.state;

        // set state
        this.setState({
          areaText: t(areaText),
          isLoading: false,
          dataInit: filterDatas,
          dataInitLastest: filterDatas,
          datas: filterDatas,
          dataProvince,
          dataBasin,
          dataAgency,
          dataCondition: dataProvince,
          latestDate: this.getMaxDate(filterDatas),
          searchData: filterDatas,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getWlImage() {
    fetch(`${process.env.MIX_API_URL}public/weather_img/image_generate`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const link = `${process.env.MIX_APP_PATH}weather/${moment(result.data[1][0].datetime).format('YYYY-MM-DD')}/64/175`;
        const wlImage = {
          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[1][0].media_path}`,
          link,
        };
        this.setState({
          wlImage,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getTeleimages = () => {
    fetch(`${initPath}json/telestation/list.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.setState({ teleImageId: data });
      })
      .catch(error => {
        console.error('Error fetching file names:', error);
      });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;
    const params = {
      header: t('กราฟระดับน้ำ'),
      content: <WlChart
        id={data.station.id}
        provinceId={data.geocode.province_code}
      />,
    };
    handler(params);
  }

  handleChartYear = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: t('กราฟระดับน้ำรายปี'),
      content: <RidChartYear id={data.station.id} provinceId={data.geocode.province_code} selectedType="waterlevel" />,
    };
    handler(params);
  }

  handleImages = (handler, data) => e => {
    const { teleImageId } = this.state;
    e.preventDefault();
    const params = {
      header: `${tJson(data.station.tele_station_name, 'สถานี')}`,
      content: <ImageGallery data={teleImageId} folder={data.station.tele_station_oldcode} />,
    };
    handler(params);
  }

  handleSearchMarker = (id, latitude, longitude) => e => {
    const { map, markers } = this.state;
    e.preventDefault();
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
      const LatLng = { lat: latitude, lng: longitude };
      const px = map.project(LatLng);
      px.y -= 320 / 2;
      map.panTo(map.unproject(px), { animate: true });
    }
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.waterlevel_datetime.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format('YYYY-MM-DD')} เวลา : ${moment(maxdate).format('HH:mm')}`;
    }
    return moment().format('YYYY-MM-DD');
  }

  // compare current water level with previous data
  compareWl = (currentWl, previousWl, storagePercent) => {
    let text = '';
    let component = '';
    const status = WlStatusColor(storagePercent);

    if (currentWl === previousWl) {
      text = 'ทรงตัว';
      component = (
        <Tooltip title={`${text}ที่ระดับ ${currentWl} ม.รทก.`}>
          <IconButton>
            <FaEquals size={15} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    } else if (currentWl > previousWl) {
      text = 'เพิ่มขึ้น';
      component = (
        <Tooltip title={`${text} ${parseFloat(currentWl - previousWl).toFixed(2)} ม.รทก.`}>
          <IconButton>
            <FaCaretUp size={20} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    } else if (currentWl < previousWl) {
      text = 'ลดลง';
      component = (
        <Tooltip title={`${text} ${parseFloat(previousWl - currentWl).toFixed(2)} ม.รทก.`}>
          <IconButton>
            <FaCaretDown size={20} style={{ fill: status.color.statusColor }} />
          </IconButton>
        </Tooltip>
      );
    }
    return [{ text, component }];
  }

  // get water level status
  wlStatus = storagePercent => {
    const status = WlStatusColor(storagePercent);
    return (
      <span
        style={{
          color: status.color.statusColor,
        }}
      >
        {status.color.label}
      </span>
    );
  }

  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByState === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  handleAreaTypeChange = (event, value) => {
    const { dataProvince, dataBasin, dataInit } = this.state;
    const { t } = this.props;
    let dataCondition = dataProvince;
    let areaText = t('ทั่วประเทศ');
    if (value === '1') {
      dataCondition = dataBasin;
      areaText = t('ทุกลุ่มน้ำ');
    }

    this.setState({
      areaType: value,
      dataCondition,
      areaId: '',
      areaText,
      dataInitLastest: dataInit,
      searchData: dataInit,
    }, () => {
      this.filterDatas();
    });
  };

  handleSearchChange = event => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm }, () => {
      this.filterDatas();
    })
  };

  handleFilterAreaChange = areaId => event => {
    this.setState({ [areaId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  handleFilterAgencyChange = agencyId => event => {
    this.setState({ [agencyId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  formatWl = value => formatNumber(value, 2, '-')

  // check stationName
  checkstationName = stName => {
    if (stName.substring(0, 10) === 'สถานีสถานี') {
      return stName.substring(5);
    }
    return stName;
  }

  filterDatas() {
    const {
      searchTerm, areaId, dataInit, rowsPerPage, page, areaType, agencyId,
    } = this.state;
    const searchData = dataInit
      .filter(d => {
        if (searchTerm !== '') {
          if (d.river_name) {
            if (!(tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1
              || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
              || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
              || d.geocode.province_name.th.indexOf(searchTerm) > -1
              || d.river_name.indexOf(searchTerm) > -1
              || d.station.tele_station_oldcode.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1
            )) {
              return false
            }
          } else {
            if (!(tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1
              || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
              || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
              || d.geocode.province_name.th.indexOf(searchTerm) > -1
              || d.station.tele_station_oldcode.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1
            )) {
              return false
            }
          }
        }
        if (areaId !== '') {
          if (areaType === '1' ? !(d.basin.basin_code.toString() === areaId) : !(d.geocode.province_code === areaId)) {
            return false
          }
        }
        if (agencyId !== '') {
          if (!(d.agency.id.toString() === agencyId)) {
            return false
          }
        }
        return d;
      });
    const pageCurrent = (searchData.length < rowsPerPage) ? 0 : page;

    this.setState({
      searchData,
      page: pageCurrent,
    });
  }

  render() {
    const {
      classes, theme, t, i18n,
    } = this.props;
    const {
      areaType, areaId, areaText, modalData, agencyId, agencyName
    } = this.state;

    const currentLocation = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const {
      isLoading, error, latestDate, photoIndex, isOpen,
      dataCondition, searchData, dataAgency,
      isOpenWlImage, wlImage,
    } = this.state;
    const {
      rowsPerPage, page, order, orderByState, teleImageId,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, searchData.length - page * rowsPerPage);
    const teleKeys = Object.keys(teleImageId);

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }
    arrayCrosssectionImage = [];

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    dataCondition.sort(function (a, b) {
      if (a.id > 100) {
        return 1;
      }
      if (b.id > 100) {
        return -1;
      }
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return (
      <div className={classes.root}>
        <WlContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          {/* Sidebar  */}
          <SidebarWater />

          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs>
                <Box mt={3} display="flex">
                  <Typography variant="h6" gutterBottom>
                    {t('ติดตามสถานการณ์น้ำ')}
                  </Typography>
                </Box>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs>
                <Box display="flex" mt={3} p={0} className={classes.boxTopRadius} alignItems="center" height={53}>
                  <Box flexGrow={1} p={1} display="flex" alignItems="center" style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}>
                    <Box pl={2} className={classes.titleBar}>ระดับน้ำ</Box>
                  </Box>
                  <Box p={1} style={{ borderLeft: '1px solid #ccc' }}>
                    <Tooltip title={t('ดูภาพล่าสุด')}>
                      <Button
                        className={classes.outlinedOldPrimary}
                        onClick={() => this.setState({ isOpenWlImage: true })}
                      >
                        <ImageOutlinedIcon fontSize="small" />
                        <Hidden xsDown>
                          <Typography variant="h6" style={{ marginLeft: 8 }}>
                            {' '}
                            {t('ดูภาพล่าสุด')}
                          </Typography>
                        </Hidden>
                      </Button>
                    </Tooltip>
                  </Box>
                  {isOpenWlImage && (
                    <Lightbox
                      mainSrc={wlImage.src}
                      imagePadding={50}
                      reactModalStyle={{ overlay: { zIndex: '1000000' } }}
                      onCloseRequest={() => this.setState({ isOpenWlImage: false })}
                    />
                  )}
                  <Box p={1} style={{ borderLeft: '1px solid #ccc', borderTopRightRadius: 6, height: 50 }} display="flex" alignItems="center" justifyContent="center">
                    <Link to={`${wlImage.link}`}>
                      <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
                        <Button className={classes.outlinedOldRightPrimary}>
                          <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                          <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูข้อมูลย้อนหลัง')}</Typography></Hidden>
                        </Button>
                      </Tooltip>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box display="flex" pt={1} width="100%">
                  <Box display="inline" p={1} alignItems="center">
                    <HiiWaterLevelIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('ระดับน้ำ')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                  <Grid item xs>
                    <Box pt={1} mt={{ xs: 0, md: 1 }} display="flex" justifyContent="center" alignItems="center">
                      <Button
                        href={`${initPath}water/wl`}
                        variant={currentLocation === 'wl' ? 'contained' : 'outlined'}
                        className={currentLocation === 'wl' ? classes.activeButton : classes.button}
                        fullWidth
                      >
                        <Typography variant="h6">{t('ระดับน้ำ')}</Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box pt={1} mt={{ xs: 0, md: 1 }} display="flex" justifyContent="center" alignItems="center">
                      <Button
                        href={`${initPath}water/wl/rid`}
                        variant={currentLocation === 'rid' ? 'contained' : 'outlined'}
                        className={currentLocation === 'rid' ? classes.activeButton : classes.button}
                        fullWidth
                      >
                        <Typography variant="h6">{t('ปริมาณน้ำท่า')}</Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={5} lg={4}>
                <Box mt={2} width="100%">
                  <WaterSitWaterLevelMap
                    {...this.state}
                    datas={searchData}
                    handleSetMap={this.handleSetMap}
                    language={i18n.language}
                  />
                  <WlLegend />
                </Box>
              </Grid>
              <Grid item xs={12} md={7} lg={8}>
                <Box mt={3} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41 }}>
                      <Box>
                        <FormControl variant="outlined" component="fieldset">
                          <RadioGroup aria-label="position" id="position" name="position" value={areaType} onChange={this.handleAreaTypeChange} row>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลจังหวัด')}</Typography>}
                              labelPlacement="end"
                              fontFamily="Kanit"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลลุ่มน้ำ')}</Typography>}
                              labelPlacement="end"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined" className={classes.formControl} width="100%">
                        <NativeSelect
                          id="select"
                          value={areaId.toString()}
                          onChange={this.handleFilterAreaChange('areaId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit', fontSize: 14 }}>{t(areaText)}</option>
                          <optgroup label={t('ประเทศไทย')} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                            {dataCondition.filter(val => val.id <= 100).map(val => (
                              <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                            ))}
                          </optgroup>
                          <optgroup label={t('ต่างประเทศ')} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                            {dataCondition.filter(val => val.id > 100).map(val => (
                              <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                            ))}
                          </optgroup>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* {add agency filter here} */}
                  <Grid item xs={12} sm={7}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined">
                        <NativeSelect
                          id="agencySelect"
                          value={agencyId.toString()}
                          onChange={this.handleFilterAgencyChange('agencyId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit' }}>{t(agencyName)}</option>
                          {dataAgency.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{tJson(val.name, 'หน่วยงาน')}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <Paper className={classes.rootSearch} elevation={0}>
                        <InputBase
                          display="flex"
                          className={classes.inputSearch}
                          placeholder={t('ค้นหา')}
                          id="search"
                          onChange={this.handleSearchChange}
                        />
                        <IconButton className={classes.iconButton} aria-label="Search">
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box mt={2} />
                    <TableContainer component={Paper}>
                      <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                        <TableHeaderEnhance
                          columnData={columnData}
                          order={order}
                          orderBy={orderByState}
                          onRequestSort={this.handleRequestSort}
                          firstColumnSticky
                        />
                        <TableBody>
                          {orderBy(searchData,
                            [
                              item => (orderByState === 'station.tele_station_name.th'
                                ? item.station.tele_station_name.th.toLowerCase()
                                : orderByState === 'image' ? (teleKeys.filter(row => row.includes(item.station.tele_station_oldcode))) : parseFloat(get(item, orderByState) || 0)),
                            ],
                            [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                              let mapLink = row.station.tele_station_name.th;
                              const stationName = row.station.tele_station_name.th
                                ? `${tJson(row.station.tele_station_name, 'สถานี')}` : row.station.tele_station_oldcode;
                              const titleName = row.agency.agency_name.th
                                ? `${tJson(row.agency.agency_name, 'หน่วยงาน')}` : `หน่วยงาน${row.agency.agency_shortname.th}`;
                              if (
                                this.handleSearchMarker
                                && row.station.tele_station_lat
                                && row.station.tele_station_long
                                && row.storage_percent
                              ) {
                                mapLink = (
                                  <Button
                                    style={{ textAlign: 'left' }}
                                    onClick={this.handleSearchMarker(
                                      row.id,
                                      row.station.tele_station_lat,
                                      row.station.tele_station_long,
                                    )}
                                    title={titleName}
                                  >
                                    {this.checkstationName(stationName)}
                                  </Button>
                                );
                              } else {
                                mapLink = stationName;
                              }

                              let crossSectionLink = '';
                              arrayCrosssectionImage.push(`${initPath}images/crosssection/${row.station.id}.png`);
                              if (crossSection.some(item => item.id === row.station.id.toString())) {
                                crossSectionLink = (
                                  <Button
                                    target="_blank"
                                    alt={t('ภาพตัดลำน้ำ')}
                                    title={t('แสดงภาพตัดลำน้ำ')}
                                    onClick={() => this.setState({ isOpen: true, photoIndex: i })}
                                    rel="noopener noreferrer"
                                  >
                                    <FaChartArea />
                                  </Button>
                                );
                              }
                              const status = WlStatusColor(row.storage_percent);
                              return (
                                <StyledTableRow key={row.id}>
                                  <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                                    {mapLink}
                                    {' '}
                                    {crossSectionLink}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.river_name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {`${tJson(row.geocode.tumbon_name, 'ต.')} ${tJson(row.geocode.amphoe_name, 'อ.')}
                                    ${tJson(row.geocode.province_name, 'จ.', row.geocode.area_code, row.geocode.area_name)}`}
                                  </TableCell>
                                  <TableCell align="right">{this.formatWl(row.waterlevel_msl)}</TableCell>
                                  <TableCell align="right">{this.formatWl(row.station.min_bank)}</TableCell>
                                  <TableCell align="center">
                                    {/* {status} */}
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <Box
                                          ml={2}
                                          borderRadius={4}
                                          style={{
                                            backgroundColor: status.color.statusColor,
                                            color: status.color.constrastColor,
                                            lineHeight: 2,
                                          }}
                                        >
                                          {t(status.color.label)}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Grid item xs={12}>
                                      <Box
                                        sx={{ gridArea: 'main' }}
                                        style={{ fontSize: '0.8em' }}
                                      >
                                        {t(row.diff_wl_bank_text)}
                                      </Box>
                                      <Box
                                        display="flex"
                                        sx={{ gridArea: 'main' }}
                                        style={{
                                          color: status.color.statusColor,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {row.diff_wl_bank}
                                      </Box>
                                    </Grid>
                                  </TableCell>
                                  <TableCell align="center">
                                    {this.compareWl(row.waterlevel_msl,
                                      row.waterlevel_msl_previous,
                                      row.storage_percent)[0].component}
                                  </TableCell>
                                  <TableCell align="center">
                                    {moment(row.waterlevel_datetime).format('HH:mm')}
                                    {' '}
                                    น.
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    <WlContext.Consumer>
                                      {({ handleModalOpen }) => (
                                        <>
                                          <Tooltip title={t('แสดงกราฟ')}>
                                            <IconButton className={classes.button} aria-label="Chart" onClick={this.handleChart(handleModalOpen, row)}>
                                              <TrendingUpIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title={t('แสดงกราฟระดับน้ำรายปี')}>
                                            <IconButton className={classes.button} aria-label="Chart" onClick={this.handleChartYear(handleModalOpen, row)}>
                                              <FaChartLine fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </WlContext.Consumer>
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    <WlContext.Consumer>
                                      {({ handleModalOpen }) => (
                                        <>
                                          {teleKeys.filter(item => item.includes(row.station.tele_station_oldcode)).length !== 0 && (
                                            <Tooltip title={t('รูปภาพเครื่องโทรมาตร')}>
                                              <Button onClick={this.handleImages(handleModalOpen, row)}>
                                                <FaImage fontSize="small" />
                                              </Button>
                                            </Tooltip>
                                          )}
                                        </>
                                      )}
                                    </WlContext.Consumer>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <StyledTableRow style={{ height: 49 * emptyRows }}>
                              <TableCell colSpan={9} />
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      className={classes.hiddenLine}
                      count={searchData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                      labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
                      labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to} ${t('จาก')} ${count} ${t('รายการ')}`}
                    />
                    <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                      {`${t('ข้อมูลล่าสุดวันที่')} ${latestDate} น.`}
                    </Box>
                  </Grid>
                </Grid>

                {isOpen && (
                  <Lightbox
                    mainSrc={arrayCrosssectionImage[photoIndex]}
                    nextSrc={arrayCrosssectionImage[(photoIndex + 1)
                      % arrayCrosssectionImage.length]}
                    prevSrc={
                      arrayCrosssectionImage[
                      (photoIndex + arrayCrosssectionImage.length - 1)
                      % arrayCrosssectionImage.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() => this.setState({
                      photoIndex: (photoIndex + arrayCrosssectionImage.length - 1)
                        % arrayCrosssectionImage.length,
                    })}
                    onMoveNextRequest={() => this.setState({
                      photoIndex: (photoIndex + 1) % arrayCrosssectionImage.length,
                    })}
                    reactModalStyle={{
                      overlay: { zIndex: '10000000' },
                    }}
                    imagePadding={85}
                  />
                )}
              </Grid>
              <Modal
                open={modalData.open}
                modalHeader={modalData.header}
                modalContent={modalData.content}
                resetModal={this.resetModal}
              />
            </Grid>
          </div>
        </WlContext.Provider>
      </div>
    );
  }
}

WaterSitWaterLevel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles, { withTheme: true }), withTranslation('translations', { withRef: true }))(WaterSitWaterLevel);
