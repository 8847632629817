import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import RainfallChart from '../../rainfall/RainfallChart';
import { FaSpinner } from '../../../utils/Fontawesome';

const rfChart = () => {
  const { stationId, rainfallType, provinceId } = useParams();
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    fetch(`${process.env.MIX_API_URL}public/rain_24h`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        const dataRainfall24h = result.data.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.rain_24h > 0);
        });

        setDatas(dataRainfall24h);
        setIsLoading(false);
      })
      .catch(setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  // rainfallType =
  //     rainfall24h
  //   rainfallToday
  //   rainfallYesterday
  //   rainfallMonth
  //   rainfallYear

  return (
    <>
      {isLoading === true && (
        <div className="text-center">
          <FaSpinner size={70} />
        </div>
      )}
      <RainfallChart
        filterData={datas}
        id={stationId}
        provinceId={provinceId}
        selectedType={rainfallType}
        year={moment().year()}
        month={moment().month()}
      />
    </>
  );
};

export default rfChart;
