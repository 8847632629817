
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import {
  uniqueId, orderBy,
} from 'lodash';

// color .
// import grey from '@material-ui/core/colors/grey';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';

// icon .
import { FaHome } from '../../utils/Fontawesome';
import { HiiVideoIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

import StormMeaningIndiaList from './StormMeaningIndiaList';
import StormMeaningPacificList from './StormMeaningPacificList';

const initPath = process.env.MIX_APP_PATH;

const TabContainer = ({ children }) => (
  <Typography component="div">
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class StormMeaning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      value: 0,
    };
  }


  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, t } = this.props;
    const {
      value,
    } = this.state;

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}weather`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('ติดตามสภาพอากาศ')}
                </Typography>
              </Button>
              <Button href={`${initPath}weather/storm`}>
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('พายุ')}
                </Typography>
              </Button>
              <Button href={`${initPath}weather/storm`}>
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('แผนที่วิเคราะห์เส้นทางและความแรงของพายุ')}
                </Typography>
              </Button>
              <Button href={`${initPath}weather/storm/history`}>
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('ภาพพายุหมุนในอดีต')}
                </Typography>
              </Button>
              <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                {t('ความหมายและที่มาของชื่อพายุ')}
              </Typography>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box mt={3}>
              <Typography variant="h5" gutterBottom>
                {t('ความหมายและที่มาของชื่อพายุ')}
              </Typography>
            </Box>
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              variant="fullWidth"
              className={classes.boxTab}
            >
              <Tab label={t('รายชื่อพายุบริเวณมหาสมุทรอินเดียเหนือ')} className={value === 0 ? classes.active_tab : ''} />
              <Tab
                label={(
                  <>
                    <Hidden smDown>{t('รายชื่อพายุบริเวณมหาสมุทรแปซิฟิกด้านตะวันตกตอนบนและทะเลจีนใต้')}</Hidden>
                    <Hidden mdUp>{t('รายชื่อพายุบริเวณมหาสมุทรแปซิฟิก')}</Hidden>
                  </>
                )}
                className={value === 1 ? classes.active_tab : ''} />
            </Tabs>
            {value === 0 && (
              <TabContainer component={Paper}>
                <Box mt={2} />
                <img src={`${initPath}images/storm/india.png`} className={classes.responsive} alt={t('รายชื่อพายุบริเวณมหาสมุทรอินเดียเหนือ')} />
                <StormMeaningIndiaList />
                <Box mt={2}>
                  <Typography className={classes.fontBase} display="block">
                    <span className={classes.fontBaseWeightAbout} >{t('หมายเหตุ :')}</span>  {t('เสนอชื่อโดยประเทศริมมหาสมุทรอินเดีย 13 ประเทศ ข้อมูลปี พ.ศ.2563')}
                  </Typography>
                  <Typography className={classes.fontBase} display="block">
                    <span className={classes.fontBaseWeightAbout} >{t('ที่มา :')}</span>  https://th.wikitionary.org
                  </Typography>
                </Box>
              </TabContainer>
            )}

            {value === 1 && (
              <TabContainer component={Paper}>
                <Box mt={2} />
                <img src={`${initPath}images/storm/pacific.png`} className={classes.responsive} alt={t('รายชื่อพายุบริเวณมหาสมุทรแปซิฟิกด้านตะวันตกตอนบนและทะเลจีนใต้')} />
                <StormMeaningPacificList />
                <Box mt={2}>
                  <Typography className={classes.fontBaseWeightAbout} display="block">
                    {t('หมายเหตุ :')}
                  </Typography>
                  <Typography className={classes.fontBase} display="block">
                    1. {t('การตั้งชื่อพายุหมุนเขตร้อนจะหมุนเวียนกันไปตามลำดับ และอ่านออกเสียงชื่อพายุตามราชบัณฑิตยสภา')}
                  </Typography>
                  <Typography className={classes.fontBase} display="block">
                    2. {t('รายชื่อพายุนี้แก้ไขปรับปรุงในการประชุมทางไกลผ่านจอภาพของคณะกรรมการไต้ฝุ่นครั้งที่ 53 ระหว่างวันที่ 23-25 กุมภาพันธ์ 2564')}
                  </Typography>
                  <Box mt={2} />
                  <Typography className={classes.fontBase} display="block">
                    <span className={classes.fontBaseWeightAbout} >{t('ที่มา :')}</span> {t('ส่วนวิจัยและความร่วมมือทางวิชาการ กองพัฒนาอุตุนิยมวิทยา/มีนาคม 2564')}
                  </Typography>
                </Box>
              </TabContainer>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

StormMeaning.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(StormMeaning);
