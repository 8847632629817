import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Img from 'react-image';
import { uniqueId } from 'lodash';
// ui
import CircularProgress from '@material-ui/core/CircularProgress';

class SwanAnimation extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      error: null,
      animation: [],
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });
    // get data from ws
    this.fetchData();
  }

  fetchData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/wave_forecast_animation_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          animation: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[0].media_path}`,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      isLoading,
      error,
      animation,
    } = this.state;

    return (
      <Grid item xs={12} sm={12} md={12} lg={12} key={uniqueId()}>
        <Box p={0} display="block">
          <Box p={1} display="block" align="center" style={{ border: '1px solid #ccc' }}>
            <Box display="flex" align="center">
              <Box p={1} width="100%" style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>
                {error && (
                  <div className="text-center">{error.message}</div>
                )}
                {isLoading === true && (
                  <div className="text-center">
                    <CircularProgress color="secondary" />
                  </div>
                )}
                <Img
                  key={uniqueId()}
                  width="71%"
                  src={animation}
                  alt="Animation"
                  unloader={(
                    <Img
                      src="https://via.placeholder.com/204x375?text=No%20Image"
                      alt="No Image"
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }
}

export default SwanAnimation;
