/**
 * *
 * @version 1.0.0
 * @author [Peerapong Srisom]
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

const CreditTooltipStyle = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: 10,
  },
}))(Tooltip);

function CreditTooltip(props) {
  const { t } = useTranslation();
  const { title, link } = props;
  return (
    <CreditTooltipStyle
      {...props}
      title={(
        <>
          {t('จัดทำโดย')}
          {' : '}
          <Link href={link} target="_blank">{t(title)}</Link>
        </>
      )}
      interactive
    />
  );
}

CreditTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default CreditTooltip;
