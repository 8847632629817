import React, { useState, useEffect } from 'react';
import Img from "react-image";
import { gistdaSituation, apiKeyGoogle } from './path/drivepath';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from "@material-ui/core/Hidden";
import useStyles from './style';
import { processDataAndFilterLatest, filterTypeImage, handleClick } from './utils/Utils'

const initPath = process.env.MIX_APP_PATH;

function BoxGistda(props) {
    const { openModalImage } = props;
    const classes = useStyles();
    const [files01, setFiles01] = useState([]);
    const [files02, setFiles02] = useState([]);
    const link01 = gistdaSituation.find(v => v.id === 1).history;
    const link02 = gistdaSituation.find(v => v.id === 2).history;


    useEffect(() => {

        const fetchFiles = async (id) => {
            try {
                const param = gistdaSituation.find(v => v.id === id);
                if (!param) return null;
                const url = `${process.env.MIX_APP_URL}proxy/api-mhesiwarroom.php?file=https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                // const url = `https://www.googleapis.com/drive/v3/files?q='${param.folderId}' in parents&key=${apiKeyGoogle}&fields=files(id,name,mimeType,createdTime,modifiedTime,size,webViewLink,webContentLink)`;
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                return data;
            } catch (error) {
                console.error(`Error fetching files for id ${id}:`, error);
                return null;
            }
        };

        const processAndSetFiles = async () => {
            const data01 = await fetchFiles(1);
            const data02 = await fetchFiles(2);

            if (data01) {
                const sortedFiles01 = await processDataAndFilterLatest(data01);
                const dataFilter01 = await filterTypeImage(sortedFiles01);
                setFiles01(dataFilter01);
            }

            if (data02) {
                const sortedFiles02 = await processDataAndFilterLatest(data02);
                const dataFilter02 = await filterTypeImage(sortedFiles02);
                setFiles02(dataFilter02);
            }

        };

        processAndSetFiles();
    }, []);


    return (
        <>
            <Grid
                container
                spacing={3}
                alignItems="start"
                justifyContent="center"
            >
                <Grid item xs={12}>
                    <Box style={{ position: "relative", width: "100%" }}>
                        <Img
                            src={`${initPath}images/mhesi_warroom/tab.png`}
                            width="100%"
                            height="50px"
                            style={{ position: "relative" }}
                            alt="ติดตามสถานการณ์น้ำจากดาวเทียม"
                        />
                        <Box className={classes.tabTitle}>
                            ภาพถ่ายดาวเทียมพื้นที่น้ำท่วม
                            <Hidden smDown><a href="https://disaster.gistda.or.th/flood/" target="_blank" style={{ fontSize: '0.7em', color: 'white', marginRight: 70, marginTop: 3 }}>ดูข้อมูลเพิ่มเติม</a></Hidden>
                        </Box>
                        <Box
                            style={{
                                position: "absolute",
                                right: -31,
                                top: -23,
                                display: "flex",
                            }}
                        >
                            <a
                                href="https://www.gistda.or.th/"
                                className={classes.imgWrapper}
                                target="_blank"
                            >
                                <Img
                                    src={`${initPath}images/mhesi_warroom/logo-gistda.png`}
                                    width="100%"
                                    alt="สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน)"
                                    className={classes.logoPartner}
                                />
                            </a>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                style={{ marginBottom: 30 }}
            >
                <Grid item xs={12} sm={8}> 
                    <p className={classes.subTitle}>ภาพถ่ายดาวเทียมพื้นที่น้ำท่วม</p>
                    <Box display="flex" justifyContent="center">
                        {files01 &&
                            files01.slice(0, 1).map((file, i) => {
                                return (
                                    <Box mx={{ xs: 3, sm: 1, md: 3 }} key={i}>
                                        <Button onClick={() => openModalImage(`https://drive.google.com/thumbnail?sz=w1000&id=${file.id}`)} className={classes.imgWrapper} key={i}>
                                            <Box style={{ width: '100%', height: {xs: 'auto', sm: '500px'}, overflow: 'hidden' }}>
                                                <img src={`https://drive.google.com/thumbnail?sz=w800&id=${file.id}`}
                                                    alt={file.name}
                                                    style={{ border: '7px solid #AEA9A5' }}
                                                    className={classes.imgGis}
                                                />
                                            </Box>
                                        </Button>
                                    </Box>
                                );
                            })}

                    </Box>

                    <Box width={'100%'} display="flex" justifyContent={'flex-end'}>
                        <Button
                            component="a"
                            color="secondary"
                            title="ดูย้อนหลัง"
                            className={classes.btn}
                            style={{ background: '#E1E1E2' }}
                            onClick={() => handleClick(link01)}
                        >
                            ดูย้อนหลัง
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}> 
                    <p className={classes.subTitleRes}>สรุปสถานการณ์จากภาพถ่ายดาวเทียม</p>
                    <Box display="flex" justifyContent="center">
                        {files02 &&
                            files02.slice(0, 1).map((file, i) => {
                                return (
                                    <Box mx={{ xs: 3, sm: 1, md: 3 }} key={i}>
                                        <Button onClick={() => openModalImage(`https://drive.google.com/thumbnail?sz=w1000&id=${file.id}`)} className={classes.imgWrapper} key={i}>
                                            <Box style={{ width: '100%', height: {xs: 'auto', sm: '500px'}, overflow: 'hidden' }}>
                                                <img src={`https://drive.google.com/thumbnail?sz=w800&id=${file.id}`}
                                                    alt={file.name}
                                                    style={{ border: '7px solid #3CCA41' }}
                                                    className={classes.imgGis}
                                                />
                                            </Box>
                                        </Button>
                                    </Box>
                                );
                            })}
                    </Box>

                    <Box width={'100%'} display="flex" justifyContent={'flex-end'}>
                        <Button
                            component="a"
                            color="secondary"
                            title="ดูย้อนหลัง"
                            className={classes.btn}
                            style={{ background: '#B6F9B9' }}
                            onClick={() => handleClick(link02)}
                        >
                            ดูย้อนหลัง
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default BoxGistda;