/* eslint-disable func-names */
/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
/* eslint-disable new-cap */
/* eslint-disable block-scoped-var */
/* eslint-disable comma-spacing */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable max-len */
// Depends on:
// https://github.com/santilland/plotty

// Test plot scale สี https://codesandbox.io/embed/plotty-testing-760b6
// ตย. scale สี
//  plotty.addColorScale("mycolorscale", ["#00ff00", "#0000ff", "#ff0000"], [0, 0.5, 1]);
//                  ( identifier   ,  color_steps,                    , percentage_steps)
// เอกสาร lib Plotty
// https://github.com/santilland/plotty/blob/master/README.md

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  var L = require('leaflet-geotiff');
  // eslint-disable-next-line global-require
  var plotty = require('plotty');
}
// สีจะต้องคำนวณ range เวลากำหนด จะนับจาก range 0-1

// custom color scale
plotty.addColorScale('wrfcolorscale',
  ['#FFF', '#CCE3FF', '#ADD4FF', '#96E6AB', '#69C902', '#FAD600', '#FF8A04', '#CC6602', '#FF2E04', '#CC2624', '#871A1A', '#9930CF'],
  [0, 0.01, 0.02, 0.04, 0.07, 0.1, 0.14, 0.18, 0.3, 0.4, 0.6, 1]);

plotty.addColorScale('compRadarScale',
  ['#FFFFFF', '#FFFFFF', '#CBE3FF', '#CBE3FF', '#ADD3FF', '#ADD3FF', '#97E5A9', '#97E5A9', '#68CA01', '#68CA01', '#FAD500', '#FAD500', '#FD8C02', '#FD8C02', '#CA6604', '#CA6604', '#FF2E07', '#FF2E07', '#CD2522', '#CD2522', '#851B1B', '#851B1B', '#9C32D4', '#9C32D4'],
  [0, 0.001 * 5, 0.001 * 5, 0.005 * 5, 0.005 * 5, 0.010 * 5, 0.010 * 5, 0.020 * 5, 0.020 * 5, 0.030 * 5, 0.030 * 5, 0.040 * 5, 0.040 * 5, 0.055 * 5, 0.055 * 5, 0.070 * 5, 0.070 * 5, 0.085 * 5, 0.085 * 5, 0.100 * 5, 0.100 * 5, 0.120 * 5, 0.120 * 5, 1]);

// ภาพเส้นชั้นน้ำฝน (isohyet) สะสม 1 วัน
plotty.addColorScale('bmaIsohyetScale_1Day',
  ['#FFFFFF', '#FFFFFF', '#CBE3FF', '#CBE3FF', '#ADD3FF', '#ADD3FF', '#97E5A9', '#97E5A9', '#68CA01', '#68CA01', '#FAD500', '#FAD500', '#FD8C02', '#FD8C02', '#CA6604', '#CA6604', '#FF2E07', '#FF2E07', '#CD2522', '#CD2522', '#851B1B', '#851B1B', '#9C32D4', '#9C32D4'],
  [0, 0.001 * 5, 0.001 * 5, 0.005 * 5, 0.005 * 5, 0.010 * 5, 0.010 * 5, 0.020 * 5, 0.020 * 5, 0.030 * 5, 0.030 * 5, 0.040 * 5, 0.040 * 5, 0.055 * 5, 0.055 * 5, 0.070 * 5, 0.070 * 5, 0.085 * 5, 0.085 * 5, 0.100 * 5, 0.100 * 5, 0.120 * 5, 0.120 * 5, 1]);

// ภาพเส้นชั้นน้ำฝน (isohyet)
plotty.addColorScale('bmaIsohyetScale_3Day_7Day',
  ['#FFFFFF', '#CCE3FF', '#ADD4FF', '#96E6AB', '#69C902', '#FAD600', '#FF8A04', '#CC6602', '#FF2E04', '#CC2624', '#871A1A', '#9930CF'],
  [0, 0.0033, 0.0166, 0.0333, 0.0666, 0.1166, 0.1666, 0.3, 0.3, 0.5, 0.6666, 1]);

// ภาพเส้นชั้นน้ำฝน (isohyet) สะสม 30 วัน
plotty.addColorScale('bmaIsohyetScale_15Day_30Day',
  ['#ffffff', '#ffffcc', '#ffff99', '#ffff33', '#ffff0b', '#ffff00', '#ccff00', '#99ff00', '#33ff00', '#00ff33', '#01ff66', '#00ff99', '#00ffff', '#00ccff', '#0099ff', '#0266ff', '#0834ff', '#4301f8', '#6600ff', '#9900ff', '#cc00ff', '#ff00ff', '#ff0099', '#ff0166', '#ec0720'],
  [0, 0.0142, 0.0285, 0.0571, 0.1142, 0.1428, 0.1714, 0.2, 0.2142, 0.2285, 0.2428, 0.2571, 0.2714, 0.2857, 0.3, 0.3142, 0.3285, 0.3571, 0.3714, 0.3857, 0.4,0.4, 0.4285, 0.7142, 1]);

// ภาพฝนสะสม 1, 3, 7 วัน
plotty.addColorScale('rainfallAccumulationScale',
  ['#FFFFFF', '#CBE3FF', '#ADD3FF',
    '#97E5A9', '#68CA01', '#FAD500', '#FD8C02',
    '#CA6604', '#FF2E07', '#CD2522','#851B1B',
    '#9C32D4'],
  [0.0025, 0.0125,
    0.025, 0.05, 0.0875, 0.125,
    0.175, 0.225, 0.375, 0.500, 0.750,
    1]);

// สีสำหรับ คาดการณ์ฝนรายเดือน ใช้งานร่วมกับ Geotiff
plotty.addColorScale('rainForecastMonthVolumeScale',
  [
    '#FFFFFF', '#FFFFCC', '#FFFF99', '#FFFF00', '#CCFF00', '#CCFF00', '#66FF00', '#33FF00', '#00FF33', '#00FF66', '#00FF99', '#00FFCC', '#00FFFF', '#00CCFF', '#0099FF', '#0066FF', '#0033FF', '#0000FF', '#4300F8', '#6600FF', '#9900FF', '#CC00FF','#FF00AA' , '#FF005E', '#ED0520','#CC041B','#B80000'],
  [0.003333333, 0.006666667, 0.013333333, 0.026666667, 0.033333333, 0.04, 0.046666667, 0.05, 0.053333333, 0.056666667, 0.06, 0.063333333, 0.066666667, 0.07, 0.073333333, 0.076666667, 0.08, 0.083333333, 0.086666667, 0.09, 0.093333333, 0.1, 0.13, 0.16, 0.19, 0.233333333, 1]);
plotty.addColorScale('rainForecastMonthAnomalyScale',
  ['#9C551F', '#A6622B', '#BA8045', '#C49054', '#CFA263', '#B07137', '#D9B375', '#E3C586', '#EDD898', '#F5EAAB', '#FCFCFC', '#EAF2BB', '#D4E6B8', '#BED9B4', '#A7C9AD', '#91BDA8', '#7BB0A3', '#68A6A1', '#52999C', '#3C8D96', '#218291'],
  [0, 0.046153846, 0.092307692, 0.138461538, 0.184615384, 0.23076923, 0.276923076, 0.323076922, 0.369230768, 0.415384614, 0.46153846, 0.507692306, 0.553846152, 0.599999998, 0.646153844, 0.69230769, 0.738461536, 0.784615382, 0.830769228, 0.876923074, 1]);

L.LeafletGeotiff.Plotty = L.LeafletGeotiffRenderer.extend({

  options: {
    colorScale: 'viridis',
    clampLow: true,
    clampHigh: true,
    displayMin: 0,
    displayMax: 1,
  },

  initialize(options) {
    if (typeof (plotty) === 'undefined') {
      throw new Error('plotty not defined');
    }
    this.name = 'Plotty';

    L.setOptions(this, options);

    this._preLoadColorScale();
  },

  setColorScale(colorScale) {
    this.options.colorScale = colorScale;
    this.parent._reset();
  },

  setDisplayRange(min, max) {
    this.options.displayMin = min;
    this.options.displayMax = max;
    this.parent._reset();
  },

  _preLoadColorScale() {
    const canvas = document.createElement('canvas');
    const plot = new plotty.plot({
      canvas,
      data: [0],
      width: 1,
      height: 1,
      domain: [this.options.displayMin, this.options.displayMax],
      colorScale: this.options.colorScale,
      clampLow: this.options.clampLow,
      clampHigh: this.options.clampHigh,
    });
    this.colorScaleData = plot.colorScaleCanvas.toDataURL();
  },

  render(raster, canvas, ctx, args) {
    const plottyCanvas = document.createElement('canvas');
    const plot = new plotty.plot({
      data: raster.data,
      width: raster.width,
      height: raster.height,
      domain: [this.options.displayMin, this.options.displayMax],
      colorScale: this.options.colorScale,
      clampLow: this.options.clampLow,
      clampHigh: this.options.clampHigh,
      canvas: plottyCanvas,
      useWebGL: false,
    });
    plot.setNoDataValue(-9999);
    plot.render();

    this.colorScaleData = plot.colorScaleCanvas.toDataURL();

    const rasterImageData = plottyCanvas.getContext('2d').getImageData(0, 0, plottyCanvas.width, plottyCanvas.height);
    const imageData = this.parent.transform(rasterImageData, args);
    ctx.putImageData(imageData, args.xStart, args.yStart);
  },

});

L.LeafletGeotiff.plotty = function (options) {
  return new L.LeafletGeotiff.Plotty(options);
};

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = L.LeafletGeotiff;
}
