import { makeStyles } from '@material-ui/core/styles';

const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles((theme) => ({
    layout:{ 
        overflow: 'hidden !important',
        width: 1280,
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    header: {
        background: `url(${initPath}images/demo_site_tele/bg-banner.jpg)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '102%',
        height: 550,
        padding: 15,
    },
   
    logo: {
        width: 50,
        p: 8,
    },
    icon: {
        width: 30,
        marginRight: 15,
    },
    logoPartner: {
        width: 80,
        marginRight: 8,
        '&:hover': {
            transform: 'scale(1.05)',
            cursor: 'pointer',
        },
    },
    logoFooter: {
        width: 130,
    },

   content: {
    left: 0,
    top: 500,
    position: 'absolute',
   },
    boxNav: {
        padding: '8px 15px',
        background: '#ffffffd1',
        borderRadius: 6,
    },
    projectName: {
        color: '#1976D2',
        fontWeight: 600,
        fontSize: '1.5em',
        fontFamily: 'Kanit',
        lineHeight: '30px',
    },
    subProjectName: {
        color: '#717171',
        fontWeight: 300,
        fontSize: '1.1em',
        fontFamily: 'Kanit',
        lineHeight: '0.9',
    },
    title: {
        color: '#000',
        fontWeight: 600,
        padding: '5px',
        fontSize: '2em',
        fontFamily: 'Kanit',
    },
    subTitle: {
        fontSize: '0.9em',
        fontWeight: 300,
        marginLeft: 10,
    },
    titleItem: {
        color: '#000',
        fontWeight: 600,
        padding: '5px',
        fontSize: '1.5em',
        fontFamily: 'Kanit',
    },
    subTitleImg: {
        fontSize: '1.2em',
        fontWeight: 400,
        textAlign: "center"
    },
    subTitleRes: {
        fontSize: '1.3em',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9em',
            padding: '7px',
        },
    },
    link: {
        color: '#000',
        cursor: 'pointer !important',
        '&:link': {
            opacity: 0.8,
        },
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer',
        },
    },
    itemLink: {
        width: '100%',
        height: 350,
        color: '#000',
        backgroundColor: '#fff',
        textAlign: 'center',
        '&:hover $img': {
            opacity: 0.8,
            tranßsform: 'scale(1.05)',
        },
    },
    bannerLink: {
        marginTop: 15,
        width: '100%',
        height: 550,
        color: '#000',
        backgroundColor: '#fff',
        textAlign: 'center',
        objectFit: 'cover',
        '&:hover $img': {
            opacity: 0.8,
            tranßsform: 'scale(1.05)',
        },
    },
    img: {
        width: '100%',
    },
    boxItem: {
        border: '1.5px solid #999',
    },

    tableHeadCell: {
        borderTop: '1px solid #ccc', // เส้นขอบด้านบน
        borderBottom: '1px solid #ccc', // เส้นขอบด้านล่าง
        '&:not(:last-child)': {
          borderRight: '1px solid #ccc', // เส้นขอบด้านขวา ยกเว้นเซลล์สุดท้าย
        },
        '&:first-child': {
          borderLeft: 'none', // ไม่แสดงเส้นขอบด้านซ้ายสำหรับเซลล์แรก
        },
      },
    // imgLinkDailyReport: {
    //     width: '100%',
    //     boxShadow: '3px 2px 3px rgb(181 177 177 / 78%)',
    //     overflow: 'hidden',
    //     display: 'flex',
    //     alignItems: 'center',
    //     [theme.breakpoints.down('xs')]: {
    //         height: '100%',
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         height: 180,
    //     },
    //     [theme.breakpoints.up('md')]: {
    //         height: 300,
    //     },
    // },


    btn: {
        color: '#fff',
        fontSize: '1.2em',
        marginTop: 10,
        marginBottom: 10,
        padding: '3px 15px',
        textAlign: 'right',
        borderRadius: 6,
        background: '#18A0FB',
        '&:hover': {
            color: '#18A0FB',
        },
    },
    tableHeadCell: {
        borderTop: '1px solid #ccc', // เส้นขอบด้านบน
        borderBottom: '1px solid #ccc', // เส้นขอบด้านล่าง
        '&:not(:last-child)': {
          borderRight: '1px solid #ccc', // เส้นขอบด้านขวา ยกเว้นเซลล์สุดท้าย
        },
        '&:first-child': {
          borderLeft: 'none', // ไม่แสดงเส้นขอบด้านซ้ายสำหรับเซลล์แรก
        },
      },

    body: {
        position:'absolute',
        left:0,
        width:'100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#d2cfcf',
    },

    footer: {
        position:'absolute',
        left:0,
        width:'100%',
        display: 'block',
        justifyContent: 'center',
    },


}));

export default useStyles;