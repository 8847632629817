// React
import React, { useEffect, useState, useRef } from 'react';
// leaflet
import {
  Map, Marker, TileLayer, Tooltip,
} from 'react-leaflet';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
// component
import HeaderMap from '../../navigation/HeaderStormSurgeMap';
import iconCircle from '../../widgets/map/iconCircle';
import StormSurgeForecastChart from './StormSurgeForecastChart';
import CurrentLocation from '../../currentlocation/CurrentLocation';

// const initPath = process.env.MIX_API_HII_URL;

export default function StormSurgeForecastMap(props) {
  const { listStation, handleModalOpen } = props;
  const [settingMap] = useState({ position: [13.8, 100.55], zoom: 6 });
  const mapRef = useRef(null);

  useEffect(() => {
    document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '15%';
  }, []);

  const handleChart = stationName => {
    const params = {
      header: 'กราฟแสดงการเปลี่ยนแปลงระดับน้ำทะเล',
      modalSubHeader: '',
      content: <StormSurgeForecastChart stationName={stationName} />,
    };
    handleModalOpen(params);
  };

  const eachData = (data, i) => {
    const position = [parseFloat(data.lat), parseFloat(data.lon)];
    let icon = iconCircle.blue;
    console.log(data.warning);
    if (data.warning.level !== 0) {
      icon = iconCircle.orange;
    }
    return (
      <Marker
        id={i}
        key={i}
        position={position}
        icon={icon}
        onClick={() => handleChart(data.name_EN)}
        location={[13.8, 100.55]}
        zoom={6}
        datas={listStation}
      >
        <Hidden smDown>
          <Tooltip title={data.name_TH}>
            <span>
              <strong>
                {data.name_TH}
              </strong>
            </span>
          </Tooltip>
        </Hidden>
      </Marker>
    );
  };

  return (
    <>
      <Map
        id="map"
        center={settingMap.position}
        zoom={settingMap.zoom}
        ref={mapRef}
        style={{
          height: window.height,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1300,
        }}
      >
        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          id="OpenStreetMap.HOT"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
        />
        {console.log(listStation)}
        {listStation.map(eachData)}
        <Box mt={{ xs: 1, sm: 2, md: 5 }} style={{ position: 'absolute', zIndex: 99999, width: '100%' }}>
          <HeaderMap />
        </Box>
        <CurrentLocation mapRef={mapRef} showOnly={false} />
      </Map>
    </>
  );
}

StormSurgeForecastMap.propTypes = {
  listStation: PropTypes.array.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
};
