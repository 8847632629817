import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// icon .
import { FaHome } from '../../utils/Fontawesome';
import { HiiPresentIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;
moment.locale('th');
class ReportMonthlyDrought extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      startDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD'),
      currentYear: moment().subtract(1, 'months').endOf('month').format('YYYY'),
      currentMonth: moment().format('MM'),
    };
  }

  handleChange = date => {
    this.setState({
      startDate: date,
      currentYear: moment(date).format('YYYY'),
    });
  };

  urlEncode = url => Buffer.from(url).toString('base64');

  render() {
    const { classes, t, i18n } = this.props;
    const {
      startDate, currentYear, currentDate, currentMonth,
    } = this.state;

    return (
      <div className={classes.layout}>
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              <Button href={`${initPath}report`}>
                <FaHome fontSize="small" style={{ marginRight: 5 }} />
                <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                  {' '}
                  {t('รายงานสถานการณ์')}
                </Typography>
              </Button>
              <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                {t('รายงานข้อมูลน้ำรายเดือนภัยแล้ง')}
              </Typography>
            </Breadcrumbs>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
              <Box display="flex" pb={0} mb={0}>
                <Box display="flex" width="100%">
                  <Box display="inline" pt={1}>
                    <HiiPresentIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={1} pl={1}>
                    <Typography variant="h4" gutterBottom>
                      {t('รายงานข้อมูลน้ำรายเดือนภัยแล้ง')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" py={2} width="100%" alignItems="center" justifyContent="center" bgcolor="background.paper">
              <Box display="inline" fontFamily="Kanit">
                <Typography variant="h6" style={{ marginRight: 10 }} noWrap>ปี</Typography>
              </Box>
              <Box display="inline">
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                    <KeyboardDatePicker
                      openTo="year"
                      views={['year']}
                      value={startDate}
                      maxDate={currentDate}
                      minDate={moment('2020-11-01').format('YYYY-MM-DD')}
                      onChange={this.handleChange}
                      style={{ fontSize: 14 }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {[1, 2, 3, 4, 11, 12].filter(item => {
            if ((currentYear === '2020' && item < 12) || parseInt(currentMonth, 0) === item) {
              return false;
            }
            return true;
          }).map(item => {
            const month = ((item) > 9) ? item : `0${item}`;
            const fileName = moment(`${currentYear}-${month}-01`).format('YYYYMM');
            const compareMonth = ((item) > moment().subtract(0, 'months').endOf('month').format('MM'));
            const thisYear = moment().format('YYYY');
            const monthlyReportImg = `${initPath}images/theme/banner/report/monthly/drought/${item}.png`;

            const reportLink = `${process.env.MIX_LIVE_URL}/product/latest/dashboard/docs/drought/drought_${fileName}.pdf`;
            let link = compareMonth ? '#' : reportLink;

            if (thisYear > currentYear) {
              link = reportLink;
            }

            const dataOutput = (
              <Fragment key={uniqueId()}>
                <Grid item xs={4} sd={3} md={2} lg={2} align="center">
                  <Box p={1}>
                    <Box fontSize={{ xs: 11, sm: 14, md: 16 }} fontFamily="Kanit" display="inline">
                      {moment(`${currentYear}-${month}-01`).format('MMMM')}
                    </Box>
                    <Box fontSize={{ xs: 11, sm: 14, md: 16 }} fontFamily="Kanit" display="inline">
                      {' '}
                      {moment(`${currentYear}-${month}-01`).format('YYYY')}
                    </Box>
                  </Box>
                  <Box>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <img src={monthlyReportImg} className={classes.outLined} width="100%" alt={t('รายงานข้อมูลน้ำรายเดือนภัยแล้ง')} />
                      <img src={`${initPath}images/theme/banner/report/book-shadow.png`} width="100%" style={{ marginTop: -7 }} alt="ปก" />
                    </a>
                  </Box>
                </Grid>
              </Fragment>
            );

            if (!compareMonth) {
              return dataOutput;
            }

            if (thisYear > currentYear) {
              return dataOutput;
            }

            return (<Fragment key={uniqueId()} />);
          })}

        </Grid>
      </div>
    );
  }
}

ReportMonthlyDrought.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withTranslation()(withStyles(styles)(ReportMonthlyDrought));
