import React from 'react';
import PropTypes from 'prop-types';

// ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import TableContainer from '@material-ui/core/TableContainer';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { FaSpinner, FaChartBar } from '../../../utils/Fontawesome';
import TablePaginationActionsWrapped from '../../widgets/table/TablePaginationActions';

// modal
import TableHeaderEnhance from '../../widgets/table/TableHeaderEnhance';

import {
  styles, StyledTableRow,
} from '../../../styles/Style';

import SwanChart from './SwanChart';

// table Header
const columnData = [
  {
    id: 'date', align: 'left', numeric: false, disablePadding: true, label: 'วันที่', unit: '(+7 hours for local time)', sortable: false,
  },
  {
    id: 'time', align: 'center', numeric: true, disablePadding: false, label: 'เวลา', unit: '-', sortable: false,
  },
  {
    id: 'depth', align: 'center', numeric: true, disablePadding: false, label: 'Depth', unit: '(m)', sortable: false,
  },
  {
    id: 'hsig', align: 'center', numeric: true, disablePadding: false, label: 'Hsig', unit: '(m)', sortable: false,
  },
  {
    id: 'dir', align: 'center', numeric: true, disablePadding: false, label: 'Dir', unit: '(degr)', sortable: false,
  },
  {
    id: 'TPsmoo', align: 'center', numeric: true, disablePadding: false, label: 'TPsmoo', unit: '(sec)', sortable: false,
  },
  {
    id: 'tmten', numeric: true, disablePadding: false, label: 'TM 10', unit: '(sec)', sortable: false,
  },
  {
    id: 'xwindv', align: 'center', numeric: false, disablePadding: false, label: 'X-Windv', unit: '(m/s)', sortable: false,
  },
  {
    id: 'ywindv', align: 'center', numeric: false, disablePadding: false, label: 'Y-Windv', unit: '(m/s)', sortable: false,
  },
];

class SwanLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      datas: [],
      selectedStation: 1,

      page: 0,
      rowsPerPage: 10,

      order: 'asc',
      orderBy: 'date',

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      selectedStation: event.target.value,
    });

    this.fetchData(event.target.value);
  };

  handleChart = (handler, data) => e => {
    e.preventDefault();

    const params = {
      header: 'กราฟคาดการณ์คลื่น',
      content: <SwanChart datas={data} />,
    };

    handler(params);
  }

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderBy === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderBy: orderByProperty });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = '60%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '60%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  fetchData(stationId = 1) {
    fetch(`${process.env.MIX_API_URL}public/wave_forecast?station_id=${stationId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          datas: result.data,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      error,
      datas,
      selectedStation,
      modalHeader,
      modalSubHeader,
      modalContent,
      open,
    } = this.state;
    const {
      rowsPerPage, page, order, orderBy,
    } = this.state;

    let waves = [];
    let stationName = '';

    if (datas.length > 0) {
      stationName = datas[0].station.swan_name.th;
      waves = datas[0].wave_forecast_data;
    }

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={35} />
          </div>
        )}

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box mt={2}>
              <img src={`${process.env.MIX_APP_PATH}images/swan_location.png`} alt="Location Map" width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Grid container style={{ marginTop: 16 }}>
              <Grid item xs={12} sm md>
                <Box display="flex">
                  <Typography variant="h4" display="inline">
                    {`สถานี${stationName}`}
                  </Typography>
                  <Typography variant="h4" display="inline">
                    <Tooltip title="กราฟเปรียบเทียบรายวัน">
                      <IconButton
                        aria-label="Chart"
                        onClick={this.handleChart(this.handleModalOpen, datas)}
                      >
                        <FaChartBar fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <form autoComplete="off">
                  <Select
                    value={selectedStation}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'selectedStation',
                      id: 'selectedStation',
                    }}
                    style={{
                      width: '100%',
                      fontFamily: 'Kanit',
                      fontSize: 16,
                      fontWeight: 500,
                      marginBottom: 5,
                    }}
                  >
                    <MenuItem value={1}>เจ้าพระยา</MenuItem>
                    <MenuItem value={2}>แม่กลอง สมุทรสงคราม</MenuItem>
                    <MenuItem value={3}>ท่าจีน สมุทรสาคร</MenuItem>
                    <MenuItem value={4}>ฉะเชิงเทรา</MenuItem>
                    <MenuItem value={5}>เกาะช้าง ตราด</MenuItem>
                    <MenuItem value={6}>แหลมสิงห์ จันทบุรี</MenuItem>
                    <MenuItem value={7}>แกลง ระยอง</MenuItem>
                    <MenuItem value={8}>สัตหีบ ชลบุรี</MenuItem>
                    <MenuItem value={9}>พัทยา ชลบุรี</MenuItem>
                    <MenuItem value={10}>ชะอำ เพชรบุรี</MenuItem>
                    <MenuItem value={12}>เกาะหลัก พังงา</MenuItem>
                    <MenuItem value={13}>เกาะมัตโพน ชุมพร</MenuItem>
                    <MenuItem value={14}>เกาะปราบ สุราษฎร์ธานี</MenuItem>
                    <MenuItem value={15}>เกาะสมุย สุราษฎร์ธานี</MenuItem>
                    <MenuItem value={16}>ปากพนัง นครศรีธรรมราช</MenuItem>
                  </Select>
                </form>
              </Grid>
            </Grid>

            <TableContainer>
              <Table className={classes.table} style={{ marginTop: 5 }}>
                <TableHeaderEnhance
                  columnData={columnData}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                />
                <TableBody>
                  {waves
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <StyledTableRow key={row.swan_datetime}>
                        <TableCell height={55}>
                          {moment(row.swan_datetime).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell align="center">{`${moment(row.swan_datetime).format('LT')} น.`}</TableCell>
                        <TableCell align="center">{row.swan_depth}</TableCell>
                        <TableCell align="center">{row.swan_highsig}</TableCell>
                        <TableCell align="center">{row.swan_direction}</TableCell>
                        <TableCell align="center">{row.swan_period_top}</TableCell>
                        <TableCell align="center">{row.swan_period_average}</TableCell>
                        <TableCell align="center">{row.swan_windx}</TableCell>
                        <TableCell align="center">{row.swan_windy}</TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              className={classes.hiddenLine}
              count={waves.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActionsWrapped}
              labelRowsPerPage="จำนวนแถวต่อหน้า"
              labelDisplayedRows={({ from, to, count }) => `แสดงรายการ ${from}-${to} จาก ${count} รายการ`}
            />

            <Box pt={2}>
              <Typography variant="h6"><b>ความหมาย</b></Typography>
              <Typography variant="h6">Hsig = Significant Wave Height ความสูงคลื่นนัยสำคัญ</Typography>
              <Typography variant="h6">Dir = Wave Direction ทิศทางคลื่น</Typography>
              <Typography variant="h6">TPsmoo = Peak period คาบสูงสุด</Typography>
              <Typography variant="h6">Tm_10 = Wave period คาบคลื่น</Typography>
              <Typography variant="h6">X-Windv = Wind velocity components in x -direction ความเร็วลมในแนวตะวันออก-ตะวันตก</Typography>
              <Typography variant="h6">Y-Windv = Wind velocity components in y -direction ความเร็วลมในแนวเหนือ-ใต้</Typography>
            </Box>
          </Grid>
          <Modal
            id="modal-chart"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={this.handleModalClose}
            style={{ align: 'center', justifyContent: 'center' }}
          >
            <div style={this.getModalStyle()} className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                spacing={0}
              >
                <Grid item>
                  <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography variant="h5" id="modal-title">
                {modalHeader}
              </Typography>
              <Typography variant="subtitle1" id="simple-modal-description">
                {modalSubHeader}
              </Typography>
              <Box mt={3}>
                {modalContent}
              </Box>
            </div>
          </Modal>
        </Grid>
      </>
    );
  }
}

SwanLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};
export default compose(withWidth(), withStyles(styles, { withTheme: true }))(SwanLocation);
// export default withStyles(styles, { withTheme: true })(SwanLocation);
