/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */
// thaiwater api key
// AIzaSyCwV9IaNrR4xuP-t--gw25MEn_Oam31x6U

// search engine id
// 011549169773725044888:uvmkat6gac6

// https://console.developers.google.com/apis/credentials?authuser=3&folder=&organizationId=&project=api-project-1055308869704
// https://search.google.com/u/3/search-console/settings?resource_id=http%3A%2F%2Fwww.thaiwater.net%2F

// The first 100 queries per day are free.
// Any more, then you have to pay $5 per 1000 queries,
// for up to 10,000 queries per day,
// Each query returns a maximum of 10 results

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { withTranslation } from 'react-i18next';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

import { blue, grey } from '@material-ui/core/colors';
import { FaSpinner } from '../../utils/Fontawesome';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1160 + theme.spacing(3) * 2)]: {
      width: 1160,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  paperGrid: {
    padding: `${theme.spacing(2)}px 0`,
  },
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
});

class SearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      searchItems: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { searchTerm } } } = this.props;
    if (prevProps.match.params.searchTerm !== searchTerm) {
      this.getData();
    }
  }

  getData() {
    const { match: { params: { searchTerm } } } = this.props;
    const apiKey = 'AIzaSyCwV9IaNrR4xuP-t--gw25MEn_Oam31x6U&';
    const customSearchId = '011549169773725044888:uvmkat6gac6';
    const url = `https://www.googleapis.com/customsearch/v1?key=${apiKey}cx=${customSearchId}&q=${searchTerm}`;

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          searchItems: result.items,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes, t } = this.props;
    const { isLoading, error, searchItems } = this.state;
    return (
      <>
        {error && <div className="text-center">{error.message}</div>}

        {isLoading === true && (
        <div className="text-center">
          <FaSpinner size={35} />
        </div>
        )}
        <div className={classes.root}>
          <Grid container spacing={10}>
            <Grid item sm={12} md={12} lg={12}>
              <Box mt={4} display="flex" alignItems="center">
                <Box flexGrow={1} fontSize={22} style={{ color: grey[600] }}>{t('ค้นหา')}</Box>
                <Box>
                  {t('ผลการค้นหาประมาณ')}
                  {' '}
                  {searchItems.length}
                  {' '}
                  {t('รายการ')}
                </Box>
              </Box>
              <Divider />
              {searchItems.map(item => (
                <Box my={4} key={uniqueId()}>
                  <Link href={item.link} color="inherit">
                    <Box fontSize={14} fontWeight={300} style={{ color: grey[600] }} fontFamily="Kanit" dangerouslySetInnerHTML={{ __html: item.link }} />
                    <Box fontSize={16} fontWeight="fontWeightMedium" style={{ color: blue[700] }} color="primary" dangerouslySetInnerHTML={{ __html: item.htmlTitle }} />
                    <Box fontSize={14} fontFamily="sans-serif" dangerouslySetInnerHTML={{ __html: item.htmlSnippet }} />
                  </Link>
                </Box>
              ))}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

SearchResult.defaultProps = {
  match: {
    params: {
      searchTerm: '',
    },
  },
};

SearchResult.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(SearchResult));
