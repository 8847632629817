/* eslint-disable no-unused-vars */
import React, { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId,
  orderBy,
} from 'lodash';
import classNames from 'classnames';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Hidden } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import CircularProgressbar from 'react-circular-progressbar';
import Box from '@material-ui/core/Box';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// map
import L from 'leaflet';
import {
  Map, Marker, Popup, TileLayer, Tooltip, GeoJSON, LayerGroup, LayersControl, WMSTileLayer, CircleMarker,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
import 'react-leaflet-fullscreen/dist/styles.css';
import FullscreenControl from 'react-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { FaChartLine, FaSpinner } from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

// color
import RainfallStatusColor from '../rainfall/RainfallStatusColor';
import RainfallContext from '../../contexts/rainfallContext';
import PmChart from '../rainfall/PmChart';

// translate
import tJson from '../../utils/TJson';

// icon
import { HiiRainIcon, HiiTakeMaskIcon, HiiUnMaskIcon } from '../../utils/Icon';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';
import { styles } from '../../styles/Style';
import RainfallMonthLegend from '../rainfall/RainfallMonthLegend';
import RainfallYearLegend from '../rainfall/RainfallYearLegend';
import RainfallLegend from '../rainfall/RainfallLegend';

const { BaseLayer, Overlay } = LayersControl; // BaseLayer
const initPathWMS = process.env.MIX_APP_WMS;
const initPath = process.env.MIX_APP_PATH;

const tooltipStyle = {
  backgroundColor: '#ff0000',
  color: '#ffffff', // optional text color
  // other style properties as needed
};

class RainfallMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.rainfall24hLayer = createRef();
    this.rainfall24hLayer1 = createRef();
    this.rainfall24hLayer2 = createRef();
    this.rainfall24hLayer3 = createRef();
    this.rainfall24hLayer4 = createRef();
    this.rainfall24hLayer5 = createRef();
    this.rainfall24hLayer6 = createRef();
    this.rainfall24hLayer7 = createRef();

    this.state = {
      error: null,
      currentHover: 'ชี้เส้นลำน้ำเพื่อแสดงชื่อ',
      height: this.setHeightMap(),
      zoom: this.setZoomMap(),
      // rainfallYesterdayDatas: props.datasRainYesterday,
      // rainMonthlyDatas: props.datasRainMonth,
      // rainfallYearDatas: props.datasRainYear,
      geojsonProvince: [],
      geojsonBasin: [],
      loading: false,
    };

    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.getGeoJson = this.getGeoJson.bind(this);
    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;
    this.getGeoJson();
    this._mounted = true;
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  shouldComponentUpdate(nextProps) {
    const {
      searchRainfall24,
      value,
      areaName,
      type,
      language,
    } = this.props;
    if (searchRainfall24 !== nextProps.searchRainfall24 || language !== nextProps.language) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const { value, areaName } = this.props;

    // eslint-disable-next-line no-underscore-dangle
    let obj = this.refBoundaryProvince.current.leafletElement._layers;

    // thailand
    if (areaName === '') {
      const { location, zoom } = this.props;
      this.map.setView(location, zoom);

      return;
    }

    if (value !== prevProps.value || areaName !== prevProps.areaName) {
      if (value === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }

      // get feature
      // eslint-disable-next-line no-underscore-dangle
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          // province
          if (value === '0') {
            const provCode = obj[key].feature.properties.prov_code;
            if (provCode.toString() === areaName) {
              this.map.fitBounds(obj[key].getBounds());
            }
          } else { // basin
            const basinCode = obj[key].feature.properties.BASIN_CODE;
            if (basinCode === areaName) {
              this.map.fitBounds(obj[key].getBounds());
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setHeightMap() {
    const { width } = this.props;
    // let heightMap = 724;
    let heightMap = 641;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 641;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 641;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 550;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 5;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 4;
    }
    return zoom;
  }

  // set marker icon based on criteria
  setIcon = (val, type) => {
    const status = RainfallStatusColor(val, type);

    return status.icon;
  }

  getBasinStyle(feature) {
    const { value, areaName } = this.props;

    if (value === '1' && areaName) {
      if (feature.properties.BASIN_CODE === areaName) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getProvinceStyle(feature) {
    const { value, areaName } = this.props;
    if (value === '0' && areaName) {
      if (feature.properties.prov_code === areaName) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getGeoJson() {
    Promise.all([
      fetch(`${initPath}json/boundary/thailand.json`),
      fetch(`${initPath}json/boundary/basin.json`),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([province, basin]) => {
        this.setState({ geojsonProvince: province, geojsonBasin: basin });
      })
      .catch(error => this.setState({ error }));
  }

  // get dam status
  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(2), type);
    return status;
  }

  handleRainfallChart = (handler, data, type, filterData) => e => {
    const { t } = this.props;
    e.preventDefault();

    const params = {
      header: t('กราฟแสดงคุณภาพอากาศ'),
      content: <PmChart
        id={data.station.id}
        provinceId={data.geocode.province_code}
        selectedType={type}
        filterData={filterData}
        year={(data.rainfall_datetime).substring(0, 4)}
        month={(data.rainfall_datetime).substring(5, 7)}
      />,
    };

    handler(params);
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  eachData = (data, i) => {
    const { classes, searchRainfall24, t } = this.props;
    if (data.station.tele_station_lat && data.station.tele_station_long
      && data.rain_24h >= 0 && data.station.id > 0) { // && data.rain_24h != null
      // marker position
      const position = [parseFloat(data.station.tele_station_lat),
        parseFloat(data.station.tele_station_long)];
      // marker icons
      const icon = this.setIcon(parseFloat(data.rain_24h), 'rainfall24h');
      // status colors
      // const statusColor = RainfallStatusColor(data.rain_24h);
      const rainfall24h = parseFloat(data.rain_24h).toFixed(2);
      const textRainfallStatus = this.rainfallStatus(rainfall24h, 'rainfall24h').color.statusColor;
      const rainfallStatusFontColor = this.rainfallStatus(rainfall24h, 'rainfall24h').color.constrastColor;

      return (
        <Marker
          id={data.station.id}
          key={i}
          zIndexOffset={i}
          position={position}
          icon={icon}
          ref={this.bindMarker(data.station.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        >
          <Popup autoPan={false}>
            <Box p={1} pb={0}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2} style={{ border: '1px solid #eee', borderBottom: 'none' }}>
                      <Box
                        display="flex"
                        p={1}
                        width="100%"
                        justifyContent="center"
                        style={{
                          backgroundColor: textRainfallStatus,
                          color: rainfallStatusFontColor,
                          fontSize: 16,
                        }}
                      >
                        <Box display="flex" alignContent="center" alignItems="center">
                          {/* <HiiUnMaskIcon className={classes.maskIcon} /> */}
                          {/* template mask */}
                          <HiiTakeMaskIcon className={classes.maskIcon} />

                          <Typography variant="h5" style={{ fontWeight: 500 }}>
                            มีผลกระทบต่อสุขภาพ
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} align="center" colSpan={2} style={{ border: '1px solid #eee', borderTop: 'none' }}>
                      <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%">
                        <Box display="flex" alignContent="center" alignItems="center">
                          <Box p={1}>
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: '1.2rem',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {t('PM 2.5')}
                            </Typography>
                          </Box>
                          <Box p={1} px={{ xs: 0, sm: 2 }} mb={1}>
                            <Typography
                              variant="h6"
                              style={{
                                color: textRainfallStatus, // สีเหลือง กับ สีเขียว จะไม่ใช่สีเดียวกับสถานะ
                                fontSize: '3em',
                                alignContent: 'flex-start',
                              }}
                            >
                              {`${data.rain_24h}`}
                            </Typography>
                          </Box>
                          <Box p={1}>
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: '0.8em',
                                justifyContent: 'flex-start',
                                display: 'flex',
                              }}
                            >
                              {t('Ug/m3')}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="none" align="center" colSpan={2}>
                      <Box p={1}>
                        <Typography variant="caption" style={{ fontWeight: 500 }}>
                          {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                        </Typography>
                        <Typography variant="caption" style={{ fontWeight: 500 }} display="block">
                          {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                          {' '}
                          {`${tJson(data.geocode.province_name, 'จ.')}`}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} style={{ paddingLeft: '0px' }}>
                      <Typography variant="caption" color="secondary" style={{ fontSize: '0.7em' }}>
                        {t('ข้อมูล')}
                        {' '}
                        {`: ${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
                      </Typography>
                      <Typography variant="caption" color="secondary" display="block" style={{ fontSize: '0.7em' }}>
                        {`ล่าสุด : ${data.rainfall_datetime}`}
                        {' '}
                        {t('น.')}
                      </Typography>

                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <Box display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%" style={{ minHeight: 34 }}>
                        <Box>
                          {data.agency.agency_shortname.en !== 'DWR' ? (
                            <RainfallContext.Consumer>
                              {({ handleModalOpen }) => (
                                <Button
                                  color="secondary"
                                  onClick={this.handleRainfallChart(handleModalOpen, data, 'rainfall24h', searchRainfall24)}
                                  title={t('แสดงกราฟ')}
                                  style={{ padding: 0 }}
                                >
                                  <FaChartLine className={classes.rightIcon} />
                                </Button>
                              )}
                            </RainfallContext.Consumer>
                          ) : (
                            <RainfallContext.Consumer>
                              {({ handleModalOpen }) => (
                                <Button
                                  color="secondary"
                                  title={t('ไม่มีกราฟ')}
                                  style={{ padding: 0 }}
                                >
                                  <FaChartLine className={classes.rightIcon} style={{ color: '#9e9e9e' }} />
                                </Button>
                              )}
                            </RainfallContext.Consumer>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`{t('สถานี')}  ${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')}`}
                {`${tJson(data.geocode.amphoe_name, 'อ.')}`}
                {`${tJson(data.geocode.province_name, 'จ.')}`}
                <br />
                {t('ข้อมูล')}
                {' '}
                {`${tJson(data.agency.agency_name, 'หน่วยงาน')}`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  // renderLayer(context) {
  //   let layer = '';
  //   const {
  //     searchRainfall24,
  //     type,
  //   } = this.props;

  //   layer = (
  //     <LayersControl position="topright">
  //       <Overlay key="th2" name="ภาคกลาง">
  //         <LayerGroup ref={this.rainfall24hLayer2}>
  //           {context.datas.filter(data => {
  //             const geocode = data.geocode.area_code;
  //             return (geocode === '2');
  //           }).map(this.eachData)}
  //         </LayerGroup>
  //       </Overlay>
  //       <Overlay key="th3" name="ภาคเหนือ">
  //         <LayerGroup ref={this.rainfall24hLayer3}>
  //           {context.datas.filter(data => {
  //             const geocode = data.geocode.area_code;
  //             return (geocode === '3');
  //           }).map(this.eachData)}
  //         </LayerGroup>
  //       </Overlay>
  //       <Overlay key="th4" name="ภาคตะวันออกเฉียงเหนือ">
  //         <LayerGroup ref={this.rainfall24hLayer4}>
  //           {context.datas.filter(data => {
  //             const geocode = data.geocode.area_code;
  //             return (geocode === '4');
  //           }).map(this.eachData)}
  //         </LayerGroup>
  //       </Overlay>
  //       <Overlay key="th5" name="ภาคตะวันตก">
  //         <LayerGroup ref={this.rainfall24hLayer5}>
  //           {context.datas.filter(data => {
  //             const geocode = data.geocode.area_code;
  //             return (geocode === '2');
  //           }).map(this.eachData)}
  //         </LayerGroup>
  //       </Overlay>
  //       <Overlay key="th6" name="ภาคตะวันออก">
  //         <LayerGroup ref={this.rainfall24hLayer6}>
  //           {context.datas.filter(data => {
  //             const geocode = data.geocode.area_code;
  //             return (geocode === '2');
  //           }).map(this.eachData)}
  //         </LayerGroup>
  //       </Overlay>
  //       <Overlay key="th7" name="ภาคใต้">
  //         <LayerGroup ref={this.rainfall24hLayer7}>
  //           {context.datas.filter(data => {
  //             const geocode = data.geocode.area_code;
  //             return (geocode === '5');
  //           }).map(this.eachData)}
  //         </LayerGroup>
  //       </Overlay>
  //       <Overlay key="th" name="ภาพรวมประเทศไทย" checked="true">
  //         <LayerGroup ref={this.rainfall24hLayer1}>
  //           {context.datas.map(this.eachData)}
  //         </LayerGroup>
  //       </Overlay>
  //     </LayersControl>
  //   );
  //   return layer;
  // }

  mapLayerControl = () => {
    const { searchRainfall24, t } = this.props;

    return (
      <LayersControl position="topright">
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Esri_WorldStreetMap">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <Overlay key="pm25" name={t('PM 2.5')} checked="true">
          <LayerGroup ref={this.rainfall24hLayer2}>
            {searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '2');
            }).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="pm10" name={t('PM 10')}>
          <LayerGroup ref={this.rainfall24hLayer2}>
            {searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '2');
            }).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        {/* <Overlay key="th2" name={t('ภาคกลาง')}>
          <LayerGroup ref={this.rainfall24hLayer2}>
            {searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '2');
            }).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="th3" name={t('ภาคเหนือ')}>
          <LayerGroup ref={this.rainfall24hLayer3}>
            {searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '3');
            }).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="th4" name={t('ภาคตะวันออกเฉียงเหนือ')}>
          <LayerGroup ref={this.rainfall24hLayer4}>
            {orderBy(searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '4');
            }), ['rain_24h'], ['asc']).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="th5" name={t('ภาคตะวันตก')}>
          <LayerGroup ref={this.rainfall24hLayer5}>
            {orderBy(searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '2');
            }), ['rain_24h'], ['asc']).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="th6" name={t('ภาคตะวันออก')}>
          <LayerGroup ref={this.rainfall24hLayer6}>
            {orderBy(searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '2');
            }), ['rain_24h'], ['asc']).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="th7" name={t('ภาคใต้')}>
          <LayerGroup ref={this.rainfall24hLayer7}>
            {orderBy(searchRainfall24.filter(data => {
              const geocode = data.geocode.area_code;
              return (geocode === '5');
            }), ['rain_24h'], ['asc']).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay key="th" name={t('ภาพรวมประเทศไทย')} checked="true">
          <LayerGroup ref={this.rainfall24hLayer1}>
            {orderBy(searchRainfall24, ['rain_24h'], ['asc']).map(this.eachData)}
          </LayerGroup>
        </Overlay>
        <Overlay name={t('เส้นแม่น้ำ')} checked>
          <WMSTileLayer
            url={`${initPathWMS}wms`}
            version="1.1.0"
            opacity={1}
            transparent
            layers="layers=thaiwater30:river_thailand"
            srs="EPSG:4326"
            format="image/png"
            tiled
          />
        </Overlay> */}
      </LayersControl>
    );
  };

  render() {
    const {
      error, height, currentHover, zoom, geojsonProvince, geojsonBasin, loading,
    } = this.state;
    const {
      location, classes, i18n,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }
    let loadingMsg = '';
    if (loading === true) {
      loadingMsg = (
        <FaSpinner size={70} />
      );
    }

    return (
      <Fragment key="key">
        {errorMsg}
        {loadingMsg}
        <Map
          id="map"
          tap
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          gestureHandling
        >
          {/* <Control position="bottomleft">
            <div className="info">
              <Typography variant="body1">{currentHover}</Typography>
            </div>
          </Control> */}
          <GeoJSON
            key={uniqueId()}
            data={geojsonBasin}
            style={this.getBasinStyle}
            ref={this.refBoundaryBasin}
            onEachFeature={this.onEachBasinFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            onEachFeature={this.onEachProvinceFeature}
          />

          {/* <RainfallContext.Consumer>
            { context => (
              this.renderLayer(context)
            )}
          </RainfallContext.Consumer> */}

          {(i18n.language === 'th') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'en') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'jp') && (
            this.mapLayerControl()
          )}

          <CircleMarker center={[13.879940312817869, 100.56806749683521]} fillOpacity={0}>
            <Tooltip permanent direction="center" className={classNames(classes.circleMarkerPm, classes.markerTooltipPm25)}>
              25
            </Tooltip>
          </CircleMarker>
          <CircleMarker center={[13.952516052402975, 100.87482951892085]} fillOpacity={0}>
            <Tooltip permanent direction="center" className={classNames(classes.circleMarkerPm, classes.markerStatusPm100)}>
              100
            </Tooltip>
          </CircleMarker>
          <CircleMarker center={[14.443214257524202, 100.85801928360262]} fillOpacity={0}>
            <Tooltip permanent direction="center" className={classNames(classes.circleMarkerPm, classes.markerStatusPm50)}>
              50
            </Tooltip>
          </CircleMarker>
          <CircleMarker center={[14.443214257524202, 100.56806749683521]} fillOpacity={0}>
            <Tooltip permanent direction="center" className={classNames(classes.circleMarkerPm, classes.markerStatusPm200)}>
              150
            </Tooltip>
          </CircleMarker>
          <CircleMarker center={[14.879940312817869, 100.56806749683521]} fillOpacity={0}>
            <Tooltip permanent direction="center" className={classNames(classes.circleMarkerPm, classes.markerStatusPmOver200)}>
              200
            </Tooltip>
          </CircleMarker>

          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
        </Map>
      </Fragment>
    );
  }
}

RainfallMap.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  areaName: PropTypes.string.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  searchRainfall24: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(RainfallMap);
