import React from 'react';
import CompRadarMap from './CompRadarMap';

export default function Main() {
  return (
    <div>
      <CompRadarMap />
    </div>
  );
}
