import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

// material ui
// import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// map
import L from 'leaflet';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  LayerGroup,
  LayersControl,
} from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { GestureHandling } from 'leaflet-gesture-handling';
import { FaChartLine } from '../../utils/Fontawesome';
import LeafletFullScreenFix from '../../utils/LeafletFullScreenFix';

import { styles } from '../../styles/Style';

// color
import SealevelStatusColor from './SealevelStatusColor';

// css
import 'leaflet/dist/leaflet.css';
import 'react-circular-progressbar/dist/styles.css';

const { BaseLayer, Overlay } = LayersControl;

/**
 * WaterQuality Map
 */
class SealevelForcastMap extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.refMap = createRef();
    this.SealevelLayer = createRef();

    this.state = {
      height: this.setHeightMap(),
    };

    this.eachSealevelData = this.eachSealevelData.bind(this);
    this.handleChart = this.handleChart.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  }

  componentDidMount() {
    const { handleSetMapForcast } = this.props;
    this._mounted = true;

    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMapForcast(this.map);
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 600;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 700;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 700;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 550;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  });

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      if (this.refMap.current.leafletElement) {
        const map = this.refMap.current.leafletElement;
        map.on('popupopen', e => {
          const px = map.project(e.target._popup._latlng);
          px.y -= e.target._popup._container.clientHeight / 2;
          map.panTo(map.unproject(px), { animate: true });
        });
      }
    }
    // if (ref) {
    //   markers[id] = ref.leafletElement;
    //   markers[id].addEventListener('click', e => {
    //     const lat = e.target.getLatLng().lat + 3;
    //     const { lng } = e.target.getLatLng();
    //     this.map.panTo([lat, lng]);
    //   });
    // }
  };

  handleChart = data => e => {
    const { handleModalForcastOpen } = this.props;
    e.preventDefault();

    const newData = {
      id: data.id,
      code: data.tide_code,
    };

    if (this._mounted) {
      handleModalForcastOpen(newData);
    }
  };

  // set marker icon based on criteria
  setIcon = val => {
    const status = SealevelStatusColor(val);

    return status.icon;
  }

  handleZoom = () => {
    const currentZoom = this.map.getZoom();
    if (currentZoom <= 4) {
      // hide layers
      this.map.removeLayer(this.SealevelLayer.current);
    } else {
      // show layers
    }
  };

  mapLayerControl = () => {
    const { datas, t } = this.props;

    return (
      <LayersControl position="topright">
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Esri_WorldStreetMap">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <Overlay name={t('สถานีทั้งหมด')} checked="true">
          <LayerGroup ref={this.SealevelLayer}>
            {datas.map(this.eachSealevelData)}
          </LayerGroup>
        </Overlay>
      </LayersControl>
    );
  }

  eachSealevelData(data, i) {
    const { classes, t, i18n } = this.props;

    if (
      data.tide_station_lat
      && data.tide_station_long
    ) {
      // marker position
      const position = [
        parseFloat(data.tide_station_lat),
        parseFloat(data.tide_station_long),
      ];

      // marker icons
      const icon = this.setIcon(
        parseFloat(31),
      );

      // status colors
      // const statusColor = SealevelStatusColor(data.storage_percent);

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={icon}
          center={position}
          ref={this.bindMarker(data.id)}
          {...this.props}
          classes={classes}
        >
          <Popup autoPan={false}>
            <div>
              <TableContainer>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.hiddenLine} padding="none" align="center" colSpan={2}>
                        <Typography variant="subtitle2">
                          {t('ระดับน้ำคาดการณ์')}
                          {' '}
                          {i18n.language === 'th' && (t('สถานี'))}

                          {i18n.language === 'th' && (data.tide_station_th)}
                          {i18n.language === 'en' && (data.tide_station_en)}
                          {i18n.language === 'jp' && (data.tide_station_th)}

                          {(i18n.language === 'en' || i18n.language === 'jp') && (t('สถานี'))}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('ต่ำสุด')} (${t('เวลา')} ${data.min_time}${t('น.')})`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.min_value}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('สูงสุด')} (${t('เวลา')} ${data.min_time}${t('น.')})`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.max_value}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>
                        <Typography variant="subtitle2">{t('ทำนายล่วงหน้าทุก 4 ชั่วโมง')}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('เวลา')} ${'00:00'}${t('น.')}`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.time_0000}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('เวลา')} ${'04:00'}${t('น.')}`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.time_0400}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('เวลา')} ${'08:00'}${t('น.')}`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.time_0800}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('เวลา')} ${'12:00'}${t('น.')}`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.time_1200}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('เวลา')} ${'16:00'}${t('น.')}`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.time_1600}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>{`${t('เวลา')} ${'20:00'}${t('น.')}`}</TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <strong>
                          {data.time_2000}
                        </strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.hiddenLine}>
                        <Typography variant="caption" color="secondary">
                          {/* {`${data.waterlevel_datetime}`}
                          {' '}
                          {'น.'}
                          {' '}
                          {'(ม.รทก.)'} */}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.hiddenLine} align="right">
                        <Button
                          color="secondary"
                          onClick={this.handleChart(data)}
                          title={t('แสดงกราฟ')}
                          style={{ padding: 0 }}
                        >
                          <FaChartLine className={classes.rightIcon} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip>
              <span>
                <strong>
                  {i18n.language === 'th' && (data.tide_station_th)}
                  {i18n.language === 'en' && (data.tide_station_en)}
                  {i18n.language === 'jp' && (data.tide_station_th)}
                </strong>
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }

    return false;
  }

  render() {
    const {
      error,
      height,
    } = this.state;
    const {
      classes, i18n,
    } = this.props;

    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">{error}</Typography>
        </Paper>
      );
    }

    return (
      <>
        {errorMsg}
        <Map
          id="mapForcast"
          center={[10, 100.5]}
          zoom={6.5}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          onZoomEnd={this.handleZoom}
          gestureHandling
        >
          {/* <Control position="bottomleft">
            <div className="info">
              <Typography variant="body1">{currentHover}</Typography>
            </div>
          </Control> */}
          <TileLayer
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution='&copy; <a href="http://osm.org/copyright">ESRI World Street Map</a> contributors'
          />
          {/* <GeoJSON
            key={uniqueId()}
            data={geojsonRiver}
            style={this.getRiverStyle()}
            onEachFeature={this.onEachRiverFeature}
          /> */}

          {(i18n.language === 'th') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'en') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'jp') && (
            this.mapLayerControl()
          )}

          <FullscreenControl
            position="topleft"
            forcePseudoFullscreen
            content={LeafletFullScreenFix()}
          />
        </Map>
      </>
    );
  }
}

SealevelForcastMap.propTypes = {
  classes: PropTypes.object.isRequired,
  markers: PropTypes.array.isRequired,
  handleSetMapForcast: PropTypes.func.isRequired,
  handleModalForcastOpen: PropTypes.func.isRequired,
  datas: PropTypes.array.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(SealevelForcastMap);
