import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import Img from 'react-image';
import { useTranslation } from 'react-i18next';

// leaflet
import L from 'leaflet';
import {
  Map,
  TileLayer,
  GeoJSON,
  LayersControl,
  WMSTileLayer,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
import 'leaflet/dist/leaflet.css';

// material-ui
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

// lodash
import { uniqueId } from 'lodash';

// components
import { PlottyGeotiffLayer } from '../../widgets/map/GeotiffLayer';
import RainForecastMonthNavigationSwitch from './RainForecastMonthNavigationSwitch';
import RainForecastMonthNavigation from './RainForecastMonthNavigation';
import RainForecastMonthNavigationAnomaly from './RainForecastMonthNavigationAnomaly';
// import RainForecastNavigationIrrigationArea from './RainForecastNavigationIrrigationArea';
// ไม่ได้ใช้งาน เบส นน. แจ้งเปลี่ยนเป็นการแสดงผลความผิดปกติของปริมาณฝน
import RainForecastMonthNavigationStation from './RainForecastMonthNavigationStation';

// rainfall isohyet
import RainIsohyet from './RainIsohyet';

import DamMarker from './DamMarker';
import MediumDamMarker from './MediumDamMarker';
import RainfallMarker from './RainfallMarker';
import CommunityMarker from './CommunityMarker';
import RainfallAnomalyMarker from './RainfallAnomalyMarker';
// legend for volumn
import Legend from './Legend';

// legend for level
import LegendLevel from './LegendLevel';
import LegendMarker from './LegendMarker';
import LevelColor from './LevelColor';
import CurrentLocation from '../../currentlocation/CurrentLocation';

// ไฟล์ scale สี
// resources\js\components\widgets\map\leaflet-geotiff-plotty.js

function RainForecastMonthMap() {
  // variable reference.
  const { t } = useTranslation();
  const initPath = process.env.MIX_APP_PATH;
  // const banner = `${initPath}images/models/MRF.png`;

  const refMap = useRef(null);
  const refBoundaryProvince = useRef(null);
  const refGeoTiff = useRef(null);

  const { BaseLayer } = LayersControl;
  const [getGeoJsonProvince, setGeoJsonProvince] = useState([]);
  const [getMonth, _setMonth] = useState('');
  // const [getMonthLevel, setMonthLevel] = useState('');
  const [type, setType] = useState('volume');
  const [fillMonth, setFillMonth] = useState('');
  const [fillData, setFillData] = useState([]);
  const [layerRainfall, setLayerRainfall] = useState(false);
  const [dataIsohyet, setDataIsohyet] = useState();
  const [isohyetGeoTiff, setIsohyetGeoTiff] = useState('');

  const [layerDam, setLayerDam] = useState(true);
  const [layerMediumDam, setLayerMediumDam] = useState(true);
  const [layerCommunity, setLayerCommunity] = useState(true);
  const [openLegendVolume, setLegendVolume] = useState(false);
  const [openLegendAnomaly, setLegendAnomaly] = useState(false);
  const [openLegendIsohyet, setLegendIsohyet] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    modalHeader: null,
    modalSubHeader: null,
    modalContent: null,
  });
  const myStateRef = React.useRef(getMonth);
  const setMonth = data => {
    myStateRef.current = data;
    _setMonth(data);
  };
  // 2023-08-08 เนื้อหาใน info ตอนนี้ยังไม่ได้ใส่บทความ
  const longText = t('');

  // geotiff
  let geoTiffUrl;
  if (type === 'volume') {
    geoTiffUrl = 'https://live1.hii.or.th/product/latest/rain/one_map/data/om_mfcst_1m.tif';
  } else if (type === 'anomaly') {
    geoTiffUrl = 'https://live1.hii.or.th/product/latest/rain/one_map/data/anomaly_rain_1m.tif';
  }

  const geoTiffOptionsVolume = {
    band: 0,
    displayMin: 0,
    displayMax: 3000,
    name: t('คาดการณ์ฝน'),
    colorScale: 'rainForecastMonthVolumeScale',
    clampLow: false,
    clampHigh: true,
  };
  const geoTiffOptionsAnomaly = {
    band: 0,
    displayMin: -200,
    displayMax: 200,
    name: t('ความผิดปกติของปริมาณฝน'),
    colorScale: 'rainForecastMonthAnomalyScale',
    clampLow: false,
    clampHigh: true,
  };
  const geoTiffOptionsIsohyet = {
    band: 0,
    displayMin: 0,
    displayMax: 3000,
    name: t('ฝน Isohyet'),
    colorScale: 'rainForecastMonthVolumeScale',
    clampLow: false,
    clampHigh: true,
  };

  // style province boundary
  function getProvinceStyle(feature) {
    let color;
    let fillOpacity = 0;
    let riskIndex = null;

    // find risk_index for each province
    const data = fillData.find(d => d.date === fillMonth);
    if (data !== undefined) {
      const dataProvince = data.value.find(d => d.prov_id === feature.properties.prov_code);
      if (dataProvince !== undefined) {
        riskIndex = dataProvince.risk_index;
      }
    }

    // set color base on risk_index
    if (type === 'level') {
      color = LevelColor(riskIndex);
      fillOpacity = 0.7;
    } else {
      color = 'transparent';
      fillOpacity = 0;
    }

    return {
      fillColor: color,
      fillOpacity,
      stroke: true,
      color: 'black',
      weight: 1,
      opacity: 0.3,
    };
  }

  function handleChangeVolume(val, txt) {
    // console.log('[function handleChangeVolume] ->', val);
    setMonth(txt);
    refGeoTiff.current.leafletElement.setURL(`https://live1.hii.or.th/product/latest/rain/one_map/data/${val}`);
  }

  function handleChangeIsohyet(val, txt) {
    // https://hydro1.hii.or.th/monthly/rain_nhc/geotiff/2023/acc01m_202302.tif
    setMonth(txt);
    refGeoTiff.current.leafletElement.setURL(`${val}`);
  }

  // function handleChangeLevel(val) {
  //   // console.log('[function handleChangeLevel] ->', val);
  //   setFillMonth(val);
  // }

  function handleChangeType(val) {
    // console.log('[function handleChangeVolume] ->', val);
    setType(val);
    console.log('handleChangeType => ', val);

    if (val === 'level') {
      setLegendVolume(false);
      setLegendAnomaly(false);
      setLegendIsohyet(false);
      // clear geotiff layer
      const filename = 'default.tif';
      handleChangeVolume(filename, '--');

      // fill initial data
      // fix hard code
      // handleChangeLevel(getMonthLevel);
    } else if (val === 'anomaly') {
      setLegendVolume(false);
      setLegendAnomaly(false);
      setLegendIsohyet(false);
      const filename = 'anomaly_rain_1m.tif';
      handleChangeVolume(filename, '--');
    } else {
      // reset fill color
      // code here
      setLegendVolume(false);
      setLegendAnomaly(false);
      setLegendIsohyet(false);
      // add initial data
      const filename = 'om_mfcst_1m.tif';
      handleChangeVolume(filename, '--');
    }
  }

  function handleInitMonth(val) {
    setMonth(val);
  }

  // function handleInitMonthLevel(val, d) {
  //   setMonthLevel(val);
  //   setFillData(d);
  //   handleChangeLevel(val);
  // }

  function handlerLayer(layer, val) {
    if (layer === 'layerDam') {
      setLayerDam(val);
    } else if (layer === 'layerRainfall') {
      setLayerRainfall(val);
    } else if (layer === 'layerMediumDam') {
      setLayerMediumDam(val);
    } else if (layer === 'layerCommunity') {
      setLayerCommunity(val);
    }
  }

  // open rainfall popup when click map
  function handleGeotiffLayerClick(e) {
    if (type === 'volume' || type === 'anomaly') {
      const { lat, lng } = e.latlng;

      // set boundary
      if (lat > 20.6 || lat < 5 || lng < 96 || lng > 106) {
        // not avaliable
      } else {
        let val = parseFloat(refGeoTiff.current.leafletElement.getValueAtLatLng(lat, lng))
          .toFixed(1);

        // clean up no rainfall data
        if (Number.isNaN(val)) {
          val = '--';
        }

        const html = `<strong>${myStateRef.current}</strong><hr /><span class="popupText">${t('ปริมาณฝน')} <strong>${val}</strong> ${t('มม.')}<br />Lat: ${lat.toFixed(3)}, Lng: ${lng.toFixed(3)}</span>`;
        L.popup().setLatLng(e.latlng).setContent(html).openOn(refMap.current.leafletElement);
      }
    }
  }

  function highlightFeature(e) {
    const layer = e.target;
    layer.setStyle({
      weight: 1,
      opacity: 1,
      color: 'red',
      dashArray: '0',
      fillOpacity: 0.5,
    });
    // if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
    //   layer.bringToFront();
    // }
  }

  function resetHighlight(e) {
    refBoundaryProvince.current.leafletElement.resetStyle(e.layer);
  }

  function onEachProvinceFeature(feature, layer) {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
    });

    // find risk_index for each province
    let riskIndex = '';
    const data = fillData.find(d => d.date === fillMonth);
    if (data !== undefined) {
      const dataProvince = data.value.find(d => d.prov_id === feature.properties.prov_code);
      if (dataProvince !== undefined) {
        riskIndex = dataProvince.risk_index;
      }
    }

    let TooltipContent = `<b>${t(feature.properties.title)}</b>`;

    if (type === 'level') {
      TooltipContent = `<b>${t(feature.properties.title)}<br />Risk Index: ${riskIndex}</b>`;
    }

    layer.bindTooltip(TooltipContent);
  }

  const handleModalOpen = params => {
    setModal({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  };

  const handleModalClose = () => {
    setModal({ open: false });
  };

  const getModalStyle = () => {
    const width = 'lg';
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = '60%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '60%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      zIndex: 2147483647,
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  };

  // โลโก้ความร่วมมือหน่วยงาน
  // เบิร์ดแจ้งให้ซ่อนไปก่อน 2023-08-08
  // const imageMOU = () => (
  //   <Paper>
  //     <Img
  //       src="https://live1.hii.or.th/product/latest/rain/one_map/logo.png"
  //       alt="โลโก้ความร่วมมือหน่วยงาน"
  //       style={{ width: '190px', height: '50px' }}
  //       unloader={(
  //         <Img
  //           src="https://via.placeholder.com/232x425?text=No%20Image"
  //           alt="No Image"
  //         />
  //         )}
  //     />
  //   </Paper>
  // );

  // information
  const info = () => (
    <Paper style={{ opacity: '0.8', backgroundColor: '#EAEAEA' }}>
      <Button onClick={() => handleModalOpen(
        {
          header: t('ระบบวิเคราะห์สถานการณ์น้ำ'),
          content: longText,
        },
      )}
      >
        <InfoIcon />
      </Button>
    </Paper>
  );

  const handleClickLegendVolume = () => {
    if (!openLegendVolume) {
      setLegendVolume(true);
    } else {
      setLegendVolume(false);
    }
  };

  const handleClickLegendAnomaly = () => {
    if (!openLegendAnomaly) {
      setLegendAnomaly(true);
    } else {
      setLegendAnomaly(false);
    }
  };

  const handleClickLegendIsohyet = () => {
    if (!openLegendIsohyet) {
      setLegendIsohyet(true);
    } else {
      setLegendIsohyet(false);
    }
  };
  function groupDataMonthly(varData) {
    const valueImage = varData.map(rows => {
      const keys = Object.keys(rows);
      const dataInMonth = keys.map(month => {
        const objData = rows[month];
        switch (month) {
          case 'm01':
            objData.month = 'มกราคม';
            break;
          case 'm02':
            objData.month = 'กุมภาพันธ์';
            break;
          case 'm03':
            objData.month = 'มีนาคม';
            break;
          case 'm04':
            objData.month = 'เมษายน';
            break;
          case 'm05':
            objData.month = 'พฤษภาคม';
            break;
          case 'm06':
            objData.month = 'มิถุนายน';
            break;
          case 'm07':
            objData.month = 'กรกฎาคม';
            break;
          case 'm08':
            objData.month = 'สิงหาคม';
            break;
          case 'm09':
            objData.month = 'กันยายน';
            break;
          case 'm10':
            objData.month = 'ตุลาคม';
            break;
          case 'm11':
            objData.month = 'พฤศจิกายน';
            break;
          case 'm12':
            objData.month = 'ธันวาคม';
            break;
          default:
            objData.month = `เดือน ${month}`;
            break;
        }
        return objData;
      });
      return dataInMonth;
    });
    return valueImage;
  }

  // add province thailand
  useEffect(() => {
    async function fetchData() {
      fetch(`${initPath}json/boundary/thailand.json`)
        .then(response => response.json())
        .then(data => {
          setGeoJsonProvince(data);
        });

      const currentDate = moment();
      const currentMonth = currentDate.month(); // Note: Month is zero-indexed (0 for January, 11 for December)
      const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;

      const apiFetch = `${process.env.MIX_API_HII_URL}/tiservice/v1/ws/MBtOTp6IUXbjaCxhQoFQNrFgZUCzNgbo/isohyet/monthly/${currentDate.year()}/thailand`;

      fetch(apiFetch)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          if (result) {
            const data = groupDataMonthly([result])[0];
            setDataIsohyet(data);
            setIsohyetGeoTiff(data[previousMonth].geotiff);
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
        });
    }

    fetchData();

    // add opacity
    document.getElementsByClassName('leaflet-overlay-pane')[0].style.opacity = 0.8;
  }, []);

  return (
    <>
      <Map
        id="map"
        center={[13, 101.6]}
        zoom={6}
        ref={refMap}
        style={{
          height: window.height,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1299,
        }}
        onClick={handleGeotiffLayerClick}
      >
        <CurrentLocation mapRef={refMap} showOnly={false} />

        <LayersControl position="topleft">
          <BaseLayer name="OpenStreetMap.Mapnik">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer name="ESRI World Topo">
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" />
          </BaseLayer>
          <BaseLayer name="BlackAndWhite" checked>
            <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
          </BaseLayer>
        </LayersControl>

        {/* Big Dam */}
        {layerDam ? <DamMarker handler={handleModalOpen} /> : null}
        {/* Medium Dam */}
        {layerMediumDam && <MediumDamMarker handler={handleModalOpen} />}
        {/* ตำแหน่งชุมชน */}
        {layerCommunity && <CommunityMarker handler={handleModalOpen} />}
        {/* สถานีฝนคาดการณ์ */}
        {layerRainfall ? (type === 'anomaly') ? <RainfallAnomalyMarker /> : <RainfallMarker /> : null}

        <GeoJSON
          key={uniqueId()}
          ref={refBoundaryProvince}
          data={getGeoJsonProvince}
          style={getProvinceStyle}
          onEachFeature={onEachProvinceFeature}
        />
        {type === 'volume'
          && (
          <PlottyGeotiffLayer
            layerRef={refGeoTiff}
            url={geoTiffUrl}
            options={geoTiffOptionsVolume}
          />
          )}
        {type === 'anomaly'
          && (
          <PlottyGeotiffLayer
            layerRef={refGeoTiff}
            url={geoTiffUrl}
            options={geoTiffOptionsAnomaly}
          />
          )}

        {type === 'irrigationArea'
            && (
            <WMSTileLayer
              url="https://mapservice.thaiwater.net/geoserver/thaiwater30/wms"
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:irrigation_area"
              srs="EPSG:4326"
              format="image/png"
              tiled
              zIndex={999}
            />
            )}
        {type === 'forestArea2563'
            && (
            <WMSTileLayer
              // 20230809 เบิร์ดให้เปลี่ยน mapservice.thaiwater.net เป็น igis.thaiwater.net ชั่วครา่ว
              // รอ optimize mapservice.thaiwater.net ให้รองรับ cache
              url="https://igis.thaiwater.net/geoserver/thaiwater30/wms"
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:forest_area_2563"
              srs="EPSG:4326"
              format="image/png"
              tiled
              zIndex={999}
            />
            )}
        {type === 'reservoirCatchmentArea'
            && (
            <WMSTileLayer
              url="https://mapservice.thaiwater.net/geoserver/thaiwater30/wms"
              version="1.1.0"
              opacity={1}
              transparent
              layers="layers=thaiwater30:reservoir_catchment_area"
              srs="EPSG:4326"
              format="image/png"
              tiled
              zIndex={999}
            />
            )}
        {type === 'isohyet'
          && (
          <PlottyGeotiffLayer
            layerRef={refGeoTiff}
            url={isohyetGeoTiff}
            options={geoTiffOptionsIsohyet}
          />
          )}

        {/* <FullscreenControl position="topleft" /> */}
        <Control position="topleft">
          {info()}
        </Control>
        <Control position="topright">
          <RainForecastMonthNavigationSwitch
            handler={handleChangeType}
          />

          {type === 'volume' && (
            <RainForecastMonthNavigation
              handler={handleChangeVolume}
              handlerInitMonth={handleInitMonth}
            />
          )}

          {type === 'anomaly' && (
            <RainForecastMonthNavigationAnomaly
              handler={handleChangeVolume}
              handlerInitMonth={handleInitMonth}
            />
          )}
          {type === 'anomaly'
              && (
              <RainForecastMonthNavigationAnomaly
                handler={handleChangeVolume}
                handlerInitMonth={handleInitMonth}
              />
              )}
          {(type === 'isohyet' && dataIsohyet)
            && (
            <RainIsohyet
              dataIsohyet={dataIsohyet}
              isohyetGeoTiff={isohyetGeoTiff}
              handler={handleChangeIsohyet}
              handlerInitMonth={handleInitMonth}
            />
            )}

          <RainForecastMonthNavigationStation
            handlerLayer={handlerLayer}
          />
          <LegendMarker />
          {/* {imageMOU()} */}
        </Control>
        {/* Legend  */}
        <Control position="bottomleft">
          {openLegendVolume && (<Legend />)}
          {type === 'volume' && (
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={handleClickLegendVolume}
              endIcon={<ArrowDropUpIcon />}
            >
              ปริมาณฝน (มม./เดือน)
            </Button>
          )}
          {openLegendAnomaly && (<LegendLevel />)}
          {type === 'anomaly' && (
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={handleClickLegendAnomaly}
              endIcon={<ArrowDropUpIcon />}
            >
              เกณฑ์ฝนรายเดือน
            </Button>
          )}
          {openLegendIsohyet && (<Legend />)}
          {type === 'isohyet' && (
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
                textTransform: 'none',
              }}
              aria-haspopup="true"
              onClick={handleClickLegendIsohyet}
              endIcon={<ArrowDropUpIcon />}
            >
              ฝน NHC
            </Button>
          )}
          {/* {type === 'volume' && (<Legend />)} */}
          {/* {type === 'anomaly' && (<LegendLevel />)} */}
          {/* {type === 'isohyet'  && (<Legend />)} */}
        </Control>
      </Map>

      <Modal
        id="modal-chart"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modal.open}
        onClose={handleModalClose}
      >
        <div style={getModalStyle()}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="h5" id="modal-title">
            {modal.modalHeader}
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
            {modal.modalSubHeader}
          </Typography>
          <Box mt={3}>
            {modal.modalContent}
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default withWidth()(RainForecastMonthMap);
