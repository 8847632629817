/* eslint-disable max-len */
/* eslint-disable no-lone-blocks */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';
import moment from 'moment';

// ui
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/moment';
import grey from '@material-ui/core/colors/grey';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  FaChartLine, FaChartBar, FaSpinner,
  FaAngleLeft, FaAngleRight,
} from '../../utils/Fontawesome';
import tJson from '../../utils/TJson';
import TableHeaderEnhanceDam from '../widgets/table/TableHeaderEnhanceDam';

import {
  styles, SmallDamTableCell, StyledTableRow,
} from '../../styles/Style';

// dam
import DamStatusColor from './DamStatusColor';
import DamLegend from './DamLegend';
import DamContext from '../../contexts/damContext';
import DamDailyChart from './DamDailyChart';
import DamDailyCompareChart from './DamDailyCompareChart';
import DamDailyChartSumByRegion from './DamDailyChartSumByRegion';
import DamDailyCompareChartSumByRegion from './DamDailyCompareChartSumByRegion';
import DamHourlyChart from './DamHourlyChart';

const tableGroupColor = '#006fb6';
const tableSummaryColor = '#eee';
const tableGrandSummaryColor = grey[300]; // #F44336
const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);
const CustomButton = withStyles(() => ({
  root: {
    '&:hover': {
      border: '1px solid #000',
      backgroundColor: '#fff',
      color: '#000',
      outlined: {
        border: '1px solid #000',
      },
    },
  },
}))(Button);

// 2020-12-25 comment จังหวัด,ลุ่มน้ำ, ความจุอ่างที่รนส., ความจุอ่างที่น้ำใช้การ
// เพื่อให้แสดงผลตารางครบทุก column ใน 1 หน้า
const year = moment().add(543, 'year').format('YY');
// table Header
const columnDataRid = [
  {
    id: 'dam.dam_name.th', align: 'left', numeric: false, disablePadding: true, label: 'อ่างเก็บน้ำ', sortable: true,
  },
  // {
  //   id: 'geocode.province_name.th', align: 'left', numeric: false, disablePadding: true, label: 'จังหวัด', sortable: true,
  // },
  // {
  //   id: 'basin.basin_name.th', align: 'left', numeric: false, disablePadding: true, label: 'ลุ่มน้ำ', sortable: true,
  // },
  // {
  //   id: 'dam.max_storage', align: 'right', numeric: true, disablePadding: false, label: 'รนส.', unit: '(ล้าน ลบ.ม.)', sortable: true,
  // },
  {
    id: 'dam.normal_storage', align: 'right', numeric: true, disablePadding: false, label: 'ที่รนก.', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  // {
  //   id: 'dam.uses_water', align: 'right', numeric: true, disablePadding: false, label: 'น้ำใช้การ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  // },
  {
    id: 'dam_storage_percent', align: 'right', numeric: true, disablePadding: false, label: 'กักเก็บ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_uses_water_percent', align: 'right', numeric: true, disablePadding: false, label: 'ใช้การได้จริง', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_inflow_avg', align: 'right', numeric: true, disablePadding: false, label: 'ค่าเฉลี่ยรวมทั้งปี', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_inflow', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาตรน้ำ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_inflow_acc', align: 'right', numeric: true, disablePadding: false, label: `สะสมตั้งแต่ 1 ม.ค. ${year}`, unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_released', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาตรน้ำ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_released_acc', align: 'right', numeric: true, disablePadding: false, label: `สะสมตั้งแต่ 1 ม.ค. ${year}`, unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  // {
  //   id: 'chart', numeric: false, disablePadding: false, label: '',
  // },
];

const columnDataEgatDaily = [
  {
    id: 'dam.dam_name.th', align: 'left', numeric: false, disablePadding: true, label: 'อ่างเก็บน้ำ', sortable: true,
  },
  // {
  //   id: 'geocode.province_name.th', align: 'left', numeric: false, disablePadding: true, label: 'จังหวัด', sortable: true,
  // },
  // {
  //   id: 'basin.basin_name.th', align: 'left', numeric: false, disablePadding: true, label: 'ลุ่มน้ำ', sortable: true,
  // },
  {
    id: 'dam_level', align: 'right', numeric: true, disablePadding: false, label: 'ระดับน้ำ', unit: '(ม.รทก.)', sortable: true,
  },
  {
    id: 'dam.max_storage', align: 'right', numeric: true, disablePadding: false, label: 'ความจุ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_storage', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาณน้ำกักเก็บ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'percent_storage', align: 'right', numeric: true, disablePadding: false, label: '% ของปริมาณน้ำกักเก็บสูงสุด', unit: '%', sortable: true,
  },
  {
    id: 'canAccept', align: 'right', numeric: true, disablePadding: false, label: 'รับได้อีก', unit: '(ล้าน ลบ.ม.)', sortable: false,
  },
  {
    id: 'dam_inflow', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาณน้ำไหลลงอ่าง', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_released', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาณน้ำระบาย', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  // {
  //   id: 'chart', numeric: false, disablePadding: false, label: '',
  // },
];

const columnDataEgatHourly = [
  {
    id: 'dam.dam_name.th', align: 'left', numeric: false, disablePadding: true, label: 'อ่างเก็บน้ำ', sortable: true,
  },
  // {
  //   id: 'geocode.province_name.th', align: 'left', numeric: false, disablePadding: true, label: 'จังหวัด', sortable: true,
  // },
  // {
  //   id: 'basin.basin_name.th', align: 'left', numeric: false, disablePadding: true, label: 'ลุ่มน้ำ', sortable: true,
  // },
  {
    id: 'dam_date', align: 'left', numeric: false, disablePadding: true, label: 'วันที่ / เวลา', sortable: true,
  },
  {
    id: 'dam_level', align: 'right', numeric: true, disablePadding: false, label: 'ระดับน้ำ', unit: '(ม.รทก.)', sortable: true,
  },
  {
    id: 'dam_storage', align: 'right', numeric: true, disablePadding: false, label: 'ปริมาณน้ำกักเก็บ', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_inflow', align: 'right', numeric: true, disablePadding: false, label: 'น้ำไหลลง', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_released', align: 'right', numeric: true, disablePadding: false, label: 'น้ำระบาย', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  {
    id: 'dam_evap', align: 'right', numeric: true, disablePadding: false, label: 'ระเหย', unit: '(ล้าน ลบ.ม.)', sortable: true,
  },
  // {
  //   id: 'chart', numeric: false, disablePadding: false, label: '',
  // },
];

// tabs
const TabContainer = ({ children }) => (
  <Typography component="div">
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const areas = [
  { id: 1, name: 'ภาคเหนือ' },
  { id: 2, name: 'ภาคตะวันออกเฉียงเหนือ' },
  { id: 3, name: 'ภาคกลาง' },
  { id: 4, name: 'ภาคตะวันตก' },
  { id: 5, name: 'ภาคตะวันออก' },
  { id: 6, name: 'ภาคใต้' },
  { id: 9, name: 'อื่นๆ' },
];

class DamLargeFullList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      value: 0,
      selectedDateLargeRid: '',
      selectedDateLargeEgat: '',
      largeDamRid: [],
      largeDamEgat: [],

      // table sorting
      order: 'desc',
      orderByRid: 'dam_storage_percent',
      orderByEgatDaily: 'dam_storage_percent',
      orderByEgatHourly: 'dam_storage_percent',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.selectedDateLargeRid === '' && props.latestDateRid !== '') {
      return {
        selectedDateLargeRid: props.latestDateRid,
        selectedDateLargeEgat: props.latestDateEgat,
        largeDamRid: props.datasRid,
        largeDamEgat: props.datasEgat,
      };
    }

    return null;
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDateChangeLargeRid = date => {
    // console.log('handleDateChangeLargeRid', date);
    // console.log(moment(date).locale('th').format('YYYY-MM-DD'));
    this.setState({
      isLoading: true,
      selectedDateLargeRid: date,
    });

    this.fetchDataRid(moment(date).format('YYYY-MM-DD'), 1);
  };

  handleDateChangeLargeEgat = date => {
    // console.log(moment(date).locale('th').format('YYYY-MM-DD'));
    this.setState({
      isLoading: true,
      selectedDateLargeEgat: date,
    });

    this.fetchDataEgat(moment(date).format('YYYY-MM-DD'), 1);
  };

  handleChart = (handler, data, agencyId = 12) => e => {
    e.preventDefault();
    const { t } = this.props;

    let header = t('กราฟอ่างเก็บน้ำรายวัน กรมชลประทาน');
    if (agencyId === 8) {
      header = t('กราฟอ่างเก็บน้ำรายวัน การไฟฟ้าฝ่ายผลิตแห่งประเทศไทย');
    }
    const params = {
      header,
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamDailyChart id={data.dam.id} agencyId={agencyId} />,
    };

    handler(params);
  }

  
  handleChartEgat = (handler, data, agencyId = 12) => e => {
    e.preventDefault();
    const { t } = this.props;

    let header = t('กราฟอ่างเก็บน้ำรายวัน กรมชลประทาน');
    if (agencyId === 8) {
      header = t('กราฟอ่างเก็บน้ำรายชั่วโมง การไฟฟ้าฝ่ายผลิตแห่งประเทศไทย');
    }
    const params = {
      header,
      content: <DamHourlyChart id={data.dam.id} agencyId={agencyId} />,
    };

    handler(params);
  }

  handleChartCompareDaily = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: t('กราฟเปรียบเทียบอ่างเก็บน้ำรายวัน กรมชลประทาน'),
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamDailyCompareChart id={data.dam.id} agencyId={12} />,
    };

    handler(params);
  }

  handleChartSumByRegion = (handler, regionId, agencyId = 12) => e => {
    e.preventDefault();
    const { t } = this.props;

    let header = t('กราฟอ่างเก็บน้ำรายวัน รายภาค กรมชลประทาน');
    if (agencyId === 8) {
      header = t('กราฟอ่างเก็บน้ำรายวัน รายภาค การไฟฟ้าฝ่ายผลิตแห่งประเทศไทย');
    }

    const params = {
      header,
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamDailyChartSumByRegion id={regionId} agencyId={agencyId} />,
    };

    handler(params);
  }

  handleChartCompareDailySumByRegion = (handler, regionId, agencyId = 12) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: t('กราฟเปรียบเทียบอ่างเก็บน้ำรายวัน รายภาค กรมชลประทาน'),
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamDailyCompareChartSumByRegion id={regionId} agencyId={agencyId} />,
    };

    handler(params);
  }

  handleRequestSortRid = (event, property) => {
    const { order, orderByRid } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByRid === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByRid: orderByProperty });
  };

  handleRequestSortEgatDaily = (event, property) => {
    const { order, orderByEgatDaily } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByEgatDaily === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByEgatDaily: orderByProperty });
  };

  handleRequestSortEgatHourly = (event, property) => {
    const { order, orderByEgatHourly } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByEgatHourly === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByEgatHourly: orderByProperty });
  };

  damStatus = (data, percent) => {
    const status = DamStatusColor(parseFloat(percent).toFixed(2));

    let statusText = '';
    if (parseFloat(percent) === 0) {
      statusText = data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      statusText = `${data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} <span style='color:${status.color.statusColor}'> <b>(${parseFloat(percent).toFixed(0)}%)</b></span>`;
    }
    return statusText;
  }

  fetchDataRid = (damDate, damSize) => {
    fetch(`${process.env.MIX_API_URL}analyst/dam?dam_date=${damDate}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // sorting and filter, large dam
        const datasDamDailyRid = orderBy(result.data.dam_daily, ['dam_storage_percent'], ['desc']).filter(d => (d.agency.id === 12));

        // set state
        this.setState({
          isLoading: false,
          largeDamRid: datasDamDailyRid,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  fetchDataEgat = (damDate, damSize) => {
    fetch(`${process.env.MIX_API_URL}analyst/dam?dam_date=${damDate}&dam_size=${damSize}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // sorting and filter, large dam
        const datasDamDailyEgat = orderBy(result.data.dam_daily, ['dam_storage_percent'], ['desc']).filter(d => (d.agency.id === 8));

        // set state
        this.setState({
          isLoading: false,
          largeDamEgat: datasDamDailyEgat,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  setDate = (agency, step) => {
    let newDate = '';

    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    const { latestDateRid, latestDateEgat, t } = this.props;
    const { selectedDateLargeRid, selectedDateLargeEgat } = this.state;

    // set new date base on agency and step (previous date or next date)
    if (step === 'prev') {
      if (agency === 'rid') {
        newDate = moment(selectedDateLargeRid).subtract(1, 'days').format('YYYY-MM-DD');
      } else if (agency === 'egat') {
        newDate = moment(selectedDateLargeEgat).subtract(1, 'days').format('YYYY-MM-DD');
      }
    } else if (step === 'next') {
      if (agency === 'rid') {
        newDate = moment(selectedDateLargeRid).add(1, 'days').format('YYYY-MM-DD');

        // check max date
        if (newDate > latestDateRid) {
          alert(t('ไม่สามารถเปลี่ยนวันได้ เนื่องจากข้อมูลยังไม่มีถึงวันที่เลือก'));
          return;
        }
      } else if (agency === 'egat') {
        newDate = moment(selectedDateLargeEgat).add(1, 'days').format('YYYY-MM-DD');

        // check max date
        if (newDate > latestDateEgat) {
          alert(t('ไม่สามารถเปลี่ยนวันได้ เนื่องจากข้อมูลยังไม่มีถึงวันที่เลือก'));
          return;
        }
      }
    }
    // console.log(agency, newDate);

    // if date is valid change date state
    if (newDate.match(regEx) != null) {
      if (agency === 'rid') {
        this.setState({
          selectedDateLargeRid: newDate,
        });
        this.handleDateChangeLargeRid(newDate);
      } else if (agency === 'egat') {
        this.setState({
          selectedDateLargeEgat: newDate,
        });
        this.handleDateChangeLargeEgat(newDate);
      }
    }
  }

  render() {
    const {
      classes,
      theme,
      datasEgatHourly,
      latestDateRid,
      latestDateEgat,
      t,
    } = this.props;

    const {
      isLoading,
      error,
      value,
      selectedDateLargeRid,
      selectedDateLargeEgat,
      largeDamRid,
      largeDamEgat,
      order,
      orderByRid,
      orderByEgatDaily,
      orderByEgatHourly,
    } = this.state;

    // set total summary
    let grandTotalInflow = 0;
    let grandTotalInflowAcc = 0;
    let grandTotalInflowAvg = 0;
    let grandTotalReleased = 0;
    let grandTotalReleasedAcc = 0;
    let grandTotalDamStorage = 0;
    let grandTotalDamUseWater = 0;
    // let grandTotalMaxStorage = 0;
    let grandTotalCanAccept = 0;
    let grandTotalNormalStorage = 0;

    const totalLargeDamRid = largeDamRid.length;
    const totalLargeDamEgat = largeDamEgat.length;
    // let grandTotalUsesWater = 0;

    // sort by date desc
    const datasEgatHourlySort = orderBy(datasEgatHourly, ['dam_date'], ['desc']);

    return (
      <Fragment key="key">
        {isLoading === true && (
          <FaSpinner
            size={75}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              margin: '-50px 0px 0px -50px',
            }}
          />
        )}
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {error && (
              <div className="text-center">{error.message}</div>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              className={classes.boxTab}
            >
              <CustomTooltip title={t('รายงานข้อมูล ณ เวลา 07.00 น. ของทุกวัน และระบุวันที่ตามวันที่รายงาน')}>
                <Tab
                  label={(
                    <>
                      <Hidden smDown>{t('กรมชลประทาน')}</Hidden>
                      <Hidden mdUp>{t('กรมชลฯ')}</Hidden>
                    </>
                  )}
                  className={value === 0 ? classes.active_tab : ''}
                />
              </CustomTooltip>
              <CustomTooltip title={t('รายงานข้อมูล ณ เวลา 0.00 ของทุกวัน และระบุวันที่รายงานเป็นวันที่ของเมื่อวาน')}>
                <Tab
                  label={(
                    <>
                      <Hidden smDown>
                        {t('การไฟฟ้าฝ่ายผลิต (รายวัน)')}
                      </Hidden>
                      <Hidden mdUp>{t('กฟผ (รายวัน)')}</Hidden>
                    </>
                  )}
                  className={value === 1 ? classes.active_tab : ''}
                />
              </CustomTooltip>
              <Tab
                label={(
                  <>
                    <Hidden smDown>
                      {t('การไฟฟ้าฝ่ายผลิต (รายชั่วโมง)')}
                    </Hidden>
                    <Hidden mdUp>{t('กฟผ (รายชั่วโมง)')}</Hidden>
                  </>
                )}
                className={value === 2 ? classes.active_tab : ''}
              />
            </Tabs>

            {/* RID Daily */}
            {value === 0 && (
              <TabContainer>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={4} lg={2}>
                    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                      <IconButton
                        className={classes.button}
                        aria-label={t('วันก่อนหน้า')}
                        onClick={() => {
                          this.setDate('rid', 'prev');
                        }}
                      >
                        <FaAngleLeft fontSize="small" />
                      </IconButton>
                      <FormControl className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog-rid-daily"
                            label={t('ข้อมูลวันที่')}
                            format="YYYY-MM-DD"
                            views={['year', 'month', 'date']}
                            value={selectedDateLargeRid || moment().subtract(1, 'days').format('YYYY-MM-DD')}
                            maxDate={latestDateRid}
                            onChange={this.handleDateChangeLargeRid}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                      <IconButton
                        className={classes.button}
                        aria-label={t('วันถัดไป')}
                        onClick={() => {
                          this.setDate('rid', 'next');
                        }}
                      >
                        <FaAngleRight fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                    <TableHead>
                      <TableRow style={{ backgroundColor: '#e0e0e0' }}>
                        <TableCell scope="row" className="first-large-column-sticky" align="center" style={{ border: '2px solid #FFF' }}>
                          {t('ชื่อ')}
                        </TableCell>
                        <TableCell align="center" style={{ border: '2px solid #FFF' }}>{t('ความจุอ่าง')}</TableCell>
                        <TableCell colSpan={2} align="center" style={{ border: '2px solid #FFF' }}>{t('น้ำในอ่าง')}</TableCell>
                        <TableCell colSpan={3} align="center" style={{ border: '2px solid #FFF' }}>{t('ปริมาตรน้ำไหลลงอ่าง')}</TableCell>
                        <TableCell colSpan={2} align="center" style={{ border: '2px solid #FFF' }}>{t('ปริมาตรน้ำระบาย')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHeaderEnhanceDam
                      columnData={columnDataRid}
                      order={order}
                      orderBy={orderByRid}
                      onRequestSort={this.handleRequestSortRid}
                      firstColumnSticky
                      // style={{ whiteSpace: 'normal' }}
                    />
                    <TableBody>
                      {areas
                        .map(area => {
                          let totalInflow = 0;
                          let totalInflowAcc = 0;
                          let totalInflowAvg = 0;
                          let totalReleased = 0;
                          let totalReleasedAcc = 0;
                          let totalDamStorage = 0;
                          let totalDamUseWater = 0;
                          // let totalMaxStorage = 0;
                          let totalNormalStorage = 0;
                          { /* let totalUsesWater = 0; */ }

                          const totalDamInArea = largeDamRid
                            .filter(row => row.geocode.area_code === area.id.toString())
                            .length;

                          if (totalDamInArea === 0) return null;

                          return (
                            <Fragment key={area.id}>
                              <StyledTableRow style={{ backgroundColor: tableGroupColor }}>
                                <TableCell component="th" scope="row" align="left" className="first-column-sticky" style={{ color: '#FFF', backgroundColor: tableGroupColor, display: 'table-cell' }}>
                                  <strong>
                                    {t(area.name)}
                                    {' '}
                                    {`(${totalDamInArea})`}
                                  </strong>
                                </TableCell>
                                <TableCell
                                  colSpan={8}
                                  component="th"
                                  scope="row"
                                  align="left"
                                  style={{
                                    fontSize: '1em', color: '#FFF', backgroundColor: tableGroupColor, display: 'table-cell',
                                  }}
                                />
                              </StyledTableRow>

                              {orderBy(largeDamRid, orderByRid, order)
                                .filter(row => row.geocode.area_code === area.id.toString())
                                .map(row => {
                                  const storageStatus = this.damStatus(
                                    row.dam_storage,
                                    parseFloat(row.dam_storage_percent).toFixed(2),
                                  );

                                  let damMaxStroage = row.dam.max_storage;
                                  if (damMaxStroage) damMaxStroage = damMaxStroage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                                  totalInflow += row.dam_inflow;
                                  totalInflowAcc += row.dam_inflow_acc;
                                  totalInflowAvg += row.dam_inflow_avg;
                                  totalReleased += row.dam_released;
                                  totalReleasedAcc += row.dam_released_acc;
                                  // totalMaxStorage += row.dam.max_storage;
                                  totalNormalStorage += row.dam.normal_storage;
                                  { /* totalUsesWater += row.dam.uses_water; */ }
                                  totalDamStorage += row.dam_storage;
                                  totalDamUseWater += row.dam_uses_water;

                                  grandTotalInflow += row.dam_inflow;
                                  grandTotalInflowAvg += row.dam_inflow_avg;
                                  grandTotalInflowAcc += row.dam_inflow_acc;
                                  grandTotalReleased += row.dam_released;
                                  grandTotalReleasedAcc += row.dam_released_acc;
                                  grandTotalDamStorage += row.dam_storage;
                                  grandTotalDamUseWater += row.dam_uses_water;
                                  // grandTotalMaxStorage += row.dam.max_storage;
                                  grandTotalNormalStorage += row.dam.normal_storage;
                                  { /* grandTotalUsesWater += row.dam.uses_water; */ }

                                  return (
                                    <StyledTableRow key={row.id}>
                                      <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                                        <DamContext.Consumer>
                                          {({ handleModalOpen }) => (
                                            <>
                                              <Tooltip title={t('กราฟรายปี')}>
                                                <CustomButton
                                                  className={classes.buttonDam}
                                                  onClick={this.handleChart(handleModalOpen, row, 12)}
                                                  style={{
                                                    textAlign: 'left', paddingLeft: 5, border: '1px solid #ccc', textTransform: 'none',
                                                  }}
                                                >
                                                  {`${tJson(row.dam.dam_name, 'เขื่อน')}`}
                                                </CustomButton>
                                              </Tooltip>
                                              <Tooltip title={t('กราฟรายปี')}>
                                                <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChart(handleModalOpen, row, 12)}>
                                                  <FaChartLine fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title={t('กราฟเปรียบเทียบรายวัน')}>
                                                <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChartCompareDaily(handleModalOpen, row)}>
                                                  <FaChartBar fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                            </>
                                          )}
                                        </DamContext.Consumer>
                                      </TableCell>
                                      {/* <SmallDamTableCell className={classes.SmallTable}>
                                        {row.geocode.province_name.th}
                                      </SmallDamTableCell>
                                      <SmallDamTableCell className={classes.SmallTable}>
                                        {row.basin.basin_name.th}
                                      </SmallDamTableCell> */}
                                      {/* <SmallDamTableCell className={classes.SmallTable} align="right">
                                        {damMaxStroage}
                                      </SmallDamTableCell> */}
                                      <SmallDamTableCell align="right">
                                        {row.dam.normal_storage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      </SmallDamTableCell>
                                      {/* <SmallDamTableCell className={classes.SmallTable} align="right">
                                        {row.dam.uses_water.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      </SmallDamTableCell> */}
                                      <SmallDamTableCell
                                        align="right"
                                        dangerouslySetInnerHTML={{ __html: storageStatus }}
                                      />
                                      <SmallDamTableCell align="right">
                                        {row.dam_uses_water_percent > 0 ? (
                                          <div>
                                            {`${row.dam_uses_water.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${row.dam_uses_water_percent.toFixed(0)}%)`}
                                          </div>
                                        ) : (
                                          <div>-</div>
                                        )}
                                      </SmallDamTableCell>
                                      <SmallDamTableCell align="right">
                                        {row.dam_inflow_avg === 0
                                        && '-'}
                                        {row.dam_inflow_avg > 0
                                        && row.dam_inflow_avg.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      </SmallDamTableCell>
                                      <SmallDamTableCell align="right">{row.dam_inflow}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">
                                        {row.dam_inflow_acc === 0
                                        && '-'}
                                        {row.dam_inflow_acc > 0
                                        && `${row.dam_inflow_acc.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${row.dam_inflow_acc_percent}%)`}
                                      </SmallDamTableCell>
                                      <SmallDamTableCell align="right">
                                        {row.dam_released}
                                      </SmallDamTableCell>
                                      <SmallDamTableCell align="right">
                                        {row.dam_released_acc === 0
                                        && '-'}
                                        {row.dam_released_acc > 0
                                        && row.dam_released_acc.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      </SmallDamTableCell>
                                    </StyledTableRow>
                                  );
                                })}

                              <StyledTableRow style={{ backgroundColor: tableSummaryColor }}>
                                <TableCell component="th" scope="row" align="left" className="first-column-sticky-total">
                                  <DamContext.Consumer>
                                    {({ handleModalOpen }) => (
                                      <>
                                        <Tooltip title={t('กราฟรายปี')}>
                                          <Button
                                            onClick={this.handleChartSumByRegion(handleModalOpen, area.id, 12)}
                                            style={{ textAlignLast: 'left' }}
                                          >
                                            <strong>
                                              {t('รวม')}
                                              {t(area.name)}
                                            </strong>
                                          </Button>
                                        </Tooltip>
                                        <Tooltip title={t('กราฟรายปี')}>
                                          <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChartSumByRegion(handleModalOpen, area.id, 12)}>
                                            <FaChartLine fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('กราฟเปรียบเทียบรายวัน')}>
                                          <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChartCompareDailySumByRegion(handleModalOpen, area.id, 12)}>
                                            <FaChartBar fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    )}
                                  </DamContext.Consumer>
                                </TableCell>
                                {/* <SmallDamTableCell />
                                <SmallDamTableCell /> */}
                                {/* <SmallDamTableCell align="right">
                                  <strong>
                                    {totalMaxStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell> */}
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell>
                                {/* <SmallDamTableCell align="right">
                                  <strong>
                                    {totalUsesWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell> */}
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    <span
                                      style={{
                                        color: DamStatusColor(
                                          parseFloat(((totalDamStorage * 100) / totalNormalStorage).toFixed(0)),
                                        ).color.statusColor,
                                      }}
                                    >
                                      {' ('}
                                      {((totalDamStorage * 100) / totalNormalStorage).toFixed(0)}
                                      {'%)'}
                                    </span>
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalDamUseWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    {' ('}
                                    {((totalDamUseWater * 100) / totalNormalStorage).toFixed(0)}
                                    {'%)'}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalInflowAvg.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalInflow.toFixed(2)}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalInflowAcc.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    {' ('}
                                    {((100 * totalInflowAcc) / totalInflowAvg).toFixed(2)}
                                    {'%)'}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalReleased.toFixed(2)}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalReleasedAcc.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell>
                              </StyledTableRow>
                            </Fragment>
                          );
                        })}
                      <StyledTableRow style={{ backgroundColor: tableGrandSummaryColor }}>
                        <TableCell component="th" scope="row" align="left" className="first-column-sticky-total" style={{ backgroundColor: tableGrandSummaryColor }}>
                          <DamContext.Consumer>
                            {({ handleModalOpen }) => (
                              <>
                                <Tooltip title={t('กราฟรายปี')}>
                                  <Button
                                    onClick={this.handleChartSumByRegion(handleModalOpen, 0, 12)}
                                    style={{ textAlign: 'left' }}
                                  >
                                    <strong>
                                      {t('รวมทั้งประเทศ')}
                                      {' '}
                                      {`(${totalLargeDamRid})`}
                                    </strong>
                                  </Button>
                                </Tooltip>
                                <Tooltip title={t('กราฟรายปี')}>
                                  <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChartSumByRegion(handleModalOpen, 0, 12)}>
                                    <FaChartLine fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t('กราฟเปรียบเทียบรายวัน')}>
                                  <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChartCompareDailySumByRegion(handleModalOpen, 0, 12)}>
                                    <FaChartBar fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </DamContext.Consumer>
                        </TableCell>
                        {/* <SmallDamTableCell />
                        <SmallDamTableCell /> */}
                        {/* <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalMaxStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell> */}
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell>
                        {/* <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalUsesWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell> */}
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            <span style={{
                              color: DamStatusColor(
                                parseFloat(((grandTotalDamStorage * 100) / grandTotalNormalStorage).toFixed(0)).toFixed(0),
                              ).color.statusColor,
                            }}
                            >
                              {' ('}
                              {((grandTotalDamStorage * 100) / grandTotalNormalStorage).toFixed(0)}
                              {'%)'}
                            </span>
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalDamUseWater.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            {' ('}
                            {((grandTotalDamUseWater * 100) / grandTotalNormalStorage).toFixed(0)}
                            {'%)'}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalInflowAvg.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalInflow.toFixed(2)}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalInflowAcc.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            {' ('}
                            {((100 * grandTotalInflowAcc) / grandTotalInflowAvg).toFixed(2)}
                            {'%)'}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalReleased.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalReleasedAcc.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <DamLegend />
                <div style={{ textAlign: 'right' }}>
                  <Link color="secondary" href="http://water.rid.go.th" target="_blank" rel="noopener noreferrer">
                    <Typography variant="caption">
                      {t('ข้อมูลจาก ')}
                      {t('กรมชลประทาน')}
                    </Typography>
                  </Link>
                </div>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                      {`${t('ข้อมูลล่าสุดวันที่')} ${moment(selectedDateLargeRid).locale('th').format('DD MMM YYYY')}`}
                    </Box>
                  </Box>
                </Grid>
              </TabContainer>
            )}

            {/* Egat Daily */}
            {value === 1 && (
              <TabContainer>
                <Grid container direction="row" justify="center" spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <FormControl className={classes.formControl}>
                      <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                        <IconButton
                          className={classes.button}
                          aria-label={t('วันก่อนหน้า')}
                          onClick={() => {
                            this.setDate('egat', 'prev');
                          }}
                        >
                          <FaAngleLeft fontSize="small" />
                        </IconButton>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog-egat-daily"
                            label={t('ข้อมูลวันที่')}
                            format="YYYY-MM-DD"
                            views={['year', 'month', 'date']}
                            value={selectedDateLargeEgat}
                            maxDate={latestDateEgat}
                            onChange={this.handleDateChangeLargeEgat}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <IconButton
                          className={classes.button}
                          aria-label={t('วันถัดไป')}
                          onClick={() => {
                            this.setDate('egat', 'next');
                          }}
                        >
                          <FaAngleRight fontSize="small" />
                        </IconButton>
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>

                <TableContainer component={Paper}>
                  <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                    <TableHeaderEnhanceDam
                      columnData={columnDataEgatDaily}
                      order={order}
                      orderBy={orderByEgatDaily}
                      onRequestSort={this.handleRequestSortEgatDaily}
                      firstColumnSticky
                      style={{ whiteSpace: 'normal' }}
                    />
                    <TableBody>
                      {areas
                        .map(area => {
                          let totalInflow = 0;
                          let totalReleased = 0;
                          let totalDamStorage = 0;
                          // let totalDamMaxStorage = 0;
                          let totalDamNormalStorage = 0;
                          let totalCanAccept = 0;

                          const totalDamInArea = largeDamEgat
                            .filter(row => row.geocode.area_code === area.id.toString())
                            .length;

                          if (totalDamInArea === 0) return null;

                          return (
                            <Fragment key={area.id}>
                              <StyledTableRow style={{ backgroundColor: tableGroupColor }}>
                                <TableCell component="th" scope="row" align="left" className="first-column-sticky" style={{ color: '#FFF', backgroundColor: tableGroupColor, display: 'table-cell' }}>
                                  <strong>
                                    {t(area.name)}
                                    {' '}
                                    {`(${totalDamInArea})`}
                                  </strong>
                                </TableCell>
                                <TableCell
                                  colSpan={8}
                                  component="th"
                                  scope="row"
                                  align="left"
                                  style={{
                                    fontSize: '1em', color: '#FFF', backgroundColor: tableGroupColor, display: 'table-cell',
                                  }}
                                />
                              </StyledTableRow>
                              {orderBy(largeDamEgat, orderByEgatDaily, order)
                                .filter(row => row.geocode.area_code === area.id.toString())
                                .map(row => {
                                  // format data
                                  // let damMaxStroage;
                                  let canAccept = 0;
                                  let percentStorage = 0;

                                  // if ('max_storage' in row.dam) {
                                  //   totalDamMaxStorage += row.dam.max_storage;
                                  //   grandTotalMaxStorage += row.dam.max_storage;
                                  //   damMaxStroage = row.dam.max_storage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                  // } else {
                                  //   damMaxStroage = '-';
                                  // }

                                  // calculate data
                                  canAccept = (row.dam.normal_storage - row.dam_storage).toFixed(0);
                                  percentStorage = ((100 * row.dam_storage) / row.dam.normal_storage)
                                    .toFixed(0);
                                  totalCanAccept += parseFloat(canAccept);
                                  grandTotalCanAccept += parseFloat(canAccept);

                                  let damStroage = 0;
                                  if (row.dam_storage) {
                                    damStroage = row.dam_storage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                  }

                                  totalDamNormalStorage += row.dam.normal_storage;
                                  totalInflow += row.dam_inflow;
                                  totalReleased += row.dam_released;
                                  totalDamStorage += row.dam_storage;

                                  grandTotalInflow += row.dam_inflow;
                                  grandTotalReleased += row.dam_released;
                                  grandTotalDamStorage += row.dam_storage;
                                  grandTotalNormalStorage += row.dam.normal_storage;

                                  return (
                                    <StyledTableRow key={row.id}>
                                      <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                                        <DamContext.Consumer>
                                          {({ handleModalOpen }) => (
                                            <>
                                              <Tooltip title={`${tJson(row.geocode.province_name, 'จ.')} ${tJson(row.basin.basin_name, 'ลุ่มน้ำ')}`}>
                                                <CustomButton
                                                  className={classes.buttonDamName}
                                                  onClick={this.handleChart(handleModalOpen, row, 8)}
                                                  style={{ textTransform: 'none' }}
                                                >
                                                  {`${tJson(row.dam.dam_name, 'เขื่อน')}`}
                                                </CustomButton>
                                              </Tooltip>
                                              <Tooltip title={t('กราฟรายปี')}>
                                                <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChart(handleModalOpen, row, 8)}>
                                                  <FaChartLine fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                            </>
                                          )}
                                        </DamContext.Consumer>

                                      </TableCell>
                                      {/* <SmallDamTableCell>
                                        {row.geocode.province_name.th}
                                      </SmallDamTableCell>
                                      <SmallDamTableCell>{row.basin.basin_name.th}</SmallDamTableCell> */}
                                      <SmallDamTableCell align="right">{row.dam_level}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">{row.dam.normal_storage}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">{damStroage}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">
                                        <span
                                          style={{
                                            color: DamStatusColor(
                                              parseFloat(percentStorage).toFixed(0),
                                            ).color.statusColor,
                                          }}
                                        >
                                          <strong>{percentStorage}</strong>
                                        </span>
                                      </SmallDamTableCell>
                                      <SmallDamTableCell align="right">{canAccept.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">{row.dam_inflow}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">{row.dam_released}</SmallDamTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              <StyledTableRow style={{ color: '#FFF', backgroundColor: tableSummaryColor }}>
                                <TableCell component="th" scope="row" align="left" className="first-column-sticky-total">
                                  <strong>
                                    {t('รวม')}
                                    {t(area.name)}
                                  </strong>
                                </TableCell>
                                {/* <SmallDamTableCell />
                                <SmallDamTableCell /> */}
                                <SmallDamTableCell />
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalDamNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    <span
                                      style={{
                                        color: DamStatusColor(
                                          parseFloat((100 * totalDamStorage) / totalDamNormalStorage)
                                            .toFixed(0),
                                        ).color.statusColor,
                                      }}
                                    >
                                      {((100 * totalDamStorage) / totalDamNormalStorage).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </span>
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalCanAccept.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalInflow.toFixed(2)}
                                  </strong>
                                </SmallDamTableCell>
                                <SmallDamTableCell align="right">
                                  <strong>
                                    {totalReleased.toFixed(2)}
                                  </strong>
                                </SmallDamTableCell>
                                {/* <SmallDamTableCell /> */}
                              </StyledTableRow>
                            </Fragment>
                          );
                        })}
                      <StyledTableRow style={{ backgroundColor: tableGrandSummaryColor }}>
                        <TableCell component="th" scope="row" align="left" className="first-column-sticky-total" style={{ backgroundColor: tableGrandSummaryColor }}>
                          <strong>
                            {t('รวมทั้งประเทศ')}
                            {' '}
                            {`(${totalLargeDamEgat})`}
                          </strong>
                        </TableCell>
                        <TableCell />
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalNormalStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalDamStorage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            <span
                              style={{
                                color: DamStatusColor(
                                  parseFloat((100 * grandTotalDamStorage) / grandTotalNormalStorage)
                                    .toFixed(0),
                                ).color.statusColor,
                              }}
                            >
                              {((100 * grandTotalDamStorage) / grandTotalNormalStorage).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </span>
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalCanAccept.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalInflow.toFixed(2)}
                          </strong>
                        </SmallDamTableCell>
                        <SmallDamTableCell align="right">
                          <strong>
                            {grandTotalReleased.toFixed(2)}
                          </strong>
                        </SmallDamTableCell>
                      </StyledTableRow>
                    </TableBody>
                    <TableFooter>
                      <StyledTableRow>
                        <SmallDamTableCell colSpan={11} style={{ textAlign: 'right' }}>
                          <Link color="secondary" href="http://water.egat.co.th/" target="_blank" rel="noopener noreferrer">
                            <Typography variant="caption">
                              {t('ข้อมูลจาก ')}
                              {t('การไฟฟ้าฝ่ายผลิตแห่งประเทศไทย')}
                            </Typography>
                          </Link>
                        </SmallDamTableCell>
                      </StyledTableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>

                <Box display="flex" justifyContent="flex-end">
                  <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                    {`${t('ข้อมูลล่าสุดวันที่')} ${moment(selectedDateLargeEgat).locale('th').format('DD MMM YYYY')} ${t('(หน่วย ล้าน ลบ.ม.)')}`}
                  </Box>
                </Box>
              </TabContainer>
            )}

            {/* Egat Hourly */}
            {value === 2 && (
              <TabContainer>
                <Grid container direction="row" justify="flex-end" spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    {/* <FormControl className={classes.formControl}>
                      <Box my={1}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog-hourly"
                            label="ข้อมูลวันที่"
                            format="YYYY-MM-DD"
                            views={['year', 'month', 'date']}
                            value={selectedDateLargeEgat}
                            maxDate={latestDateEgat}
                            onChange={this.handleDateChangeLargeEgat}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </FormControl> */}
                  </Grid>
                </Grid>

                <TableContainer component={Paper}>
                  <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                    <TableHeaderEnhanceDam
                      columnData={columnDataEgatHourly}
                      order={order}
                      orderBy={orderByEgatHourly}
                      onRequestSort={this.handleRequestSortEgatHourly}
                      firstColumnSticky
                    />
                    <TableBody>
                      {areas
                        .map(area => {
                          const totalDamInArea = datasEgatHourlySort
                            .filter(row => row.geocode.area_code === area.id.toString())
                            .length;

                          if (totalDamInArea === 0) return null;

                          return (
                            <Fragment key={area.id}>
                              <StyledTableRow style={{ backgroundColor: tableGroupColor }}>
                                <TableCell component="th" scope="row" align="left" className="first-column-sticky" style={{ color: '#FFF', backgroundColor: tableGroupColor, display: 'table-cell' }}>
                                  <strong>
                                    {t(area.name)}
                                    {' '}
                                    {`(${totalDamInArea})`}
                                  </strong>
                                </TableCell>
                                <TableCell
                                  colSpan={7}
                                  component="th"
                                  scope="row"
                                  align="left"
                                  style={{
                                    fontSize: '1em', color: '#FFF', backgroundColor: tableGroupColor, display: 'table-cell',
                                  }}
                                />
                              </StyledTableRow>
                              {orderBy(datasEgatHourlySort, orderByEgatHourly, order)
                                .filter(row => row.geocode.area_code === area.id.toString())
                                .map(row => {                             
                                  const storageStatus = this.damStatus(
                                    row.dam_storage,
                                    row.dam_storage_percent.toFixed(2),
                                  );

                                  return (
                                    <StyledTableRow key={row.id}>
                                      <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                                        <DamContext.Consumer>
                                          {({ handleModalOpen }) => (
                                            <>
                                              <Tooltip title={t('กราฟ')}>
                                                <CustomButton
                                                  className={classes.buttonDamName}
                                                  onClick={this.handleChart(handleModalOpen, row, 8)}
                                                >
                                                  {`${tJson(row.dam.dam_name, 'เขื่อน')}`}
                                                </CustomButton>
                                              </Tooltip>
                                              <Tooltip title={t('กราฟรายชั่วโมง')}>
                                                <IconButton className={classes.buttonDam} aria-label="Chart" onClick={this.handleChartEgat(handleModalOpen, row, 8)}>
                                                  <FaChartLine fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                            </>
                                          )}
                                        </DamContext.Consumer>

                                      </TableCell>
                                      {/* <SmallDamTableCell>
                                        {row.geocode.province_name.th}
                                      </SmallDamTableCell>
                                      <SmallDamTableCell>{row.basin.basin_name.th}</SmallDamTableCell> */}
                                      <SmallDamTableCell>{row.dam_date}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">{row.dam_level}</SmallDamTableCell>
                                      <SmallDamTableCell align="right" dangerouslySetInnerHTML={{ __html: storageStatus }} />
                                      <SmallDamTableCell align="right">{row.dam_inflow}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">{row.dam_released}</SmallDamTableCell>
                                      <SmallDamTableCell align="right">{row.dam_evap}</SmallDamTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                            </Fragment>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <StyledTableRow>
                        <SmallDamTableCell colSpan={10} style={{ textAlign: 'right' }}>
                          <Link color="secondary" href="http://water.egat.co.th/" target="_blank" rel="noopener noreferrer">
                            <Typography variant="caption">
                              {t('ข้อมูลจาก การไฟฟ้าฝ่ายผลิตแห่งประเทศไทย')}
                            </Typography>
                          </Link>
                        </SmallDamTableCell>
                      </StyledTableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                <Box display="flex" justifyContent="flex-end">
                  <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                    {t('แสดงข้อมูลล่าสุด (หน่วย ล้าน ลบ.ม.)')}
                  </Box>
                </Box>
              </TabContainer>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

DamLargeFullList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  datasEgatHourly: PropTypes.array.isRequired,
  datasRid: PropTypes.array.isRequired,
  datasEgat: PropTypes.array.isRequired,
  latestDateEgat: PropTypes.string.isRequired,
  latestDateRid: PropTypes.string.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }), withTranslation())(DamLargeFullList);
