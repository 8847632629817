import React, { useEffect, useState } from 'react';
import MainMap from './MainMap';

export default function Main() {
  // const [getgeojsonamphoe, setGeoJsonAmphoe] = useState();
  // const [getgeojsonlive1, setGeoJsonLive1] = useState();
  // const [getGeoJsonRiver, setGeoJsonRiver] = useState();
  // const [getGeoJsonProvince, setGeoJsonProvince] = useState();
  // const initPath = process.env.MIX_APP_PATH;

  // async function fetchDataApi() {
  //   Promise.all([
  //     fetch(`${initPath}json/boundary/amphoe.json`),
  //     fetch('https://live1.haii.or.th/product/latest/rain/rain_amphoe.json'),
  //     fetch(`${initPath}json/river/river_main.json`),
  //     fetch(`${initPath}json/boundary/thailand.json`),
  //   ])
  //     .then((
  //       [
  //         res1,
  //         res2,
  //         res3,
  //         res4,
  //       ],
  //     ) => Promise.all(
  //       [
  //         res1.json(),
  //         res2.json(),
  //         res3.json(),
  //         res4.json(),
  //       ],
  //     ))
  //     .then((
  //       [
  //         amphoeJson,
  //         rainAmphoeJson,
  //         riverMain,
  //         provinceMain,
  //       ],
  //     ) => {
  //       setGeoJsonAmphoe(amphoeJson);
  //       setGeoJsonLive1(rainAmphoeJson);
  //       setGeoJsonRiver(riverMain);
  //       setGeoJsonProvince(provinceMain);
  //     })
  //     .catch(error => error);
  // }

  // useEffect(() => {
  //   fetchDataApi();
  // }, []);

  return (
    <div>
      {/* <MainMap
        getgeojsonamphoe={getgeojsonamphoe}
        getgeojsonlive1={getgeojsonlive1}
        getGeoJsonRiver={getGeoJsonRiver}
        getGeoJsonProvince={getGeoJsonProvince}
      /> */}
      <MainMap />
    </div>
  );
}
