import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// ui
// import FormGroup from '@material-ui/core/FormGroup';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import StormListItem from './StormListItem';
import { StormStatusColor } from './StormColor';

// const textRed = red[900];

export default function StormListJtwc(props) {
  // debug - should get storm list
  // console.log(props)
  const { data, handleToggleLayer, selectAll, handleSelectAllChange } = props;
  const { t } = useTranslation();
  const handleChange = event => {
    // console.log(event.target.id, event.target.checked)
    handleToggleLayer(event.target.id, event.target.name);
  };

  return (
    <>
      <div style={{ overflowY: 'auto', height: 450 }}>
        {data.length === 0 && (
          <>
            {t('ไม่พบข้อมูลพายุ ตามเงื่อนไข')}
          </>
        )}
        <FormGroup>
          {data.length > 0 && (
            <FormControlLabel
              control={
                <Checkbox checked={selectAll} onChange={handleSelectAllChange} name="selectAll" color="primary" />
              }
              label="Select all"
            />
          )}
          {data.map(d => {
            const color = StormStatusColor(d.metadata.max_wind_speed);
            const statusStyle = {
              backgroundColor: color.color.statusColor,
              color: color.color.constrastColor,
            };
            return (
              <StormListItem
                key={`${d.metadata.name}-${d.metadata.code}`}
                onCheckItem={handleChange}
                item={d}
                statusStyle={statusStyle}
              />
            );
          })}
        </FormGroup>
      </div>
    </>
  );
}

StormListJtwc.propTypes = {
  data: PropTypes.array.isRequired,
  handleToggleLayer: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  handleSelectAllChange: PropTypes.func.isRequired,
};

export const StormListJtwcMemo = React.memo(StormListJtwc);
