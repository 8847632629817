import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { orderBy, get } from 'lodash';

// style, icons
import { withStyles } from '@material-ui/core/styles';

// ui
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { FaChartLine } from '../../utils/Fontawesome';
import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';

import {
  styles,
  StyledTableRow,
} from '../../styles/Style';

import WaterQualityStatusColor from './WaterQualityStatusColor';

// translate
import tJson from '../../utils/TJson';

const columnData = [
  {
    id: 'waterquality_station.waterquality_station_name.th', align: 'left', disablePadding: true, label: 'สถานี', sortable: true,
  },
  {
    id: 'address', align: 'left', disablePadding: false, label: 'ที่ตั้ง', unit: '', sortable: false,
  },
  {
    id: 'river', align: 'left', disablePadding: false, label: 'แม่น้ำ', unit: '', sortable: false,
  },
  {
    id: 'waterquality_salinity', align: 'center', disablePadding: false, label: 'ความเค็ม', unit: '(g/L)', sortable: true,
  },
  {
    id: 'waterquality_ph', align: 'center', disablePadding: false, label: 'กรด-ด่าง', unit: '', sortable: true,
  },
  {
    id: 'waterquality_do', align: 'center', disablePadding: false, label: 'ออกซิเจนละลายในน้ำ', unit: '(mg/L)', sortable: true,
  },
  {
    id: 'waterquality_datetime', align: 'center', disablePadding: false, label: 'เวลา', sortable: false,
  },
  {
    id: 'chart', align: 'center', disablePadding: false, label: '',
  },
];

class WaterQualityLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // isLoading: true,
      page: 0,
      rowsPerPage: 10,
      order: 'asc',
      orderByGeocode: 'geocode.province_name.th',
    };
    this.handleChart = this.handleChart.bind(this);
  }

  // componentDidMount() {
  //   setTimeout(() => this.setState({ isLoading: false }), 500);
  // }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChart = data => e => {
    const { handleStationModalOpen } = this.props;
    e.preventDefault();

    const newData = {
      id: data.waterquality_station.id,
      provinceId: data.waterquality_station.province_code,
    };

    handleStationModalOpen(newData);
  };

  waterqualityStatus = (data, percent) => {
    const status = WaterQualityStatusColor(parseFloat(percent).toFixed(0));

    let statusText = '';
    if (parseFloat(percent) === 0) {
      statusText = `${data.toFixed(0)} <b>(--)</b>`;
    } else {
      statusText = `${data.toFixed(0)} <span style='color:${
        status.color.statusColor
      }'> <b>(${parseFloat(percent).toFixed(0)}%)</b></span>`;
    }
    return statusText;
  };

  // get water quality status
  waterQualityStatusBadge = (data, value) => {
    const { classes } = this.props;
    const className = classNames(classes.status, classes.stay);
    const status = WaterQualityStatusColor(data, 'salinity');
    let statusText = '';
    statusText = `
      <p
        class="${className}"
        style= background:${status.color.statusColor === '#66c803' ? 'unset' : status.color.statusColor};text-align:center;width:60px;margin-left:18%;color:${(status.color.statusColor === '#FFD966') || (status.color.statusColor === '#66c803') ? '#000000' : '#FFFFFF'}
      >
        ${Number(value).toFixed(2)}
      </p>`;

    return typeof value === 'undefined' || value == null ? '' : statusText;
  }

  // get water quality status
  waterQualityStatusBadgeDo = (data, value) => {
    const { classes } = this.props;
    const className = classNames(classes.status, classes.stay);
    const status = WaterQualityStatusColor(data, 'do');
    let statusText = '';
    statusText = `
      <p
        class="${className}"
        style= background:${status.color.statusColor === '#66C803' ? 'unset' : status.color.statusColor};text-align:center;width:60px;margin-left:18%;color:${status.color.statusColor === '#66C803' ? '#000000' : '#FFFFFF'};
        >
        ${Number(value).toFixed(2)}
      </p>`;

    return typeof value === 'undefined' || value == null ? '' : statusText;
  }

  // get water quality status
  waterQualityStatusBadgePh = (data, value) => {
    const { classes } = this.props;
    const className = classNames(classes.status, classes.stay);
    const status = WaterQualityStatusColor(data, 'ph');
    let statusText = '';
    statusText = `
    <p
      class="${className}"
      style= background:${status.color.statusColor === '#66C803' ? 'unset' : status.color.statusColor};text-align:center;width:60px;margin-left:18%;color:${status.color.statusColor === '#66C803' ? '#000000' : '#FFFFFF'};
    >
    ${Number(value).toFixed(2)}
    </p>`;

    return typeof value === 'undefined' || value == null ? '' : statusText;
  }

  // waterQualityStatusBadgeEc = (data, value) => {
  //   const { classes } = this.props;
  //   const className = classNames(classes.status, classes.stay);
  //   const status = WaterQualityStatusColor(data, 'ec');
  //   const statusText = `
  //   <p
  //     class="${className}"
  //     style= background:${status.color.statusColor === '#66C803' ? 'unset' :
  //     status.color.statusColor};text-align:center;width:60px;margin-left:35%;
  //     color:${status.color.statusColor === '#66C803' ? '#000000' : '#FFFFFF'};
  //   >
  //   ${value}
  //   </p>`;
  //   return typeof value === 'undefined' || value == null ? '' : statusText;
  // };

  handleRequestSort = (event, property) => {
    const { order, orderByGeocode } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByGeocode === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByGeocode: orderByProperty });
  };

  render() {
    const { classes, datas, t } = this.props;
    const {
      rowsPerPage,
      page,
      order,
      orderByGeocode,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, datas.length - page * rowsPerPage);
    return (
      <Fragment key="key">
        <TableContainer component={Paper} className="zui-scroller">
          <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
            <TableHeaderEnhance
              columnData={columnData}
              order={order}
              orderBy={orderByGeocode}
              onRequestSort={this.handleRequestSort}
              firstColumnSticky
            />
            <TableBody>
              {orderBy(datas,
                [
                  item => (orderByGeocode === 'waterquality_station.waterquality_station_name.th'
                    ? item.waterquality_station.waterquality_station_name.th.toLowerCase()
                    : parseFloat(get(item, orderByGeocode) || 0)),
                ],
                [order])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const { handleSearchMarker } = this.props;
                  const WqPh = this.waterQualityStatusBadgePh(
                    row.waterquality_ph,
                    row.waterquality_ph,
                  );
                  const WqSalinity = this.waterQualityStatusBadge(
                    row.waterquality_salinity,
                    row.waterquality_salinity,
                  );
                  const WqDo = this.waterQualityStatusBadgeDo(
                    row.waterquality_do,
                    row.waterquality_do,
                  );
                  let mapLink = null;
                  if (
                    handleSearchMarker
                    && row.waterquality_station.waterquality_station_lat
                    && row.waterquality_station.waterquality_station_long
                  ) {
                    mapLink = (
                      <Button
                        onClick={handleSearchMarker(
                          row.id,
                          row.waterquality_station.waterquality_station_lat,
                          row.waterquality_station.waterquality_station_long,
                        )}
                        title={row.waterquality_station.agency_name.th}
                        // title="แสดงตำแหน่งบนแผนที่"
                        style={{ textAlign: 'left' }}
                      >
                        {`${tJson(row.waterquality_station.waterquality_station_name, 'สถานี')}`}
                        {/* {`${row.waterquality_station.waterquality_station_name.th}`} */}
                      </Button>
                    );
                  } else {
                    // mapLink = row.waterquality_station.waterquality_station_name.th;
                    mapLink = tJson(row.waterquality_station.waterquality_station_name, 'สถานี');
                  }
                  return (
                    <StyledTableRow key={row.id}>
                      <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                        {mapLink}
                      </TableCell>
                      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                        {row.waterquality_station.tumbon_name.th !== '' ? ` ${tJson(row.waterquality_station.tumbon_name, 'ต.')}` : ''}
                        {row.waterquality_station.amphoe_name.th !== '' ? ` ${tJson(row.waterquality_station.amphoe_name, 'อ.')}` : ''}
                        {` ${tJson(row.waterquality_station.province_name, 'จ.')}`}
                      </TableCell>
                      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                        {t(row.waterquality_station.river_name)}
                      </TableCell>
                      <TableCell align="center" style={{ padding: 0 }} dangerouslySetInnerHTML={{ __html: WqSalinity }} />
                      <TableCell align="center" style={{ padding: 0 }} dangerouslySetInnerHTML={{ __html: WqPh }} />
                      <TableCell align="center" style={{ padding: 0 }} dangerouslySetInnerHTML={{ __html: WqDo }} />
                      <TableCell align="center">
                        {row.waterquality_datetime.substring(
                          row.waterquality_datetime.length - 5,
                        )}
                        {' '}
                        น.
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <Tooltip title={t('แสดงกราฟ')}>
                          <IconButton
                            onClick={this.handleChart(row)}
                            className={classes.button}
                            aria-label="Chart"
                          >
                            <FaChartLine fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}

              {emptyRows === rowsPerPage && (
                <TableRow
                  style={{ height: 1 * emptyRows, backgroundColor: '#F7F7F7' }}
                >
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    {t('ไม่พบข้อมูล')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          className={classes.hiddenLine}
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActionsWrapped}
          labelRowsPerPage={t('จำนวนแถวต่อหน้า')}
          labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to} ${t('จาก')} ${count} ${t('รายการ')}`}
        />
      </Fragment>
    );
  }
}

WaterQualityLists.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  handleStationModalOpen: PropTypes.func.isRequired,
  handleSearchMarker: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(WaterQualityLists);
