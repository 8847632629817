import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
  },
});

export default function LegendFcstRain() {
  const classes = useStyles();

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.8',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          {/* <Grid container>
            <Grid item><Typography variant="subtitle2"> Rainfall (mm) </Typography></Grid>
          </Grid> */}
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'< 0'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#aed3ff',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 0-10'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#aeecb7',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 10-20'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#7fc63c',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 20-35'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#f0d447',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 35-50'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#ef9036',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 50-70'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#bd6a27',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 70-90'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#da352e',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {'> 90'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
