import React, { Component } from 'react';
import PropTypes from 'prop-types';

// moment
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Hidden } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

// lodash
import {
  uniqueId,
} from 'lodash';

// icon
import { FaSpinner, FaCalendarAlt } from '../../../utils/Fontawesome';

// credit tooltip
import CreditTooltip from '../../../utils/CreditTooltip';

// wrf
// import WrfAnimation from './PrecipitationAnimation';
// import PrecipitationLegend from './PrecipitationLegend';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

const initPathApi = process.env.MIX_API_URL;
const initPath = process.env.MIX_APP_PATH;
const hostImage = `${initPathApi}shared/image?_csrf=&image=`;
const imageNull = 'https://via.placeholder.com/322x230?text=No%20Image&italic=true';

// lightbox
const images = [];

class Precipitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      wmo: [],
      afwa: [],
      photoIndex: 0,
      isOpen: false,
      // select: 'afwa',
      // anchorEl: null,
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.fetchData();
  }

  // handleModalAnimation = handler => e => {
  //   e.preventDefault();

  //   const params = {
  //     header: 'ภาพเคลื่อนไหวคาดการณ์ฝนล่วงหน้า 7 วัน จากแบบจำลองสภาพอากาศ (WRF-ROMS Model)',
  //     content: <WrfAnimation />,
  //   };

  //   handler(params);
  // }

  // handleChangeSelect = event => {
  //   this.setState({ select: event.target.value });
  // };

  // handleClick = event => {
  //   this.setState({ anchorEl: event.currentTarget });
  // };

  // handleClose = () => {
  //   this.setState({ anchorEl: null });
  // };

  fetchData() {
    // get data from service
    fetch(`${initPathApi}public/weather_img/modis_precipitation_usda`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const wmo = result.data.filter(d => {
          const mediaTypeId = d.detail[0].media_type_id;
          return (mediaTypeId === 151 || mediaTypeId === 152);
        });

        const afwa = result.data.filter(d => {
          const mediaTypeId = d.detail[0].media_type_id;
          return (mediaTypeId === 150 || mediaTypeId === 149);
        });
        this.setState({
          wmo,
          afwa,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes, t } = this.props;
    const {
      isLoading,
      error,
      wmo,
      afwa,
      photoIndex,
      isOpen,
    } = this.state;

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={70} />
          </div>
        )}
        <Box mt={{ xs: 3, sm: 9, md: 9 }} />
        <Grid container spacing={0} className={classes.boxTopRadius}>
          <Grid item xs className={classes.boxTitlePrimary}>
            <Box m={1} className={classes.titleBar}>{t('ปริมาณและการกระจายตัวของฝน')}</Box>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title={t('ดูข้อมูลย้อนหลัง')}>
              <Button
                href={`${initPath}weather/precipitation/histroy`}
                className={classes.outlinedLeftDesktopPrimary}
              >
                <FaCalendarAlt style={{ fontSize: '0.8em' }} />
                <Hidden xsDown>
                  <Typography variant="h6" style={{ marginLeft: 8 }} noWrap>
                    {t('ดูข้อมูลย้อนหลัง')}
                  </Typography>
                </Hidden>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Box width="100%" display="block" className={classes.boxBottomFixibleRadius} align="center">
              <Grid container justifyContent="center" spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    p={1}
                    mt={{
                      xs: 2, sm: 2, md: 0, lg: 0,
                    }}
                    display="flex"
                    justifyContent="center"
                    style={{ backgroundColor: grey[300], border: '1px solid #ccc' }}
                  >
                    AFWA
                  </Box>
                  <Box p={2} style={{ border: '1px solid #ccc' }}>
                    {/* afwa */}
                    <Grid container justifyContent="center" spacing={0}>
                      { afwa && (
                        afwa.map((row, i) => {
                          let realImage = '';
                          images.push({
                            src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.media_path}`,
                            title: `${t('วันที่')} ${moment(row.cover_image.media_datetime).format('YYYY-MM-DD')} ${t('เวลา')} : ${moment(row.cover_image.media_datetime).format('HH:mm')} ${t('น.')}`,
                          });
                          if (row.src !== '') {
                            realImage = (
                              <CreditTooltip
                                title={row.agency.agency_name.th}
                                link={row.agency.agency_link}
                              >
                                <Button
                                  onClick={
                                    () => this.setState({ isOpen: true, photoIndex: i + 2 })
                                  }
                                >
                                  <Img
                                    key={uniqueId()}
                                    src={`${hostImage}${row.cover_image.thumbnail_media_path}`}
                                    alt={moment(row.cover_image.media_datetime).format('ll')}
                                    className={classes.responsive}
                                  />
                                </Button>
                              </CreditTooltip>
                            );
                          } else {
                            realImage = (
                              <Img
                                key={uniqueId()}
                                src={imageNull}
                                width={400}
                                alt="No Data"
                                className={classes.responsive}
                                style={{ height: 400 }}
                              />
                            );
                          }
                          return (
                            <Grid item xs={12} sm={6} md={6} lg={6} key={uniqueId()}>
                              <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
                                {row.description.description_name.th.replace('ปริมาณและการกระจายตัวของฝน AFWA Decadal Percent Normal โดย  United States Department of Agriculture', 'AFWA Decadal Percent Normal').replace('ปริมาณและการกระจายตัวของฝน AFWA โดย  United States Department of Agriculture', 'AFWA')}
                              </Box>
                              { realImage }
                            </Grid>
                          );
                        })
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    p={1}
                    mt={{
                      xs: 2, sm: 2, md: 0, lg: 0,
                    }}
                    display="flex"
                    justifyContent="center"
                    style={{ backgroundColor: grey[300], border: '1px solid #ccc' }}
                  >
                    WMO
                  </Box>
                  <Box p={2} style={{ border: '1px solid #ccc' }}>
                    {/* wmo */}
                    <Grid container justifyContent="center" spacing={0}>
                      { wmo && (
                        wmo.map((row, i) => {
                          let realImage = '';
                          images.push({
                            src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.cover_image.media_path}`,
                            title: `วันที่ ${moment(row.cover_image.media_datetime).format('YYYY-MM-DD')} เวลา : ${moment(row.cover_image.media_datetime).format('HH:mm')} น.`,
                          });
                          if (row.src !== '') {
                            realImage = (
                              <CreditTooltip
                                title={row.agency.agency_name.th}
                                link={row.agency.agency_link}
                              >
                                <Button
                                  onClick={
                                    () => this.setState({ isOpen: true, photoIndex: i })
                                  }
                                >
                                  <Img
                                    key={uniqueId()}
                                    src={`${hostImage}${row.cover_image.thumbnail_media_path}`}
                                    alt={moment(row.cover_image.media_datetime).format('ll')}
                                    className={classes.responsive}
                                  />
                                </Button>
                              </CreditTooltip>
                            );
                          } else {
                            realImage = (
                              <Img
                                key={uniqueId()}
                                src={imageNull}
                                width={400}
                                alt="No Data"
                                className={classes.responsive}
                                style={{ height: 400 }}
                              />
                            );
                          }
                          return (
                            <Grid item xs={12} sm={6} md={6} lg={6} key={uniqueId()}>
                              <Box flexGrow={1} p={1} pl={2} fontFamily="Kanit" fontSize={14} fontWeight="fontWeightMedium">
                                {row.description.description_name.th.replace('ปริมาณและการกระจายตัวของฝน WMO Decadal Percent Normal โดย  United States Department of Agriculture', 'WMO Decadal Percent Normal').replace('ปริมาณและการกระจายตัวของฝน WMO โดย  United States Department of Agriculture', 'WMO')}
                              </Box>
                              { realImage }
                            </Grid>
                          );
                        })
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

Precipitation.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withStyles(styles, { withTheme: true })(Precipitation));
