import React from 'react';
import PropTypes from 'prop-types';
import {
  Marker,
  Popup,
  Tooltip,
} from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';
import Img from 'react-image';
import moment from 'moment';
import { isEmpty } from 'lodash';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';

import TwitterStatusColor from './icon/twitter';

const initPath = process.env.MIX_APP_PATH;

const useStyles = makeStyles(theme => ({
  logo: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  bmaContent: {
    fontFamily: 'Kanit',
    width: '270px',
    whiteSpace: 'normal',
    [theme.breakpoints.down('xs')]: {
      width: 217,
    },
  },
  imgBmaContent: {
    height: '200px',
    width: '270px',
    [theme.breakpoints.down('xs')]: {
      width: 217,
      height: 160,
    },
  },
  hiddenLine: {
    borderBottom: 0,
    fontSize: 'small',
    whiteSpace: 'normal',
  },
}));
export default function CompTwitter(props) {
  const {
    dataTwitter,
    bindMarker,
  } = props;

  function setIconTwitter(type) {
    const status = TwitterStatusColor(type);
    return status.icon;
  }

  const markTwitter = !isEmpty(dataTwitter) ? dataTwitter.map((data, i) => {
    if (data.lat && data.long) {
      // marker position
      const position = [
        parseFloat(data.lat),
        parseFloat(data.long),
      ];

      // marker icons
      const iconTwit = setIconTwitter(data.tw_source);

      const classes = useStyles();

      return (
        <Marker
          id={data.id}
          key={i}
          position={position}
          icon={iconTwit}
          ref={bindMarker(data.id)}
          classes={classes}
          zIndexOffset={i}
        >
          {/* twitter, สวพ, traffy */}
          <Popup maxWidth={600} minHeight={150} autoPan={false} className={classes.bmaPopup}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLine}
                      padding="none"
                      align="left"
                    >
                      <Box p={1} className={classes.bmaContent}>
                        {data.tw_content}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Box className={classes.bmaContent}>
                        {(data.media_url !== null)
                          ? (
                            <Link href={data.tw_url} target="_blank">
                              <Img
                                src={data.media_url}
                                alt={data.twitter_id}
                                className={classes.imgBmaContent}
                                unloader={(
                                  <Img
                                    src="https://via.placeholder.com/400x400?text=No%20Image"
                                    alt="No Data"
                                    className={classes.imgBmaContent}
                                  />
                                )}
                              />
                            </Link>
                          )
                          : (
                            <Link href={data.tw_url} target="_blank">Click </Link>
                          )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={classes.hiddenLine}
                      padding="none"
                      align="left"
                    >
                      <Box px={1} display="flex" justifyItems="center">
                        {`${data.tw_source}` === 'fm91trafficpro' ? <img src={`${initPath}images/marker/bma/twitter/fm91_25x25.png`} alt="fm91trafficpro" className={classes.logo} /> : null}
                        {`${data.tw_source}` === 'js100radio' ? <img src={`${initPath}images/marker/bma/twitter/js100_25x25.png`} alt="fm91trafficpro" className={classes.logo} /> : null}
                        {`${data.tw_source}` === 'twitter' ? <img src={`${initPath}images/marker/bma/twitter/twitter.png`} alt="twitter" className={classes.logo} /> : null}
                        {`${data.tw_source}` === 'prdoh1' ? <img src={`${initPath}images/marker/bma/twitter/prdoh_25x25.png`} alt="prdoh1" className={classes.logo} /> : null}
                        {`${data.tw_source}` === 'DDPMNews' ? <img src={`${initPath}images/marker/bma/twitter/pm_25x25.png`} alt="DDPMNews" className={classes.logo} /> : null}
                        <div>
                          <strong>{`เวลา : ${moment(data.event_date).format('YYYY-MM-DD HH:mm')}`}</strong>
                          {'ที่มา : '}
                          <a href={`${data.tw_url}`} alt="ที่มา" target="_blank" rel="noreferrer">{`${data.tw_source}`}</a>
                        </div>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`ที่ : ${data.address_text}`}>
              <span>
                <strong>{`เวลา : ${moment(data.event_date).format('YYYY-MM-DD HH:mm')}`}</strong>
                <br />
                {`ที่มา : ${data.tw_source}`}
                <br />
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }) : null;
  return markTwitter;
}

CompTwitter.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTwitter: PropTypes.array.isRequired,
  bindMarker: PropTypes.func.isRequired,
};
