/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '17px',
    width: '20px',
    [theme.breakpoints.up('xs')]: {
      width: '22px',
    },
  },
  label: {
    color: 'white',
    fontSize: '0.7em',
    fontWeight: 400,
    textAlign: 'right',
    marginRight: '-3px',
  },
  detail: {
    color: 'white',
    fontSize: '0.8em',
    fontWeight: 400,
  },
}));

const RainfallLegend = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container alignItems="flex-end" justifyContent="flex-start" wrap="nowrap" spacing={0}>
        <Grid item>
          <Box className={classes.label}>300</Box>
          <Box
            style={{ backgroundColor: '#9931CF' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>200</Box>
          <Box
            style={{ backgroundColor: '#891B1D' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>150</Box>
          <Box
            style={{ backgroundColor: '#CC2723' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>90</Box>
          <Box
            style={{ backgroundColor: '#EE141F' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>70</Box>
          <Box
            style={{ backgroundColor: '#CA6504' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>50</Box>
          <Box
            style={{ backgroundColor: '#FE8A04' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>35</Box>
          <Box
            style={{ backgroundColor: '#F6D300' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>20</Box>
          <Box
            style={{ backgroundColor: '#66C803' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>10</Box>
          <Box
            style={{ backgroundColor: '#9CEEB2' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>5</Box>
          <Box
            style={{ backgroundColor: '#A9CEFC' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box className={classes.label}>1</Box>
          <Box
            style={{ backgroundColor: '#CCE3FF' }}
            className={classes.legend}
          />
        </Grid>
        <Grid item>
          <Box
            style={{ backgroundColor: '#F0F0F0' }}
            className={classes.legend}
          />
        </Grid>
      </Grid>
      <Box className={classes.detail}>ปริมาณน้ำฝน (mm)</Box>
    </div>
  );
};

export default RainfallLegend;
