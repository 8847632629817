import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Block } from '@material-ui/icons';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
    position: 'absolute',
    left: 158,
    width: 220,
    bottom: 36,
    display: 'Block',
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
  },
});

export default function DroughtLegend() {
  const classes = useStyles();

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.8',
          backgroundColor: '#EAEAEA',
          marginLeft: 10,
        }}
      >
        <Box p={1}>
          {/* <Grid container>
            <Grid item><Typography variant="subtitle2"> Fcst Rain (mm) </Typography></Grid>
          </Grid> */}
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#d70004',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> เสี่ยงสูงมาก </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#ee7b4a',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> เสี่ยงสูง </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#f3f024',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> เสี่ยงปานกลาง </Typography></Grid>
             </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#7bc624',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> เสี่ยงต่ำ </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#00920c',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> เสี่ยงต่ำมาก </Typography></Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
