import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
// ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Hidden } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import { FaCalendarAlt, FaRegMap, FaPlayCircle, FaImage } from '../../../utils/Fontawesome';
import SwanLatest from './SwanLatest';
import Modal from '@material-ui/core/Modal';

import Animation from '../../forecast/Animation';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

import { styles } from '../../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class Swan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenImage: false,
      animation: [],
      animationmp4: [],
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,
      open: false,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchDataMp4();
  }

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleModalClose = () => {
    this.setState({ open: false });
  };
  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '50%';
    let heightChart = '99%';

    if (isWidthDown('xs', width)) {
      widthChart = '75%';
      heightChart = '99%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '99%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  };

  handleModalAnimation = (data) => e => {
    e.preventDefault();

    const params = {
      content: (
        <div style={{
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
        }}
        >
          <Animation media={data} />
        </div>),
    };

    this.handleModalOpen(params);
  }

  fetchDataMp4() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/wave_forecast_animation_img?media_type=mp4`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const obj = {
          media: result.data[0].media_path,
          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[0].media_path}`,
          title: `${moment(result.data[0].media_datetime).format('LL')}`,
        };
        this.setState({ animationmp4: obj });
      })
      .catch(error => error);
  }
  fetchData() {
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/wave_forecast_animation_img`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const obj = {
          media: result.data[0].media_path,
          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${result.data[0].media_path}`,
          title: `${moment(result.data[0].media_datetime).format('LL')}`,
        };
        this.setState({ animation: obj });
      })
      .catch(error => error);
  }


  render() {
    const { classes, theme, t } = this.props;
    const { isOpenImage,
      animation,
      animationmp4,
      modalHeader,
      modalSubHeader,
      modalContent,
      open,
    } = this.state;
    return (
      <>
        <Grid
          container
          spacing={0}
          className={classes.boxTopRadius}
          style={{ marginTop: 50, marginBottom: 20 }}
        >
          <Grid
            item
            xs
            style={{ backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 6 }}
          >
            <Box m={1} className={classes.titleBar}>
              <Hidden xsDown>
                {t('แผนภาพคาดการณ์ความสูงและทิศทางคลื่นล่วงหน้า 7 วัน')}
              </Hidden>
              <Hidden smUp><Typography variant="h6">{t('แผนภาพคาดการณ์ความสูงและทิศทางคลื่นล่วงหน้า 7 วัน')}</Typography></Hidden>
            </Box>
          </Grid>
          <Grid item
            xs="auto"
          >
            <Tooltip title="คาดการณ์รายสถานี">
              <Button
                href={`${initPath}forecast/swan/history/forecastByStation`}
                className={classes.outlinedLeftPrimary}
              >
                <FaRegMap />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('คาดการณ์รายสถานี')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item
            xs="auto"
          >
            <Tooltip title="ภาพเคลื่อนไหว">
              <Button
                onClick={() => this.setState({ isOpenImage: true })}
                // onClick={this.handleModalAnimation(animationmp4.media)}
                className={classes.outlinedPrimary}
              >
                <FaImage />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ภาพเคลื่อนไหว')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item
            xs="auto"
          >
            <Tooltip title="วิดีโอ">
              <Button
                // onClick={() => this.setState({ isOpenImage: true })}
                onClick={this.handleModalAnimation(animationmp4.media)}
                className={classes.outlinedPrimary}
              >
                <FaPlayCircle />
                <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('วิดีโอ')}</Typography></Hidden>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item
            xs="auto"
          >
            <Tooltip title="ดูข้อมูลย้อนหลัง">
              <Button
                href={`${initPath}forecast/swan/history`}
                className={classes.outlinedPrimary}
              >
                <FaCalendarAlt />
                {/* <Hidden mdDown><Typography variant="h6" style={{ marginLeft: 8 }}>{t('ดูข้อมูลย้อนหลัง')}</Typography></Hidden> */}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <SwanLatest />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mt={3}>
            {t('แบบจำลอง SWAN พัฒนาขึ้นเพื่อใช้กับบริเวณน้ำตื้น และเหมาะสมกับลักษณะภูมิประเทศของอ่าวไทยที่มีความลึกเฉลี่ย 45 เมตร โดยระบบพยากรณ์คลื่นที่พัฒนาขึ้นนี้ ได้มีการพัฒนาให้เป็นระบบปฏิบัติการและเชื่อมโยงกับแบบจำลองสภาพภูมิอากาศ WRF เพื่อนำข้อมูลคาดการณ์ลมที่ความละเอียดสูงมาใช้เป็นข้อมูลนำเข้าให้กับแบบจำลอง SWAN ให้สามารถพยากรณ์คลื่นล่วงหน้าได้ 7 วัน')}
          </Box>
        </Grid>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleModalClose}
          style={{ align: 'center', justifyContent: 'center' }}
        >
          <div style={this.getModalStyle()} className={classes.paperModal}>
            <Typography variant="h6" id="modal-title">
              {modalHeader}
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              {modalSubHeader}
            </Typography>
            {modalContent}
          </div>
        </Modal>
        {isOpenImage && (
          <Lightbox
            mainSrc={animation.src}
            imageTitle={animation.title}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpenImage: false })}
          />
        )}
      </>
    );
  }
}

Swan.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation(), withStyles(styles, { withTheme: true }))(Swan);
