// https://react.i18next.com/
// https://react.i18next.com/latest/migrating-v9-to-v10
// https://github.com/i18next/i18next-browser-languageDetector
// https://react.i18next.com/latest/i18next-instance

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './translations/en';
import th from './translations/th';
import jp from './translations/jp';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en,
      th,
      jp,
    },
    // lng: 'th',
    fallbackLng: 'th',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
    },
    whitelist: ['en', 'th', 'jp'],
    detection: {
      // order and from where user language should be detected
      order: ['cookie'],

      // keys or params to lookup language from
      lookupCookie: 'i18next',

      // cache user language on
      caches: ['cookie'],

      // optional expire and domain for set cookie
      cookieMinutes: 10,
    },
  });

export default i18n;
