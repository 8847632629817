import React, { Component } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

// color .
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

import ThaiwaterApp from './ThaiwaterApp';

// language
import '../lang/i18n';

const breakpoints = createBreakpoints({});

const opt = {
  xs: 16,
  sm: 16,
  md: 22,
  lg: 24,
};

// custom theme
const theme = createTheme({
  // zIndex: {
  //   modal: 2147483648,
  // },

  breakpoints: {
    xs: 0, sm: 375, md: 768, lg: 1024, xl: 1920,
  },

  palette: {
    primary: {
      light: '#757ce8',
      main: blue[700],
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#757575',
      dark: '#ba000d',
      contrastText: '#000',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
  },
  nested: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  textColorPrimary: {
    color: 'white',
  },
  tooltip: {
    background: '#ff0000',
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Kanit',
      'sans-serif',
    ].join(','),
    fontSize: 14,
    h1: {
      fontFamily: 'Kanit',
      fontSize: 38,
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Kanit',
      fontSize: 34,
    },
    h3: {
      fontFamily: 'Kanit',
      fontWeight: 500,
      fontSize: opt.x,
    },
    h4: {
      fontFamily: 'Kanit',
      fontSize: 26,
    },
    h5: {
      fontFamily: 'Kanit',
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 500,
    },
    kanit: {
      fontFamily: 'Kanit',
    },
    fontWeight: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiTabScrollButton: {
      root: {
        borderTop: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        borderLeft: '1px solid #ccc',
      },
    },
    MuiPopover: {
      paper: {
        left: '0px important',
      },
    },
    MuiContainer: {
      root: {
        width: '100%',
        // paddingLeft: '100px',
        // paddingRight: '100px',
        // '@media (min-width: 600px)': {
        //   paddingLeft: 100,
        // },
      },

    },
    MuiPaper: {
      elevation1: {
        boxShadow: 0,
      },
      elevation4: {
        boxShadow: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        paddingLeft: 10,
      },
    },
    MuiExpansionPanelSummary: {
      '&$expanded': {
      },
    },
    '& .MuiExpansionPanelSummary-root-431.MuiExpansionPanelSummary-expanded-432': {
    },
    '& .MuiBox-root-276': {
      margin: 0,
    },
    '& .MuiSvgIcon-root-76': {
      color: '#000!important',
    },
    MuiTabs: {
      root: {
        width: '100%',
        minWidth: 0,
      },
      indicator: {
        backgroundColor: blue[900],
        color: 'white',
        fontFamily: 'Kanit',
        fontSize: 16,
        fontWeight: 500,
        height: 0,
      },
      flexContainer: {
        marginRight: 1,
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'Kanit',
        fontWeight: 500,
        border: '1px solid #ccc',
        textTransform: 'none',
      },
      textColorInherit: {
        borderBottom: 0,
        borderLeft: 1,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: '0.5px solid rgba(210, 208, 208, 0.42)!important',
      },
      button: {
        [breakpoints.down(1025)]: {
          fontSize: '1vw',
        },
      },
    },

    MuiTableCell: {
      root: {
        padding: '5px 10px 5px 10px',
      },
      head: {
        '&:nth-of-type(1)': {
          paddingLeft: 26,
        },
        whiteSpace: 'nowrap',
        backgroundColor: grey[300],
        fontWeight: 500,
        fontSize: '0.9rem',
      },
      body: {
        color: grey[800],
        whiteSpace: 'nowrap',
        fontFamily: 'sans-serif',
      },
    },

    MuiButton: {
      root: {
        minWidth: 0,
      },
      label: {
        // fontFamily: 'sans-serif',
        textTransform: 'none',
        lineHeight: '18px',
        fontWeight: 400,
        fontSize: '1em',
        '&:hover': {
          color: '#000',
        },
      },
      outlinedPrimary: {
        color: '#1976d2',
        border: '1px solid #1976d2',
        '&:hover': {
          border: '1px solid #000',
          color: '#000',
        },
        '&:visited': {
          backgroundColor: '#1976d2',
          color: '#1976d2',
        },
      },
    },

    CssBaseline: {
      body: {
        backgroundColor: '#fff',
      },
    },
    MuiNativeSelect: {
      outlined: {
        fontSize: 15,
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Kanit',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.8rem',
      },
    },
    MuiFormGroup: {
      root: {
        flexWrap: 'nowrap',
      },
    },

    MuiFormControl: {
      root: {
        margin: '0!important',
        width: '100%',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 'none',
        marginRight: 'none',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '3rem',
      },
      h2: {
        fontSize: '2rem',
      },
      h3: {
        fontSize: '2rem',
      },
      h4: {
        fontSize: '1.5rem',
      },
      h5: {
        fontFamily: 'Kanit',
        fontSize: '1.1rem',
      },
      h6: {
        fontFamily: 'Kanit',
        fontSize: '1rem',
      },
      body2: {
        fontSize: { xs: 12, sm: 12 },
        color: '#333',
      },
      displayInline: {
        color: '#000',
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#fff0',
        boxShadow: 'none',
      },
      // action: {
      //   marginTop: '-90px',
      //   marginLeft: '-35px',
      // },
    },

    // bma new style
    MuiSlider: {
      root: {
        padding: '9px 0px',
      },
      rail: {
        opacity: 1,
        borderRadius: 4,
        height: 5,
        backgroundColor: 'white',
      },
      track: {
        height: 5,
        borderRadius: 4,
      },
      mark: {
        backgroundColor: 'none',
      },
      marked: {
      },
      markLabel: {
        color: '#ccc',
        fontSize: '0.9em',
        marginTop: '-7px',
        [breakpoints.down('sm')]: {
          //marginTop: '-25px',
        },
      },
      markActive: {
        backgroundColor: 'none',
        color: 'white',
      },
      markLabelActive: {
        color: 'white',
        fontSize: '0.9em',
      },
    },
  },

  fontBase: {
    fontSize: 14,
    color: grey[600],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  },
  fontBaseWeight: {
    fontSize: 14,
    color: grey[900],
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
  },
  iconRight: {
    paddingTop: 0,
    right: 0,
    position: 'absolute',
  },
});

class RootContainer extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ThaiwaterApp />
      </MuiThemeProvider>
    );
  }
}

export default RootContainer;
