/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { createRef, Component, memo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import moment from 'moment';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// map
import L from 'leaflet';
import {
  Map, GeoJSON, CircleMarker, Marker, Popup, TileLayer, Tooltip, LayerGroup, LayersControl,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GestureHandling } from 'leaflet-gesture-handling';

// screen width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import deepOrange from '@material-ui/core/colors/deepOrange';
import { FaSpinner, FaChartLine } from '../../../utils/Fontawesome';
import { styles } from '../../../styles/Style';

import weatherContext from '../../../contexts/weatherContext';

// color
import TemperatureStatusColor from './TemperatureStatusColor';

import TemperatureChart from '../../dashboard/TemperatureChart';

import tJson from '../../../utils/TJson';

// css
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

import './styles.scss';

import CurrentLocation from '../../currentlocation/CurrentLocation';
import WeatherListChart from './WeatherListChart';

const initPath = process.env.MIX_APP_PATH;
const { BaseLayer, Overlay } = LayersControl;

const formatValue = value => (typeof value === 'undefined' || value == null ? '-' : parseFloat(value).toFixed(2));

const createClusterCustomIcon = cluster => {
  const markers = cluster.getAllChildMarkers();
  let mean = 0;
  let sum = 0;
  const dataArray = [];
  markers.map(marker => {
    sum += marker.options.value;
    dataArray.push(marker.options.value);
    return sum;
  });
  mean = sum / markers.length;
  // const value = mean;
  const currentMonth = moment().format('M');
  const value = (currentMonth >= 11 || currentMonth <= 2) ? Math.min(...dataArray) : Math.max(...dataArray);

  let colorClass = 'marker-cluster-lite-cold';
  if (value > 40) {
    colorClass = 'marker-cluster-extra-hot';
  } else if (value > 35) {
    // orange
    colorClass = 'marker-cluster-hot';
  } else if (value > 18) {
    colorClass = 'marker-cluster-lite-cold';
  } else if (value > 16) {
    colorClass = 'marker-cluster-little-cold';
    // light blue
  } else if (value > 8) {
    // light navy
    colorClass = 'marker-cluster-cold';
  } else if (value <= 8) {
    // navy
    colorClass = 'marker-cluster-extra-cold';
  }

  // cluster size
  let x = 45;
  let y = 45;
  if (cluster.getChildCount() < 10) {
    x = 40;
    y = 40;
  } else if (cluster.getChildCount() > 100) {
    x = 50;
    y = 50;
  }

  return L.divIcon({
    html: `<div><span>${formatValue(value)}℃</span></div>`,
    className: `leaflet-marker-icon marker-cluster ${colorClass} leaflet-zoom-animated leaflet-interactive`,
    iconSize: L.point(x, y, true),
  });
};

const createClusterCustomIconHumid = cluster => {
  const markers = cluster.getAllChildMarkers();
  let mean = 0;
  let sum = 0;
  const dataArray = [];
  markers.map(marker => {
    sum += marker.options.value;
    dataArray.push(marker.options.value);
    return sum;
  });
  mean = sum / markers.length;
  // const value = mean;
  const currentMonth = moment().format('M');
  const value = (currentMonth >= 11 || currentMonth <= 2) ? Math.min(...dataArray) : Math.max(...dataArray);

  let colorClass = 'marker-cluster-hp-1';
  if (value >= 100) {
    colorClass = 'marker-cluster-hp-7';
  } else if (value > 85) {
    colorClass = 'marker-cluster-hp-6';
  } else if (value > 70) {
    colorClass = 'marker-cluster-hp-5';
  } else if (value > 40) {
    colorClass = 'marker-cluster-hp-4';
  } else if (value > 10) {
    colorClass = 'marker-cluster-hp-3';
  } else if (value > 5) {
    colorClass = 'marker-cluster-hp-2';
  } else {
    colorClass = 'marker-cluster-hp-1';
  }

  // cluster size
  let x = 45;
  let y = 45;
  if (cluster.getChildCount() < 10) {
    x = 40;
    y = 40;
  } else if (cluster.getChildCount() > 100) {
    x = 50;
    y = 50;
  }

  return L.divIcon({
    html: `<div><span>${formatValue(value)}%</span></div>`,
    className: `leaflet-marker-icon marker-cluster ${colorClass} leaflet-zoom-animated leaflet-interactive`,
    iconSize: L.point(x, y, true),
  });
};

const createClusterCustomIconPress = cluster => {
  const markers = cluster.getAllChildMarkers();
  let mean = 0;
  let sum = 0;
  const dataArray = [];
  markers.map(marker => {
    sum += marker.options.value;
    dataArray.push(marker.options.value);
    return sum;
  });
  mean = sum / markers.length;
  // const value = mean;
  const currentMonth = moment().format('M');
  const value = (currentMonth >= 11 || currentMonth <= 2) ? Math.min(...dataArray) : Math.max(...dataArray);

  let colorClass = 'marker-cluster-hp-1';
  if (value >= 1015) {
    colorClass = 'marker-cluster-hp-7';
  } else if (value > 1010) {
    colorClass = 'marker-cluster-hp-6';
  } else if (value > 1008) {
    colorClass = 'marker-cluster-hp-5';
  } else if (value > 1000) {
    colorClass = 'marker-cluster-hp-4';
  } else if (value > 800) {
    colorClass = 'marker-cluster-hp-3';
  } else if (value > 400) {
    colorClass = 'marker-cluster-hp-2';
  } else {
    colorClass = 'marker-cluster-hp-1';
  }

  // cluster size
  let x = 45;
  let y = 45;
  if (cluster.getChildCount() < 10) {
    x = 45;
    y = 45;
  } else if (cluster.getChildCount() > 100) {
    x = 50;
    y = 50;
  }

  return L.divIcon({
    html: `<div style="margin-left: 1px"><span>${formatValue(value)}</span></div><div style="margin-top: -15px"><span>hPa</span></div>`,
    className: `leaflet-marker-icon marker-cluster ${colorClass} leaflet-zoom-animated leaflet-interactive`,
    iconSize: L.point(x, y, true),
  });
};

const createColorMarker = (color) => {
  return L.divIcon({
    html: `
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 20 20"
        style="position: relative; transform: translate(-50%, -50%);"
      >
        <circle
          cx="10"
          cy="10"
          r="8"
          fill="${color}"
          fill-opacity="0.8"
          stroke="blue" stroke-opacity="0.8"
        />
      </svg>
    `,
    iconSize: [0, 0],
  });
};

class WeatherListMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.refMap = createRef();
    this.refBoundaryProvince = createRef();
    this.refBoundaryBasin = createRef();
    this.temperatureLayer = createRef();
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    this.renderCanvas = L.canvas({ padding: 0.5 });

    this.state = {
      error: '',
      height: this.setHeightMap(),
      geojsonBasin: [],
      geojsonProvince: [],

      regionId: props.regionId,
    };

    this.getProvinceStyle = this.getProvinceStyle.bind(this);
    this.getBasinStyle = this.getBasinStyle.bind(this);
    this.getGeoJson = this.getGeoJson.bind(this);
  }

  componentDidMount() {
    const { handleSetMap } = this.props;

    this._mounted = true;

    this.getGeoJson();
    this.map = this.refMap.current.leafletElement; // <= this is the Leaflet Map object
    handleSetMap(this.map);
  }

  shouldComponentUpdate(nextProps) {
    const {
      datas,
      areaType,
      areaId,
      language,
    } = this.props;
    if ((datas === nextProps.datas)
      && (areaType === nextProps.areaType)
      && (areaId === nextProps.areaId)
      && (language === nextProps.i18n.language)) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const { areaType, areaId, listType } = this.props;

    let obj = this.refBoundaryProvince.current.leafletElement._layers;

    // thailand
    if (areaId === '') {
      const { location, zoom } = this.props;
      this.map.setView(location, zoom);

      return;
    }

    if (areaType !== prevProps.areaType || areaId !== prevProps.areaId) {
      if (areaType === '1') {
        // eslint-disable-next-line no-underscore-dangle
        obj = this.refBoundaryBasin.current.leafletElement._layers;
      }

      // get feature
      // eslint-disable-next-line no-underscore-dangle

      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
          // change zoom snap to make fitBounds work

          // province
          if (areaType === '0') {
            const provCode = obj[key].feature.properties.prov_code;
            if (provCode.toString() === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          } else { // basin
            const basinCode = obj[key].feature.properties.BASIN_ID;

            if (basinCode === areaId) {
              this.map.fitBounds(obj[key].getBounds(), { padding: [50, 50] });
            }
          }
        }
      }
    }

  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getGeoJson() {
    Promise.all([
      fetch(`${initPath}json/boundary/thailand.json`),
      fetch(`${initPath}json/boundary/basin.json`),
    ])
      .then(([res2, res3]) => Promise.all([res2.json(), res3.json()]))
      .then(([province, basin]) => {
        this.setState({ geojsonProvince: province, geojsonBasin: basin });
      })
      .catch(error => this.setState({ error }));
  }

  getRiverStyle = () => ({
    weight: 0.8,
    opacity: 1,
    color: '#007DBF',
  })

  getBasinStyle(feature) {
    const { areaType, areaId } = this.props;
    if (areaType === '1' && areaId) {
      if (feature.properties.BASIN_ID === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }

    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  getProvinceStyle(feature) {
    const { areaType, areaId } = this.props;
    if (areaType === '0' && areaId) {
      if (feature.properties.prov_code === areaId) {
        return {
          stroke: true,
          color: deepOrange[700],
          weight: 2,
          fillColor: '#FFF',
          fillOpacity: 0,
        };
      }
    }
    return {
      stroke: false,
      fillColor: '#FFF',
      fillOpacity: 0,
    };
  }

  setHeightMap() {
    const { width } = this.props;
    let heightMap = 600;
    if (isWidthDown('xl', width) && isWidthUp('lg', width)) {
      heightMap = 600;
    } else if (isWidthDown('lg', width) && isWidthUp('md', width)) {
      heightMap = 600;
    } else if (isWidthDown('md', width) && isWidthUp('sm', width)) {
      heightMap = 700;
    } else if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      heightMap = 450;
    }
    return heightMap;
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  // set marker icon based on criteria
  setIcon = val => {
    const status = TemperatureStatusColor(val);

    return status.icon;
  }

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t, listType } = this.props;
    let textDisplay = '';
    if (listType === 'temperature') {
      textDisplay = t('อุณหภูมิ');
    } else if (listType === 'humid') {
      textDisplay = t('ความชื้นสัมพัทธ์');
    } else if (listType === 'pressure') {
      textDisplay = t('ความกดอากาศ');
    }
    const params = {
      header: textDisplay,
      content: <WeatherListChart id={data.station.id} provinceId={data.geocode.province_code} listType={listType} />,
    };
    handler(params);
  }

  // add marker reference
  bindMarker = id => ref => {
    const { markers } = this.props;
    if (ref) {
      markers[id] = ref.leafletElement;
      markers[id].addEventListener('click', e => {
        this.map.panTo(e.target.getLatLng());
      });
    }
  }

  mapLayerControl = () => {
    const { t, datas, listType } = this.props;
    return (
      <LayersControl position="topright">
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Esri_WorldStreetMap">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        {listType === "temperature" && (
          <Overlay key="temperature" name={t(`อุณหภูมิ`)} checked>
            <LayerGroup ref={this.temperatureLayer}>
              <MarkerClusterGroup
                chunkedLoading
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={10}
                showCoverageOnHover
                spiderfyDistanceMultiplier={1}
                removeOutsideVisibleBounds
                iconCreateFunction={createClusterCustomIcon}
              >
                {datas.map(this.eachDataTemp)}
              </MarkerClusterGroup>
            </LayerGroup>
          </Overlay>
        )}

        {listType === "humid" && (
          <Overlay key="humid" name={t(`ความชื้นสัมพัทธ์`)} checked>
            <LayerGroup ref={this.temperatureLayer}>
              <MarkerClusterGroup
                chunkedLoading
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={10}
                showCoverageOnHover
                spiderfyDistanceMultiplier={1}
                removeOutsideVisibleBounds
                iconCreateFunction={createClusterCustomIconHumid}
              >
                {datas.map(this.eachDataHumid)}
              </MarkerClusterGroup>
            </LayerGroup>
          </Overlay>
        )}

        {listType === "pressure" && (
          <Overlay key="pressure" name={t(`ความกดอากาศ`)} checked>
            <LayerGroup ref={this.temperatureLayer}>
              <MarkerClusterGroup
                chunkedLoading
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={10}
                showCoverageOnHover
                spiderfyDistanceMultiplier={1}
                removeOutsideVisibleBounds
                iconCreateFunction={createClusterCustomIconPress}
              >
                {datas.map(this.eachDataPress)}
              </MarkerClusterGroup>
            </LayerGroup>
          </Overlay>
        )}
      </LayersControl>
    );
  }

  eachDataTemp = (data, i) => {
    const {
      // location,
      zoom,
      classes,
      t,
      listType,
      datas,
    } = this.props;

    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
      && data.temperature != null) {
      // status colors

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name);
      }
      let time = data.temperature_datetime;
      let val = data.temperature.toFixed(2)

      const statusColor = TemperatureStatusColor(val);

      return (
        <Marker
        id={data.station.id}
        key={i}
        position={[parseFloat(data.station.tele_station_lat), parseFloat(data.station.tele_station_long)]}
        icon={createColorMarker(statusColor.color.statusColor)}
        ref={this.bindMarker(data.station.id)}
        location={location}
        zoom={zoom}
        datas={datas}
        value={data.temperature}
        classes={classes}
        zIndexOffset={i}

        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {tJson(data.station.tele_station_name, 'สถานี')}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('อุณหภูมิ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{formatValue(val)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {t('หน่วย : (℃)')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {time}
                        {' '}
                        {t('น.')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <weatherContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleChart(handleModalOpen, data)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </weatherContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                <br />
                {`${tJson(data.agency.agency_name, 'หน่วยงาน ')}`}
                <br />
                {`${t('อุณหภูมิ')}: ${formatValue(val)} ℃`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataHumid = (data, i) => {
    const {
      // location,
      zoom,
      classes,
      t,
      listType,
      datas,
    } = this.props;

    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
      && data.humid != null) {
      // status colors

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)} `;
      }
      let time = data.humid_datetime;
      let val = data.humid.toFixed(2)

      let color = '';
      if (val >= 100) {
        color = 'rgba(255, 0, 0, 0.8)';
      } else if (val > 85) {
        color = 'rgba(183, 67, 0, 0.8)';
      } else if (val > 70) {
        color = 'rgba(255, 183, 0, 0.8)';
      } else if (val > 40) {
        color = 'rgba(255, 255, 48, 0.8)';
      } else if (val > 10) {
        color = 'rgba(0, 218, 4, 0.8)';
      } else if (val > 5) {
        color = 'rgba(93, 246, 187, 0.8)';
      } else {
        color = 'rgba(17, 195, 255, 0.8)';
      }

      return (
        <Marker
          id={data.station.id}
          key={i}
          position={[parseFloat(data.station.tele_station_lat), parseFloat(data.station.tele_station_long)]}
          icon={createColorMarker(color)}
          ref={this.bindMarker(data.station.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          value={data.humid}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {tJson(data.station.tele_station_name, 'สถานี')}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ความชื้นสัมพัทธ์')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{formatValue(val)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {t('หน่วย : (%)')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {time}
                        {' '}
                        {t('น.')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <weatherContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleChart(handleModalOpen, data)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </weatherContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                <br />
                {`${tJson(data.agency.agency_name, 'หน่วยงาน ')}`}
                <br />
                {`${t('ความชื้นสัมพัทธ์')}: ${formatValue(val)} %`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  eachDataPress = (data, i) => {
    const {
      // location,
      zoom,
      classes,
      t,
      listType,
      datas,
    } = this.props;

    if (
      data.station.tele_station_lat
      && data.station.tele_station_long
      && data.pressure != null) {
      // status colors

      let locationText = '';
      if (data.geocode.tumbon_name.th !== undefined) {
        locationText = `${tJson(data.geocode.tumbon_name, 'ต.')} `;
      }
      if (data.geocode.amphoe_name.th !== undefined) {
        locationText += `${tJson(data.geocode.amphoe_name, 'อ.')} `;
      }
      if (data.geocode.province_name.th !== undefined) {
        locationText += `${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)} `;
      }
      let time = data.pressure_datetime;
      let val = data.pressure.toFixed(2)

      let color = '';
      if (val >= 1015) {
        color = 'rgba(255, 0, 0, 0.8)';
      } else if (val > 1010) {
        color = 'rgba(183, 67, 0, 0.8)';
      } else if (val > 1008) {
        color = 'rgba(255, 183, 0, 0.8)';
      } else if (val > 1000) {
        color = 'rgba(255, 255, 48, 0.8)';
      } else if (val > 800) {
        color = 'rgba(0, 218, 4, 0.8)';
      } else if (val > 400) {
        color = 'rgba(93, 246, 187, 0.8)';
      } else {
        color = 'rgba(17, 195, 255, 0.8)';
      }

      return (
        <Marker
          id={data.station.id}
          key={i}
          position={[parseFloat(data.station.tele_station_lat), parseFloat(data.station.tele_station_long)]}
          icon={createColorMarker(color)}
          ref={this.bindMarker(data.station.id)}
          location={location}
          zoom={zoom}
          datas={datas}
          value={data.pressure}
          classes={classes}
          zIndexOffset={i}
        >
          <Popup autoPan={false}>
            <div>
              <Table aria-labelledby="Info">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine} padding="none" colSpan={2} align="center">
                      <Typography variant="subtitle2">
                        {tJson(data.station.tele_station_name, 'สถานี')}
                      </Typography>
                      <Typography variant="subtitle2">
                        {locationText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>{t('ความกดอากาศ')}</TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <strong>{formatValue(val)}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {t('หน่วย : (hPa)')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <Typography variant="caption" color="secondary">
                        {time}
                        {' '}
                        {t('น.')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.hiddenLine} align="right">
                      <weatherContext.Consumer>
                        {({ handleModalOpen }) => (
                          <Button
                            color="secondary"
                            onClick={this.handleChart(handleModalOpen, data)}
                            title={t('แสดงกราฟ')}
                            style={{ padding: 0 }}
                          >
                            <FaChartLine className={classes.rightIcon} />
                          </Button>
                        )}
                      </weatherContext.Consumer>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popup>
          <Hidden smDown>
            <Tooltip title={`${tJson(data.station.tele_station_name, 'สถานี')}`}>
              <span>
                <strong>
                  {`${tJson(data.station.tele_station_name, 'สถานี')}`}
                </strong>
                <br />
                {`${tJson(data.geocode.tumbon_name, 'ต.')} ${tJson(data.geocode.amphoe_name, 'อ.')} `}
                {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}
                <br />
                {`${tJson(data.agency.agency_name, 'หน่วยงาน ')}`}
                <br />
                {`${t('ความกดอากาศ')}: ${formatValue(val)} hPa`}
              </span>
            </Tooltip>
          </Hidden>
        </Marker>
      );
    }
    return false;
  }

  render() {
    const {
      error,
      height,
      isLoading,
      geojsonBasin,
      geojsonProvince,
    } = this.state;
    const {
      location, zoom, i18n,
    } = this.props;

    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // preferCanvas = true to render markers on canvas instead of SVG
    const prefercanvas = true;

    return (
      <>
        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={30} />
          </div>
        )}
        <Map
          id="map"
          center={location}
          zoom={zoom}
          zoomSnap={0}
          zoomDelta={0.5}
          ref={this.refMap}
          style={{ height }}
          gestureHandling
          preferCanvas={prefercanvas}
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="OpenStreetMap.HOT"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>ESRI World Street Map</a> contributors"
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonBasin}
            style={this.getBasinStyle}
            ref={this.refBoundaryBasin}
            onEachFeature={this.onEachBasinFeature}
          />
          <GeoJSON
            key={uniqueId()}
            data={geojsonProvince}
            style={this.getProvinceStyle}
            ref={this.refBoundaryProvince}
            onEachFeature={this.onEachProvinceFeature}
          />
          {(i18n.language === 'th') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'en') && (
            this.mapLayerControl()
          )}

          {(i18n.language === 'jp') && (
            this.mapLayerControl()
          )}
          <CurrentLocation mapRef={this.refMap} showOnly={false} themeDL={false} />

        </Map>
      </>
    );
  }
}

WeatherListMap.propTypes = {
  datas: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleSetMap: PropTypes.func.isRequired,
  regionId: PropTypes.string.isRequired,
  markers: PropTypes.array.isRequired,
  location: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  i18n: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withWidth(), withStyles(styles), withTranslation())(memo(WeatherListMap));
