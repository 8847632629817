import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Papa from 'papaparse';
import {
  orderBy, uniqueId,
} from 'lodash';

import { withTranslation } from 'react-i18next';

// HighCharts
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import { FaSpinner } from '../../../utils/Fontawesome';

require('highcharts/modules/annotations')(Highcharts);
// init highcharts module
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class FloodPlainForecastChart extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      name: props.name,
      type: props.type,
      chartStatus: null,
      teleStation: props.teleStation,
      // observeData: [],
      forecastData: [],
      observeData2: [],
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.setState({ isLoading: true });
    this.fetchCsv();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getChartOptionsDischarge(teleStation) {
    const { t } = this.props;
    const timeForecast = new Date();
    timeForecast.setHours(6);
    timeForecast.setMinutes(0);

    this.options = {
      title: {
        text: '',
        style: {
          fontSize: '15px',
        },
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: false,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b}',
        },
        plotLines: [
          {
            label: {
              text: 'Time of Forecast',
            },
            color: '#8A8A8A',
            width: 2,
            value: moment(timeForecast, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf(),
          },
        ],
      },
      yAxis: {
        // Primary yAxis
        opposite: false,
        labels: {
          format: '{value:.0f}',
        },
        title: {
          text: t('ปริมาณน้ำท่า (ลบ.ม./วินาที)'),
        },
        plotLines: [
          {
            label: {
              text: `${t('ความจุต่ำสุด')} 0 ${t('ลบ.ม./วินาที')}`,
            },
            color: '#FFA500',
            width: 2,
            value: 0,
          },
          {
            label: {
              text: `${t('ความจุลำน้ำ')} ${teleStation[10]} ${t('ความจุลำน้ำ')} ${t('ลบ.ม./วินาที')}`,
            },
            color: '#FF0000',
            width: 2,
            value: teleStation[10],
          },
        ],
        plotBands: [
          {
            from: 0,
            to: teleStation[8],
            color: 'rgba(0, 100, 0, 0.2)',
          },
          {
            from: teleStation[8],
            to: teleStation[9],
            color: 'rgba(255, 255, 0, 0.2)',
          },
          {
            from: teleStation[9],
            to: teleStation[10],
            color: 'rgba(255, 128, 0, 0.2)',
          },
          {
            from: teleStation[10],
            to: teleStation[10] + 1000,
            color: 'rgba(255, 0, 0, 0.2)',
          },
        ],
      },
      legend: {
        enabled: true,
      },
      annotationsOptions: {
        enabledButtons: false,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      series: [],
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
            ],
          },
        },
      },
    };

    return this.options;
  }

  getChartOptionsWl(teleStation) {
    const { t } = this.props;
    const timeForecast = new Date();
    timeForecast.setHours(6);
    timeForecast.setMinutes(0);

    this.options = {
      title: {
        text: '',
        style: {
          fontSize: '15px',
        },
      },
      chart: {
        zoomType: 'x',
        renderTo: 'graph',
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
        series: {
          showInNavigator: true,
          gapSize: 6,
        },
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: false,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%b}',
        },
        plotLines: [
          {
            label: {
              text: 'Time of Forecast',
            },
            color: '#8A8A8A',
            width: 2,
            value: moment(timeForecast, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf(),
          },
        ],
      },
      yAxis: {
        // Primary yAxis
        labels: {
          format: '{value:.0f}',
        },
        title: {
          text: t('ระดับน้ำ (ม.รทก.)'),
        },
        plotLines: [
          {
            label: {
              text: `${t('ระดับท้องน้ำ')} ${teleStation[7]} ${t('ม.รทก.')}`,
            },
            color: '#FFA500',
            width: 2,
            value: teleStation[7],
          },
          {
            label: {
              text: `${t('ระดับตลิ่ง')} ${teleStation[10]} ${t('ม.รทก.')}`,
            },
            color: '#FF0000',
            width: 2,
            value: teleStation[10],
          },
        ],
        plotBands: [
          {
            from: 0,
            to: teleStation[7],
            color: 'rgba(224, 224, 224, 0.6)',
          },
          {
            from: teleStation[7],
            to: teleStation[8],
            color: 'rgba(0, 100, 0, 0.2)',
          },
          {
            from: teleStation[8],
            to: teleStation[9],
            color: 'rgba(255, 255, 0, 0.2)',
          },
          {
            from: teleStation[9],
            to: teleStation[10],
            color: 'rgba(255, 128, 0, 0.2)',
          },
          {
            from: teleStation[10],
            to: (teleStation[10] * 1) + 1000,
            color: 'rgba(255, 0, 0, 0.2)',
          },
        ],
      },
      legend: {
        enabled: true,
      },
      annotationsOptions: {
        enabledButtons: false,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      series: [],
    };

    return this.options;
  }

  handleStationChange = event => {
    this.setState({ name: event.target.value }, () => {
      this.fetchCsv();
    });
  };

  getDatas = () => {
    const {
      name, type,
    } = this.state;
    let agencyId;

    // get observe data from db
    if (type === 'wl') {
      agencyId = 9;

      const startDate = moment().subtract(7, 'days').locale('th').format('YYYY-MM-DD');
      const toDate = moment().add(1, 'days').locale('th').format('YYYY-MM-DD');

      // get api2
      fetch(`${process.env.MIX_API_URL}public/waterlevel_graph_oldcode?station_id=${name}&agency_id=${agencyId}&start_date=${startDate}&end_date=${toDate}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          this.setState({
            observeData2: result.data.graph_data,
          }, () => {
            this.resetChart();
            this.renderChart();
          });
        });
    }

    // get observe data from file
    if (type === 'discharge') {
      agencyId = 12;

      fetch(`${process.env.MIX_APP_URL}proxy/txt.php.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/rid_discharge/observe/${name}.txt`)
        .then(response => {
          if (response.ok) {
            return response.text();
          }
          throw new Error('Something went wrong ...');
        })
        .then(result => {
          // pasrse csv to json [station,date,time,value]
          // console.log(Papa.parse(result).data);
          const resultArray = Papa.parse(result).data;
          const jsonFormatData = [];
          resultArray.slice(1).forEach(row => {
            // console.log(row);
            if (!Number.isNaN(row[3])) {
              jsonFormatData.push({
                datetime: `${row[1]} ${row[2]}`,
                value: parseFloat(row[3]),
              });
            }
          });
          // console.log(jsonFormatData);

          // set data state
          this.setState({
            observeData2: jsonFormatData,
          }, () => {
            this.resetChart();
            this.renderChart();
          });
        });
    }
  }

  fetchCsv = () => {
    const { name, type, teleStation } = this.state;
    let files = [];

    if (type === 'wl') {
      files = [
        // eslint-disable-next-line max-len
        // `./proxy/csv.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/hii_waterlevel/observe/${name}.txt`,
        `${process.env.MIX_APP_URL}proxy/txt.php.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/hii_waterlevel/forecast/${name}.txt`,
        // eslint-disable-next-line max-len
        // `./proxy/csv.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/metadata/hii_waterlevel.csv`,
      ];
    } if (type === 'discharge') {
      files = [
        // eslint-disable-next-line max-len
        // `./proxy/csv.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/rid_discharge/observe/${name}.txt`,
        `${process.env.MIX_APP_URL}proxy/txt.php.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/rid_discharge/forecast/${name}.txt`,
        // eslint-disable-next-line max-len
        // `./proxy/csv.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/metadata/rid_discharge.csv`,
      ];
    }

    const promises = files.map(file => fetch(file).then(resp => resp.text()));
    Promise.all(promises)
      .then(results => {
        this.getDatas();
        this.setState({
          // observeData: Papa.parse(results[0]).data,
          forecastData: Papa.parse(results[0]).data,
          teleStation,
        }, () => {
          this.getDatas();
          // this.renderChart();
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  resetChart = () => {
    const chartCount = Highcharts.charts.length - 1;
    const charts = Highcharts.charts[chartCount];
    if (chartCount >= 0 && charts !== undefined) {
      if (charts.xAxis !== undefined) {
        charts.xAxis[0].setExtremes();
      }
    }
  }

  renderChart = () => {
    const { t } = this.props;
    const {
      // observeData,
      observeData2, forecastData, teleStation, type, name,
    } = this.state;

    // transform datas
    const series2 = [];
    const series3 = [];
    const dataSeries = [];
    const tempForecastValue = [];
    const tempObservetValue = [];
    let ts = null;
    let val = null;
    let chartOptions = '';
    let maxS2 = '';
    let maxS3 = '';
    let minS2 = '';
    let minS3 = '';

    // series - forcast data
    if (teleStation) {
      const tele = teleStation.filter(n => n[0] === name);
      const teleStationDatas = tele[0];
      // set chart options
      if (type === 'wl') {
        chartOptions = this.getChartOptionsWl(teleStationDatas);
      } else {
        chartOptions = this.getChartOptionsDischarge(teleStationDatas);
      }

      // check we have forecast data and display status is true
      if (forecastData.length > 0 && teleStationDatas[14] === 't') {
        forecastData.slice(1)
          .filter(n => n[3] !== undefined)
          .map(n => {
            val = ((n[3]) === '' || (n[3]) === null) ? null : (parseFloat(n[3]).toFixed(2)) * 1;

            ts = moment(`${n[1]} ${n[2]}`, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
            series2.push([ts, parseFloat(val)]);
            tempForecastValue.push(val);

            return series2;
          });

        // find max min for forecast data
        maxS2 = Math.max.apply(null, tempForecastValue);
        minS2 = Math.min.apply(null, tempForecastValue);
      }

      // series - observe data
      if (observeData2.length > 0) {
        // ข้อมูลวันที่ใช้จากข้อมูล forecast เป็นหลัก เวลาเริ่มต้นข้อมูล observe ไม่เท่า forecast
        observeData2.slice(1)
          .filter(n => moment(n.datetime) >= moment(`${forecastData[1][1]} ${forecastData[1][2]}`))
          .filter(n => !Number.isNaN(n.value))
          .filter(n => n.value !== null)
          .filter(n => n.datetime.substring(14, 16) === '00')
          .map(n => {
            val = ((n.value) === '' || (n.value) === null) ? null : (parseFloat(n.value).toFixed(2)) * 1;
            // discharge = ((n.discharge) === ''
            //    || (n.discharge) === null) ? null : (parseFloat(n.discharge).toFixed(2)) * 1;

            ts = moment(n.datetime, 'YYYY/MM/DD hh:mm:ss').add(7, 'hours').valueOf();
            series3.push([ts, parseFloat(val)]);
            tempObservetValue.push(val);

            return series3;
          });

        // find max min for observation data
        maxS3 = Math.max.apply(null, tempObservetValue);
        minS3 = Math.min.apply(null, tempObservetValue);
      }

      // calculate min max, if observation is not present not use maxS3 minS3 to calculate
      let max = '';
      let min = '';

      // console.log(`Max Forecast : ${maxS2},
      // Min Forecast : ${minS2}, Max Observe : ${maxS3}, Min Observe :  ${minS3}`);
      max = maxS2 > maxS3 ? maxS2 : maxS3;
      min = minS2 < minS3 ? minS2 : minS3;
      // console.log(max, min);

      const max2 = (teleStationDatas[10] * 1 > max || max === '') ? teleStationDatas[10] * 1 : max;
      const min2 = (teleStationDatas[7] * 1 < min || min === '') ? teleStationDatas[7] * 1 : min;
      // console.log(max2, min2);

      // add chart min max
      if (type !== 'wl') {
        chartOptions.yAxis.max = (max2 * 1) + 1;
        chartOptions.yAxis.min = min2;
      } else {
        chartOptions.yAxis.max = max2;
        chartOptions.yAxis.min = min2;
      }

      // add forecast data to chart
      if (series2.length > 0) {
        dataSeries.push({
          name: t('คาดการณ์'),
          type: 'spline',
          color: '#4682B4',
          data: series2,
        });
      }

      // add observation to chart
      if (series3.length > 0) {
        dataSeries.push({
          name: t('ตรวจวัด'),
          type: 'line',
          dashStyle: 'ShortDot',
          color: '#000000',
          data: series3,
        });
      }

      if (series2.length > 0 || series3.length > 0) {
        chartOptions.title.text = `${teleStationDatas[0]} ${teleStationDatas[2]} ${teleStationDatas[11]} ${teleStationDatas[12]} ${teleStationDatas[13]}`;
        chartOptions.series = dataSeries;

        this.setState({ chartOptions, isLoading: false, chartStatus: true });
      } else {
        this.setState({ chartStatus: false, chartOptions, isLoading: false });
      }
    }
  }

  render() {
    const {
      name,
      chartStatus,
      isLoading,
      error,
      chartOptions,
    } = this.state;
    const { classes, teleStation, t } = this.props;

    if (error) {
      return (
        <div className="text-center">
          {error.message}
        </div>
      );
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    let chart = '';
    let info = '';
    if (chartStatus) {
      chart = <HighchartsReact highcharts={Highcharts} constructorType="chart" options={chartOptions} />;
      info = (
        <div style={{ color: '#A2A2A2' }}>
          {'*'}
          {t('สามารถซูมกราฟได้ โดยคลิกเมาส์ซ้ายค้างลากคลุมบนกราฟในช่วงเวลาที่ต้องการซูม')}
        </div>
      );
    } else {
      chart = <Paper className={classes.paper}>{t('ไม่มีข้อมูล')}</Paper>;
      info = '';
    }

    // console.log('teleStation', teleStation);

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="station-label-placeholder">
                {t('สถานี')}
              </InputLabel>
              <NativeSelect value={name} onChange={this.handleStationChange}>
                {
                orderBy(teleStation, [0], ['asc'])
                  .slice(1)
                  .filter(n => (n[0] !== 'code' && n[0] !== 'undefined' && n[0] !== ' '))
                  .map(row => (
                    <option key={uniqueId()} value={row[0]}>
                      {`${row[0]} ${row[2]} `}
                    </option>
                  ))
                }
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                title={t('แสดง')}
                onClick={() => this.fetchCsv()}
              >
                {t('แสดง')}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {chart}
          </Grid>
          <Grid item xs={12}>
            {info}
          </Grid>
        </Grid>
      </>
    );
  }
}

FloodPlainForecastChart.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  teleStation: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(FloodPlainForecastChart));
