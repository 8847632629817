/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@material-ui/core/Box';
import { styles, lineBox } from '../../styles/Style';

const RainfallLegend3Day = () => (
  <div>
    <Box display="flex" width="100%" justifyContent="center" alignContent="center">
      <Box m={0} pb={0} borderTop={0} border={1} borderColor={lineBox} display="inline" align="center" width="25%">
        <Box display="flex" borderTop={1} borderColor={lineBox}>
          <Box display="inline" style={{ backgroundColor: '#ccc' }} width="33.33%" py={1} />
          <Box display="inline" style={{ backgroundColor: '#cce3ff' }} width="33.33%" py={1} />
          <Box display="inline" style={{ backgroundColor: '#AED3FF' }} width="33.33%" py={1} />
        </Box>
        <Box display="block" pb={0} borderTop={1} borderColor={lineBox} align="center">
          <div className={styles.RainfallLegend} style={{ fontSize: 11 }}>
            ฝนเล็กน้อย
          </div>
        </Box>
      </Box>
      <Box m={0} pb={0} borderTop={0} borderLeft={0} border={1} borderColor={lineBox} display="inline" align="center" width="25%">
        <Box display="flex" borderTop={1} borderColor={lineBox} align="center" width="100%">
          <Box display="inline" style={{ backgroundColor: '#aeecb7' }} width="50%" py={1} />
          <Box display="inline" style={{ backgroundColor: '#7fc63c' }} width="50%" py={1} />
        </Box>
        <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
          <div className={styles.regend} style={{ fontSize: 11 }}>
            ฝนปานกลาง
          </div>
        </Box>
      </Box>
      <Box m={0} pb={0} borderTop={0} borderLeft={0} border={1} borderColor={lineBox} display="inline" align="center" width="25%">
        <Box display="flex" borderTop={1} borderColor={lineBox} align="center" width="100%">
          <Box display="inline" style={{ backgroundColor: '#f0d447' }} width="33.33%" py={1} />
          <Box display="inline" style={{ backgroundColor: '#ef9036' }} width="33.33%" py={1} />
          <Box display="inline" style={{ backgroundColor: '#bd6a27' }} width="33.33%" py={1} />
        </Box>
        <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
          <div className={styles.regend} style={{ fontSize: 11 }}>
            ฝนหนัก
          </div>
        </Box>
      </Box>
      <Box m={0} pb={0} border={1} borderLeft={0} borderColor={lineBox} display="inline" align="center" width="25%">
        <Box display="flex" borderTop={1} borderColor={lineBox} align="center" width="100%">
          <Box display="block" style={{ backgroundColor: '#EE141F' }} py={1} width="25%" />
          <Box display="block" style={{ backgroundColor: '#CC2723' }} py={1} width="25%" />
          <Box display="block" style={{ backgroundColor: '#891B1D' }} py={1} width="25%" />
          <Box display="block" style={{ backgroundColor: '#9931CF' }} py={1} width="25%" />
        </Box>
        <Box display="block" pb={0} borderTop={1} borderColor={lineBox}>
          <div className={styles.regend} style={{ fontSize: 11 }}>
            ฝนหนักมาก
          </div>
        </Box>
      </Box>
    </Box>
  </div>
);

export default RainfallLegend3Day;
