import PropTypes from 'prop-types';
import React, { Component } from 'react';

// lodash
import {
  uniqueId,
} from 'lodash';

// moment
import moment from 'moment';

// material-ui
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// images
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';
import { FaSpinner } from '../../../utils/Fontawesome';

// style, css
import 'react-image-lightbox/style.css';
import { styles } from '../../../styles/Style';

let images = [];
const width = '90%';

class WrfHistoryList extends Component {
  constructor(props, context) {
    super(props, context);
    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      photoIndex: 0,
      isOpen: false,
      imgs: [],
    };
  }

  componentDidMount() {
    const { period, agencyId, mediaTypeId } = this.props;
    // set loading status
    this.setState({
      isLoading: true,
    });
    // get data from ws
    this.fetchData(period, agencyId, mediaTypeId);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.period !== state.period) {
      return {
        period: props.period,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { period, agencyId, mediaTypeId } = this.props;
    if ((prevProps.period.year !== period.year)
      || (prevProps.period.month !== period.month)
      || (prevProps.agencyId !== agencyId)
      || (prevProps.mediaTypeId !== mediaTypeId)) {
      this.fetchData(period, agencyId, mediaTypeId);
    }
  }

  fetchData = (period, agencyId, mediaTypeId) => {
    let selectedMonth = period.month;
    const selectedYear = period.year;

    if (selectedMonth.toString().length < 2) {
      selectedMonth = `0${selectedMonth}`;
    }
    // get data from service
    fetch(`${process.env.MIX_API_URL}public/weather_history_img/month?agency_id=${agencyId}&media_type_id=${mediaTypeId}&year=${selectedYear}&month=${selectedMonth}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({
          imgs: result.data,
          isLoading: false,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    images = [];

    const { classes } = this.props;
    const {
      isLoading,
      error,
      imgs,
      photoIndex,
      isOpen,
    } = this.state;

    return (
      <>
        {error && (
          <div className="text-center">{error.message}</div>
        )}

        {isLoading === true && (
          <div className="text-center">
            <FaSpinner size={35} />
          </div>
        )}
        <Grid container spacing={4} direction="row">
          {imgs.map((row, i) => {
            let realImage = '';

            if (row.media_path_thumb !== null) {
              images.push({
                src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`,
                title: `${row.media_datetime}`,
              });

              realImage = (
                <Button onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                  <Img
                    src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                    width={width}
                    alt={moment(row.media_datetime).format('ll')}
                    className={classes.responsive}
                  />
                </Button>
              );
            } else {
              realImage = (
                <Img
                  src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`}
                  width={width}
                  alt="No Data"
                  className={classes.responsive}
                />
              );
            }

            return (
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                <Box mt={1} p={4} className={classes.boxAccumulatHistory}>
                  <Box display="block" align="center">
                    {realImage}
                  </Box>
                  <Box p={2} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      {moment(row.media_datetime).format('ll')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

WrfHistoryList.propTypes = {
  classes: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  agencyId: PropTypes.string.isRequired,
  mediaTypeId: PropTypes.string.isRequired,
};

export default withStyles(styles)(WrfHistoryList);
