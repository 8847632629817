/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { withLeaflet, MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-kmz';

class GeotiffLayer extends MapLayer {
  // eslint-disable-next-line class-methods-use-this
  createLeafletElement() {
    return L.kmzLayer();
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    const { datas } = this.props;

    const kmz = this.leafletElement.addTo(map);

    const control = L.control.layers(null, null, { collapsed: false, position: 'topleft' }).addTo(map);
    control.addOverlay(kmz, 'Strom Track');

    // eslint-disable-next-line array-callback-return
    datas.map(data => {
      if (data.file) {
        kmz.load(`${process.env.MIX_APP_URL}proxy/kmz.php?file=${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/${data.file}`, data.name);
      }
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export const StormTwcLayer = withLeaflet(props => <GeotiffLayer {...props} />);
