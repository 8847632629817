import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { orderBy } from "lodash";

// ui
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";

// style and icons
import { withStyles } from "@material-ui/core/styles";
import Lightbox from "react-image-lightbox";

import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import tJson from "../../utils/TJson";

import { FaChartLine } from "../../utils/Fontawesome";

// lightbox
import "react-image-lightbox/style.css";
import TableHeaderEnhance from "../widgets/table/TableHeaderEnhance";
import TablePaginationActionsWrapped from "../widgets/table/TablePaginationActions";
import RainfallStatusColor from "./RainfallStatusColor";
import RainfallContext from "../../contexts/rainfallContext";
import RainfallChart from "./RainfallChart";
// import RainfallYearLegend from './RainfallYearLegend';

import { styles, StyledTableRow } from "../../styles/Style";

// table Header
const columnData = [
  {
    id: "station.tele_station_name.th",
    disablePadding: false,
    label: "ชื่อสถานี",
    sortable: true,
    align: "left",
  },
  {
    id: "location",
    disablePadding: false,
    label: "ที่ตั้ง",
    unit: "",
    sortable: false,
    align: "left",
  },
  {
    id: "rainfall_end_date",
    disablePadding: false,
    label: "วันที่",
    sortable: true,
    align: "center",
  },
  {
    id: "rain_5d",
    disablePadding: false,
    label: "ฝนสะสม (มม.)",
    sortable: true,
    align: "center",
  },
  {
    id: "chart",
    disablePadding: false,
    label: "",
    align: "center",
  },
];
class Rainfall5DayList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arrayCrosssectionImage: [],
      photoIndex: 0,
      isOpen: false,
      page: 0,
      rowsPerPage: 10,
      type: "rainfall5day",
      order: "desc",
      orderByState: "rain_5d",
    };
  }

  componentDidMount() {
    this._mounted = true;
    const { datas, handleData } = this.props;
    // console.log('datas', datas);
    handleData(datas, "rainfall5day");
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChart = (handler, data, type, filterData) => (e) => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: `${t("กราฟฝนสะสม 5 วัน")} ${tJson(
        data.station.tele_station_name,
        "สถานี"
      )}`,
      content: (
        <RainfallChart
          filterData={filterData}
          id={data.station.id}
          provinceId={data.geocode.province_code}
          selectedType={type}
          year={(data.rainfall_datetime).substring(0, 4)}
          month={(data.rainfall_datetime).substring(5, 7)}
          sponsored_by={data.sponsored_by}
        />
      ),
    };
    handler(params);
  };

  // for table data change
  handleDataChange = (name, datas) => {
    this.setState({ [name]: datas });
  };

  // get rainfall status
  rainfallStatus = (data, type) => {
    const status = RainfallStatusColor(parseFloat(data).toFixed(1), type);
    return status;
  };

  // for map data change when select filter
  handleFilterChange = (name) => (event) => {
    this.setState({ [name]: event.target.value }, () => {
      const column = event.target.value;
      const { datas, handleData } = this.props;
      let filterData = [];
      if (column.length === 0) {
        filterData = datas;
      } else {
        filterData = datas.filter(
          (value) =>
            column.indexOf(value.geocode.province_name.th) !== -1 ||
            column.indexOf(value.station.basin_name) !== -1 ||
            column.indexOf(
              RainfallStatusColor(value.storage_percent).color.label
            ) !== -1
        );
      }
      this.handleDataChange("rainfall5day", filterData);
      handleData(filterData, "rainfall");
    });
  };

  // for map data change when search from table
  handleSearchChange = (name) => (event) => {
    const searchTerm = event.target.value;
    this.setState({ [name]: event.target.value }, () => {
      const { datas, handleData } = this.props;
      let searchData = [];

      if (searchTerm === "") {
        searchData = datas;
      } else {
        searchData = datas.filter(
          (value) =>
            value.station.tele_station_name.th.indexOf(searchTerm) > -1 ||
            value.geocode.amphoe_name.th.indexOf(searchTerm) > -1 ||
            value.geocode.tumbon_name.th.indexOf(searchTerm) > -1 ||
            value.geocode.province_name.th.indexOf(searchTerm) > -1 ||
            value.station.basin_name.indexOf(searchTerm) > -1 ||
            RainfallStatusColor(value.storage_percent).color.label.indexOf(
              searchTerm
            ) > -1
        );
      }
      this.handleDataChange("rainfall5day", searchData);
      handleData(searchData, "rainfall");
    });
  };

  // for table data change
  handleDataChange = (name, datas) => {
    this.setState({ [name]: datas });
  };

  // for sort table
  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = "desc";

    if (orderByState === property && order === "desc") {
      orderLocal = "asc";
    }

    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  render() {
    const { classes, handleSearchMarker, datas, t } = this.props;

    const {
      photoIndex,
      isOpen,
      arrayCrosssectionImage,
      type,
      order,
      orderByState,
    } = this.state;
    const { rowsPerPage, page } = this.state;

    const sortedData = orderBy(
      datas.map((data) => ({
        ...data,
        rain_5d: parseFloat(data.rain_5d),
      })),
      [orderByState],
      [order]
    );

    return (
      <Fragment key="key">
        <TableContainer>
          <Table className={classes.table}>
            <TableHeaderEnhance
              columnData={columnData}
              order={order}
              orderBy={orderByState}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data) => {
                  let mapLink = tJson(data.station.tele_station_name, "สถานี");
                  if (
                    handleSearchMarker &&
                    data.station.tele_station_lat &&
                    data.station.tele_station_long
                  ) {
                    mapLink = (
                      <Button
                        onClick={handleSearchMarker(
                          data.station.id,
                          data.station.tele_station_lat,
                          data.station.tele_station_long
                        )}
                        title={tJson(data.agency.agency_name, "หน่วยงาน")}
                        style={{ textAlign: "left", paddingLeft: 5 }}
                      >
                        {tJson(data.station.tele_station_name, "สถานี")}
                      </Button>
                    );
                  }
                  const rain = parseFloat(data.rain_5d).toFixed(2);
                  const rainfallStatusC = this.rainfallStatus(
                    rain,
                    "rainfall5day"
                  );
                  return (
                    <StyledTableRow key={`${data.station.id}`}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {mapLink}
                      </TableCell>
                      <TableCell align="left">
                        {`${tJson(data.geocode.tumbon_name, "ต.")}`}{" "}
                        {`${tJson(data.geocode.amphoe_name, "อ.")}`}{" "}
                        {`${tJson(data.geocode.province_name, 'จ.', data.geocode.area_code, data.geocode.area_name)}`}{" "}
                      </TableCell>
                      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                        {moment(new Date(data.rainfall_end_date))
                          .locale("th")
                          .format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          borderRadius={3}
                          style={{
                            align: "center",
                            margin: "0 auto",
                            backgroundColor: rainfallStatusC.color.statusColor,
                            color: rainfallStatusC.color.constrastColor,
                            width: "45px",
                          }}
                        >
                          {parseFloat(data.rain_5d).toFixed(1)}
                        </Box>
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        <RainfallContext.Consumer>
                          {({ handleModalOpen }) => (
                            <Fragment key="key">
                              <Tooltip
                                title={`${tJson(
                                  data.station.tele_station_name,
                                  "สถานี"
                                )} \n ${t("คลิ๊กเพื่อแสดงกราฟ")}`}
                              >
                                <IconButton
                                  className={classes.button}
                                  aria-label="Chart"
                                  onClick={this.handleChart(
                                    handleModalOpen,
                                    data,
                                    type,
                                    datas
                                  )}
                                >
                                  <FaChartLine fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )}
                        </RainfallContext.Consumer>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              {datas.length === 0 && (
                <StyledTableRow>
                  <TableCell colSpan={5} align="center">
                    {t("ไม่มีข้อมูล")}
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={5}
                  count={datas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                  labelRowsPerPage={t("จำนวนแถวต่อหน้า")}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${t("แสดงรายการ")} ${from}-${to}  ${count} ${t("รายการ")}`
                  }
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            {/* <RainfallYearLegend /> */}
          </Grid>
        </TableContainer>
        {isOpen && (
          <Lightbox
            mainSrc={arrayCrosssectionImage[photoIndex]}
            nextSrc={
              arrayCrosssectionImage[
                (photoIndex + 1) % arrayCrosssectionImage.length
              ]
            }
            prevSrc={
              arrayCrosssectionImage[
                (photoIndex + arrayCrosssectionImage.length - 1) %
                  arrayCrosssectionImage.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + arrayCrosssectionImage.length - 1) %
                  arrayCrosssectionImage.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % arrayCrosssectionImage.length,
              })
            }
            reactModalStyle={{
              overlay: { zIndex: "10000000" },
            }}
            imagePadding={85}
          />
        )}
      </Fragment>
    );
  }
}

Rainfall5DayList.propTypes = {
  classes: PropTypes.object.isRequired,
  datas: PropTypes.array.isRequired,
  handleData: PropTypes.func.isRequired,
  handleSearchMarker: PropTypes.func.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation("translations", { withRef: true })
)(Rainfall5DayList);
