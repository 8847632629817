/* eslint-disable prefer-destructuring */
import iconRect from '../widgets/map/iconRect';
import iconCircle from '../widgets/map/iconCircle';

const damColor = [
  {
    label: '',
    rangeTitle: 'ข้อมูลไม่เพียงพอ',
    statusColor: '#bdbdbd',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อยวิกฤต',
    rangeTitle: '<=30',
    statusColor: '#ffc000',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'น้อย',
    rangeTitle: '>30',
    statusColor: '#00b050',
    constrastColor: '#000',
    fontSize: '12px',
  },
  {
    label: 'ปานกลาง',
    rangeTitle: '>50',
    statusColor: '#003cfa',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'มาก',
    rangeTitle: '>80',
    statusColor: '#ff0000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
  {
    label: 'ล้นเขื่อน',
    rangeTitle: '>100',
    statusColor: '#c70000',
    constrastColor: '#fff',
    fontSize: '12px',
  },
];

const DamStatusColor = (wl, type = 'large') => {
  let color;
  let icon;
  let damIcon = iconRect;

  if (type === 'medium') {
    damIcon = iconCircle;
  }

  if (wl > 100) {
    color = damColor[5];
    icon = damIcon.red;
  } else if (wl > 80 && wl <= 100) {
    color = damColor[4];
    icon = damIcon.red;
  } else if (wl > 50 && wl <= 80) {
    color = damColor[3];
    icon = damIcon.blue;
  } else if (wl > 30 && wl <= 50) {
    color = damColor[2];
    icon = damIcon.green;
  } else if (wl <= 30 && wl >= 0) {
    color = damColor[1];
    icon = damIcon.yellow;
  } else {
    color = damColor[0];
    icon = damIcon.grey;
  }

  return { color, icon };
};

export default DamStatusColor;
