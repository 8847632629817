/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  uniqueId, uniqBy, orderBy
} from 'lodash';
import moment from 'moment';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import Modal from '@material-ui/core/Modal';

// table .
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  FaChartLine, FaSpinner,
} from '../../utils/Fontawesome';

// modal
import TableHeaderEnhance from '../widgets/table/TableHeaderEnhance';
import Modal from '../modal/Modal';

import {
  styles, StyledTableRow, BootstrapInput,
} from '../../styles/Style';

import TablePaginationActionsWrapped from '../widgets/table/TablePaginationActions';
import WlContext from '../../contexts/wlContext';
import WaterSitWaterGateMap from './WaterSitWaterGateMap';
import WaterGateChart from '../water_gate/WaterGateChart';

// sidebar
import SidebarWater from '../navigation/SidebarWater';

// icon .
import { HiiWaterGateIcon } from '../../utils/Icon';
import tJson from '../../utils/TJson';

// table Header
const columnData = [
  {
    id: 'title', align: 'left', disablePadding: true, label: 'สถานี', sortable: true,
  },
  {
    id: 'geocode.tumbon_name.th', align: 'left', disablePadding: false, label: 'ตำบล', sortable: true,
  },
  {
    id: 'geocode.amphoe_name.th', align: 'left', disablePadding: false, label: 'อำเภอ', sortable: true,
  },
  {
    id: 'geocode.province_name.th', align: 'left', disablePadding: false, label: 'จังหวัด', sortable: true,
  },
  {
    id: 'watergate_in', align: 'right', disablePadding: false, label: 'ระดับน้ำเหนือ', unit: '(ม.รทก)', sortable: true,
  },
  {
    id: 'watergate_out', align: 'right', disablePadding: false, label: 'ระดับน้ำท้าย', unit: '(ม.รทก)', sortable: true,
  },
  {
    id: 'datetime', align: 'center', disablePadding: false, label: 'เวลา', sortable: false,
  },
  {
    id: 'chart', align: 'center', disablePadding: false, label: 'กราฟ',
  },
];

class WaterSitWaterGate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error

      datas: [],
      page: 0,
      rowsPerPage: 10,
      dataCondition: [],
      dataProvince: [],
      dataBasin: [],
      areaText: 'ทั่วประเทศ',
      agencyName: 'ทุกหน่วยงาน',
      dataAgency: [],

      // for search
      dataInit: [],
      dataInitLastest: [],
      searchData: [],
      searchTerm: '',

      // map
      map: null,
      zoom: 5.55,
      location: [12.9, 101.6],
      markers: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,

      // radio group
      areaType: '0',
      areaId: '',

      //agency select
      agencyId: '',

      order: 'asc',
      orderByState: 'geocode.province_name.th',

      modalData: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
      modalDataReset: {
        open: false,
        header: '',
        subheader: null,
        content: null,
      },
    };
  }

  componentDidMount() {
    // set loading status
    this.setState({ isLoading: true });

    // get data from ws
    this.getData();
  }

  getData = () => {
    fetch(`${process.env.MIX_API_URL}public/watergate_load`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 1);
        const filterDatas = result.watergate_data.data.filter(d => {
          const time = new Date(d.watergate_datetime_in.replace(/-/g, '/')).getTime();
          return last24hrs < time && d.watergate_in != null;
        });
        // basin and province dropdown
        let dataProvince = filterDatas
          .filter(d => d.geocode.province_name.th !== undefined)
          .map(d => ({
            id: d.geocode.province_code,
            name: d.geocode.province_name.th,
          }));
        dataProvince = uniqBy(dataProvince.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataBasin = filterDatas
          .filter(d => d.basin.basin_name.th !== undefined)
          .map(d => ({
            id: d.basin.basin_code,
            name: d.basin.basin_name.th,
          }));
        dataBasin = uniqBy(dataBasin.sort((a, b) => a.name.localeCompare(b.name, 'th')), 'id');

        let dataAgency = filterDatas
        .map(d => ({
          id: d.agency.id,
          name: d.agency.agency_name,
        }));
        dataAgency = uniqBy(dataAgency.sort((a, b) => a.name.th.localeCompare(b.name.th, 'th')), 'id');
        
        // set state
        this.setState({
          isLoading: false,
          dataInit: filterDatas,
          dataInitLastest: filterDatas,
          datas: filterDatas,
          dataProvince,
          dataBasin,
          dataAgency,
          latestDate: this.getMaxDate(filterDatas),
          dataCondition: dataProvince,
          searchData: filterDatas,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    const modalData = { ...this.state.modalData };
    modalData.open = true;
    modalData.header = params.header;
    modalData.subheader = null;
    modalData.content = params.content;
    this.setState({ modalData });
  }

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;
    const params = {
      header: t('กราฟระดับน้ำที่ปตร./ฝาย'),
      content: <WaterGateChart id={data.station.id} provinceId={data.geocode.province_code} />,
    };
    handler(params);
  }

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.watergate_datetime_in.replace(/-/g, '/')));
      const maxdate = new Date(Math.max.apply(null, dates));
      return `${moment(maxdate).format('YYYY-MM-DD')} เวลา : ${moment(maxdate).format('HH:mm')}`;
    }
    return moment().format('YYYY-MM-DD');
  };

  handleRequestSort = (event, property) => {
    const { order, orderByState } = this.state;
    const orderByProperty = property;
    let orderLocal = 'desc';

    if (orderByState === property && order === 'desc') {
      orderLocal = 'asc';
    }

    this.setState({ order: orderLocal, orderByState: orderByProperty });
  };

  handleAreaTypeChange = (event, value) => {
    const { dataProvince, dataBasin, dataInit } = this.state;
    const { t } = this.props;
    let dataCondition = dataProvince;
    let areaText = t('ทั่วประเทศ');
    if (value === '1') {
      dataCondition = dataBasin;
      areaText = t('ทุกลุ่มน้ำ');
    }

    this.setState({
      areaType: value,
      dataCondition,
      areaId: '',
      areaText,
      dataInitLastest: dataInit,
      searchData: dataInit,
    }, () => {
      this.filterDatas();
    });
  };

  handleSearchChange = event => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm }, () => {
      this.filterDatas();
    });
  };

  handleFilterAreaChange = areaId => event => {
    this.setState({ [areaId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  handleFilterAgencyChange = agencyId => event => {
    this.setState({ [agencyId]: event.target.value }, () => {
      this.filterDatas();
    });
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '80%';
    let heightChart = '60%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '50%';
      heightChart = '65%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  resetModal = () => {
    const { modalDataReset } = this.state;
    this.setState({ modalData: modalDataReset });
    const modalData = { ...this.state.modalData };
    modalData.open = false;
    this.setState({ modalData });
  }

  filterDatas() {
    const {
      searchTerm, areaId, areaType, dataInit, rowsPerPage, page, agencyId,
    } = this.state;

    const searchData = dataInit
      .filter(d => {
        if (searchTerm !== '') {
          if(!(tJson(d.station.tele_station_name, 'สถานี').indexOf(searchTerm) > -1 
              || d.geocode.amphoe_name.th.indexOf(searchTerm) > -1
              || d.geocode.tumbon_name.th.indexOf(searchTerm) > -1
              || d.geocode.province_name.th.indexOf(searchTerm) > -1
              || d.station.tele_station_oldcode.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1
            )){
            return false
          }
        }
        if (areaId !== '') {
          if(areaType === '1' ? !(d.basin.basin_code.toString() === areaId) : !(d.geocode.province_code === areaId)){
            return false
          }
        }
        if (agencyId !== '') {
          if(!(d.agency.id.toString() === agencyId)){
            return false
          }
        }
        return d;
      });

    const pageCurrent = (searchData.length < rowsPerPage) ? 0 : page;

    this.setState({
      searchData,
      page: pageCurrent,
    });
  }

  render() {
    const {
      classes, theme, t, i18n,
    } = this.props;
    const {
      isLoading, error, datas, latestDate, dataCondition,
      searchData, modalData, rowsPerPage, page, order, orderByState,
      areaType, areaId, areaText, dataAgency, agencyName, agencyId,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, datas.length - page * rowsPerPage);

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinner
    if (isLoading) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <WlContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          {/* Sidebar  */}
          <SidebarWater />

          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs>
                <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
                  <Box display="flex" pb={0} mb={0}>
                    <Typography variant="h6" gutterBottom>
                      {t('ติดตามสถานการณ์น้ำ')}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={5} lg={5}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" p={1} alignItems="center">
                    <HiiWaterGateIcon fontSize={24} />
                  </Box>
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('ระดับน้ำที่ ปตร./ฝาย')}
                    </Typography>
                  </Box>
                </Box>
                {/* Map */}
                <Box mt={2} width="100%">
                  <WaterSitWaterGateMap
                    {...this.state}
                    datas={searchData}
                    handleSetMap={this.handleSetMap}
                    language={i18n.language}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                <Box mt={3} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41 }}>
                      <Box>
                        <FormControl variant="outlined" component="fieldset">
                          <RadioGroup aria-label="position" id="position" name="position" value={areaType} onChange={this.handleAreaTypeChange} row>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลจังหวัด')}</Typography>}
                              labelPlacement="end"
                              fontFamily="Kanit"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลลุ่มน้ำ')}</Typography>}
                              labelPlacement="end"
                              style={{ height: 31, fontFamily: 'Kanit' }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <FormControl variant="outlined" className={classes.formControl} width="100%">
                        <NativeSelect
                          id="select"
                          value={areaId}
                          onChange={this.handleFilterAreaChange('areaId')}
                          input={(
                            <BootstrapInput
                              className={classes.outLinedRadius}
                              style={{ paddingLeft: 10 }}
                            />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit' }}>{t(areaText)}</option>
                          {dataCondition.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                  <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                    <FormControl variant="outlined">
                      <NativeSelect
                        id="agencySelect"
                        value={agencyId.toString()}
                        onChange={this.handleFilterAgencyChange('agencyId')}
                        input={(
                          <BootstrapInput
                            className={classes.outLinedRadius}
                            style={{ paddingLeft: 10 }}
                          />
                          )}
                        >
                          <option value="" style={{ fontFamily: 'Kanit', fontSize: 14 }}>{t(agencyName)}</option>
                          {dataAgency.map(val => (
                            <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{tJson(val.name, 'หน่วยงาน')}</option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                  </Box>
                </Grid>
                  <Grid item xs={12} sm={5}>
                    <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                      <Paper className={classes.rootSearch} elevation={0}>
                        <InputBase
                          display="flex"
                          className={classes.inputSearch}
                          placeholder={t('ค้นหา')}
                          id="search"
                          onChange={this.handleSearchChange}
                        />
                        <IconButton className={classes.iconButton} aria-label="Search">
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box mt={2} />
                    <TableContainer component={Paper}>
                      <Table className={[classes.table, 'table-custom-sticky'].join(' ')}>
                        <TableHeaderEnhance
                          columnData={columnData}
                          order={order}
                          orderBy={orderByState}
                          onRequestSort={this.handleRequestSort}
                          firstColumnSticky
                        />
                        <TableBody>
                          {orderBy(searchData, orderByState, order)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(row => {
                              let title = tJson(row.station.tele_station_name, 'สถานี');
                              if (row.agency.agency_name.th !== undefined) {
                                title = tJson(row.agency.agency_name, 'หน่วยงาน');
                              }
                              const station = (tJson(row.station.tele_station_name, 'สถานี')).split(' ');
                              let stationList = tJson(row.station.tele_station_name, 'สถานี');
                              if (station.length > 1 && station[0].search('สถานีโทรมาตร') > -1) {
                                stationList = station[1];
                              }
                              if (stationList === '') {
                                stationList = tJson(row.station.tele_station_name, 'สถานี');
                              }
                              let mapLink = tJson(row.station.tele_station_name, 'สถานี');

                              if (
                                this.handleSearchMarker
                                && row.station.tele_station_lat
                                && row.station.tele_station_long
                              ) {
                                mapLink = (
                                  <Button
                                    style={{ textAlign: 'left' }}
                                    onClick={this.handleSearchMarker(
                                      row.station.id,
                                      row.station.tele_station_lat,
                                      row.station.tele_station_long,
                                    )}
                                    title={title}
                                  >
                                    {stationList}
                                  </Button>
                                );
                              }
                              return (
                                <StyledTableRow key={uniqueId()}>
                                  <TableCell component="th" scope="row" align="left" className="first-column-sticky">
                                    <Typography variant="body2">
                                      {mapLink}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="left">
                                    {row.geocode.tumbon_name.th !== undefined ? tJson(row.geocode.tumbon_name, 'ต.') : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="left">
                                    {row.geocode.amphoe_name.th !== undefined ? tJson(row.geocode.amphoe_name, 'อ.') : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="left">
                                    {row.geocode.province_name.th !== undefined ? tJson(row.geocode.province_name, 'จ.') : ''}
                                  </TableCell>
                                  <TableCell align="right">
                                    {(row.watergate_in === null) ? '' : parseFloat(row.watergate_in).toFixed(2)}
                                  </TableCell>
                                  <TableCell align="right">
                                    {(row.watergate_out === null) ? '' : parseFloat(row.watergate_out).toFixed(2)}
                                  </TableCell>
                                  {/* <TableCell  align="center">
                                      น้ำระบาย
                                  </TableCell >
                                  <TableCell  align="center">
                                      Qmax
                                  </TableCell > */}
                                  <TableCell align="center">{moment(row.watergate_datetime_out).locale('th').format('HH:MM')}</TableCell>
                                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    <WlContext.Consumer>
                                      {({ handleModalOpen }) => (
                                        <>
                                          <Tooltip title="แสดงกราฟ">
                                            <IconButton className={classes.button} aria-label="Chart" onClick={this.handleChart(handleModalOpen, row)}>
                                              <FaChartLine fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </WlContext.Consumer>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <StyledTableRow style={{ height: 49 * emptyRows }}>
                              <TableCell colSpan={8} />
                            </StyledTableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <StyledTableRow />
                        </TableFooter>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      className={classes.hiddenLine}
                      count={datas.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                      labelRowsPerPage={`${t('จำนวนแถวต่อหน้า')}:`}
                      labelDisplayedRows={({ from, to, count }) => `${t('แสดงรายการ')} ${from}-${to} ${t('จาก')} ${count} ${t('รายการ')}`}
                    />
                    <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                      {`${t('ล่าสุด')} ${latestDate} น.`}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Modal
                open={modalData.open}
                modalHeader={modalData.header}
                modalContent={modalData.content}
                resetModal={this.resetModal}
              />
            </Grid>
          </div>
        </WlContext.Provider>
      </div>
    );
  }
}

WaterSitWaterGate.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  i18n: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(WaterSitWaterGate);
