import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

// ui, styles //
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

// icon
import { FaHome } from '../../utils/Fontawesome';
import { HiiPresentIcon } from '../../utils/Icon';

import {
  styles,
} from '../../styles/Style';

const initPath = process.env.MIX_APP_PATH;

class IframeReport extends Component {
  urlDecode = url => Buffer.from(url, 'base64').toString('ascii');

  render() {
    const { classes, t } = this.props;
    const { match: { params: { year, url } } } = this.props;

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Button href={`${initPath}report`}>
                  <FaHome fontSize="small" style={{ marginRight: 5 }} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t('รายงานสถานการณ์')}
                  </Typography>
                </Button>
                <Button href={`${initPath}report/yearly/history`}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t('รายปี')}
                  </Typography>
                </Button>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t(('รายงานสถานการณ์น้ำประเทศไทย ปีพุทธศักราช'))}
                  {' '}
                  {year}
                </Typography>
              </Breadcrumbs>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs>
              <Box mt={2} mb={0} pb={0} style={{ width: '100%' }}>
                <Box display="flex" pb={0} mb={0}>
                  <Box display="flex" width="100%">
                    <Box display="inline" pt={1}>
                      <HiiPresentIcon fontSize={24} />
                    </Box>
                    <Box display="inline" pt={1} pl={1}>
                      <Typography variant="h4" gutterBottom>
                        {t('รายงานสถานการณ์น้ำ')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            style={{
              position: 'relative',
              paddingBottom: '50%' /* 16:9 */,
              paddingTop: 25,
              marginTop: 25,
              height: 0,
            }}
          >
            {/* <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              src={this.urlDecode(url)}
              frameBorder="0"
              title="ViewIframe"
            /> */}
            <Link href={this.urlDecode(url)}>{t('กดเพื่อดูข้อมูล')}</Link>
          </Box>
        </div>
      </>
    );
  }
}

IframeReport.defaultProps = {
  match: {
    params: {
      year: '',
      url: '',
    },
  },
};

IframeReport.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      year: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withStyles(styles, { withTheme: true })(IframeReport));
