import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import moment from 'moment';

// ui, styles
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// dam
import DamMap from './DamMap';
import DamList from './DamList';
import DamLegend from './DamLegend';
import DamDailyChart from './DamDailyChart';

import { styles, BootstrapInput } from '../../styles/Style';
import tJson from '../../utils/TJson';

class DamLatest extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      search: '',
      searchDatasDamDaily: [],
      searchDatasDamMedium: [],
      dataCondition: [],

      // map
      map: null,
      zoom: 5.75,
      location: [12.9, 101.6],
      markers: [],
      // currentBounds: null,

      // modal
      // open: false, // modal
      // modalHeader: null,
      // modalSubHeader: null,
      // modalContent: null,

      // matrix, user not used now
      // totalMainDamUseWater: 0,
      // datasMainDam: [],

      // radio group
      areaType: '0',
      areaId: '',

      // trext
      allRegionTxt: 'ทั่วประเทศ',
    };

    this.handleSearchMarker = this.handleSearchMarker.bind(this);
    this.handleSetMap = this.handleSetMap.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.filterDatasDamDaily !== state.datasDamDaily) {
      return {
        datasDamDaily: props.filterDatasDamDaily,
        searchDatasDamDaily: props.filterDatasDamDaily,
        searchDatasDamMedium: props.filterDatasDamMedium,
        dataCondition: props.dataProvince,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleAreaTypeChange = (event, value) => {
    const { dataProvince, dataBasin, t } = this.props;
    // console.log(dataProvince, dataBasin);

    let dataCondition = dataProvince;
    let txt = t('ทั่วประเทศ');

    // set to current default data
    this.setState({ [value]: event.target.value }, () => {
      this.filterDatas();
    });

    if (value === '1') {
      dataCondition = dataBasin;
      txt = t('ทุกลุ่มน้ำ');
    }

    this.setState({
      areaType: value,
      dataCondition,
      areaId: '',
      allRegionTxt: txt,
    });
  };

  handleSearchChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      this.filterDatas();
    });
  };

  handleFilterAreaChange = areaId => event => {
    this.setState({ [areaId]: event.target.value }, () => {
      this.filterDatas();
    });

    // const column = event.target.value;
    // const { dataInit } = this.state;
    // let filterData = [];

    // if (column.length === 0) {
    //   filterData = dataInit;
    // } else {
    // filterData = dataInit.filter(
    //   value => column.indexOf(value.geocode.province_name.th) !== -1
    //   || column.indexOf(value.basin.basin_name.th) !== -1,
    // );
    // }

    // this.handleDataChange('datas', filterData);
  }

  handleChart = (handler, data) => e => {
    e.preventDefault();
    const { t } = this.props;

    const params = {
      header: `${t('กราฟอ่างเก็บน้ำรายวัน กรมชลประทาน')}`,
      // subheader: `อ.${data.geocode.province_name.th} ต.${data.geocode.tumbon_name.th}`,
      content: <DamDailyChart id={parseInt(data.dam_id, 10)} agencyId={12} />,
    };

    handler(params);
  }

  filterDatas = () => {
    const { search, areaType, areaId } = this.state;
    // console.log(search, areaType, areaId);
    const { filterDatasDamDaily, filterDatasDamMedium } = this.props;

    const searchDatasDamDaily = filterDatasDamDaily
      .filter(d => {
        // search by keyword
        // check search ว่ามีคำว่าเขื่อน ในช่อง search ไหม แล้วให้แสดงผลการ search ตามเงื่อนไขที่กำหนด

        if (search !== '') {
          if (areaId !== '') {
            if (areaType === '1') {
              return tJson(d.dam.dam_name, 'เขื่อน').indexOf(search) > -1
                && String(d.basin.basin_code) === areaId;
            }
            return tJson(d.dam.dam_name, 'เขื่อน').indexOf(search) > -1
                && d.geocode.province_code === areaId;
          }
          return tJson(d.dam.dam_name, 'เขื่อน').indexOf(search) > -1;
        }

        // search by area type
        if (areaId !== '') {
          // by basin
          if (areaType === '1') {
            // console.log('Basin Code - ' + d.basin.basin_code);
            // console.log('Selected Area ID - ' + areaId);
            return d.basin.basin_code.toString() === areaId;
          }

          // by province
          return d.geocode.province_code === areaId;
        }
        return d;
      });

    const searchDatasDamMedium = filterDatasDamMedium
      .filter(d => {
        // search by keyword
        if (search !== '') {
          if (areaId !== '') {
            if (areaType === '1') {
              return d.dam.dam_name.th.indexOf(search) > -1
                && String(d.basin.basin_code) === areaId;
            }

            return d.dam.dam_name.th.indexOf(search) > -1
                && d.geocode.province_code === areaId;
          }

          return d.dam.dam_name.th.indexOf(search) > -1;
        }

        // search by area type
        if (areaType !== '' && areaId !== '') {
          // by basin
          if (areaType === '1') {
            return String(d.basin.basin_code) === areaId;
          }

          // by province
          return d.geocode.province_code === areaId;
        }
        return d;
      });

    this.setState({
      searchDatasDamDaily,
      searchDatasDamMedium,
    });
  }

  render() {
    const {
      searchDatasDamDaily,
      searchDatasDamMedium,
      areaType,
      areaId,
      dataCondition,
      allRegionTxt,
      zoom,
      location,
      markers,
      // totalMainDamUseWater,
      // datasMainDam,
    } = this.state;

    const {
      classes,
      theme,
      latestDateRid,
      damsMediumRidList,
      i18n,
      t,
    } = this.props;
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Paper>
              <DamMap
                location={location}
                zoom={zoom}
                areaType={areaType}
                areaId={areaId}
                markers={markers}
                datasLargeDam={searchDatasDamDaily}
                datasMediumDam={searchDatasDamMedium}
                damsMediumRidList={damsMediumRidList}
                handleSetMap={this.handleSetMap}
                language={i18n.language}
              />
            </Paper>

            <DamLegend />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center" className={classes.outLinedRadius} style={{ height: 41 }}>
                  <Box>
                    <FormControl variant="outlined" component="fieldset">
                      <RadioGroup aria-label="position" name="position" value={areaType} onChange={this.handleAreaTypeChange} row>
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลจังหวัด')}</Typography>}
                          labelPlacement="end"
                          fontFamily="Kanit"
                          style={{ height: 31, fontFamily: 'Kanit' }}
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label={<Typography style={{ fontFamily: 'Kanit', fontSize: 14 }} fontSize={15}>{t('ข้อมูลลุ่มน้ำ')}</Typography>}
                          labelPlacement="end"
                          style={{ height: 31, fontFamily: 'Kanit' }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                  <FormControl variant="outlined" className={classes.formControl} width="100%">
                    <NativeSelect
                      id="select"
                      value={areaId}
                      onChange={this.handleFilterAreaChange('areaId')}
                      input={(
                        <BootstrapInput
                          className={classes.outLinedRadius}
                          style={{ paddingLeft: 10 }}
                        />
                        )}
                    >
                      <option value="" style={{ fontFamily: 'Kanit' }}>
                        {t(allRegionTxt)}
                      </option>
                      {dataCondition.map(val => (
                        <option key={uniqueId()} value={val.id} style={{ fontFamily: 'Kanit' }}>{t(val.name)}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box fontFamily="Kanit" display="flex" justifyContent="center" alignItems="center">
                  <Paper className={classes.rootSearch} elevation={0}>
                    <InputBase
                      id="search"
                      display="flex"
                      className={classes.inputSearch}
                      placeholder={t('ค้นหา')}
                      onChange={this.handleSearchChange('search')}
                    />
                    <IconButton className={classes.iconButton} aria-label="Search">
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box mt={2} />
              <Paper>
                <DamList
                  datasLargeDam={searchDatasDamDaily}
                  datasMediumDam={searchDatasDamMedium}
                  damsMediumRidList={damsMediumRidList}
                  handleSearchMarker={this.handleSearchMarker}
                />
              </Paper>

              <div style={{ textAlign: 'right' }}>
                <Box width="100%" p={1} align="right" fontSize="small" fontFamily="Kanit" color={theme.palette.secondary.main}>
                  {`${t('ข้อมูลล่าสุดวันที่')} ${moment(latestDateRid).locale('th').format('DD MMM YYYY')} ${t('(หน่วย ล้าน ลบ.ม.)')}`}
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

DamLatest.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  filterDatasDamDaily: PropTypes.array.isRequired,
  filterDatasDamMedium: PropTypes.array.isRequired,
  latestDateRid: PropTypes.string.isRequired,
  dataProvince: PropTypes.array.isRequired,
  dataBasin: PropTypes.array.isRequired,
  damsMediumRidList: PropTypes.array.isRequired,
  i18n: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }), withTranslation())(DamLatest);
