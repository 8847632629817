/**
 * *
 * @version 1.0.0
 * @author [Chakat Sae-choen]
 * Modified by  [Thitiporn Meeprasert]
 */

import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: '#061E2C',
    color: 'white',
    width: '100%',
    padding: 10,
  },
  label: {
    fontSize: '1em',
    fontWeight: 300,
  },
});

const CompRadarSelect = props => {
  const classes = useStyles();

  const { handletimeLineStatus, timelineStatus, handleMenuPopupClose } = props;

  const handleClick = event => {
    handletimeLineStatus(event.target.value);
    handleMenuPopupClose();
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="radar" name="radar" value={timelineStatus}>
          <FormControlLabel value="3" control={<Radio onClick={handleClick} style={{ color: timelineStatus === '3' ? '#3980c0' : '#ccc' }} />} label={<Box fontWeight={300}>เรดาร์ตรวจวัดและคาดการณ์ฝน</Box>} className={classes.label} />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

CompRadarSelect.propTypes = {
  handletimeLineStatus: PropTypes.func.isRequired,
  timelineStatus: PropTypes.string.isRequired,
  handleMenuPopupClose: PropTypes.func.isRequired,
};

export default CompRadarSelect;
