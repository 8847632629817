/**
 * *
 * @version 1.0.0
 * @author [Thitiporn Meeprasert]
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import moment from 'moment';

// ui, styles
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import { Hidden } from '@material-ui/core';

// table
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

// translate
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import tJson from '../../utils/TJson';

// icon

// credit tooltip
import CreditTooltip from '../../utils/CreditTooltip';

import {
  FaCalendarAlt,
} from '../../utils/Fontawesome';
import { HiiStormIcon } from '../../utils/Icon';

import {
  styles, LegendTableCell,
} from '../../styles/Style';

// components
import StormJtwc from '../storm_history/StormJtwc';

import imageSource from '../../../../public/json/contents/image_source.json';

const initPath = process.env.MIX_APP_PATH;

class Storm extends Component {
  constructor(props) {
    super(props);

    this.bounds = [];
    this.state = {
      datas: [],
      isOpenCollapse: false,
    };
  }

  componentDidMount() {
    this._mounted = true;

    // get data from ws
    this.setData();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setData() {
    const { dataStorm } = this.props;
    try {
      if (dataStorm.data !== undefined) {
        this.setState({
          datas: dataStorm.data.data.college,
        });
      }
      return '';
    } catch (error) {
      return '';
    }
  }

  handleClick = () => {
    const { isOpenCollapse } = this.state; // classes
    if (isOpenCollapse === false) {
      this.setState({ isOpenCollapse: true });
    } else {
      this.setState({ isOpenCollapse: false });
    }
  }

  render() {
    const { classes, t } = this.props;
    const { datas, isOpenCollapse } = this.state; // classes
    return (
      <Fragment key="key">
        <Box height={{ xs: 20, md: 40 }} mt={{ xs: 4, md: 4, sm: 4 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            <Box display="flex" pb={0} style={{ width: '100%' }}>
              <Box>
                <HiiStormIcon />
              </Box>
              <Box pl={1}>
                <span style={{ fontSize: 26, fontFamily: 'Kanit' }}>
                  {t('เส้นทางพายุ')}

                </span>
                {/* <span style={{ fontSize: 16, fontFamily: 'Kanit' }}> เส้นทางพายุ</span> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
              <Button
                aria-haspopup="true"
                onClick={this.handleClick}
                variant="outlined"
                size="small"
                color="default"
                align="center"
                // className={classes.button}
                style={{ fontFamily: 'Kanit', fontSize: 12, marginRight: 8 }}
              >
                <span style={{ fontSize: 12, fontFamily: 'Kanit' }}>
                  {t('แสดงเกณฑ์พายุ')}

                </span>
                <ArrowDropDownIcon />
              </Button>
              <Button
                // href={`${initPath}/weather/${moment().format('YYYY-MM-DD')}/41/62`}
                href={`${initPath}weather/${moment().format('YYYY-MM-DD')}/41/62`}
                size="small"
                style={{ fontFamily: 'Kanit', fontSize: 12 }}
                variant="outlined"
                color="default"
                align="center"
              >
                <FaCalendarAlt style={{ marginRight: 5 }} />
                <span style={{ fontSize: 12, fontFamily: 'Kanit' }}>
                  {t('ดูข้อมูลย้อนหลัง')}
                </span>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {isOpenCollapse && (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box mt={4}>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <LegendTableCell colSpan={5}>
                          {t('ข้อมูลเกณฑ์พายุ')}
                          {' '}
                          :  Tropical Cyclone Windspeed Scale
                        </LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell colSpan={2}> </LegendTableCell>
                        <LegendTableCell colSpan={3}>
                          1 Minute Maximum Sustained Winds
                        </LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell scope="col">Strength</LegendTableCell>
                        <LegendTableCell scope="col">Category</LegendTableCell>
                        <LegendTableCell scope="col">knots</LegendTableCell>
                        <LegendTableCell scope="col">mph</LegendTableCell>
                        <LegendTableCell scope="col">km/h</LegendTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <LegendTableCell align="left">Tropical Depression</LegendTableCell>
                        <LegendTableCell bgcolor="#00CCFF">TD</LegendTableCell>
                        <LegendTableCell>&lt;34</LegendTableCell>
                        <LegendTableCell>&lt;39</LegendTableCell>
                        <LegendTableCell>&lt;63</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Tropical Storm</LegendTableCell>
                        <LegendTableCell bgcolor="#00FF00">TS</LegendTableCell>
                        <LegendTableCell>34-63</LegendTableCell>
                        <LegendTableCell>39-73</LegendTableCell>
                        <LegendTableCell>63-118</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Severe Cyclonic Storm Cat 1</LegendTableCell>
                        <LegendTableCell bgcolor="#FFFF00">Cat 1</LegendTableCell>
                        <LegendTableCell>64-82</LegendTableCell>
                        <LegendTableCell>74-95</LegendTableCell>
                        <LegendTableCell>119-153</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Severe Cyclonic Storm Cat 2</LegendTableCell>
                        <LegendTableCell bgcolor="#FFCC00">Cat 2</LegendTableCell>
                        <LegendTableCell>83-95</LegendTableCell>
                        <LegendTableCell>96-110</LegendTableCell>
                        <LegendTableCell>154-177</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Severe Cyclonic Storm Cat 3</LegendTableCell>
                        <LegendTableCell bgcolor="#FF6600">Cat 3</LegendTableCell>
                        <LegendTableCell>96-113</LegendTableCell>
                        <LegendTableCell>111-130</LegendTableCell>
                        <LegendTableCell>178-210</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Super Cyclonic Storm Cat 4</LegendTableCell>
                        <LegendTableCell bgcolor="#FF0000">Cat 4</LegendTableCell>
                        <LegendTableCell>114-135</LegendTableCell>
                        <LegendTableCell>131-155</LegendTableCell>
                        <LegendTableCell>211-250</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Super Cyclonic Storm Cat 5</LegendTableCell>
                        <LegendTableCell bgcolor="#CC00CC">Cat 5</LegendTableCell>
                        <LegendTableCell>&gt;135</LegendTableCell>
                        <LegendTableCell>&gt;155</LegendTableCell>
                        <LegendTableCell>&gt;250</LegendTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box mt={4}>
                <TableContainer>
                  <Table size="small" align="center" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <LegendTableCell colSpan={5}>
                          {t('ข้อมูลเกณฑ์พายุ')}
                          {' '}
                          :  Tropical Typhoon Windspeed Scale
                        </LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell colSpan={2}> </LegendTableCell>
                        <LegendTableCell colSpan={3}>
                          1 Minute Maximum Sustained Winds
                        </LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell scope="col">Strength</LegendTableCell>
                        <LegendTableCell scope="col">Category</LegendTableCell>
                        <LegendTableCell scope="col">knots</LegendTableCell>
                        <LegendTableCell scope="col">mph</LegendTableCell>
                        <LegendTableCell scope="col">km/h</LegendTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <LegendTableCell align="left">Tropical Depression</LegendTableCell>
                        <LegendTableCell bgcolor="#00CCFF">TD</LegendTableCell>
                        <LegendTableCell>&lt;34</LegendTableCell>
                        <LegendTableCell>&lt;39</LegendTableCell>
                        <LegendTableCell>&lt;63</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Tropical Storm</LegendTableCell>
                        <LegendTableCell bgcolor="#00FF00">TS</LegendTableCell>
                        <LegendTableCell>34-63</LegendTableCell>
                        <LegendTableCell>39-73</LegendTableCell>
                        <LegendTableCell>63-118</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Typhoon Cat 1</LegendTableCell>
                        <LegendTableCell bgcolor="#FFFF00"> Cat 1</LegendTableCell>
                        <LegendTableCell>64-82</LegendTableCell>
                        <LegendTableCell>74-95</LegendTableCell>
                        <LegendTableCell>119-153</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Typhoon Cat 2</LegendTableCell>
                        <LegendTableCell bgcolor="#FFCC00">Cat 2</LegendTableCell>
                        <LegendTableCell>83-95</LegendTableCell>
                        <LegendTableCell>96-110</LegendTableCell>
                        <LegendTableCell>154-177</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Typhoon Cat 3</LegendTableCell>
                        <LegendTableCell bgcolor="#FF6600">Cat 3</LegendTableCell>
                        <LegendTableCell>96-113</LegendTableCell>
                        <LegendTableCell>111-130</LegendTableCell>
                        <LegendTableCell>178-210</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Typhoon Cat 4</LegendTableCell>
                        <LegendTableCell bgcolor="#FF0000">Cat 4</LegendTableCell>
                        <LegendTableCell>114-135</LegendTableCell>
                        <LegendTableCell>131-155</LegendTableCell>
                        <LegendTableCell>211-250</LegendTableCell>
                      </TableRow>
                      <TableRow>
                        <LegendTableCell align="left">Super Typhoon Cat 5</LegendTableCell>
                        <LegendTableCell bgcolor="#CC00CC">Cat 5</LegendTableCell>
                        <LegendTableCell>&gt;135</LegendTableCell>
                        <LegendTableCell>&gt;155</LegendTableCell>
                        <LegendTableCell>&gt;250</LegendTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <br />
            </Grid>
          </Grid>
        )}
        <Box mt={{ xs: 4, md: 4, sm: 4 }}>
          <Grid container spacing={2}>
            {datas.map(row => (
              <Grid item xs={12} sm={12} md={6} lg={6} key={row.media_path}>
                {row.filename === 'I.png' && (
                  <Typography align="center" variant="h6">
                    {t('มหาสมุทรอินเดีย')}

                  </Typography>
                )}
                {row.filename === 'W.png' && (
                  <Typography align="center" variant="h6">{t('มหาสมุทรแปซิฟิกเหนือ')}</Typography>
                )}
                <Box align="center">
                  <CreditTooltip
                    title={imageSource[0].storm.title}
                    link={imageSource[0].storm.url}
                  >
                    <img src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${row.media_path}`} width="100%" alt="สถานภาพพายุที่เข้าใกล้ประเทศไทย" className={classes.responsive} />
                  </CreditTooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <StormJtwc height={400} />
      </Fragment>
    );
  }
}

Storm.propTypes = {
  dataStorm: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }), withTranslation())(Storm);
