import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { GeoJSON, Marker, Popup } from 'react-leaflet';
import red from '@material-ui/core/colors/red';
import moment from 'moment';

import L from 'leaflet';

// style
import './style.scss';

const getAreaStyle = {
  fillColor: red[800],
};

class BmaWarning extends Component {
  constructor(props) {
    super(props);
    this.state = {

      // array data response api
      dataBmaWarning: [],
      error: null,

      // object icon marker warning.
      iconObl: {
        iconUrl: './images/marker/bma/Warning/warningBma.png',
        iconSize: [25, 25],
      },
    };
    this.refBoundaryArea = createRef();
  }

  componentDidMount() {
    this.fetchData();
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  eachDataBmaWarning = (data, i) => {
    const { dataBmaWarning, iconObl } = this.state;
    const century21icon = L.icon(iconObl);

    const getCentroid = arr => arr.reduce(
      (x, y) => [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length], [0, 0],
    );

    const eachDataloader = (feature, layer) => {
      dataBmaWarning.map((value, index) => {
        if (index === i) {
          layer.setStyle({ className: 'warning-class' }); // new class name for animation dash line border
        }
        return false;
      });
    };

    const eachDataMarker = (value, index) => {
      const geojson = JSON.parse(value.geojson);
      const positionMarker = getCentroid(geojson.coordinates[0][0]).reverse();
      return (
        <Marker
          id={value.catchment_name}
          key={index}
          position={positionMarker}
          icon={century21icon}
        >
          <Popup autoPan={false}>
            {`${moment(value.event_date).format('LT')}น. เตือนฝนตกหนัก: ${value.markpoint}`}
          </Popup>
        </Marker>
      );
    };

    return (
      <>
        {/* render marker */}
        {dataBmaWarning.map(eachDataMarker)}
        {/* render polygon warning */}
        <GeoJSON
          key={uniqueId()}
          // data={data.geojson} // mockup data
          data={JSON.parse(data.geojson)} // response api
          style={getAreaStyle}
          ref={this.refBoundaryArea}
          onEachFeature={eachDataloader}
        />
      </>
    );
  }

  fetchData() {
    const { handleDataUrbanWarning } = this.props;
    const api = `./proxy/pdf.php?file=${process.env.MIX_HYDRO_HIMS_URL}urban/warning?token=${process.env.MIX_HYDRO_HIMS_TOKEN}`;
    // const api = './json/bmaWarning/ExampleJson.json'; // mockup data
    fetch(api)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        this.setState({ dataBmaWarning: result });
        handleDataUrbanWarning(result);
      })
      .catch(error => this.setState({ error }));
  }

  render() {
    const { error, dataBmaWarning } = this.state;
    return (
      <>
        {error && <Grid key="error-text" className="text-center">{error.message}</Grid>}
        {dataBmaWarning.map(this.eachDataBmaWarning)}
      </>
    );
  }
}

BmaWarning.propTypes = {
  handleDataUrbanWarning: PropTypes.func.isRequired,
};

export default BmaWarning;
