import React, {
  useEffect, useRef, useState,
} from 'react';

import Iframe from 'react-iframe';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

// material-ui
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { Hidden } from '@material-ui/core';

// leaflet
import L from 'leaflet';
import Control from 'react-leaflet-control';

// style
import './MainMapCss.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'leaflet/dist/leaflet.css';
import {
  LayersControl, Map, TileLayer, GeoJSON,
} from 'react-leaflet';
import { uniqueId } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import CurrentLocationHook from '../../currentlocation/CurrentLocationHook';

const { BaseLayer } = LayersControl; // BaseLayer

const useStyles = makeStyles(theme => ({
  root: {
    height: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
  styleBanner: {
    zIndex: 99999,
    textAlign: 'center',
    position: 'fixed',
    top: '30px',
    left: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tabledata: {
    zIndex: 99999,
    height: '42px',
    background: '#ffffff',
    border: 'solid 1px #9e9e9e',
    borderRadius: '5px',
    textAlign: 'center',
    padding: '5px',
    position: 'fixed',
    bottom: '3px',
    left: '38%',
    [theme.breakpoints.down('xs')]: {
      left: '50%',
    },
    // marginLeft: '-300px',
  },
  iconInfo: {
    zIndex: 99999,
    position: 'inherit',
    cursor: 'pointer',
  },
}));

const styles = {
  mainmenu1: {
    fontFamily: 'kanit',
    fontSize: '1.5em',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  paperstyle: {
    width: '210px',
    height: '100%',
    borderRadius: '5px',
    border: 'solid 1px #dedede',
    backgroundColor: '#ffffff',
    textAlign: 'center',
  },
  iframetable: {
    zIndex: 999999,
    position: 'fixed',
    bottom: '50px',
    textAlign: 'center',
    padding: '5px',
    width: '100%',
    left: '33%',
    marginLeft: '-33%',
  },
  infoStyle: {
    zIndex: 99999,
    textAlign: 'left',
    position: 'fixed',
    left: '10px',
    bottom: '52%',
    width: '20%',
    // padding: '5px',
    // backgroundColor: '#FFFFFF',
  },
  switchStyle: {
    padding: '5px',
    zIndex: 99999,
    backgroundColor: 'white',
    borderRadius: '5px',
    position: 'fixed',
    right: '0.5%',
    top: '12%',
  },
  menuControlStyle: {
    padding: '5px',
    zIndex: 99999,
    backgroundColor: 'white',
    borderRadius: '5px',
    position: 'fixed',
    right: '0.4%',
    top: '18%',
    width: '18em',
  },
};

export default function MainMap() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language === 'th'
    ? 'th-TH'
    : 'en-US';
  const currentToDay = new Date().toLocaleDateString(currentLang, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).slice(0, 13);
  // set state
  const [getYear, setYear] = useState([]);
  const [getCurrent, setCurrent] = useState(currentToDay);
  const [getGeoJsonAmphoe, setGeoJsonAmphoe] = useState();
  const [getGeoJsonRainAmphoe, setGeoJsonRainAmphoe] = useState([]);
  const [getGeoJsonProvince, setGeoJsonProvince] = useState([]);
  const [getGeoJsonRiver, setGeoJsonRiver] = useState([]);
  // const [getGeoJsonData, setGeoJsonData] = useState([]);
  // set variable env.
  const initPath = process.env.MIX_APP_PATH;
  const banner = `${initPath}images/models/banner-desktop.png`;
  // variable reference.
  const provinceRef = useRef(null);
  const mapRef = useRef(null);
  const rainAmphoeRef = useRef(null);
  const riverRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const [getOpenCollapse, setOpenCollapse] = useState(false);
  const [getOpenLegend, setOpenLegend] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isLoaded, setIsloaded] = useState(false);
  const [renderRainAp, setRenderRainAp] = useState();

  function highlightFeature(e) {
    const layer = e.target;
    layer.setStyle({
      weight: 1,
      opacity: 1,
      color: 'red',
      dashArray: '0',
      fillOpacity: 0.5,
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  function resetHighlight(e) {
    rainAmphoeRef.current.leafletElement.resetStyle(e.layer);
  }

  function getProvinceStyle() {
    return {
      stroke: true,
      color: 'black',
      weight: 1,
      fillColor: 'transparent',
      opacity: 1,
      fillOpacity: 0,
    };
  }

  function getRainAmphoeStyle(feature) {
    const maxrain = Number(feature.properties.rain);
    if (maxrain > 0 && maxrain <= 1) {
      return {
        fillColor: '#FFFFFF',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.7,
      };
    } if (maxrain > 1 && maxrain <= 5) {
      return {
        fillColor: '#cbe2fc',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 5 && maxrain <= 10) {
      return {
        fillColor: '#add4fb',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 10 && maxrain <= 20) {
      return {
        fillColor: '#95e6af',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 20 && maxrain <= 35) {
      return {
        fillColor: '#72bd45',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 35 && maxrain <= 50) {
      return {
        fillColor: '#f8d649',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 50 && maxrain <= 70) {
      return {
        fillColor: '#ff8933',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 70 && maxrain <= 90) {
      return {
        fillColor: '#cc6524',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 90 && maxrain <= 150) {
      return {
        fillColor: '#fe2b1f',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 150 && maxrain <= 200) {
      return {
        fillColor: '#ca242b',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 200 && maxrain <= 300) {
      return {
        fillColor: '#851a1e',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain > 300) {
      return {
        fillColor: '#9c34c7',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    } if (maxrain === 0) {
      return {
        // fillColor: 'transparent',
        fillColor: '#FFFFFF',
        weight: 0.5,
        opacity: 1,
        color: '#000000',
        dashArray: '2',
        fillOpacity: 0.5,
      };
    }
    return {
      fillColor: '',
      weight: 0.5,
      opacity: 1,
      color: '#000000',
      dashArray: '3',
      fillOpacity: 0.5,
    };
  }

  function getRiverStyle() {
    return {
      weight: 0.8,
      opacity: 1,
      color: '#007DBF',
    };
  }

  function onEachFeature(feature, layer) {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
    });

    const popupContent = `
        <b>${t('จังหวัด')} : </b>${layer.feature.properties.PROV_NAM_T}<br>
        <b>${t('อำเภอ')} : </b>${layer.feature.properties.AMPHOE_T}<br>
        <b>${t('ปริมาณฝนสูงสุด')} : </b>${layer.feature.properties.rain} มม.<br/>
      `;

    const TooltipContent = `
        <b>${t('จังหวัด')} : </b>${layer.feature.properties.PROV_NAM_T}<br>
        <b>${t('อำเภอ')} : </b>${layer.feature.properties.AMPHOE_T}<br>
      `;

    layer.bindPopup(popupContent);
    layer.bindTooltip(TooltipContent);
  }

  function changeFormatDate(date) {
    const dates = new Date(date.replace(/-/g, '/'));
    const datesThai = dates.toLocaleDateString(currentLang, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    }).slice(0, 13);
    return datesThai;
  }

  function matchesData(element) {
    const geoamphoe = getGeoJsonAmphoe.features;
    const matches = [];
    for (let i = 0; i < geoamphoe.length; i += 1) {
      for (let j = 0; j < element.data.length; j += 1) {
        if (geoamphoe[i].properties.AMPHOE_IDN === String(element.data[j].ID)) {
          geoamphoe[i].properties.rain = element.data[j].Rain;
          matches.push(geoamphoe[i]);
        }
      }
    }
    return matches;
  }

  function setCurrentDate(value) {
    setCurrent(value);
    let matchData = null;
    let NewDate = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const element of getGeoJsonRainAmphoe) {
      if (element.date !== undefined) {
        NewDate = changeFormatDate(element.date);
        if (NewDate === value) {
          matchData = matchesData(element);
        }
      } else if ((value === 'ฝนตรวจวัด 3 วัน') && (element.status === 'sum_obs')) {
        matchData = matchesData(element);
      } else if ((value === 'ฝนพยากรณ์ 3 วัน') && (element.status === 'sum_fcst')) {
        matchData = matchesData(element);
      } else if ((value === 'ฝนสะสม 6 วัน') && (element.status === 'sum_6day')) {
        matchData = matchesData(element);
      }
    }
    setRenderRainAp(matchData);
  }

  // eslint-disable-next-line no-shadow
  function StyledRadio(props) {
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  const handleClick = () => {
    if (getOpenCollapse === false) {
      setOpenCollapse(true);
      document.getElementsByClassName('leaflet-top leaflet-right')[0].style.zIndex = 998;
      document.getElementsByClassName('leaflet-bottom leaflet-left')[0].style.zIndex = 998;
      document.getElementsByClassName('leaflet-bottom leaflet-right')[0].style.zIndex = 998;
    } else {
      setOpenCollapse(false);
    }
  };

  const handleClickLegend = () => {
    if (getOpenLegend === false) {
      setOpenLegend(true);
    } else {
      setOpenLegend(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeSlide = () => {
    setChecked(prev => !prev);
  };

  function handleChange(event) {
    const el = document.getElementsByClassName('leaflet-popup-close-button');
    for (let i = 0; i < el.length; i += 1) {
      el[i].click();
    }

    setCurrent(event.target.value);
    setCurrentDate(event.target.value);
  }

  function sortData() {
    const array = [];
    getGeoJsonRainAmphoe.forEach(element => {
      if (element.date !== undefined) {
        const dates = new Date(element.date.replace(/-/g, '/'));
        const datesThai = dates.toLocaleDateString(currentLang, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
        });
        array.push(datesThai.slice(0, 13));
      } else {
        switch (element.status) {
          case 'sum_obs':
            array.push(t('ฝนตรวจวัด 3 วัน'));
            break;
          case 'sum_fcst':
            array.push(t('ฝนพยากรณ์ 3 วัน'));
            break;
          case 'sum_6day':
            array.push(t('ฝนสะสม 6 วัน'));
            break;
          default: break;
        }
      }
    });
    const arrayyear = array.filter((a, b) => array.indexOf(a) === b);
    setYear(arrayyear);
    setCurrentDate(arrayyear[0]);
  }

  const tableData = () => (
    <Button
      variant="contained"
      className={classes.buttonStyle}
      aria-haspopup="true"
      onClick={handleClick}
    >
      <Box display="flex" alignItems="center" className={classes.tabledata}>
        <Hidden xsDown>
          <Box px={1}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img alt="image-01" src={`${initPath}images/models/chrome-reader-mode.png`} />
          </Box>
        </Hidden>
        <Box display="flex" alignItems="center">
          {t('ตารางแสดงรายละเอียด')}
        </Box>
        <Box px={1}>
          {getOpenCollapse ? (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={`${initPath}images/models/keyboard_arrow_down-24px.svg`} />
          ) : (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={`${initPath}images/models/keyboard_arrow_up-24px.svg`} />
          )}
        </Box>
      </Box>
    </Button>
  );

  const legend = () => (
    <div
      style={{
        zIndex: 99999,
        position: 'fixed',
        left: '1%',
        bottom: '6%',
        // width: '8.5%',
      }}
    >
      <Paper
        style={{
          opacity: '0.8',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#FFFFFF',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>0-1</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#cbe2fc',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>1-5</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#add4fb',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>5-10</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#95e6af',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>10-20</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#72bd45',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>20-35</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#f8d649',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>35-50</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#ff8933',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>50-70</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#cc6524',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>70-90</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#fe2b1f',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>90-150</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#ca242b',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>150-200</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#851a1e',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}>200-300</b>
          </Grid>
          <Grid container>
            <div style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#9c34c7',
            }}
            />
            <b style={{ paddingLeft: '10px', opacity: '1' }}> &gt;300 </b>
          </Grid>
        </Box>
      </Paper>
    </div>
  );

  const menucontroller = () => (
    <Box>
      <FormControlLabel
        style={styles.switchStyle}
        control={(
          <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={checked ? 'ซ่อน' : 'เปิด'}>
            <Switch checked={checked} onChange={handleChangeSlide} style={{ color: '#006FB6' }} />
          </Tooltip>
        )}
        label={t('ดูสถานการณ์ฝนรายวัน')}
      />
      <Slide direction="left" in={checked} mountOnEnter unmountOnExit style={styles.menuControlStyle}>
        <Paper>
          <div>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <Box component="span" m={1} style={styles.mainmenu1}>
                {t('ฝนตรวจวัดสูงสุด')}
              </Box>
              <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
                <RadioGroup
                  value={getCurrent}
                  style={{ padding: 15 }}
                  aria-label={t('ฝนตรวจวัด')}
                  name="customized-radios"
                  onChange={handleChange}
                >
                  {
                    getYear.slice(0, 3).map(dateTime => (
                      <FormControlLabel
                        key={dateTime}
                        value={dateTime}
                        control={<StyledRadio />}
                        label={dateTime}
                      />
                    ))
                  }
                </RadioGroup>
              </div>
              <Box component="span" m={1} style={styles.mainmenu1}>
                {t('ฝนพยากรณ์สูงสุด')}
              </Box>
              <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
                <RadioGroup
                  value={getCurrent}
                  aria-label={t('ฝนพยากรณ์สูงสุด')}
                  style={{ padding: 15 }}
                  name="customized-radios"
                  onChange={handleChange}
                >
                  {
                    getYear.slice(3, 6).map(dateTime => (
                      <FormControlLabel
                        key={dateTime}
                        value={dateTime}
                        control={<StyledRadio />}
                        label={dateTime}
                      />
                    ))
                  }
                </RadioGroup>
              </div>
              <Box component="span" m={1} style={styles.mainmenu1}>
                {t('ฝนสะสม')}
              </Box>
              <div style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
                <RadioGroup
                  value={getCurrent}
                  aria-label={t('ฝนสะสม')}
                  style={{ padding: 15 }}
                  name="customized-radios"
                  onChange={handleChange}
                >
                  {
                    getYear.slice(6, 9).map(dateTime => (
                      <FormControlLabel
                        key={dateTime}
                        value={dateTime}
                        control={<StyledRadio />}
                        label={dateTime}
                      />
                    ))
                  }
                </RadioGroup>
              </div>
            </FormControl>
          </div>
        </Paper>
      </Slide>
    </Box>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const provinceResponse = await fetch(`${initPath}json/boundary/thailand.json`);
        const provinceData = await provinceResponse.json();
        setGeoJsonProvince(provinceData);

        const riverResponse = await fetch(`${initPath}json/river/river_main.json`);
        const riverData = await riverResponse.json();
        setGeoJsonRiver(riverData);

        const amphoeResponse = await fetch(`${initPath}json/boundary/amphoe.json`);
        const amphoeData = await amphoeResponse.json();
        setGeoJsonAmphoe(amphoeData);

        const rainAmphoeResponse = await fetch(`${process.env.MIX_LIVE_URL}/product/latest/rain/rain_amphoe.json`);
        const rainAmphoeData = await rainAmphoeResponse.json();
        setGeoJsonRainAmphoe(rainAmphoeData);
        setIsloaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    if (isLoaded) {
      document.getElementsByClassName('leaflet-top leaflet-left')[0].style.top = '18%';
      sortData();
    }
  }, [isLoaded]);

  return (
    <>
      <Map
        id="map"
        style={{
          height: window.height,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
        center={[13, 101.6]}
        zoom={6}
        ref={mapRef}
      >
        <GeoJSON
          key={uniqueId()}
          data={getGeoJsonProvince}
          style={getProvinceStyle}
          ref={provinceRef}
        />
        <GeoJSON
          key={uniqueId()}
          data={getGeoJsonRiver}
          style={getRiverStyle}
          ref={riverRef}
        />
        <GeoJSON
          key={uniqueId()}
          data={renderRainAp}
          style={getRainAmphoeStyle}
          onEachFeature={onEachFeature}
          ref={rainAmphoeRef}
        />
        <LayersControl position="topleft">
          <CurrentLocationHook mapRef={mapRef} showOnly={false} />
            <BaseLayer checked name="OpenStreetMap.Mapnik">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </BaseLayer>
            <BaseLayer name="ESRI World Topo">
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" />
            </BaseLayer>
            <BaseLayer name="BlackAndWhite">
              <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
            </BaseLayer>
          </LayersControl>
        <Control position="topleft">
          <Box p={0.5} className={classes.iconInfo}>
            <Tooltip title={t('ระบบติดตามสถานการณ์ฝนรายอำเภอ เป็นการแสดงแผนที่ฝนสะสมของข้อมูลฝนตรวจวัดสูงสุดและข้อมูลฝนคาดการณ์สูงสุด โดยใช้ข้อมูลฝนตรวจวัดรายสถานีจากคลังข้อมูลน้ำแห่งชาติและภูมิอากาศแห่งชาติ (NHC)มาทำการประมาณค่าปริมาณน้ำฝนด้วยวิธี Inverse Distance Weight (IDW) ร่วมกับข้อมูลฝนคาดการณ์เชิงพื้นที่จากแบบจำลองสภาพอากาศ WRF-ROMS ระบบทำการประมวลผลวันละ 1 ครั้ง เพื่อใช้ในการติดตามสถานการณ์ฝนและการบริหารจัดการน้ำ')}>
              <InfoIcon />
            </Tooltip>
          </Box>
          <img src={banner} alt="banner" className={classes.styleBanner} />
        </Control>
        {getOpenCollapse ? (
          <Paper style={styles.iframetable}>
            <Iframe
              url={`${process.env.MIX_LIVE_URL}/product/latest/rain/rain_avg.html`}
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />
          </Paper>
        ) : null}
        {tableData()}
        {getOpenLegend ? (
          legend()
        ) : null }
        <Control position="bottomleft">
          <Box mt={6}>
            <Button
              variant="contained"
              style={{
                backgroundColor: 'white',
                zIndex: 99999,
              }}
              aria-haspopup="true"
              onClick={handleClickLegend}
            >
              {t('เกณฑ์ปริมาณฝน  (มม.)')}
            </Button>
          </Box>
        </Control>
        {menucontroller()}
      </Map>
    </>
  );
}
