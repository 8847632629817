// React
import React, { useEffect, useState } from 'react';
// material-ui
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
// component
import StormSurgeForecastMap from './StormSurgeForecastMap';
// style
import { styles } from '../../../styles/Style';

const initPath = process.env.MIX_API_HII_URL;
const useStyles = makeStyles(styles);

export default function StormSurgeForecast() {
  // const { width } = props;
  const classes = useStyles();
  const [getError, setError] = useState();
  const [getListStation, setListStation] = useState([]);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  const getModalStyle = () => {
    const { dynamicWidth } = screenSize;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '70%';
    if (isWidthDown('xl', dynamicWidth)) {
      widthChart = '90%';
      heightChart = '67%';
    }
    if (isWidthUp('md', dynamicWidth)) {
      widthChart = '80%';
      heightChart = '80%';
    }
    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
    // const top = 0;
    // const left = 0;
    // const right = 0;
    // const bottom = 0;
    // return {
    //   top: `${top}%`,
    //   left: `${left}%`,
    //   right: `${right}%`,
    //   bottom: `${bottom}%`,
    //   margin: 'auto',
    //   width: '90%',
    //   height: '65%',
    //   overflowY: 'auto',
    //   backgroundColor: '#FFF',
    //   padding: '20px',
    //   position: 'absolute',
    // };
  };
  const [modalStyle] = useState(getModalStyle);
  const [getModal, setModal] = useState({
    open: false,
    modalHeader: '',
    modalSubHeader: '',
    modalContent: '',
  });

  function fetchData() {
    fetch(`${initPath}/tiservice/v1/ws/cEniGCuZcTBSa3xj4A8PY187BhpExTfE/model/stromsurge/station_info`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        if (result.result === 'OK') {
          setListStation(result.data.station);
        }
      })
      .catch(error => setError(error));
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    return (() => {
      window.removeEventListener('resize', setDimension);
    });
  }, [screenSize]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleModalClose = () => {
    const settingModal = {
      open: false,
      modalHeader: '',
      modalSubHeader: '',
      modalContent: '',
    };
    setModal(settingModal);
  };

  const handleModalOpen = params => {
    const settineModal = {
      open: true,
      modalHeader: params.header,
      modalContent: params.content,
    };
    setModal(settineModal);
  };

  return (
    <>
      {getError && (
        <div className="text-center">
          {getError.message}
        </div>
      )}
      <div className={classes.layout}>
        {
          getListStation.length !== 0 && (
            <StormSurgeForecastMap
              listStation={getListStation}
              handleModalOpen={handleModalOpen}
            />
          )
        }
        <Modal
          id="modal-chart"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={getModal.open}
          onClose={handleModalClose}
          style={{ align: 'center', justifyContent: 'center' }}
        >
          <div style={modalStyle} className={classes.paper}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              <Grid item>
                <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Typography variant="h5" id="modal-title">
              {getModal.modalHeader}
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              {getModal.modalSubHeader}
            </Typography>
            <Box mt={3}>
              {getModal.modalContent}
            </Box>
          </div>
        </Modal>
      </div>
    </>
  );
}

// StormSurgeForecast.propTypes = {
//   width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
// };
