import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  uniqueId,
} from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import {
  Link,
} from 'react-router-dom';
import Img from 'react-image';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

// ui
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import {
  FaHome, FaSpinner,
} from '../../../utils/Fontawesome';

import {
  styles, BootstrapInput,
} from '../../../styles/Style';

// import 'moment/locale/ja';

const initPath = process.env.MIX_APP_PATH;
const damCondition = [
  { key: 189, value: 'รวมอ่างขนาดกลางและใหญ่' },
  { key: 185, value: 'อ่างขนาดใหญ่' },
  { key: 187, value: 'อ่างขนาดกลาง' },
];

const damLatest = `${process.env.MIX_API_URL}public/weather_img/image_generate`;
const sshLatest = `${process.env.MIX_API_URL}public/weather_img/ssh_hii`;

const breadCrumbs = {
  dam: {
    level1: {
      link: '',
      text: 'ติดตามสถานการณ์น้ำ',
    },
    level2: {
      link: `${initPath}water`,
      text: 'อ่างเก็บน้ำ',
    },
  },
  ssh: {
    level1: {
      link: '',
      text: 'ติดตามสภาพอากาศ',
    },
    level2: {
      link: `${initPath}weather/sea`,
      text: 'ทะเล',
    },

  },
};
const mediaType = [];
mediaType[185] = {
  index: 0, title: 'ภาพอ่างขนาดใหญ่', thumbSize: { w: 232, h: 415 }, option: damCondition, url: damLatest, breadcrumb1: breadCrumbs.dam.level1, breadcrumb2: breadCrumbs.dam.level2,
};
mediaType[187] = {
  index: 3, title: 'ภาพอ่างขนาดกลาง', thumbSize: { w: 232, h: 415 }, option: damCondition, url: damLatest, breadcrumb1: breadCrumbs.dam.level1, breadcrumb2: breadCrumbs.dam.level2,
};
mediaType[189] = {
  index: 4, title: 'ภาพรวมอ่างขนาดกลางและใหญ่', thumbSize: { w: 232, h: 415 }, option: damCondition, url: damLatest, breadcrumb1: breadCrumbs.dam.level1, breadcrumb2: breadCrumbs.dam.level2,
};
mediaType[219] = {
  index: 0, title: 'แผนภาพค่าเบี่ยงเบนความสูงระดับน้ำทะเล', thumbSize: { w: 250, h: 165 }, option: [], url: sshLatest, breadcrumb1: breadCrumbs.ssh.level1, breadcrumb2: breadCrumbs.ssh.level2,
};

const currentDate = new Date(moment().subtract(1, 'day'));

class WeatherImageHistoryMonth extends Component {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef();
    this.state = {
      isLoading: false,
      error: null,
      datas: [],
      latestImage: [],
      displayState: 'history',

      photoIndex: 0,
      isOpen: false,
      isOpenLatestImage: false,
      mediaTypeId: 185,
    };
  }

  componentDidMount() {
    const { match: { params: { agencyId, mediaTypeId } } } = this.props;
    const startDate = new Date(moment().subtract(1, 'day'));

    this.setState({
      isLoading: true,
      mediaTypeId,
      agencyId,
      startDate,
      valueDropDown: mediaTypeId,
    });
    this.fetchData(agencyId, mediaTypeId, startDate);
  }

  fetchData = (agencyId, mediaTypeId, date) => {
    Promise.all([
      fetch(`${process.env.MIX_API_URL}public/weather_history_img/month?agency_id=${agencyId}&media_type_id=${mediaTypeId}&year=${moment(date).format('YYYY')}&month=${moment(date).format('MM')}`),
      fetch(mediaType[mediaTypeId].url),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([res1, res2]) => {
        this.setState({
          isLoading: false,
          datas: res1.data,
          latestImage: res2.data,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    });

    const { agencyId, mediaTypeId } = this.state;
    this.fetchData(agencyId, mediaTypeId, date);
  };

  handleDateChangeButton = change => {
    const { startDate } = this.state;
    let selectedDate = startDate;
    if (change === '1') {
      selectedDate = moment(selectedDate).add(1, 'months').format('YYYY-MM-DD');
    } else if (change === '-1') {
      selectedDate = moment(selectedDate).add(-1, 'months').format('YYYY-MM-DD');
    }
    this.setState({
      startDate: selectedDate,
    });
    const { agencyId, mediaTypeId } = this.state;
    this.fetchData(agencyId, mediaTypeId, selectedDate);
  }

  handleDropDownChange = event => {
    const { history } = this.props;
    const { value } = event.target;
    const { agencyId, startDate } = this.state;
    this.setState({
      valueDropDown: value,
      mediaTypeId: value,

    });
    this.fetchData(agencyId, value, startDate);

    const url = `${process.env.MIX_APP_PATH}weather/history/month/${agencyId}/${value}`;
    history.push(url);
  }

  render() {
    const { classes, t, i18n } = this.props;
    const latestImg = [];
    const {
      isLoading,
      error,
      datas,
      photoIndex,
      isOpen,
      startDate,
      displayState,
      mediaTypeId,
      valueDropDown,
      isOpenLatestImage,
      latestImage,
    } = this.state;

    // lightbox
    const images = [];
    let currentIndex = 0;
    const { w: thumbWidth, h: thumbHeight } = mediaType[mediaTypeId].thumbSize;
    const width = '100%';
    const height = '100%';
    const languageI18n = i18n.language === 'jp' ? 'ja' : i18n.language;

    if (latestImage.length > 0) {
      latestImg[185] = {
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${latestImage[mediaType[mediaTypeId].index][0].media_path}`,
        datetime: moment(latestImage[mediaType[mediaTypeId].index][0].media_datetime).locale(languageI18n).format('LL'),
      };
      latestImg[187] = {
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${latestImage[mediaType[mediaTypeId].index][0].media_path}`,
        datetime: moment(latestImage[mediaType[mediaTypeId].index][0].media_datetime).locale(languageI18n).format('LL'),
      };
      latestImg[189] = {
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${latestImage[mediaType[mediaTypeId].index][0].media_path}`,
        datetime: moment(latestImage[mediaType[mediaTypeId].index][0].media_datetime).locale(languageI18n).format('LL'),
      };
    }
    if (latestImage.cover_image !== undefined) {
      latestImg[219] = {
        src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL}${latestImage.cover_image.media_path}`,
        datetime: moment(latestImage.cover_image.media_datetime).locale(languageI18n).format('LL'),
      };
    }

    return (
      <>
        <div className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs>
              {isLoading === true && (
                <FaSpinner size={25} />
              )}

              {error && (
                <div className="text-center">{error.message}</div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={mediaType[mediaTypeId].breadcrumb2.link}>
                  <FaHome className={classes.iconSub} />
                  <Typography variant="h6" color="textSecondary" display="inline" style={{ fontSize: 14 }}>
                    {' '}
                    {t(mediaType[mediaTypeId].breadcrumb1.text)}
                  </Typography>
                </Link>
                <Link to={mediaType[mediaTypeId].breadcrumb2.link}>
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                    {t(mediaType[mediaTypeId].breadcrumb2.text)}
                  </Typography>
                </Link>
                <Typography variant="h6" color="textSecondary" style={{ fontSize: 14 }}>
                  {t(mediaType[mediaTypeId].title)}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: 24 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex">
                <Box flexGrow={1} p={1} display="flex" style={{ border: '1px solid #ccc', borderTopLeftRadius: 6 }}>
                  <Box className={classes.titleName}>
                    {t(mediaType[mediaTypeId].title)}
                  </Box>
                </Box>
                {(mediaType[mediaTypeId].option.length > 0) ? (
                  <Box p={1} style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <NativeSelect
                        value={valueDropDown}
                        onChange={this.handleDropDownChange}
                        input={<BootstrapInput name="region" id="regend-customized-native-simple" />}
                      >
                        {mediaType[mediaTypeId].option.map(row => (
                          <option fontFamily="Kanit" value={row.key} style={{ fontFamily: 'Kanit' }} key={uniqueId()}>{t(row.value)}</option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                ) : ''}

                <Box style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                  <Tooltip title={t('ดูภาพล่าสุด')}>
                    <Button
                      className={classes.outlinedPrimary}
                      color={displayState === 'latest' ? 'primary' : 'default'}
                      variant={displayState === 'latest' ? 'contained' : 'text'}
                      onClick={() => this.setState({ displayState: 'latest' })}
                    >
                      <ImageOutlinedIcon fontSize="small" />
                      <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>{t('ดูภาพล่าสุด')}</Typography></Hidden>
                    </Button>
                  </Tooltip>
                </Box>
                <Box style={{ border: '1px solid #ccc', borderTopRightRadius: 6 }} display="flex" alignItems="center" justifyContent="center">
                  <Tooltip title={t('ดูภาพย้อนหลัง')}>
                    <Button
                      className={classes.outlinedTopRadius}
                      color={displayState === 'history' ? 'primary' : 'default'}
                      variant={displayState === 'history' ? 'contained' : 'text'}
                      onClick={() => this.setState({ displayState: 'history' })}
                    >
                      <PhotoLibraryOutlinedIcon fontSize="small" />
                      <Hidden xsDown><Typography variant="h6" style={{ marginLeft: 8, fontSize: 15 }}>{t('ดูภาพย้อนหลัง')}</Typography></Hidden>
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {displayState === 'history' && (
            <>
              <Grid container direction="row" justifyContent="center" spacing={4} style={{ marginTop: 24, marginBottom: 24 }}>
                <Grid item><Button onClick={e => this.handleDateChangeButton('-1')}><KeyboardArrowLeftIcon /></Button></Grid>
                <Grid item xs sm={4} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider locale={languageI18n} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        openTo="month"
                        views={['year', 'month']}
                        value={startDate}
                        onChange={this.handleChange}
                        style={{ fontSize: 14 }}
                        maxDate={currentDate}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item><Button onClick={e => this.handleDateChangeButton('1')}><KeyboardArrowRightIcon /></Button></Grid>
              </Grid>
              {datas.length <= 0 ? (
                <Grid container spacing={4} justify="center">
                  <Grid item xs>
                    <Typography variant="h6">
                      {t('ไม่พบข้อมูลในช่วงเวลาที่เลือก')}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  {datas
                    .sort((a, b) => new Date(a.media_datetime) - new Date(b.media_datetime))
                    .map(row => {
                      let realImage = '';
                      const currentImgIndex = currentIndex;
                      const showImg = (row.media_path_thumb !== null)
                        ? row.thumbnail_media_path : row.media_path;
                      const timeShow = moment(row.media_datetime).locale(languageI18n).format('LL');
                      if (row.media_path !== '') {
                        images.push({
                          src: `${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${row.media_path}`,
                          title: timeShow,
                        });
                        realImage = (
                          <Button
                            key={uniqueId()}
                            onClick={() => this.setState({
                              isOpen: true,
                              photoIndex: currentImgIndex,
                            })}
                          >
                            <Img
                              src={`${process.env.MIX_API_URL}${process.env.MIX_IMAGE_URL.substr(1)}${showImg}`}
                              width={width}
                              height={height}
                              alt={timeShow}
                              className={classes.responsive}
                              key={uniqueId()}
                              unloader={(
                                <Img
                                  src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                                  alt="No Image"
                                  className={classes.responsive}
                                />
                                )}
                            />
                          </Button>
                        );
                      } else {
                        realImage = (
                          <Img
                            src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt={timeShow}
                            className={classes.responsive}
                          />
                        );
                      }
                      currentIndex += 1;
                      return (
                        <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                          <Box display="flex" alignItems="center" justifyContent="center" className={classes.boxWeatherHistory}>
                            <Box display="block">
                              <Box display="block" align="center">
                                {realImage}
                              </Box>
                              <Box p={1} display="flex" alignItems="center" justifyContent="center">
                                <Typography className={classes.dateTime}>
                                  {timeShow}
                                  {/* <Box
                                    p={1}
                                    pb={0}
                                    align="center"
                                    fontFamily="Kanit"
                                    fontWeight="fontWeightMedium">
                                    </Box> */}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
            </>
          )}

          {displayState === 'latest' && (
            <Grid container spacing={4}>
              <Grid item xs={6} sm={3} md={3} lg={3} key={uniqueId()}>
                <Box className={classes.boxWeatherHistory}>
                  <Button onClick={() => this.setState({ isOpenLatestImage: true })}>
                    <Box display="block" align="center">
                      <Img
                        src={latestImg[mediaTypeId].src}
                        className={classes.responsive}
                        alt="image-last-rain"
                        key={uniqueId()}
                        width={width}
                        height={height}
                        unloader={(
                          <Img
                            src={`https://via.placeholder.com/${thumbWidth}x${thumbHeight}?text=No+Image`}
                            alt="No Image"
                            className={classes.responsive}
                          />
                        )}
                      />
                    </Box>
                  </Button>
                  <Box p={1} display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.dateTime}>
                      {latestImg[mediaTypeId].datetime}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

        </div>

        {isOpenLatestImage && (
          <Lightbox
            mainSrc={latestImg[mediaTypeId].src}
            imageTitle={latestImg[mediaTypeId].datetime}
            imagePadding={50}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpenLatestImage: false })}
          />
        )}

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={images[photoIndex].title}
            reactModalStyle={{ overlay: { zIndex: '1000000' } }}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imagePadding={50}
            onMovePrevRequest={() => (
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              }))}
            onMoveNextRequest={() => (
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              }))}
          />
        )}
      </>
    );
  }
}

WeatherImageHistoryMonth.defaultProps = {
  match: {
    params: {
      agencyId: 64,
      mediaTypeId: 185,
    },
  },
};

WeatherImageHistoryMonth.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      agencyId: PropTypes.string.isRequired,
      mediaTypeId: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withRouter(
  compose(
    withStyles(styles, { withTheme: true }),
    withTranslation(),
  )(WeatherImageHistoryMonth),
);
