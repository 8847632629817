// Legend
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    position: 'fixed',
    left: '1%',
    bottom: '6%',
  },
  legend: {
    height: '13px',
    width: '20px',
    marginRight: 0,
  },
});

export default function Legend() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper style={{ opacity: '0.8', backgroundColor: '#EAEAEA' }}>
      <Box p={1}>
        <b>{t('ปริมาณฝน (มม./เดือน)')}</b>
        <Grid container>
          <div
            style={{
              backgroundColor: '#FFFFFF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>0 - 10</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#FFFFCC',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;10 - 20</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#FFFF99',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;20 - 40</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#FFFF00',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;40 - 80</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#CCFF00',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;80 - 100</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#CCFF00',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;100 - 120</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#66FF00',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;120 - 140</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#33FF00',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;140 - 150</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#00FF33',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;150 - 160</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#00FF66',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;160 - 170</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#00FF99',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;170 - 180</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#00FFCC',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;180 - 190</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#00FFFF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;190 - 200</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#00CCFF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;200 - 210</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#0099FF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;210 - 220</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#0066FF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;220 - 230</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#0033FF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;230 - 240</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#0000FF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;240 - 250</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#4300F8',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;250 - 260</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#6600FF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;260 - 270</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#9900FF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;270 - 280</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#CC00FF',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;280 - 290</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#FF00AA',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;290 - 300</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#FF005E',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;300 - 400</b>
        </Grid>

        <Grid container>
          <div
            style={{
              backgroundColor: '#ED0520',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;400 - 500</b>
        </Grid>

        <Grid container>
          <div
            style={{
              backgroundColor: '#CC041B',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;500 - 700</b>
        </Grid>
        <Grid container>
          <div
            style={{
              backgroundColor: '#B80000',
            }}
            className={classes.legend}
          />
          <b style={{ paddingLeft: '10px', opacity: '1' }}>&gt;700 - 3000</b>
        </Grid>
      </Box>
    </Paper>
  );
}
