import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Img from 'react-image';
import iconImage from '../../widgets/map/iconImage';
import WlStatusColor from '../../wl/WlStatusColor';
/**
 * Legend
 */

const useStyles = makeStyles({
  legendContainer: {
    zIndex: 99999,
    marginTop: 10,
  },
  legend: {
    height: '20px',
    width: '20px',
    marginRight: 10,
  },
  iconSize: {
    height: '20px',
    width: '20px',
  },
});

export default function LegendSymbol() {
  const classes = useStyles();
  const initPath = process.env.MIX_APP_PATH;

  return (
    <div
      className={classes.legendContainer}
    >
      <Paper
        style={{
          opacity: '0.8',
          backgroundColor: '#EAEAEA',
        }}
      >
        <Box p={1}>
          <Grid container>
            <Grid item>
              <img src={`${initPath}images/theme/icon/station-icon.png`} alt="ระดับน้ำ" />
            </Grid>
            <Grid item><Typography variant="subtitle2">ระดับน้ำ</Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Img
                src={iconImage.waterLevel.options.iconUrl}
                alt="waterlevel"
                className={classes.iconSize}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> ระดับน้ำ (ม.รทก.) </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#333FFF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> น้ำท่า (ลบ.ม./ว) </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#6833FF',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> ระดับน้ำปตร </Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#E6083E',
                }}
                className={classes.legend}
              />
            </Grid>
            <Grid item><Typography variant="subtitle2"> ระดับน้ำปตร กทม. </Typography></Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
