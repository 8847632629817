import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import L from 'leaflet';
import RefreshPage from './RefreshPage';

const useStyles = makeStyles(theme => ({
  root: {
    width: '85%',
    paddingLeft: '20px',
    paddingRight: '20px',
    zIndex: 99999,
    borderRadius: '5px',
    position: 'fixed',
    color: 'white',
    bottom: 25,

    [theme.breakpoints.down('xs')]: {
      width: '80%',
      bottom: '60px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
  },
  hiddenLine: {
    borderBottom: 0,
    fontSize: 'small',
    whiteSpace: 'normal',
    display: 'flex',
  },
  thumb: {
    background: ({ current, countRadarObserve }) => (current > countRadarObserve ? '#67C55B' : '#1976d2'),
    marginTop: '-7px',
    width: 18,
    height: 18,
    '&~&': {
      background: 'green',
    },
  },
  marked: {
    padding: 0,
  },
  mark: {
    background: 'white',
    opacity: '0.5',
    width: 1,
    height: 12,
    borderRadius: '0%',
    marginLeft: '6px',
    marginTop: '-4px',
  },
  markActive: {
    width: 1,
    height: 12,
    borderRadius: '0%',
    marginLeft: '6px',
    marginTop: '-4px',
  },
  markLabel: {
    marginTop: '-12px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-26px',
    },
  },
  rail: {
    background: ({ dataRadarBkk, countRadarObserve, countRadarForcast }) => {
      const percentColorPrimary = countRadarForcast > 0 ? (100 / dataRadarBkk.length - 1) * countRadarObserve : '100';
      const percentColorSecondary = (100 / dataRadarBkk.length) * countRadarObserve + 0;
      return `linear-gradient(90deg, #1976d2 ${percentColorPrimary}%, #67C55B ${percentColorSecondary}%)`;
    },
  },
  track: {
    display: 'none',
  },
  valueLabel: {
    '&>*': {
      background: 'black',

    },
  },
  currentLabel: {
    position: 'relative',
    left: '0.5%',
  },
  forcastLabel: {
    position: 'absolute',
    left: ({ dataRadarBkk, countRadarObserve }) => `${((100 / dataRadarBkk.length) * countRadarObserve)}%`,
    display: ({ countRadarForcast }) => (countRadarForcast <= 0 ? 'none' : ''),
  },
  sliderArea: {
    position: 'relative',
    display: 'inline-flex',
  },
  cursorPlayStop: {
    cursor: 'pointer',
  },
}));

function valuetext(value) {
  return `${value}°C`;
}

export default function CompRadarBkk(props) {
  const [current, setCurrent] = useState(0);
  const [paused, setPaused] = useState(false);
  const [reset, setReset] = useState(false);

  const [valueRadarBkk, setValueRadarBkk] = useState(0);
  const [imageRadarBkk, setImageRadarBkk] = useState(null);
  const [currentIndexRadarBkk, setCurrentIndexRadarBkk] = useState(0);

  const play = () => {
    setPaused(false);
  };
  const pause = () => {
    setPaused(true);
  };

  const {
    dataRadarBkk, metaDataRadarBkk, currentMapRef,
    handleSetImageRadarBkk,
  } = props;

  // check data type
  const countRadarObserve = dataRadarBkk.filter(d => d.url.includes('/obs/')).length;
  const countRadarForcast = dataRadarBkk.filter(d => d.url.includes('/for/')).length;

  const classes = useStyles({
    current, dataRadarBkk, countRadarObserve, countRadarForcast,
  });

  const handleChangeRadarBkk = async (event, newValue) => {
    if (imageRadarBkk) {
      currentMapRef.removeLayer(imageRadarBkk);
    }

    const index = dataRadarBkk.findIndex(ele => ele.value === newValue);
    if (index) {
      setCurrentIndexRadarBkk(index + 1);
    }
    const result = dataRadarBkk.find(ele => ele.value === newValue);

    if (result) {
      // const imageBounds = [[14.3, 100], [13, 101.2]];
      const { xmin } = metaDataRadarBkk.metadata[0];
      const { xmax } = metaDataRadarBkk.metadata[0];
      const { ymin } = metaDataRadarBkk.metadata[0];
      const { ymax } = metaDataRadarBkk.metadata[0];
      const imageUrl = result.url;
      const imageBounds = [[ymin, xmin], [ymax, xmax]];
      const image = L.imageOverlay(imageUrl, imageBounds);
      image.addTo(currentMapRef);

      setImageRadarBkk(image);
      handleSetImageRadarBkk(image);
    }
    setValueRadarBkk(newValue);
  };

  useEffect(
    () => {
      let timeout;
      let next = 0;

      if (!paused) {
        timeout = setTimeout(() => {
          next = current < dataRadarBkk.length - 1 ? current + 1 : 0;

          if (currentIndexRadarBkk !== next && current !== 0) {
            next = currentIndexRadarBkk;
          }

          const { value } = dataRadarBkk[next];
          handleChangeRadarBkk('', value);
          setCurrent(next);
        }, 1000);
      }

      if (currentIndexRadarBkk === dataRadarBkk.length) {
        setReset(true);
        return () => clearTimeout(timeout);
      }

      return () => clearTimeout(timeout);
    },
    [paused, current, dataRadarBkk.length, imageRadarBkk],
  );

  useEffect(() => {
    if (reset) {
      setCurrent(-1);
      setReset(false);
      // setPaused(true);
      setCurrentIndexRadarBkk(0);
    }
  }, [reset]);

  useEffect(() => {
    // play();
  }, []);

  function valueLabelFormat(v) {
    return dataRadarBkk.findIndex(mark => mark.value === v) + 1;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={0} alignItems="center">
        <RefreshPage />
        <Grid item xs="auto">
          <Box display="block" justifyContent="center" alignItems="flex-start" mt={1} p={1}>
            <Box display="flex" alignItems="flex-start" title={paused ? 'Play' : 'Stop'}>
              {
                (paused === true)
                  ? (<PlayCircleFilledIcon fontSize="large" onClick={play} className={classes.cursorPlayStop} />)
                  : (<PauseCircleFilledIcon fontSize="large" onClick={pause} className={classes.cursorPlayStop} />)
              }
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Grid container spacing={0} style={{ marginLeft: '-21px' }}>
            <Grid item xs className={classes.sliderArea}>
              <Table className={classes.currentLabel}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <LocationOnIcon style={{ color: '#3980C0' }} />
                      <Typography component="h1" variant="subtitle1" style={{ fontSize: '1em', color: 'white' }}>
                        ตรวจวัด
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table className={classes.forcastLabel}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.hiddenLine}>
                      <LocationOnIcon style={{ color: '#67C55B' }} />
                      <Typography component="h1" variant="subtitle1" style={{ fontSize: '1em', color: 'white' }}>
                        คาดการณ์
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Slider
            // className={classes.sliderColor}
            classes={{
              thumb: classes.thumb,
              rail: classes.rail,
              track: classes.track,
              valueLabel: classes.valueLabel,
              mark: classes.mark,
              markLabel: classes.markLabel,
              marked: classes.marked,
              markActive: classes.markActive,
            }}
            key={`slider-${valueRadarBkk}`}
            onChange={handleChangeRadarBkk}
            defaultValue={valueRadarBkk}
            // eslint-disable-next-line react/jsx-no-bind
            valueLabelFormat={valueLabelFormat}
            getAriaValueText={valuetext}
            aria-label="Slider Radar"
            step={null}
            valueLabelDisplay="auto"
            marks={dataRadarBkk}
          />
        </Grid>
      </Grid>
    </div>
  );
}

CompRadarBkk.propTypes = {
  dataRadarBkk: PropTypes.array.isRequired,
  metaDataRadarBkk: PropTypes.object.isRequired,
  currentMapRef: PropTypes.any.isRequired,
  handleSetImageRadarBkk: PropTypes.func.isRequired,
};
