/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={classnames('pagination-container', { [className]: className })}>
      <Typography component="li" key={uniqueId()} className={classnames('pagination-item', { disabled: currentPage === 1 })} onClick={() => onPrevious()}>
        <div className="arrow left" key={currentPage} />
      </Typography>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <Typography key={uniqueId()} component="li" className="pagination-item dots">&#8230;</Typography>;
        }

        return (
          <Typography component="li" key={uniqueId()} className={classnames('pagination-item', { selected: pageNumber === currentPage })} onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </Typography>
        );
      })}
      <Typography component="li" key={uniqueId()} className={classnames('pagination-item', { disabled: currentPage === lastPage })} onClick={() => onNext()}>
        <div className="arrow right" key={lastPage} />
      </Typography>
    </ul>
  );
};

export default Pagination;
