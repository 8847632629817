import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';
import {
  orderBy, // uniqBy, uniqueId,
} from 'lodash';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

// ui, styles //
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';

// width
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import {
  FaSpinner,
} from '../../utils/Fontawesome';

import {
  styles,
} from '../../styles/Style';

import WlContext from '../../contexts/wlContext';
import FlashFloodMap from './FlashFloodMap';

class FlashFlood extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, // loading status
      error: null, // error
      isLoadingRainToday: false,

      datas: [],
      datasRiskArea: [],
      header: [],
      dataStorm: [],
      arrayTambonId: [],

      // map
      map: null,
      zoom: this.setZoomMap(),
      location: [13, 101.6],
      markers: [],

      // modal
      open: false,
      modalHeader: null,
      modalSubHeader: null,
      modalContent: null,

      geojsonRiver: [],
      geojsonBasin: [],

    };
  }

  componentDidMount() {
    this._mounted = true;
    // set loading status
    this.setState({ isLoading: true, isLoadingRainToday: true });
    this.getRainfallToday();
    this.fetchCsv();
    // this.getGeoJson();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getModalStyle = () => {
    const { width } = this.props;
    const top = 0;
    const left = 0;
    const right = 0;
    const bottom = 0;
    let widthChart = '100%';
    let heightChart = '80%';

    if (isWidthDown('xs', width)) {
      widthChart = '90%';
      heightChart = '90%';
    }
    if (isWidthUp('md', width)) {
      widthChart = '80%';
      heightChart = '60%';
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      bottom: `${bottom}%`,
      margin: 'auto',
      width: widthChart,
      height: heightChart,
      overflowY: 'auto',
      backgroundColor: '#FFF',
      padding: '20px',
      position: 'absolute',
    };
  }

  setZoomMap() {
    const { width } = this.props;
    let zoom = 6;
    if (isWidthDown('sm', width) && isWidthUp('xs', width)) {
      zoom = 5;
    }
    return zoom;
  }

  getMaxDate = datas => {
    if (datas !== '') {
      const dates = datas.map(x => new Date(x.rainfall_datetime.replace(/-/g, '/')));
      const maxDate = new Date(Math.max.apply(null, dates));
      return `${moment(maxDate).format('YYYY-MM-DD')} เวลา : ${moment(maxDate).format('HH:mm')}`;
    }
    return new Date();
  }

  getRainfallToday() {
    // get data from service rain today
    fetch(`${process.env.MIX_API_URL}public/rain_today`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(result => {
        // filter null and data not updated in 24 hrs
        const currentDateTime = new Date();
        const last24hrs = currentDateTime.setDate(currentDateTime.getDate() - 2);

        // sorting and filter, rainToday
        const rainTodaySort = orderBy(result.data, ['rainfall_value'], ['desc']);
        const filterDatas = rainTodaySort.filter(d => {
          const time = new Date(d.rainfall_datetime.replace(/-/g, '/')).getTime();
          return (last24hrs < time && d.agency.agency_shortname.en === 'HII'); // && d.rainfall_value > 0
        });
        this.setState({
          datasRainToday: filterDatas,
          isLoadingRainToday: false,
          latestRainToday: this.getMaxDate(filterDatas),
        });
      })
      .catch(error => this.setState({ error, isLoadingRainToday: false }));
  }


  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleModalOpen = params => {
    this.setState({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  }

  handleSearchMarker = (id, lat, lng) => e => {
    const { map, markers } = this.state;

    // prevent default action
    e.preventDefault();

    // pan map to current marker position
    map.panTo([parseFloat(lat), parseFloat(lng)]);

    // open Popup
    const currentMarker = markers[id];
    if (currentMarker) {
      currentMarker.openPopup();
    }
  }

  handleData = () => {
    this.setState({
      type: 'rainfall24h',
    });
  };

  handleSetMap = map => {
    this.setState({
      map,
    });
  };

  fetchCsv() {
    // fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/ffpi_report.txt`)
    //   // .then(resp => resp.text())
    //   .then(response => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error('Something went wrong ...');
    //   })
    //   .then(results => {
    //     const list = Papa.parse(results).data.slice(1);
    //     const array = [];
    //     // eslint-disable-next-line arrow-body-style
    //     list.map(a => {
    // eslint-disable-next-line max-len
    //       return array.push({'provices': a[0], 'amphon': a[1], 'tumbon': a[2], 'ffpi': a[3], 'rainForecast': a[4], 'telestation': a[5], 'basin': a[6], 'geocode': a[7]});
    //     });
    //     this.setState({
    //       isLoading: false,
    //       datas: array,
    //     });
    //   })
    //   .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/header.txt`)
      .then(resp => resp.text())
      .then(results => {
        this.setState({
          isLoading: false,
          header: Papa.parse(results).data.slice(1),
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/flashflood_report.txt`)
      .then(resp => resp.text())
      .then(results => {
        const list = Papa.parse(results).data.slice(1);
        const array = [];
        const arrayList = [];
        const tambonId = [];
        // console.log('list ', list);
        list.map(a => array.push({
          tambon_id: a[0], ffpi: a[4], accrf: a[5],
        }));
        list.map(a => tambonId.push(a[0]));
        list.map(a => arrayList.push({
          // eslint-disable-next-line max-len
          provices: a[1], amphon: a[2], tumbon: a[3], ffpi: a[4], rainForecast: a[5], telestation: a[6], basin: a[7],
        }));

        this.setState({
          isLoading: false,
          datasRiskArea: array,
          arrayTambonId: tambonId,
          datas: arrayList,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    fetch(`${process.env.MIX_FEWS_URL}/model-output/data_portal/flashflood/storm.txt`)
      .then(resp => resp.text())
      .then(results => {
        const list = Papa.parse(results).data.slice(1);
        const array = [];
        list.map(a => array.push({
          name: a[0], file: a[1], lat: a[2], long: a[3],
        }));
        this.setState({
          isLoading: false,
          dataStorm: array,
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { classes, t } = this.props;

    const {
      error, datas, modalHeader, modalSubHeader, datasRiskArea, arrayTambonId,
      modalContent, open, header, datasRainToday, latestRainToday, isLoadingRainToday, dataStorm,
    } = this.state;

    // if error, show error message
    if (error) {
      return <div className="text-center">{error.message}</div>;
    }

    // if still loading, show spinnerisLoading ||
    if (isLoadingRainToday) {
      return (
        <div className="text-center">
          <FaSpinner size={30} />
        </div>
      );
    }

    return (
      <div className={classes.root}>

        <WlContext.Provider
          value={{
            handleModalOpen: this.handleModalOpen,
          }}
        >
          <div className={classes.layout}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" py={1} width="100%">
                  <Box display="inline" pt={2} alignItems="center">
                    <Typography variant="h4">
                      {t('คาดการณ์น้ำท่า-ระดับน้ำในแม่น้ำสายหลัก')}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} width="100%">
                  <FlashFloodMap
                    {...this.state}
                    datas={datas}
                    datasRiskArea={datasRiskArea}
                    dataStorm={dataStorm}
                    arrayTambonId={arrayTambonId}
                    header={header}
                    // datasDischarge={datasDischarge}
                    handleSetMap={this.handleSetMap}
                    handleModalOpen={this.handleModalOpen}
                    datasRainToday={datasRainToday}
                    latestRainToday={latestRainToday}
                  />
                </Box>
              </Grid>
              <Modal
                id="modal-chart"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={this.handleModalClose}
                style={{ align: 'center', justifyContent: 'center' }}
              >
                <div style={this.getModalStyle()} className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      <IconButton onClick={this.handleModalClose} style={{ padding: 0 }}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Typography variant="h5" id="modal-title">
                    {modalHeader}
                  </Typography>

                  <Typography variant="subtitle1" id="simple-modal-description">
                    {modalSubHeader}
                  </Typography>
                  <Box mt={3}>
                    {modalContent}
                  </Box>
                </div>
              </Modal>
            </Grid>
          </div>
        </WlContext.Provider>
      </div>
    );
  }
}

FlashFlood.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withWidth(), withStyles(styles, { withTheme: true }), withTranslation(),
)(FlashFlood);
