import React, { useState, useEffect } from 'react';
import { gistdaSituation, apiKeyGoogle } from './path/drivepath';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Img from "react-image";

import useStyles from './style';
import { processDataAndFilterLatest, handleClick } from './utils/Utils'

const initPath = process.env.MIX_APP_PATH;

function BoxGistda(props) {
    const { openModalImage } = props;
    const classes = useStyles();

    return (
        <>
            <Grid
                container
                spacing={3}
                alignItems="start"
                justifyContent="center"
                style={{ marginBottom: 30 }}
            >
                <Grid item xs={12}>
                    <Box style={{ position: "relative", width: "100%" }}>
                        <Img
                            src={`${initPath}images/mhesi_warroom/tab.png`}
                            width="100%"
                            height="50px"
                            style={{ position: "relative" }}
                            alt="Traffy fondue แจ้งปัญหาสำหรับประชาชน"
                        />
                        <Box className={classes.tabTitle}>
                            Traffy fondue “ อว. ช่วยน้ำท่วม ”
                        </Box>
                        <Box
                            style={{
                                position: "absolute",
                                right: -31,
                                top: -23,
                                display: "flex",
                            }}
                        >
                            <a
                                href="https://www.nstda.or.th/"
                                className={classes.imgWrapper}
                                target="_blank"
                            >

                                <Img
                                    src={`${initPath}images/mhesi_warroom/logo-fondue.png`}
                                    width="100%"
                                    alt="Traffy fondue แจ้งปัญหาสำหรับประชาชน"
                                    className={classes.logoPartner}
                                />
                            </a>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    style={{ marginBottom: 30 }}
                >
                    {/* <Grid item xs={12} md={12}>
                        <a href="#">
                            <Img
                                src={`${initPath}images/mhesi_warroom/traffy-v1.png`}
                                className={classes.img}
                                style={{ borderRadius: 9 }}
                                alt="Traffy fondue แจ้งปัญหาสำหรับประชาชน"
                            />
                        </a>
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Img
                            src={`${initPath}images/mhesi_warroom/founde_01.jpg`}
                            className={classes.img}
                            style={{ borderRadius: 9 }}
                            alt="Traffy fondue แจ้งปัญหาสำหรับประชาชน"
                        />
                        <Box p={2} fontSize={18}>Line <a href="https://page.line.me/105rlyvn?openQrModal=true" target='_blank'>@traffyfondue</a></Box>
                        <a href="https://drive.google.com/drive/u/1/folders/1opfmAREZ9WcMhIVSbmqjmvVZlCKQGzyK" target='_blank'>
                            <Img
                                src={`${initPath}images/mhesi_warroom/founde_04.jpg`}
                                className={classes.img}
                                style={{ borderRadius: 9 }}
                                alt="Traffy fondue แจ้งปัญหาสำหรับประชาชน"
                            />
                        </a>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Img
                            src={`${initPath}images/mhesi_warroom/founde_02.jpg`}
                            className={classes.img}
                            style={{ borderRadius: 9 }}
                            alt="Traffy fondue แจ้งปัญหาสำหรับประชาชน"
                        />
                    </Grid>
                </Grid>
        </>
    );
}

export default BoxGistda;