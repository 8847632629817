import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

// icon
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FaChartLine, FaChartBar } from '../../utils/Fontawesome';

// components
import DamMainDailyChart from './DamMainDailyChart';
import DamMainDailyChartCompare from './DamMainDailyChartCompare';
// import DamMainDailyChartCompare from './DamMainDailyChartCompare';

import { styles } from '../../styles/Style';

const AntTable = withStyles({
  root: {
    fontFamily: 'Kanit',
  },
})(Table);

const AntTableHeadCell = withStyles({
  root: {
    fontFamily: 'Kanit',
    fontSize: '1rem',
    '&:nth-of-type(1)': {
      borderRight: '1px solid #aaa',
      borderBottom: '1px solid #aaa',
    },
    '&:nth-of-type(2)': {
      borderRight: '1px solid #aaa',
      borderBottom: '1px solid #aaa',
    },
    '&:nth-of-type(3)': {
      borderBottom: '1px solid #aaa',
    },
  },
})(TableCell);

const AntTableCell = withStyles({
  root: {
    fontFamily: 'Kanit',
    fontSize: '1rem',
    '&:nth-of-type(1)': {
      borderRight: '1px solid #aaa',
      borderBottom: 'none',
    },
    '&:nth-of-type(2)': {
      borderRight: '1px solid #aaa',
      borderBottom: 'none',
    },
    '&:nth-of-type(3)': {
      borderBottom: 'none',
    },
  },
})(TableCell);

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #ccc',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    fontFamily: 'Kanit',
    fontSize: '1rem',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      borderBottom: '4px solid',
      borderBottomColor: theme.palette.primary.main,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const TabContainer = ({ children }) => (
  <Typography component="div">
    <Box py={4}>
      {children}
    </Box>
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

// function TabPanel(props) {
//   const {
//     children, value, index, ...other
//   } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`dam-tabpanel-${index}`}
//       aria-labelledby={`dam-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           {children}
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `dam-tab-${index}`,
//     'aria-controls': `dam-tabpanel-${index}`,
//   };
// }

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const useStyles = makeStyles(styles);

export default function DamMainContainer() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <>
      <AntTabs
        id="value"
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="main dam tab"
        // className={classes.boxTabStyle}
        variant="scrollable"
        scrollButtons="auto"
      >
        <AntTab label={(
          <>
            <Box>
              <FaChartLine fontSize="small" style={{ marginRight: 8 }} />
              {' '}
              <Hidden xsDown>{t('เปรียบเทียบ')}</Hidden>
              {' '}
              {t('รายปี')}
            </Box>
          </>
)}
        />
        {' '}
        />
        <AntTab label={(
          <>
            <Box>
              <FaChartBar fontSize="small" style={{ marginRight: 8 }} />
              {' '}
              <Hidden xsDown>{t('เปรียบเทียบ')}</Hidden>
              {' '}
              {t('รายวัน')}
            </Box>
          </>
)}
        />
        {' '}
        />
        <AntTab label={(
          <>
            <Box flexDirection="row" alignItems="center" style={{ display: 'flex' }}>
              <HelpOutlineIcon fontSize="small" style={{ marginRight: 6, height: 16 }} />
              {' '}
              <p>{t('คำอธิบาย')}</p>
            </Box>
          </>
)}
        />
        {' '}
        />
      </AntTabs>
      {value === 0 && (
        <TabContainer component="div">
          <DamMainDailyChart />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer component="div">
          <DamMainDailyChartCompare />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer component="div">
          <Box px={4} pb={4} textAlign="center">
            <Typography variant="h6">
              {t('เนื่องจากทั้ง')}
              {' '}
              <b>
                {`4 ${t('เขื่อนหลักลุ่มน้ำเจ้าพระยา')} (${t('เขื่อน')}${t('ภูมิพล')} ${t('เขื่อน')}${t('สิริกิติ์')} ${t('เขื่อน')}${t('ป่าสักชลสิทธิ์')} ${t('เขื่อน')}${t('แควน้อยบำรุงแดน')}) `}
              </b>
              {t('มีข้อมูลสมบูรณ์ในช่วงเวลาแตกต่างกัน ดังนั้นการแสดงผลในแต่ละปีเป็นการรวมข้อมูลจากจำนวนเขื่อนที่ไม่เท่ากัน โดยรายละเอียดการแสดงผลข้อมูลในแต่ละช่วงเวลาดังตาราง')}
            </Typography>
          </Box>
          <Hidden xsDown>
            <AntTable key="key" className={classes.tableStyle} aria-label="description table">
              <TableHead>
                <TableRow>
                  <AntTableHeadCell align="center">
                    <Typography variant="h6">
                      {t('ปี ค.ศ')}
                      {' '}
                      1994-2002
                    </Typography>
                  </AntTableHeadCell>
                  <AntTableHeadCell align="center">
                    <Typography variant="h6">
                      {t('ปี ค.ศ')}
                      {' '}
                      2003-2008
                    </Typography>
                  </AntTableHeadCell>
                  <AntTableHeadCell align="center">
                    <Typography variant="h6">
                      {t('ปี ค.ศ')}
                      {' '}
                      2009-
                      {t('ปัจจุบัน')}
                    </Typography>
                  </AntTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('ภูมิพล')}
                  </AntTableCell>
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('ภูมิพล')}
                  </AntTableCell>
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('ภูมิพล')}
                  </AntTableCell>
                </TableRow>
                <TableRow>
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('สิริกิติ์')}
                  </AntTableCell>
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('สิริกิติ์')}
                  </AntTableCell>
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('สิริกิติ์')}
                  </AntTableCell>
                </TableRow>
                <TableRow>
                  <AntTableCell align="center" />
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('ป่าสักชลสิทธิ์')}
                  </AntTableCell>
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('ป่าสักชลสิทธิ์')}
                  </AntTableCell>
                </TableRow>
                <TableRow>
                  <AntTableCell align="center" />
                  <AntTableCell align="center" />
                  <AntTableCell align="center">
                    {t('เขื่อน')}
                    {''}
                    {t('แควน้อยบำรุงแดน')}
                  </AntTableCell>
                </TableRow>
              </TableBody>
            </AntTable>
          </Hidden>
          <Hidden smUp>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6">ปี ค.ศ 1994-2002</Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('ภูมิพล')}
                </Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('สิริกิติ์')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">ปี ค.ศ 2003-2008</Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('ภูมิพล')}
                </Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('สิริกิติ์')}
                </Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('ป่าสักชลสิทธิ์')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">ปี ค.ศ 2009-ปัจจุบัน</Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('ภูมิพล')}
                </Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('สิริกิติ์')}
                </Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('ป่าสักชลสิทธิ์')}
                </Typography>
                <Typography variant="body2">
                  {t('เขื่อน')}
                  {''}
                  {t('แควน้อยบำรุงแดน')}
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </TabContainer>
      )}
    </>
  );
}
