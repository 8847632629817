import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

// ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Button, Hidden } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import { FaHome } from '../../utils/Fontawesome';

// components
import StormHistoryCriteriaJtwc from './StormHistoryCriteriaJtwc';
import StormHistoryMapJtwc from './StormHistoryMapJtwc';
import StormHistoryLegendTable from './StormHistoryLegendTable';
import { StormListJtwcMemo } from './StormListJtwc';
import StormTrackingImage from './StormTrackingImage';

// main  style
import {
  styles,
} from '../../styles/Style';

// context
import StormContext from '../../contexts/stormContext';

const useStyles = makeStyles(styles);

// constant
const initPath = process.env.MIX_APP_PATH;

function getModalStyle() {
  const top = 0;
  const left = 0;
  const right = 0;
  const bottom = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    right: `${right}%`,
    bottom: `${bottom}%`,
    margin: 'auto',
    width: '90%',
    height: '90%',
    overflowY: 'auto',
    backgroundColor: '#FFF',
    padding: '20px',
    position: 'absolute',
  };
}

export default function StormHistoryJtwc() {
  const classes = useStyles();
  const { t, i18n } = useTranslation(); 
  const [data, setData] = useState([]);
  const [activeLayer, setLayer] = useState({});
  const [modalStyle] = useState(getModalStyle);
  const [modal, setModal] = useState({
    open: false,
    modalHeader: null,
    modalSubHeader: null,
    modalContent: null,
  });
  const [selectAll, setSelectAll] = useState(true);
  const [historyPage, setHistoryPage] = useState(false);

  const handleToggleLayer = (id, code) => {
    // console.log(`handleToggleLayer: ${id}, status: ${value}`);
    const index = data.findIndex(obj => obj.text === id && obj.metadata.code === code);
    if (index !== -1) {
      const updatedData = [...data]; // Create a copy of the original array
      updatedData[index] = { ...updatedData[index], isChecked: !updatedData[index].isChecked };
      setData(updatedData);

      const areAllChecked = updatedData.every(storm => storm.isChecked);
      if (areAllChecked) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  };

  const handleSelectAllChange = event => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    const updatedData = data.map(obj => ({ ...obj, isChecked }));
    setData(updatedData);
  };

  const handleData = datas => {
    setData(datas);
  };

  const handleActiveLayer = layers => {
    setLayer(layers);
  };

  const handleModalOpen = params => {
    setModal({
      open: true,
      modalHeader: params.header,
      modalSubHeader: params.subheader,
      modalContent: params.content,
    });
  };

  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  };

  return (
    <div className={classes.layout}>
      <Grid container spacing={4} style={{ marginTop: 10 }}>
        <Grid item xs>
          <Breadcrumbs aria-label="breadcrumb">
            <Button href={`${initPath}weather`}>
              <FaHome fontSize="small" style={{ marginRight: 5 }} />
              <Typography
                variant="h6"
                color="textSecondary"
                display="inline"
                style={{ fontSize: 14 }}
              >
                {" "}
                {t("ติดตามสภาพอากาศ")}
              </Typography>
            </Button>
            <Button href={`${initPath}weather/storm`}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ fontSize: 14 }}
              >
                {t("พายุ")}
              </Typography>
            </Button>
            <Button href={`${initPath}weather/storm`}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ fontSize: 14 }}
              >
                {t("แผนที่วิเคราะห์เส้นทางและความแรงของพายุ")}
              </Typography>
            </Button>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ fontSize: 14 }}
            >
              {t("ภาพพายุหมุนในอดีต")}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs>
          <Box display="flex" className={classes.boxRadiusSecondary}>
            <Box
              flexGrow={1}
              p={1}
              display="flex"
              alignItems="center"
              style={{
                backgroundColor: "#fff",
                borderBottomLeftRadius: 6,
                borderTopLeftRadius: 6,
              }}
            >
              <Box p={1} className={classes.titleName}>
                {t("ภาพพายุหมุนในอดีต")}
              </Box>
            </Box>
            <Box
              p={1}
              style={{
                borderLeft: "1px solid #ccc",
                backgroundColor: "#fff",
              }}
            >
              <Button
                href="../meaning"
                target="_blank"
                size="small"
                className={classes.outlinedOldPrimary}
              >
                <HelpOutlineIcon />
                <Hidden xsDown>
                  <Typography
                    variant="h6"
                    style={{ marginLeft: 8, fontSize: 15 }}
                  >
                    {t("ความหมายและที่มาของชื่อพายุ")}
                  </Typography>
                </Hidden>
              </Button>
            </Box>
            <Box
              style={{
                borderLeft: "1px solid #ccc",
                backgroundColor: "#fff",
                borderBottomRightRadius: 6,
                borderTopRightRadius: 6,
              }}
            >
              <Button
                className={classes.outlinedRightPrimary}
                size="small"
                onClick={() => setHistoryPage(!historyPage)}
              >
                <PhotoLibraryOutlinedIcon
                  fontSize="small"
                  className="rightRadius"
                />
                <Hidden xsDown>
                  <Typography
                    variant="h6"
                    style={{ marginLeft: 8, fontSize: 15 }}
                  >
                    {historyPage ? t("ดูแผนที่พายุ") : t("ดูภาพย้อนหลัง")}
                  </Typography>
                </Hidden>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {historyPage ? (
        <StormTrackingImage classes={classes}/>
      ) : (
        <Box>
          <StormHistoryCriteriaJtwc
            handleActiveLayer={handleActiveLayer}
            handleData={handleData}
            setSelectAll={setSelectAll}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={10} lg={10}>
              <StormContext.Provider
                value={{
                  handleModalOpen,
                }}
              >
                <StormHistoryMapJtwc data={data} activeLayer={activeLayer} />
              </StormContext.Provider>
              <StormHistoryLegendTable />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2}>
              <Box style={{ borderRadius: 6, border: "1px solid #ccc" }}>
                <StormListJtwcMemo
                  data={data}
                  handleToggleLayer={handleToggleLayer}
                  selectAll={selectAll}
                  handleSelectAllChange={handleSelectAllChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Modal
        id="modal-chart"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modal.open}
        onClose={handleModalClose}
        style={{ align: "center", justifyContent: "center" }}
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="h5" id="modal-title">
            {modal.modalHeader}
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
            {modal.modalSubHeader}
          </Typography>
          <Box mt={3}>{modal.modalContent}</Box>
        </div>
      </Modal>
    </div>
  );
}

// For reference : https://www.thaiwater.net/TyphoonTracking/storm/storm.html
